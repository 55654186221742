import { connect, useDispatch, useSelector } from 'react-redux';
import { debounce, map } from 'lodash-es';
import { Field, InjectedFormProps, reduxForm, submit } from 'redux-form';

import { AppState } from '../../../store';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  Text,
  ModalFixedFooter,
  PanelSection,
} from '../../../core/components/styled';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';

import { DatePicker, Dropdown, FileInput, Input, TextArea, TypeAhead, TypedField } from '../../../core/components';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { isDecimalUpTo2, isRequired } from 'src/utils/services/validator';
import { REQUEST_TYPE_OPTIONS } from 'src/customers/constants/requestTypeOptions';
import searchRubiconCustomerLocations from 'src/customers/services/rubiconServices';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {
  onClose: (formPristine?: boolean) => void;
  onSubmit: (formValues: any) => any;
  isRubiconServicesPage: boolean;
}

interface FormValues {}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const AddNewWorkOrderForm: React.FC<Props> = ({ onClose, pristine, isRubiconServicesPage }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state: AppState) => state.account.login.user.userId);
  const vendorId = useSelector(currentVendorId);

  const requestTypeOptions = map(REQUEST_TYPE_OPTIONS, reqType => ({
    label: reqType.name,
    value: reqType.id,
  }));

  const loadContainersLocationsOptions = debounce((searchTerm, onOptionsLoaded) => {
    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }

    searchRubiconCustomerLocations(
      vendorId,
      userId,
      searchTerm,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      1,
      10000,
    ).then(onOptionsLoaded);
  }, 500);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(submit('AddNewWorkOrderForm'));
      }}
    >
      <PanelSection padding="small xSmall no">
        <Grid multiLine>
          {!isRubiconServicesPage && (
            <GridColumn size="12/12" padding="no small">
              <TypedField
                name="serviceId"
                component={TypeAhead}
                validate={[isRequired]}
                props={{
                  label: translate('customers.rubiconService.rubiconServicesCustomerServiceInfo'),
                  getOptions: loadContainersLocationsOptions,
                  isClearable: true,
                }}
              />
            </GridColumn>
          )}
          <GridColumn size="6/12">
            <TypedField
              name={`datePerformed`}
              component={DatePicker}
              validate={[isRequired]}
              props={{
                isTodayWithNoStyle: true,
                label: translate('customers.rubiconService.dateSubmitted'),
              }}
            />
          </GridColumn>
          <GridColumn size="6/12">
            <TypedField
              name="serviceRequestType"
              component={Dropdown}
              validate={[isRequired]}
              props={{
                label: translate('customers.rubiconService.serviceRequestType'),
                options: requestTypeOptions,
              }}
            />
          </GridColumn>
          <Grid size="6/12">
            <>
              <Text margin="medium small no small">$</Text>
              <TypedField
                name="amount"
                component={Input}
                validate={[isRequired, isDecimalUpTo2]}
                props={{
                  label: translate('autoDispatch.enterAmount'),
                  type: 'number',
                }}
              />
            </>
          </Grid>
          <GridColumn size="12/12">
            <Field name="notes" component={TextArea} rows="2" label={translate('autoDispatch.addNote')} />
          </GridColumn>
          <GridColumn size="6/12">
            <Field name="files" component={FileInput} type="text" />
          </GridColumn>
        </Grid>
      </PanelSection>
      <ModalFixedFooter flex-direction="column" align="center" padding="xLarge small" justifyContent="space-around">
        <ButtonSet align="center" margin="large small">
          <Button type="submit" color="primary">
            {translate('common.save')}
          </Button>
          <Button type="button" margin="no small" color="secondary" onClick={() => onClose(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </ModalFixedFooter>
    </form>
  );
};
export default connect()(
  reduxForm<FormValues, PropsWithoutReduxForm>({
    form: 'AddNewWorkOrderForm',
    onSubmitFail: focusFirstInvalidField,
  })(AddNewWorkOrderForm),
);
