import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DocumentTitle } from 'src/common/components';
import { loadVideoDownloadDetails } from 'src/routes/ducks';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import translate from 'src/core/services/translate';
import VideoMessageModal from './VideoMessageModal';

type Props = {
  closeModal: () => void;
  videoDownloadJobId?: number;
};

const VideoMessageModalResolver: React.FC<Props> = ({ closeModal, videoDownloadJobId }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = () => {
    const dependencies: Promise<unknown>[] = [];

    if (vendorId) {
      dependencies.push(loadVideoDownloadDetails(vendorId, videoDownloadJobId)(dispatch));
    }

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('routes.videoRequest.videoFootage')}</DocumentTitle>
      <TypedResolver
        loadingComponent={PageLoadingOverlay}
        redirectOnError="/dashboard"
        resolve={loadDependencies}
        successComponent={VideoMessageModal}
        successProps={{ closeModal }}
      />
    </>
  );
};

export default VideoMessageModalResolver;
