import humps from 'humps';
import translate from 'src/core/services/translate';
import SERVICE_TYPE_ICONS from 'src/customers/constants/serviceTypeIcons';
import styled from 'styled-components';
import { OpportunityRow } from '../interfaces/Opportunities';
import { AWARD_COMPLETED, OPEN_AWARD, OPEN_FOR_BID } from '../constants/opportunitiesStatus';
import { ServiceIconImage } from 'src/fleet/components/styled/RubiconDispatches';
import { IconButtonIcon } from 'src/core/components/styled/Button';
import { Text } from 'src/core/components/styled';

const MobileListRowContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
  padding: 10px 0;
`;

const ContentContainer = styled.div`
  width: calc(100% - 70px);
`;

const ContentRowContainer = styled.div`
  width: 90%;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px 0;
`;

const HeaderInfoContainer = styled(ContentRowContainer)`
  display: flex;
`;

export const OpportunityMobileListRow: React.FC<OpportunityRow> = ({
  customerName,
  isActionRequired,
  equipment,
  action: { numberOfDaysOverdue, isRequired },
  opportunityStatus,
  requestType,
  offer,
}) => {
  const showDate =
    opportunityStatus.id === AWARD_COMPLETED ||
    opportunityStatus.id === OPEN_AWARD ||
    opportunityStatus.id === OPEN_FOR_BID;
  return (
    <MobileListRowContainer>
      <ServiceIconImage width="70px" src={SERVICE_TYPE_ICONS[humps.camelize(equipment.iconType.split('.').join(''))]} />
      <ContentContainer>
        {isActionRequired && !!numberOfDaysOverdue && (
          <Text block weight="normal" margin="no xxSmall" singleLine size="small" color="alert">
            {translate('common.xDaysOverdue', { days: numberOfDaysOverdue })}
          </Text>
        )}
        <HeaderInfoContainer>
          <Text size="small" weight="medium" margin="no xxSmall">
            {opportunityStatus.name}
          </Text>
          {showDate && (
            <Text block margin="no xxSmall" weight="normal" size="small" color="grayDarker">
              {offer.deliveryDate}
              {isRequired && <IconButtonIcon icon="bell" color="alert" size="medium" margin="no no no xxSmall" />}
            </Text>
          )}
        </HeaderInfoContainer>
        <HeaderInfoContainer>
          <Text size="small" weight="medium" margin="no xxSmall">
            {translate('opportunity.opportunities.targetRate', { rate: offer.service })}
          </Text>
          {!!offer.overallserviceCharge && (
            <Text block margin="no xxSmall" weight="normal" size="small" color="primary">
              {translate('opportunity.opportunities.bidRate', { rate: offer.overallserviceCharge })}
            </Text>
          )}
        </HeaderInfoContainer>
        <ContentRowContainer>
          <Text margin="no xxSmall" weight="medium" singleLine>
            {customerName}
          </Text>
        </ContentRowContainer>
        <ContentRowContainer>
          <Text margin="no xxSmall" weight="medium" singleLine>
            {equipment.subType
              ? translate(`haulerProfile.equipments.equipmentSubTypes.${equipment.subType}`)
              : equipment.type}
          </Text>
          {equipment.size && (
            <Text margin="no xxSmall" weight="light" size="small" singleLine>
              {parseInt(equipment.size)} {equipment.units}
            </Text>
          )}

          <Text margin="no xxSmall" singleLine weight="light" size="small">
            {equipment.wasteMaterial}
          </Text>
        </ContentRowContainer>
        <ContentRowContainer>
          <Text size="small" margin="no xxSmall" weight="light">
            {requestType.name}
          </Text>
        </ContentRowContainer>
      </ContentContainer>
    </MobileListRowContainer>
  );
};
