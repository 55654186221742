import React, { PureComponent } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';

import {
  MapInfoImage,
  MapInfoImageWrapper,
  MapInfoWindow,
  MapInfoWindowDetail,
  MapInfoWindowDetailLabel,
  MapInfoWindowDetails,
  MapInfoWindowDetailsContainer,
  MapInfoWindowIcon,
  MapInfoWindowTitle,
  MapInfoWindowTitleContainer,
} from '../../core/components/styled';
import { INSIGHT_ICONS, SERVICE_MODES, GOOGLE as google } from '../constants';
import { getServiceModeIcon } from '../services/serviceMode';
import { getSpeedInformation } from '../services/getSpeedInformation';
import RouteImagesModal from '../../routes/components/modals/RouteImagesModal';
import translate from '../../core/services/translate';
import { time } from '../../utils/services/formatter';

export type ServiceException = any;

interface Props {
  isInfoWindowOpen?: boolean;
  toggleInfoWindow: (key: string) => void;
  unitOfMeasure: string;
  serviceException: ServiceException;
  serviceMode: number;
  clusterer: Clusterer;
}

interface State {
  isImageModalOpen: boolean;
}

class ServiceExceptionMarker extends PureComponent<Props, State> {
  readonly state = {
    isImageModalOpen: false,
  };

  polyline: any = null;

  setPolylineRef = (polyline: any) => {
    this.polyline = polyline;
  };

  toggleInfoWindow = () => {
    const {
      toggleInfoWindow,
      serviceException: { id },
    } = this.props;

    toggleInfoWindow(`serviceExceptions.${id}`);
  };

  openImageModal = () => {
    this.setState({ isImageModalOpen: true });
  };

  closeImageModal = () => {
    this.setState({ isImageModalOpen: false });
  };

  render() {
    const { serviceException, isInfoWindowOpen, unitOfMeasure, serviceMode, clusterer } = this.props;
    const { isImageModalOpen } = this.state;

    const markerPosition = {
      lat: parseFloat(serviceException.latitude),
      lng: parseFloat(serviceException.longitude),
    };

    const markerIcon = {
      url: INSIGHT_ICONS.exceptionsObstacles,
      size: new google.maps.Size(15, 15),
      scaledSize: new google.maps.Size(15, 15),
    };
    const pixelOffset = new google.maps.Size(0, -20);

    return (
      <Marker
        key={serviceException.id}
        icon={markerIcon}
        position={markerPosition}
        onClick={this.toggleInfoWindow}
        clusterer={clusterer}
        noClustererRedraw
      >
        {isInfoWindowOpen && markerPosition && (
          <InfoWindow onCloseClick={this.toggleInfoWindow} position={markerPosition} options={{ pixelOffset }}>
            <MapInfoWindow vertical>
              <MapInfoWindowTitleContainer>
                <MapInfoWindowIcon size="small" src={INSIGHT_ICONS[getServiceModeIcon(serviceMode)]} />
                <MapInfoWindowTitle>{SERVICE_MODES[serviceMode].name}</MapInfoWindowTitle>
              </MapInfoWindowTitleContainer>
              <MapInfoWindowDetailsContainer>
                <MapInfoWindowDetails>
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('common.truck')}:</MapInfoWindowDetailLabel>
                    {serviceException.vehicleName}
                  </MapInfoWindowDetail>
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('common.speed')}:</MapInfoWindowDetailLabel>
                    {getSpeedInformation(serviceException.speed, unitOfMeasure)}
                  </MapInfoWindowDetail>
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('common.timestamp')}:</MapInfoWindowDetailLabel>
                    {time(serviceException.timestamp)}
                  </MapInfoWindowDetail>
                  {!!serviceException.address && (
                    <MapInfoWindowDetail>
                      <MapInfoWindowDetailLabel>{translate('common.address')}:</MapInfoWindowDetailLabel>
                      {serviceException.address}
                    </MapInfoWindowDetail>
                  )}
                  {!!serviceException.obstacles && (
                    <MapInfoWindowDetail>
                      <MapInfoWindowDetailLabel>{translate('common.obstacle')}:</MapInfoWindowDetailLabel>
                      {serviceException.obstacles}
                    </MapInfoWindowDetail>
                  )}
                </MapInfoWindowDetails>
                <MapInfoImageWrapper onClick={this.openImageModal}>
                  <MapInfoImage
                    key={serviceException.imageUrl}
                    src={serviceException.imageUrl}
                    onClick={this.openImageModal}
                  />
                </MapInfoImageWrapper>
              </MapInfoWindowDetailsContainer>

              {!!isImageModalOpen && (
                <RouteImagesModal
                  locationObstacles={serviceException.obstacles}
                  locationAddress={serviceException.address}
                  locationTimestamp={time(serviceException.timestamp)}
                  images={[serviceException]}
                  closeModal={this.closeImageModal}
                />
              )}
            </MapInfoWindow>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

export default ServiceExceptionMarker;
