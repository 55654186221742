import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { Icon } from '../../../core/components';
import { BareButtonStyle } from '../../../core/components/styled';
import { colorMapper, mapper, sizeMapper } from '../../../utils/styles';
import { ButtonWrapperWithPopover } from '../../../common/components/styled';
import { rotate } from '../../../core/styles';

interface Props {
  border?: boolean;
  size?: string;
  color?: string;
  padding?: string;
  margin?: string;
  align?: string;
  vertical?: boolean;
  verticalCenter?: boolean;
  requiresRightMargin?: boolean;
  noWrap?: boolean;

  /**
   * This property will set the basic filter width which
   * will not shrink wether more filters are added, but
   * will overflow in a scrolling manner. This width will
   * not surpass the @param maxFilterWidth in any case.
   *
   * Both @param filterWidth and @param maxFilterWidth are
   * used as proportions from the parent.
   *
   * Example:
   * filterWidth = 6
   * maxFilterWidth = 5
   * The filter will have a width of 100% / 6, which is a
   * greater width than 100% / 5. Careful with this. :D
   */
  filterWidth?: number;

  /**
   * This property restricts the filter entry width so it
   * would not expand throughout the entire parent's space.
   * This is mainly used when there are not enough filters
   * so all the space in the parent is used, thus making the
   * filter to maintain a small proportion.
   */
  maxFilterWidth?: number;
}

export const ActionRequiredContainer = styled.div<Props>`
  border-left: 2px solid lightslategrey;
  padding: 0px 5px;
  ${props =>
    props.border &&
    css`
      border: none;
    `}
`;

export const ServiceIconImage = styled.img<{ width?: string }>`
  width: ${p => p.width || '50px'};
  border-radius: 3px 3px 0 0;
  cursor: pointer;
`;

export const RubiconServicesIconImage = styled.img<{ width?: string }>`
  width: ${p => p.width || '50px'};
  border-radius: 3px 3px 0 0;
`;
export const PopoverDetailsContainer = styled.div`
  display: flex;
  text-align: left;
  & > div {
    margin-top: 20px;
  }
`;

export const BellIcon = styled(Icon)<Props>`
  width: ${props => mapper(props.size, { small: '26px', medium: '30px', large: '33px' })};
  color: ${colorMapper};
  cursor: pointer;
`;

export const BellIconWrapper = styled.div<Props>`
  color: ${props => colorMapper(props)};
`;

export const BetaHeading = styled.h2`
  text-align: center;
  &:after {
    content: ' \00DF';
  }
`;

export const ViewButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: underline;
  cursor: pointer;
`;

export const StatusFilterButton = styled.button<{ isActive?: boolean; small?: boolean }>`
  ${BareButtonStyle}
  display: flex;
  flex-direction: column;
  padding: ${p => (p.small ? '5px' : '20px')};
  border: 1px solid ${props => (props.isActive ? props.theme.brandPrimary : props.theme.gray)};
  border-radius: 6px;
  width: 100%;
  height: ${p => (p.small ? '65px' : '90px')};
  text-align: left;

  ${props =>
    props.isActive &&
    css`
      background-color: ${rgba(props.theme.brandPrimary, 0.13)};
    `}
`;

interface StatusFilterButtonWithLeewayProps {
  isLoading?: boolean;
}

export const StatusFilterButtonWithLeeway = styled(StatusFilterButton)<StatusFilterButtonWithLeewayProps>`
  padding: 0 20px;
  justify-content: center;
  position: relative;

  ${props =>
    props.isLoading &&
    css`
      &:after {
        content: '';
        position: absolute;
        bottom: 8px;
        right: 8px;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        border: 2px solid ${props.theme.brandPrimary};
        border-right-color: transparent;
        animation: ${rotate} 1s linear infinite;
        z-index: 1;
      }
    `}
`;

export const StatusFiltersContainer = styled.div<Props>`
  width: 100%;
  display: flex;
  overflow: auto;
  padding: ${props => sizeMapper(props.padding, 'no xSmall')};
  white-space: ${p => (p.noWrap ? 'nowrap' : 'initial')};

  & ${ButtonWrapperWithPopover} {
    margin: 5px;
    flex: 1 0 ${props => `calc(100% / ${props.filterWidth || 6} - 10px)`};
    max-width: ${props => `calc(100% / ${props.maxFilterWidth || props.filterWidth || 6} - 10px)`};
  }

  ${props =>
    props.verticalCenter &&
    css`
      align-items: center;
    `}

  ${props =>
    props.requiresRightMargin &&
    css`
      &:after {
        content: '';
        flex: 1 0 5px;
      }
    `}
`;

interface DetailsContainerProps {
  padding?: string;
  noShadow?: boolean;
}

export const DetailsContainer = styled.div<DetailsContainerProps>`
  display: flex;
  position: relative;
  padding: ${props => sizeMapper(props.padding, 'no xSmall')};
  width: 100%;

  ${props =>
    !props.noShadow &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100px;
        background: linear-gradient(to right, transparent, white);
        pointer-events: none;
      }
    `}
`;

export const ActionButtonContainer = styled.div<Props>`
  margin: ${props => sizeMapper(props.margin, 'no')};
  display: flex;

  ${props =>
    props.align &&
    css`
      align-items: ${mapper(props.align, { left: 'flex-start', center: 'center', right: 'flex-end' }, 'left')};
    `};
  ${props =>
    props.size &&
    css`
      width: ${mapper(
        props.size,
        {
          xSmall: '25px',
          small: '30px',
          medium: '65px',
          sMedium: '75px',
          large: '80px',
          mLarge: '95px',
          xLarge: '100px',
        },
        'small',
      )};
    `};
  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
    `};
`;

interface ForwardIconContainerProps {
  border?: boolean;
  flex?: boolean;
}

export const ForwardIconContainer = styled.div<ForwardIconContainerProps>`
  margin: 0 6px;

  ${props =>
    props.border &&
    css`
      margin: 0 2px 0 2px;
      border-right: 2px solid #00a599;
    `};

  ${props =>
    props.flex &&
    css`
      display: flex;
    `};
`;

export const RubiconDispatchesFormWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

interface RubiconDispatchesFormItemWrapperProps {
  size?: number;
  grow?: boolean;
  hasSeparator?: boolean;
  padding?: string;
}

export const RubiconDispatchesFormItemWrapper = styled.div<RubiconDispatchesFormItemWrapperProps>`
  padding: ${props => sizeMapper(props.padding, 'no small')};

  ${props =>
    !!props.size &&
    css`
      flex: 0 0 ${props.size}%;
    `}

  ${props =>
    props.grow &&
    css`
      flex-grow: 1;
    `}

  ${props =>
    props.hasSeparator &&
    css`
      border-left: 1px solid ${props.theme.grayLight};
    `}
`;

export const WorkOrderLabel = styled.span<Props>`
  display: inline-block;
  width: ${props => mapper(props.size, { auto: 'auto', small: '60px', medium: '80px', large: '100px' }, 'auto')};
  max-width: 100%;
  height: 22px;
  overflow: hidden;
  border-radius: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 32px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: ${props => mapper(props.size, { small: '12px', medium: '14px', large: '16px' }, 'large')};
  color: ${props => props.theme.brandDefault};
`;
