import Icon from 'src/core/components/Icon';
import { BareButtonStyle } from 'src/core/components/styled';
import { COLOR_WHITE } from 'src/core/styles';
import styled from 'styled-components';

interface Props {
  disabled?: boolean;
}

export const EmailRecipient = styled.span<Props>`
  background-color: ${props => (props.disabled ? props.theme.grayDark : props.theme.brandPrimary)};
  color: ${COLOR_WHITE};
  padding: 2.5px 5px 3px 5px;
  margin-right: 10px;
  margin-bottom: 15px;
  border-radius: 10px;
`;

export const EmailRecipientRemoveIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 10px;
  margin-left: 2.5px;
  height: 10px;
  color: ${props => props.theme.brandPrimary};
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
  padding: 1px;

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }
`;

export const EmailRecipientRemove = styled.button`
  ${BareButtonStyle};
  width: 15px;
  height: 15px;
`;
