import React, { PureComponent } from 'react';

import { Modal } from '../../../core/components';
import { ModalSection, Text } from '../../../core/components/styled';

interface Props {
  closeModal: () => void;
  modalTitle?: string;
  note: string;
}

class CommunityInsightsNoteModal extends PureComponent<Props> {
  render() {
    const { closeModal, modalTitle, note } = this.props;

    return (
      <Modal title={modalTitle} size="small" padding="small" verticalSize="xSmall" onClose={closeModal}>
        <ModalSection margin="no no sMedium">
          <Text align="center" block>
            {note}
          </Text>
        </ModalSection>
      </Modal>
    );
  }
}

export default CommunityInsightsNoteModal;
