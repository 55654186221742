import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import doCreateRouteImport from '../services/createRouteImport';

// Actions
const START_CREATE = 'routeImport/routeImportCreation/START_CREATE';
const COMPLETE_CREATE = 'routeImport/routeImportCreation/COMPLETE_CREATE';
const FAIL_CREATE = 'routeImport/routeImportCreation/FAIL_CREATE';
const RESET = 'routeImport/routeImportCreation/RESET';

// Initial state
const initialState = {
  isCreating: false,
  isCreationFailed: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_CREATE:
      return update(state, {
        $merge: {
          isCreating: true,
          isCreationFailed: false,
        },
      });

    case COMPLETE_CREATE:
      return update(state, {
        $merge: {
          isCreating: false,
          isCreationFailed: false,
        },
      });

    case FAIL_CREATE:
      return update(state, {
        $merge: {
          isCreating: false,
          isCreationFailed: true,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startCreate = () => ({
  type: START_CREATE,
});

const completeCreate = () => ({
  type: COMPLETE_CREATE,
});

const failCreate = () => ({
  type: FAIL_CREATE,
});

export const createRouteImport = (vendorId: number, description: string, file: File) => (dispatch: Dispatch) => {
  dispatch(startCreate());

  const createRouteImportPromise = doCreateRouteImport(vendorId, description, file);

  createRouteImportPromise.then(() => dispatch(completeCreate())).catch(() => dispatch(failCreate()));

  return createRouteImportPromise;
};

export const resetRouteImportCreation = () => ({
  type: RESET,
});
