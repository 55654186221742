import React from 'react';
import { Field, FieldArray, WrappedFieldArrayProps } from 'redux-form';

import { Checkbox, Input } from '../../../../core/components';
import { Grid, GridColumn, Message, Panel, PanelSectionTitle } from '../../../../core/components/styled';
import translate from '../../../../core/services/translate';

const MaterialsFieldArray: React.FC<WrappedFieldArrayProps> = ({ fields }) => (
  <Panel padding="large medium">
    <PanelSectionTitle>{translate('haulerProfile.materials')}</PanelSectionTitle>

    <Grid multiLine>
      {fields.map((field, index) => (
        <GridColumn size="4/12" padding="no medium medium no" tabletPadding="no medium small no" key={index}>
          <Field name={`${field}.isSelected`} component={Checkbox} label={fields.get(index).displayTitle} />

          {fields.get(index).supportsNote && fields.get(index).isSelected && (
            <GridColumn size="12/12" padding="medium no no no">
              <Field name={`${field}.userNote`} component={Input} label={translate('haulerProfile.userNote')} />
            </GridColumn>
          )}
        </GridColumn>
      ))}
    </Grid>

    {!fields.length && <Message padding="no">{translate('haulerProfile.noServices')}</Message>}
  </Panel>
);

const Materials = () => <FieldArray name="materials" component={MaterialsFieldArray} props={{}} />;

export default Materials;
