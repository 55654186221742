import { map, omit } from 'lodash-es';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';
import { ContainerType } from '../interfaces/ContainerTypes';

const transformLoadContainerTypes = (containerTypes: ContainerType[]) =>
  map(containerTypes, containerType => ({
    ...omit(containerType, ['equipmentTypes']),
    name: translate(createTranslationKey(containerType.technicalName, 'common.equipmentTypes')) as string,
    subTypes: map(containerType.equipmentTypes, subtype => ({
      ...subtype,
      name: translate(createTranslationKey(subtype.technicalName, 'common.equipmentTypes')),
    })),
  })) as ContainerType[];

export default transformLoadContainerTypes;
