import React from 'react';
import { currency } from '../../../utils/services/formatter';
import { TableCell, TableRow, Text } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
interface Props {
  accountNumber?: string;
  checkNumber?: string;
  invoiceAmount?: string;
  invoiceDate?: string;
  invoiceNumber?: string;
  paymentAmount?: string;
  paymentDate?: string;
  paymentMethod?: string;
  receivedDate?: string;
  referenceNumber?: string;
  status?: string; 
}

const InvoiceFormTableRow: React.FC<Props> = ({
  accountNumber,
  checkNumber,
  invoiceAmount,
  invoiceDate,
  invoiceNumber,
  paymentAmount,
  paymentDate,
  paymentMethod,
  receivedDate,
  referenceNumber,
  status,
}) => (
  <TableRow>
    <TableCell width="20%">
      <Text block size="small">
        {invoiceNumber}
      </Text>
    </TableCell>
    <TableCell width="20%">
      <Text block size="small">
        {accountNumber}
      </Text>
    </TableCell>
    <TableCell width="10%">
      <Text block size="small">
        {status}
      </Text>
    </TableCell>
    <TableCell width="10%">
      <Text block size="small">
        {invoiceDate}
      </Text>
    </TableCell>
    <TableCell width="10%">
      <Text block size="small">
        {receivedDate}
      </Text>
    </TableCell>
    <TableCell width="10%">
      <Text block size="small">
        {currency(parseInt(invoiceAmount || '0'))}
      </Text>
    </TableCell>
    <TableCell width="10%">
      <Text block size="small">
        {paymentAmount ? currency(parseInt(paymentAmount)) : 0}
      </Text>
    </TableCell>
    <TableCell vertical width="10%">
      <Text singleLine title={`${translate('invoice.paymentDate')}: ${paymentDate}`} block size="small">
        {paymentDate}
      </Text>
      {!!checkNumber ? <Text singleLine title={`${translate('invoice.checkNumber')}: ${checkNumber}`} block size="small">
        {checkNumber}
      </Text> :<Text singleLine title={`${translate('invoice.referenceNumber')}: ${referenceNumber}`} block size="small">
        {referenceNumber}
      </Text>}
      <Text singleLine title={`${translate('invoice.paymentMethod.paymentMethod')}: ${paymentMethod}`} block size="small">
        {paymentMethod}
      </Text>
    </TableCell>
  </TableRow>
);

export default InvoiceFormTableRow;
