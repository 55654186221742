export const trimAndNormalizeNumber = (value: string) => {
  const trimmedValue = value.replace(/^\s+|\s+$/g, '');

  if (!trimmedValue.length) {
    return null;
  }

  return isNaN(+trimmedValue) ? null : +trimmedValue;
};

export const normalizeAndReturnNumber = (value: string) => {
  const trimmedValue = value.replace(/[^0-9]+/g, '');
  return trimmedValue;
};

const normalizeNumber = (value: string) => (!!value ? Number(value) : null);

export default normalizeNumber;
