import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { size, filter, find } from 'lodash-es';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { EXCEPTIONS } from '../../constants';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { ModalTitle, ModalSection } from '../../../core/components/styled';
import { saveWasteAuditConfiguration, resetWasteAuditConfiguration } from '../../ducks';
import { WasteAuditConfiguration } from '../../interfaces/WasteAudit';
import { WasteAuditForm } from '../forms';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  isLoading: boolean;
  resetWasteAuditConfiguration: DuckAction<typeof resetWasteAuditConfiguration>;
  saveWasteAuditConfiguration: DuckFunction<typeof saveWasteAuditConfiguration>;
  vendorId: number;
}

class WasteAuditModal extends PureComponent<Props> {
  componentWillUnmount() {
    this.props.resetWasteAuditConfiguration();
  }

  onSubmit = ({ wasteAuditConfiguration }: WasteAuditConfiguration) => {
    const { vendorId, saveWasteAuditConfiguration, closeModal } = this.props;
    const exceptions = find(wasteAuditConfiguration, ({ name }) => name === EXCEPTIONS);
    const activeWasteAuditExceptions = size(
      filter(exceptions?.vendorWasteAuditConfigurationTypes, ({ isEnabled }) => isEnabled),
    );

    if (activeWasteAuditExceptions > 9) {
      createErrorNotification(translate('vendors.alertMessages.wasteAuditMa9'));
      return;
    }

    saveWasteAuditConfiguration(vendorId, wasteAuditConfiguration)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.wasteConfigurationSaved'));
        closeModal(true);
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.saveWasteConfigurationError')));
  };

  render() {
    const { isLoading, closeModal } = this.props;

    return (
      <Modal padding="no" isLoading={isLoading}>
        <ModalSection padding="medium medium small">
          <ModalTitle>{translate('vendors.featureCodes.wasteAudit')}</ModalTitle>
        </ModalSection>
        <WasteAuditForm onSubmit={this.onSubmit} onCancel={closeModal} />
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.vendors.wasteAuditConfiguration.isLoading,
});

const mapDispatchToProps = { saveWasteAuditConfiguration, resetWasteAuditConfiguration };

export default connect(mapStateToProps, mapDispatchToProps)(WasteAuditModal);
