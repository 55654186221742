import React, { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { map } from 'lodash-es';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../../store';
import { currentUserIdSelector, currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../../common/components';
import { DuckFunction } from '../../../../contracts/ducks';
import { getVehicleFiltersPreferencesIds } from 'src/common/utils/filters';
import { getQueryParams } from '../../../../utils/services/queryParams';
import { loadFeatures } from '../../../../vendors/ducks';
import { loadFiltersSettings } from 'src/common/ducks';
import { loadRouteTemplates, loadRouteSequenceStatus, loadGroups } from '../../../ducks';
import { loadVehicleTypesForVendor } from '../../../../fleet/ducks';
import { PageLoading } from '../../../../common/components/styled';
import { Resolver } from '../../../../core/components';
import { SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE } from '../../../constants';
import { shouldLoadPermissionsSelector, loadPermissions } from '../../../../account/ducks';
import RouteTemplatesPage from './RouteTemplatesPage';
import translate from '../../../../core/services/translate';

interface Props extends RouteComponentProps {
  loadFeatures: DuckFunction<typeof loadFeatures>;
  loadPermissions: DuckFunction<typeof loadPermissions>;
  loadRouteSequenceStatus: DuckFunction<typeof loadRouteSequenceStatus>;
  loadRouteTemplates: DuckFunction<typeof loadRouteTemplates>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  loadFiltersSettings: DuckFunction<typeof loadFiltersSettings>;
  loadGroups: DuckFunction<typeof loadGroups>;
  shouldLoadPermissions: boolean;
  vendorId: number;
  userId: string;
}

class RouteTemplatesPageResolver extends PureComponent<Props> {
  loadDependencies = async () => {
    const {
      loadFeatures,
      loadPermissions,
      loadRouteSequenceStatus,
      loadRouteTemplates,
      loadVehicleTypesForVendor,
      loadFiltersSettings,
      loadGroups,
      location: { search },
      shouldLoadPermissions,
      vendorId,
      userId,
    } = this.props;

    const filters = await loadFiltersSettings(vendorId, userId);

    const vehicleFilterPreferences = getVehicleFiltersPreferencesIds(filters).length
      ? getVehicleFiltersPreferencesIds(filters)
      : [];

    const {
      routeTemplateName,
      daysOfServiceIds,
      vehicleTypeIds = vehicleFilterPreferences.join(','),
      routeStatus,
      page,
      limit,
      sortOrder,
      sortedBy,
      groupIds,
    } = getQueryParams(search);

    const { routeTemplates } = await loadRouteTemplates({
      vendorId,
      routeTemplateName,
      daysOfServiceIds,
      vehicleTypeIds,
      routeStatus,
      page,
      limit,
      sortOrder,
      sortedBy,
      groupIds,
    });

    const routeTemplatesIds = map(routeTemplates, route => route.id);

    const dependencies = [
      loadFeatures(vendorId),
      loadRouteSequenceStatus(routeTemplatesIds, SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE),
      loadVehicleTypesForVendor(vendorId),
      loadFiltersSettings(vendorId, userId),
      loadGroups(vendorId),
    ];

    if (shouldLoadPermissions) {
      dependencies.push(loadPermissions());
    }
    return Promise.all(dependencies);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('routes.planner.routePlanner')}</DocumentTitle>
        <Resolver
          successComponent={RouteTemplatesPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/dashboard"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  shouldLoadPermissions: shouldLoadPermissionsSelector(state.account.login, state.account.permissions),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  userId: currentUserIdSelector(state.account.login) as any,
});

const mapDispatchToProps = {
  loadFeatures,
  loadPermissions,
  loadRouteSequenceStatus,
  loadRouteTemplates,
  loadVehicleTypesForVendor,
  loadFiltersSettings,
  loadGroups,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteTemplatesPageResolver));
