import { change, formValueSelector } from 'redux-form';
import { FC, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import { BULKY_ITEM_SCHEDULER_FORM } from 'src/vendors/constants';
import { BulkySchedulerLimitByDaySettings } from 'src/vendors/interfaces/BulkyItemScheduler';
import { Dropdown, Switch } from 'src/core/components';
import { DropdownOption } from 'src/core/components/Dropdown';
import { FUTURE_DAYS, NUMBER_OF_DAYS, RESTRICT_PICKUP_DAYS } from 'src/vendors/constants/bulkyItemScheduler';
import { Grid, GridColumn, PanelSection, Separator, Text } from '../../../core/components/styled';
import { TypedField } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from '../../../core/services/translate';

const formSelector = formValueSelector(BULKY_ITEM_SCHEDULER_FORM);

type Props = {};

const BulkyItemSchedulerSchedulingAndCancellationWindows: FC<Props> = () => {
  const dispatch = useDispatch();

  const bulkySchedulerLimitByDaySettings =
    useSelector(state => formSelector(state, 'bulkySchedulerLimitByDaySettings')) || [];

  const alignPickupScheduleOptions = Array.from({ length: 6 }, (_, index) => ({
    value: index,
    label: index.toString(),
  }));

  const schedulePickupInFutureOptions = Array.from({ length: 7 }, (_, index) => ({
    value: index,
    label: index.toString(),
  }));

  const numberOfDaysOptions = NUMBER_OF_DAYS.map(day => ({
    label: day.name,
    value: day.value,
  }));

  const futureDayPickupScheduleOptions = FUTURE_DAYS.map(day => ({
    label: day.name,
    value: day.value,
  }));

  const bulkyCancellationTimeFrameTypes = useSelector(
    state => state.common.bulkyCancellationTimeFrameTypes.bulkyCancellationTimeFrameTypes,
  );

  let customerCancellationOptions = [] as DropdownOption[];
  bulkyCancellationTimeFrameTypes?.forEach(timeFrame => {
    customerCancellationOptions.push({ value: timeFrame.id, label: timeFrame.name });
  });

  const restrictPickupChange = (isChecked: boolean, selectedDay: number) => {
    const newBulkySchedulerLimitByDaySettings = bulkySchedulerLimitByDaySettings?.map(
      (setting: BulkySchedulerLimitByDaySettings) => {
        return {
          ...setting,
          days: isChecked ? setting.days?.filter(day => Number(day) !== Number(selectedDay)) : setting.days,
        };
      },
    );

    dispatch(
      change(BULKY_ITEM_SCHEDULER_FORM, 'bulkySchedulerLimitByDaySettings', newBulkySchedulerLimitByDaySettings),
    );
  };

  const onRestrictPickupDayChange = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean, day: number) => {
    restrictPickupChange(isChecked, day);
  };

  return (
    <>
      <GridColumn size="12/12" padding="no xSmall small xSmall">
        <Text block size="large" weight="medium" margin="no no small no">
          {translate('vendors.bulkyItemScheduler.schedulingCancellationWindows')}
        </Text>

        <Grid multiLine>
          <GridColumn size="12/12" padding="no">
            <Text block size="lMedium" weight="medium" margin="no" color="grayDark">
              {translate('vendors.bulkyItemScheduler.scheduling')}
            </Text>
          </GridColumn>
          <GridColumn size="12/12" padding="small xSmall xSmall no" alignVerticalCenter>
            <PanelSection>
              <TypedField
                name="isEnabledPriorDaysOffset"
                component={Switch}
                props={{
                  label: translate('vendors.bulkyItemScheduler.dayAllowSchedule'),
                  margin: 'no',
                }}
              />
              <TypedField
                name="recurringPriorDaysOffset"
                component={Dropdown}
                props={{
                  options: numberOfDaysOptions,
                  margin: 'no small',
                  width: '10%',
                }}
              />
              <TypedField
                name="recurringServiceDayMinusDaysOffset"
                component={Dropdown}
                props={{
                  options: alignPickupScheduleOptions,
                  margin: 'no small',
                  width: '5%',
                }}
              />
              <Text block padding="no" flex flexAlignCenter>
                {`${translate('vendors.bulkyItemScheduler.dayPrior')}.`}
              </Text>
            </PanelSection>
          </GridColumn>

          <GridColumn size="12/12" padding="xSmall xSmall xSmall no" alignVerticalCenter>
            <PanelSection>
              <TypedField
                name="isEnabledAfterDaysOffset"
                component={Switch}
                props={{
                  label: translate('vendors.bulkyItemScheduler.dayAllowSchedule'),
                  margin: 'no',
                }}
              />
              <TypedField
                name="recurringAfterDaysOffset"
                component={Dropdown}
                props={{
                  options: numberOfDaysOptions,
                  margin: 'no small',
                  width: '10%',
                }}
              />
              <TypedField
                name="recurringServiceDayPlusDaysOffset"
                component={Dropdown}
                props={{
                  options: alignPickupScheduleOptions,
                  margin: 'no small',
                  width: '5%',
                }}
              />
              <Text block padding="no" flex flexAlignCenter>
                {`${translate('vendors.bulkyItemScheduler.dayAfter')}.`}
              </Text>
            </PanelSection>
          </GridColumn>

          <GridColumn size="12/12" padding="xSmall xSmall xSmall no" alignVerticalCenter>
            <PanelSection>
              <TypedField
                name="allowPlusMonthsOffset"
                component={Switch}
                props={{
                  label: translate('vendors.bulkyItemScheduler.upTo'),
                  margin: 'no',
                }}
              />
              <TypedField
                name="maxPickupSchedulePlusMonthsOffset"
                component={Dropdown}
                props={{
                  options: schedulePickupInFutureOptions,
                  margin: 'no small',
                  width: '5%',
                }}
              />
              <Text block padding="no" flex flexAlignCenter>
                {`${translate('vendors.bulkyItemScheduler.monthsInTheFuture')}.`}
              </Text>
            </PanelSection>
          </GridColumn>

          <GridColumn size="12/12" padding="xSmall xSmall xSmall no" alignVerticalCenter>
            <PanelSection>
              <TypedField
                name="allowFutureDayPickupRequest"
                component={Switch}
                props={{
                  label: translate('vendors.bulkyItemScheduler.allowCustomerToSchedule'),
                  margin: 'no',
                }}
              />
              <TypedField
                name="futureDayPickupScheduleDaysOffset"
                component={Dropdown}
                props={{
                  options: futureDayPickupScheduleOptions,
                  margin: 'no small',
                  width: '20%',
                }}
              />
            </PanelSection>
          </GridColumn>

          <GridColumn size="12/12" padding="no">
            <Text block size="lMedium" weight="medium" margin="small no no no" color="grayDark">
              {translate('vendors.bulkyItemScheduler.cancellations')}
            </Text>
          </GridColumn>

          <GridColumn size="12/12" padding="small xSmall xSmall no" alignVerticalCenter>
            <PanelSection>
              <TypedField
                name="allowCancellation"
                component={Switch}
                props={{
                  label: translate('vendors.bulkyItemScheduler.allowCancellations'),
                  margin: 'no',
                }}
              />
              <TypedField
                name="cancellationTimeFrameTypeId"
                component={Dropdown}
                props={{
                  options: customerCancellationOptions,
                  margin: 'no small',
                  width: '10%',
                }}
              />
              <Text block padding="no" flex flexAlignCenter>
                {`${translate('vendors.bulkyItemScheduler.aheadOfDay')}.`}
              </Text>
            </PanelSection>
          </GridColumn>

          <GridColumn size="12/12" padding="no">
            <Text block size="lMedium" weight="medium" margin="small no no no" color="grayDark">
              {translate('vendors.bulkyItemScheduler.dayOfWeekRestrictions')}
            </Text>
          </GridColumn>

          {RESTRICT_PICKUP_DAYS.map(pickupDay => (
            <GridColumn size="3/12" padding="small xSmall xSmall no" alignVerticalCenter key={pickupDay.day}>
              <PanelSection>
                <TypedField
                  name={pickupDay.name}
                  component={Switch}
                  props={{
                    label: pickupDay.label,
                    margin: 'no',
                  }}
                  onChange={(event, isChecked) => onRestrictPickupDayChange(event, isChecked, pickupDay.day)}
                />
              </PanelSection>
            </GridColumn>
          ))}

          <Separator color="grayLight" size={2} margin="sMedium no xSmall no" />
        </Grid>
      </GridColumn>
    </>
  );
};

export default BulkyItemSchedulerSchedulingAndCancellationWindows;
