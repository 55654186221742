import { Modal } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import TransferRouteStopsForm, { TransferRouteStopsFormValues } from '../forms/TransferRouteStopsForm';
import translate from '../../../core/services/translate';

type Props = {
  closeModal: () => void;
  transferRouteSegments?: (formData: TransferRouteStopsFormValues) => void;
  transferRouteStops?: (formData: TransferRouteStopsFormValues) => void;
  isSnowPlowRoute?: boolean;
  isStreetSweeperRoute?: boolean;
};

export default function TransferRouteStopsModal({
  closeModal,
  transferRouteSegments,
  transferRouteStops,
  isSnowPlowRoute,
  isStreetSweeperRoute,
}: Props) {
  const handleOnsubmit = transferRouteStops ? transferRouteStops : transferRouteSegments;
  const title =
    isSnowPlowRoute || isStreetSweeperRoute ? translate('routes.transferSegments') : translate('routes.transferStops');

  const { batchTransfererInProgress } = useSelector(state => state.routes.route);

  return (
    <Modal title={title} overflow="visible" onClose={closeModal} padding="medium" isLoading={batchTransfererInProgress}>
      <TransferRouteStopsForm
        isSnowPlowRoute={isSnowPlowRoute}
        isStreetSweeperRoute={isStreetSweeperRoute}
        onSubmit={handleOnsubmit}
      />
    </Modal>
  );
}
