import React from 'react';

import { ActionButtonTooltip } from '../../../../core/components';
import { date, time } from '../../../../utils/services/formatter';
import { TableActionButton, TableCell, TableRow, Text } from '../../../../core/components/styled';
import { VehicleFuelEfficiencyLogDetail } from '../../../interfaces/VehicleFuelEfficiencyLog';
import TooltipButton from 'src/core/components/TooltipButton';
import translate from 'src/core/services/translate';

type Props = {
  isImperialMeasurementSystem: boolean;
  openVehicleFillUpDetailsModal: (vehicleFillUpDetails: VehicleFuelEfficiencyLogDetail) => void;
};

export default function VehicleFuelLogSectionTableRow({
  isImperialMeasurementSystem,
  openVehicleFillUpDetailsModal,
  ...vehicleFillUpDetails
}: Props & VehicleFuelEfficiencyLogDetail) {
  return (
    <TableRow>
      <TableCell width="12%" vertical>
        <Text weight="medium" margin="no no xxSmall" singleLine>
          {date(vehicleFillUpDetails.fillUpTimeStamp)}
        </Text>
        <Text weight="light" margin="no no xxSmall" singleLine>
          {time(vehicleFillUpDetails.fillUpTimeStamp)}
        </Text>
      </TableCell>

      <TableCell width="40%">{vehicleFillUpDetails.fillUpLocation}</TableCell>

      <TableCell width="8%">
        <TooltipButton
          text
          tooltip={translate(`vehicles.${isImperialMeasurementSystem ? 'mpgTooltip' : 'kplTooltip'}`, {
            miles: vehicleFillUpDetails.distanceTravelled.toFixed(0),
            gallons: vehicleFillUpDetails.gallonsUsed.toFixed(1),
          })}
          tooltipAsString
        >
          {vehicleFillUpDetails.milesPerGallon.toFixed(1)}
        </TooltipButton>
      </TableCell>

      <TableCell width="10%">{vehicleFillUpDetails.gallonsUsed.toFixed(1)}</TableCell>

      <TableCell width="11%">{vehicleFillUpDetails.distanceTravelled.toFixed(0)}</TableCell>

      <TableCell width="11%">{vehicleFillUpDetails.odometer}</TableCell>

      <TableCell width="8%" align="right">
        <TableActionButton color="grayDarker" onClick={() => openVehicleFillUpDetailsModal(vehicleFillUpDetails)}>
          <ActionButtonTooltip iconSize="medium" icon="map" tooltip="viewFillUpDetails" />
        </TableActionButton>
      </TableCell>
    </TableRow>
  );
}
