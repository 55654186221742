import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { HaulerLocation } from 'src/dashboard/interfaces/haulerLocations';
import { VENDOR_LOCATION_ICON_TYPES_NEW, getIconTypeForHaulerLocation } from 'src/common/constants/vendorLocationIcons';
import { DASHBOARD_HAULER_LOCATIONS_LAYER } from 'src/dashboard/constants/dashboardMapGL';
import { DISPOSAL_FACILITY } from 'src/common/constants';
import { find } from 'lodash-es';

export type HaulerLocationFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
};

export const getHaulerLocationsGeoJSON = (haulerLocations: HaulerLocation[]) =>
  getFeatureCollection<GeoJSON.Point, HaulerLocationFeatureProperties>(
    haulerLocations.map(location =>
      getPointFeature(location.id, [location.longitude, location.latitude], {
        id: location.id,
        clickable: true,
        layer: DASHBOARD_HAULER_LOCATIONS_LAYER,
        icon: getIconTypeForHaulerLocation(location)?.id,
      }),
    ),
  );

const getHaulerLocationIcon = (haulerLocation: HaulerLocation) => {
  if (haulerLocation.customerTypeId === DISPOSAL_FACILITY) {
    return 'disposal';
  } else {
    const icon = find(
      VENDOR_LOCATION_ICON_TYPES_NEW,
      type => type.types.indexOf(haulerLocation.customerSubTypeId) !== -1,
    )?.id;
    if (icon) {
      return icon;
    }
    return 'other';
  }
};

export const getMapboxHaulerLocationsGeoJSON = (haulerLocation: HaulerLocation[]) =>
  getFeatureCollection<GeoJSON.Point, HaulerLocationFeatureProperties>(
    haulerLocation.map(location =>
      getPointFeature(location.id, [location.longitude, location.latitude], {
        id: location.id,
        clickable: true,
        layer: DASHBOARD_HAULER_LOCATIONS_LAYER,
        icon: getHaulerLocationIcon(location),
      }),
    ),
  );
