import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import { COMPLETE, INFERENCE_AUDIT_STATUSES } from '../constants/inferenceAuditStatuses';
import { InferenceAudit } from '../interfaces/interfaces';
import { transformInferenceAudit } from '../services/transformInferenceAudits';
import doLoadInferenceAudits from '../services/inferenceAudits';
import doSaveInferenceAudits from '../services/saveInferenceAudit';
import translate from '../../core/services/translate';

// Actions
const START_LOAD = 'inferenceAudit/inferenceAudits/START_LOAD';
const COMPLETE_LOAD = 'inferenceAudit/inferenceAudits/COMPLETE_LOAD';
const FAIL_LOAD = 'inferenceAudit/inferenceAudits/FAIL_LOAD';
const START_SAVE = 'inferenceAudit/inferenceAudits/START_SAVE';
const COMPLETE_SAVE = 'inferenceAudit/inferenceAudits/COMPLETE_SAVE';
const FAIL_SAVE = 'inferenceAudit/inferenceAudits/FAIL_SAVE';
const RESET = 'inferenceAudit/inferenceAudits/RESET';

// Initial state
interface State {
  isLoading: boolean;
  isSaving: boolean;
  inferenceAudits: InferenceAudit[];
  total?: number;
}

const initialState: State = {
  isLoading: false,
  isSaving: false,
  inferenceAudits: [],
  total: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          inferenceAudits: action.response.inferenceAudits,
          total: action.response.total,
        },
      });

    case FAIL_LOAD: {
      return update(state, {
        $merge: {
          isLoading: false,
          inferenceAudits: undefined,
        },
      });
    }

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE: {
      const { inferenceAudits } = state;
      const { updatedAudit } = action;
      const updatedAuditIndex = inferenceAudits.findIndex(audit => audit.id === updatedAudit.id);
      inferenceAudits.splice(updatedAuditIndex, 1, updatedAudit);
      return update(state, {
        $merge: {
          isSaving: true,
          inferenceAudits: [...inferenceAudits],
        },
      });
    }

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (response: any[]) => ({
  type: COMPLETE_LOAD,
  response,
});

const failLoad = (error: any) => ({
  type: FAIL_LOAD,
  error,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (updatedAudit: InferenceAudit[]) => ({
  type: COMPLETE_SAVE,
  updatedAudit,
});

const failSave = (error: any) => ({
  type: FAIL_SAVE,
  error,
});

export const loadInferenceAudits =
  (vendorId: number, date: Date | string, vehicleId: number, auditStatusType: string, page: number, limit?: number) =>
  (dispatch: ThunkDispatch<State, any, AnyAction>) => {
    dispatch(startLoad());
    const loadInferenceAuditsPromise = doLoadInferenceAudits(vendorId, date, vehicleId, auditStatusType, page, limit);
    loadInferenceAuditsPromise
      .then(inferenceAudits => dispatch(completeLoad(inferenceAudits)))
      .catch(e => dispatch(failLoad(e)));
    return loadInferenceAuditsPromise;
  };

export const saveInferenceAudits =
  (inferenceAudit: InferenceAudit, auditId: number) => (dispatch: ThunkDispatch<State, any, AnyAction>) => {
    const saveInferenceAuditsPromise = doSaveInferenceAudits(inferenceAudit, auditId);
    const updatedAudit = transformInferenceAudit({
      ...inferenceAudit,
      status: COMPLETE,
      statusName: translate(INFERENCE_AUDIT_STATUSES[COMPLETE].name),
    });
    dispatch(startSave());
    saveInferenceAuditsPromise.then(() => dispatch(completeSave(updatedAudit))).catch(e => dispatch(failSave(e)));
    return saveInferenceAuditsPromise;
  };

export const resetInferenceAudits = () => ({
  type: RESET,
});
