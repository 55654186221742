import { useSelector, useDispatch } from 'react-redux';

import { TypedResolver } from '../../../core/components';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { loadContainerNew } from 'src/fleet/ducks';
import CreateEditContainerModal from './CreateEditContainerModal';
import { loadContainerTypes, loadEquipmentConditions, loadEquipmentSizes, loadWasteTypes } from 'src/common/ducks';

interface Props {
  containerId?: number;
  closeModal: () => void;
  onSubmit: (values: any) => void;
}

const CreateEditContainerModalResolver: React.FC<Props> = ({ containerId, closeModal, onSubmit }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = async () => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<any>[] = [
      loadWasteTypes()(dispatch),
      loadEquipmentConditions()(dispatch),
      loadEquipmentSizes()(dispatch),
      loadContainerTypes()(dispatch),
    ];
    if (containerId) {
      dependencies.push(loadContainerNew(containerId)(dispatch));
    }

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      successComponent={CreateEditContainerModal}
      successProps={{ containerId, closeModal, onSubmit }}
      loadingComponent={PageLoadingOverlay}
      onError={closeModal}
      resolve={loadDependencies}
    />
  );
};

export default CreateEditContainerModalResolver;
