import store from '../../store';
import { createNotification as doCreateNotification } from '../ducks';
import humps from 'humps';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import translate from './translate';

const DEFAULT_AUTO_CLOSE_TIME = 6000;

const createNotification = (message: string, color: string, autoClose?: number, seeMoreContent?: () => JSX.Element) =>
  doCreateNotification(message, color, autoClose, seeMoreContent)(store.dispatch);

export const createSuccessNotification = (message: string, autoClose: number = DEFAULT_AUTO_CLOSE_TIME) =>
  createNotification(message, 'success', autoClose);

export const createErrorNotification = (message: string, autoClose: number = DEFAULT_AUTO_CLOSE_TIME) =>
  createNotification(message, 'alert', autoClose);

const getReadingTime = (text: string) => {
  const wps = 200 / 60; // standard words per second
  const words = text.split(' ').length;
  const readingTime = Math.ceil(words / wps) * 1000;
  return readingTime;
};

export const createErrorNotificationIncludingTechnicalMessage = (
  error: any,
  defaultError?: string,
  translateMessagePath?: string,
  autoClose?: number,
  extraArgs?: object,
) => {

  let errorCode, errorMessage;

  if (error && error.response && error.response.data) {
    if (error.response.data.code) errorCode = humps.camelize(error.response.data.code);
    errorMessage = error.response.data.message || '';
  } else {
    const { code, message } = error;
    errorCode = code ? humps.camelize(code) : '';
    errorMessage = message || '';
  }

  const translatedError =
    errorCode && translateMessagePath && translate(createTranslationKey(errorCode, translateMessagePath), extraArgs);

  // if there is a translated error, use it, otherwise use the error message from the server combined with the default error or just the default error
  const errorToShow =
    translatedError || (errorMessage && defaultError) ? `${defaultError} \n\n ${errorMessage}` : defaultError || '';

  const readingTime =
    autoClose || getReadingTime(errorToShow) > DEFAULT_AUTO_CLOSE_TIME
      ? getReadingTime(errorToShow)
      : DEFAULT_AUTO_CLOSE_TIME;

  createErrorNotification(errorToShow, readingTime);
};

export const createWarningNotification = (
  message: string,
  autoClose: number = DEFAULT_AUTO_CLOSE_TIME,
  seeMoreContent?: () => JSX.Element,
) => createNotification(message, 'warning', autoClose, seeMoreContent);
