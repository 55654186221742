import React from 'react';

import {
  Checkbox as CheckboxContainer,
  CheckboxCheck,
  CheckboxInput,
  TableCell,
  TableRow,
} from '../../../../../core/components/styled';
import { Service } from '../../../../interfaces/RouteLocation';

interface Customer {
  name: string;
}

interface Location {
  address: {
    line1: string;
  };
}

interface Props {
  customer: Customer;
  isChecked: boolean;
  location: Location;
  onLocationSelected: (serviceId: number) => void;
  service: Service;
  wasteMaterialType: string;
}

const UnassignedLocationsModalTableRow: React.FC<Props> = ({
  customer,
  location,
  wasteMaterialType,
  service: { id: serviceId, name: serviceName },
  onLocationSelected,
  isChecked,
}) => (
  <TableRow>
    <TableCell width="5%">
      <CheckboxContainer block noLabel size="small" margin="no no xSmall no">
        <CheckboxInput
          type="checkbox"
          name={serviceId.toString()}
          checked={isChecked}
          value={isChecked.toString()}
          onChange={() => onLocationSelected(serviceId)}
        />
        <CheckboxCheck />
      </CheckboxContainer>
    </TableCell>
    <TableCell width="25%">{customer.name}</TableCell>
    <TableCell width="30%">{location.address.line1}</TableCell>
    <TableCell width="25%">{wasteMaterialType}</TableCell>
    <TableCell width="15%">{serviceName}</TableCell>
  </TableRow>
);

export default UnassignedLocationsModalTableRow;
