import React from 'react';

import { DocumentTitle } from '../../../common/components';
import { AccountPage, AccountPanel, AccountPanelLogo, AccountPanelTitle, AccountPanelDescription } from '../styled';
import translate from '../../../core/services/translate';

const PasswordResetEmailSentPage = () => (
  <>
    <DocumentTitle>{translate('account.checkYourEmail')}</DocumentTitle>
    <AccountPage>
      <AccountPanel>
        <AccountPanelLogo />
        <AccountPanelTitle>{translate('account.checkYourEmail')}</AccountPanelTitle>
        <AccountPanelDescription>{translate('account.passwordResetEmailSent')}</AccountPanelDescription>
      </AccountPanel>
    </AccountPage>
  </>
);

export default PasswordResetEmailSentPage;
