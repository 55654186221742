import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import moment from 'moment';
import { RouteComponentProps } from 'react-router';

import { AppState } from 'src/store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DeclineOpenDispatchesForm } from '../forms';
import { DuckFunction } from 'src/contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { Grid, GridColumn, ModalTitle, ModalSubtitle } from '../../../core/components/styled';
import { loadDispatchOpportunitiesCount } from 'src/common/ducks';
import { Modal } from '../../../core/components';
import { MISSED_PICKUP } from 'src/fleet/constants/openDispatachesServices';
import { NON_SCHEDULED, LIMIT_PER_PAGE } from '../../constants';
import { ReasonCodeGroups } from 'src/fleet/interfaces/RubiconDispatches';
import { updateOpenDispatches } from 'src/fleet/ducks';
import translate from '../../../core/services/translate';
import { UseIsMobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';

type RouteParams = {
  token: string;
};

interface Props extends RouteComponentProps<RouteParams> {
  isSaving?: boolean;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  note: string;
  onCancel: (pristine: boolean, isReload?: boolean) => void;
  pickupTypeId: number;
  pristine: boolean;
  reasonCode: string;
  reasonCodes?: ReasonCodeGroups;
  requestedServiceDate: Date | string;
  rubiconPONbr: string;
  serviceDate: Date | string;
  status: string;
  updateOpenDispatches: DuckFunction<typeof updateOpenDispatches>;
  userId: string;
  vendID: string;
  vendorId: number;
}

class DeclineOpenDispatchesModal extends PureComponent<Props> {
  onSubmit = async () => {
    const {
      loadDispatchOpportunitiesCount,
      location: { search },
      match: {
        params: { token },
      },
      note,
      onCancel,
      reasonCode,
      rubiconPONbr,
      serviceDate,
      status,
      updateOpenDispatches,
      userId,
      vendID,
      vendorId,
    } = this.props;

    const { dispatchStatus, startDate, endDate, workOrder, page } = getQueryParams(search);
    const searchFilter = {
      dispatchStatus,
      startDate,
      endDate,
      workOrder,
      workOrderType: NON_SCHEDULED,
      page,
      limit: LIMIT_PER_PAGE,
    };
    const action = 'Decline';
    const weightTicketUom = undefined;
    const promise = updateOpenDispatches(
      rubiconPONbr,
      status,
      note,
      reasonCode,
      moment(serviceDate).format('YYYY-MM-DD HH:mm:ss'),
      action,
      null,
      null,
      userId,
      token,
      vendorId,
      vendID,
      weightTicketUom,
      searchFilter,
    );

    promise &&
      promise
        .then(() => {
          const isReload = true;
          loadDispatchOpportunitiesCount(userId, vendorId, token);
          createSuccessNotification(translate('opportunity.opportunitySave'));
          onCancel(true, isReload);
        })
        .catch(() => {
          createErrorNotification(translate('opportunity.opportunitySaveError'));
        });
  };

  render() {
    const { isSaving, pickupTypeId } = this.props;
    const modalTitle =
      pickupTypeId === MISSED_PICKUP
        ? translate('autoDispatch.missedPickup.declineTitle')
        : translate('opportunity.declineService');
    return (
      <Modal size="small" isLoading={isSaving}>
        <ModalTitle>{modalTitle}</ModalTitle>
        <ModalSubtitle />
        <Grid centered>
          <UseIsMobileWidthView
            render={isMobile => (
              <GridColumn size={!isMobile ? '8/12' : '12/12'}>
                <DeclineOpenDispatchesForm {...this.props} onSubmit={this.onSubmit} />
              </GridColumn>
            )}
          />
        </Grid>
      </Modal>
    );
  }
}

const formSelector = formValueSelector('declineOpenDispatches');

const mapStateToProps = (state: AppState, ownProps: Props) => ({
  note: formSelector(state, `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.note`),
  reasonCode: formSelector(state, `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.reasonCode`),
  isSaving: state.fleet.openDispatches.isSaving,
});

export default connect(mapStateToProps)(DeclineOpenDispatchesModal);
