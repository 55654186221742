import { FC, memo } from 'react';
import { Layer, Source } from 'react-map-gl';

type Props = {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.LineString, any>;
};

export const TRAVEL_PATH_ROUTE_SEGMENTS_GL_SOURCE = 'TRAVEL_PATH_ROUTE_SEGMENTS_GL_SOURCE';
export const TRAVEL_PATH_ROUTE_SEGMENTS_GL_LAYER = 'TRAVEL_PATH_ROUTE_SEGMENTS_GL_LAYER';
export const TRAVEL_PATH_ROUTE_TWO_WAY_SEGMENTS_GL_LAYER = 'TRAVEL_PATH_ROUTE_TWO_WAY_SEGMENTS_GL_LAYER';

const TravelPathRouteSegmentsGLSource: FC<Props> = ({ geoJSON }) => {
  return (
    <Source type="geojson" id={TRAVEL_PATH_ROUTE_SEGMENTS_GL_SOURCE} data={geoJSON} tolerance={0.0001}>
      <Layer
        id={TRAVEL_PATH_ROUTE_SEGMENTS_GL_LAYER}
        type="line"
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        source={TRAVEL_PATH_ROUTE_SEGMENTS_GL_SOURCE}
        paint={{
          'line-color': '#0066a6',
          'line-opacity': 0.5,
          'line-width': 6,
          'line-offset': ['get', 'lineOffset'],
        }}
        filter={['all', ['!=', ['get', 'hasForwardPass'], false]]}
      />
      <Layer
        id={TRAVEL_PATH_ROUTE_TWO_WAY_SEGMENTS_GL_LAYER}
        type="line"
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        source={TRAVEL_PATH_ROUTE_SEGMENTS_GL_SOURCE}
        filter={['all', ['!=', ['get', 'hasReversePass'], false]]}
        paint={{
          'line-color': '#0066a6',
          'line-width': 6,
          'line-opacity': 0.5,
          'line-offset': ['get', 'lineOffsetNegative'],
        }}
      />
    </Source>
  );
};

export default memo(TravelPathRouteSegmentsGLSource);
