import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DeclineServiceChangeRequestForm } from '../forms';
import { DuckFunction } from '../../../contracts/ducks';
import { Grid, GridColumn } from '../../../core/components/styled';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { loadServiceChangeRequests } from '../../ducks';
import { Modal } from '../../../core/components';
import translate from '../../../core/services/translate';
import { updateServiceChangeRequest } from '../../ducks';
import { YES } from '../../../fleet/constants/status';
import { useIsMobileView } from 'src/utils/hooks';
import { closeMobilePageDetailOverlay } from 'src/core/ducks/mobilePage';
interface RouteParams {
  token: string;
}

interface ComponentProps {
  caseId: number;
  itemId?: number;
  location: any;
  status: any;
  userId: string;
  vendorId: number;
  onCancel(pristine: boolean): void;
}

interface ReduxProps {
  isSaving: boolean;
  response: any;
  respondedNo?: any;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  loadServiceChangeRequests: DuckFunction<typeof loadServiceChangeRequests>;
  updateServiceChangeRequest: DuckFunction<typeof updateServiceChangeRequest>;
  closeMobilePageDetailOverlay: DuckFunction<typeof closeMobilePageDetailOverlay>;
}

type Props = ComponentProps & ReduxProps & RouteComponentProps<RouteParams>;

const DeclineServiceChangeRequestModal: React.FC<Props> = props => {
  const { isSaving, onCancel } = props;

  const isMobile = useIsMobileView();

  const onSubmit = React.useCallback(async () => {
    const {
      itemId,
      loadDispatchOpportunitiesCount,
      loadServiceChangeRequests,
      location: { search },
      match: {
        params: { token },
      },
      onCancel,
      respondedNo,
      status,
      userId,
      vendorId,
      updateServiceChangeRequest,
      closeMobilePageDetailOverlay,
    } = props;

    const { showActionRequired, startDate, endDate } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES ? true : false;
    const requestOpportunityObj: any = {};
    requestOpportunityObj.itemId = itemId;
    requestOpportunityObj.Response = status === 'AwaitingHaulerApproval' ? 'RejectsChanges' : 'No';

    if (status === 'AwaitingHaulerApproval') {
      requestOpportunityObj.requestedChanges = respondedNo;
    } else {
      requestOpportunityObj.RespondedNo = respondedNo;
    }

    const promise = updateServiceChangeRequest(requestOpportunityObj, userId, token, vendorId);

    if (!promise) {
      return;
    }

    await promise
      .then(() => {
        loadDispatchOpportunitiesCount(userId, vendorId, token);
        loadServiceChangeRequests(userId, token, vendorId, startDate, endDate, isActionRequired);
        createSuccessNotification(translate('opportunity.opportunitySave'));
        onCancel(true);
        closeMobilePageDetailOverlay();
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  }, [props]);

  return (
    <Modal title={translate('opportunity.declineService')} size="small" isLoading={isSaving}>
      <Grid centered>
        <GridColumn size={!isMobile ? '8/12' : '12/12'}>
          <DeclineServiceChangeRequestForm {...props} onSubmit={onSubmit} onCancel={onCancel} />
        </GridColumn>
      </Grid>
    </Modal>
  );
};

const formSelector = formValueSelector('declineServiceChangeRequest');

const mapStateToProps = (state: AppState, ownProps: any) => ({
  response: formSelector(state, `changeRequests.settings${ownProps.caseId}.response`),
  respondedNo: formSelector(state, `changeRequests.settings${ownProps.caseId}.respondedNo`),
  isSaving: state.opportunity.serviceChangeRequest.isSaving,
});

const mapDispatchToProps = {
  loadDispatchOpportunitiesCount,
  loadServiceChangeRequests,
  updateServiceChangeRequest,
  closeMobilePageDetailOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeclineServiceChangeRequestModal);
