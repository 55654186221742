import { differenceBy } from 'lodash-es';
import { isPristine } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../services/currentVendorSelector';
import { DELIVERY_UTILITY_ID } from 'src/fleet/constants';
import { isBulkyItemSchedulerFeatureEnabled } from 'src/vendors/ducks/features';
import { MATERIAL_TYPES_FORM } from '../../constants/materialTypes';
import { MaterialTypes } from 'src/vendors/interfaces/MaterialTypes';
import { Message, ModalSection } from '../../../core/components/styled';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { saveMaterialTypes } from 'src/vendors/ducks';
import confirm from 'src/core/services/confirm';
import MaterialTypesForm from '../forms/MaterialTypesForm';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {}

const MaterialTypesModal: React.FC<Props> = ({ closeModal }) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const { materialTypes, isSaving, isLoading } = useSelector((state: AppState) => state.vendors.materialTypes);
  const formPristine = useSelector((state: AppState) => isPristine(MATERIAL_TYPES_FORM)(state));
  const isBulkyItemSchedulerEnabled = useSelector(isBulkyItemSchedulerFeatureEnabled);

  const onClose = (pristine = formPristine) => {
    closeModal(pristine);
  };

  const onSubmit = async (formData: any) => {
    if (!vendorId) {
      return;
    }

    let isInformMessageVisible = false;

    if (!formPristine) {
      const differences = differenceBy(formData, materialTypes, 'bulkyItemTypes') as unknown as MaterialTypes[];
      differences.forEach(difference => {
        difference.bulkyItemTypes.forEach(bulkyItemType => {
          const materialTypBulkyItemTypes = materialTypes.find(
            materialType => materialType.category.id === difference.category.id,
          )?.bulkyItemTypes;

          const initialBulkyItemTypeIsActive = materialTypBulkyItemTypes?.find(
            materialTypBulkyItemType => materialTypBulkyItemType.bulkyItem.id === bulkyItemType.bulkyItem.id,
          )?.isActive;
          const initialBulkyItemTypeIncludesDeliveryUtility = (
            materialTypBulkyItemTypes?.find(
              materialTypBulkyItemType => materialTypBulkyItemType.bulkyItem.id === bulkyItemType.bulkyItem.id,
            )?.vehicleTypeId as any[]
          ).includes(DELIVERY_UTILITY_ID);

          const bulkyItemTypeIncludesDeliveryUtility =
            (bulkyItemType.vehicleTypeId as any[]).includes(DELIVERY_UTILITY_ID) ||
            (bulkyItemType.vehicleTypeId as any[]).includes(DELIVERY_UTILITY_ID.toString());
          const bulkyItemTypeIsActive = bulkyItemType.isActive;

          if (
            bulkyItemTypeIsActive &&
            bulkyItemTypeIncludesDeliveryUtility &&
            (!initialBulkyItemTypeIsActive || !initialBulkyItemTypeIncludesDeliveryUtility)
          )
            isInformMessageVisible = true;
        });
      });
    }

    if (
      isInformMessageVisible &&
      isBulkyItemSchedulerEnabled &&
      !(await confirm(
        translate('vendors.alertMessages.informMessageToEnterPrices'),
        '',
        '',
        translate('common.continueSaving'),
        true,
        'center',
      ))
    ) {
      return;
    }

    saveMaterialTypes(
      vendorId,
      formData,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.materialTypesSaved'));
        onClose(true);
      })
      .catch(() => {
        createErrorNotification(translate('vendors.alertMessages.materialTypesSavedError'));
      });
  };

  return (
    <Modal
      verticalSize="small"
      size="medium"
      overflow="hidden"
      padding="medium no no"
      flexDirection="column"
      title={translate('vendors.featureCodes.materialTypesConfiguration')}
      onClose={() => onClose()}
    >
      <ModalSection flexGrow isLoading={isLoading || isSaving}>
        {!!materialTypes.length ? (
          <MaterialTypesForm initialValues={materialTypes} onSubmit={onSubmit} onClose={onClose} />
        ) : (
          <Message padding="sMedium">{translate('vendors.noConfigurations')}</Message>
        )}
      </ModalSection>
    </Modal>
  );
};

export default MaterialTypesModal;
