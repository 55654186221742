import { identity, map } from 'lodash-es';
import { createSelector } from 'reselect';

import { getQueryParams } from '../../utils/services/queryParams';
import { TODAY_FORMATTED, ONE_MONTH_AGO } from '../../core/constants';

const getServiceHistoryFormInitialValues = (queryString: string) => {
  const { locationId, serviceContractIds, pickupStatusTypeIds, startDate, endDate, jobPriorityTypeIds } =
    getQueryParams(queryString);
  return {
    locationId: locationId && Number(locationId),
    serviceContractIds: serviceContractIds && map(serviceContractIds.toString().split(','), Number),
    jobPriorityTypeIds: jobPriorityTypeIds && map(jobPriorityTypeIds.toString().split(','), Number),
    pickupStatusTypeIds: pickupStatusTypeIds && map(pickupStatusTypeIds.toString().split(','), Number),
    date: {
      from: startDate || ONE_MONTH_AGO,
      to: endDate || TODAY_FORMATTED,
    },
  };
};

const serviceHistoryFormInitialValuesSelector = createSelector(getServiceHistoryFormInitialValues, identity);
export default serviceHistoryFormInitialValuesSelector;
