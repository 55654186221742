import React from 'react';

import { isPristine, hasSubmitSucceeded } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../services/currentVendorSelector';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { ModalSection } from '../../../core/components/styled';
import { ShadowedButtonSet } from '../../../core/components/styled/ButtonSet';
import { VEHICLE_NOTIFICATIONS_FORM } from '../../constants/vehicleNotifications';
import { VehicleNotificationsFormValues } from '../../interfaces/VehicleNotifications';
import * as Ducks from '../../ducks/vehicleNotifications';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import TooltipButton from '../../../core/components/TooltipButton';
import translate from '../../../core/services/translate';
import VehicleNotificationsForm from '../forms/VehicleNotificationsForm';

const VehicleNotificationsModal: React.FC<ModalProps> = ({ featureCode, closeModal }) => {
  const dispatch = useDispatch();

  const [typingEmail, setTypingEmail] = React.useState<boolean>(false);

  const vendorId = useSelector(currentVendorId);
  const configurations = useSelector((state: AppState) => state.vendors.vehicleNotifications.configurations);
  const configurationsLoading = useSelector(
    (state: AppState) => state.vendors.vehicleNotifications.configurationsLoading,
  );
  const configurationsSaving = useSelector(
    (state: AppState) => state.vendors.vehicleNotifications.configurationsSaving,
  );
  const rawTypes = useSelector((state: AppState) => state.vendors.vehicleNotifications.types);
  const types = rawTypes.map(type => ({
    label: translate(`vendors.vehicleNotificationTypes.${type.technicalName}`),
    value: type.id,
  }));

  const pristine = useSelector((state: AppState) =>
    isPristine(VEHICLE_NOTIFICATIONS_FORM)(
      state,
      ...types.map((_, index) => `configurations[${index}].emailAddressesCSV`),
    ),
  );
  const submitSucceeded = useSelector((state: AppState) => hasSubmitSucceeded(VEHICLE_NOTIFICATIONS_FORM)(state));

  const onClose = () => {
    closeModal(pristine || (!pristine && !submitSucceeded));
  };

  const onTypingEmailChange = (typing: boolean) => {
    setTypingEmail(typing);
  };

  const onSubmit = (values: VehicleNotificationsFormValues) => {
    if (!vendorId) {
      return;
    }

    Ducks.saveVehicleNotificationsConfigurations(
      vendorId,
      values.configurations,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.vehicleNotificationsSaveCompleted'));
        onClose();
      })
      .catch(() => {
        createErrorNotification(translate('vendors.vehicleNotificationsSaveFailed'));
      });
  };

  return (
    <Modal
      hasBorderRadius
      size="smallMedium"
      padding="medium no no"
      title={translate(createTranslationKey(featureCode || '', 'vendors.featureCodes'))}
      onClose={onClose}
    >
      <ModalSection
        flexGrow
        isLoading={configurationsLoading || configurationsSaving}
        padding="no medium medium"
        height="250px"
      >
        <VehicleNotificationsForm
          types={types}
          initialValues={{
            currentNotificationTypeId: types.length ? types[0].value : undefined,
            configurations: rawTypes.map(type => {
              const correspondingConfiguration =
                configurations.find(configuration => configuration.vehicleNotificationType.id === type.id) || {};

              return {
                vehicleNotificationType: type,
                emailAddressesCSV: [],
                isActive: false,
                ...correspondingConfiguration,
              };
            }),
          }}
          onTypingEmailChange={onTypingEmailChange}
          onSubmit={onSubmit}
        />
      </ModalSection>

      <ModalSection flexGrow>
        <ShadowedButtonSet>
          <TooltipButton
            color="primary"
            type="submit"
            form={VEHICLE_NOTIFICATIONS_FORM}
            disabled={typingEmail}
            tooltipAsString
            tooltip={typingEmail ? translate('vendors.pressEnterBeforeSubmittingEmails') : undefined}
          >
            {translate('common.save')}
          </TooltipButton>
        </ShadowedButtonSet>
      </ModalSection>
    </Modal>
  );
};

export default VehicleNotificationsModal;
