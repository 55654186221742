import { http } from '../../core/services/http';
import { transformChangeRequest } from './transformServiceChangeRequests';
import { TEN_DAYS_BEFORE_TODAY, TEN_DAYS_AFTER_TODAY } from '../../core/constants';

export const loadChangeRequestServiceOptions = () =>
  http.get(`/gus/servicechange/options`).then(response => response.data);

export const loadServiceChangeRequests = (
  token: string,
  startDate: string = TEN_DAYS_BEFORE_TODAY,
  endDate: string = TEN_DAYS_AFTER_TODAY,
  isActionRequired: boolean,
) =>
  http
    .get(`bpm/sourcing/hauler/changeservices/${token}`, { params: { startDate, endDate, isActionRequired } })
    .then(response => transformChangeRequest(response.data));

export const loadServiceChangeRequestsByUserId = (
  userId: string,
  vendorId: number,
  startDate: string = TEN_DAYS_BEFORE_TODAY,
  endDate: string = TEN_DAYS_AFTER_TODAY,
  isActionRequired: boolean,
) =>
  http
    .get(`bpm/sourcing/hauler/changeservices/vendor/${vendorId}/user/${userId}`, { params: { startDate, endDate, isActionRequired } })
    .then(response => transformChangeRequest(response.data));

export const updateServiceChangeRequest = (requestOpportunityObj: any, token: string) =>
  http.put(`/bpm/sourcing/hauler/changeservice/response/${token}`, [requestOpportunityObj]);
    
export const updateServiceChangeRequestByUserId = (requestOpportunityObj: any, userId: string, vendorId: number) =>
  http.put(`bpm/sourcing/hauler/changeservice/response/vendor/${vendorId}/user/${userId}`, [requestOpportunityObj]);

    