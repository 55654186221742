import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import ChangeRequestNotesModal from './ChangeRequestNotesModal';
import { currentUserId, currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { getNotes } from '../../../fleet/ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';

interface RouteParams {
  token?: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  closeModal: () => void;
  equipmentSizes?: any;
  requestedChanges?: any;
  serviceDate: string;
  workOrder: string;
}

const ChangeRequestNotesModalResolver: React.FC<Props> = ({
  closeModal,
  equipmentSizes,
  match: {
    params: { token },
  },
  requestedChanges,
  serviceDate,
  workOrder,
}) => {
  const dispatch = useDispatch();

  const userId = useSelector((state: AppState) => currentUserId(state));
  const vendorId = useSelector((state: AppState) => currentVendorId(state));

  const loadDependencies = () => {
    return Promise.all([getNotes({ token, userId, vendorId, workOrder, serviceDate })(dispatch)]);
  };
  
  return (
    <TypedResolver
      successComponent={ChangeRequestNotesModal}
      successProps={{ closeModal, equipmentSizes, requestedChanges }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default withRouter(ChangeRequestNotesModalResolver);
