import { useEffect, useMemo } from "react";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import { useEsriMap } from "./EsriMapContext";
import { DASHBOARD_MAPBOX_VEHICLE_DETAILS_FORM, DashboardMapboxVehicleDetailsFormValues } from "src/dashboard/components/forms/DashboardMapboxVehicleDetailsForm";
import { useSelector } from "src/core/hooks/useSelector";
import { getFormValues } from "redux-form";
import { BreadCrumb, VehicleBreadcrumbs } from "src/dashboard/interfaces/vehiclePositions";
import { assignRandomIds, getMapboxVehicleTrackingsForRouteGeoJSON, getMapboxVehicleTrackingsForVehicleGeoJSON, getMapboxVehicleTrackingsIndividualPointsForRouteGeoJSON, getMapboxVehicleTrackingsIndividualPointsForVehicleGeoJSON } from "../../dashboardPageSections/mapGL/dashboardVehicleTrackings/utils";
import { size } from "lodash-es";
interface GeoJSONProperties {
    [key: string]: any; // Define specific types as per your GeoJSON properties
}

interface GeoJSONFeature {
    type: string;
    properties: GeoJSONProperties;
    geometry: {
        type: string;
        coordinates: number[]; // Adjust based on your specific GeoJSON structure
    };
    id?: string; // Optional ID property if needed
}

export interface GeoJSON {
    type: string;
    features: GeoJSONFeature[];
}

const lineRenderer = new SimpleRenderer({
    symbol: new SimpleLineSymbol({
        width: 1,
        color: "#0000FF"
    })
});

const pointRenderer = new SimpleRenderer({
    symbol: new SimpleMarkerSymbol({
        size: 5,
        color: "#ffffff",
        outline: {
            width: 2,
            color: "#0000FF"
        }
    })
});

const symbolArrow = {
    type: "simple-marker",
    path: "m 50,0 l 50,50 -100,0 z",
    color: "#ffffff",
    outline: {
        color: [0, 0, 0, 0.7],
        width: 0
    },
    angle: 90,
    size: 8,
    yoffset: 0
};

const rotationRendererYellow: any = {
    type: "simple",
    symbol: symbolArrow,
    visualVariables: [
        {
            type: "rotation",
            field: "bearing",
            rotationType: "geographic",

        },

    ]
};


const Popup = {
    title: "",
    content: [
        {
            type: "fields",
            fieldInfos: [
                {
                    fieldName: "name",
                    label: "Name",
                },
                {
                    fieldName: "description",
                    label: "Description",
                },
                {
                    fieldName: "icon",
                    label: "icon",
                },
                {
                    fieldName: "category",
                    label: "category",
                },
                {
                    fieldName: "layer",
                    label: "layer",
                },
                {
                    fieldName: "vehicleId",
                    label: "vehicleId",
                },
                {
                    fieldName: "source",
                    label: "source",
                },
            ],
        },
    ],
};

export const EsriBreadcrumbs = ({ selectedVehicleId, showBearing }: any) => {
    const { map } = useEsriMap();
    const vehicleDetailsFormValues = useSelector(
        getFormValues(DASHBOARD_MAPBOX_VEHICLE_DETAILS_FORM),
    ) as DashboardMapboxVehicleDetailsFormValues;
    const vehiclePositionsForRoute: any = useSelector(state => state.dashboard.routesData.routeDriversVehicles);
    // vehiclesDetails screen
    const vehicleTrackings = useSelector(state => state.dashboard.vehiclesData.vehicleBreadcrumbs as VehicleBreadcrumbs);
    const vehiclePositions = useSelector(state => state.dashboard.vehiclesData.vehiclesList);
    const vehicleTrackingsForRoute = useSelector(state => state.dashboard.routesData.routeVehiclesBreadCrumbs);
    const routeVehicles = useSelector(state => state.dashboard.routesData.routeDriversVehicles);

    const geoJSONForVehicle = useMemo(() => {
        const trackings = vehicleDetailsFormValues?.displayBreadCrumbs
            ? vehicleTrackings
            : ({ coords: [[]] as BreadCrumb[][] } as VehicleBreadcrumbs);
        return {
            lineFeature: getMapboxVehicleTrackingsForVehicleGeoJSON(trackings),
            pointFeature: getMapboxVehicleTrackingsIndividualPointsForVehicleGeoJSON(trackings),
        };
    }, [vehicleDetailsFormValues?.displayBreadCrumbs, vehicleTrackings]);

    const geoJSONForRoute = useMemo(() => {
        return {
            lineFeature: getMapboxVehicleTrackingsForRouteGeoJSON(vehicleTrackingsForRoute?.vehicles || [], routeVehicles),
            pointFeature: getMapboxVehicleTrackingsIndividualPointsForRouteGeoJSON(
                vehicleTrackingsForRoute?.vehicles || [],
                routeVehicles,
            ),
        };
    }, [routeVehicles, vehicleTrackingsForRoute?.vehicles]);


    const showVehicleTracking =
        (!!size(geoJSONForVehicle.lineFeature.features) && size(vehiclePositions) > 0) ||
        (!!size(geoJSONForRoute.lineFeature.features) && size(vehiclePositionsForRoute) > 0);

    const lineFeature = selectedVehicleId ? geoJSONForVehicle.lineFeature : geoJSONForRoute.lineFeature
    const pointFeature: any = selectedVehicleId ? geoJSONForVehicle.pointFeature : geoJSONForRoute.pointFeature

    // Create blob URLs for the GeoJSON data
    const lineBlob = new Blob([JSON.stringify(lineFeature)], { type: "application/json" });
    const pointBlob = new Blob([JSON.stringify(assignRandomIds(pointFeature))], { type: "application/json" });

    const lineUrl = URL.createObjectURL(lineBlob);
    const pointUrl = URL.createObjectURL(pointBlob);

    useEffect(() => {
        if (!map) return;
        if (!showVehicleTracking) return
        const lineLayer = new GeoJSONLayer({
            url: lineUrl,
            renderer: lineRenderer,
            copyright: "Rubicon",
            listMode: "hide",
         

        });

        const pointLayerBearing = new GeoJSONLayer({
            url: pointUrl,
            renderer: rotationRendererYellow,
            listMode: "hide",
      
            minScale: 4513, // Show this layer only when zoomed in beyond this scale
            // maxScale: 40,     // The default maximum scale (show at all zoom levels up to minScale)
    
        });

        const pointLayer = new GeoJSONLayer({
            url: pointUrl,
            renderer: pointRenderer,
            minScale: 4513,
            listMode: "hide",
            popupEnabled: true,
            popupTemplate: Popup,
            // copyright: "Rubicon",
            // outFields: ["source", "vehicleId", "groupIndex"],
            // popupTemplate: popupTrailheads,
        });

        map.add(lineLayer);
        map.add(pointLayer);
        if (showBearing) map.add(pointLayerBearing)

        return () => {
            map.remove(lineLayer);
            map.remove(pointLayer);
            if (showBearing) map.remove(pointLayerBearing)

        };
    }, [map, lineUrl, pointUrl, lineFeature, pointFeature, showBearing, showVehicleTracking]);

    return null;
};