import Cookie from 'js-cookie';

const COOKIE_KEY = 'routeSequenceInstructions';
const COOKIE_EXPIRATION = {
  completed: 3600 * 24 * 365 * 5,
};

export const hideRouteSequenceInstructions = () =>
  Cookie.set(COOKIE_KEY, 1 as any, { expires: COOKIE_EXPIRATION.completed });
export const shouldHideRouteSequenceInstructions = () => Cookie.get(COOKIE_KEY);
