import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { GeoJSONSource } from 'mapbox-gl';

import { getFeatureCollection } from 'src/common/components/map/util';
import { setRouteMapViewport } from 'src/routes/ducks';
import { ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER, ROUTE_MAP_CLUSTERS_SOURCE } from './layerIds';
import RouteMapClustersGLSource from './RouteMapClustersGLSource';
import { getRouteStopsGeoJSON } from './routeStops/utils';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';

type Props = {
  map: mapboxgl.Map;
  routeStops: RouteLocation[];
};

export default function RouteMapClusters({ map, routeStops }: Props) {
  const dispatch = useDispatch();

  const geoJson = useMemo(() => {
    let collection = getFeatureCollection<GeoJSON.Point, any>([]);

    if (routeStops.length) {
      const routeStopsCollection = getRouteStopsGeoJSON(routeStops);
      collection.features = collection.features.concat(routeStopsCollection.features);
    }
    return collection;
  }, [routeStops]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER, event => {
        const [feature] = map
          .queryRenderedFeatures(event.point, {
            layers: [ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER],
          })
          .filter(feature => feature.source === ROUTE_MAP_CLUSTERS_SOURCE);

        const clusterId = feature.properties?.cluster_id;
        const source = map.getSource(ROUTE_MAP_CLUSTERS_SOURCE) as GeoJSONSource;

        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          dispatch(
            setRouteMapViewport({
              latitude: (feature.geometry as any).coordinates[1],
              longitude: (feature.geometry as any).coordinates[0],
              zoom,
            }),
          );
        });
      });
    });
  }, [map, dispatch]);

  return <RouteMapClustersGLSource geoJSON={geoJson} />;
}
