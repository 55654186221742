import { connect } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';
import humps from 'humps';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash-es';

import { UnconnectedDropdown, UnconnectedInput, ActionButtonTooltip } from '../../core/components';
import { AppState } from '../../store';
import translate from '../../core/services/translate';
import { TableActionButton, BareButtonStyle, FormError, FormLabel } from '../../core/components/styled';
import { AccountStatusToggleIcon } from '../../customers/components/styled';

interface VehicleCameraConfig {
  cameraSerialNumber: string;
  displayName: string;
  id: number;
  name: string;
  vehicleCameraConfigurationTypeId: number;
  password: string;
}

interface VehicleCameraConfigProps {
  cameraConfig: VehicleCameraConfig;
  index: number;
  vehicleCameraConfigurationOptions: any[];
  onChange: (vehicleCameraConfig: VehicleCameraConfig, index: number) => void;
  onDelete: (index: number) => void;
}

const AddNewConfig = styled.button.attrs(() => ({ type: 'button' }))`
  ${BareButtonStyle};
  display: flex;

  svg {
    color: white;
  }
  span {
    font-size: 12px;
    color: ${p => p.theme.brandPrimary};
  }
`;

const CameraConfigs = styled.div`
  margin-bottom: 15px;
`;

const FieldsContainer = styled.div`
  padding: 10px 0 10px 10px;
  border-left: 2px solid ${p => p.theme.brandSecondary};
  border-bottom: 2px solid ${p => p.theme.brandSecondary};
`;

const VehicleConfigContainer = styled.div`
  display: flex;
  flex-grow: 1;

  & > div {
    flex: 1;
    margin-right: 10px;
  }
`;

const FieldLabel = styled(FormLabel)`
  margin-bottom: 5px;
  font-size: 12px;
`;

const getTranslation = (key: string) => translate(`vehicles.cameraConfigurations.${key}`);

const VehicleCameraConfiguration: React.SFC<VehicleCameraConfigProps> = ({
  cameraConfig,
  index,
  onChange,
  onDelete,
  vehicleCameraConfigurationOptions,
}) => {
  const changeValue = (value: any, key: string) => {
    const currentConfig: any = { ...cameraConfig };
    currentConfig[key] = value;
    onChange(currentConfig, index);
  };

  return (
    <VehicleConfigContainer>
      <UnconnectedDropdown
        label={getTranslation('view')}
        key="vehicleCameraConfigurationTypeId"
        value={cameraConfig ? cameraConfig.vehicleCameraConfigurationTypeId : 1}
        disabled={false}
        options={vehicleCameraConfigurationOptions}
        onChange={(value: number) => changeValue(value, 'vehicleCameraConfigurationTypeId')}
      />
      <UnconnectedInput
        label={getTranslation('name')}
        key="name"
        onChange={(value: number) => changeValue(value, 'name')}
        value={cameraConfig.name || ''}
      />
      <UnconnectedInput
        label={getTranslation('displayName')}
        key="displayName"
        onChange={(value: number) => changeValue(value, 'displayName')}
        value={cameraConfig.displayName || ''}
      />
      <UnconnectedInput
        label={getTranslation('serialNumber')}
        key="cameraSerialNumber"
        onChange={(value: number) => changeValue(value, 'cameraSerialNumber')}
        value={cameraConfig.cameraSerialNumber || ''}
      />
      <UnconnectedInput
        label={translate(`common.password`)}
        key="password"
        onChange={(value: number) => changeValue(value, 'password')}
        value={cameraConfig.password}
      />
      <TableActionButton onClick={() => onDelete(index)}>
        <ActionButtonTooltip icon="trash" tooltip="delete" />
      </TableActionButton>
    </VehicleConfigContainer>
  );
};

interface Props extends WrappedFieldProps {
  cameraConfigurations: any[];
}

class VehicleCameraConfigurations extends PureComponent<Props> {
  get value() {
    return cloneDeep(this.props.input.value);
  }

  onChange = (event: any, value: any) => {
    this.props.input.onChange(cloneDeep(value));
  };

  onCameraConfigUpdate = (cameraConfig: VehicleCameraConfig, index: number) => {
    const value = this.value;
    const config = cameraConfig;
    value.splice(index, 1, config);
    this.onChange(null, value);
  };

  addNewConfig = () => {
    const value = this.value || [];
    value.push({ vehicleCameraConfigurationTypeId: 1 });
    this.onChange(null, value);
  };

  deleteConfig = (index: number) => {
    const value = this.value;
    value.splice(index, 1);
    this.onChange(null, value);
  };

  render() {
    const {
      cameraConfigurations,
      input: { value },
      meta: { error, submitFailed },
    } = this.props;

    const vehicleCameraConfigurationOptions = cameraConfigurations.map(cc => ({
      value: cc.id,
      label: getTranslation(humps.camelize(cc.technicalName)),
    }));

    return (
      <CameraConfigs>
        {<FieldLabel noTransform>{getTranslation('fieldTitle')}</FieldLabel>}
        <FieldsContainer>
          {value &&
            (value as VehicleCameraConfig[]).map((cameraConfig, i) => (
              <VehicleCameraConfiguration
                cameraConfig={cameraConfig}
                index={i}
                key={i}
                onChange={this.onCameraConfigUpdate}
                vehicleCameraConfigurationOptions={vehicleCameraConfigurationOptions}
                onDelete={this.deleteConfig}
              />
            ))}
          <AddNewConfig onClick={this.addNewConfig}>
            <AccountStatusToggleIcon />
            <span>{getTranslation('addNewCamera')}</span>
          </AddNewConfig>
          {submitFailed && error && <FormError>{error}</FormError>}
        </FieldsContainer>
      </CameraConfigs>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  cameraConfigurations: state.fleet.vehicles.vehicleCameraConfigurations,
});

export default connect(mapStateToProps)(VehicleCameraConfigurations);
