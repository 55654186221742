import styled, { css } from 'styled-components';

import { Devices, theme } from '../../../core/styles';

import ArrowForward from '../../assets/arrowForward.svg';
import NoBlogPhotoAvatar from '../../assets/NoBlogPhotoAvatar.png';

interface BlogPostContainerProps {
  hasRightMargin?: boolean;
}

interface BlogPostImageContainerProps {
  src?: string;
}

export const BlogPostContainer = styled.div<BlogPostContainerProps>`
  display: inline-block;
  width: 100%;
  padding: 25px 0px;
  vertical-align: top;
  border-bottom: 1px solid #e7ebef;
  min-height: 215px;
  ${props =>
    props.hasRightMargin &&
    css`
      margin-right: 6%;
    `};
`;

export const BlogPostImageContainer = styled.div<BlogPostImageContainerProps>`
  display: inline-block;
  width: 33%;
  min-height: 150px;
  background-image: url(${props => props.src}), url(${NoBlogPhotoAvatar});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  vertical-align: top;
  @media ${Devices.tablet} {
    width: 100%;
  }
`;

export const BlogPostImageStub = styled.div<BlogPostImageContainerProps>`
  display: inline-block;
  width: 33%;
  min-height: 150px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  vertical-align: top;
  @media ${Devices.tablet} {
    width: 100%;
  }
`;

export const BlogPostInfoContainer = styled.div`
  display: inline-block;
  width: 67%;
  padding: 0 0 0 15px;
  @media ${Devices.tablet} {
    width: 100%;
    padding: 0px;
    margin-top: 15px;
  }
`;

export const BlogPostDate = styled.div`
  color: #1a1a1a;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
`;

export const BlogPostTitle = styled.div`
  color: ${theme.black};
  font-size: 24px;
  font-weight: 800;
  line-height: 34px;
`;

export const BlogPostExcerpt = styled.div`
  color: #7a7c82;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
`;

export const ReadMoreUrl = styled.a`
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  padding: 0 20px 0 0;
  background: url(${ArrowForward});
  background-position: right center;
  background-size: 15px 15px;
  cursor: pointer;
  background-repeat: no-repeat;
  color: ${props => props.theme.brandPrimary};
`;
