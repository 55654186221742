import { get } from 'lodash-es';

import { dateAndTime } from '../../../../utils/services/formatter';
import { TableCell, TableRow } from '../../../../core/components/styled';
import { WASTE_AUDIT_LOCATION_STATUS_TYPES } from '../../../constants';
import { WasteAuditNotes } from 'src/routes/interfaces/RouteStop';
import translate from '../../../../core/services/translate';

const RoutePageWasteAuditNotesTableRow = ({ notes, createdOn, wasteAuditLocationStatusTypeId }: WasteAuditNotes) => (
  <TableRow>
    <TableCell width={'25%'}>{notes}</TableCell>
    <TableCell width={'25%'}>{translate('common.completed')}</TableCell>
    <TableCell width={'25%'}>{createdOn ? dateAndTime(createdOn) : ''}</TableCell>
    <TableCell width={'25%'}>
      {wasteAuditLocationStatusTypeId !== undefined
        ? get(WASTE_AUDIT_LOCATION_STATUS_TYPES[wasteAuditLocationStatusTypeId], 'name')
        : '-'}
    </TableCell>
  </TableRow>
);

export default RoutePageWasteAuditNotesTableRow;
