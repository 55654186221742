import { map } from 'lodash-es';
import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';

type PaymentMethods = {
  id: string;
  description: string;
  typeCode: string;
  name: string;
  technicalName: string;
}

const transformPaymentMethods = (paymentMethods: PaymentMethods[]) =>
  map(paymentMethods, ({ id, name, technicalName }) => ({
    name: name,
    label: translate(createTranslationKey(technicalName, 'common.paymentMethodTypes')),
    value: id,
  }));

export default transformPaymentMethods;
