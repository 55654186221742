import { downloadInsightsExcelFile } from '../../utils/services/downloadExcelFile';
import { FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';
import { httpInsightsReporting } from '../../core/services/http';
import transformLoadDisposalSummary from './transformLoadDisposalSummary';
import translate from '../../core/services/translate';

export const loadDailyDisposalSummary = (
  vendorId: number,
  startDate: string,
  endDate: string,
  vehicleTypeId: number,
  searchString: string,
  ticketNumber: number,
  disposalSiteLocationId: number,
  sortedBy: string,
  sortOrder: string,
  filtersPreferencesIds: FiltersPreferencesIds,
) =>
  httpInsightsReporting
    .get('/reporting/dailydisposalsummary', {
      params: {
        vendorId,
        startDate,
        endDate,
        vehicleTypeId,
        searchString,
        ticketNumber,
        disposalSiteLocationId,
        sortDirection: sortOrder,
        sortOrder: sortedBy,
        vehicleTypeIdsCSV: filtersPreferencesIds.vehicleTypeIdsCSV,
        serviceZoneIdsCSV: filtersPreferencesIds.serviceZoneIdsCSV,
        supervisorIdsCSV: filtersPreferencesIds.supervisorIdsCSV,
      },
    })
    .then(response => transformLoadDisposalSummary(response.data));

export const exportDailyDisposalSummary = (
  vendorId: number,
  startDate: string,
  endDate: string,
  vehicleTypeId: number,
  searchString: string,
  ticketNumber: number,
  disposalSiteLocationId: number,
  sortedBy: string,
  sortOrder: string,
  filtersPreferencesIds: FiltersPreferencesIds,
) =>
  downloadInsightsExcelFile(
    'export/dailydisposalsummary',
    `${translate('insights.dailyDisposalSummary')}-${translate('insights.report')}`,
    {
      vendorId,
      startDate,
      endDate,
      vehicleTypeId,
      searchString,
      ticketNumber,
      disposalSiteLocationId,
      sortDirection: sortOrder,
      sortOrder: sortedBy,
      vehicleTypeIdsCSV: filtersPreferencesIds.vehicleTypeIdsCSV,
      serviceZoneIdsCSV: filtersPreferencesIds.serviceZoneIdsCSV,
      supervisorIdsCSV: filtersPreferencesIds.supervisorIdsCSV,
    },
  );
