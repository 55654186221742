import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { formValueSelector } from 'redux-form';
import { RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { AcceptOpportunityForm } from '../forms';
import { ACCEPT_CHANGES, AWAITING_HAULER_APPROVAL, YES } from '../../constants/status';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DuckFunction } from '../../../contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { Grid, GridColumn } from '../../../core/components/styled';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { loadOpportunity, updateHaulerOpportunity } from '../../ducks';
import { loadOpportunities } from '../../ducks/opportunities';
import { Modal } from '../../../core/components';
import translate from '../../../core/services/translate';
import { useIsMobileView } from 'src/utils/hooks';
import { closeMobilePageDetailOverlay } from 'src/core/ducks/mobilePage';

interface RouteParams {
  token: string;
}

interface ComponentProps {
  caseId: number;
  divisionId: string;
  divisionName: string;
  isBiddingSourcing?: boolean;
  isEquipmentCustomerOwned: boolean;
  itemId: number;
  status: any;
  offer: any;
  vendorId: number;
  userId: string;
  onCancel(pristine: boolean): void;
}

interface ReduxProps {
  isSaving: boolean;
  response: any;
  requestedChanges: any;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  loadOpportunity: DuckFunction<typeof loadOpportunity>;
  loadOpportunities: DuckFunction<typeof loadOpportunities>;
  updateHaulerOpportunity: DuckFunction<typeof updateHaulerOpportunity>;
  closeMobilePageDetailOverlay: DuckFunction<typeof closeMobilePageDetailOverlay>;
}

type Props = ComponentProps & ReduxProps & RouteComponentProps<RouteParams>;

const AcceptOpportunityModal: React.FC<Props> = props => {
  const { isSaving, onCancel } = props;

  const isMobile = useIsMobileView();

  const onSubmit = React.useCallback(async () => {
    const {
      isBiddingSourcing,
      itemId,
      userId,
      requestedChanges,
      offer,
      onCancel,
      status,
      vendorId,
      loadDispatchOpportunitiesCount,
      loadOpportunity,
      loadOpportunities,
      updateHaulerOpportunity,
      closeMobilePageDetailOverlay,
      location: { search },
      match: {
        params: { token },
      },
    } = props;

    const { showActionRequired, startDate, endDate } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES;

    const requestOpportunityObj: any = {};
    requestOpportunityObj.itemId = itemId;
    requestOpportunityObj.Response = status === AWAITING_HAULER_APPROVAL ? ACCEPT_CHANGES : YES;
    requestedChanges.facilityId = !requestedChanges.facilityId ? '0' : requestedChanges.facilityId;

    if (status === AWAITING_HAULER_APPROVAL) {
      requestOpportunityObj.requestedChanges = requestedChanges;
      requestOpportunityObj.requestedChanges.deliveryDate = moment(offer.deliveryDate).format('YYYY-MM-DD');
    } else {
      requestOpportunityObj.respondedYes = requestedChanges;
      requestOpportunityObj.respondedYes.deliveryDate = moment(offer.deliveryDate).format('YYYY-MM-DD');
    }

    const promise = updateHaulerOpportunity(
      requestOpportunityObj,
      userId,
      token,
      vendorId,
      isActionRequired,
      startDate,
      endDate,
      isBiddingSourcing,
    );

    if (!promise) {
      return;
    }

    await promise
      .then(() => {
        loadDispatchOpportunitiesCount(userId, vendorId, token);
        if (isBiddingSourcing) {
          loadOpportunities(userId, vendorId, isActionRequired, startDate, endDate);
        } else {
          loadOpportunity(userId, token, vendorId, isActionRequired);
        }
        createSuccessNotification(translate('opportunity.opportunitySave'));
        onCancel(true);
        closeMobilePageDetailOverlay();
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  }, [props]);
  return (
    <Modal title={translate('opportunity.acceptService')} size="small" isLoading={isSaving}>
      <Grid centered>
        <GridColumn size={isMobile ? '12/12' : '8/12'}>
          <AcceptOpportunityForm {...props} onSubmit={onSubmit} onCancel={onCancel} />
        </GridColumn>
      </Grid>
    </Modal>
  );
};

const formSelector = formValueSelector('acceptOpportunity');

const mapStateToProps = (state: AppState, ownProps: any) => ({
  response: formSelector(state, `opportunities.settings${ownProps.caseId}.response`),
  requestedChanges: formSelector(state, `opportunities.settings${ownProps.caseId}.requestedChanges`),
  isSaving: state.opportunity.opportunity.isSaving,
});

const mapDispatchToProps = {
  loadOpportunity,
  loadOpportunities,
  updateHaulerOpportunity,
  closeMobilePageDetailOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptOpportunityModal);
