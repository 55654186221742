import styled from 'styled-components';
import Text from './styled/Text';

const DataFieldsContainer = styled.div``;

const DataFieldContainer = styled.div`
  padding: 10px;
  border-bottom: 1px solid lightgray;
`;

interface Field {
  label: string;
  text: string | number | JSX.Element;
}

const DataField: React.FC<Field> = ({ label, text }) => (
  <DataFieldContainer>
    <Text block margin="xxSmall xxSmall" size="medium" weight="light">
      {label}
    </Text>
    <Text block margin="no xxSmall" singleLine size="large">
      {text}
    </Text>
  </DataFieldContainer>
);

export const MobileDataFields: React.FC<{ fields: Field[] }> = ({ fields }) => (
  <DataFieldsContainer>
    {fields
      .filter(f => !!f.text)
      .map(f => (
        <DataField key={f.label} {...f} />
      ))}
  </DataFieldsContainer>
);
