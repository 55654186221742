import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { DocumentTitle } from 'src/common/components';
import { finalizeSetupPaymentIntent } from 'src/finance/ducks/paymentManagement';
import { getQueryParams } from 'src/utils/services/queryParams';
import { loadInvoiceDetails, loadPaymentMethodsByInvoice } from 'src/finance/ducks';
import { PageLoading } from 'src/common/components/styled';
import { PAYMENT_STATUS_SUCCEEDED } from 'src/finance/constants/paymentStatuses';
import { TypedResolver } from 'src/core/components';
import PayNowPage from './PayNowPage';
import translate from 'src/core/services/translate';

export interface PayNowRouteParams {
  invoiceId: string;
}

interface PayNowSearchParams {
  setup_intent: string;
  setup_intent_client_secret: string;
  redirect_status: string;
  returnToBillingDetails?: string;
}

const PayNowPageResolver = () => {
  const dispatch = useDispatch();

  const { invoiceId } = useParams<PayNowRouteParams>();
  const { search } = useLocation();
  const { setup_intent, setup_intent_client_secret, redirect_status, returnToBillingDetails } =
    getQueryParams<PayNowSearchParams>(search);

  const loadDependencies = useCallback(async () => {
    const dependencies: Promise<any>[] = [
      loadInvoiceDetails(invoiceId)(dispatch),
      loadPaymentMethodsByInvoice(invoiceId)(dispatch),
    ];

    if (
      setup_intent &&
      setup_intent_client_secret &&
      redirect_status &&
      redirect_status === PAYMENT_STATUS_SUCCEEDED
    ) {
      await finalizeSetupPaymentIntent({ setup_intent, setup_intent_client_secret, redirect_status })(dispatch);
    }

    return Promise.all(dependencies);
  }, [invoiceId, setup_intent, setup_intent_client_secret, redirect_status, dispatch]);

  if (!invoiceId) return null;

  return (
    <>
      <DocumentTitle>{translate('finance.billing')}</DocumentTitle>
      <TypedResolver
        redirectOnError="/finance/billing"
        resolve={loadDependencies}
        loadingComponent={PageLoading}
        successComponent={PayNowPage}
        successProps={{
          invoiceId,
          returnToBillingDetails,
        }}
      />
    </>
  );
};

export default PayNowPageResolver;
