import React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { Modal } from '../../../core/components';
import { ModalTitle, ModalSubtitle, ModalSection } from '../../../core/components/styled';
import translate from './../../../core/services/translate';
import { PhoneNumbersForm } from './';

interface Props {
  closeModal: () => void;
  contacts: any;
  isDeleting: boolean;
  isLoading: boolean;
  isSaving: boolean;
  isUpdating: boolean;
}

function ManagePhoneNumbersModal({ closeModal, contacts, isDeleting, isLoading, isSaving, isUpdating }: Props) {
  return (
    <Modal padding="medium" isLoading={isLoading || isSaving || isUpdating || isDeleting} onClose={closeModal}>
      <ModalTitle noTransform>{translate('autoDispatch.managePhoneNumbers')}</ModalTitle>
      <ModalSubtitle>{translate('autoDispatch.managePhoneNumbersModalSubtitle')}</ModalSubtitle>
      <ModalSection margin="medium no no">
        <PhoneNumbersForm initialValues={{ phoneNumbers: [...contacts, {}] }} />
      </ModalSection>
    </Modal>
  );
}

const mapStateToProps = (state: AppState) => ({
  contacts: state.fleet.contacts.contacts || [],
  isDeleting: state.fleet.contacts.isDeleting,
  isLoading: state.fleet.contacts.isLoading,
  isSaving: state.fleet.contacts.isSaving,
  isUpdating: state.fleet.contacts.isUpdating,
});

export default connect(mapStateToProps)(ManagePhoneNumbersModal);
