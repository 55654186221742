import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import { AppState } from '../../../store';
import { Container, ButtonSet, Button } from '../../../core/components/styled';
import { Dropdown, TextArea } from '../../../core/components';
import { isRequired } from '../../../utils/services/validator';
import { MISSED_PICKUP } from 'src/fleet/constants/openDispatachesServices';
import { ReasonCodeGroups } from '../../interfaces/RubiconDispatches';
import openDispatchesFormInitialValueSelector from '../../services/openDispatchesFormInitialValueSelector';
import translate from '../../../core/services/translate';
import { UseIsMobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';

type PropsWithoutReduxForm = {
  onCancel: (pristine: boolean, isReload?: boolean) => void;
  pickupTypeId: number;
  pristine?: boolean;
  reasonCodes?: ReasonCodeGroups;
  rubiconPONbr: string;
  serviceDate?: Date | string;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<{}, PropsWithoutReduxForm>;

class DeclineOpenDispatchesForm extends PureComponent<Props> {
  render() {
    const { rubiconPONbr, serviceDate, handleSubmit, onCancel, pristine, pickupTypeId, reasonCodes } = this.props;
    const reasonCodesOptions =
      reasonCodes && pickupTypeId === MISSED_PICKUP ? reasonCodes.declineRecovery : reasonCodes?.declineService;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <Container>
          <Field
            name={`opportunities.settings${rubiconPONbr}${serviceDate}.reasonCode`}
            component={Dropdown}
            options={reasonCodesOptions}
            label={translate('autoDispatch.reasonCode')}
            validate={[isRequired]}
            margin="large small small"
          />
        </Container>
        <Container>
          <Field
            name={`opportunities.settings${rubiconPONbr}${serviceDate}.note`}
            component={TextArea}
            rows={2}
            label={translate('routes.addRouteNote')}
            size="small"
            validate={[isRequired]}
            margin="medium small large"
          />
        </Container>
        <UseIsMobileWidthView
          render={isMobile => (
            <ButtonSet margin={isMobile ? 'no' : 'large small large'}>
              <Button type="submit" color="primary">
                {translate('opportunity.submit')}
              </Button>
              <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
                {translate('common.cancel')}
              </Button>
            </ButtonSet>
          )}
        />
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  initialValues: openDispatchesFormInitialValueSelector(
    state.fleet.openDispatches.opportunities,
    state.fleet.rubiconDispatches.rubiconDispatches,
  ),
});

export default connect(mapStateToProps)(
  reduxForm<{}, PropsWithoutReduxForm>({
    form: 'declineOpenDispatches',
  })(DeclineOpenDispatchesForm),
);
