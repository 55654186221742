import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const PICKUP_TYPE_OPT_PICKUP = 'Pickup';
export const PICKUP_TYPE_OPT_FINAL = 'Final';
export const PICKUP_TYPE_OPT_DELIVERY = 'Delivery';
export const PICKUP_TYPE_OPT_EMPTY_AND_RETURN = 'EmptyAndReturn';
export const PICKUP_TYPE_OPT_RELOCATE = 'Relocate';
export const PICKUP_TYPE_OPT_SWAP = 'Swap';
export const PICKUP_TYPE_OPT_REMOVAL = 'Removal';
export const PICKUP_TYPE_OPT_REPLACEMENT = 'Replacement';
export const PICKUP_TYPE_OPT_EXTRA_PICKUP_PICKUP_TYPE = 'ExtraPickup';
export const PICKUP_TYPE_OPT_BULK_ITEM = 'BulkItem';
export const PICKUP_TYPE_OPT_CLEAN_UP = 'CleanUp';
export const PICKUP_TYPE_OPT_MAINTENANCE = 'Maintenance';
export const PICKUP_TYPE_OPT_WASH_OUT = 'WashOut';

export const ROUTE_PICKUP_TYPES_OPTIONS_CART = mapKeys(
  [
    { technicalName: PICKUP_TYPE_OPT_PICKUP, name: translate('routes.pickupTypes.pickup') },
    { technicalName: PICKUP_TYPE_OPT_EXTRA_PICKUP_PICKUP_TYPE, name: translate('routes.pickupTypes.extraPickup') },
    { technicalName: PICKUP_TYPE_OPT_BULK_ITEM, name: translate('routes.pickupTypes.bulkItem') },
  ],
  'technicalName',
);

export const ROUTE_PICKUP_TYPES_OPTIONS_DEFAULT = mapKeys(
  [{ technicalName: PICKUP_TYPE_OPT_PICKUP, name: translate('routes.pickupTypes.pickup') }],
  'technicalName',
);

export const ROUTE_PICKUP_TYPES_OPTIONS_DELIVERY = mapKeys(
  [
    { technicalName: PICKUP_TYPE_OPT_DELIVERY, name: translate('routes.pickupTypes.delivery') },
    { technicalName: PICKUP_TYPE_OPT_RELOCATE, name: translate('routes.pickupTypes.relocate') },
    { technicalName: PICKUP_TYPE_OPT_BULK_ITEM, name: translate('routes.pickupTypes.bulkItem') },
    { technicalName: PICKUP_TYPE_OPT_CLEAN_UP, name: translate('routes.pickupTypes.cleanUp') },
    { technicalName: PICKUP_TYPE_OPT_MAINTENANCE, name: translate('routes.pickupTypes.maintenance') },
    { technicalName: PICKUP_TYPE_OPT_REMOVAL, name: translate('routes.pickupTypes.removal') },
    { technicalName: PICKUP_TYPE_OPT_REPLACEMENT, name: translate('routes.pickupTypes.replacement') },
    { technicalName: PICKUP_TYPE_OPT_WASH_OUT, name: translate('routes.pickupTypes.washOut') },
  ],
  'technicalName',
);

export const ROUTE_PICKUP_TYPES_OPTIONS_FRONTLOAD = mapKeys(
  [
    { technicalName: PICKUP_TYPE_OPT_PICKUP, name: translate('routes.pickupTypes.pickup') },
    { technicalName: PICKUP_TYPE_OPT_RELOCATE, name: translate('routes.pickupTypes.relocate') },
    { technicalName: PICKUP_TYPE_OPT_EXTRA_PICKUP_PICKUP_TYPE, name: translate('routes.pickupTypes.extraPickup') },
    { technicalName: PICKUP_TYPE_OPT_BULK_ITEM, name: translate('routes.pickupTypes.bulkItem') },
    { technicalName: PICKUP_TYPE_OPT_CLEAN_UP, name: translate('routes.pickupTypes.cleanUp') },
  ],
  'technicalName',
);

export const ROUTE_PICKUP_TYPES_OPTIONS_RESIDENTIAL = mapKeys(
  [
    { technicalName: PICKUP_TYPE_OPT_PICKUP, name: translate('routes.pickupTypes.pickup') },
    { technicalName: PICKUP_TYPE_OPT_EXTRA_PICKUP_PICKUP_TYPE, name: translate('routes.pickupTypes.extraPickup') },
    { technicalName: PICKUP_TYPE_OPT_BULK_ITEM, name: translate('routes.pickupTypes.bulkItem') },
  ],
  'technicalName',
);

export const ROUTE_PICKUP_TYPES_OPTIONS_ROLLOFF = mapKeys(
  [
    { technicalName: PICKUP_TYPE_OPT_FINAL, name: translate('routes.pickupTypes.final') },
    { technicalName: PICKUP_TYPE_OPT_DELIVERY, name: translate('routes.pickupTypes.delivery') },
    { technicalName: PICKUP_TYPE_OPT_EMPTY_AND_RETURN, name: translate('routes.pickupTypes.emptyAndReturn') },
    { technicalName: PICKUP_TYPE_OPT_RELOCATE, name: translate('routes.pickupTypes.relocate') },
    { technicalName: PICKUP_TYPE_OPT_SWAP, name: translate('routes.pickupTypes.swap') },
    // { technicalName: CLEAN_UP, name: translate('routes.pickupTypes.cleanUp') },
  ],
  'technicalName',
);
