import React, { PureComponent, Fragment } from 'react';
import { observer } from 'mobx-react';

import VehicleTrackingMarker from './VehicleTrackingMarker';
import { GOOGLE as google } from '../constants';
import { OpenedInfoWindows } from '../interfaces/OpenedInfoWindows';
import { Coordinates, GroupedVehicleTrackings } from '../../routes/components/mapWithTimeline/Interfaces';

const isCoordinateGroupInsideViewBoundaries = (coordinateGroups: any[], mapBounds: any, mapZoom: number) => {
  if (mapZoom <= 10) return false;

  const groupBounds = new google.maps.LatLngBounds();
  coordinateGroups.forEach(coordinateGroup => {
    coordinateGroup.coordinates.forEach((coordinate: Coordinates) => {
      groupBounds.extend(new google.maps.LatLng(coordinate.latitude, coordinate.longitude));
    });
  });

  return mapBounds.intersects(groupBounds);
};

interface Props {
  coordinateGroups: GroupedVehicleTrackings[];
  mapBounds: any;
  mapZoom: number;
  openedInfoWindows: OpenedInfoWindows;
  shouldDisplayDownloadVideo?: boolean;
  toggleInfoWindow: (key: string, vehicleIndex: number, endTimestamp: string) => string;
  unitOfMeasure: string;
  vehicleId: number;
  vehicleIndex: number;
  vehicleName: string;
}

class VehicleTrackingGroup extends PureComponent<Props, { coordinateGroupIds: any[] }> {
  readonly state = {
    coordinateGroupIds: [],
  };

  componentDidMount() {
    const { coordinateGroups } = this.props;
    if (coordinateGroups) this.setCoordinateGroupIds();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.coordinateGroups.length !== this.props.coordinateGroups.length) {
      this.setCoordinateGroupIds();
    }
  }

  setCoordinateGroupIds = () => {
    this.setState({
      coordinateGroupIds: this.props.coordinateGroups.map((group, i) => `coordinateGroup_${i + 1}`),
    });
  };

  getId = (index: number) => this.state.coordinateGroupIds[index];

  render() {
    const {
      coordinateGroups,
      mapBounds,
      mapZoom,
      openedInfoWindows,
      shouldDisplayDownloadVideo,
      toggleInfoWindow,
      unitOfMeasure,
      vehicleId,
      vehicleIndex,
      vehicleName,
    } = this.props;

    return (
      <Fragment>
        {coordinateGroups &&
          isCoordinateGroupInsideViewBoundaries(coordinateGroups, mapBounds, mapZoom) &&
          coordinateGroups.map((coordinateGroup, index) =>
            coordinateGroup.hideOnMap && coordinateGroup.hideOnMap.get() ? null : (
              <VehicleTrackingMarker
                coordinateGroup={coordinateGroup}
                coordinateGroupId={this.getId(index)}
                isInfoWindowOpen={openedInfoWindows.vehicleTrackings[this.getId(index)]}
                key={index}
                mapZoom={mapZoom}
                shouldDisplayDownloadVideo={shouldDisplayDownloadVideo}
                strobeImageUrl={openedInfoWindows.imageUrl}
                toggleInfoWindow={toggleInfoWindow}
                unitOfMeasure={unitOfMeasure}
                vehicleId={vehicleId}
                vehicleIndex={vehicleIndex}
                vehicleName={vehicleName}
              />
            ),
          )}
      </Fragment>
    );
  }
}

export default observer(VehicleTrackingGroup);
