import { useSelector, useDispatch } from 'react-redux';

import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';

import { AppState } from 'src/store';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadRubiconServiceSubmitedTickets } from 'src/customers/ducks/rubiconServices';
import RubiconServicesTicketStatusModal from './RubiconServicesTicketStatusModal';

interface Props {
  closeModal: () => void;
  customerName: String;
  location: string;
  serviceId: number;
  serviceStatus: string;
}

export default function RubiconServicesTicketStatusModalResolver(props: Props) {
  const { closeModal, serviceId, customerName, location } = props;

  const userId = useSelector((state: AppState) => state.account.login.user.userId);
  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();

  const loadDependencies = () => {
    if (!vendorId) return Promise.reject();
    return loadRubiconServiceSubmitedTickets(serviceId, vendorId, userId)(dispatch);
  };

  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={RubiconServicesTicketStatusModal}
      successProps={{ closeModal, serviceId, customerName, location }}
    />
  );
}
