import update from 'immutability-helper';
import { get, identity, sortBy } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import doLoadMapInsights from '../services/loadMapInsights';

// Actions
const START_LOAD = 'dashboard/mapInsights/START_LOAD';
export const COMPLETE_LOAD = 'dashboard/mapInsights/COMPLETE_LOAD';
const FAIL_LOAD = 'dashboard/mapInsights/FAIL_LOAD';
export const TOGGLE_MAP_INSIGHTS = 'dashboard/mapInsights/TOGGLE';
const RESET = 'dashboard/mapInsights/RESET';

// Initial state
const initialState = {
  isLoading: false,
  mapInsights: undefined,
  isExpanded: true,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          mapInsights: action.mapInsights,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          mapInsights: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          isLoading: false,
          mapInsights: undefined,
        },
      });
    case TOGGLE_MAP_INSIGHTS:
      return update(state, {
        $merge: {
          isExpanded: action.isExpanded,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (
  mapInsights: any,
  containerIssueInsightTypes?: string[],
  containerServiceInsightTypes?: string[],
  vehicleInsightTypes?: string[],
  cityInsightTypes?: string[],
) => ({
  type: COMPLETE_LOAD,
  mapInsights,
  containerIssueInsightTypes,
  containerServiceInsightTypes,
  vehicleInsightTypes,
  cityInsightTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadMapInsights =
  ({
    vendorId,
    date,
    vehicleIds,
    containerIssueInsightTypes,
    containerServiceInsightTypes,
    vehicleInsightTypes,
    cityInsightTypes,
    vehicleTypeIdsCSV,
    serviceZoneIdsCSV,
    supervisorIdsCSV,
  }: {
    vendorId: number;
    date: Date | string;
    vehicleIds: number[];
    containerIssueInsightTypes?: string[];
    containerServiceInsightTypes?: string[];
    vehicleInsightTypes?: string[];
    cityInsightTypes?: string[];
    vehicleTypeIdsCSV?: number[];
    serviceZoneIdsCSV?: number[];
    supervisorIdsCSV?: number[];
  }) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    return doLoadMapInsights({
      vendorId,
      date,
      vehicleIds,
      containerIssueInsightTypes,
      containerServiceInsightTypes,
      vehicleInsightTypes,
      cityInsightTypes,
      vehicleTypeIdsCSV,
      serviceZoneIdsCSV,
      supervisorIdsCSV,
    })
      .then(mapInsights =>
        dispatch(
          completeLoad(
            mapInsights,
            containerIssueInsightTypes,
            containerServiceInsightTypes,
            vehicleInsightTypes,
            cityInsightTypes,
          ),
        ),
      )
      .catch(() => dispatch(failLoad()));
  };

export const resetMapInsights = () => ({
  type: RESET,
});

export const toggleMapInsightsContainer = (isExpanded: boolean) => ({
  type: TOGGLE_MAP_INSIGHTS,
  isExpanded,
});

// Selectors
const getVehicleMapInsightTypes = (mapInsightsState: any) =>
  get(mapInsightsState, 'mapInsights.vehicleInsights.insightTypes') || [];
export const vehicleMapInsightTypesSelector = createSelector(getVehicleMapInsightTypes, identity);

const getContainerMapInsightTypes = (mapInsightsState: any) =>
  sortBy(get(mapInsightsState, 'mapInsights.containerInsights.insightTypes'), 'displayOrder') || [];
export const containerMapInsightTypesSelector = createSelector(getContainerMapInsightTypes, identity);

const getCityMapInsightTypes = (mapInsightsState: any) =>
  get(mapInsightsState, 'mapInsights.cityInsights.insightTypes') || [];
export const cityMapInsightTypesSelector = createSelector(getCityMapInsightTypes, identity);

const getVehicleMapInsights = (mapInsightsState: any) =>
  get(mapInsightsState, 'mapInsights.vehicleInsights.insights') || [];
export const vehicleMapInsightsSelector = createSelector(getVehicleMapInsights, identity);

const getContainerMapInsights = (mapInsightsState: any) =>
  get(mapInsightsState, 'mapInsights.containerInsights.insights') || [];
export const containerMapInsightsSelector = createSelector(getContainerMapInsights, identity);

const getCityMapInsights = (mapInsightsState: any) => get(mapInsightsState, 'mapInsights.cityInsights.insights') || [];
export const cityMapInsightsSelector = createSelector(getCityMapInsights, identity);
