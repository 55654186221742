import { camelCase, get, map } from 'lodash-es';
import { Fragment, PureComponent } from 'react';
import humps from 'humps';

import { ActionButtonTooltip, ThumbnailSet } from '../../../core/components';
import { ContainerInsightSubType } from 'src/routes/components/mapWithTimeline/Interfaces';
import { CustomerEmailModalResolver } from '../../../customers/components/modals/CustomerEmailModalResolver';
import { date, time } from '../../../utils/services/formatter';
import { PICKUP_STATUS_SOURCE_PORTAL } from 'src/dashboard/constants/dashboardFilter';
import { TableActionButton, TableCell, TableRow, Text } from '../../../core/components/styled';
import RouteImagesModal from '../../../routes/components/modals/RouteImagesModal';
import translate from '../../../core/services/translate';

type Props = ContainerInsightSubType & {
  address: string;
  customerEmail: string;
  customerName: string;
  pickupStatusSourceName?: string;
  pickupStatusSourceType?: string;
  reportDateTime?: string;
  routeId: number;
  routeLocationDetailPickupExceptionId: number;
  routeLocationId: number;
  sourceTypeName?: string;
  vehicleName?: string;
  vehicleTypeId?: number;
};

interface State {
  isRouteLocationIssueImagesModalOpen: boolean;
  isEmailModalOpen: boolean;
}

class IssuesReportedModalTableRow extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isRouteLocationIssueImagesModalOpen: false, isEmailModalOpen: false };
  }

  openRouteLocationIssueImagesModal = () => {
    this.setState({ isRouteLocationIssueImagesModalOpen: true });
  };

  closeRouteLocationIssueImagesModal = () => {
    this.setState({ isRouteLocationIssueImagesModalOpen: false });
  };

  render() {
    const {
      address,
      customerEmail,
      customerName,
      imageBlobId,
      images: issueImages = [],
      imageUrl,
      rawImageUrl,
      insightDetailsExceptionSubTypes,
      name,
      pickupExceptionDetails,
      pickupStatusSourceName,
      pickupStatusSourceType,
      reportDateTime,
      routeLocationDetailPickupExceptionId,
      routeLocationId,
      sourceTypeName = translate('common.vehicle'),
      vehicleName,
      vehicleTypeId,
    } = this.props;

    const timeStamp = get(pickupExceptionDetails, 'timeStamp');
    const elapsedTime = get(pickupExceptionDetails, 'elapsedTime');
    const description = get(pickupExceptionDetails, 'description');
    const numericValue = get(pickupExceptionDetails, 'numericValue');

    const { isRouteLocationIssueImagesModalOpen, isEmailModalOpen } = this.state;

    const imageInfo = {
      customerName,
      issueName: translate(`routes.pickupIssueTypes.${camelCase(name)}`),
      locationName: address,
      timeStamp: timeStamp || reportDateTime,
    };

    const images = [];

    if (!!issueImages.length) {
      issueImages.forEach(image => {
        images.push({
          ...imageInfo,
          imageUrl: image.imageUrl,
          rawImageUrl: image?.rawImageUrl || image.imageUrl,
          imageBlobId: image.imageBlobId,
        });
      });
    } else {
      if (!!imageUrl) {
        images.push({
          ...imageInfo,
          imageUrl,
          rawImageUrl: rawImageUrl || imageUrl,
          imageBlobId,
        });
      }
    }

    const locationIssues = [translate(`routes.pickupIssueTypes.${camelCase(name)}`)];

    return (
      <Fragment>
        <TableRow>
          <TableCell width="15%" vertical align="center">
            <Text block margin="no no xxSmall">
              {date(timeStamp)}
            </Text>
            <Text block size="small">
              {time(timeStamp)}
            </Text>
          </TableCell>

          <TableCell width="20%" vertical align="center">
            <Fragment>
              {map(locationIssues, (issue, index) => (
                <Text block size="medium" key={index}>
                  {issue}
                </Text>
              ))}
              {insightDetailsExceptionSubTypes && (
                <Text block size="small">
                  {translate(
                    `routes.pickupIssueTypes.exceptionSubTypes.${humps.camelize(
                      insightDetailsExceptionSubTypes.technicalName,
                    )}`,
                  )}
                </Text>
              )}

              {elapsedTime && (
                <Text block size="small">
                  {elapsedTime}
                </Text>
              )}
              {numericValue && (
                <Text block size="small">
                  {numericValue}
                </Text>
              )}
            </Fragment>
          </TableCell>
          <TableCell width="20%">
            <Text block margin="no no xxSmall">
              {description || '-'}
            </Text>
          </TableCell>
          <TableCell width="17%" vertical align="center">
            <Text block margin="no no xxSmall">
              {pickupStatusSourceName || (pickupStatusSourceType !== PICKUP_STATUS_SOURCE_PORTAL && vehicleName)}
            </Text>
            <Text block size="small">
              {pickupStatusSourceType ? translate(`common.${camelCase(pickupStatusSourceType)}`) : sourceTypeName}
            </Text>
          </TableCell>
          <TableCell width="15%">
            <TableActionButton onClick={() => this.setState({ isEmailModalOpen: true })}>
              <ActionButtonTooltip size="medium" icon="email" tooltip="sendEmail" />
            </TableActionButton>
          </TableCell>
          <TableCell width="13%">
            {!!images.length && (
              <ThumbnailSet images={images.map(i => i.imageUrl)} onClick={this.openRouteLocationIssueImagesModal} />
            )}
          </TableCell>
        </TableRow>

        {!!isRouteLocationIssueImagesModalOpen && (
          <RouteImagesModal images={images} closeModal={this.closeRouteLocationIssueImagesModal} />
        )}
        {isEmailModalOpen && (
          <CustomerEmailModalResolver
            routeLocationId={routeLocationId}
            routeLocationDetailPickupExceptionId={routeLocationDetailPickupExceptionId}
            onClose={() => this.setState({ isEmailModalOpen: false })}
            email={{
              toEmail: customerEmail,
              emailSubject: `${date(timeStamp)}: ${locationIssues.join(', ')}`,
              images,
              vehicleTypeId,
            }}
          />
        )}
      </Fragment>
    );
  }
}

export default IssuesReportedModalTableRow;
