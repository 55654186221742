import { VehicleTracking, TimelineTracking, StationaryTimeInfo, TripTimeIncident } from './Interfaces';
import { last, times } from 'lodash-es';

const minTrackingsPerMinute = 5;

export const timelineWidth = 880;
export const timelineLargeWidth = 1020;
export const timelineLargestWidth = 1140;
export const cursorWidth = 2;

type TrackingsByMinuteMap = { [key: string]: TimelineTracking[] };

const clonedTrackingDefaultData = { isCloned: true, speed: 0 };

function mapTrackingsByMinute(
  vehicleTracking: VehicleTracking,
  tripStartDate: string,
  tripEndDate: string,
): TrackingsByMinuteMap {
  const trackingsByMinute: TrackingsByMinuteMap = {};

  const tripStartDateMs = new Date(tripStartDate).getTime();
  const tripEndDateMs = new Date(tripEndDate).getTime();

  let lastProcessedTrackingMinute: number = 0;

  vehicleTracking.coordinateGroups.forEach(cg => {
    const groupStartDateTimeMs = new Date(cg.startTimestamp).getTime();
    if (groupStartDateTimeMs < tripStartDateMs || tripEndDateMs < groupStartDateTimeMs) {
      return;
    }
    const groupTimeframeMs = new Date(cg.endTimestamp).getTime() - groupStartDateTimeMs;
    const timeDifferenceBetweenCoordinatesMs = Math.round(groupTimeframeMs / cg.coordinates.length);

    cg.coordinates.forEach(c => {
      const currentTrackingTimeEstimateMs = groupStartDateTimeMs + timeDifferenceBetweenCoordinatesMs;
      const currentTrackingMinute = Math.round((currentTrackingTimeEstimateMs - tripStartDateMs) / 1000 / 60);

      const currentVehicleTracking = {
        timestamp: currentTrackingTimeEstimateMs,
        speed: cg.speed,
        coordinates: c,
      };

      if (!trackingsByMinute[currentTrackingMinute]) {
        trackingsByMinute[currentTrackingMinute] = [currentVehicleTracking];
      }

      if (!trackingsByMinute[lastProcessedTrackingMinute]) {
        trackingsByMinute[lastProcessedTrackingMinute] = [];
      }

      const trackingsPreviousMinute = trackingsByMinute[lastProcessedTrackingMinute];

      const lastVehicleTracking = last(trackingsPreviousMinute) || currentVehicleTracking;
      const differenceInMinutesSinceLastTracking = currentTrackingMinute - lastProcessedTrackingMinute;

      // if there are any previous minutes that have missing data, fill with last known location
      if (differenceInMinutesSinceLastTracking > 1) {
        let lastProcessedMinute = lastProcessedTrackingMinute + 1;
        let timestampEstimate = lastVehicleTracking.timestamp;
        times(differenceInMinutesSinceLastTracking - 1, () => {
          timestampEstimate = timestampEstimate + 60000; // add one minute
          trackingsByMinute[lastProcessedMinute] = times(minTrackingsPerMinute, () => ({
            ...lastVehicleTracking,
            ...clonedTrackingDefaultData,
            timestamp: timestampEstimate,
          }));
          lastProcessedTrackingMinute = lastProcessedMinute;
          lastProcessedMinute++;
        });
      }

      // ensure the previous minute has enough trackings
      if (
        currentTrackingMinute !== lastProcessedTrackingMinute &&
        trackingsPreviousMinute.length < minTrackingsPerMinute
      ) {
        times(minTrackingsPerMinute - trackingsPreviousMinute.length, () => {
          trackingsPreviousMinute.push({ ...lastVehicleTracking, ...clonedTrackingDefaultData });
        });
      }

      trackingsByMinute[currentTrackingMinute].push(currentVehicleTracking);

      lastProcessedTrackingMinute = currentTrackingMinute;
    });
  });

  makeSureWeHaveEnoughTrackingsAtTheBeginning(trackingsByMinute, tripStartDate);

  makeSureWeHaveEnoughTrackingsAtTheEnd(trackingsByMinute, Math.round((tripEndDateMs - tripStartDateMs) / 1000 / 60));

  return trackingsByMinute;
}

function makeSureWeHaveEnoughTrackingsAtTheBeginning(trackingsByMinute: TrackingsByMinuteMap, tripStartDate: string) {
  // add missing trackings at the beginning of the trip if trip start date is not the same as the first tracking
  const firstMinuteTrackings = trackingsByMinute[Object.keys(trackingsByMinute)[0]];
  if (firstMinuteTrackings) {
    const firstTracking = firstMinuteTrackings[0];
    const tripStartDateMs = new Date(tripStartDate).getTime();
    const differenceInMinutesSinceTripStart = Math.round((firstTracking.timestamp - tripStartDateMs) / 1000 / 60);

    if (differenceInMinutesSinceTripStart > 0) {
      let lastMinute = 0;
      let timestampEstimate = tripStartDateMs;
      times(differenceInMinutesSinceTripStart, () => {
        trackingsByMinute[lastMinute] = times(minTrackingsPerMinute, () => ({
          ...firstTracking,
          ...clonedTrackingDefaultData,
          timestamp: timestampEstimate,
        }));
        lastMinute++;
        timestampEstimate = timestampEstimate + 60000;
      });
    }
  }
}

function makeSureWeHaveEnoughTrackingsAtTheEnd(trackingsByMinute: TrackingsByMinuteMap, tripDurationMinutes: number) {
  const lastMinuteTrackings = trackingsByMinute[Object.keys(trackingsByMinute).length - 1];
  if (lastMinuteTrackings) {
    let lastMinute = Number(Object.keys(trackingsByMinute)[Object.keys(trackingsByMinute).length - 1]);
    let timestampEstimate = lastMinuteTrackings[lastMinuteTrackings.length - 1].timestamp;
    const c = lastMinuteTrackings[lastMinuteTrackings.length - 1].coordinates;
    times(tripDurationMinutes - lastMinute - 1, () => {
      trackingsByMinute[lastMinute + 1] = times(minTrackingsPerMinute, () => ({
        ...clonedTrackingDefaultData,
        coordinates: c,
        timestamp: timestampEstimate,
      }));
      lastMinute++;
      timestampEstimate = timestampEstimate + 60000;
    });
  }
}

function distributeTrackingsEvenly(trackingsByMinuteMap: TrackingsByMinuteMap) {
  let normalizedVehicleTrackings: TimelineTracking[] = [];
  Object.keys(trackingsByMinuteMap).forEach(k => {
    const trackingsForCurrentMinute = trackingsByMinuteMap[k];
    const numberOfTrackingsCurrentMinute = trackingsForCurrentMinute.length;

    if (numberOfTrackingsCurrentMinute < minTrackingsPerMinute) {
      normalizedVehicleTrackings = [...normalizedVehicleTrackings, ...trackingsForCurrentMinute];
    } else {
      // if the minute has the required number of trackings or more, then take the required amount of trackings evenly distributed
      const optimalDistanceBetweenTrackings = Math.floor(numberOfTrackingsCurrentMinute / minTrackingsPerMinute);
      let trackingIndex = 0;
      times(minTrackingsPerMinute, () => {
        normalizedVehicleTrackings.push(trackingsForCurrentMinute[trackingIndex]);
        trackingIndex = trackingIndex + optimalDistanceBetweenTrackings;
      });
    }
  });

  return normalizedVehicleTrackings;
}

// The timeline unit width is how much the cursor needs to move on the timeline per unit
export const getTimelineUnitWidth = (totalTripTimeInSeconds: number) =>
  (timelineWidth - cursorWidth) / ((totalTripTimeInSeconds / 60) * minTrackingsPerMinute);

export const getAccurateTimelineUnitWidth = (totalNumberOfVehicleTrackings: number) =>
  (timelineWidth - cursorWidth) / totalNumberOfVehicleTrackings;

// We need the same amount of trackings for each minute for the entire duration of the trip.
// This is for the timeline cursor to be accurate. Certain minutes have missing or insufficient data.
// We clone the last known location in those cases.

export function normalizeTrackingDistribution(
  vehicleTracking: VehicleTracking,
  tripStartDate: string,
  tripEndDate: string,
): TimelineTracking[] {
  // first we map the trackings by the minute
  const trackingsByMinuteMap = mapTrackingsByMinute(vehicleTracking, tripStartDate, tripEndDate);

  // then we take the same amount of trackings for each minute
  return distributeTrackingsEvenly(trackingsByMinuteMap);
}

export function getStationaryTimeInfo(
  tripTimeIncidents: TripTimeIncident[],
  routeStartDate: string,
): StationaryTimeInfo {
  const stationaryTimeInfo = new StationaryTimeInfo();

  let totalNumberOfStationaryTime = 0;

  const startDate = new Date(routeStartDate);

  if (tripTimeIncidents) {
    tripTimeIncidents.forEach(i => {
      const startTimestamp = new Date(i.reportDateTime).getTime();
      const differenceSinceStart = (startTimestamp - startDate.getTime()) / 1000 / 60;
      const startIndexEstimate = Math.round(differenceSinceStart * minTrackingsPerMinute);
      const durationInMs = i.duration * 60 * 1000;
      stationaryTimeInfo.map[startIndexEstimate] = {
        numberOfStationaryTrackings: Math.round(i.duration * minTrackingsPerMinute),
        startTimestamp: startTimestamp,
        endTimestamp: startTimestamp + durationInMs,
      };
      totalNumberOfStationaryTime = totalNumberOfStationaryTime + durationInMs;
    });
  }

  stationaryTimeInfo.total = totalNumberOfStationaryTime;

  return stationaryTimeInfo;
}
