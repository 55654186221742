import React, { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadVehicleTypes } from '../../../fleet/ducks';
import { loadVersionStatuses } from '../../ducks';
import { PanelSectionLoading } from '../../../core/components/styled';
import { Resolver } from '../../../core/components';
import translate from '../../../core/services/translate';
import VersionStatusesPage from './VersionStatusesPage';

interface Props extends RouteComponentProps {
  loadVersionStatuses(...args: any[]): any;
  loadVehicleTypes(...args: any[]): any;
  vendorId: string;
}

class VersionStatusesPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { location, vendorId, loadVersionStatuses, loadVehicleTypes } = this.props;
    const { vehicleTypeId } = getQueryParams(location.search);

    const dependencies = [loadVehicleTypes()];
    if (vendorId && vehicleTypeId) {
      dependencies.push(loadVersionStatuses(vendorId, vehicleTypeId));
    }
    return Promise.all(dependencies);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('settings.versionStatus')}</DocumentTitle>
        <Resolver
          successComponent={VersionStatusesPage}
          loadingComponent={PanelSectionLoading}
          resolve={this.loadDependencies}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadVersionStatuses, loadVehicleTypes };

export default connect(mapStateToProps, mapDispatchToProps)(VersionStatusesPageResolver);
