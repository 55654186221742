import { FC, useState } from 'react';

import { Box } from 'src/core/components/styled/Box';
import { IconButtonIcon, Text } from 'src/core/components/styled';
import { TravelPathDrawingInstructionsContainer } from 'src/routes/components/styled';
import translate from 'src/core/services/translate';

const TravelPathEditInstructions: FC = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <TravelPathDrawingInstructionsContainer
      isOpen={isOpen}
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        setIsOpen(!isOpen);
      }}
    >
      {isOpen ? (
        <>
          <Box display="block" onClick={() => setIsOpen(!isOpen)}>
            <IconButtonIcon icon="xMark" margin="no no xxSmall" />
          </Box>
          <ol>
            <li>
              <Text block margin="xxSmall no no" size="small">
                {translate('maps.instructions.selectStart')}
              </Text>
            </li>
            <li>
              <Text block margin="xxSmall no no" size="small">
                {translate('maps.instructions.selectEnd')}
              </Text>
            </li>
            <li>
              <Text block margin="xxSmall no no" size="small">
                {translate('maps.instructions.drawDesiredPath')}
              </Text>
            </li>
            <li>
              <Text block margin="xxSmall no no" size="small">
                {translate('maps.instructions.reviewAndSave')}
              </Text>
            </li>
          </ol>
        </>
      ) : (
        <IconButtonIcon icon="info" margin="no" />
      )}
    </TravelPathDrawingInstructionsContainer>
  );
};

export default TravelPathEditInstructions;
