import { http } from '../../core/services/http';

const loadPickupTypes = (vehicleTypeId: number | null) =>
  http
    .get('common/systemTypes/pickupTypes', {
      params: { vehicleTypeId },
    })
    .then(response => response.data);

export default loadPickupTypes;
