import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import {
  getVehicleTrackingsGeoJSON,
  handleRouteMapVehicleTrackingsFeatureState,
  VehicleTrackingFeatureProperties,
} from './utils';
import { ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE } from '../constants';
import { RouteMapVehicleTracking } from 'src/routes/interfaces/RouteMapVehicleData';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import arrow from 'src/common/assets/img/common/arrowSmall.png';
import RouteTimelineMapVehicleTrackingsGLPopupResolver from './RouteTimelineMapVehicleTrackingsGLPopupResolver';
import RouteTimelineMapVehicleTrackingsGLSource from './RouteTimelineMapVehicleTrackingsGLSource';
import YRouteMapVehicleTrackingsGLPopup from 'src/routes/components/pages/yRoute/yRoutePageSections/yRouteMap/YRouteMapVehicleTrackingsGLPopup';

const mapImages = [
  {
    id: 'arrow',
    url: arrow,
    sourceId: ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE,
    sdf: true,
  },
];

const mapImagesOptions = { addLayers: false };

type Props = {
  map: mapboxgl.Map;
  isYRoute?: boolean;
  vehicleTrackings: RouteMapVehicleTracking[];
  isPlaybackMode?: boolean;
};

export default function RouteTimelineMapVehicleTrackingsGL({ map, vehicleTrackings, isYRoute, isPlaybackMode }: Props) {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();

  const geoJSON = useMemo(() => getVehicleTrackingsGeoJSON(vehicleTrackings), [vehicleTrackings]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE);

        if (feature) {
          const { vehicleId, coordinateGroupIndex } = feature.properties as unknown as VehicleTrackingFeatureProperties;

          dispatch(clearRouteMapSelectedFeature());
          dispatch(
            setRouteMapSelectedFeature(RouteMapFeature.vehicleTrackings, vehicleId, {
              coordinates: event.lngLat,
              vehicleTrackingCoordinateGroupIndex: coordinateGroupIndex,
            }),
          );
        }
      });

      map.on('styledata', () => handleRouteMapVehicleTrackingsFeatureState(map, vehicleTrackings));
    });
  }, [map, dispatch, vehicleTrackings]);

  useEffect(() => {
    handleRouteMapVehicleTrackingsFeatureState(map, vehicleTrackings);
  }, [map, vehicleTrackings]);

  return (
    <>
      <RouteTimelineMapVehicleTrackingsGLSource geoJSON={geoJSON} isPlaybackMode={isPlaybackMode} />
      {isYRoute ? <YRouteMapVehicleTrackingsGLPopup /> : <RouteTimelineMapVehicleTrackingsGLPopupResolver />}
    </>
  );
}
