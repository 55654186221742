import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { loadRouteImportStatuses } from '../../ducks';
import { Resolver } from '../../../core/components';
import { PageLoading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { getQueryParams } from '../../../utils/services/queryParams';
import translate from '../../../core/services/translate';
import RouteImportStatusesPage from './RouteImportStatusesPage';
import { LIMIT_PER_PAGE } from '../../../core/constants';
import { DuckFunction } from '../../../contracts/ducks';

interface Props extends RouteComponentProps {
  loadRouteImportStatuses: DuckFunction<typeof loadRouteImportStatuses>;
}

class RouteImportStatusesPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      loadRouteImportStatuses,
      location: { search },
    } = this.props;
    const { routeImportId, haulerRouteImportStatusTypeId, page = 1 } = getQueryParams(search);
    return loadRouteImportStatuses(routeImportId, haulerRouteImportStatusTypeId, page, LIMIT_PER_PAGE);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('routeImport.routeImportStatuses')}</DocumentTitle>
        <Resolver
          successComponent={RouteImportStatusesPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/route-import"
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = { loadRouteImportStatuses };

export default connect(undefined, mapDispatchToProps)(RouteImportStatusesPageResolver);
