import { map, orderBy, replace } from 'lodash-es';

import { httpInsightsReporting } from '../../core/services/http';
import transformLoadInsightReports from './transformLoadInsightReports';
import paramsSerializer from '../../utils/services/paramsSerializer';
import { FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';

export const loadFleetInsightReports = (
  vendorId: number,
  date: Date | string,
  dashboardInsightTypeIds: string,
  vehicleTypeId: number,
  daysOfOperation: any[],
  filtersPreferencesIds: FiltersPreferencesIds,
) =>
  httpInsightsReporting
    .get('/reporting/getInsightsReports/fleet', {
      params: {
        vendorId,
        date,
        dashboardInsightTypeIds,
        vehicleTypeId,
        daysOfOperation,
        vehicleTypeIdsCSV: filtersPreferencesIds.vehicleTypeIdsCSV,
        serviceZoneIdsCSV: filtersPreferencesIds.serviceZoneIdsCSV,
        supervisorIdsCSV: filtersPreferencesIds.supervisorIdsCSV,
      },
      paramsSerializer,
    } as any)
    .then(response => transformLoadInsightReports(response.data));

export const loadWasteAuditInsightReports = (
  vendorId: number,
  date: Date | string,
  dashboardInsightTypeIds: string,
  vehicleTypeId: number,
  daysOfOperation: any[],
  filtersPreferencesIds: FiltersPreferencesIds,
) =>
  httpInsightsReporting
    .get('/reporting/getInsightsReports/wasteAudit', {
      params: {
        vendorId,
        date,
        dashboardInsightTypeIds,
        vehicleTypeId,
        daysOfOperation,
        vehicleTypeIdsCSV: filtersPreferencesIds.vehicleTypeIdsCSV,
        serviceZoneIdsCSV: filtersPreferencesIds.serviceZoneIdsCSV,
        supervisorIdsCSV: filtersPreferencesIds.supervisorIdsCSV,
      },
      paramsSerializer,
    } as any)
    .then(response => transformLoadInsightReports(response.data));

export const loadStreetSweepingInsightReports = (
  vendorId: number,
  date: Date | string,
  dashboardInsightTypeIds: string,
  vehicleTypeId: number,
  daysOfOperation: any[],
  filtersPreferencesIds: FiltersPreferencesIds,
) =>
  httpInsightsReporting
    .get('/reporting/getInsightsReports/streetSweeping', {
      params: {
        vendorId,
        date,
        dashboardInsightTypeIds,
        vehicleTypeId,
        daysOfOperation,
        vehicleTypeIdsCSV: filtersPreferencesIds.vehicleTypeIdsCSV,
        serviceZoneIdsCSV: filtersPreferencesIds.serviceZoneIdsCSV,
        supervisorIdsCSV: filtersPreferencesIds.supervisorIdsCSV,
      },
      paramsSerializer,
    } as any)
    .then(response => transformLoadInsightReports(response.data));

export const loadSnowPlowInsightReports = (
  vendorId: number,
  date: Date | string,
  dashboardInsightTypeIds: string,
  vehicleTypeId: number,
  daysOfOperation: any[],
  filtersPreferencesIds: FiltersPreferencesIds,
) =>
  httpInsightsReporting
    .get('/reporting/getInsightsReports/snowPlow', {
      params: {
        vendorId,
        date,
        dashboardInsightTypeIds,
        vehicleTypeId,
        daysOfOperation,
        vehicleTypeIdsCSV: filtersPreferencesIds.vehicleTypeIdsCSV,
        serviceZoneIdsCSV: filtersPreferencesIds.serviceZoneIdsCSV,
        supervisorIdsCSV: filtersPreferencesIds.supervisorIdsCSV,
      },
      paramsSerializer,
    } as any)
    .then(response => transformLoadInsightReports(response.data));

export const getOrderedInsightReport = (reports: any[], insightDashboardPreferences: any[]) => {
  return orderBy(
    map(reports, report => ({
      ...report,
      orderNo: insightDashboardPreferences.filter(insightDashboardPreference =>
        insightDashboardPreference.reportType === report.reportType.includes('StreetSweeping')
          ? replace(report.reportType, 'StreetSweeping', 'StreetSweeper')
          : report.reportType,
      )[0].orderNo,
    })),
    ['orderNo'],
  );
};
