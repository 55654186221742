import React, { Component } from 'react';

import { connect } from 'react-redux';
import { push, Push } from 'connected-react-router';
import { reset } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../../store';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { DuckFunction } from '../../../../contracts/ducks';
import { getQueryParams, createUrl } from '../../../../utils/services/queryParams';
import { loadRouteTrackerIssuesSummary } from '../../../ducks';
import { loadVehicleTypesForVendor } from '../../../../fleet/ducks';
import { Message, Panel, PanelSection, PanelSectionGroup } from '../../../../core/components/styled';
import {
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../../common/components/styled';
import { RouteTrackerForm } from '../../forms';
import { Table } from '../../../../core/components';
import RouteIssuesReportedSummaryTableRow from './RouteIssuesReportedSummaryTableRow';
import translate from '../../../../core/services/translate';

interface Props extends RouteComponentProps {
  isLoading: boolean;
  issuesReportedSummary?: any[];
  loadRouteTrackerIssuesSummary: DuckFunction<typeof loadRouteTrackerIssuesSummary>;
  push: Push;
  reset: (form: string) => void;
  vendorId: number;
}

class RouteIssuesReportedSummaryPage extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { vendorId, location, loadRouteTrackerIssuesSummary, reset } = this.props;

    if (location.search !== prevProps.location.search) {
      const { searchTerm, startDate, endDate, vehicleTypeIds, routeStatusTypeIds } = getQueryParams(location.search);
      loadRouteTrackerIssuesSummary(vendorId, searchTerm, vehicleTypeIds, routeStatusTypeIds, startDate, endDate);
    }
    reset('routeTracker');
  }

  handleSubmit = ({ date, vehicleTypeIds, searchTerm, routeStatusTypeIds }: any) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    const { from, to } = date;
    push(
      createUrl(pathname, search, {
        startDate: from,
        endDate: to,
        routeStatusTypeIds: routeStatusTypeIds && routeStatusTypeIds.toString(),
        vehicleTypeIds: vehicleTypeIds && vehicleTypeIds.toString(),
        searchTerm,
      }),
    );
  };

  handleBackAction = () => {
    const {
      push,
      location: { search },
    } = this.props;
    const {
      agingIntervalIds,
      endDate,
      groupIds,
      isSnowPlowRoute,
      isStreetSweeperRoute,
      priorityTypeIds,
      routeStatusTypeIds,
      searchTerm,
      serviceZones,
      startDate,
      supervisors,
      vehicleTypeIds,
    } = getQueryParams(search);

    const searchParams = {
      agingIntervalIds,
      endDate,
      groupIds,
      priorityTypeIds,
      routeStatusTypeIds,
      searchTerm,
      serviceZones,
      startDate,
      supervisors,
      vehicleTypeIds,
    };

    const routeUrl = isSnowPlowRoute
      ? '/routes/snow-tracker'
      : isStreetSweeperRoute
      ? '/routes/sweeper-tracker'
      : '/routes/route-tracker';

    return push(createUrl(routeUrl, undefined, searchParams));
  };

  render() {
    const {
      isLoading,
      issuesReportedSummary,
      location: { search },
    } = this.props;
    const issuesSummaryTableCells = [
      { name: 'customerName', label: translate('common.customer'), width: '25%', sortable: true },
      { name: 'routeName', label: translate('routes.route'), width: '20%', sortable: true },
      { name: 'timeStamp', label: translate('common.date'), width: '10%', sortable: true },
      { name: 'pickupExceptionTypeName', label: translate('insights.issuesReported'), width: '17%', sortable: true },
      { name: 'image', label: translate('common.image'), width: '8%', align: 'center' },
      { name: 'isServiceCompleted', label: translate('common.serviceCompleted'), width: '12%' },
      { name: 'email', label: translate('common.email'), width: '8%', align: 'center' },
    ];

    const { isSnowPlowRoute, isStreetSweeperRoute } = getQueryParams(search);

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButtonAction id="back-button" onClick={this.handleBackAction}>
                <PageBackButtonIcon />
              </PageBackButtonAction>

              <PageTitle>{translate('dashboard.issuesReported')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoading}>
            <RouteTrackerForm
              onSubmit={this.handleSubmit}
              isVehicleTypeFilterVisible={!isSnowPlowRoute && !isStreetSweeperRoute}
            />
            <PanelSection>
              {issuesReportedSummary?.length ? (
                <Table
                  cells={issuesSummaryTableCells}
                  rows={issuesReportedSummary}
                  rowComponent={RouteIssuesReportedSummaryTableRow}
                />
              ) : (
                <Message padding="sMedium">{translate('insights.noReportsFound')}</Message>
              )}
            </PanelSection>
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.routes.routeTrackerIssuesSummary.isLoading,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  issuesReportedSummary: state.routes.routeTrackerIssuesSummary.routeTrackerIssuesSummary,
});

const mapDispatchToProps = {
  loadRouteTrackerIssuesSummary,
  loadVehicleTypesForVendor,
  push,
  reset,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteIssuesReportedSummaryPage));
