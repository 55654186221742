import { arrayPush, Field, FieldArray, InjectedFormProps, reduxForm, WrappedFieldArrayProps } from 'redux-form';
import { useDispatch } from 'react-redux';

import { ActionButtonTooltip, Input } from 'src/core/components';
import { isRequiredTrimmedValue } from 'src/utils/services/validator';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  ModalFixedFooter,
  PanelSection,
  PanelSectionSubTitle,
  TableActionButton,
  Text,
} from '../../../core/components/styled';
import translate from '../../../core/services/translate';

interface Receptients {
  customerEmail: string;
  customerName: string;
}

type RowProps = WrappedFieldArrayProps<Receptients> & {};

const SendBillFormRow = ({ fields }: RowProps) => {
  return fields.map((field, index) => (
    <Grid key={`${index}-email-recepient`}>
      <GridColumn width="40%">
        <Field
          name={`${field}.customerName`}
          component={Input}
          validate={[isRequiredTrimmedValue]}
          placeholder={translate('common.name')}
        />
      </GridColumn>
      <GridColumn width="40%">
        <Field
          name={`${field}.customerEmail`}
          component={Input}
          validate={[isRequiredTrimmedValue]}
          placeholder={translate('common.email')}
        />
      </GridColumn>
      <GridColumn width="20%">
        <TableActionButton onClick={() => fields.remove(index)} id={`${field}-delete-button`}>
          <ActionButtonTooltip icon="delete" tooltip="removeRecipient" />
        </TableActionButton>
      </GridColumn>
    </Grid>
  ));
};

interface PropsWithoutReduxForm {
  onCancel: () => void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const SendBill = ({ handleSubmit, onCancel }: Props) => {
  const dispatch = useDispatch();
  const handleAddNewRate = () => dispatch(arrayPush('sendBillForm', 'emailRecipients', { name: '', email: '' }));

  return (
    <form onSubmit={handleSubmit}>
      <PanelSection padding="medium" vertical>
        <PanelSectionSubTitle margin="no">{translate('finance.emailRecipients')}:</PanelSectionSubTitle>
        <Grid margin="medium">
          <GridColumn width="40%">
            <Text>{translate('common.contact.contactName')}</Text>
          </GridColumn>
          <GridColumn width="40%">
            <Text>{translate('common.email')}</Text>
          </GridColumn>
        </Grid>
        <Grid flexDirection="column" margin="no medium no medium">
          <FieldArray name="emailRecipients" component={SendBillFormRow as any} rerenderOnEveryChange />
        </Grid>
        <Grid margin="small medium medium medium">
          <ButtonSet margin="no" align="center">
            <Button type="button" color="primary" line onClick={handleAddNewRate} margin="no no no xSmall">
              {`+ ${translate('common.new')}`}
            </Button>
          </ButtonSet>
        </Grid>
      </PanelSection>

      <ModalFixedFooter isShadowed>
        <Button type="button" color="secondary" margin="no xSmall" onClick={() => onCancel()}>
          {translate('common.cancel')}
        </Button>
        <Button type="submit" color="primary" margin="no xSmall" id="send-button">
          {translate('common.send')}
        </Button>
      </ModalFixedFooter>
    </form>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  form: 'sendBillForm',
  enableReinitialize: true,
})(SendBill);
