import React, { ChangeEvent, PureComponent } from 'react';

import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { VehicleTypes } from './../interfaces/VehicleTypes';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  withTechnicalName?: boolean;
  vehicleTypes: VehicleTypes[];
}

class VehicleTypesForExceptionConfigurationDropdown extends PureComponent<Props> {
  onChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
      withTechnicalName,
      vehicleTypes,
    } = this.props;

    const vehicleTypesOptions = vehicleTypes.map(vehicleType => ({
      label: vehicleType.name,
      value: withTechnicalName ? vehicleType.technicalName : vehicleType.id,
    }));

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('vehicles.vehicleType') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('vehicles.vehicleType') : undefined),
          options: vehicleTypesOptions,
          ...dropdownProps,
        }}
      />
    );
  }
}

export default VehicleTypesForExceptionConfigurationDropdown;
