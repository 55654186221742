import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Rate, RateType, UOM } from 'src/finance/interfaces/ServiceRates';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import {
  loadServiceRates as doLoadServiceRates,
  loadRateTypes as doLoadRateTypes,
  loadUOMTypes as doLoadUOMs,
  saveServiceRates as doSaveServiceRates,
} from 'src/finance/services/serviceRates';

// Actions
const START_LOAD_RATES = 'finance/service/rates/START_LOAD_RATES';
const COMPLETE_LOAD_RATES = 'finance/service/rates/COMPLETE_LOAD_RATES';
const FAIL_LOAD_RATES = 'finance/service/rates/FAIL_LOAD_RATES';
const START_SAVE_RATES = 'finance/service/rates/START_SAVE_RATES';
const COMPLETE_SAVE_RATES = 'finance/service/rates/COMPLETE_SAVE_RATES';
const FAIL_SAVE_RATES = 'finance/service/rates/FAIL_SAVE_RATES';
const START_LOAD_RATE_TYPES = 'finance/service/rates/START_LOAD_RATE_TYPES';
export const COMPLETE_LOAD_RATE_TYPES = 'finance/rateCode/COMPLETE_LOAD_RATE_TYPES';
export const FAIL_LOAD_RATE_TYPES = 'finance/service/rates/FAIL_LOAD_RATE_TYPES';
const START_LOAD_RATE_UOMs = 'finance/service/rates/START_LOAD_RATE_UOMs';
export const COMPLETE_LOAD_UOMs = 'finance/rateCode/COMPLETE_LOAD_UOMs';
export const FAIL_LOAD_UOMs = 'finance/rateCode/FAIL_LOAD_UOMs';
export const ADD_RATE = 'finance/rateCode/ADD_RATE';
export const DISCARD_RATE = 'finance/rateCode/DISCARD_RATE';
const RESET = 'finance/service/RESET';

interface State {
  isLoading: boolean;
  isSaving: boolean;
  rates: Rate[];
  UOMs: UOM[];
  rateTypes: RateType[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  isSaving: false,
  rates: [],
  UOMs: [],
  rateTypes: [],
};

export const getEmptyRate = (): Rate => ({
  amount: 0,
  description: undefined,
  id: -1,
  isManual: true,
  overridable: true,
  quantity: undefined,
  rate: undefined,
  status: 'inactive',
  rateCodeId: undefined,
  uomId: undefined,
});

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_RATES:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_RATES:
      return update(state, {
        $merge: {
          isLoading: false,
          rates: action.rates,
        },
      });

    case FAIL_LOAD_RATES:
      return update(state, {
        $merge: {
          isLoading: false,
          rates: [],
        },
      });

    case START_SAVE_RATES:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE_RATES:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE_RATES:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_LOAD_RATE_TYPES:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_RATE_TYPES:
      return update(state, {
        $merge: {
          isLoading: true,
          rateTypes: action.rateTypes,
        },
      });

    case FAIL_LOAD_RATE_TYPES:
      return update(state, {
        $merge: {
          isLoading: false,
          rateTypes: [],
        },
      });

    case START_LOAD_RATE_UOMs:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_UOMs:
      return update(state, {
        $merge: {
          isLoading: false,
          UOMs: action.UOMs,
        },
      });

    case FAIL_LOAD_UOMs:
      return update(state, {
        $merge: {
          isLoading: false,
          UOMs: [],
        },
      });

    case ADD_RATE:
      return update(state, {
        rates: { $push: [action.rate] },
      });

    case DISCARD_RATE:
      return update(state, {
        rates: {
          $splice: [[action.rateIndex, 1]],
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

const startLoadRates = () => ({
  type: START_LOAD_RATES,
});

const completeLoadRates = (rates: Rate[]) => ({
  type: COMPLETE_LOAD_RATES,
  rates,
});

const failLoadRates = () => ({
  type: FAIL_LOAD_RATES,
});

const startLoadRateTypes = () => ({
  type: START_LOAD_RATE_TYPES,
});

const completeLoadRateTypes = (rateTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD_RATE_TYPES,
  rateTypes,
});

const failLoadRateTypes = () => ({
  type: FAIL_LOAD_RATE_TYPES,
});

const startLoadUOMs = () => ({
  type: START_LOAD_RATE_UOMs,
});

const completeLoadUOMs = (UOMs: []) => ({
  type: COMPLETE_LOAD_UOMs,
  UOMs,
});

const failLoadUOMs = () => ({
  type: FAIL_LOAD_UOMs,
});

const addRate = (rate: Rate) => ({
  type: ADD_RATE,
  rate,
});

const discardRate = (rateIndex: number) => ({
  type: DISCARD_RATE,
  rateIndex,
});

const startSaveRates = () => ({
  type: START_SAVE_RATES,
});

const completeSaveRates = () => ({
  type: COMPLETE_SAVE_RATES,
});

const failSaveRates = () => ({
  type: FAIL_SAVE_RATES,
});

export const loadServiceRates = (headerId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadRates());
  const loadServiceRatesPromise = doLoadServiceRates(headerId);
  loadServiceRatesPromise
    .then((rates: any) => {
      dispatch(completeLoadRates(rates));
    })
    .catch(() => dispatch(failLoadRates()));
  return loadServiceRatesPromise;
};

export const addNewRate = () => (dispatch: Dispatch) => dispatch(addRate(getEmptyRate()));

export const discardNewRate = (rateIndex: number) => (dispatch: Dispatch) => dispatch(discardRate(rateIndex));
export const loadRateTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoadRateTypes());

  const loadRateTypesPromise = doLoadRateTypes();
  loadRateTypesPromise
    .then(rateTypes => dispatch(completeLoadRateTypes(rateTypes)))
    .catch(() => dispatch(failLoadRateTypes()));

  return loadRateTypesPromise;
};

export const loadUOMs = () => (dispatch: Dispatch) => {
  dispatch(startLoadUOMs());

  const loadUOMsPromise = doLoadUOMs();

  loadUOMsPromise.then(UOMs => dispatch(completeLoadUOMs(UOMs))).catch(() => dispatch(failLoadUOMs()));

  return loadUOMsPromise;
};

export const saveServiceRates = (headerId: number, rates: Rate[]) => (dispatch: Dispatch) => {
  dispatch(startSaveRates());

  const saveServiceRatesPromise = doSaveServiceRates(headerId, rates);
  saveServiceRatesPromise
    .then(() => {
      dispatch(completeSaveRates());
    })
    .catch(() => dispatch(failSaveRates()));

  return saveServiceRatesPromise;
};
