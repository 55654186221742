import { Fragment, PureComponent } from 'react';

import { camelCase, map } from 'lodash-es';

import { PICKUP_STATUSES } from '../../../../../common/constants';
import { ActionButtonTooltip, ThumbnailSet } from '../../../../../core/components';
import { date, time } from '../../../../../utils/services/formatter';
import { EmailRouteImagesModal, RouteImagesModal } from '../../../modals';
import {
  Grid,
  GridColumn,
  Label,
  TableActionButton,
  TableCell,
  TableRow,
  Text,
} from '../../../../../core/components/styled';
import { PICKUP_ISSUE_TYPES, PICKUP_STATUS_LABEL_COLORS } from '../../../../constants';
import { RouteImage } from '../../../../interfaces/RouteImages';
import { WASTE_AUDIT_EXCEPTION_TYPES, WASTE_AUDIT_LOCATION_STATUS_TYPES } from 'src/routes/constants';
import { WASTE_AUDIT_ID } from 'src/fleet/constants';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import translate from 'src/core/services/translate';

type Props = RouteImage & {
  vehicleTypeId: number;
  routeId: number;
};

interface State {
  images: Array<{
    contaminationSubTypesName?: string;
    customerName: string;
    imageBlobId: string;
    imageUrl: string;
    rawImageUrl?: string;
    issueName: string;
    locationName: string;
    timeStamp: Date | string;
  }>;
  imageUrls: string[];
  isRouteImagesModalOpen: boolean;
  isEmailRouteImagesModalOpen: boolean;
}

class RouteImagesTableRow extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { customerName, customerLocationAddress, pickupIssueReportedReasonTypeName, timeStamp } = props;

    const images = map(props.images, image => ({
      customerName,
      timeStamp,
      locationName: customerLocationAddress,
      issueName: pickupIssueReportedReasonTypeName,
      imageUrl: image.url,
      rawImageUrl: image?.rawUrl || image.url,
      imageBlobId: image.blobId,
    }));

    const imageUrls = map(props.images, 'url');

    this.state = {
      images,
      imageUrls,
      isRouteImagesModalOpen: false,
      isEmailRouteImagesModalOpen: false,
    };
  }

  openRouteImagesModal = () => {
    this.setState({ isRouteImagesModalOpen: true });
  };

  closeRouteImagesModal = () => {
    this.setState({ isRouteImagesModalOpen: false });
  };

  openEmailRouteImagesModal = () => {
    this.setState({ isEmailRouteImagesModalOpen: true });
  };

  closeEmailRouteImagesModal = () => {
    this.setState({ isEmailRouteImagesModalOpen: false });
  };

  render() {
    const {
      stopNumber,
      customerName,
      customerLocationAddress,
      timeStamp,
      routeId,
      routeLocationId,
      routeLocationStatusId,
      vehicleTypeId,
      pickupExceptionId,
      pickupExceptionType,
      pickupExceptionSubType,
      images: initialImages,
    } = this.props;

    const { images, imageUrls, isRouteImagesModalOpen, isEmailRouteImagesModalOpen } = this.state;

    const routeImage = {
      images: this.props.images,
      routeId,
      vehicleTypeId,
      routeLocationId,
      routeLocationDetailPickupExceptionId: pickupExceptionId,
    };

    let pickupExceptionName;
    if (pickupExceptionId) {
      if (vehicleTypeId === WASTE_AUDIT_ID) {
        if (pickupExceptionSubType) {
          pickupExceptionName = WASTE_AUDIT_EXCEPTION_TYPES[pickupExceptionSubType.id].name;
        } else {
          pickupExceptionName = WASTE_AUDIT_LOCATION_STATUS_TYPES[pickupExceptionType.id].name;
        }
      } else {
        pickupExceptionName = PICKUP_ISSUE_TYPES[pickupExceptionType.id]
          ? PICKUP_ISSUE_TYPES[pickupExceptionType.id].name
          : undefined;
      }
    }

    if (pickupExceptionType?.technicalName && pickupExceptionName == null) {
      pickupExceptionName = translate(`dashboard.${camelCase(pickupExceptionType.technicalName)}`);
    }

    if (initialImages[0]?.imageTimingType) {
      pickupExceptionName = translate(
        createTranslationKey(initialImages[0]?.imageTimingType.technicalName, 'vendors.pickupIssueReasonTypes'),
      );
    }

    return (
      <Fragment>
        <TableRow id={`route-${routeId}-images-table-row`}>
          <TableCell id={`route-${routeId}-images-stop-number-cell`} width="10%">
            {stopNumber}
          </TableCell>
          <TableCell id={`route-${routeId}-images-customer-cell`} width="25%">
            <Grid>
              <GridColumn width="100%" padding="no">
                <Text block weight="medium" margin="no no xxSmall">
                  {customerName}
                </Text>
                <Text block size="small">
                  {customerLocationAddress}
                </Text>
              </GridColumn>
            </Grid>
          </TableCell>

          <TableCell id={`route-${routeId}-images-timestamp-cell`} width="15%">
            <Grid>
              <GridColumn width="100%" padding="no">
                <Text block margin="no no xxSmall">
                  {date(timeStamp)}
                </Text>
                <Text block size="small">
                  {time(timeStamp)}
                </Text>
              </GridColumn>
            </Grid>
          </TableCell>

          <TableCell id={`route-${routeId}-images-exception-cell`} width="15%">
            <Text block size="modal">
              {pickupExceptionName}
            </Text>
          </TableCell>

          <TableCell id={`route-${routeId}-images-status-cell`} width="15%">
            {!!routeLocationStatusId && (
              <Label color={PICKUP_STATUS_LABEL_COLORS[routeLocationStatusId.toString()]}>
                {PICKUP_STATUSES[routeLocationStatusId].name}
              </Label>
            )}
          </TableCell>

          <TableCell id={`route-${routeId}-images-image-cell`} width="10%">
            <ThumbnailSet
              id={`route-${routeId}-images-thumbnail-set-button`}
              images={imageUrls}
              onClick={this.openRouteImagesModal}
            />
          </TableCell>

          <TableCell id={`route-${routeId}-images-options-cell`} width="10%" align="right">
            <TableActionButton
              id={`route-${routeId}-images-email-route-images-button`}
              onClick={this.openEmailRouteImagesModal}
            >
              <ActionButtonTooltip icon="email" tooltip="emailImages" />
            </TableActionButton>
          </TableCell>
        </TableRow>

        {!!isRouteImagesModalOpen && <RouteImagesModal images={images} closeModal={this.closeRouteImagesModal} />}

        {!!isEmailRouteImagesModalOpen && (
          <EmailRouteImagesModal routeImage={routeImage} closeModal={this.closeEmailRouteImagesModal} />
        )}
      </Fragment>
    );
  }
}

export default RouteImagesTableRow;
