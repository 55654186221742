import React from 'react';
import { Overlay, Content, Title } from './styled/ProgressPopup';
import { ProgressBar } from '.';

interface Props {
  isVisible: boolean;
  message: string;
  progress: number;
}

const ProgressPopup = ({ isVisible, message, progress }: Props) =>
  !isVisible ? null : (
    <Overlay>
      <Content>
        <Title>{message}</Title>
        <ProgressBar progress={progress} />
      </Content>
    </Overlay>
  );

export default ProgressPopup;
