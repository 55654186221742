import { find, map, mapKeys } from 'lodash-es';
import moment from 'moment';
import translate from '../../core/services/translate';

export const transformInvoices = (invoices: any) => {
  return map(invoices, i => ({
    ...i,
    paymentAmount: i.paymentAmout,
    paymentMethod: i.paymentMethod ? getPaymentMethodName(i.paymentMethod) : null,
    receivedDate: i.receivedOn ? INVOICE_DATE_MODIFIER(i.receivedOn) : null,
    paymentDate: i.checkDate ? INVOICE_DATE_MODIFIER(i.checkDate) : null,
  }));
};

const INVOICE_DATE_MODIFIER = (data: string) => moment(data).format('MM/DD/YYYY');

const PAYMENT_METHODS = mapKeys(
  [
    { id: 'CK', name: translate('invoice.paymentMethod.check') },
    { id: 'ZC', name: translate('invoice.paymentMethod.check') },
    { id: 'HC', name: translate('invoice.paymentMethod.hbcheck') },
    { id: 'EP', name: translate('invoice.paymentMethod.ach') },
  ],
  'id',
);

const getPaymentMethodName = (paymentMethod: string) => {
  const isPaymentMethodExist = find(PAYMENT_METHODS, { id: paymentMethod });
  if (isPaymentMethodExist) return PAYMENT_METHODS[paymentMethod].name;
  else return paymentMethod;
};
