import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import translate from 'src/core/services/translate';
import { doNotShowRouteSequenceInstructions, hideRouteSequenceInstructions } from 'src/routes/ducks';
import RouteSequenceInstructionsForm from '../../forms/RouteSequenceInstructionsForm';
import { InstructionsBackButtonIcon, RouteSequenceInstructionsPanel } from '../../styled/RouteMap';

const RouteSequenceInstructions = () => {
  const [step, setStep] = useState(1);

  const dispatch = useDispatch();

  const onSubmit = (formData: any) => {
    const { doNotShowAgain } = formData || false;
    return doNotShowAgain ? doNotShowRouteSequenceInstructions()(dispatch) : hideRouteSequenceInstructions()(dispatch);
  };

  const decreaseStep = () => step > 1 && setStep(step - 1);
  const increaseStep = () => step < 3 && setStep(step + 1);

  return (
    <>
      <RouteSequenceInstructionsForm initialValues={{ doNotShowAgain: false }} onSubmit={onSubmit} />
      <RouteSequenceInstructionsPanel>
        <Button text onClick={decreaseStep} disabled={step <= 1}>
          <InstructionsBackButtonIcon disabled={step <= 1} />
        </Button>
        <Box width="80%" display="flex" alignItems="center">
          <Text block size="large" margin="no xxLarge" align="center" weight="medium">
            {translate(`routes.routeSequenceInstructions.step${step}`)}
          </Text>
        </Box>
        <Button text onClick={increaseStep} disabled={step >= 3}>
          <InstructionsBackButtonIcon forward disabled={step >= 3} />
        </Button>
      </RouteSequenceInstructionsPanel>
    </>
  );
};

export default RouteSequenceInstructions;
