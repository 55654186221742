import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { loadingOverlay } from '../../styles';
import { mapper, sizeMapper } from '../../../utils/styles';
import Icon from '../Icon';
import { FormGroupClear } from './FormGroupClear';

export const PanelSearchIcon = styled(Icon).attrs({ icon: 'search' })`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  color: ${props => props.theme.gray};
`;

export const PanelSearchInput = styled.input<{ margin?: string; minWidth?: string }>`
  width: 100%;
  flex: 1;
  padding: 0;
  background-color: transparent;
  outline: none;
  border: none;
  line-height: 22px;
  font-size: 18px;

  ${props =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `}

  &::placeholder {
    text-transform: capitalize;
    color: ${props => props.theme.grayDark};
    font-size: 16px;
  }
`;

export const PanelSearch = styled.div<{ borderBottom?: boolean; padding?: string; margin?: string }>`
  display: flex;
  align-items: center;
  padding: ${p => sizeMapper(p.padding, 'xxSmall no')};
  margin: ${p => sizeMapper(p.margin, 'no')};

  ${p =>
    p.borderBottom &&
    css`
      border-bottom: 1px solid ${p.theme.grayLight};
    `}

  & ${FormGroupClear} {
    top: 0;
    right: 0;
    transform: translate(-10px, -50%);
  }
`;

export const PanelSectionLoading = styled.div<{ minHeight?: number }>`
  position: relative;
  padding: 30px 0;
  ${loadingOverlay('24px')};

  ${props =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight}px;
    `};
`;

interface PanelSectionProps {
  centered?: boolean;
  dark?: boolean;
  lightDark?: boolean;
  display?: string;
  flexDirection?: string;
  fluid?: boolean;
  fullHeight?: boolean;
  height?: string;
  isLoading?: boolean;
  margin?: string;
  minHeight?: number;
  noBackground?: boolean;
  noBackgroundColorOnLoading?: boolean;
  noBoxShadow?: boolean;
  padding?: string;
  position?: string;
  positionLeft?: string;
  sticky?: boolean;
  tabletPadding?: string;
  vertical?: boolean;
  width?: string;
  withBorder?: boolean;
  withTopAndBottomBorder?: boolean;
  withBorderGreen?: boolean;
  withBoxShadow?: boolean;
  withTopInnerBoxShadow?: boolean;
  disableAllContent?: boolean;
}

export const PanelFooter = styled.div<PanelSectionProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1140px;
  padding: ${props => sizeMapper(props.padding, 'no medium')};
`;

export const PanelSectionTitle = styled.h2<PanelSectionProps>`
  margin: ${props => sizeMapper(props.margin, 'no no medium')};
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 24px;
`;

export const PanelSectionSubTitle = styled.h3<PanelSectionProps>`
  margin: ${props => sizeMapper(props.margin, 'no no small')};
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 18px;
`;

export const PanelSection = styled.div<PanelSectionProps>`
  display: flex;
  position: relative;
  padding: ${props => sizeMapper(props.padding, 'no')};

  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `};

  ${props =>
    props.withTopAndBottomBorder &&
    css`
      border-top: 1px solid ${props => props.theme.gray};
      border-bottom: 1px solid ${props => props.theme.gray};
    `}
  ${props =>
    props.tabletPadding &&
    css`
      @media (max-width: ${props => props.theme.desktopBreakpoint}) {
        padding: ${sizeMapper(props.tabletPadding, 'no')};
      }
    `};

  ${props =>
    props.display &&
    css`
      display: ${props.display};
    `};

  ${props =>
    props.fullHeight &&
    css`
      height: 100%;
    `};

  ${props =>
    props.withBorder &&
    css`
      border-bottom: 1px solid ${props => props.theme.grayLight};
    `};

  ${props =>
    props.dark &&
    css`
      background-color: ${props => props.theme.grayLight};
    `};

  ${props =>
    props.lightDark &&
    css`
      background-color: ${props => props.theme.grayLighter};
    `};

  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
    `};

  ${props =>
    props.centered &&
    css`
      justify-content: ${props.vertical ? 'flex-start' : 'center'};
      align-items: ${props.vertical ? 'center' : 'flex-start'};
    `};

  ${props =>
    props.sticky &&
    css`
      @supports (position: sticky) {
        position: sticky;
        top: 0;
        z-index: 1100;
      }
    `};

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')};
    `};

  ${props =>
    props.withBoxShadow &&
    css`
      box-shadow: 0 4px 10px ${props => transparentize(0.95, props.theme.grayBase)};
    `};

  ${props =>
    props.withTopInnerBoxShadow &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 20px;
        pointer-events: none;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.03) 0%,
          rgba(0, 0, 0, 0.02) 30%,
          rgba(0, 0, 0, 0.01) 50%,
          transparent 100%
        );
      }
    `}

  ${props =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight}px;
    `};

  ${props =>
    props.disableAllContent &&
    css`
      pointer-events: none;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        background: ${transparentize(0.8, props.theme.grayBase)};
      }
    `}
`;

export const PanelSectionHeader = styled.div<PanelSectionProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: ${props => mapper(props.margin, { no: '0', small: '10px', medium: '20px', large: '30px' }, 'no no medium')};
`;

export const PanelSectionGroup = styled.div<PanelSectionProps>`
  position: ${props => (props.position && props.positionLeft ? props.position : 'relative')};
  left: ${props => props.positionLeft && props.positionLeft};
  padding: ${props => sizeMapper(props.padding, 'no')};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `};

  ${props =>
    props.withBorder &&
    css`
      border-right: 1px solid ${props => props.theme.grayLight};
    `};

  ${props =>
    props.isLoading &&
    css`
      min-height: 500px;
      ${loadingOverlay(
        '24px',
        props.noBackgroundColorOnLoading ? props.theme.grayLighter : undefined,
        undefined,
        undefined,
        props.position ? props.position : undefined,
      )};
    `};

  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
    `};

  ${props =>
    props.centered &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    `};

  ${props =>
    props.disableAllContent &&
    css`
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none !important;
        z-index: 1000;
        cursor: not-allowed;
        background: ${transparentize(0.9, props.theme.grayBase)};
      }
    `}
`;

export const Panel = styled.div<PanelSectionProps>`
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};
  background-color: #fff;
  box-shadow: 0 4px 10px ${props => transparentize(0.95, props.theme.grayBase)};

  ${props =>
    props.fluid &&
    css`
      width: 100%;
    `};

  ${props =>
    props.flexDirection &&
    css`
      display: flex;
      flex-direction: ${props.flexDirection};
    `};

  ${props =>
    props.noBackground &&
    css`
      background-color: transparent;
    `};

  ${props =>
    props.noBoxShadow &&
    css`
      box-shadow: none;
    `};

  .markerLabel {
    background: #fff;
    color: #000;
    padding: 2px 4px;
    border-radius: 5px;
    margin-top: 10px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -5px;
      left: calc(50%-5px);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
    }
  }
`;
