import React from 'react';
import mapboxgl from 'mapbox-gl';
import { Layer } from 'react-map-gl';

import { NEW_INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import IconWithTypes from 'src/common/interfaces/IconWithTypes';
import { ROUTE_SEQUENCE_MAP_CLUSTER_SOURCE } from './constants';

type Props = {
  map: mapboxgl.Map;
  showOrderNumbers: boolean;
  zoomLevel: number;
};

const RouteSequenceStopLayer: React.FC<Props & { iconType: IconWithTypes }> = ({
  iconType,
  showOrderNumbers,
  zoomLevel,
}) => {
  return (
    <>
      <Layer
        key={iconType.id}
        id={iconType.id}
        type="symbol"
        source={ROUTE_SEQUENCE_MAP_CLUSTER_SOURCE}
        filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'icon'], iconType.id]]}
        paint={{}}
        layout={{
          'symbol-z-order': 'source',
          'symbol-sort-key': 1,
          'icon-image': iconType.id,
          'icon-size': 1,
          'icon-allow-overlap': true,
        }}
      />

      <Layer
        key={iconType.id + 'orderNumber'}
        id={iconType.id + 'orderNumber'}
        type="symbol"
        minzoom={zoomLevel || 15}
        source={ROUTE_SEQUENCE_MAP_CLUSTER_SOURCE}
        filter={[
          'all',
          ['!', ['has', 'point_count']],
          ['==', ['get', 'icon'], iconType.id],
          ['!=', ['get', 'orderNo'], JOB_PENDING_OPTIMIZATION_ID],
        ]}
        paint={{
          'text-halo-color': showOrderNumbers ? '#00a599' : '#fff',
          'text-halo-width': 10,
          'text-color': showOrderNumbers ? '#fff' : '#000',
        }}
        layout={{
          'symbol-z-order': 'source',
          'text-field': showOrderNumbers ? ['get', 'newOrderNumber'] : ['get', 'orderNo'],
          'text-offset': [0, 1.25],
          'text-size': 15,
        }}
      />
    </>
  );
};

export default React.memo(function RouteSequenceStopsGLLayers(props: Props) {
  return (
    <>
      {NEW_INSIGHT_ICON_TYPES.map(iconType => (
        <RouteSequenceStopLayer key={iconType.id} iconType={iconType} {...props} />
      ))}
    </>
  );
});
