import React, { PureComponent, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadFiltersSettings } from 'src/common/ducks/filters';
import { loadReportingDataTrends } from '../../ducks';
import { loadVehicleTypesForVendor } from '../../../fleet/ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import ReportingDataTrendsPage from './ReportingDataTrendsPage';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  loadFiltersSettings: DuckFunction<typeof loadFiltersSettings>;
  loadReportingDataTrends: DuckFunction<typeof loadReportingDataTrends>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  userId: string;
  vendorId: number;
}

class ReportingDataTrendsPageResolver extends PureComponent<Props> {
  loadDependencies = async () => {
    const {
      loadFiltersSettings,
      loadReportingDataTrends,
      loadVehicleTypesForVendor,
      location: { search },
      userId,
      vendorId,
    } = this.props;
    const { reportType, endDate, startDate, vehicleTypeId, daysOfOperation } = getQueryParams(search);

    const defaultStartDate = moment(endDate, 'MM/DD/YYYY').subtract(2, 'weeks').format('MM/DD/YYYY');

    await loadFiltersSettings(vendorId, userId);

    return Promise.all([
      loadReportingDataTrends(
        vendorId,
        reportType,
        startDate || defaultStartDate,
        endDate,
        vehicleTypeId,
        daysOfOperation,
      ),
      loadVehicleTypesForVendor(vendorId),
    ]);
  };

  render() {
    const { reportType } = getQueryParams(this.props.location.search);

    return (
      <Fragment>
        <DocumentTitle>{translate(createTranslationKey(reportType, 'insights.reportTypes'))}</DocumentTitle>
        <Resolver
          successComponent={ReportingDataTrendsPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userId: currentUserIdSelector(state.account.login) as any,
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadReportingDataTrends, loadVehicleTypesForVendor, loadFiltersSettings };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportingDataTrendsPageResolver));
