import {
  DetailsListBodySection,
  DetailsListItemDescription,
  DetailsListItemTitle,
  Popover,
} from 'src/core/components/styled';
import { PopoverUnbilledCharges } from 'src/finance/components/styled/BillDetails';
import { RateCode } from 'src/finance/interfaces/UnbilledCharges';

type Props = {
  rateCodes: RateCode[];
};

const UnbilledChargesAmountPopover = ({ rateCodes }: Props) => (
  <Popover align="left">
    <PopoverUnbilledCharges>
      {rateCodes &&
        rateCodes.map((rateCode: RateCode, index: number) => (
          <DetailsListBodySection key={index} width="50%" padding="xSmall">
            <DetailsListItemTitle size="small">{rateCode.rateDescr}</DetailsListItemTitle>
            <DetailsListItemDescription size="medium">${rateCode.amount || 0}</DetailsListItemDescription>
          </DetailsListBodySection>
        ))}
    </PopoverUnbilledCharges>
  </Popover>
);

export default UnbilledChargesAmountPopover;
