import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';
import moment from 'moment';
import styled from 'styled-components';

import { GridColumn, Grid } from '../../../core/components/styled';
import { DateRangePicker, TypedField } from '../../../core/components';

const GridContainer = styled(Grid)`
  border-bottom: 1px solid ${p => p.theme.grayLight};
  padding-bottom: 5px;
`;

const ReleaseNotesManagementFilterForm: React.FC<InjectedFormProps<any, {}>> = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit} noValidate>
    <GridContainer>
      <GridColumn size="4/12">
        <TypedField
          name="dateRange"
          component={DateRangePicker}
          props={{ hasMarginLeft: 'smaller', margin: 'small no no' }}
        />
      </GridColumn>
    </GridContainer>
  </form>
);

export const releaseNotesDateRangeInitialValues = {
  from: moment().subtract(1, 'month').format('MM/DD/YYYY'),
  to: moment().add(1, 'month').format('MM/DD/YYYY'),
};

const mapStateToProps = () => ({
  initialValues: { dateRange: releaseNotesDateRangeInitialValues },
});

const formKey = 'releaseNotesManagementFilter';
export const releaseNotesFilterSelector = formValueSelector(formKey);

export default connect(mapStateToProps)(
  reduxForm<any, {}>({
    form: formKey,
    enableReinitialize: true,
  })(ReleaseNotesManagementFilterForm),
);
