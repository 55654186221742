import { map } from 'lodash-es';
import { MouseEvent, useRef, useState } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { useDispatch } from 'react-redux';
import { WrappedFieldArrayProps } from 'redux-form';

import {
  ACCOUNT_STATUSES,
  COMPLETED,
  IN_PROGRESS,
  PICKUP_STATUS_LABEL_COLORS,
  PLACED_ON_HOLD,
  ROUTE_PICKUP_TYPE_IDS,
  SCHEDULED,
} from 'src/routes/constants';
import { ActionButtonTooltip, Checkbox, DragHandle, Dropdown, TypedField } from 'src/core/components';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { formatStopAddress, getEditRouteStopsTableCellWidths } from './utils';
import { getMapBounds } from 'src/common/components/map/util';
import { getShortServiceName } from 'src/routes/services/transformRoute';
import { isUserManagerSelector } from 'src/vendors/ducks';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { JobStatusModalResolver, RouteLocationNotesModalResolver } from '../../../../modals';
import {
  Label,
  TableActionButton,
  TableCell,
  TableRow,
  Text,
  Container,
  GridColumn,
  Link,
} from 'src/core/components/styled';
import { loadRouteStops, loadRouteSummary, setRouteMapSelectedFeature, setRouteMapViewport } from 'src/routes/ducks';
import { OPTIMIZED_JOB_POSITION_ID } from 'src/core/constants/jobPositionOptions';
import { OrderNumberForm } from 'src/routes/components/forms';
import { PICKUP_STATUSES } from 'src/common/constants';
import { ROUTE_STOPS_FIELD_ARRAY_NAME } from 'src/routes/components/pages/routes/routePageSections/routeStops/RouteStopsForm';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { TOP } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import createPopover from 'src/core/services/createPopover';
import translate from 'src/core/services/translate';
import { JobPriorityTypesDropdown } from 'src/common/components';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';

export const EDIT_TABLE_ROW_HEIGHT = 120;

const isDeletable = (isManager: boolean, statusId: number) =>
  !isManager || (isManager && statusId !== COMPLETED && statusId !== IN_PROGRESS && statusId !== PLACED_ON_HOLD);

const tableCellWidths = getEditRouteStopsTableCellWidths();

const accountStatusOptions = map(ACCOUNT_STATUSES, ({ name, id }) => ({
  label: name,
  value: id,
}));

type RowProps = {
  change: (field: string, value: any) => void;
  deleteStop: (index: number) => void;
  field: RouteStop;
  fieldArrayKey: string;
  fieldIndex: number;
  filtersActive: boolean;
  handleChangeField: (fieldIndex: number) => void;
  handleOrderChange: (from: number, to: number) => void;
  newOptimizedRouteLocationsLength: number;
};

export const UnsortableEditRouteStopsTableRow: React.FC<WrappedFieldArrayProps & RowProps> = ({
  change,
  deleteStop,
  field,
  fieldArrayKey,
  fieldIndex,
  filtersActive,
  handleChangeField,
  handleOrderChange,
  newOptimizedRouteLocationsLength,
}) => {
  const dispatch = useDispatch();
  const closeOrderNumberPopover = useRef<() => void>();
  const [isJobStatusModalOpen, setJobStatusModalOpen] = useState(false);
  const [isRouteLocationNotesModalOpen, setRouteLocationNotesModalOpen] = useState(false);

  const vendorId = useSelector(currentVendorId);
  const isVendorManager = useSelector(state => isUserManagerSelector(state.vendors.users)) || false;
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { pickupTypes } = useSelector(state => state.routes.pickupTypes);
  const { reasonCodeTypes } = useSelector(state => state.common.reasonCodeTypes);
  const { equipmentTypes } = useSelector(state => state.common.equipmentTypes);
  const { equipmentSizes } = useSelector(state => state.common.equipmentSizes);
  const { wasteTypes } = useSelector(state => state.common.wasteTypes);
  const { routeStops } = useSelector(state => state.routes.routeStops);
  const isBillingFeatureActive = useSelector(state => billingFeatureStatusSelector(state.vendors.features.features));

  if (!routeSummary) return null;

  const equipmentType = equipmentTypes.find(equipmentType => equipmentType.id === field.equipmentTypeId);
  const equipmentSize = equipmentSizes.find(equipmentSize => equipmentSize.id === field.equipmentSizeId);
  const wasteTypeName = field.wasteMaterialTypeTechnicalName
    ? wasteTypes.find(wasteType => wasteType.technicalName === field.wasteMaterialTypeTechnicalName)?.name
    : field.wasteMaterialTypeId
    ? wasteTypes.find(wasteType => wasteType.id === field.wasteMaterialTypeId)?.name
    : translate('common.wasteTypes.unknown');

  const routePickupTypeOptions = (pickupTypes as any)[0].pickupTypes.map((pickupType: TechnicalType) => ({
    label: ROUTE_PICKUP_TYPE_IDS[pickupType.id].name,
    value: pickupType.id,
  }));
  const reasonCodeTypeOptions = reasonCodeTypes.map(reasonCode => ({
    label: reasonCode.name,
    value: reasonCode.id,
  }));

  const handleOpenJobStatusModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setJobStatusModalOpen(true);
  };

  const openOrderNumberPopover = (event: MouseEvent<HTMLButtonElement>, oldOrderNumber: number) => {
    event.stopPropagation();
    const routeStopsWithStopNumber = routeStops.filter(stop => stop.orderNo > 0);
    closeOrderNumberPopover.current = createPopover(
      event.target as HTMLElement,
      OrderNumberForm,
      {
        onSubmit: ({ orderNumber }: { orderNumber: string }) =>
          onOrderNumberFormSubmit(oldOrderNumber, Number(orderNumber)),
        maxOrderNumber: routeStopsWithStopNumber.length,
        initialValues: { orderNumber: oldOrderNumber },
      },
      { position: TOP },
    );
  };

  const onOrderNumberFormSubmit = (oldOrderNumber: number, newOrderNumber: number) => {
    handleOrderChange(oldOrderNumber - 1, newOrderNumber - 1);

    if (closeOrderNumberPopover?.current) closeOrderNumberPopover.current();
  };

  const handleCloseJobStatusModal = () => {
    loadRouteStops(vendorId, routeSummary.routeId)(dispatch);
    loadRouteSummary(vendorId, routeSummary.routeId)(dispatch);
    setJobStatusModalOpen(false);
  };

  const handleClick = (event: any) => {
    const isTableRowClicked = event ? event.target.parentNode.tagName === 'DIV' : false;

    if (isTableRowClicked) {
      const point = { latitude: field.binLatitude, longitude: field.binLongitude };
      const bounds = getMapBounds([point]);

      dispatch(setRouteMapViewport(bounds));
      dispatch(setRouteMapSelectedFeature(RouteMapFeature.routeStops, field.id));
    }
  };

  const address = formatStopAddress(field.locationName, field.street, field.streetNumber);

  const jobIsPendingOptimization =
    field.positionTypeId === OPTIMIZED_JOB_POSITION_ID || field.orderNo === JOB_PENDING_OPTIMIZATION_ID;
  const orderNumber = jobIsPendingOptimization ? JOB_PENDING_OPTIMIZATION_ID : fieldIndex + 1;

  const isCompleted = field.pickupStatusTypeId === COMPLETED;

  return (
    <>
      <TableRow
        alignItems="center"
        onClick={handleClick}
        height={EDIT_TABLE_ROW_HEIGHT}
        wrap="wrap"
        position="relative"
      >
        <TableCell width={tableCellWidths[0]}>
          {!newOptimizedRouteLocationsLength && (
            <TypedField
              name={`${fieldArrayKey}.isChecked`}
              component={Checkbox}
              props={{ block: true, size: 'small', disabled:isCompleted}}
            />
          )}
        </TableCell>

        <TableCell width={tableCellWidths[1]} align="center" padding="no">
          {!newOptimizedRouteLocationsLength && !filtersActive && !jobIsPendingOptimization && <DragHandle />}
        </TableCell>

        <TableCell width={tableCellWidths[2]}>
          {orderNumber > -1 && (
            <TableActionButton
              onClick={event =>
                !newOptimizedRouteLocationsLength ? openOrderNumberPopover(event, orderNumber) : undefined
              }
            >
              {orderNumber}
            </TableActionButton>
          )}
        </TableCell>

        <TableCell vertical align="center" width={tableCellWidths[3]}>
          <Text title={field.customerName} block weight="medium" margin="no no xxSmall" singleLine>
            {field.customerName}
          </Text>
          <Text title={address} weight="light" size="small" margin="no no xxSmall" singleLine>
            {address}
          </Text>
          {field.isTemporaryContainer && (
            <Text title={translate('routes.temporary')} block singleLine size="small" color="primary">
              {translate('routes.temporary')}
            </Text>
          )}
        </TableCell>

        <TableCell width={tableCellWidths[4]}>
          <GridColumn width="100%" padding="no">
            <Text
              title={getShortServiceName(field.serviceTypeId, equipmentType, equipmentSize) || field.serviceName}
              block
              singleLine
            >
              {getShortServiceName(field.serviceTypeId, equipmentType, equipmentSize) || field.serviceName}
            </Text>
            <Text title={wasteTypeName} block size="small" singleLine>
              {wasteTypeName}
            </Text>
          </GridColumn>
        </TableCell>

        <TableCell width={tableCellWidths[5]}>
          <Container>
            <TypedField
              name={`${fieldArrayKey}.accountTypeId`}
              component={Dropdown}
              normalize={Number}
              onChange={() => handleChangeField(fieldIndex)}
              props={{
                options: accountStatusOptions,
                margin: 'no',
                disabled: newOptimizedRouteLocationsLength > 0 || isBillingFeatureActive,
                menuPosition: 'fixed',
              }}
            />
          </Container>
        </TableCell>

        <TableCell alignItems="center" vertical width={tableCellWidths[6]}>
          <Container>
            <TypedField
              name={`${fieldArrayKey}.pickupTypeId`}
              component={Dropdown}
              normalize={Number}
              onChange={() => handleChangeField(fieldIndex)}
              props={{
                options: routePickupTypeOptions,
                margin: 'no',
                disabled: newOptimizedRouteLocationsLength > 0 || isBillingFeatureActive,
                menuPosition: 'fixed',
              }}
            />
          </Container>
          <Container>
            <TypedField
              name={`${fieldArrayKey}.reasonCodeTypeId`}
              component={Dropdown}
              normalize={Number}
              onChange={() => handleChangeField(fieldIndex)}
              props={{
                options: reasonCodeTypeOptions,
                placeholder: translate('common.reasonCode'),
                isClearable: true,
                margin: 'no',
                disabled: newOptimizedRouteLocationsLength > 0 || (isBillingFeatureActive && isCompleted),
                menuPosition: 'fixed',
              }}
            />
          </Container>
          <Container>
            <TypedField
              name={`${fieldArrayKey}.jobPriorityTypeId`}
              component={JobPriorityTypesDropdown}
              normalize={Number}
              onChange={() => handleChangeField(fieldIndex)}
              props={{
                withPlaceholder: true,
                dropdownProps: {
                  margin: 'no',
                  disabled: newOptimizedRouteLocationsLength > 0 || (isBillingFeatureActive && isCompleted),
                  menuPosition: 'fixed',
                  isClearable: true,
                },
              }}
            />
          </Container>
        </TableCell>

        <TableCell width={tableCellWidths[7]} padding="xSmall">
          <Label color={PICKUP_STATUS_LABEL_COLORS[field.pickupStatusTypeId]} disabled={!field.id}>
            {PICKUP_STATUSES[field.pickupStatusTypeId].name}
          </Label>
          {field.id && routeSummary.routeStatusTypeId !== SCHEDULED && !(isCompleted && isBillingFeatureActive) && (
            <TableActionButton margin="no xxSmall" onClick={handleOpenJobStatusModal}>
              <ActionButtonTooltip icon="edit" tooltip="edit" />
            </TableActionButton>
          )}
        </TableCell>

        <TableCell width={tableCellWidths[8]}>
          {routeSummary.routeId && !newOptimizedRouteLocationsLength && (
            <TableActionButton onClick={() => setRouteLocationNotesModalOpen(true)}>
              <ActionButtonTooltip icon="note" tooltip="notes" />
            </TableActionButton>
          )}

          {!!field.customerId && !newOptimizedRouteLocationsLength && !(isCompleted && isBillingFeatureActive) && (
            <TableActionButton>
              <Link
                id="edit-service-link"
                color="grayDark"
                to={`/customers/customers/${field.customerId}/location/${field.locationId}/service/${field.serviceContractId}`}
              >
                <ActionButtonTooltip icon="edit" tooltip="editService" />
              </Link>
            </TableActionButton>
          )}

          {isDeletable(isVendorManager, routeSummary.routeStatusTypeId) &&
            (!newOptimizedRouteLocationsLength || field.isNew) &&
            !(isCompleted && isBillingFeatureActive) && (
              <TableActionButton onClick={() => deleteStop(fieldIndex)}>
                <ActionButtonTooltip icon="delete" tooltip="delete" />
              </TableActionButton>
            )}
        </TableCell>
      </TableRow>

      {isRouteLocationNotesModalOpen && routeSummary.routeId && (
        <RouteLocationNotesModalResolver
          closeModal={() => setRouteLocationNotesModalOpen(false)}
          isNewStop={field.isNew}
          modalSubTitle={field.locationName}
          modalTitle={field.customerName}
          routeId={routeSummary.routeId}
          routeLocationId={field.id}
        />
      )}

      {isJobStatusModalOpen && (
        <JobStatusModalResolver
          closeModal={handleCloseJobStatusModal}
          routeId={routeSummary.routeId}
          routeLocation={field}
          changeStatus={(value: number) =>
            change(`${ROUTE_STOPS_FIELD_ARRAY_NAME}[${fieldIndex}]`, { ...field, pickupStatusTypeId: value })
          }
          routeLocationInfo={{
            equipmentTypeNameSize: equipmentType?.name,
            jobStatusId: field.pickupStatusTypeId,
            vehicleTypeId: routeSummary.vehicleTypeId,
            wasteAuditTypeId: routeSummary.wasteAuditTypeId,
            wasteMaterialTypeName: wasteTypeName,
          }}
        />
      )}
    </>
  );
};

export default SortableElement(UnsortableEditRouteStopsTableRow);
