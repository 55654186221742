import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { loadVehicleTypesForVendor } from '../../../fleet/ducks';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import ZDeviceConfigurationModal from './ZDeviceConfigurationModal';

const ZDeviceConfigurationModalResolver: React.FC<ModalProps> = ({ featureCode, closeModal }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    return Promise.all([loadVehicleTypesForVendor(vendorId)(dispatch)]);
  }, [vendorId, dispatch]);

  return (
    <Resolver
      successComponent={ZDeviceConfigurationModal}
      successProps={{ featureCode, closeModal }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default ZDeviceConfigurationModalResolver;
