import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { push, replace } from 'connected-react-router';
import moment from 'moment';

import { AppState } from '../../../store';
import {
  Button,
  Grid,
  GridColumn,
  Panel,
  PanelSection,
  PanelSectionGroup,
  Text,
} from '../../../core/components/styled';
import { ENGINE_HOURS_DATA_ID, FAULT_DATA_ID, FUEL_DATA_ID, ODOMETER_DATA_ID } from 'src/dashboard/constants/yData';
import { FLEET_VEHICLES_EDIT } from '../../../account/constants';
import { getQueryParams, createUrl } from '../../../utils/services/queryParams';
import { isAdmin } from '../../../account/services/auth';
import { isY } from 'src/vendors/constants/deviceRoleTypes';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { PermissionGuard } from '../../../account/components';
import { PM_TYPE_DATE_CHECKS } from '../../constants/preventativeMaintenance';
import { PreventativeMaintenanceConfiguration } from '../../interfaces/PreventativeMaintenance';
import { Vehicle } from '../../interfaces/Vehicle';
import * as PMDucks from '../../ducks/preventativeMaintenance';
import LabeledDataView from '../../../core/components/LabeledDataView';
import PreventativeMaintenanceLogSection from './vehiclePageSections/PreventativeMaintenanceLogSection';
import PreventativeMaintenanceModalResolver from '../modal/PreventativeMaintenanceModalResolver';
import TooltipButton from '../../../core/components/TooltipButton';
import translate from '../../../core/services/translate';
import {
  VEHICLE_DETAILS_DEFAULT_DATE_RANGE_FROM,
  VEHICLE_DETAILS_DEFAULT_DATE_RANGE_TO,
} from '../../constants/vehicle';
import VehicleFaultCodesSection from './vehiclePageSections/VehicleFaultCodesSection';
import VehicleFuelLogSection from './vehiclePageSections/VehicleFuelLogSection';
import VehicleInspectionLogSection from './vehiclePageSections/VehicleInspectionLogSection';
import VehicleIssuesSection from './vehiclePageSections/VehicleIssuesSection';
import VehicleMaintenanceStatusSection from './vehiclePageSections/VehicleMaintenanceStatusSection';
import DateRangeForm, { DateRangeFormValues } from 'src/common/components/DateRangeForm';
import { MAX_INTERVAL_NINETY_DAYS } from 'src/core/constants/weekdays';
import { dateTimeFormat } from 'src/utils/services/validator';

interface Props {
  isFleetMaintenanceFeatureEnabled: boolean;
}

interface RouteParams {
  vehicleId: string;
}

const VehiclePage: React.FC<RouteComponentProps<RouteParams> & Props> = ({
  match: {
    params: { vehicleId },
  },
  history,
  location: { pathname, search },
  isFleetMaintenanceFeatureEnabled,
}) => {
  const [pmModalOpen, setPMModalOpen] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const hasAdminPrivileges = useSelector(isAdmin);
  const vehicle: Vehicle | undefined = useSelector((state: AppState) => state.fleet.vehicle.vehicle);
  const vehicleTypes = useSelector((state: AppState) => state.fleet.vehicleTypes.vehicleTypes);
  const binColors = useSelector((state: AppState) => state.fleet.vehicle.binColors);
  const vehicleTypeLevelPM = useSelector((state: AppState) => state.fleet.preventativeMaintenance.vehicleTypeLevelPM);
  const rubiconYDeviceManufacturerList = useSelector((state: AppState) => state.fleet.vehicles.deviceManufacturerList);
  const { lastLocations } = useSelector((state: AppState) => state.core);

  const { yData } = useSelector((state: AppState) => state.vendors.yData);
  const isOdometerReadingDataVisible = yData.find(data => data.yDataType.id === ODOMETER_DATA_ID)?.isActive;
  const isEngineHoursDataVisible = yData.find(data => data.yDataType.id === ENGINE_HOURS_DATA_ID)?.isActive;
  const isFaultDataVisible = yData.find(data => data.yDataType.id === FAULT_DATA_ID)?.isActive;
  const isFuelDataVisible = yData.find(data => data.yDataType.id === FUEL_DATA_ID)?.isActive;

  const deviceRoleTypeId: number | undefined = useSelector(
    (state: AppState) => (state.vendors.vendor.vendor as any)?.deviceRoleTypeId,
  );

  const isYType = isY(deviceRoleTypeId);

  const { startDate = VEHICLE_DETAILS_DEFAULT_DATE_RANGE_FROM, endDate = VEHICLE_DETAILS_DEFAULT_DATE_RANGE_TO } =
    getQueryParams(search);

  const vehicleHasYDevice = !!vehicle && !!vehicle.deviceId;

  let onlyAvailableConfiguration: keyof PreventativeMaintenanceConfiguration | undefined = undefined;
  let preventativeMaintenanceIsActive: boolean = false;
  let preventativeMaintenanceIsDateChecks: boolean = false;

  if (!!vehicleTypeLevelPM) {
    (Object.keys(vehicleTypeLevelPM) as (keyof PreventativeMaintenanceConfiguration)[]).forEach(key => {
      const configuration = vehicleTypeLevelPM[key];

      if (!!configuration && configuration.isActive) {
        preventativeMaintenanceIsActive = true;
        onlyAvailableConfiguration = key;

        if (configuration.preventativeMaintenanceType.id === PM_TYPE_DATE_CHECKS) {
          preventativeMaintenanceIsDateChecks = true;
        }
      }
    });
  }

  const onVehicleDetailsDateRangeChange = ({ dateRange }: Partial<DateRangeFormValues>) => {
    if (dateRange && (dateRange.from !== startDate || dateRange.to !== endDate)) {
      dispatch(replace(createUrl(pathname, search, { startDate: dateRange.from, endDate: dateRange.to })));
    }
  };

  const openPMModal = () => {
    setPMModalOpen(true);
  };

  const closePMModal = () => {
    setPMModalOpen(false);
  };

  const savePMConfiguration = (configuration: PreventativeMaintenanceConfiguration) => {
    PMDucks.savePMConfigurationForVehicle(
      +vehicleId,
      configuration,
    )(dispatch)
      .then(() => {
        closePMModal();
      })
      .catch(() => {});
  };

  const vehicleType = vehicle ? vehicleTypes.find(type => type.id === vehicle.vehicleTypeId) : null;

  const vehicleSubType =
    vehicle && vehicleType ? vehicleType.subTypes.find(type => type.id === vehicle.vehicleSubTypeId) : null;

  const deviceManufacturer =
    !!vehicle && vehicle.deviceManufacturerId
      ? rubiconYDeviceManufacturerList.find(manufacturer => manufacturer.id === vehicle.deviceManufacturerId)
      : null;

  let dueForNextPM: string | undefined = undefined;

  if (vehicle) {
    if (vehicle.nextPreventativeMaintenanceDate) {
      dueForNextPM = moment(vehicle.nextPreventativeMaintenanceDate).format('MM/DD/YYYY');
    } else if (vehicle.nextPreventativeMaintenanceEngineHours) {
      dueForNextPM = `${vehicle.nextPreventativeMaintenanceEngineHours} ${translate(
        'vehicles.preventativeMaintenanceLabels.hours',
      )}`;
    } else if (vehicle.nextPreventativeMaintenanceMileage) {
      dueForNextPM = `${vehicle.nextPreventativeMaintenanceMileage} ${translate(
        'vehicles.preventativeMaintenanceLabels.miles',
      )}`;
    }
  }

  React.useEffect(
    () => () => {
      dispatch(PMDucks.reset());
    },
    [dispatch],
  );

  const vehicleDetailsDateRangeFormInitialValues = {
    dateRange: {
      from: startDate,
      to: endDate,
    },
  };

  const goBackToPreviousPage = () => {
    const hasLastLocations = Object.keys(lastLocations).length > 1;

    return hasLastLocations ? history.goBack() : dispatch(push('/fleet/vehicles'));
  };

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction id="back-button" onClick={goBackToPreviousPage}>
              <PageBackButtonIcon />
            </PageBackButtonAction>

            <PageTitle>
              {translate('vehicles.vehicleWithName', { vehicleName: vehicle ? vehicle.regplate : '' })}
            </PageTitle>
          </PageTitleContainer>
        </PageDetails>

        <PageActions flex align="right">
          <DateRangeForm
            intervalAmount={MAX_INTERVAL_NINETY_DAYS}
            initialValues={vehicleDetailsDateRangeFormInitialValues}
            onChange={onVehicleDetailsDateRangeChange}
          />
        </PageActions>
      </PageHeader>

      <Panel padding="medium">
        <PanelSection withBorder padding="no no medium">
          <PanelSectionGroup padding="no" width="100%">
            <Grid multiLine>
              <GridColumn size="12/12" padding="no no medium">
                <Grid>
                  <GridColumn size="6/12" padding="no" verticalAlign="center">
                    <Text size="xLarge">{translate('vehicles.vehicleSummary')}</Text>
                  </GridColumn>

                  <GridColumn size="6/12" padding="no" align="right">
                    {hasAdminPrivileges && preventativeMaintenanceIsActive && (
                      <TooltipButton
                        text
                        color="primary"
                        margin="no small no no"
                        onClick={openPMModal}
                        disabled={!vehicleHasYDevice && !preventativeMaintenanceIsDateChecks}
                        tooltip={
                          !vehicleHasYDevice && !preventativeMaintenanceIsDateChecks ? 'pmNotAllowedNonY' : undefined
                        }
                      >
                        {translate('vehicles.configurePM')}
                      </TooltipButton>
                    )}

                    <PermissionGuard permission={FLEET_VEHICLES_EDIT}>
                      <Button
                        line
                        color="primary"
                        onClick={() => {
                          dispatch(push(`/fleet/vehicles/${vehicleId}/edit`));
                        }}
                      >
                        {translate('common.edit')}
                      </Button>
                    </PermissionGuard>
                  </GridColumn>
                </Grid>
              </GridColumn>

              {!!vehicle && (
                <>
                  <GridColumn size="2/12" padding="no small medium no">
                    <LabeledDataView label="Vehicle Type" value={vehicleType ? vehicleType.name : '–'} />
                  </GridColumn>

                  {hasAdminPrivileges && (
                    <>
                      <GridColumn size="2/12" padding="no small medium no">
                        <LabeledDataView
                          label={translate('common.deviceManufacturer')}
                          value={deviceManufacturer ? deviceManufacturer.name : '–'}
                        />
                      </GridColumn>

                      <GridColumn size="2/12" padding="no small medium no">
                        <LabeledDataView
                          label={translate('common.rubiconZDeviceId')}
                          value={vehicle.rubiconZDeviceId || '–'}
                        />
                      </GridColumn>
                    </>
                  )}

                  {isOdometerReadingDataVisible && (
                    <GridColumn size="2/12" padding="no small medium no">
                      <LabeledDataView label={translate('vehicles.mileage')} value={vehicle.mileage || '–'} />
                    </GridColumn>
                  )}

                  <GridColumn size="2/12" padding="no small medium no">
                    <LabeledDataView label={translate('vehicles.dueForNextPMAt')} value={dueForNextPM || '–'} />
                  </GridColumn>

                  {isEngineHoursDataVisible && (
                    <GridColumn size="2/12" padding="no no medium no">
                      <LabeledDataView label={translate('vehicles.engineHours')} value={vehicle.engineHours || '–'} />
                    </GridColumn>
                  )}

                  <GridColumn size="2/12" padding="no small medium no">
                    <LabeledDataView
                      label={translate('vehicles.vehicleSubType')}
                      value={vehicleSubType ? vehicleSubType.name : '–'}
                    />
                  </GridColumn>

                  {hasAdminPrivileges && (
                    <>
                      <GridColumn size="2/12" padding="no small medium no">
                        <LabeledDataView label={translate('common.rubiconYDeviceId')} value={vehicle.deviceId || '–'} />
                      </GridColumn>

                      <GridColumn size="2/12" padding="no small medium no">
                        <LabeledDataView
                          label={translate('vehicles.cameraConfigurations.fieldTitle')}
                          value={
                            vehicle.vehicleCameraConfigurations.length
                              ? translate('vehicles.xConfigurations', {
                                  count: vehicle.vehicleCameraConfigurations.length,
                                })
                              : '–'
                          }
                        />
                      </GridColumn>

                      <GridColumn size="2/12" padding="no small medium no">
                        <LabeledDataView label={translate('common.containerColor')}>
                          {!!vehicle.binColors.length &&
                            (vehicle.binColors.length === binColors.length
                              ? translate('common.allContainerColors')
                              : translate('common.xContainerColors', { selected: vehicle.binColors.length }))}

                          {!vehicle.binColors.length && '–'}
                        </LabeledDataView>
                      </GridColumn>
                    </>
                  )}

                  <GridColumn size="2/12" padding="no small medium no">
                    <LabeledDataView
                      label={translate('vehicles.totalUnresolvedIssues')}
                      value={vehicle.totalUnresolvedIssues || '–'}
                    />
                  </GridColumn>

                  <GridColumn size="2/12" padding="no no medium no">
                    <LabeledDataView label={translate('vehicles.lastKnownLocation')}>
                      {vehicle.lastKnownLocationAddress && vehicle.lastKnownLocationDatetime ? (
                        <>
                          <Text block size="sMedium" lineHeight="1.3">
                            {vehicle.lastKnownLocationAddress}
                          </Text>

                          <Text block size="small" color="grayDarker">
                            {moment(vehicle.lastKnownLocationDatetime).format(dateTimeFormat)}
                          </Text>
                        </>
                      ) : (
                        '–'
                      )}
                    </LabeledDataView>
                  </GridColumn>

                  <GridColumn size="2/12" padding="no small medium no">
                    <LabeledDataView
                      label={translate('common.vehicleStatus')}
                      value={translate(vehicle.isActive ? 'common.active' : 'common.inactive')}
                    />
                  </GridColumn>

                  <GridColumn size="2/12" padding="no small medium no">
                    <LabeledDataView
                      label={translate('common.serviceStatus')}
                      value={translate(vehicle.isAvailable ? 'common.available' : 'common.unavailable')}
                    />
                  </GridColumn>

                  {(!vehicle.isAvailable || !!vehicle.vehicleUnavailable?.startDate) && (
                    <>
                      <GridColumn size="2/12" padding="no small medium no">
                        <LabeledDataView
                          label={translate('common.unavailableFrom')}
                          value={moment(vehicle.vehicleUnavailable?.startDate).format('MM/DD/YYYY')}
                        />
                      </GridColumn>

                      <GridColumn size="2/12" padding="no small medium no">
                        <LabeledDataView
                          label={translate('common.unavailableTo')}
                          value={
                            vehicle.vehicleUnavailable?.endDate
                              ? moment(vehicle.vehicleUnavailable?.endDate).format('MM/DD/YYYY')
                              : '-'
                          }
                        />
                      </GridColumn>
                    </>
                  )}

                  <GridColumn size="2/12" padding="no small medium no">
                    <LabeledDataView
                      label={translate('common.customerTypes.operationalFacility')}
                      value={vehicle.locationName || '–'}
                    />
                  </GridColumn>
                </>
              )}
            </Grid>
          </PanelSectionGroup>
        </PanelSection>

        <VehicleMaintenanceStatusSection />
        <PreventativeMaintenanceLogSection />
        {isYType && isFuelDataVisible && <VehicleFuelLogSection />}
        <VehicleIssuesSection isFleetMaintenanceFeatureEnabled={isFleetMaintenanceFeatureEnabled} />
        <VehicleInspectionLogSection />
        {isFaultDataVisible && <VehicleFaultCodesSection />}
      </Panel>

      {pmModalOpen && (
        <PreventativeMaintenanceModalResolver
          vehicleId={+vehicleId}
          onlyAvailableConfiguration={onlyAvailableConfiguration}
          onClose={closePMModal}
          onSubmit={savePMConfiguration}
        />
      )}
    </PageContent>
  );
};

export default withRouter(VehiclePage);
