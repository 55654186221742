import { http } from 'src/core/services/http';
import { StripeAccountLink, StripeAccountStatus } from 'src/vendors/interfaces/StripeAccount';

export const checkIsBillingActive = (vendorId: number) =>
  http.get<boolean>(`/payment/isBillingActive?vendorId=${vendorId}`).then(response => response.data);

export const createStripeAccount = (vendorId: number) =>
  http.post<StripeAccountLink>(`/payment/createAccount?vendorId=${vendorId}`).then(response => response.data);

export const refreshStripeAccountLink = (vendorId: number) =>
  http.post<StripeAccountLink>(`/payment/refresh?vendorId=${vendorId}`).then(response => response.data);

export const completeStripeAccountSetup = (vendorId: number) =>
  http.post<StripeAccountStatus>(`/payment/completeSetup?vendorId=${vendorId}`).then(response => response.data);
