import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { BareButtonStyle } from '../../../core/components/styled';
import { TooltipButtonTooltip } from 'src/core/components/styled/TooltipButtonStyles';

export const RouteTemplateBuilderFiltersContainer = styled.div`
  position: relative;
`;

export const RouteTemplateBuilderFiltersReadOnlyWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 3;
  cursor: not-allowed;
`;

export const SimpleFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
`;

interface SimpleFilterButtonProps {
  isActive?: boolean;
  grow?: boolean;
}

export const SimpleFilterButton = styled.button<SimpleFilterButtonProps>`
  ${BareButtonStyle}
  display: flex;
  padding: 0 12px;
  margin: 5px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => (props.isActive ? props.theme.brandPrimary : props.theme.gray)};
  border-radius: 6px;
  height: 40px;
  text-align: center;
  flex: 1 1;

  ${props =>
    props.isActive &&
    css`
      background-color: ${rgba(props.theme.brandPrimary, 0.13)};
    `}

  ${props =>
    props.grow &&
    css`
      flex: 1 0;
    `}
`;

export const RouteTemplateBuilderEmptyFiltersNotice = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  pointer-events: none;
`;

export const WorkSpaceLayouts = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  user-select: none;
`;

interface WorkSpaceLayoutButtonProps {
  active?: boolean;
}

export const WorkSpaceLayoutButton = styled.div<WorkSpaceLayoutButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0 0 1px ${props => props.theme.gray};
  color: ${props => props.theme.grayDarker};
  position: relative;

  & svg {
    max-height: 24px;
    max-width: 24px;
  }

  &:last-child {
    margin-left: 12px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }

  &:hover ${TooltipButtonTooltip} {
    display: block;
  }

  &:active {
    background: rgba(0, 0, 0, 0.05);
  }

  ${props =>
    props.active &&
    css`
      box-shadow: 0 0 0 1px ${props => props.theme.brandPrimary};
      color: ${props => props.theme.brandPrimary};
    `}
`;
