import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { BareButtonStyle } from '../../../../core/components/styled';
import { DISPATCH_BOARD_ROUTE_ITEM_HEIGHT } from 'src/routes/constants/dispatchBoard';
import { Icon } from '../../../../core/components';
import { loadingOverlay } from '../../../../core/styles';

interface Props {
  autoHeight?: boolean;
  dropZone?: boolean;
  isHoveringOver?: boolean;
  isLoading?: boolean;
  isReadyToDrop?: boolean;
}

export const DispatchBoardRouteDate = styled.span`
  display: block;
  margin-bottom: 6px;
  font-size: 12px;
  color: ${props => props.theme.grayDark};
`;

export const DispatchBoardRouteActionIcon = styled(Icon)`
  width: 14px;
  height: 14px;
`;

export const DispatchBoardUploadRouteButtonWrapper = styled.div`
  margin-right: 10px;
`;

export const DispatchBoardDownloadSampleButtonWrapper = styled.div`
  text-align: right;
`;

export const UploadDocumentArea = styled.div<Props>`
  height: 300px;
  width: 100%;
  border: 5px dashed ${p => p.theme.brandPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => (p.isHoveringOver ? transparentize(0.8, p.theme.brandPrimary) : 'inherit')};
  margin-bottom: 20px;

  a {
    color: ${p => p.theme.brandPrimary};
  }

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')};
    `};
`;

export const DispatchBoardRouteAction = styled.button`
  ${BareButtonStyle}
  margin-right: 10px;
  padding: 4px;
  color: ${props => props.theme.gray};
  transition: color 0.3s ease-out;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${props => props.theme.grayDarker};
  }
`;

export const DispatchBoardRouteActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
`;

export const DispatchBoardRouteNumberOfJobs = styled.span`
  display: block;
  margin-bottom: 10px;
  font-size: 13px;
`;

export const DispatchBoardRouteNumberOfOverdueJobs = styled.span`
  color: ${props => props.theme.brandAlert};
`;

export const DispatchBoardRouteType = styled.span`
  display: block;
  margin-bottom: 6px;
  font-size: 13px;
`;

export const DispatchBoardRouteName = styled.span`
  display: block;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 16px;
`;

export const DispatchBoardRouteSecondaryDetails = styled.div`
  text-align: right;
  flex: 20%;
`;

export const DispatchBoardRoutePrimaryDetails = styled.div`
  flex: 80%;
`;

export const DispatchBoardRouteContainer = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  height: ${DISPATCH_BOARD_ROUTE_ITEM_HEIGHT}px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid transparent;
  border-bottom-color: ${props => props.theme.grayLight};
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.grayLighter};
  }

  ${props =>
    props.autoHeight &&
    css`
      height: auto;
    `}

  ${props =>
    props.dropZone &&
    css`
      & * {
        pointer-events: none;
      }
    `}

    ${props =>
    props.isReadyToDrop &&
    css`
      background-color: ${transparentize(0.9, props.theme.brandPrimary)};
      border: 1px dashed ${props.theme.brandPrimary};
    `};
`;
