import { http } from '../../core/services/http';
import { VehicleIssue } from '../interfaces/VehicleIssues';
import { TechnicalType } from '../../common/interfaces/TechnicalType';
import { EXCEL_MIME_TYPES } from '../../core/constants';
import FileSaver from 'file-saver';
import translate from '../../core/services/translate';

export interface LoadVehicleIssuesFilters {
  startDate?: string;
  endDate?: string;
  statusIds?: number[];
  driverNames?: string[];
  issueSubcategoryIds?: number[];
}

export const loadVehicleIssues = (
  vehicleId: number,
  { startDate, endDate, statusIds = [], driverNames = [], issueSubcategoryIds = [] }: LoadVehicleIssuesFilters = {},
) =>
  http
    .get<VehicleIssue[]>(`vehicles/${vehicleId}/tripInspectionIssues`, {
      params: {
        startDate,
        endDate,
        statusIdsCSV: !statusIds.length ? undefined : statusIds.join(','),
        driverNamesCSV: !driverNames.length ? undefined : driverNames.join(','),
        tripInspectionSubCategoryIdsCSV: !issueSubcategoryIds.length ? undefined : issueSubcategoryIds.join(','),
      },
    })
    .then(response => response.data);

export const loadVehicleIssueStatuses = () =>
  http.get<TechnicalType[]>('common/systemTypes/tripInspectionIssueStatusTypes').then(response => response.data);

export const loadVehicleDrivers = (vehicleId: number) =>
  http.get<string[]>(`vehicles/${vehicleId}/driversForTipInspectionIssuesFilters`).then(response => response.data);

export const loadVehicleIssueSubcategories = (vehicleId: number) =>
  http.get<TechnicalType[]>(`vehicles/${vehicleId}/tripInspectionSubCategoryFilters`).then(response => response.data);

export const updateVehicleIssue = (
  vehicleId: number,
  tripInspectionDetailId: number,
  statusId: number,
  vehicleNote?: string,
) =>
  http.put(`vehicles/${vehicleId}/tripInspectionIssues`, [
    {
      tripInspectionDetailId,
      statusId,
      note: vehicleNote,
    },
  ]);

export const exportVehicleIssues = (
  vehicleId: number,
  { startDate, endDate, statusIds = [], driverNames = [], issueSubcategoryIds = [] }: LoadVehicleIssuesFilters = {},
) =>
  http
    .get(`vehicles/${vehicleId}/exportVehicleIssues`, {
      params: {
        startDate,
        endDate,
        statusIdsCSV: !statusIds.length ? undefined : statusIds.join(','),
        driverNamesCSV: !driverNames.length ? undefined : driverNames.join(','),
        tripInspectionSubCategoryIdsCSV: !issueSubcategoryIds.length ? undefined : issueSubcategoryIds.join(','),
      },
      responseType: 'arraybuffer',
    })
    .then(response => {
      const blob = new Blob([response.data], {
        type: EXCEL_MIME_TYPES,
      });

      FileSaver.saveAs(blob, `${translate('vehicles.vehicleIssuesExport')}.xlsx`);
    });
