import React, { useState } from 'react';
import { connect } from 'react-redux';
import { removeNotification } from '../ducks';
import {
  NotificationList as NotificationListContainer,
  Notification as NotificationContainer,
  NotificationClose,
  NotificationCloseIcon,
  Button,
} from './styled';
import { AppState } from '../../store';
import { AppNotification } from '../ducks/notifications';
import Modal from './Modal';
import translate from '../services/translate';

interface NotificationProps {
  id: string;
  message: string;
  color: string;
  removeNotification: (id: string) => void;
  autoClose: boolean;
  seeMoreContent?: () => JSX.Element;
  onSeeMoreContent: (seeMoreContent: JSX.Element) => void;
}

const Notification: React.FC<NotificationProps> = ({
  id,
  message,
  color,
  removeNotification,
  seeMoreContent,
  onSeeMoreContent,
}) => {
  return (
    <>
      <NotificationContainer color={color}>
        <NotificationClose onClick={() => removeNotification(id)}>
          <NotificationCloseIcon />
        </NotificationClose>
        <div>{message}</div>
        {seeMoreContent && (
          <Button color="primary" text onClick={() => onSeeMoreContent(seeMoreContent())}>
            {translate('common.seeMore')}...
          </Button>
        )}
      </NotificationContainer>
    </>
  );
};

interface NotificationListProps {
  notifications: AppNotification[];
  removeNotification: (id: string) => void;
}

const NotificationList: React.FC<NotificationListProps> = ({ notifications, removeNotification }) => {
  const [currentSeeMoreContent, setCurrentSeeMoreContent] = useState<JSX.Element | undefined>();

  return (
    <NotificationListContainer>
      {notifications.map(({ id, message, color, autoClose, seeMoreContent }) => (
        <Notification
          id={id}
          key={id}
          message={message}
          color={color}
          autoClose={autoClose}
          removeNotification={removeNotification}
          seeMoreContent={seeMoreContent}
          onSeeMoreContent={smc => setCurrentSeeMoreContent(smc)}
        />
      ))}
      {currentSeeMoreContent && (
        <Modal onClose={() => setCurrentSeeMoreContent(undefined)}>{currentSeeMoreContent}</Modal>
      )}
    </NotificationListContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  notifications: state.core.notifications,
});

const mapDispatchToProps = { removeNotification };

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
