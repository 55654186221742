import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const TONS = 1;
export const POUNDS = 2;
export const OUNCES = 3;
export const KILOGRAMS = 4;

export const WEIGHT_MEASUREMENT_UNITS = mapKeys(
  [
    { id: TONS, name: translate('common.weightMeasurementUnits.tons') },
    { id: POUNDS, name: translate('common.weightMeasurementUnits.pounds') },
    { id: KILOGRAMS, name: translate('common.weightMeasurementUnits.kilograms') },
  ],
  'id',
);

export default WEIGHT_MEASUREMENT_UNITS;
