import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';
import { orderBy } from 'lodash-es';
import moment from 'moment';

import * as Services from '../services/vehicleInspections';
import { VehicleInspectionsState, VehicleInspection } from '../interfaces/VehicleInspections';

// Actions
const START_LOAD = 'vendors/vehicle/inspections/START_LOAD';
const COMPLETE_LOAD = 'vendors/vehicle/inspections/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/vehicle/inspections/FAIL_LOAD';
const RESET = 'vendors/vehicle/inspections/RESET';

const initialState: VehicleInspectionsState = {
  inspections: [],
  inspectionsLoading: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          inspectionsLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          inspections: orderBy(action.inspections, inspection => moment(inspection.routeDate).toISOString(), 'desc'),
          inspectionsLoading: false,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          inspections: [],
          inspectionsLoading: false,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (inspections: VehicleInspection[]) => ({
  type: COMPLETE_LOAD,
  inspections,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const resetVehicleInspections = () => ({
  type: RESET,
});

export const loadVehicleInspections = (vendorId: number, vehicleId: number, startDate?: string, endDate?: string) => (
  dispatch: Dispatch,
) => {
  dispatch(startLoad());

  const promise = Services.loadVehicleInspection(vendorId, vehicleId, startDate, endDate);

  promise
    .then(inspections => {
      dispatch(completeLoad(inspections));
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return promise;
};
