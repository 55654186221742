import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { DuckFunction } from 'src/contracts/ducks';
import { getNotes } from '../../ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import NotesModal from './NotesModal';

type RouteParams = {
  token: string;
};

interface Props extends RouteComponentProps<RouteParams> {
  getNotes: DuckFunction<typeof getNotes>;
  onCancel: (pristine: boolean, isReload?: boolean) => void;
  rubiconPONbr: string;
  serviceDate: Date | string;
  userId: string;
  vendorId: number;
}

class NotesModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      userId,
      vendorId,
      getNotes,
      rubiconPONbr,
      serviceDate,
      match: {
        params: { token },
      },
    } = this.props;
    const promises = [getNotes({ token, userId, vendorId, workOrder: rubiconPONbr, serviceDate })];
    return Promise.all(promises);
  };

  render() {
    return (
      <Fragment>
        <Resolver
          successComponent={NotesModal}
          loadingComponent={PageLoadingOverlay}
          successProps={{ ...this.props }}
          resolve={this.loadDependencies}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  getNotes,
};

export default withRouter(connect(undefined, mapDispatchToProps)(NotesModalResolver));
