import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const ACTIVE = 1;
export const NEW = 2;
export const SUSPENDED = 3;
export const ON_HOLD = 4;
export const CLOSED = 5;
export const ACTIVE_LABEL = 'Active';
export const SUSPENDED_LABEL = 'Suspended';
export const ON_HOLD_LABEL = 'On Hold';

export const ACCOUNT_STATUSES = mapKeys(
  [
    { id: ACTIVE, name: translate('common.accountStatuses.active') },
    { id: NEW, name: translate('common.accountStatuses.new') },
    { id: SUSPENDED, name: translate('common.accountStatuses.suspended') },
    { id: ON_HOLD, name: translate('common.accountStatuses.onHold') },
    { id: CLOSED, name: translate('common.accountStatuses.closed') },
  ],
  'id',
);

export const ACCOUNT_STATUSES_BY_NAME = mapKeys(
  [
    { id: ACTIVE, name: translate('common.accountStatuses.active') },
    { id: NEW, name: translate('common.accountStatuses.new') },
    { id: SUSPENDED, name: translate('common.accountStatuses.suspended') },
    { id: ON_HOLD, name: translate('common.accountStatuses.onHold') },
    { id: CLOSED, name: translate('common.accountStatuses.closed') },
  ],
  'name',
);
