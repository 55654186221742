import { ACCOUNT_STATUSES, ACTIVE, EXTRA_PICKUP, SCHEDULED } from 'src/routes/constants';
import { PICKUP_STATUSES } from 'src/common/constants';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { NEW_INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';
import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { ROUTE_MAP_ROUTE_STOPS_LAYER } from '../layerIds';

export type ContainerInsightFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
  orderNo?: number;
};

export const getRouteStopIconType = (routeStop: RouteLocation) => {
  const accountStatus =
    routeStop.accountStatusId !== ACTIVE && routeStop.accountStatusId !== EXTRA_PICKUP
      ? ACCOUNT_STATUSES[routeStop.accountStatusId]?.technicalName || ''
      : '';
  let pickupStatus = PICKUP_STATUSES[SCHEDULED]?.technicalName || '';

  const flagged = routeStop.location.hasVendorLocationAlert ? 'Flagged' : '';

  const insightType = `ic${accountStatus}${flagged}${pickupStatus}`;

  return NEW_INSIGHT_ICON_TYPES.find(({ types }) => types.indexOf(insightType) !== -1);
};

export const getRouteStopsGeoJSON = (routeStops: RouteLocation[]) =>
  getFeatureCollection<GeoJSON.Point, ContainerInsightFeatureProperties>(
    routeStops.map(routeStop => {
      const lat =
        routeStop.service.serviceContractBinDetails[0]?.displayLatitude ||
        routeStop.service.serviceContractBinDetails[0]?.binLatitude ||
        routeStop.location.address.latitude;
      const long =
        routeStop.service.serviceContractBinDetails[0]?.displayLongitude ||
        routeStop.service.serviceContractBinDetails[0]?.binLongitude ||
        routeStop.location.address.longitude;

      const id = (routeStop.serviceContractRouteTemplateId || routeStop.stopId) as number;

      return getPointFeature(id, [long, lat], {
        id: id,
        clickable: true,
        layer: ROUTE_MAP_ROUTE_STOPS_LAYER,
        icon: getRouteStopIconType(routeStop)?.id,
        orderNo: routeStop.orderNumber,
        newOrderNumber: routeStop.newOrderNumber || undefined,
      });
    }),
  );
