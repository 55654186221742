import { filter, find, flatMap, map, reduce, some } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import { RouteGeoFenceMultiPolygon } from 'src/routes/interfaces/RouteGeoFence';
import { getPolygonsFromGeoFenceCoordinates } from '../utils/geoFenceControllerMapbox';

export const NEW_GEO_FENCE_ID = -1;
interface GeoFenceForMapbox {
  id: number;
  name?: string;
  polygons: { id: number; coordinates: number[][]; history: any[]; isNew: boolean }[];
  removedPolygons: { id: number; coordinates: number[][]; history: any[]; isNew: boolean }[];
  canEdit?: boolean;
  geoJson?: any;
}

interface SelectedGeoFence {
  id: number;
  polygonId: number;
}

const useGeoFenceControllerMapbox = () => {
  const [mapGeoFences, setMapGeoFences] = useState<GeoFenceForMapbox[]>([]);
  const [selectedGeoFence, setSelectedGeoFence] = useState<SelectedGeoFence | null>(null);

  const emptyGeoFences = useCallback(() => {
    setMapGeoFences([]);
  }, []);

  const addGeoFence = useCallback(
    (geoFence: RouteGeoFenceMultiPolygon) => {
      const geoId = geoFence.id || NEW_GEO_FENCE_ID;

      const existingGeoFence = mapGeoFences.find(geoFenceInList => geoFenceInList.id === geoId);

      if (existingGeoFence) {
        const newGeoFence = {
          ...existingGeoFence,
          polygons: getPolygonsFromGeoFenceCoordinates(geoFence.geoFenceCoordinates),
        };
        setMapGeoFences(prevMapGeoFences => {
          const newMapGeoFences = [...prevMapGeoFences];
          const index = newMapGeoFences.findIndex(geoFenceInList => geoFenceInList.id === geoId);
          newMapGeoFences[index] = newGeoFence;
          return index >= 0 ? newMapGeoFences : mapGeoFences;
        });
      } else {
        const geoFenceForMapbox: GeoFenceForMapbox = {
          id: geoId,
          name: geoFence.geoFenceName,
          polygons: getPolygonsFromGeoFenceCoordinates(geoFence.geoFenceCoordinates),
          removedPolygons: [],
        };
        setMapGeoFences(prevMapGeoFences => [...prevMapGeoFences, geoFenceForMapbox]);
      }
    },
    [mapGeoFences],
  );

  const bulkAddGeoFences = useCallback(
    (geoFences: RouteGeoFenceMultiPolygon[]) => {
      emptyGeoFences();

      const geoFencesForMapbox = map(geoFences, geoFence => ({
        id: geoFence.id || NEW_GEO_FENCE_ID,
        name: geoFence.geoFenceName,
        polygons: getPolygonsFromGeoFenceCoordinates(geoFence.geoFenceCoordinates),
        removedPolygons: [],
      }));

      setMapGeoFences(geoFencesForMapbox);
    },
    [emptyGeoFences],
  );

  const removeGeoFence = useCallback((id: number) => {
    setMapGeoFences(prevMapGeoFences => filter(prevMapGeoFences, geoFence => geoFence.id !== id));
  }, []);

  const removeUnsavedGeoFenceOrPolygons = useCallback(() => {
    setMapGeoFences(prevMapGeoFences =>
      filter(prevMapGeoFences, geoFence => {
        if (geoFence.id === NEW_GEO_FENCE_ID && !some(geoFence.polygons, 'isNew')) {
          return false;
        }
        geoFence.polygons = [
          ...filter(geoFence.polygons, polygon => {
            if (polygon.isNew) return false;

            polygon.coordinates = polygon.history[0];
            polygon.history = [polygon.history[0]];
            return true;
          }),
          ...(filter(geoFence.removedPolygons, p => !p.isNew) || []),
        ];
        geoFence.removedPolygons = [];
        return true;
      }),
    );
  }, []);

  const removePolygonFromGeoFence = useCallback((geoFenceId: number, polygonId: number) => {
    setMapGeoFences(prevMapGeoFences => [
      ...map(prevMapGeoFences, geoFence => {
        if (geoFence.id === geoFenceId) {
          const polygon = find(geoFence.polygons, polygon => polygon.id === polygonId);
          if (polygon) geoFence.removedPolygons.push(polygon);
          geoFence.polygons = filter(geoFence.polygons, polygon => polygon.id !== polygonId);
        }
        return geoFence;
      }),
    ]);
  }, []);

  const addPolygonToGeoFence = useCallback((coordinates: number[][], geoFenceId?: number) => {
    let polygonId = 0;

    setMapGeoFences(prevMapGeoFences => {
      if (geoFenceId) {
        return map(prevMapGeoFences, geoFence => {
          if (geoFence.id === geoFenceId) {
            polygonId = geoFence.polygons.length;
            geoFence.polygons.push({
              id: geoFence.polygons.length,
              coordinates,
              isNew: true,
              history: [coordinates],
            });
          }
          return geoFence;
        });
      } else if (find(prevMapGeoFences, { id: NEW_GEO_FENCE_ID })) {
        return map(prevMapGeoFences, geoFence => {
          if (geoFence.id === NEW_GEO_FENCE_ID) polygonId = geoFence.polygons.length;
          geoFence.polygons.push({
            id: geoFence.polygons.length,
            coordinates,
            isNew: true,
            history: [coordinates],
          });

          return geoFence;
        });
      } else {
        return [
          ...prevMapGeoFences,
          {
            id: NEW_GEO_FENCE_ID,
            polygons: [
              {
                id: 0,
                coordinates,
                history: [coordinates],
                isNew: true,
              },
            ],
            removedPolygons: [],
          },
        ];
      }
    });
    return polygonId;
  }, []);

  const getGeoJsonForGeoFence = useCallback(
    (id: number, geoFenceInitial?: RouteGeoFenceMultiPolygon) => {
      const geoFence = geoFenceInitial?.id
        ? {
            id: geoFenceInitial.id,
            name: geoFenceInitial.geoFenceName,
            polygons: getPolygonsFromGeoFenceCoordinates(geoFenceInitial.geoFenceCoordinates),
          }
        : find(mapGeoFences, geoFence => geoFence.id === id);

      if (!geoFence) return [];

      return map(geoFence.polygons, polygon => ({
        type: 'Feature',
        properties: { polygonId: polygon.id, geoFenceName: geoFence.name, geoFenceId: geoFence.id },
        geometry: {
          type: 'Polygon',
          coordinates: [polygon.coordinates],
        },
      })) as GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>[];
    },
    [mapGeoFences],
  );

  const getAllGeoJsonForEditing = useCallback(() => {
    return flatMap(mapGeoFences, geoFence => {
      return map(
        geoFence.polygons,
        polygon =>
          ({
            type: 'Feature',
            properties: {
              polygonId: polygon.id,
              geoFenceName: geoFence.name,
              geoFenceId: geoFence.id,
            },
            geometry: {
              type: 'Polygon',
              coordinates: [polygon.coordinates],
            },
          } as GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>),
      );
    });
  }, [mapGeoFences]);

  const updatePolygonInGeoFence = useCallback(
    (geoFenceId: number, polygonId: number, coordinates: number[][]) => {
      const geoFence = find(mapGeoFences, geoFence => geoFence.id === geoFenceId);
      if (!geoFence) return;

      const polygon = find(geoFence.polygons, polygon => polygon.id === polygonId);
      if (!polygon) return;

      setMapGeoFences(prevMapGeoFences =>
        map(prevMapGeoFences, geoFence => {
          if (geoFence.id === geoFenceId) {
            if (!polygon) return geoFence;

            return {
              ...geoFence,
              polygons: map(geoFence.polygons, polygon => {
                if (polygon.id === polygonId) {
                  const history = [...polygon.history];
                  history.push(coordinates);
                  return {
                    ...polygon,
                    coordinates,
                    history: [...history],
                  };
                }
                return polygon;
              }),
            };
          }
          return geoFence;
        }),
      );
    },
    [mapGeoFences],
  );

  const checkIfGeoFenceExists = useCallback(
    (id: number) => {
      return find(mapGeoFences, geoFence => geoFence.id === id);
    },
    [mapGeoFences],
  );

  const selectGeoFencePolygon = useCallback(
    (geoFenceId: number, polygonId: number) => {
      const geoFence = find(mapGeoFences, geoFence => geoFence.id === geoFenceId);
      if (!geoFence) return;

      const polygon = find(geoFence.polygons, polygon => polygon.id === polygonId);
      if (!polygon) return;

      setSelectedGeoFence({
        id: geoFence.id,
        polygonId: polygon.id,
      });
    },
    [mapGeoFences],
  );

  const undoPolygonInGeoFence = useCallback(
    (geoFenceId: number, polygonId: number) => {
      const geoFence = find(mapGeoFences, geoFence => geoFence.id === geoFenceId);
      if (!geoFence) return;

      const polygon = find(geoFence.polygons, polygon => polygon.id === polygonId);
      if (!polygon) return;

      setMapGeoFences(prevMapGeoFences =>
        map(prevMapGeoFences, geoFence => {
          if (geoFence.id === geoFenceId) {
            if (!polygon) return geoFence;

            return {
              ...geoFence,
              polygons: map(geoFence.polygons, polygon => {
                if (polygon.id === polygonId) {
                  const history = [...polygon.history];
                  history.length > 1 && history.pop();
                  return {
                    ...polygon,
                    coordinates: history[history.length - 1],
                    history: [...history],
                  };
                }
                return polygon;
              }),
            };
          }
          return geoFence;
        }),
      );
    },
    [mapGeoFences],
  );

  const unselectGeoFencePolygon = useCallback(() => {
    setSelectedGeoFence(null);
  }, []);

  const allGeoFencesGeoJson = useMemo(() => {
    const geoJson = map(mapGeoFences, geoFence => ({
      type: 'Feature',
      properties: { id: geoFence.id, name: geoFence.name },
      geometry: {
        type: 'MultiPolygon',
        coordinates: map(geoFence.polygons, polygon => [polygon.coordinates]).reverse(),
      },
    }));

    return geoJson as GeoJSON.Feature<GeoJSON.MultiPolygon, GeoJSON.GeoJsonProperties>[];
  }, [mapGeoFences]);

  // get all coordinates from all polygons in all geoFences as an array of  objects with lat and lng
  const getAllCoords = useMemo(() => {
    const coords = reduce(
      mapGeoFences,
      (acc, geoFence) => {
        const coordsFromGeoFence = reduce(
          geoFence.polygons,
          (acc, polygon) => {
            const coordsFromPolygon = map(polygon.coordinates, coord => ({ latitude: coord[1], longitude: coord[0] }));
            return [...acc, ...coordsFromPolygon];
          },
          [] as { latitude: number; longitude: number }[],
        );
        return [...acc, ...coordsFromGeoFence];
      },
      [] as { latitude: number; longitude: number }[],
    );
    return coords;
  }, [mapGeoFences]);

  const getGeoFenceCoords = useCallback(
    (geoFenceId: number) => {
      const geoFence = find(mapGeoFences, geoFence => geoFence.id === geoFenceId);
      if (!geoFence) return [] as { latitude: number; longitude: number }[];

      const coords = reduce(
        geoFence.polygons,
        (acc, polygon) => {
          const coordsFromPolygon = map(polygon.coordinates, coord => ({ latitude: coord[1], longitude: coord[0] }));
          return [...acc, ...coordsFromPolygon];
        },
        [] as { latitude: number; longitude: number }[],
      );
      return coords;
    },
    [mapGeoFences],
  );

  const selectedGeoFenceGeo = useMemo(() => {
    if (!selectedGeoFence) return null;

    const geoFence = find(mapGeoFences, geoFence => geoFence.id === selectedGeoFence.id);
    if (!geoFence) return null;

    const polygon = find(geoFence.polygons, polygon => polygon.id === selectedGeoFence.polygonId);
    if (!polygon) return null;

    return {
      id: geoFence.id,
      name: geoFence.name,
      polygonId: polygon.id,
      coordinates: polygon.coordinates,
      history: polygon.history,
    };
  }, [mapGeoFences, selectedGeoFence]);

  const hasChanges = useMemo(() => {
    return (
      some(mapGeoFences, geoFence => {
        return some(geoFence.polygons, polygon => {
          return polygon.history.length > 1;
        });
      }) ||
      some(mapGeoFences, geoFence => some(geoFence.polygons, polygon => polygon.isNew)) ||
      some(mapGeoFences, geoFence => geoFence.removedPolygons && geoFence.removedPolygons.length > 0)
    );
  }, [mapGeoFences]);

  return {
    getAllCoords,
    allGeoFencesGeoJson,
    selectedGeoFenceGeo,
    mapGeoFences,
    hasChanges,
    addGeoFence,
    addPolygonToGeoFence,
    bulkAddGeoFences,
    checkIfGeoFenceExists,
    emptyGeoFences,
    getGeoJsonForGeoFence,
    getAllGeoJsonForEditing,
    getGeoFenceCoords,
    removeGeoFence,
    removePolygonFromGeoFence,
    removeUnsavedGeoFenceOrPolygons,
    selectGeoFencePolygon,
    unselectGeoFencePolygon,
    updatePolygonInGeoFence,
    undoPolygonInGeoFence,
  };
};

export default useGeoFenceControllerMapbox;
