import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import {
  DRIVER_INSPECTION_LOG_DEFAULT_DATE_RANGE_FROM,
  DRIVER_INSPECTION_LOG_DEFAULT_DATE_RANGE_TO,
} from 'src/fleet/constants/driverInspectionLog';
import { loadDriver } from 'src/fleet/ducks';
import { loadDriverInspectionLogs } from 'src/fleet/ducks/driverInspection';
import { getQueryParams } from 'src/utils/services/queryParams';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import DriverInspectionLogPage from './DriverInspectionLogPage';

interface RouteParams {
  driverId: string;
}

const DriverInspectionLogPageResolver: React.FC<RouteComponentProps<RouteParams>> = ({
  match: {
    params: { driverId },
  },
  location: { pathname, search },
}) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);

  let { startDate, endDate } = getQueryParams(search);

  if (!startDate && !endDate) {
    startDate = DRIVER_INSPECTION_LOG_DEFAULT_DATE_RANGE_FROM;
    endDate = DRIVER_INSPECTION_LOG_DEFAULT_DATE_RANGE_TO;
  }

  const loadDependencies = async () => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<unknown>[] = [
      loadDriverInspectionLogs(vendorId, parseInt(driverId), startDate, endDate)(dispatch),
      loadDriver(parseInt(driverId))(dispatch),
    ];

    return Promise.all(dependencies);
  };

  return (
    <>
      <TypedResolver
        successComponent={DriverInspectionLogPage}
        successProps={{
          initialDateRange: {
            from: startDate,
            to: endDate,
          },
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={loadDependencies}
        redirectOnError="/fleet/resources"
      />
    </>
  );
};

export default DriverInspectionLogPageResolver;
