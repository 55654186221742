import { createSelector } from 'reselect';
import { identity } from 'lodash-es';
import { map, orderBy } from 'lodash-es';

import { Facility } from '../../common/interfaces/Facility';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const getFacility = (facility?: Facility) => {
  const weightTicketsDetails = map(facility?.weightTicketsDetails, weightTicketsDetails => ({
    ...weightTicketsDetails,
    name: translate(createTranslationKey(weightTicketsDetails.vehicleType.technicalName, 'vehicles.vehicleTypes')),
  }));

  const weightTicketsDetailsSorted = orderBy(weightTicketsDetails, ['name'], ['asc']);

  return {
    isActive: facility && facility.isActive !== undefined ? facility.isActive : true,
    isWeightTicketEnabled:
      facility && facility.isWeightTicketEnabled !== undefined ? facility.isWeightTicketEnabled : true,
    ...facility,
    weightTicketsDetails: weightTicketsDetailsSorted,
    facilityType: facility && facility.facilityType.id,
    facilitySubType: facility && facility.facilitySubType.id,
  };
};

const facilityEditorFormInitialValues = createSelector(getFacility, identity);

export default facilityEditorFormInitialValues;
