import React from 'react';
import { Checkbox as CheckboxContainer, CheckboxCheck, CheckboxInput, CheckboxText } from './styled';
import { WrappedFieldProps } from 'redux-form';

interface Props extends WrappedFieldProps {
  block?: boolean;
  disabled?: boolean;
  id?: string;
  isStyleAsCircle?: boolean;
  label?: string;
  labelLeft?: string;
  labelRight?: string;
  margin?: string;
  noWrap?: boolean;
  onClick?: React.MouseEventHandler<HTMLLabelElement>;
  padding?: string;
  size?: string;
  withBottomBorder?: boolean;
}

const Checkbox = ({
  block,
  disabled,
  id,
  input,
  isStyleAsCircle,
  label,
  labelLeft,
  labelRight,
  margin,
  noWrap,
  onClick,
  padding,
  size,
  withBottomBorder,
}: Props) => (
  <CheckboxContainer
    block={block}
    isDisabled={disabled}
    margin={margin}
    noWrap={noWrap}
    onClick={onClick}
    padding={padding}
    size={size}
    withBottomBorder={withBottomBorder}
  >
    {!!labelLeft && <CheckboxText leftAlign>{labelLeft}</CheckboxText>}
    <CheckboxInput {...input} type="checkbox" disabled={disabled} checked={input.value} id={id} />
    <CheckboxCheck margin="no" isStyleAsCircle={isStyleAsCircle} />
    {!!label && <CheckboxText>{label}</CheckboxText>}
    {!!labelRight && (
      <CheckboxText rightAlign color="primary">
        {labelRight}
      </CheckboxText>
    )}
  </CheckboxContainer>
);

export default Checkbox;
