import { camelCase, size } from 'lodash-es';

import { Input, MultiSelect, TypedField } from 'src/core/components';
import { OptionDropdownPosition } from 'src/core/components/MultiSelect';
import { TableCell, TableRow } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import translate from 'src/core/services/translate';

const formatText = (selectedOptions: number[], allOptionsSelected: boolean) =>
  allOptionsSelected
    ? translate('routes.allPickupTypes')
    : translate('routes.xPickupTypesSelected', { selected: size(selectedOptions) });

interface Props {
  filteredPickupTypesCount: number;
  index: number;
  vehicleTypeId: number;
}

const DriverPickListDefaultsTableRow: React.FC<Props> = ({ filteredPickupTypesCount, index, vehicleTypeId }) => {
  const { pickupTypes } = useSelector(state => state.routes.pickupTypes);

  const currentPickupTypes = pickupTypes?.find(pickupType => pickupType.vehicleTypeId === vehicleTypeId)?.pickupTypes;

  const currentPickupTypeOptions =
    currentPickupTypes?.map(pickupType => {
      return {
        label: translate(`routes.pickupTypes.${camelCase(pickupType.technicalName)}`),
        value: pickupType.id,
      };
    }) || [];

  const optionDropdownPosition =
    index <= filteredPickupTypesCount / 2 ? OptionDropdownPosition.Bottom : OptionDropdownPosition.Top;

  return (
    <TableRow>
      <TableCell width="50%">{VEHICLE_TYPE_IDS[vehicleTypeId].name}</TableCell>
      <TableCell width="50%">
        <TypedField
          name={`[${index}].vehicleTypeId`}
          component={Input}
          props={{
            type: 'hidden',
          }}
        />
        <TypedField
          name={`[${index}].pickupTypeIds`}
          component={MultiSelect}
          props={{
            placeholder: translate('routes.pickupType'),
            options: currentPickupTypeOptions,
            isClearable: true,
            formatText,
            margin: 'no',
            defaultToAll: false,
            optionDropdownPosition: optionDropdownPosition,
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default DriverPickListDefaultsTableRow;
