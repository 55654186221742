import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { NEW_INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';
import {
  DashboardMapboxRouteDetailsFormValues,
  DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME,
} from 'src/dashboard/components/forms/DashboardMapboxRouteDetailsForm';
import { DASHBOARD_CLUSTERS_SOURCE, DASHBOARD_ROUTE_STOPS_LAYER } from 'src/dashboard/constants/dashboardMapGL';
import { setDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { DELIVERY_UTILITY_ID } from 'src/fleet/constants';
import { AppState } from 'src/store';
import DashboardRouteStopGLPopupResolver from './DashboardRouteStopGLPopupResolver';
import DashboardRouteStopsGLLayer from './DashboardRouteStopsGLLayer';

const mapImages = NEW_INSIGHT_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: DASHBOARD_CLUSTERS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

type Props = {
  map: mapboxgl.Map;
};

export default function DashboardRouteStopsGL({ map }: Props) {
  useMapImages(mapImages, map, mapImagesOptions);
  const dispatch = useDispatch();

  const routeDetailsFormValues = useSelector(
    getFormValues(DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME),
  ) as DashboardMapboxRouteDetailsFormValues;

  const vehicleType = useSelector((state: AppState) => state.dashboard.routesData.routeInformation?.vehicleType.id);

  const isDeliveryUtilityVehicle = vehicleType === DELIVERY_UTILITY_ID;

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === DASHBOARD_CLUSTERS_SOURCE && feature.properties?.layer === DASHBOARD_ROUTE_STOPS_LAYER,
          );

   
        if (feature) {
          dispatch(setDashboardSelectedFeature('routeStop', feature.properties?.id as number));
        }
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <DashboardRouteStopsGLLayer
        showOrderNumbers={!!routeDetailsFormValues?.routeStopsFilters?.showOrderNumbers}
        isDeliveryUtilityVehicle={isDeliveryUtilityVehicle}
      />
      <DashboardRouteStopGLPopupResolver />
    </>
  );
}
