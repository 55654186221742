import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const PERSONAL = 1;
export const BUSINESS = 2;

export const CUSTOMER_ACCOUNT_TYPES = mapKeys(
  [
    { id: PERSONAL, name: translate('common.customerAccountTypes.personal') },
    { id: BUSINESS, name: translate('common.customerAccountTypes.business') },
  ],
  'id',
);
