import { filter, forEach, map } from 'lodash-es';

import { COMPLETED, IN_PROGRESS, SCHEDULED } from 'src/routes/constants';
import { SnowSweeperFiltersFormValues } from '../components/forms/SnowSweeperFiltersForm';
import { NONE_ZERO_ID, ROUTE_ALL_PRIORITY_TYPES_OPTIONS } from 'src/routes/constants/routePriorityTypes';
import translate from 'src/core/services/translate';
import { BRINING_ID, PLOWING_ID, SALTING_ID, SWEEPING_ID } from 'src/common/constants/serviceConfiguration';

const getSnowSweeperFiltersFormInitialValues = (
  snowPlowSettings: any,
  streetSweepingSettings: any,
  filtersConfig: {
    serviceZones: any;
    groups: any;
  },
  isSnowPlow?: boolean,
): SnowSweeperFiltersFormValues => {
  const { serviceZones, groups } = filtersConfig;

  const lastActivity: any = { all: true };

  forEach(
    filter(
      isSnowPlow
        ? snowPlowSettings?.displayConfiguration?.segmentConfiguration
        : streetSweepingSettings?.segmentColorSettings,
      s => !!s.enabled,
    ),
    config => {
      lastActivity[`_${config.id}`] = true;
    },
  );

  const pickupStatusTypeIds: any = {
    all: true,
    [`_${COMPLETED}`]: true,
    [`_${IN_PROGRESS}`]: true,
    [`_${SCHEDULED}`]: true,
  };

  const priorityTypeIds: any = {
    all: true,
  };
  const snowPlowServiceActivityIds: any = {
    all: true,
    [`_${PLOWING_ID}`]: true,
    [`_${SALTING_ID}`]: true,
    [`_${BRINING_ID}`]: true,
    [`_${NONE_ZERO_ID}`]: true,
  };

  const streetSweepererviceActivityIds: any = {
    all: true,
    [`_${SWEEPING_ID}`]: true,
    [`_${NONE_ZERO_ID}`]: true,
  };

  ROUTE_ALL_PRIORITY_TYPES_OPTIONS.forEach(priority => {
    priorityTypeIds[`_${priority.value}`] = true;
  });

  // service zones
  const serviceZonesFilters = map(serviceZones, (serviceZone: any) => ({
    name: serviceZone.name,
    id: serviceZone.id,
  }));
  serviceZonesFilters.unshift({
    name: translate('dashboard.none'),
    id: NONE_ZERO_ID,
  });

  const serviceZoneIds: any = { isAll_serviceZoneIds: true };

  serviceZonesFilters.forEach(serviceZone => {
    serviceZoneIds[`_${serviceZone.id}`] = true;
  });

  // groups
  const groupIds: any = {
    isAll_groupIds: true,
    [`_${NONE_ZERO_ID}`]: true,
  };

  groups.forEach((group: { id: any }) => {
    groupIds[`_${group.id}`] = true;
  });

  return {
    lastActivity,
    pickupStatusTypeIds,
    priorityTypeIds,
    serviceActivityIds: isSnowPlow ? snowPlowServiceActivityIds : streetSweepererviceActivityIds,
    serviceZoneIds,
    isAll_serviceZoneIds: true,
    routeId: {},
    groupIds,
    isAll_groupIds: true,
    showVehiclePositions: true,
    searchTerm: '',
    isLastActivity: true,
  };
};

export default getSnowSweeperFiltersFormInitialValues;
