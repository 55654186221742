import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { RouteComponentProps, withRouter } from 'react-router';
import React from 'react';

import { AppState } from 'src/store';
import { CustomerCommunicationTableRow } from './CustomerCommunicationTableRow';
import { Email } from '../../../interfaces/Email';
import { Message, Panel, PanelSection, PanelSectionGroup } from '../../../../core/components/styled';
import {
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageTitleContainer,
} from '../../../../common/components/styled';
import { Table } from '../../../../core/components';
import translate from '../../../../core/services/translate';

const tableCells = [
  { name: 'email', label: '', width: '4%' },
  { name: 'timestamp', label: `${translate('common.date')} / ${translate('common.time')}`, width: '10%' },
  { name: 'from', label: translate('common.from'), width: '13%' },
  { name: 'source', label: `${translate('common.source')} (${translate('customers.emails.userId')})`, width: '15%' },
  { name: 'to', label: translate('common.to'), width: '15%' },
  { name: 'issue', label: translate('common.issue'), width: '15%' },
  { name: 'location', label: `${translate('common.location')} / ${translate('common.address')}`, width: '15%' },
  { name: 'service', label: translate('common.service'), width: '13%' },
];

interface Props extends RouteComponentProps<{ customerId: string }> {
  currentServiceHistoryPageUrl?: string;
  customerAccountNumber: string;
  customerName: string;
  customerType: string;
  emails: Email[];
  push: (url: string) => void;
}

const CustomerCommunicationsPage: React.FC<Props> = ({
  currentServiceHistoryPageUrl,
  customerAccountNumber,
  customerName,
  customerType,
  emails,
  match: {
    params: { customerId },
  },
  push,
}) => {
  const goBackToPreviousPage = () =>
    currentServiceHistoryPageUrl
      ? push(currentServiceHistoryPageUrl)
      : push(`/customers/customers/${customerId}/serviceHistory`);

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction
              id={`customers-${customerId}-communications-back-button`}
              onClick={goBackToPreviousPage}
            >
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle>{translate('customers.emails.communication')}</PageTitle>
            <PageSubtitle>
              {customerName} ({customerAccountNumber ? `${customerAccountNumber} - ` : ''}
              {customerType})
            </PageSubtitle>
          </PageTitleContainer>
        </PageDetails>
      </PageHeader>
      <Panel>
        <PanelSectionGroup isLoading={false}>
          <PanelSection>
            {emails.length ? (
              <Table cells={tableCells} rows={emails} rowComponent={CustomerCommunicationTableRow} />
            ) : (
              <Message padding="sMedium">{translate('customers.emails.noEmails')}</Message>
            )}
          </PanelSection>
        </PanelSectionGroup>
      </Panel>
    </PageContent>
  );
};

const mapStateToProps = (state: AppState) => ({
  currentServiceHistoryPageUrl: state.customers.serviceHistory.currentServiceHistoryPageUrl,
});

const mapDispatchToProps = {
  push,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerCommunicationsPage));
