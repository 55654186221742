import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import {
  loadStationaryAlert as doLoadStationaryAlert,
  saveStationaryAlert as doSaveStationaryAlert,
} from '../services/stationaryAlerts';

// Actions
const START_LOAD = 'vendors/stationaryAlerts/START_LOAD';
const COMPLETE_LOAD = 'vendors/stationaryAlerts/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/stationaryAlerts/FAIL_LOAD';
const START_SAVE = 'vendors/stationaryAlerts/START_SAVE';
const COMPLETE_SAVE = 'vendors/stationaryAlerts/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/stationaryAlerts/FAIL_SAVE';
const RESET = 'vendors/stationaryAlerts/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  stationaryAlert: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          stationaryAlert: action.stationaryAlert,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE: {
      return update(state, {
        $merge: { isSaving: false },
      });
    }

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (stationaryAlert: any) => ({
  type: COMPLETE_LOAD,
  stationaryAlert,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (stationaryAlert: any) => ({
  type: COMPLETE_SAVE,
  stationaryAlert,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadStationaryAlert = (vendorId: number, vehicleTypeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadStationaryAlertPromise = doLoadStationaryAlert(vendorId, vehicleTypeId);
  loadStationaryAlertPromise
    .then(stationaryAlert => dispatch(completeLoad(stationaryAlert)))
    .catch(() => dispatch(failLoad()));

  return loadStationaryAlertPromise;
};

export const saveStationaryAlert = (vendorId: number, stationaryAlert: any) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveStationaryAlertPromise = doSaveStationaryAlert(vendorId, stationaryAlert);
  saveStationaryAlertPromise
    .then(savedStationaryAlert => dispatch(completeSave(savedStationaryAlert)))
    .catch(() => dispatch(failSave()));

  return saveStationaryAlertPromise;
};

export const resetStationaryAlerts = () => ({
  type: RESET,
});
