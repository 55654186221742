import React from 'react';

import { LinkAlert } from './styled/SOSAlert';
import translate from 'src/core/services/translate';

type Props = {
  onOpenVideoFootageModal: () => void;
};

const VideoMessageTriggerModal: React.FC<Props> = ({ onOpenVideoFootageModal }) => {
  return (
    <>
      {translate('routes.videoRequest.message.please')}{' '}
      <LinkAlert onClick={() => onOpenVideoFootageModal()}>
        {translate('routes.videoRequest.message.clickHere')}
      </LinkAlert>{' '}
      {translate('routes.videoRequest.message.toView')}
    </>
  );
};

export default VideoMessageTriggerModal;
