import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

interface Props {
  isCursorPointer?: boolean;
  hasBorderRight?: boolean;
  isActive?: boolean;
}

export const ContainerButton = styled.div`
  margin-top: 20px;
  text-align: right;

  button {
    margin-left: 10px;
  }
`;

export const TopToggleButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px ${props => transparentize(0.9, props.theme.grayBase)};
`;

export const TopToggleIconButton = styled.div<Props>`
  white-space: nowrap;
  min-width: 100px;
  padding: 15px;

  button {
    width: 100%;
    text-align: center;
    span,
    svg {
      color: ${props => props.theme.black};
    }
  }

  ${props =>
    props.isActive &&
    css`
      span,
      svg {
        color: ${props => props.theme.brandPrimary}!important;
      }
    `}

  ${props =>
    props.hasBorderRight &&
    css`
      border-right: 2px solid ${props => props.theme.grayLight};
    `};
`;
