import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import humps from 'humps';

import { useSelector } from 'src/core/hooks/useSelector';
import {
  MapGLPopupWrapper,
  MapGLPopup,
  MapGLPopupTitle,
  MapGLPopupContent,
} from 'src/common/components/map/MapGLPopup';
import { Box } from 'src/core/components/styled/Box';
import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { date } from 'src/utils/services/formatter';
import { getBlobIdFromUrl } from 'src/utils/imageHelpers';
import { getCityInsightId, getCityInsightIconType } from './utils';
import { MapInfoImageWrapper, MapInfoImage } from 'src/core/components/styled';
import { MATERIAL_CONTAMINATION_ID } from 'src/insights/constants';
import { RouteImagesModal } from 'src/routes/components/modals';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';

export default function RouteMapCityInsightsGLPopup() {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { cityInsights } = useSelector(state => state.routes.routeMapCityInsights);
  const { selectedFeature } = useSelector(state => state.routes.mapControls);
  const { locationFlagTypes } = useSelector(state => state.common.locationFlagTypes);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  const cityInsight = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.cityInsights) {
      return undefined;
    }

    return cityInsights.find(cityInsight => getCityInsightId(cityInsight) === selectedFeature.id);
  }, [selectedFeature, cityInsights]);

  const singleInsight = !cityInsight?.isCluster ? cityInsight?.communityInsights[0] : null;

  if (!cityInsight || !singleInsight) {
    return null;
  }

  const isMaterialContamination = singleInsight.locationFlaggingTypeId === MATERIAL_CONTAMINATION_ID;
  const hasMaterialSubtypes = isMaterialContamination && (singleInsight.contaminationSubTypes?.length || 0) > 0;
  const contaminationSubTypesName =
    hasMaterialSubtypes &&
    singleInsight?.contaminationSubTypes &&
    singleInsight.contaminationSubTypes
      .map(contaminationSubType =>
        translate(createTranslationKey(contaminationSubType, 'insights.contaminationSubType')),
      )
      .join(', ');
  const contaminationSubTypeFormatted = hasMaterialSubtypes && `: ${contaminationSubTypesName}`;

  let images: Array<{
    contaminationSubTypesName?: string;
    imageBlobId: string;
    imageUrl: string;
    rawImageUrl?: string;
    issueName: string;
    locationName: string;
    routeName?: string;
    timeStamp: string;
  }> = [];

  cityInsight.communityInsights.forEach(cityInsight => {
    const contaminationSubTypesName =
      cityInsight?.contaminationSubTypes &&
      cityInsight.contaminationSubTypes
        .map(contaminationSubType =>
          translate(createTranslationKey(contaminationSubType, 'insights.contaminationSubType')),
        )
        .join(', ');

    const locationFlagType = locationFlagTypes.find(
      locationFlagType => locationFlagType.id === cityInsight.locationFlaggingTypeId,
    );

    const imageInfo = {
      issueName: translate(createTranslationKey(locationFlagType?.technicalName, 'vendors.locationFlags')),
      locationName: cityInsight.address,
      routeName: routeSummary?.name,
      timeStamp: cityInsight.locationFlagTimeStamp,
      contaminationSubTypesName,
    };

    images = !!cityInsight.images?.length
      ? cityInsight.images.map(image => ({
          ...imageInfo,
          imageUrl: image.imageUrl,
          rawImageUrl: image?.rawImageUrl || image.imageUrl,
          imageBlobId: image.imageId,
        }))
      : !!cityInsight.imageUrl
      ? [
          {
            ...imageInfo,
            imageUrl: cityInsight.imageUrl,
            rawImageUrl: cityInsight?.rawImageUrl || cityInsight.imageUrl,
            imageBlobId: getBlobIdFromUrl(cityInsight.imageUrl),
          },
        ]
      : [];
  });

  const locationFlagType = locationFlagTypes.find(
    locationFlagType => locationFlagType.id === cityInsight.locationFlaggingTypeId,
  );

  return (
    <>
      <MapGLPopupWrapper
        closeOnClick={false}
        latitude={cityInsight.latitude}
        longitude={cityInsight.longitude}
        onClose={() => dispatch(clearRouteMapSelectedFeature())}
      >
        <MapGLPopup>
          <MapGLPopupTitle
            beforeTitleContent={
              <Box mr={8} width={15} height={15}>
                <img src={getCityInsightIconType(cityInsight)?.iconUrl} width="15" height="15" alt="" />
              </Box>
            }
            title={translate(`dashboard.${humps.camelize(locationFlagType?.technicalName || '')}`)}
            afterTitleContent={contaminationSubTypeFormatted}
          />

          <MapGLPopupContent>
            {routeSummary?.name && (
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('routes.route')}
                value={routeSummary.name}
              />
            )}

            {routeSummary?.date && (
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('insights.date')}
                value={date(routeSummary?.date)}
              />
            )}

            {singleInsight.vehicleName && (
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('common.truck')}
                value={singleInsight.vehicleName}
              />
            )}

            {singleInsight.statusId >= 0 && (
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('insights.status')}
                value={singleInsight.statusId === 1 ? translate('insights.cleared') : translate('insights.open')}
              />
            )}

            {singleInsight.address && (
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('insights.address')}
                value={singleInsight.address}
              />
            )}

            {!!images.length && (
              <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={translate('common.images')}>
                <Box mt={5}>
                  <MapInfoImageWrapper
                    overlay={images.length > 1 ? images.length : undefined}
                    onClick={() => setIsImageModalOpen(true)}
                  >
                    <MapInfoImage src={images[0].imageUrl} />
                  </MapInfoImageWrapper>
                </Box>
              </LabeledDataView>
            )}
          </MapGLPopupContent>
        </MapGLPopup>
      </MapGLPopupWrapper>

      {!!isImageModalOpen && <RouteImagesModal images={images} closeModal={() => setIsImageModalOpen(false)} />}
    </>
  );
}
