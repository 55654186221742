import { useDispatch } from 'react-redux';

import { PanelSectionLoading } from 'src/core/components/styled';
import { TypedResolver } from 'src/core/components';
import RouteDetailsModal from './RouteDetailsModal';
import { loadWasteTypes } from 'src/common/ducks';

type Props = {
  closeModal: () => void;
  isDriverExperienceSimple?: boolean;
  isNoRoute?: boolean;
};

export default function RouteDetailsModalResolver(props: Props) {
  const dispatch = useDispatch();

  const loadDependencies = () => {
    return Promise.all([loadWasteTypes()(dispatch)]);
  };

  return (
    <TypedResolver
      loadingComponent={PanelSectionLoading}
      resolve={loadDependencies}
      successComponent={RouteDetailsModal}
      successProps={{ ...props }}
      loadingProps={{ minHeight: 500 }}
    />
  );
}
