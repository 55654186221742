import { map, last } from 'lodash-es';

import { ASC, DESC } from '../../core/constants';
import {
  AUDIT_RATE,
  AUDIT_TIME,
  DISPOSAL_TONS,
  DRIVER_SAFETY,
  EXCEPTION_RATE,
  EXCEPTIONS_BY_HOUSEHOLD,
  EXCEPTIONS,
  FAULT_CODES,
  GEO_FENCES_COVERAGE_STREET_SWEEPING,
  GEO_FENCES_STREET_SWEEPING,
  HARD_ACCELERATION,
  HARD_BREAKING,
  HARD_TURNING,
  MATERIAL_CONTAMINATION,
  OBSTACLES_STREET_SWEEPING,
  OFF_CURB_OBSTACLES,
  OFF_ROUTE,
  ON_CURB_STREET_SWEEPER,
  PARTICIPATION_RATE,
  PICKUPS_PER_HOUR,
  RECYCLE_PARTICIPATION_RATE,
  SET_OUT_RATE,
  SNOW_PLOW_CHART,
  SPEEDING,
  STATIONARY_OFF_ROUTE,
  STATIONARY,
  TRIP_TIME_STREET_SWEEPER,
  TRIP_TIME,
} from '../constants';
import { decimal, duration, number } from '../../utils/services/formatter';
import {
  EXCEPTIONS_BY_CONTAMINATED_HOUSEHOLD,
  STREET_SWEEPING_COMPLETION,
  STREET_SWEEPING_LOADS_DUMPED,
  STREET_SWEEPING_MILES_SWEPT,
  STREET_SWEEPING_WATER_FILLUP,
} from '../constants/reportTypes';
import translate from '../../core/services/translate';

interface ReportData {
  averagePercentage: number;
  averageValue: number;
  history: any;
  insightsData: any[];
  reportDescription: string;
  reportName: string;
  reportType: string;
  unitCount: number;
}

export const formatInsightValue = (insightValue: number | string, { reportType }: ReportData) => {
  const insightValueNumber = Number(insightValue);

  switch (reportType) {
    case AUDIT_TIME:
    case OFF_ROUTE:
    case STATIONARY:
    case STATIONARY_OFF_ROUTE:
    case TRIP_TIME:
    case TRIP_TIME_STREET_SWEEPER:
      return duration(insightValueNumber, 'minutes', 'hh:mm');

    case AUDIT_RATE:
    case PICKUPS_PER_HOUR:
      return Math.round(insightValueNumber);

    case EXCEPTION_RATE:
    case ON_CURB_STREET_SWEEPER:
    case PARTICIPATION_RATE:
    case SET_OUT_RATE:
      return `${decimal(insightValueNumber * 100, 0)}%`;

    case EXCEPTIONS:
    case GEO_FENCES_COVERAGE_STREET_SWEEPING:
    case GEO_FENCES_STREET_SWEEPING:
    case MATERIAL_CONTAMINATION:
    case OBSTACLES_STREET_SWEEPING:
    case RECYCLE_PARTICIPATION_RATE:
    case SNOW_PLOW_CHART:
    case STREET_SWEEPING_COMPLETION:
    case STREET_SWEEPING_MILES_SWEPT:
      return `${number(insightValueNumber)}%`;

    case DISPOSAL_TONS:
      return decimal(insightValueNumber, 2);

    default:
      return number(insightValueNumber);
  }
};

const getFormattedValue = (reportData: ReportData) => {
  const { averagePercentage, averageValue, history, reportType, unitCount } = reportData;
  const lastEvent = last(history) as any;
  const hasActiveVehiclesOnLastEvent = lastEvent && lastEvent.activeVehicles;

  switch (reportType) {
    case AUDIT_RATE:
    case AUDIT_TIME:
    case DISPOSAL_TONS:
    case OFF_ROUTE:
    case PICKUPS_PER_HOUR:
    case STATIONARY:
    case STATIONARY_OFF_ROUTE:
    case TRIP_TIME:
    case TRIP_TIME_STREET_SWEEPER:
      return hasActiveVehiclesOnLastEvent ? formatInsightValue(averageValue, reportData) : '--';

    case EXCEPTION_RATE:
    case ON_CURB_STREET_SWEEPER:
    case PARTICIPATION_RATE:
    case RECYCLE_PARTICIPATION_RATE:
    case SET_OUT_RATE:
    case SNOW_PLOW_CHART:
    case STREET_SWEEPING_COMPLETION:
    case STREET_SWEEPING_MILES_SWEPT:
      return hasActiveVehiclesOnLastEvent ? formatInsightValue(averagePercentage, reportData) : '--';

    case FAULT_CODES:
    case HARD_ACCELERATION:
    case HARD_BREAKING:
    case HARD_TURNING:
    case OFF_CURB_OBSTACLES:
    case SPEEDING:
    case STREET_SWEEPING_LOADS_DUMPED:
    case STREET_SWEEPING_WATER_FILLUP:
    case DRIVER_SAFETY:
      return hasActiveVehiclesOnLastEvent ? formatInsightValue(unitCount, reportData) : '--';

    default:
      return undefined;
  }
};

const getUnit = (reportType: string) => {
  switch (reportType) {
    case AUDIT_RATE:
      return translate('insights.reportTypeUnits.containers/Hr');

    case SPEEDING:
      return translate('insights.reportTypeUnits.violations');

    case AUDIT_TIME:
    case OFF_ROUTE:
    case STATIONARY:
    case STATIONARY_OFF_ROUTE:
    case TRIP_TIME:
    case TRIP_TIME_STREET_SWEEPER:
      return translate('insights.reportTypeUnits.hours(HH:MM)');

    case FAULT_CODES:
      return translate('insights.reportTypeUnits.codes');

    case DISPOSAL_TONS:
      return translate('insights.reportTypeUnits.tons');

    case PICKUPS_PER_HOUR:
      return translate('insights.reportTypeUnits.pickups/Hr');

    case HARD_ACCELERATION:
    case HARD_BREAKING:
    case HARD_TURNING:
    case DRIVER_SAFETY:
      return translate('insights.reportTypeUnits.events');

    case PARTICIPATION_RATE:
    case RECYCLE_PARTICIPATION_RATE:
      return translate('insights.reportTypeUnits.participationRate');

    case SET_OUT_RATE:
      return translate('insights.reportTypeUnits.setOutRate');

    case EXCEPTION_RATE:
      return translate('insights.reportTypeUnits.exceptionRate');

    case ON_CURB_STREET_SWEEPER:
      return translate('insights.reportTypeUnits.onCurbRate');

    case STREET_SWEEPING_COMPLETION:
    case SNOW_PLOW_CHART:
      return translate('insights.reportTypeUnits.snowPlow');

    case OFF_CURB_OBSTACLES:
      return translate('insights.reportTypeUnits.offCurbObstacleRate');

    default:
      return undefined;
  }
};

const getExplain = (reportType: string) => {
  switch (reportType) {
    case DISPOSAL_TONS:
    case FAULT_CODES:
    case HARD_ACCELERATION:
    case HARD_BREAKING:
    case HARD_TURNING:
    case OFF_CURB_OBSTACLES:
    case ON_CURB_STREET_SWEEPER:
    case SPEEDING:
    case STREET_SWEEPING_LOADS_DUMPED:
    case STREET_SWEEPING_WATER_FILLUP:
    case DRIVER_SAFETY:
      return translate('common.inTotal');

    case AUDIT_RATE:
    case AUDIT_TIME:
    case OFF_ROUTE:
    case PICKUPS_PER_HOUR:
    case RECYCLE_PARTICIPATION_RATE:
    case STATIONARY:
    case STATIONARY_OFF_ROUTE:
    case TRIP_TIME:
    case TRIP_TIME_STREET_SWEEPER:
      return translate('common.onAverage');

    default:
      return undefined;
  }
};

const getSeeDetailsPath = (reportType: string) => {
  switch (reportType) {
    case DISPOSAL_TONS:
      return '/insights/disposal-summary';

    default:
      return '/insights/reporting-details';
  }
};

const getTableColumns = (reportType: string) => {
  switch (reportType) {
    case AUDIT_RATE:
      return [translate('vehicles.vehicle'), translate('insights.reportTypeUnits.containers/Hr')];

    case SPEEDING:
      return [translate('vehicles.vehicle'), translate('insights.reportTypeUnits.violations')];

    case AUDIT_TIME:
    case STATIONARY:
    case TRIP_TIME_STREET_SWEEPER:
    case TRIP_TIME:
      return [translate('vehicles.vehicle'), translate('insights.reportTypeUnits.hours(HH:MM)')];

    case FAULT_CODES:
      return [translate('vehicles.vehicle'), translate('insights.reportTypeUnits.codes')];

    case DISPOSAL_TONS:
      return [translate('vehicles.vehicle'), translate('insights.reportTypeUnits.tons')];

    case PICKUPS_PER_HOUR:
      return [translate('vehicles.vehicle'), translate('insights.reportTypeUnits.pickups/Hr')];

    case HARD_ACCELERATION:
    case HARD_BREAKING:
    case HARD_TURNING:
    case DRIVER_SAFETY:
      return [translate('vehicles.vehicle'), translate('insights.reportTypeUnits.events')];

    case PARTICIPATION_RATE:
      return [translate('routes.routeName'), translate('insights.reportTypeUnits.participationRate')];

    case SET_OUT_RATE:
      return [translate('routes.routeName'), translate('insights.reportTypeUnits.setOutRate')];

    case EXCEPTION_RATE:
      return [translate('routes.routeName'), translate('insights.reportTypeUnits.exceptionRate')];

    case EXCEPTIONS:
      return [translate('insights.reportTypeUnits.exceptions'), translate('insights.reportTypeUnits.percentOfTotal')];

    case ON_CURB_STREET_SWEEPER:
      return [translate('vehicles.vehicle'), translate('insights.reportTypeUnits.rate')];

    case SNOW_PLOW_CHART:
    case STREET_SWEEPING_COMPLETION:
      return [translate('routes.routeName'), `% ${translate('common.complete')}`];

    case STREET_SWEEPING_LOADS_DUMPED:
      return [translate('vehicles.vehicle'), `${translate('insights.reportTypes.streetSweepingLoadsDumped')}`];

    case STREET_SWEEPING_WATER_FILLUP:
      return [translate('vehicles.vehicle'), `${translate('insights.reportTypes.streetSweepingWaterFillUps')}`];

    case OFF_CURB_OBSTACLES:
      return [translate('vehicles.offCurbObstacle'), translate('insights.reportTypeUnits.count')];

    case OBSTACLES_STREET_SWEEPING:
      return [
        translate('insights.reportTypes.obstaclesStreetSweeping'),
        translate('insights.reportTypeUnits.percentOfTotal'),
      ];

    case GEO_FENCES_COVERAGE_STREET_SWEEPING:
      return [
        translate('insights.reportTypes.geoFencesCoverageStreetSweeping'),
        translate('insights.reportTypeUnits.percentOfTotal'),
      ];

    case GEO_FENCES_STREET_SWEEPING:
      return [
        translate('insights.reportTypes.geoFencesStreetSweeping'),
        translate('insights.reportTypeUnits.percentOfTotal'),
      ];

    case MATERIAL_CONTAMINATION:
      return [
        translate('insights.reportTypes.materialContamination'),
        translate('insights.reportTypeUnits.percentOfTotal'),
      ];

    case EXCEPTIONS_BY_HOUSEHOLD:
    case EXCEPTIONS_BY_CONTAMINATED_HOUSEHOLD:
      return [translate('insights.reportTypeUnits.exceptions'), translate('insights.reportTypeUnits.percentOfTotal')];

    case OFF_ROUTE:
    case RECYCLE_PARTICIPATION_RATE:
      return [translate('common.route'), translate('insights.reportTypeUnits.rate')];
    case STATIONARY_OFF_ROUTE:
      return [translate('vehicles.vehicle'), translate('insights.reportTypeUnits.hours(HH:MM)')];

    default:
      return undefined;
  }
};

const getTableRows = (reportData: ReportData) =>
  map(reportData.insightsData, ({ name, technicalName, value }) => ({
    name,
    technicalName,
    value,
    formattedValue: formatInsightValue(value, reportData),
  }));

const getSortOrder = (reportType: string) => {
  switch (reportType) {
    case AUDIT_RATE:
    case PICKUPS_PER_HOUR:
      return ASC;

    default:
      return DESC;
  }
};

const getCardDetails = (reportData: ReportData) => {
  const { reportDescription, reportName, reportType } = reportData;

  return {
    explain: getExplain(reportType),
    formattedValue: getFormattedValue(reportData),
    reportDescription,
    reportName,
    reportType,
    seeDetailsPath: getSeeDetailsPath(reportType),
    sortOrder: getSortOrder(reportType),
    tableColumns: getTableColumns(reportType),
    tableRows: getTableRows(reportData),
    unit: getUnit(reportType),
  };
};

export default getCardDetails;
