import React, { Fragment, PureComponent } from 'react';

import { debounce, get } from 'lodash-es';
import { connect } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { ActiveVehicleDropdown } from '..';
import { EquipmentConditionDropdown, EquipmentSizeDropdown, EquipmentTypeDropdown } from '../../../common/components';
import { ROLL_OFF } from '../../../common/constants';
import { serviceTypeIdByTechnicalNameSelector } from '../../../common/ducks';
import { searchCustomerLocations } from '../../../common/services/customers';
import { DatePicker, Input, NavigationPrompt, TypeAhead, TypedField } from '../../../core/components';
import { Button, ButtonSet, Grid, GridColumn, Text } from '../../../core/components/styled';
import { TODAY } from '../../../core/constants';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { isDateValidValidator, isRequired, maxLength50 } from '../../../utils/services/validator';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { Container } from '../../interfaces/containers';
import containerEditorFormInitialValuesSelector from '../../services/containerEditorFormInitialValuesSelector';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

interface FormValues extends Container {}

interface PropsWithoutReduxForm {
  customerTypes?: TechnicalType[];
  lastMovementDate?: string;
  serviceTypeId?: number;
  vendorId?: number;
  id?: number;
  locationId?: number;
  locationName?: string;
  equipmentTypeId?: number;
  daysSinceLastTurn?: number;
  turnsInLast30Days?: number;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

class ContainerEditorForm extends PureComponent<Props> {
  loadCustomerLocationOptions = debounce((searchTerm, onOptionsLoaded) => {
    const { vendorId, customerTypes = [] } = this.props;

    if (!vendorId || searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }

    const customerTypeId = customerTypes.map(value => value.id);
    const stringTypeId = customerTypeId.join();
    searchCustomerLocations(searchTerm, vendorId, stringTypeId).then(onOptionsLoaded);
  }, 500);

  onChangeEquipmentTypeId = () => {
    this.updateFormValue('equipmentSizeId', null);
  };

  updateFormValue = (name: string, value: any) => {
    this.props.change(name, value);
  };

  render() {
    const {
      id,
      serviceTypeId,
      equipmentTypeId,
      daysSinceLastTurn,
      submitSucceeded,
      turnsInLast30Days,
      handleSubmit,
      pristine,
      lastMovementDate,

      initialValues,
    } = this.props;

    const lastEnabledDate = lastMovementDate ? new Date(lastMovementDate) : null;
    const defaultLocationIdOption =
      initialValues.locationName && initialValues.locationId
        ? { label: initialValues.locationName, value: initialValues.locationId }
        : undefined;

    return (
      <Grid centered>
        <NavigationPrompt when={!pristine && !submitSucceeded} />
        <GridColumn size="4/12">
          <form onSubmit={handleSubmit} noValidate>
            <Field
              name="containerNumber"
              component={Input}
              label={translate('containers.containerNumber')}
              validate={[isRequired, maxLength50]}
            />

            <TypedField
              name="locationId"
              component={TypeAhead}
              props={{
                defaultOptions: defaultLocationIdOption ? [defaultLocationIdOption] : undefined,
                defaultValue: defaultLocationIdOption,
                getOptions: this.loadCustomerLocationOptions,
                isClearable: true,
                label: `${translate('common.locationTypes.containerLocation')}`,
              }}
              validate={[isRequired]}
            />

            <Field
              name="equipmentTypeId"
              component={EquipmentTypeDropdown}
              serviceTypeId={serviceTypeId}
              withLabel
              validate={[isRequired]}
              dropdownProps={{ isClearable: true }}
              onChange={this.onChangeEquipmentTypeId}
            />

            <Field
              name="equipmentSizeId"
              component={EquipmentSizeDropdown}
              equipmentTypeId={equipmentTypeId}
              withLabel
              validate={[isRequired]}
              dropdownProps={{ isClearable: true }}
            />

            <Field
              name="equipmentConditionId"
              component={EquipmentConditionDropdown}
              withLabel
              validate={[isRequired]}
              dropdownProps={{ isClearable: true }}
            />

            <Field
              name="lastMovementDate"
              component={DatePicker}
              label={translate('containers.lastMovementDate')}
              isClearable
              validate={[isRequired, isDateValidValidator]}
              disabledDays={[{ after: TODAY, before: lastEnabledDate }]}
            />

            <Field
              name="droppedByVehicleId"
              component={ActiveVehicleDropdown}
              validate={[isRequired]}
              dropdownProps={{ isClearable: true }}
              label={translate('containers.droppedByVehicle')}
            />

            {!!id && (
              <Fragment>
                <Text block margin="lMedium no small">
                  <Text weight="light">{translate('vehicles.daysSinceLastTurn')}</Text>: {daysSinceLastTurn}
                </Text>

                <Text block>
                  <Text weight="light">{translate('vehicles.turnsInLast30Days')}</Text>: {turnsInLast30Days}
                </Text>
              </Fragment>
            )}

            <ButtonSet margin="large no no">
              <Button type="submit" color="primary" id="save-container-button">
                {translate('common.save')}
              </Button>
            </ButtonSet>
          </form>
        </GridColumn>
      </Grid>
    );
  }
}

const formSelector = formValueSelector('containerEditorForm');

const mapStateToProps = (state: AppState) => {
  const vendorId = currentVendorIdSelector(state.account.login, state.vendors.defaultVendor);

  return {
    serviceTypeId: serviceTypeIdByTechnicalNameSelector(state.common.serviceTypes, ROLL_OFF),
    id: Number(formSelector(state, 'id')),
    lastMovementDate: get(get(state.fleet.container, 'container'), 'lastMovementDate'),
    equipmentTypeId: Number(formSelector(state, 'equipmentTypeId')),
    daysSinceLastTurn: formSelector(state, 'daysSinceLastTurn'),
    turnsInLast30Days: formSelector(state, 'turnsInLast30Days'),
    initialValues: vendorId ? containerEditorFormInitialValuesSelector(state.fleet.container, vendorId) : {},
    vendorId,
    customerTypes: state.fleet.customerTypes.customerTypes,
  };
};

export default connect(mapStateToProps)(
  reduxForm<FormValues, PropsWithoutReduxForm>({
    form: 'containerEditorForm',
    onSubmitFail: focusFirstInvalidField,
  })(ContainerEditorForm),
);
