import React, { PureComponent, Fragment } from 'react';

import { kebabCase } from 'lodash-es';

import { ActionButtonTooltip, ThumbnailSet } from '../../../../core/components';
import { date, time } from '../../../../utils/services/formatter';
import { DisposalSummary } from '../../../interfaces/DisposalSummary';
import { TableCell, TableRow, Text } from '../../../../core/components/styled';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { WeightTicketImagesModal } from '../../modals';
import translate from 'src/core/services/translate';

interface Props extends DisposalSummary {
  wasteTypes: TechnicalType[];
}

interface State {
  isRouteImagesModalOpen: boolean;
}

class RouteDisposalsSummaryTableRow extends PureComponent<Props, State> {
  state = {
    isRouteImagesModalOpen: false,
  };

  openRouteImagesModal = () => {
    this.setState({ isRouteImagesModalOpen: true });
  };

  closeRouteImagesModal = () => {
    this.setState({ isRouteImagesModalOpen: false });
  };

  render() {
    const {
      customerName,
      disposalSiteAddress,
      disposalSiteName,
      disposalTimeLocal,
      image,
      isTicketAddedByAssistingVehicle,
      routeDate,
      routeName,
      ticketNumber,
      vehicleName,
      vehicleType,
      wasteMaterialTypeId,
      wasteTypes,
      weightInTons,
    } = this.props;

    const { isRouteImagesModalOpen } = this.state;
    const weightTicket = { ticketNumber, disposalSiteAddress, image, customerName };

    const wasteMaterialType = wasteTypes.find(wasteType => wasteType.id === wasteMaterialTypeId)?.name;

    return (
      <Fragment>
        <TableRow>
          <TableCell width="11%">
            <Text weight="medium" margin="no no xxSmall" singleLine>
              {date(routeDate)}
            </Text>
          </TableCell>
          <TableCell width="9%">{ticketNumber}</TableCell>
          <TableCell width="14%" vertical justifyContent="center">
            {vehicleName ? (
              <>
                <Text weight="medium" margin="no no xxSmall" hasIcon={isTicketAddedByAssistingVehicle && !!vehicleName}>
                  {vehicleName}
                  {isTicketAddedByAssistingVehicle && vehicleName && (
                    <ActionButtonTooltip
                      margin="no no no xSmall"
                      icon="infoFill"
                      tooltip={translate('routeAssist.assistingVehicle')}
                      tooltipAsString
                      iconSize="sMedium"
                      color="alert"
                      hasTextWithIcon={true}
                    />
                  )}
                </Text>
                <Text weight="light" margin="no">
                  {vehicleType}
                </Text>
              </>
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell width="15%">
            <Text weight="light" margin="no no xxSmall">
              {routeName}
            </Text>
          </TableCell>
          <TableCell width="10%">{wasteMaterialType}</TableCell>
          <TableCell width="8%">{weightInTons}</TableCell>
          <TableCell width="11%" vertical justifyContent="center">
            <Text weight="medium" margin="no no xxSmall" singleLine>
              {disposalTimeLocal ? date(disposalTimeLocal) : '-'}
            </Text>
            <Text weight="light" margin="no" singleLine>
              {time(disposalTimeLocal)}
            </Text>
          </TableCell>
          <TableCell width="14%">{disposalSiteName}</TableCell>
          <TableCell width="8%" align="center">
            {!!image && (
              <ThumbnailSet
                id={`open-route-${kebabCase(routeName)}-images-button`}
                images={[image.url]}
                onClick={this.openRouteImagesModal}
              />
            )}
          </TableCell>
        </TableRow>
        {!!isRouteImagesModalOpen && (
          <WeightTicketImagesModal weightTicket={weightTicket} closeModal={this.closeRouteImagesModal} />
        )}
      </Fragment>
    );
  }
}

export default RouteDisposalsSummaryTableRow;
