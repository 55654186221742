import { http } from '../../core/services/http';
import { Feature, RawFeature } from '../interfaces/Features';
import { transformFeatures, transformRawFeatures } from './featuresTransformers';

export const loadFeatures = (vendorId: number) =>
  http.get<RawFeature[]>(`/vendors/${vendorId}/features`).then(response => transformRawFeatures(response.data));

export const saveFeatures = (vendorId: number, features: Feature[]) =>
  http
    .post(`/vendors/${vendorId}/features`, transformFeatures(features))
    .then(response => transformRawFeatures(response.data));
