import { Fragment, PureComponent } from 'react';

import { connect } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { find } from 'lodash-es';

import { AppState } from '../../../../src/store';
import { PageLoading } from '../../../common/components/styled';
import { Radio } from '../../../core/components';
import {
  Button,
  ButtonSet,
  Grid as GridUntyped,
  GridColumn as GridColumnUntyped,
  PanelSection,
  TableCell,
  TableRow,
  Text,
} from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { RESIDENTIAL_ID, ROLL_OFF_ID } from '../../../fleet/constants';
import RouteSequenceConfiguration from '../../../vendors/components/RouteSequenceConfiguration';
import { routeSequenceMappedSettingsFormInitialValuesSelector } from '../../../vendors/services/routeSequenceSettingsFormInitialValuesSelector';

const BOTH_SIDES = '1';
const RIGHT_SIDE = '3';

const Grid = GridUntyped as any;
const GridColumn = GridColumnUntyped as any;

interface ComponentProps {
  vehicleTypeId: number;
  intermediateLocationId?: number | null;
  closeModal: () => void;
}

interface Props extends ComponentProps {
  isLoading: boolean;
  routeSequenceSettings?: any[];
  disposalFacilities: any[];
  amBreakTime?: Date;
  pmBreakTime?: Date;
  lunchBreakTime?: Date;
  maxRouteTime?: Date;
}

interface State {
  bothSidesServiceVisible: boolean;
  hasError: boolean[];
}

class ServiceSideForm extends PureComponent<Props & InjectedFormProps<any, Props>, State> {
  constructor(props: Props & InjectedFormProps<any, Props>) {
    super(props);

    this.state = {
      bothSidesServiceVisible: true,
      hasError: [],
    };
  }

  onServiceSideChange = () => {
    this.setState({ bothSidesServiceVisible: !this.state.bothSidesServiceVisible });
  };

  setHasError = (index: number, value: boolean) => {
    const { hasError } = this.state;
    const newHasError = [...hasError];
    newHasError[index] = value;
    this.setState({ hasError: [...newHasError] });
  };

  render() {
    const {
      change,
      closeModal,
      handleSubmit,
      isLoading,
      routeSequenceSettings,
      vehicleTypeId,
      disposalFacilities,
      amBreakTime,
      pmBreakTime,
      lunchBreakTime,
      maxRouteTime,
    } = this.props;
    const { bothSidesServiceVisible, hasError } = this.state;

    const routeStartTime = routeSequenceSettings
      ? routeSequenceSettings.find(routeSequenceSetting => routeSequenceSetting.vehicleTypeId === vehicleTypeId)
          .routeStartTime
      : undefined;

    const additionalConfigurationsVisible = vehicleTypeId !== ROLL_OFF_ID;

    return (
      <form onSubmit={handleSubmit}>
        <PanelSection padding="no xSmall" vertical>
          {isLoading ? (
            <PageLoading height="140px" />
          ) : (
            <Fragment>
              {vehicleTypeId === RESIDENTIAL_ID && (
                <TableRow borderWidth={'0'}>
                  <Grid multiLine>
                    <TableCell width="100%">
                      <Text size="medium" weight="medium" margin="no no no xSmall">
                        {translate('routes.serviceSideFormModalTitle')}
                      </Text>
                    </TableCell>
                    <GridColumn size="12/12" padding="no no small ssMedium">
                      <Field
                        name="serviceSide"
                        component={Radio}
                        type="radio"
                        label={translate('routes.bothSides')}
                        value={BOTH_SIDES}
                        onChange={() => this.onServiceSideChange()}
                      />
                    </GridColumn>
                    <GridColumn size="12/12" padding="no no small ssMedium">
                      <Field
                        name="serviceSide"
                        component={Radio}
                        type="radio"
                        label={translate('routes.rightSide')}
                        value={RIGHT_SIDE}
                        onChange={() => this.onServiceSideChange()}
                      />
                    </GridColumn>
                  </Grid>
                </TableRow>
              )}

              <TableRow
                hidden={!additionalConfigurationsVisible}
                visible={additionalConfigurationsVisible}
                borderWidth={'0'}
              >
                <br />
                <RouteSequenceConfiguration
                  showIntermediateFacility
                  bothSidesServiceVisible={bothSidesServiceVisible}
                  change={change}
                  routeStartTime={routeStartTime}
                  serviceTimePerStopVisible={true}
                  vehicleTypeId={vehicleTypeId}
                  disposalFacilities={disposalFacilities}
                  amBreakTime={amBreakTime}
                  pmBreakTime={pmBreakTime}
                  lunchBreakTime={lunchBreakTime}
                  maxRouteTime={maxRouteTime}
                  hasError={hasError}
                  setHasError={this.setHasError}
                />
              </TableRow>
              <TableRow borderWidth={'0'} textAlign="center">
                <br />
                <br />
                {translate('routes.routeSequenceStartMessage1')}
                <br />
                {translate('routes.routeSequenceStartMessage2')}
              </TableRow>
              <TableRow borderWidth={'0'}>
                <ButtonSet margin="medium auto no">
                  <Button
                    type="button"
                    color="secondary"
                    margin="no small"
                    id="cancel-sequence-route-button"
                    onClick={closeModal}
                  >
                    {translate('common.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    id="confirm-sequence-route-button"
                    disabled={find(hasError, el => el && true)}
                  >
                    {translate('common.save')}
                  </Button>
                </ButtonSet>
              </TableRow>
            </Fragment>
          )}
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, props: ComponentProps) => {
  const formSelector = formValueSelector('serviceSideForm');
  return {
    isLoading: state.routes.routeSequence.isLoading,
    initialValues: routeSequenceMappedSettingsFormInitialValuesSelector(
      state.vendors.routeSequenceSettings,
      BOTH_SIDES,
      props.intermediateLocationId,
    ),
    routeSequenceSettings: state.vendors.routeSequenceSettings.routeSequenceSettings,
    disposalFacilities: state.fleet.facilities.disposalFacilities,
    amBreakTime: formSelector(state, `${props.vehicleTypeId}.amBreakTime`),
    pmBreakTime: formSelector(state, `${props.vehicleTypeId}.pmBreakTime`),
    lunchBreakTime: formSelector(state, `${props.vehicleTypeId}.lunchBreakTime`),
    maxRouteTime: formSelector(state, `${props.vehicleTypeId}.maxRouteTime`),
  };
};

export default connect(mapStateToProps)(
  reduxForm<any, Props>({
    enableReinitialize: true,
    form: 'serviceSideForm',
  })(ServiceSideForm),
);
