import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { RouteComponentProps } from 'react-router';
import { pick } from 'lodash-es';
import moment from 'moment';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DuckFunction } from '../../../contracts/ducks';
import { Equipment } from '../../interfaces/Opportunities';

import { getQueryParams } from '../../../utils/services/queryParams';
import { Grid, GridColumn, ModalTitle, ModalSubtitle } from '../../../core/components/styled';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { loadOpportunities, updateSourcingBid } from '../../ducks/opportunities';
import { Modal } from '../../../core/components';
import EnterBidForm from '../forms/EnterBidForm';
import translate from '../../../core/services/translate';
import { YES } from '../../constants/status';
import { useIsMobileView } from 'src/utils/hooks';
import { closeMobilePageDetailOverlay } from 'src/core/ducks/mobilePage';
import { CHANGE_OF_BILLING } from 'src/opportunity/constants/requestType';
import { updateHaulerOpportunity } from 'src/opportunity/ducks';

const formSelector = formValueSelector('enterBid');

interface RouteParams {
  token: string;
}
interface ComponentProps {
  caseId: number;
  equipment: Equipment;
  equipmentType: string;
  eventServiceId: number;
  eventType: string;
  isEquipmentCustomerOwned: boolean;
  itemId: number;
  offer: any;
  userId: string;
  vendorId: number;
  onCancel(pristine: boolean): void;
}
interface ReduxProps {
  requestedChanges: any;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  loadOpportunities: DuckFunction<typeof loadOpportunities>;
  updateSourcingBid: DuckFunction<typeof updateSourcingBid>;
  updateHaulerOpportunity: DuckFunction<typeof updateHaulerOpportunity>;
  closeMobilePageDetailOverlay: DuckFunction<typeof closeMobilePageDetailOverlay>;
}

type Props = ComponentProps & ReduxProps & RouteComponentProps<RouteParams>;

const EnterBidModal: React.FC<Props> = props => {
  const { eventType, onCancel } = props;
  const onSubmit = React.useCallback(() => {
    const {
      eventServiceId,
      itemId,
      location: { search },
      match: {
        params: { token },
      },
      requestedChanges,
      userId,
      vendorId,
      loadDispatchOpportunitiesCount,
      loadOpportunities,
      updateSourcingBid,
      updateHaulerOpportunity,
      onCancel,
      closeMobilePageDetailOverlay,
    } = props;

    const { showActionRequired, startDate, endDate } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES;
    const bidOpportunityObj: any = {};
    const requestChangeForBillingObj: any = {};

    if (eventType === CHANGE_OF_BILLING) {
      requestChangeForBillingObj.itemId = itemId;
      requestChangeForBillingObj.Response = 'RequestChanges';
      requestChangeForBillingObj.RequestedChanges = requestedChanges;
      requestChangeForBillingObj.RequestedChanges.deliveryDate = moment(requestedChanges.deliveryDate).format(
        'YYYY-MM-DD',
      );
      requestChangeForBillingObj.RequestedChanges.frequencyDays = requestedChanges.frequencyDays?.toString();
    } else {
      bidOpportunityObj.eventServiceId = eventServiceId;
      bidOpportunityObj.itemId = itemId;
      bidOpportunityObj.offer = pick(requestedChanges, 'haulerRateDetails');

      if (pick(requestedChanges, 'notes')?.notes) {
        let rawNote = { title: '', userNote: '' };
        rawNote.title = 'Hauler Notes';
        rawNote.userNote = pick(requestedChanges, 'notes').notes;
        bidOpportunityObj.note = rawNote;
      }

      bidOpportunityObj.offer.haulerRateDetails = bidOpportunityObj.offer.haulerRateDetails.map((item: any) => {
        return { ...item, rateInDollar: Number(item.rateInDollar) };
      });
    }

    const promise =
      eventType === CHANGE_OF_BILLING
        ? updateHaulerOpportunity(
            requestChangeForBillingObj,
            userId,
            token,
            vendorId,
            isActionRequired,
            startDate,
            endDate,
            true,
          )
        : updateSourcingBid(bidOpportunityObj);

    if (!promise) {
      return;
    }

    promise
      .then(() => {
        loadDispatchOpportunitiesCount(userId, vendorId, '');
        loadOpportunities(userId, vendorId, isActionRequired, startDate, endDate);
        createSuccessNotification(translate('opportunity.opportunitySave'));
        onCancel(true);
        closeMobilePageDetailOverlay();
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  }, [props, eventType]);

  const isMobile = useIsMobileView();
  return (
    <Modal size="small">
      <ModalTitle>
        {eventType === CHANGE_OF_BILLING
          ? translate('opportunity.acceptChangeOfBilling')
          : translate('opportunity.enterBid')}
      </ModalTitle>
      <ModalSubtitle />
      <Grid centered>
        <GridColumn size={isMobile ? '12/12' : '10/12'}>
          <EnterBidForm {...props} onSubmit={onSubmit} onCancel={onCancel} />
        </GridColumn>
      </Grid>
    </Modal>
  );
};

const mapStateToProps = (state: AppState, ownProps: any) => ({
  requestedChanges: formSelector(state, `settings${ownProps.caseId}.requestedChanges`),
  isSaving: state.opportunity.opportunities.isSaving,
});

const mapDispatchToProps = {
  loadOpportunities,
  loadDispatchOpportunitiesCount,
  updateSourcingBid,
  closeMobilePageDetailOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterBidModal);
