export const DataSourceIdsGL = {
  routeLocations: 'RouteLocations',
  containerInsights: {
    completed: 'Completed',
    scheduled: 'Scheduled',
    inProgress: 'InProgress',
    issueReported: 'IssueReported',
  },
  vehicleTrackings: {
    line: 'VehicleTrackingsLine',
    point: 'VehicleTrackingsPoint',
  },
  debugVehicleTrackings: {
    confirmationPoint: 'ConfirmationPoint',
    line: 'DebugVehicleTrackingsLine',
    point: 'DebugVehicleTrackingsPoint',
    polygon: 'DebugVehicleTrackingsPolygon',
    polygonReduced: 'DebugVehicleTrackingsPolygonReduced',
  },
  vehiclePositions: {
    point: 'VehicleCurrentPosition',
    line: 'VehicleLine',
  },
};
