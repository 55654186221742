import { Modal } from '../../../core/components';
import translate from '../../../core/services/translate';
import { TransferContainersForm } from '../forms';
import { TransferContainersFormValues } from '../forms/TransferContainersForm';

type Props = {
  closeModal: () => void;
  onSubmit: (formData: TransferContainersFormValues) => void;
};

export default function TransferContainersModal({ closeModal, onSubmit }: Props) {
  return (
    <Modal title={translate('containers.transferContainers')} overflow="visible" onClose={closeModal} padding="medium">
      <TransferContainersForm onSubmit={onSubmit} />
    </Modal>
  );
}
