import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { BulkyItemScheduler } from '../interfaces/BulkyItemScheduler';
import {
  loadBulkyItemScheduler as doLoadBulkyItemScheduler,
  saveBulkyItemScheduler as doSaveBulkyItemScheduler,
  loadBulkyCategoryTypes as doLoadBulkyCategoryTypes,
  loadBulkyItemTypes as doLoadBulkyItemTypes,
  loadPeriodTypes as doLoadPeriodTypes,
} from '../services/bulkyItemScheduler';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

// Actions
const START_LOAD = 'vendors/bulkyItemScheduler/START_LOAD';
const COMPLETE_LOAD = 'vendors/bulkyItemScheduler/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/bulkyItemScheduler/FAIL_LOAD';
const START_SAVE = 'vendors/bulkyItemScheduler/START_SAVE';
const COMPLETE_SAVE = 'vendors/bulkyItemScheduler/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/bulkyItemScheduler/FAIL_SAVE';
const START_LOAD_BULKY_CATEGORY_TYPES = 'vendors/bulkyItemScheduler/START_LOAD_BULKY_CATEGORY_TYPES';
const COMPLETE_LOAD_BULKY_CATEGORY_TYPES = 'vendors/bulkyItemScheduler/COMPLETE_LOAD_BULKY_CATEGORY_TYPES';
const FAIL_LOAD_BULKY_CATEGORY_TYPES = 'vendors/bulkyItemScheduler/FAIL_LOAD_BULKY_CATEGORY_TYPES';
const START_LOAD_BULKY_ITEM_TYPES = 'vendors/bulkyItemScheduler/START_LOAD_BULKY_ITEM_TYPES';
const COMPLETE_LOAD_BULKY_ITEM_TYPES = 'vendors/bulkyItemScheduler/COMPLETE_LOAD_BULKY_ITEM_TYPES';
const FAIL_LOAD_BULKY_ITEM_TYPES = 'vendors/bulkyItemScheduler/FAIL_LOAD_BULKY_ITEM_TYPES';
const START_LOAD_PERIOD_TYPES = 'vendors/bulkyItemScheduler/START_LOAD_PERIOD_TYPES';
const COMPLETE_LOAD_PERIOD_TYPES = 'vendors/bulkyItemScheduler/COMPLETE_LOAD_PERIOD_TYPES';
const FAIL_LOAD_PERIOD_TYPES = 'vendors/bulkyItemScheduler/FAIL_LOAD_PERIOD_TYPES';

// Initial state
const initialState = {
  bulkyCategoryTypes: [] as TechnicalType[],
  bulkyItemScheduler: {} as BulkyItemScheduler,
  bulkyItemTypes: [] as TechnicalType[],
  isLoading: false,
  isSaving: false,
  periodTypes: [] as TechnicalType[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          bulkyItemScheduler: action.bulkyItemScheduler,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false },
      });

    case START_SAVE:
      return update(state, {
        $merge: { isSaving: true },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case START_LOAD_BULKY_CATEGORY_TYPES:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD_BULKY_CATEGORY_TYPES:
      return update(state, {
        $merge: {
          isLoading: false,
          bulkyCategoryTypes: action.bulkyCategoryTypes,
        },
      });

    case FAIL_LOAD_BULKY_CATEGORY_TYPES:
      return update(state, {
        $merge: { isLoading: false },
      });

    case START_LOAD_BULKY_ITEM_TYPES:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD_BULKY_ITEM_TYPES:
      return update(state, {
        $merge: {
          isLoading: false,
          bulkyItemTypes: action.bulkyItemTypes,
        },
      });

    case FAIL_LOAD_BULKY_ITEM_TYPES:
      return update(state, {
        $merge: { isLoading: false },
      });

    case START_LOAD_PERIOD_TYPES:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD_PERIOD_TYPES:
      return update(state, {
        $merge: {
          isLoading: false,
          periodTypes: action.periodTypes,
        },
      });

    case FAIL_LOAD_PERIOD_TYPES:
      return update(state, {
        $merge: { isLoading: false },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (bulkyItemScheduler: BulkyItemScheduler) => ({
  type: COMPLETE_LOAD,
  bulkyItemScheduler,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoadBulkyCategoryTypes = () => ({
  type: START_LOAD_BULKY_CATEGORY_TYPES,
});

const completeLoadBulkyCategoryTypes = (bulkyCategoryTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD_BULKY_CATEGORY_TYPES,
  bulkyCategoryTypes,
});

const failLoadBulkyCategoryTypes = () => ({
  type: FAIL_LOAD_BULKY_CATEGORY_TYPES,
});

const startLoadBulkyItemTypes = () => ({
  type: START_LOAD_BULKY_ITEM_TYPES,
});

const completeLoadBulkyItemTypes = (bulkyItemTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD_BULKY_ITEM_TYPES,
  bulkyItemTypes,
});

const failLoadBulkyItemTypes = () => ({
  type: FAIL_LOAD_BULKY_ITEM_TYPES,
});

const startLoadPeriodTypes = () => ({
  type: START_LOAD_PERIOD_TYPES,
});

const completeLoadPeriodTypes = (periodTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD_PERIOD_TYPES,
  periodTypes,
});

const failLoadPeriodTypes = () => ({
  type: FAIL_LOAD_PERIOD_TYPES,
});

export const loadBulkyItemScheduler = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadBulkyItemSchedulerPromise = doLoadBulkyItemScheduler(vendorId);
  loadBulkyItemSchedulerPromise
    .then((bulkyItemScheduler: BulkyItemScheduler) => {
      dispatch(completeLoad(bulkyItemScheduler));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadBulkyItemSchedulerPromise;
};

export const saveBulkyItemScheduler =
  (bulkyItemScheduler: BulkyItemScheduler, vendorId: number) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveBulkyItemSchedulerPromise = doSaveBulkyItemScheduler(bulkyItemScheduler, vendorId);
    saveBulkyItemSchedulerPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveBulkyItemSchedulerPromise;
  };

export const loadBulkyCategoryTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoadBulkyCategoryTypes());
  const loadBulkyCategoryTypesPromise = doLoadBulkyCategoryTypes();
  loadBulkyCategoryTypesPromise
    .then((bulkyCategoryTypes: TechnicalType[]) => {
      dispatch(completeLoadBulkyCategoryTypes(bulkyCategoryTypes));
    })
    .catch(() => {
      dispatch(failLoadBulkyCategoryTypes());
    });
  return loadBulkyCategoryTypesPromise;
};

export const loadBulkyItemTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoadBulkyItemTypes());
  const loadBulkyItemTypesPromise = doLoadBulkyItemTypes();
  loadBulkyItemTypesPromise
    .then((bulkyItemTypes: TechnicalType[]) => {
      dispatch(completeLoadBulkyItemTypes(bulkyItemTypes));
    })
    .catch(() => {
      dispatch(failLoadBulkyItemTypes());
    });
  return loadBulkyItemTypesPromise;
};

export const loadPeriodTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoadPeriodTypes());
  const loadPeriodTypesPromise = doLoadPeriodTypes();
  loadPeriodTypesPromise
    .then((periodTypes: TechnicalType[]) => {
      dispatch(completeLoadPeriodTypes(periodTypes));
    })
    .catch(() => {
      dispatch(failLoadPeriodTypes());
    });
  return loadPeriodTypesPromise;
};
