import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Button, Grid, GridColumn, Panel, PanelSection, PanelSectionTitle } from '../../../core/components/styled';
import { currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { ActionButtonTooltip } from '../../../core/components';
import { HoursOfOperationForm } from '../forms';
import { HoursOfOperationViewMode } from '../forms/companyInfoFormSections';
import { saveHoursOfOperation, loadHoursOfOperation } from '../../ducks';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { DuckFunction } from '../../../contracts/ducks';

interface Props {
  gusId: string;
  hoursOfOperation: any[];
  saveHoursOfOperation: DuckFunction<typeof saveHoursOfOperation>;
  loadHoursOfOperation: DuckFunction<typeof loadHoursOfOperation>;
}

interface State {
  isEditable: boolean;
}

class HoursOfOperationPage extends PureComponent<Props, State> {
  readonly state: State = {
    isEditable: false,
  };

  onSubmit = async (data: any) => {
    const { gusId, saveHoursOfOperation, loadHoursOfOperation } = this.props;
    saveHoursOfOperation(gusId, data)
      .then(() => {
        loadHoursOfOperation(gusId);
        createSuccessNotification(translate('common.alertMessages.successMessage'));
        this.setState({ isEditable: false });
      })
      .catch(() => createErrorNotification(translate('common.alertMessages.errorMessage')));
  };

  onCancel = () => {
    this.setState({ isEditable: false });
  };

  editIconClick = () => {
    this.setState({ isEditable: true });
  };

  render() {
    const { hoursOfOperation } = this.props;
    const { isEditable } = this.state;
    return (
      <Panel noBoxShadow>
        <PanelSection>
          <Grid padding="no small">
            <GridColumn padding="no" size="11/12">
              <PanelSectionTitle>{translate('haulerProfile.hoursOfOperation')}</PanelSectionTitle>
            </GridColumn>
            <GridColumn size="1/12">
              {!isEditable && (
                <Button type="button" text color="primary" onClick={this.editIconClick}>
                  <ActionButtonTooltip icon="edit" tooltip="edit" />
                </Button>
              )}
            </GridColumn>
          </Grid>
        </PanelSection>

        {isEditable && (
          <HoursOfOperationForm
            onSubmit={this.onSubmit}
            closeModal={this.onCancel}
            isEditable={isEditable}
            onEditIconClick={this.editIconClick}
          />
        )}

        {!isEditable && <HoursOfOperationViewMode hoursOfOperations={hoursOfOperation} />}
      </Panel>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  gusId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor) as any,
});

const mapDispatchToProps = {
  saveHoursOfOperation,
  loadHoursOfOperation,
};

export default connect(mapStateToProps, mapDispatchToProps)(HoursOfOperationPage);
