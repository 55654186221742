import { createSelector } from 'reselect';
import { identity } from 'lodash-es';

import { getQueryParams } from '../../utils/services/queryParams';

const getReportingDetailsFormInitialValues = (queryString: string) => {
  const { date, endDate, reportType, routeId, searchString, sortDirection, startDate, vehicleTypeId, wasteAuditType } =
    getQueryParams(queryString);

  return {
    date: startDate,
    dateRange: {
      from: startDate || date,
      to: endDate || date,
    },
    reportType,
    routeId,
    searchString,
    sortDirection,
    vehicleTypeId: Number(vehicleTypeId),
    wasteAuditType,
  };
};

const reportingDetailsFormInitialValuesSelector = createSelector(getReportingDetailsFormInitialValues, identity);

export default reportingDetailsFormInitialValuesSelector;
