import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { push } from 'connected-react-router';

import { AppState } from '../../../store';
import {
  PageActions,
  PageBackButton,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { Table } from '../../../core/components';
import { Button, Message, Panel, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import { getLastUrlSelector } from '../../../core/ducks';
import translate from '../../../core/services/translate';
import { createUrl, getQueryParams } from '../../../utils/services/queryParams';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import {
  EXCEPTION_RATE,
  FAULT_CODES,
  PARTICIPATION_RATE,
  SET_OUT_RATE,
  WASTE_AUDIT_INSIGHTS,
  TRIP_TIME,
} from '../../constants';
import {
  exportReportingDetails,
  loadReportingDetails,
  loadVehiclesByReportType,
  resetReportingDetails,
} from '../../ducks';
import {
  DriverSafetyEvent,
  getReportingDetailsPageTitle,
  getReportingDetailsTableColumns,
  getReportingDetailsTableRowComponent,
} from '../../services/reportingDetailsOptions';
import { dashboardActiveCategorySelector } from '../../services/dashboardActiveCategorySelector';
import { DuckFunction, DuckAction } from '../../../contracts/ducks';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { getAllFiltersPreferencesIds } from 'src/common/utils/filters';
import { ReportingDetailsForm } from '../forms';
import { DriverSafetyImageModal, DriverSafetyVideoModal } from '../modals';

interface Props extends RouteComponentProps {
  exportReportingDetails: DuckFunction<typeof exportReportingDetails>;
  filtersPreferences: FilterSetting[];
  isExporting: boolean;
  isLoading: boolean;
  loadReportingDetails: DuckFunction<typeof loadReportingDetails>;
  loadVehiclesByReportType: DuckFunction<typeof loadVehiclesByReportType>;
  previousPageUrl: string;
  push: any;
  reportingDetails?: any[];
  resetReportingDetails: DuckAction<typeof resetReportingDetails>;
  vendorId: number;
}

interface State {
  highlightedRow?: any;
  highlightedRowHeight?: number;
  isDriverSafetyImageModalOpen?: boolean;
  isDriverSafetyVideoModalOpen?: boolean;
  driverSafetyEvent: DriverSafetyEvent;
}

const ROW_HEIGHT = 64;

class ReportingDetailsPage extends Component<Props, State> {
  readonly state: State = {
    driverSafetyEvent: {} as DriverSafetyEvent,
  };

  componentDidUpdate(prevProps: Props) {
    const { filtersPreferences, location, loadReportingDetails, loadVehiclesByReportType, vendorId } = this.props;
    const filtersPreferencesIds = getAllFiltersPreferencesIds(filtersPreferences);

    if (location.search !== prevProps.location.search) {
      const {
        startDate,
        endDate,
        reportType,
        vehicleTypeId,
        vehicleId,
        searchString,
        sortedBy,
        sortOrder,
        wasteAuditType,
        obstacleType,
        materialContaminationType,
        routeId,
      } = getQueryParams(location.search);

      loadReportingDetails(
        vendorId,
        startDate,
        endDate,
        vehicleTypeId,
        vehicleId,
        searchString,
        wasteAuditType,
        reportType,
        sortedBy,
        sortOrder,
        obstacleType,
        materialContaminationType,
        routeId,
        filtersPreferencesIds,
      );

      loadVehiclesByReportType(vendorId, startDate, endDate, reportType, filtersPreferencesIds);
    }
  }

  componentWillUnmount() {
    this.props.resetReportingDetails();
  }

  onSortOrderChange = (sortedBy: string, sortOrder: string) => {
    const { location, push } = this.props;
    push(createUrl(location.pathname, location.search, { sortedBy, sortOrder }));
  };

  handleSubmit = (formData: any) => {
    const {
      dateRange: { from, to },
      date,
    } = formData;
    const { location, push } = this.props;
    const { sortOrder, reportType } = getQueryParams(location.search);
    const fromDate =
      reportType === PARTICIPATION_RATE || reportType === SET_OUT_RATE || reportType === EXCEPTION_RATE ? date : from;
    const toDate =
      reportType === PARTICIPATION_RATE || reportType === SET_OUT_RATE || reportType === EXCEPTION_RATE ? date : to;
    push(
      createUrl(location.pathname, location.search, { startDate: fromDate, endDate: toDate, sortOrder, ...formData }),
    );
  };

  exportReportingDetails = () => {
    const { exportReportingDetails, filtersPreferences, location, vendorId } = this.props;
    const filtersPreferencesIds = getAllFiltersPreferencesIds(filtersPreferences);

    const {
      endDate,
      obstacleType,
      materialContaminationType,
      reportType,
      searchString,
      sortedBy,
      sortOrder,
      startDate,
      vehicleId,
      vehicleTypeId,
      wasteAuditType,
      routeId,
    } = getQueryParams(location.search);

    exportReportingDetails(
      vendorId,
      startDate,
      endDate,
      vehicleTypeId,
      vehicleId,
      searchString,
      wasteAuditType,
      reportType,
      sortedBy,
      sortOrder,
      obstacleType,
      materialContaminationType,
      routeId,
      filtersPreferencesIds,
    );
  };

  toggleHighlightedRow = (row: any, highlightedRowHeight?: number) =>
    this.setState(prevState => ({
      highlightedRow: prevState.highlightedRow !== row ? row : undefined,
      highlightedRowHeight,
    }));

  openDriverSafetyImageModal = (driverSafetyEvent: DriverSafetyEvent) => {
    this.setState({ isDriverSafetyImageModalOpen: true, driverSafetyEvent });
  };

  closeDriverSafetyImageModal = () => {
    this.setState({ isDriverSafetyImageModalOpen: false, driverSafetyEvent: {} as DriverSafetyEvent });
  };

  openDriverSafetyVideoModal = (driverSafetyEvent: DriverSafetyEvent, cameraType: string) => {
    this.setState({ isDriverSafetyVideoModalOpen: true, driverSafetyEvent: { ...driverSafetyEvent, cameraType } });
  };

  closeDriverSafetyVideoModal = () => {
    this.setState({ isDriverSafetyVideoModalOpen: false, driverSafetyEvent: {} as DriverSafetyEvent });
  };

  render() {
    const {
      isExporting,
      isLoading,
      location: { search },
      previousPageUrl,
      push,
      reportingDetails,
    } = this.props;

    const reportingDetailsLength = reportingDetails?.length;

    const {
      driverSafetyEvent,
      highlightedRow,
      highlightedRowHeight,
      isDriverSafetyImageModalOpen,
      isDriverSafetyVideoModalOpen,
    } = this.state;
    const { reportType, startDate, endDate, vehicleTypeId } = getQueryParams(search);

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButton to={previousPageUrl}>
                <PageBackButtonIcon />
              </PageBackButton>
              <PageTitle id="report-details-title">{getReportingDetailsPageTitle(reportType)}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions>
            <Button color="primary" id="report-details-export-button" onClick={this.exportReportingDetails}>
              {translate('common.export')}
            </Button>
          </PageActions>
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoading || isExporting}>
            <ReportingDetailsForm onSubmit={this.handleSubmit} reportType={reportType} />
            <PanelSection>
              {reportingDetailsLength ? (
                <Table
                  virtualized={reportType === TRIP_TIME}
                  virtualizedProps={
                    reportType === TRIP_TIME
                      ? {
                          height:
                            Math.min(
                              highlightedRow !== undefined
                                ? reportingDetailsLength * ROW_HEIGHT + 140
                                : reportingDetailsLength * ROW_HEIGHT,
                              ROW_HEIGHT * 8,
                            ) || 1,
                          itemSize: (index: number) => {
                            return index === highlightedRow ? highlightedRowHeight || 200 : ROW_HEIGHT;
                          },
                          activeItem: highlightedRow,
                        }
                      : undefined
                  }
                  cells={getReportingDetailsTableColumns(reportType)!}
                  rows={reportingDetails}
                  rowComponent={getReportingDetailsTableRowComponent(
                    reportType,
                    startDate,
                    endDate,
                    vehicleTypeId,
                    this.toggleHighlightedRow,
                    this.openDriverSafetyImageModal,
                    this.openDriverSafetyVideoModal,
                    highlightedRow,
                    highlightedRowHeight,
                  )}
                  rowProps={{ push }}
                  sort={this.onSortOrderChange}
                  withClickableRows={reportType === FAULT_CODES}
                />
              ) : (
                <Message padding="sMedium" id="report-details-no-entries">
                  {translate('insights.noReportsFound')}
                </Message>
              )}
            </PanelSection>

            {isDriverSafetyImageModalOpen && (
              <DriverSafetyImageModal
                driverSafetyEvent={driverSafetyEvent}
                closeModal={() => this.closeDriverSafetyImageModal()}
              ></DriverSafetyImageModal>
            )}

            {isDriverSafetyVideoModalOpen && (
              <DriverSafetyVideoModal
                driverSafetyEvent={driverSafetyEvent}
                closeModal={() => this.closeDriverSafetyVideoModal()}
              ></DriverSafetyVideoModal>
            )}
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const activeCategories = (dashboardActiveCategorySelector as any)(state.insights.insightDashboardPreferences);

  return {
    isLoading: state.insights.reportingDetails.isLoading,
    isExporting: state.insights.reportingDetails.isExporting,
    vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
    reportingDetails: state.insights.reportingDetails.reportingDetails,
    previousPageUrl: activeCategories[WASTE_AUDIT_INSIGHTS]
      ? (getLastUrlSelector as any)(state.core, '/insights/wasteaudit')
      : (getLastUrlSelector as any)(state.core, '/insights/insightsfleet'),
    filtersPreferences: state.common.filters.filters || [],
  };
};

const mapDispatchToProps = {
  loadReportingDetails,
  exportReportingDetails,
  loadVehiclesByReportType,
  resetReportingDetails,
  push,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportingDetailsPage));
