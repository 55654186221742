import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { map } from 'lodash-es';

import { AppState } from 'src/store';
import { Button, Grid, GridColumn, PanelSection } from 'src/core/components/styled';
import { Input, MultiSelect, TypedField } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { SavedEquipment, SavedMaterial } from 'src/haulerProfile/interfaces/ServiceArea';
import translate from 'src/core/services/translate';
import {
  AccountStatusDropdown,
  EquipmentSizesMultiSelect,
  PickupFrequencyTypeDropdown,
  ServiceTypeDropdown,
} from 'src/common/components';
import { isRequired } from 'src/utils/services/validator';
import EquipmentTypesMultiSelect from 'src/common/components/EquipmentTypesMultiSelect';
import * as Ducks from 'src/customers/ducks/service';

const equipmentCountOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5+', value: 5 },
];

const formSelector = formValueSelector('rateBuilderFilterForm');

interface PropsWithoutReduxForm {
  savedEquipments: SavedEquipment[];
  savedMaterials: SavedMaterial[];
  initialValues: FormValues;
}

export interface FormValues {
  equipmentTypes?: string;
  equipmentSizeIds?: number[];
  materials?: string[];
  accountStatusId?: number;
  excludedRateCodeIds?: number[];
  includedRateCodeIds?: number[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const RateBuilderFilterForm: FC<Props> = ({ change, handleSubmit, savedEquipments, savedMaterials }) => {
  const dispatch = useDispatch();

  const [rateTypesOptions, setRateTypesOptions] = useState([]);

  const { serviceTypeId, equipmentTypeIds } = useSelector(state =>
    formSelector(state, 'serviceTypeId', 'equipmentTypeIds'),
  );

  const wasteMaterialTypes = useSelector(state => state.common.wasteMaterialTypes.wasteMaterialTypes);
  const { rateTypesByServiceType, isLoading } = useSelector(state => state.customers.service);

  // Call the selector with the serviceTypes and serviceTypeId
  const wasteMaterialTypeOptions = map(wasteMaterialTypes, wasteMaterial => ({
    label: wasteMaterial.name,
    value: wasteMaterial.id,
  }));

  // Only trigger the effect when serviceTypeId changes and reset the rate codes
  useEffect(() => {
    if (serviceTypeId) {
      Ducks.loadRateTypesByServiceType(serviceTypeId)(dispatch);

      change('includedRateCodeIds', []);
      change('excludedRateCodeIds', []);
    }
  }, [serviceTypeId, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoading && rateTypesByServiceType.length > 0) {
      const mappedRateTypes: any = map(
        rateTypesByServiceType.filter(rateType => rateType.isActive),
        rateType => ({
          label: rateType.typeCode,
          value: Number(rateType.id),
        }),
      );
      setRateTypesOptions(mappedRateTypes);
    }
  }, [isLoading, rateTypesByServiceType]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <PanelSection withBorder padding="xSmall small" display="block">
        <Grid>
          <GridColumn size="3/12">
            <Field
              name="serviceTypeId"
              component={ServiceTypeDropdown}
              label={translate('common.serviceType')}
              validate={[isRequired]}
              dropdownProps={{ isClearable: true, canCheckAll: true }}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <Field
              name="equipmentTypeIds"
              component={EquipmentTypesMultiSelect}
              emptyWithoutServiceType
              multiSelectProps={{ canCheckAll: true }}
              serviceTypeId={serviceTypeId}
              label={translate('common.equipmentType')}
              dropdownProps={{ isClearable: true }}
            />
          </GridColumn>

          <GridColumn size="3/12">
            <Field
              name="equipmentSizeIds"
              component={EquipmentSizesMultiSelect}
              multiSelectProps={{ canCheckAll: true }}
              serviceTypeId={serviceTypeId}
              equipmentTypeIds={equipmentTypeIds}
              label={translate('common.equipmentSize')}
            />
          </GridColumn>
          <GridColumn size="3/12" align="right">
            <Button margin="small no no no" color="primary">
              {translate('common.search')}
            </Button>
          </GridColumn>
        </Grid>
        <Grid margin="no">
          <GridColumn size="3/12">
            <TypedField
              name="numberOfContainers"
              component={MultiSelect}
              props={{
                label: translate('common.equipmentCount'),
                options: equipmentCountOptions,
                isClearable: true,
                canCheckAll: true,
              }}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <Field
              name="pickupFrequencyTypeId"
              component={PickupFrequencyTypeDropdown}
              withLabel
              dropdownProps={{ isClearable: true }}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <TypedField
              name="wasteMaterialTypeId"
              component={MultiSelect}
              props={{
                label: translate('customers.wasteMaterial'),
                options: wasteMaterialTypeOptions,
                canCheckAll: true,
              }}
            />
          </GridColumn>

          <GridColumn size="3/12">
            <Field
              name="accountStatusId"
              component={AccountStatusDropdown}
              label={translate('common.accountStatus')}
              dropdownProps={{ isClearable: true, canCheckAll: true }}
            />
          </GridColumn>
        </Grid>

        <Grid margin="no">
          <GridColumn size="3/12">
            <TypedField
              name="includedRateCodeIds"
              component={MultiSelect}
              props={{
                label: translate('customers.includedRateCodeIds'),
                options: rateTypesOptions,
                canCheckAll: true,
                isClearable: true,
              }}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <TypedField
              name="excludedRateCodeIds"
              component={MultiSelect}
              props={{
                label: translate('customers.excludedRateCodeIds'),
                options: rateTypesOptions,
                canCheckAll: true,
                isClearable: true,
              }}
            />
          </GridColumn>

          <GridColumn padding="small xSmall no small" size="2/12">
            <Field name="citySearchTerm" component={Input} placeholder={translate('customers.city')} />
          </GridColumn>
          <GridColumn padding="small xSmall no xSmall" size="2/12">
            <Field name="stateSearchTerm" component={Input} placeholder={translate('customers.state')} />
          </GridColumn>
          <GridColumn padding="small xSmall no xSmall" size="2/12">
            <Field name="zipSearchTerm" component={Input} placeholder={translate('customers.zip')} />
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  savedEquipments: state.haulerProfile.serviceAreas.serviceAreaOptions?.savedEquipments || [],
  savedMaterials: state.haulerProfile.serviceAreas.serviceAreaOptions?.savedMaterials || [],
  initialValues: { accountStatusId: 1 },
});

export default connect(mapStateToProps)(
  reduxForm<FormValues, PropsWithoutReduxForm>({
    form: 'rateBuilderFilterForm',
  })(RateBuilderFilterForm),
);
