import styled, { css } from 'styled-components';
import { mapper, sizeMapper } from '../../../utils/styles';
import { Button } from './Button';

type Props = Partial<{
  align: string;
  grouped?: boolean;
  flexFlow?: string;
  margin?: string;
  padding?: string;
  size?: string;
  vertical?: boolean;
  roundedBorder?: boolean;
}>;

const ButtonSet = styled.div<Props>`
  display: flex;
  justify-content: space-around;
  margin: ${props => sizeMapper(props.margin, 'medium no no')};
  padding: ${props => sizeMapper(props.padding, 'no')};

  ${props =>
    props.align &&
    css`
      justify-content: ${mapper(
        props.align,
        { left: 'flex-start', center: 'center', right: 'flex-end', spaceBetween: 'space-between' },
        'left',
      )};
    `};

  ${props =>
    props.size &&
    css`
      width: ${mapper(props.size, { xSmall: '25px', small: '30px', medium: '60px', large: '85px' }, 'small')};
    `};

  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
    `};

  ${props =>
    props.flexFlow &&
    css`
      flex-flow: ${props.flexFlow};
    `};

  ${props =>
    props.roundedBorder &&
    css`
      border-radius: 4px;
    `};

  ${props =>
    props.grouped &&
    css`
      > ${Button}:not(:last-of-type) {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right-color: transparent;
      }

      > ${Button}:not(:first-of-type) {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-left: -1px;
      }
    `};
`;

export const ShadowedButtonSet = styled(ButtonSet)`
  background: #f9f9fd;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.14);
  margin: ${props => (props.margin ? sizeMapper(props.margin, 'medium no no') : '6px 0 0')};
  padding: ${props => sizeMapper(props.padding, 'medium no')};
`;

export default ButtonSet;
