import React, { ChangeEvent, PureComponent } from 'react';

import { map, remove } from 'lodash-es';

import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { JOB_STATUSES, INPROGRESS, SERVICED, PLACED_ON_HOLD, ISSUE_REPORTED } from '../constants';
import { ROLL_OFF_ID, RESIDENTIAL_ID, WASTE_AUDIT_ID, DELIVERY_UTILITY_ID } from '../../fleet/constants';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  vehicleTypeId: number;
}

class JobStatusDropdown extends PureComponent<Props> {
  onChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
      vehicleTypeId,
    } = this.props;

    let pickupStatusOptions = map(JOB_STATUSES, jobStatus => ({
      label: jobStatus.name,
      value: jobStatus.id,
    }));

    switch (vehicleTypeId) {
      case RESIDENTIAL_ID:
        pickupStatusOptions = remove(
          pickupStatusOptions,
          jobStatus => jobStatus.value !== INPROGRESS && jobStatus.value !== PLACED_ON_HOLD,
        );
        break;
      case ROLL_OFF_ID:
        pickupStatusOptions = remove(pickupStatusOptions, jobStatus => jobStatus.value !== SERVICED);
        break;
      case DELIVERY_UTILITY_ID:
        pickupStatusOptions = remove(pickupStatusOptions, jobStatus => jobStatus.value !== SERVICED);
        break;
      case WASTE_AUDIT_ID:
        pickupStatusOptions = remove(
          pickupStatusOptions,
          jobStatus =>
            jobStatus.value !== INPROGRESS &&
            jobStatus.value !== SERVICED &&
            jobStatus.value !== PLACED_ON_HOLD &&
            jobStatus.value !== ISSUE_REPORTED,
        );
        break;
      default:
        pickupStatusOptions = remove(
          pickupStatusOptions,
          jobStatus =>
            jobStatus.value !== INPROGRESS && jobStatus.value !== SERVICED && jobStatus.value !== PLACED_ON_HOLD,
        );
        break;
    }

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('common.pickupStatus') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('common.pickupStatus') : undefined),
          options: pickupStatusOptions,
          ...dropdownProps,
        }}
      />
    );
  }
}

export default JobStatusDropdown;
