import { useDispatch } from 'react-redux';

import { PageLoadingOverlay } from 'src/common/components/styled';
import { loadFiltersSettings, loadWasteTypes } from 'src/common/ducks';
import { Resolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { loadOperationalFacilities, loadVehicles } from 'src/fleet/ducks';
import { loadDisposalFacilities } from 'src/fleet/ducks/facilities';
import { loadGroups, loadServiceZones } from 'src/routes/ducks';
import { loadDispatchBoardRoute } from 'src/routes/ducks/dispatchBoard';
import { loadSupervisors } from 'src/routes/ducks/supervisors';
import { loadUserById } from 'src/vendors/ducks';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import DispatchBoardRouteEditorModal from './DispatchBoardRouteEditorModal';

interface Props {
  closeModal: () => void;
  disableVehicleType?: boolean;
  jobIds?: any[];
  onSuccess?: () => void;
  onSave?: (routeData?: any) => Promise<any>;
  routeId?: number;
}

const DispatchBoardRouteEditorModalResolver = (props: Props) => {
  const dispatch = useDispatch();
  const { closeModal, disableVehicleType, jobIds, onSave, onSuccess, routeId } = props;

  const vendorId = useSelector(state => currentVendorIdSelector(state.account.login, state.vendors.defaultVendor));
  const user = useSelector(state => state.account.login.user);

  const loadDependencies = () => {
    const dependencies = [
      loadDisposalFacilities(vendorId)(dispatch),
      loadOperationalFacilities(vendorId)(dispatch),
      loadServiceZones(vendorId)(dispatch),
      loadSupervisors(vendorId)(dispatch),
      loadUserById(vendorId, user.userId)(dispatch),
      loadVehicles(vendorId)(dispatch),
      loadWasteTypes()(dispatch),
      loadFiltersSettings(vendorId, user.userId)(dispatch),
      loadGroups(vendorId)(dispatch),
    ];

    if (routeId) {
      dependencies.push(loadDispatchBoardRoute(routeId)(dispatch));
    }

    return Promise.all(dependencies);
  };

  return (
    <Resolver
      successComponent={DispatchBoardRouteEditorModal}
      loadingComponent={PageLoadingOverlay}
      successProps={{ routeId, closeModal, jobIds, onSuccess, disableVehicleType, vendorId, onSave }}
      resolve={loadDependencies}
    />
  );
};

export default DispatchBoardRouteEditorModalResolver;
