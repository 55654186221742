import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { RouteGeoFenceState, RouteGeoFenceMultiPolygon, RouteGeoFenceSettings } from '../interfaces/RouteGeoFence';
import * as Services from '../services/routeGeoFence';

/**
 * Actions
 */
const SET_GEO_FENCE = 'routes/geoFence/SET_GEO_FENCE';
const SET_GEO_FENCE_LOADING = 'routes/geoFence/SET_GEO_FENCE_LOADING';
const SET_GEO_FENCE_SAVING = 'routes/geoFence/SET_GEO_FENCE_SAVING';
const SET_GEO_FENCE_EDITING = 'routes/geoFence/SET_GEO_FENCE_EDITING';
const SET_GEO_FENCE_SETTINGS = 'routes/geoFence/SET_GEO_FENCE_SETTINGS';
const SET_GEO_FENCE_SETTINGS_LOADING = 'routes/geoFence/SET_GEO_FENCE_SETTINGS_LOADING';
const SET_GEO_FENCE_SETTINGS_SAVING = 'routes/geoFence/SET_GEO_FENCE_SETTINGS_SAVING';
const RESET_GEO_FENCE = 'routes/geoFence/RESET_GEO_FENCE';

/**
 * Initial State
 */
const initialState: RouteGeoFenceState = {
  geoFenceLoading: false,
  geoFenceSaving: false,
  geoFenceEditing: false,

  geoFenceSettings: [],
  geoFenceSettingsLoading: false,
  geoFenceSettingsSaving: false,

  geoFence: undefined,
};

/**
 * Reducer
 * @param state
 * @param action
 */
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_GEO_FENCE:
      return update(state, {
        $merge: {
          geoFence: action.geoFence,
        },
      });

    case SET_GEO_FENCE_LOADING:
      return update(state, {
        $merge: {
          geoFenceLoading: action.loading,
        },
      });

    case SET_GEO_FENCE_SAVING:
      return update(state, {
        $merge: {
          geoFenceSaving: action.saving,
        },
      });

    case SET_GEO_FENCE_EDITING:
      return update(state, {
        $merge: {
          geoFenceEditing: action.editing,
        },
      });

    case SET_GEO_FENCE_SETTINGS:
      return update(state, {
        $merge: {
          geoFenceSettings: action.geoFenceSettings,
        },
      });

    case SET_GEO_FENCE_SETTINGS_LOADING:
      return update(state, {
        $merge: {
          geoFenceSettingsLoading: action.loading,
        },
      });

    case SET_GEO_FENCE_SETTINGS_SAVING:
      return update(state, {
        $merge: {
          geoFenceSettingsSaving: action.saving,
        },
      });

    case RESET_GEO_FENCE:
      return initialState;

    default:
      return state;
  }
};

/**
 * Action Creators
 */
const setGeoFence = (geoFence: RouteGeoFenceMultiPolygon) => ({
  type: SET_GEO_FENCE,
  geoFence,
});

const setGeoFenceLoading = (loading: boolean = true) => ({
  type: SET_GEO_FENCE_LOADING,
  loading,
});

const setGeoFenceSaving = (saving: boolean = true) => ({
  type: SET_GEO_FENCE_SAVING,
  saving,
});

export const setRouteGeoFenceEditing = (editing: boolean = true) => ({
  type: SET_GEO_FENCE_EDITING,
  editing,
});

const setGeoFenceSettings = (geoFenceSettings: RouteGeoFenceSettings[]) => ({
  type: SET_GEO_FENCE_SETTINGS,
  geoFenceSettings,
});

const setGeoFenceSettingsLoading = (loading: boolean = true) => ({
  type: SET_GEO_FENCE_SETTINGS_LOADING,
  loading,
});

const setGeoFenceSettingsSaving = (saving: boolean = true) => ({
  type: SET_GEO_FENCE_SETTINGS_SAVING,
  saving,
});

export const resetGeoFence = () => ({
  type: RESET_GEO_FENCE,
});

/**
 * Async Action Creators
 */
export const loadRouteGeoFence =
  (routeId: number | string, isTemplate?: boolean, noLoadingIndicator?: boolean) => (dispatch: Dispatch) => {
    !noLoadingIndicator && dispatch(setGeoFenceLoading());
    const promise = Services.loadRouteGeoFence(routeId, isTemplate)
      .then(geoFence => {
        dispatch(setGeoFence(geoFence));
      })
      .finally(() => {
        dispatch(setGeoFenceLoading(false));
      });
    return promise;
  };

export const saveRouteGeoFence =
  (routeId: number, geoFence: RouteGeoFenceMultiPolygon, isTemplate?: boolean) => (dispatch: Dispatch) => {
    dispatch(setGeoFenceSaving());

    const promise = Services.saveRouteGeoFence(routeId, geoFence, isTemplate);

    promise.finally(() => {
      dispatch(setGeoFenceSaving(false));
    });

    return promise;
  };

export const loadRouteGeoFenceSettings = (geoFenceId: number, vendorId: number) => (dispatch: Dispatch) => {
  dispatch(setGeoFenceSettingsLoading());

  const promise = Services.loadRouteGeoFenceSettings(geoFenceId, vendorId);

  promise
    .then(geoFenceSettings => {
      dispatch(setGeoFenceSettings(geoFenceSettings));
    })
    .finally(() => {
      dispatch(setGeoFenceSettingsLoading(false));
    });

  return promise;
};

export const saveRouteGeoFenceSettings =
  (geoFenceId: number, vendorId: number, geoFenceSettings: RouteGeoFenceSettings[]) => (dispatch: Dispatch) => {
    dispatch(setGeoFenceSettingsSaving());

    const promise = Services.saveRouteGeoFenceSettings(geoFenceId, vendorId, geoFenceSettings);

    promise.finally(() => {
      dispatch(setGeoFenceSettingsSaving(false));
    });

    return promise;
  };
