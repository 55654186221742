import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { countBy, values } from 'lodash-es';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { DatePicker, DateRangePicker, PanelSearch } from '../../../core/components';
import {
  ExceptionDropdown,
  MaterialContaminationsDropdown,
  ObstaclesStreetSweepingDropdown,
  VehiclesForReportDropdown,
} from '..';
import { getReportingDetailsFormFields } from '../../services/reportingDetailsOptions';
import { Grid, GridColumn, PanelSection, Button } from '../../../core/components/styled';
import {
  GEO_FENCES_COVERAGE_STREET_SWEEPING,
  GEO_FENCES_STREET_SWEEPING,
  OBSTACLES_STREET_SWEEPING,
  SNOW_PLOW_CHART,
} from '../../constants';
import { ROUTE } from '../../../fleet/constants';
import { VehicleTypeForVendorDropdown } from '../../../fleet/components';
import reportingDetailsFormInitialValuesSelector from '../../services/reportingDetailsFormInitialValuesSelector';
import RoutesForReportDropdown from '../RoutesForReportDropdown';
import translate from '../../../core/services/translate';
import { isDateValidValidator } from 'src/utils/services/validator';
import { getExcludeVehicleFilters } from 'src/common/utils/filters';

interface ComponentProps extends RouteComponentProps {
  reportType: string;
  excludeVehicleTypes?: string[];
}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

interface State {
  formFields: { [key: string]: boolean };
  buttonShiftValue: string;
}

class ReportingDetailsForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  getInitialState = (props: Props): State => {
    const formFields = getReportingDetailsFormFields(props.initialValues.reportType) as any;
    const buttonShiftValue = this.getButtonShiftValue(formFields);
    return {
      formFields,
      buttonShiftValue,
    };
  };

  getButtonShiftValue = (formFields: any) => {
    const numberOfFormFields = countBy(values(formFields), Boolean).true;
    return numberOfFormFields > 0 ? `${10 - numberOfFormFields * 2}/12` : '';
  };

  render() {
    const { handleSubmit, reportType, excludeVehicleTypes } = this.props;
    const { formFields } = this.state;

    return (
      <form noValidate onSubmit={handleSubmit}>
        <PanelSection padding="small xSmall" withBorder>
          <Grid spaceBetween>
            {formFields.search && (
              <GridColumn size={reportType === GEO_FENCES_COVERAGE_STREET_SWEEPING ? '4/12' : '8/12'}>
                <Field name="searchString" component={PanelSearch} margin="no" id="report-details-form-search" />
              </GridColumn>
            )}

            {formFields.singleDate ? (
              <GridColumn size="2/12">
                <Field
                  name="date"
                  component={DatePicker}
                  margin="no"
                  id="report-details-form-date"
                  validate={[isDateValidValidator]}
                />
              </GridColumn>
            ) : (
              <GridColumn
                size={
                  reportType === SNOW_PLOW_CHART ? '4/12' : reportType === GEO_FENCES_STREET_SWEEPING ? '5/12' : '3/12'
                }
              >
                <Field
                  name="dateRange"
                  component={DateRangePicker}
                  tabletAlignLeft={
                    reportType === GEO_FENCES_COVERAGE_STREET_SWEEPING ||
                    reportType === GEO_FENCES_STREET_SWEEPING ||
                    reportType === OBSTACLES_STREET_SWEEPING
                  }
                  props={{ hasMarginLeft: 'smaller' }}
                  margin="no"
                  id="report-details-form-date-range"
                />
              </GridColumn>
            )}

            {formFields.obstacles && (
              <GridColumn
                size={
                  reportType === GEO_FENCES_COVERAGE_STREET_SWEEPING || reportType === GEO_FENCES_STREET_SWEEPING
                    ? '4/12'
                    : '3/12'
                }
              >
                <Field
                  name="obstacleType"
                  component={ObstaclesStreetSweepingDropdown}
                  dropdownProps={{ margin: 'no', isClearable: true, id: 'report-details-form-obstacle-type' }}
                  withPlaceholder
                />
              </GridColumn>
            )}

            {formFields.materialContaminations && (
              <GridColumn size="3/12">
                <Field
                  name="materialContaminationType"
                  component={MaterialContaminationsDropdown}
                  dropdownProps={{ margin: 'no', isClearable: true, id: 'report-details-form-obstacle-type' }}
                  withPlaceholder
                />
              </GridColumn>
            )}

            {formFields.vehicleTypeId && (
              <GridColumn size="2/12">
                <Field
                  name="vehicleTypeId"
                  component={VehicleTypeForVendorDropdown}
                  vehicleRoleTypeId={ROUTE}
                  excludeVehicleTypes={excludeVehicleTypes}
                  dropdownProps={{
                    margin: 'no',
                    isClearable: true,
                    id: 'report-details-form-vehicle-type',
                  }}
                  withPlaceholder
                />
              </GridColumn>
            )}

            {formFields.routeName && (
              <GridColumn size={reportType === SNOW_PLOW_CHART ? '4/12' : '2/12'}>
                <Field
                  name="routeId"
                  component={RoutesForReportDropdown}
                  dropdownProps={{ margin: 'no', isClearable: true, id: 'report-details-form-route-id' }}
                  withPlaceholder
                />
              </GridColumn>
            )}

            {formFields.vehicleId && (
              <GridColumn size={reportType === SNOW_PLOW_CHART ? '4/12' : '2/12'}>
                <Field
                  name="vehicleId"
                  component={VehiclesForReportDropdown}
                  dropdownProps={{ margin: 'no', isClearable: true, id: 'report-details-form-vehicle-id' }}
                  withPlaceholder
                />
              </GridColumn>
            )}

            {formFields.exceptions && (
              <GridColumn size={'2/12'}>
                <Field
                  name="wasteAuditType"
                  component={ExceptionDropdown}
                  dropdownProps={{ margin: 'no', isClearable: true, id: 'report-details-form-waste-audit-type' }}
                  withPlaceholder
                />
              </GridColumn>
            )}

            <GridColumn
              size={formFields.obstacles || formFields.materialContaminations ? '6/12' : '2/12'}
              align="right"
            >
              <Button type="submit" color="primary" id="report-details-form-search-button">
                {translate('common.search')}
              </Button>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const {
    location: { search },
  } = ownProps;
  const initialValues = reportingDetailsFormInitialValuesSelector(search);

  const filtersPreferences = state.common.filters.filters;
  const excludeVehicleTypes = getExcludeVehicleFilters(filtersPreferences);

  return {
    initialValues,
    excludeVehicleTypes,
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'reportingDetails',
      enableReinitialize: true,
    })(ReportingDetailsForm),
  ),
);
