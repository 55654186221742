import React, { PureComponent } from 'react';

import { debounce } from 'lodash-es';
import { connect } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { AppState } from '../../../store';
import { Button, Grid, GridColumn, PanelSection, Popover, Text } from '../../../core/components/styled';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { DELIVERY_UTILITY_ID } from '../../../fleet/constants';
import { DuckFunction } from 'src/contracts/ducks';
import { isRequired } from '../../../utils/services/validator';
import { loadPickupTypes } from '../../ducks/pickupTypes';
import { PICKUP_TYPE_SERVICE_ID } from '../../constants/routePickupTypes';
import { PopoverWrapper, TypeAhead } from 'src/core/components';
import { renderCustomerLocationOptionLabel } from './utils/CustomerLocationUtils';
import { ROLL_OFF_ID } from 'src/common/constants/serviceTypes';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import JobPositionTypeDropdown from 'src/common/components/JobPositionTypeDropdown';
import PickupTypeDropdown from '../PickupTypeDropdown';
import searchCustomerLocations from '../../services/searchCustomerLocations';
import translate from '../../../core/services/translate';

interface FormValues {
  routeLocation: RouteLocation;
  pickupTypeId: number;
}

interface PropsWithoutReduxForm {
  loadPickupTypes: DuckFunction<typeof loadPickupTypes>;
  newOptimizedRouteLocationsLength: number;
  openUnassignedLocationsModal: () => void;
  routeTemplateId?: string;
  scheduledDay: string;
  vehicleTypeId: number;
  vendorId: number;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

interface State {
  inputValue?: string;
  pickupTypes: TechnicalType[];
}

class AddRouteTemplateLocationForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      inputValue: undefined,
      pickupTypes: [],
    };
  }

  get isDeliveryUtility(): boolean {
    return this.props.vehicleTypeId === DELIVERY_UTILITY_ID;
  }

  componentDidMount() {
    this.loadPickupTypes();
  }

  componentDidUpdate(prevProps: Props) {
    const { vehicleTypeId } = this.props;
    const { vehicleTypeId: prevVehicleTypeId } = prevProps;

    if (vehicleTypeId !== prevVehicleTypeId && this.isDeliveryUtility) {
      this.loadPickupTypes();
    }
  }

  loadPickupTypes = async () => {
    const { vehicleTypeId, loadPickupTypes } = this.props;
    if (vehicleTypeId) {
      const pickupTypesPromise = await loadPickupTypes(Number(vehicleTypeId));
      this.setState({
        pickupTypes: pickupTypesPromise[0].pickupTypes,
      });
    }
  };

  onHandleClick = () => {
    this.setState({ inputValue: undefined });
  };

  loadCustomerLocations = debounce((searchTerm, onOptionsLoaded) => {
    const { vendorId, vehicleTypeId, scheduledDay } = this.props;
    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }

    searchCustomerLocations(vendorId, searchTerm, vehicleTypeId, scheduledDay).then(onOptionsLoaded);

    this.setState({ inputValue: undefined });
  }, 500);

  render() {
    const {
      handleSubmit,
      newOptimizedRouteLocationsLength,
      openUnassignedLocationsModal,
      routeTemplateId,
      valid,
      vehicleTypeId,
    } = this.props;
    const { inputValue, pickupTypes } = this.state;

    return (
      <form onSubmit={handleSubmit}>
        <PanelSection padding="medium xSmall" vertical withBorder>
          <Grid multiLine>
            <GridColumn size="12/12" alignVerticalCenter>
              <Text block weight="medium" size="large" margin="no no small">
                {translate('routes.addStops')}
              </Text>
            </GridColumn>

            <GridColumn size={this.isDeliveryUtility ? '3/12' : '6/12'} alignVerticalCenter>
              <Field
                name="routeLocation"
                component={TypeAhead}
                placeholder={`${translate('common.customer')} / ${translate('common.location')}`}
                getOptions={this.loadCustomerLocations}
                isClearable
                margin="no"
                validate={[isRequired]}
                inputValue={inputValue}
                props={{
                  getOptionLabel: renderCustomerLocationOptionLabel,
                }}
              />
            </GridColumn>

            {this.isDeliveryUtility && (
              <GridColumn size="3/12" alignVerticalCenter>
                <Field
                  component={PickupTypeDropdown}
                  dropdownProps={{
                    margin: 'no',
                    isClearable: true,
                    id: 'route-template-pickup-type',
                  }}
                  name="pickupTypeId"
                  pickupTypes={pickupTypes}
                  validate={[isRequired]}
                  withPlaceholder
                />
              </GridColumn>
            )}

            {routeTemplateId && (
              <GridColumn size="2/12">
                <Field
                  isOptimizedOptionHidden={vehicleTypeId === ROLL_OFF_ID}
                  withLabel
                  dropdownProps={{
                    padding: 'no no medium',
                  }}
                  name="positionTypeId"
                  component={JobPositionTypeDropdown as any}
                />
              </GridColumn>
            )}

            <GridColumn size={routeTemplateId ? '4/12' : '6/12'} align="right" verticalAlign="center">
              <PopoverWrapper
                margin="no"
                triggerButton={
                  <Button
                    color="primary"
                    margin="no small"
                    disabled={!valid || newOptimizedRouteLocationsLength > 0}
                    onClick={this.onHandleClick}
                    id="route-add-stop-button"
                  >
                    {translate('routes.addStop')}
                  </Button>
                }
                popoverContent={
                  newOptimizedRouteLocationsLength ? (
                    <Popover>{translate('routes.alertMessages.addingJobDisabled')}</Popover>
                  ) : undefined
                }
                size="large"
              />

              <PopoverWrapper
                margin="no"
                triggerButton={
                  <Button
                    color="secondary"
                    type="button"
                    onClick={openUnassignedLocationsModal}
                    id="route-see-unassigned-button"
                    disabled={newOptimizedRouteLocationsLength > 0}
                  >
                    {translate('routes.seeUnassigned')}
                  </Button>
                }
                popoverContent={
                  newOptimizedRouteLocationsLength ? (
                    <Popover>{translate('routes.alertMessages.addingJobDisabled')}</Popover>
                  ) : undefined
                }
                size="large"
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const formSelector = formValueSelector('routeTemplateEditor');

const mapStateToProps = (state: AppState) => {
  const vehicleTypeId = Number(formSelector(state, 'vehicleTypeId'));
  const scheduledDay = formSelector(state, 'scheduledDay');

  let initialValues = {};

  if (vehicleTypeId === DELIVERY_UTILITY_ID) {
    initialValues = { pickupTypeId: PICKUP_TYPE_SERVICE_ID };
  }

  return {
    initialValues,
    vendorId: currentVendorId(state),
    vehicleTypeId,
    scheduledDay,
  };
};

const mapDispatchToProps = { loadPickupTypes };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<FormValues, PropsWithoutReduxForm>({
    form: 'addRouteTemplateLocation',
    enableReinitialize: true,
  })(AddRouteTemplateLocationForm),
);
