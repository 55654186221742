import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { transferRouteJobs as doTransferRouteJobs } from '../../services/dispatchBoardRouteJobs';
import {
  loadDispatchBoardJobs,
  moveDispatchBoardJobToPosition,
  deleteDispatchBoardJob,
  resetDispatchBoardJobs,
  baseReducer,
} from './dispatchBoardBaseJobs';

const actionMap = {
  START_LOAD: 'dispatchBoard/targetRouteJobs/START_LOAD',
  COMPLETE_LOAD: 'dispatchBoard/targetRouteJobs/COMPLETE_LOAD',
  FAIL_LOAD: 'dispatchBoard/targetRouteJobs/FAIL_LOAD',
  START_DELETE_JOB: 'dispatchBoard/targetRouteJobs/START_DELETE_JOB',
  COMPLETE_DELETE_JOB: 'dispatchBoard/targetRouteJobs/COMPLETE_DELETE_JOB',
  FAIL_DELETE_JOB: 'dispatchBoard/targetRouteJobs/FAIL_DELETE_JOB',
  MOVE_JOB_TO_POSITION: 'dispatchBoard/targetRouteJobs/MOVE_JOB_TO_POSITION',
  RESET: 'dispatchBoard/targetRouteJobs/RESET',
};

interface State {}

type Dispatch = ThunkDispatch<State, {}, AnyAction>;

// Reducer
export const reducer = (state: any, action: AnyAction) => baseReducer(state, action, actionMap);

export const loadDispatchBoardTargetRouteJobs = (routeId: number, searchTerm?: string) => (dispatch: Dispatch) =>
  loadDispatchBoardJobs(dispatch, actionMap, routeId, searchTerm);

export const moveDispatchBoardTargetRouteJobToPosition =
  (fromPosition: number, toPosition: number) => (dispatch: Dispatch, getState: () => any) => {
    const isSource = false;
    const promise = moveDispatchBoardJobToPosition(dispatch, getState, actionMap, fromPosition, toPosition, isSource);
    return promise;
  };

export const deleteDispatchBoardTargetRouteJob = (routeId: number, jobId: number) => (dispatch: Dispatch) =>
  deleteDispatchBoardJob(dispatch, actionMap, routeId, jobId);

export const transferDispatchBoardTargetRouteJob = (routeId: number, jobId: number) => () =>
  doTransferRouteJobs([jobId], routeId);

export const resetDispatchBoardTargetRouteJobs = () => resetDispatchBoardJobs(actionMap);
