import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadVehicleStrobeImageFrequency as doLoadVehicleStrobeImageFrequencyTypes } from '../services/vehicleStrobeImages';

// Actions

const START_LOAD = 'vendors/vehicleStrobeImageFrequencyTypes/START_LOAD';
const COMPLETE_LOAD = 'vendors/vehicleStrobeImageFrequencyTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/vehicleStrobeImageFrequencyTypes/FAIL_LOAD';
const RESET = 'vendors/vehicleStrobeImageFrequencyTypes/RESET';

// Initial state

const initialState = {
  isLoading: false,
  vehicleStrobeImageFrequencyTypes: undefined,
};

// Reducer

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleStrobeImageFrequencyTypes: action.vehicleStrobeImageFrequencyTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehicleStrobeImageFrequencyTypes: any) => ({
  type: COMPLETE_LOAD,
  vehicleStrobeImageFrequencyTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const reset = () => ({
  type: RESET,
});

export const loadVehicleStrobeImageFrequencyTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadVehicleStrobeImageFrequencyTypesPromise = doLoadVehicleStrobeImageFrequencyTypes();
  loadVehicleStrobeImageFrequencyTypesPromise
    .then((vehicleStrobeImageFrequencyTypes: any) => {
      dispatch(completeLoad(vehicleStrobeImageFrequencyTypes));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadVehicleStrobeImageFrequencyTypesPromise;
};

export const resetVehicleStrobeImageFrequencyTypes = () => (dispatch: Dispatch) => {
  dispatch(reset());
};
