import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import moment from 'moment';
import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { Resolver } from '../../../core/components';
import history from '../../../core/services/history';
import { TODAY_FORMATTED } from '../../../core/constants';
import { PageLoading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { getQueryParams } from '../../../utils/services/queryParams';
import translate from '../../../core/services/translate';
import UnassignedWeightTicketsPage from './UnassignedWeightTicketsPage';
import { loadUnassignedWeightTickets, authenticateToken } from '../../ducks/index';

interface Props {
  match: any;
  vendorId: number;
  location: any;
  authenticateToken: (token: string) => Promise<any>;
  loadUnassignedWeightTickets: (
    token: string,
    vendorId: number,
    startDate: string,
    endDate: string,
    searchTerm: string,
    page: number,
  ) => Promise<any>;
}

class UnassignedWeightTicketsPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      authenticateToken,
      vendorId,
      loadUnassignedWeightTickets,
      match: {
        params: { token },
      },
      location: { search },
    } = this.props;

    const { startDate, endDate, searchTerm, page } = getQueryParams(search);
    const from = moment().subtract(3, 'days').toDate();
    return token
      ? authenticateToken(token).then(response => {
          if (!response || response.isVPortalLoginRequired) {
            history.push({ pathname: '/account/logout', state: { from: history.location } });
          } else {
            return loadUnassignedWeightTickets(
              token,
              vendorId,
              startDate || moment(from).format('MM/DD/YYYY'),
              endDate || TODAY_FORMATTED,
              searchTerm,
              page,
            );
          }
        })
      : loadUnassignedWeightTickets(
          token,
          vendorId,
          startDate || moment(from).format('MM/DD/YYYY'),
          endDate || TODAY_FORMATTED,
          searchTerm,
          page,
        );
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('autoDispatch.unassignedWeightTickets')}</DocumentTitle>

        <Resolver
          successComponent={UnassignedWeightTicketsPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/fleet/vehicles"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadUnassignedWeightTickets,
  authenticateToken,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnassignedWeightTicketsPageResolver));
