import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Button, Grid, GridColumn, Message, ModalFixedFooter } from '../../../core/components/styled';
import { MATERIAL_TYPES_FORM } from '../../constants/materialTypes';
import { MaterialTypes } from '../../interfaces/MaterialTypes';
import { Table } from '../../../core/components';
import MaterialTypesFormTableRow from './MaterialTypesFormTableRow';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {
  initialValues: MaterialTypes[];
  onClose: () => void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, {}>;

const MaterialTypesForm: React.FC<Props> = ({ handleSubmit, initialValues: materialTypes, onClose }) => {
  const materialTypesTableCells = [
    { name: 'materialType', label: translate('vendors.materialTypes.materialType'), width: '40%' },
    { name: 'active', label: translate('vendors.materialTypes.active'), width: '25%', align: 'center' },
    {
      name: 'vehicleType',
      label: translate('vendors.materialTypes.vehicleType'),
      width: '35%',
    },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <Grid centered margin="small no">
        <GridColumn size="12/12">
          {!!materialTypes.length ? (
            <Table cells={materialTypesTableCells} rows={materialTypes} rowComponent={MaterialTypesFormTableRow} />
          ) : (
            <Message padding="sMedium">{translate('vendors.noMaterialTypes')}</Message>
          )}
        </GridColumn>
      </Grid>

      <ModalFixedFooter isShadowed>
        <Button type="button" color="secondary" margin="no xSmall" onClick={() => onClose()}>
          {translate('common.cancel')}
        </Button>
        <Button color="primary" type="submit">
          {translate('common.save')}
        </Button>
      </ModalFixedFooter>
    </form>
  );
};

export default reduxForm<any, any>({
  form: MATERIAL_TYPES_FORM,
  enableReinitialize: true,
})(MaterialTypesForm);
