import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { loadCustomerPortalRoles as doLoadCustomerPortalRoles } from '../services/customerPortal';

export interface CustomerPortalRoles {
  id: number;
  name: string;
  technicalName: string;
}

// Actions
const START_LOAD = 'customerPortal/roles/START_LOAD';
const COMPLETE_LOAD = 'customerPortal/roles/COMPLETE_LOAD';
const FAIL_LOAD = 'customerPortal/roles/FAIL_LOAD';
const RESET = 'customerPortal/roles/RESET';

interface State {
  isLoading: boolean;
  customerRoles?: CustomerPortalRoles[];
}

// Initial state
const initialState: State = {
  isLoading: false,
  customerRoles: undefined,
};

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          customerRoles: action.customerRoles,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          customerRoles: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (customerRoles: CustomerPortalRoles) => ({
  type: COMPLETE_LOAD,
  customerRoles,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const reset = () => ({
  type: RESET,
});

export const loadCustomerPortalRoles = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadCustomerPortalRolesPromise = doLoadCustomerPortalRoles();
  loadCustomerPortalRolesPromise
    .then(customerRoles => dispatch(completeLoad(customerRoles)))
    .catch(() => dispatch(failLoad()));
  return loadCustomerPortalRolesPromise;
};

export const resetCustomerPortalRoles = () => (dispatch: Dispatch) => {
  dispatch(reset());
};
