import translate from 'src/core/services/translate';

export const billMonthOfQuarterOptions = [
  {
    label: translate('customers.billing.firstMonth'),
    value: 1,
  },
  {
    label: translate('customers.billing.secondMonth'),
    value: 2,
  },
  {
    label: translate('customers.billing.thirdMonth'),
    value: 3,
  },
];
