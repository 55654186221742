import FileSaver from 'file-saver';

import { http } from '../../core/services/http';
import { EXCEL_MIME_TYPES } from '../../core/constants';
import translate from '../../core/services/translate';
import { RawVehicleInspection } from '../interfaces/VehicleInspections';
import { transformRawVehicleInspections } from './vehicleInspectionsTransformers';

export const loadVehicleInspection = (vendorId: number, vehicleId: number, startDate?: string, endDate?: string) =>
  http
    .get<RawVehicleInspection[]>(`vendors/${vendorId}/tripInspectionWithNoOfIssues`, {
      params: { vehicleId, startDate, endDate },
    })
    .then(response => transformRawVehicleInspections(response.data));

export const loadVehicleTripInspectionDetails = (vendorId?: number, tripInspectionId?: number) =>
  http
    .get(`/vendors/${vendorId}/tripInspectionWithDetails`, { params: { tripInspectionId } })
    .then(response => response.data);

export const exportTripInspectionReport = (vendorId: number, date: Date | string, tripInspectionId: number) => {
  return http
    .post(
      `/reports/vendors/${vendorId}/tripInspectionReport`,
      { vendorId, date, tripInspectionId },
      {
        responseType: 'arraybuffer',
      },
    )
    .then(response => {
      const blob = new Blob([response.data], {
        type: EXCEL_MIME_TYPES,
      });
      FileSaver.saveAs(
        blob,
        `${translate('vehicles.vehicleInspections.tripInspectionReport')}-${tripInspectionId}-${date},
        )}.xlsx`,
      );
    });
};

export const exportTripInspectionReportV2 = (vendorId: number, tripInspectionId: number) => {
  return http
    .post(
      `/reports/vendors/${vendorId}/tripInspectionReport`,
      { tripInspectionId },
      {
        responseType: 'arraybuffer',
      },
    )
    .then(response => {
      const blob = new Blob([response.data], {
        type: EXCEL_MIME_TYPES,
      });

      FileSaver.saveAs(blob, `${translate('vehicles.vehicleInspections.tripInspectionReport')}.xlsx`);
    });
};
