import { identity, map } from 'lodash-es';
import { createSelector } from 'reselect';

import { getQueryParams } from '../../utils/services/queryParams';
import { FOURTEEN_DAYS_AFTER_TODAY, TODAY_FORMATTED } from '../../core/constants/weekdays';

const getUpcomingServicesFormInitialValuesSelector = (queryString: string) => {
  const { locationId, serviceContractIds, startDate, endDate } = getQueryParams(queryString);

  return {
    locationId: locationId && Number(locationId),
    serviceContractIds: serviceContractIds && map(serviceContractIds.toString().split(','), Number),
    date: {
      from: startDate || TODAY_FORMATTED,
      to: endDate || FOURTEEN_DAYS_AFTER_TODAY,
    },
  };
};

const upcomingServicesFormInitialValuesSelector = createSelector(
  getUpcomingServicesFormInitialValuesSelector,
  identity,
);
export default upcomingServicesFormInitialValuesSelector;
