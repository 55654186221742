import { useDispatch } from 'react-redux';
import humps from 'humps';

import { Button, TableCell, TableRow, Text } from 'src/core/components/styled';
import { dateAndTime } from 'src/utils/services/formatter';
import { downloadAlertsImportFile } from 'src/fleet/ducks/locationAndCityAlertsImports';
import { getStatusColor } from 'src/customers/components/modals/customerImportModal/CustomerImportModalTableRow';
import { Loader } from 'src/customers/components/styled/CustomerLocations';
import { StreetsImportFile } from 'src/customers/interfaces/Streets';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import translate from 'src/core/services/translate';

interface Props extends StreetsImportFile {
  isLoading: boolean;
  tableCellsWidths: string[];
}

export const StreetsImportModalTableRow = ({
  date,
  errorFileDownloadUrl,
  errorFileName,
  failedRecordsCount,
  fileDownloadUrl,
  fileName,
  id,
  isLoading,
  status,
  successRecordsCount,
  tableCellsWidths,
  totalRecordsCount,
}: Props) => {
  const dispatch = useDispatch();

  const statusColor = getStatusColor(status);

  const showLoading = status !== 'ValidationFailed' && status !== 'Uploaded' && isLoading;

  const handleDownloadFile = (fileUrl: string, fileName: string, date?: string) => {
    downloadAlertsImportFile(fileUrl, fileName, date)(dispatch);
  };

  return (
    <TableRow key={id} height={TABLE_ROW_HEIGHT_SMALL}>
      <TableCell width={tableCellsWidths[0]}>
        {fileDownloadUrl ? (
          <Button
            fullWidth
            text
            noCapitalize
            color="primary"
            onClick={() => handleDownloadFile(fileDownloadUrl, fileName)}
          >
            <Text align="left" block singleLine title={fileName}>
              {fileName}
            </Text>
          </Button>
        ) : (
          <Text align="left" block singleLine title={fileName}>
            {fileName}
          </Text>
        )}
      </TableCell>
      <TableCell width={tableCellsWidths[1]}>
        {showLoading ? (
          <Loader />
        ) : status ? (
          <Text color={statusColor}>{translate(`customers.customerUpload.statuses.${humps.camelize(status)}`)}</Text>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell width={tableCellsWidths[2]}>
        <Text>{totalRecordsCount}</Text>
      </TableCell>
      <TableCell width={tableCellsWidths[3]}>
        <Text>{failedRecordsCount}</Text>
      </TableCell>
      <TableCell width={tableCellsWidths[4]}>
        <Text>{successRecordsCount}</Text>
      </TableCell>
      <TableCell width={tableCellsWidths[5]}>
        {errorFileDownloadUrl ? (
          <Button text color="primary" onClick={() => handleDownloadFile(errorFileDownloadUrl, errorFileName, date)}>
            {translate('common.download')}
          </Button>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell width={tableCellsWidths[6]}>
        <Text>{dateAndTime(date)}</Text>
      </TableCell>
    </TableRow>
  );
};
