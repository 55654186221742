import { includes } from 'lodash-es';
import moment from 'moment';

import { getItem, setItem, removeItem } from '../../core/services/persistentStorage';
import { TODAY_END_OF_DAY } from '../../core/constants';

export const ROUTE_MAP_FILTER_PERSISTENT_STORAGE_KEY = 'routeMapFilters';

export const getPersistentMapFilters = (routeOrTemplateId?: number, pageType?: string) => {
  let filters;
  try {
    filters = JSON.parse(getItem(`${ROUTE_MAP_FILTER_PERSISTENT_STORAGE_KEY}_${routeOrTemplateId}_${pageType}`) || '');
  } catch (error) {
    removeItem(`${ROUTE_MAP_FILTER_PERSISTENT_STORAGE_KEY}_${routeOrTemplateId}`);
    filters = {};
  }

  return filters;
};

export const setPersistentMapFilters = (filters: any) => {
  setItem(
    `${ROUTE_MAP_FILTER_PERSISTENT_STORAGE_KEY}_${filters.routeOrTemplateId}_${filters.pageType}`,
    JSON.stringify(filters),
    TODAY_END_OF_DAY as any,
  );
};

export const removePersistentMapFilters = () => {
  for (const key in localStorage) {
    if (includes(key, ROUTE_MAP_FILTER_PERSISTENT_STORAGE_KEY) && includes(key, 'Exp')) {
      const storageTime = localStorage.getItem(key);
      const expirationTime = storageTime ? moment(new Date(storageTime)) : undefined;
      if (expirationTime && moment().isAfter(expirationTime)) {
        removeItem(key.split('Exp')[0]);
      }
    }
  }
};
