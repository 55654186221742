import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { BareButtonStyle } from './Button';
import { InputStyle, SelectStyle } from './Input';
import ImpDatePickerInput from 'react-day-picker/DayPickerInput';

const horizontalAlign = {
  left: css`
    right: 0;
  `,
  right: css`
    right: auto;
  `,
  middle: css`
    right: -50%;
  `,
};

const verticalAlign = {
  top: css`
    bottom: 100%;
  `,
  bottom: css`
    top: 100%;
  `,
};

export const DatePickerInput = styled(ImpDatePickerInput)`
  width: 100%;
`;

export type HorizontalAlign = 'left' | 'right' | 'middle';
export type VerticalAlign = 'top' | 'bottom';

interface Props {
  focus?: boolean;
  hasTodayButton?: boolean;
  horizontalAlign?: HorizontalAlign;
  isDateRangePicker?: boolean;
  isTodayWithNoStyle?: boolean;
  isValueHidden?: boolean;
  mask?: string;
  numberOfMonths?: number;
  position?: string;
  tabletAlignHalfLeft?: boolean;
  tabletAlignLeft?: boolean;
  verticalAlign?: VerticalAlign;
}

const DatePicker = styled.div<Props>`
  ${props =>
    props.position &&
    css`
      position: ${props.position};
    `};

  .DayPickerInput {
    * {
      outline: none;
    }

    ${props =>
      props.focus &&
      css`
        border-bottom-color: ${props.theme.brandPrimary};
      `}

    input {
      ${InputStyle};
      ${SelectStyle};

      ${props =>
        props.isValueHidden &&
        css`
          text-indent: -9999px;
          color: transparent;
        `}
    }
  }

  .DayPickerInput-Overlay {
    position: absolute;
    z-index: 3000;
    padding: 10px;
    background: #fff;
    box-shadow: 0 4px 8px ${props => transparentize(0.9, props.theme.grayBase)};

    ${props => (props.horizontalAlign ? horizontalAlign[props.horizontalAlign] : horizontalAlign['right'])};

    ${props => (props.verticalAlign ? verticalAlign[props.verticalAlign] : verticalAlign['bottom'])};

    @media (max-width: ${props => props.theme.tabletBreakpoint}) {
      right: 0;

      ${props =>
        props.tabletAlignLeft &&
        css`
          right: auto;
        `};

      ${props =>
        props.tabletAlignHalfLeft &&
        css`
          right: -50%;
        `};
    }
  }

  .DayPicker {
    display: inline-block;
    width: 100%;
  }

  .DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    user-select: none;
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .DayPicker-Month {
    display: table;
    margin: 15px 15px 0;
    border-collapse: collapse;
    border-spacing: 0;
    user-select: none;
  }

  .DayPicker-NavBar {
    position: relative;
    width: ${props =>
      props.isDateRangePicker && props.numberOfMonths !== 1
        ? '70%'
        : props.numberOfMonths === 1 || !props.hasTodayButton
        ? '100%'
        : '70%'};
  }

  .DayPicker-NavButton {
    position: absolute;
    top: 0;
    display: inline-block;
    padding: 14px 14px;
    cursor: pointer;
    background-size: 50%;
    background: center no-repeat;
    border-radius: 3px;
    transition: opacity 0.2s ease-out;

    &:hover {
      background-color: ${props => props.theme.grayLight};
    }
  }

  .DayPicker-NavButton--prev {
    left: 5px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNy40IDEuNEw2IDAgMCA2bDYgNiAxLjQtMS40TDIuOCA2eiIgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBmaWxsLW9wYWNpdHk9IjAuNSIvPjwvc3ZnPg==');
  }

  .DayPicker-NavButton--next {
    right: ${props => (props.isDateRangePicker ? (props.numberOfMonths === 1 ? '0' : '-135px') : '5px')};
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNLjYgMS40TDIgMGw2IDYtNiA2LTEuNC0xLjRMNS4yIDZ6IiBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtb3BhY2l0eT0iMC41Ii8+PC9zdmc+);
  }

  .DayPicker-NavButton--interactionDisabled {
    display: none;
  }

  .DayPicker-Caption {
    display: table-caption;
    margin: ${props => (props.isDateRangePicker ? '0px auto 15px' : '0px 0px 10px 0px')};
    text-align: center;
    line-height: 18px;
    font-weight: ${props => props.theme.fontWeightMedium};
    font-size: 14px;
    width: ${props => (props.hasTodayButton ? '70%' : '100%')};
  }

  .DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 15px;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    min-width: 32px;
    padding: 8px 6px;
    text-align: center;
    font-weight: ${props => props.theme.fontWeightMedium};
    font-size: 12px;
    color: ${props => props.theme.grayDark};
    word-break: keep-all;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Month {
    margin: 5px 5px 0;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Day {
    display: table-cell;
    padding: 8px 0;
    vertical-align: middle;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .DayPicker-TodayButton {
    ${BareButtonStyle};
    padding: 8px;
    border-radius: 3px;
    font-weight: ${props => props.theme.fontWeightMedium};
    font-size: 12px;
    color: ${props => props.theme.brandPrimary};
    transition: opacity 0.2s ease-out;
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      opacity: 1;
    }

    &:active {
      background-color: ${props => props.theme.grayLight};
    }
  }

  .DayPicker-Day--today {
    font-weight: ${props => props.theme.fontWeightMedium};
    color: ${props => props.theme.brandPrimary};
    background: ${props => props.theme.gray};
    border-radius: 50%;
    ${props =>
      props.isTodayWithNoStyle &&
      css`
        background: none;
        border-radius: 0%;
        color: ${props => props.theme.brandDefault};
      `};
  }

  .DayPicker-Day--outside {
    visibility: hidden;
  }

  .DayPicker-Day--disabled {
    cursor: default;
    pointer-events: none;
    color: ${props => props.theme.grayDark};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
    background-color: ${props => props.theme.brandPrimary};
    border-radius: 3px;
    color: #fff;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: ${props => transparentize(0.9, props.theme.brandPrimary)};
    border-radius: 0;
    color: ${props => props.theme.brandPrimary};
  }

  .DayPicker:not(.DayPicker--interactionDisabled) {
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover {
      background-color: ${props => props.theme.grayLight};
      border-radius: 3px;
    }
  }

  ${props =>
    props.mask &&
    css`
      &:after {
        content: '${props.mask}';
        position: absolute;
        top: 1px;
        left: 0;
        right: 36px;
        bottom: 1px;
        padding: 2px 2px 8px 0;
        background-color: white;
        line-height: 22px;
        font-family: inherit;
        font-weight: ${props.theme.fontWeightLight};
        font-size: 14px;
        text-overflow: ellipsis;
        pointer-events: none;
      }
    `}
`;

export default DatePicker;
