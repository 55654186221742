import { filter, find, groupBy, map, size } from 'lodash-es';
import { change, getFormValues } from 'redux-form';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  checkIfGeoFenceIsEnabled,
  checkIfRoadConditionsAreEnabled,
  checkIfSnowRoadConditionsAreEnabled,
  checkIfStreetSweepingConditionsAreEnabled,
  flagLocationStatusSelector,
} from 'src/vendors/ducks/features';
import {
  CUSTOMER_SUBTYPES,
  CUSTOMER_SUBTYPE_CONTAINER_YARD,
  CUSTOMER_SUBTYPE_FUELING_STATION,
  CUSTOMER_SUBTYPE_LANDFILL,
  CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION,
  CUSTOMER_SUBTYPE_WATER_FILLING_STATION,
  CUSTOMER_TYPES,
} from 'src/common/constants';
import {
  DashboardExtraFiltersHeader,
  DashboardExtraFiltersHeaderItem,
} from 'src/dashboard/components/styled/DashboardFilterMapbox';
import { DAILY_ROUTE_GEO_FENCE_ID, GENERAL_GEO_FENCE_ID, GEO_FENCES_ALL_TYPES } from 'src/routes/constants';
import { filtersByVehicleSearchTermSelector } from 'src/dashboard/ducks';
import { GeoFenceMapFilterOption } from 'src/routes/ducks/geoFenceFilterOptions';
import { IconButton, IconButtonIcon } from 'src/core/components/styled';
import { isSmartCitySelector } from 'src/account/ducks';
import { multiWordAndSearch } from 'src/core/services/search';
import { useSelector } from 'src/core/hooks/useSelector';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import translate from 'src/core/services/translate';
import { DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME, DashboardExtraMapLayersFormValues } from './ExtraMapLayersWidgetContent';
import { CityAlertSetting } from 'src/vendors/interfaces/CityAlertSettings';
import { getActiveCityAlertTypes } from 'src/fleet/components/forms/LocationAndCityAlertsSearchForm';

const FILTER_CATEGORIES = {
  cityInsights: 'cityInsights',
  cityAlerts: 'cityAlerts',
  geoFences: 'geoFences',
  vendorLocations: 'vendorLocations',
};

interface ExtraMapLayersHeaderMenuProps {
  isGeoFencesOpen: boolean;
  isCityInsightsOpen: boolean;
  isCityAlertsOpen: boolean;
  isHaulerLocationsOpen: boolean;
  isForSnowSweeper?: boolean;
  toggleCategory: (category: string) => void;
}

const ExtraMapLayersHeaderMenu: FC<ExtraMapLayersHeaderMenuProps> = ({
  isGeoFencesOpen,
  isCityInsightsOpen,
  isCityAlertsOpen,
  isHaulerLocationsOpen,
  isForSnowSweeper,
  toggleCategory,
}) => {
  const dispatch = useDispatch();

  const thisFormValues = useSelector(
    getFormValues(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME),
  ) as DashboardExtraMapLayersFormValues;

  const isGeoFenceActive = useSelector(checkIfGeoFenceIsEnabled);
  const roadConditionsEnabled = useSelector(checkIfRoadConditionsAreEnabled);
  const snowRoadConditionsEnabled = useSelector(checkIfSnowRoadConditionsAreEnabled);
  const streetSweepingConditionsEnabled = useSelector(checkIfStreetSweepingConditionsAreEnabled);
  const isSmartCity = useSelector(state => isSmartCitySelector(state.account.login, state.vendors.defaultVendor));
  const isFlagLocationActive = useSelector(state => flagLocationStatusSelector(state.vendors.features.features));

  const filters = useSelector(state =>
    filtersByVehicleSearchTermSelector(state.dashboard.filters.filters, thisFormValues),
  ) as any;

  const geoFenceMapFilterOptions = useSelector(
    state => state.routes.geoFencesFilterOptions.geoFencesFilterOptions.geoFenceMapFilterOptions,
  );

  const cityAlertSettings = useSelector(state => state.vendors.cityAlertSettings?.cityAlertSettings);
  const activeCityAlertTypes = getActiveCityAlertTypes(cityAlertSettings).activeCityAlertSettings;
  const hasCityAlerts = getActiveCityAlertTypes(cityAlertSettings).hasCityAlerts;

  const vendorLocationsForMapbox = useSelector(state => state.dashboard.vendorLocations?.vendorLocationsForMapbox);

  const cityFlaggingIsEnabled = !!filters?.cityInsightTypes.filters.length && isSmartCity && isFlagLocationActive;
  const cityInsightsSectionIsVisible =
    cityFlaggingIsEnabled || roadConditionsEnabled || snowRoadConditionsEnabled || streetSweepingConditionsEnabled;

  const geoFenceFilters = useMemo(() => {
    const geoFencesGroupedByType = groupBy(
      geoFenceMapFilterOptions,
      (geoFence: GeoFenceMapFilterOption) => geoFence.zoneTypeId,
    );

    return map(
      Object.keys(geoFencesGroupedByType).filter(key =>
        [DAILY_ROUTE_GEO_FENCE_ID, GENERAL_GEO_FENCE_ID].includes(Number(key)),
      ),
      geoFenceZoneTypeId => {
        const geoFences = filter(
          geoFencesGroupedByType[geoFenceZoneTypeId],
          geoFence =>
            !thisFormValues?.geoFences?.searchTerm ||
            multiWordAndSearch(geoFence.name, thisFormValues?.geoFences?.searchTerm),
        );
        const geoFenceZoneTypeName = GEO_FENCES_ALL_TYPES[geoFenceZoneTypeId];
        return {
          name: `geoTypeId_${geoFenceZoneTypeId}`,
          label: geoFenceZoneTypeName.name,
          subFilters: map(geoFences, (option: GeoFenceMapFilterOption) => ({
            name: `geoId_${option.id}`,
            label: option.name,
          })) as any,
        };
      },
    );
  }, [geoFenceMapFilterOptions, thisFormValues?.geoFences?.searchTerm]);

  const haulerLocationsFilters = useMemo(() => {
    const locationGroupedByType = groupBy(vendorLocationsForMapbox, location => location.customerTypeId);
    return map(Object.keys(locationGroupedByType), locationTypeId => {
      const locations = locationGroupedByType[locationTypeId];

      const locationsGroupedBySubType = groupBy(locations, location => location.customerSubType);
      const locationTypeName = CUSTOMER_TYPES[locationTypeId];
      return {
        name: `locationTypeId_${locationTypeId}`,
        label: locationTypeName.name,
        subFilters: map(
          filter(Object.keys(locationsGroupedBySubType), subType =>
            isForSnowSweeper
              ? ![CUSTOMER_SUBTYPE_LANDFILL, CUSTOMER_SUBTYPE_CONTAINER_YARD].includes(
                  locationsGroupedBySubType[subType][0].customerSubTypeId,
                )
              : true,
          ),
          locationSubType => {
            const subTypeId = locationsGroupedBySubType[locationSubType][0].customerSubTypeId;
            const locationSubTypeName = find(CUSTOMER_SUBTYPES, { id: subTypeId })?.name || '';
            return {
              name: `locationSubTypeId_${subTypeId}`,
              label: locationSubTypeName,
              subFilters:
                isForSnowSweeper &&
                [
                  CUSTOMER_SUBTYPE_FUELING_STATION,
                  CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION,
                  CUSTOMER_SUBTYPE_WATER_FILLING_STATION,
                ].includes(locationsGroupedBySubType[locationSubType][0].customerSubTypeId)
                  ? (map(locationsGroupedBySubType[locationSubType], location => {
                      return {
                        name: `locationId_${location.id}`,
                        label: location.locationName,
                      };
                    }) as any)
                  : [],
            };
          },
        ) as any,
      };
    });
  }, [isForSnowSweeper, vendorLocationsForMapbox]);

  const cityInsightsFilters = useMemo(() => {
    const cityInsightsFilters = [];
    if (cityFlaggingIsEnabled && filters.cityInsightTypes) {
      cityInsightsFilters.push({
        name: 'cityInsightTypes',
        label: translate('routes.filters.allCityInsights'),
        subFilters: map(filters.cityInsightTypes.filters, (option: any) => {
          const insightType = option.formName.split('.').pop();
          return {
            name: insightType,
            label: translate(option.label),
          };
        }),
      });
    }

    return cityInsightsFilters;
  }, [cityFlaggingIsEnabled, filters.cityInsightTypes]);

  const cityAlertsFilters = useMemo(() => {
    const cityAlertsFilters = [];
    if (hasCityAlerts && activeCityAlertTypes) {
      cityAlertsFilters.push({
        name: 'cityAlertTypes',
        label: translate('routes.filters.allCityAlerts'),
        subFilters: map(activeCityAlertTypes, (option: CityAlertSetting) => {
          const alertType = option.cityAlertType;
          return {
            name: `alertType_${alertType.id}`,
            label: alertType.name,
          };
        }),
      });
    }
    return cityAlertsFilters;
  }, [hasCityAlerts, activeCityAlertTypes]);

  const handleToggleAllGeoFences = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      toggleCategory(FILTER_CATEGORIES.geoFences);
      dispatch(change(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME, 'isGeoFencesLayerOn', !thisFormValues?.isGeoFencesLayerOn));
      const newGeoFenceFormValues = map(geoFenceFilters, geoFence => {
        const subFilters = map(geoFence.subFilters, subFilter => ({
          [subFilter.name]: !thisFormValues?.isGeoFencesLayerOn,
        }));

        return {
          [geoFence.name]: {
            groupFilters: Object.assign({}, ...subFilters),
            isGroupSelected: !thisFormValues?.isGeoFencesLayerOn,
          },
        };
      }).reduce((acc, val) => Object.assign(acc, val), {});
      dispatch(change(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME, 'geoFences.groups', newGeoFenceFormValues));
    },
    [dispatch, geoFenceFilters, thisFormValues?.isGeoFencesLayerOn, toggleCategory],
  );

  const handleActiveCityInsights = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      toggleCategory(FILTER_CATEGORIES.cityInsights);
      dispatch(
        change(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME, 'isCityInsightsLayerOn', !thisFormValues?.isCityInsightsLayerOn),
      );
      const newCityInsightsFormValues = map(cityInsightsFilters, cityInsight => {
        const subFilters = map(cityInsight.subFilters, subFilter => ({
          [subFilter.name]: !thisFormValues?.isCityInsightsLayerOn,
        }));

        return {
          [cityInsight.name]: {
            groupFilters: Object.assign({}, ...subFilters),
            isGroupSelected: !thisFormValues?.isCityInsightsLayerOn,
          },
        };
      }).reduce((acc, val) => Object.assign(acc, val), {});
      dispatch(change(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME, 'cityInsights.groups', newCityInsightsFormValues));
    },
    [cityInsightsFilters, dispatch, thisFormValues?.isCityInsightsLayerOn, toggleCategory],
  );

  const handleActiveHaulerLocations = useCallback(
    async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      toggleCategory(FILTER_CATEGORIES.vendorLocations);

      const newHaulerLocationsFormValues = map(haulerLocationsFilters, haulerLocation => {
        const subFilters = map(haulerLocation.subFilters, subFilter => ({
          [subFilter.name]: size(subFilter.subFilters)
            ? {
                groupFilters: Object.assign(
                  {},
                  ...map(subFilter.subFilters, subSubFilter => ({
                    [subSubFilter.name]: !thisFormValues?.isHaulerLocationsLayerOn,
                  })),
                ),
                isGroupSelected: !thisFormValues?.isHaulerLocationsLayerOn,
              }
            : !thisFormValues?.isHaulerLocationsLayerOn,
        }));

        return {
          [haulerLocation.name]: {
            groupFilters: Object.assign({}, ...subFilters),
            isGroupSelected: !thisFormValues?.isHaulerLocationsLayerOn,
          },
        };
      }).reduce((acc, val) => Object.assign(acc, val), {});

      dispatch(change(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME, 'haulerLocations.groups', newHaulerLocationsFormValues));
      dispatch(
        change(
          DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME,
          'isHaulerLocationsLayerOn',
          !thisFormValues?.isHaulerLocationsLayerOn,
        ),
      );
    },
    [dispatch, haulerLocationsFilters, thisFormValues?.isHaulerLocationsLayerOn, toggleCategory],
  );

  const handleActiveCityAlerts = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      toggleCategory(FILTER_CATEGORIES.cityAlerts);
      dispatch(
        change(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME, 'isCityAlertsLayerOn', !thisFormValues?.isCityAlertsLayerOn),
      );
      const newCityAlertsFormValues = map(cityAlertsFilters, cityAlert => {
        const subFilters = map(cityAlert.subFilters, subFilter => ({
          [subFilter.name]: !thisFormValues?.isCityAlertsLayerOn,
        }));

        return {
          [cityAlert.name]: {
            groupFilters: Object.assign({}, ...subFilters),
            isGroupSelected: !thisFormValues?.isCityAlertsLayerOn,
          },
        };
      }).reduce((acc, val) => Object.assign(acc, val), {});

      dispatch(change(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME, 'cityAlerts.groups', newCityAlertsFormValues));
    },
    [cityAlertsFilters, dispatch, thisFormValues?.isCityAlertsLayerOn, toggleCategory],
  );

  return (
    <DashboardExtraFiltersHeader isSubPanelOpen={isGeoFencesOpen || isCityInsightsOpen || isHaulerLocationsOpen}>
      {isGeoFenceActive && (
        <DashboardExtraFiltersHeaderItem>
          <TooltipIconButton
            tooltip="geoFences"
            margin="no xSmall"
            color="secondary"
            onClick={handleToggleAllGeoFences}
          >
            <IconButtonIcon
              size="mLarge"
              color={thisFormValues?.isGeoFencesLayerOn ? 'primary' : ''}
              icon="pinWithCircle"
              customViewBox="-3 0 28 25"
            />
          </TooltipIconButton>
          <IconButton
            margin="no xSmall"
            color={isGeoFencesOpen ? 'primary' : 'grayDarker'}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              toggleCategory(FILTER_CATEGORIES.geoFences);
            }}
          >
            <IconButtonIcon icon="arrowDown" />
          </IconButton>
        </DashboardExtraFiltersHeaderItem>
      )}
      {cityInsightsSectionIsVisible && (
        <DashboardExtraFiltersHeaderItem>
          <TooltipIconButton
            tooltip="cityInsights"
            margin="no xSmall"
            color="secondary"
            onClick={handleActiveCityInsights}
          >
            <IconButtonIcon
              size="mLarge"
              icon="cityInsightsFilters"
              customViewBox="0 0 32 22"
              color={thisFormValues?.isCityInsightsLayerOn ? 'primary' : ''}
            />
          </TooltipIconButton>
          <IconButton
            margin="no xSmall"
            color={isCityInsightsOpen ? 'primary' : 'grayDarker'}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              toggleCategory(FILTER_CATEGORIES.cityInsights);
            }}
          >
            <IconButtonIcon icon="arrowDown" />
          </IconButton>
        </DashboardExtraFiltersHeaderItem>
      )}

      {hasCityAlerts && (
        <DashboardExtraFiltersHeaderItem>
          <TooltipIconButton tooltip="cityAlerts" margin="no xSmall" color="secondary" onClick={handleActiveCityAlerts}>
            <IconButtonIcon size="mLarge" icon="alert" color={thisFormValues?.isCityAlertsLayerOn ? 'primary' : ''} />
          </TooltipIconButton>
          <IconButton
            margin="no xSmall"
            color={isCityAlertsOpen ? 'primary' : 'grayDarker'}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              toggleCategory(FILTER_CATEGORIES.cityAlerts);
            }}
          >
            <IconButtonIcon icon="arrowDown" />
          </IconButton>
        </DashboardExtraFiltersHeaderItem>
      )}

      <DashboardExtraFiltersHeaderItem>
        <TooltipIconButton
          tooltip="haulerLocations"
          tooltipPosition="left"
          margin="no xSmall"
          color="secondary"
          onClick={handleActiveHaulerLocations}
        >
          <IconButtonIcon
            size="mLarge"
            icon="geoFencesFilters"
            customViewBox="0 0 28 22"
            color={thisFormValues?.isHaulerLocationsLayerOn ? 'primary' : ''}
          />
        </TooltipIconButton>
        <IconButton
          margin="no xSmall"
          color={isHaulerLocationsOpen ? 'primary' : 'grayDarker'}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            toggleCategory(FILTER_CATEGORIES.vendorLocations);
          }}
        >
          <IconButtonIcon icon="arrowDown" />
        </IconButton>
      </DashboardExtraFiltersHeaderItem>
    </DashboardExtraFiltersHeader>
  );
};

export default ExtraMapLayersHeaderMenu;
