import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { Modal } from '../../../core/components';
import { ModalSection } from '../../../core/components/styled';
import { saveSOSAlertSettings } from '../../ducks/';
import { SOSAlertSettings } from '../../interfaces/SOSAlertSettings';
import { SOSAlertSettingForm } from '../forms';
import translate from '../../../core/services/translate';

interface Props {
  closeModal: (pristine?: boolean) => void;
}

export default function SOSAlertModal({ closeModal }: Props) {
  const dispatch = useDispatch();
  const { sosAlertSettings, isSaving } = useSelector((state: AppState) => state.vendors.sosAlertSettings);

  const handleSubmit = (formData: SOSAlertSettings) => {
    saveSOSAlertSettings(formData)(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.sosAlertSettingSaved'));
        closeModal();
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.sosAlertSettingError')));
  };

  return (
    <Modal size="medium" title={translate('vendors.sosAlert.sosAlert')} onClose={closeModal}>
      <ModalSection padding="no" isLoading={isSaving}>
        <SOSAlertSettingForm onSubmit={handleSubmit} initialValues={sosAlertSettings} />
      </ModalSection>
    </Modal>
  );
}
