import { Dictionary } from 'src/common/interfaces/Dictionary';
import translate from 'src/core/services/translate';

export const getNumberOfActiveFilters = (filters: Dictionary<boolean>, allKey: string = 'all') =>
  Object.keys(filters).filter(k => k !== allKey && filters[k]).length;

export const getNumberOfFiltersSelectedLabel = (filters: Dictionary<boolean>, allKey: string = 'all') =>
  filters[allKey]
    ? translate('common.allSelected')
    : translate('common.xSelected', { selected: getNumberOfActiveFilters(filters, allKey) });
