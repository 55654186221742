import { connect } from 'react-redux';
import { PureComponent } from 'react';

import { AppState } from 'src/store';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DuckFunction } from '../../../../../contracts/ducks';
import { loadMaterialPickups, loadMaterialTickets } from '../../../../ducks';
import { MaterialTicketsModal } from '.';
import { PageLoadingOverlay } from '../../../../../common/components/styled';
import { Resolver } from '../../../../../core/components';

interface MaterialTicketsModalResolverProps {
  closeModal: () => void;
  loadMaterialTickets: DuckFunction<typeof loadMaterialTickets>;
  loadMaterialPickups: DuckFunction<typeof loadMaterialPickups>;
  modalTitle?: string;
  routeId: number;
  vendorId: number;
}

class MaterialTicketsModalResolver extends PureComponent<MaterialTicketsModalResolverProps> {
  loadDependencies = () => {
    const { loadMaterialPickups, loadMaterialTickets, routeId, vendorId } = this.props;
    return Promise.all([loadMaterialTickets(routeId), loadMaterialPickups(vendorId, routeId)]);
  };

  render() {
    const { modalTitle, closeModal } = this.props;

    return (
      <Resolver
        successComponent={MaterialTicketsModal}
        successProps={{
          modalTitle,
          closeModal,
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapDispatchToProps = { loadMaterialTickets, loadMaterialPickups };

export default connect(
  (state: AppState) => ({
    vendorId: currentVendorId(state),
  }),
  mapDispatchToProps,
)(MaterialTicketsModalResolver);
