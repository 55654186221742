import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { difference, get, size } from 'lodash-es';

import { AppState } from '../../../store';
import { Button, ButtonSet, Grid, GridColumn, Message } from '../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { FeatureConfigurationForm } from '../forms';
import { FeatureConfigurationModalTableRow } from './';
import { Modal, Table } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { FeatureConfigurations } from '../../interfaces/FeatureConfiguration';
import { resetFeatureConfigurations, saveFeatureConfigurations, toggleFeatureConfiguration } from '../../ducks';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  featureConfigurations?: FeatureConfigurations;
  isLoading: boolean;
  resetFeatureConfigurations: DuckAction<typeof resetFeatureConfigurations>;
  saveFeatureConfigurations: DuckFunction<typeof saveFeatureConfigurations>;
  toggleFeatureConfiguration: DuckAction<typeof toggleFeatureConfiguration>;
  vendorId: number;
}

interface State {
  featureConfigurations?: FeatureConfigurations;
  initialValuesLoaded: boolean;
}

class FeatureConfigurationModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      featureConfigurations: undefined,
      initialValuesLoaded: false,
    };
  }

  componentDidUpdate() {
    if (!!size(this.props.featureConfigurations) && !this.state.initialValuesLoaded) {
      this.setState({ initialValuesLoaded: true, featureConfigurations: this.props.featureConfigurations });
    }
  }

  componentWillUnmount() {
    this.props.resetFeatureConfigurations();
  }

  onChangeVehicleTypeId = () => {
    this.setState({ initialValuesLoaded: false });
  };

  saveFeatureConfigurations = () => {
    const { vendorId, featureConfigurations, saveFeatureConfigurations, closeModal } = this.props;
    saveFeatureConfigurations(vendorId, featureConfigurations)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.featureConfigurationSaved'));
        closeModal(true);
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.saveFeatureConfigurationError')));
  };

  closeModal = () => {
    const { featureConfigurations, closeModal } = this.props;
    closeModal(
      !size(
        difference(
          get(featureConfigurations, 'configData'),
          get(this.state.featureConfigurations ? this.state.featureConfigurations : [], 'configData'),
        ),
      ),
    );
  };

  render() {
    const { featureConfigurations, toggleFeatureConfiguration, featureCode, isLoading, vendorId } = this.props;
    const featureConfigurationTableCells = [
      { name: 'featureName', label: translate('vendors.configurations'), width: '80%' },
      { name: 'status', width: '20%', align: 'right' },
    ];

    return (
      <Modal
        title={translate(createTranslationKey(featureCode || '', 'vendors.featureCodes'))}
        onClose={this.closeModal}
        verticalSize="small"
        isLoading={isLoading}
      >
        <FeatureConfigurationForm
          vendorId={vendorId}
          featureCode={featureCode}
          onChangeVehicleTypeId={this.onChangeVehicleTypeId}
        />
        {featureConfigurations && !!size(featureConfigurations.configData) && (
          <Table
            cells={featureConfigurationTableCells}
            rows={featureConfigurations.configData}
            rowComponent={FeatureConfigurationModalTableRow}
            rowProps={{ toggleFeatureConfiguration }}
          />
        )}
        {featureConfigurations && !size(featureConfigurations.configData) && (
          <Message padding="sMedium">{translate('vendors.noConfigurations')}</Message>
        )}
        {featureConfigurations && (
          <Grid centered>
            <GridColumn size="6/12">
              <ButtonSet margin="medium no">
                <Button color="primary" onClick={this.saveFeatureConfigurations}>
                  {translate('common.save')}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  featureConfigurations: state.vendors.featureConfigurations.featureConfigurations,
  isLoading: state.vendors.featureConfigurations.isLoading,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  saveFeatureConfigurations,
  resetFeatureConfigurations,
  toggleFeatureConfiguration,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeatureConfigurationModal);
