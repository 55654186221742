import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { loadSOSAlertSettings } from '../../ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import SOSAlertModal from './SOSAlertModal';

interface Props {
  closeModal: (pristine?: boolean) => void;
}

export default function SOSAlertModalResolver(props: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = () => {
    if (!vendorId) return Promise.reject();

    return loadSOSAlertSettings(vendorId)(dispatch);
  };

  return (
    <Resolver
      successComponent={SOSAlertModal}
      successProps={{ ...props, vendorId }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
}
