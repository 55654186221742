import update from 'immutability-helper';
import { filter, findIndex, identity } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

import store from '../../store';
import { Vehicle, VehiclesState, VehicleSummary } from '../interfaces/Vehicles';
import {
  deleteVehicle as doDeleteVehicle,
  deleteVehicles as doDeleteVehicles,
  assignVehicles as doAssignVehicles,
} from '../services/vehicle';
import * as Services from '../services/vehicles';

// Actions
const START_LOAD = 'fleet/vehicles/START_LOAD';
export const COMPLETE_LOAD = 'fleet/vehicles/COMPLETE_LOAD';
export const SORT_VEHICLES = 'fleet/vehicles/SORT_VEHICLES';
export const SEARCH_VEHICLES = 'fleet/vehicles/SEARCH_VEHICLES';
export const FILTER_VEHICLES = 'fleet/vehicles/FILTER_VEHICLES';
const FAIL_LOAD = 'fleet/vehicles/FAIL_LOAD';
const START_DELETE = 'fleet/vehicles/START_DELETE';
export const COMPLETE_DELETE = 'fleet/vehicles/COMPLETE_DELETE';
const FAIL_DELETE = 'fleet/vehicles/FAIL_DELETE';
const RESET = 'fleet/vehicles/RESET';
const COMPLETE_LOAD_CAMERA_CONFIGURATIONS = 'fleet/vehicles/COMPLETE_LOAD_CAMERA_CONFIGURATIONS';

const START_LOAD_DEVICE_MANUFACTURER = 'fleet/vehicles/START_DEVICE_MANUFACTURER';
const COMPLETE_LOAD_DEVICE_MANUFACTURER = 'fleet/vehicles/COMPLETE_DEVICE_MANUFACTURER';
const FAIL_LOAD_DEVICE_MANUFACTURER = 'fleet/vehicles/FAIL_DEVICE_MANUFACTURER';

const START_LOAD_LIST = 'fleet/vehicles/START_LOAD_LIST';
const COMPLETE_LOAD_LIST = 'fleet/vehicles/COMPLETE_LOAD_LIST';
const FAIL_LOAD_LIST = 'fleet/vehicles/FAIL_LOAD_LIST';
const START_LOAD_SUMMARY = 'fleet/vehicles/START_LOAD_SUMMARY';
const COMPLETE_LOAD_SUMMARY = 'fleet/vehicles/COMPLETE_LOAD_SUMMARY';
const FAIL_LOAD_SUMMARY = 'fleet/vehicles/FAIL_LOAD_SUMMARY';
const SET_VENDOR_HAS_MILEAGE_INFO = 'fleet/vehicles/SET_VENDOR_HAS_MILEAGE_INFO';

const START_DELETE_VEHICLES = 'fleet/vehicles/START_DELETE_VEHICLES';
const COMPLETE_DELETE_VEHICLES = 'fleet/vehicles/COMPLETE_DELETE_VEHICLES';
const FAIL_DELETE_VEHICLES = 'fleet/vehicles/FAIL_DELETE_VEHICLES';
const START_ASSIGN_VEHICLES = 'fleet/vehicles/START_ASSIGN_VEHICLES';
const COMPLETE_ASSIGN_VEHICLES = 'fleet/vehicles/COMPLETE_ASSIGN_VEHICLES';
const FAIL_ASSIGN_VEHICLES = 'fleet/vehicles/FAIL_ASSIGN_VEHICLES';

// Initial state =
const initialState: VehiclesState = {
  vehicles: [],
  isLoading: false,
  isDeleting: false,
  vehicleCameraConfigurations: [],

  deviceManufacturerList: [],
  deviceManufacturerListLoading: false,

  vehicleList: [],
  vehicleListLoading: false,

  vehicleSummaryLoading: false,
  vendorHasMileageInformation: false,

  isAssigningVehicles: false,
  isDeletingVehicles: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicles: action.vehicles,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicles: [],
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const vehicleIndex = findIndex(state.vehicles, { id: action.vehicleId });
      const vehicleListIndex = findIndex(state.vehicleList, { id: action.vehicleId });

      return update(state, {
        vehicles: { $splice: [[vehicleIndex, 1]] },
        vehicleList: { $splice: [[vehicleListIndex, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case COMPLETE_LOAD_CAMERA_CONFIGURATIONS:
      return update(state, {
        $merge: {
          vehicleCameraConfigurations: action.vehicleCameraConfigurations,
        },
      });

    case START_LOAD_DEVICE_MANUFACTURER:
      return update(state, {
        $merge: {
          deviceManufacturerListLoading: true,
        },
      });

    case COMPLETE_LOAD_DEVICE_MANUFACTURER:
      return update(state, {
        $merge: {
          deviceManufacturerListLoading: false,
          deviceManufacturerList: action.deviceManufacturerList,
        },
      });

    case FAIL_LOAD_DEVICE_MANUFACTURER:
      return update(state, {
        $merge: {
          vehicleListLoading: false,
          deviceManufacturerList: [],
        },
      });

    case START_LOAD_LIST:
      return update(state, {
        $merge: {
          vehicleListLoading: true,
        },
      });

    case COMPLETE_LOAD_LIST:
      return update(state, {
        $merge: {
          vehicleListLoading: false,
          vehicleList: action.vehicleList,
        },
      });

    case FAIL_LOAD_LIST:
      return update(state, {
        $merge: {
          vehicleListLoading: false,
          vehicleList: [],
        },
      });

    case START_LOAD_SUMMARY:
      return update(state, {
        $merge: {
          vehicleSummaryLoading: true,
        },
      });

    case COMPLETE_LOAD_SUMMARY:
      return update(state, {
        $merge: {
          vehicleSummaryLoading: false,
          vehicleSummary: action.vehicleSummary,
        },
      });

    case FAIL_LOAD_SUMMARY:
      return update(state, {
        $merge: {
          vehicleSummaryLoading: false,
          vehicleSummary: undefined,
        },
      });

    case SET_VENDOR_HAS_MILEAGE_INFO:
      return update(state, {
        $merge: {
          vendorHasMileageInformation: action.vendorHasMileageInformation,
        },
      });

    case START_DELETE_VEHICLES:
      return update(state, {
        $merge: {
          isDeletingVehicles: true,
        },
      });

    case COMPLETE_DELETE_VEHICLES: {
      return update(state, {
        $merge: {
          isDeletingVehicles: false,
        },
      });
    }

    case FAIL_DELETE_VEHICLES:
      return update(state, {
        $merge: {
          isDeletingVehicles: false,
        },
      });

    case START_ASSIGN_VEHICLES:
      return update(state, {
        $merge: {
          isAssigningVehicles: true,
        },
      });

    case COMPLETE_ASSIGN_VEHICLES: {
      return update(state, {
        $merge: {
          isAssigningVehicles: false,
        },
      });
    }

    case FAIL_ASSIGN_VEHICLES:
      return update(state, {
        $merge: {
          isAssigningVehicles: false,
        },
      });

    case RESET:
      return { ...initialState };

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehicles: any) => ({
  type: COMPLETE_LOAD,
  vehicles,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadList = () => ({
  type: START_LOAD_LIST,
});

const completeLoadList = (vehicleList: Vehicle[]) => ({
  type: COMPLETE_LOAD_LIST,
  vehicleList,
});

const failLoadList = () => ({
  type: FAIL_LOAD_LIST,
});

const startLoadSummary = () => ({
  type: START_LOAD_SUMMARY,
});

const completeLoadSummary = (vehicleSummary: VehicleSummary) => ({
  type: COMPLETE_LOAD_SUMMARY,
  vehicleSummary,
});

const failLoadSummary = () => ({
  type: FAIL_LOAD_SUMMARY,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (vehicleId: number) => ({
  type: COMPLETE_DELETE,
  vehicleId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const completeLoadCameraConfigurations = (values: any) => ({
  type: COMPLETE_LOAD_CAMERA_CONFIGURATIONS,
  vehicleCameraConfigurations: values,
});

const startLoadDeviceManufacturer = () => ({
  type: START_LOAD_DEVICE_MANUFACTURER,
});

const completeLoadDeviceManufacturer = (deviceManufacturerList: TechnicalType[]) => ({
  type: COMPLETE_LOAD_DEVICE_MANUFACTURER,
  deviceManufacturerList,
});

const failLoadDeviceManufacturer = () => ({
  type: FAIL_LOAD_DEVICE_MANUFACTURER,
});

const startDeleteVehicles = () => ({
  type: START_DELETE_VEHICLES,
});

const completeDeleteVehicles = () => ({
  type: COMPLETE_DELETE_VEHICLES,
});

const failDeleteVehicles = () => ({
  type: FAIL_DELETE_VEHICLES,
});

const startAssignVehicles = () => ({
  type: START_ASSIGN_VEHICLES,
});

const completeAssignVehicles = () => ({
  type: COMPLETE_ASSIGN_VEHICLES,
});

const failAssignVehicles = () => ({
  type: FAIL_ASSIGN_VEHICLES,
});

export const loadVehicleCameraConfigurations = () => (dispatch: Dispatch) => {
  const promise = Services.loadVehicleCameraConfigurations();
  promise.then(values => dispatch(completeLoadCameraConfigurations(values)));
  return promise;
};

export const loadDeviceManufacturerTypeList = () => (dispatch: Dispatch) => {
  dispatch(startLoadDeviceManufacturer());
  const promise = Services.loadDeviceManufacturerTypeList();
  promise
    .then(values => dispatch(completeLoadDeviceManufacturer(values)))
    .catch(() => dispatch(failLoadDeviceManufacturer()));
  return promise;
};

export const deleteVehicle = (vehicleId: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteVehiclePromise = doDeleteVehicle(vehicleId);
  deleteVehiclePromise.then(() => dispatch(completeDelete(vehicleId))).catch(() => dispatch(failDelete()));
  return deleteVehiclePromise;
};

export const deleteVehicles = (vendorId: number, deletedVehiclesIds: number[]) => (dispatch: Dispatch) => {
  dispatch(startDeleteVehicles());
  const deleteVehiclesPromise = doDeleteVehicles(vendorId, deletedVehiclesIds);
  deleteVehiclesPromise.then(() => dispatch(completeDeleteVehicles())).catch(() => dispatch(failDeleteVehicles()));
  return deleteVehiclesPromise;
};

export const assignVehicles = (vendorId: number, vehicles: Vehicle[]) => (dispatch: Dispatch) => {
  dispatch(startAssignVehicles());
  const assignVehiclesPromise = doAssignVehicles(vendorId, vehicles);
  assignVehiclesPromise.then(() => dispatch(completeAssignVehicles())).catch(() => dispatch(failAssignVehicles()));
  return assignVehiclesPromise;
};

export const loadVehicles =
  (vendorId: number, showActiveTrucks?: boolean, vehicleTypeId?: number, date?: Date | string) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadVehiclesPromise = Services.loadVehicles(vendorId, showActiveTrucks, vehicleTypeId, date);
    loadVehiclesPromise.then(vehicles => dispatch(completeLoad(vehicles))).catch(() => dispatch(failLoad()));
    return loadVehiclesPromise;
  };

export const loadVehicleList =
  (vendorId: number, filters?: Services.LoadVehicleListFilters) => (dispatch: Dispatch) => {
    dispatch(startLoadList());

    const state = store.getState();
    const { vehicleTypes } = state.fleet.vehicleTypes;
    const promise = Services.loadVehicleList(vendorId, vehicleTypes, filters);

    promise
      .then(vehicleList => {
        dispatch(completeLoadList(vehicleList));
      })
      .catch(() => {
        dispatch(failLoadList());
      });

    return promise;
  };

export const loadVehicleSummary =
  (vendorId: number, filters?: Services.LoadVehicleListFilters) => (dispatch: Dispatch) => {
    dispatch(startLoadSummary());

    const promise = Services.loadVehicleSummary(vendorId, filters);

    promise
      .then(vehicleSummary => {
        dispatch(completeLoadSummary(vehicleSummary));
      })
      .catch(() => {
        dispatch(failLoadSummary());
      });

    return promise;
  };

const setVendorHasMileageInformation = (vendorHasMileageInformation: boolean) => ({
  type: SET_VENDOR_HAS_MILEAGE_INFO,
  vendorHasMileageInformation,
});

export const checkVendorHasMileageInformation =
  (vendorId: number, vehicleTypeIds?: number[]) => (dispatch: Dispatch) => {
    const promise = Services.checkVendorHasMileageInformation(vendorId, vehicleTypeIds);

    promise.then(vendorHasMileageInformation => {
      dispatch(setVendorHasMileageInformation(vendorHasMileageInformation));
    });

    return promise;
  };

export const resetVehicles = () => ({
  type: RESET,
});

export const sortVehicles = (sortedBy: string) => ({
  type: SORT_VEHICLES,
  sortedBy,
});

export const searchVehicles = () => ({
  type: SORT_VEHICLES,
});

export const filterVehicles = () => ({
  type: FILTER_VEHICLES,
});

// Selectors
const getVehiclesByVehicleTypeId = (vehiclesState: any, vehicleTypeId: number) =>
  vehicleTypeId
    ? filter(vehiclesState.vehicles, vehicle => vehicle.vehicleTypeId === vehicleTypeId)
    : vehiclesState.vehicles;
export const vehiclesByVehicleTypeIdSelector = createSelector(getVehiclesByVehicleTypeId, identity);

const getVehiclesWithoutVehicleTypeId = (vehiclesState: any, vehicleTypeId: number) =>
  vehicleTypeId
    ? filter(vehiclesState.vehicles, vehicle => vehicle.vehicleTypeId !== vehicleTypeId)
    : vehiclesState.vehicles;
export const vehiclesWithoutVehicleTypeIdSelector = createSelector(getVehiclesWithoutVehicleTypeId, identity);
