import styled, { css } from 'styled-components';
import { Icon } from '../../../core/components';
import { Grid, GridColumn } from '../../../core/components/styled';
import { sizeMapper } from '../../../utils/styles';
interface HaulerProfileProps {
  color?: string;
  category?: string;
  padding?: string;
}

export const ClassificationsContainer = styled.div<HaulerProfileProps>`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: ${props => sizeMapper(props.padding, 'no')};
  width: 100%;

  ${props =>
    props.category === 'compliance' &&
    css`
      width: 70%;
      padding: 20px;
      border-bottom: 1px solid ${props => props.theme.grayLight};
    `};
  ${props =>
    props.category === 'coiStatus' &&
    css`
      margin-left: 40px;
      padding: 10px 20px 5px;
      border: none;
    `};
`;

export const ClassificationsDetails = styled.div<HaulerProfileProps>`
  width: 60%;
`;

export const ActionButtonContainer = styled.div<HaulerProfileProps>`
  width: 10%;

  ${props =>
    props.category === 'compliance' &&
    css`
      width: 5%;
    `};
  ${props =>
    props.category === 'coiStatus' &&
    css`
      width: 3%;
    `};
`;

export const SignDocumentButtonContainer = styled.div<HaulerProfileProps>`
  width: 25%;
`;

export const DocumentIcon = styled.span`
  display: inline-block;
  width: 30px;
  height: 22px;
  margin: 3px 15px 0 0;
  background-size: 15px;
  background-repeat: no-repeat;
`;

export const DocumentInfo = styled.span`
  position: absolute;
  top: 14px;
  left: 14px;
  cursor: pointer;
`;

export const DocumentInfoIcon = styled(Icon).attrs({ icon: 'page-check' })`
  width: 20px;
  height: 20px;
  color: ${props => props.theme.grayDark};
  transition: color 0.3s ease-out;

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }
`;

export const ComplianceListContainer = styled.div<HaulerProfileProps>`
  display: flex;
  padding: 0 15px;
  margin-bottom: 8px;
`;

export const DocumentName = styled.span`
  flex: 1;
  text-transform: capitalize;
  line-height: 22px;
  font-size: 13px;
`;

export const DocumentValue = styled.span`
  text-align: right;
  line-height: 22px;
  font-size: 13px;
`;

export const OverviewGrid = styled(Grid)`
  padding: 20px;
`;

export const OverviewGridColumn = styled(GridColumn)`
  &:not(:first-child) {
    border-left: 1px solid ${p => p.theme.brandSecondary};
  }
`;
