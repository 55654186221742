import { map, filter } from 'lodash-es';

import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const transformLoadTriggers = (triggers: any) =>
  map(filter(triggers, 'hasTriggers'), trigger => ({
    ...trigger,
    triggers: map(trigger.triggers, ({ name, count }) => ({
      count,
      name: translate(createTranslationKey(name, 'routes.triggerTypes')),
    })),
  }));

export default transformLoadTriggers;
