import { http } from '../../core/services/http';
import { RouteDetails, RouteDetailsDataToSubmit } from '../interfaces/RouteDetails';

export const loadRouteDetails = (vendorId: number, routeId: number) =>
  http.get<RouteDetails>(`${vendorId}/routes/${routeId}/routeDetail/tripDetails`).then(response => response.data);

export const loadSnowSweeperRouteDetails = (
  vendorId: number,
  routeId: number,
  startDate?: Date | string,
  endDate?: Date | string,
) =>
  http
    .get<RouteDetails>(`${vendorId}/routes/${routeId}/routeDetail/alternativeFleet/tripDetails`, {
      params: { startDate, endDate },
    })
    .then(response => response.data);

export const updateRouteDetails = (vendorId: number, routeId: number, routeDetails: RouteDetailsDataToSubmit) =>
  http.put(`${vendorId}/routes/${routeId}/routeDetail/details`, routeDetails);
