import React, { PureComponent } from 'react';

import { Marker, InfoWindow } from '@react-google-maps/api';

import {
  MapInfoWindow,
  MapInfoWindowDetails,
  MapInfoWindowDetail,
  MapInfoWindowDetailLabel,
} from '../../core/components/styled';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import { GOOGLE as google } from '../../common/constants';
import mapPin from '../../common/assets/img/common/mapPin.svg';

interface Props {
  clusterer: Clusterer;
  index: number;
  isInfoWindowOpen?: boolean;
  toggleInfoWindow: (index?: string) => void;
  trigger: any;
}

interface State {
  popupMarkerPositions: any;
}

class TriggerMarker extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { popupMarkerPositions: undefined };
  }

  toggleInfoWindow = (popupMarkerPositions?: any) => {
    const { index, toggleInfoWindow } = this.props;

    this.setState({ popupMarkerPositions });
    toggleInfoWindow(`triggers.${index}`);
  };

  render() {
    const { index, trigger, isInfoWindowOpen, clusterer } = this.props;
    const { popupMarkerPositions } = this.state;

    const markerPosition = {
      lat: parseFloat(trigger.latitude),
      lng: parseFloat(trigger.longitude),
    };
    const pixelOffset = new google.maps.Size(0, -5);

    return (
      <Marker
        key={index}
        icon={mapPin}
        position={markerPosition}
        onClick={() => this.toggleInfoWindow(markerPosition)}
        clusterer={clusterer}
      >
        {isInfoWindowOpen && popupMarkerPositions && (
          <InfoWindow onCloseClick={this.toggleInfoWindow} position={popupMarkerPositions} options={{ pixelOffset }}>
            <MapInfoWindow>
              <MapInfoWindowDetails>
                {trigger.triggers.map(({ name, count }: any) => (
                  <MapInfoWindowDetail key={name}>
                    <MapInfoWindowDetailLabel>{name}:</MapInfoWindowDetailLabel>
                    {count}
                  </MapInfoWindowDetail>
                ))}
              </MapInfoWindowDetails>
            </MapInfoWindow>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

export default TriggerMarker;
