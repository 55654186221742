import { orderBy } from 'lodash-es';

const transformAiModelConfigurations = (values: any) => ({
  ...values,
  modelConfigurations: values.modelConfigurations.map((modelConfiguration: any) => ({
    ...modelConfiguration,
    modelFeatures: orderBy(modelConfiguration.modelFeatures, ['modelFeature.name'], ['asc']),
  })),
});

export default transformAiModelConfigurations;
