import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import moment from 'moment';

import { AppState } from 'src/store';
import {
  Button,
  ButtonSet,
  Container,
  ModalTitle,
  Panel,
  PanelSection,
  TableCell,
  TableRow,
  Text,
} from '../../../core/components/styled';
import { DISPATCH_SERVICE_CONFIRMATION } from '../../../account/constants';
import { Dropdown, TextArea } from '../../../core/components';
import { isRequired } from '../../../utils/services/validator';
import { NoteItem } from '../../interfaces/RubiconDispatchesNotes';
import { Option } from 'src/common/interfaces/Option';
import { PermissionGuard } from '../../../account/components';
import translate from '../../../core/services/translate';

type PropsWithoutReduxForm = {
  isLoading: boolean;
  notes: NoteItem[];
  onCancel: (pristine?: boolean) => void;
  reasonCodes: Option[];
};

type Props = PropsWithoutReduxForm & InjectedFormProps<{}, PropsWithoutReduxForm>;

class NotesForm extends PureComponent<Props> {
  render() {
    const { onCancel, pristine, reasonCodes, notes, handleSubmit, isLoading } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <Panel>
          <PanelSection vertical centered padding="small" isLoading={isLoading}>
            <Container>
              <PanelSection vertical withBorder>
                {notes &&
                  notes.map((note, index) => (
                    <TableRow key={index} borderBottom="0px">
                      <TableCell vertical padding="no no no xSmall">
                        {note.noteDate && (
                          <Fragment key={note.noteDate}>
                            <Text block margin="small no no" weight="light" size="small" singleLine color="grayDark">
                              {translate('common.date')}{' '}
                              {note.createdByUserID && `/ ${translate('autoDispatch.createdBy')}`}:{' '}
                            </Text>
                            <Text margin="no no no" weight="medium" size="small">
                              {moment(note.noteDate).format('YYYY-MM-DD HH:mm:ss')}{' '}
                              {note.createdByUserID &&
                                `/ ${
                                  note.createdByUserID.toLowerCase() === 'vendorportal'
                                    ? translate('common.vendor')
                                    : translate('autoDispatch.rubicon')
                                }`}
                            </Text>
                          </Fragment>
                        )}
                        {note.reasonCode && (
                          <Fragment key={`${index} ${note.noteDate}`}>
                            <Text block margin="xSmall no no" weight="light" size="small" singleLine color="grayDark">
                              {translate('autoDispatch.reasonCode')}:{' '}
                            </Text>
                            <Text margin="no no no" weight="normal" size="small" singleLine>
                              {note.reasonCode}
                            </Text>{' '}
                          </Fragment>
                        )}
                        {note.userNote && (
                          <Fragment key={index}>
                            <Text block margin="xSmall no no" weight="light" size="small" singleLine color="grayDark">
                              {translate('common.note')}:{' '}
                            </Text>
                            <Text margin="no no no" weight="normal" size="small">
                              {note.userNote}
                            </Text>
                          </Fragment>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </PanelSection>
            </Container>
            <Container margin="small small small">
              <ModalTitle>{translate('routes.addRouteNote')}</ModalTitle>
              <Container>
                <Field
                  name={'reasonCode'}
                  component={Dropdown}
                  options={reasonCodes}
                  label={translate('autoDispatch.reasonCode')}
                  validate={[isRequired]}
                  margin="small small small"
                />
              </Container>
              <Container>
                <Field
                  name={'note'}
                  component={TextArea}
                  rows={2}
                  label={translate('routes.addRouteNote')}
                  size="small"
                  validate={[isRequired]}
                  margin="small small large"
                />
              </Container>
              <ButtonSet margin="large small large">
                <PermissionGuard permission={DISPATCH_SERVICE_CONFIRMATION}>
                  <Button type="submit" color="primary">
                    {translate('opportunity.submit')}
                  </Button>
                </PermissionGuard>
                <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
                  {translate('autoDispatch.close')}
                </Button>
              </ButtonSet>
            </Container>
          </PanelSection>
        </Panel>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  notes: state.fleet.notes.notes,
  reasonCodes: state.fleet.notes.reasonCodes,
  isLoading: state.fleet.notes.isLoading,
});

export default connect(mapStateToProps)(
  reduxForm<{}, PropsWithoutReduxForm>({
    form: 'notesForm',
    enableReinitialize: true,
  })(NotesForm),
);
