import { http } from 'src/core/services/http';
import { IBillingModule } from '../interfaces/BillingModule';
import { transformBillingModule } from './transformBillingModule';

export const loadBillingModule = (vendorId: number) =>
  http
    .get<IBillingModule>(`/vendors/${vendorId}/billingConfig`)
    .then(response => transformBillingModule(response.data));

export const saveBillingModule = (vendorId: number, formData: IBillingModule) =>
  http.post(`/vendors/${vendorId}/billingConfig`, formData).then(response => response.data);
