import styled, { css } from 'styled-components';

import { DispatchBoardJobContainer as DispatchBoardJobContainerUntyped } from '../../styled';
import { Button } from '../../../../core/components/styled';

export const AssignedToContainer = styled.div`
  display: flex;
`;

export const AssignedToTitleContainer = styled.div`
  margin-bottom: 10px;
`;

export const AssignedTo = styled.div`
  height: 100%;
  color: ${p => p.theme.brandPrimary};
`;

export const StopInformation = styled.div``;

export const ExceptionDispatcherButton = styled(Button)<{ isViewOnly?: boolean }>`
  margin-right: 15px;

  ${p =>
    p.isViewOnly &&
    css`
      margin-right: 0;
    `}
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  label {
    margin: 0;
  }
`;

export const JobWithExceptionContainer: any = styled(DispatchBoardJobContainerUntyped)`
  cursor: initial;
  padding: 10px 30px;
  overflow: initial;
  height: 110px;
`;

export const ModalContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  padding: 0 30px;
  flex-grow: 0;
`;

export const ContainerDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const NumberOfAssignedItems = styled.div`
  cursor: context-menu;
  position: absolute;
  right: 30px;
  color: ${p => p.theme.brandSuccess};
`;

export const Overlay = styled(Wrapper)<{ center?: boolean }>`
  position: relative;
  background-color: ${p => p.theme.bodyBackgroundColor};
  min-height: 80px;
  display: flex;
  align-items: center;

  ${p =>
    p.center &&
    css`
      justify-content: center;
    `}

  & ${Button} {
    align-self: center;
  }

  & form {
    flex: 1;
  }
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ContentHeading = styled.div<{ isViewOnly?: boolean }>`
  padding: 15px 30px 15px 45px;
  border-bottom: 1px solid ${props => props.theme.grayLight};

  ${p =>
    p.isViewOnly &&
    css`
      padding-left: 30px;
    `}
`;

export const Centered = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const JobInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoContainer = styled.div`
  flex: 1;
`;

export const IconsContainer = styled.div`
  margin: 10px 0 0 0;
`;

export const SearchTermContainer = styled.div`
  padding: 0 30px 20px;
`;
