import { http } from '../../core/services/http';
import transformLoadCityInsightTypes from './transformLoadCityInsightTypes';
import { AxiosResponse } from 'axios';
import { CityInsightTypesResponse } from '../interfaces/cityInsightTypes';

const loadCityInsightTypes = (vendorId: number) =>
  http
    .get(`/vendors/${vendorId}/locationFlagTypes`)
    .then((response: AxiosResponse<CityInsightTypesResponse>) => transformLoadCityInsightTypes(response.data));

export default loadCityInsightTypes;
