import React, { PureComponent, Fragment } from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import Input from './Input';
import TextDisplay from './TextDisplay';

interface Props extends WrappedFieldProps {
  isInEditMode: boolean;
  displayComponent?: any;
  displayProps?: any;
  inputComponent?: any;
  inputProps?: any;
}

class InlineEditor extends PureComponent<Props> {
  onChange = (event: any, value: any) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      isInEditMode,
      input,
      displayComponent: DisplayComponent = TextDisplay,
      displayProps,
      inputComponent: InputComponent = Input,
      inputProps,
    } = this.props;

    return (
      <Fragment>
        {!isInEditMode && <DisplayComponent input={input} {...displayProps} />}
        {isInEditMode && <Field name={input.name} component={InputComponent} {...inputProps} />}
      </Fragment>
    );
  }
}

export default InlineEditor;
