import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const FILTER_SERVICE_ZONE_ID = 1;
export const FILTER_SERVICE_TYPE_ID = 2;
export const FILTER_VEHICLE_TYPE_ID = 3;
export const FILTER_SUPERVISOR_ID = 4;

export const FILTER_TYPES = mapKeys(
  [
    { id: FILTER_SERVICE_ZONE_ID, technicalName: 'ServiceZone', name: translate('routes.serviceZones') },
    { id: FILTER_SERVICE_TYPE_ID, technicalName: 'ServiceType', name: translate('common.serviceTypesTitle') },
    { id: FILTER_VEHICLE_TYPE_ID, technicalName: 'VehicleType', name: translate('common.vehicleTypes') },
    { id: FILTER_SUPERVISOR_ID, technicalName: 'Supervisor', name: translate('common.supervisors') },
  ],
  'id',
);
