import React, { PureComponent } from 'react';
import { map } from 'lodash-es';
import { Field } from 'redux-form';
import { ACCOUNT_STATUSES } from '../constants';
import { Dropdown } from '../../core/components';
import translate from '../../core/services/translate';
import { DropDownFieldProps } from './DropDownFieldProps';

class AccountStatusDropdown extends PureComponent<DropDownFieldProps> {
  onChange = (event: any, value: string) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
    } = this.props;
    const accountStatusOptions = map(ACCOUNT_STATUSES, accountStatus => ({
      label: accountStatus.name,
      value: accountStatus.id,
    }));

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('common.accountStatus') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('common.accountStatus') : undefined)}
        component={Dropdown as any}
        options={accountStatusOptions}
        onChange={this.onChange}
        {...dropdownProps}
      />
    );
  }
}

export default AccountStatusDropdown;
