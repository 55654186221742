import { groupBy, keys, map } from 'lodash-es';
import { useSelector } from 'react-redux';
import { Modal } from 'src/core/components';
import { ModalTitle } from 'src/core/components/styled';
import translate from 'src/core/services/translate';

import { AppState } from 'src/store';
import FacilityTypeForm from '../forms/FacilityTypeForm';

interface Props {
  title: string;
  handleContainerTransfer(values: any): void;
  closeModal: () => void;
}

const TransferContainerModal: React.FC<Props> = ({ closeModal, handleContainerTransfer, title }) => {
  const facilitiesGroupBySubType = groupBy(
    useSelector((state: AppState) => state.fleet.containerFacilities.containerFacilities),
    'facilitySubType.name',
  );

  const facilityTypeOptions = map(keys(facilitiesGroupBySubType), facilityKey => {
    return {
      label: facilitiesGroupBySubType[facilityKey][0].facilitySubType.name,
      options: map(facilitiesGroupBySubType[facilityKey], ({ locationId, name }) => ({
        value: locationId,
        label: name,
      })),
    };
  });

  facilityTypeOptions.unshift({ options: [{ value: -1, label: translate('common.removeFromInventory') }], label: '' });

  return (
    <Modal padding="large" onClose={closeModal}>
      <ModalTitle>{title}</ModalTitle>
      <FacilityTypeForm
        closeModal={closeModal}
        facilityTypes={facilityTypeOptions}
        onSubmit={handleContainerTransfer}
      />
    </Modal>
  );
};

export default TransferContainerModal;
