import { memo } from 'react';
import { Layer } from 'react-map-gl';
import { MAP_CITY_ZOOM_IN, MAP_CITY_ZOOM_SMALL } from 'src/core/constants/mapOptions';

import { DASHBOARD_CLUSTERS_SOURCE, DASHBOARD_ROUTE_STOPS_LAYER } from 'src/dashboard/constants/dashboardMapGL';

interface Props {
  showOrderNumbers: boolean;
  isDeliveryUtilityVehicle?: boolean;
}

export default memo(function DashboardRouteStopsGLLayer({ showOrderNumbers, isDeliveryUtilityVehicle }: Props) {
  return (
    <>
      <Layer
        key="stopsLayer"
        id="stopsLayer"
        type="symbol"
        source={DASHBOARD_CLUSTERS_SOURCE}
        filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], DASHBOARD_ROUTE_STOPS_LAYER]]}
        paint={{}}
        layout={{
          'icon-image': ['get', 'icon'],
          'icon-size': 1,
          'icon-allow-overlap': true,
          'symbol-z-order': 'source',
          'symbol-sort-key': 1,
        }}
      />
      <Layer
        key="stopsLayerOrderNumber"
        id="stopsLayerOrderNumber"
        type="symbol"
        minzoom={isDeliveryUtilityVehicle ? MAP_CITY_ZOOM_SMALL : MAP_CITY_ZOOM_IN}
        source={DASHBOARD_CLUSTERS_SOURCE}
        filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], DASHBOARD_ROUTE_STOPS_LAYER]]}
        paint={{ 'text-halo-color': '#fff', 'text-halo-width': 10 }}
        layout={{
          'symbol-z-order': 'source',
          'text-field': showOrderNumbers ? ['get', 'orderNo'] : '',
          'text-offset': [0, 1.25],
          'text-size': 15,
        }}
      />
    </>
  );
});
