import { DragEvent, PureComponent } from 'react';

import { connect } from 'react-redux';

import {
  ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS,
  ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS,
} from 'src/account/constants';
import { hasPermissionSelector } from 'src/account/ducks';
import { UnconnectedCheckbox } from 'src/core/components';
import { Label, Text } from 'src/core/components/styled';
import { createErrorNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import {
  DispatchBoardRouteContainer,
  DispatchBoardRouteDate,
  DispatchBoardRouteName,
  DispatchBoardRouteNumberOfJobs,
  DispatchBoardRouteNumberOfOverdueJobs,
  DispatchBoardRoutePrimaryDetails,
  DispatchBoardRouteSecondaryDetails,
} from 'src/routes/components/styled';
import { JobTypes, PICKUP_STATUS_LABEL_COLORS, ROUTE_STATUSES_BY_ID } from 'src/routes/constants';
import { ON_HOLD_ROUTE_ID, UNASSIGNED_ROUTE_ID } from 'src/routes/constants/dispatchBoard';
import routeJobCountFormatter from 'src/routes/services/routeJobCountFormatter';
import { AppState } from 'src/store';
import { date } from 'src/utils/services/formatter';

interface Props {
  hasPermissionToManageScheduledJobs?: boolean;
  hasPermissionToManageUnscheduledJobs?: boolean;
  isSelected?: boolean;
  onJobDrop: (
    sourceRouteId: number,
    targetRouteId: number,
    jobsSelected: any[],
    position?: number,
    type?: string,
  ) => void;
  onRouteClick: (route: any) => void;
  onRouteToggle: any;
  route?: any;
  type: string;
  unassignedJobsCount: any;
  onOnHoldClick?: () => void;
  onHoldJobsCount: number;
}

interface State {
  isReadyToDrop: boolean;
}

class DispatchBoardRoute extends PureComponent<Props, State> {
  readonly state: State = {
    isReadyToDrop: false,
  };

  static defaultProps = {
    unassignedJobsCount: 0,
  };

  onRouteClick = () => {
    const { onRouteClick, onOnHoldClick, route, type } = this.props;

    if (type === JobTypes.onHold && onOnHoldClick) {
      onOnHoldClick();
    } else {
      onRouteClick(route);
    }
  };

  onRouteToggle = () => {
    const { onRouteToggle, route, type, unassignedJobsCount, onHoldJobsCount } = this.props;

    if (type === JobTypes.unassigned) {
      onRouteToggle({
        id: UNASSIGNED_ROUTE_ID,
        name: translate('routes.unassignedJobs'),
        totalNumberOfJobs: unassignedJobsCount.totalCount,
      });
    } else if (type === JobTypes.onHold) {
      onRouteToggle({
        id: ON_HOLD_ROUTE_ID,
        name: translate('routes.onHoldJobs'),
        totalNumberOfJobs: onHoldJobsCount,
      });
    } else {
      onRouteToggle(route);
    }
  };

  onDragOver = (event: DragEvent) => {
    event.preventDefault();
  };

  onDragEnter = (event: DragEvent) => {
    event.preventDefault();
    this.setState({ isReadyToDrop: true });
  };

  onDragLeave = () => {
    this.setState({ isReadyToDrop: false });
  };

  onDrop = (event: DragEvent) => {
    event.preventDefault();

    const { route, onJobDrop, hasPermissionToManageScheduledJobs, hasPermissionToManageUnscheduledJobs, type } =
      this.props;

    if (type === JobTypes.onHold) {
      createErrorNotification(translate('dispatchBoard.cannotTransferToOnHoldRoute'));
    } else {
      this.setState({ isReadyToDrop: false });

      const { dataTransfer } = event.nativeEvent;
      const sourceRouteId = dataTransfer?.getData('routeId')
        ? Number(dataTransfer?.getData('routeId'))
        : UNASSIGNED_ROUTE_ID;
      const jobsSelected =
        dataTransfer && dataTransfer.getData('jobsSelected')
          ? JSON.parse(dataTransfer.getData('jobsSelected'))
          : undefined;
      const routeId = route ? route.id : UNASSIGNED_ROUTE_ID;
      if (!jobsSelected || !jobsSelected.length) return;

      if (!hasPermissionToManageScheduledJobs && hasPermissionToManageUnscheduledJobs) {
        createErrorNotification(translate('dispatchBoard.cannotModifyRoute'));
        return;
      }

      if (sourceRouteId === routeId) {
        createErrorNotification(translate('dispatchBoard.cannotTransferWithinSameRoute'));
        return;
      }
      onJobDrop(sourceRouteId, routeId, jobsSelected, undefined, type);
    }
  };

  render() {
    const { isSelected, route, type, unassignedJobsCount } = this.props;
    const { isReadyToDrop } = this.state;

    const numberOfJobsText = unassignedJobsCount
      ? `${unassignedJobsCount.totalCount} ${
          unassignedJobsCount.totalCount === 1 ? translate('routes.job') : translate('routes.jobs')
        }`
      : '';

    const isRegularRoute = type === JobTypes.source || type === JobTypes.target;
    const isUnassignedRoute = type === JobTypes.unassigned;
    const isOnHoldRoute = type === JobTypes.onHold;

    const completionProgress = !!route?.numberOfCompletedJobs
      ? Math.round((route.numberOfCompletedJobs / route.totalNumberOfJobs) * 100)
      : 0;

    const routeName = isRegularRoute
      ? route.name
      : isUnassignedRoute
      ? translate('routes.unassignedJobs')
      : translate('routes.onHoldJobs');

    return (
      <DispatchBoardRouteContainer
        dropZone={isOnHoldRoute ? false : isReadyToDrop}
        isReadyToDrop={isOnHoldRoute ? false : isReadyToDrop}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
        onClick={this.onRouteClick}
        id={`route-${type}-${
          route && route.name
            ? route.name.replace(/\s/g, '')
            : isUnassignedRoute
            ? JobTypes.unassigned
            : JobTypes.onHold
        }`}
      >
        {type !== JobTypes.target && (
          <UnconnectedCheckbox
            block
            size="medium"
            margin="no small no no"
            name={routeName}
            checked={isSelected}
            onChange={this.onRouteToggle}
            onClick={event => event.stopPropagation()}
          />
        )}

        <DispatchBoardRoutePrimaryDetails>
          <DispatchBoardRouteName>{routeName}</DispatchBoardRouteName>
          <DispatchBoardRouteNumberOfJobs>
            {isRegularRoute ? routeJobCountFormatter(route) : numberOfJobsText}
            {isUnassignedRoute && unassignedJobsCount.overdueCount > 0 && (
              <DispatchBoardRouteNumberOfOverdueJobs>
                {` (${unassignedJobsCount.overdueCount} ${translate('routes.jobOverdue')})`}
              </DispatchBoardRouteNumberOfOverdueJobs>
            )}
          </DispatchBoardRouteNumberOfJobs>
        </DispatchBoardRoutePrimaryDetails>

        {isRegularRoute && (
          <DispatchBoardRouteSecondaryDetails>
            <DispatchBoardRouteDate>{date(route.date)}</DispatchBoardRouteDate>

            <Label color={PICKUP_STATUS_LABEL_COLORS[route.statusId.toString()]}>
              {ROUTE_STATUSES_BY_ID[route.statusId].technicalName} - {completionProgress}%
            </Label>

            <Text singleLine block size="small" margin="xxSmall no no">
              {route.driverName}
            </Text>
          </DispatchBoardRouteSecondaryDetails>
        )}
      </DispatchBoardRouteContainer>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  hasPermissionToManageScheduledJobs: hasPermissionSelector(
    state.account.permissions,
    ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS,
  ),
  hasPermissionToManageUnscheduledJobs: hasPermissionSelector(
    state.account.permissions,
    ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS,
  ),
  onHoldJobsCount: state.routes.dispatchBoard.onHoldJobs.onHoldJobsCount,
});

export default connect(mapStateToProps)(DispatchBoardRoute);
