import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { filter, findIndex, size } from 'lodash-es';

import { AccessContainer, DistrictItem } from './../styled/DivisionAccess';
import { AppState } from '../../../store';
import { Button, ButtonSet, Grid, GridColumn, Text } from '../../../core/components/styled';
import { DuckAction } from '../../../contracts/ducks';
import { Modal } from '../../../core/components';
import { saveAssignedVendors } from '../../ducks';
import { UserVendors } from '../../interfaces/UserEditor';
import translate from '../../../core/services/translate';

type Vendor = {
  id: number;
  name: string;
  isActive: boolean;
}[];

interface Props extends RouteComponentProps {
  assignedVendors: UserVendors[];
  availableVendors: UserVendors[];
  closeModal: () => void;
  defaultVendor: any;
  saveAssignedVendors: DuckAction<typeof saveAssignedVendors>;
  userId: string;
}
interface State {
  assignedVendorList: any[];
  availableVendorList: any[];
  isRemoveAllAssignedVendors: boolean;
  selectedItem: number;
}

class VendorAccessModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      assignedVendorList: [],
      availableVendorList: [],
      isRemoveAllAssignedVendors: false,
      selectedItem: 0,
    };
  }

  componentDidMount() {
    const { assignedVendors, defaultVendor, availableVendors, userId } = this.props;

    if (!userId && !size(assignedVendors)) {
      let defaultVendorList: Vendor = [];
      defaultVendorList.push({ id: defaultVendor.id, name: defaultVendor.name, isActive: defaultVendor.isActive });
      const availableVendorsList = filter(availableVendors, vendor => vendor.id !== defaultVendor.id);
      this.setState({ assignedVendorList: defaultVendorList, availableVendorList: availableVendorsList });
    } else {
      this.setState({ assignedVendorList: assignedVendors, availableVendorList: availableVendors });
    }
  }

  handleSelectedItem = (id: number) => () => {
    this.setState({ selectedItem: id });
  };

  handleMoveAll = (value: boolean) => () => {
    const { assignedVendorList, availableVendorList } = this.state;
    if (value) {
      this.setState({ assignedVendorList: [...availableVendorList, ...assignedVendorList], availableVendorList: [] });
    } else {
      this.setState({
        availableVendorList: [...assignedVendorList, ...availableVendorList],
        assignedVendorList: [],
        isRemoveAllAssignedVendors: true,
      });
    }
  };

  handleSingleMove = (value: boolean) => () => {
    const { assignedVendorList, availableVendorList, selectedItem } = this.state;

    if (selectedItem === 0) return;
    const selectedVendor = value
      ? filter(availableVendorList, vendor => vendor.id === selectedItem)
      : filter(assignedVendorList, vendor => vendor.id === selectedItem);
    if (value && size(selectedVendor)) {
      const index = findIndex(availableVendorList, ['id', selectedItem]);
      this.setState(prevState => ({
        availableVendorList: [
          ...prevState.availableVendorList.slice(0, index),
          ...prevState.availableVendorList.slice(index + 1),
        ],
        assignedVendorList: [...selectedVendor, ...prevState.assignedVendorList],
      }));
    } else if (!value && size(selectedVendor)) {
      const index = findIndex(assignedVendorList, ['id', selectedItem]);
      this.setState(prevState => ({
        assignedVendorList: [
          ...prevState.assignedVendorList.slice(0, index),
          ...prevState.assignedVendorList.slice(index + 1),
        ],
        availableVendorList: [...selectedVendor, ...prevState.availableVendorList],
      }));
    }
  };

  onClose = () => {
    const { closeModal, defaultVendor, saveAssignedVendors } = this.props;
    const { assignedVendorList, availableVendorList, isRemoveAllAssignedVendors } = this.state;

    let defaultVendorList: Vendor = [];
    if (!size(assignedVendorList)) {
      defaultVendorList.push({ id: defaultVendor.id, name: defaultVendor.name, isActive: defaultVendor.isActive });
    }
    const userAssignedVendorList = !size(assignedVendorList) ? defaultVendorList : assignedVendorList;
    saveAssignedVendors(userAssignedVendorList, availableVendorList, isRemoveAllAssignedVendors);
    closeModal();
  };

  render() {
    const { selectedItem, assignedVendorList, availableVendorList } = this.state;
    return (
      <Modal title={translate('account.vendorAccess')} onClose={this.onClose} padding="medium" size="smallMedium">
        <Grid centered multiLine>
          <GridColumn size="5/12">
            <GridColumn shift="5/12">
              <Text size="large">{translate('common.available')}</Text>
            </GridColumn>
            <AccessContainer right>
              {availableVendorList.map(item => (
                <DistrictItem
                  selectedItem={selectedItem}
                  id={item.id}
                  key={item.id}
                  onClick={this.handleSelectedItem(item.id)}
                >
                  {item.name}
                </DistrictItem>
              ))}
            </AccessContainer>
          </GridColumn>
          <GridColumn size="2/12">
            <ButtonSet vertical style={{ height: '200px' }}>
              <Button
                color="primary"
                onClick={this.handleMoveAll(true)}
                disabled={size(availableVendorList) ? false : true}
              >
                &gt;&gt;
              </Button>
              <Button color="primary" onClick={this.handleSingleMove(true)}>
                &gt;
              </Button>
              <Button color="primary" onClick={this.handleSingleMove(false)}>
                &lt;
              </Button>
              <Button
                color="primary"
                onClick={this.handleMoveAll(false)}
                disabled={size(assignedVendorList) ? false : true}
              >
                &lt;&lt;
              </Button>
            </ButtonSet>
          </GridColumn>
          <GridColumn size="5/12">
            <GridColumn shift="2/12">
              <Text size="large">{translate('common.assigned')}</Text>
            </GridColumn>
            <AccessContainer>
              {!!size(assignedVendorList) &&
                assignedVendorList.map(item => (
                  <DistrictItem
                    selectedItem={selectedItem}
                    id={item.id}
                    key={item.id}
                    onClick={this.handleSelectedItem(item.id)}
                  >
                    {item.name}
                  </DistrictItem>
                ))}
            </AccessContainer>
          </GridColumn>
          <GridColumn size="12/12" padding="sMedium no">
            <ButtonSet>
              <Button type="button" color="primary" onClick={this.onClose}>
                {translate('common.close')}
              </Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  defaultVendor: state.vendors.defaultVendor.defaultVendor,
});

const mapDispatchToProps = { saveAssignedVendors };

export default connect(mapStateToProps, mapDispatchToProps)(VendorAccessModal);
