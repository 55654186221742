import { flattenDepth, map, reduce } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { getMapBounds } from 'src/common/components/map/util';
import { MapGL } from 'src/common/components/map/MapGL';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import { MapGLWrapper } from 'src/customers/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import { Vendor } from 'src/vendors/interfaces/Vendors';
import ServiceAreasGL from './ServiceAreasGL';

interface Props {
  equipmentSizes?: string[];
  equipmentTypes: string;
  filteredFeatures: any;
  isFiltersApplied?: boolean;
  isServiceAreaEditorModalOpen?: boolean;
  isSourceServiceAreasPage?: boolean;
  isZipCodeChanged?: boolean;
  setIsFiltersApplied?: (isApplied: boolean) => void;
  setIsZipCodeChanged?: (isZipCodeChanged: boolean) => void;
}

const ServiceAreasMapGL = ({
  equipmentSizes,
  equipmentTypes,
  filteredFeatures,
  isFiltersApplied,
  isServiceAreaEditorModalOpen,
  isSourceServiceAreasPage,
  isZipCodeChanged,
  setIsFiltersApplied,
  setIsZipCodeChanged,
}: Props) => {
  const vendor = useSelector(state => state.vendors.vendor.vendor) as any as Vendor;

  const [shouldCenterByVendor, setShouldCenterByVendor] = useState<boolean>(!filteredFeatures?.length);
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map>();
  const [viewport, setViewport] = useState<MapGLViewport>({});
  const [mapViewStyle, setMapViewStyle] = useState<any>({
    isSatelliteEnabled: false,
    mapCenter: null,
    mapZoom: null,
  });
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  const mapCenterByVendor = useMemo(() => {
    if (vendor?.id) {
      return getMapBounds([{ latitude: vendor.homeAddress.latitude, longitude: vendor.homeAddress.longitude }], {
        capZoom: 16,
      });
    }
  }, [vendor.id, vendor.homeAddress]);

  const allServiceAreasGeoJSON = useMemo(() => {
    const geoJson = map(filteredFeatures, serviceArea => {
      const hasOnlyOnePolygon = serviceArea.geometry.type === 'Polygon';

      return {
        ...serviceArea,
        geometry: {
          type: 'MultiPolygon',
          coordinates: hasOnlyOnePolygon ? [serviceArea.geometry.coordinates] : serviceArea.geometry.coordinates,
        },
      };
    });

    return (geoJson as GeoJSON.Feature<GeoJSON.MultiPolygon, GeoJSON.GeoJsonProperties>[]) || [];
  }, [filteredFeatures]);

  const allCoords = useMemo(
    () =>
      reduce(
        allServiceAreasGeoJSON,
        (result, serviceArea) => {
          const coordinates = serviceArea.geometry.coordinates as any[];
          const flattenCoordinates = flattenDepth(coordinates, 2);
          const points = map(flattenCoordinates, coordinate => {
            return {
              latitude: coordinate[1] as unknown as number,
              longitude: coordinate[0] as unknown as number,
            };
          });
          return [...result, ...points] as { latitude: number; longitude: number }[];
        },
        [] as { latitude: number; longitude: number }[],
      ),
    [allServiceAreasGeoJSON],
  );

  useEffect(() => {
    if (!!allCoords.length && (isFirstRender || isFiltersApplied || isZipCodeChanged)) {
      const bounds = getMapBounds(allCoords, {
        capZoom: 16,
      });
      setViewport(bounds);
      setIsFirstRender(false);
      setIsFiltersApplied && setIsFiltersApplied(false);
      setIsZipCodeChanged && setIsZipCodeChanged(false);
    }
  }, [allCoords, isFiltersApplied, isFirstRender, isZipCodeChanged, setIsFiltersApplied, setIsZipCodeChanged]);

  useEffect(() => {
    if (shouldCenterByVendor) {
      mapCenterByVendor && !mapViewStyle.mapCenter && setViewport(mapCenterByVendor);
      setShouldCenterByVendor(false);
    } else if (mapViewStyle.mapCenter && mapViewStyle.mapZoom) {
      const bounds = getMapBounds([{ latitude: mapViewStyle.mapCenter.lat, longitude: mapViewStyle.mapCenter.lng }], {
        capZoom: mapViewStyle.mapZoom,
      });
      setViewport(bounds);
    }
  }, [mapCenterByVendor, mapViewStyle.mapCenter, mapViewStyle.mapZoom, shouldCenterByVendor]);

  const handleMapViewStyleChange = (enabled: boolean) => {
    setMapViewStyle({
      isSatelliteEnabled: enabled,
      mapCenter: mapInstance?.getCenter(),
      mapZoom: mapInstance?.getZoom(),
    });
  };

  const handleSelectFeature = () => {
    setIsFirstRender(false);
    setIsFiltersApplied && setIsFiltersApplied(false);
    setIsZipCodeChanged && setIsZipCodeChanged(false);
  };

  return (
    <MapGLWrapper>
      <MapGL
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        viewport={viewport}
        onMapRefLoaded={setMapInstance}
        setIsSatelliteViewEnabled={handleMapViewStyleChange}
      >
        {mapInstance && (
          <ServiceAreasGL
            equipmentSizes={equipmentSizes}
            equipmentTypes={equipmentTypes}
            handleSelectFeature={handleSelectFeature}
            isSatellite={mapViewStyle.isSatelliteEnabled}
            isServiceAreaEditorModalOpen={isServiceAreaEditorModalOpen}
            isSourceServiceAreasPage={isSourceServiceAreasPage}
            map={mapInstance}
            serviceAreasGeoJSON={allServiceAreasGeoJSON}
          />
        )}
      </MapGL>
    </MapGLWrapper>
  );
};

export default ServiceAreasMapGL;
