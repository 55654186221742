import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const TIME_12AM = '00:00:00';
export const TIME_11PM = '23:00:00';
export const TIME_10PM = '22:00:00';
export const TIME_9PM = '21:00:00';
export const TIME_8PM = '20:00:00';
export const TIME_7PM = '19:00:00';
export const TIME_6PM = '18:00:00';

export const ROUTE_START_TIMES_LIST = [
  { id: '00:00:00', name: translate('vendors.routeStartTimes.12AM') },
  { id: '23:00:00', name: translate('vendors.routeStartTimes.11PM') },
  { id: '22:00:00', name: translate('vendors.routeStartTimes.10PM') },
  { id: '21:00:00', name: translate('vendors.routeStartTimes.09PM') },
  { id: '20:00:00', name: translate('vendors.routeStartTimes.08PM') },
  { id: '19:00:00', name: translate('vendors.routeStartTimes.07PM') },
  { id: '18:00:00', name: translate('vendors.routeStartTimes.06PM') },
];

const ROUTE_START_TIMES = mapKeys(ROUTE_START_TIMES_LIST, 'id');

export default ROUTE_START_TIMES;
