import { AnyAction } from 'redux';
import { sortBy } from 'lodash-es';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import applyBatching from '../../common/services/applyBatching';
import {
  createRouteTemplate,
  exportRouteTemplate as doExportRouteTemplate,
  loadRouteTemplate as doLoadRouteTemplate,
  loadRouteTemplateOptimized as doLoadRouteTemplateOptimized,
  loadRouteTemplateWithLocationsOptimized as doLoadRouteTemplateWithLocationsOptimized,
  routeTemplateLocationAddressChange as doRouteTemplateLocationAddressChange,
  transferRouteTemplateLocations as doTransferRouteTemplateLocations,
  updateRouteTemplate,
} from '../services/routeTemplate';
import { generateCallId } from '../utils/routeLocationCallId';
import { RouteTemplate, RouteTemplateLocation } from 'src/routes/interfaces/RouteTemplates';

// Actions
const BATCH_UPDATE = 'routes/route/BATCH_UPDATE';
const COMPLETE_ADDRESS_CHANGE = 'routes/routeTemplate/COMPLETE_ADDRESS_CHANGE';
const COMPLETE_LOAD_LOCATIONS = 'routes/routeTemplate/COMPLETE_LOAD_LOCATIONS';
const END_LOAD_LOCATIONS = 'routes/route/END_LOAD_LOCATIONS';
const FAIL_ADDRESS_CHANGE = 'routes/routeTemplate/FAIL_ADDRESS_CHANGE ';
const FAIL_EXPORT = 'routes/routeTemplate/FAIL_EXPORT';
const FAIL_LOAD = 'routes/routeTemplate/FAIL_LOAD';
const FAIL_LOAD_LOCATIONS = 'routes/routeTemplate/FAIL_LOAD_LOCATIONS';
const FAIL_SAVE = 'routes/routeTemplate/FAIL_SAVE';
const RESET = 'routes/routeTemplate/RESET';
const START_ADDRESS_CHANGE = 'routes/routeTemplate/START_ADDRESS_CHANGE';
const START_BATCH_TRANSFER = 'routes/routeTemplate/START_BATCH_TRANSFER';
const START_EXPORT = 'routes/routeTemplate/START_EXPORT';
const START_LOAD = 'routes/routeTemplate/START_LOAD';
const START_LOAD_LOCATIONS = 'routes/routeTemplate/START_LOAD_LOCATIONS';
const START_SAVE = 'routes/routeTemplate/START_SAVE';
export const COMPLETE_BATCH_TRANSFER = 'routes/routeTemplate/COMPLETE_BATCH_TRANSFER';
export const COMPLETE_EXPORT = 'routes/routeTemplate/COMPLETE_EXPORT';
export const COMPLETE_LOAD = 'routes/routeTemplate/COMPLETE_LOAD';
export const COMPLETE_SAVE = 'routes/routeTemplate/COMPLETE_SAVE';
export const FAIL_BATCH_TRANSFER = 'routes/routeTemplate/FAIL_BATCH_TRANSFER';

const batchedTemplateLocationsRequesters = {
  callId: null,
};

const DEFAULT_LOCATIONS_LIMIT = 300;

interface State {
  batchTransfererInProgress: boolean;
  batchTransfererProgress: number;
  isChangingAddress: boolean;
  isDeleting: boolean;
  isExporting: boolean;
  isLoading: boolean;
  isLoadingLocations: boolean;
  isSaving: boolean;
  loadedPages: number[];
  locationsLimit: number;
  locationsTotal: number;
  routeTemplate?: RouteTemplate;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  batchTransfererInProgress: false,
  batchTransfererProgress: 0,
  isChangingAddress: false,
  isDeleting: false,
  isExporting: false,
  isLoading: false,
  isLoadingLocations: false,
  isSaving: false,
  loadedPages: [],
  locationsLimit: DEFAULT_LOCATIONS_LIMIT,
  locationsTotal: 0,
  routeTemplate: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case START_LOAD_LOCATIONS:
      return update(state, {
        $merge: {
          isLoadingLocations: true,
          locationsLimit: action.limit,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeTemplate: action.routeTemplate,
        },
      });

    case COMPLETE_LOAD_LOCATIONS: {
      if ((!batchedTemplateLocationsRequesters as any)[action.requesterId]) {
        return state;
      } else {
        const updatedLocations =
          batchedTemplateLocationsRequesters.callId === action.callId
            ? (state.routeTemplate as any).routeLocations.concat(action.routeLocations.locations)
            : action.routeLocations.locations;
        return update(state, {
          $merge: {
            locationsLimit: action.routeLocations.limit,
            loadedPages: [...state.loadedPages, action.page],
            locationsTotal: action.routeLocations.total,
            routeTemplate: {
              ...(state.routeTemplate as any),
              routeLocations: sortBy(updatedLocations, 'orderNumber'),
            },
          },
        });
      }
    }

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case FAIL_LOAD_LOCATIONS:
    case END_LOAD_LOCATIONS:
      return update(state, {
        $merge: {
          isLoadingLocations: false,
          loadedPages: [],
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_BATCH_TRANSFER:
      return update(state, {
        $merge: {
          batchTransfererInProgress: true,
        },
      });

    case COMPLETE_BATCH_TRANSFER:
      return update(state, {
        $merge: {
          batchTransfererInProgress: false,
        },
      });

    case FAIL_BATCH_TRANSFER:
      return update(state, {
        $merge: {
          batchTransfererInProgress: false,
        },
      });

    case START_ADDRESS_CHANGE:
      return update(state, {
        $merge: {
          isChangingAddress: true,
        },
      });

    case COMPLETE_ADDRESS_CHANGE:
      return update(state, {
        $merge: {
          isChangingAddress: false,
        },
      });

    case FAIL_ADDRESS_CHANGE:
      return update(state, {
        $merge: {
          isChangingAddress: false,
        },
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    case BATCH_UPDATE:
      return update(state, {
        $merge: {
          batchTransfererProgress: action.progress,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const startLoadLocations = (page: number, limit: number) => ({
  type: START_LOAD_LOCATIONS,
  page,
  limit,
});

const completeLoad = (routeTemplate: RouteTemplate) => ({
  type: COMPLETE_LOAD,
  routeTemplate,
});

const completeLoadLocations = (
  routeLocations: any[],
  page: number,
  requesterId: number | string,
  callId: number | string,
) => ({
  type: COMPLETE_LOAD_LOCATIONS,
  routeLocations,
  page,
  requesterId,
  callId,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const endLoadLocations = () => ({
  type: END_LOAD_LOCATIONS,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (saveType: any) => ({
  type: COMPLETE_SAVE,
  saveType,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startBatchTransfer = () => ({
  type: START_BATCH_TRANSFER,
});

const completeBatchTransfer = () => ({
  type: COMPLETE_BATCH_TRANSFER,
});

const failBatchTransfer = () => ({
  type: FAIL_BATCH_TRANSFER,
});

const startAddressChange = () => ({
  type: START_ADDRESS_CHANGE,
});

const completeAddressChange = () => ({
  type: COMPLETE_ADDRESS_CHANGE,
});

const failAddressChange = () => ({
  type: FAIL_ADDRESS_CHANGE,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

const failLoadLocations = () => ({
  type: FAIL_LOAD_LOCATIONS,
});

const batchUpdate = (progress: any) => ({
  type: BATCH_UPDATE,
  progress,
});

export const loadRouteTemplate = (routeTemplateId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRouteTemplatePromise = doLoadRouteTemplate(routeTemplateId);
  loadRouteTemplatePromise
    .then(routeTemplate => dispatch(completeLoad(routeTemplate)))
    .catch(() => dispatch(failLoad()));
  return loadRouteTemplatePromise;
};

export const loadRouteTemplateOptimized = (routeTemplateId: number | string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRoutePromise = doLoadRouteTemplateOptimized(routeTemplateId);
  loadRoutePromise.then(route => dispatch(completeLoad(route))).catch(() => dispatch(failLoad()));
  return loadRoutePromise;
};

const loadRouteTemplateLocationsInternal = (
  dispatch: Dispatch,
  routeTemplateId: number | string,
  page: number,
  limit: number,
  requesterId: number | string,
  callId: number | string,
) => {
  dispatch(startLoadLocations(page, limit));
  const loadRouteLocationsPromise = doLoadRouteTemplateWithLocationsOptimized(routeTemplateId, page, limit);

  loadRouteLocationsPromise
    .then(routeWithLocations => dispatch(completeLoadLocations(routeWithLocations as any, page, requesterId, callId)))
    .catch(() => dispatch(failLoadLocations()));
  return loadRouteLocationsPromise;
};

export const cancelBatchedLocationsLoading = (requesterId: number | string) => {
  (batchedTemplateLocationsRequesters as any)[requesterId] = false;
};

export const loadRouteTemplateLocationsBatched =
  (
    requesterId: number | string,
    routeTemplateId: number | string,
    page: number = 1,
    limit: number = DEFAULT_LOCATIONS_LIMIT,
  ) =>
  (dispatch: Dispatch) => {
    const callId = generateCallId();
    (batchedTemplateLocationsRequesters as any)[requesterId] = true;
    (batchedTemplateLocationsRequesters as any).callId = callId;

    const promises = loadRouteTemplateLocationsInternal(dispatch, routeTemplateId, page, limit, requesterId, callId);
    promises.then(result => {
      const numPages = Math.ceil(result.total / limit);
      const promises = [];

      for (let index = page + 1; index <= numPages; index++) {
        promises.push(loadRouteTemplateLocationsInternal(dispatch, routeTemplateId, index, limit, requesterId, callId));
      }

      Promise.all(promises).then(() => {
        (batchedTemplateLocationsRequesters as any)[requesterId] = false;
        dispatch(endLoadLocations());
      });
    });

    return promises;
  };

export const saveRouteTemplate =
  (
    routeTemplate: RouteTemplate,
    optimized?: boolean,
    shouldRecreateRoutes?: boolean,
    shouldCreateTravelPath?: boolean,
    isOrderNumberChangedByUser?: boolean,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveRouteTemplatePromise = routeTemplate.id
      ? updateRouteTemplate(routeTemplate, shouldRecreateRoutes, shouldCreateTravelPath, isOrderNumberChangedByUser)
      : createRouteTemplate(routeTemplate);
    const saveType = routeTemplate.id ? 'Update' : 'Create';
    saveRouteTemplatePromise.then(() => dispatch(completeSave(saveType))).catch(() => dispatch(failSave()));
    return saveRouteTemplatePromise;
  };

export const transferRouteTemplateLocations = (request: any) => async (dispatch: Dispatch) => {
  dispatch(startBatchTransfer());

  const batchSize = 50;
  const batchResult = {
    totalStops: 0,
    transferredStops: 0,
    notTransfferedStopIds: [],
  };

  await applyBatching(
    request.routeEntityLocationsToTransfer,
    batchSize,
    async (batchRouteTemplateLocations, progress) => {
      const result = await doTransferRouteTemplateLocations({
        ...request,
        routeTemplateLocationsToTransfer: batchRouteTemplateLocations,
        sourceRouteTemplateId: request.routeTemplateId,
        shouldRecreateRoutes: request.shouldRecreateRoutes,
      }).catch(() => dispatch(failBatchTransfer()));

      batchResult.totalStops += result.totalStops;
      batchResult.transferredStops += result.transferredStops;
      (batchResult as any).notTransfferedStopIds = [
        ...batchResult.notTransfferedStopIds,
        ...result.notTransfferedStopIds,
      ];

      dispatch(batchUpdate(progress));
    },
  );

  dispatch(completeBatchTransfer());

  return batchResult;
};

export const routeTemplateLocationAddressChange =
  (routeTemplateLocation: RouteTemplateLocation) => (dispatch: Dispatch) => {
    dispatch(startAddressChange());
    const routeTemplateLocationAddressChangePromise = doRouteTemplateLocationAddressChange(routeTemplateLocation);
    routeTemplateLocationAddressChangePromise
      .then(() => dispatch(completeAddressChange()))
      .catch(() => dispatch(failAddressChange()));
    return routeTemplateLocationAddressChangePromise;
  };

export const resetRouteTemplate = () => ({
  type: RESET,
});

export const exportRouteTemplate = (routeTemplateId: number) => (dispatch: Dispatch) => {
  dispatch(startExport());
  const exportRouteTemplatePromise = doExportRouteTemplate(routeTemplateId);
  exportRouteTemplatePromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));
  return exportRouteTemplatePromise;
};
