import { goBack, push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { submit } from 'redux-form';

import { useSelector } from 'src/core/hooks/useSelector';
import { PermissionGuard } from '../../../../account/components';
import { GEO_FENCES_CREATE_EDIT_DELETE } from '../../../../account/constants';
import { hasPermissionSelector } from '../../../../account/ducks';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../../common/components/styled';
import { Button, Panel, PanelSection, Popover } from '../../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../../core/services/createNotification';
import translate from '../../../../core/services/translate';
import { currentVendorId } from '../../../../vendors/services/currentVendorSelector';
import { GeoFence, saveGeoFence } from '../../../ducks/geoFences';
import { GeoFenceEditorForm } from '../../forms';
import { size } from 'lodash-es';
import { useState } from 'react';
import { PopoverWrapper } from 'src/core/components';

interface RouteParams {
  id: string;
}
const GeoFenceEditorPage = () => {
  const params = useParams<RouteParams>();
  const { id } = params;

  const [isEditingGeoFence, setIsEditingGeoFence] = useState(false);

  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const { geoFence } = useSelector(state => state.routes.geoFences);

  const hasCreateSaveEditGeoFencePermission = useSelector(state =>
    hasPermissionSelector(state.account.permissions, GEO_FENCES_CREATE_EDIT_DELETE),
  );
  const isSaving = useSelector(state => state.routes.geoFences.isSaving);

  const onSubmit = (geoFence: GeoFence) => {
    const parsedGeoJson = JSON.parse(geoFence.geoFenceJson || '{ "geometry": { "coordinates": [] } }');

    if (parsedGeoJson && size(parsedGeoJson.geometry.coordinates)) {
      saveGeoFence(
        geoFence,
        vendorId,
      )(dispatch)
        .then(() => {
          const successMessage = id ? 'editSuccess' : 'saveSuccess';
          createSuccessNotification(translate(`routes.geoFences.alertMessages.${successMessage}`));
          dispatch(push(`/fleet/geo-fences`));
        })
        .catch(error => {
          const code = error.response.data && error.response.data.code;
          switch (code) {
            case 'EntityAlreadyExists':
              createErrorNotification(translate('routes.geoFences.alertMessages.entityAlreadyExists'));
              break;
            default:
              createErrorNotification(translate('routes.geoFences.alertMessages.saveError'));
              break;
          }
        });
    } else {
      createErrorNotification(translate('routes.geoFences.alertMessages.noAreaSelected'));
    }
  };

  const saveChanges = () => {
    dispatch(submit('geoFenceEditorForm'));
  };

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction
              id="back-button"
              onClick={() => {
                dispatch(goBack());
              }}
            >
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle>{translate(`routes.geoFences.${id ? 'edit' : 'create'}GeoFence`)}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PermissionGuard permission={GEO_FENCES_CREATE_EDIT_DELETE}>
          <PageActions align="right">
            <PopoverWrapper
              triggerButton={
                <Button color="primary" id="geo-fence-save-button" onClick={saveChanges} disabled={isEditingGeoFence}>
                  {translate('common.save')}
                </Button>
              }
              popoverContent={isEditingGeoFence && <Popover>{translate('routes.geoFences.savePolygons')}</Popover>}
            />
          </PageActions>
        </PermissionGuard>
      </PageHeader>
      <Panel>
        <PanelSection isLoading={isSaving}>
          <GeoFenceEditorForm
            isCreate={!id}
            onSubmit={onSubmit}
            hasCreateSaveEditGeoFencePermission={hasCreateSaveEditGeoFencePermission}
            initialValues={id ? geoFence : {}}
            isEditingGeoFence={isEditingGeoFence}
            setIsEditingGeoFence={setIsEditingGeoFence}
          />
        </PanelSection>
      </Panel>
    </PageContent>
  );
};
export default GeoFenceEditorPage;
