import { change, submit } from 'redux-form';
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce, filter, forEach, get, map, uniqBy } from 'lodash-es';
import { push } from 'connected-react-router';
import { Resizable } from 're-resizable';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';

import { AppState } from 'src/store';
import { ActionButtonTooltip, MapDragHandle, PopoverWrapper, Table, UnconnectedCheckbox } from 'src/core/components';
import { BOTTOM, TABLE_ROW_HEIGHT_LARGE, TODAY_FORMATTED } from 'src/core/constants';
import {
  Button,
  Grid,
  GridColumn,
  Label,
  Message,
  Panel,
  PanelSection,
  Popover,
  TableActionButton,
  Text,
} from 'src/core/components/styled';
import {
  checkIfSnowPlowIsEnabled,
  checkIfStreetSweepingIsEnabled,
  isNavi3FeatureEnabled,
  isTravelPathNavigationFeatureEnabled,
} from 'src/vendors/ducks/features';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import {
  clearRouteMapSelectedFeature,
  RouteMapFeature,
  setIsDrawingMode,
  setRouteMapSelectedFeature,
} from 'src/routes/ducks/mapControls';
import { COMPLETED, IN_PROGRESS, SCHEDULED } from 'src/routes/constants';
import { COMPLEX, SIMPLE } from 'src/common/constants/drivingComplexity';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { date as dateFormatter } from 'src/utils/services/formatter';
import { dateFormat } from 'src/utils/services/validator';
import { filterSegmentsPredicate, getFilteredNewSegments } from 'src/dashboard/utils/alternativeFleet';
import { getIsResetRouteEnabled } from 'src/routes/utils/routeDetails';
import { getOrderedRows, SortOrder } from 'src/core/components/Table';
import { getSegmentsToShow } from 'src/customers/components/pages/streetNetwork/utils';
import { getTextWithAccentSections } from 'src/landing/components/services';
import {
  loadNextRunNumber,
  loadRoute,
  loadRouteMapFilters,
  loadRouteSegments,
  loadSnowPlowSegmentList,
  loadSnowSweeperRouteSummary,
  loadTravelPathStatusDetails,
  resetRoute,
  resetRouteSegments,
  resetRouteTracker,
  setPickupStatusIds,
  setSearchTerm,
  transferRouteLocations,
  updateRouteStops,
} from 'src/routes/ducks';
import { OverrideDate } from 'src/routes/components';
import {
  PageActionsSmall,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageFooter,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { removeLastLocation } from 'src/core/ducks';
import { resetFilters } from 'src/dashboard/ducks';
import { ResetRouteData, Route } from 'src/routes/interfaces/Route';
import { resetRouteSummary } from 'src/routes/ducks/routeSummary';
import { ROUTE_LABEL_COLORS, ROUTE_STATUSES_BY_ID } from 'src/routes/constants';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { RouteLocationImagesModalResolver, RouteLocationIssuesModalResolver } from '../../modals';
import { RouteResetForm } from 'src/routes/components/forms';
import { RouteSegment } from 'src/routes/interfaces/RouteSegment';
import { RouteStopsPayLoad } from 'src/routes/interfaces/RouteStop';
import {
  setIsEditSegmentsModeOn,
  setIsDrawSelectionModeOn,
  resetSnowOrSweeperStreetNetwork,
  setSegmentColoringType,
} from 'src/customers/ducks';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { STREET_SEGMENT_MAP_FILTERS_FORM_NAME } from 'src/customers/constants/streetNetwork';
import { STREET_SEGMENTS_MAP_HEIGHT } from 'src/routes/constants/streetSegmentsMap';
import { StreetNetwork } from 'src/customers/interfaces/StreetNetwork';
import { StreetNetworkMapWrapper } from 'src/customers/components/styled';
import { TransferRouteStopsFormValues } from '../../forms/TransferRouteStopsForm';
import createPopover from 'src/core/services/createPopover';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import EffectiveDate from 'src/routes/components/EffectiveDate';
import routeAssistance from 'src/common/assets/img/insights/routeAssistance.png';
import RouteStopsSearchForm from '../../forms/RouteStopsSearchForm';
import SnowPlowSegmentsTableRow from './SnowPlowSegmentsTableRow';
import SnowSweeperDatePickerForm from '../../forms/SnowSweeperDatePickerForm';
import SnowSweeperRouteHeadingDetails from './SnowSweeperRouteHeadingDetails';
import SnowSweeperRouteSummaryDetails from './SnowSweeperRouteSummaryDetails';
import StreetNetworkMapGL from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import StreetSweeperSegmentsTableRow from './StreetSweeperSegmentsTableRow';
import TransferRouteStopsModal from '../../modals/TransferRouteStopsModal';
import translate from 'src/core/services/translate';

const createNewSnowPagePageUrl = '/routes/snow-tracker/create';
const createNewStreetSweeperPageUrl = '/routes/sweeper-tracker/create';

type Props = {
  isSnowPlowRoute?: boolean;
  isStreetSweeperRoute?: boolean;
  routeLocationId?: number;
  shouldOpenImagesModal?: boolean;
};

interface LocationState {
  createdDailyRoute?: boolean;
  streetSegmentId?: number;
}

const SnowOrSweeperRoutePage = ({
  isSnowPlowRoute,
  isStreetSweeperRoute,
  routeLocationId,
  shouldOpenImagesModal,
}: Props) => {
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const wrapperRef = useRef<HTMLDivElement>(null);

  let closePopover = () => {};

  const location = useLocation();
  const locationState = location.state as LocationState;
  let isCreateNewRoute = false;
  if (locationState && locationState?.createdDailyRoute) {
    isCreateNewRoute = true;
    window.history.pushState({ createdDailyRoute: false }, '');
  }

  const defaultStreetSegmentId = locationState?.streetSegmentId;

  const vendorId = useSelector(currentVendorId);
  const { searchTerm, pickupStatusIds } = useSelector((state: AppState) => state.routes.filters);
  const { isLoading: isLoadingRoute, route } = useSelector((state: AppState) => state.routes.route);
  const { isSaving } = useSelector((state: AppState) => state.routes.routeStops);
  const { routeSummary, isLoading: isLoadingRouteSummary } = useSelector(
    (state: AppState) => state.routes.routeSummary,
  );
  const { routeLocations, isNoRoute, routeTemplateId } = useSelector(
    (state: AppState) => state.routes.route.route || ({} as Route),
  );
  const { isLoading: isLoadingSegmentList, segmentList } = useSelector(
    (state: AppState) => state.routes.snowPlowSegmentList,
  );
  const { lastLocations } = useSelector((state: AppState) => state.core);
  const { routeSegments: segmentsList, isLoading: isLoadingRouteSegments } = useSelector(
    (state: AppState) => state.routes.routeSegments,
  );
  const {
    isLoading: isLoadingStreetNetwork,
    streetNetwork,
    isEditSegmentsModeOn,
    isEditRouteGeoFenceModeOn,
  } = useSelector((state: AppState) => state.customers.streetNetwork) as any;
  const isSnowPlowFeatureEnabled = useSelector((state: AppState) => checkIfSnowPlowIsEnabled(state));
  const isStreetSweeperFeatureEnabled = useSelector((state: AppState) => checkIfStreetSweepingIsEnabled(state));
  const { selectedFeature } = useSelector((state: AppState) => state.routes.mapControls);
  const { wasteAuditTypes } = useSelector((state: AppState) => state.common.wasteAuditTypes);
  const { travelPathStatusDetails } = useSelector((state: AppState) => state.routes.travelPath);
  const isTravelPathFeatureEnabled = useSelector(isTravelPathNavigationFeatureEnabled);
  const isNaviV3FeatureEnabled = useSelector(isNavi3FeatureEnabled);

  const { snowPlowSettings } = useSelector((state: AppState) => state.vendors.snowPlowSettings);
  const { streetSweepingSettings } = useSelector((state: AppState) => state.vendors.streetSweepingSettings);
  const isDriverExperienceSimple =
    (isSnowPlowRoute && snowPlowSettings.drivingComplexityType?.id === SIMPLE) ||
    (isStreetSweeperRoute && streetSweepingSettings.drivingComplexityType?.id === SIMPLE);
  const isDriverExperienceComplex =
    (isSnowPlowRoute && snowPlowSettings.drivingComplexityType?.id === COMPLEX) ||
    (isStreetSweeperRoute && streetSweepingSettings.drivingComplexityType?.id === COMPLEX);

  const [isTransferSegmentsModalOpen, setTransferSegmentsModalOpen] = useState<boolean>(false);
  const [routeSegments, setRouteSegments] = useState<StreetNetwork[]>(segmentList);
  const [selectedSegments, setSelectedSegments] = useState<number[]>([]);
  const [newSegments, setNewSegments] = useState<RouteLocation[]>([]);
  const [newSegmentIds, setNewSegmentIds] = useState<number[]>([]);
  const [deletedSegments, setDeletedSegments] = useState<number[]>([]);
  const [segmentsToTransfer, setSegmentsToTransfer] = useState<RouteLocation[]>([]);
  const [hasTransferredSegments, setHasTransferredSegments] = useState<boolean>(false);
  const [mapHeight, setMapHeight] = useState(STREET_SEGMENTS_MAP_HEIGHT);
  const [rowScrollTopPosition, setRowScrollTopPosition] = useState(0);
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
  const [sortBy, setSortBy] = useState<string>('');
  const [isRouteImagesModalOpen, setIsRouteImagesModalOpen] = useState(!!shouldOpenImagesModal);
  const [isRouteStopIssuesModalOpen, setIsRouteStopIssuesModalOpen] = useState(!!routeLocationId);
  const [isRouteStopsLoaded, setIsRouteStopsLoaded] = useState(false);
  const [segmentsFilterStartDate, setSegmentsFilterStartDate] = useState<Date | string>(
    moment(routeSummary?.date).format(dateFormat) || TODAY_FORMATTED,
  );
  const [segmentsFilterEndDate, setSegmentsFilterEndDate] = useState<Date | string>(
    moment(routeSummary?.date).format(dateFormat) || TODAY_FORMATTED,
  );

  const isSupport = checkIfSupport();
  const isViewOnly = checkIfViewOnly();

  const resetSearchForm = useCallback(() => {
    dispatch(setSearchTerm(''));
    dispatch(setPickupStatusIds([]));
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(resetRoute());
      dispatch(resetRouteSummary());
      dispatch(resetRouteSegments());
      dispatch(resetFilters());
      dispatch(resetSnowOrSweeperStreetNetwork());
      dispatch(setSegmentColoringType('segmentStatus'));
      resetSearchForm();
      dispatch(clearRouteMapSelectedFeature());
    },
    [dispatch, resetSearchForm],
  );

  const routeSegmentIds = useMemo(() => routeSegments.map(segment => segment.id), [routeSegments]);

  const routeSegmentsWithStreetPasses = useMemo(
    () =>
      uniqBy(
        map(segmentsList.filter((el: RouteSegment) => routeSegmentIds.includes(el.streetSegmentId))),
        'streetSegmentId',
      ),
    [segmentsList, routeSegmentIds],
  );

  const segmentsToShow: any = useMemo(
    () =>
      getSegmentsToShow(
        routeSegments,
        streetNetwork,
        routeSegmentsWithStreetPasses,
        segmentsList,
        selectedSegments,
        isSnowPlowRoute,
      ),
    [routeSegments, selectedSegments, streetNetwork, routeSegmentsWithStreetPasses, segmentsList, isSnowPlowRoute],
  );

  useEffect(() => {
    const segmentListIds = segmentList.map((el: StreetNetwork) => el.id);
    if (
      routeSegments.length > segmentsList.length &&
      segmentsToShow.filter((segment: StreetNetwork) => segmentListIds.includes(segment.id)).length &&
      !newSegments.length
    ) {
      setRouteSegments(segmentsToShow.filter((segment: StreetNetwork) => !segmentListIds.includes(segment.id)));
    }
  }, [segmentList, routeSegments, segmentsList, segmentsToShow, newSegments]);

  const onSearchTermChange = debounce(searchTerm => {
    dispatch(setSearchTerm(searchTerm));
  }, 200);

  const handleLoadRouteStops = useCallback(
    (from?: Date | string, to?: Date | string) => {
      if (route) {
        setIsRouteStopsLoaded(true);

        setTimeout(() => dispatch(submit(STREET_SEGMENT_MAP_FILTERS_FORM_NAME)), 0);

        const startDate = from || segmentsFilterStartDate || moment(routeSummary?.date).format(dateFormat);
        const endDate = to || segmentsFilterEndDate || moment(routeSummary?.date).format(dateFormat);
        loadSnowSweeperRouteSummary(vendorId, routeSummary?.routeId || 0, startDate, endDate)(dispatch);
        loadRouteMapFilters(vendorId, routeSummary?.routeId, startDate, endDate)(dispatch);
      }
    },
    [
      dispatch,
      route,
      routeSummary?.date,
      routeSummary?.routeId,
      segmentsFilterEndDate,
      segmentsFilterStartDate,
      vendorId,
    ],
  );

  const onDateChange = (startDate: any | Date | string, endDate: Date | string, isEditMode?: boolean) => {
    if (!isEditMode || isRouteStopsLoaded) {
      handleLoadRouteStops(startDate, endDate);
    }
    setSegmentsFilterStartDate(startDate as Date | string);
    setSegmentsFilterEndDate(endDate as Date | string);
  };

  const onPickupStatusChange = (pickupStatusIds: number[]) => {
    dispatch(setPickupStatusIds(pickupStatusIds));

    const options: any = {};
    forEach(pickupStatusIds, (id: number) => {
      options[`_${id}`] = true;
    });

    if (!pickupStatusIds) {
      forEach(
        isDriverExperienceComplex ? [SCHEDULED, IN_PROGRESS, COMPLETED] : [SCHEDULED, COMPLETED],
        (id: number) => {
          options[`_${id}`] = true;
        },
      );
    }

    if (!isEditSegmentsModeOn) {
      dispatch(change(STREET_SEGMENT_MAP_FILTERS_FORM_NAME, 'pickupStatusTypeIds', options));
      handleLoadRouteStops();
    }
  };

  const filteredSegments = useMemo(() => {
    const filteredNewSegments = getFilteredNewSegments(newSegments, searchTerm, pickupStatusIds);

    return [
      ...getOrderedRows(
        [...segmentsToShow.filter(filterSegmentsPredicate(searchTerm, pickupStatusIds)), ...filteredNewSegments],
        sortBy,
        sortOrder,
      ),
    ];
  }, [segmentsToShow, searchTerm, pickupStatusIds, sortBy, sortOrder, newSegments]);

  useEffect(() => {
    if (!selectedFeature?.noTableScroll) {
      const segmentIndex = filteredSegments.findIndex((segment: StreetNetwork) => segment.id === selectedFeature?.id);
      let rowScrollTopPosition = segmentIndex * TABLE_ROW_HEIGHT_LARGE + 1;
      if (filteredSegments.length > 8) {
        if (segmentIndex > filteredSegments.length - 7) {
          rowScrollTopPosition = (filteredSegments.length - 7) * TABLE_ROW_HEIGHT_LARGE + 1;
        }
        setRowScrollTopPosition(rowScrollTopPosition);
      }
    }
  }, [filteredSegments, selectedFeature?.id, selectedFeature?.noTableScroll]);

  useEffect(() => {
    if (defaultStreetSegmentId) {
      handleLoadRouteStops();
      dispatch(setRouteMapSelectedFeature(RouteMapFeature.routeSegments, defaultStreetSegmentId));
    }
  }, [defaultStreetSegmentId, dispatch, handleLoadRouteStops]);

  const handleSubmit = () => {
    const payload = {
      changedStops: [],
      deletedStops: deletedSegments,
      newStops: newSegments,
      pinnedStops: [],
    };
    if (route)
      updateRouteStops(
        vendorId,
        route.id,
        payload as unknown as RouteStopsPayLoad,
      )(dispatch).then(async () => {
        const { routeSegmentCount, routeId } = await loadSnowSweeperRouteSummary(
          vendorId,
          route.id,
          segmentsFilterStartDate,
          segmentsFilterEndDate,
        )(dispatch);
        loadRoute(route.id)(dispatch);
        dispatch(submit(STREET_SEGMENT_MAP_FILTERS_FORM_NAME));

        if (
          routeSegmentCount &&
          routeId &&
          routeSegmentCount >= 2 &&
          (isTravelPathFeatureEnabled || isNaviV3FeatureEnabled)
        ) {
          loadTravelPathStatusDetails(routeId)(dispatch);
        }

        dispatch(setIsEditSegmentsModeOn(false));
        setNewSegments([]);
        setHasTransferredSegments(false);
        setNewSegmentIds([]);
        setSelectedSegments([]);
        setDeletedSegments([]);
      });
  };

  const handleCancel = () => {
    setIsRouteStopsLoaded(true);

    const segmentsIds = segmentsList.map((segment: any) => segment.streetSegmentId);

    if (!!segmentsIds.length) {
      loadSnowPlowSegmentList(
        vendorId,
        segmentsIds,
      )(dispatch).then(res => {
        setRouteSegments(res);
      });
    }

    setRouteSegments([]);
    setNewSegments([]);
    setSelectedSegments([]);
    setDeletedSegments([]);
    setHasTransferredSegments(false);
    dispatch(setIsEditSegmentsModeOn(false));
  };

  const handleSelectSegment = (segmentId: number) => {
    if (selectedSegments.length && selectedSegments.includes(segmentId)) {
      setSelectedSegments(selectedSegments.filter(id => id !== segmentId));
    } else {
      const selected = [...selectedSegments, segmentId];

      setSelectedSegments(selected);
    }
  };

  const handleCheckAllSegments = () => {
    if (selectedSegments.length === filteredSegments.length) {
      setSelectedSegments([]);
    } else {
      setSelectedSegments(filteredSegments.map((segment: StreetNetwork) => segment.id));
    }
  };

  const handleOnSortChanged = (sortBy: string, sortOrder: SortOrder) => {
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  };

  const checkCell = isEditSegmentsModeOn
    ? !checkIfViewOnly() && !checkIfSupport()
      ? {
          name: 'selectAll',
          component: UnconnectedCheckbox,
          componentProps: {
            onChange: handleCheckAllSegments,
            checked: selectedSegments.length === filteredSegments.length,
            partial: 0 < selectedSegments.length && selectedSegments.length < filteredSegments.length,
          },
          width: '3%',
          padding: 'defaultCellVertical xSmall',
          noPaddingRight: true,
          onClick: (e: ChangeEvent<HTMLInputElement>) => e.stopPropagation(),
        }
      : {
          name: 'selectAll',
          width: '3%',
          padding: 'defaultCellVertical ',
          noPaddingRight: true,
        }
    : { name: 'selectAll', label: '', width: '0%', padding: 'no', noPaddingRight: true };

  const snowPlowRouteStopsTableCells = [
    checkCell,
    { name: 'name', label: translate('common.locationName'), width: '19%', sortable: true },
    { name: 'status', label: translate('routes.snowPlow.status'), width: '9%', sortable: true },
    { name: 'pickupStatusTypeId', label: translate('common.segmentStatus'), width: '14%', sortable: true },
    { name: 'streetPasses', label: translate('routes.snowPlow.passes'), width: '14%', sortable: true },
    {
      name: 'passDateTime',
      label: translate('routes.snowPlow.mostRecentPass'),
      width: '17%',
      sortable: true,
    },
    {
      name: 'isOneWay',
      label: translate('routes.snowPlow.direction'),
      width: `${isEditSegmentsModeOn ? '10%' : '13%'}`,
      sortable: true,
    },
    { name: 'events', label: translate('common.events'), width: '14%', sortable: false, align: 'right' },
  ];

  const streetSweeperRouteStopsTableCells = [
    checkCell,
    {
      name: 'name',
      label: translate('common.locationName'),
      width: `${isEditSegmentsModeOn ? '16%' : '19%'}`,
      sortable: true,
    },
    { name: 'status', label: translate('routes.snowPlow.status'), width: '9%', sortable: true },
    { name: 'pickupStatusTypeId', label: translate('common.segmentStatus'), width: '14%', sortable: true },
    { name: 'streetPasses', label: translate('routes.snowPlow.passes'), width: '11%', sortable: true },
    {
      name: 'passDateTime',
      label: translate('routes.snowPlow.mostRecentPass'),
      width: '16%',
      sortable: true,
    },
    {
      name: 'isOneWay',
      label: translate('routes.snowPlow.direction'),
      width: '10%',
      sortable: true,
    },
    { name: 'serviceSide', label: translate('routes.snowPlow.serviceSide'), width: '11%', sortable: true },
    { name: 'events', label: translate('common.events'), width: '9%', sortable: false, align: 'right' },
  ];

  const handleClearSelection = () => {
    setSelectedSegments([]);
    dispatch(setIsDrawingMode(false));
    dispatch(setIsDrawSelectionModeOn(false));
  };

  const handleAddSegments = (segmentsAddedToRoute: number) => {
    loadSnowPlowSegmentList(
      vendorId,
      selectedSegments.filter(segmentId => !routeSegmentIds.includes(segmentId)),
    )(dispatch).then(res => {
      const newSegmentIdsToAdd = [...newSegmentIds, ...(res ? res.map((segment: StreetNetwork) => segment.id) : [])];

      segmentsAddedToRoute === 1
        ? createSuccessNotification(translate('routes.alertMessages.routeLocationAdded'))
        : createSuccessNotification(
            translate('routes.alertMessages.segmentsAddedToRoute', { numberOfSegments: segmentsAddedToRoute }),
          );

      setNewSegmentIds(newSegmentIdsToAdd);
      const newSegmentsToAdd = [...newSegments, ...(res || [])];
      setNewSegments(newSegmentsToAdd);
      const updatedRouteSegments = [...routeSegments, ...res];
      setRouteSegments(updatedRouteSegments);
      dispatch(setIsDrawingMode(false));
      dispatch(setIsDrawSelectionModeOn(false));
      setSelectedSegments([]);
    });
  };

  const handleRemoveSegments = (segmentsDeletedFromRoute: number) => {
    const currentDeletedSegments = segmentsToShow
      .filter((segment: StreetNetwork) => segment.isSelected && segment.routeStopId)
      .map((segment: StreetNetwork) => segment.routeStopId);

    const allDeletedSegments =
      deletedSegments && currentDeletedSegments ? [...deletedSegments, ...currentDeletedSegments] : [];

    segmentsDeletedFromRoute === 1
      ? createSuccessNotification(translate('routes.alertMessages.segmentRemoved'))
      : createSuccessNotification(
          translate('routes.alertMessages.segmentsRemoved', { numberOfSegments: segmentsDeletedFromRoute }),
        );

    const currentSegments = routeSegments.filter(segment => !selectedSegments.includes(segment.id));
    const updatedNewSegmentIds = newSegmentIds.filter(
      (el: any) =>
        !routeSegments
          .filter((segment: StreetNetwork) => selectedSegments.map((el: any) => el).includes(segment.id))
          .map((segment: StreetNetwork) => segment.id)
          .includes(el),
    );
    const updatedNewSegments = newSegments.filter(
      (el: any) =>
        !routeSegments
          .filter((segment: StreetNetwork) => selectedSegments.map((el: any) => el).includes(segment.id))
          .map((segment: StreetNetwork) => segment.locationId)
          .includes(el.locationId),
    );

    setDeletedSegments(allDeletedSegments);
    setNewSegmentIds(updatedNewSegmentIds);
    setNewSegments(updatedNewSegments);
    setRouteSegments(currentSegments);
    setSelectedSegments([]);

    dispatch(setIsDrawingMode(false));
    dispatch(setIsDrawSelectionModeOn(false));
    dispatch(clearRouteMapSelectedFeature());
  };

  const handleDeleteSingleSegment = (id: number, routeStopId: number) => {
    deletedSegments.push(routeStopId);
    const updatedNewSegments = newSegments.filter(
      (el: any) =>
        !routeSegments
          .filter((segment: StreetNetwork) => segment.id === id)
          .map((segment: StreetNetwork) => segment.locationId)
          .includes(el.locationId),
    );

    setNewSegmentIds(newSegmentIds.filter((el: number) => el !== id));
    setNewSegments(updatedNewSegments);
    setDeletedSegments(deletedSegments.some(el => el) ? deletedSegments : []);
    setRouteSegments(routeSegments.filter((segment: StreetNetwork) => segment.id !== id));

    dispatch(clearRouteMapSelectedFeature());
    createSuccessNotification(translate('routes.alertMessages.segmentRemoved'));
  };

  const selectStopsToTransfer = () => {
    loadSnowPlowSegmentList(
      vendorId,
      selectedSegments.filter(segmentId =>
        newSegmentIds.length ? !newSegmentIds.includes(segmentId) : routeSegmentIds.includes(segmentId),
      ),
    )(dispatch).then(res => {
      if (routeLocations)
        setSegmentsToTransfer(
          routeLocations
            ?.filter((stop: RouteLocation) => res.map((el: StreetNetwork) => el.locationId).includes(stop.location.id))
            .map((stop: RouteLocation) => stop),
        );
      setTransferSegmentsModalOpen(true);
    });
  };

  const handleTransferRouteSegments = ({ targetRouteId, routeDate, positionTypeId }: TransferRouteStopsFormValues) => {
    if (!routeSummary) return;

    transferRouteLocations({
      routeEntityLocationsToTransfer: segmentsToTransfer,
      targetRouteId,
      routeDate,
      positionTypeId,
    })(dispatch)
      .then(({ totalStops, transferredStops, notTransfferedStopIds }) => {
        dispatch(setIsDrawingMode(false));
        dispatch(setIsDrawSelectionModeOn(false));

        if (transferredStops === 0) {
          createErrorNotification(translate('routes.alertMessages.segmentsAlreadyExist'));
        } else {
          loadSnowSweeperRouteSummary(
            vendorId,
            routeSummary.routeId,
            segmentsFilterStartDate,
            segmentsFilterEndDate,
          )(dispatch);
          loadRoute(routeSummary.routeId)(dispatch);
          setTransferSegmentsModalOpen(false);
          dispatch(setIsDrawingMode(false));
          dispatch(setIsDrawSelectionModeOn(false));
          setSelectedSegments([]);
          setDeletedSegments([]);
          setHasTransferredSegments(true);
          setRouteSegments(
            filter(
              routeSegments,
              (segment: StreetNetwork) =>
                !selectedSegments
                  .filter(segmentId =>
                    newSegmentIds.length ? !newSegmentIds.includes(segmentId) : routeSegmentIds.includes(segmentId),
                  )
                  .includes(segment.id),
            ),
          );

          const deletedIndexes = segmentsToTransfer
            .filter((el: any) => !notTransfferedStopIds.includes(el.id))
            .map((stop: any) => stop.index);
          const totalDeletedStops = deletedIndexes.length;
          createSuccessNotification(
            translate('routes.alertMessages.segmentsTransferred', {
              totalSegments: totalStops,
              transferredSegments: totalDeletedStops,
            }),
          );
        }
      })
      .catch(({ code }) => {
        createErrorNotification(
          `${translate(createTranslationKey(code || 'routeLocationsTransferError', 'routes.alertMessages'))}`,
        );
      });
  };

  const handleResizeMap = (_: any, __: any, map: HTMLElement) => {
    setMapHeight(map.offsetHeight);
  };

  const goBackToPreviousPage = () => {
    const createRoute = get(lastLocations, isSnowPlowRoute ? createNewSnowPagePageUrl : createNewStreetSweeperPageUrl);
    if (createRoute) {
      removeLastLocation(createRoute);
    }

    const hasLastLocations = Object.keys(lastLocations).length > 1;

    return hasLastLocations && !createRoute
      ? goBack()
      : dispatch(
          push(
            isSnowPlowRoute
              ? `/routes/snow-tracker?vehicleTypeIds=${SNOW_PLOW_ID}`
              : `/routes/sweeper-tracker?vehicleTypeIds=${STREET_SWEEPER_ID}`,
          ),
        );
  };

  let routeAssistTooltip = '';
  if (routeSummary) {
    if (routeSummary.assistingVehiclesActiveCount || routeSummary.assistingVehiclesCount) {
      routeAssistTooltip =
        translate('routeAssist.routeAssisted', { numberOfVehicles: routeSummary.assistingVehiclesCount }) +
        (routeSummary.assistingVehiclesActiveCount
          ? ` (${translate('routeAssist.activeVehicles', {
              numberOfVehicles: routeSummary.assistingVehiclesActiveCount,
            })})`
          : '');
    }
  }

  const onHandleSubmitFilters = useCallback(async () => {
    if (route) {
      setIsRouteStopsLoaded(true);

      const routeSegments = await loadRouteSegments({
        vendorId,
        routeId: route.id,
        startDate: segmentsFilterStartDate,
        endDate: segmentsFilterEndDate,
        isSnowPlowRoute,
      })(dispatch);

      const segmentsIds = routeSegments.map((segment: any) => segment.streetSegmentId);

      if (segmentsIds.length) {
        loadSnowPlowSegmentList(
          vendorId,
          segmentsIds,
        )(dispatch).then(res => {
          setRouteSegments(res);
        });
      }
    }
  }, [dispatch, route, segmentsFilterStartDate, segmentsFilterEndDate, vendorId, isSnowPlowRoute]);

  const segmentsAddedToRoute = selectedSegments.filter(segmentId => !routeSegmentIds.includes(segmentId)).length;
  const segmentsDeletedFromRoute = selectedSegments.filter(segmentId => routeSegmentIds.includes(segmentId)).length;

  const selectedSegment = filteredSegments.find(filteredSegment => filteredSegment.routeStopId === routeLocationId);
  const wasteAuditType = wasteAuditTypes.find(wasteAuditType => wasteAuditType.id === routeSummary?.wasteAuditTypeId);

  const isLoading =
    isLoadingRouteSummary ||
    isLoadingSegmentList ||
    isLoadingStreetNetwork ||
    isLoadingRoute ||
    isLoadingRouteSegments ||
    isSaving;

  const filteredSegmentsUniq = uniqBy(filteredSegments, 'id');

  const virtualizedProps = {
    height: Math.min(filteredSegmentsUniq?.length * TABLE_ROW_HEIGHT_LARGE, TABLE_ROW_HEIGHT_LARGE * 8) || 1,
    itemSize: TABLE_ROW_HEIGHT_LARGE,
  };

  const onResetRouteFormSubmit = (routeId: number, isSnowPlowRoute: boolean, data: ResetRouteData) => {
    resetRouteTracker(
      routeId,
      data,
    )(dispatch)
      .then(async response => {
        !!closePopover && closePopover();
        createSuccessNotification(translate('routes.alertMessages.routeReseted'));

        const routeUrl = isSnowPlowRoute ? 'snow-tracker' : 'sweeper-tracker';
        window.location.href = `/routes/${routeUrl}/${response.id}`;
      })
      .catch(({ code }) => {
        createErrorNotification(
          `${translate(createTranslationKey(code, 'routes.alertMessages'), { routeDate: data.date })}`,
        );
      });
  };

  const resetSnowOrSweeperRoute = (
    routeId: number,
    routeName: string,
    isSnowPlowRoute: boolean,
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();

    loadNextRunNumber(routeId)(dispatch);

    closePopover = createPopover(
      event.currentTarget,
      RouteResetForm,
      { onSubmit: (data: ResetRouteData) => onResetRouteFormSubmit(routeId, isSnowPlowRoute, data), routeName },
      { position: BOTTOM, zIndex: 6000 },
    );
  };

  const isResetButtonVisible = getIsResetRouteEnabled(isSnowPlowRoute, isStreetSweeperRoute, !!routeTemplateId);

  return (
    <PageContent isLoading={isLoading}>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction onClick={goBackToPreviousPage} id="back-button">
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <Grid>
              <PageTitle>
                {routeSummary ? (
                  <>
                    {routeSummary?.name}

                    <Text size="xLarge" margin="no no no xSmall">
                      • {translate('routes.snowPlow.priority')}:{' '}
                      {routeSummary?.routePriorityTypeId || translate('common.notAvailable')}
                    </Text>
                  </>
                ) : (
                  translate('routes.newRoute')
                )}
              </PageTitle>

              {routeSummary &&
                (routeSummary.routeAssistanceEnabled ||
                  !!(routeSummary.assistingVehiclesActiveCount || routeSummary.assistingVehiclesCount)) && (
                  <ActionButtonTooltip
                    imageSrc={routeAssistance}
                    tooltip={routeAssistTooltip}
                    tooltipAsString
                    margin="small xSmall"
                  />
                )}
            </Grid>
          </PageTitleContainer>

          <Grid multiLine>
            <GridColumn size="12/12" padding="xxSmall no xSmall no">
              <Text uppercase>
                <b>{translate('common.status')}: </b>
              </Text>
              <Text>
                <Label
                  fontSize="ssMedium"
                  alignTop
                  color={
                    routeSummary?.routeStatusTypeId
                      ? ROUTE_LABEL_COLORS[routeSummary?.routeStatusTypeId]
                      : ROUTE_LABEL_COLORS[SCHEDULED]
                  }
                >
                  {routeSummary?.routeStatusTypeId
                    ? ROUTE_STATUSES_BY_ID[routeSummary?.routeStatusTypeId]?.name
                    : ROUTE_STATUSES_BY_ID[SCHEDULED].name}
                </Label>
              </Text>

              {isResetButtonVisible && (
                <TableActionButton
                  id={`route-${routeSummary?.routeId}-reset-button`}
                  margin="no no no xSmall"
                  onClick={event =>
                    resetSnowOrSweeperRoute(
                      routeSummary?.routeId || 0,
                      routeSummary?.name || '',
                      isSnowPlowRoute || false,
                      event,
                    )
                  }
                >
                  <ActionButtonTooltip size="sMedium" icon="refresh" tooltip="resetRoute" />
                </TableActionButton>
              )}
            </GridColumn>

            <GridColumn size="12/12" padding="no no xxSmall no">
              <Text uppercase>
                <b>{translate('common.date')}: </b>
              </Text>
              <Text>
                {routeSummary?.date ? moment(routeSummary.date).format('MM/DD/YYYY') : TODAY_FORMATTED}{' '}
                {routeSummary?.routeRunDate && <OverrideDate routeRunDate={dateFormatter(routeSummary.routeRunDate)} />}
                {routeSummary?.templateEffectiveDate && (
                  <EffectiveDate
                    margin="no xxSmall no no"
                    templateEffectiveDate={dateFormatter(routeSummary.templateEffectiveDate)}
                  />
                )}
              </Text>
            </GridColumn>
          </Grid>

          {!isNoRoute && (
            <SnowSweeperDatePickerForm
              onDateChange={onDateChange}
              initialValues={{
                startDate: route ? route.routeDate : TODAY_FORMATTED,
                endDate: route ? route.routeDate : TODAY_FORMATTED,
              }}
            />
          )}
        </PageDetails>
        <PageActionsSmall>
          <SnowSweeperRouteHeadingDetails routeSummary={routeSummary} isSnowPlowRoute={isSnowPlowRoute} />
        </PageActionsSmall>
      </PageHeader>
      <Panel>
        <PanelSection>
          <SnowSweeperRouteSummaryDetails
            isDriverExperienceSimple={isDriverExperienceSimple}
            isNoRoute={isNoRoute}
            onDateChange={onDateChange}
          />
        </PanelSection>
      </Panel>
      <PanelSection margin="xxSmall no">
        <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }} onResize={handleResizeMap}>
          <StreetNetworkMapWrapper ref={wrapperRef} height={mapHeight}>
            <StreetNetworkMapGL
              hasFilteredSegments={routeSegments.length !== filteredSegmentsUniq.length}
              isDailyRoute
              isDriverExperienceComplex={isDriverExperienceComplex}
              isNoRoute={isNoRoute}
              isSnowPlowRoute={isSnowPlowRoute}
              isStreetSweeperRoute={isStreetSweeperRoute}
              newSegments={newSegments}
              numberOfRouteSegments={routeSummary ? routeSummary.routeSegmentCount : undefined}
              onHandleSubmitFilters={onHandleSubmitFilters}
              routeId={routeSummary ? routeSummary.routeId : undefined}
              routeSegments={filteredSegmentsUniq}
              routeStatusTypeId={routeSummary ? routeSummary.routeStatusTypeId : undefined}
              selectedSegments={selectedSegments}
              setSelectedSegments={setSelectedSegments}
              routeName={routeSummary ? routeSummary.name : undefined}
              routeDate={routeSummary ? routeSummary.date : undefined}
            />
          </StreetNetworkMapWrapper>
        </Resizable>
      </PanelSection>
      <Panel isLoading={isLoading}>
        {isRouteStopsLoaded || isCreateNewRoute ? (
          <>
            <Grid>
              <GridColumn size="9/12">
                <RouteStopsSearchForm
                  onSearchTermChange={onSearchTermChange}
                  onPickupStatusChange={onPickupStatusChange}
                  isSnowPlowRoute={isSnowPlowRoute}
                  isStreetSweeperRoute={isStreetSweeperRoute}
                  isDriverExperienceSimple={isDriverExperienceSimple}
                />
              </GridColumn>
              <GridColumn align="right" size="3/12">
                {!isSupport &&
                  !isViewOnly &&
                  (isEditSegmentsModeOn ? (
                    <>
                      <Button margin="xSmall" color="secondary" onClick={handleCancel}>
                        {translate('common.cancel')}
                      </Button>
                      <Button
                        margin="xSmall no"
                        disabled={!(newSegments.length || deletedSegments.length || filteredSegmentsUniq.length)}
                        onClick={handleSubmit}
                        color="primary"
                      >
                        {translate('common.save')}
                      </Button>
                    </>
                  ) : (
                    <PopoverWrapper
                      triggerButton={
                        <Button
                          margin="xSmall no"
                          type="button"
                          color="primary"
                          disabled={
                            (!isSnowPlowFeatureEnabled && isSnowPlowRoute) ||
                            (!isStreetSweeperFeatureEnabled && isStreetSweeperRoute) ||
                            (!!travelPathStatusDetails && travelPathStatusDetails.inProgress) ||
                            isEditRouteGeoFenceModeOn
                          }
                          onClick={() => {
                            dispatch(setIsEditSegmentsModeOn(true));
                          }}
                        >
                          {translate('routes.snowPlow.editSegments')}
                        </Button>
                      }
                      popoverContent={
                        (!isSnowPlowFeatureEnabled && isSnowPlowRoute) ||
                        (!isStreetSweeperFeatureEnabled && isStreetSweeperRoute) ? (
                          <Popover>{translate('routes.alertMessages.featureNotEnabled')}</Popover>
                        ) : !!travelPathStatusDetails && travelPathStatusDetails.inProgress ? (
                          <Popover>
                            <Text block weight="medium" margin="xxSmall no xxSmall">
                              {translate('routes.travelPath.alertMessages.cantMakeChanges')}
                            </Text>
                          </Popover>
                        ) : null
                      }
                      size="large"
                    />
                  ))}
              </GridColumn>
            </Grid>
            <Grid centered>
              {isLoading ? (
                <Message padding="medium">{translate('routes.snowPlow.loadingSegments')}</Message>
              ) : filteredSegmentsUniq.length ? (
                <Table
                  cells={isSnowPlowRoute ? snowPlowRouteStopsTableCells : streetSweeperRouteStopsTableCells}
                  rowComponent={isSnowPlowRoute ? SnowPlowSegmentsTableRow : StreetSweeperSegmentsTableRow}
                  rows={filteredSegmentsUniq}
                  rowProps={{
                    totalRows: filteredSegmentsUniq.length,
                    rowHeight: TABLE_ROW_HEIGHT_LARGE,
                    selectSegment: handleSelectSegment,
                    selectedSegments,
                    deleteSegment: handleDeleteSingleSegment,
                    routeSegments,
                    setRouteSegments,
                  }}
                  sort={handleOnSortChanged}
                  virtualized
                  virtualizedProps={virtualizedProps}
                  scrollMarker
                  noOverflow
                  withClickableRows
                  rowScrollTopPosition={rowScrollTopPosition}
                />
              ) : !filteredSegmentsUniq.length && routeSegments.length ? (
                <Message padding="medium">{translate('routes.snowPlow.noSegmentsMatchCriteria')}</Message>
              ) : (
                <Message padding="medium">{translate('routes.noRouteStreetSegmentsAdded')}</Message>
              )}
            </Grid>
          </>
        ) : (
          <PanelSection padding="large no" centered>
            <Text onClick={() => handleLoadRouteStops()} size="large" cursor="pointer">
              {getTextWithAccentSections(translate(`routes.loadRouteSegments`))}
            </Text>
          </PanelSection>
        )}
      </Panel>

      {!!selectedSegments.length && (
        <PanelSection centered padding="no">
          <PageFooter>
            <Button
              id="clear-selection-button"
              color="alert"
              line
              onClick={handleClearSelection}
              margin="no small no no"
            >
              {translate('common.clearSelection')}
            </Button>

            <Button
              id="remove-segments--button"
              color="primary"
              line
              disabled={!segmentsDeletedFromRoute}
              onClick={() => handleRemoveSegments(segmentsDeletedFromRoute)}
              margin="no small no no"
            >
              {`${translate('routes.removeFromRoute')} (${segmentsDeletedFromRoute})`}
            </Button>

            <Button
              id="add-to-route-button"
              disabled={!segmentsAddedToRoute}
              color="primary"
              line
              onClick={() => handleAddSegments(segmentsAddedToRoute)}
              margin="no small no no"
            >
              {`${translate('routes.addToRoute')} (${segmentsAddedToRoute})`}
            </Button>
            <PopoverWrapper
              size="large"
              triggerButton={
                <Button
                  id="transfer-segments-button"
                  color="primary"
                  line
                  disabled={
                    !selectedSegments.filter(segmentId =>
                      newSegmentIds.length ? !newSegmentIds.includes(segmentId) : routeSegmentIds.includes(segmentId),
                    ).length ||
                    !!newSegmentIds.length ||
                    !!deletedSegments.length ||
                    hasTransferredSegments
                  }
                  onClick={selectStopsToTransfer}
                  margin="no small no no"
                >
                  {`${translate('routes.transferSegments')} (${
                    selectedSegments.filter(segmentId =>
                      newSegmentIds.length ? !newSegmentIds.includes(segmentId) : routeSegmentIds.includes(segmentId),
                    ).length
                  })`}
                </Button>
              }
              popoverContent={
                (!!newSegmentIds.length ||
                  !!deletedSegments.length ||
                  routeSegments.length !== routeLocations?.length ||
                  hasTransferredSegments) && (
                  <Popover>
                    <Text block weight="medium" margin="xxSmall no xxSmall">
                      {translate('routes.alertMessages.routeSegmentsDirtyForm')}
                    </Text>
                  </Popover>
                )
              }
            />
          </PageFooter>
          {isTransferSegmentsModalOpen && (
            <TransferRouteStopsModal
              isSnowPlowRoute={isSnowPlowRoute}
              isStreetSweeperRoute={isStreetSweeperRoute}
              transferRouteSegments={handleTransferRouteSegments}
              closeModal={() => setTransferSegmentsModalOpen(false)}
            />
          )}
        </PanelSection>
      )}

      {!!isRouteStopIssuesModalOpen && !!routeLocationId && !!routeSummary && (
        <RouteLocationIssuesModalResolver
          modalTitle={selectedSegment.customerName}
          modalSubTitle={selectedSegment.name}
          routeId={routeSummary.routeId}
          routeLocationId={routeLocationId}
          closeModal={() => setIsRouteStopIssuesModalOpen(false)}
          wasteAuditType={wasteAuditType}
          vehicleTypeId={routeSummary.vehicleTypeId}
        />
      )}

      {!!isRouteImagesModalOpen && !!shouldOpenImagesModal && (
        <RouteLocationImagesModalResolver
          routeId={routeSummary?.routeId}
          closeModal={() => setIsRouteImagesModalOpen(false)}
          routeLocationId={routeLocationId}
        />
      )}
    </PageContent>
  );
};

export default SnowOrSweeperRoutePage;
