import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

const CUBICYARD = 'CUBICYARD';
const EACH = 'EACH';
const GALLON = 'GALLON';
const LITRES = 'LITRES';
const UNIT = 'UNIT';

export const TON_ID = 1;
export const POUND_ID = 2;
export const OUNCE_ID = 3;
export const KILOGRAM_ID = 4;
export const LITER_ID = 5;
export const GALLON_ID = 6;
export const BUCKET_ID = 7;
export const LOAD_ID = 8;
export const FILLUP_ID = 9;
export const SCOOP_ID = 10;
export const CUBIC_YARDS_ID = 11;
export const MILE_ID = 12;
export const YARD_ID = 13;
export const FOOT_ID = 14;
export const BIMONTH_ID = 15;
export const DAY_ID = 16;
export const EACH_ID = 17;
export const MONTH_ID = 18;
export const ONE_TIME_ID = 19;

export const UNITS = mapKeys(
  [
    { id: CUBICYARD, name: translate('common.units.cubicYard') },
    { id: EACH, name: translate('common.units.each') },
    { id: GALLON, name: translate('common.units.gallon') },
    { id: LITRES, name: translate('common.units.litres') },
    { id: UNIT, name: translate('common.units.unit') },
  ],
  'id',
);

export const UNITS_FULL = mapKeys(
  [
    { id: TON_ID, name: translate('common.unitsOfMeasurement.ton') },
    { id: POUND_ID, name: translate('common.unitsOfMeasurement.pound') },
    { id: OUNCE_ID, name: translate('common.unitsOfMeasurement.ounce') },
    { id: KILOGRAM_ID, name: translate('common.unitsOfMeasurement.kilogram') },
    { id: LITER_ID, name: translate('common.unitsOfMeasurement.liter') },
    { id: GALLON_ID, name: translate('common.unitsOfMeasurement.gallon') },
    { id: BUCKET_ID, name: translate('common.unitsOfMeasurement.bucket') },
    { id: LOAD_ID, name: translate('common.unitsOfMeasurement.load') },
    { id: FILLUP_ID, name: translate('common.unitsOfMeasurement.fillUp') },
    { id: SCOOP_ID, name: translate('common.unitsOfMeasurement.scoop') },
    { id: CUBIC_YARDS_ID, name: translate('common.unitsOfMeasurement.cubicYards') },
    { id: MILE_ID, name: translate('common.unitsOfMeasurement.mile') },
    { id: YARD_ID, name: translate('common.unitsOfMeasurement.yard') },
    { id: FOOT_ID, name: translate('common.unitsOfMeasurement.foot') },
    { id: BIMONTH_ID, name: translate('common.unitsOfMeasurement.bimonth') },
    { id: DAY_ID, name: translate('common.unitsOfMeasurement.day') },
    { id: EACH_ID, name: translate('common.unitsOfMeasurement.each') },
    { id: MONTH_ID, name: translate('common.unitsOfMeasurement.month') },
    { id: ONE_TIME_ID, name: translate('common.unitsOfMeasurement.oneTime') },
  ],
  'id',
);
