import React, { useEffect, useRef } from 'react';

import { Button } from 'src/core/components/styled';
import { currentUserId, currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { MessageInput } from './MessageInput';
import { MessageItem } from './MessageItem';
import {
  MessageListContainer,
  MessagingActions,
  MessagingContentContainer,
  MessagingHeader,
  MessagingHeaderContainer,
} from '../styles/MessagingContainer';
import { MessageRecipient } from './MessageRecipient';
import { MessageType } from '../interfaces/MessateType';
import {
  loadThreadMessages,
  readMessage,
  resetActiveThreadMessages,
  sendNewMessage,
  setSendNewMessage,
} from '../ducks/messaging';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/core/hooks/useSelector';
import { useState } from 'react';
import translate from 'src/core/services/translate';
import { loadFiltersSettings } from 'src/common/ducks';

export const MessageComposer: React.FC = () => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const userId = useSelector(currentUserId);

  const threads = useSelector(s => s.messaging.messageThreads);

  const messageListRef = useRef<HTMLDivElement>(null);

  const [messageTypeId, setMessageType] = useState<MessageType>(0);
  const [messageRecipient, setMessageRecipient] = useState<number>(0);

  const [messageText, setMessageText] = useState('');

  const [sendingFailed, setSendingFailed] = useState(false);

  const messages = useSelector(s => s.messaging.activeThreadMessages);

  const toDriverId =
    messageTypeId === MessageType.All ||
    messageTypeId === MessageType.Route ||
    messageTypeId === MessageType.VehicleType
      ? undefined
      : messageRecipient;

  const routeId =
    messageTypeId === MessageType.All ||
    messageTypeId === MessageType.Direct ||
    messageTypeId === MessageType.VehicleType
      ? undefined
      : messageRecipient;

  const vehicleTypeId =
    messageTypeId === MessageType.All || messageTypeId === MessageType.Direct || messageTypeId === MessageType.Route
      ? undefined
      : messageRecipient;

  const goToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  const sendMessage = (message: string) => {
    if (sendingFailed) {
      setSendingFailed(false);
    }
    setMessageText(message);
    sendNewMessage({
      vendorId,
      messageTypeId,
      messageText: message,
      toDriverId,
      routeId,
      vehicleTypeId,
    })(dispatch).catch(() => setSendingFailed(true));
  };

  const disabledSendButton = !messageTypeId || (messageTypeId !== MessageType.All && !messageRecipient);

  useEffect(() => {
    goToBottom();
  }, [messageText, sendingFailed]);

  useEffect(() => {
    const lastUnreadMessage = messages.filter(message => !message.isMessageRead && !message.fromUserId).slice(-1)[0];
    if (lastUnreadMessage) readMessage(lastUnreadMessage)(dispatch);
  }, [messages, dispatch]);

  useEffect(() => {
    if (!disabledSendButton) {
      const thread = threads.find(t => {
        if (t.messageTypeId === messageTypeId) {
          if (!messageRecipient) return true;
          if (messageTypeId === MessageType.Direct && messageRecipient === t.driverId) return true;
          if (messageTypeId === MessageType.Route && messageRecipient === t.routeId) return true;
          if (messageTypeId === MessageType.VehicleType && messageRecipient === t.vehicleTypeId) return true;
        }
        return false;
      });
      if (thread) {
        loadThreadMessages(vendorId, thread.threadId)(dispatch).then(() => goToBottom());
      } else {
        resetActiveThreadMessages()(dispatch);
      }
    } else {
      resetActiveThreadMessages()(dispatch);
    }
  }, [disabledSendButton, dispatch, messageRecipient, messageTypeId, threads, vendorId]);

  useEffect(() => {
    if (userId) loadFiltersSettings(vendorId, userId)(dispatch);
  }, [dispatch, vendorId, userId]);

  return (
    <>
      <MessagingHeaderContainer>
        <MessagingHeader>{translate('messaging.newMessageTo')}</MessagingHeader>

        <MessageRecipient
          messageRecipient={messageRecipient}
          messageType={messageTypeId}
          onChange={(mt, mr) => {
            setMessageType(mt);
            setMessageRecipient(mr);
            setMessageText('');
            setSendingFailed(false);
          }}
        />

        <Button margin="xSmall no xSmall auto" onClick={() => dispatch(setSendNewMessage(false))} text color="warning">
          {translate('messaging.dismiss')}
        </Button>
      </MessagingHeaderContainer>

      <MessagingContentContainer ref={messageListRef}>
        <MessageListContainer>
          {messages.map(m => (
            <MessageItem key={m.messageId} message={m} />
          ))}
          {messageText && (
            <MessageItem
              onRetry={sendingFailed ? () => sendMessage(messageText) : undefined}
              key="newMessage"
              message={{ messageText, isLoading: true }}
            />
          )}
        </MessageListContainer>
      </MessagingContentContainer>

      <MessagingActions>
        <MessageInput
          disabledSendButton={disabledSendButton}
          disabledTextArea={!!messageText}
          onSendMessage={sendMessage}
        />
      </MessagingActions>
    </>
  );
};
