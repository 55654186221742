import { ChangeEvent } from 'react';

import { DropDownFieldProps } from 'src/common/components/DropDownFieldProps';
import { Dropdown, TypedField } from 'src/core/components';
import translate from 'src/core/services/translate';
import { useSelector } from 'src/core/hooks/useSelector';

interface UOMTypeDropdownProps extends DropDownFieldProps {
  byName?: boolean;
  margin?: string;
  uomsByRateCode?: number[];
}

const UOMDropdown = (props: UOMTypeDropdownProps) => {
  const {
    byName,
    dropdownProps,
    input: { name, onChange: inputOnChange },
    label,
    margin,
    placeholder,
    uomsByRateCode,
    withLabel,
    withPlaceholder,
  } = props;

  const UOMs = useSelector(state => state.common.unitOfMeasureTypes.unitOfMeasureTypes);
  const currrenUOMs = UOMs.filter(uom => uomsByRateCode?.find(uomByRateCode => uomByRateCode === uom.id));

  const onChange = (
    _event: ChangeEvent<HTMLInputElement>,
    value: string | undefined,
    _previousValue: string | undefined,
    fieldName: string,
  ) => {
    inputOnChange({ fieldName, value });
  };

  const uomOptions = currrenUOMs
    ? currrenUOMs.map(uom => ({
        label: uom.name,
        value: byName ? uom.technicalName : uom.id,
      }))
    : [];

  return (
    <TypedField
      name={name}
      component={Dropdown}
      onChange={onChange}
      props={{
        width: '100%',
        label: label || (withLabel ? translate('finance.modal.uom') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('finance.modal.uom') : undefined),
        options: uomOptions,
        menuPosition: 'fixed',
        margin: margin,
        ...dropdownProps,
      }}
    />
  );
};

export default UOMDropdown;
