import moment from 'moment';
import React from 'react';

import { ActionButtonTooltip } from 'src/core/components';
import { CANCELLED_ID, COMPLETED_ID, FAILED_ID, IN_PROGRESS_ID, QUEUED_ID } from '../constants/dailyReportTypes';
import { downloadReport } from 'src/insights/services/exportReports';
import { EditReportRecipientsModal } from './modals/index';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import translate from 'src/core/services/translate';

interface Props {
  date: string;
  downloadUrl: string;
  emailAddresses: string[];
  emailsCount: number;
  fileName: string;
  id: number;
  recordsCount: number;
  reportType: any;
  status: any;
  vendorId: number;
}

const RecentExportsTableRow: React.FC<Props> = ({
  date,
  downloadUrl,
  emailAddresses,
  emailsCount,
  fileName,
  id,
  recordsCount,
  reportType: { technicalName },
  status: { id: statusId },
  vendorId,
}) => {
  const [isEditRecipientsModalOpen, setIsEditRecipientsModalOpen] = React.useState<boolean>(false);

  const openModal = () => setIsEditRecipientsModalOpen(true);
  const closeModal = () => setIsEditRecipientsModalOpen(false);

  const exportReport = (downloadUrl: string, fileName: string) => {
    downloadReport(downloadUrl, fileName);
  };

  const getStatus = (statusId: number) => {
    switch (statusId) {
      case CANCELLED_ID:
        return translate('dashboard.cancelled');
      case COMPLETED_ID:
        return translate('insights.dailyReportTypes.status.completed');
      case FAILED_ID:
        return translate('insights.dailyReportTypes.status.failed');
      case IN_PROGRESS_ID:
        return translate('insights.dailyReportTypes.status.inProgress');
      case QUEUED_ID:
        return translate('insights.dailyReportTypes.status.queued');
      default:
        return translate('insights.dailyReportTypes.status.queued');
    }
  };

  const reportName = translate(createTranslationKey(technicalName, 'insights.dailyReportTypes'));

  return (
    <>
      <TableRow>
        <TableCell hasLeftBorder width="28%">
          {fileName || '-'}
        </TableCell>
        <TableCell width="21%">{reportName}</TableCell>
        <TableCell width="13%">{getStatus(statusId)}</TableCell>
        <TableCell width="13%">{recordsCount || '-'}</TableCell>
        <TableCell width="15%">{moment(date).format('MM/DD/YYYY')}</TableCell>
        <TableCell width="10%">
          {downloadUrl && (
            <TableActionButton onClick={() => exportReport(downloadUrl, `${fileName || 'Download'}`)}>
              <ActionButtonTooltip size="sMedium" icon="download" tooltip={'downloadReport'} />
            </TableActionButton>
          )}
          <TableActionButton onClick={openModal}>
            <ActionButtonTooltip margin="no xxSmall no no" size="sMedium" icon="email" tooltip="editRecipients" />
            <Text size="xSmall">({emailsCount})</Text>
          </TableActionButton>
        </TableCell>
      </TableRow>

      {isEditRecipientsModalOpen && (
        <EditReportRecipientsModal emails={emailAddresses} vendorId={vendorId} id={id} closeModal={closeModal} />
      )}
    </>
  );
};

export default RecentExportsTableRow;
