import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { DuckFunction } from '../../../contracts/ducks';
import { Modal } from '../../../core/components';
import { saveDispatchBoardUnassignedJobRouteNote } from '../../ducks/dispatchBoard';
import { UnassignedJobsRouteNoteForm } from '../../../routes/components/forms';
import translate from '../../../core/services/translate';

interface Props {
  closeModal: () => void;
  jobId: number;
  saveDispatchBoardUnassignedJobRouteNote: DuckFunction<typeof saveDispatchBoardUnassignedJobRouteNote>;
}

class DispatchBoardUnassignedJobsNoteModal extends PureComponent<Props> {
  saveRouteNote = (formData: any) => {
    const { jobId, saveDispatchBoardUnassignedJobRouteNote, closeModal } = this.props;
    saveDispatchBoardUnassignedJobRouteNote(jobId, formData.routeNote)
      .then(() => {
        createSuccessNotification(`${translate('routes.alertMessages.routeSaved')}`);
        closeModal();
      })
      .catch(() => createErrorNotification(`${translate('routes.alertMessages.routeSaveError')}`));
  };

  render() {
    const { closeModal } = this.props;
    return (
      <Modal title={translate('routes.routeNotes')} onClose={closeModal} padding="medium" verticalSize="medium">
        <UnassignedJobsRouteNoteForm onSubmit={this.saveRouteNote} closeModal={closeModal} />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  saveDispatchBoardUnassignedJobRouteNote,
};

export default connect(undefined, mapDispatchToProps)(DispatchBoardUnassignedJobsNoteModal);
