import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import {
  loadRouteSummary as doLoadRouteSummary,
  loadSnowSweeperRouteSummary as doLoadSnowSweeperRouteSummary,
} from '../services/routeSummary';
import { RouteSummary } from '../interfaces/RouteSummary';

const START_LOAD = 'routes/routeSummary/START_LOAD';
const COMPLETE_LOAD = 'routes/routeSummary/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeSummary/FAIL_LOAD';
const START_LOAD_SNOW_SWEEPER_SUMMARY = 'routes/routeSummary/START_LOAD_SNOW_SWEEPER_SUMMARY';
const COMPLETE_LOAD_SNOW_SWEEPER_SUMMARY = 'routes/routeSummary/COMPLETE_LOAD_SNOW_SWEEPER_SUMMARY';
const FAIL_LOAD_SNOW_SWEEPER_SUMMARY = 'routes/routeSummary/FAIL_LOAD_SNOW_SWEEPER_SUMMARY';
const RESET = 'routes/routeSummary/RESET';

interface State {
  isLoading: boolean;
  routeSummary?: RouteSummary;
}

const initialState: State = {
  isLoading: false,
  routeSummary: undefined,
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeSummary: action.routeSummary,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeSummary: undefined,
        },
      });

    case START_LOAD_SNOW_SWEEPER_SUMMARY:
      return update(state, {
        $merge: {
          isLoading: !!action.noLoadingIndicator ? false : true,
        },
      });

    case COMPLETE_LOAD_SNOW_SWEEPER_SUMMARY:
      return update(state, {
        $merge: {
          isLoading: false,
          routeSummary: action.routeSummary,
        },
      });

    case FAIL_LOAD_SNOW_SWEEPER_SUMMARY:
      return update(state, {
        $merge: {
          isLoading: false,
          routeSummary: undefined,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeSummary: RouteSummary) => ({
  type: COMPLETE_LOAD,
  routeSummary,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadSnowSweeperSummary = (noLoadingIndicator?: boolean) => ({
  type: START_LOAD_SNOW_SWEEPER_SUMMARY,
  noLoadingIndicator,
});

const completeLoadSnowSweeperSummary = (routeSummary: RouteSummary) => ({
  type: COMPLETE_LOAD_SNOW_SWEEPER_SUMMARY,
  routeSummary,
});

const failLoadSnowSweeperSummary = () => ({
  type: FAIL_LOAD_SNOW_SWEEPER_SUMMARY,
});

export const loadRouteSummary = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRouteSummaryPromise = doLoadRouteSummary(vendorId, routeId);
  loadRouteSummaryPromise.then(routeSummary => dispatch(completeLoad(routeSummary))).catch(() => dispatch(failLoad()));
  return loadRouteSummaryPromise;
};

export const loadSnowSweeperRouteSummary =
  (
    vendorId: number,
    routeId: number,
    startDate?: Date | string,
    endDate?: Date | string,
    noLoadingIndicator?: boolean,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoadSnowSweeperSummary(noLoadingIndicator));

    const loadSnowSweeperRouteSummaryPromise = doLoadSnowSweeperRouteSummary(vendorId, routeId, startDate, endDate);
    loadSnowSweeperRouteSummaryPromise
      .then(routeSummary => dispatch(completeLoadSnowSweeperSummary(routeSummary)))
      .catch(() => dispatch(failLoadSnowSweeperSummary()));
    return loadSnowSweeperRouteSummaryPromise;
  };

export const resetRouteSummary = () => ({
  type: RESET,
});
