import { debounce } from 'lodash-es';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { PanelSearch, TypedField } from 'src/core/components';
import translate from 'src/core/services/translate';

interface PropsWithoutReduxForm {
  onSearchTermChange: (searchTerm: string) => void;
}

interface FormData {
  searchTerm?: string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormData, PropsWithoutReduxForm>;

const RoutesOrTemplatesSearchForm = (props: Props) => {
  const { handleSubmit, onSearchTermChange } = props;

  const onSearchTermChangeHandler = debounce((event: any, searchTerm: string) => {
    onSearchTermChange(searchTerm);
  }, 300);

  return (
    <form onSubmit={handleSubmit}>
      <TypedField
        name="searchTerm"
        component={PanelSearch}
        onChange={onSearchTermChangeHandler}
        props={{
          margin: 'no',
          isClearable: true,
          placeholder: translate('common.search'),
        }}
      />
    </form>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  enableReinitialize: true,
  form: 'routesOrTemplatesSearchForm',
  initialValues: {
    searchTerm: '',
  },
})(RoutesOrTemplatesSearchForm);
