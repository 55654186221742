import { AnyLayout } from 'mapbox-gl';
import { FC, memo } from 'react';
import { Layer } from 'react-map-gl';

import { getAlertLayout, getAlertPaint } from './utils';
import {
  LOCATION_AND_CITY_ALERTS_CLUSTERS_SOURCE,
  LOCATION_AND_CITY_ALERTS_LAYER,
} from 'src/fleet/constants/locationAndCityAlerts';

const LocationAndCityAlertsGLLayer: FC = memo(() => (
  <Layer
    id={LOCATION_AND_CITY_ALERTS_LAYER}
    type="symbol"
    source={LOCATION_AND_CITY_ALERTS_CLUSTERS_SOURCE}
    filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], LOCATION_AND_CITY_ALERTS_LAYER]]}
    paint={getAlertPaint()}
    layout={getAlertLayout(['get', 'icon'], ['get', 'count']) as AnyLayout}
  />
));

export default LocationAndCityAlertsGLLayer;
