import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { loadRouteMapCityInsights as doLoadRouteMapCityInsights } from '../services/routeMapCityInsights';
import {
  RouteMapCityInsight,
  RouteMapCityInsightFilter,
  RouteMapCityInsightsData,
} from '../interfaces/RouteMapCityInsights';

const START_LOAD = 'routes/routeMapCityInsights/START_LOAD';
const COMPLETE_LOAD = 'routes/routeMapCityInsights/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeMapCityInsights/FAIL_LOAD';
const RESET = 'routes/routeMapCityInsights/RESET';

interface State {
  cityInsights: RouteMapCityInsight[];
  filters: RouteMapCityInsightFilter[];
  isLoading: boolean;
}

const initialState: State = {
  cityInsights: [],
  filters: [],
  isLoading: false,
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: action.noLoading ? false : true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          cityInsights: action.routeMapCityInsights,
          filters: action.filters,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          cityInsights: [],
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = (noLoading?: boolean) => ({
  type: START_LOAD,
  noLoading,
});

const completeLoad = (routeMapCityInsights: RouteMapCityInsightsData) => ({
  type: COMPLETE_LOAD,
  routeMapCityInsights: routeMapCityInsights.records,
  filters: routeMapCityInsights.filters,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRouteMapCityInsights =
  (vendorId: number, routeId: number, cityInsights?: number[], noLoading?: boolean) => (dispatch: Dispatch) => {
    dispatch(startLoad(noLoading));
    const loadRouteMapCityInsights = doLoadRouteMapCityInsights(vendorId, routeId, cityInsights);
    loadRouteMapCityInsights
      .then(routeMapCityInsights => dispatch(completeLoad(routeMapCityInsights)))
      .catch(() => dispatch(failLoad()));
    return loadRouteMapCityInsights;
  };

export const resetRouteMapCityInsights = () => ({
  type: RESET,
});
