import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import { PureComponent, Fragment } from 'react';
import { push } from 'connected-react-router';
import { RouteComponentProps } from 'react-router';
import { map, size } from 'lodash-es';

import { AppState } from '../../../store';
import { ActionButtonTooltip, PopoverWrapper } from '../../../core/components';
import {
  ActionButtonContainer,
  ClassificationsContainer,
  ClassificationsDetails,
  SignDocumentButtonContainer,
} from '../styled/HaulerProfileOverview';
import {
  Button,
  Grid,
  Panel,
  Message,
  PanelSectionTitle,
  PanelSectionGroup,
  Popover,
  Text,
} from '../../../core/components/styled';

import { COI, COMPLIANCE, SC } from '../../constants/documentTypesOption';
import { COMPLIANT, NON_COMPLIANT } from '../../constants/compilanceStatus';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentUserIdSelector, currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { date as formatDate } from '../../../utils/services/formatter';
import defaultDivisionFormInitialValuesSelector from '../../services/defaultDivisionFormInitialValuesSelector';
import { Districts } from 'src/vendors/interfaces/DivisionAccess';
import DivisionForm from '../forms/DivisionForm';
import { DuckFunction } from '../../../contracts/ducks';
import { HaulerProfileInvalidGusIdMessage } from '..';
import {
  HAULER_PROFILE_DIVISION_COOKIE,
  HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION,
} from '../../constants/HaulerProfileDivisionCookie';
import { loadCOIStatus } from '../../ducks/compliance';
import { PageContent, PageDetails, PageHeader, PageTitle, PageTitleContainer } from '../../../common/components/styled';
import { saveFile, uploadSelfCertification, loadCompliance } from '../../ducks';
import { SignDocumentModal, UploadFileModal, ViewCertificationsModal, ViewErrorsModal } from '../modals';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  compliance: any;
  complianceStatus: any;
  divisions: Districts[];
  formInitialValues: any;
  gusId: string;
  isLoading: boolean;
  isValidGusId: boolean;
  userId: string;
  loadCompliance: DuckFunction<typeof loadCompliance>;
  loadCOIStatus: DuckFunction<typeof loadCOIStatus>;
  push: typeof push;
  saveFile: DuckFunction<typeof saveFile>;
  uploadSelfCertification: DuckFunction<typeof uploadSelfCertification>;
}

interface State {
  documentList: any[];
  documentType: string;
  errorsList: any[];
  isErrorsModalOpen: boolean;
  isFileUpload: boolean;
  isSignDocumentModal: boolean;
  isUploadModalOpen: boolean;
  isViewModalOpen: boolean;
  selfCertificationDocumentUrl: string;
}

class CompliancePage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      documentList: [],
      documentType: '',
      errorsList: [],
      isErrorsModalOpen: false,
      isFileUpload: false,
      isSignDocumentModal: false,
      isUploadModalOpen: false,
      isViewModalOpen: false,
      selfCertificationDocumentUrl: props.compliance
        ? props.compliance.filter((complianceObj: any) => complianceObj.documentType === SC)[0]
            .selfCertificationDocumentUrl
        : '',
    };
  }

  onDivisionChange = (divisionId: string) => {
    Cookie.set(HAULER_PROFILE_DIVISION_COOKIE, divisionId, { expires: HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION });
    window.history.pushState(null, '', `?divisionId=${divisionId}`);
    this.refreshComplianceData(divisionId);
  };

  refreshComplianceData = (divisionId: string) => {
    const { gusId, loadCompliance, loadCOIStatus } = this.props;
    loadCompliance(gusId, divisionId);
    loadCOIStatus(gusId, divisionId);
  };

  openViewModal = (documentType: string, documentList: any[]) => {
    this.setState({ isViewModalOpen: true, documentType: documentType, documentList: documentList });
  };

  closeViewModal = (documentDeleted: boolean) => {
    const { formInitialValues, gusId, loadCompliance } = this.props;
    this.setState({ isViewModalOpen: false });
    const divisionId = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE) || formInitialValues.divisionId;
    documentDeleted && loadCompliance(gusId, divisionId);
  };

  openErrorsModal = (documentType: string, errorsList: any[]) => {
    this.setState({ isErrorsModalOpen: true, documentType: documentType, errorsList: errorsList });
  };

  closeErrorsModal = () => {
    this.setState({ isErrorsModalOpen: false });
  };

  openUploadModal = (documentType: string) => {
    this.setState({ isUploadModalOpen: true, documentType: documentType });
  };

  closeUploadFileModal = () => {
    this.setState({ isUploadModalOpen: false, documentType: '' });
  };

  openSignDocumentModal = () => {
    this.setState({ isSignDocumentModal: true });
  };

  closeSignDocumentModal = () => {
    this.setState({ isSignDocumentModal: false });
  };

  signDocumentSubmit = async (formData: any) => {
    const { formInitialValues, gusId, userId, loadCompliance, uploadSelfCertification } = this.props;
    const { name, title } = formData;
    const divisionId = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE) || formInitialValues.divisionId;
    await uploadSelfCertification(userId, gusId, name, title, divisionId)
      .then(() => {
        this.closeSignDocumentModal();
        createSuccessNotification('Document signed successfully!');
      })
      .catch((error: any) => createErrorNotification(`Error occurred: ${error}`));
    loadCompliance(gusId, divisionId);
  };

  submitUploadFile = async (data: any) => {
    const { formInitialValues, gusId, userId, saveFile, loadCompliance } = this.props;
    const { documentType } = this.state;
    const divisionId = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE) || formInitialValues.divisionId;
    await saveFile({
      data,
      documentType,
      vendorGroupId: gusId,
      userId,
      isFileUpload: data.file.length > 0 || false,
      divisionId,
    })
      .then(() => {
        this.closeUploadFileModal();
        createSuccessNotification('Files uploaded successfully!');
      })
      .catch((error: any) => createErrorNotification(`File upload fail: ${error}`));
    loadCompliance(gusId, divisionId);
  };

  renderCOIStatus = (complianceStatus: any) => {
    return map(complianceStatus, (obj: any, index: number) => (
      <Fragment key={index}>
        {!!obj.status && (
          <ClassificationsContainer category="coiStatus">
            <ActionButtonContainer category="coiStatus">
              <ActionButtonTooltip
                color={obj.status === COMPLIANT ? 'primary' : obj.status === NON_COMPLIANT ? 'alert' : 'warning'}
                customViewBox="0 0 18 18"
                icon={
                  obj.status === COMPLIANT
                    ? 'page-check'
                    : obj.status === NON_COMPLIANT
                    ? 'page-check-error'
                    : 'page-content'
                }
                size="mMedium"
                tooltip={
                  obj.status === COMPLIANT ? 'compliant' : obj.status === NON_COMPLIANT ? 'nonCompliant' : 'pendingCOI'
                }
              />
            </ActionButtonContainer>
            <ClassificationsDetails category="coiStatus">
              <Text block size="medium" lineHeight="24px">
                {obj.displayTitle}
              </Text>
            </ClassificationsDetails>
          </ClassificationsContainer>
        )}
      </Fragment>
    ));
  };
  render() {
    const { isLoading, compliance, complianceStatus, isValidGusId, divisions, formInitialValues } = this.props;
    const {
      documentList,
      documentType,
      errorsList,
      isErrorsModalOpen,
      isSignDocumentModal,
      isUploadModalOpen,
      isViewModalOpen,
      selfCertificationDocumentUrl,
    } = this.state;
    const divisionId = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE) || formInitialValues.divisionId;
    return (
      <PageContent>
        <PageHeader>
          <PageDetails>
            <PageTitleContainer>
              <PageTitle>{translate('haulerProfile.compliance')}</PageTitle>
              <DivisionForm
                initialValues={formInitialValues}
                divisions={divisions}
                onDivisionChange={this.onDivisionChange}
              />
            </PageTitleContainer>
          </PageDetails>
        </PageHeader>

        <Panel padding={isValidGusId ? 'medium' : 'no'} margin="no">
          <PanelSectionGroup isLoading={isLoading}>
            {isValidGusId ? (
              <Fragment>
                <PanelSectionTitle>{translate('haulerProfile.documents')}</PanelSectionTitle>

                <Grid multiLine>
                  {!!size(compliance) &&
                    compliance.map((obj: any, index: number) => {
                      return obj.documents ? (
                        <Fragment key={index}>
                          <ClassificationsContainer category="compliance">
                            <ActionButtonContainer category="compliance">
                              <ActionButtonTooltip
                                color={obj.isCertified || obj.isDocumentUploaded ? 'primary' : 'alert'}
                                customViewBox="0 0 18 18"
                                icon={obj.isCertified || obj.isDocumentUploaded ? 'page-check' : 'page-check-error'}
                                size="medium"
                              />
                            </ActionButtonContainer>
                            <ClassificationsDetails category="compliance">
                              <Text block size="xLarge" lineHeight="26px">
                                {obj.displayTitle}
                              </Text>
                            </ClassificationsDetails>
                            <ActionButtonContainer category="compliance">
                              <Button
                                id={`view-uploaded-document-${index}-modal`}
                                text
                                color={obj.documents.length ? 'primary' : 'gray'}
                                padding="small"
                                onClick={() => this.openViewModal(obj.documentType, obj.documents)}
                                disabled={obj.documents.length === 0}
                              >
                                <ActionButtonTooltip
                                  size="medium"
                                  icon="redeye"
                                  tooltip={obj.documents.length ? 'viewDocuments' : ''}
                                />
                              </Button>
                            </ActionButtonContainer>
                            <ActionButtonContainer category="compliance">
                              {obj.documentType !== SC && obj.hasError && (
                                <Button
                                  id={`certification-error-${index}`}
                                  text
                                  color="alert"
                                  padding="small"
                                  size="lMedium"
                                  onClick={() => this.openErrorsModal(obj.documentType, obj.errorDivisions)}
                                >
                                  <ActionButtonTooltip
                                    size="sMedium"
                                    icon="exclamation"
                                    tooltip="errorEncountered"
                                    color="alert"
                                  />
                                </Button>
                              )}
                            </ActionButtonContainer>
                            <SignDocumentButtonContainer>
                              {obj.documentType === SC ? (
                                <Fragment>
                                  <PopoverWrapper
                                    triggerButton={
                                      <Button
                                        color="primary"
                                        size="small"
                                        padding="small"
                                        onClick={this.openSignDocumentModal}
                                        line
                                        disabled={obj.isCertified}
                                      >
                                        {translate('haulerProfile.signDocument')}
                                      </Button>
                                    }
                                    popoverContent={
                                      obj.isCertified && (
                                        <Popover>
                                          <Text block margin="xxSmall no xxSmall">
                                            {translate('haulerProfile.signDocumentButtonDisableMessage')}
                                          </Text>
                                        </Popover>
                                      )
                                    }
                                    size="medium"
                                  />
                                </Fragment>
                              ) : (
                                <Button
                                  color="primary"
                                  size="small"
                                  padding="small"
                                  onClick={() => this.openUploadModal(obj.documentType)}
                                  line
                                >
                                  {translate('haulerProfile.uploadDocument')}
                                </Button>
                              )}
                              {obj.updatedOn && (
                                <Text block margin="xSmall small no no" size="xSmall" color="grayDarker">
                                  {translate('common.updatedOn', { date: formatDate(obj.updatedOn) })}
                                </Text>
                              )}
                            </SignDocumentButtonContainer>
                          </ClassificationsContainer>
                          {obj.documentType === COI ? this.renderCOIStatus(complianceStatus) : undefined}
                        </Fragment>
                      ) : undefined;
                    })}
                </Grid>
              </Fragment>
            ) : !isValidGusId && !divisionId ? (
              <Message padding="sMedium">{translate('common.alertMessages.invalidDivisionId')}</Message>
            ) : (
              <HaulerProfileInvalidGusIdMessage />
            )}
          </PanelSectionGroup>
        </Panel>
        {isViewModalOpen && (
          <ViewCertificationsModal
            closeModal={this.closeViewModal}
            divisionId={divisionId}
            documentList={documentList}
            documentType={documentType}
            isviewCertificateNo={false}
          />
        )}
        {isErrorsModalOpen && (
          <ViewErrorsModal closeModal={this.closeErrorsModal} errorsList={errorsList} documentType={documentType} />
        )}
        {isUploadModalOpen && (
          <UploadFileModal
            closeModal={this.closeUploadFileModal}
            documentType={documentType}
            submitUploadFile={this.submitUploadFile}
            uploadFileSource={COMPLIANCE}
          />
        )}
        {isSignDocumentModal && (
          <SignDocumentModal
            closeModal={this.closeSignDocumentModal}
            submitDocument={this.signDocumentSubmit}
            selfCertificationDocumentUrl={selfCertificationDocumentUrl}
          />
        )}
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  compliance: state.haulerProfile.compliance.compliance,
  complianceStatus: state.haulerProfile.compliance.complianceStatus,
  divisions: state.haulerProfile.divisions.divisions,
  formInitialValues: defaultDivisionFormInitialValuesSelector(state.haulerProfile.divisions),
  gusId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  isLoading: state.haulerProfile.compliance.isLoading,
  isValidGusId: state.haulerProfile.compliance.isValidGusId,
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  saveFile,
  uploadSelfCertification,
  loadCompliance,
  loadCOIStatus,
  push,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompliancePage);
