import { http } from '../../core/services/http';

export const loadVehicleNotes = (vehicleId: number, tripInspectionDetailId: number) =>
  http
    .get(`vehicles/${vehicleId}/tripInspectionIssueStatusNotes`, { params: { tripInspectionDetailId } })
    .then(response => response.data);

export const saveVehicleNote = (
  vehicleId: number,
  tripInspectionDetailId: number,
  vehicleNote: string,
  newIssueStatus: number,
  vehicleNoteId?: number,
) => {
  const action = vehicleNoteId ? http.put : http.post;

  return action(`vehicles/${vehicleId}/tripInspectionIssueStatusNote`, {
    tripInspectionDetailId,
    tripInspectionIssueStatusType: {
      id: newIssueStatus,
    },
    note: vehicleNote,
    id: vehicleNoteId,
  }).then(response => response.data);
};

export const deleteVehicleNote = (vehicleId: number, vehicleNoteId: number) =>
  http.delete(`/vehicles/${vehicleId}/tripInspectionIssueStatusNote`, {
    params: { tripInspectionIssueStatusNoteId: vehicleNoteId },
  });
