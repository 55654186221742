import { useDispatch } from 'react-redux';

import { loadEventLog, loadEventTypes } from 'src/routes/ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import EventLogModal from './EventLogModal';

type Props = {
  closeModal: () => void;
};

export default function EventLogModalResolver(props: Props) {
  const dispatch = useDispatch();
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  if (!routeSummary) return null;

  const loadDependencies = () =>
    Promise.all([loadEventTypes()(dispatch), loadEventLog(routeSummary.routeId)(dispatch)]);

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={EventLogModal}
      successProps={{ ...props, routeSummary }}
    />
  );
}
