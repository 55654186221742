import React, { Fragment } from 'react';

import { getFormValues, reduxForm, InjectedFormProps } from 'redux-form';
import { useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { Button, ButtonSet, PanelSection, Grid, GridColumn, Text } from '../../../core/components/styled';
import { GEO_FENCE_ALERTS } from '../../constants';
import { GeoFenceAlert, TimeField } from '../../interfaces/GeoFenceSettings';
import { GeoFenceAlertSettingWrapper } from '../styled';
import { Input, TypedField, Switch } from '../../../core/components';
import translate from '../../../core/services/translate';

function GeoFenceAlertsSettingsForm({ change, form, handleSubmit }: InjectedFormProps) {
  const formValues: any = useSelector((state: AppState) => getFormValues(form)(state));

  const changeTime = (field: string) => (_: unknown, value: string) => {
    change(field, value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PanelSection vertical padding="small">
        {GEO_FENCE_ALERTS.map((geoFenceAlert: GeoFenceAlert) => (
          <GeoFenceAlertSettingWrapper key={geoFenceAlert.id}>
            <Grid>
              <GridColumn size="9/12" verticalAlign="center" padding="no no small">
                <Text size="large" weight="medium">
                  {translate(`vendors.geoFenceAlerts.${geoFenceAlert.translationKey}`)}
                </Text>
              </GridColumn>
              <GridColumn size="3/12" align="right" verticalAlign="center" padding="no no small">
                <TypedField name={`[${geoFenceAlert.id}].isActive`} component={Switch} />
              </GridColumn>
            </Grid>
            {formValues[geoFenceAlert.id].isActive && (
              <Fragment>
                {geoFenceAlert.timeFields.map((timeField: TimeField) => {
                  const fieldName = `[${geoFenceAlert.id}].${timeField.name}`;
                  return (
                    <Grid key={timeField.name}>
                      <GridColumn size="4/12">
                        <TypedField
                          name={fieldName}
                          component={Input}
                          props={{
                            isTimeField: true,
                            label: timeField.translationKey
                              ? translate(`vendors.${timeField.translationKey}`)
                              : undefined,
                            margin: 'no no small no',
                            onTimeChange: changeTime(fieldName),
                            time: formValues[geoFenceAlert.id][timeField.name],
                          }}
                        />
                      </GridColumn>
                    </Grid>
                  );
                })}
              </Fragment>
            )}
          </GeoFenceAlertSettingWrapper>
        ))}
        <Grid centered>
          <GridColumn size="6/12">
            <ButtonSet>
              <Button type="submit" color="primary">
                {translate('common.save')}
              </Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
}

export default reduxForm({ form: 'geoFenceAlertsSettingsForm' })(GeoFenceAlertsSettingsForm);
