import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { push } from 'connected-react-router';
import * as signalR from '@microsoft/signalr';
import moment from 'moment';

import { AppState } from 'src/store';
import {
  Address,
  Name,
  SOSAlertContent,
  SOSAlertInner,
  SOSAlertWrapper,
  SOSIcon,
  SOSIconMap,
  Title,
} from './styled/SOSAlert';
import { Button, ModalClose, ModalCloseIcon } from 'src/core/components/styled';
import { DuckAction } from 'src/contracts/ducks';
import { getAuthToken } from 'src/account/services/auth';
import { getCommunicationApiUrl } from 'src/core/services/environment';
import { getItem, setItem } from 'src/core/services/persistentStorage';
import { Icon } from 'src/core/components';
import { loadSOSAlertSettings } from 'src/vendors/ducks';
import { setPersistentSOSAlertVehicleId } from '../utils/sosAlerts';
import { setSOSAlertVehicle } from 'src/dashboard/ducks/mapControls';
import { SOS_ALERT_KEY } from 'src/common/constants/sosAlert';
import { SOSAlert as SOSAlertInterface } from 'src/common/interfaces/SOSAlert';
import { sosAlertsFeatureIsEnabled } from 'src/vendors/ducks/features';
import { TODAY_FORMATTED } from 'src/core/constants';
import translate from 'src/core/services/translate';

interface Props {
  loadSOSAlertSettings?(vendorId: number): Promise<any>;
  push: any;
  setConnection?(isConnectionInitiated: boolean): void;
  setSOSAlertVehicle: DuckAction<typeof setSOSAlertVehicle>;
  silenceAlertButtonVisible: boolean;
  snoozeAlertButtonVisible: boolean;
  snoozeTime: string;
  sosAlertsFeatureEnabled: boolean;
  vendorId: number;
}

interface State {
  messages: SOSAlertInterface[];
  snoozeTimeHours?: string;
  snoozeTimeMinutes?: string;
  snoozeTimeSeconds?: string;
}

class SOSAlert extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      messages: [],
      snoozeTimeHours: undefined,
      snoozeTimeMinutes: undefined,
      snoozeTimeSeconds: undefined,
    };
  }

  componentDidMount() {
    const { vendorId } = this.props;

    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Trace)
      .withUrl(`${getCommunicationApiUrl()}/hubs/vehicleNotificationHub?vendorId=${vendorId}`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: getAuthToken,
      })
      .withAutomaticReconnect()
      .build();

    connection.on('SOSAlertEvent', message => {
      const storageKey = getItem(SOS_ALERT_KEY);
      const sosAlertMessages: SOSAlertInterface[] = storageKey ? JSON.parse(storageKey) : [];

      const messageFormatted = {
        ...message,
        vehicleIdFormatted: `${message.vehicleId}_${Date.now()}`,
      };
      sosAlertMessages.push(messageFormatted);
      setItem(SOS_ALERT_KEY, JSON.stringify(sosAlertMessages));
    });

    connection.start().catch(() => {
      connection.stop();
    });

    setInterval(() => {
      const storageKey = getItem(SOS_ALERT_KEY);
      const sosAlertMessages = storageKey ? JSON.parse(storageKey) : undefined;
      const currentTime = moment();
      const { messages } = this.state;
      const { sosAlertsFeatureEnabled } = this.props;

      sosAlertMessages &&
        sosAlertsFeatureEnabled &&
        sosAlertMessages.forEach((message: SOSAlertInterface) => {
          const currentMessages = messages.filter(
            sosAlert => sosAlert.vehicleIdFormatted === message.vehicleIdFormatted,
          );
          if ((moment(message.expiryTime) < currentTime || !message.expiryTime) && !currentMessages.length) {
            this.setState({
              messages: [...messages, message],
            });
          }
        });
    }, 1000);
  }

  componentDidUpdate(prevProps: any) {
    const { loadSOSAlertSettings, snoozeTime, sosAlertsFeatureEnabled, vendorId } = this.props;

    if (prevProps.sosAlertsFeatureEnabled !== sosAlertsFeatureEnabled && sosAlertsFeatureEnabled) {
      loadSOSAlertSettings && !snoozeTime && loadSOSAlertSettings(vendorId);
    }

    if (prevProps.snoozeTime !== snoozeTime || snoozeTime) {
      const snoozeTimeDoubleZero = '00';
      const snoozeTimeHours = snoozeTime.split(':')[0] !== snoozeTimeDoubleZero ? snoozeTime.split(':')[0] : undefined;
      const snoozeTimeMinutes =
        snoozeTime.split(':')[1] !== snoozeTimeDoubleZero ? snoozeTime.split(':')[1] : undefined;
      const snoozeTimeSeconds =
        snoozeTime.split(':')[2] !== snoozeTimeDoubleZero ? snoozeTime.split(':')[2] : undefined;

      const snoozeTimeZero = '0';
      this.setState({
        snoozeTimeHours:
          snoozeTimeHours && snoozeTimeHours[0] === snoozeTimeZero ? snoozeTimeHours[1] : snoozeTimeHours,
        snoozeTimeMinutes:
          snoozeTimeMinutes && snoozeTimeMinutes[0] === snoozeTimeZero ? snoozeTimeMinutes[1] : snoozeTimeMinutes,
        snoozeTimeSeconds:
          snoozeTimeSeconds && snoozeTimeSeconds[0] === snoozeTimeZero ? snoozeTimeSeconds[1] : snoozeTimeSeconds,
      });
    }
  }

  closeSOSAlert = (vehicleIdFormatted: string) => {
    const { messages } = this.state;
    const storageKey = getItem(SOS_ALERT_KEY);
    const sosAlertMessages: SOSAlertInterface[] = storageKey ? JSON.parse(storageKey) : [];

    const newMessages = messages.filter(sosAlert => sosAlert.vehicleIdFormatted !== vehicleIdFormatted);
    const newMessagesFromStorage = sosAlertMessages.filter(
      sosAlert => sosAlert.vehicleIdFormatted !== vehicleIdFormatted,
    );

    this.setState({
      messages: newMessages,
    });
    setItem(SOS_ALERT_KEY, JSON.stringify(newMessagesFromStorage));
  };

  snoozeSOSAlert = (vehicleIdFormatted: string) => {
    const { messages, snoozeTimeHours, snoozeTimeMinutes, snoozeTimeSeconds } = this.state;
    const storageKey = getItem(SOS_ALERT_KEY);
    const sosAlertMessages: SOSAlertInterface[] = storageKey ? JSON.parse(storageKey) : [];

    const snoozeTimeHoursInSeconds: number = parseInt(snoozeTimeHours || '0') * 3600;
    const snoozeTimeMinutesInSeconds: number = parseInt(snoozeTimeMinutes || '0') * 60;
    const snoozeTimeSecondsInSeconds: number = parseInt(snoozeTimeSeconds || '0');
    const totalSnoozeTimeInseconds = snoozeTimeHoursInSeconds + snoozeTimeMinutesInSeconds + snoozeTimeSecondsInSeconds;

    const newMessages = messages.filter(sosAlert => sosAlert.vehicleIdFormatted !== vehicleIdFormatted);

    const newMessageFromStorage = sosAlertMessages.filter(
      sosAlert => sosAlert.vehicleIdFormatted === vehicleIdFormatted,
    )[0];
    const newMessagesFromStorage = sosAlertMessages.filter(
      sosAlert => sosAlert.vehicleIdFormatted !== vehicleIdFormatted,
    );
    const newMessageWithExpiryFromStorage = {
      ...newMessageFromStorage,
      expiryTime: moment().add(totalSnoozeTimeInseconds, 'seconds').toString(),
    };

    this.setState({
      messages: newMessages,
    });
    setItem(SOS_ALERT_KEY, JSON.stringify([...newMessagesFromStorage, newMessageWithExpiryFromStorage]));
  };

  redirectToDashboard = (vehicleId: number) => {
    const { push, setSOSAlertVehicle } = this.props;
    setPersistentSOSAlertVehicleId(vehicleId);
    setSOSAlertVehicle(vehicleId);
    push(`/dashboard?date=${TODAY_FORMATTED}`);
  };

  render() {
    const { messages, snoozeTimeHours, snoozeTimeMinutes, snoozeTimeSeconds } = this.state;
    const { silenceAlertButtonVisible, snoozeAlertButtonVisible, vendorId } = this.props;

    return (
      !!messages.length && (
        <SOSAlertWrapper>
          {messages.map(
            (message, index) =>
              message.vendorId === vendorId && (
                <SOSAlertContent key={index}>
                  <SOSIcon>
                    <Icon icon="exclamationTriangleV2" height="22" />
                  </SOSIcon>
                  <Title>{translate('common.sosAlerts.driverName', { driverName: message.driverName })}</Title>
                  <SOSAlertInner>
                    <Address>
                      <span onClick={() => this.redirectToDashboard(message.vehicleId)}>
                        <SOSIconMap>
                          <Icon icon="map" height="18" />
                        </SOSIconMap>
                        {message.location}
                      </span>
                    </Address>
                    <Name margin="small no no no">
                      {translate('common.sosAlerts.vehicleName', { vehicleName: message.vehicleName })}
                    </Name>
                    {snoozeAlertButtonVisible && (
                      <Button
                        type="button"
                        color="primary"
                        margin="medium small no no"
                        onClick={() => this.snoozeSOSAlert(message.vehicleIdFormatted)}
                      >
                        {translate('common.sosAlerts.snooze')}
                        {snoozeTimeHours ? ` ${snoozeTimeHours} ${translate('common.sosAlerts.hours')}` : undefined}
                        {snoozeTimeMinutes
                          ? ` ${snoozeTimeMinutes} ${translate('common.sosAlerts.minutes')}`
                          : undefined}
                        {snoozeTimeSeconds
                          ? ` ${snoozeTimeSeconds} ${translate('common.sosAlerts.seconds')}`
                          : undefined}
                      </Button>
                    )}
                    {silenceAlertButtonVisible && (
                      <Button
                        type="button"
                        color="secondary"
                        margin="medium no no small"
                        onClick={() => this.closeSOSAlert(message.vehicleIdFormatted)}
                      >
                        {translate('common.sosAlerts.silence')}
                      </Button>
                    )}
                  </SOSAlertInner>
                  <SOSIcon alignRight withClose={!silenceAlertButtonVisible}>
                    <Icon icon="exclamationTriangleV2" height="22" />
                  </SOSIcon>
                  {!silenceAlertButtonVisible && (
                    <ModalClose onClick={() => this.closeSOSAlert(message.vehicleIdFormatted)} margin="xxSmall">
                      <ModalCloseIcon />
                    </ModalClose>
                  )}
                </SOSAlertContent>
              ),
          )}
        </SOSAlertWrapper>
      )
    );
  }
}

const mapDispatchToProps = {
  loadSOSAlertSettings,
  push,
  setSOSAlertVehicle,
};

const mapStateToProps = (state: AppState) => ({
  snoozeTime: state.vendors.sosAlertSettings.sosAlertSettings.snoozeTime,
  snoozeAlertButtonVisible: state.vendors.sosAlertSettings.sosAlertSettings.snoozeAlert,
  silenceAlertButtonVisible: state.vendors.sosAlertSettings.sosAlertSettings.silenceAlert,
  sosAlertsFeatureEnabled: sosAlertsFeatureIsEnabled(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(SOSAlert);
