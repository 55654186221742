import { InjectedFormProps, reduxForm } from 'redux-form';
import { map } from 'lodash-es';
import { PureComponent } from 'react';

import { Button, ButtonSet, Grid, GridColumn } from '../../../core/components/styled';
import { DataRetention } from 'src/vendors/interfaces/DataRetention';
import { Dropdown, TypedField } from '../../../core/components';
import translate from '../../../core/services/translate';

type ComponentProps = {
  onCancel: (pristine: boolean) => void;
  dataRetention?: DataRetention[];
};

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class DataRetentionForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, onCancel, pristine, dataRetention } = this.props;
    const dataRetentionOptions = map(dataRetention, config => ({
      label: `${config.dataRetentionValue} ${translate(
        config.timeMeasurementType?.id === 7
          ? config.dataRetentionValue === 1
            ? 'common.year'
            : 'common.years'
          : 'common.months.months',
      )}`,
      value: config.dataRetentionValue,
    }));

    return (
      <form onSubmit={handleSubmit}>
        <Grid centered multiLine margin="small no no no">
          <GridColumn size="5/12" padding="no no medium no" verticalAlign="center">
            <span>{translate('vendors.retentionConfiguration')}</span>
          </GridColumn>
          <GridColumn size="3/12" padding="no no medium no" verticalAlign="center">
            <TypedField
              name="retentionConfiguration"
              component={Dropdown}
              props={{
                label: translate('vendors.timeMeasurementType'),
                options: dataRetentionOptions,
                width: '100%',
              }}
            />
          </GridColumn>
        </Grid>

        <Grid centered>
          <GridColumn size="12/12" padding="no medium medium medium">
            <ButtonSet margin="medium no" align="center">
              <Button type="submit" color="primary" margin="no small">
                {translate('common.save')}
              </Button>
              <Button type="button" color="secondary" margin="no small" onClick={() => onCancel(pristine)}>
                {translate('common.cancel')}
              </Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
      </form>
    );
  }
}

export default reduxForm<any, ComponentProps>({ form: 'DataRetentionForm' })(DataRetentionForm);
