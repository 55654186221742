import { FC, MouseEvent, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { ACCOUNT_SETTINGS_EDIT_VENDOR, ACCOUNT_SETTINGS_FEATURES } from '../../../account/constants';
import { AccountSettingsFeaturesForm } from '../forms';
import { ActionButtonTooltip } from '../../../core/components';
import { AdminOnlyGuard, PermissionGuard } from '../../../account/components';
import {
  AiModelConfigurationModalResolver,
  AlternativeFleetModal,
  AppExperienceModal,
  BillingModuleModalResolver,
  BulkyItemSchedulerModalResolver,
  CityAlertSettingsModalResolver,
  CityWorksConfigurationModalResolver,
  ContainerColorConfigurationModalResolver,
  CustomerNotificationModalResolver,
  DataRetentionModalResolver,
  DriverPickListDefaultsModalResolver,
  DriverSafetyModalResolver,
  ExceptionConfigurationModalResolver,
  FaultCodeEmailsModalResolver,
  FeatureConfigurationModalResolver,
  FlagLocationModalResolver,
  FleetMaintenanceResolver,
  FuelingTicketsSettingsModalResolver,
  GeoFenceAlertsSettingsModalResolver,
  InsightConfigurationModalResolver,
  LocationAlertsModalResolver,
  MaterialTypesModalResolver,
  NavigationModalResolver,
  ReportingConfigurationModalResolver,
  ROContainerManagementModalResolver,
  RouteAssistModalResolver,
  RouteBuilderModalResolver,
  SnowPlowModalResolver,
  SOSAlertModalResolver,
  StationaryAlertsModalResolver,
  StreetSweepingModalResolver,
  TimeManagementModalResolver,
  TripConfigurationModalResolver,
  VehicleStrobeImagesModalResolver,
  VehicleTrackingModalResolver,
  VendorEditorModal,
  WasteAuditModalResolver,
  YDataModalResolver,
  ZDeviceConfigurationModalResolver,
} from '../modals';
import { VisionConfigurationEditorModal } from 'src/fleet/components/modal';
import {
  Button,
  ButtonLink,
  Grid,
  GridColumn,
  Panel,
  PanelSection,
  PanelSectionGroup,
  PanelSectionTitle,
} from '../../../core/components/styled';
import { changePassword, saveFeatures, loadFeatures } from '../../ducks';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorId, currentVendorTypeId } from '../../services/currentVendorSelector';
import { featuresForLoggedInUser } from '../../ducks/features';
import { FeaturesFormValues } from '../../interfaces/Features';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { POSTTRIP, PRETRIP, RUBICON_PRO_PORTAL } from '../../constants';
import { User } from '../../../account/ducks/login';
import accountSettingsFeaturesFormInitialValues from '../../services/accountSettingsFeaturesFormInitialValuesSelector';
import AccountSettingsUserForm from '../forms/AccountSettingsUserForm';
import AccountSettingsVendorDisplay from '../forms/AccountSettingsVendorDisplay';
import BinSensorModalResolver from '../modals/BinSensorModalResolver';
import confirm from '../../../core/services/confirm';
import ContainerManagementModalResolver from '../modals/ContainerManagementModalResolver';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import EsriIntegrationConfigurationModalResolver from '../modals/EsriIntegrationConfigurationModalResolver';
import EsriSyncModalResolver from '../modals/EsriSyncModalResolver';
import ExceptionManagementConfigurationModalResolver from '../modals/ExceptionManagementConfigurationModalResolver';
import Open311ConfigurationModalResolver from '../modals/Open311ConfigurationModalResolver';
import RouteSequenceModalResolver from '../modals/RouteSequenceModalResolver';
import SupervisorExperienceSettingsModalResolver from '../modals/SupervisorExperienceSettingsModalResolver';
import SurfsightDataModalResolver from '../modals/SurfsightConfigurationModalResolver';
import translate from '../../../core/services/translate';
import VehicleNotificationsModalResolver from '../modals/VehicleNotificationsModalResolver';

const MODALS = {
  aiModel: 'AiModel',
  alternativeFleet: 'AlternativeFleet',
  appExperience: 'AppExperience',
  billingModule: 'BillingModule',
  binSensor: 'BinSensor',
  bulkyItemScheduler: 'BulkyItemScheduler',
  changePassword: 'ChangePassword',
  cityAlerts: 'CityAlertNew',
  cityworksConfiguration: 'CityworksConfiguration',
  containerColorConfiguration: 'ContainerColorConfiguration',
  containerManagement: 'ContainerManagement',
  customerNotification: 'CustomerNotification',
  dataRetention: 'DataRetention',
  driverPickListDefaults: 'DriverPickListDefaults',
  driverSafety: 'DriverSafety',
  esriIntegration: 'ESRIIntegration',
  esriSync: 'EsriSync',
  exceptionsDispatchTool: 'ExceptionsDispatchTool',
  faultCodeEmail: 'FaultCodeEmail',
  flagLocation: 'FlagLocation',
  fleetMaintenance: 'FleetMaintenance',
  fuelTickets: 'FuelTickets',
  geoFencing: 'GeoFencing',
  insightConfiguration: 'InsightConfiguration',
  locationAlerts: 'LocationAlerts',
  materialTypesConfiguration: 'MaterialTypesConfiguration',
  navigation: 'Navigation',
  open311Configuration: 'Open311Configuration',
  pickupException: 'PickupExceptions',
  postTripInspection: 'PostCheck',
  preTripInspection: 'PreCheck',
  reportingConfiguration: 'ReportingConfiguration',
  roContainerManagement: 'ROContainerManagement',
  routeAssist: 'RouteAssistanceInApp',
  routeBuilder: 'RouteBuilder',
  routeSequencing: 'RouteSequencing',
  snowPlow: 'SnowPlow',
  sosAlert: 'SOSAlert',
  stationaryAlerts: 'StationaryAlerts',
  streetSweeping: 'StreetSweeping',
  supervisorExperience: 'SupervisorExperience',
  surfsightConfiguration: 'SurfsightConfiguration',
  timeManagement: 'TimeManagement',
  vehicleNotifications: 'VehicleNotifications',
  vehicleRouteTriggers: 'SendVehicleRouteTriggers',
  vehicleStrobeImages: 'VehicleStrobeImages',
  vehicleTracking: 'VehicleTracking',
  visionConfiguration: 'VisionConfiguration',
  wasteAudit: 'WasteAudit',
  yData: 'YData',
  zDeviceConfiguration: 'ZDeviceConfiguration',
};

interface AccountSettingsModalsProps {
  featureCode?: string;
  closeFeatureModal(pristine?: boolean): void;
}

export const AccountSettingsModals: FC<AccountSettingsModalsProps> = ({ featureCode, closeFeatureModal }) => {
  switch (featureCode) {
    case MODALS.alternativeFleet:
      return <AlternativeFleetModal featureCode={featureCode} closeModal={closeFeatureModal} />;

    case MODALS.appExperience:
      return <AppExperienceModal featureCode={featureCode} closeModal={closeFeatureModal} />;

    case MODALS.binSensor:
      return <BinSensorModalResolver closeModal={closeFeatureModal} />;

    case MODALS.vehicleTracking:
      return <VehicleTrackingModalResolver closeModal={closeFeatureModal} />;

    case MODALS.open311Configuration:
      return <Open311ConfigurationModalResolver closeModal={closeFeatureModal} />;

    case MODALS.cityAlerts:
      return <CityAlertSettingsModalResolver closeModal={closeFeatureModal} />;

    case MODALS.customerNotification:
      return <CustomerNotificationModalResolver closeModal={closeFeatureModal} />;

    case MODALS.containerManagement:
      return <ContainerManagementModalResolver closeModal={closeFeatureModal} />;

    case MODALS.esriIntegration:
      return <EsriIntegrationConfigurationModalResolver closeModal={closeFeatureModal} />;

    case MODALS.esriSync:
      return <EsriSyncModalResolver closeModal={closeFeatureModal} />;

    case MODALS.flagLocation:
      return <FlagLocationModalResolver closeModal={closeFeatureModal} />;

    case MODALS.fleetMaintenance:
      return <FleetMaintenanceResolver closeModal={closeFeatureModal} />;

    case MODALS.faultCodeEmail:
      return <FaultCodeEmailsModalResolver closeModal={closeFeatureModal} />;

    case MODALS.fuelTickets:
      return <FuelingTicketsSettingsModalResolver closeModal={closeFeatureModal} />;

    case MODALS.locationAlerts:
      return <LocationAlertsModalResolver closeModal={closeFeatureModal} />;

    case MODALS.preTripInspection:
      return (
        <TripConfigurationModalResolver
          closeModal={closeFeatureModal}
          featureCode={featureCode}
          tripInspectionTypeId={PRETRIP}
        />
      );

    case MODALS.postTripInspection:
      return (
        <TripConfigurationModalResolver
          closeModal={closeFeatureModal}
          featureCode={featureCode}
          tripInspectionTypeId={POSTTRIP}
        />
      );

    case MODALS.roContainerManagement:
      return <ROContainerManagementModalResolver closeModal={closeFeatureModal} />;

    case MODALS.visionConfiguration:
      return <VisionConfigurationEditorModal closeModal={closeFeatureModal} hasVehicleTypeId />;

    case MODALS.routeSequencing:
      return <RouteSequenceModalResolver closeModal={closeFeatureModal} />;

    case MODALS.containerColorConfiguration:
      return <ContainerColorConfigurationModalResolver closeModal={closeFeatureModal} />;

    case MODALS.snowPlow:
      return <SnowPlowModalResolver closeModal={closeFeatureModal} />;

    case MODALS.streetSweeping:
      return <StreetSweepingModalResolver closeModal={closeFeatureModal} />;

    case MODALS.routeAssist:
      return <RouteAssistModalResolver closeModal={closeFeatureModal} />;

    case MODALS.routeBuilder:
      return <RouteBuilderModalResolver closeModal={closeFeatureModal} />;

    case MODALS.vehicleRouteTriggers:
      return <FeatureConfigurationModalResolver featureCode={featureCode} closeModal={closeFeatureModal} />;

    case MODALS.navigation:
      return <NavigationModalResolver closeModal={closeFeatureModal} />;

    case MODALS.stationaryAlerts:
      return <StationaryAlertsModalResolver closeModal={closeFeatureModal} />;

    case MODALS.wasteAudit:
      return <WasteAuditModalResolver closeModal={closeFeatureModal} />;

    case MODALS.aiModel:
      return <AiModelConfigurationModalResolver featureCode={featureCode} closeModal={closeFeatureModal} />;

    case MODALS.pickupException:
      return <ExceptionConfigurationModalResolver featureCode={featureCode} closeModal={closeFeatureModal} />;

    case MODALS.zDeviceConfiguration:
      return <ZDeviceConfigurationModalResolver featureCode={featureCode} closeModal={closeFeatureModal} />;

    case MODALS.exceptionsDispatchTool:
      return <ExceptionManagementConfigurationModalResolver featureCode={featureCode} closeModal={closeFeatureModal} />;

    case MODALS.vehicleNotifications:
      return <VehicleNotificationsModalResolver featureCode={featureCode} closeModal={closeFeatureModal} />;

    case MODALS.vehicleStrobeImages:
      return <VehicleStrobeImagesModalResolver closeModal={closeFeatureModal} />;

    case MODALS.insightConfiguration:
      return <InsightConfigurationModalResolver closeModal={closeFeatureModal} />;

    case MODALS.reportingConfiguration:
      return <ReportingConfigurationModalResolver closeModal={closeFeatureModal} />;

    case MODALS.yData:
      return <YDataModalResolver closeModal={closeFeatureModal} />;

    case MODALS.dataRetention:
      return <DataRetentionModalResolver closeModal={closeFeatureModal} />;

    case MODALS.geoFencing:
      return <GeoFenceAlertsSettingsModalResolver closeModal={closeFeatureModal} />;

    case MODALS.supervisorExperience:
      return <SupervisorExperienceSettingsModalResolver closeModal={closeFeatureModal} />;

    case MODALS.sosAlert:
      return <SOSAlertModalResolver closeModal={closeFeatureModal} />;

    case MODALS.bulkyItemScheduler:
      return <BulkyItemSchedulerModalResolver closeModal={closeFeatureModal} />;

    case MODALS.driverSafety:
      return <DriverSafetyModalResolver closeModal={closeFeatureModal} />;

    case MODALS.timeManagement:
      return <TimeManagementModalResolver closeModal={closeFeatureModal} />;

    case MODALS.materialTypesConfiguration:
      return <MaterialTypesModalResolver closeModal={closeFeatureModal} />;

    case MODALS.surfsightConfiguration:
      return <SurfsightDataModalResolver featureCode={featureCode} closeModal={closeFeatureModal} />;

    case MODALS.billingModule:
      return <BillingModuleModalResolver closeModal={closeFeatureModal} />;

    case MODALS.cityworksConfiguration:
      return <CityWorksConfigurationModalResolver closeModal={closeFeatureModal} />;

    case MODALS.driverPickListDefaults:
      return <DriverPickListDefaultsModalResolver closeModal={closeFeatureModal} />;

    default:
      return null;
  }
};

const AccountSettingsPage: FC = () => {
  const dispatch = useDispatch();

  const [createNewVendor, setCreateNewVendor] = useState(false);
  const [userProfileInEditMode, setUserProfileInEditMode] = useState(false);
  const [vendorModalOpen, setVendorModalOpen] = useState(false);
  const [selectedFeatureCode, setSelectedFeatureCode] = useState<string | undefined>();

  const rawUser = useSelector((state: AppState) => state.account.login.user);
  const vendor = useSelector((state: AppState) => state.vendors.vendor.vendor);
  const vendorId = useSelector(currentVendorId);
  const vendorTypeId = useSelector(currentVendorTypeId);
  const features = useSelector(featuresForLoggedInUser);
  const featuresValues = useSelector(accountSettingsFeaturesFormInitialValues);
  const currentUser = useSelector((state: AppState) => state.vendors.users.user);

  const user = useMemo(
    () => ({
      ...rawUser,
      name: rawUser ? rawUser.name : '-',
      password: userProfileInEditMode ? '' : new Array(17).join(' '),
    }),
    [rawUser, userProfileInEditMode],
  );

  const toggleUserProfileEditMode = () => {
    setUserProfileInEditMode(!userProfileInEditMode);
  };

  const openCreateVendorModal = () => {
    setVendorModalOpen(true);
    setCreateNewVendor(true);
  };

  const openEditVendorModal = () => {
    setVendorModalOpen(true);
    setCreateNewVendor(false);
  };

  const closeVendorModal = () => {
    setVendorModalOpen(false);
    setCreateNewVendor(false);
  };

  const openFeatureModal = (code: string, event: MouseEvent) => {
    event.stopPropagation();
    setSelectedFeatureCode(code);
  };

  const closeFeatureModal = async (pristine = true) => {
    if (!pristine && !(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
      return;
    }

    setSelectedFeatureCode(undefined);
  };

  const saveFeaturesConfiguration = (values: FeaturesFormValues) => {
    if (!vendorId) {
      return;
    }

    const updatedFeatures = features.map(feature => {
      const parentFeature = features.find(f => f.id === feature.parentId);

      const enabled =
        !!feature.parentId && parentFeature && !values[parentFeature.code] ? false : !!values[feature.code];

      return {
        ...feature,
        enabled,
      };
    });

    saveFeatures(
      vendorId,
      updatedFeatures,
    )(dispatch)
      .then(() => {
        loadFeatures(vendorId)(dispatch);
      })
      .catch(() => {
        createErrorNotification(translate('vendors.alertMessages.saveFeaturesError'));
      });
  };

  const onSubmitChangePassword = (formData: any) => {
    changePassword(formData)(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.passwordChanged'));
        toggleUserProfileEditMode();
      })
      .catch((e: { code: string; message: string }) => {
        createErrorNotification(`${translate(createTranslationKey(e.code, 'vendors.alertMessages'))}`);
      });
  };

  return (
    <PageContent>
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle>{translate('common.applicationSettings')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>

        <PageActions>
          <ButtonLink color="alert" line to="/account/logout">
            {translate('account.logout')}
          </ButtonLink>

          <AdminOnlyGuard>
            <Button color="primary" margin="no no no small" onClick={openCreateVendorModal}>
              {translate('vendors.createVendor')}
            </Button>
          </AdminOnlyGuard>
        </PageActions>
      </PageHeader>

      <Panel padding="medium">
        <PanelSection withBorder padding="no no medium">
          <PanelSectionGroup withBorder padding="no medium no no" width="33.33%">
            <Grid margin="no no medium">
              <GridColumn size="9/12" padding="no">
                <PanelSectionTitle margin="no">{translate('vendors.userProfile')}</PanelSectionTitle>
              </GridColumn>

              <GridColumn size="3/12" padding="no" align="right" verticalAlign="center">
                <Button
                  text
                  padding="no"
                  color={userProfileInEditMode ? 'alert' : 'grayDark'}
                  onClick={toggleUserProfileEditMode}
                >
                  <ActionButtonTooltip
                    iconSize="sMedium"
                    tooltip={userProfileInEditMode ? 'cancel' : 'editUser'}
                    icon={userProfileInEditMode ? 'close' : 'edit'}
                  />
                </Button>
              </GridColumn>
            </Grid>

            <AccountSettingsUserForm
              onSubmit={onSubmitChangePassword}
              passwordEditable={userProfileInEditMode}
              initialValues={user}
            />
          </PanelSectionGroup>

          <PanelSectionGroup padding="no no no medium" width="66.66%">
            <Grid margin="no no medium">
              <GridColumn size="11/12" padding="no">
                <PanelSectionTitle margin="no">{translate('vendors.companyConfiguration')}</PanelSectionTitle>
              </GridColumn>

              <PermissionGuard permission={ACCOUNT_SETTINGS_EDIT_VENDOR}>
                <GridColumn size="1/12" padding="no" align="right" verticalAlign="center">
                  <Button text padding="no" color="grayDark" onClick={openEditVendorModal}>
                    <ActionButtonTooltip iconSize="sMedium" tooltip="editVendor" icon="edit" />
                  </Button>
                </GridColumn>
              </PermissionGuard>
            </Grid>

            <AccountSettingsVendorDisplay roleId={currentUser ? (currentUser as User).roleId : undefined} />
          </PanelSectionGroup>
        </PanelSection>

        {vendorTypeId !== RUBICON_PRO_PORTAL && (
          <PermissionGuard permission={ACCOUNT_SETTINGS_FEATURES}>
            <AccountSettingsFeaturesForm
              initialValues={featuresValues}
              openFeatureModal={openFeatureModal}
              onSubmit={saveFeaturesConfiguration}
            />

            <AccountSettingsModals featureCode={selectedFeatureCode} closeFeatureModal={closeFeatureModal} />
          </PermissionGuard>
        )}
      </Panel>

      {vendorModalOpen && <VendorEditorModal vendor={createNewVendor ? undefined : vendor} close={closeVendorModal} />}
    </PageContent>
  );
};

export default AccountSettingsPage;
