import React, { useMemo } from 'react';

import { push } from 'connected-react-router';
import { debounce, map } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { Dropdown, PanelSearch, Switch, TypedField } from 'src/core/components';
import DateRangePicker, { DateRangeOptionValue } from 'src/core/components/DateRangePicker';
import { Grid, GridColumn, PanelSection } from 'src/core/components/styled';
import { DATE_RANGE_PICKER_TODAY_7_30_90_PAST } from 'src/core/constants/weekdays';
import translate from 'src/core/services/translate';
import { createUrl } from 'src/utils/services/queryParams';

export interface BillingFilterFormValues {
  billsSearchTerm: string;
  billStatusId: number;
  isOverdue: boolean;
  billDateRange: DateRangeOptionValue;
}

interface PropsWithoutReduxForm extends RouteComponentProps {
  invoiceStatusesOptions: TechnicalType[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<BillingFilterFormValues, PropsWithoutReduxForm>;

const BillingFilterForm = (props: Props) => {
  const {
    handleSubmit,
    location: { pathname, search },
    invoiceStatusesOptions,
  } = props;

  const dispatch = useDispatch();

  const invoiceStatuses = map(invoiceStatusesOptions, ({ name, id }) => ({
    label: name,
    value: id.toString(),
  }));

  const debouncedCallback = useMemo(
    () =>
      debounce((searchTerm: string) => {
        dispatch(push(createUrl(pathname, search, { billsSearchTerm: searchTerm })));
      }, 470),
    [dispatch, pathname, search],
  );

  const setSearchTermDebounced = React.useCallback(debouncedCallback, [debouncedCallback]);

  const setBillStatus = (billStatusId: number) => {
    dispatch(push(createUrl(pathname, search, { billStatusId })));
  };

  const setOverdue = (isOverdue: boolean) => {
    dispatch(push(createUrl(pathname, search, { isOverdue })));
  };

  const setDateRange = (billDateRange: { from: StringOrDate; to: StringOrDate }) => {
    dispatch(push(createUrl(pathname, search, { startDate: billDateRange.from, endDate: billDateRange.to })));
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <PanelSection padding="small xSmall" withBorder>
        <Grid>
          <GridColumn size="5/12" borderRight>
            <TypedField
              name="billsSearchTerm"
              component={PanelSearch}
              props={{ placeholder: translate('common.search'), isClearable: true }}
              onChange={(_, newValue: BillingFilterFormValues['billsSearchTerm']) => setSearchTermDebounced(newValue)}
            />
          </GridColumn>
          <GridColumn size="2/12">
            <TypedField
              name="billStatusId"
              component={Dropdown}
              props={{
                isClearable: true,
                options: invoiceStatuses as any,
                placeholder: translate('finance.status'),
                margin: 'no',
              }}
              onChange={(_, newValue: BillingFilterFormValues['billStatusId']) => setBillStatus(newValue)}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <TypedField
              name="billDateRange"
              component={DateRangePicker as any}
              onChange={(_, newValue: BillingFilterFormValues['billDateRange']) => setDateRange(newValue)}
              props={{
                placeholder: translate('finance.billDate'),
                hasMarginLeft: 'smaller',
                options: DATE_RANGE_PICKER_TODAY_7_30_90_PAST,
                margin: 'no',
                width: '100%',
              }}
            />
          </GridColumn>
          <GridColumn size="2/12">
            <TypedField
              name="isOverdue"
              component={Switch}
              onChange={(_, newValue: BillingFilterFormValues['isOverdue']) => setOverdue(newValue)}
              props={{
                label: translate('common.pastDue'),
                margin: 'xxSmall no no',
              }}
            />
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
};

export default withRouter(
  reduxForm<BillingFilterFormValues, PropsWithoutReduxForm>({
    enableReinitialize: true,
    form: 'billingFilter',
  })(BillingFilterForm),
);
