import { map, find, differenceWith, isEqual, compact } from 'lodash-es';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';

import { Contact } from 'src/customers/interfaces/Customers';
import { useSelector } from 'src/core/hooks/useSelector';
import { Input, Checkbox, Dropdown } from 'src/core/components';
import {
  Button,
  Grid,
  GridColumn,
  ModalClose,
  ModalCloseIcon,
  ModalFixedFooter,
  PanelSection,
  Text,
} from 'src/core/components/styled';
import {
  isOptionalPhone,
  isOptionalFax,
  isRequired,
  isAtLeastOneValidator,
  maxLength50,
  isOPtionalEmail,
} from 'src/utils/services/validator';
import ContactLocationsFormSection from 'src/customers/components/ContactLocationsFormSection';
import ContactTypesMultiSelectDropdown from 'src/customers/components/ContactTypesMultiSelectDropdown';
import translate from 'src/core/services/translate';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import { resendRegistrationEmail } from 'src/customers/services/customerPortal';

interface ComponentProps {
  customerId: number;
  locationId?: number;
  contactId?: number;
  onCancel(pristine: boolean): void;
  initialValues: any;
}

interface PropsWithoutReduxForm extends ComponentProps {
  isFormDirty?: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<Contact, PropsWithoutReduxForm>;

export const CONTACTS_EDITOR_FORM = 'contactsEditorForm';

const ContactsEditorForm = ({
  handleSubmit,
  pristine,
  error,
  onCancel,
  contactId,
  initialValues,
  locationId,
}: Props) => {
  const formValues: any = useSelector(state => getFormValues(CONTACTS_EDITOR_FORM)(state));

  const contactTypes = useSelector(state => state.common.contactTypes.contactTypes) || [];
  const customerPortalRoles = useSelector(state => state.customers.customerPortal.customerRoles);
  const customerLocations = useSelector(state => state.customers.locations.locations) || [];
  const simplifiedCustomerLocations = map(customerLocations, location => ({ id: location.id, name: location.name }));
  const assignedLocationIds = !contactId ? compact([locationId]) : initialValues.assignedLocationIds;
  const assignedLocations = map(assignedLocationIds, id => find(simplifiedCustomerLocations, { id: id }));
  const handleResendActivationEmail = () => {
    resendRegistrationEmail(contactId);
  };

  const disableEnableCustomerPortalLogin = initialValues?.isEnrolledInCustomerPortal;
  const isRequiredIfCustomerPortalLoginSelected = formValues?.isEnrolledInCustomerPortal ? [isRequired] : [];
  const showResendActivationEmail =
    initialValues?.isEnrolledInCustomerPortal === true && initialValues?.isRegisteredInCustomerPortal === false;

  const availableLocations = differenceWith(simplifiedCustomerLocations, assignedLocations, isEqual);

  const renderFormFields = () => (
    <>
      <Grid multiLine>
        <GridColumn size="6/12">
          <Field
            name="name"
            component={Input}
            validate={[isRequired, maxLength50]}
            label={translate('common.contact.contactName')}
            id="contacts-name-field"
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="title"
            component={Input}
            label={translate('common.contact.contactTitle')}
            validate={[maxLength50]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="phone"
            component={Input}
            label={translate('common.contact.contactPhone')}
            validate={[isOptionalPhone]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="alternatePhone"
            component={Input}
            label={translate('common.contact.contactAltPhone')}
            validate={[isOptionalPhone]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="email"
            component={Input}
            label={translate('common.contact.contactEmail')}
            validate={[isOPtionalEmail, maxLength50, ...isRequiredIfCustomerPortalLoginSelected]}
            disabled={disableEnableCustomerPortalLogin}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="alternateEmail"
            component={Input}
            label={translate('common.contact.contactAltEmail')}
            validate={[isOPtionalEmail, maxLength50]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="fax"
            component={Input}
            label={translate('common.contact.contactFax')}
            validate={[isOptionalFax]}
          />
        </GridColumn>
        <GridColumn size="6/12" margin="no no small no">
          <Field
            name="contactTypeIds"
            component={ContactTypesMultiSelectDropdown}
            multiSelectProps={{ margin: 'sMedium no no no' }}
            dropDownOptions={contactTypes}
            placeholder={translate('common.contact.contactType')}
            validate={[isAtLeastOneValidator]}
          />
        </GridColumn>
        <GridColumn size="6/12" margin="no no small no">
          <Field name="isActive" component={Checkbox} label={translate('common.contact.active')} />
        </GridColumn>
        <GridColumn size="6/12" margin="no no small no">
          <Field name="isPrimary" component={Checkbox} label={translate('common.contact.isPrimary')} />
        </GridColumn>
        <GridColumn size="6/12" margin="no no small no">
          <Field
            name="isEmailNotificationEnabled"
            component={Checkbox}
            label={translate('common.contact.emailNotifications')}
          />
        </GridColumn>
        <GridColumn size="6/12" margin="no no small no">
          <Field
            name="isTextNotificationEnabled"
            component={Checkbox}
            label={translate('common.contact.textNotifications')}
          />
        </GridColumn>
        <GridColumn size="12/12">
          <PanelSection withBorder />
        </GridColumn>

        <GridColumn size="12/12" margin="small no no no">
          <Text weight="medium" size="large">
            {translate('common.contact.customerPortalRegistration')}
          </Text>

          <Grid centered multiLine>
            <GridColumn size="6/12" margin="small no no no" padding="no">
              <Field
                name="isEnrolledInCustomerPortal"
                component={Checkbox}
                label={translate('common.contact.enableCustomerPortalLogin')}
                disabled={disableEnableCustomerPortalLogin}
              />
            </GridColumn>
            <GridColumn size="6/12">
              {formValues?.isEnrolledInCustomerPortal && (
                <Field
                  name="customerPortalRoleId"
                  component={Dropdown}
                  multiSelectProps={{ margin: 'sMedium no no no' }}
                  options={customerPortalRoles}
                  placeholder={translate('common.contact.customerPortalRoles')}
                  disabled={disableEnableCustomerPortalLogin}
                  validate={[...isRequiredIfCustomerPortalLoginSelected]}
                />
              )}
            </GridColumn>
            <GridColumn size="12/12" margin="no no small no" padding="no">
              {showResendActivationEmail && (
                <Button line color="primary" onClick={handleResendActivationEmail} size="small" margin="small no no no">
                  {translate('common.contact.resendActivationEmail')}
                </Button>
              )}
            </GridColumn>
          </Grid>
        </GridColumn>

        <GridColumn size="12/12">
          <PanelSection withBorder />
        </GridColumn>

        <GridColumn size="12/12" margin="small no sMedium no">
          <Text weight="medium" size="large">
            {translate('common.contact.locations')}
          </Text>
          <ContactLocationsFormSection
            key={assignedLocationIds.length}
            assignedLocations={assignedLocations}
            availableLocations={availableLocations}
            submissionError={error}
          />
        </GridColumn>
      </Grid>
    </>
  );

  return (
    <form onSubmit={handleSubmit} noValidate>
      <ModalClose onClick={() => onCancel(pristine)}>
        <ModalCloseIcon />
      </ModalClose>

      <PanelSection padding="small">{renderFormFields()}</PanelSection>

      <ModalFixedFooter isShadowed>
        <Button type="submit" margin="no xSmall" color="primary">
          {translate('common.save')}
        </Button>
        <Button type="button" color="secondary" margin="no xSmall" onClick={() => onCancel(pristine)}>
          {translate('common.cancel')}
        </Button>
      </ModalFixedFooter>
    </form>
  );
};

export default reduxForm<Contact, PropsWithoutReduxForm>({
  form: CONTACTS_EDITOR_FORM,
  onSubmitFail: focusFirstInvalidField,
})(ContactsEditorForm);
