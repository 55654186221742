import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { VENDOR_LOCATION_ICON_TYPES_NEW } from 'src/common/constants/vendorLocationIcons';
import {
  STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE,
  STREET_NETWORK_SERVICE_AREAS_HAULER_LOCATIONS_LAYER,
} from 'src/customers/constants';
import { setStreetNetworkServiceAreasSelectedFeature } from 'src/customers/ducks/streetNetworkServiceAreas';
import HaulerLocationGLPopup from './HaulerLocationGLPopup';
import HaulerLocationsGLLayer from './HaulerLocationsGLLayer';

const mapImages = VENDOR_LOCATION_ICON_TYPES_NEW.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

interface Props {
  map: mapboxgl.Map;
}

const HaulerLocationsGL: React.FC<Props> = ({ map }) => {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();

  const handleHaulerLocationsClick = useCallback(
    (event: mapboxgl.MapLayerMouseEvent) => {
      const [feature] = map
        .queryRenderedFeatures(event.point)
        .filter(
          feature =>
            feature.source === STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE &&
            feature.properties?.layer === STREET_NETWORK_SERVICE_AREAS_HAULER_LOCATIONS_LAYER,
        );

      if (!!feature) {
        dispatch(
          setStreetNetworkServiceAreasSelectedFeature({
            namespace: 'haulerLocations',
            id: feature.id as number,
          }),
        );
      }
    },
    [dispatch, map],
  );

  useEffect(() => {
    map.once('load', () => {
      map.on('click', handleHaulerLocationsClick);
    });

    return () => {
      map.off('click', handleHaulerLocationsClick);
    };
  }, [map, handleHaulerLocationsClick]);

  return (
    <>
      <HaulerLocationsGLLayer />
      <HaulerLocationGLPopup />
    </>
  );
};

export default HaulerLocationsGL;
