import React, { useRef, useState } from 'react';

import { InfoWindow, Polyline } from '@react-google-maps/api';

import {
  MapInfoWindow,
  MapInfoWindowDetail,
  MapInfoWindowDetailLabel,
  MapInfoWindowDetails,
  MapInfoWindowIcon,
  MapInfoWindowTitle,
} from '../../core/components/styled';
import translate from '../../core/services/translate';
import { Coordinates } from '../../routes/components/mapWithTimeline/Interfaces';
import { time } from '../../utils/services/formatter';
import { GOOGLE as google, INSIGHT_ICONS, POLYLINE_COLORS, SERVICE_MODES } from '../constants';
import { getSpeedInformation } from '../services/getSpeedInformation';

const createPolylineCoordinates = (positions: Coordinates[]) =>
  positions.map(position => new google.maps.LatLng(position.latitude, position.longitude));

interface Props {
  colorIndex: number;
  coordinateGroupId: string;
  coordinates: Coordinates[];
  endTimestamp: string;
  isInfoWindowOpen?: boolean;
  markerIconType: string;
  serviceMode: number;
  speed: number;
  toggleInfoWindow: (key: string) => void;
  unitOfMeasure: string;
  vehicleName: string;
}

const StreetSweeperTrackingMarker = (props: Props) => {
  const {
    colorIndex,
    coordinateGroupId,
    coordinates,
    endTimestamp,
    isInfoWindowOpen,
    markerIconType,
    serviceMode,
    speed,
    toggleInfoWindow,
    unitOfMeasure,
    vehicleName,
  } = props;

  const [infoWindowPosition, setInfoWindowPosition] = useState({
    lat: 0,
    lng: 0,
  });

  const setPolylineRef = useRef<any>(null);

  const toggleWindow = (event: google.maps.MapMouseEvent) => {
    if (event?.latLng) {
      setInfoWindowPosition({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    }
    toggleInfoWindow(`streetSweeperTrackings.${coordinateGroupId}`);
  };

  const pixelOffset = new google.maps.Size(0, -20);

  return (
    <>
      <Polyline
        path={createPolylineCoordinates(coordinates)}
        ref={setPolylineRef}
        options={{
          geodesic: true,
          clickable: true,
          strokeColor: POLYLINE_COLORS[colorIndex],
          strokeOpacity: 0.6,
          strokeWeight: 3,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                fillColor: POLYLINE_COLORS[colorIndex],
                fillOpacity: 0.4,
                strokeColor: POLYLINE_COLORS[colorIndex],
                strokeOpacity: 1,
                strokeWeight: 1,
                scale: 2,
                offset: '0%',
              } as any,
              repeat: '150px',
            },
          ],
        }}
        onClick={event => toggleWindow(event)}
      />
      {isInfoWindowOpen && infoWindowPosition.lat !== 0 && infoWindowPosition.lng !== 0 && (
        <InfoWindow position={infoWindowPosition} onCloseClick={toggleWindow as any} options={{ pixelOffset }}>
          <MapInfoWindow>
            <MapInfoWindowIcon size="small" src={INSIGHT_ICONS[markerIconType]} />
            <MapInfoWindowDetails>
              <MapInfoWindowDetail>
                <MapInfoWindowTitle>{SERVICE_MODES[serviceMode].name}</MapInfoWindowTitle>
              </MapInfoWindowDetail>

              <MapInfoWindowDetail>
                <MapInfoWindowDetailLabel>{translate('common.truck')}:</MapInfoWindowDetailLabel>
                {vehicleName}
              </MapInfoWindowDetail>
              <MapInfoWindowDetail>
                <MapInfoWindowDetailLabel>{translate('common.speed')}:</MapInfoWindowDetailLabel>
                {getSpeedInformation(speed, unitOfMeasure)}
              </MapInfoWindowDetail>
              <MapInfoWindowDetail>
                <MapInfoWindowDetailLabel>{translate('common.timestamp')}:</MapInfoWindowDetailLabel>
                {time(endTimestamp)}
              </MapInfoWindowDetail>
            </MapInfoWindowDetails>
          </MapInfoWindow>
        </InfoWindow>
      )}
    </>
  );
};

export default StreetSweeperTrackingMarker;
