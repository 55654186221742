import { camelCase, uniqBy } from 'lodash-es';
import moment from 'moment';

import { Activities, ServiceHistoryData } from 'src/routes/interfaces/ServiceHistory';
import { dateFormat, dateTimeSecondsFormat, timeFormat } from 'src/utils/services/validator';
import { duration } from 'src/utils/services/formatter';
import {
  Popover,
  TableCell,
  TableRow,
  Text,
  TimelineElement,
  TimelineLegend,
  TimelineLegendItem,
  TimelineWrapper,
  ToggleIcon,
} from 'src/core/components/styled';
import { PopoverWrapper } from 'src/core/components';
import { serviceHistoryTableCellWidths } from './SnowSweeperServiceHistorySection';
import { theme } from 'src/core/styles';
import { TimelineBox, TripDetailsWrapper } from 'src/core/components/styled/Timeline';
import { ToggleContainer } from '../../styled';
import translate from 'src/core/services/translate';

type Props = {
  activities: Activities[];
  driverFirstName: string;
  driverLastName: string;
  highlightedRows: number[];
  id: number;
  servicedSegments: number;
  sessionEndTime: Date | string;
  sessionStartTime: Date | string;
  toggleHighlightedRow: (id: number) => void;
  totalMaterialTrips: number;
  totalMilesDriven: number;
  totalSegments: number;
  totalTime: number;
  vehicleName: string;
};

export default function SnowSweeperServiceHistoryTableRow({
  activities,
  driverFirstName,
  driverLastName,
  highlightedRows,
  id,
  servicedSegments,
  sessionEndTime,
  sessionStartTime,
  toggleHighlightedRow,
  totalMaterialTrips,
  totalMilesDriven,
  totalSegments,
  totalTime,
  vehicleName,
}: Props & ServiceHistoryData) {
  const totalActiviesCount = activities?.length;
  const totalActiviesServiceTypeUniq = uniqBy(
    activities.filter(activity => activity.alternativeFleetServiceType),
    'alternativeFleetServiceType.id',
  );
  const totalActiviesNonServiceTypeUniq = uniqBy(
    activities.filter(activity => activity.alternativeFleetNonServiceType),
    'alternativeFleetNonServiceType.id',
  );
  const totalActiviesUniq = [...totalActiviesServiceTypeUniq, ...totalActiviesNonServiceTypeUniq];

  const totalActiviesServiceTypeUniqLabel =
    totalActiviesServiceTypeUniq.map(activity => activity.alternativeFleetServiceType?.name) || [];
  const totalActiviesNonServiceTypeUniqLabel =
    totalActiviesNonServiceTypeUniq.map(activity => activity.alternativeFleetNonServiceType?.name) || [];
  const totalActiviesLabel = [...totalActiviesServiceTypeUniqLabel, ...totalActiviesNonServiceTypeUniqLabel].join(', ');

  const isHighlightedRow = highlightedRows.includes(id);

  return (
    <>
      <TableRow
        wrap="wrap"
        isHighlighted={isHighlightedRow}
        borderWidth={isHighlightedRow ? '2px' : '0 0 1px 0'}
        borderColor={isHighlightedRow ? theme.brandPrimary : 'transparent'}
        marginTop={isHighlightedRow ? '-2px' : undefined}
      >
        <TableCell width={serviceHistoryTableCellWidths[0]}>
          <Text>
            {vehicleName} / {driverFirstName} {driverLastName} <br />
          </Text>
        </TableCell>
        <TableCell width={serviceHistoryTableCellWidths[1]}>
          <Text>
            {!!totalActiviesServiceTypeUniq.length || !!totalActiviesNonServiceTypeUniq.length
              ? totalActiviesLabel
              : '-'}
          </Text>
        </TableCell>
        <TableCell width={serviceHistoryTableCellWidths[2]}>
          {sessionStartTime ? (
            <Text>
              {moment(sessionStartTime).format(dateFormat)} <br />
              {moment(sessionStartTime).format(timeFormat)}
            </Text>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell width={serviceHistoryTableCellWidths[3]}>
          {sessionEndTime ? (
            <Text>
              {moment(sessionEndTime).format(dateFormat)} <br />
              {moment(sessionEndTime).format(timeFormat)}
            </Text>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell width={serviceHistoryTableCellWidths[4]}>
          <Text>{totalTime >= 0 && sessionEndTime ? duration(totalTime, 'seconds', 'hh:mm:ss') : '-'}</Text>
        </TableCell>
        <TableCell width={serviceHistoryTableCellWidths[5]}>
          <Text>{totalMilesDriven}</Text>
        </TableCell>
        <TableCell width={serviceHistoryTableCellWidths[6]}>
          <Text>
            {servicedSegments}
            {totalSegments && ` (${((servicedSegments * 100) / totalSegments).toFixed(2)}%)`}
          </Text>
        </TableCell>
        <TableCell width={serviceHistoryTableCellWidths[7]} justifyContent="space-between">
          <Text>{totalMaterialTrips}</Text>
          {totalActiviesCount > 0 && (
            <ToggleContainer id="toggle-filter-button" onClick={() => toggleHighlightedRow(id)}>
              <ToggleIcon size={10} isOpen={isHighlightedRow} icon="arrowDownFill" />
            </ToggleContainer>
          )}
        </TableCell>

        {isHighlightedRow && (
          <TripDetailsWrapper>
            <TimelineBox hasMargin>
              <TimelineWrapper>
                {activities.map((activity, index) => {
                  const alternativeFleetServiceTypeName = activity.alternativeFleetServiceType?.technicalName;
                  const alternativeFleetNonServiceTypeName = activity.alternativeFleetNonServiceType?.technicalName;

                  return (
                    <PopoverWrapper
                      key={index}
                      triggerButton={
                        <TimelineElement
                          width={100}
                          type={alternativeFleetServiceTypeName || alternativeFleetNonServiceTypeName}
                        />
                      }
                      popoverContent={
                        <Popover>
                          <Text block margin="xxSmall">
                            {translate(
                              `customers.streetNetwork.serviceActivity.${camelCase(
                                alternativeFleetServiceTypeName || alternativeFleetNonServiceTypeName,
                              )}`,
                            )}
                          </Text>
                          <Text block size="small" margin="xxSmall">
                            <b>{translate('routes.serviceHistoryData.sessionStartTime')}</b>:{' '}
                            {activity.startTime ? moment(activity.startTime).format(dateTimeSecondsFormat) : '-'}
                          </Text>
                          <Text block size="small" margin="xxSmall">
                            <b>{translate('routes.serviceHistoryData.sessionEndTime')}</b>:{' '}
                            {activity.endTime ? moment(activity.endTime).format(dateTimeSecondsFormat) : '-'}
                          </Text>
                          <Text block size="small" margin="xxSmall">
                            <b>{translate('routes.serviceHistoryData.totalTime')}</b>:{' '}
                            {activity.totalTime >= 0 && activity.endTime
                              ? duration(activity.totalTime, 'seconds', 'hh:mm:ss')
                              : '-'}
                          </Text>
                        </Popover>
                      }
                      size="medium"
                      width={`${100 / activities.length}%`}
                      height="100%"
                    />
                  );
                })}
              </TimelineWrapper>
              <TimelineLegend>
                {totalActiviesUniq.map((activity, index) => {
                  const alternativeFleetServiceTypeName = activity.alternativeFleetServiceType?.technicalName;
                  const alternativeFleetNonServiceTypeName = activity.alternativeFleetNonServiceType?.technicalName;

                  return (
                    <TimelineLegendItem
                      key={index}
                      type={alternativeFleetServiceTypeName || alternativeFleetNonServiceTypeName}
                    >
                      <Text>
                        {translate(
                          `customers.streetNetwork.serviceActivity.${camelCase(
                            alternativeFleetServiceTypeName || alternativeFleetNonServiceTypeName,
                          )}`,
                        )}
                      </Text>
                    </TimelineLegendItem>
                  );
                })}
              </TimelineLegend>
            </TimelineBox>
          </TripDetailsWrapper>
        )}
      </TableRow>
    </>
  );
}
