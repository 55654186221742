import { PureComponent } from 'react';

import { PageLoadingOverlay } from 'src/common/components/styled';
import { Resolver } from 'src/core/components';
import AddRemoveInventoryModal from './AddRemoveInventoryModal';

interface Props {
  closeModal: () => void;
  containerTypeId: number;
  currentQuantity?: number;
  id?: number;
  isAddInventory?: boolean;
  isAddNewInventory?: boolean;
}

class AddRemoveInventoryModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const promises: any[] = [];
    return Promise.all(promises);
  };

  render() {
    const { closeModal, containerTypeId, currentQuantity, id, isAddInventory, isAddNewInventory } = this.props;

    return (
      <Resolver
        successComponent={AddRemoveInventoryModal}
        successProps={{
          closeModal,
          containerTypeId,
          currentQuantity,
          id,
          isAddInventory,
          isAddNewInventory,
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
      />
    );
  }
}

export default AddRemoveInventoryModalResolver;
