import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadContainersLocationsForCreateEdit as doLoadContainersLocationsForCreateEdit } from '../services/containersLocationsForCreateEdit';
import { ContainerNewSimplified } from '../interfaces/containers';

// Actions
const START_LOAD = 'fleet/containers/containersLocationsForCreateEdit/START_LOAD';
const COMPLETE_LOAD = 'fleet/containers/containersLocationsForCreateEdit/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/containers/containersLocationsForCreateEdit/FAIL_LOAD';
const RESET = 'fleet/containers/containersLocationsForCreateEdit/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  containersLocationsForCreateEdit: ContainerNewSimplified[];
} = {
  isLoading: false,
  containersLocationsForCreateEdit: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containersLocationsForCreateEdit: action.containersLocationsForCreateEdit,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containersLocationsForCreateEdit: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (containersLocationsForCreateEdit: ContainerNewSimplified[]) => ({
  type: COMPLETE_LOAD,
  containersLocationsForCreateEdit,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadContainersLocationsForCreateEdit =
  (
    vendorId: number,
    equipmentTypeId?: number,
    equipmentSizeId?: number,
    wasteMaterialTypeId?: number,
    searchTerm?: string,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadContainersLocationsForCreateEdit = doLoadContainersLocationsForCreateEdit(
      vendorId,
      equipmentTypeId,
      equipmentSizeId,
      wasteMaterialTypeId,
      searchTerm,
    );
    loadContainersLocationsForCreateEdit
      .then((containersLocationsForCreateEdit: ContainerNewSimplified[]) =>
        dispatch(completeLoad(containersLocationsForCreateEdit)),
      )
      .catch(() => dispatch(failLoad()));
    return loadContainersLocationsForCreateEdit;
  };

export const resetContainersLocationsForCreateEdit = () => ({
  type: RESET,
});
