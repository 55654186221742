import { Box } from 'src/core/components/styled/Box';
import { Checkbox, PanelSearch, Switch, TypedField } from 'src/core/components';
import { COMPLETED, IN_PROGRESS, SCHEDULED } from 'src/routes/constants';
import { DashboardFilterRouteStopsFormContainer } from '../../styled/DashboardFilterMapbox';
import { JobPriorityTypesMultiSelect, PickupStatusDropdown } from 'src/common/components';
import { TypedFieldOnChangeFunction } from 'src/core/components/TypedField';
import translate from 'src/core/services/translate';

interface Props {
  handleViewAllStopsChange: TypedFieldOnChangeFunction;
  isSnowPlowOrStreetSweeperRoute: boolean;
}

const DashboardFilterRouteStops = ({ isSnowPlowOrStreetSweeperRoute, handleViewAllStopsChange }: Props) => {
  return (
    <DashboardFilterRouteStopsFormContainer>
      <Box padding="small no" display="flex">
        <TypedField
          name="routeStopsFilters.searchTerm"
          component={PanelSearch}
          props={{ margin: 'no', borderBottom: true, minWidth: '62%' }}
        />
        {!isSnowPlowOrStreetSweeperRoute && (
          <TypedField
            name="routeStopsFilters.showOrderNumbers"
            component={Switch}
            props={{
              label: translate('routes.filters.showOrderNumbers'),
              size: 'medium',
              margin: 'no no no large',
              labelOnLeft: true,
              width: '38%',
            }}
          />
        )}
      </Box>
      <Box padding="no no small" display="flex">
        <TypedField
          name="routeStopsFilters.pickupStatusIds"
          component={PickupStatusDropdown}
          props={{
            withPlaceholder: !isSnowPlowOrStreetSweeperRoute,
            acceptedPickupStatuses: isSnowPlowOrStreetSweeperRoute ? [COMPLETED, SCHEDULED, IN_PROGRESS] : [],
            dropdownProps: { margin: 'no', isClearable: true, width: '60%' },
            placeholder: isSnowPlowOrStreetSweeperRoute && translate('common.segmentStatus'),
            isSnowOrSweeperRoute: isSnowPlowOrStreetSweeperRoute,
          }}
        />
        <TypedField
          name="routeStopsFilters.jobPriorityTypeIds"
          component={JobPriorityTypesMultiSelect}
          props={{
            withPlaceholder: true,
            includeNoneOption: true,
            multiSelectProps: { margin: 'no xSmall', isClearable: true, canCheckAll: true },
          }}
        />
      </Box>

      <Box display="flex" margin="no no xSmall">
        <TypedField
          name="routeStopsFilters.showAll"
          component={Checkbox}
          onChange={handleViewAllStopsChange}
          props={{
            label: translate('common.all'),
            size: 'medium',
            margin: 'no small no no',
          }}
        />

        {!isSnowPlowOrStreetSweeperRoute && (
          <>
            <TypedField
              name="routeStopsFilters.issueReported"
              component={Checkbox}
              props={{
                size: 'small',
                margin: 'no small no no',
                label: translate('common.pickupStatuses.issueReported'),
              }}
            />
            <TypedField
              name="routeStopsFilters.locationAlert"
              component={Checkbox}
              props={{
                label: translate('common.locationTypes.locationAlert'),
                size: 'small',
                margin: 'no',
              }}
            />
          </>
        )}
      </Box>
    </DashboardFilterRouteStopsFormContainer>
  );
};

export default DashboardFilterRouteStops;
