import React, { Fragment } from 'react';

import { map } from 'lodash-es';
import { useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import translate from 'src/core/services/translate';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import { AppState } from 'src/store';
import { Dropdown, TypedField } from '../../../core/components';
import { Button, Grid, GridColumn, PanelSection, Text } from '../../../core/components/styled';
import { VehicleStrobeImagesSettingsItem } from 'src/vendors/interfaces/VehicleStrobeImagesSettings';

interface PropsWithoutReduxForm {}

type VehicleStrobeImagesSettingsFormProps = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const VehicleStrobeImagesSettingsForm: React.FC<VehicleStrobeImagesSettingsFormProps> = ({ form, handleSubmit }) => {
  const { vehicleStrobeImagesSettings } = useSelector((state: AppState) => state.vendors.vehicleStrobeImagesSettings);
  const { vehicleStrobeImageFrequencyTypes } = useSelector(
    (state: AppState) => state.vendors.vehicleStrobeImageFrequencyTypes,
  );

  const vehicleStrobeImageFrequencyTypesOptions = map(
    vehicleStrobeImageFrequencyTypes,
    (vehicleStrobeImageFrequencyType: any) => ({
      value: vehicleStrobeImageFrequencyType.id,
      label: vehicleStrobeImageFrequencyType.name,
    }),
  );

  return (
    <form onSubmit={handleSubmit} noValidate>
      <PanelSection padding="small" centered>
        <GridColumn size="12/12">
          <Grid centered multiLine>
            {map(vehicleStrobeImagesSettings, (vehicleStrobeImagesSetting: VehicleStrobeImagesSettingsItem, index) => (
              <Fragment key={index}>
                <GridColumn size="8/12">
                  <Text>{VEHICLE_TYPE_IDS[vehicleStrobeImagesSetting?.vehicleTypeId].name}</Text>
                </GridColumn>
                <GridColumn size="4/12">
                  <TypedField
                    name={`${vehicleStrobeImagesSetting.vehicleTypeId}`}
                    component={Dropdown}
                    props={{ options: vehicleStrobeImageFrequencyTypesOptions, menuPosition: 'fixed' }}
                  />
                </GridColumn>
              </Fragment>
            ))}
          </Grid>
        </GridColumn>
      </PanelSection>
      <PanelSection padding="small" centered>
        <Button color="primary" margin="no" type="submit">
          {translate('common.save')}
        </Button>
      </PanelSection>
    </form>
  );
};

export default reduxForm({ form: 'vehicleStrobeImagesSettingsForm' })(VehicleStrobeImagesSettingsForm);
