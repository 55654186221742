import { camelCase, map } from 'lodash-es';
import { connect, useDispatch } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { useState, useEffect, useRef } from 'react';

import { ActionButtonTooltip, DatePicker, Dropdown, FileOpenDialogToggle, Input } from '../../../core/components';
import { Address } from 'src/common/interfaces/Facility';
import { AppState } from 'src/store';
import { Button, ButtonSet, Grid, GridColumn, ModalFixedFooter, Text } from '../../../core/components/styled';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  isAfterCurrentTimeLoadsDumped,
  isDateValidValidator,
  isRequired,
  minValueNumeric1,
  maxValueNumeric100,
  maxValueNumeric999,
} from '../../../utils/services/validator';
import { loadVendor } from 'src/vendors/ducks';
import { POUND_ID } from 'src/routes/constants';
import { setLocationValue } from 'src/routes/utils/selectLocationOnMap';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { TODAY } from '../../../core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import CustomerLocationWithPin from '../CustomerLocationWithPin';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import PinOnMapModal from '../modals/PinOnMapModal';
import TimePickerInput from '../../../core/components/TimePicker';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {
  materialTypeValues: TechnicalType[];
  onClearfileName: () => void;
  onClose: (formPristine?: boolean) => void;
  onImageUpload: (files: FileList | null) => void;
  selectedFileName?: string;
  unitOfMeasureValues: TechnicalType[];
}

export interface LoadDumpedFormValues {
  latitude?: number;
  loadDumpedDate?: string;
  loadDumpedTime?: string;
  locationName?: string;
  locationNameAddress?: string;
  longitude?: number;
  materialAmount?: number;
  materialTypeId?: number;
  unitOfMeasureTypeId?: number;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<LoadDumpedFormValues, PropsWithoutReduxForm>;

function LoadDumpedEditorForm({
  change,
  handleSubmit,
  materialTypeValues,
  onClearfileName,
  onClose,
  onImageUpload,
  pristine,
  selectedFileName,
  unitOfMeasureValues,
}: Props) {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const formValues = useSelector(getFormValues('loadDumpedEditorForm')) as LoadDumpedFormValues;

  const [isPinOnMapSelected, setIsPinOnMapSelected] = useState(false);
  const [isPinOnMapModalOpen, setIsPinOnMapModalOpen] = useState(false);
  const [mapCenterByVendor, setMapCenterByVendor] = useState(undefined as unknown as { lat: number; lng: number });

  const didMountRef = useRef(false);

  useEffect(() => {
    // set to true if not the 1st render
    if (!didMountRef.current && formValues.locationName) {
      didMountRef.current = true;

      // set location value on 1st render only
      setLocationValue(change, undefined, formValues);
    }
  }, [change, formValues]);

  const onTimeChange = (value: Date | string) => {
    change('loadDumpedTime', value);
  };

  const onMaterialTypeChange = () => {
    change('unitOfMeasureTypeId', undefined);
  };

  const materialTypeValuesOptions = map(materialTypeValues, ({ id, technicalName }: TechnicalType) => ({
    label: translate(`common.loadsDumped.materialTypeValues.${camelCase(technicalName)}`),
    value: id,
  }));

  const unitOfMeasureValuesOptions = map(unitOfMeasureValues, ({ id, technicalName }: TechnicalType) => ({
    label: translate(`common.loadsDumped.unitOfMeasureValues.${camelCase(technicalName)}`),
    value: id,
  }));

  const clearPinnedAddress = () => {
    setIsPinOnMapSelected(false);
    setLocationValue(change, undefined);
  };

  const setOpenSelectOnMap = () => {
    setIsPinOnMapModalOpen(true);

    loadVendor(vendorId)(dispatch).then((response: any) => {
      const mapCenterByVendor = { lat: response.homeAddress.latitude, lng: response.homeAddress.longitude } as {
        lat: number;
        lng: number;
      };

      setMapCenterByVendor(mapCenterByVendor);
    });
  };

  const closePinOnMapModal = () => {
    setIsPinOnMapModalOpen(false);
  };

  const handleAddressSelection = (address: Address) => {
    setIsPinOnMapSelected(true);
    closePinOnMapModal();
    setLocationValue(change, address);
  };

  const handleEnteredAddress = (address: Address) => {
    setLocationValue(change, address);
  };

  const quantityValidation = formValues.unitOfMeasureTypeId === POUND_ID ? [maxValueNumeric999] : [maxValueNumeric100];

  const fileName = selectedFileName && selectedFileName?.split('.')[1] + '.' + selectedFileName?.split('.')[2];

  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <Grid multiLine>
          <GridColumn size="6/12" padding="no">
            <CustomerLocationWithPin
              colSize={12}
              fieldName="locationNameAddress"
              handleClearAddress={clearPinnedAddress}
              handlePinClick={setOpenSelectOnMap}
              handleEnteredAddress={handleEnteredAddress}
              isFieldFullWidth
              isPinIconAbsolute
              isPinOnMapSelected={!!isPinOnMapSelected}
              margin="no"
              hasLocationSelected={!!formValues.locationName}
              pinOnMapTooltip="selectLocationOnMap"
            />
            <Field margin="no" name="latitude" component={Input} type="hidden" />
            <Field margin="no" name="longitude" component={Input} type="hidden" />
            <Field margin="no" name="locationName" component={Input} type="hidden" />
          </GridColumn>
          <GridColumn size="6/12">
            <Field
              name="materialTypeId"
              component={Dropdown}
              onChange={() => onMaterialTypeChange()}
              label={translate('common.material')}
              options={materialTypeValuesOptions}
              validate={[isRequired]}
            />
          </GridColumn>
          <GridColumn size="6/12">
            <Field
              name="materialAmount"
              component={Input}
              label={translate('common.materialTickets.quantity')}
              validate={[isRequired, minValueNumeric1, ...quantityValidation]}
              type="number"
              min="1"
              max="100"
              props={{
                autoComplete: 'off',
              }}
            />
          </GridColumn>
          <GridColumn size="6/12">
            <Field
              name="unitOfMeasureTypeId"
              component={Dropdown}
              label={translate('common.materialTickets.uom')}
              options={unitOfMeasureValuesOptions}
              validate={[isRequired]}
            />
          </GridColumn>
          <GridColumn size="6/12">
            <Field
              name="loadDumpedDate"
              component={DatePicker}
              label={translate('common.materialTickets.ticketDate')}
              validate={[isRequired, isDateValidValidator]}
              disabledDays={[{ after: TODAY }]}
            />
          </GridColumn>
          <GridColumn size="6/12">
            <Field
              name="loadDumpedTime"
              component={TimePickerInput}
              label={translate('common.materialTickets.ticketTime')}
              onTimeChange={(value: Date | string) => onTimeChange(value)}
              validate={[isRequired, isAfterCurrentTimeLoadsDumped]}
              props={{
                raisedLabel: true,
              }}
            />
          </GridColumn>

          {!!fileName ? (
            <GridColumn size="6/12">
              <Text weight="medium" size="small" margin="no">
                {fileName}
              </Text>

              <Button id="clear-image-button" text color="primary" padding="no" onClick={() => onClearfileName()}>
                <ActionButtonTooltip icon="cancelClear" tooltip="clear" />{' '}
              </Button>
            </GridColumn>
          ) : (
            <GridColumn size="6/12">
              <Text block size="small">
                {translate('common.images')}
              </Text>

              <FileOpenDialogToggle
                margin="no xSmall no no"
                icon="upload"
                accept=".jpg"
                onFilesSelected={onImageUpload}
              />
            </GridColumn>
          )}
        </Grid>
        <ModalFixedFooter padding="sMedium small" justifyContent="space-around">
          <ButtonSet align="center" margin="no small">
            <Button type="submit" color="primary">
              {translate('common.save')}
            </Button>
            <Button type="button" margin="no small" color="secondary" onClick={() => onClose(pristine)}>
              {translate('common.cancel')}
            </Button>
          </ButtonSet>
        </ModalFixedFooter>
      </form>

      {isPinOnMapModalOpen && (
        <PinOnMapModal
          handleAddressSelection={handleAddressSelection}
          handleCloseModal={closePinOnMapModal}
          mapCenterByVendor={mapCenterByVendor}
          minHeight={'592px'}
        />
      )}
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  materialTypeValues: state.routes.loadsDumped.materialTypeValues,
  unitOfMeasureValues: state.routes.loadsDumped.unitOfMeasureValues,
});

export default connect(mapStateToProps)(
  reduxForm<LoadDumpedFormValues, PropsWithoutReduxForm>({
    form: 'loadDumpedEditorForm',
    onSubmitFail: focusFirstInvalidField,
  })(LoadDumpedEditorForm),
);
