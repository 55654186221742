import { map } from 'lodash-es';

import { http } from '../../core/services/http';
import { NavigationSettings } from '../interfaces/NavigationSettings';

export const loadNavigationSettings = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/settings/navigationSettings`).then(response => response.data);

export const saveNavigationSettings = (vendorId: number, navigationSettings: NavigationSettings[]) =>
  http
    .post(`/vendors/${vendorId}/settings/navigationSettings`, map(navigationSettings))
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
