import { FC, MouseEvent } from 'react';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';

import { ActionButtonTooltip } from 'src/core/components';
import { TableActionButton, TableCell, TableRow } from 'src/core/components/styled';
import translate from 'src/core/services/translate';

interface Props {
  groupName: string;
  id: number;
  deleteGroup: (id: number, e: MouseEvent<HTMLButtonElement>) => void;
  isActive: boolean;
  editGroup: (id: number, e: MouseEvent<HTMLButtonElement>) => void;
}

const GroupTableRow: FC<Props> = ({ groupName, id, deleteGroup, isActive, editGroup }) => {
  const isSupport = checkIfSupport();
  const isViewOnly = checkIfViewOnly();

  const hasPermissions = !isSupport && !isViewOnly;

  return (
    <TableRow>
      <TableCell width="80%">{groupName}</TableCell>
      <TableCell width="10%" align="center">
        {isActive ? translate('common.yes') : translate('common.no')}
      </TableCell>
      {hasPermissions && (
        <TableCell width="10%" align="right">
          <TableActionButton id={`edit-group-${id}-button`} onClick={e => editGroup(id, e)}>
            <ActionButtonTooltip icon="edit" tooltip="editGroup" />
          </TableActionButton>

          <TableActionButton id={`delete-group-${id}-button`} onClick={event => deleteGroup(id, event)}>
            <ActionButtonTooltip icon="delete" tooltip="deleteGroup" />
          </TableActionButton>
        </TableCell>
      )}
    </TableRow>
  );
};

export default GroupTableRow;
