import { lastIndexOf, map, orderBy } from 'lodash-es';

export const transformLoadFiles = (files: any) => {
  const newFiles = map(files, file => ({
    ...file,
    caseId: file.workflowCaseId && file.workflowCaseId.toString(),
    customerId: file.customerId && file.customerId,
    documentType: file.type, //DOCUMENT_TYPE_OPTIONS[file.type].name,
    fileName: file.fileName.substr(lastIndexOf(file.fileName, '_') + 1),
    siteId: file.siteId && file.siteId,
    svcId: file.serviceId && file.serviceId.toString(),
    svcOrder: file.orderId && file.orderId.toString(),
    workOrder: file.workOrderId ? file.workOrderId.toString() : 'No workflow',
  }));

  return orderBy(newFiles, ['createdDate'], ['desc']);
};
