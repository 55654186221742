import { mapKeys } from 'lodash-es';

export const JANUARY_ID = 1;
export const FEBRUARY_ID = 2;
export const MARCH_ID = 3;
export const APRIL_ID = 4;
export const MAY_ID = 5;
export const JUNE_ID = 6;
export const JULY_ID = 7;
export const AUGUST_ID = 8;
export const SEPTEMBER_ID = 9;
export const OCTOBER_ID = 10;
export const NOVEMBER_ID = 11;
export const DECEMBER_ID = 12;

const JANUARY = 'January';
const FEBRUARY = 'February';
const MARCH = 'March';
const APRIL = 'April';
const MAY = 'May';
const JUNE = 'June';
const JULY = 'July';
const AUGUST = 'Augutst';
const SEPTEMBER = 'September';
const OCTOBER = 'October';
const NOVEMBER = 'November';
const DECEMBER = 'December';

const MONTHS = mapKeys(
  [
    { id: JANUARY, translationKey: 'common.months.january' },
    { id: FEBRUARY, translationKey: 'common.months.february' },
    { id: MARCH, translationKey: 'common.months.march' },
    { id: APRIL, translationKey: 'common.months.april' },
    { id: MAY, translationKey: 'common.months.may' },
    { id: JUNE, translationKey: 'common.months.june' },
    { id: JULY, translationKey: 'common.months.july' },
    { id: AUGUST, translationKey: 'common.months.august' },
    { id: SEPTEMBER, translationKey: 'common.months.september' },
    { id: OCTOBER, translationKey: 'common.months.october' },
    { id: NOVEMBER, translationKey: 'common.months.november' },
    { id: DECEMBER, translationKey: 'common.months.december' },
  ],
  'id',
);

export default MONTHS;
