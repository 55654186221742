import React, { Component, Fragment } from 'react';
import { map } from 'lodash-es';
import { connect } from 'react-redux';
import { change, Field, FieldArray, WrappedFieldArrayProps, getFormValues } from 'redux-form';

import { Checkbox, Input } from '../../../../core/components';
import { Grid, GridColumn, Message } from '../../../../core/components/styled';
import translate from '../../../../core/services/translate';
import { AppState } from '../../../../store';

const FieldMock = Field as any;
const FieldArrayMock = FieldArray as any;

interface BusinessTypeFieldArrayProps extends WrappedFieldArrayProps {
  change(...args: any[]): any;
}

interface BusinessTypeFieldArrayState {
  errorMessage?: string;
}

class BusinessTypeFieldArray extends Component<BusinessTypeFieldArrayProps, BusinessTypeFieldArrayState> {
  readonly state: BusinessTypeFieldArrayState = {};

  onCheckboxCheck = (event: any, value: any, index: string) => {
    const { change, fields } = this.props;

    map(
      fields,
      (field, fieldIndex) => fieldIndex !== index && change('businessType', `types[${fieldIndex}].isSelected`, false),
    );
  };

  render() {
    const { fields } = this.props;

    return (
      <Fragment>
        <Grid multiLine>
          {fields.map((businessType, index) => (
            <Fragment key={index}>
              <GridColumn size="12/12" padding="no medium medium no" tabletPadding="no medium small no">
                <FieldMock
                  name={`${businessType}.isSelected`}
                  component={Checkbox}
                  label={fields.get(index).displayTitle}
                  onChange={(event: any, value: any) => this.onCheckboxCheck(event, value, index.toString())}
                />
              </GridColumn>

              {fields.get(index).supportsNote && fields.get(index).isSelected && (
                <GridColumn size="1/12" padding="no">
                  <Field
                    name={`${businessType}.userNote`}
                    component={Input}
                    label={translate('haulerProfile.userNote')}
                  />
                </GridColumn>
              )}
            </Fragment>
          ))}
        </Grid>

        {!fields.length && <Message padding="no">{translate('haulerProfile.noBusinessTypes')}</Message>}
      </Fragment>
    );
  }
}

interface BusinessTypeProps {
  formValues: any;
  change(...args: any[]): any;
}

const BusinessType: React.FC<BusinessTypeProps> = ({ change }) => (
  <FieldArrayMock name="types" component={BusinessTypeFieldArray} change={change} />
);

const mapStateToProps = (state: AppState) => ({
  formValues: getFormValues('businessType')(state) || {},
});

const mapDispatchToProps = { change };

export default connect(mapStateToProps, mapDispatchToProps)(BusinessType);
