import { map } from 'lodash-es';
import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { BillStatus } from '../interfaces/ApplyChecks';

const transformBillStatuses = (billStatuses: BillStatus[]) => {
  return map(billStatuses, ({ id, technicalName }) => ({
    label: translate(createTranslationKey(technicalName, 'finance.applyChecks.invoicePaymentStatuses')),
    value: id,
  }))
}

export default transformBillStatuses;
