import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const BYOD = 1;
export const RUBICON_LEASED_DEVICE = 2;
export const RUBICON_AND_BYOD = 3;

export const X_DEVICE_TYPES = mapKeys(
  [
    { id: BYOD, name: translate('vendors.xDeviceTypes.byod'), technicalName: 'byod' },
    { id: RUBICON_LEASED_DEVICE, name: translate('vendors.xDeviceTypes.rubiconLeasedDevice'), technicalName: 'rubiconLeasedDevice' },
    { id: RUBICON_AND_BYOD, name: translate('vendors.xDeviceTypes.byodAndRubiconLeasedDevice'), technicalName: 'byodAndRubiconLeasedDevice' },
  ],
  'id',
);
