import { http } from '../../core/services/http';
import { transformRubiconServicesOptions } from './transformRubiconServicesOptions';

export const loadRubiconServiceRates = (serviceId: number, serviceStatus: string, vendorId: string, userId: string) => {
  return http
    .post(`gus/rubiconservices/rates/vendor/${vendorId}/user/${userId}`, {
      serviceId: serviceId,
      serviceStatus,
    })
    .then(response => {
      return response.data;
    });
};

export const loadRubiconServicesOptions = (gusId: string) =>
  http.get(`haulerprofile/${gusId}/lookups`).then(response => transformRubiconServicesOptions(response.data));
