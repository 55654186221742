import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import mapboxgl from 'mapbox-gl';

import { getLocationAndCityAlertGeoJSON } from './utils';
import { getMapBounds } from 'src/common/components/map/util';
import { LocationAndCityAlertsForMap } from 'src/fleet/interfaces/LocationAndCityAlerts';
import { MAP_CITY_ZOOM } from 'src/core/constants';
import { MapGL } from 'src/common/components/map/MapGL';
import { mapId } from 'src/common/hooks/scroll';
import { setLocationAndCityAlertsMapViewport } from 'src/fleet/ducks';
import { useSelector } from 'src/core/hooks/useSelector';
import LocationAndCityAlertsClustersGL from './LocationAndCityAlertsClustersGL';
import LocationAndCityAlertsGL from './LocationAndCityAlertsGL';
import LocationAndCityAlertsGLHeatmap from './LocationAndCityAlertsGLHeatmap';
import LocationAndCityAlertsMapLegend from './LocationAndCityAlertsMapLegend';
import MapGLWrapper from 'src/common/components/map/MapGLWrapper';

interface Props {
  locationAndCityAlertsForMap: LocationAndCityAlertsForMap[];
}

const LocationAndCityAlertsMapGL: FC<Props> = ({ locationAndCityAlertsForMap }) => {
  const dispatch = useDispatch();

  const { viewport } = useSelector(state => state.fleet.locationAndCityAlerts);

  const [map, setMap] = useState<mapboxgl.Map | undefined>();
  const [isSatelliteView, setIsSatelliteView] = useState(false);
  const [isHeatMapVisible, setIsHeatMapVisible] = useState(false);

  const handleSatelliteViewChange = () => {
    setIsSatelliteView(!isSatelliteView);
  };

  const toggleHeatmap = () => {
    setIsHeatMapVisible(!isHeatMapVisible);
  };

  useEffect(() => {
    const points = locationAndCityAlertsForMap.map(alert => {
      return { longitude: alert.longitude, latitude: alert.latitude };
    });
    const bounds = getMapBounds(points, {
      padding: 25,
      capZoom: MAP_CITY_ZOOM,
    });
    dispatch(setLocationAndCityAlertsMapViewport(bounds));
  }, [dispatch, locationAndCityAlertsForMap]);

  const geoJSON = useMemo(
    () => getLocationAndCityAlertGeoJSON(locationAndCityAlertsForMap),
    [locationAndCityAlertsForMap],
  );

  return (
    <MapGLWrapper id={mapId}>
      <MapGL
        viewport={viewport}
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        onMapRefLoaded={map => {
          setMap(map);
        }}
        setIsSatelliteViewEnabled={handleSatelliteViewChange}
      >
        {!!map &&
          (isHeatMapVisible ? (
            <LocationAndCityAlertsGLHeatmap geoJSON={geoJSON} />
          ) : (
            <>
              <LocationAndCityAlertsClustersGL
                map={map}
                locationAndCityAlertsForMap={locationAndCityAlertsForMap || []}
              />
              <LocationAndCityAlertsGL map={map} />
            </>
          ))}

        {!!locationAndCityAlertsForMap.length && (
          <LocationAndCityAlertsMapLegend
            isHeatmapVisible={isHeatMapVisible}
            toggleHeatMap={toggleHeatmap}
            locationAndCityAlertsForMap={locationAndCityAlertsForMap}
          />
        )}
      </MapGL>
    </MapGLWrapper>
  );
};

export default LocationAndCityAlertsMapGL;
