import translate from '../services/translate';

export const FIRST_JOB_POSITION_ID = 1;
export const LAST_JOB_POSITION_ID = 2;
export const OPTIMIZED_JOB_POSITION_ID = 3;

export const ROLLOFF_JOB_POSITION_OPTIONS = [
  {
    value: FIRST_JOB_POSITION_ID,
    label: translate('customers.routeRecommendations.first'),
  },
  {
    value: LAST_JOB_POSITION_ID,
    label: translate('customers.routeRecommendations.last'),
  },
];

export const JOB_POSITION_OPTIONS = [
  ...ROLLOFF_JOB_POSITION_OPTIONS,

  {
    value: OPTIMIZED_JOB_POSITION_ID,
    label: translate('customers.routeRecommendations.optimized'),
  },
];
