import { Field } from 'redux-form';

import { Dropdown } from '../../core/components';
import { DropDownFieldProps } from './DropDownFieldProps';
import {
  FIRST_JOB_POSITION_ID,
  JOB_POSITION_OPTIONS,
  ROLLOFF_JOB_POSITION_OPTIONS,
} from 'src/core/constants/jobPositionOptions';
import { isOptimizedStopInsertionFeatureEnabled } from 'src/vendors/ducks/features';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  isOptimizedOptionHidden: boolean;
}

const JobPositionTypeDropdown: React.FC<Props> = ({
  input: { name, onChange },
  placeholder,
  withPlaceholder,
  label,
  withLabel,
  dropdownProps,
  isOptimizedOptionHidden,
}) => {
  const isOptimizedStopInsertionEnabled = useSelector(state => isOptimizedStopInsertionFeatureEnabled(state));

  return (
    <Field
      name={name}
      label={label || (withLabel ? translate('customers.routeRecommendations.insertAs') : undefined)}
      placeholder={placeholder || (withPlaceholder ? translate('customers.routeRecommendations.insertAs') : undefined)}
      component={Dropdown as any}
      options={
        isOptimizedOptionHidden || !isOptimizedStopInsertionEnabled
          ? ROLLOFF_JOB_POSITION_OPTIONS
          : JOB_POSITION_OPTIONS
      }
      onChange={(_, value) => onChange(value)}
      defaultValue={{ value: FIRST_JOB_POSITION_ID }}
      {...dropdownProps}
    />
  );
};

export default JobPositionTypeDropdown;
