import styled, { css } from 'styled-components';

interface Props {
  height?: string;
  width?: string;
}

export const Form = styled.form`
  width: 100%;
`;

export const FieldSet = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`;

export const FormContainer = styled.div<Props>`
  position: relative;

  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

export const FormOverlay = styled.div`
  background: rgb(16, 22, 0, 0.2);
  top: 0;
  left: -30px;
  right: -30px;
  bottom: 0;
  z-index: 20;
  position: absolute;
`;
