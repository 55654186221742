import React from 'react';
import styled, { css } from 'styled-components';
import { Popup } from 'react-map-gl';

import { Box } from 'src/core/components/styled/Box';
import { rotate } from 'src/core/styles';
import { sizeMapper } from 'src/utils/styles';
import { Text, FormGroup } from 'src/core/components/styled';

const mapGLPopupWidth = 315;
const mapGLPopupMinHeight = 93;

export const MapGLPopupLoading = styled.div<{ minHeight?: number; width?: number }>`
  position: relative;
  width: ${p => p.width || mapGLPopupWidth}px;
  height: ${p => p.minHeight || mapGLPopupMinHeight}px;

  &:after {
    content: '';
    position: absolute;
    box-sizing: content-box;
    top: calc(50% - 9.5px);
    left: calc(50% - 9.5px);
    width: 15px;
    height: 15px;
    border-radius: 15px;
    border: 2px solid ${props => props.theme.brandPrimary};
    border-right-color: transparent;
    animation: ${rotate} 1s linear infinite;
    z-index: 1;
  }
`;

export const MapGLPopupSmallLoading = styled.div`
  position: relative;
  width: 50px;
  height: 30px;

  &:after {
    content: '';
    position: absolute;
    box-sizing: content-box;
    top: calc(50% - 7.5px);
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    border: 2px solid ${props => props.theme.brandPrimary};
    border-right-color: transparent;
    animation: ${rotate} 1s linear infinite;
    z-index: 1;
  }
`;

export const MapGLPopupWrapper = styled(Popup)<{ hasNegativeMargin?: boolean }>`
  z-index: 6001;

  ${props =>
    props.hasNegativeMargin &&
    css`
      margin-top: -35px;
    `};

  .mapboxgl-popup-content {
    border-radius: 7px;
    padding: 20px 12px 12px;

    .mapboxgl-popup-close-button {
      border-radius: 0 7px 0 7px;
    }
  }

  &.mapboxgl-popup-anchor-top-left .mapboxgl-popup-content {
    border-top-left-radius: 0;
  }

  &.mapboxgl-popup-anchor-top-right .mapboxgl-popup-content {
    border-top-right-radius: 0;
  }

  &.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-content {
    border-bottom-right-radius: 0;
  }

  &.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content {
    border-bottom-left-radius: 0;
  }
`;

export const MapGLPopup = styled.div<{ minHeight?: number; maxHeight?: number; width?: number }>`
  display: flex;
  flex-wrap: wrap;
  width: ${p => p.width || mapGLPopupWidth}px;
  min-height: ${p => (p.minHeight ? `${p.minHeight}px` : `${mapGLPopupMinHeight}px`)};
  max-height: ${p => (p.maxHeight ? `${p.maxHeight}px` : '100%')};
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;

  & > ${Text} {
    flex: 0 0 100%;
  }
`;

interface MapGLPopupTitleProps {
  title: string;
  beforeTitleContent?: React.ReactNode;
  afterTitleContent?: React.ReactNode;
  secondTitle?: React.ReactNode;
}

export const MapGLPopupTitle: React.FC<MapGLPopupTitleProps> = ({
  title,
  beforeTitleContent = null,
  afterTitleContent = null,
  secondTitle = null,
}) => (
  <>
    <Box display="flex" alignItems="center" width="100%">
      {beforeTitleContent}

      <Text weight="medium" size="medium">
        {title}
      </Text>

      {afterTitleContent}
    </Box>
    {secondTitle}
  </>
);

interface MapGLPopupSubtitleProps {
  subtitle: string;
}

export const MapGLPopupSubtitle: React.FC<MapGLPopupSubtitleProps> = ({ subtitle }) => (
  <Text block size="small" margin="xxSmall no no">
    {subtitle}
  </Text>
);

interface MapGLPopupContentProps {
  padding?: string;
  verticalAllign?: boolean;
}

export const MapGLPopupContent = styled.div<MapGLPopupContentProps>`
  margin: 0 -5px;
  width: calc(100% + 10px);
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: ${props => sizeMapper(props.padding, 'no')};

  & > ${FormGroup} {
    margin: 5px 5px 0;
  }
  ${props =>
    props.verticalAllign &&
    css`
      align-items: center;
    `};
`;
