import styled, { css } from 'styled-components';
import { colorMapper, mapper, sizeMapper } from '../../../utils/styles';

type Props = Partial<{
  block: boolean;
  checkboxMargin: string;
  cursor?: string;
  flex?: boolean;
  isDisabled: boolean;
  isOrderedMultiselect?: boolean;
  isStyleAsCircle?: boolean;
  leftAlign: boolean;
  margin: string;
  noLabel: boolean;
  noWrap?: boolean;
  overflow?: boolean;
  padding?: string;
  partial: boolean;
  rightAlign: boolean;
  size: string;
  withBottomBorder?: boolean;
}>;

export const CheckboxCheck = styled.span<Props>`
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: top;
  border: 1px solid ${props => props.theme.gray};
  border-radius: 3px;
  cursor: pointer;
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    props.isStyleAsCircle &&
    css`
      border-radius: 100%;
    `};

  ${props =>
    props.partial &&
    css`
      background-color: ${props => props.theme.brandPrimary};
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOXB0IiBoZWlnaHQ9IjdwdCIgc3R5bGU9Imlzb2xhdGlvbjppc29sYXRlIiB2aWV3Qm94PSIwIDAgOSA3IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJhIj4KPHJlY3Qgd2lkdGg9IjkiIGhlaWdodD0iNyIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+CjxnIGNsaXAtcGF0aD0idXJsKCNhKSI+CjxsaW5lIHgxPSIyIiB4Mj0iNyIgeTE9IjMuNTE1IiB5Mj0iMy41IiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS13aWR0aD0iMS40IiB2ZWN0b3ItZWZmZWN0PSJub24tc2NhbGluZy1zdHJva2UiLz4KPC9nPgo8L3N2Zz4K');
      background-position: center center;
      background-repeat: no-repeat;
      border-color: ${props => props.theme.brandPrimary};
    `};
`;

export const CheckboxText = styled.span<Props>`
  vertical-align: top;
  text-transform: capitalize;
  line-height: 19px;
  font-size: 14px;
  margin: ${props => sizeMapper(props.margin, 'no')};
  text-overflow: clip;
  word-wrap: break-word;
  word-break: break-word;
  ${props =>
    props.color &&
    css`
      color: ${colorMapper(props)};
    `};
  ${props =>
    props.rightAlign &&
    css`
      float: right;
    `};

  ${props =>
    props.leftAlign &&
    css`
      margin-left: 0px !important;
      margin-right: 10px;
    `};
`;

export const CheckboxInput = styled.input<Props>`
  display: none;

  &:checked + ${CheckboxCheck} {
    background-color: ${props => props.theme.brandPrimary};
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI3IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xIDMuMjQzTDMuMzkgNiA4IDEiIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIxLjQiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
    background-position: center center;
    background-repeat: no-repeat;
    border-color: ${props => props.theme.brandPrimary};
    background-size: 80%;
  }
`;

export const Checkbox = styled.label<Props>`
  display: ${props => (props.overflow ? 'inline-flex' : 'inline-block')};
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};
  user-select: none;

  @media (max-width: ${props => props.theme.tabletBreakpoint}) {
    margin: 0;
  }

  ${props =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `};

  ${props =>
    props.block &&
    css`
      display: ${props.overflow ? 'flex' : 'block'};
    `};

  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `};

  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props => props.theme.grayLight};
    `};

  ${CheckboxCheck} {
    width: ${props => mapper(props.size, { small: '13px', medium: '15px', large: '17px' })};
    height: ${props => mapper(props.size, { small: '13px', medium: '15px', large: '17px' })};
    display: ${props =>
      props.isDisabled && props.isOrderedMultiselect ? 'none' : props.overflow ? 'inline-flex' : 'inline-block'};
    ${props =>
      props.checkboxMargin &&
      css`
        margin: ${sizeMapper(props.checkboxMargin)};
      `};

    ${props =>
      props.noLabel &&
      css`
        margin-top: ${mapper(props.size, { small: '10px', medium: '12px', large: '14px' })};
      `};

    ${props =>
      props.cursor &&
      css`
        cursor: ${props.cursor};
      `};

    ${props =>
      props.isDisabled &&
      css`
        cursor: not-allowed;
      `};
  }

  ${CheckboxText} {
    line-height: ${props => mapper(props.size, { small: '13px', medium: '15px', large: '17px' })};
    font-size: ${props => mapper(props.size, { small: '12px', medium: '14px', large: '16px' })};
    margin-left: ${props =>
      props.isDisabled && props.isOrderedMultiselect
        ? '0px'
        : mapper(props.size, { small: '10px', medium: '12px', large: '14px' })};

    ${props =>
      props.isDisabled &&
      css`
        cursor: not-allowed;
      `};

    ${props =>
      props.overflow &&
      css`
        overflow: hidden;
        text-overflow: ellipsis;
      `};

    @media (max-width: ${props => props.theme.tabletBreakpoint}) {
      display: inline-block;
      padding-left: 20px;
      width: 100%;
      margin-top: -16px;
    }
  }

  ${props =>
    props.flex &&
    css`
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      ${CheckboxText} {
        margin: 0 0 5px 0;
      }
    `};
`;

export const ContainerSeparator = styled.div<Props>`
  width: 100%;
  height: 2px;
  border-bottom: 1px dashed ${props => props.theme.gray};
  margin-bottom: 12px;
`;
