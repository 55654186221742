import React, { FocusEvent, PureComponent } from 'react';
import { FakeInput, FormError, FormGroup, FormLabel, Input as FormInput, FormInputWrapper } from './styled';

interface Props {
  align?: string;
  append?: string | JSX.Element;
  autoFocus?: boolean;
  disabled?: boolean;
  error?: string;
  hasError?: boolean;
  label?: string;
  margin?: string;
  max?: number | string;
  min?: number | string;
  onChange?: (value: any) => void;
  placeholder?: string;
  prepend?: string | JSX.Element;
  preventNumberExponent?: boolean;
  step?: string;
  type?: string;
  value?: number | string;
  width?: string;
  isCurrency?: boolean
}

class UnconnectedInput extends PureComponent<Props, { value?: number | string; inputText?: string }> {
  constructor(props: Props) {
    super(props);
    this.state = { value: props.value };
  }

  onChange = (event: any) => {
    const { value } = event.target;
    this.setState({ value });

    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  preventDefault = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { preventNumberExponent } = this.props;
    const keyCodeForE = 69;
    const keyForE = 'E';


    if (preventNumberExponent && (event.keyCode === keyCodeForE || event.key.toUpperCase() === keyForE)) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  /**
 * onBlur event handler for an input element.
 * This function is triggered when the input field loses focus.
 * It checks if the input should handle currency formatting.
 * If so, it converts the input value to a floating-point number,
 * rounds it to two decimal places, and updates the onChange handler.
 */

  onBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { onChange, isCurrency } = this.props;
    if (isCurrency) {
      let value = Math.abs(parseFloat(event.target.value))
      if (!isNaN(value) && onChange) {
        onChange((Math.round(value * 100) / 100).toFixed(2));
      }
    }
  }

  render() {
    const { align, append, disabled, error, hasError, label, margin, placeholder, prepend, width, ...props } =
      this.props;
    const { value, inputText } = this.state;

    return (
      <FormGroup
        width={width}
        margin={margin}
        hasValue={value || value === 0 || inputText}
        raisedLabel={!!append || !!prepend}
      >
        {!!label && <FormLabel>{label}</FormLabel>}

        <FormInputWrapper flex={!!append || !!prepend}>
          {prepend && (
            <FakeInput noOverflow noFw verticalMiddle>
              {prepend}
            </FakeInput>
          )}
          <FormInput
            {...props}
            align={align}
            hasError={hasError}
            disabled={disabled}
            placeholder={placeholder || ''}
            onChange={this.onChange}
            onKeyDown={this.preventDefault}
            onBlur={this.onBlur}
          />
          {append && (
            <FakeInput noOverflow noFw verticalMiddle>
              {append}
            </FakeInput>
          )}
        </FormInputWrapper>

        {hasError && error ? <FormError>{error}</FormError> : null}
      </FormGroup>
    );
  }
}

export default UnconnectedInput;
