import React from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import PickupLocationsEditorPage from './PickupLocationsEditorPage';
import { loadRouteTemplateLocationsBatched, loadRouteTemplateOptimized } from '../../../ducks';
import { TypedResolver } from '../../../../core/components';
import { PageLoadingOverlay } from '../../../../common/components/styled/Page';

interface RouteParams {
  routeTemplateId: string;
}

const PickupLocationsEditorPageResolver: React.FC<RouteComponentProps<RouteParams>> = ({
  match: {
    params: { routeTemplateId },
  },
}) => {
  const dispatch = useDispatch();

  const loadDependencies = React.useCallback(() => {
    loadRouteTemplateLocationsBatched('pickupLocationsEditorPageResolver', routeTemplateId)(dispatch);

    return Promise.all([loadRouteTemplateOptimized(routeTemplateId)(dispatch)]);
  }, [routeTemplateId, dispatch]);

  return (
    <TypedResolver
      successComponent={PickupLocationsEditorPage}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default withRouter(PickupLocationsEditorPageResolver);
