import React, { ChangeEvent } from 'react';

import moment from 'moment';
import { Field, WrappedFieldArrayProps } from 'redux-form';

import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { DatePicker, Table, UnconnectedCheckbox } from 'src/core/components';
import translate from 'src/core/services/translate';
import { AdvRoute } from '../pages/routes/RouteTrackerPage';
import RouteReschedulerTableRow, { RouteReschedulerRow } from './RouteReschedulerTableRow';

type Props = WrappedFieldArrayProps<AdvRoute & { isChecked: boolean }> & {
  allChecked: boolean;
  checkAllRoutes: (e: ChangeEvent<HTMLInputElement>) => void;
  isSnowPlowRoute?: boolean;
  isStreetSweeperRoute?: boolean;
  partial: boolean;
  updateTargetDateAllRoutes: (date: Date | string | null) => void;
  validationErrors: any[];
};

const RouteReschedulerFieldArray: React.FC<Props> = ({
  allChecked,
  checkAllRoutes,
  fields,
  isSnowPlowRoute,
  isStreetSweeperRoute,
  partial,
  updateTargetDateAllRoutes,
  validationErrors,
}) => {
  const routeSchedulerTableCells = [
    { name: 'routeName', label: translate('routes.route'), width: '17%', sortable: true },
    { name: 'vehicleTypeName', label: translate('vehicles.vehicle'), width: '15%', sortable: true },
    {
      name: isSnowPlowRoute || isStreetSweeperRoute ? 'segmentsCount' : 'totalStops',
      label: translate('routes.stops'),
      width: '10%',
      sortable: true,
    },
    {
      name: isSnowPlowRoute || isStreetSweeperRoute ? 'routeStatusTypeTechnicalName' : 'routeStatusType.id',
      label: translate('common.status'),
      width: '15%',
      sortable: true,
    },
    { name: 'routeDate', label: translate('common.currentDate'), width: '15%', sortable: true },
    {
      name: 'selectAll',
      component: UnconnectedCheckbox,
      componentProps: {
        onChange: checkAllRoutes,
        checked: allChecked,
        partial,
      },
      width: '5%',
    },
    {
      name: 'newRouteDate',
      component: Field,
      componentProps: {
        name: 'allTargetDate',
        component: DatePicker,
        disabled: !partial && !allChecked,
        horizontalAlign: 'left',
        disabledDays: [
          {
            before: moment().toDate(),
            after: moment().add(30, 'days').toDate(),
          },
          moment().toDate(),
        ],
        margin: 'no',
        label: translate('common.targetDate'),
        isClearable: true,
        onChange: updateTargetDateAllRoutes,
      },
      width: '23%',
      sortable: false,
      textTransform: 'none',
    },
  ];

  const rows = fields.map((configuration, index) => {
    const {
      driverName,
      isChecked,
      materialType,
      routeDate,
      routeId,
      routeName,
      routeRunDate,
      routeStatusName,
      routeStatusTypeId,
      segmentsCount,
      totalStops,
      tripEndTime,
      tripStartTime,
      tripTime,
      vehicleName,
      vehicleTypeName,
    } = fields.get(index);

    return {
      configuration,
      datePickerVAlign: fields.length >= 5 && index > fields.length - 4 && 'top',
      driverName,
      hasDuplicateError: !!validationErrors.find(e => e.routeId === routeId),
      isChecked,
      materialType,
      routeDate,
      routeId,
      routeName,
      routeRunDate,
      routeStatusName,
      routeStatusTypeId,
      segmentsCount,
      totalStops,
      tripEndTime,
      tripStartTime,
      tripTime,
      vehicleName,
      vehicleTypeName,
    } as RouteReschedulerRow;
  });

  const virtualizedProps = {
    height: TABLE_ROW_HEIGHT_LARGE * 6,
    itemSize: TABLE_ROW_HEIGHT_LARGE,
  };

  return (
    <Table
      cells={routeSchedulerTableCells}
      rowComponent={RouteReschedulerTableRow}
      rows={rows}
      rowProps={{ isSnowPlowRoute, isStreetSweeperRoute }}
      tableHeadRowProps={{ align: 'flex-end' }}
      virtualized
      virtualizedProps={virtualizedProps}
    />
  );
};

export default RouteReschedulerFieldArray;
