import { useState } from 'react';
import moment from 'moment';

import { ActionButtonTooltip, ThumbnailSet } from 'src/core/components';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { getLoadsDumpedTableCellWidths } from './LoadsDumpedSection';
import { LOADS_DUMPED_MATERIALS, LOADS_DUMPED_UOM } from 'src/routes/constants/loadsDumped';
import { LoadsDumped } from 'src/routes/interfaces/LoadsDumped';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { timeFormatWithoutSeconds } from 'src/utils/services/validator';
import { useSelector } from 'src/core/hooks/useSelector';
import { WeightTicketImagesModal } from '..';

type Props = {
  deleteLoadDumped?: (loadDumpedId: number) => void;
  editLoadDumped?: (loadDumpedId: number) => void;
  isViewOnly?: boolean;
  loadsDumpedCellWidths?: string[];
};

export default function LoadsDumpedTableRow({
  deleteLoadDumped,
  editLoadDumped,
  id: loadDumpedId,
  image,
  isViewOnly,
  loadsDumpedCellWidths,
  locationAddress,
  locationName,
  materialAmount,
  materialTypeId,
  pickUpDateTimeLocal,
  unitOfMeasureTypeId,
}: Props & LoadsDumped) {
  const isSupportPermission = checkIfSupport();
  const isViewOnlyPermission = checkIfViewOnly();

  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  const [isLoadsDumpedImageModalOpen, setLoadsDumpedImageModalOpen] = useState(false);

  if (!routeSummary && !isViewOnly) return null;

  const timestampMoment = moment(pickUpDateTimeLocal);
  const loadsDumpedTableCellWidths =
    isViewOnly && loadsDumpedCellWidths ? loadsDumpedCellWidths : getLoadsDumpedTableCellWidths();

  return (
    <>
      <TableRow>
        <TableCell width={loadsDumpedTableCellWidths[0]} vertical justifyContent="center">
          <Text>{locationName}</Text>
          <Text size="small">{locationAddress}</Text>
        </TableCell>
        <TableCell width={loadsDumpedTableCellWidths[1]}>
          <Text>{materialTypeId && LOADS_DUMPED_MATERIALS[materialTypeId].name}</Text>
        </TableCell>
        <TableCell width={loadsDumpedTableCellWidths[2]}>
          <Text>{materialAmount}</Text>
        </TableCell>
        <TableCell width={loadsDumpedTableCellWidths[3]}>
          <Text>{unitOfMeasureTypeId && LOADS_DUMPED_UOM[unitOfMeasureTypeId].name}</Text>
        </TableCell>
        <TableCell width={loadsDumpedTableCellWidths[4]} vertical justifyContent="center">
          <Text weight="medium">{timestampMoment.format('MM/DD/YYYY')}</Text>
          <Text>{timestampMoment.format(timeFormatWithoutSeconds)}</Text>
        </TableCell>
        <TableCell width={loadsDumpedTableCellWidths[5]}>
          <Text>
            {image?.url && <ThumbnailSet images={[image?.url]} onClick={() => setLoadsDumpedImageModalOpen(true)} />}
          </Text>
        </TableCell>

        {!isViewOnlyPermission && !isSupportPermission && !isViewOnly && (
          <>
            <TableCell width={loadsDumpedTableCellWidths[6]} align="right">
              <TableActionButton
                onClick={() => (editLoadDumped && loadDumpedId ? editLoadDumped(loadDumpedId) : undefined)}
              >
                <ActionButtonTooltip icon="edit" tooltip="edit" />
              </TableActionButton>
              <TableActionButton
                onClick={() => (deleteLoadDumped && loadDumpedId ? deleteLoadDumped(loadDumpedId) : undefined)}
              >
                <ActionButtonTooltip icon="delete" tooltip="delete" />
              </TableActionButton>
            </TableCell>
          </>
        )}

        {isLoadsDumpedImageModalOpen && (
          <WeightTicketImagesModal
            weightTicket={{
              disposalSiteAddress: locationName,
              image: { url: image?.url, rawUrl: image?.url },
              locationAddress,
              materialType: materialTypeId && LOADS_DUMPED_MATERIALS[materialTypeId].name,
            }}
            closeModal={() => setLoadsDumpedImageModalOpen(false)}
          />
        )}
      </TableRow>
    </>
  );
}
