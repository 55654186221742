import { map } from 'lodash-es';

import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';

const transformWorkFlowTypes = (workFlowTypes: any[]) =>
  map(workFlowTypes, ({ id, technicalName }) => ({
    id: id,
    name: translate(createTranslationKey(technicalName, 'finance.workOrderTypes')),
    technicalName: technicalName,
  }));

export default transformWorkFlowTypes;
