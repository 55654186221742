import { getItem, setItem } from '../../core/services/persistentStorage';

const OLD_DASHBOARD_NAVIGATION_LAYOUT_STORAGE_KEY = 'OLD_DASHBOARD_NAVIGATION_LAYOUT';

export const isOldDashboardNavigationLayout = () => {
  return JSON.parse(getItem(OLD_DASHBOARD_NAVIGATION_LAYOUT_STORAGE_KEY) || 'false');
};

export const setOldDashboardNavigationLayout = (dashboardNavigationLayout: boolean) => {
  setItem(OLD_DASHBOARD_NAVIGATION_LAYOUT_STORAGE_KEY, JSON.stringify(dashboardNavigationLayout));
};
