import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isPristine } from 'redux-form';

import { AppState } from '../../../store';
import { Modal, Table, PopoverWrapper } from '../../../core/components';
import { ModalSection, Message, ButtonSet, Button } from '../../../core/components/styled';
import RubiconDispatchesNotesTableRow from '../forms/RubiconDispatchesNotesTableRow';
import translate from '../../../core/services/translate';
import * as Ducks from '../../ducks/notes';
import { every } from 'lodash-es';

const tableCells = [
  { name: 'notes', label: translate('common.note'), width: '35%' },
  { name: 'sourceName', label: translate('common.source'), width: '25%' },
  { name: 'reason', label: translate('common.reason'), width: '25%' },
  { name: 'date', label: translate('common.timestamp'), width: '15%' },
];

interface Props {
  onCancel(isReload: boolean, pristine: boolean): void;
}

const RubiconDispatchesNotesModal: React.FC<Props> = ({ onCancel }) => {
  const dispatch = useDispatch();

  const allNotes = useSelector((state: AppState) => state.fleet.notes.notes);
  const isLoading = useSelector((state: AppState) => state.fleet.notes.isLoading);
  const isReload = useSelector((state: AppState) => state.fleet.notes.isReload);
  const modal = useSelector((state: AppState) => state.fleet.notes.modal);
  
  const rawNotes =
    !!modal && !modal.isWorkOrderNote
      ? allNotes.filter(note => !!note.createdByUserID && note.createdByUserID.replace(/\s+/g, '').toLowerCase() === 'vendorportal')
      : allNotes.filter(note => !!note.createdByUserID && note.createdByUserID.replace(/\s+/g, '').toLowerCase() !== 'vendorportal');

  const allOpenForms = rawNotes
    .map((note, index) => ({ note, index }))
    .filter(item => item.note.isFresh)
    .map(item => isPristine(`rubiconDispatchesNotesFor${item.index}`));

  const formPristine = useSelector((state: AppState) =>
    every(
      allOpenForms.map(isPristineSelector => isPristineSelector(state)),
      true,
    ),
  );

  const notes = React.useMemo(
    () =>
      rawNotes
        .sort((a, b) => {
          if (a.isFresh) {
            return 1;
          }

          const aDate = a.noteDate ? new Date(a.noteDate) : new Date();
          const bDate = b.noteDate ? new Date(b.noteDate) : new Date();

          if (aDate < bDate) {
            return -1;
          }

          if (aDate > bDate) {
            return 1;
          }

          return 0;
        })
        .map(note => ({ note })),
    [rawNotes],
  );

  const freshNoteExists = !!rawNotes.find(note => note.isFresh);

  const onClose = React.useCallback(() => {
    onCancel(isReload, formPristine);
  }, [onCancel, isReload, formPristine]);

  const addNote = React.useCallback(() => {
    dispatch(Ducks.addFreshNote());
  }, [dispatch]);

  if (!modal) {
    return null;
  }

  return (
    <Modal padding="medium no no" size="medium" title={modal.title} subTitle={modal.subtitle} onClose={onClose}>
      <ModalSection padding="no" isLoading={isLoading} overflow="visible">
        {!!notes.length && (
          <Table withTopBorder cells={tableCells} rows={notes} rowComponent={RubiconDispatchesNotesTableRow} />
        )}

        {!notes.length && <Message padding="no no sMedium sMedium">{translate('autoDispatch.noNotes')}</Message>}

        {!freshNoteExists && !modal.isWorkOrderNote && (
          <ButtonSet margin="sMedium no sMedium">
            <PopoverWrapper
              triggerButton={
                <Button type="button" color="primary" line onClick={addNote} id="add-note">
                  + {translate('autoDispatch.addNote')}
                </Button>
              }
              size="large"
            />
          </ButtonSet>
        )}
      </ModalSection>
    </Modal>
  );
};

export default RubiconDispatchesNotesModal;
