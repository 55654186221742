import { combineReducers } from 'redux';
import { reducer as routeImportsReducer } from './routeImports';
import { reducer as routeImportStatusesReducer } from './routeImportStatuses';
import { reducer as routeImportCreationReducer } from './routeImportCreation';
import { reducer as routeImportDetailsReducer } from './routeImportDetails';

export { loadRouteImports, resetRouteImports } from './routeImports';
export {
  loadRouteImportDetails,
  saveRouteImportDetails,
  resetRouteImportDetails,
  vendorIdSelector,
  routeImportIdSelector,
  routeImportStatusTypeIdSelector,
} from './routeImportDetails';
export { loadRouteImportStatuses, resetRouteImportStatuses } from './routeImportStatuses';
export { createRouteImport, resetRouteImportCreation } from './routeImportCreation';

export const reducer = combineReducers({
  routeImports: routeImportsReducer,
  routeImportCreation: routeImportCreationReducer,
  routeImportStatuses: routeImportStatusesReducer,
  routeImportDetails: routeImportDetailsReducer,
});
