import React from 'react';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { connectToMessagingSocket, loadMessageThreads } from '../ducks/messaging';
import { currentUserId, currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DashboardFleetInsightsCount } from 'src/dashboard/components/styled';
import { disconnectMessagingWebsocket } from '../services/messaging';
import { MessageComposer } from './MessageComposer';
import { MessageThreads } from './MessageThreads';
import { MessagingActiveThread } from './MessagingActiveThread';
import { MessagingContainerDiv } from '../styles/MessagingContainer';
import { setMessagesMapControl } from 'src/dashboard/ducks/mapControls';
import { useSelector } from 'src/core/hooks/useSelector';
import FloatingCollapsible from 'src/core/components/FloatingCollapsible';
import store from 'src/store';
import translate from 'src/core/services/translate';

export const MessagingContainer: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const userId = useSelector(currentUserId)!;
  const activeThread = useSelector(s => s.messaging.activeThread);
  const sendNewMessage = useSelector(s => s.messaging.sendNewMessage);
  const numberOfUnreadMessages = useSelector(s =>
    s.messaging.messageThreads.reduce((prev, curr) => prev + curr.numberOfUnreadMessages, 0),
  );
  const controlSpacing = useSelector(state => state.dashboard.mapControls.spacing);
  const feedbackControlWidth = useSelector(state => state.dashboard.mapControls.feedback.width);

  useEffect(() => {
    connectToMessagingSocket(vendorId, userId)(dispatch, store.getState);
    loadMessageThreads(vendorId)(dispatch);
  }, [dispatch, vendorId, userId]);

  useEffect(() => () => disconnectMessagingWebsocket(), []);

  const getContent = () => {
    if (activeThread) {
      return <MessagingActiveThread />;
    }

    if (sendNewMessage) {
      return <MessageComposer />;
    }

    return <MessageThreads />;
  };

  const setMessagesRef = (element: HTMLDivElement | null) => {
    if (element) {
      dispatch(setMessagesMapControl({ width: element.offsetWidth, height: element.offsetHeight, isVisible: true }));
    } else {
      dispatch(setMessagesMapControl({ isVisible: false }));
    }
  };

  const offsetX = controlSpacing * 2 + feedbackControlWidth;

  return (
    <MessagingContainerDiv offsetX={offsetX} offsetY={controlSpacing}>
      <FloatingCollapsible
        title={translate('messaging.messages')}
        minWidthWhenClosed={150}
        renderAfterTitle={() =>
          numberOfUnreadMessages > 0 && (
            <DashboardFleetInsightsCount>
              {numberOfUnreadMessages > 99 ? '99+' : numberOfUnreadMessages}
            </DashboardFleetInsightsCount>
          )
        }
        onInstanceChange={setMessagesRef}
      >
        {getContent()}
      </FloatingCollapsible>
    </MessagingContainerDiv>
  );
};
