import React from 'react';

interface OnClickAwayProps {
  ref: React.MutableRefObject<any>;
  handler(): void;
}

const useClickAway = ({ ref, handler }: OnClickAwayProps) =>
  React.useEffect(() => {
    const handleClickAway = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener('click', handleClickAway);

    return () => {
      document.removeEventListener('click', handleClickAway);
    };
  }, [ref, handler]);

export default useClickAway;
