import React, { PureComponent, Fragment } from 'react';
import { find } from 'lodash-es';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';

import { Input } from '../../../core/components';
import { Container, ButtonSet, Button } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import recurringServicesInitialValueSelector from '../../services/recurringServicesInitialValueSelector';
import { InputMask } from '../../../opportunity/components/styled';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { AppState } from '../../../store';

interface ComponentProps {
  workOrderIdentifier: any;
  onCancel(...args: any): any;
}

interface ReduxProps {
  initialValues: any;
  rateCodesState: any[];
  response: any;
}

type PropsWithoutReduxForm = ReduxProps & ComponentProps;

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class AcceptProcessedServicesForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, onCancel, pristine, workOrderIdentifier, rateCodesState } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <Container>
          {rateCodesState.map((fees, index) => (
            <Fragment key={index}>
              {fees && (
                <Fragment>
                  <InputMask>$</InputMask>
                  <Field
                    name={`workOrdersInfo.settings${workOrderIdentifier}.rateCodes[${index}].rate`}
                    component={Input}
                    type="number"
                    label={`${fees.rateDescr} (${translate(`opportunity.${fees.uom ? fees.uom.toLowerCase() : ' '}`)})`}
                    size="small"
                    margin="xSmall"
                    disabled={!fees.overridable}
                  />
                </Fragment>
              )}
            </Fragment>
          ))}
        </Container>

        <ButtonSet>
          <Button type="submit" color="primary">
            {translate('opportunity.submit')}
          </Button>
          <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

const formSelector = formValueSelector('acceptProcessedServices');

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const workOrdersInfo = find(state.payments.processedServices.workOrdersInfo, {
    workOrderIdentifier: ownProps.workOrderIdentifier,
  }) as any;

  return {
    initialValues: recurringServicesInitialValueSelector(state.payments.processedServices.workOrdersInfo),
    response: formSelector(state, `workOrdersInfo.settings${ownProps.workOrderIdentifier}.response`),
    // rateCodes: formSelector(state, `workOrdersInfo.settings${ownProps.workOrderIdentifier}.rateCodes`),
    rateCodesState: workOrdersInfo.rateCodes,
  };
};

export default connect(mapStateToProps)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'acceptProcessedServices',
    onSubmitFail: focusFirstInvalidField,
  })(AcceptProcessedServicesForm),
);
