import { useDispatch } from 'react-redux';

import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { loadVehicles } from 'src/fleet/ducks';
import { loadFuelTicket } from 'src/routes/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import FuelTicketEditorModal from './FuelTicketEditorModal';

type Props = {
  onClose: (formPristine?: boolean) => void;
  routeId: number;
  fuelTicketId?: number;
};

export default function FuelTicketEditorModalResolver(props: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  const loadDependencies = () => {
    if (!vendorId) return Promise.reject();
    const showActiveTrucks = true;
    const vehicleTypeId = undefined;
    const date = routeSummary?.date;

    const dependencies = [loadVehicles(vendorId, showActiveTrucks, vehicleTypeId, date)(dispatch)];
    if (props.fuelTicketId) dependencies.push(loadFuelTicket(props.fuelTicketId)(dispatch));

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={FuelTicketEditorModal}
      successProps={{ ...props }}
      onError={props.onClose}
    />
  );
}
