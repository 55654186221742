import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupSubtitle,
  MapGLPopupTitle,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import { getVehicleIconType } from 'src/common/components/VehiclePositionMarker';
import { VEHICLE_ICONS, VEHICLE_STATUSES } from 'src/common/constants';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { Link } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import translate from 'src/core/services/translate';
import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { time } from 'src/utils/services/formatter';

export default function YRouteMapVehiclePositionsGLPopup() {
  const dispatch = useDispatch();

  const { vehiclePositions } = useSelector((state: any) => state.routes.routeMapDetails.routeMapDetails);
  const { selectedFeature } = useSelector((state: any) => state.routes.mapControls);
  const { route } = useSelector((state: any) => state.routes.route);

  const vehiclePosition = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.vehiclePositions) {
      return undefined;
    }

    return vehiclePositions.find((vehiclePosition: any) => vehiclePosition.vehicle.id === selectedFeature.id);
  }, [selectedFeature, vehiclePositions]);

  if (!vehiclePosition) return null;

  const vehicleIconType = getVehicleIconType(
    route.vehicleTypeName,
    vehiclePosition.isStationary,
    vehiclePosition.isActive,
  );

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={vehiclePosition.coordinates.latitude}
      longitude={vehiclePosition.coordinates.longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopup>
        <MapGLPopupTitle
          beforeTitleContent={
            <Box mr={8} width={48} height={48}>
              <img src={VEHICLE_ICONS[vehicleIconType]} width="48" height="48" alt={route.vehicleTypeName} />
            </Box>
          }
          title={vehiclePosition.vehicle.name}
        />

        <MapGLPopupSubtitle subtitle={vehiclePosition.vehicleSubTypeName || '-'} />

        <MapGLPopupContent>
          <LabeledDataView
            noWrap
            width="calc(100% - 10px)"
            size="small"
            label={translate('common.timestamp')}
            value={time(vehiclePosition.eventDateTime)}
          />

          {!!route.driverName && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('drivers.driverName')}
              value={route.driverName}
            />
          )}

          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('vehicles.vehicleStatus')}
            value={VEHICLE_STATUSES[vehiclePosition.vehicleStatus].name}
          />

          <LabeledDataView noWrap width="calc(100% - 10px)" size="small">
            <Box textDecoration="underline">
              <Link
                to={`/fleet/vehicles/${vehiclePosition.vehicle.id}/inspection-data?&source=routes/route-tracker/${route.id}`}
              >
                {translate('vehicles.vehicleInspections.viewInspections')}
              </Link>
            </Box>
          </LabeledDataView>
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
}
