import React, { ChangeEvent, useState } from 'react';

import moment from 'moment';
import { FieldArray, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { Button, ModalFixedFooter, ModalSection } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { AdvRoute } from '../pages/routes/RouteTrackerPage';
import RouteReschedulerFieldArray from './RouteReschedulerFieldArray';
import { some, filter } from 'lodash-es';
import { RouteReschedulerRow } from './RouteReschedulerTableRow';

interface RouteToReschedule extends AdvRoute {
  newRouteDate?: Date | string;
  isChecked: boolean;
}

export interface RouteReschedulerFormValues {
  allTargetDate: Date | string;
  routes: RouteToReschedule[];
}

interface PropsWithoutReduxForm {
  closeModal: () => void;
  isSnowPlowRoute?: boolean;
  isStreetSweeperRoute?: boolean;
}

type RouteReschedulerFormProps = PropsWithoutReduxForm &
  InjectedFormProps<RouteReschedulerFormValues, PropsWithoutReduxForm>;

const routeSchedulerFormSelector = formValueSelector('routeSchedulerForm');

const RouteReschedulerForm: React.FC<RouteReschedulerFormProps> = ({
  change,
  closeModal,
  handleSubmit,
  initialValues: { routes = [] },
  isSnowPlowRoute,
  isStreetSweeperRoute,
}) => {
  const routeValues: RouteToReschedule[] = useSelector(state => routeSchedulerFormSelector(state, 'routes'));

  const [validationErrors, setValidationErrors] = useState<RouteReschedulerRow[]>([]);

  let validRouteReschedules = 0;
  const checkedRoutes = [];
  if (routeValues && routeValues.length) {
    routeValues.forEach(route => {
      if (route.isChecked) checkedRoutes.push(route.routeId);
    });
    validRouteReschedules = routeValues.filter(r => !!r.newRouteDate).length;
  }

  const allChecked = checkedRoutes.length === routes.length;
  const partial = checkedRoutes.length > 0 && checkedRoutes.length < routes.length;

  const checkAllRoutes = (e: ChangeEvent<HTMLInputElement>) => {
    routeValues.forEach((_, index) => {
      if (checkedRoutes.length > 0) {
        change(`routes[${index}].isChecked`, false);
      } else {
        change(`routes[${index}].isChecked`, true);
      }
    });
  };

  const updateTargetDateAllRoutes = (date: Date | string | null) => {
    const mDate = moment(date);
    routeValues.forEach((route, index) => {
      const routeDate = moment(isSnowPlowRoute || isStreetSweeperRoute ? route.date : route.routeDate);
      if (
        route.isChecked &&
        !mDate.isBefore(moment(routeDate).subtract(30, 'days')) &&
        !mDate.isAfter(moment(routeDate).add(30, 'days')) &&
        !mDate.isSame(moment(routeDate))
      ) {
        change(`routes[${index}].newRouteDate`, date);
      } else {
        change(`routes[${index}].newRouteDate`, null);
      }
    });
    setTimeout(() => {
      change('allTargetDate', null);
    }, 0.5);
  };

  const validate = (values: RouteReschedulerRow[]) => {
    const errors = filter(values, v =>
      some(
        values,
        val =>
          val !== v &&
          val.routeName === v.routeName &&
          val.newRouteDate &&
          v.newRouteDate &&
          val.newRouteDate === v.newRouteDate,
      ),
    );
    setValidationErrors(errors);
    return errors.length ? translate('routes.rescheduleErrors.duplicateRoute') : undefined;
  };

  return (
    <form onSubmit={handleSubmit}>
      <ModalSection minHeight="500px">
        <FieldArray
          allChecked={allChecked}
          checkAllRoutes={checkAllRoutes}
          component={RouteReschedulerFieldArray}
          isSnowPlowRoute={isSnowPlowRoute}
          isStreetSweeperRoute={isStreetSweeperRoute}
          name="routes"
          partial={partial}
          updateTargetDateAllRoutes={updateTargetDateAllRoutes}
          validate={[validate]}
          validationErrors={validationErrors}
        />
      </ModalSection>
      <ModalFixedFooter align="center" padding="medium no">
        <Button id="cancel-route-reschedule-button" color="alert" line onClick={closeModal} margin="no small no no">
          {translate('common.cancel')}
        </Button>
        <Button
          id="save-route-reschedule-button"
          color="primary"
          type="submit"
          margin="no small no no"
          disabled={!validRouteReschedules || !!validationErrors.length}
        >
          {translate('routes.rescheduleXRoutes', { noOfRoutes: validRouteReschedules })}
        </Button>
      </ModalFixedFooter>
    </form>
  );
};

export default reduxForm<RouteReschedulerFormValues, PropsWithoutReduxForm>({
  form: 'routeSchedulerForm',
})(RouteReschedulerForm);
