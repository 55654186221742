import React, { PureComponent } from 'react';

import { arrayMove } from 'react-sortable-hoc';
import { connect } from 'react-redux';
import { map, size } from 'lodash-es';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { AppState } from '../../../store';
import { Button, ButtonSet, Grid, GridColumn, Message, PanelSection } from '../../../core/components/styled';
import { FlagLocationFormTableRow } from './';
import { LocationFlags } from '../../interfaces/LocationFlags';
import { SortableTable } from '../../../core/components';
import flagLocationFormInitialValuesSelector from '../../services/flagLocationFormInitialValuesSelector';
import translate from '../../../core/services/translate';

interface ComponentProps {
  onCancel: (pristine: boolean) => void;
  locationFlags?: LocationFlags[];
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

interface State {
  locationFlags?: LocationFlags[];
  initialValuesLoaded: boolean;
}

class FlagLocationForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { locationFlags: undefined, initialValuesLoaded: false };
  }

  componentDidUpdate() {
    if (!!size(this.props.locationFlags) && !this.state.initialValuesLoaded) {
      this.setState({ initialValuesLoaded: true, locationFlags: this.props.locationFlags });
    }
  }

  onSortOrderChange = (oldIndex: number, newIndex: number) => {
    const locationFlags = arrayMove(this.state.locationFlags || [], oldIndex, newIndex);
    this.resetOrderNumbers(locationFlags);

    this.setState({ locationFlags });
  };

  resetOrderNumbers = (locationFlags: LocationFlags[]) =>
    map(locationFlags, ({ technicalName }, index) => this.props.change(`${technicalName}.orderNo`, index + 1));

  render() {
    const { handleSubmit, onCancel, pristine } = this.props;
    const { locationFlags } = this.state;

    const locationFlagsTableCells = [
      { name: 'dragHandle', width: '5%' },
      { name: 'locationFlag', label: translate('vendors.flag'), width: '35%' },
      { name: 'isActive', label: translate('common.active'), width: '15%' },
      { name: 'requirePicture', label: translate('vendors.requirePicture'), width: '20%', align: 'right' },
      { name: 'email', label: translate('common.emailSummary'), width: '25%' },
    ];

    return (
      <form onSubmit={handleSubmit}>
        <PanelSection vertical>
          {!!size(locationFlags) && (
            <SortableTable
              cells={locationFlagsTableCells}
              rows={locationFlags}
              rowComponent={FlagLocationFormTableRow}
              sort={this.onSortOrderChange}
            />
          )}

          {!size(locationFlags) && <Message padding="sMedium">{translate('vendors.noLocationFlags')}</Message>}
          <Grid centered>
            <GridColumn size="5/12">
              <ButtonSet margin="medium no">
                <Button type="submit" color="primary">
                  {translate('common.save')}
                </Button>
                <Button type="button" color="secondary" margin="no small" onClick={() => onCancel(pristine)}>
                  {translate('common.cancel')}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const locationFlags = state.vendors.locationFlags.locationFlags;
  return {
    locationFlags,
    initialValues: flagLocationFormInitialValuesSelector(state.vendors.locationFlags),
  };
};

export default connect(mapStateToProps)(
  reduxForm<any, ComponentProps>({
    form: 'flagLocation',
    enableReinitialize: true,
  })(FlagLocationForm),
);
