
import React, { ChangeEvent } from 'react';
import { Button, ButtonSet, Text } from 'src/core/components/styled';
import { map } from 'lodash-es';
import translate from 'src/core/services/translate';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from '../../../../core/services/confirm';
import { Table, UnconnectedCheckbox } from 'src/core/components';
// import CityWorksServiceRequestErrorsTableRow from './CityWorksServiceRequestErrorsTableRow';
import { TableCell, TableRow } from '../../../../core/components/styled';
import moment from 'moment';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { deleteServiceRequestErrors, loadCityWorksConfigurationErrors } from 'src/vendors/ducks/cityWorksConfigurations';
import { useDispatch } from 'react-redux';
import { createErrorNotification } from 'src/core/services/createNotification';
import { stringOrDate } from 'react-big-calendar';
interface IRowProps {
  id: number;
  status: string;
  workOrder: number;
  errorMessage: string;
  dateOccurred: stringOrDate;
  error: number;
  isChecked: boolean;
  checkConfigError: (id: number, e: ChangeEvent<HTMLInputElement>) => void;
}
const CityWorksServiceRequestErrorsTableRow: React.FC<IRowProps> = ({
  checkConfigError,
  isChecked,
  workOrder,
  id,
  status,
  errorMessage,
  dateOccurred
}) => (
  <TableRow>
    <TableCell width="5%" onClick={e => e.stopPropagation()} padding="defaultCellVertical xSmall">
      <UnconnectedCheckbox
        block
        checked={isChecked}
        onChange={e => checkConfigError(id, e)}
        onClick={e => e.stopPropagation()}
        id={`config-error-${id}-checkbox`}
      />
    </TableCell>
    <TableCell width="15%">{workOrder}</TableCell>
    <TableCell width="15%">{moment(dateOccurred).format('MM/DD/YYYY')}</TableCell>
    <TableCell width="20%">{status}</TableCell>
    <TableCell width="45%">{errorMessage}</TableCell>
  </TableRow>
);


export default function CityWorksServiceRequestErrorsSection() {
  const serviceRequestErrors = useSelector(state => state.vendors.cityWorks.dataErrors || []);
  const [allErrorsChecked, setAllErrorsChecked] = React.useState<boolean>(false);
  const [selectedErrors, setSelectedErrors] = React.useState<number[]>([]);
  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();

  const checkAllConfigError = () => {
    const configErrorList: number[] = [];
    serviceRequestErrors.forEach(configError => {
      if (selectedErrors.length === 0) {
        configErrorList.push(configError.id);
      }
    });

    setAllErrorsChecked(!allErrorsChecked);
    setSelectedErrors(configErrorList);
  };

  const checkConfigError = (id: number, e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const updatedSelectedErrors = [...selectedErrors];
    const indexDelete = updatedSelectedErrors.indexOf(id);
    if (indexDelete > -1) {
      updatedSelectedErrors.splice(indexDelete, 1);
    } else {
      updatedSelectedErrors.push(id);
    }
    setSelectedErrors(updatedSelectedErrors);
  };


  const getServiceRequestsErrorTableCells = [
    {
      name: 'selectAll',
      component: UnconnectedCheckbox,
      componentProps: {
        onChange: checkAllConfigError,
        checked: selectedErrors.length === serviceRequestErrors.length,
        partial: 0 < selectedErrors.length && selectedErrors.length < serviceRequestErrors.length,
      },
      width: '5%',
      padding: 'defaultCellVertical xSmall',
    },
    {
      name: 'workOrder',
      label: translate('vendors.cityworks.workOrder'),
      width: '15%',
      sortable: false,
    },
    {
      name: 'dateOccurred',
      label: translate('vendors.cityworks.dateOccurred'),
      width: '25%',
      sortable: false,
    },
    {
      name: 'status',
      label: translate('vendors.cityworks.status'),
      width: '20%',
      sortable: false,
    },
    {
      name: 'errorMessage',
      label: translate('vendors.cityworks.error'),
      width: '45%',
      sortable: false,
    },
  ];

  const serviceRequestErrorsList = map(serviceRequestErrors, requestError => {
    return {
      ...requestError,
      isChecked: selectedErrors.includes(requestError.id),
    };
  });


  const dismissError = async () => {
    if (!(await confirm(translate('vendors.open311.alertMessages.dismissErrorMessage')))) {
      return;
    }
    deleteServiceRequestErrors(
      vendorId,
      selectedErrors,
    )(dispatch)
      .then(() => {
        loadCityWorksConfigurationErrors(vendorId)(dispatch)
      })
      .catch(() => {
        createErrorNotification(translate('vendors.open311.alertMessages.serviceRequestDismissError'));
      });
  };


  return (
    <>
      {serviceRequestErrors.length ? (
        <Table
          height={"medium"}
          cells={getServiceRequestsErrorTableCells}
          rows={serviceRequestErrorsList}
          rowProps={{
            checkConfigError: checkConfigError,
          }}
          rowComponent={CityWorksServiceRequestErrorsTableRow}
        />
      ) : (
        <Text block align="center">
          {translate('vendors.open311.noServiceRequestErrors')}
        </Text>
      )}
      {!!serviceRequestErrors.length && (
        <ButtonSet margin="sMedium no">
          <Button disabled={selectedErrors.length <= 0} type="button" color="alert" line onClick={dismissError}>
            {translate('vendors.open311.dismissError')}
          </Button>
        </ButtonSet>
      )}
    </>
  );
}
