import styled, { css } from 'styled-components';

import { mapper, sizeMapper } from 'src/utils/styles';

interface FormLabelProps {
  disabled?: boolean;
  margin?: string;
  noTransform?: boolean;
  noWrapLabel?: boolean;
  size?: 'normal' | 'small' | 'medium';
}

const FormLabel = styled.label<FormLabelProps>`
  grid-column: 1/-1;
  display: inline-block;
  pointer-events: none;
  text-transform: capitalize;
  line-height: ${props => mapper(props.size, { small: '1', normal: '20px' }, 'normal')};
  font-size: ${props => mapper(props.size, { small: '10px', medium: '12px', normal: '14px' }, 'normal')};
  color: ${props => props.theme.grayDark};
  transform: ${p => (p.noTransform ? 'initial' : 'translateY(23px)')};
  position: relative;
  z-index: 1;
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    props.noWrapLabel &&
    css`
      white-space: nowrap;
    `}
`;

export default FormLabel;
