import React from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';

import { RouteSettingsFormSpacer } from '../styled/RouteSettingsForm';
import { Input, Table } from '../../../core/components';
import { isOptionalNumberOrZero, maxLength4 } from '../../../utils/services/validator';
import { StreetTurnTimesEntry } from '../../interfaces/RouteSettings';
import { TableRow, TableCell } from '../../../core/components/styled';
import { TURN_TYPES, DIRECTIONS } from '../../constants/routeSettingsForm';
import translate from '../../../core/services/translate';
import normalizeNumber from '../../../core/services/normalizeNumber';

const defaultCells = [
  {
    name: 'highway',
    label: translate('routes.routeSettings.turnTypeLabels.highway'),
    width: '25%',
  },
  {
    name: 'arterial',
    label: translate('routes.routeSettings.turnTypeLabels.arterial'),
    width: '25%',
  },
  {
    name: 'local',
    label: translate('routes.routeSettings.turnTypeLabels.local'),
    width: '25%',
  },
];

type Props = WrappedFieldArrayProps<StreetTurnTimesEntry>;

const RouteSettingsTurnTimeField: React.FC<Props> = ({ fields }) => {
  return (
    <>
      {[1, 2, 3, 4].map((turnType, index) => {
        const itemsForThisType = fields
          .getAll()
          .map((row, initialIndex) => ({
            ...row,
            initialIndex,
          }))
          .filter(row => row.turnType === turnType);

        return (
          <React.Fragment key={turnType}>
            {!!index && <RouteSettingsFormSpacer />}

            <Table
              noBackground
              cells={[
                {
                  name: 'description',
                  label: translate(`routes.routeSettings.turnTypes.${TURN_TYPES[turnType]}`),
                  width: '25%',
                },
                ...defaultCells,
              ]}
            >
              {itemsForThisType.map(item => (
                <TableRow height={60} key={item.id}>
                  <TableCell width="25%">
                    {item.direction ? translate(`routes.routeSettings.directions.${DIRECTIONS[item.direction]}`) : ''}
                  </TableCell>

                  <TableCell width="25%">
                    <Field
                      name={`${fields.name}[${item.initialIndex}].highway`}
                      type="number"
                      component={Input}
                      validate={[isOptionalNumberOrZero, maxLength4]}
                      props={{
                        width: '100%',
                        margin: 'no',
                        placeholder: translate('routes.routeSettings.seconds'),
                      }}
                      normalize={normalizeNumber}
                    />
                  </TableCell>

                  <TableCell width="25%">
                    <Field
                      name={`${fields.name}[${item.initialIndex}].arterial`}
                      type="number"
                      component={Input}
                      validate={[isOptionalNumberOrZero, maxLength4]}
                      props={{
                        width: '100%',
                        margin: 'no',
                        placeholder: translate('routes.routeSettings.seconds'),
                      }}
                      normalize={normalizeNumber}
                    />
                  </TableCell>

                  <TableCell width="25%">
                    <Field
                      name={`${fields.name}[${item.initialIndex}].local`}
                      type="number"
                      component={Input}
                      validate={[isOptionalNumberOrZero, maxLength4]}
                      props={{
                        width: '100%',
                        margin: 'no',
                        placeholder: translate('routes.routeSettings.seconds'),
                      }}
                      normalize={normalizeNumber}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default RouteSettingsTurnTimeField;
