import { push } from 'connected-react-router';
import { debounce, filter, find, get, map, omit, orderBy, size } from 'lodash-es';
import { useEffect, useMemo, useState, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getFormValues } from 'redux-form';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { MapDragHandle, SortableTable, Table, UpdateTrackerRouteSwitchWrapper } from 'src/core/components';
import {
  Button,
  Grid,
  GridColumn,
  MapContainer,
  Message,
  Panel,
  PanelSection,
  PanelSectionGroup,
  PanelSectionLoading,
} from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import translate from 'src/core/services/translate';
import { CANCELED, FINALIZED, SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE } from 'src/routes/constants';
import { saveRouteTemplate, setSearchTerm, updateRouteSequenceStatus } from 'src/routes/ducks';
import { RouteTemplate } from 'src/routes/interfaces/RouteTemplates';
import { checkRouteHasScheduledDailyRoutes } from 'src/routes/services/routeTemplate';
import { routeSequencingStatusSelector, technicalNameByVehicleTypeIdSelector } from 'src/vendors/ducks';
import { isNavi3FeatureEnabled, isTravelPathNavigationFeatureEnabled } from 'src/vendors/ducks/features';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import RouteTemplateSequenceSummaryPanel from './RouteTemplateSequenceSummaryPanel';
import { Resizable } from 're-resizable';
import RouteSequenceMapGL from './routeSequenceMap/RouteSequenceMapGL';
import { TABLE_ROW_HEIGHT, TOP } from 'src/core/constants';
import { multiWordAndSearch } from 'src/core/services/search';
import RouteTemplateSequenceEditorPageStopsWithZeroTableRow from './RouteTemplateSequenceEditorPageStopsWithZeroTableRow';
import createPopover from 'src/core/services/createPopover';
import { OrderNumberForm } from '../../forms';
import { arrayMove } from 'react-sortable-hoc';
import RouteTemplateSequenceEditorPageTableRow from './RouteTemplateSequenceEditorPageTableRow';
import RouteSequenceStopsSearchForm from '../../forms/RouteSequenceStopsSearchForm';
import RouteSequenceInstructions from './RouteSequenceInstructions';

const RouteTemplateSequenceEditorPageNew = () => {
  const dispatch = useDispatch();
  const { goBack } = useHistory();

  const [hasScheduledDailyRoutes, setHasScheduledDailyRoutes] = useState(false);
  const [routeLocationsModified, setRouteLocationsModified] = useState<any[]>([]);

  const { lastLocations } = useSelector(state => state.core);
  const { isLoading, isLoadingLocations, isSaving } = useSelector(state => state.routes.routeTemplate);
  const { isUpdatingSequenceStatus } = useSelector(state => state.routes.routeSequence);
  const locationsInfo = useSelector(state => ({
    isLoading: state.routes.routeTemplate.isLoadingLocations,
    limit: state.routes.routeTemplate.locationsLimit,
    loadedPages: state.routes.routeTemplate.loadedPages,
    total: state.routes.routeTemplate.locationsTotal,
  }));

  const vendorId = useSelector(currentVendorId);
  const route = useSelector(state => state.routes.routeTemplate.routeTemplate || ({} as RouteTemplate));
  const routeSequence = useSelector(state => state.routes.routeSequence.routeSequence || []);
  const isTravelPathFeatureEnabled = useSelector(isTravelPathNavigationFeatureEnabled);
  const isNaviV3FeatureEnabled = useSelector(isNavi3FeatureEnabled);
  const routeSequencingEnabled = useSelector(state => routeSequencingStatusSelector(state.vendors.features.features));
  const routeStatuses = useSelector(state => state.routes.routeSequence.routeStatuses);
  const formValues = useSelector(getFormValues('updateTrackerRouteSwitchWrapper')) as any;
  const shouldRecreateRoutes = formValues ? formValues.shouldRecreateRoutes : false;
  const routeSequenceInstructionsOpen = useSelector(
    state => state.routes.routeSequenceInstructions.isRouteSequenceOpen,
  );

  const { searchTerm } = useSelector(state => state.routes.filters);

  const vehicleTypeTechnicalName = useSelector(state =>
    technicalNameByVehicleTypeIdSelector(
      state.fleet.vehicleTypesForVendor,
      (state.routes.routeTemplate.routeTemplate as any).vehicleTypeId,
    ),
  );

  const routeLocationsWithNewOrderNumber = useMemo(
    () =>
      orderBy(
        map(route.routeLocations, routeLocation => ({
          ...routeLocation,
          newOrderNumber: get(
            find(routeSequence, { routeLocationId: routeLocation.serviceContractRouteTemplateId }),
            'orderNo',
          ),
        })),
        ['newOrderNumber'],
      ),
    [routeSequence, route.routeLocations],
  );
  useEffect(() => {
    setRouteLocationsModified(routeLocationsWithNewOrderNumber);
  }, [routeLocationsWithNewOrderNumber]);

  const filteredRouteLocations = useMemo(
    () =>
      filter(
        routeLocationsModified,
        routeLocation =>
          !searchTerm ||
          multiWordAndSearch(routeLocation.customer.name, searchTerm) ||
          multiWordAndSearch(routeLocation.location.name, searchTerm) ||
          multiWordAndSearch(
            `${routeLocation.location.name}, ${routeLocation.location.address.streetNumber} ${routeLocation.location.address.street}`,
            searchTerm,
          ),
      ),
    [routeLocationsModified, searchTerm],
  );

  const routeLocationsWithZeroStopNumber = useMemo(
    () => filter(filteredRouteLocations, stop => stop?.newOrderNumber === 0),
    [filteredRouteLocations],
  );
  const routeLocationsWithStopNumber = useMemo(
    () => filter(filteredRouteLocations, stop => stop && stop.newOrderNumber && stop.newOrderNumber > 0),
    [filteredRouteLocations],
  );

  useEffect(() => {
    checkRouteHasScheduledDailyRoutes(route.id, vendorId).then(response => {
      setHasScheduledDailyRoutes(response);
    });
  }, [route.id, vendorId]);

  const cancelRouteSequence = async () => {
    if (!(await confirm(translate('routes.alertMessages.cancelRouteSequence')))) {
      return;
    }

    const jobId = get(find(routeStatuses, { routeId: route.id }), 'jobId');
    updateRouteSequenceStatus({
      routeId: route.id,
      jobId,
      status: CANCELED,
      sequenceSourceTypeId: SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE,
    })(dispatch).then(() => dispatch(push(`/routes/route-templates/${route.id}`)));
  };

  const saveRouteSequence = async () => {
    const locations = map(routeLocationsModified, routeLocation => ({
      ...omit(routeLocation, ['newOrderNumber']),
      orderNumber: routeLocation.newOrderNumber,
      id: routeLocation.serviceContractRouteTemplateId,
    }));

    const locationsWithZeroStopNumber = locations.filter(location => location.orderNumber === 0).length;
    const routeData = { ...route, locations, saveStops: true };
    const shouldCreateTravelPath = isTravelPathFeatureEnabled || isNaviV3FeatureEnabled;

    let isOrderNumberChangedByUser = false;
    routeLocationsWithNewOrderNumber.forEach(routeStop => {
      if (
        locations.find(routeLocation => routeLocation.id === routeStop.serviceContractRouteTemplateId)?.orderNumber !==
        routeStop.newOrderNumber
      ) {
        isOrderNumberChangedByUser = true;
      }
    });

    if (locationsWithZeroStopNumber > 0)
      createErrorNotification(translate('routes.alertMessages.routeSaveErrorWithNewStopNumberZero'));
    else {
      saveRouteTemplate(
        routeData,
        false,
        shouldRecreateRoutes,
        shouldCreateTravelPath,
        isOrderNumberChangedByUser,
      )(dispatch)
        .then(data => {
          const jobId = get(find(routeStatuses, { routeId: data.id }), 'jobId');
          updateRouteSequenceStatus({
            routeId: data.id,
            jobId,
            status: FINALIZED,
            sequenceSourceTypeId: SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE,
          })(dispatch).then(() => dispatch(push(`/routes/route-templates/${data.id}`)));
          createSuccessNotification(translate('routes.alertMessages.routeSaved'));
        })
        .catch(() => createErrorNotification(translate('routes.alertMessages.routeSaveError')));
    }
  };

  const goBackToPreviousPage = () => {
    const hasLastLocations = Object.keys(lastLocations).length > 1;
    return hasLastLocations ? goBack() : dispatch(push('/routes/route-templates'));
  };

  const resetOrderNumbers = (routeStops: any[]) =>
    map(routeStops, (routeLocation, index) => ({
      ...routeLocation,
      newOrderNumber: index + 1,
    }));

  const onRouteLocationDrop = (event: { nativeEvent: { dataTransfer: any } }, insertRouteLocationAtIndex: number) => {
    const { dataTransfer } = event.nativeEvent;
    const routeLocationId = dataTransfer?.getData('routeLocationId')
      ? Number(dataTransfer.getData('routeLocationId'))
      : -1;
    onSortOrderChangeLocationWithZeroStopNumber(0, insertRouteLocationAtIndex, routeLocationId);
  };

  const onSortOrderChange = (oldIndex: number, newIndex: number) => {
    const routeLocationsWithStopNumber = filter(
      routeLocationsModified,
      routeLocation => routeLocation.newOrderNumber !== 0,
    );
    const routeLocationsWithZeroStopNumber = filter(
      routeLocationsModified,
      routeLocation => routeLocation.newOrderNumber === 0,
    );
    const routeLocationsReorderedMoved = arrayMove(routeLocationsWithStopNumber, oldIndex, newIndex);
    const routeLocationsReordered = resetOrderNumbers(routeLocationsReorderedMoved);
    setRouteLocationsModified([...routeLocationsReordered, ...routeLocationsWithZeroStopNumber]);
  };

  const onSortOrderChangeLocationWithZeroStopNumber = (oldIndex: number, newIndex: number, locationId: number) => {
    const routeLocationsWithStopNumber = filter(
      routeLocationsModified,
      routeLocation => routeLocation.newOrderNumber !== 0,
    );

    const routeLocationCurrent = map(
      filter(routeLocationsModified, routeLocation => routeLocation.location.id === locationId),
      routeLocation => ({
        ...routeLocation,
        newOrderNumber: newIndex + 1,
      }),
    );
    const routeLocationsWithZeroStopNumber = filter(
      routeLocationsModified,
      routeLocation => routeLocation.newOrderNumber === 0 && routeLocation.location.id !== locationId,
    );

    if (routeLocationCurrent[0]) routeLocationsWithStopNumber.splice(newIndex, 0, routeLocationCurrent[0]);
    const routeLocationsWithStopNumberReordered = resetOrderNumbers(routeLocationsWithStopNumber);

    setRouteLocationsModified([...routeLocationsWithZeroStopNumber, ...routeLocationsWithStopNumberReordered]);
  };

  const onOrderNumberFormSubmit = (oldOrderNumber: number, newOrderNumber: number, locationId: number) => {
    if (oldOrderNumber === 0) {
      onSortOrderChangeLocationWithZeroStopNumber(oldOrderNumber - 1, newOrderNumber - 1, locationId);
    } else {
      onSortOrderChange(oldOrderNumber - 1, newOrderNumber - 1);
    }
    !!closePopover && closePopover();
  };

  let closePopover: () => void = () => {};

  const openOrderNumberPopover = (oldOrderNumber: number, event: MouseEvent<HTMLElement>, locationId: number) => {
    event.stopPropagation();
    closePopover = createPopover(
      event.currentTarget,
      OrderNumberForm,
      {
        onSubmit: ({ orderNumber }: any) => onOrderNumberFormSubmit(oldOrderNumber, Number(orderNumber), locationId),
        maxOrderNumber: size(route.routeLocations),
        initialValues: { orderNumber: oldOrderNumber },
      },
      { position: TOP },
    );
  };

  const onSearchTermChange = debounce(searchTerm => {
    dispatch(setSearchTerm(searchTerm));
  }, 200);

  const routeLocationsTableCells = useMemo(
    () => [
      { name: 'dragHandle', width: '5%' },
      { name: 'newStopNumber', label: translate('routes.newStopNumber'), width: '11%' },
      { name: 'stopNumber', label: translate('routes.stopNumber'), width: '11%' },
      { name: 'customer', label: translate('common.customer'), width: '30%' },
      { name: 'accountStatus', label: translate('routes.accountStatus'), width: '10%' },
      { name: 'service', label: translate('routes.service'), width: '15%' },
      { name: 'dayOfService', label: translate('routes.dayOfService'), width: '18%' },
    ],
    [],
  );

  const generateTable = () => {
    const virtualizedProps = {
      itemSize: TABLE_ROW_HEIGHT,
      height: Math.min(filteredRouteLocations.length * TABLE_ROW_HEIGHT, TABLE_ROW_HEIGHT * 8) || 1,
    };

    if (locationsInfo && locationsInfo.isLoading && !filteredRouteLocations.length) {
      return <PanelSectionLoading />;
    }

    return !size(filteredRouteLocations) ? (
      <Message padding="sMedium">{translate('routes.noRouteLocations')}</Message>
    ) : (
      <>
        <PanelSection>
          {!!size(routeLocationsWithZeroStopNumber) && (
            <Table
              cells={routeLocationsTableCells}
              rowComponent={RouteTemplateSequenceEditorPageStopsWithZeroTableRow}
              rows={routeLocationsWithZeroStopNumber}
              rowProps={{
                openOrderNumberPopover: openOrderNumberPopover,
                routeId: route.id,
              }}
              tableHeading={translate('routes.unableToSequenceRoute')}
            />
          )}
        </PanelSection>
        <PanelSection>
          {!!size(routeLocationsWithStopNumber) && (
            <SortableTable
              cells={size(routeLocationsWithZeroStopNumber) ? [] : routeLocationsTableCells}
              rows={routeLocationsWithStopNumber}
              rowComponent={RouteTemplateSequenceEditorPageTableRow}
              rowProps={{
                onRouteLocationDrop: onRouteLocationDrop,
                openOrderNumberPopover: openOrderNumberPopover,
                routeId: route.id,
                vehicleTypeTechnicalName,
              }}
              virtualized
              virtualizedProps={virtualizedProps}
              sort={onSortOrderChange}
              withClickableRows
            />
          )}
        </PanelSection>
      </>
    );
  };

  const isLoadingData = locationsInfo?.isLoading || isLoading || isLoadingLocations;

  return (
    <PageContent isLoading={isSaving || isUpdatingSequenceStatus}>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction onClick={goBackToPreviousPage} id="back-button">
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle textTransform="none">{route.routeTemplateName}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions>
          <Button
            color="alert"
            onClick={cancelRouteSequence}
            line
            margin="no xSmall no no"
            disabled={isLoadingData || !routeSequencingEnabled}
          >
            {translate('routes.cancelSequenceUpdates')}
          </Button>
          <Button
            color="primary"
            margin="no xSmall no no"
            onClick={saveRouteSequence}
            disabled={isLoadingData || !routeSequencingEnabled}
          >
            {translate('routes.saveStopSequence')}
          </Button>

          <UpdateTrackerRouteSwitchWrapper hasScheduledDailyRoutes={hasScheduledDailyRoutes} />
        </PageActions>
      </PageHeader>
      {routeSequenceInstructionsOpen && <RouteSequenceInstructions />}
      <Panel margin="no no xLarge">
        <PanelSectionGroup>
          <PanelSection withBorder>
            <RouteTemplateSequenceSummaryPanel />
          </PanelSection>
          <PanelSection isLoading={isLoadingData} centered>
            <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }}>
              <MapContainer size="large">
                <RouteSequenceMapGL
                  routeLocations={filteredRouteLocations}
                  vehicleTypeId={route.vehicleTypeId}
                  isTemplate
                />
              </MapContainer>
            </Resizable>
          </PanelSection>
          <PanelSection withBorder>
            <Grid multiLine>
              <GridColumn size={'12/12'}>
                <RouteSequenceStopsSearchForm
                  initialValues={{ routeStopsSearchTerm: searchTerm }}
                  onSearchTermChange={onSearchTermChange}
                />
              </GridColumn>
              <GridColumn size="12/12" padding="no">
                {generateTable()}
              </GridColumn>
            </Grid>
          </PanelSection>
        </PanelSectionGroup>
      </Panel>
    </PageContent>
  );
};

export default RouteTemplateSequenceEditorPageNew;
