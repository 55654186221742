import { http } from '../../core/services/http';
import { DisposalTicket } from '../interfaces/DisposalTicket';
import { DisposalTrip } from '../interfaces/DisposalTrip';

export const getDisposalTickets = (vendorId: number, routeId: number) =>
  http
    .get<DisposalTicket[]>(`${vendorId}/routes/${routeId}/routeDetail/disposalTickets`)
    .then(response => response.data);

export const getDisposalTrips = (vendorId: number, routeId: number) =>
  http.get<DisposalTrip[]>(`${vendorId}/routes/${routeId}/routeDetail/disposalTrips`).then(response => response.data);
