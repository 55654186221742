import { createSelector } from 'reselect';
import { identity, orderBy } from 'lodash-es';
import Cookie from 'js-cookie';

import { Districts } from 'src/vendors/interfaces/DivisionAccess';
import { HAULER_PROFILE_DIVISION_COOKIE } from '../../haulerProfile/constants/HaulerProfileDivisionCookie';

export const getFirstDivisionId = (divisions: Districts[]) => {
  if (divisions.length > 0) {
    const assignedDivisionsOrderByName = orderBy(divisions, 'name', 'asc');
    const getFirstDivision = assignedDivisionsOrderByName[0].value;
    return getFirstDivision;
  }
  return undefined;
};

const getDefaultDivisionFormInitialValues = (defaultDivisionState: any) => {
  const firstDivisionId = getFirstDivisionId(defaultDivisionState?.divisions);
  if (firstDivisionId) return { divisionId: Cookie.get(HAULER_PROFILE_DIVISION_COOKIE) || firstDivisionId };
  else return { divisionId: undefined };
};

const defaultDivisionFormInitialValuesSelector = createSelector(getDefaultDivisionFormInitialValues, identity);

export default defaultDivisionFormInitialValuesSelector;
