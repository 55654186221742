import React, { PureComponent } from 'react';
import { DropdownOption } from './Dropdown';
import { FormGroup, Select as FormSelect } from './styled';

interface Props {
  children?: any;
  disabled?: boolean;
  id?: string;
  margin?: string;
  normalize?: (value: string) => string | number;
  onChange?: (value: number | string) => void;
  onClick?: (event: any) => void;
  options?: DropdownOption[];
  value?: number | string;
}

interface State {
  value?: number | string;
}

class UnconnectedSelect extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { value: props.value };
  }

  onChange = (event: any) => {
    const { normalize } = this.props;
    const value = normalize ? normalize(event.target.value) : event.target.value;
    this.setState({ value });

    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  render() {
    const { disabled, options, children, margin, ...props } = this.props;

    return (
      <FormGroup margin={margin}>
        <FormSelect {...props} onChange={this.onChange} disabled={disabled}>
          {!!options &&
            options.map((option, index) => (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            ))}
        </FormSelect>
      </FormGroup>
    );
  }
}

export default UnconnectedSelect;
