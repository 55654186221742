import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const BOTH = 1;
export const LEFT = 2;
export const RIGHT = 3;

export const SERVICING_SIDES = mapKeys(
  [
    { id: BOTH, name: translate('vendors.servicingSides.both') },
    { id: LEFT, name: translate('vendors.servicingSides.left') },
    { id: RIGHT, name: translate('vendors.servicingSides.right') },
  ],
  'id',
);
