import translate from 'src/core/services/translate';

export const billThruQuarterOptions = [
  {
    label: translate('customers.billing.previousQuarter'),
    value: -1,
  },
  {
    label: translate('customers.billing.currentQuarter'),
    value: 0,
  },
  {
    label: translate('customers.billing.nextQuarter'),
    value: 1,
  },
];
