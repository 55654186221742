import { Field, WrappedFieldArrayProps } from 'redux-form';
import { formValueSelector } from 'redux-form';

import { Checkbox, DatePicker, Input, ActionButtonTooltip } from 'src/core/components';
import { isRequired, isDecimalUpTo2, isDateValidValidator } from 'src/utils/services/validator';
import { NEW_SERVICE_ID } from 'src/customers/constants/serviceStatuses';
import { Rate } from 'src/customers/interfaces/Services';
import { RateStatusDropdown } from '.';
import { RateType } from 'src/finance/interfaces/ServiceRates';
import { SERVICE_DETAILS_EDITOR_FORM } from '../../forms/ServiceDetailsEditorForm';
import { TableActionButton } from 'src/core/components/styled';
import { TableCell, TableRow, Text } from 'src/core/components/styled';
import { TON_ID } from 'src/common/constants';
import { UNITS_FULL } from 'src/common/constants/unitOfMeasure';
import { useSelector } from 'src/core/hooks/useSelector';
import RateTypesByServiceTypeDropdown from './RateTypesByServiceTypeDropdown';
import UOMDropdown from './UOMDropdown';

const formSelector = formValueSelector(SERVICE_DETAILS_EDITOR_FORM);

type Props = WrappedFieldArrayProps<Rate> & {
  updateFormValue: () => void;
  cellWidths: { [key: string]: string };
};

const ServicesFormTableRow = ({ fields, updateFormValue, cellWidths }: Props) => {
  const serviceEffectiveDate: string = useSelector(state => formSelector(state, 'effectiveDate')) || '';
  const rateTypes: RateType[] = useSelector(state => state.customers.service.rateTypesByServiceType || []);

  const getUomsByRateCode = (rateCodeId?: number | string) => {
    const uomsByRateCode = rateTypes.find(rateType => Number(rateType.id) === rateCodeId)?.rateCodeServiceType[0]
      ?.unitOfMeasureTypeIds;

    return uomsByRateCode;
  };

  return fields.map((field, index) => {
    const currentRow = fields.get(index);
    const { id, rateCodeId, unitOfMeasureTypeId, typeCode: rateCode, description: rateCodeDescription } = currentRow;

    const isNewRow = id && +id < 0;

    return (
      <TableRow key={`rateCodeCell-${field}`} className="tableRow">
        <TableCell width={cellWidths.rateTypeId}>
          {isNewRow ? (
            <Field
              name={`${field}.rateCodeId`}
              component={RateTypesByServiceTypeDropdown}
              validate={[isRequired]}
              onChange={updateFormValue}
              margin="no"
              uomFieldName={`${field}.unitOfMeasureTypeId`}
            />
          ) : (
            <Text>{`${rateCode} / ${rateCodeDescription}`}</Text>
          )}
        </TableCell>
        <TableCell width={cellWidths.overridable}>
          <Field name={`${field}.overridable`} component={Checkbox} onChange={updateFormValue} />
        </TableCell>
        <TableCell width={cellWidths.rate}>
          <Field
            name={`${field}.rate`}
            component={Input}
            type="number"
            validate={[isRequired, isDecimalUpTo2]}
            onChange={updateFormValue}
            margin="no"
          />
        </TableCell>
        <TableCell width={cellWidths.uomId}>
          {isNewRow ? (
            <Field
              name={`${field}.unitOfMeasureTypeId`}
              component={UOMDropdown}
              validate={[isRequired]}
              onChange={updateFormValue}
              margin="no"
              uomsByRateCode={getUomsByRateCode(rateCodeId)}
            />
          ) : (
            <Text>{unitOfMeasureTypeId ? UNITS_FULL[unitOfMeasureTypeId]?.name : '-'}</Text>
          )}
        </TableCell>
        <TableCell width={cellWidths.quantity}>
          <Field
            name={`${field}.includedQty`}
            component={Input}
            type="number"
            validate={[isRequired, isDecimalUpTo2]}
            onChange={updateFormValue}
            margin="no"
            disabled={unitOfMeasureTypeId !== TON_ID}
          />
        </TableCell>
        <TableCell width={cellWidths.effectiveFromDate}>
          <Field
            name={`${field}.effectiveFromDate`}
            component={DatePicker}
            validate={fields.get(index).effectiveFromDate ? [isRequired] : [isRequired, isDateValidValidator]}
            onChange={updateFormValue}
            disabledDays={[{ before: new Date(serviceEffectiveDate) }]}
            margin="no"
          />
        </TableCell>
        <TableCell width={cellWidths.effectiveToDate}>
          <Field
            name={`${field}.effectiveToDate`}
            component={DatePicker}
            validate={fields.get(index).effectiveToDate ? [] : [isDateValidValidator]}
            onChange={updateFormValue}
            disabledDays={
              fields.get(index)?.effectiveFromDate && [{ before: new Date(fields.get(index).effectiveFromDate || 0) }]
            }
            margin="no"
          />
        </TableCell>
        <TableCell width={cellWidths.status}>
          <Field
            name={`${field}.status`}
            component={RateStatusDropdown}
            onChange={updateFormValue}
            dropdownProps={{ disabled: true }}
            margin="no"
          />
        </TableCell>

        <TableCell width={cellWidths.action} justifyContent="center" alignItems="center">
          {fields.get(index)?.id === NEW_SERVICE_ID && (
            <TableActionButton onClick={() => fields.remove(index)} id={`bill-${field}-delete-button`}>
              <ActionButtonTooltip icon="delete" tooltip="deleteRate" />
            </TableActionButton>
          )}
        </TableCell>
      </TableRow>
    );
  });
};

export default ServicesFormTableRow;
