import React, { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadVehicleTypes } from '../../../fleet/ducks';
import { PanelSectionLoading } from '../../../core/components/styled';
import { Resolver } from '../../../core/components';
import { RouteComponentProps } from 'react-router';
import DeviceStatusesPage from './DeviceStatusesPage';
import translate from '../../../core/services/translate';
import { loadYDeviceStatuses } from '../../ducks/deviceStatuses';
import { DuckFunction } from '../../../contracts/ducks';

interface Props extends RouteComponentProps {
  loadYDeviceStatuses: DuckFunction<typeof loadYDeviceStatuses>;
  loadVehicleTypes: DuckFunction<typeof loadVehicleTypes>;
  vendorId: number;
}

class DeviceStatusesPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { location, vendorId, loadYDeviceStatuses, loadVehicleTypes } = this.props;
    const { vehicleTypeId, sortedBy, sortOrder, page = 1, limit = 20 } = getQueryParams(location.search);

    return Promise.all([
      loadYDeviceStatuses(vendorId, vehicleTypeId, sortedBy, sortOrder, page, limit),
      loadVehicleTypes(),
    ]);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('settings.deviceStatus')}</DocumentTitle>
        <Resolver
          successComponent={DeviceStatusesPage}
          loadingComponent={PanelSectionLoading}
          resolve={this.loadDependencies}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadYDeviceStatuses, loadVehicleTypes };

export default connect(mapStateToProps, mapDispatchToProps)(DeviceStatusesPageResolver);
