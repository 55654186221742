import React, { ChangeEvent, FC, useMemo } from 'react';

import { filter, map, orderBy, size } from 'lodash-es';
import { WrappedFieldInputProps } from 'redux-form';

import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { MultiSelect, TypedField } from '../../core/components';
import { MultiSelectProps } from '../../core/components/MultiSelect';
import { NONE_ID } from '../constants/dispatchBoard';

import translate from '../../core/services/translate';
import { RouteTemplateStatus } from './styled/RouteTemplateBuilderMap';
import { useSelector } from 'src/core/hooks/useSelector';
import { ServiceZone } from '../interfaces/ServiceZones';
import { NONE_ZERO_ID } from '../constants/routePriorityTypes';

const formatText = (selectedOptions: number[], allOptionsSelected: boolean) =>
  allOptionsSelected
    ? translate('routes.allServiceZones')
    : size(selectedOptions) === 1 && selectedOptions[0] === NONE_ID
    ? translate('routes.noServiceZonesPlaceholder')
    : translate('routes.xServiceZonesSelected', { selected: size(selectedOptions) });

interface Props extends DropDownFieldProps {
  input: WrappedFieldInputProps;
  multiSelectProps?: Partial<MultiSelectProps>;
  excludeServiceZonesIds?: number[];
  includeNoneOption?: boolean;
  inactiveCheckedByDefault?: boolean;
}

const ServiceZonesMultiSelect: FC<Props> = ({
  includeNoneOption,
  input,
  label,
  multiSelectProps,
  placeholder,
  withLabel,
  withPlaceholder,
  excludeServiceZonesIds,
  inactiveCheckedByDefault,
}) => {
  const serviceZones = useSelector(state => state.routes.serviceZones.serviceZones);

  const handleChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    input.onChange(value);
    multiSelectProps?.onChange && multiSelectProps.onChange(value);
  };

  const serviceZonesOptions = useMemo(() => {
    const filtered = map(
      orderBy(
        filter(
          serviceZones,
          (serviceZone: ServiceZone) => !!serviceZone.id && !excludeServiceZonesIds?.includes(serviceZone.id),
        ),
        ['isActive', 'name'],
        ['desc', 'asc'],
      ),
      (serviceZone: ServiceZone) => ({
        label: serviceZone.name,
        value: serviceZone.id,
        isUncheckedByDefault: !serviceZone.isActive && !inactiveCheckedByDefault,
        renderCustomOption: () => (
          <>
            {serviceZone.name}
            {!serviceZone.isActive && <RouteTemplateStatus neutral>{translate('common.inactive')}</RouteTemplateStatus>}
          </>
        ),
      }),
    );
    if (includeNoneOption) {
      filtered.unshift({
        label: translate('routes.noServiceZone'),
        value: NONE_ZERO_ID,
        isUncheckedByDefault: false,
        renderCustomOption: () => <>{translate('routes.noServiceZone')}</>,
      });
    }
    return filtered;
  }, [excludeServiceZonesIds, includeNoneOption, inactiveCheckedByDefault, serviceZones]);

  return (
    <TypedField
      name={input.name}
      component={MultiSelect}
      onChange={handleChange}
      props={{
        label: label || (withLabel ? translate('routes.serviceZone') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('routes.serviceZone') : undefined),
        options: serviceZonesOptions,
        formatText,
        ...multiSelectProps,
      }}
    />
  );
};

export default ServiceZonesMultiSelect;
