import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { loadVehicleTypes, loadVehicleTypesForVendor } from '../../ducks';
import { TypedResolver } from '../../../core/components';
import { PageLoading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import VehicleTypesPage from './VehicleTypesPage';
import translate from '../../../core/services/translate';
import { DuckFunction } from '../../../contracts/ducks';
import { AppState } from '../../../store';

interface Props {
  vendorId?: number;
  loadVehicleTypes: DuckFunction<typeof loadVehicleTypes>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
}

class VehicleTypesPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { vendorId, loadVehicleTypes, loadVehicleTypesForVendor } = this.props;

    const dependencies = [loadVehicleTypes()];

    if (vendorId) {
      dependencies.push(loadVehicleTypesForVendor(vendorId));
    }

    return Promise.all(dependencies);
  };

  render() {
    return (
      <>
        <DocumentTitle>{translate('vehicles.configVehicleTypes')}</DocumentTitle>

        <TypedResolver
          successComponent={VehicleTypesPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/fleets/vehicles"
        />
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorId(state),
});

const mapDispatchToProps = { loadVehicleTypes, loadVehicleTypesForVendor };

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTypesPageResolver);
