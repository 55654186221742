import { pick, map } from 'lodash-es';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';

const transformLoadRouteImportStatuses = (routeImportStatuses: any) => ({
  ...pick(routeImportStatuses, 'total'),
  routeImportStatuses: map(routeImportStatuses.haulerRouteImportDetails, routeImportDetails => ({
    ...routeImportDetails,
    importStatusName: translate(createTranslationKey(routeImportDetails.statusType, 'routeImport.importStatuses')),
  })),
});

export default transformLoadRouteImportStatuses;
