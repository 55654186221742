import React from 'react';

import { date } from '../../../utils/services/formatter';
import { Equipment } from '../../interfaces/RubiconDispatches';
import {
  Popover,
  DetailsListBodySection,
  DetailsListItemTitle,
  DetailsListItemDescription,
} from '../../../core/components/styled';
import { PopoverDetailsContainer } from '../styled/RubiconDispatches';
import translate from '../../../core/services/translate';

const renderDetail = (translation: string, value: string) => (
  <DetailsListBodySection>
    <DetailsListItemTitle size="small">{translate(translation)}</DetailsListItemTitle>
    <DetailsListItemDescription size="small" weight="normal">
      {value}
    </DetailsListItemDescription>
  </DetailsListBodySection>
);

export const RubiconEquipmentPopover: React.SFC<{
  service: Equipment;
  startDate: string;
  endDate?: string;
}> = ({ service, startDate, endDate }) => (
  <Popover>
    <DetailsListItemDescription weight="medium" align="left" size="medium">
      {service.type}
    </DetailsListItemDescription>
    <div>
      <PopoverDetailsContainer>
        {renderDetail(
          'common.equipmentType',
          service.subType ? translate(`haulerProfile.equipments.equipmentSubTypes.${service.subType}`) : service.type,
        )}
        {renderDetail('customers.frequency', service.frequency || '-')}
      </PopoverDetailsContainer>
      <PopoverDetailsContainer>
        {renderDetail('customers.equipmentSize', service.size ? `${parseInt(service.size)} ${service.units}` : '-')}
        {renderDetail('customers.wasteMaterial', service.wasteMaterial || '-')}
      </PopoverDetailsContainer>
      <PopoverDetailsContainer>
        {renderDetail('opportunity.quantity', `${service.quantity || 0} Container(s)`)}
        {renderDetail('payment.serviceDescription', service.serviceDescription)}
      </PopoverDetailsContainer>
      <PopoverDetailsContainer>
        {!!startDate && renderDetail('common.startDate', date(startDate))}
        {!!endDate && renderDetail('common.endDate', date(endDate))}
      </PopoverDetailsContainer>
    </div>
  </Popover>
);
