import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { loadPermissions, shouldLoadPermissions } from 'src/account/ducks/permissions';
import { DocumentTitle } from 'src/common/components';
import { PageLoading } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { createUrl, getQueryParams } from 'src/utils/services/queryParams';
import {
  checkIsBillingActive,
  completeStripeAccountSetup,
  createStripeAccount,
  refreshStripeAccountLink,
} from 'src/vendors/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import StripeOnboardingPage from './StripeOnboardingPage';

const StripeOnboardingPageResolver = () => {
  const dispatch = useDispatch();

  const { pathname, search } = useLocation();
  const { callback } = getQueryParams(search);

  const vendorId = useSelector(currentVendorId);
  const permissionsShouldBeLoaded = useSelector(shouldLoadPermissions);

  const loadDependencies = async () => {
    const dependencies: Promise<any>[] = [];

    if (permissionsShouldBeLoaded) {
      dependencies.push(loadPermissions()(dispatch));
    }

    const isBillingActive = await checkIsBillingActive(vendorId)(dispatch);

    if (isBillingActive) {
      dispatch(push('/account-settings'));
    }

    switch (callback) {
      case 'reauth':
        dependencies.push(refreshStripeAccountLink(vendorId)(dispatch));
        break;

      case 'return':
        const stripeAccountStatus = await completeStripeAccountSetup(vendorId)(dispatch);
        if (!stripeAccountStatus?.isActive) {
          dispatch(push(createUrl(pathname, search, { callback: null })));
          dependencies.push(refreshStripeAccountLink(vendorId)(dispatch));
        } else {
          createSuccessNotification(translate('finance.stripeIntegration.accountActivatedSetUp'));
          dispatch(push('/account-settings'));
        }
        break;

      default:
        dependencies.push(createStripeAccount(vendorId)(dispatch));
        break;
    }

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('finance.stripeIntegration.stripeOnboarding')}</DocumentTitle>
      <TypedResolver
        redirectOnError="/finance"
        resolve={loadDependencies}
        loadingComponent={PageLoading}
        successComponent={StripeOnboardingPage}
      />
    </>
  );
};

export default StripeOnboardingPageResolver;
