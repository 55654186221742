import React from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'src/core/hooks/useSelector';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { loadVehicleTypesForVendor } from 'src/fleet/ducks';
import { currentUserId, currentVendorId } from 'src/vendors/services/currentVendorSelector';
import DefaultFiltersModal from './DefaultFiltersModal';
import { loadFiltersSettings } from 'src/common/ducks/filters';
import { AppState } from 'src/store';
import { loadServiceTypes } from 'src/common/ducks';
import { loadServiceZones } from 'src/routes/ducks';
import { loadSupervisors } from 'src/routes/ducks/supervisors';

const DefaultFiltersPreferencesModalResolver: React.FC<ModalProps> = ({ closeModal }: ModalProps) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const userId = useSelector((state: AppState) => currentUserId(state));
  // TODO: load here the default user filters
  const loadDependencies = React.useCallback(() => {
    if (!userId || !vendorId) {
      return Promise.reject();
    }
    return Promise.all([
      loadVehicleTypesForVendor(vendorId)(dispatch),
      loadSupervisors(vendorId)(dispatch),
      loadServiceZones(vendorId)(dispatch),
      loadServiceTypes()(dispatch),
      loadFiltersSettings(vendorId, userId)(dispatch),
    ]);
  }, [userId, vendorId, dispatch]);

  return (
    <Resolver
      successComponent={DefaultFiltersModal}
      successProps={{ closeModal, isAdmin: false }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default DefaultFiltersPreferencesModalResolver;
