import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  loadStreetNetworkServiceAreas as doLoadStreetNetworkServiceAreas,
  saveStreetNetworkServiceAreas as doSaveStreetNetworkServiceAreas,
  updateStreetNetworkServiceAreas as doUpdateStreetNetworkServiceAreas,
} from 'src/customers/services/streetNetwork';
import { loadCustomerLocationsForMap as doLoadCustomerLocationsForMap } from 'src/customers/services/customers';

import { loadHaulerLocationsForMapbox } from 'src/dashboard/services/loadVendorLocations';

import { ServiceAreas } from '../interfaces/StreetNetwork';
import { HaulerLocation } from 'src/dashboard/interfaces/haulerLocations';
import { CustomerLocation, CustomerLocationSimple } from '../interfaces/Customers';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';

// Actions
const START_LOAD_SERVICE_AREA = 'customers/streetNetworkServiceAreas/START_LOAD_SERVICE_AREA';
const COMPLETE_LOAD_SERVICE_AREA = 'customers/streetNetworkServiceAreas/COMPLETE_LOAD_SERVICE_AREA';
const FAIL_LOAD_SERVICE_AREA = 'customers/streetNetworkServiceAreas/FAIL_LOAD_SERVICE_AREA';

const START_SAVE_SERVICE_AREA = 'customers/streetNetworkServiceAreas/START_SAVE_SERVICE_AREA';
const COMPLETE_SAVE_SERVICE_AREA = 'customers/streetNetworkServiceAreas/COMPLETE_SAVE_SERVICE_AREA';
const FAIL_SAVE_SERVICE_AREA = 'customers/streetNetworkServiceAreas/FAIL_SAVE_SERVICE_AREA';

const START_UPDATE_SERVICE_AREA = 'customers/streetNetworkServiceAreas/START_UPDATE_SERVICE_AREA';
const COMPLETE_UPDATE_SERVICE_AREA = 'customers/streetNetworkServiceAreas/COMPLETE_UPDATE_SERVICE_AREA';
const FAIL_UPDATE_SERVICE_AREA = 'customers/streetNetworkServiceAreas/FAIL_UPDATE_SERVICE_AREA';

const COMPLETE_LOAD_HAULER_LOCATIONS = 'customers/streetNetworkServiceAreas/COMPLETE_LOAD_HAULER_LOCATIONS';
const FAIL_LOAD_HAULER_LOCATIONS = 'customers/streetNetworkServiceAreas/FAIL_LOAD_HAULER_LOCATIONS';
const RESET_HAULER_LOCATIONS = 'customers/streetNetworkServiceAreas/RESET_HAULER_LOCATIONS';

const SHOW_CUSTOMER_LOCATIONS = 'customers/streetNetworkServiceAreas/SHOW_CUSTOMER_LOCATIONS';

const COMPLETE_LOAD_CUSTOMER_LOCATIONS = 'customers/streetNetworkServiceAreas/COMPLETE_LOAD_CUSTOMER_LOCATIONS';
const FAIL_LOAD_CUSTOMER_LOCATIONS = 'customers/streetNetworkServiceAreas/FAIL_LOAD_CUSTOMER_LOCATIONS';
const RESET_CUSTOMER_LOCATIONS = 'customers/streetNetworkServiceAreas/RESET_CUSTOMER_LOCATIONS';

const SET_VIEWPORT = 'customers/streetNetworkServiceAreas/SET_VIEWPORT';
const SET_SELECTED_FEATURE = 'customers/streetNetworkServiceAreas/SET_SELECTED_FEATURE';
const CLEAR_SELECTED_FEATURE = 'customers/streetNetworkServiceAreas/CLEAR_SELECTED_FEATURE';
const SET_SELECTED_HAULER_LOCATIONS_FOR_DISPLAY =
  'customers/streetNetworkServiceAreas/SET_SELECTED_HAULER_LOCATIONS_FOR_DISPLAY';

const RESET = 'customers/streetNetworkServiceAreas/RESET';

type FeatureNamespace = 'customerLocations' | 'haulerLocations' | 'serviceAreas';

type SelectedFeature = {
  namespace: FeatureNamespace;
  id: number;
  extraData?: any;
};

interface State {
  isLoading: boolean;
  isSaving: boolean;
  isUpdating: boolean;
  viewport: MapGLViewport;
  serviceAreas?: ServiceAreas[];
  selectedFeature?: SelectedFeature;
  haulerLocations: HaulerLocation[];
  selectedHaulerLocationsForDisplay: HaulerLocation[];
  customerLocations: CustomerLocationSimple[];
  showCustomerLocations: boolean;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  isSaving: false,
  isUpdating: false,
  haulerLocations: [],
  selectedHaulerLocationsForDisplay: [],
  customerLocations: [],
  viewport: {},
  showCustomerLocations: false,
  selectedFeature: undefined,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_SERVICE_AREA:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_SERVICE_AREA:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceAreas: action.serviceAreas,
        },
      });

    case FAIL_LOAD_SERVICE_AREA:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceAreas: undefined,
        },
      });

    case START_SAVE_SERVICE_AREA:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE_SERVICE_AREA:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE_SERVICE_AREA:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_UPDATE_SERVICE_AREA:
      return update(state, {
        $merge: {
          isUpdating: true,
        },
      });

    case COMPLETE_UPDATE_SERVICE_AREA:
      return update(state, {
        $merge: {
          isUpdating: false,
        },
      });

    case FAIL_UPDATE_SERVICE_AREA:
      return update(state, {
        $merge: {
          isUpdating: false,
        },
      });

    case COMPLETE_LOAD_HAULER_LOCATIONS:
      return update(state, {
        $merge: {
          haulerLocations: action.haulerLocations,
          isLoading: false,
        },
      });

    case FAIL_LOAD_HAULER_LOCATIONS:
      return update(state, {
        $merge: {
          haulerLocations: [],
          isLoading: false,
        },
      });

    case RESET_HAULER_LOCATIONS:
      return update(state, {
        $merge: {
          haulerLocations: [],
        },
      });

    case COMPLETE_LOAD_CUSTOMER_LOCATIONS:
      return update(state, {
        $merge: {
          customerLocations: action.customerLocations,
          isLoading: false,
        },
      });

    case FAIL_LOAD_CUSTOMER_LOCATIONS:
      return update(state, {
        $merge: {
          customerLocations: [],
          isLoading: false,
        },
      });

    case SHOW_CUSTOMER_LOCATIONS:
      return update(state, {
        $merge: {
          showCustomerLocations: action.showCustomerLocations,
        },
      });

    case SET_SELECTED_HAULER_LOCATIONS_FOR_DISPLAY:
      return update(state, {
        $merge: {
          selectedHaulerLocationsForDisplay: action.selectedHaulerLocationsForDisplay,
        },
      });

    case RESET_CUSTOMER_LOCATIONS:
      return update(state, {
        $merge: {
          customerLocations: [],
        },
      });

    case SET_VIEWPORT:
      return update(state, {
        $merge: {
          viewport: action.viewport,
        },
      });

    case SET_SELECTED_FEATURE:
      return update(state, {
        $merge: {
          selectedFeature: action.selectedFeature,
        },
      });

    case CLEAR_SELECTED_FEATURE:
      return update(state, {
        $merge: {
          selectedFeature: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          isLoading: false,
          isSaving: false,
          viewport: {},
          selectedFeature: undefined,
          haulerLocations: [],
          selectedHaulerLocationsForDisplay: [],
          customerLocations: [],
          showCustomerLocations: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoadServiceAreas = () => ({
  type: START_LOAD_SERVICE_AREA,
});

const completeLoadServiceAreas = (serviceAreas: ServiceAreas) => ({
  type: COMPLETE_LOAD_SERVICE_AREA,
  serviceAreas,
});

const failLoadServiceAreas = () => ({
  type: FAIL_LOAD_SERVICE_AREA,
});

const startSaveServiceAreas = () => ({
  type: START_SAVE_SERVICE_AREA,
});

const completeSaveServiceAreas = () => ({
  type: COMPLETE_SAVE_SERVICE_AREA,
});

const failSaveServiceAreas = () => ({
  type: FAIL_SAVE_SERVICE_AREA,
});

const startUpdateServiceAreas = () => ({
  type: START_UPDATE_SERVICE_AREA,
});

const completeUpdateServiceAreas = () => ({
  type: COMPLETE_UPDATE_SERVICE_AREA,
});

const failUpdateServiceAreas = () => ({
  type: FAIL_UPDATE_SERVICE_AREA,
});

const completeLoadHaulerLocations = (haulerLocations: HaulerLocation[]) => ({
  type: COMPLETE_LOAD_HAULER_LOCATIONS,
  haulerLocations,
});

const failLoadHaulerLocations = () => ({
  type: FAIL_LOAD_HAULER_LOCATIONS,
});

const completeLoadCustomerLocations = (customerLocations: CustomerLocation[]) => ({
  type: COMPLETE_LOAD_CUSTOMER_LOCATIONS,
  customerLocations,
});

const failLoadCustomerLocations = () => ({
  type: FAIL_LOAD_CUSTOMER_LOCATIONS,
});

export const setSelectedHaulerLocationsForDisplay = (selectedHaulerLocationsForDisplay: HaulerLocation[]) => ({
  type: SET_SELECTED_HAULER_LOCATIONS_FOR_DISPLAY,
  selectedHaulerLocationsForDisplay,
});

export const resetStreetNetworkServiceAreas = () => ({
  type: RESET,
});

export const resetNetworkServiceAreaCustomerLocations = () => ({
  type: RESET_CUSTOMER_LOCATIONS,
});

export const resetNetworkServiceAreaHaulerLocations = () => ({
  type: RESET_HAULER_LOCATIONS,
});

// Thunks

export const loadStreetNetworkServiceAreas =
  (vendorId: number, noLoadingIndicator?: boolean) => async (dispatch: Dispatch) => {
    !noLoadingIndicator && dispatch(startLoadServiceAreas());
    const serviceAresPromise = doLoadStreetNetworkServiceAreas(vendorId);

    serviceAresPromise
      .then(serviceAreas => dispatch(completeLoadServiceAreas(serviceAreas)))
      .catch(() => dispatch(failLoadServiceAreas()));

    return serviceAresPromise;
  };

export const saveStreetNetworkServiceAreas =
  (vendorId: number, serviceAreas: ServiceAreas) => async (dispatch: Dispatch) => {
    dispatch(startSaveServiceAreas());
    const saveServiceAreasPromise = doSaveStreetNetworkServiceAreas(vendorId, serviceAreas);

    saveServiceAreasPromise
      .then(() => dispatch(completeSaveServiceAreas()))
      .catch(() => dispatch(failSaveServiceAreas()));

    return saveServiceAreasPromise;
  };

export const updateStreetNetworkServiceAreas =
  (vendorId: number, serviceAreas: ServiceAreas) => async (dispatch: Dispatch) => {
    dispatch(startUpdateServiceAreas());
    const updateServiceAreasPromise = doUpdateStreetNetworkServiceAreas(vendorId, serviceAreas);

    updateServiceAreasPromise
      .then(() => dispatch(completeUpdateServiceAreas()))
      .catch(() => dispatch(failUpdateServiceAreas()));

    return updateServiceAreasPromise;
  };

export const loadStreetNetworkHaulerLocations = (vendorLocationTypes?: string) => async (dispatch: Dispatch) => {
  dispatch(startLoadServiceAreas());
  const includeInactiveFacilities = true;
  const haulerLocationsPromise = loadHaulerLocationsForMapbox(vendorLocationTypes, includeInactiveFacilities);

  haulerLocationsPromise
    .then(haulerLocations => dispatch(completeLoadHaulerLocations(haulerLocations)))
    .catch(() => dispatch(failLoadHaulerLocations()));

  return haulerLocationsPromise;
};

export const loadStreetNetworkCustomerLocations = (vendorId: number) => async (dispatch: Dispatch) => {
  dispatch(startLoadServiceAreas());

  const customerLocationsPromise = doLoadCustomerLocationsForMap(vendorId);

  customerLocationsPromise
    .then(customerLocations => dispatch(completeLoadCustomerLocations(customerLocations)))
    .catch(() => dispatch(failLoadCustomerLocations()));

  return customerLocationsPromise;
};

export const setStreetNetworkServiceAreasViewport = (viewport: MapGLViewport) => ({
  type: SET_VIEWPORT,
  viewport,
});

export const setStreetNetworkServiceAreasSelectedFeature = (selectedFeature: SelectedFeature) => ({
  type: SET_SELECTED_FEATURE,
  selectedFeature,
});

export const clearStreetNetworkServiceAreasSelectedFeature = () => ({
  type: CLEAR_SELECTED_FEATURE,
});

export const setShowCustomerLocations = (showCustomerLocations: boolean) => ({
  type: SHOW_CUSTOMER_LOCATIONS,
  showCustomerLocations,
});
