import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import { InputMask } from '../../../opportunity/components/styled';
import { Input } from '../../../core/components';
import { Container, ButtonSet, Button } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import recurringServicesInitialValueSelector from '../../services/recurringServicesInitialValueSelector';

interface ComponentProps {
  workOrderIdentifier: string;
  rateCodes: any[];
  onCancel(pristine: boolean): void;
  initialValues: any;
}

interface PropsWithoutReduxForm extends ComponentProps {}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const AcceptProcessedServicesForm: React.FC<Props> = ({
  rateCodes,
  workOrderIdentifier,
  handleSubmit,
  onCancel,
  pristine,
}) => {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Container>
        {rateCodes.map((fees, index) => (
          <Fragment key={index}>
            {fees && (
              <Fragment>
                <InputMask>$</InputMask>
                <Field
                  name={`workOrdersInfo.settings${workOrderIdentifier}.rateCodes[${index}].rate`}
                  component={Input}
                  type="number"
                  label={`${fees.rateDescr} (${translate(`opportunity.${fees.uom ? fees.uom.toLowerCase() : ' '}`)})`}
                  size="small"
                  margin="xSmall"
                  disabled={!fees.overridable}
                />
              </Fragment>
            )}
          </Fragment>
        ))}
      </Container>

      <ButtonSet>
        <Button type="submit" color="primary">
          {translate('opportunity.submit')}
        </Button>
        <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
          {translate('common.cancel')}
        </Button>
      </ButtonSet>
    </form>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    initialValues: recurringServicesInitialValueSelector(state.payments.recurringServices.workOrdersInfo),
  };
};

export default connect(mapStateToProps)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'acceptProcessedServicesForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AcceptProcessedServicesForm),
);
