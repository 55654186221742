import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import { ButtonSet, Button } from '../../../core/components/styled';
import { Input } from '../../../core/components';
import { AccountPanelLogo, AccountPanelTitle, AccountPanelError } from '../styled';
import { isRequired, minLength8, passwordComplexity } from '../../../utils/services/validator';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {
  isResetPasswordFailed: boolean;
  isResetPasswordFailedMessage: string | null;
  isCreatePassword: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const matchPassword = (value: string, allValues: any) =>
  value === allValues.password ? undefined : translate('account.mustMatchPassword');

const ResetPasswordForm: React.FC<Props> = ({
  isResetPasswordFailed,
  isResetPasswordFailedMessage,
  handleSubmit,
  isCreatePassword,
}) => (
  <form onSubmit={handleSubmit} noValidate>
    <AccountPanelLogo />

    <AccountPanelTitle>
      {isCreatePassword ? translate('account.createYourPassword') : translate('account.resetYourPassword')}
    </AccountPanelTitle>

    <Field
      name="password"
      component={Input}
      label={translate('common.password')}
      type="password"
      validate={[isRequired, minLength8, passwordComplexity]}
    />

    <Field
      name="passwordConfirmation"
      component={Input}
      label={translate('account.passwordConfirmation')}
      type="password"
      validate={[isRequired, matchPassword]}
    />

    {isResetPasswordFailed && (
      <AccountPanelError>
        {isResetPasswordFailedMessage
          ? translate(`account.${isResetPasswordFailedMessage}`)
          : translate('account.resetPasswordInvalidToken')}
      </AccountPanelError>
    )}

    <ButtonSet>
      <Button type="submit" color="primary">
        {isCreatePassword ? translate('account.createPassword') : translate('account.resetPassword')}
      </Button>
    </ButtonSet>
  </form>
);

export default reduxForm<any, PropsWithoutReduxForm>({
  form: 'resetPassword',
  onSubmitFail: focusFirstInvalidField,
})(ResetPasswordForm);
