import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { BillStatusOptions } from '../interfaces/ApplyChecks';

import { loadBillStatuses as doLoadBillStatuses } from '../services/billStatuses';

// Actions
const START_LOAD = 'finance/applyChecks/billStatuses/START_LOAD';
const COMPLETE_LOAD = 'finance/applyPayemnts/billStatuses/COMPLETE_LOAD';
const FAIL_LOAD = 'finance/applyChecks/billStatuses/FAIL_LOAD';
const RESET = 'finance/applyChecks/billStatuses/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  billStatuses: BillStatusOptions[];
} = {
  isLoading: false,
  billStatuses: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          billStatuses: action.billStatuses,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          billStatuses: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (billStatuses: any) => ({
  type: COMPLETE_LOAD,
  billStatuses,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadBillStatuses = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadBillStatusesPromise = doLoadBillStatuses();
  loadBillStatusesPromise
    .then((billStatuses: any) => dispatch(completeLoad(billStatuses)))
    .catch(() => dispatch(failLoad()));
  return loadBillStatusesPromise;
};

export const resetBillStatuses = () => ({
  type: RESET,
});
