import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { InsightConfiguration } from '../interfaces/InsightConfiguration';
import {
  loadInsightConfiguration as doLoadInsightConfiguration,
  saveInsightConfiguration as doSaveInsightConfiguration,
} from '../services/insightConfiguration';

// Actions
const START_LOAD = 'vendors/insightConfiguration/START_LOAD';
const COMPLETE_LOAD = 'vendors/insightConfiguration/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/insightConfiguration/FAIL_LOAD';
const START_SAVE = 'vendors/insightConfiguration/START_SAVE';
const COMPLETE_SAVE = 'vendors/insightConfiguration/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/insightConfiguration/FAIL_SAVE';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  insightConfiguration: [] as InsightConfiguration[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          insightConfiguration: action.insightConfiguration,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false },
      });

    case START_SAVE:
      return update(state, {
        $merge: { isSaving: true },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (insightConfiguration: InsightConfiguration[]) => ({
  type: COMPLETE_LOAD,
  insightConfiguration,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadInsightConfiguration = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadInsightConfigurationPromise = doLoadInsightConfiguration();
  loadInsightConfigurationPromise
    .then((insightConfiguration: InsightConfiguration[]) => {
      dispatch(completeLoad(insightConfiguration));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadInsightConfigurationPromise;
};

export const saveInsightConfiguration = (insightConfiguration: InsightConfiguration[]) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveInsightConfigurationPromise = doSaveInsightConfiguration(insightConfiguration);
  saveInsightConfigurationPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveInsightConfigurationPromise;
};
