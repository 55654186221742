import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { RubiconServicesOptions } from '../interfaces/RubiconServices';
import { loadRubiconServicesOptions as doLoadRubiconServicesOptions } from '../services/rubiconServices';

// Actions
const START_LOAD = 'customers/rubiconServicesOptions/START_LOAD';
const COMPLETE_LOAD = 'customers/rubiconServicesOptions/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/rubiconServicesOptions/FAIL_LOAD';
const RESET = 'customers/rubiconServicesOptions/RESET';

interface State {
  rubiconServicesOptions: RubiconServicesOptions;
  isLoading: boolean;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  rubiconServicesOptions: {},
  isLoading: false,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          rubiconServicesOptions: action.rubiconServicesOptions,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          rubiconServicesOptions: { equipments: [], materials: [], divisions: [], uom: [], rates: [], frequency: [] },
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (rubiconServicesOptions: RubiconServicesOptions) => ({
  type: COMPLETE_LOAD,
  rubiconServicesOptions,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRubiconServicesOptions = (gusId: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRubiconServiceOptionsPromise = doLoadRubiconServicesOptions(gusId);

  loadRubiconServiceOptionsPromise.then(options => dispatch(completeLoad(options))).catch(() => dispatch(failLoad()));
};
export const resetRubiconServices = () => ({
  type: RESET,
});
