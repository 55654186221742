import styled, { css } from 'styled-components';

import { Box } from './Box';
import Icon from '../Icon';

export const FloatingCollapsibleHoverBox = styled(Box)`
  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
`;

interface FloatingCollapsibleToggleIconProps {
  isOpen?: boolean;
}

export const FloatingCollapsibleToggleIcon = styled(Icon).attrs({
  icon: 'arrowDown',
})<FloatingCollapsibleToggleIconProps>`
  width: 12px;
  height: 12px;
  color: ${props => props.theme.grayDark};
  transition: transform 0.3s ease;
  transform: rotate(180deg);

  ${props =>
    props.isOpen &&
    css`
      transform: rotate(0);
    `};
`;
