import { transparentize } from 'polished';
import { colorMapper } from 'src/utils/styles';
import styled, { css } from 'styled-components';

export const DashboardMapDataInformationWrapper = styled.div<{ color: string }>`
  background: ${props => transparentize(0.9, props.color)};
  border-radius: 5px;
  box-shadow: 0 4px 30px ${props => transparentize(0.9, props.color)};
  backdrop-filter: blur(7.3px);
  -webkit-backdrop-filter: blur(7.3px);
  padding: 5px;
  & > span,
  & > div > span {
    ${props =>
      props.color &&
      css`
        color: ${colorMapper(props)};
      `};
    font-size: 11px;
    margin-bottom: 2px;
  }
`;
