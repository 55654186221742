import React from 'react';

import { connect } from 'react-redux';
import { debounce, map } from 'lodash-es';
import { useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { AppState } from 'src/store';
import { Dropdown, PanelSearch, TypedField } from '../../../core/components';
import { EXPLICIT_STATUSES, SERVICE_STATUSES } from '../../constants/status';
import { Facility } from 'src/common/interfaces/Facility';
import { getOperationalFacilityWithNoneOptions } from '../utils/vehiclesPageHooks';
import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { ROLE_TYPES, SUPERVISOR } from '../../constants/resourceRoleTypes';
import { supervisorExperienceFeatureIsEnabled } from '../../../vendors/ducks/features';
import translate from '../../../core/services/translate';

const rolesOptions = ROLE_TYPES.filter(role => role.id !== SUPERVISOR).map(role => ({
  label: role.name,
  value: role.id,
}));

const statusOptions = map(EXPLICIT_STATUSES, status => ({
  label: status.name,
  value: status.id,
}));

const serviceStatusesOptions = SERVICE_STATUSES.map(serviceStatus => ({
  label: serviceStatus.name,
  value: serviceStatus.id,
}));

interface FormValues {
  operationalFacility: number;
  roleId: number;
  searchTerm: string;
  serviceStatus: string;
  status: string;
}

interface PropsWithoutReduxForm {
  driverFacilities: Facility[];
  onOperationalFacilityChange(operationalFacility?: number): void;
  onRoleChange(roleId?: number): void;
  onSearchTermChange(term?: string): void;
  onServiceStatusChange(serviceStatus?: string): void;
  onStatusChange(status?: string): void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const ResourcesFiltersForm: React.FC<Props> = ({
  driverFacilities,
  onOperationalFacilityChange,
  onRoleChange,
  onSearchTermChange,
  onServiceStatusChange,
  onStatusChange,
}) => {
  const supervisorEnabled = useSelector(supervisorExperienceFeatureIsEnabled);

  const debouncedCallback = React.useMemo(
    () =>
      debounce((term?: string) => {
        onSearchTermChange(term);
      }, 300),
    [onSearchTermChange],
  );

  const setSearchTermDebounced = React.useCallback(debouncedCallback, [debouncedCallback]);

  const onRoleChangeHandler = (event: any, value: number) => {
    onRoleChange(value);
  };

  const onStatusChangeHandler = (event: any, value: string) => {
    onStatusChange(value);
  };

  const onServiceStatusChangeHandler = (event: any, value: string) => {
    onServiceStatusChange(value);
  };

  const onOperationalFacilityChangeHandler = (event: any, value: number) => {
    onOperationalFacilityChange(value);
  };

  const operationalFacilityOptions = getOperationalFacilityWithNoneOptions(driverFacilities);

  return (
    <form>
      <PanelSection padding="sMedium xSmall" withBorder>
        <Grid>
          <GridColumn size={supervisorEnabled ? '3/12' : '5/12'}>
            <TypedField
              name="searchTerm"
              component={PanelSearch}
              onChange={(_, newValue: FormValues['searchTerm']) => setSearchTermDebounced(newValue)}
              props={{
                margin: 'no',
                isClearable: true,
              }}
            />
          </GridColumn>

          {supervisorEnabled && (
            <GridColumn size="2/12">
              <TypedField
                name="roleId"
                component={Dropdown}
                onChange={onRoleChangeHandler}
                props={{
                  isClearable: true,
                  margin: 'no',
                  placeholder: translate('common.role'),
                  options: rolesOptions,
                }}
              />
            </GridColumn>
          )}

          <GridColumn size="2/12">
            <TypedField
              name="status"
              component={Dropdown}
              onChange={onStatusChangeHandler}
              props={{
                isClearable: true,
                margin: 'no',
                placeholder: translate('common.status'),
                options: statusOptions,
              }}
            />
          </GridColumn>

          <GridColumn size="2/12">
            <TypedField
              name="serviceStatus"
              component={Dropdown}
              onChange={onServiceStatusChangeHandler}
              props={{
                isClearable: true,
                margin: 'no',
                options: serviceStatusesOptions,
                placeholder: translate('common.serviceStatus'),
              }}
            />
          </GridColumn>

          <GridColumn size="3/12">
            <TypedField
              name="operationalFacility"
              component={Dropdown}
              onChange={onOperationalFacilityChangeHandler}
              props={{
                isClearable: true,
                margin: 'no',
                options: operationalFacilityOptions,
                placeholder: translate('common.customerTypes.operationalFacility'),
              }}
            />
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  driverFacilities: state.fleet.driver.driverFacilities,
});

export default connect(mapStateToProps)(
  reduxForm<FormValues, PropsWithoutReduxForm>({
    form: 'resourcesFilters',
    enableReinitialize: true,
  })(ResourcesFiltersForm),
);
