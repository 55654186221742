import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const SCHEDULED = 1;
export const IN_PROGRESS = 2;
export const COMPLETED = 3;

export const ROUTE_STATUSES= [
  { id: SCHEDULED, technicalName: 'Scheduled', name: translate('routes.routeStatuses.scheduled') },
  { id: IN_PROGRESS, technicalName: 'InProgress', name: translate('routes.routeStatuses.inProgress') },
  { id: COMPLETED, technicalName: 'Completed', name: translate('routes.routeStatuses.completed') },
];

export const ROUTE_STATUSES_BY_ID = mapKeys(ROUTE_STATUSES, 'id');

export const ROUTE_STATUSES_BY_TECHNICAL_NAME = mapKeys(ROUTE_STATUSES, 'technicalName');

export const ROUTE_LABEL_COLORS: { [key: number]: string } = {
  [SCHEDULED]: 'secondary',
  [IN_PROGRESS]: 'info',
  [COMPLETED]: 'success',
};
