import { transparentize } from 'polished';
import Devices from 'src/core/styles/devices';
import styled, { css } from 'styled-components';
import { Icon } from '../../../core/components';
import { loadingOverlay } from 'src/core/styles';
import { mapper, sizeMapper } from '../../../utils/styles';
interface Props {
  isHoveringOver?: boolean;
  isModal?: boolean;
  isSelected?: boolean;
  size?: string;
  height?: string;
  isError?: boolean;
  center?: boolean;
  isLoading?: boolean;
}

export const WeightTicketsCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

export const WeightTicketsCard = styled.div<Props>`
  width: 23%;
  margin: ${props => (props.isModal ? '0 2% 10px 0' : '0 2% 30px 0')};

  &:nth-child(4n) {
    margin-right: 0;
  }

  @media ${Devices.mobile} {
    width: 100%;
  }
`;

export const WeightTicketsCardFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
`;

export const ActionButtonContainer = styled.div`
  width: 10%;
`;

export const WeightTicketsCardContent = styled.div<Props>`
  display: flex;
  height: ${props => (props.isModal ? '202px' : '252px')};
  width: ${props => (props.isModal ? '202px' : '252px')};
  border: 1px solid ${props => props.theme.default};
  box-shadow: 0 3px 6px ${props => transparentize(0.98, props.theme.grayBase)};
  transition: box-shadow 0.3s ease-out;

  &:hover {
    box-shadow: 0 4px 12px ${props => transparentize(0.92, props.theme.grayBase)};
  }
`;

export const WeightTicketsImageContainer = styled.img<Props>`
  cursor: ${props => (props.isModal ? 'pointer' : 'auto')};
  height: ${props => (props.isModal ? '200px' : '250px')};
  width: ${props => (props.isModal ? '200px' : '250px')};
  ${props =>
    props.isSelected &&
    css`
      border: 4px solid ${props => props.theme.brandPrimary};
    `};
  object-fit: cover;
`;

export const WeightTicketInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const WeightTicketDetails = styled.div`
  width: 80%;
`;

export const PreviewImageContainer = styled.img`
  width: 500px;
`;

export const UploadDocumentArea = styled.div<Props>`
  width: 100%;
  height: ${props => sizeMapper(props.height, 'xxxGrande')};
  border: 5px dashed ${p => p.isError ? p.theme.brandAlert : p.theme.brandPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: ${p => p.center ? "center": "inherit"};
  background-color: ${p => (p.isHoveringOver ? transparentize(0.8, p.theme.brandPrimary) : 'inherit')};
  margin-bottom: 20px;
  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')};
    `};

  a {
    color: ${p => p.theme.brandPrimary};
  }
`;

export const WeightTicketOtherFileContainer = styled.div<Props>`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: ${props => (props.isModal ? '200px' : '250px')};
  cursor: ${props => (props.isModal ? 'pointer' : 'auto')};
  ${props =>
    props.isSelected &&
    css`
      border: 4px solid ${props => props.theme.brandPrimary};
    `};
`;

export const WeightTicketOtherFileWrapper = styled.div<Props>`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: ${props => (props.isModal ? '80px 0px' : '100px 0px')};
  width: ${props => (props.isModal ? '200px' : '250px')};
`;

export const WeightTicketOtherFileIcon = styled(Icon)<Props>`
  width: ${props => mapper(props.size, { small: '26px', medium: '30px', large: '50px' })};
  color: ${props => props.theme.brandPrimary};
`;

export const ImageSelectButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 50px;
  text-shadow: 0 0 4px ${props => transparentize(0.4, props.theme.grayBase)};
  color: #fff;
  cursor: pointer;
`;

export const WeightTicketsTableContainer = styled.div`
  height: 425px;
  overflow: auto;
`;
