import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useDispatch } from 'react-redux';

import { useSelector } from 'src/core/hooks/useSelector';
import { locale } from 'src/core/services/translate';
import { RoutePlannerCalendarWrapper } from 'src/routes/components/styled';
import { loadRoutePlannerCalendarData } from 'src/routes/ducks/routePlanner';
import { generateEvents } from 'src/routes/utils/routePlanner';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import CalendarDayWrapper from './CalendarDateCellWrapper';
import CalendarEvent from './CalendarEvent';
import CalendarToolbar from './CalendarToolbar';
import CalendarWeekHeader from './CalendarWeekHeader';

// making the current date the first day of the calendar
moment.updateLocale(locale, {
  week: {
    dow: new Date().getDay(),
    doy: new Date().getDay(),
  },
});

const localizer = momentLocalizer(moment);

interface RoutePlannerCalendarProps {
  selectedDate?: Date;
  calendarDate: Date;
  vehicleTypeIds?: number[];
  groupIds?: number[];
  setCalendarDate: (date: Date) => void;
  onSelectDate: (date?: Date) => void;
  onExport: (isDaily: boolean) => void;
  onImport: () => void;
  openScheduler: (templates: { id: number; scheduledDay: number }[], defaultDate?: Date) => void;
  openAddJobModal: (routeId?: number, vehicleTypeId?: number, date?: string) => void;
}

const RoutePlannerCalendar = ({
  selectedDate,
  calendarDate,
  vehicleTypeIds,
  groupIds,
  setCalendarDate,
  onExport,
  onImport,
  onSelectDate,
  openScheduler,
  openAddJobModal,
}: RoutePlannerCalendarProps) => {
  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();

  const { calendarData: events, isLoading: isLoadingEvents } = useSelector(
    state => state.routes.routePlanner.routePlannerCalendarData,
  );

  const eventsTransformed = useMemo(
    () => (!isLoadingEvents ? generateEvents(events, calendarDate) : []),
    [calendarDate, events, isLoadingEvents],
  );

  const calendarCustomComponents = {
    toolbar: CalendarToolbar({
      onDateChange: d => {
        setCalendarDate(d);
        // to reset the table when week is changed
        onSelectDate(undefined);
      },
      openScheduler,
      onExport,
      onImport,
      date: calendarDate,
      vehicleTypeInitialValues: { vehicleTypeIds, groupIds },
    }),
    week: {
      header: CalendarWeekHeader({ selectedDate }),
      event: CalendarEvent({ selectedDate, openScheduler, openAddJobModal }),
    },
    dateCellWrapper: CalendarDayWrapper,
  };

  // load calendar data for 7 days from the current date
  useEffect(() => {
    if (calendarDate) {
      const beginDate = moment(calendarDate).format('MM-DD-YYYY');
      const endDate = moment(calendarDate).add(6, 'days').format('MM-DD-YYYY');
      loadRoutePlannerCalendarData({
        vendorId,
        beginDate,
        endDate,
        vehicleTypeIdsCSV: vehicleTypeIds?.join(','),
        groupIdsCSV: groupIds?.join(','),
      })(dispatch);
    }
  }, [calendarDate, dispatch, groupIds, vehicleTypeIds, vendorId]);

  return (
    <RoutePlannerCalendarWrapper isLoading={isLoadingEvents}>
      <Calendar
        defaultView={Views.WEEK}
        views={[Views.WEEK]}
        defaultDate={calendarDate}
        localizer={localizer}
        components={calendarCustomComponents}
        events={eventsTransformed}
        onDrillDown={onSelectDate}
      />
    </RoutePlannerCalendarWrapper>
  );
};

export default RoutePlannerCalendar;
