import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { loadRollOffContainerManagementSettings } from '../../ducks';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import ROContainerManagementModal from './ROContainerManagementModal';

const ROContainerManagementModalResolver: React.FC<ModalProps> = ({ closeModal }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    return Promise.all([loadRollOffContainerManagementSettings(vendorId)(dispatch)]);
  }, [vendorId, dispatch]);

  return (
    <Resolver
      successComponent={ROContainerManagementModal}
      successProps={{ closeModal, vendorId }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default ROContainerManagementModalResolver;
