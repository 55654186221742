import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { Resolver as ResolverUntyped } from '../../../../core/components';
import { loadExceptionConfigurations } from '../../../../vendors/ducks';
import { loadReasonCodeTypes, loadWasteTypes } from '../../../../common/ducks';
import { PageLoadingOverlay } from '../../../../common/components/styled';
import { AppState } from '../../../../store';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import ExceptionManagerModal from './ExceptionManagerModal';
import { DuckFunction } from '../../../../contracts/ducks';
import { loadExceptionManagementConfigurations } from '../../../../vendors/ducks/exceptionManagementConfiguration';
import { DISPATCH_BOARD_FORM_NAME } from 'src/routes/constants/dispatchBoard';

const Resolver = ResolverUntyped as any;

export type LoadExceptions = (
  vendorId: number,
  vehicleTypeId: number,
  startDate: Date | string,
  endDate: Date | string,
  exceptionTypeId: number[],
  wasteTypeId: number[],
) => Promise<any>;

interface ResolverProps {
  vehicleTypeId: number;
  vendorId: number;
  loadExceptionConfigurations: (vendorId: number, vehicleTypeId: number) => Promise<any>;
  loadWasteTypes: () => Promise<any>;
  loadReasonCodeTypes: DuckFunction<typeof loadReasonCodeTypes>;
  loadExceptionManagementConfigurations: DuckFunction<typeof loadExceptionManagementConfigurations>;
  onClose: () => void;
}

const ExceptionManagerModalResolver: React.SFC<ResolverProps> = ({
  loadExceptionConfigurations,
  loadWasteTypes,
  vendorId,
  vehicleTypeId,
  onClose,
  loadReasonCodeTypes,
  loadExceptionManagementConfigurations,
}) => {
  const loadDependencies = () =>
    Promise.all([
      loadWasteTypes(),
      loadReasonCodeTypes(),
      loadExceptionConfigurations(vendorId, vehicleTypeId),
      loadExceptionManagementConfigurations(vendorId, vehicleTypeId),
    ]);

  return (
    <Resolver
      successComponent={ExceptionManagerModal as any}
      loadingComponent={PageLoadingOverlay}
      successProps={{ vendorId, vehicleTypeId, onClose }}
      resolve={loadDependencies}
    />
  );
};

const formSelector = formValueSelector(DISPATCH_BOARD_FORM_NAME);

const mapStateToProps = (state: AppState) => ({
  vehicleTypeId: formSelector(state, 'vehicleTypeId'),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadExceptionConfigurations,
  loadWasteTypes,
  loadReasonCodeTypes,
  loadExceptionManagementConfigurations,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExceptionManagerModalResolver);
