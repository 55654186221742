import { change, Field, reduxForm, InjectedFormProps, submit } from 'redux-form';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { DELIVERY_UTILITY_ID, FRONT_LOAD_ID, RESIDENTIAL_ID } from 'src/fleet/constants/vehicleTypes';
import { Dropdown, DatePicker, Input, TypedField } from '../../../core/components';
import { getComboRouteOptions } from './utils/CustomOptionsUtils';
import { Grid, GridColumn, PanelSection, Button, ButtonSet } from '../../../core/components/styled';
import { isDateValidValidator, isRequired } from '../../../utils/services/validator';
import { JOB_IS_NOT_PENDING_ID, JOB_IS_PENDING_ID } from 'src/routes/constants/jobStatuses';
import { loadRoutesForReassignment } from '../../ducks';
import { ROLL_OFF_ID } from 'src/fleet/constants';
import { SCHEDULED } from 'src/routes/constants/routeStatuses';
import { useSelector } from 'src/core/hooks/useSelector';
import JobPositionTypeDropdown from 'src/common/components/JobPositionTypeDropdown';
import translate from '../../../core/services/translate';

export interface TransferRouteStopsFormValues {
  hasStopsInPendingOptimization: boolean;
  positionTypeId: number;
  routeDate: Date | string;
  targetRouteId: string;
}

interface PropsWithoutReduxForm {
  change: any;
  isSnowPlowRoute?: boolean;
  isStreetSweeperRoute?: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<TransferRouteStopsFormValues, PropsWithoutReduxForm>;
const FORM = 'transferRouteLocations';

const TransferRouteStopsForm: React.FC<Props> = ({ change, handleSubmit, isSnowPlowRoute, isStreetSweeperRoute }) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { isLoading, routesForReassignment = [] } = useSelector(state => state.routes.routesForReassignment);

  const [isOptimizedOptionHidden, setIsOptimizedOptionHidden] = useState(false);

  const loadAvailableRoutesForReassignment = (_: unknown, value: string) => {
    if (!routeSummary) return;
    const { routeId, vehicleTypeId, wasteMaterialTypeId } = routeSummary;
    if (isSnowPlowRoute || isStreetSweeperRoute) {
      loadRoutesForReassignment(vendorId, routeId, vehicleTypeId, undefined, value)(dispatch);
    } else loadRoutesForReassignment(vendorId, routeId, vehicleTypeId, wasteMaterialTypeId, value)(dispatch);
  };

  const routesForReassignmentOptions = () => {
    if (!routeSummary) return;
    const { vehicleTypeId } = routeSummary;
    const isVehicleTypeIdCorrect =
      vehicleTypeId === FRONT_LOAD_ID || vehicleTypeId === RESIDENTIAL_ID || vehicleTypeId === DELIVERY_UTILITY_ID;

    const routesForReassignmentOptions =
      isSnowPlowRoute || isStreetSweeperRoute
        ? routesForReassignment
            .filter((el: any) => el.isTemplate !== true)
            .map(({ name, id }) => ({
              label: name,
              value: id,
            }))
        : routesForReassignment.map(({ name, id }) => ({
            label: name,
            value: id,
          }));

    const groupedCustomOptions = [
      ...(isVehicleTypeIdCorrect
        ? getComboRouteOptions(vehicleTypeId, routesForReassignment, true)
        : routesForReassignmentOptions),
    ];

    return groupedCustomOptions;
  };

  const changeTargetRouteId = (event: any, value: any) => {
    const { routeStatusTypeId, hasStopsInPendingOptimization } = (routesForReassignment as any).filter(
      (route: any) => route.id === Number(value),
    )[0];

    change('hasStopsInPendingOptimization', hasStopsInPendingOptimization ? JOB_IS_PENDING_ID : JOB_IS_NOT_PENDING_ID);

    setIsOptimizedOptionHidden(routeStatusTypeId !== SCHEDULED);
  };

  const transferButton =
    isSnowPlowRoute || isStreetSweeperRoute ? translate('routes.transferSegments') : translate('routes.transferStops');

  return (
    <form onSubmit={handleSubmit}>
      <PanelSection padding="medium no no" vertical isLoading={isLoading}>
        <Grid centered>
          <GridColumn size="6/12">
            <Field
              name="routeDate"
              component={DatePicker}
              label={translate('common.date')}
              validate={[isRequired, isDateValidValidator]}
              onChange={loadAvailableRoutesForReassignment}
            />

            <Field
              name="targetRouteId"
              component={Dropdown}
              options={routesForReassignmentOptions()}
              label={translate('routes.targetRoute')}
              disabled={!routesForReassignment?.length}
              validate={isRequired}
              onChange={changeTargetRouteId}
            />
            <Field margin="no" name="hasStopsInPendingOptimization" component={Input} type="hidden" />

            {!isSnowPlowRoute && !isStreetSweeperRoute && (
              <TypedField
                name="positionTypeId"
                component={JobPositionTypeDropdown}
                props={{
                  isOptimizedOptionHidden: routeSummary?.vehicleTypeId === ROLL_OFF_ID || isOptimizedOptionHidden,
                  withLabel: true,
                  dropdownProps: {
                    margin: 'no no small',
                  },
                }}
              />
            )}
          </GridColumn>
        </Grid>

        <ButtonSet margin="lMedium no no">
          <Button type="button" onClick={() => dispatch(submit(FORM))} color="primary">
            {transferButton}
          </Button>
        </ButtonSet>
      </PanelSection>
    </form>
  );
};

const mapDispatchToProps = {
  change,
};

export default connect(
  undefined,
  mapDispatchToProps,
)(
  reduxForm<TransferRouteStopsFormValues, PropsWithoutReduxForm>({
    form: FORM,
  })(TransferRouteStopsForm),
);
