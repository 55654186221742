import { FC } from 'react';

import greenFlag from 'src/common/assets/img/common/greenFlag.png';
import redFlag from 'src/common/assets/img/common/redFlag.png';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import TravelPathBreadcrumbsFlagsGLSource, { TRAVEL_PATH_FLAGS_SOURCE } from './TravelPathBreadcrumbsFlagsGLSource';
import { getFeatureCollection } from 'src/common/components/map/util';

const mapImages = [
  {
    id: 'greenFlag',
    url: greenFlag,
    sourceId: TRAVEL_PATH_FLAGS_SOURCE,
  },
  {
    id: 'redFlag',
    url: redFlag,
    sourceId: TRAVEL_PATH_FLAGS_SOURCE,
  },
];

const mapImagesOptions = { addLayers: false };

interface Props {
  map: mapboxgl.Map;
  flagsGeoJSON: GeoJSON.FeatureCollection<GeoJSON.Point> | null;
}

const TravelPathBreadcrumbsFlagsGL: FC<Props> = ({ map, flagsGeoJSON }) => {
  useMapImages(mapImages, map, mapImagesOptions);

  let geoJsonToDisplay = flagsGeoJSON as GeoJSON.FeatureCollection<GeoJSON.Point>;

  if (!flagsGeoJSON) geoJsonToDisplay = getFeatureCollection<GeoJSON.Point, any>([]);

  return <TravelPathBreadcrumbsFlagsGLSource flagsGeoJSON={geoJsonToDisplay} />;
};

export default TravelPathBreadcrumbsFlagsGL;
