import React, { MouseEvent } from 'react';

import { UnconnectedSwitch, ActionButtonTooltip } from '../../../core/components';
import { TableActionButton, TableCell, TableRow } from '../../../core/components/styled';
import { LocationAlert } from '../../interfaces/LocationAlert';

interface Props extends LocationAlert {
  openLocationAlertEditorModal(event: MouseEvent, { id, title, description, isActive }: LocationAlert): void;
  removeLocationAlert(locationAlertTypeId: number): void;
}

const LocationAlertsModalTableRow: React.FC<Props> = ({
  description,
  id,
  isActive,
  openLocationAlertEditorModal,
  removeLocationAlert,
  title,
}) => (
  <TableRow>
    <TableCell width="30%">{title}</TableCell>
    <TableCell width="40%">{description}</TableCell>
    <TableCell width="10%">
      <UnconnectedSwitch checked={isActive} disabled />
    </TableCell>
    <TableCell width="20%" align="right">
      <TableActionButton onClick={event => openLocationAlertEditorModal(event, { id, title, description, isActive })}>
        <ActionButtonTooltip icon="edit" tooltip="editLocation" />
      </TableActionButton>
      <TableActionButton onClick={() => removeLocationAlert(id)}>
        <ActionButtonTooltip icon="delete" tooltip="deleteLocation" />
      </TableActionButton>
    </TableCell>
  </TableRow>
);

export default LocationAlertsModalTableRow;
