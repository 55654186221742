import { debounce, flatMap, keys, pickBy } from 'lodash-es';
import { ChangeEvent, useState } from 'react';

import { HIGH_JOB_PRIORITY_ID } from 'src/common/constants';
import { DateRangeOptionValue } from '../../../../../../core/components/DateRangePicker';
import { UPDATE_SEARCH_TERM_WAIT } from '../../../../../constants';
import { MultiSelectValues } from 'src/core/components/MultiSelectEnhanced';

export const getMaterialTypesIds = (materialTypes: MultiSelectValues | undefined) => {
  return flatMap(materialTypes, (value, key) => {
    // when a job does not have materialType, the materialType id is c_
    if (key === '0' && value.all) return ['c_'];
    if (value.subOptions) {
      return keys(pickBy(value.subOptions, subValue => subValue));
    }
    return [];
  });
};

export interface FilterFunctions {
  onFilterBlur: () => void;
  onFilterFocus: () => void;
  onDateFilterChange?: (event: ChangeEvent<HTMLInputElement>, value: DateRangeOptionValue) => void;
  onJobPriorityFilterChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSearchTermChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  onWasteTypeChange: (event: ChangeEvent<HTMLInputElement>, wasteType: string) => void;
  onPickupTypeFilterChange: (event: ChangeEvent<HTMLInputElement>, pickupTypeId: number) => void;
  onEquipmentTypeFilterChange: (event: ChangeEvent<HTMLInputElement>, equipmentType: string) => void;
  onReasonCodeTypeIdFilterChange: (event: ChangeEvent<HTMLInputElement>, reasonCodeTypeId: number) => void;
  onServiceZonesFilterChange: (event: ChangeEvent<HTMLInputElement>, serviceZones: number[]) => void;
  onSupervisorsFilterChange: (event: ChangeEvent<HTMLInputElement>, serviceZones: number[]) => void;
  onMaterialTypesFilterChange: (event: ChangeEvent<HTMLInputElement>, materialTypes: MultiSelectValues) => void;
  onResetMaterialTypesFilter: () => void;
}

export interface UnassignedJobsFilters {
  searchTerm: string;
  fromDate?: Date;
  equipmentType?: string;
  pickupTypeId?: number;
  reasonCodeTypeId?: number;
  serviceZones?: number[];
  supervisors?: number[];
  toDate?: Date;
  wasteType?: string;
  jobPriorityTypeId?: number;
  materialTypes?: MultiSelectValues;
}

const useDispatchBoardFilters = () => {
  const [dispatchBoardSearchFilters, setDispatchBoardSearchFilters] = useState<UnassignedJobsFilters>({
    searchTerm: '',
  });
  const [isFilterFocused, setIsFilterFocused] = useState<boolean>(false);

  const handleSearchTermChange = debounce((event: ChangeEvent<HTMLInputElement>, searchTerm: string) => {
    setDispatchBoardSearchFilters({
      ...dispatchBoardSearchFilters,
      searchTerm,
    });
  }, UPDATE_SEARCH_TERM_WAIT);

  const handleDateFilterChange = (event: ChangeEvent<HTMLInputElement>, dateFilter: DateRangeOptionValue) => {
    setDispatchBoardSearchFilters({
      ...dispatchBoardSearchFilters,
      fromDate: dateFilter ? dateFilter.from : undefined,
      toDate: dateFilter ? dateFilter.to : undefined,
    });
  };

  const handleWasteTypeChange = (event: ChangeEvent<HTMLInputElement>, wasteType: string) => {
    setDispatchBoardSearchFilters({
      ...dispatchBoardSearchFilters,
      wasteType,
    });
  };

  const handleEquipmentTypeFilterChange = (event: ChangeEvent<HTMLInputElement>, equipmentType: string) => {
    setDispatchBoardSearchFilters({
      ...dispatchBoardSearchFilters,
      equipmentType,
    });
  };

  const handlePickupTypeFilterChange = (event: ChangeEvent<HTMLInputElement>, pickupTypeId: number) => {
    setDispatchBoardSearchFilters({
      ...dispatchBoardSearchFilters,
      pickupTypeId,
    });
  };

  const handleReasonCodeTypeIdFilterChange = (event: ChangeEvent<HTMLInputElement>, reasonCodeTypeId: number) => {
    setDispatchBoardSearchFilters({
      ...dispatchBoardSearchFilters,
      reasonCodeTypeId,
    });
  };

  const handleServiceZonesFilterChange = (event: ChangeEvent<HTMLInputElement>, serviceZones: number[]) => {
    setDispatchBoardSearchFilters({
      ...dispatchBoardSearchFilters,
      serviceZones,
    });
  };

  const handleSupervisorsFilterChange = (event: ChangeEvent<HTMLInputElement>, supervisors: number[]) => {
    setDispatchBoardSearchFilters({
      ...dispatchBoardSearchFilters,
      supervisors,
    });
  };

  const handleJobPriorityFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDispatchBoardSearchFilters({
      ...dispatchBoardSearchFilters,
      jobPriorityTypeId: event.target.checked ? HIGH_JOB_PRIORITY_ID : undefined,
    });
  };
  const handleMaterialTypesFilterChange = (event: ChangeEvent<HTMLInputElement>, materialTypes: MultiSelectValues) => {
    setDispatchBoardSearchFilters({
      ...dispatchBoardSearchFilters,
      materialTypes,
    });
  };
  const resetMaterialTypesFilter = () => {
    setDispatchBoardSearchFilters({
      ...dispatchBoardSearchFilters,
      materialTypes: undefined,
    });
  };

  const handleFilterFocus = () => {
    setIsFilterFocused(true);
  };

  const handleFilterBlur = () => {
    setIsFilterFocused(false);
  };

  const filterFunctions: FilterFunctions = {
    onFilterBlur: handleFilterBlur,
    onFilterFocus: handleFilterFocus,
    onDateFilterChange: handleDateFilterChange,
    onSearchTermChange: handleSearchTermChange,
    onWasteTypeChange: handleWasteTypeChange,
    onEquipmentTypeFilterChange: handleEquipmentTypeFilterChange,
    onPickupTypeFilterChange: handlePickupTypeFilterChange,
    onReasonCodeTypeIdFilterChange: handleReasonCodeTypeIdFilterChange,
    onServiceZonesFilterChange: handleServiceZonesFilterChange,
    onSupervisorsFilterChange: handleSupervisorsFilterChange,
    onJobPriorityFilterChange: handleJobPriorityFilterChange,
    onMaterialTypesFilterChange: handleMaterialTypesFilterChange,
    onResetMaterialTypesFilter: resetMaterialTypesFilter,
  };

  return {
    dispatchBoardSearchFilters,
    isFilterFocused,
    filterFunctions,
  };
};

export default useDispatchBoardFilters;
