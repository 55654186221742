import { http } from '../../core/services/http';

export const loadNotificationTypes = (vendorId: number, vehicleTypeId: number) =>
  http
    .get(`/vendors/${vendorId}/customerNotification/notificationTypes`, {
      params: {
        vehicleTypeId,
      },
    })
    .then(response => response.data);
