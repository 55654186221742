import { AxiosError } from 'axios';
import { change, isDirty } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { map } from 'lodash-es';
import { push, Push } from 'connected-react-router';
import { useHistory } from 'react-router';
import { useState } from 'react';
import moment from 'moment';

import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { createSnowOrSweeperRoute, editSnowOrSweeperRoute } from 'src/routes/services/routeTracker';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { dateFormat } from 'src/utils/services/validator';
import { DuckFunction } from 'src/contracts/ducks';
import { EDIT_ROUTE_DETAILS_FORM_NAME } from 'src/routes/components/forms/EditRouteDetailsForm';
import { loadSnowSweeperRouteSummary, loadTravelPathStatusDetails } from 'src/routes/ducks';
import { Modal } from 'src/core/components';
import { Route } from 'src/routes/interfaces/Route';
import { RouteDetailsFormData } from 'src/routes/interfaces/RouteDetails';
import { RouteSummary } from 'src/routes/interfaces/RouteSummary';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { SNOW_SWEEPER_DATE_PICKER_FORM } from 'src/routes/components/forms/SnowSweeperDatePickerForm';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import CreateEditSnowSweeperRouteForm from 'src/routes/components/forms/CreateEditSnowSweeperRouteForm';
import translate from 'src/core/services/translate';

type Props = {
  closeModal: () => void;
  loadSnowSweeperRouteSummary: DuckFunction<typeof loadSnowSweeperRouteSummary>;
  onDateChange?: (startDate: any | Date | string, endDate: Date | string, isEditMode?: boolean) => void;
  push: Push;
  routeSummary?: RouteSummary;
};

const CreateEditSnowSweeperRouteModal: React.FC<Props> = ({
  closeModal,
  loadSnowSweeperRouteSummary,
  onDateChange,
  push,
  routeSummary,
}) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const isFormDirty = useSelector(isDirty(EDIT_ROUTE_DETAILS_FORM_NAME));

  const [isSaving, setIsSaving] = useState(false);

  const history = useHistory();
  const { pathname } = history.location;
  const isSnowPlowRoute = pathname.includes('snow-tracker');

  const snowOrSweeperFormInitialValues = {
    routeDate: moment().format(dateFormat),
  };

  const isEditMode = !!routeSummary;

  const handleSaveSnowOrSweeperRoute = (formData: RouteDetailsFormData) => {
    const newFormData = {
      ...formData,
      vendorId,
      groups: map(formData.groups, id => ({ id })),
      vehicleTypeId: isSnowPlowRoute ? SNOW_PLOW_ID : STREET_SWEEPER_ID,
      date: formData.routeDate,
    };
    setIsSaving(true);

    dispatch(change(SNOW_SWEEPER_DATE_PICKER_FORM, 'startDate', newFormData.date));
    dispatch(change(SNOW_SWEEPER_DATE_PICKER_FORM, 'endDate', newFormData.date));
    onDateChange && onDateChange(newFormData.date, newFormData.date, isEditMode);

    const handleErrorOnSave = (error: AxiosError) => {
      const code = error?.response?.data && (error?.response?.data as any).code;

      switch (code) {
        case 'EntityAlreadyExists':
          createErrorNotification(translate(`routes.alertMessages.entityAlreadyExists`));
          break;

        default:
          createErrorNotification(translate('routes.alertMessages.routeSaveError'));
          break;
      }

      setIsSaving(false);
    };

    isEditMode
      ? editSnowOrSweeperRoute(newFormData as any as Route, vendorId, routeSummary?.routeId)
          .then(() => {
            createSuccessNotification(
              `${translate(
                `routes.alertMessages.${isSnowPlowRoute ? 'snowPlowRouteEdited' : 'streetSweeperRouteEdited'}`,
              )}`,
            );

            const noLoadingIndicator = true;
            loadTravelPathStatusDetails(routeSummary.routeId, undefined)(dispatch);
            loadSnowSweeperRouteSummary(
              vendorId,
              routeSummary.routeId,
              newFormData.date,
              newFormData.date,
              noLoadingIndicator,
            ).then(() => {
              closeModal();
            });
          })
          .catch(error => {
            handleErrorOnSave(error);
          })
      : createSnowOrSweeperRoute(newFormData as any as Route)
          .then(res => {
            createSuccessNotification(
              `${translate(
                `routes.alertMessages.${isSnowPlowRoute ? 'snowPlowRouteCreated' : 'streetSweeperRouteCreated'}`,
              )}`,
            );
            push(`${res.id}`, { createdDailyRoute: true });
          })
          .catch(error => {
            handleErrorOnSave(error);
          });
  };

  const handleCloseModal = async () => {
    if (isFormDirty) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    closeModal();
  };

  const initialValues = isEditMode
    ? {
        ...routeSummary,
        routeName: routeSummary?.name,
        routeDate: moment(routeSummary?.date).format(dateFormat),
        groups: routeSummary?.groups?.map(group => group.id),
      }
    : snowOrSweeperFormInitialValues;

  return (
    <Modal
      title={translate(isEditMode ? 'routes.editRouteDetails' : 'routes.routeDetails')}
      size="small"
      isLoading={isSaving}
      onClose={handleCloseModal}
    >
      <CreateEditSnowSweeperRouteForm
        closeModal={handleCloseModal}
        initialValues={initialValues}
        isEditMode={isEditMode}
        onSubmit={handleSaveSnowOrSweeperRoute}
      />
    </Modal>
  );
};

const mapDispatchToProps = { loadSnowSweeperRouteSummary, push };

export default connect(undefined, mapDispatchToProps)(CreateEditSnowSweeperRouteModal);
