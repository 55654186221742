import { AxiosResponse } from 'axios';

import { http } from '../../core/services/http';
import { RouteSettings, RawRouteSettings } from '../interfaces/RouteSettings';
import { transformRouteSequenceRawSettings, transformRouteSequenceSettings } from './transformRouteSettings';

export const loadRouteSequenceSettings = (vendorId: number): Promise<RouteSettings[]> =>
  http
    .get(`/vendors/${vendorId}/routeSequenceSettings`)
    .then((response: AxiosResponse<RawRouteSettings[]>) => transformRouteSequenceRawSettings(response.data));

export const loadRouteSequenceDefaultSettings = (vehicleTypeId: number): Promise<RouteSettings> =>
  http
    .get(`/vendors/routeSequenceDefaultSettings`, { params: { vehicleTypeId } })
    .then((response: AxiosResponse<RawRouteSettings[]>) => transformRouteSequenceRawSettings(response.data)[0]);

export const saveRouteSequenceSettings = (vendorId: number, settings: RouteSettings[]) =>
  http
    .post(`/vendors/${vendorId}/routeSequenceSettings`, transformRouteSequenceSettings(settings))
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
