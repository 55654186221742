import { createSelector } from 'reselect';
import { Dispatch, AnyAction } from 'redux';
import { find, findIndex, get, identity } from 'lodash-es';
import update from 'immutability-helper';

import { AppState } from '../../store';
import { deleteWeightTicket as doDeleteWeightTicket } from '../services/weightTicket';
import { WeightTicketsState, WeightTickets } from '../interfaces/WeightTickets';
import {
  loadWeightTickets as doLoadWeightTickets,
  loadWeightTicket as doLoadWeightTicket,
} from '../services/loadWeightTickets';

// Actions
const START_LOAD = 'routes/weightTickets/START_LOAD';
const COMPLETE_LOAD = 'routes/weightTickets/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/weightTickets/FAIL_LOAD';
const START_DELETE = 'routes/weightTickets/START_DELETE';
const COMPLETE_DELETE = 'routes/weightTickets/COMPLETE_DELETE';
const FAIL_DELETE = 'routes/weightTickets/FAIL_DELETE';
const RESET = 'routes/weightTickets/RESET';

// Initial state
const initialState: WeightTicketsState = {
  isLoading: false,
  isDeleting: false,
  weightTickets: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          weightTickets: action.weightTickets,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          weightTickets: undefined,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const weightTicketIndex = findIndex(state.weightTickets, { id: action.weightTicketId });
      return update(state, {
        weightTickets: { $splice: [[weightTicketIndex, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (weightTickets: WeightTickets[]) => ({
  type: COMPLETE_LOAD,
  weightTickets,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (weightTicketId: number) => ({
  type: COMPLETE_DELETE,
  weightTicketId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

export const loadWeightTickets = (routeId: number, routeLocationId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadWeightTicketsPromise = doLoadWeightTickets(routeId, routeLocationId);
  loadWeightTicketsPromise
    .then(weightTickets => dispatch(completeLoad(weightTickets)))
    .catch(() => dispatch(failLoad()));
  return loadWeightTicketsPromise;
};

export const loadWeightTicket = (weightTicketId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadWeightTicketPromise = doLoadWeightTicket(weightTicketId);
  loadWeightTicketPromise
    .then(weightTicket => dispatch(completeLoad([weightTicket])))
    .catch(() => dispatch(failLoad()));
  return loadWeightTicketPromise;
};

export const deleteWeightTicket = (weightTicketId: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteWeightTicketPromise = doDeleteWeightTicket(weightTicketId);
  deleteWeightTicketPromise.then(() => dispatch(completeDelete(weightTicketId))).catch(() => dispatch(failDelete()));
  return deleteWeightTicketPromise;
};

export const resetWeightTickets = () => ({
  type: RESET,
});

export const weightTicketsFeatureSelector = createSelector(
  (state: AppState) => state.vendors.features.features,
  featuresState => featuresState.find(f => f.code === 'WeightTicketOCR'),
);

const getLocationNameById = (routeState: any, routeLocationId: number) => {
  const location = find(routeState.routeLocations, { id: routeLocationId });
  return get(location, 'location.name', '');
};

export const routeLocationNameByIdSelector = createSelector(getLocationNameById, identity);
