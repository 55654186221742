import { connect } from 'react-redux';
import { find, findIndex, size, sortBy } from 'lodash-es';
import { PureComponent } from 'react';
import moment from 'moment';

import { AppState } from '../../../store';
import { Box } from 'src/core/components/styled/Box';
import { createSuccessNotification, createErrorNotification } from 'src/core/services/createNotification';
import { currentUserIdSelector, currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { dateFormat, timeFormat } from 'src/utils/services/validator';
import { DuckFunction } from '../../../contracts/ducks';
import { FORWARD_VIEW_ID } from 'src/routes/constants';
import { loadStrobeImages, loadNextStrobeImages, loadPrevStrobeImages } from '../../ducks';
import { Modal, StrobeImagesSlider } from '../../../core/components';
import { ModalTitle, ModalSection, ModalError } from '../../../core/components/styled';
import { RouteSummary } from 'src/routes/interfaces/RouteSummary';
import { saveVideoRequest } from 'src/routes/ducks';
import { VideoRequestFormValues } from '../pages/routes/routePageSections/routeMap/vehicleTrackings/VideoRequestForm';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from '../../../core/services/translate';
import VideoRequestModalResolver from '../pages/routes/routePageSections/routeMap/vehicleTrackings/VideoRequestModalResolver';

const NUMBER_OF_IMAGES = 10;

type LoadNextPrevStrobeImages = (
  vendorId: number,
  vehicleId: number,
  strobeImageId: number,
  cameraSerialNumber: string,
  numberOfImages: number,
) => Promise<any>;

interface Props {
  closeModal: () => void;
  loadNextStrobeImages: LoadNextPrevStrobeImages;
  loadPrevStrobeImages: LoadNextPrevStrobeImages;
  loadStrobeImages: DuckFunction<typeof loadStrobeImages>;
  routeSummary?: RouteSummary;
  saveVideoRequest: DuckFunction<typeof saveVideoRequest>;
  shouldDisplayDownloadVideo: boolean;
  strobeImages: any[];
  timeStamp: string;
  userId: string;
  vehicleId: number;
  vehicleName: string;
  vendorId: number;
  videoDeviceTypeId?: number;
  deviceId?: string;
}

interface State {
  images: any[];
  index: number;
  isInRange: boolean;
  isVideoRequestModalOpen: boolean;
}

class StrobeImagesModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      images: props.strobeImages,
      index: findIndex(props.strobeImages, ['isSelectedImage', true]),
      isInRange: find(props.strobeImages, ['isSelectedImage', true])?.isInRange || false,
      isVideoRequestModalOpen: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isInRange: true });
    }, 5000);
  }

  onCameraViewChange = async (cameraSerialNumber: string) => {
    const { vendorId, vehicleId, timeStamp, loadStrobeImages } = this.props;

    await loadStrobeImages(vendorId, vehicleId, timeStamp, cameraSerialNumber)
      .then(strobeImagesResponse => {
        this.setState({
          images: strobeImagesResponse,
          index: size(strobeImagesResponse) > 0 ? findIndex(strobeImagesResponse, ['isSelectedImage', true]) : 0,
        });
      })
      .catch(() => createErrorNotification(translate('common.alertMessages.errorMessage')));
  };

  onIndexChange = async (index: number, cameraSerialNumber: string) => {
    const { loadNextStrobeImages, loadPrevStrobeImages, vendorId, vehicleId } = this.props;
    const { images } = this.state;
    if (index >= 0 && index < images.length) {
      this.setState({ index });
    }

    const { strobeImageId } = images[index];
    if (index === images.length - 1) {
      await loadNextStrobeImages(vendorId, vehicleId, strobeImageId, cameraSerialNumber, NUMBER_OF_IMAGES)
        .then(nextStrobeImages => {
          if (nextStrobeImages.length > 0) {
            this.setState({ images: [...this.state.images, ...nextStrobeImages] });
          }
        })
        .catch(() => createErrorNotification(translate('common.alertMessages.errorMessage')));
    } else if (index === 0) {
      await loadPrevStrobeImages(vendorId, vehicleId, strobeImageId, cameraSerialNumber, NUMBER_OF_IMAGES).then(
        prevStrobeImages => {
          const previousImages = [...this.state.images, ...prevStrobeImages];
          this.setState({ images: sortBy(previousImages, ['timeStamp']) });
        },
      );
    }
  };

  resetState = () => {
    this.setState({ isInRange: false });
  };

  setIsVideoRequestModalOpen = (isOpen: boolean) => {
    this.setState({ isVideoRequestModalOpen: isOpen });
  };

  onVideoRequestModalSubmit = (formValues: VideoRequestFormValues) => {
    const { routeSummary, saveVideoRequest, userId, vendorId } = this.props;

    saveVideoRequest({
      ...formValues,
      userId,
      vendorId,
      routeId: routeSummary?.routeId,
      driverId: routeSummary?.driverId,
    })
      .then(() => {
        createSuccessNotification(translate('routes.alertMessages.videoRequestSaved'));
        this.setIsVideoRequestModalOpen(false);
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.videoRequestSaveError'));
      });
  };

  render() {
    const {
      closeModal,
      shouldDisplayDownloadVideo,
      vehicleId,
      vehicleName,
      videoDeviceTypeId,
      deviceId,
    } = this.props;
    const { index, images, isInRange, isVideoRequestModalOpen } = this.state;

    const { timeStamp } = size(images) > 0 ? images[index] : '';

    return (
      <Modal onClose={closeModal} padding="no">
        <ModalSection padding="medium medium no">
          <ModalTitle>
            {vehicleName}

            {shouldDisplayDownloadVideo && (
              <LabeledDataView noWrap size="small">
                <Box cursor="pointer" onClick={() => this.setIsVideoRequestModalOpen(true)} textDecoration="underline">
                  {translate('routes.videoRequest.requestVideoFootage')}
                </Box>
              </LabeledDataView>
            )}
          </ModalTitle>
        </ModalSection>
        <StrobeImagesSlider
          margin="small no no"
          timeStamp={timeStamp}
          images={images}
          onIndexChange={this.onIndexChange}
          onCameraViewChange={this.onCameraViewChange}
          imageTitle={vehicleName}
        />
        {!isInRange && <ModalError>{translate('common.strobeImageError')}</ModalError>}
        {size(images) === 0 && <ModalError>{translate('common.strobeImageNotFound')}</ModalError>}

        {isVideoRequestModalOpen && (
          <VideoRequestModalResolver
            closeModal={() => this.setIsVideoRequestModalOpen(false)}
            onSubmit={this.onVideoRequestModalSubmit}
            initialValues={{
              date: moment(timeStamp).format(dateFormat),
              time: moment(timeStamp).format(timeFormat),
              vehicleId,
              vehicleName,
              vehicleCameraConfigurationTypeIds: [FORWARD_VIEW_ID],
              videoDeviceTypeId,
              deviceId,
            }}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  routeSummary: state.routes.routeSummary.routeSummary,
  strobeImages: state.routes.strobeImages.strobeImages,
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadNextStrobeImages,
  loadPrevStrobeImages,
  loadStrobeImages,
  saveVideoRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(StrobeImagesModal);
