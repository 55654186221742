import React from 'react';
import { Field } from 'redux-form';

import { InfoIcon } from '../../routes/components/styled';
import { Popover } from '../../core/components/styled';
import { PopoverWrapper } from '../../core/components';
import { Switch } from '../../core/components';
import translate from '../../core/services/translate';
import { UpdateTrackerRouteSwitchWrapper } from '../styles';

interface Props {
  align?: string;
  hasScheduledDailyRoutes?: boolean;
  isFormDirty?: boolean;
  topMargin?: string;
  wrapperMargin?: string;
}

const UpdateTrackerRouteSwitch: React.SFC<Props> = ({
  align,
  hasScheduledDailyRoutes,
  isFormDirty,
  topMargin,
  wrapperMargin,
}) => {
  return (
    <UpdateTrackerRouteSwitchWrapper align={align} wrapperMargin={wrapperMargin}>
      <Field
        name="shouldRecreateRoutes"
        component={Switch}
        props={{
          disabled: !hasScheduledDailyRoutes || !isFormDirty,
          label: translate('common.updateTrackerRoute'),
          margin: `${topMargin || 'medium'} no no`,
        }}
      />
      <PopoverWrapper
        margin={`${topMargin || 'medium'} no no xSmall`}
        triggerButton={<InfoIcon />}
        popoverContent={<Popover>{translate('routes.alertMessages.routeSaveShouldRecreateRoutes')}</Popover>}
        size="large"
      />
    </UpdateTrackerRouteSwitchWrapper>
  );
};

UpdateTrackerRouteSwitch.defaultProps = {
  isFormDirty: true,
  hasScheduledDailyRoutes: false,
};

export default UpdateTrackerRouteSwitch;
