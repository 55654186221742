import { http } from 'src/core/services/http';

export const loadPaymentDetails = (vendorId: number, paymentId: number) =>
  http
    .get(`reconciliations/payments/${paymentId}`, {
      params: { vendorId },
    })
    .then(response => response.data);

// export const savePaymentDetails = (customerId: number, paymentTotal: number, paymentStatusId: number, paymentMethodId: number, receivedViaId: number, paymentNumber: number, receivedDate: string, paymentDate: number, imageId?: number)
