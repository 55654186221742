import { downloadExcelFile } from '../../utils/services/downloadExcelFile';
import { http } from '../../core/services/http';
import translate from '../../core/services/translate';

export const uploadVehiclesFile = (fileData: any, vendorId: number) => {
  const payload = new FormData();
  payload.append('file', fileData);
  payload.append('data', JSON.stringify({ vendorId }));

  return http.post('/vehicleImport/upload', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const downloadSampleVehicleTemplate = () =>
  downloadExcelFile('/vehicleImport/downloadTemplate', translate('vehicles.vehicleImport.sampleTemplate'));
