import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { size } from 'lodash-es';

import { AppState } from '../../../store';
import { Container, Grid, GridColumn, ModalSection, Text } from '../../../core/components/styled';
import { dateAndTime } from '../../../utils/services/formatter';
import { OpportunityContainer } from '../styled/OpportunityStyle';
import { Modal } from '../../../core/components';
import RequestChangeInformation from '../RequestChangeInformation';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  closeModal: () => void;
  equipmentSizes?: any;
  requestedChanges?: any;
}

const ChangeRequestNotesModal: React.FC<Props> = ({
  closeModal,
  equipmentSizes,
  requestedChanges,
}) => {
  const allNotes = useSelector((state: AppState) => state.fleet.notes.notes);
  const notes = allNotes.filter(note => !!note.createdByUserID && note.createdByUserID.replace(/\s+/g, '').toLowerCase() === 'vendorportal');
  const { deliveryDate, displayFees, serviceRecommendation,  } = requestedChanges;
  return (
    <Modal title={translate('opportunity.serviceChangeRequest.changesRequested')} size="small" padding="small" verticalSize="xSmall" onClose={closeModal}>
      <ModalSection margin="no no sMedium">
        <Grid>
          <GridColumn size="12/12">
            {!!deliveryDate && (
              <Container>
                <OpportunityContainer margin="small no">
                  <Text underlined weight="medium" size="medium" margin="small no">
                    {translate('opportunity.serviceChangeRequest.proposedStartDate')}
                  </Text>
                </OpportunityContainer>
                <Grid margin="no no small no">
                  <GridColumn size="2/12">
                    <Text size="small">{translate('common.date')}:</Text>
                  </GridColumn>
                  <GridColumn size="10/12">
                    <Text weight="medium" size="small" lineHeight="8px">
                      {deliveryDate}
                    </Text>
                  </GridColumn>
                </Grid>
              </Container>
            )}
            {!!size(serviceRecommendation) && (
              <Container>
                <OpportunityContainer margin="sMedium no small">
                  <Text underlined weight="medium" size="medium" margin="small no">
                    {translate('opportunity.serviceChangeRequest.equipmentProposal')}
                  </Text>
                </OpportunityContainer>
  
                <RequestChangeInformation
                  equipmentSizes={equipmentSizes}
                  requestedChangeService={serviceRecommendation[0]}
                />
              </Container>
            )}
            {!!size(displayFees) && (
              <Container>
                <OpportunityContainer margin="small no">
                  <Text underlined weight="medium" size="medium" margin="small no">
                    {translate('opportunity.serviceChangeRequest.pricingProposal')}
                  </Text>
                </OpportunityContainer>
                {displayFees.map((fee: any, index: number) => (
                  <Grid margin="no no small no" key={index}>
                    <GridColumn size="4/12">
                    {fee.name && (<Text size="small">{fee.name}:</Text>)}
                    </GridColumn>
                    <GridColumn size="8/12">
                      <Text weight="medium" size="small" lineHeight="8px">
                        {fee.value}
                      </Text>
                    </GridColumn>
                  </Grid>
                ))}
              </Container>
            )}
            {!!size(notes) && (
              <Container>
                <OpportunityContainer margin="small no">
                  <Text underlined weight="medium" size="medium" margin="small no">
                    {translate('opportunity.notes')}
                  </Text>
                </OpportunityContainer>
                {!!notes && notes.map((note, index) => (
                  <Grid margin="no no small no" key={index}>
                    <GridColumn size="4/12">
                      {!!note.noteDate && (<Text size="small">{dateAndTime(note.noteDate)}</Text>)}
                    </GridColumn>
                    <GridColumn size="8/12">
                      <Text size="small" weight="medium" lineHeight="8px">
                        {note.userNote}
                      </Text>
                    </GridColumn>
                  </Grid>
                ))}
              </Container>
            )}
          </GridColumn>
        </Grid>
      </ModalSection>
    </Modal>
  );
};

export default withRouter(ChangeRequestNotesModal);
