import axios from 'axios';
import FileSaver from 'file-saver';
import update from 'immutability-helper';
import moment from 'moment';
import { AnyAction, Dispatch } from 'redux';

import { EXCEL_MIME_TYPES } from 'src/core/constants';
import { CustomerImportFile } from 'src/customers/interfaces/CustomerImport';
import {
  loadCityAlertsImportUploadedFilesStatus as doLoadCityAlertsImportUploadedFilesStatus,
  loadLocationAlertsImportUploadedFilesStatus as doLoadLocationAlertsImportUploadedFilesStatus,
  uploadCityAlertsFile as doUploadCityAlertsFile,
  uploadLocationAlertsFile as doUploadLocationAlertsFile,
} from '../services/locationAndCityAlertsImports';

//action types
const START_LOAD = 'fleet/locationAndCityAlertsImports/START_LOAD';
const COMPLETE_LOAD = 'fleet/locationAndCityAlertsImports/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/locationAndCityAlertsImports/FAIL_LOAD';
const START_UPLOAD = 'fleet/locationAndCityAlertsImports/START_UPLOAD';
const COMPLETE_UPLOAD = 'fleet/locationAndCityAlertsImports/COMPLETE_UPLOAD';
const FAIL_UPLOAD = 'fleet/locationAndCityAlertsImports/FAIL_UPLOAD';
const UPDATE_UPLOAD_PERCENT = 'fleet/locationAndCityAlertsImports/UPDATE_UPLOAD_PERCENT';

interface State {
  isLoading: boolean;
  isUploading: boolean;
  uploadingPercent: number;
  uploadedFiles: CustomerImportFile[];
}

const initialState: State = {
  isLoading: false,
  isUploading: false,
  uploadingPercent: 0,
  uploadedFiles: [],
};

//reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        isLoading: { $set: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        isLoading: { $set: false },
        uploadedFiles: { $set: action.uploadedFiles },
      });

    case FAIL_LOAD:
      return update(state, {
        isLoading: { $set: false },
      });

    case START_UPLOAD:
      return update(state, {
        isUploading: { $set: true },
      });

    case COMPLETE_UPLOAD:
      return update(state, {
        isUploading: { $set: false },
        uploadedFiles: { $set: action.uploadedFiles },
      });

    case FAIL_UPLOAD:
      return update(state, {
        isUploading: { $set: false },
      });

    default:
      return state;
  }
};

//action creators
const startLoad = () => ({ type: START_LOAD });
const completeLoad = (uploadedFiles: CustomerImportFile[]) => ({ type: COMPLETE_LOAD, uploadedFiles });
const failLoad = () => ({ type: FAIL_LOAD });
const startUpload = () => ({ type: START_UPLOAD });
const completeUpload = (uploadedFiles: CustomerImportFile[]) => ({ type: COMPLETE_UPLOAD, uploadedFiles });
const failUpload = () => ({ type: FAIL_UPLOAD });

// thunks
export const loadCityAlertsImportUploadedFilesStatus = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadCityAlertsImportUploadedFilesStatusPromise = doLoadCityAlertsImportUploadedFilesStatus(vendorId);

  loadCityAlertsImportUploadedFilesStatusPromise
    .then((uploadedFiles: CustomerImportFile[]) => {
      dispatch(completeLoad(uploadedFiles));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadCityAlertsImportUploadedFilesStatusPromise;
};

export const loadLocationAlertsImportUploadedFilesStatus = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadLocationAlertsImportUploadedFilesStatusPromise = doLoadLocationAlertsImportUploadedFilesStatus(vendorId);

  loadLocationAlertsImportUploadedFilesStatusPromise
    .then((uploadedFiles: CustomerImportFile[]) => {
      dispatch(completeLoad(uploadedFiles));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadLocationAlertsImportUploadedFilesStatusPromise;
};

export const uploadCityAlertsFile = (fileData: any, vendorId: number) => async (dispatch: Dispatch) => {
  dispatch(startUpload());

  const uploadCityAlertsFilePromise = doUploadCityAlertsFile(fileData, vendorId, (percent: number) => {
    dispatch({ type: UPDATE_UPLOAD_PERCENT, percent });
  });

  uploadCityAlertsFilePromise
    .then((uploadedFiles: CustomerImportFile[]) => {
      dispatch(completeUpload(uploadedFiles));
    })
    .catch(() => {
      dispatch(failUpload());
    });
  return uploadCityAlertsFilePromise;
};

export const uploadLocationAlertsFile = (fileData: any, vendorId: number) => async (dispatch: Dispatch) => {
  dispatch(startUpload());

  const uploadLocationAlertsFilePromise = doUploadLocationAlertsFile(fileData, vendorId, (percent: number) => {
    dispatch({ type: UPDATE_UPLOAD_PERCENT, percent });
  });

  uploadLocationAlertsFilePromise
    .then((uploadedFiles: CustomerImportFile[]) => {
      dispatch(completeUpload(uploadedFiles));
    })
    .catch(() => {
      dispatch(failUpload());
    });
  return uploadLocationAlertsFilePromise;
};

export const downloadAlertsImportFile =
  (fileDownloadUrl: string, fileName: string, date?: string) => async (dispatch: Dispatch) =>
    axios.get(fileDownloadUrl, { responseType: 'arraybuffer' }).then(response => {
      const fileExtension = fileDownloadUrl.substring(fileDownloadUrl.lastIndexOf('.'));
      const blob =
        fileExtension === 'csv'
          ? new Blob([response.data], { type: 'text/csv;charset=utf-8' })
          : new Blob([response.data], {
              type: EXCEL_MIME_TYPES,
            });
      FileSaver.saveAs(blob, date ? `${moment(date).format('MM-DD-YYYY_HH_mm_ss')}-${fileName}` : `${fileName}`);
    });
