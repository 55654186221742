import React, { PureComponent } from 'react';

import { Modal } from '../../../core/components';
import { ModalSection, Text, ImageSliderNavigation, ImageSliderNavigationIcon } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { InferenceAuditForm } from '../forms';
import { InferenceAudit } from '../../interfaces/interfaces';

interface Props {
  audit: InferenceAudit;
  auditIndex: number;
  closeModal: () => void;
  handleNextInference: () => void;
  handlePreviousInference: () => void;
  handleSave: (audit: InferenceAudit) => void;
  isLoading: boolean;
  lastAuditInferenceIndex: number;
}

const Form = InferenceAuditForm as any;

class InferenceAuditImageModal extends PureComponent<Props> {
  render() {
    const {
      audit,
      auditIndex,
      closeModal,
      handleNextInference,
      handlePreviousInference,
      handleSave,
      isLoading,
      lastAuditInferenceIndex,
    } = this.props;
    const { address, id, image, modelClassName, detectionNames, vehicleName } = audit;

    return (
      <Modal
        title={`${translate('inferenceAudit.inferenceAuditModel.vehicle')}: ${vehicleName}`}
        onClose={closeModal}
        size="small"
        padding="small"
        verticalSize="medium"
      >
        <ModalSection margin="no no sMedium">
          <Text align="center" block>
            {address}
          </Text>
        </ModalSection>
        <ModalSection>
          <img src={image} alt={`${modelClassName}: ${detectionNames}`} width="100%" />
          {auditIndex > 0 && (
            <ImageSliderNavigation previous onClick={handlePreviousInference}>
              <ImageSliderNavigationIcon icon="arrowLeft" />
            </ImageSliderNavigation>
          )}

          {auditIndex < lastAuditInferenceIndex && (
            <ImageSliderNavigation next onClick={handleNextInference}>
              <ImageSliderNavigationIcon icon="arrowRight" />
            </ImageSliderNavigation>
          )}
        </ModalSection>
        <ModalSection>
          <Form
            isModal
            form={`modalInferenceAudit-${id}`}
            key={`modalInferenceAudit-${id}`}
            auditIndex={auditIndex}
            onSubmit={handleSave}
            isLoading={isLoading}
          />
        </ModalSection>
      </Modal>
    );
  }
}

export default InferenceAuditImageModal;
