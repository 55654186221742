import React from 'react';
import { Layer } from 'react-map-gl';

import { APPLICATION_STATUS_ICONS_LIST } from 'src/common/constants/applicationStatuses';
import { ROUTE_MAP_CLUSTERS_SOURCE } from '../constants';

export default React.memo(function RouteMapTimelineApplicationStatusGLLayers() {
  return (
    <>
      {APPLICATION_STATUS_ICONS_LIST.map(status => (
        <Layer
          key={status.iconType}
          id={status.iconType}
          type="symbol"
          source={ROUTE_MAP_CLUSTERS_SOURCE}
          filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'icon'], status.iconType]]}
          paint={{}}
          layout={{
            'icon-image': status.iconType,
            'icon-size': 1,
            'icon-allow-overlap': true,
          }}
          minzoom={15}
        />
      ))}
    </>
  );
});
