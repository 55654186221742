import { useDispatch } from 'react-redux';

import { loadLocationFlagTypes } from 'src/common/ducks';
import { TypedResolver } from 'src/core/components';
import { PanelSectionLoading } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import { SCHEDULED } from 'src/routes/constants';
import {
  loadGeoFenceFilterOptions,
  loadRouteMapCityInsights,
  loadRouteMapVehicleData,
  loadRouteMapVehicleInsights,
  loadTravelPathStatusDetails,
} from 'src/routes/ducks/';
import { loadRouteGeoFence } from 'src/routes/ducks/routeGeoFence';
import { loadCityAlertSettings, loadVendor, resetCityAlerts } from 'src/vendors/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import RouteMapGL, { RouteMapGLProps } from './RouteMapGL';
import {
  isCityAlertFeatureEnabled,
  isNavi3FeatureEnabled,
  isTravelPathNavigationFeatureEnabled,
} from 'src/vendors/ducks/features';

export default function RouteMapResolver(props: RouteMapGLProps) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const isTravelPathFeatureEnabled = useSelector(isTravelPathNavigationFeatureEnabled);
  const isNaviV3FeatureEnabled = useSelector(isNavi3FeatureEnabled);
  const isCityAlertEnabled = useSelector(isCityAlertFeatureEnabled);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  const loadDependencies = () => {
    if (!routeSummary) return Promise.reject();

    let promises = [
      loadVendor(vendorId)(dispatch),
      loadRouteMapVehicleData(vendorId, routeSummary.routeId)(dispatch),
      loadRouteGeoFence(routeSummary.routeId)(dispatch),
      loadGeoFenceFilterOptions({ vendorId, routeId: routeSummary.routeId, routeDate: routeSummary.date })(dispatch),
    ];

    if (routeSummary.totalStopsCount >= 2 && (isTravelPathFeatureEnabled || isNaviV3FeatureEnabled)) {
      promises.push(loadTravelPathStatusDetails(routeSummary.routeId)(dispatch));
    }

    if (isCityAlertEnabled) {
      promises.push(loadCityAlertSettings(vendorId)(dispatch));
      dispatch(resetCityAlerts());
    }

    if (routeSummary.routeStatusTypeId === SCHEDULED) {
      return Promise.all(promises);
    }

    promises.push(
      loadRouteMapVehicleData(vendorId, routeSummary.routeId)(dispatch),
      loadRouteMapVehicleInsights(vendorId, routeSummary.routeId)(dispatch),
      loadRouteMapCityInsights(vendorId, routeSummary.routeId)(dispatch),
      loadLocationFlagTypes()(dispatch),
    );

    return Promise.all(promises);
  };

  return (
    <TypedResolver
      loadingComponent={PanelSectionLoading}
      resolve={loadDependencies}
      successComponent={RouteMapGL}
      successProps={props}
      loadingProps={{ minHeight: 500 }}
    />
  );
}
