import { includes, size } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import humps from 'humps';

import { Button, ModalFixedFooter, PanelSection, Text } from 'src/core/components/styled';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { downloadSampleStreetsTemplate } from 'src/customers/services/streets';
import { EXCEL_MIME_TYPES, TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import { Loader } from 'src/customers/components/styled/CustomerLocations';
import { loadStreetsImportUploadedFilesStatus, uploadStreetsImportFile } from 'src/customers/ducks';
import { Modal, Table } from 'src/core/components';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { StreetsImportModalTableRow } from './StreetsImportModalTableRow';
import { UploadArea } from 'src/customers/components/styled/CustomerImport';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

interface Props {
  closeModal: () => void;
  isSnowPlow: boolean;
}

type Drag = React.DragEvent<HTMLDivElement>;

const StreetsImportModal = ({ closeModal, isSnowPlow }: Props) => {
  const fileUploaderRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const { isUploading, uploadedFiles, isLoading } = useSelector(state => state.customers.streets);

  const [isValidHover, setIsValidHover] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (getIsVendorNotChanged(vendorId)) loadStreetsImportUploadedFilesStatus()(dispatch);
    }, 15000);

    return () => clearInterval(interval);
  }, [dispatch, uploadedFiles, vendorId]);

  const vehicleTypeId = isSnowPlow ? SNOW_PLOW_ID : STREET_SWEEPER_ID;
  const uploadedFilesFiltered =
    uploadedFiles.filter(uploadedFile => uploadedFile.vehicleTypeId === vehicleTypeId) || [];

  const isValidFile = (items: DataTransferItemList) => {
    if (items.length !== 1) return false;
    return items[0].kind === 'file' && includes([EXCEL_MIME_TYPES], items[0].type);
  };

  const onDragEnter = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer) {
      setIsDragging(true);
      if (isValidFile(e.dataTransfer.items)) setIsValidHover(true);
      else setIsValidHover(false);
    }
  };

  const onDragOver = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer) {
      if (!isDragging) setIsDragging(true);
      if (!isValidHover && isValidFile(e.dataTransfer.items)) {
        setIsValidHover(true);
      }
    }
  };

  const onDragLeave = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    setIsValidHover(false);
    setIsDragging(false);
  };

  const onDrop = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer) {
      if (isValidFile(e.dataTransfer.items)) {
        uploadFile(e.dataTransfer.files[0]);
      }
    }
    setIsValidHover(false);
    setIsDragging(false);
  };

  const uploadFile = (file: File) => {
    uploadStreetsImportFile(
      file,
      vehicleTypeId,
    )(dispatch)
      .then(() => createSuccessNotification(translate('customers.customerUpload.successMessage')))
      .catch((e: any) => {
        const errorMessageCode =
          e && e.response && e.response.data && e.response.data.code && humps.camelize(e.response.data.code);

        createErrorNotification(
          errorMessageCode
            ? translate(`customers.alertMessages.${errorMessageCode}`)
            : translate('customers.customerUpload.errorMessage'),
        );
      });
  };

  const onFileInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target && e.target.files) {
      uploadFile(e.target.files[0]);
      e.target.value = '';
    }
  };

  const openFileUploader = () => {
    fileUploaderRef.current && fileUploaderRef.current.click();
  };

  const tableCellsWidths = ['19%', '16%', '13%', '13%', '13%', '15%', '11%'];

  const tableCells = [
    {
      name: 'fileName',
      label: translate('customers.customerUpload.file'),
      width: tableCellsWidths[0],
      sortable: true,
    },
    {
      name: 'status',
      label: translate('customers.customerUpload.fileStatus'),
      width: tableCellsWidths[1],
      sortable: true,
    },
    {
      name: 'totalRecordsCount',
      label: translate('customers.customerUpload.numberOfRecords'),
      width: tableCellsWidths[2],
      sortable: true,
    },
    {
      name: 'failedRecordsCount',
      label: translate('customers.customerUpload.numberOfFailedRecords'),
      width: tableCellsWidths[3],
      sortable: true,
    },
    {
      name: 'successRecordsCount',
      label: translate('customers.customerUpload.numberOfValidRecords'),
      width: tableCellsWidths[4],
      sortable: true,
    },
    {
      name: 'errorFileDownloadUrl',
      label: translate('customers.customerUpload.outputFile'),
      width: tableCellsWidths[5],
    },
    {
      name: 'date',
      label: translate('customers.customerUpload.dateTime'),
      width: tableCellsWidths[6],
      sortable: true,
    },
  ];

  const virtualizedProps = {
    itemSize: TABLE_ROW_HEIGHT_SMALL,
    height: Math.min(uploadedFilesFiltered.length * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 4) || 1,
  };

  return (
    <Modal
      size="xLarge"
      title={translate(
        isSnowPlow ? 'customers.streets.streetsImportSnowData' : 'customers.streets.streetsImportStreetSweeperData',
      )}
      onClose={() => closeModal()}
      padding="medium no no no"
    >
      <UploadArea
        isValidHover={isValidHover}
        isDragging={isDragging}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {!isUploading && (
          <Text color={`${isDragging ? 'white' : 'primary'}`}>
            <Button
              color={`${isDragging ? 'white' : 'primary'}`}
              text
              underline
              margin="no xxSmall no"
              onClick={openFileUploader}
            >
              <Text weight="medium">{translate('common.browse')}</Text>
            </Button>
            {translate('common.or')} {translate('customers.customerUpload.dropYourExcelFile')}
          </Text>
        )}
        {isUploading && <Loader />}
      </UploadArea>

      {!!size(uploadedFilesFiltered) && (
        <PanelSection padding="small" vertical>
          <Table
            cells={tableCells}
            rows={uploadedFilesFiltered}
            rowComponent={StreetsImportModalTableRow}
            rowProps={{
              isLoading,
              tableCellsWidths,
            }}
            virtualized
            virtualizedProps={virtualizedProps}
          />
          <Text margin="small no medium" fontStyle="italic" color="info" block>
            {translate('customers.customerUpload.informativeMessage')}
          </Text>
        </PanelSection>
      )}

      <ModalFixedFooter isShadowed>
        <Button
          color="primary"
          size="medium"
          line
          onClick={() => downloadSampleStreetsTemplate(vehicleTypeId)}
          margin="no xSmall no no"
        >
          {translate('customers.customerUpload.downloadSample')}
        </Button>
        <Button
          color="primary"
          size="medium"
          onClick={openFileUploader}
          type="button"
          disabled={isUploading}
          margin="no no no xSmall"
        >
          {translate('haulerProfile.uploadFile')}
        </Button>
        <input
          accept={EXCEL_MIME_TYPES}
          id="fileSelect"
          onChange={onFileInputChanged}
          ref={fileUploaderRef}
          style={{ display: 'none' }}
          type="file"
        />
      </ModalFixedFooter>
    </Modal>
  );
};

export default StreetsImportModal;
