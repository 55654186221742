import update from 'immutability-helper';
import { findIndex } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';

import {
  loadAiModelConfigurations as doLoadAiModelConfigurations,
  saveAiModelConfigurations as doSaveAiModelConfigurations,
} from '../services/aiModelConfigurations';

// Actions
const START_LOAD = 'vendors/aiModelConfigurations/START_LOAD';
const COMPLETE_LOAD = 'vendors/aiModelConfigurations/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/aiModelConfigurations/FAIL_LOAD';
const VERSION_CHANGE = 'vendors/aiModelConfigurations/VERSION_CHANGE';
const TOGGLE_AIMODEL_CONFIGURATION = 'vendors/aiModelConfigurations/TOGGLE_AIMODEL_CONFIGURATION';
const START_SAVE = 'vendors/aiModelConfigurations/START_SAVE';
const COMPLETE_SAVE = 'vendors/aiModelConfigurations/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/aiModelConfigurations/FAIL_SAVE';
const RESET = 'vendors/aiModelConfigurations/RESET';

interface AIModelConfigurationsState {
  isLoading: boolean;
  isSaving: boolean;
  aiModelConfigurations?: any;
}

// Initial state
const initialState: AIModelConfigurationsState = {
  isLoading: false,
  isSaving: false,
  aiModelConfigurations: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          aiModelConfigurations: action.aiModelConfigurations,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case TOGGLE_AIMODEL_CONFIGURATION: {
      const { aiModelTypeId, enabled } = action;
      const configurationIndex = findIndex(state.aiModelConfigurations, { aiModelTypeId });

      return update(state, {
        aiModelConfigurations: {
          [configurationIndex]: { enabled: { $set: enabled } },
        },
      } as any);
    }

    case VERSION_CHANGE: {
      const { aiModelTypeId, versionNumber } = action;
      const configurationIndex = findIndex(state.aiModelConfigurations, { aiModelTypeId });

      return update(state, {
        aiModelConfigurations: {
          [configurationIndex]: { versionNumber: { $set: versionNumber } },
        },
      } as any);
    }

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return {
        ...state,
        aiModelConfigurations: null,
      };

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (aiModelConfigurations: any) => ({
  type: COMPLETE_LOAD,
  aiModelConfigurations,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const resetAiModelConfigurations = () => ({
  type: RESET,
});

export const loadAiModelConfigurations = (vendorId: number, vehicleTypeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadAiModelConfigurationsPromise = doLoadAiModelConfigurations(vendorId, vehicleTypeId);
  loadAiModelConfigurationsPromise
    .then(aiModelConfigurations => dispatch(completeLoad(aiModelConfigurations)))
    .catch(() => dispatch(failLoad()));

  return loadAiModelConfigurationsPromise;
};

export const toggleAiModelConfiguration = (aiModelTypeId: number, enabled: boolean) => ({
  type: TOGGLE_AIMODEL_CONFIGURATION,
  aiModelTypeId,
  enabled,
});

export const onConfigurationVersionChange = (aiModelTypeId: number, versionNumber: number | string) => ({
  type: VERSION_CHANGE,
  aiModelTypeId,
  versionNumber,
});

export const saveAiModelConfigurations = (modelConfigurations: any, commands: any) => (
  dispatch: Dispatch,
  getState: any,
) => {
  const aIModelConfigurations = getState().vendors.aiModelConfigurations.aiModelConfigurations;
  const newAIModelConfigurations = { ...aIModelConfigurations, modelConfigurations, commands };
  dispatch(startSave());
  const saveAiModelConfigurationsPromise = doSaveAiModelConfigurations(newAIModelConfigurations);
  saveAiModelConfigurationsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveAiModelConfigurationsPromise;
};

// Selectors

export const modelConfigurationSelector = (state: any) =>
  state.vendors.aiModelConfigurations.aiModelConfigurations &&
  state.vendors.aiModelConfigurations.aiModelConfigurations.modelConfigurations;

export const commandsConfigurationSelector = (state: any) =>
  state.vendors.aiModelConfigurations.aiModelConfigurations &&
  state.vendors.aiModelConfigurations.aiModelConfigurations.commands;
