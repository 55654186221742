import translate from 'src/core/services/translate';

export const billMonthOfAnnualOptions = [
  {
    label: translate('common.months.january'),
    value: 1,
  },
  {
    label: translate('common.months.february'),
    value: 2,
  },
  {
    label: translate('common.months.march'),
    value: 3,
  },
  {
    label: translate('common.months.april'),
    value: 4,
  },
  {
    label: translate('common.months.may'),
    value: 5,
  },
  {
    label: translate('common.months.june'),
    value: 6,
  },
  {
    label: translate('common.months.july'),
    value: 7,
  },
  {
    label: translate('common.months.august'),
    value: 8,
  },
  {
    label: translate('common.months.september'),
    value: 9,
  },
  {
    label: translate('common.months.october'),
    value: 10,
  },
  {
    label: translate('common.months.november'),
    value: 11,
  },
  {
    label: translate('common.months.december'),
    value: 12,
  },
];
