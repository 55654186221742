import { filter, take } from 'lodash-es';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { PermissionGuard } from 'src/account/components';
import {
  CUSTOMERS_DETAILS_ADD_LOCATION,
  CUSTOMERS_DETAILS_DELETE_LOCATION,
  CUSTOMERS_DETAILS_EDIT_LOCATION,
} from 'src/account/constants';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { ACCOUNT_STATUSES, STREET } from 'src/common/constants';
import { ACTIVE, CLOSED, NEW, ON_HOLD, SUSPENDED } from 'src/common/constants/accountStatuses';
import { DuckFunction } from 'src/contracts/ducks';
import { ActionButtonTooltip, PopoverWrapper } from 'src/core/components';
import { Link, TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import {
  CustomerLocationServices,
  ExpandServices,
  Loader,
  MoreServicesContainer,
  ServicesContainer,
} from 'src/customers/components/styled/CustomerLocations';
import { SERVICE_TYPE_ICONS } from 'src/customers/constants';
import { loadCustomerLocations, loadServices } from 'src/customers/ducks';
import { CustomerLocation } from 'src/customers/interfaces/Customers';
import { Service, StatusIds } from 'src/customers/interfaces/Services';
import { AppState } from 'src/store';
import { billingFeatureStatusSelector, isServiceContractWorkflowFeatureEnabled } from 'src/vendors/ducks/features';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { ServiceContractWorkflowModalResolver } from '../../modals/serviceContractWorkflowModal/ServiceContractWorkflowModalResolver';
import { CustomerServiceIcon } from './CustomerServiceIcon';
import ServiceDetail from './ServiceDetail';
import { ServicePopover } from './ServicePopover';

const Icons = SERVICE_TYPE_ICONS as any;
interface Props extends CustomerLocation {
  accountStatusType: string;
  customerId: number;
  customerTypeId: number;
  deleteCustomerLocation: (event: any, locationId: number) => void;
  editCustomerLocation: (event: any, locationId: number) => void;
  index: number;
  loadCustomerLocations: DuckFunction<typeof loadCustomerLocations>;
  loadServices: DuckFunction<typeof loadServices>;
  openProximitySearchModal: (event: any, customerId: number) => void;
  push: (url: string) => void;
  serviceStatusFilters: StatusIds[];
  vendorId: number;
  isServiceContractWorkflowEnabled: boolean;
  customerAccountStatusTypeId?: number;
  isBillingFeatureActive?: boolean;
  isPaymentOverdue: boolean;
}

interface State {
  isExpanded?: boolean;
  isLoadingServices?: boolean;
  isServiceContractWorkflowModalOpen?: boolean;
  services: Service[];
}

class CustomerLocationsTableRow extends PureComponent<Props, State> {
  state: State = { services: [] };

  componentDidUpdate(prevProps: Props) {
    const { serviceStatusFilters } = this.props;

    if (serviceStatusFilters !== prevProps.serviceStatusFilters) this.refreshServices();
  }

  goToLocationDetails = () => {
    const { accountStatusType, customerId, customerTypeId, id, push } = this.props;
    const params = new URLSearchParams(window.location.search);
    params.set('locationId', id.toString());
    params.set('accountStatusTypeId', accountStatusType);
    const searchParams = params.toString();

    if (customerTypeId !== STREET) push(`/customers/customers/${customerId}/services?${searchParams}`);
  };

  refreshServices = () => {
    const { loadCustomerLocations, customerId } = this.props;
    loadCustomerLocations(customerId);
    this.loadServices();
  };

  loadServices = () => {
    const { id, loadServices, serviceStatusFilters } = this.props;
    this.setState({ isLoadingServices: true });
    loadServices(id)
      .then((data: any) => {
        this.setState({
          isExpanded: true,
          isLoadingServices: false,
          services: this.sortServices(
            filter(data.services, s => serviceStatusFilters.includes(s.accountStatusTypeId)),
            'accountStatusTypeId',
          ),
        });
      })
      .catch(() => this.setState({ isLoadingServices: false }));
  };

  openServices = () => {
    const { isExpanded } = this.state;
    if (isExpanded) {
      this.setState({ isExpanded: false });
    } else {
      this.loadServices();
    }
  };

  toggleWorkflowModal = () => {
    const { isServiceContractWorkflowModalOpen } = this.state;
    this.setState({ isServiceContractWorkflowModalOpen: !isServiceContractWorkflowModalOpen });
  };

  sortServices = (services: Service[], sortKey: 'accountStatusTypeId' | 'serviceContractAccountStatusTypeId') => {
    const customOrder = {
      [NEW]: 0,
      [ACTIVE]: 1,
      [SUSPENDED]: 2,
      [ON_HOLD]: 3,
      [CLOSED]: 4,
    };

    return services.sort((x, y) => {
      if (customOrder[x[sortKey]] < customOrder[y[sortKey]]) {
        return -1;
      }
      if (customOrder[x[sortKey]] > customOrder[y[sortKey]]) {
        return 1;
      }
      return 0;
    });
  };

  render() {
    const {
      accountNumber,
      accountStatusId,
      address: { formattedAddress },
      customerTypeId,
      customerId,
      deleteCustomerLocation,
      editCustomerLocation,
      id,
      index,
      name,
      openProximitySearchModal,
      serviceContracts,
      serviceStatusFilters,
      vendorId,
      isServiceContractWorkflowEnabled,
      customerAccountStatusTypeId,
      accountStatusTypeId: locationAccountStatusTypeId,
      isBillingFeatureActive,
      isPaymentOverdue,
    } = this.props;

    const { isExpanded, services, isLoadingServices, isServiceContractWorkflowModalOpen } = this.state;

    const filteredServiceContracts = this.sortServices(
      filter(serviceContracts, fsc => serviceStatusFilters.includes(fsc.serviceContractAccountStatusTypeId)),
      'serviceContractAccountStatusTypeId',
    );

    const isViewOnly = checkIfViewOnly();
    const isSupport = checkIfSupport();

    const hasWorkflowAccess =
      (isViewOnly || isSupport ? false : isServiceContractWorkflowEnabled) &&
      customerAccountStatusTypeId !== CLOSED &&
      locationAccountStatusTypeId !== CLOSED;

    return (
      <>
        <TableRow id={`location-${id}`}>
          <TableCell width="10%" id={`location-${id}-number`}>
            {index + 1}
          </TableCell>

          <TableCell onClick={this.goToLocationDetails} vertical align="center" width="30%">
            <Text block weight="medium" margin="no no xxSmall" singleLine id={`location-${id}-name`}>
              {name}
            </Text>

            <Text weight="light" margin="no no xxSmall" singleLine id={`location-${id}-address`}>
              {formattedAddress}
            </Text>
          </TableCell>

          <TableCell vertical align="center" width="15%">
            <Text weight="light" singleLine id={`location-${id}-account-status`}>
              {ACCOUNT_STATUSES[accountStatusId] && ACCOUNT_STATUSES[accountStatusId].name}
              {isPaymentOverdue && isBillingFeatureActive && (
                <ActionButtonTooltip size="medium" icon="overdue" tooltip="pastDue" margin="no no no xxSmall" />
              )}
            </Text>

            {customerTypeId !== STREET && (
              <Text weight="light" singleLine id={`location-${id}-account-number`}>
                {accountNumber}
              </Text>
            )}
          </TableCell>

          <TableCell vertical align="left" width="35%">
            <CustomerLocationServices>
              {take(filteredServiceContracts, 5).map((sc, i) => (
                <PopoverWrapper
                  key={i}
                  triggerButton={
                    <CustomerServiceIcon
                      accountStatusId={sc.serviceContractAccountStatusTypeId}
                      src={Icons[sc.serviceTypeIcon === 'toter' ? 'cart' : sc.serviceTypeIcon]}
                    />
                  }
                  popoverContent={<ServicePopover service={sc} />}
                  width="53px"
                  size="large"
                />
              ))}

              {filteredServiceContracts.length > 5 && <MoreServicesContainer>...</MoreServicesContainer>}

              {!!filteredServiceContracts.length && (
                <ExpandServices>
                  {isLoadingServices ? (
                    <Loader />
                  ) : (
                    <TableActionButton
                      id={isExpanded ? `hide-customer-${id}-services-button` : `show-customer-${id}-services-button`}
                      onClick={this.openServices}
                    >
                      <ActionButtonTooltip
                        icon={isExpanded ? 'arrowUpFill' : 'arrowDownFill'}
                        tooltip={isExpanded ? 'hideServices' : 'showServices'}
                      />
                    </TableActionButton>
                  )}
                </ExpandServices>
              )}
            </CustomerLocationServices>
          </TableCell>

          <TableCell align="right" width="10%">
            {customerTypeId !== STREET && (
              <>
                {hasWorkflowAccess && !isBillingFeatureActive && (
                  <TableActionButton id={`location-${id}-workflow-service-button`} onClick={this.toggleWorkflowModal}>
                    <ActionButtonTooltip
                      icon="swap"
                      size="medium"
                      rotationAngle="90"
                      tooltip={translate('customers.serviceUpdate')}
                      tooltipAsString
                      customViewBox="-4 -4 32 32"
                    />
                  </TableActionButton>
                )}
                <TableActionButton
                  id={`location-${id}-search-vehicle-button`}
                  onClick={event => openProximitySearchModal(event, id)}
                >
                  <ActionButtonTooltip icon="searchVehicle" tooltip="customerProximitySearch" />
                </TableActionButton>
              </>
            )}

            <PermissionGuard permission={CUSTOMERS_DETAILS_EDIT_LOCATION}>
              <TableActionButton
                id={`location-${id}-edit-location-button`}
                onClick={event => editCustomerLocation(event, id)}
              >
                <ActionButtonTooltip icon="edit" tooltip="editLocation" />
              </TableActionButton>
            </PermissionGuard>

            {customerTypeId !== STREET && (
              <PermissionGuard permission={CUSTOMERS_DETAILS_ADD_LOCATION}>
                <TableActionButton>
                  <Link
                    id="add-service-link"
                    color="grayDark"
                    to={`/customers/customers/${customerId}/location/${id}/service/-1`}
                  >
                    <ActionButtonTooltip icon="add" tooltip="addNewService" />
                  </Link>
                </TableActionButton>
              </PermissionGuard>
            )}

            {customerTypeId !== STREET && !isBillingFeatureActive && (
              <PermissionGuard permission={CUSTOMERS_DETAILS_DELETE_LOCATION}>
                <TableActionButton
                  id={`location-${id}-delete-location-button`}
                  onClick={event => deleteCustomerLocation(event, id)}
                >
                  <ActionButtonTooltip icon="delete" tooltip="deleteLocation" />
                </TableActionButton>
              </PermissionGuard>
            )}
          </TableCell>
        </TableRow>

        {isExpanded && (
          <ServicesContainer>
            {services &&
              services.map(s => (
                <ServiceDetail
                  refreshServices={this.refreshServices}
                  locationId={id}
                  key={s.id}
                  service={s}
                  vendorId={vendorId}
                />
              ))}
          </ServicesContainer>
        )}

        {!!isServiceContractWorkflowModalOpen && (
          <ServiceContractWorkflowModalResolver
            closeModal={this.toggleWorkflowModal}
            onSubmitSuccess={this.refreshServices}
            selectedCustomerId={customerId}
            selectedLocationId={id}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoadingServices: state.customers.services.isLoading,
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  isServiceContractWorkflowEnabled: isServiceContractWorkflowFeatureEnabled(state),
  isBillingFeatureActive: billingFeatureStatusSelector(state.vendors.features.features),
});

export default connect(mapStateToProps, { loadServices, loadCustomerLocations })(CustomerLocationsTableRow);
