import styled from 'styled-components';
import Icon from '../Icon';
import { sizeMapper } from '../../../utils/styles';

export const FileOpenDialogToggleInput = styled.input`
  display: none;
`;

export const FileOpenDialogToggleIcon = styled(Icon)`
  vertical-align: middle;
  width: 17px;
  height: 17px;
  color: ${props => props.theme.brandPrimary};
`;

export const FileOpenDialogToggleText = styled.span`
  vertical-align: middle;

  ${FileOpenDialogToggleIcon} + & {
    margin-left: 10px;
  }
`;

export const FileOpenDialogToggleLabel = styled.label`
  display: inline-block;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 13px;
`;

export const FileOpenDialogToggle = styled.div<{ margin?: string }>`
  margin: ${props => sizeMapper(props.margin, 'no')};
`;
