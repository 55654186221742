import { http } from '../../core/services/http';
import {
  BulkAcceptAwardsObject,
  BulkDeclineAwardsObject,
  OpportunitiesSearchFilter,
} from '../interfaces/Opportunities';
import { downloadExcelFile } from '../../utils/services/downloadExcelFile';
import { transformOpportunity, transformSourcingOpportunities } from './transformOpportunity';
import translate from '../../core/services/translate';

export const loadOpportunityByUserId = (
  userId: string | number | undefined,
  vendorId: number,
  isActionRequired: boolean,
  startDate: string,
  endDate: string,
) =>
  http
    .get(`bpm/sourcing/hauler/consolidatedOpportunities/vendor/${vendorId}/user/${userId}`, {
      params: { isActionRequired, startDate, endDate, includeAllStatus: true },
    })
    .then(response => transformSourcingOpportunities(response.data));

export const updateBiddingOpportunity = (bidOpportunityObj: any) =>
  http
    .put(`bpm/sourcing/hauler/bidOpportunities`, { requestedChanges: [bidOpportunityObj] })
    .then(response => transformOpportunity(response.data));

export const updateBulkAwards = (
  bulkAwardsData: BulkDeclineAwardsObject | BulkAcceptAwardsObject,
  userId: string | undefined,
  vendorId: number,
  isActionRequired?: boolean,
  startDate?: string,
  endDate?: string,
  includeAllStatus = true,
) =>
  http
    .put(`bpm/sourcing/hauler/accept/bulk/awarded/opportunities/vendor/${vendorId}/user/${userId}`, bulkAwardsData, {
      params: { isActionRequired, startDate, endDate, includeAllStatus },
    })
    .then(response => transformSourcingOpportunities(response.data));

export const downloadOpenBidsTemplate = (vendorId: number, params: OpportunitiesSearchFilter) =>
  downloadExcelFile(
    `sourcing/vendors/${vendorId}/opportunities/export`,
    translate('opportunity.opportunities.sampleTemplate'),
    params,
  );

export const uploadOpenBidsFile = (fileData: any, vendorId: number) => {
  const payload = new FormData();
  payload.append('file', fileData);
  payload.append('data', JSON.stringify({ vendorId }));

  return http.put(`sourcing/vendors/${vendorId}/opportunities/uploads`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getUploadedFilesStatus = (vendorId: number): Promise<{ data: any[] }> =>
  http.get(`/sourcing/vendors/${vendorId}/opportunities/uploads`);

export const downloadReport = (reportId: number, vendorId: number) =>
  downloadExcelFile(
    `/sourcing/vendors/${vendorId}/opportunities/reports/${reportId}`,
    translate('opportunity.opportunities.errorFile'),
  );

export const getSourcingEventNotes = (itemId: number, eventServiceId: number) =>
  http
    .get(`sourcing/vendors/${itemId}/opportunities/notes?eventServiceId=${eventServiceId}`)
    .then(response => response.data.haulerNotes);

export const getMarketSubTypes = () => http.get('gus/opportunities/market-condition').then(response => response.data);
