import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { APPLICATION_STATUS_ICONS_LIST } from 'src/common/constants/applicationStatuses';
import { ApplicationStatusFeatureProperties } from './utils';
import { ROUTE_MAP_CLUSTERS_SOURCE, ROUTE_MAP_APPLICATION_STATUS_LAYER } from '../constants';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import RouteMapApplicationStatusGLLayers from './RouteMapTimelineApplicationStatusGLLayers';
import RouteMapApplicationStatusGLPopupResolver from './RouteMapTimelineApplicationStatusGLPopupResolver';

const mapImages = APPLICATION_STATUS_ICONS_LIST.map(status => ({
  id: status.iconType,
  url: status.icon,
  sdf: true,
}));

const mapImagesOptions = { addLayers: false };

type Props = {
  map: mapboxgl.Map;
};

export default function RouteMapTimelineApplicationStatusGL({ map }: Props) {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === ROUTE_MAP_CLUSTERS_SOURCE &&
              feature.properties?.layer === ROUTE_MAP_APPLICATION_STATUS_LAYER,
          );

        if (feature) {
          const { vehicleId, statusIndex } = feature.properties as unknown as ApplicationStatusFeatureProperties;
          dispatch(
            setRouteMapSelectedFeature(RouteMapFeature.applicationModeChanges, vehicleId, {
              coordinates: event.lngLat,
              vehicleTrackingApplicationStatusIndex: statusIndex,
            }),
          );
        }
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <RouteMapApplicationStatusGLLayers />
      <RouteMapApplicationStatusGLPopupResolver />
    </>
  );
}
