import { Icon } from 'src/core/components';
import { BareButtonStyle } from 'src/core/components/styled';
import styled, { css } from 'styled-components';
import { loading } from '../../../core/styles';

export const MapControl = styled.div<{ notAbsolute?: boolean; left?: string; top?: string }>`
  ${p =>
    p.notAbsolute
      ? css`
          button {
            background-color: initial;
            color: ${p => p.theme.brandPrimary};
            font-size: 12px;
            padding: 0;
          }
        `
      : css`
          position: absolute;
          left: ${p.left || '20px'};
          top: ${p.top || '20px'};
        `}
`;

export type ComplexMapControlPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

const calculateOffset = (offset = 0, normalMargin = 20) => `${normalMargin + offset}px`;

interface ComplexMapControlProps {
  background?: string;
  normalMargin?: number;
  position?: ComplexMapControlPosition;
  vertical?: boolean;
  xOffset?: number;
  yOffset?: number;
  zIndex?: number;
}

export const ComplexMapControl = styled.div<ComplexMapControlProps>`
  position: absolute;
  display: flex;

  z-index: ${props => props.zIndex || 2};

  ${props =>
    props.background &&
    css`
      background: ${props.background};
      padding: 5px;
    `}

  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
      align-items: flex-end;
    `}

  ${props =>
    (!props.position || props.position === 'top-left') &&
    css`
      top: ${calculateOffset(props.yOffset, props.normalMargin)};
      left: ${calculateOffset(props.xOffset, props.normalMargin)};
    `}

  ${props =>
    props.position === 'top-right' &&
    css`
      top: ${calculateOffset(props.yOffset, props.normalMargin)};
      right: ${calculateOffset(props.xOffset, props.normalMargin)};
    `}

  ${props =>
    props.position === 'bottom-left' &&
    css`
      bottom: ${calculateOffset(props.yOffset, props.normalMargin)};
      left: ${calculateOffset(props.xOffset, props.normalMargin)};
    `}

  ${props =>
    props.position === 'bottom-right' &&
    css`
      bottom: ${calculateOffset(props.yOffset, props.normalMargin)};
      right: ${calculateOffset(props.xOffset, props.normalMargin)};
    `}
`;

interface MapLegendProps {
  collapsed?: boolean;
  color?: boolean;
  position?: string;
  minWidth?: string;
}

export const MapLegend = styled.div<MapLegendProps>`
  background: #fff;
  padding: 6px 10px;
  font-size: 15px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  & b {
    margin-right: 20px;
  }

  & > svg,
  & > span > svg {
    width: 15px;
    height: 15px;
    transition: transform 0.2s;
    transform: rotate(0);
    margin: 0;
    position: absolute;
    top: 8px;
    right: 5px;
  }

  ${props =>
    props.collapsed &&
    css`
      & > svg,
      & > span > svg {
        transform: rotate(180deg);
      }
    `}
`;

export const MapLegendInfo = styled.div<MapLegendProps>`
  display: flex;
  flex-direction: column;
  margin: 12px 0 0 0;

  ${props =>
    props.position &&
    css`
      position: ${props.position};
    `}

  ${props =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `}
`;

export const MapLegendInfoBullet = styled.div<MapLegendProps>`
  background: ${props => props.color};
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 2px;
`;

export const GoogleMapLoading = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: ${props => props.theme.grayLight};

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -16px 0 0 -16px;
    ${loading('24px')};
  }
`;

export const RouteMapWrapper = styled.div`
  height: calc(100vh - 500px);
  width: 100%;

  & .overlays {
    & > :not(#editor) {
      pointer-events: all;
    }
  }

  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-attrib {
    display: none;
  }
`;

export const RouteMapInfoOverlay = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  opacity: 0.7;

  h4 {
    text-align: center;
    margin-top: 200px;
  }
`;

export const RouteMapContainer = styled.div<{ scroll?: boolean }>`
  height: calc(100vh - 500px);
  width: 100%;
  position: relative;

  ${props =>
    props.scroll &&
    css`
      overflow: auto;
    `}
`;

export const RouteSequenceSummaryContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  background: white;
  padding: 8px;
`;

export const RouteSequenceSummaryTitleContainer = styled.span`
  margin-right: 16px;
`;

export const RouteSequenceSummaryInfoContainer = styled.div`
  display: flex;
`;

export const RouteSequenceSummaryLabels = styled.div`
  flex: 1;
  white-space: nowrap;
  margin-right: 16px;
  & > * {
    margin-top: 8px;
  }
`;

export const RouteSequenceSummaryValues = styled.div`
  flex: 1;
  white-space: nowrap;
  & > * {
    margin-top: 8px;
  }
`;

export const RouteSequenceInstructionsPanel = styled.div`
  display: flex;
  background-color: #fbfad7;
  justify-content: space-between;
  padding: 30px 20px;
  min-height: 120px;
`;

export const CloseInstructions = styled.button`
  ${BareButtonStyle}
  z-index: 2101;
  font-size: 20px;
  font-weight: 500;
  padding: 5px;
  color: ${p => p.theme.grayDarker};
  background: #fbfad7;
  width: 40px;
  margin-top: -10px;
  margin-left: 10px;
`;

interface InstructionsBackButtonIconProps {
  forward?: boolean;
  disabled?: boolean;
}

export const InstructionsBackButtonIcon = styled(Icon).attrs({
  icon: 'back',
})<InstructionsBackButtonIconProps>`
  display: inline-block;
  vertical-align: top;
  width: 25px;
  height: 25px;
  color: ${props => props.theme.brandPrimary};

  ${props =>
    props.forward &&
    css`
      transform: rotate(180deg);
    `};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
      color: ${props.theme.grayBase};
    `};
`;
