import styled, { css } from 'styled-components';

import { theme } from '../../styles';
import { sizeMapper } from '../../../utils/styles';

export const SwitchWrapper = styled.span<{ margin?: string }>`
  display: flex;
  align-items: center;
  margin: ${props => sizeMapper(props.margin, 'no no xSmall no')};
`;

export const SwitchLabel = styled.span<{ disabled?: boolean }>`
  padding: 0px 10px;

  ${props =>
    props.disabled &&
    css`
      color: ${theme.grayDarker};
    `};
`;
