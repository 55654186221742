import { mapKeys } from 'lodash-es';

import translate from '../../core/services/translate';

export const STREET_SEGMENT_ASSIGNED_STATUSES = [
  {
    id: 1,
    label: translate('customers.streetNetwork.status.assignedToThisRoute'),
  },
  {
    id: 2,
    label: translate('customers.streetNetwork.status.notAssignedToThisRoute'),
  },
];

export const STREET_SEGMENT_ASSIGNED_TYPES_STATUSES = [
  {
    id: 1,
    label: translate('customers.streetNetwork.status.assignedToThisRouteTemplate'),
  },
  {
    id: 2,
    label: translate('customers.streetNetwork.status.assignedToAnotherRouteTemplate'),
  },
  {
    id: 3,
    label: translate('customers.streetNetwork.status.notAssignedToAnyRouteTemplate'),
  },
];
export const STREET_SEGMENT_ASSIGNED_TYPES_CREATE = [
  {
    id: 1,
    label: '',
  },
  {
    id: 2,
    label: translate('customers.streetNetwork.status.assignedToAnotherRouteTemplate'),
  },
  {
    id: 3,
    label: translate('customers.streetNetwork.status.notAssignedToAnyRouteTemplate'),
  },
];

export const STREET_SEGMENT_STATUSES = [
  {
    id: 1,
    label: translate('customers.streetNetwork.status.active'),
  },
  {
    id: 2,
    label: translate('customers.streetNetwork.status.inactive'),
  },
];

export const STREET_SEGMENT_NUMBER_OF_LANES = [
  {
    id: 0,
    label: translate('customers.streetNetwork.none'),
  },
  {
    id: 1,
    label: '1',
  },
  {
    id: 2,
    label: '2',
  },
  {
    id: 3,
    label: '3',
  },
  {
    id: 4,
    label: '4',
  },
];

export const STREET_SEGMENT_NUMBER_OF_PASSES = [
  {
    id: 0,
    label: translate('customers.streetNetwork.none'),
  },
  {
    id: 1,
    label: '1',
  },
  {
    id: 2,
    label: '2',
  },
  {
    id: 3,
    label: '3',
  },
  {
    id: 4,
    label: '4',
  },
];

export const STREET_SEGMENT_SERVICE_SIDES = [
  {
    id: 0,
    label: translate('customers.streetNetwork.none'),
  },
  {
    id: 1,
    label: translate('customers.streetNetwork.serviceSide.right'),
  },
  {
    id: 2,
    label: translate('customers.streetNetwork.serviceSide.left'),
  },
  {
    id: 3,
    label: translate('customers.streetNetwork.serviceSide.both'),
  },
];

export const STREET_SEGMENT_SNOW_OR_SWEEPER_ROUTE_STATUSES = [
  {
    id: '_1',
    label: translate('common.pickupStatuses.notServiced'),
  },
  {
    id: '_2',
    label: translate('common.pickupStatuses.partiallyServiced'),
  },
  {
    id: '_3',
    label: translate('common.pickupStatuses.serviced'),
  },
];

export const STREET_SEGMENT_SNOW_OR_SWEEPER_ROUTE_STATUSES_NOT_COMPLEX = [
  {
    id: '_1',
    label: translate('common.pickupStatuses.notServiced'),
  },
  {
    id: '_3',
    label: translate('common.pickupStatuses.serviced'),
  },
];

export const STREET_SEGMENT_MAP_FILTERS_FORM_NAME = 'streetSegmentMapFiltersForm';
export const ROUTE_MAP_ROUTE_SEGMENTS_SOURCE_SNOW_PLOW = 'routeMapRouteSegmentsSourceSnowPlow';

export const STREET_SWEEPER_BROOM_SIDE_TYPE = mapKeys(
  [
    {
      id: 1,
      name: translate('customers.streetNetwork.broomSide.left'),
    },
    {
      id: 2,
      name: translate('customers.streetNetwork.broomSide.right'),
    },
    {
      id: 3,
      name: translate('customers.streetNetwork.broomSide.main'),
    },
  ],
  'id',
);

export const STREET_SWEEPER_SEGMENT_TYPE = mapKeys(
  [
    {
      id: 1,
      name: translate('customers.streetNetwork.segmentType.residential'),
    },
    {
      id: 2,
      name: translate('customers.streetNetwork.segmentType.downTownCurb'),
    },
    {
      id: 3,
      name: translate('customers.streetNetwork.segmentType.mainCurb'),
    },
    {
      id: 4,
      name: translate('customers.streetNetwork.segmentType.stadium'),
    },
    {
      id: 5,
      name: translate('customers.streetNetwork.segmentType.dust'),
    },
    {
      id: 6,
      name: translate('customers.streetNetwork.segmentType.industrial'),
    },
    {
      id: 7,
      name: translate('customers.streetNetwork.segmentType.yard'),
    },
  ],
  'id',
);

export const SNOW_OR_SWEEPER_SERVICE_ACTIVITY_TYPE = mapKeys(
  [
    {
      id: 1,
      name: translate('customers.streetNetwork.serviceActivity.plowing'),
    },
    {
      id: 2,
      name: translate('customers.streetNetwork.serviceActivity.salting'),
    },
    {
      id: 3,
      name: translate('customers.streetNetwork.serviceActivity.brining'),
    },
    {
      id: 4,
      name: translate('customers.streetNetwork.serviceActivity.swept'),
    },
  ],
  'id',
);

export const ROUTE = 'route';
export const ROUTE_TEMPLATE = 'routeTemplate';
export const STREET_NETWORK = 'streetNetwork';
