import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';
import { find, get, size } from 'lodash-es';

import { AppState } from '../../../store';
import { ActionButtonTooltip, PopoverWrapper } from '../../../core/components';
import {
  AcceptServiceChangeRequestModal,
  ChangeRequestNotesModalResolver,
  DeclineServiceChangeRequestModal,
  RequestServiceChangesModal,
} from '../modal';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import confirm from '../../../core/services/confirm';
import { CHANGE_REQUESTS_TYPE, CHANGE_SERVICE_STATUS, HAUL_FEE, HAULING, HAULING_EXTRA_PICKUP } from '../../constants';
import { DetailsContainer } from '../../../fleet/components/styled/RubiconDispatches';
import {
  DetailsListBodySection,
  IconButton,
  IconButtonIcon,
  Text,
  TableActionButton,
  TableRow,
  TableCell,
} from '../../../core/components/styled';
import { DuckAction } from '../../../contracts/ducks';
import { EC, FC, MC } from '../../constants/changeRequestsType';
import { InfoIcon } from '../../../routes/components/styled';
import PriceDetailsPopover from '../PriceDetailsPopover';
import { REQUESTED } from '../../constants/status';
import ServiceDetailsPopover from '../ServiceDetailsPopover';
import { showNotesModal } from '../../../fleet/ducks';
import translate from '../../../core/services/translate';
import { ServiceChangeRequestMobileListRowDetailsView } from '../ServiceChangeRequestMobileListRowDetailsView';

export interface ServiceSummary {
  equipmentId: string;
  equipmentName: string;
  equipmentType: string;
  equipmentSubType: string;
  equipmentUnits: string;
  equipmentSize: string;
  quantity: number;
  frequencyId: string;
  frequencyDays: string[];
  materialId: string;
  serviceDescription: string;
  startDate: Date;
  endDate: Date;
  frequency: string;
  material: string;
}

interface ComponentProps extends RouteComponentProps {
  caseId: number;
  currentServiceLevelSummary: any;
  customerName: string;
  customerAddress: string;
  daysLeft?: string;
  equipmentChanged: boolean;
  equipmentType?: string;
  equipmentSubType: string;
  frequency?: string;
  frequencyChanged: boolean;
  hasVendorNotes: boolean;
  hasWorkOrderNotes: boolean;
  isActionRequired: boolean;
  material?: string;
  materialChanged: boolean;
  options: any;
  portalStatus: string;
  quantity?: string;
  requestedServiceLevelSummary: ServiceSummary;
  siteName: string;
  status?: string;
  workOrder: string;
  workflowStatus?: string;
  showMobileDetails?: boolean;
}

interface ReduxProps {
  equipmentSizes: any[];
  offer: any;
  response: any;
  requestedChanges: any;
  userId: number;
  vendorId?: number;
  reset: typeof reset;
  showNotesModal: DuckAction<typeof showNotesModal>;
}

interface State {
  isAcceptServiceChangeModalOpen: boolean;
  isDeclineServiceChangeModalOpen: boolean;
  isNoteModalOpen: boolean;
  isRequestChangesForServiceModalOpen: boolean;
  response?: any;
  requestedChanges: any;
}

type Props = ReduxProps & ComponentProps;

class ServiceChangeRequestsTableRow extends PureComponent<Props, State> {
  readonly state: State = {
    isAcceptServiceChangeModalOpen: false,
    isDeclineServiceChangeModalOpen: false,
    isNoteModalOpen: false,
    isRequestChangesForServiceModalOpen: false,
    response: undefined,
    requestedChanges: undefined,
  };

  // Accept
  openAcceptOpportunityModal = (event?: any) => {
    this.setState({ isAcceptServiceChangeModalOpen: true });
    if (event) event.stopPropagation();
  };

  closeAcceptOpportunityModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('acceptServiceChangeRequest');
    this.setState({ isAcceptServiceChangeModalOpen: false });
  };

  // Request Changes
  openRequestServiceChangesModal = (event: any, value: any) => {
    this.setState({ isRequestChangesForServiceModalOpen: true });
    this.setState({ response: value });
  };

  closeRequestServiceChangesModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('requestServiceChange');
    this.setState({ isRequestChangesForServiceModalOpen: false });
  };

  // Decline
  openDeclineServiceChangeModal = () => {
    this.setState({ isDeclineServiceChangeModalOpen: true });
  };

  closeDeclineServiceChangeModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('declineServiceChangeRequest');
    this.setState({ isDeclineServiceChangeModalOpen: false });
  };

  openNotesModal = (isWorkOrderNote: boolean) => {
    const { customerName, customerAddress, offer, workOrder, showNotesModal } = this.props;

    if (isWorkOrderNote) {
      showNotesModal({
        workOrder,
        serviceDate: offer.deliveryDate,
        title: customerName,
        subtitle: customerAddress,
        isWorkOrderNote,
      });
    } else {
      this.setState({
        isNoteModalOpen: true,
      });
    }
  };

  closeWorkOrderNotesModal = () => {
    this.setState({ isNoteModalOpen: false });
  };

  getOverallServiceCharge = (overallServiceCharges: number, displayFees: any[]) => {
    if (overallServiceCharges) {
      return translate('opportunity.overallMonthlyCharges', { overallCharges: overallServiceCharges });
    } else {
      const haulingCharges = get(
        find(displayFees, fee => fee.name === HAUL_FEE || fee.name === HAULING || fee.name === HAULING_EXTRA_PICKUP),
        'value',
      );
      return !!haulingCharges ? `${haulingCharges}` : '-';
    }
  };

  render() {
    const {
      caseId,
      currentServiceLevelSummary,
      customerName,
      customerAddress,
      daysLeft,
      equipmentChanged,
      equipmentSizes,
      equipmentSubType,
      equipmentType,
      frequency,
      frequencyChanged,
      hasVendorNotes,
      hasWorkOrderNotes,
      isActionRequired,
      material,
      materialChanged,
      options,
      offer,
      portalStatus,
      requestedChanges,
      requestedServiceLevelSummary,
      siteName,
      workOrder,
      showMobileDetails,
    } = this.props;

    const {
      isAcceptServiceChangeModalOpen,
      isDeclineServiceChangeModalOpen,
      isRequestChangesForServiceModalOpen,
      isNoteModalOpen,
      response,
    } = this.state;
    const { displayFees, serviceRecommendation } = requestedChanges;
    const { canAccept, canDecline, canRequestDateChange } = options;
    const filterEquipmentsByBin = equipmentSizes.filter((obj: any) => obj.bin === equipmentSubType);
    const equipmentSizesList = size(filterEquipmentsByBin) > 0 ? filterEquipmentsByBin[0].equipmentSizes : [];
    const isNoteIconVisible = hasVendorNotes || size(serviceRecommendation) || displayFees;
    return (
      <Fragment>
        {showMobileDetails ? (
          <ServiceChangeRequestMobileListRowDetailsView
            {...(this.props as any)}
            onAcceptOpportunityModal={this.openAcceptOpportunityModal}
            onDeclineServiceChangeModal={this.openDeclineServiceChangeModal}
            onRequestServiceChangesModal={() => this.openRequestServiceChangesModal(null, 'RequestChanges')}
            onOpenNotesModal={() => this.openNotesModal(false)}
          />
        ) : (
          <TableRow key={caseId}>
            <TableCell vertical width={'14%'} padding="defaultCellVertical">
              <DetailsContainer padding="no" noShadow>
                <DetailsListBodySection>
                  {workOrder && (
                    <Text block size="large" weight="medium" singleLine>
                      {workOrder}
                      {hasWorkOrderNotes && (
                        <IconButton
                          type="submit"
                          size="xSmall"
                          color="secondary"
                          margin="no"
                          onClick={() => this.openNotesModal(true)}
                        >
                          <IconButtonIcon
                            icon="page-content"
                            color="secondary"
                            size="xLarge"
                            margin="xxSmall no no xxSmall"
                          />
                        </IconButton>
                      )}
                    </Text>
                  )}
                  <Fragment>
                    {equipmentChanged && (
                      <Text block size="small" margin="no" weight="light">
                        {CHANGE_REQUESTS_TYPE[EC].name}
                      </Text>
                    )}
                    {frequencyChanged && (
                      <Text block size="small" margin="no" weight="light">
                        {CHANGE_REQUESTS_TYPE[FC].name}
                      </Text>
                    )}
                    {materialChanged && (
                      <Text block size="small" margin="no" weight="light">
                        {CHANGE_REQUESTS_TYPE[MC].name}
                      </Text>
                    )}
                  </Fragment>
                </DetailsListBodySection>
              </DetailsContainer>
            </TableCell>

            <TableCell vertical width={'15%'}>
              <Fragment>
                <PopoverWrapper
                  triggerButton={
                    <Text block weight="light" size="small" margin="no" doubleLine>
                      {currentServiceLevelSummary.currentServiceLevel.equipmentType}
                    </Text>
                  }
                  popoverContent={
                    <ServiceDetailsPopover
                      serviceSummary={currentServiceLevelSummary.currentServiceLevel}
                      title={translate('opportunity.serviceChangeRequest.currentService')}
                    />
                  }
                  size="large"
                  margin="no"
                />
                <DetailsListBodySection>
                  <Text block weight="light" margin="no" size="small">
                    {currentServiceLevelSummary.currentServiceLevel.material}
                  </Text>
                  <Text block weight="light" size="small" margin="no" singleLine>
                    {currentServiceLevelSummary.currentServiceLevel.frequency}
                  </Text>
                </DetailsListBodySection>
              </Fragment>
              <Fragment>
                {size(currentServiceLevelSummary.displayFees) && (
                  <Text block weight="light" margin="no" size="small">
                    {this.getOverallServiceCharge(
                      currentServiceLevelSummary.currentServiceLevel.overallserviceCharge,
                      currentServiceLevelSummary.displayFees,
                    )}
                    <PopoverWrapper
                      triggerButton={<InfoIcon />}
                      popoverContent={
                        <PriceDetailsPopover
                          displayFees={currentServiceLevelSummary.displayFees}
                          overallCharges={currentServiceLevelSummary.overallserviceCharge}
                          title={translate('opportunity.serviceChangeRequest.currentPricing')}
                        />
                      }
                      size="large"
                      margin="no xSmall no"
                    />
                  </Text>
                )}
              </Fragment>
            </TableCell>
            <TableCell vertical width={'15%'}>
              <Fragment>
                <PopoverWrapper
                  triggerButton={
                    <Text block size="small" weight="light" margin="no" singleLine>
                      {equipmentType}
                    </Text>
                  }
                  popoverContent={
                    <ServiceDetailsPopover
                      serviceSummary={requestedServiceLevelSummary}
                      title={translate('opportunity.serviceChangeRequest.requestedService')}
                    />
                  }
                  size="large"
                  margin="no"
                />

                <Text block weight="light" margin="no" size="small" singleLine>
                  {material}
                </Text>
                <Text block weight="light" margin="nono" size="small" singleLine>
                  {frequency}
                </Text>
              </Fragment>
              <Fragment>
                <Text block weight="light" margin="no" size="small">
                  {this.getOverallServiceCharge(offer.overallserviceCharge, offer.displayFees)}
                  <PopoverWrapper
                    triggerButton={<InfoIcon />}
                    popoverContent={
                      <PriceDetailsPopover
                        displayFees={offer.displayFees}
                        overallCharges={offer.overallserviceCharge}
                        title={translate('opportunity.serviceChangeRequest.requestedPricing')}
                      />
                    }
                    size="large"
                    margin="no xSmall no"
                  />
                </Text>
              </Fragment>
            </TableCell>

            <TableCell width={'23%'} vertical>
              <Text block weight="medium" margin="no no xxSmall">
                {customerName}
              </Text>
              <Text weight="light" size="small">
                {siteName}
              </Text>
              {customerAddress && (
                <Text block margin="no" weight="light" size="small">
                  {` ${customerAddress}`}
                </Text>
              )}
            </TableCell>

            <TableCell vertical width={'15%'} padding="defaultCellVertical">
              <DetailsContainer padding="no" noShadow>
                <DetailsListBodySection>
                  <Fragment>
                    <Text block weight="medium" margin="no">
                      {CHANGE_SERVICE_STATUS[portalStatus].name}
                    </Text>
                  </Fragment>
                  <Fragment>
                    <Text weight="normal" margin="no no xxSmall" singleLine size="small">
                      {offer ? offer.deliveryDate : ''}
                      {isActionRequired && (
                        <IconButtonIcon icon="bell" color="alert" size="medium" margin="no no no xxSmall" />
                      )}
                    </Text>

                    {portalStatus === REQUESTED && (
                      <Text block weight="normal" margin="no no xxSmall" singleLine size="small" color="alert">
                        {daysLeft}
                      </Text>
                    )}
                  </Fragment>
                </DetailsListBodySection>
              </DetailsContainer>
            </TableCell>

            <TableCell width={'12%'}>
              {canAccept && (
                <TableActionButton margin="small small no no" onClick={this.openAcceptOpportunityModal}>
                  <ActionButtonTooltip customViewBox="0 0 25 25" size="mLarge" icon="checkCircle" tooltip="accept" />
                </TableActionButton>
              )}

              {canDecline && (
                <TableActionButton margin="xSmall small no no" onClick={this.openDeclineServiceChangeModal}>
                  <ActionButtonTooltip customViewBox="0 0 25 25" size="mLarge" icon="closedIssue" tooltip="decline" />
                </TableActionButton>
              )}

              {canRequestDateChange && (
                <TableActionButton
                  margin="xSmall sMedium no no"
                  onClick={(event: any) => this.openRequestServiceChangesModal(event, 'RequestChanges')}
                >
                  <ActionButtonTooltip size="mMedium" icon="changeRequest" color="primary" tooltip="requestChange" />
                </TableActionButton>
              )}
            </TableCell>
            <TableCell width={'6%'}>
              {isNoteIconVisible && (
                <TableActionButton margin="sMedium small no no" onClick={() => this.openNotesModal(false)}>
                  <ActionButtonTooltip
                    customViewBox="0 0 26 26"
                    size="mLarge"
                    icon="page-content"
                    color="primary"
                    tooltip="viewNote"
                  />
                </TableActionButton>
              )}
            </TableCell>
          </TableRow>
        )}

        {isRequestChangesForServiceModalOpen && (
          <RequestServiceChangesModal
            {...this.props}
            onCancel={this.closeRequestServiceChangesModal}
            responseValue={response}
          />
        )}
        {isNoteModalOpen && (
          <ChangeRequestNotesModalResolver
            closeModal={this.closeWorkOrderNotesModal}
            equipmentSizes={equipmentSizesList}
            workOrder={workOrder}
            serviceDate={offer.deliveryDate}
            requestedChanges={requestedChanges}
          />
        )}
        {isAcceptServiceChangeModalOpen && (
          <AcceptServiceChangeRequestModal {...this.props} onCancel={this.closeAcceptOpportunityModal} />
        )}
        {isDeclineServiceChangeModalOpen && (
          <DeclineServiceChangeRequestModal {...this.props} onCancel={this.closeDeclineServiceChangeModal} />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  equipmentSizes: state.opportunity.serviceOptions.equipmentSizes,
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor) as any,
});

const mapDispatchToProps = {
  reset,
  showNotesModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceChangeRequestsTableRow));
