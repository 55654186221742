import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { change } from 'redux-form';

import { AppState } from '../../../store';
import { AVAILABLE } from 'src/fleet/constants/status';
import { checkSupervisorsHaveRoutesInRange } from 'src/fleet/ducks/driver';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { Dictionary } from 'src/common/interfaces/Dictionary';
import { DRIVER_SUPERVISOR, DRIVER, SUPERVISOR } from '../../constants/resourceRoleTypes';
import { DriverEditorForm } from '../forms';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { Facility } from 'src/common/interfaces/Facility';
import { FileOpenDialogToggle } from '../../../core/components';
import { getIsFacilityActive } from '../utils/vehiclesPageHooks';
import { Grid, GridColumn, PanelSection, Panel } from '../../../core/components/styled';
import {
  PageContent,
  PageHeader,
  PageDetails,
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageTitleContainer,
  PageTitle,
  ProfileImage,
} from '../../../common/components/styled';
import { supervisorExperienceFeatureIsEnabled } from '../../../vendors/ducks/features';
import { TODAY } from 'src/core/constants';
import { uploadProfileImage, saveDriver, resetDriver, profileImageUrlSelector } from '../../ducks';
import confirm from '../../../core/services/confirm';
import history from 'src/core/services/history';
import readFileAsDataUrl from '../../../utils/services/readFileAsDataUrl';
import translate from '../../../core/services/translate';

interface RouteParams {
  driverId: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  change: any;
  checkSupervisorsHaveRoutesInRange: DuckFunction<typeof checkSupervisorsHaveRoutesInRange>;
  driverFacilities: Facility[];
  isCheckingSupervisorsHaveRoutesInRange: boolean;
  isSaving: boolean;
  isUploadingProfileImage: boolean;
  lastLocations: Dictionary<string>;
  profileImageUrl?: string;
  push: any;
  resetDriver: DuckAction<typeof resetDriver>;
  saveDriver: DuckFunction<typeof saveDriver>;
  supervisorFeatureEnabled: boolean;
  uploadProfileImage: DuckFunction<typeof uploadProfileImage>;
  vendorId: number;
}

interface State {
  profileImageUrl?: string | null;
}

class DriverEditorPage extends PureComponent<Props, State> {
  readonly state: State = {};

  componentWillUnmount() {
    this.props.resetDriver();
  }

  onProfileImageSelected = (files: FileList | null) => {
    const { uploadProfileImage, change } = this.props;

    if (!files) {
      return;
    }

    uploadProfileImage(files[0])
      .then(async profileImageId => {
        const profileImageUrl = await readFileAsDataUrl(files[0]);
        this.setState({ profileImageUrl });
        change('driverEditorForm', 'profileImageId', profileImageId);
        createSuccessNotification(translate('common.alertMessages.uploadCompleted'));
      })
      .catch(() => {
        createErrorNotification(translate('common.alertMessages.invalidImageFormat'));
      });
  };

  onSubmit = async (rawFormData: any) => {
    const { driverFacilities, lastLocations, push, saveDriver, supervisorFeatureEnabled } = this.props;

    const isAvailable = rawFormData.isAvailable === AVAILABLE;
    const isFacilityActive = getIsFacilityActive(driverFacilities, rawFormData);

    if (!isFacilityActive) {
      if (!(await confirm(translate('resources.alertMessages.saveDriverWithInactiveFacility')))) {
        return;
      }
    }

    // TODO: uncomment after BE is done (see RVP-5369)

    // const { checkSupervisorsHaveRoutesInRange, vendorId } = this.props;};
    // const currentDriver = isAvailable
    //   ? []
    //   : await checkSupervisorsHaveRoutesInRange(
    //       vendorId,
    //       [rawFormData.id],
    //       rawFormData.driverUnavailableStartDate || TODAY,
    //       rawFormData.driverUnavailableEndDate,
    //     );

    // if (currentDriver[0]?.hasRoutes)
    //   if (
    //     !(await confirm(
    //       translate('resources.alertMessages.resourceHasRoutesInRange', {
    //         selectedResource: `${rawFormData.firstName} ${rawFormData.lastName}`,
    //       }),
    //       '',
    //       translate('common.cancel'),
    //       translate('common.continue'),
    //     ))
    //   )
    //     return;

    const driverUnavailable =
      rawFormData.id && rawFormData.driverUnavailableInitialValues?.id
        ? {
            id: rawFormData.driverUnavailableInitialValues?.id,
            startDate: rawFormData.driverUnavailableStartDate || TODAY,
            endDate: rawFormData.driverUnavailableEndDate,
            isDeleted: isAvailable && !rawFormData.driverUnavailableStartDate && !rawFormData.driverUnavailableEndDate,
          }
        : (rawFormData.id && !rawFormData.driverUnavailableInitialValues?.id && isAvailable) ||
          (!rawFormData.id && isAvailable)
        ? null
        : {
            startDate: rawFormData.driverUnavailableStartDate || TODAY,
            endDate: rawFormData.driverUnavailableEndDate,
            isDeleted: isAvailable && !rawFormData.driverUnavailableStartDate && !rawFormData.driverUnavailableEndDate,
          };

    const formData = { ...rawFormData, isAvailable, driverUnavailable };
    const { isDriver, isSupervisor } = formData;

    delete formData.isDriver;
    delete formData.isSupervisor;
    delete formData.driverUnavailableInitialValues;
    delete formData.driverUnavailableStartDate;
    delete formData.driverUnavailableEndDate;

    if (supervisorFeatureEnabled) {
      if (isDriver && isSupervisor) {
        formData.resourceRoleType.id = DRIVER_SUPERVISOR;
      } else if (isDriver) {
        formData.resourceRoleType.id = DRIVER;
      } else {
        formData.resourceRoleType.id = SUPERVISOR;
      }
    } else {
      formData.resourceRoleType.id = DRIVER;
    }

    await saveDriver(formData);

    createSuccessNotification(`${translate('resources.alertMessages.resourceSaved')}`);

    const hasLastLocations = Object.keys(lastLocations).length > 1;
    hasLastLocations ? history.goBack() : push('/fleet/resources');
  };

  render() {
    const {
      isCheckingSupervisorsHaveRoutesInRange,
      isSaving,
      isUploadingProfileImage,
      match: {
        params: { driverId },
      },
      profileImageUrl,
    } = this.props;
    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButtonAction id="back-button" onClick={() => history.goBack()}>
                <PageBackButtonIcon />
              </PageBackButtonAction>

              <PageTitle>{translate(`resources.${driverId ? 'edit' : 'create'}Resource`)}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions />
        </PageHeader>

        <Grid>
          <GridColumn size="3/12">
            <Panel>
              <PanelSection vertical centered padding="large" isLoading={isUploadingProfileImage}>
                <ProfileImage src={this.state.profileImageUrl || profileImageUrl} margin="no no small" />
                <FileOpenDialogToggle
                  icon="image"
                  label={translate('common.uploadPicture')}
                  accept=".jpg,.png"
                  onFilesSelected={this.onProfileImageSelected}
                />
              </PanelSection>
            </Panel>
          </GridColumn>

          <GridColumn size="9/12">
            <Panel>
              <PanelSection centered padding="small" isLoading={isSaving || isCheckingSupervisorsHaveRoutesInRange}>
                <DriverEditorForm onSubmit={this.onSubmit} />
              </PanelSection>
            </Panel>
          </GridColumn>
        </Grid>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  driverFacilities: state.fleet.driver.driverFacilities,
  isCheckingSupervisorsHaveRoutesInRange: state.fleet.driver.isCheckingSupervisorsHaveRoutesInRange,
  isSaving: state.fleet.driver.isSaving,
  isUploadingProfileImage: state.fleet.driver.isUploadingProfileImage,
  lastLocations: state.core.lastLocations,
  profileImageUrl: profileImageUrlSelector(state.fleet.driver),
  supervisorFeatureEnabled: supervisorExperienceFeatureIsEnabled(state),
});

const mapDispatchToProps = {
  change,
  checkSupervisorsHaveRoutesInRange,
  push,
  resetDriver,
  saveDriver,
  uploadProfileImage,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DriverEditorPage));
