import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const CONTAINER_NOT_OUT = 1;
export const OVERFLOWING = 2;
export const CONTAINER_CONTAMINATED = 3;
export const BULK_ITEM = 4;
export const CONTAINER_DAMAGED = 5;
export const OTHER = 6;

export const PICKUP_ISSUE_REASON_TYPES = mapKeys(
  [
    { id: CONTAINER_NOT_OUT, name: translate('vendors.pickupIssueReasonTypes.containerNotOut') },
    { id: OVERFLOWING, name: translate('vendors.pickupIssueReasonTypes.overflowing') },
    { id: CONTAINER_CONTAMINATED, name: translate('vendors.pickupIssueReasonTypes.containerContaminated') },
    { id: BULK_ITEM, name: translate('vendors.pickupIssueReasonTypes.bulkItem') },
    { id: CONTAINER_DAMAGED, name: translate('vendors.pickupIssueReasonTypes.other') },
  ],
  'id',
);
