import { PureComponent } from 'react';

import { change } from 'redux-form';

import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { AssignJobsDivContainer } from '../../../../styled';
import {
  assignJobsToRouteOrRouteTemplate,
  clearSelectedJobs,
  setDuplicateJobs,
} from '../../../../../ducks/dispatchBoard';
import { Button, Popover, Text } from '../../../../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../../../../../vendors/services/currentVendorSelector';
import { DuckAction, DuckFunction } from '../../../../../../contracts/ducks';
import { extractRouteId, isRouteTemplate } from '../../../../../services/routeTemplateHelper';
import { FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';
import { getAllFiltersPreferencesIds } from 'src/common/utils/filters';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import {
  loadRouteHasStopsInPendingOptimization,
  loadRouteTemplatesHaveStopsInPendingOptimization,
} from 'src/routes/ducks/dispatchBoard/dispatchBoardRouteJob';
import { loadRoutesAndTemplates } from '../../../../../services/dispatchBoardRoutes';
import { PopoverWrapper } from '../../../../../../core/components';
import { SELECTED_TRANSFERRED_STOPS } from '../../../../../../routes/constants';
import AssignJobsModal from './AssignJobsModal';
import AssignJobsModalForm from './AssignJobsModalForm';
import confirm from 'src/core/services/confirm';
import ProgressPopup from '../../../../../../common/components/ProgressPopup';
import translate from '../../../../../../core/services/translate';
import { UNASSIGNED_ROUTE_ID } from 'src/routes/constants/dispatchBoard';

const assignJobsTitle = (hasSelectedJobs: boolean, selectedJobsCount: number) => {
  const title = translate('dispatchBoard.assignJobs');
  return hasSelectedJobs ? `${title} (${selectedJobsCount})` : title;
};

interface Props {
  assignJobsToRouteOrRouteTemplate: DuckFunction<typeof assignJobsToRouteOrRouteTemplate>;
  batchTransfererInProgress: boolean;
  batchTransfererProgress: number;
  change: any;
  clearSelectedJobs: DuckAction<typeof clearSelectedJobs>;
  filtersPreferencesIds: FiltersPreferencesIds;
  hasDuplicateJobs: boolean;
  initialDate: Date | string;
  loadJobsForSelectedRoutes: () => void;
  loadRouteHasStopsInPendingOptimization: DuckFunction<typeof loadRouteHasStopsInPendingOptimization>;
  loadRouteTemplatesHaveStopsInPendingOptimization: DuckFunction<
    typeof loadRouteTemplatesHaveStopsInPendingOptimization
  >;
  onSuccessClose?: () => void;
  selectedJobs: any[];
  selectedJobsCount: number;
  setDuplicateJobs: DuckAction<typeof setDuplicateJobs>;
  vehicleTypeId: number;
  vendorId: number;
}

interface State {
  isModalOpen: boolean;
  duplicateRouteId?: number;
}

class AssignJobsContainer extends PureComponent<Props, State> {
  state = {
    isModalOpen: false,
    duplicateRouteId: undefined,
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
    this.props.setDuplicateJobs(false);
  };

  closeModal = () => this.setState({ isModalOpen: false });

  loadRoutesForDate = (date?: Date | string) => {
    const { filtersPreferencesIds, vendorId, vehicleTypeId } = this.props;
    return loadRoutesAndTemplates(vendorId, vehicleTypeId, date, filtersPreferencesIds);
  };

  assignJobs = async (assignment: any) => {
    const {
      assignJobsToRouteOrRouteTemplate,
      change,
      clearSelectedJobs,
      hasDuplicateJobs,
      loadJobsForSelectedRoutes,
      loadRouteHasStopsInPendingOptimization,
      loadRouteTemplatesHaveStopsInPendingOptimization,
      selectedJobs,
      vendorId,
    } = this.props;

    const shouldCheckForDuplicates = !hasDuplicateJobs;
    if (!shouldCheckForDuplicates) this.closeModal();

    const routeId = extractRouteId(assignment.route);
    this.setState({ duplicateRouteId: routeId });

    const isTemplate = isRouteTemplate(assignment.route);
    const date = assignment.date;
    const jobs = selectedJobs.map(({ id, routeId, serviceContractId, isOnHoldJob }) => ({
      id,
      isUnassigned: !routeId || routeId === UNASSIGNED_ROUTE_ID,
      isOnHoldJob,
      serviceContractId,
    }));
    const position = assignment.route.numberOfJobs;
    const positionTypeId = assignment.positionTypeId;

    let hasStopsInPendingOptimization = false;

    if (!isTemplate) hasStopsInPendingOptimization = await loadRouteHasStopsInPendingOptimization(routeId);
    else {
      const templateResponse = await loadRouteTemplatesHaveStopsInPendingOptimization(routeId.toString());
      if (templateResponse && templateResponse.length > 0)
        hasStopsInPendingOptimization = templateResponse[0].hasStopsInPendingOptimization;
    }

    if (hasStopsInPendingOptimization)
      if (
        !(await confirm(
          translate('routes.alertMessages.routeHasStopsInOptimization'),
          '',
          translate('common.cancel'),
          translate('common.continue'),
        ))
      )
        return;

    try {
      await assignJobsToRouteOrRouteTemplate({
        jobs,
        routeId,
        position,
        isTemplate,
        vendorId,
        date,
        shouldCheckForDuplicates,
        positionTypeId,
      });
      if (!this.props.hasDuplicateJobs || !shouldCheckForDuplicates) {
        this.closeModal();
        await loadJobsForSelectedRoutes();
        const successMessage =
          jobs.length > 1
            ? translate('routes.jobsWereTransferred', { jobCount: jobs.length })
            : translate('routes.jobWasTransferred');
        createSuccessNotification(successMessage);
        clearSelectedJobs();
        change('searchJobsForm', 'searchJobs', '');
        if (this.props.onSuccessClose) {
          this.props.onSuccessClose();
        }
      }
    } catch ({ code }) {
      createErrorNotification(translate('routes.alertMessages.routeExists'));
    }
  };

  render() {
    const { selectedJobsCount, initialDate, batchTransfererInProgress, batchTransfererProgress, hasDuplicateJobs } =
      this.props;
    const { isModalOpen, duplicateRouteId } = this.state;

    const hasSelectedJobs = selectedJobsCount > 0;
    const title = assignJobsTitle(hasSelectedJobs, selectedJobsCount);
    const canAssignJobs = hasSelectedJobs && selectedJobsCount <= SELECTED_TRANSFERRED_STOPS;

    return (
      <AssignJobsDivContainer>
        <PopoverWrapper
          triggerButton={
            <Button id="assign-jobs-button" line color="primary" onClick={this.openModal} disabled={!canAssignJobs}>
              {title}
            </Button>
          }
          popoverContent={
            !canAssignJobs &&
            selectedJobsCount > SELECTED_TRANSFERRED_STOPS && (
              <Popover>
                <Text block weight="medium" margin="xxSmall no xxSmall">
                  {translate('routes.alertMessages.routeLocationsMoreThan', { SELECTED_TRANSFERRED_STOPS })}
                </Text>
              </Popover>
            )
          }
          size="large"
        />
        {isModalOpen && (
          <AssignJobsModal title={title} closeModal={this.closeModal}>
            <AssignJobsModalForm
              initialDate={initialDate}
              loadRoutes={this.loadRoutesForDate}
              onSubmit={this.assignJobs}
              hasDuplicateJobs={hasDuplicateJobs}
              duplicateRouteId={duplicateRouteId}
              onCancel={this.closeModal}
            />
          </AssignJobsModal>
        )}
        <ProgressPopup
          isVisible={batchTransfererInProgress}
          message={translate('dispatchBoard.batchTransfer')}
          progress={batchTransfererProgress}
        />
      </AssignJobsDivContainer>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const {
    routes: { dispatchBoard },
  } = state;
  const vendorId = currentVendorIdSelector(state.account.login, state.vendors.defaultVendor);

  return {
    initialDate: dispatchBoard.sourceRoutes.selectedDate,
    vendorId,
    selectedJobs: dispatchBoard.map.selectedJobs.filter(
      selectedJob => selectedJob.orderNo !== JOB_PENDING_OPTIMIZATION_ID,
    ),
    selectedJobsCount: dispatchBoard.map.selectedJobs.filter(
      selectedJob => selectedJob.orderNo !== JOB_PENDING_OPTIMIZATION_ID,
    ).length,
    batchTransfererInProgress: dispatchBoard.map.batchTransfererInProgress,
    batchTransfererProgress: dispatchBoard.map.batchTransfererProgress,
    hasDuplicateJobs: dispatchBoard.map.hasDuplicateJobs,
    filtersPreferencesIds: getAllFiltersPreferencesIds(state.common.filters.filters),
  };
};

const mapDispatchToProps = {
  assignJobsToRouteOrRouteTemplate,
  change,
  clearSelectedJobs,
  loadRouteHasStopsInPendingOptimization,
  loadRouteTemplatesHaveStopsInPendingOptimization,
  setDuplicateJobs,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignJobsContainer);
