import mapboxgl from 'mapbox-gl';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PermissionGuard } from 'src/account/components';
import { GEO_FENCES_DAILY_ROUTE, GEO_FENCES_ROUTE_TEMPLATE } from 'src/account/constants';
import { MapGL } from 'src/common/components/map/MapGL';
import MapGLWrapper from 'src/common/components/map/MapGLWrapper';
import { getMapBounds } from 'src/common/components/map/util';
import useGeoFenceControllerMapbox from 'src/common/hooks/geoFenceControllerMapbox';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import { IconButtonIcon } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { DELIVERY_UTILITY_ID } from 'src/fleet/constants';
import { ContainerInsights, VehiclePosition } from 'src/routes/components/mapWithTimeline/Interfaces';
import RouteGeoFenceAlerts from 'src/routes/components/pages/geoFences/components/RouteGeoFenceAlerts';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { clearRouteMapSelectedFeature, setRouteMapSelectedFeature, setRouteMapViewport } from 'src/routes/ducks';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { RouteMapVehicleTracking } from 'src/routes/interfaces/RouteMapVehicleData';
import { RouteMapVehicleInsight } from 'src/routes/interfaces/RouteMapVehicleInsights';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { checkIfGeoFenceIsEnabled } from 'src/vendors/ducks/features';
import RouteMapTimeLineVehiclePositions from './vehiclePositionsTimeline/RouteMapTimelineVehiclePositions';
import RouteMapCityInsightsGL from './cityInsights/RouteMapCityInsightsGL';
import RouteMapClustersGL from './cluster/RouteMapClustersGL';
import { ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_LAYER, ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_SOURCE } from './constants';
import RouteMapGeoFencesGL from './geoFences/RouteMapGeoFencesGL';
import RouteMapHaulerLocationsGL from './haulerLocations/RouteMapHaulerLocationsGL';
import RouteMapRouteStopsGL from './routeStops/RouteMapRouteStopsGL';
import RouteMapVehicleInsightsGL from './vehicleInsights/RouteMapVehicleInsightsGL';
import RouteTimelineMapVehicleTrackingsGL from './vehicleTrackingTimeline/RouteTimelineMapVehicleTrackingsGL';
import RouteMapTimelineApplicationStatusGL from './applicationStatusTimeline/RouteMapTimelineApplicationStatusGL';

type Props = {
  alertsAreReadOnly: boolean;
  containerInsights: ContainerInsights[];
  vehicleInsights: RouteMapVehicleInsight[];
  vehiclePosition: VehiclePosition;
  vehicleTrackings: RouteMapVehicleTracking[];
};

export default function RouteMapTimelineGL({
  alertsAreReadOnly,
  containerInsights,
  vehicleInsights,
  vehiclePosition,
  vehicleTrackings,
}: Props) {
  const dispatch = useDispatch();
  const [map, setMap] = useState<mapboxgl.Map>();
  const [mapViewStyle, setMapViewStyle] = useState<any>({
    isSatelliteEnabled: false,
    mapCenter: null,
    mapZoom: null,
  });
  const [geoFenceIsVisible, setGeoFenceIsVisible] = useState<boolean>(false);

  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { viewport } = useSelector(state => state.routes.mapControls);
  const { cityInsights } = useSelector(state => state.routes.routeMapCityInsights);
  const { routeStopsForMap } = useSelector(state => state.routes.routeStops);
  const { geoFence } = useSelector(state => state.routes.geoFence);
  const { route } = useSelector(state => state.routes.route);
  const isGeoFenceEnabled = useSelector(checkIfGeoFenceIsEnabled);

  const didMountRef = useRef(false);

  let geoFenceExists = false;
  let geoFenceId;
  if (geoFence && geoFence.id !== 0) {
    if (route?.id) {
      geoFenceExists = true;
      geoFenceId = geoFence.id;
    }
  }

  const { getAllCoords, allGeoFencesGeoJson, mapGeoFences, addGeoFence, checkIfGeoFenceExists } =
    useGeoFenceControllerMapbox();

  const dragPan = useSelector(s => s.routes.routeMapSettings.dragPan);

  const filteredVehiclePositions = useMemo(() => {
    const vehiclePositionFormatted = vehiclePosition.vehicleId
      ? {
          ...vehiclePosition,
          id: vehiclePosition.vehicle.id,
          latitude: vehiclePosition.coordinates.latitude,
          longitude: vehiclePosition.coordinates.longitude,
        }
      : undefined;

    return vehiclePositionFormatted ? [vehiclePositionFormatted] : [];
  }, [vehiclePosition]);

  const filteredVehicleTrackings = useMemo(() => vehicleTrackings, [vehicleTrackings]);

  const filteredVehicleInsights = useMemo(() => vehicleInsights, [vehicleInsights]);

  const filteredCityInsights = useMemo(() => cityInsights, [cityInsights]);

  const filteredStops = useMemo(() => {
    const containerInsightsFormatted = containerInsights.map((containerInsight: ContainerInsights) => {
      const currentRouteStop = routeStopsForMap.filter(
        (routeStop: RouteStop) => routeStop.id === containerInsight.routeLocationId,
      );

      return {
        ...containerInsight,
        ...currentRouteStop[0],
      };
    });

    return containerInsightsFormatted;
  }, [containerInsights, routeStopsForMap]);

  /************************************************
   * GEO-FENCES Management
   ************************************************/

  // adding geoFences to controller
  useEffect(() => {
    if (geoFence && !!geoFence?.geoFenceCoordinates.length && !checkIfGeoFenceExists(geoFence.id)) {
      addGeoFence(geoFence);
    }
  }, [addGeoFence, checkIfGeoFenceExists, geoFence]);

  /************************************************
   * End of GEO-FENCES Management
   * *********************************************/

  // view port
  const handleSetRouteMapViewport = useCallback(() => {
    // set to true if not the 1st render
    if (!didMountRef.current) {
      didMountRef.current = true;

      // set route map viewport on 1st render only
      const points: { latitude: number; longitude: number }[] = [];

      filteredVehiclePositions.forEach(({ latitude, longitude }) => {
        points.push({ latitude, longitude });
      });

      filteredVehicleTrackings.forEach(vehicleTracking => {
        vehicleTracking.coordinateGroups?.forEach(coordinateGroup => {
          coordinateGroup.coordinates.forEach(({ latitude, longitude }) => {
            points.push({ latitude, longitude });
          });
        });
      });

      filteredVehicleInsights.forEach(({ latitude, longitude }) => {
        points.push({ latitude, longitude });
      });

      filteredStops.forEach(({ binLatitude, latitude, binLongitude, longitude }) => {
        points.push({ latitude: binLatitude || latitude, longitude: binLongitude || longitude });
      });

      filteredCityInsights.forEach(({ latitude, longitude }) => {
        points.push({ latitude, longitude });
      });

      if (mapGeoFences.length > 0) {
        points.push(...getAllCoords);
      }

      if (mapViewStyle.mapCenter && mapViewStyle.mapZoom) {
        const bounds = getMapBounds([{ latitude: mapViewStyle.mapCenter.lat, longitude: mapViewStyle.mapCenter.lng }], {
          capZoom: mapViewStyle.mapZoom,
        });
        dispatch(setRouteMapViewport(bounds));
      } else if (!!points.length) {
        const bounds = getMapBounds(points, {
          capZoom: 16,
        });
        dispatch(setRouteMapViewport(bounds));
      }
    }
  }, [
    dispatch,
    filteredCityInsights,
    filteredStops,
    filteredVehicleInsights,
    filteredVehiclePositions,
    filteredVehicleTrackings,
    getAllCoords,
    mapGeoFences.length,
    mapViewStyle.mapCenter,
    mapViewStyle.mapZoom,
  ]);

  useEffect(() => {
    map?.once('render', () => {
      handleSetRouteMapViewport();
    });

    map?.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_SOURCE &&
              feature.properties?.layer === ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_LAYER,
          );
        const features = map.queryRenderedFeatures(event.point).filter(feature => !!feature.properties?.cluster_id);

        if (feature) {
          dispatch(setRouteMapSelectedFeature(RouteMapFeature.vehiclePositions, feature.id as number));
        }
        if (features.length) {
          dispatch(clearRouteMapSelectedFeature());
        }
      });

      map.on('mousemove', event => {
        const features = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.properties?.clickable === true || !!feature.properties?.cluster_id);
        map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
      map.once('mouseleave', () => {
        map.getCanvas().style.cursor = '';
      });

      map.once('idle', () => {
        if (map.getLayer(ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_LAYER)) {
          map.moveLayer(ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_LAYER);
        }
      });
    });
  }, [map, dispatch, handleSetRouteMapViewport]);

  const handleMapViewStyleChange = (enabled: boolean) => {
    setMapViewStyle({
      isSatelliteEnabled: enabled,
      mapCenter: map?.getCenter(),
      mapZoom: map?.getZoom(),
    });
  };

  return (
    <>
      <MapGLWrapper>
        <MapGL
          dragPan={dragPan}
          disableDefaultSatelliteView
          enableNewSatelliteView
          disableDefaultNavigationControl
          enableNewNavigationControl
          viewport={viewport}
          onMapRefLoaded={setMap}
          setIsSatelliteViewEnabled={handleMapViewStyleChange}
        >
          {isGeoFenceEnabled && (
            <ComplexMapControl vertical position="top-right">
              <TooltipIconButton
                disabled={!geoFenceExists}
                color={geoFenceExists && geoFenceIsVisible ? 'primary' : 'secondary'}
                tooltipAsString
                tooltip={
                  geoFenceExists
                    ? geoFenceIsVisible
                      ? translate('routes.geoFences.hideGeoFence')
                      : translate('routes.geoFences.showGeoFence')
                    : translate('routes.geoFences.noGeoFence')
                }
                tooltipPosition="left"
                margin="no"
                onClick={() => setGeoFenceIsVisible(!geoFenceIsVisible)}
              >
                <IconButtonIcon icon="geoFence" size="large" />
              </TooltipIconButton>

              <PermissionGuard permission={GEO_FENCES_DAILY_ROUTE || GEO_FENCES_ROUTE_TEMPLATE}>
                <RouteGeoFenceAlerts geoFenceId={geoFenceId} alertsAreReadOnly={alertsAreReadOnly} />
              </PermissionGuard>
            </ComplexMapControl>
          )}

          {map && (
            <>
              <RouteMapClustersGL
                map={map}
                routeStops={filteredStops}
                vehiclePositions={filteredVehiclePositions}
                vehicleInsights={filteredVehicleInsights}
                cityInsights={filteredCityInsights}
                timelineVehicleTrackings={filteredVehicleTrackings}
                isTimelineMap
              />
              <RouteMapTimelineApplicationStatusGL map={map} />

              <RouteMapTimeLineVehiclePositions map={map} currentVehiclePositions={filteredVehiclePositions} />

              <RouteMapRouteStopsGL
                map={map}
                zoomLevel={routeSummary?.vehicleTypeId === DELIVERY_UTILITY_ID ? 10 : 15}
                isPlaybackMode
              />
              <RouteMapVehicleInsightsGL map={map} />
              <RouteMapCityInsightsGL map={map} />
              <RouteMapHaulerLocationsGL map={map} />
              <RouteTimelineMapVehicleTrackingsGL
                map={map}
                vehicleTrackings={filteredVehicleTrackings}
                isPlaybackMode
              />
              <RouteMapGeoFencesGL
                map={map}
                geoFencesGeoJSON={geoFenceIsVisible && isGeoFenceEnabled ? allGeoFencesGeoJson : []}
                isSatellite={mapViewStyle.isSatelliteEnabled}
              />
            </>
          )}
        </MapGL>
      </MapGLWrapper>
    </>
  );
}
