import React, { DragEvent, PureComponent, ReactNode } from 'react';

import { DispatchBoardInfiniteWrapper } from '../../../styled';
import { dispatchBoardJobDropAction } from '../../../../services/dispatchBoardJobDropAction';
import { DispatchBoardRouteJob } from '../../../../interfaces/DispatchBoardRouteJob';

interface Props {
  children: ReactNode;
  insertAtIndex: number;
  maxHeight?: number;
  onJobDrop: (
    sourceRouteId: number,
    targetRouteId: number,
    jobsSelected: DispatchBoardRouteJob[],
    position?: number,
    type?: string,
  ) => void;
  routeId: number;
}

interface State {
  isDropTarget: boolean;
}

class DispatchBoardDroppableList extends PureComponent<Props, State> {
  state = {
    isDropTarget: false,
  };

  onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (this.isTargetingLastPosition(e)) {
      this.setState({ isDropTarget: true });
    }
  };

  onDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    this.setState({ isDropTarget: false });
  };

  onDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    this.setState({ isDropTarget: false });
    if (this.isTargetingLastPosition(e)) {
      const { routeId, onJobDrop, insertAtIndex } = this.props;
      dispatchBoardJobDropAction(e, routeId, insertAtIndex, onJobDrop);
    }
  };

  id = `rvp-droppableList-${Math.random().toString()}`;

  isTargetingLastPosition = (dragEvent: DragEvent) => dragEvent && (dragEvent.target as any).id === this.id;

  render() {
    return (
      <DispatchBoardInfiniteWrapper
        id={this.id}
        maxHeight={this.props.maxHeight}
        isDropTarget={this.state.isDropTarget}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
      >
        {this.props.children}
      </DispatchBoardInfiniteWrapper>
    );
  }
}

export default DispatchBoardDroppableList;
