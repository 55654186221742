import { mapKeys } from 'lodash-es';

import translate from 'src/core/services/translate';

const DEBRIS_ID = 5;

const TON_ID = 1;
const POUND_ID = 2;
const LOAD_ID = 8;
const CUBIC_YARDS_ID = 11;

export const LOADS_DUMPED_MATERIALS = mapKeys(
  [{ id: DEBRIS_ID, name: translate('common.loadsDumped.materialTypeValues.debris') }],
  'id',
);

export const LOADS_DUMPED_UOM = mapKeys(
  [
    {
      id: TON_ID,
      name: translate('common.loadsDumped.unitOfMeasureValues.ton'),
    },
    {
      id: POUND_ID,
      name: translate('common.loadsDumped.unitOfMeasureValues.pound'),
    },
    {
      id: LOAD_ID,
      name: translate('common.loadsDumped.unitOfMeasureValues.load'),
    },
    {
      id: CUBIC_YARDS_ID,
      name: translate('common.loadsDumped.unitOfMeasureValues.cubicYards'),
    },
  ],
  'id',
);
