import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  RouteHistoryDriverVehicle,
  RouteHistoryItem,
  RouteVehiclePosition,
  RouteVehiclesBreadCrumbs,
} from '../interfaces/routesData';
import {
  loadRouteVehiclePositions as doLoadRouteVehiclePositions,
  loadRouteVehiclesBreadCrumbs as doLoadRouteVehiclesBreadCrumbs,
} from '../services/routesData';
import { loadRouteSegmentsForDashboard as doLoadRouteSegments } from 'src/routes/services/routeSegments';
import { RouteSegment } from 'src/routes/interfaces/RouteSegment';

//Action

const START_LOAD_ROUTE_HISTORY_VEHICLE_POSITION =
  'dashboard/routeHistoryData/START_LOAD_ROUTE_HISTORY_VEHICLE_POSITION';
const COMPLETE_LOAD_ROUTE_HISTORY_VEHICLE_POSITION =
  'dashboard/routeHistoryData/COMPLETE_LOAD_ROUTE_HISTORY_VEHICLE_POSITION';
const FAIL_LOAD_ROUTE_HISTORY_VEHICLE_POSITION = 'dashboard/routeHistoryData/FAIL_LOAD_ROUTE_HISTORY_VEHICLE_POSITION';
const START_LOAD_ROUTE_HISTORY_VEHICLE_BREADCRUMB =
  'dashboard/routeHistoryData/START_LOAD_ROUTE_HISTORY_VEHICLE_BREADCRUMB';
const COMPLETE_LOAD_ROUTE_HISTORY_VEHICLE_BREADCRUMB =
  'dashboard/routeHistoryData/COMPLETE_LOAD_ROUTE_HISTORY_VEHICLE_BREADCRUMB';
const FAIL_LOAD_ROUTE_HISTORY_VEHICLE_BREADCRUMB =
  'dashboard/routeHistoryData/FAIL_LOAD_ROUTE_HISTORY_VEHICLE_BREADCRUMB';
const START_LOAD_ROUTE_HISTORY_SEGMENTS = 'dashboard/routeHistoryData/START_LOAD_ROUTE_HISTORY_SEGMENTS';
const COMPLETE_LOAD_ROUTE_HISTORY_SEGMENTS = 'dashboard/routeHistoryData/COMPLETE_LOAD_ROUTE_HISTORY_SEGMENTS';
const FAIL_LOAD_ROUTE_HISTORY_SEGMENTS = 'dashboard/routeHistoryData/FAIL_LOAD_ROUTE_HISTORY_SEGMENTS';
const SET_ROUTE_HISTORY_MODAL_OPEN = 'dashboard/routeHistoryData/SET_ROUTE_HISTORY_MODAL_OPEN';
const RESET = 'dashboard/routeHistoryData/RESET';

// initial state
const initialState = {
  isModalOpen: false,
  routeHistoryItem: null as RouteHistoryItem | null,
  routeHistoryDriverVehicle: null as RouteHistoryDriverVehicle | null,
  routeVehiclePositions: [] as RouteVehiclePosition[],
  routeSegments: [] as RouteSegment[],
  routeVehiclesBreadCrumbs: null as RouteVehiclesBreadCrumbs | null,
  isLoadingRouteVehiclePositions: false,
  isLoadingRouteVehiclesBreadCrumbs: false,
  isLoadingRouteSegments: false,
};

// Reducer

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_ROUTE_HISTORY_VEHICLE_POSITION:
      return update(state, {
        isLoadingRouteVehiclePositions: { $set: true },
      });
    case COMPLETE_LOAD_ROUTE_HISTORY_VEHICLE_POSITION:
      return update(state, {
        isLoadingRouteVehiclePositions: { $set: false },
        routeVehiclePositions: { $set: action.payload },
      });
    case FAIL_LOAD_ROUTE_HISTORY_VEHICLE_POSITION:
      return update(state, {
        isLoadingRouteVehiclePositions: { $set: false },
      });
    case START_LOAD_ROUTE_HISTORY_VEHICLE_BREADCRUMB:
      return update(state, {
        isLoadingRouteVehiclesBreadCrumbs: { $set: true },
      });
    case COMPLETE_LOAD_ROUTE_HISTORY_VEHICLE_BREADCRUMB:
      return update(state, {
        isLoadingRouteVehiclesBreadCrumbs: { $set: false },
        routeVehiclesBreadCrumbs: { $set: action.payload },
      });
    case FAIL_LOAD_ROUTE_HISTORY_VEHICLE_BREADCRUMB:
      return update(state, {
        isLoadingRouteVehiclesBreadCrumbs: { $set: false },
      });
    case START_LOAD_ROUTE_HISTORY_SEGMENTS:
      return update(state, {
        isLoadingRouteSegments: { $set: true },
      });
    case COMPLETE_LOAD_ROUTE_HISTORY_SEGMENTS:
      return update(state, {
        isLoadingRouteSegments: { $set: false },
        routeSegments: { $set: action.payload },
      });
    case FAIL_LOAD_ROUTE_HISTORY_SEGMENTS:
      return update(state, {
        isLoadingRouteSegments: { $set: false },
      });
    case SET_ROUTE_HISTORY_MODAL_OPEN:
      return update(state, {
        isModalOpen: { $set: action.payload },
        routeHistoryItem: { $set: action.payload ? action.routeHistoryItem : null },
        routeHistoryDriverVehicle: { $set: action.payload ? action.routeHistoryDriverVehicle : null },
      });
    case RESET:
      return initialState;
    default:
      return state;
  }
};

// Action Creators
const startLoadRouteHistoryVehiclePosition = () => ({
  type: START_LOAD_ROUTE_HISTORY_VEHICLE_POSITION,
});
const completeLoadRouteHistoryVehiclePosition = (payload: RouteVehiclePosition[]) => ({
  type: COMPLETE_LOAD_ROUTE_HISTORY_VEHICLE_POSITION,
  payload,
});
const failLoadRouteHistoryVehiclePosition = () => ({
  type: FAIL_LOAD_ROUTE_HISTORY_VEHICLE_POSITION,
});
const startLoadRouteHistoryVehicleBreadCrumb = () => ({
  type: START_LOAD_ROUTE_HISTORY_VEHICLE_BREADCRUMB,
});
const completeLoadRouteHistoryVehicleBreadCrumb = (payload: RouteVehiclesBreadCrumbs) => ({
  type: COMPLETE_LOAD_ROUTE_HISTORY_VEHICLE_BREADCRUMB,
  payload,
});
const failLoadRouteHistoryVehicleBreadCrumb = () => ({
  type: FAIL_LOAD_ROUTE_HISTORY_VEHICLE_BREADCRUMB,
});
const startLoadRouteHistorySegments = () => ({
  type: START_LOAD_ROUTE_HISTORY_SEGMENTS,
});
const completeLoadRouteHistorySegments = (payload: RouteSegment[]) => ({
  type: COMPLETE_LOAD_ROUTE_HISTORY_SEGMENTS,
  payload,
});
const failLoadRouteHistorySegments = () => ({
  type: FAIL_LOAD_ROUTE_HISTORY_SEGMENTS,
});
export const setRouteHistoryModalOpen = (
  payload: boolean,
  routeHistoryItem: RouteHistoryItem | null,
  routeHistoryDriverVehicle: RouteHistoryDriverVehicle | null,
) => ({
  type: SET_ROUTE_HISTORY_MODAL_OPEN,
  payload,
  routeHistoryItem,
  routeHistoryDriverVehicle,
});

export const resetRouteHistoryData = () => ({
  type: RESET,
});

// Thunks
export const loadRouteHistoryVehiclePosition =
  (routeId: number, date: string | Date, vehicleIds?: string, noLoadingIndicator?: boolean) => (dispatch: Dispatch) => {
    if (!noLoadingIndicator) dispatch(startLoadRouteHistoryVehiclePosition());
    const promise = doLoadRouteVehiclePositions(routeId, date, vehicleIds);
    promise
      .then(response => {
        dispatch(completeLoadRouteHistoryVehiclePosition(response.vehiclePositions));
      })
      .catch(() => {
        dispatch(failLoadRouteHistoryVehiclePosition());
      });
    return promise;
  };

export const loadRouteHistoryVehicleBreadCrumb =
  (routeId: number, date: string | Date, vehicleIds?: string, noLoadingIndicator?: boolean) => (dispatch: Dispatch) => {
    if (!noLoadingIndicator) dispatch(startLoadRouteHistoryVehicleBreadCrumb());
    const promise = doLoadRouteVehiclesBreadCrumbs(routeId, date, vehicleIds);
    promise
      .then((response: RouteVehiclesBreadCrumbs) => {
        dispatch(completeLoadRouteHistoryVehicleBreadCrumb(response));
      })
      .catch(() => {
        dispatch(failLoadRouteHistoryVehicleBreadCrumb());
      });
    return promise;
  };

export const loadRouteHistorySegments = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadRouteHistorySegments());
  const promise = doLoadRouteSegments({ vendorId, routeId });
  promise
    .then(response => {
      dispatch(completeLoadRouteHistorySegments(response));
    })
    .catch(() => {
      dispatch(failLoadRouteHistorySegments());
    });
  return promise;
};
