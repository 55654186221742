import { useMemo } from 'react';
import { some } from 'lodash-es';

import useSelectedDraftStops from './useSelectedDraftStops';
import { useSelector } from 'src/core/hooks/useSelector';
import { UNASSIGNED_FILTER, ALL_STOPS_FILTER, ASSIGNED_FILTER } from '../constants/routeTemplateBuilder';

export default function useServicesAvailableForSelection() {
  const selectedServiceTypeIds = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.serviceTypeIds);

  const selectedDraftServices = useSelectedDraftStops();
  const services = useSelector(state => state.routes.routeTemplateBuilder.services);
  const selectedMaterialTypeIdsOnMainFilter = useSelector(
    state => state.routes.routeTemplateBuilder.mapFilters.materialTypeIds,
  );
  const selectedDayOfServiceIdsOnMainFilter = useSelector(
    state => state.routes.routeTemplateBuilder.mapFilters.dayOfServiceIds,
  );
  const selectedStopFilterTypeOnMainFilter = useSelector(
    state => state.routes.routeTemplateBuilder.mapFilters.stopFilterType,
  );
  const selectedRouteTemplateIds = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.routeTemplateIds);

  const selectedEquipmentTypeIds = useSelector(s => s.routes.routeTemplateBuilder.mapFilters.equipmentTypeIds);

  const selectedEquipmentSizeIds = useSelector(s => s.routes.routeTemplateBuilder.mapFilters.equipmentSizeIds);

  return useMemo(
    () =>
      selectedDraftServices ||
      services.filter(service => {
        const availableDays = service.daysOfService?.filter(
          day => selectedDayOfServiceIdsOnMainFilter.indexOf(day.id) !== -1,
        );

        const isAssigned = some(
          availableDays,
          d =>
            (!!d.routeTemplateId || !!d.draftRouteTemplateId) &&
            selectedRouteTemplateIds.indexOf(d.routeName) !== -1,
        );

        const isUnassigned = some(availableDays, d => !d.routeTemplateId && !d.draftRouteTemplateId);

        return (
          (!service.serviceTypeId || selectedServiceTypeIds.indexOf(service.serviceTypeId!) > -1) &&
          selectedMaterialTypeIdsOnMainFilter.indexOf(service.materialTypeId) !== -1 &&
          some(service.daysOfService, day => selectedDayOfServiceIdsOnMainFilter.indexOf(day.id) !== -1) &&
          selectedEquipmentTypeIds.indexOf(service.equipmentTypeId) > -1 &&
          selectedEquipmentSizeIds.indexOf(service.equipmentSizeId) > -1 &&
          (selectedStopFilterTypeOnMainFilter !== UNASSIGNED_FILTER
            ? isUnassigned
              ? true
              : !selectedRouteTemplateIds.length ||
                some(
                  service.daysOfService?.filter(day => selectedDayOfServiceIdsOnMainFilter.indexOf(day.id) !== -1),
                  d =>
                    !!(d.routeTemplateId || d.draftRouteTemplateId) &&
                    selectedRouteTemplateIds.indexOf(d.routeName) !== -1,
                )
            : true) &&
          (selectedStopFilterTypeOnMainFilter === ALL_STOPS_FILTER ||
            (selectedStopFilterTypeOnMainFilter === UNASSIGNED_FILTER && isUnassigned) ||
            (selectedStopFilterTypeOnMainFilter === ASSIGNED_FILTER && isAssigned))
        );
      }),
    [
      selectedDraftServices,
      services,
      selectedServiceTypeIds,
      selectedMaterialTypeIdsOnMainFilter,
      selectedEquipmentTypeIds,
      selectedEquipmentSizeIds,
      selectedStopFilterTypeOnMainFilter,
      selectedRouteTemplateIds,
      selectedDayOfServiceIdsOnMainFilter,
    ],
  );
}
