import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

const DECLINE_REASONS = mapKeys(
  [
    { id: 'Container/Equipment size is unavailable', name: translate('opportunity.serviceChangeRequest.declineReasons.containerSizeUnavailable') },
    { id: 'Service Frequency is unavailable', name: translate('opportunity.serviceChangeRequest.declineReasons.serviceFrequencyUnavailable') },
    { id: 'Material is unavailable', name: translate('opportunity.serviceChangeRequest.declineReasons.materialUnavailable') },
    { id: 'Space Limitations', name: translate('opportunity.serviceChangeRequest.declineReasons.spaceLimitations') },
    { id: 'Other', name: translate('opportunity.other') },
  ],
  'id',
);

export default DECLINE_REASONS;

