import { push } from 'connected-react-router';
import { filter, find, get, includes, map, size, toLower } from 'lodash-es';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { ROUTES_TRACKER_SEQUENCE_ROUTE } from 'src/account/constants';
import { checkIfSupport, checkIfViewOnly, hasPermission } from 'src/account/utils/permissions';
import { PageFooter } from 'src/common/components/styled';
import { Table } from 'src/core/components';
import { Button, Panel, PanelSection, PanelSectionGroup, Text } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import informationPrompt from 'src/core/services/informationPrompt';
import translate from 'src/core/services/translate';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { RoutesOrTemplatesSearchForm } from 'src/routes/components/forms';
import { NoDataMessageContainer } from 'src/routes/components/styled';
import {
  PENDING,
  CANCELED,
  COMPLETE,
  FAILED,
  FAILED_CONFIRMED,
  SEQUENCE_SOURCE_TYPE_DAILY_ROUTE,
} from 'src/routes/constants';
import { assignRoutesToGroups, updateRouteSequenceStatus } from 'src/routes/ducks';
import { bulkDeleteRoutes, loadRoutePlannerRoutes } from 'src/routes/ducks/routePlanner';
import { RoutePlannerRoute } from 'src/routes/interfaces/routePlanner/RoutePlannerRoute';
import { getRoutePlannerFilters } from 'src/routes/services/routePlannerFilters';
import { getRoutePlannerOptions } from 'src/routes/services/routePlannerVehicleTypesFormInitialValuesSelector';
import { getRoutePlannerBuilderTableCells } from 'src/routes/utils/routePlanner';
import { getQueryParams } from 'src/utils/services/queryParams';
import { routeSequencingStatusSelector } from 'src/vendors/ducks';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import RoutePlannerRouteListingTableRow from './RoutePlannerRouteListingTableRow';
import { AddToGroupsModalResolver } from 'src/routes/components/modals';
import { AddToGroupsFormValues } from 'src/routes/components/forms/AddToGroupsForm';

interface Props {
  rows: RoutePlannerRoute[];
  isLoading: boolean;
  deleteRoute: (routeId: number) => void;
}

const RoutePlannerRouteListingSection = ({ rows, isLoading, deleteRoute }: Props) => {
  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const hasRouteSequencePermission = hasPermission(ROUTES_TRACKER_SEQUENCE_ROUTE);
  const routeSequencingEnabled = useSelector(state => routeSequencingStatusSelector(state.vendors.features.features));
  const { routeStatuses, isLoading: isLoadingRouteSequenceStatus } = useSelector(state => state.routes.routeSequence);
  const vehicleTypes = useSelector(state => state.fleet.vehicleTypesForVendor.vehicleTypesForVendor);
  const filterPreferences = useSelector(state => state.common.filters.filters);
  const supervisorEnabled = useSelector(supervisorExperienceFeatureIsEnabled);

  const [selectedRoutes, setSelectedRoutes] = useState<number[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isAddToGroupsModalOpen, setIsAddToGroupsModalOpen] = useState(false);

  const filteredRoutes = useMemo(
    () =>
      map(
        filter(rows, rw => includes(toLower(rw.routeName), toLower(searchTerm))),
        route => {
          const routeSequence = find(routeStatuses, r => r.routeId === route.routeId);

          return {
            ...route,
            routeSequenceStatus: get(routeSequence, 'status'),
            jobId: get(routeSequence, 'jobId'),
            isChecked: selectedRoutes.includes(route.routeId),
          };
        },
      ),
    [routeStatuses, rows, searchTerm, selectedRoutes],
  );

  const toggleSelectAll = () => {
    if (size(selectedRoutes) === size(filteredRoutes)) {
      setSelectedRoutes([]);
    } else {
      setSelectedRoutes(map(filteredRoutes, 'routeId'));
    }
  };

  const selectRoute = (routeId: number) => {
    if (selectedRoutes.includes(routeId)) {
      setSelectedRoutes(selectedRoutes.filter(id => id !== routeId));
    } else {
      setSelectedRoutes([...selectedRoutes, routeId]);
    }
  };

  const onDeleteRoute = (routeId: number) => {
    deleteRoute(routeId);
  };

  const routePlannerFilters = useMemo(() => {
    return getRoutePlannerFilters(search);
  }, [search]);

  const vehicleTypeAndDayOfServiceFilters = useMemo(() => {
    return getRoutePlannerOptions(vehicleTypes, filterPreferences, search);
  }, [filterPreferences, search, vehicleTypes]);

  const { date } = getQueryParams(search);
  const dateParam = date ? moment(date, 'DD/MM/YYYY').format('MM-DD-YYYY') : moment().format('MM-DD-YYYY');
  const { supervisorsIds, serviceZonesIds, facilityTypeIds, materialTypeIds } = routePlannerFilters;
  const { vehicleTypeId } = vehicleTypeAndDayOfServiceFilters;

  const onBulkDelete = async () => {
    if (await confirm(translate('routes.planner.deleteRoutesConfirmation', { count: selectedRoutes.length }))) {
      bulkDeleteRoutes(
        vendorId,
        selectedRoutes,
      )(dispatch)
        .then(count => {
          createSuccessNotification(
            translate('routes.planner.deleteRoutesSuccess', { count, total: selectedRoutes.length }),
          );
          const filtersToPass = {
            vendorId,
            date: dateParam,
            materialTypeIds: materialTypeIds?.length ? materialTypeIds?.toString() : '',
            vehicleTypeIds: vehicleTypeId ? vehicleTypeId?.toString() : '',
            supervisorIds: supervisorsIds?.length && supervisorEnabled ? supervisorsIds?.toString() : '',
            serviceZoneIds: serviceZonesIds?.length ? serviceZonesIds?.toString() : '',
            facilityIds: facilityTypeIds?.length ? facilityTypeIds?.toString() : '',
          };
          loadRoutePlannerRoutes(filtersToPass)(dispatch);
          setSelectedRoutes([]);
        })
        .catch(() => {
          createErrorNotification(translate('routes.planner.deleteRoutesError'));
        });
    }
    return;
  };

  const onTableRowClick = async (
    id: number,
    routeSequenceStatus: string,
    jobId: number,
    isEdit: boolean,
    vehicleTypeId: number,
  ) => {
    const isSnowPlowRoute = vehicleTypeId === SNOW_PLOW_ID;
    const isStreetSweeperRoute = vehicleTypeId === STREET_SWEEPER_ID;

    const getRedirectUrl = () => {
      if (isSnowPlowRoute) return `/routes/snow-tracker/${id}`;
      if (isStreetSweeperRoute) return `/routes/sweeper-tracker/${id}`;
      return `/routes/route-tracker/${id}`;
    };

    if (routeSequencingEnabled && hasRouteSequencePermission) {
      switch (routeSequenceStatus) {
        case PENDING: {
          if (!(await confirm(translate('routes.alertMessages.confirmCancelSequenceRequest')))) {
            break;
          }
          updateRouteSequenceStatus({
            routeid: id,
            jobId,
            status: CANCELED,
            sequenceSourceTypeId: SEQUENCE_SOURCE_TYPE_DAILY_ROUTE,
          })(dispatch).then(() => dispatch(push(getRedirectUrl())));
          break;
        }
        case COMPLETE:
          dispatch(push(`/routes/route-tracker/${id}/route-sequence`));
          break;
        case FAILED:
          if (!(await informationPrompt(translate('routes.alertMessages.confirmFailedRouteSequenceRequest')))) {
            break;
          }
          updateRouteSequenceStatus({
            routeid: id,
            jobId,
            status: FAILED_CONFIRMED,
            sequenceSourceTypeId: SEQUENCE_SOURCE_TYPE_DAILY_ROUTE,
          })(dispatch).then(() => dispatch(push(getRedirectUrl())));
          break;
        default:
          dispatch(push(getRedirectUrl()));
      }
    } else dispatch(push(getRedirectUrl()));
  };

  const toggleAddToGroupsModal = () => {
    setIsAddToGroupsModalOpen(!isAddToGroupsModalOpen);
  };

  const addRoutesToGroups = ({ groupIds }: AddToGroupsFormValues) => {
    if (groupIds.length === 0) return;
    assignRoutesToGroups(
      selectedRoutes,
      groupIds,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('routes.alertMessages.routesAddedToGroup'));
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.routesAddedToGroupError'));
      })
      .finally(() => {
        toggleAddToGroupsModal();
        setSelectedRoutes([]);
      });
  };

  const routesTableCells = getRoutePlannerBuilderTableCells(
    size(rows) === size(selectedRoutes),
    !!size(selectedRoutes) && size(selectedRoutes) < size(rows),
    true,
    toggleSelectAll,
  );

  return (
    <PanelSectionGroup>
      <Panel isLoading={isLoading || isLoadingRouteSequenceStatus} padding="small">
        {!!size(rows) && !isLoading && (
          <PanelSection padding="xSmall no">
            <RoutesOrTemplatesSearchForm onSearchTermChange={setSearchTerm} />
          </PanelSection>
        )}
        {size(filteredRoutes) ? (
          <PanelSection minHeight={550}>
            <Table
              cells={routesTableCells}
              rows={filteredRoutes}
              rowComponent={RoutePlannerRouteListingTableRow}
              scrollMarker
              virtualized
              virtualizedProps={{
                height: Math.min(size(filteredRoutes) * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 8) || 1,
                itemSize: TABLE_ROW_HEIGHT_SMALL,
              }}
              rowProps={{
                selectRoute,
                onTableRowClick,
                deleteRoute: onDeleteRoute,
              }}
              tableHeadRowProps={{
                padding: '0 20px 0 0',
              }}
            />
          </PanelSection>
        ) : (
          <NoDataMessageContainer>
            <Text>{!!size(rows) ? translate('routes.planner.noDataMatches') : translate('routes.planner.noData')}</Text>
          </NoDataMessageContainer>
        )}
      </Panel>
      {!checkIfViewOnly() && !checkIfSupport() && !!size(selectedRoutes) && (
        <PanelSection centered padding="no">
          <PageFooter>
            <Button id="delete-planner-button" color="alert" line onClick={onBulkDelete} margin="no small no no">
              {`${translate('routes.planner.deleteDailyRoute')} (${size(selectedRoutes)})`}
            </Button>

            <Button id="add-to-groups-button" color="primary" onClick={toggleAddToGroupsModal} margin="no small no no">
              {`${translate('routes.groups.addToGroups')} (${size(selectedRoutes)})`}
            </Button>
          </PageFooter>
        </PanelSection>
      )}
      {isAddToGroupsModalOpen && (
        <AddToGroupsModalResolver onAddToGroups={addRoutesToGroups} closeModal={toggleAddToGroupsModal} />
      )}
    </PanelSectionGroup>
  );
};

export default RoutePlannerRouteListingSection;
