import { InjectedFormProps } from 'redux-form';

import { Checkbox, SimpleInput, TypedField } from 'src/core/components';
import { Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import {
  ExpandableMapFiltersSubSection,
  ExpandableMapFilterSubSectionHeading,
  ExpandableMapFilterSubSectionSearch,
  ExpandableMapFilterSubSectionSearchIcon,
} from '../styled/StreetNetwork';

type FiltersSectionProps = {
  change: InjectedFormProps['change'];
  filters: {
    id: number | string;
    label: string;
    rightLabel?: string;
    subFilters?: { id: number; label: string; rightLabel?: string }[];
  }[];
  name: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  leftPosition?: string;
  rightPosition?: string;
};

export const ExpandableMapFiltersSubsection = ({
  change,
  filters,
  name,
  isOpen,
  leftPosition,
  rightPosition,
}: FiltersSectionProps) => {
  if (!filters.length) return null;

  return (
    <ExpandableMapFiltersSubSection isOpen={isOpen} leftPosition={leftPosition} rightPosition={rightPosition}>
      <ExpandableMapFilterSubSectionSearch>
        <TypedField
          name="geoFenceSearchTerm"
          component={SimpleInput}
          props={{
            placeholder: translate('routes.geoFences.searchGeoFence'),
            size: 'small',
            margin: 'no',
          }}
        />
        <ExpandableMapFilterSubSectionSearchIcon />
      </ExpandableMapFilterSubSectionSearch>
      {filters.map((filter, index) => (
        <div key={filter.id}>
          <ExpandableMapFilterSubSectionHeading key={index}>
            <Text size="xSmall">{filter.label}</Text>
            <Text size="xSmall">{filter.subFilters?.length || 0}</Text>
          </ExpandableMapFilterSubSectionHeading>
          {/* Hide check "all", per RVP-4510 comment */}
          {/* <TypedField
            name={`${filter.label}_checkAll`}
            component={Checkbox}
            onChange={(_, isChecked) => {
              filter.subFilters?.forEach(filter => {
                change(`${name}[${filter.id}]`, isChecked);
              });
            }}
            props={{
              label: translate('common.all'),
              block: true,
              margin: 'xSmall no no small',
              size: 'small',
            }}
          /> */}
          {filter.subFilters?.map(subFilter => (
            <TypedField
              key={subFilter.id}
              name={`${name}[${subFilter.id}]`}
              component={Checkbox}
              props={{
                label: subFilter.label,
                block: true,
                margin: 'xSmall no no small',
                size: 'small',
              }}
            />
          ))}
        </div>
      ))}
    </ExpandableMapFiltersSubSection>
  );
};
