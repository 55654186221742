import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

import { getQueryParams } from '../../utils/services/queryParams';

const getContainersNewFormInitialValues = (queryString: string) => {
  const { searchTerm, containerTypeId, locationId, equipmentTypeId, equipmentSizeId, equipmentConditionId } =
    getQueryParams(queryString);

  return {
    searchTerm,
    containerTypeId: Number(containerTypeId),
    locationId: Number(locationId),
    equipmentTypeId: Number(equipmentTypeId),
    equipmentSizeId: equipmentTypeId ? Number(equipmentSizeId) : undefined,
    equipmentConditionId: Number(equipmentConditionId),
  };
};

const containersNewFormInitialValuesSelector = createSelector(getContainersNewFormInitialValues, identity);

export default containersNewFormInitialValuesSelector;
