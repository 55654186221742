import React from 'react';
import styled from 'styled-components';
import { BareButtonStyle } from '../../../../core/components/styled/Button';
import translate from '../../../../core/services/translate';

const Container = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const Box = styled.div`
  display: inline;
  border-radius: 5px;
`;

const Button = styled.button<{ isActive: boolean }>`
  ${BareButtonStyle}
  border: 1px solid ${p => p.theme.brandPrimary};
  background: ${p => (p.isActive ? p.theme.brandPrimary : 'white')};
  color: ${p => (p.isActive ? 'white' : 'initial')};
  padding: 5px;
  border-radius: 5px 0 0 5px;
  &:last-child {
    border-radius: 0 5px 5px 0;
  }
`;

interface Props {
  isEditingCollectionPoints: boolean;
  setIsEditingCollectionPoints: (isEditingCollectionPoints: boolean) => void;
}

export const PickupEditorModeToggle: React.FC<Props> = ({
  isEditingCollectionPoints,
  setIsEditingCollectionPoints,
}) => {
  return (
    <Container>
      <Box>
        <Button isActive={!isEditingCollectionPoints} onClick={() => setIsEditingCollectionPoints(false)}>
          {translate('pickupLocationEditor.tabs.individual')}
        </Button>
        <Button isActive={isEditingCollectionPoints} onClick={() => setIsEditingCollectionPoints(true)}>
          {translate('pickupLocationEditor.tabs.collectionPoints')}
        </Button>
      </Box>
    </Container>
  );
};
