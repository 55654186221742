import React, { ChangeEvent, PureComponent } from 'react';

import { connect } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';

import { Dropdown, TypedField } from '../../core/components';
import { FieldProps } from '../../core/components/FieldProps';
import translate from '../../core/services/translate';
import { AppState } from '../../store';
import { TechnicalType } from '../interfaces/TechnicalType';
import { DropDownFieldProps } from './DropDownFieldProps';

export enum OptionValueType {
  name = 'name',
  object = 'object',
}

interface WasteTypeDropdownProps extends WrappedFieldProps, FieldProps, DropDownFieldProps {
  optionValueType?: OptionValueType;
  wasteTypes: TechnicalType[];
  wasteTypeFilters?: string[];
}

class WasteTypeDropdown extends PureComponent<WasteTypeDropdownProps> {
  onChange = (event: ChangeEvent<HTMLInputElement>, value: any) => {
    this.props.input.onChange(value);
  };

  wasteTypesOptions = () => {
    const { optionValueType, wasteTypes = [], wasteTypeFilters } = this.props;

    return wasteTypes
      .filter(wt => !wasteTypeFilters || wasteTypeFilters.includes(wt.technicalName))
      .map((wasteType: TechnicalType) => ({
        label: wasteType.name,
        value: optionValueType ? (optionValueType === OptionValueType.name ? wasteType.name : wasteType) : wasteType.id,
      }));
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
    } = this.props;

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('common.wasteType') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('common.wasteType') : undefined),
          options: this.wasteTypesOptions(),
          ...dropdownProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  wasteTypes: state.common.wasteTypes.wasteTypes || [],
});

export default connect(mapStateToProps)(WasteTypeDropdown);
