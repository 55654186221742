import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { rotate } from '../../../core/styles';
import { sizeMapper } from '../../../utils/styles';

interface HolidayPlannerTableRowProps {
  isInEditMode?: boolean;
  isLoading?: boolean;
  alignItems?: string;
}

export const HolidayPlannerTableRow = styled.div<HolidayPlannerTableRowProps>`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 24px;
  border-radius: 5px;
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  margin-bottom: 18px;
  background: #fff;
  position: relative;

  ${props =>
    props.isInEditMode
      ? css`
          box-shadow: 0 1px 4px 2px ${props => transparentize(0.8, props.theme.brandPrimary)},
            inset 0 0 0 1px ${props => props.theme.brandPrimary};
        `
      : css`
          box-shadow: 0 1px 4px 2px ${props => transparentize(0.9, props.theme.grayBase)};
        `}

  ${props =>
    props.isLoading &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 5px;
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        width: 20px;
        height: 20px;
        border: 3px solid ${props => props.theme.brandPrimary};
        border-right-color: transparent;
        border-radius: 20px;
        animation: ${rotate} 1s linear infinite;
        z-index: 1;
      }
    `}
`;

export const HolidayPlannerTableCellAbsolute = styled.div`
  position: absolute;
  right: 24px;
  top: 16px;
  display: flex;
`;

export const HolidayPlannerAddButton = styled.button`
  display: flex;
  border-radius: 5px;
  height: 80px;
  align-items: center;
  justify-content: center;
  border: 3px dashed ${props => props.theme.brandPrimary};
  color: ${props => props.theme.brandPrimary};
  background: none;
  background-color: ${props => transparentize(0.93, props.theme.brandPrimary)};
  outline: none;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: ${props => transparentize(0.89, props.theme.brandPrimary)};
  }

  svg {
    margin-right: 12px;
  }
`;

interface HolidayPlannerSpinnerProps {
  bigger?: boolean;
  position?: string;
  margin?: string;
}

export const HolidayPlannerSpinner = styled.div<HolidayPlannerSpinnerProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: ${props => sizeMapper(props.margin, 'no')};

  &:not(:last-child) {
    margin-right: 15px;
  }

  ${props =>
    props.bigger &&
    css`
      padding: 4.5px;
    `}

  ${props =>
    props.position &&
    css`
      position: ${props.position};
    `}

  &:before {
    content: '';
    width: 16px;
    height: 16px;
    border: 3px solid ${props => props.theme.brandPrimary};
    border-right-color: transparent;
    border-radius: 16px;
    animation: ${rotate} 1s linear infinite;
  }
`;

export const HolidayPlannerCancel = styled.span`
  color: ${props => props.theme.brandAlert};
`;

export const HolidayPlannerSave = styled.span`
  color: ${props => props.theme.brandPrimary};
`;

interface HolidayPlannerSchedulerProps {
  disabled?: boolean;
}

export const HolidayPlannerScheduler = styled.div<HolidayPlannerSchedulerProps>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const HolidayPlannerSettingsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 45px 0 30px;
`;

interface HolidayPlannerSliderWrapperProps {
  disabled?: boolean;
}

export const HolidayPlannerSliderWrapper = styled.div<HolidayPlannerSliderWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}

  .rc-slider {
    width: 42%;
    margin-top: 2px;
  }

  .rc-slider-tooltip-arrow {
    display: none;
  }

  .rc-slider-tooltip-placement-top {
    padding-bottom: 0;
  }

  .rc-slider-disabled {
    background: none;

    .rc-slider-step {
      cursor: not-allowed;
    }
  }

  .rc-slider-tooltip-inner {
    background: none;
    color: ${props => props.theme.brandPrimary};
    box-shadow: none;
    white-space: nowrap;
    font-weight: bold;
    font-size: 14px;
  }
`;
