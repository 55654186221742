import { map, filter } from 'lodash-es';
import { RateType } from 'src/finance/interfaces/ServiceRates';

const transformRateCodeTypes = (rateCodeTypes: RateType[]) =>
  map(
    filter(rateCodeTypes, { isActive: true }), // Only include active rate codes
    rateCode => ({
      ...rateCode,
      value: rateCode.id,
      label: rateCode.typeCode,
    }),
  );

export default transformRateCodeTypes;
