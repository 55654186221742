import React, { PureComponent } from 'react';

import { identity, isNumber, orderBy, toLower } from 'lodash-es';
import { createSelector } from 'reselect';

import { ASC, DESC } from '../constants';
import { AccordionTableCells } from '.';
import {
  Table as TableContainer,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadCellSortIcon,
  TableRow,
} from './styled';

const getOrderedRows = (rows: any[], sortedBy: string, sortOrder: boolean | 'asc' | 'desc') =>
  orderBy(rows, [row => (isNumber(row[sortedBy]) ? row[sortedBy] : toLower(row[sortedBy]))], [sortOrder]);

const orderedRowsSelector = createSelector(getOrderedRows, identity);

interface Props {
  cells: any[];
  headerComponent: any;
  noBottomBorder?: boolean;
  rowComponent: any;
  rowProps?: any;
  rows: any[];
  sort?: (sortedBy: string, sortOrder: string) => void;
  sortedBy?: string;
  sortOrder?: string;
  tableHeadProps?: any;
  virtualized?: boolean;
  virtualizedProps?: any;
  visibility?: string;
  withClickableRows?: boolean;
  withTopBorder: boolean;
}

class Table extends PureComponent<Props, { sortedBy?: string; sortOrder?: string }> {
  constructor(props: Props) {
    super(props);
    const { sortedBy, sortOrder } = props;
    this.state = { sortedBy, sortOrder };
  }

  componentDidUpdate(prevProps: Props) {
    const { sortedBy, sortOrder } = this.props;
    if (prevProps.sortedBy !== sortedBy || prevProps.sortOrder !== sortOrder) {
      this.setState({ sortedBy, sortOrder });
    }
  }
  onSortableTableHeadCellClick = (name: string) => {
    this.setState(prevState => {
      const sortedBy = name;
      const sortOrder = prevState.sortedBy !== name || prevState.sortOrder === DESC ? ASC : DESC;

      const { sort } = this.props;
      if (sort) sort(sortedBy, sortOrder);

      return { sortedBy, sortOrder };
    });
  };

  render() {
    const {
      cells,
      rows,
      rowComponent,
      headerComponent,
      rowProps,
      withClickableRows,
      withTopBorder,
      noBottomBorder,
      tableHeadProps,
      virtualized,
      virtualizedProps,
      sort,
      visibility,
    } = this.props;

    const orderedRows = sort ? rows : (orderedRowsSelector(rows, this.state.sortedBy!, this.state.sortOrder) as any as any[]);

    return (
      <TableContainer withTopBorder={withTopBorder} noBottomBorder={noBottomBorder}>
        <TableHead {...tableHeadProps}>
          <TableRow>
            {cells.map(({ name, label, component: Component, componentProps, sortable, align, ...cellProps }) => (
              <TableHeadCell
                key={name}
                sortable={sortable}
                isSortedBy={this.state.sortedBy === name}
                sortOrder={this.state.sortOrder}
                onClick={sortable ? () => this.onSortableTableHeadCellClick(name) : undefined}
                align={align}
                visibility={visibility}
                {...cellProps}
              >
                {Component ? <Component {...componentProps} /> : label}
                <TableHeadCellSortIcon />
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody withClickableRows={withClickableRows} visibility={visibility}>
          <AccordionTableCells
            rows={orderedRows}
            rowComponent={rowComponent}
            headerComponent={headerComponent}
            rowProps={rowProps}
            virtualized={virtualized}
            virtualizedProps={virtualizedProps}
          />
        </TableBody>
      </TableContainer>
    );
  }
}

export default Table;
