import { SavedEquipment, SavedMaterial, BinType, Equipment } from '../interfaces/ServiceArea';
import translate from '../../core/services/translate';

export const getEquipmentTypeId = (equipment: SavedEquipment | Equipment) =>
  equipment.binSubTypeCategory + equipment.binSubType;

export const getAllEquipmentSizes = (equipments: SavedEquipment[]) =>
  equipments.reduce(
    (equipmentSizes: BinType[], equipmentType: SavedEquipment) => [...equipmentSizes, ...equipmentType.binTypes],
    [],
  );

export const getEquipmentTypeOptions = (equipments: SavedEquipment[]) =>
  equipments.map(equipmentType => ({
    label: translate(
      `haulerProfile.equipments.equipmentSubTypes.${equipmentType.binSubTypeCategory + equipmentType.binSubType}`,
    ),
    value: getEquipmentTypeId(equipmentType),
  }));

export const getEquipmentSizeOptions = (equipments: SavedEquipment[], selectedEquipmentTypes: string[]) => {
  const selectedEquipments = selectedEquipmentTypes.length
    ? equipments.filter(equipment => selectedEquipmentTypes.includes(getEquipmentTypeId(equipment)))
    : equipments;

  return getAllEquipmentSizes(selectedEquipments).map((equipmentSize: BinType) => ({
    label: equipmentSize.other
      ? equipmentSize.other
      : translate(`haulerProfile.equipments.equipmentTypes.${equipmentSize.binClass}`),
    value: equipmentSize.vendorGroupEquipmentId,
  }));
};

export const getMaterialOptions = (materials: SavedMaterial[]) =>
  materials.map(material => ({
    label: translate(`haulerProfile.materialTypes.${material.matSubType}`),
    value: material.materialId,
  }));
