import { FC, useEffect, useState } from 'react';

import { Modal } from 'src/core/components';
import { ModalClose, ModalCloseIcon } from 'src/core/components/styled';
import { createErrorNotificationIncludingTechnicalMessage } from 'src/core/services/createNotification';
import { http } from 'src/core/services/http';
import translate from 'src/core/services/translate';
import { IBillingModule } from 'src/vendors/interfaces/BillingModule';
import { ModalProps } from 'src/vendors/interfaces/ModalProps';

interface Props extends ModalProps {
  closeModal: () => void;
  getFormattedFormValues: () => Promise<IBillingModule>;
  vendorId: string;
}

const EmailPreviewModal: FC<Props> = ({ closeModal, getFormattedFormValues, vendorId }) => {
  const [htmlData, setHtmlData] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      const formattedFormValues = await getFormattedFormValues();
      try {
        const response = await http.post<IBillingModule>(
          `/vendors/${vendorId}/billingConfig/email/preview`,
          formattedFormValues,
        );
        setHtmlData(response.data);
      } catch (error) {
        createErrorNotificationIncludingTechnicalMessage(error, translate('common.alertMessages.feedbackSentFail'));
        closeModal();
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal title="email preview">
      <ModalClose onClick={() => closeModal()}>
        <ModalCloseIcon />
      </ModalClose>

      <div dangerouslySetInnerHTML={{ __html: htmlData }} />
    </Modal>
  );
};

export default EmailPreviewModal;
