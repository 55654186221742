import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import { GridColumn } from '../../../core/components/styled';
import { GridWrapper } from '../styled/RouteRecommendations';
import { PanelSearch, Dropdown } from '../../../core/components';
import { DropdownOption } from '../../../core/components/Dropdown';
import translate from '../../../core/services/translate';
import { debounce } from 'lodash-es';

interface RouteRecommendationsFormProps {
  serviceTypes: DropdownOption[];
  wasteTypes: DropdownOption[];
  daysOfService: DropdownOption[];
  accountStatuses: DropdownOption[];
  onSubmit: (data: any) => void;
}

const RouteRecommendationsForm: React.FC<
  RouteRecommendationsFormProps & InjectedFormProps<{}, RouteRecommendationsFormProps>
> = ({ serviceTypes, wasteTypes, daysOfService, accountStatuses, handleSubmit }) => (
  <form onSubmit={handleSubmit} onChange={handleSubmit}>
    <GridWrapper>
      <GridColumn size="4/12">
        <Field
          name="searchTerm"
          component={PanelSearch as any}
          margin="no"
          props={{ borderBottom: true, debounceTime: 200 }}
        />
      </GridColumn>
      <GridColumn size="2/12">
        <Field
          clearable
          isClearable
          name="serviceType"
          component={Dropdown}
          options={serviceTypes}
          label={translate('common.serviceType')}
          margin="no"
        />
      </GridColumn>
      <GridColumn size="2/12">
        <Field
          isClearable
          name="wasteMaterialType"
          component={Dropdown}
          options={wasteTypes}
          label={translate('common.wasteType')}
          margin="no"
        />
      </GridColumn>
      <GridColumn size="2/12">
        <Field
          isClearable
          name="schedulerType"
          component={Dropdown}
          options={daysOfService}
          label={translate('common.dayOfService')}
          margin="no"
        />
      </GridColumn>
      <GridColumn size="2/12">
        <Field
          isClearable
          name="serviceContractAccountStatusType"
          component={Dropdown}
          options={accountStatuses}
          label={translate('common.accountStatus')}
          margin="no"
        />
      </GridColumn>
    </GridWrapper>
  </form>
);

const debounceFormUpdate = debounce((callback: any) => {
  // debouncing the submit event because the filtering is recreated with each type in the search bar
  callback();
}, 500);

export default reduxForm<any, RouteRecommendationsFormProps>({
  form: 'routeRecommendations',
  enableReinitialize: true,
  onChange: (values, dispatch, props, previousValues) => {
    debounceFormUpdate(() => props.onSubmit(values));
  },
})(RouteRecommendationsForm);
