import React, { PureComponent } from 'react';

import { InjectedFormProps, reduxForm } from 'redux-form';

import { OptionValueType, WasteTypeDropdown } from '../../../../../../common/components';
import { TypedField } from '../../../../../../core/components';
import DateRangePicker, { DateRangeOptionValue } from '../../../../../../core/components/DateRangePicker';
import { DATE_RANGE_PICKER_30_60_90 } from '../../../../../../core/constants';
import translate from '../../../../../../core/services/translate';
import { FilterInlineWrapper } from '../../../../styled';
import { FilterFunctions } from './useDispatchBoardFilters';

interface PropsWithoutReduxForm {
  filterFunctions: FilterFunctions;
}

interface FormValues {
  dateRange?: DateRangeOptionValue;
  wasteType?: string;
}

type UnassignedJobsFilterFormProps = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

class UnassignedJobsFilterForm extends PureComponent<UnassignedJobsFilterFormProps> {
  render() {
    const { handleSubmit, filterFunctions } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <FilterInlineWrapper>
          <TypedField
            name="dateRange"
            component={DateRangePicker}
            onChange={filterFunctions.onDateFilterChange}
            props={{
              hasMarginLeft: 'normal',
              isClearable: true,
              dateFilterBlur: filterFunctions.onFilterBlur,
              dateFilterFocus: filterFunctions.onFilterFocus,
              width: '50%',
              options: DATE_RANGE_PICKER_30_60_90,
              placeholder: translate('common.date'),
              margin: 'no small no no',
            }}
          />
          <TypedField
            name="wasteType"
            component={WasteTypeDropdown}
            onChange={filterFunctions.onWasteTypeChange}
            props={{
              optionValueType: OptionValueType.name,
              withPlaceholder: true,
              dropdownProps: {
                onMenuOpen: filterFunctions.onFilterFocus,
                onMenuClose: filterFunctions.onFilterBlur,
                isClearable: true,
                margin: 'no',
                width: '50%',
              },
            }}
          />
        </FilterInlineWrapper>
      </form>
    );
  }
}

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: 'unassignedJobsFilterForm',
})(UnassignedJobsFilterForm);
