import { http } from '../../core/services/http';

import { transformLoadContacts } from './transformContacts';

export const loadContacts = (haulerId: string, contactType: string, divisionId: string) =>
  http
    .get(`/haulerProfile/${haulerId}/contacts?contactTypes=${contactType}`)
    .then(response => transformLoadContacts(response.data));

export const saveContacts = (haulerId: string, data: any) => http.post(`/haulerProfile/${haulerId}/contact`, data);

export const updateContacts = (haulerId: string, data: any) =>
  http.put(`/haulerProfile/${haulerId}/contact/${data.id}`, data);

export const deleteContact = (haulerId: string, contactId: number, divisionId: string) =>
  http.delete(`/haulerProfile/${haulerId}/contact/${contactId}`);
