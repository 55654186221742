import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { DuckFunction } from '../../../contracts/ducks';
import { loadBinColors } from '../../../fleet/ducks';
import { loadContainerColorConfiguration } from '../../ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import ContainerColorConfigurationModal from './ContainerColorConfigurationModal';

interface Props {
  closeModal: () => void;
  loadBinColors: DuckFunction<typeof loadBinColors>;
  loadContainerColorConfiguration: DuckFunction<typeof loadContainerColorConfiguration>;
  vendorId: number;
}

class ContainerColorConfigurationModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { loadContainerColorConfiguration, loadBinColors, vendorId } = this.props;

    const dependencies = [loadContainerColorConfiguration(vendorId), loadBinColors()];

    return Promise.all(dependencies);
  };

  render() {
    const { closeModal } = this.props;

    return (
      <Resolver
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
        successComponent={ContainerColorConfigurationModal}
        successProps={{ closeModal }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadBinColors, loadContainerColorConfiguration };

export default connect(mapStateToProps, mapDispatchToProps)(ContainerColorConfigurationModalResolver);
