import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { BareButtonStyle } from '../../../core/components/styled';
import { fadeAndSlideInDown } from '../../../core/styles';
import { Icon } from '../../../core/components';
import { mapper, iterator } from '../../../utils/styles';

interface Props {
  first?: boolean;
  size?: string;
  step?: number;
  tabletWrap?: boolean;
}

export const RouteSummaryIcon = styled(Icon)<Props>`
  width: ${props => mapper(props.size, { small: '26px', medium: '30px', large: '33px' })};
  color: ${props => props.theme.brandPrimary};
`;

export const RouteSummaryIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-right: 15px;
  border-radius: 30px;
  border: 2px solid ${props => props.theme.grayLight};
  flex: 0 0 60px;
`;

export const RouteSummaryLabel = styled.span`
  display: block;
  margin-bottom: 6px;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 13px;
  color: ${props => props.theme.grayDark};
  white-space: nowrap;
`;

export const RouteSummaryValue = styled.span<{ isSizeSmall?: boolean }>`
  display: block;
  font-size: 16px;

  ${props =>
    props.isSizeSmall &&
    css`
      font-size: 14px;
      margin-top: 3px;
    `}
`;

export const RouteSummaryValueButton = styled(RouteSummaryValue.withComponent('button'))`
  ${BareButtonStyle};
  text-align: left;
  color: ${props => props.theme.brandPrimary};

  &:hover {
    color: ${props => darken(0.05, props.theme.brandPrimary)};
  }
`;

export const RouteSummaryDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RouteSummaryItem = styled.div<Props>`
  display: flex;
  margin: 10px 20px;

  &:last-child {
    margin-right: 0;
  }

  animation: ${fadeAndSlideInDown} 0.3s ease-out backwards;
  ${iterator(5, (step: number) => `&:nth-child(${step + 1}) {animation-delay: ${(step + 1) * 0.1}s;}`)};

  @media (max-width: ${props => props.theme.desktopBreakpoint}) {
    margin-bottom: 20px;
  }
`;

export const RouteSummary = styled.div<Props>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const RouteSummaryAssistedContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const RouteSummaryAssistedToggle = styled.div`
  cursor: pointer;
  margin-top: 20px;
  margin-left: 20px;
`;

export const RouteSummaryAssistedTitle = styled.span`
  margin-left: 10px;
`;

export const AssistingVehicleContainer = styled.div<Props>`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${props =>
    props.first &&
    css`
      margin-left: 0;
    `}
`;

export const AssistingVehicleInfo = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const RouteAssistSummaryContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
