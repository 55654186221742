import { Feature } from '@turf/turf';
import { filter, findIndex, flatten, forEach } from 'lodash-es';

import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { APPLICATION_STATUS_ICONS, POLYLINE_COLORS } from 'src/common/constants';
import { RouteDriversVehiclesItem, RouteVehiclesBreadCrumbsItem } from 'src/dashboard/interfaces/routesData';
import { RouteMapVehicleTracking } from 'src/routes/interfaces/RouteMapVehicleData';
import { ROUTE_MAP_APPLICATION_STATUS_LAYER } from '../constants';

export const getApplicationStatusId = (tracking: RouteMapVehicleTracking, statusIndex: number) =>
  Number(`${tracking.vehicle.id}.${statusIndex}`);

export type ApplicationStatusFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon: string;
  vehicleId: number;
  statusIndex: number;
};

export const getApplicationStatusGeoJSON = (vehicleTrackings: RouteMapVehicleTracking[]) => {
  return getFeatureCollection<GeoJSON.Point, ApplicationStatusFeatureProperties>(
    vehicleTrackings
      .map(vehicleTracking =>
        vehicleTracking.applicationModeChanges?.map((status, statusIndex) =>
          getPointFeature(getApplicationStatusId(vehicleTracking, statusIndex), [status.longitude, status.latitude], {
            id: getApplicationStatusId(vehicleTracking, statusIndex),
            clickable: true,
            layer: ROUTE_MAP_APPLICATION_STATUS_LAYER,
            icon: APPLICATION_STATUS_ICONS[status.applicationModeStatusId].iconType,
            vehicleId: vehicleTracking.vehicle.id,
            statusIndex,
          }),
        ),
      )
      .reduce((acc, current) => acc.concat(current), []),
  );
};

export const getApplicationStatusForRouteGeoJSON = (
  vehicleTrackings: RouteVehiclesBreadCrumbsItem[],
  routeVehicles?: RouteDriversVehiclesItem[],
) => {
  const applicationModeChanges: Feature<GeoJSON.Point, ApplicationStatusFeatureProperties>[] = [];

  for (let index = 0; index < vehicleTrackings.length; index++) {
    const vehicle = vehicleTrackings[index];

    const flatTrackings = flatten(vehicle.coords);

    let color = index;

    // find the index of the vehicle in the routeVehiclesBreadCrumbs
    const vehicleIndex = findIndex(routeVehicles, v => v.vehicleId === vehicle.id);
    if (vehicleIndex > -1) {
      color = vehicleIndex;
    }

    if (color > POLYLINE_COLORS.length - 1) color = 0;

    const vehicleApplicationModeChanges = filter(flatTrackings, (coord, index) => {
      if (index === 0) return false;
      return coord.am !== flatTrackings[index - 1].am;
    });

    forEach(vehicleApplicationModeChanges, (change, index) => {
      const point = getPointFeature(+`${vehicle.id}.${index}`, [change.lng, change.lat], {
        id: +(+`${vehicle.id}.${index}`),
        clickable: true,
        layer: ROUTE_MAP_APPLICATION_STATUS_LAYER,
        icon: APPLICATION_STATUS_ICONS[change.am].iconType,
        vehicleId: vehicle.id,
        statusIndex: index,
        color: POLYLINE_COLORS[color],
      });
      applicationModeChanges.push(point);
    });
  }

  return getFeatureCollection<GeoJSON.Point, ApplicationStatusFeatureProperties>(applicationModeChanges);
};
