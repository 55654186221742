import React, { PureComponent } from 'react';

import { isNumber, orderBy, toLower } from 'lodash-es';

import { ASC, DESC } from '../constants';
import { TableCells } from '.';
import {
  Table as TableContainer,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadCellSortIcon,
  TableRow,
} from './styled';
import { TableFooter, TableBodyProps, TableHeadProps, TableRowProps } from './styled/Table';

export type SortOrder = 'asc' | 'desc';

export const getOrderedRows = (rows: any[], sortedBy: string, sortOrder: SortOrder) =>
  orderBy(rows, [row => (isNumber(row[sortedBy]) ? row[sortedBy] : toLower(row[sortedBy]))], [sortOrder]);

export interface TableCell {
  align?: string;
  component?: any;
  componentProps?: any;
  flex?: string;
  height?: string;
  label?: string;
  name: string;
  noPaddingRight?: boolean;
  padding?: string;
  rotate?: boolean;
  sortable?: boolean;
  visibility?: 'none';
  width?: string;
}

interface Props {
  cells: TableCell[];
  children?: any;
  height?: string;
  noBackground?: boolean;
  noBottomBorder?: boolean;
  noOverflow?: boolean;
  overflow?: string;
  rowComponent?: any;
  rowProps?: any;
  rows?: any[];
  rowScrollTopPosition?: number;
  scrollMarker?: boolean;
  sort?: (sortBy: string, sortOrder: SortOrder) => void;
  sortedBy?: string;
  sortOrder?: SortOrder;
  tableBodyProps?: TableBodyProps;
  tableFooterComponent?: any;
  tableFooterRowProps?: any;
  tableHeading?: string;
  tableHeadingAction?: any;
  tableHeadProps?: TableHeadProps;
  tableHeadRowProps?: TableRowProps;
  virtualized?: boolean;
  virtualizedProps?: object;
  visibility?: string;
  withClickableRows?: boolean;
  withTopBorder?: boolean;
}

class Table extends PureComponent<Props, { sortedBy?: string; sortOrder?: SortOrder }> {
  constructor(props: Props) {
    super(props);
    const { sortedBy, sortOrder } = props;
    this.state = { sortedBy, sortOrder };
  }

  componentDidUpdate(prevProps: Props) {
    const { sortedBy, sortOrder } = this.props;
    if (prevProps.sortedBy !== sortedBy || prevProps.sortOrder !== sortOrder) {
      this.setState({ sortedBy, sortOrder });
    }
  }

  onSortableTableHeadCellClick = (name: string) => {
    this.setState(prevState => {
      const sortedBy = name;
      const sortOrder = prevState.sortedBy !== name || prevState.sortOrder === DESC ? ASC : DESC;

      const { sort } = this.props;
      if (sort) sort(sortedBy, sortOrder);
      return { sortedBy, sortOrder };
    });
  };

  render() {
    const {
      cells,
      children,
      height,
      noBackground,
      noBottomBorder,
      noOverflow,
      overflow,
      rowComponent,
      rowProps,
      rows,
      rowScrollTopPosition,
      scrollMarker,
      sort,
      tableBodyProps,
      tableFooterComponent,
      tableFooterRowProps,
      tableHeading,
      tableHeadingAction,
      tableHeadProps,
      tableHeadRowProps,
      virtualized,
      virtualizedProps,
      visibility,
      withClickableRows,
      withTopBorder,
    } = this.props;

    const orderedRows = sort ? rows : getOrderedRows(rows!, this.state.sortedBy!, this.state.sortOrder!);

    return (
      <TableContainer
        height={height}
        withTopBorder={withTopBorder}
        noBottomBorder={noBottomBorder}
        noBackground={noBackground}
      >
        <TableHead {...tableHeadProps}>
          <TableRow {...tableHeadRowProps}>
            {cells.map(({ name, label, component: Component, componentProps, sortable = false, ...cellProps }) => (
              <TableHeadCell
                key={name}
                sortable={sortable}
                visibility={visibility}
                {...cellProps}
                sortOrder={this.state.sortOrder}
                isSortedBy={this.state.sortedBy === name}
                onClick={sortable ? () => this.onSortableTableHeadCellClick(name) : undefined}
              >
                {!!label && label}
                {!!Component && <Component {...componentProps} />}
                <TableHeadCellSortIcon />
              </TableHeadCell>
            ))}
          </TableRow>
          {tableHeading && (
            <TableRow hasHeading={tableHeading !== undefined}>
              {tableHeading}
              {tableHeadingAction}
            </TableRow>
          )}
        </TableHead>
        <TableBody
          withClickableRows={withClickableRows}
          visibility={visibility}
          hasHeading={tableHeading !== undefined}
          overflow={overflow}
          {...tableBodyProps}
        >
          {!children ? (
            <TableCells
              noOverflow={noOverflow}
              rowComponent={rowComponent}
              rowProps={rowProps}
              rows={orderedRows!}
              rowScrollTopPosition={rowScrollTopPosition}
              scrollMarker={scrollMarker}
              virtualized={virtualized}
              virtualizedProps={virtualizedProps}
            />
          ) : (
            children
          )}
        </TableBody>
        {tableFooterComponent && <TableFooter {...tableFooterRowProps}>{tableFooterComponent}</TableFooter>}
      </TableContainer>
    );
  }
}

export default Table;
