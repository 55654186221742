import styled from 'styled-components';
import Icon from '../../../core/components/Icon';
import { theme } from '../../../core/styles';

export const InspectionsListContainer = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(2, auto);
  margin-left: 60px;
  margin-top: 40px;
`;

export const InspectionImageContainer = styled.img`
  cursor: pointer;
  height: 40px;
  width: 40px;
`;

export const InspectionPreviewImageContainer = styled.img`
  width: 400px;
`;

export const InspectionPassedIcon = styled(Icon).attrs({ icon: 'check' })`
  color: ${theme.brandSuccess};
  height: 19px;
  margin-right: 6px;
  margin-top: -1px;
  vertical-align: top;
  width: 19px;
`;

export const InspectionFailedIcon = styled(Icon).attrs({ icon: 'close' })`
  color: ${theme.brandAlert};
  height: 14px;
  margin-left: 6px;
  margin-top: 1px;
  vertical-align: top;
  width: 14px;
`;

export const ToggleIconContainer = styled.div`
  left: -20px;
  position: relative;
  top: 10px;
`;

export const InspectionAsterisk = styled.span`
  color: ${theme.brandAlert};
  margin-left: 10px;
  font-size: 170%;

  &:before {
    content: '*';
  }
`;
