import update from 'immutability-helper';
import { find, identity } from 'lodash-es';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';

import { Vendor } from '../interfaces/Vendors';
import { loadVendors as doLoadVendors } from '../services/vendors';

// Actions
const START_LOAD = 'vendors/vendors/START_LOAD';
const COMPLETE_LOAD = 'vendors/vendors/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/vendors/FAIL_LOAD';
const START_EXPORT = 'vendors/vendors/START_EXPORT';
const COMPLETE_EXPORT = 'vendors/vendors/COMPLETE_EXPORT';
const FAIL_EXPORT = 'vendors/vendors/FAIL_EXPORT';
const RESET = 'vendors/vendors/RESET';

export interface VendorsState {
  isLoading: boolean;
  isExporting: boolean;
  vendors: Vendor[];
  total?: number;
}

// Initial state
const initialState: VendorsState = {
  isLoading: false,
  isExporting: false,
  vendors: [],
  total: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vendors: action.vendors,
          total: action.total,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vendors: [],
        },
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          isLoading: false,
          vendors: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vendors: Vendor[], total: number) => {
  return {
    type: COMPLETE_LOAD,
    vendors,
    total,
  };
};

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadVendors = (
  vendorName?: string,
  status?: string,
  page?: number,
  limit?: number,
  sortOrder?: string,
  sortDirection?: string,
) => (dispatch: ThunkDispatch<VendorsState, any, AnyAction>) => {
  dispatch(startLoad());
  const loadVendorsPromise = doLoadVendors(vendorName, status, page, limit, sortOrder, sortDirection);
  loadVendorsPromise
    .then(({ vendors, total }) => dispatch(completeLoad(vendors, total)))
    .catch(() => dispatch(failLoad()));

  return loadVendorsPromise;
};

export const resetVendors = () => ({
  type: RESET,
});

// Selectors
const getVendorById = (vendorState: VendorsState, vendorId: number) => find(vendorState.vendors, { id: vendorId });
export const vendorByIdSelector = createSelector(getVendorById, identity);
