import { chunk, drop, uniqueId } from 'lodash-es';
import { useState } from 'react';

import {
  ContainerStatisticsTable,
  ContainerStatisticsTableBody,
  ContainerStatisticsTableBodyContainer,
  ContainerStatisticsTableCell,
  ContainerStatisticsTableFeaturedValue,
  ContainerStatisticsTableHead,
  ContainerStatisticsTableRow,
  ContainerStatisticsTableToggle,
  ContainerStatisticsTableValue,
} from '../../styled/ContainersStatistics';
import { Message } from '../../../../core/components/styled';
import LowInventoryPartsModalResolver from './lowInventoryParts/LowInventoryPartsModalResolver';
import translate from 'src/core/services/translate';

interface Props {
  containerTypeId?: number;
  featuredValue?: number;
  hasLowInventoryPartsRowsData?: boolean;
  rows: any[];
  title: string;
  noViewAllButton?: boolean;
  isCentered?: boolean;
  isSmaller?: boolean;
  isTitleSmaller?: boolean;
  displayAllData?: boolean;
}

const StatisticsTable = ({
  containerTypeId,
  featuredValue,
  hasLowInventoryPartsRowsData,
  rows,
  title,
  noViewAllButton,
  isCentered,
  isSmaller,
  isTitleSmaller,
  displayAllData,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLowInventoryPartsModalVisible, setIsLowInventoryPartsModalVisible] = useState(false);

  const rowsChunked = displayAllData ? [rows] : chunk(rows, 3);

  return (
    <ContainerStatisticsTable>
      <ContainerStatisticsTableHead>
        <ContainerStatisticsTableRow isTitleSmaller={isTitleSmaller} isCentered={isCentered}>
          <ContainerStatisticsTableCell>{title}</ContainerStatisticsTableCell>
          {featuredValue && (
            <ContainerStatisticsTableCell>
              <ContainerStatisticsTableFeaturedValue>{featuredValue}</ContainerStatisticsTableFeaturedValue>
            </ContainerStatisticsTableCell>
          )}
          {!noViewAllButton && rows.length > 3 && (
            <ContainerStatisticsTableToggle onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? translate('containers.viewLess') : translate('containers.viewAll')}
            </ContainerStatisticsTableToggle>
          )}

          {hasLowInventoryPartsRowsData && (
            <>
              <ContainerStatisticsTableToggle
                onClick={() => setIsLowInventoryPartsModalVisible(!isLowInventoryPartsModalVisible)}
              >
                {translate('containers.manageInventory')}
              </ContainerStatisticsTableToggle>

              {isLowInventoryPartsModalVisible && (
                <LowInventoryPartsModalResolver
                  containerTypeId={containerTypeId}
                  closeModal={() => setIsLowInventoryPartsModalVisible(false)}
                />
              )}
            </>
          )}
        </ContainerStatisticsTableRow>
      </ContainerStatisticsTableHead>
      <ContainerStatisticsTableBodyContainer isExpanded={isExpanded} width="200px">
        <ContainerStatisticsTableBody height={hasLowInventoryPartsRowsData && !rowsChunked.length ? '0' : '100%'}>
          {rowsChunked[0]?.length ? (
            rowsChunked[0].map(row => (
              <ContainerStatisticsTableRow key={uniqueId('statistics_table_')} isSmaller={isSmaller}>
                <ContainerStatisticsTableCell isSmaller={isSmaller}>{row.label}</ContainerStatisticsTableCell>
                <ContainerStatisticsTableCell isSmaller={isSmaller} align="right">
                  <ContainerStatisticsTableValue>{row.value}</ContainerStatisticsTableValue>
                </ContainerStatisticsTableCell>
              </ContainerStatisticsTableRow>
            ))
          ) : (
            <ContainerStatisticsTableRow isSmaller={isSmaller} isCentered={isCentered}>
              <ContainerStatisticsTableCell isSmaller={isSmaller}>
                {translate('containers.noDataAvailable')}
              </ContainerStatisticsTableCell>
            </ContainerStatisticsTableRow>
          )}
        </ContainerStatisticsTableBody>

        {rowsChunked?.length > 1 &&
          isExpanded &&
          drop(rowsChunked).map(chunk => (
            <ContainerStatisticsTableBody key={uniqueId('statistics_table_body')}>
              {chunk.map(row => (
                <ContainerStatisticsTableRow key={uniqueId('statistics_table_expanded_')}>
                  <ContainerStatisticsTableCell>{row.label}</ContainerStatisticsTableCell>
                  <ContainerStatisticsTableCell>
                    <ContainerStatisticsTableValue>{row.value}</ContainerStatisticsTableValue>
                  </ContainerStatisticsTableCell>
                </ContainerStatisticsTableRow>
              ))}
            </ContainerStatisticsTableBody>
          ))}
      </ContainerStatisticsTableBodyContainer>

      {hasLowInventoryPartsRowsData && !rowsChunked.length && (
        <Message padding="xSmall no">{translate('containers.noLowInventoryParts')}</Message>
      )}
    </ContainerStatisticsTable>
  );
};

export default StatisticsTable;
