import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PageActions,
  PageBackButton,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { Button, Message, Panel, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import ReleaseNotesTableRow from '../ReleaseNotesTableRow';
import { Table } from '../../../core/components';
import { ReleaseNoteEditorModal } from '../ReleaseNoteEditorModal';
import ReleaseNotesManagementFilterForm from '../forms/ReleaseNotesManagementFilterForm';
import { loadReleaseNotes } from '../../ducks/releaseNotes';

const releaseNoteTableCells = [
  { name: 'releaseDate', label: translate('versioning.releaseDate'), width: '25%' },
  { name: 'releaseType', label: translate('versioning.releaseType'), width: '25%' },
  { name: 'notifyUsers', label: translate('versioning.notifyUsers'), width: '20%' },
  { name: 'publishDate', label: translate('versioning.publishDate'), width: '20%' },
  { name: 'actions', label: '', width: '10%' },
];

export default function ReleaseNotesManagementPage() {
  const { isLoading, releaseNotes } = useSelector((state: AppState) => state.settings.releaseNotes);
  const [isCreateReleaseNoteModalOpen, setIsCreateReleaseNoteModalOpen] = useState(false);
  const dispatch = useDispatch();

  return (
    <PageContent isLoading={isLoading}>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButton to={`/release-notes`} id="back-button">
              <PageBackButtonIcon />
            </PageBackButton>
            <PageTitle>{translate('versioning.manageNotes')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions>
          <Button
            onClick={() => setIsCreateReleaseNoteModalOpen(true)}
            color="primary"
            id="add-new-release-button"
            margin="no xSmall no no"
          >
            {translate('versioning.createReleaseNote')}
          </Button>
        </PageActions>
      </PageHeader>
      <Panel>
        <PanelSectionGroup>
          <ReleaseNotesManagementFilterForm
            onChange={({ dateRange: { to, from } }) => loadReleaseNotes(from, to)(dispatch)}
          />
          {!!releaseNotes.length ? (
            <PanelSection>
              <Table cells={releaseNoteTableCells} rows={releaseNotes} rowComponent={ReleaseNotesTableRow} />
            </PanelSection>
          ) : (
            <PanelSection centered>
              <Message padding="large">{translate('versioning.noReleaseNotesFound')}</Message>
            </PanelSection>
          )}
        </PanelSectionGroup>
      </Panel>
      {isCreateReleaseNoteModalOpen && (
        <ReleaseNoteEditorModal onCloseModal={() => setIsCreateReleaseNoteModalOpen(false)} />
      )}
    </PageContent>
  );
}
