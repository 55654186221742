import { map } from 'lodash-es';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';
import { TechnicalType } from '../interfaces/TechnicalType';

const transformServicesYearlyFrequencyTypes = (pickupFrequencyOptions: TechnicalType[]) =>
  map(pickupFrequencyOptions, pickupFrequencyOption => {
    const everyXMonthNumber = pickupFrequencyOption.technicalName.replace(/[^0-9]/g, '');

    return {
      ...pickupFrequencyOption,
      name:
        parseFloat(everyXMonthNumber) === 1
          ? translate(createTranslationKey(pickupFrequencyOption.technicalName, 'customers'))
          : translate('customers.everyXMonths', { selected: everyXMonthNumber }),
    };
  });

export default transformServicesYearlyFrequencyTypes;
