import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { loadPermissions, shouldLoadPermissions } from '../../../account/ducks/permissions';
import { loadUsers } from '../../ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import translate from '../../../core/services/translate';
import UsersPage from './UsersPage';

const VendorEditorPageResolver: React.FC = () => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const permissionsShallBeLoaded = useSelector(shouldLoadPermissions);

  const loadDependencies = React.useCallback(() => {
    const dependencies = [
      loadUsers(vendorId)(dispatch),
      permissionsShallBeLoaded ? loadPermissions()(dispatch) : (null as any),
    ];

    return Promise.all(dependencies);
  }, [dispatch, permissionsShallBeLoaded, vendorId]);

  return (
    <>
      <DocumentTitle>{translate('vendors.users')}</DocumentTitle>

      <Resolver
        successComponent={UsersPage}
        loadingComponent={PageLoading}
        resolve={loadDependencies}
        redirectOnError="/dashboard"
      />
    </>
  );
};

export default VendorEditorPageResolver;
