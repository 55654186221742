import { find } from 'lodash-es';
import moment from 'moment';
import { useState } from 'react';

import { ThumbnailSet } from 'src/core/components';
import { TableCell, TableRow, Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import FuelTicketImagesModal from 'src/routes/components/modals/FuelTicketImagesModal';
import { FuelTicket } from 'src/routes/interfaces/FuelTicket';
import { dateFormat, timeFormatWithoutSeconds } from 'src/utils/services/validator';

interface FuelTicketTableRowProps extends FuelTicket {}

const FuelTicketTableRow = ({
  driverId,
  fuelAmount,
  fuelCost,
  fuelingDateTimeLocal,
  id,
  image,
  locationId,
  routeId,
  routeLocationId,
  unitOfMeasureTypeId,
  vehicleId,
}: FuelTicketTableRowProps) => {
  const [isFuelTicketImageModalOpen, setIsFuelTicketImageModalOpen] = useState(false);

  const fuelingStations = useSelector(state => state.routes.fuelTickets.fuelingStations);
  const fuelMeasurementUnits = useSelector(state => state.routes.fuelTickets.unitsOfMeasureValues);

  const fuelingStation = find(fuelingStations, { id: locationId });
  const measurementUnit = find(fuelMeasurementUnits, { id: unitOfMeasureTypeId });

  const fuelTicket = {
    driverId,
    fuelAmount,
    fuelCost,
    fuelingDateTimeLocal,
    fuelingStation,
    id,
    image,
    locationId,
    measurementUnit,
    routeId,
    routeLocationId,
    unitOfMeasureTypeId,
    vehicleId,
  } as FuelTicket;

  return (
    <>
      <TableRow>
        <TableCell width="14%" vertical>
          <Text block size="medium" singleLine>
            {moment(fuelingDateTimeLocal).format(dateFormat)}
          </Text>
          <Text block size="medium">
            {moment(fuelingDateTimeLocal).format(timeFormatWithoutSeconds)}
          </Text>
        </TableCell>
        <TableCell width="40%" vertical>
          <Text
            block
            size="medium"
            singleLine
            title={fuelingStation ? fuelingStation.fuelingStationName : '-'}
            weight="medium"
          >
            {fuelingStation ? fuelingStation.fuelingStationName : '-'}
          </Text>
          <Text block size="medium">
            {fuelingStation ? fuelingStation.fuelingStationAddress : '-'}
          </Text>
        </TableCell>
        <TableCell width="14%">
          <Text block size="medium">
            {fuelAmount ?? '-'}
          </Text>
        </TableCell>
        <TableCell width="10%">
          <Text block size="medium">
            {measurementUnit ? measurementUnit.name : '-'}
          </Text>
        </TableCell>
        <TableCell width="12%">
          <Text block size="medium">
            {fuelCost ? `$${fuelCost}` : '-'}
          </Text>
        </TableCell>
        <TableCell width="10%">
          {!!image && image.url && (
            <ThumbnailSet images={[image.url]} onClick={() => setIsFuelTicketImageModalOpen(true)} />
          )}
        </TableCell>
      </TableRow>
      {isFuelTicketImageModalOpen && (
        <FuelTicketImagesModal
          fuelTicket={fuelTicket}
          fuelingStation={fuelingStation}
          closeModal={() => setIsFuelTicketImageModalOpen(false)}
        />
      )}
    </>
  );
};

export default FuelTicketTableRow;
