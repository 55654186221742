import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadLocationFlags as doLocationFlags,
  saveLocationFlags as doSaveLocationFlags,
} from '../services/locationFlags';
import { LocationFlags } from '../interfaces/LocationFlags';

// Actions
const START_LOAD = 'vendors/locationFlags/START_LOAD';
const COMPLETE_LOAD = 'vendors/locationFlags/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/locationFlags/FAIL_LOAD';
const START_SAVE = 'vendors/locationFlags/START_SAVE';
const COMPLETE_SAVE = 'vendors/locationFlags/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/locationFlags/FAIL_SAVE';
const RESET = 'vendors/locationFlags/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  locationFlags: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          locationFlags: action.locationFlags,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (locationFlags: LocationFlags[]) => ({
  type: COMPLETE_LOAD,
  locationFlags,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadLocationFlags = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadLocationFlagsPromise = doLocationFlags(vendorId);
  loadLocationFlagsPromise
    .then(locationFlags => dispatch(completeLoad(locationFlags)))
    .catch(() => dispatch(failLoad()));

  return loadLocationFlagsPromise;
};

export const saveLocationFlags = (vendorId: number, locationFlags: LocationFlags[]) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveLocationFlagsPromise = doSaveLocationFlags(vendorId, locationFlags);
  saveLocationFlagsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveLocationFlagsPromise;
};

export const resetLocationFlags = () => ({
  type: RESET,
});
