import update from 'immutability-helper';
import { mapKeys, identity } from 'lodash-es';
import { createSelector } from 'reselect';
import doLoadDisposalSiteLocations from '../services/loadDisposalSiteLocations';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// Actions
const START_LOAD = 'common/disposalSiteLocations/START_LOAD';
const COMPLETE_LOAD = 'common/disposalSiteLocations/COMPLETE_LOAD';
const FAIL_LOAD = 'common/disposalSiteLocations/FAIL_LOAD';
const RESET = 'common/disposalSiteLocations/RESET';

interface DisposalSiteLocationsState {
  isLoading: boolean;
  disposalSiteLocations?: any[];
}

// Initial state
const initialState: DisposalSiteLocationsState = {
  isLoading: false,
  disposalSiteLocations: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction): DisposalSiteLocationsState => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          disposalSiteLocations: action.disposalSiteLocations,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          disposalSiteLocations: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (disposalSiteLocations: any[]) => ({
  type: COMPLETE_LOAD,
  disposalSiteLocations,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadDisposalSiteLocations = (vendorId: number) => (
  dispatch: ThunkDispatch<DisposalSiteLocationsState, any, AnyAction>,
) => {
  dispatch(startLoad());
  const loadDisposalSiteLocationsPromise = doLoadDisposalSiteLocations(vendorId);
  loadDisposalSiteLocationsPromise
    .then(disposalSiteLocations => dispatch(completeLoad(disposalSiteLocations)))
    .catch(() => dispatch(failLoad()));
  return loadDisposalSiteLocationsPromise;
};

export const resetDisposalSiteLocations = () => ({
  type: RESET,
});

// Selectors
const getDisposalSiteLocationsById = (disposalSiteLocationsState: DisposalSiteLocationsState) =>
  mapKeys(disposalSiteLocationsState.disposalSiteLocations, 'id');

export const disposalSiteLocationsByIdSelector = createSelector(getDisposalSiteLocationsById, identity);
