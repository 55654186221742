import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';

import { AppState } from '../../store';
import { Dropdown } from '../../core/components';
import translate from '../../core/services/translate';
import { DropDownFieldProps } from './DropDownFieldProps';

// todo: type this
type EquipmentCondition = any;

interface Props extends DropDownFieldProps {
  equipmentConditions: EquipmentCondition[];
}

class EquipmentConditionDropdown extends PureComponent<Props> {
  onChange = (event: any, value: string) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      equipmentConditions,
      dropdownProps,
    } = this.props;

    const equipmentConditionsOptions = equipmentConditions?.map(equipmentCondition => ({
      label: equipmentCondition.name,
      value: equipmentCondition.id,
    }));

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('common.equipmentCondition') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('common.equipmentCondition') : undefined)}
        component={Dropdown as any}
        options={equipmentConditionsOptions}
        onChange={this.onChange}
        {...dropdownProps}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  equipmentConditions: state.common.equipmentConditions.equipmentConditions as any as EquipmentCondition[],
});

export default connect(mapStateToProps)(EquipmentConditionDropdown);
