import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import { Resolver } from '../../../core/components';
import { PageLoading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { loadBusinessProfile, loadBusinessType, loadHoursOfOperation, loadCertifications } from '../../ducks';
import { currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import OverviewPage from './OverviewPage';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';

interface Props {
  gusId?: string;
  loadBusinessProfile(...args: any[]): any;
  loadBusinessType(...args: any[]): any;
  loadCertifications(...args: any[]): any;
  loadHoursOfOperation(...args: any[]): any;
}

class OverviewPageResolver extends PureComponent<Props> {
  loadDependecies = () => {
    const { loadBusinessProfile, loadCertifications, loadHoursOfOperation, gusId } = this.props;
    return Promise.all([
      loadBusinessProfile(gusId).then(() => {
        loadCertifications(gusId);
        loadHoursOfOperation(gusId);
      }),
    ]);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('haulerProfile.companyInfo')}</DocumentTitle>
        <Resolver resolve={this.loadDependecies} successComponent={OverviewPage} loadingComponent={PageLoading} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  gusId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadBusinessProfile,
  loadBusinessType,
  loadCertifications,
  loadHoursOfOperation,
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPageResolver);
