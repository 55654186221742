import { http } from '../../core/services/http';
import { SOSAlertSettings } from '../interfaces/SOSAlertSettings';

export const loadSOSAlertSettings = (vendorId: number) =>
  http.get<SOSAlertSettings>(`/vendors/${vendorId}/sosAlertSetting`).then(response => response.data);

export const saveSOSAlertSettings = (sosAlertSettings: SOSAlertSettings) =>
  http
    .put<SOSAlertSettings>(`/vendors/${sosAlertSettings.vendorId}/sosAlertSetting`, sosAlertSettings)
    .then(response => response.data);
