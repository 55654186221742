import React from 'react';
import { AccentTextSection } from '../styled';

const getRichText = (string: string, regex: any, matchJsxCreator: any, returnMatch?: boolean) => {
  const matches: any = {};
  let hasMatches;
  let result;
  // eslint-disable-next-line no-cond-assign
  while ((result = regex.exec(string)) !== null) {
    hasMatches = true;
    if (result[1]) matches[result[1]] = result[0];
    if (result[2]) matches[result[2]] = result[0];
    if (result[3]) matches[result[3]] = result[0];
  }
  if (hasMatches) {
    return string
      .split(regex)
      .map(text => (matches[text] ? matchJsxCreator(returnMatch ? matches[text] : text) : text));
  }
  return string;
};

const boldRegex = /\*(.*?)\*/g;
export const getTextWithBoldSections = (string: string) =>
  getRichText(string, boldRegex, (m: string) => <strong key={m}>{m}</strong>);

const accentColorRegex = /`(.*?)`|&quot;(.*?)&quot;|„(.*?)“/g;
export const getTextWithAccentSections = (string: string) =>
  getRichText(string, accentColorRegex, (m: string) => (
    <AccentTextSection key={m}>
      <strong>{m}</strong>
    </AccentTextSection>
  ));

const stringWithlinksRegex = /(?:__|[*#])|\[(.*?)\]\(.*?\)/g;
const linkRegex = /\[([^[\]]+)\]\(([^)]+)/;
export const getTextWithLinks = (string: string, url?: string) =>
  getRichText(
    string,
    stringWithlinksRegex,
    (m: string) => {
      const linkMatches = m.match(linkRegex) || undefined;
      return (
        <a key={m} target="_blank" rel="noopener noreferrer" href={url || (linkMatches && linkMatches[2])}>
          {linkMatches && linkMatches[1]}
        </a>
      );
    },
    true,
  );
