import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

const getWeightTicketsFormInitialValues = (weightTicketsState: any) => ({
  weightTickets: weightTicketsState.weightTickets,
});

const weightTicketsFormInitialValuesSelector = createSelector(getWeightTicketsFormInitialValues, identity);

export default weightTicketsFormInitialValuesSelector;
