import { mapKeys } from 'lodash-es';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from 'src/core/components';
import { ModalSection, ModalTitle } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { saveFuelingTicketsSettings } from 'src/vendors/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { FuelingTicketsSettingsForm } from '../forms';
import { FuelTicketSettingItem } from 'src/routes/interfaces/FuelTicket';

interface Props {
  closeModal: (pristine: boolean) => void;
}

const FuelingTicketsSettingsModal = ({ closeModal }: Props) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const fuelingTicketsSettings = useSelector(state => state.vendors.fuelingTicketsSettings.fuelingTicketsSettings);

  const initialValues = useMemo(() => {
    return mapKeys(fuelingTicketsSettings, ({ vehicleTypeId }) => vehicleTypeId);
  }, [fuelingTicketsSettings]);

  const onSubmit = (fuelingTicketsSettings: FuelTicketSettingItem[]) => {
    saveFuelingTicketsSettings(
      vendorId as number,
      fuelingTicketsSettings,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.fuelingTicketsSettingSaved'));
        closeModal(true);
      })
      .catch(({ code }) =>
        createErrorNotification(
          code
            ? translate(createTranslationKey(code, 'vendors.alertMessages'))
            : translate('vendors.alertMessages.fuelingTicketsSettingError'),
        ),
      );
  };

  return (
    <Modal padding="no" size="small">
      <ModalSection padding="medium no no">
        <ModalTitle>{translate('vendors.featureCodes.fuelTickets')}</ModalTitle>
      </ModalSection>
      <ModalSection>
        <FuelingTicketsSettingsForm onCancel={closeModal} onSubmit={onSubmit} initialValues={initialValues} />
      </ModalSection>
    </Modal>
  );
};
export default FuelingTicketsSettingsModal;
