import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadContainersList as doLoadContainersForMapLocation } from '../services/containersList';
import { ContainerNewSimplified } from '../interfaces/containers';

// Actions
const START_LOAD = 'fleet/containers/containersForMapLocation/START_LOAD';
const COMPLETE_LOAD = 'fleet/containers/containersForMapLocation/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/containers/containersForMapLocation/FAIL_LOAD';
const RESET = 'fleet/containers/containersForMapLocation/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  containersForMapLocation: ContainerNewSimplified[];
} = {
  isLoading: false,
  containersForMapLocation: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containersForMapLocation: action.containersForMapLocation,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containersForMapLocation: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (containersForMapLocation: ContainerNewSimplified[]) => ({
  type: COMPLETE_LOAD,
  containersForMapLocation,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadContainersForMapLocation =
  (
    vendorId: number,
    containerTypeId?: number,
    equipmentTypeId?: number,
    equipmentSizeId?: number,
    equipmentConditionId?: number,
    searchTerm?: string,
    locationId?: number,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadContainersListPromise = doLoadContainersForMapLocation(
      vendorId,
      containerTypeId,
      equipmentTypeId,
      equipmentSizeId,
      equipmentConditionId,
      searchTerm,
      locationId,
    );
    loadContainersListPromise
      .then((containersForMapLocation: ContainerNewSimplified[]) => dispatch(completeLoad(containersForMapLocation)))
      .catch(() => dispatch(failLoad()));
    return loadContainersListPromise;
  };

export const resetContainersForMapLocation = () => ({
  type: RESET,
});
