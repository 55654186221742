import React from 'react';

import { TableCell, TableRow } from '../../../core/components/styled';
import { UnconnectedSwitch } from '../../../core/components';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props {
  code: string;
  enabled: boolean;
  id: number;
  toggleFeatureConfiguration: (id: number, value: boolean) => void;
}

const FeatureConfigurationModalTableRow: React.FC<Props> = ({ code, enabled, id, toggleFeatureConfiguration }) => (
  <TableRow>
    <TableCell width="80%">{translate(createTranslationKey(code, 'vendors.inspectionCodes'))}</TableCell>
    <TableCell width="20%" align="right">
      <UnconnectedSwitch checked={enabled} onChange={value => toggleFeatureConfiguration(id, value)} />
    </TableCell>
  </TableRow>
);

export default FeatureConfigurationModalTableRow;
