import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { reduxForm, reset, InjectedFormProps } from 'redux-form';
// import { size } from 'lodash-es';
// import { Table } from '../../../core/components';
import { Grid, GridColumn, PanelSection, Text } from '../../../core/components/styled';
// import { updateAcceptSelectedPaymentServices } from '../../ducks';
// import RecurringServicesFormInitialValueSelector from '../../services/recurringServicesInitialValueSelector';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
// import { RecurringServicesFormTableRow } from './';

interface ComponentProps extends RouteComponentProps {}

interface PropsWithoutReduxForm extends ComponentProps {
  workOrdersInfo: any;
  pricingInfo: any;
  paymentInfo: any;
  reset: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class PricingInfoFormFooter extends PureComponent<Props> {
  render() {
    const { pricingInfo, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <PanelSection centered withBorder padding="small">
          <Grid padding="no no no no" multiLine>
            <GridColumn size="2/12" align="left">
              <PanelSection>
                <Text block size="small" weight="medium" margin="no no xxSmall">
                  {translate('payment.acceptedWoTotalAmount')}: ${pricingInfo.acceptedWOTotalAmount}
                </Text>
                {/* <Text block weight="medium" margin="no xSmall xxSmall">
                  {pricingInfo.acceptedWOTotalAmount}
                </Text> */}
              </PanelSection>
            </GridColumn>
            <GridColumn size="2/12" align="left">
              <PanelSection>
                <Text block size="small" weight="medium" margin="no no xxSmall">
                  {translate('payment.acceptedWoCount')}: {pricingInfo.acceptedWOCount}
                </Text>
                {/* <Text block weight="medium" margin="no xSmall xxSmall">
                  {pricingInfo.acceptedWOCount}
                </Text> */}
              </PanelSection>
            </GridColumn>
            <GridColumn size="2/12" align="left">
              <PanelSection>
                <Text block size="small" weight="medium" margin="no no xxSmall">
                  {translate('payment.allIssueWoCount')}: {pricingInfo.atIssueWOCount}
                </Text>
                {/* <Text block weight="medium" margin="no xSmall xxSmall">
                  {pricingInfo.atIssueWOCount}
                </Text> */}
              </PanelSection>
            </GridColumn>

            <GridColumn size="2/12" align="left">
              <PanelSection>
                <Text block size="small" weight="medium" margin="no no xxSmall">
                  {translate('payment.pendingApprovalWoCount')}: {pricingInfo.pendingApprovelWOCount}
                </Text>
                {/* <Text block weight="medium" margin="no xSmall xxSmall">
                  {pricingInfo.pendingApprovelWOCount}
                </Text> */}
              </PanelSection>
            </GridColumn>
            <GridColumn size="4/12" align="right">
              <PanelSection>
                <Text size="small" block weight="medium" margin="no no xxSmall">
                  {translate('payment.rubiconInvoiceNo')}:{pricingInfo.rubiconInvoiceNo}
                </Text>
                {/* <Text block weight="medium" margin="no xSmall xxSmall">
                  {pricingInfo.rubiconInvoiceNo}
                </Text> */}
              </PanelSection>
            </GridColumn>
            <GridColumn size="12/12" align="right">
              {/* <PanelSection> */}
              {/* <Button
                background-color="transparent"
                color="primary"
                size="small"
                onClick={this.openAcceptPaymentForSelectedRecurringModal}
                disabled={pricingInfo.acceptedWOCount <= 0}
                margin="no no no"
              >
                {translate('payment.acceptPaymentForSelected')}
              </Button>{' '}
              {this.state.isAcceptPaymentForSelectedRecurringModalOpen && (
                <AcceptPaymentForSelectedRecurringModal {...this.props} onCancel={this.closeAcceptPaymentForSelectedRecurringModal} />
              )} */}
              {/* <Text block weight="medium" margin="no xSmall xxSmall">
                  {pricingInfo.rubiconInvoiceNo}
                </Text> */}
              {/* </PanelSection> */}
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  workOrdersInfo: state.payments.recurringServices.workOrdersInfo,
  pricingInfo: state.payments.recurringServices.pricingInfo,
  paymentInfo: state.payments.recurringServices.paymentInfo,
});

const mapDispatchToProps = {
  reset,
  //   updateAcceptSelectedPaymentServices,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'pricingInfoFormFooter',
      enableReinitialize: true,
    })(PricingInfoFormFooter),
  ),
);
