import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const ACTIVE = 1;
export const INACTIVE = 0;

export const ROUTE_TEMPLATE_STATUSES_LIST = [
  { id: ACTIVE, name: translate('common.active') },
  { id: INACTIVE, name: translate('common.inactive') },
];

export const ROUTE_TEMPLATE_STATUSES = mapKeys(ROUTE_TEMPLATE_STATUSES_LIST, 'id');
