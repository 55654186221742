import { useDispatch } from 'react-redux';
import { FC, useMemo, useState } from 'react';

import { loadCustomerPaymentMethods } from 'src/customers/ducks/paymentMethods';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { PaymentManagementModal } from './PaymentManagementModal';
import { TypedResolver } from 'src/core/components';

export const PaymentManagementModalResolver: FC<{ onClose: () => void; customerId: number }> = ({
  onClose,
  customerId,
}) => {
  const dispatch = useDispatch();
  const [isAddNewPaymentMethodUnavailable, setIsAddNewPaymentMethodUnavailable] = useState(false);

  const dependency = useMemo(
    () => () =>
      loadCustomerPaymentMethods(customerId)(dispatch).catch(() => {
        setIsAddNewPaymentMethodUnavailable(true);
      }),
    [customerId, dispatch],
  );

  return (
    <TypedResolver
      resolve={dependency}
      successProps={{ customerId, isAddNewPaymentMethodUnavailable, onClose }}
      loadingComponent={PageLoadingOverlay}
      successComponent={PaymentManagementModal}
    />
  );
};
