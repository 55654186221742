import { getBaseUrl } from '../../core/services/environment';
import { http } from '../../core/services/http';
import { Vendor } from '../interfaces/Vendors';
import generateDeviceRegistrationCode from './generateDeviceRegistrationCode';

export const loadVendor = (vendorId: number) => http.get<Vendor>(`vendors/${vendorId}`).then(response => response.data);

export const createVendor = (vendor: any) =>
  generateDeviceRegistrationCode().then(deviceRegistrationCode =>
    http
      .post('vendors', { ...vendor, deviceRegistrationCode, inviteUrl: getBaseUrl() })
      .then(response => response.data),
  );  
export const updateVendor = (vendorId: number, vendor: any) =>
  http.put(`vendors/${vendorId}`, vendor).then(response => response.data);

export const checkIfVendorHasNetworkFile = (vendorId: number) =>
  http.get<boolean>(`vendors/${vendorId}/vendorHasNetworkFile`).then(response => response.data);
