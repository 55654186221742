import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { isOnDemandVideoDownloadFeatureEnabled } from 'src/vendors/ducks/features';
import { MapGLPopupWrapper, MapGLPopupLoading } from 'src/common/components/map/MapGLPopup';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import RouteMapVehicleTrackingsGLPopup from '../vehicleTrackings/RouteMapVehicleTrackingsGLPopup';

export default function RouteMapTimelineApplicationStatusGLPopupResolver() {
  const dispatch = useDispatch();

  const { vehicleTrackings, unitOfMeasure } = useSelector(state => state.routes.routeMapVehicleData);
  const { selectedFeature } = useSelector(state => state.routes.mapControls);
  const isOnDemandVideoDownloadEnabled = useSelector(isOnDemandVideoDownloadFeatureEnabled);

  const selectedTracking = useMemo(() => {
    if (
      !selectedFeature ||
      selectedFeature.feature !== RouteMapFeature.applicationModeChanges ||
      selectedFeature.vehicleTrackingApplicationStatusIndex === undefined
    ) {
      return undefined;
    }

    const { id: vehicleId, vehicleTrackingApplicationStatusIndex: statusIndex } = selectedFeature;

    const vehicleTracking = vehicleTrackings.find(vehicleTracking => vehicleTracking.vehicle.id === vehicleId);
    const status = vehicleTracking?.applicationModeChanges[statusIndex];

    if (!vehicleTracking || !status) {
      return undefined;
    }

    return {
      unitOfMeasure,
      vehicle: vehicleTracking.vehicle,
      status,
      shouldDisplayDownloadVideo: vehicleTracking.vehicle.shouldDisplayDownloadVideo,
    };
  }, [selectedFeature, vehicleTrackings, unitOfMeasure]);

  if (!selectedTracking) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedTracking.status.latitude}
      longitude={selectedTracking.status.longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <TypedResolver
        key={`${selectedFeature!.id}.${selectedFeature!.vehicleTrackingApplicationStatusIndex}`}
        successComponent={RouteMapVehicleTrackingsGLPopup}
        successProps={{
          applicationModeStatusId: selectedTracking.status.applicationModeStatusId,
          shouldDisplayDownloadVideo: selectedTracking.shouldDisplayDownloadVideo && isOnDemandVideoDownloadEnabled,
          speed: selectedTracking.status.speed,
          timestamp: selectedTracking.status.timestamp,
          unitOfMeasure: selectedTracking.unitOfMeasure,
          vehicleId: selectedTracking.vehicle.id,
          vehicleName: selectedTracking.vehicle.name,
        }}
        loadingComponent={MapGLPopupLoading}
        loadingProps={{ minHeight: 240 }}
      />
    </MapGLPopupWrapper>
  );
}
