import { omit } from 'lodash-es';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { linkFuelTicketImage, loadFuelTickets, saveFuelTicket, uploadFuelTicketImage } from 'src/routes/ducks';
import { FuelTicket } from 'src/routes/interfaces/FuelTicket';
import { date } from 'src/utils/services/formatter';
import FuelTicketEditorForm, { FuelTicketEditorFormValues } from '../forms/FuelTicketEditorForm';

const validateFuelTicket = (fuelTicket: FuelTicket) => {
  const { fuelCost, locationId, fuelAmount, unitOfMeasureTypeId } = fuelTicket;
  return !!fuelCost && fuelCost > 0 && !!locationId && !!fuelAmount && fuelAmount > 0 && !!unitOfMeasureTypeId;
};

type Props = {
  onClose: (formPristine?: boolean) => void;
  routeId: number;
  fuelTicketId?: number;
};

const FuelTicketEditorModal = ({ onClose, routeId, fuelTicketId }: Props) => {
  const { isSavingFuelTicket, isUploadingFuelTicketImage, isLinkingFuelTicketImage, fuelTicket } = useSelector(
    state => state.routes.fuelTickets,
  );
  const routeDate = useSelector(state => state.routes.route.route?.routeDate);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  const [fileName, setFileName] = useState(undefined);
  const [uploadedImageId, setUploadedImageId] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(undefined);

  const isEditMode = fuelTicketId ? true : false;
  const dispatch = useDispatch();

  const onImageUpload = (files: any) => {
    if (files.length > 0) {
      uploadFuelTicketImage(
        files[0],
        routeId,
      )(dispatch)
        .then(async response => {
          setUploadedImageId(response[0].id);
          setUploadedImageUrl(response[0].imageUrl);
          setFileName(response[0].imageFileName);
          createSuccessNotification(translate('common.alertMessages.uploadCompleted'));
        })
        .catch(() => {
          createErrorNotification(translate('common.alertMessages.invalidImageFormat'));
        });
    }
  };

  const onClearFileName = () => {
    setFileName(undefined);
    setUploadedImageUrl(undefined);
    setUploadedImageId(null);
  };

  const refreshFuelTickets = () => {
    loadFuelTickets(routeId)(dispatch);
  };

  const handleSubmitFuelTicket = (fuelTicketValues: FuelTicketEditorFormValues) => {
    const { fuelingDate, fuelingTime } = fuelTicketValues;
    const fuelingDateTimeLocal = moment(fuelingDate, 'MM/DD/YYYY')
      .set({
        hour: Number(fuelingTime.split(':')[0]),
        minute: Number(fuelingTime.split(':')[1]),
      })
      .utc(true)
      .format();

    let fuelTicketToSave: FuelTicket;

    if (!fuelTicketId) {
      fuelTicketToSave = {
        ...omit(fuelTicketValues, ['fuelingDate', 'fuelingTime']),
        routeId,
        fuelingDateTimeLocal,
      };
    } else {
      fuelTicketToSave = {
        ...fuelTicket,
        ...omit(fuelTicketValues, ['fuelingDate', 'fuelingTime']),
        fuelingDateTimeLocal,
      };
    }

    if (uploadedImageId && uploadedImageUrl) {
      fuelTicketToSave.image = {
        url: uploadedImageUrl,
      };
    }

    if (!validateFuelTicket(fuelTicketToSave)) {
      createErrorNotification(translate('routes.alertMessages.weightTicketValidationFailed'));
      return;
    }

    saveFuelTicket(
      routeId,
      fuelTicketToSave,
    )(dispatch)
      .then(response => {
        const id = isEditMode ? fuelTicketId : response;
        if (uploadedImageId) {
          linkFuelTicketImage(
            uploadedImageId,
            id,
          )(dispatch).then(() => {
            refreshFuelTickets();
          });
        } else {
          refreshFuelTickets();
        }
        createSuccessNotification(translate('routes.alertMessages.fuelTicketSaved'));
        onClose(true);
      })
      .catch(e => {
        createErrorNotification(translate('routes.alertMessages.fuelTicketSaveError'));
      });
  };

  const initialValues = useMemo(() => {
    if (fuelTicketId && fuelTicket) {
      const { fuelingDateTimeLocal } = fuelTicket;

      return {
        ...fuelTicket,
        fuelingDate: date(fuelingDateTimeLocal),
        fuelingTime: moment(fuelingDateTimeLocal).format('HH:mm'),
      };
    } else
      return {
        fuelingDate: date(routeDate),
        fuelingTime: moment().format('HH:mm'),
        vehicleId: routeSummary?.vehicleId,
        driverId: routeSummary?.driverId,
      };
  }, [fuelTicket, fuelTicketId, routeDate, routeSummary?.driverId, routeSummary?.vehicleId]);

  return (
    <Modal
      size="large"
      verticalSize="small"
      isLoading={isSavingFuelTicket || isUploadingFuelTicketImage || isLinkingFuelTicketImage}
      onClose={onClose}
      title={translate(`routes.${isEditMode ? 'edit' : 'add'}FuelTicket`)}
    >
      <FuelTicketEditorForm
        onClose={onClose}
        onImageUpload={onImageUpload}
        onClearFileName={onClearFileName}
        selectedFileName={fileName}
        initialValues={initialValues}
        onSubmit={handleSubmitFuelTicket}
      />
    </Modal>
  );
};

export default FuelTicketEditorModal;
