import { map } from 'lodash-es';

const transformSearchDashboardVehicles = (customers: any) =>
  map(customers, customer => {
    const label = customer.customerName;
    const options = map(customer.locations, location => ({
      label: location.locationName,
      value: { ...location, vehicleId: location.vehicle.id, vehicleType: location.vehicle.technicalName },
    }));

    return { label, options };
  });

export default transformSearchDashboardVehicles;
