import React, { Fragment } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { ButtonSet, Button } from '../../../core/components/styled';
import { Input, DatePicker, Checkbox, NavigationPrompt } from '../../../core/components';
import { isDateValidValidator, isRequired } from '../../../utils/services/validator';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

type Props = RouteComponentProps & InjectedFormProps<any, RouteComponentProps>;

const TrackingSettingsForm: React.FC<Props> = ({ handleSubmit, pristine }) => (
  <Fragment>
    <NavigationPrompt when={!pristine} />
    <form onSubmit={handleSubmit} noValidate>
      <Field
        name="releaseDate"
        component={DatePicker}
        label={translate('settings.tracking.releaseDate')}
        validate={[isRequired, isDateValidValidator]}
        disabled
        isClearable
      />

      <Field
        name="distance"
        component={Input}
        label={translate('settings.tracking.registerFrequency')}
        type="number"
        disabled
        validate={[isRequired]}
      />

      <Field
        name="timeInterval"
        component={Input}
        label={translate('settings.tracking.submitFrequency')}
        type="number"
        disabled
        validate={[isRequired]}
      />

      <Field
        name="stationaryWaitTimeInSeconds"
        component={Input}
        label={translate('settings.tracking.stationaryWaitTime')}
        type="number"
        disabled
        validate={[isRequired]}
      />

      <Field
        name="waitConfirmationViewInSeconds"
        margin="no no large"
        component={Input}
        label={translate('settings.tracking.confirmationViewWaitTime')}
        type="number"
        disabled
        validate={[isRequired]}
      />

      <Field
        block
        name="useGyroscope"
        component={Checkbox}
        label={translate('settings.tracking.trackGyroscope')}
        margin="no no small"
        disabled
      />

      <Field
        block
        name="useAccelerometer"
        component={Checkbox}
        label={translate('settings.tracking.trackAccelerometer')}
        disabled
      />

      <ButtonSet margin="large no no">
        {!pristine && (
          <Button type="submit" color="primary">
            {translate('common.save')}
          </Button>
        )}
      </ButtonSet>
    </form>
  </Fragment>
);

export default withRouter(
  reduxForm<any, RouteComponentProps>({
    form: 'trackingSettings',
    onSubmitFail: focusFirstInvalidField,
  })(TrackingSettingsForm),
);
