import { AnyAction, Dispatch } from 'redux';
import { DriverInspectionDetails } from '../interfaces/DriverInspecitonDetails';
import { DriverInspectionLog } from '../interfaces/DriverInspectionLog';
import { doLoadDriverInspectionLogs, doLoadDriverInspectionDetails } from '../services/driverInspection';

// Constants
const START_LOAD_DRIVER_INSPECTION_LOGS = 'fleet/driverInspectionLogs/START_LOAD_DRIVER_INSPECTION_LOGS';
const FAIL_LOAD_INSPECTION_LOGS = 'fleet/driverInspectionLogs/FAIL_LOAD_INSPECTION_LOGS';
const COMPLETE_LOAD_INSPECTION_LOGS = 'fleet/driverInspectionLogs/COMPLETE_LOAD_INSPECTION_LOGS';
const START_LOAD_DRIVER_INSPECTION_DETAILS = 'fleet/driverInspectionDetails/START_LOAD_DRIVER_INSPECTION_DETAILS';
const FAIL_LOAD_INSPECTION_DETAILS = 'fleet/driverInspectionDetails/FAIL_LOAD_INSPECTION_DETAILS';
const COMPLETE_LOAD_INSPECTION_DETAILS = 'fleet/driverInspectionDetails/COMPLETE_LOAD_INSPECTION_DETAILS';

// InitialState
const initialState: any = {
  isLoading: false,
  driverInspectionLogs: [],
  isExporting: false,
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_DRIVER_INSPECTION_LOGS:
      return {
        ...state,
        isLoading: true,
      };
    case FAIL_LOAD_INSPECTION_LOGS:
      return {
        ...state,
        isLoading: false,
        driverInspectionLogs: [],
      };
    case COMPLETE_LOAD_INSPECTION_LOGS:
      return {
        ...state,
        isLoading: false,
        driverInspectionLogs: action.driverInspectionLogs,
      };
    case START_LOAD_DRIVER_INSPECTION_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case FAIL_LOAD_INSPECTION_DETAILS:
      return {
        ...state,
        isLoading: false,
        driverInspectionDetails: null,
      };
    case COMPLETE_LOAD_INSPECTION_DETAILS:
      return {
        ...state,
        isLoading: false,
        driverInspectionDetails: action.driverInspectionDetails,
      };

    default:
      return state;
  }
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD_DRIVER_INSPECTION_LOGS,
});

const completeLoad = (driverInspectionLogs: DriverInspectionLog[]) => ({
  type: COMPLETE_LOAD_INSPECTION_LOGS,
  driverInspectionLogs,
});
const failLoad = () => ({
  type: FAIL_LOAD_INSPECTION_LOGS,
});

const startDetailsLoad = () => ({
  type: START_LOAD_DRIVER_INSPECTION_DETAILS,
});

const completeDetailsLoad = (driverInspectionDetails: DriverInspectionDetails) => ({
  type: COMPLETE_LOAD_INSPECTION_DETAILS,
  driverInspectionDetails,
});
const failDetailsLoad = () => ({
  type: FAIL_LOAD_INSPECTION_DETAILS,
});

// Load Driver Inspection Logs
export const loadDriverInspectionLogs =
  (vendorId: number, driverId: number, startDate: string, endDate: string) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    return doLoadDriverInspectionLogs(vendorId, driverId, startDate, endDate)
      .then(driverInspectionLogs => dispatch(completeLoad(driverInspectionLogs)))
      .catch(() => dispatch(failLoad()));
  };

// Load Driver Inspection Details/Summery
export const loadDriverInspectionDetails =
  (vendorId: number, driverSafetyInspectionId: number) => (dispatch: Dispatch) => {
    dispatch(startDetailsLoad());
    return doLoadDriverInspectionDetails(vendorId, driverSafetyInspectionId)
      .then(driverInspectionDetails => dispatch(completeDetailsLoad(driverInspectionDetails)))
      .catch(() => dispatch(failDetailsLoad()));
  };
// Export Driver Inspection Log
