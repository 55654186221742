import React, { useEffect, useMemo } from 'react';
import { Source, Layer } from 'react-map-gl';
import { useDispatch } from 'react-redux';

import { FeaturePoint } from '../FeaturePoint';
import { getFeatureCollection, getLineString } from '../util';
import { MarkerSourceComponentProps } from './MarkerSourceComponentProps';
import {
  StreetServicingSideConfiguration,
  VehicleSettings,
  VehicleTrackingRaw,
} from '../../../../routes/components/mapWithTimeline/Interfaces';
import { useInteractiveLayers } from '../hooks/useInteractiveLayers';
import { TrapezoidPoint } from '../Trapezoid';
import { DataSourceIdsGL } from '../DataSourceIdsGL';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import RouteMapDebugVehicleTrackingsGLPopup from 'src/routes/components/pages/routes/routePageSections/routeMap/vehicleTrackings/RouteMapDebugVehicleTrackingsGLPopup';

const getCoordinatesAndFeaturePoints = (
  rawVehicleTrackings: VehicleTrackingRaw[],
  uniqueKey: string,
  vehicleId?: number,
) => {
  const coordinates: number[][] = [];
  const featurePoints: GeoJSON.Feature<any>[] = [];

  rawVehicleTrackings.forEach((rvt, i) => {
    const featurePoint = new FeaturePoint(rvt.latitude, rvt.longitude);
    featurePoint.properties = {
      type: uniqueKey,
      vehicleId: rvt.vehicleId || vehicleId,
      coordinateGroup: {
        endTimestamp: rvt.reportedDate,
        speed: Math.round(rvt.vehicleSpeed),
        applicationModeStatusId: rvt.applicationModeStatusId,
      },
    };
    featurePoints.push(featurePoint);
    coordinates.push([rvt.longitude, rvt.latitude]);
  });
  return {
    featureCollection: getFeatureCollection(featurePoints),
    lineString: getLineString(coordinates),
  };
};

const getTrapezoids = (
  trapezoids: VehicleTrackingRaw[],
  trapezoidConfiguration: StreetServicingSideConfiguration,
  trapezoidFilters: number[],
) => {
  const trapezoidCoordinates: GeoJSON.Feature<any>[] = [];
  const reducedTrapezoidCoordinates: GeoJSON.Feature<any>[] = [];

  trapezoids.forEach((rvt, index) => {
    if (index >= trapezoidFilters[0] && index <= trapezoidFilters[1]) {
      trapezoidCoordinates.push(new TrapezoidPoint(rvt, trapezoidConfiguration, false));
      reducedTrapezoidCoordinates.push(new TrapezoidPoint(rvt, trapezoidConfiguration, true));
    }
  });

  return {
    trapezoidPoints: getFeatureCollection(trapezoidCoordinates),
    reducedTrapezoidPoints: getFeatureCollection(reducedTrapezoidCoordinates),
  };
};

interface Props extends MarkerSourceComponentProps<VehicleTrackingRaw> {
  color: string;
  uniqueKey: string;
  showConfirmationPolygon: boolean;
  vehicleConfiguration: VehicleSettings;
  trapezoids?: VehicleTrackingRaw[];
  trapezoidFilters: number[];
  map: mapboxgl.Map;
  vehicleId?: number;
}

export const DebugVehicleTrackingMarkerSource: React.FC<Props> = ({
  markers,
  onAddInteractiveLayers,
  trapezoidFilters,
  trapezoids,
  color,
  uniqueKey,
  vehicleConfiguration,
  showConfirmationPolygon,
  map,
  vehicleId,
}) => {
  const uniquePointKey = DataSourceIdsGL.debugVehicleTrackings.point + uniqueKey;
  const uniqueLineKey = DataSourceIdsGL.debugVehicleTrackings.line + uniqueKey;
  const uniquePolygonKey = DataSourceIdsGL.debugVehicleTrackings.polygon + uniqueKey;
  const uniqueReducedPolygonKey = DataSourceIdsGL.debugVehicleTrackings.polygonReduced + uniqueKey;
  const dispatch = useDispatch();

  useEffect(() => {
    map.on('click', event => {
      const [feature] = map
        .queryRenderedFeatures(event.point)
        .filter(f => f.layer.id === uniquePointKey || f.layer.id === uniqueLineKey);
      if (feature) {
        const { vehicleId, coordinateGroup } = feature.properties as { vehicleId: number; coordinateGroup: string };
        dispatch(
          setRouteMapSelectedFeature(RouteMapFeature.vehicleDebugTrackings, vehicleId, {
            coordinates: event.lngLat,
            coordinateGroup,
          }),
        );
      }
    });
  }, [map, dispatch, uniquePointKey, uniqueLineKey]);

  const interactiveLayers = useMemo(() => [uniquePointKey], [uniquePointKey]);

  useInteractiveLayers(interactiveLayers, uniquePointKey, onAddInteractiveLayers);

  const { featureCollection, lineString } = useMemo(
    () => getCoordinatesAndFeaturePoints(markers, uniqueLineKey, vehicleId),
    [markers, uniqueLineKey, vehicleId],
  );

  const { trapezoidPoints, reducedTrapezoidPoints } = useMemo(() => {
    if (vehicleConfiguration) {
      const confirmationMode = vehicleConfiguration.confirmationModes.find(
        cm => cm.id === vehicleConfiguration.confirmationModeId,
      );
      if (confirmationMode) {
        const polygonSettings = confirmationMode.streetServicingSides.find(
          ss => ss.id === vehicleConfiguration.streetServicingSideId,
        );
        if (polygonSettings && trapezoids) {
          return getTrapezoids(trapezoids, polygonSettings, trapezoidFilters);
        }
      }
    }
    return { trapezoidPoints: null, reducedTrapezoidPoints: null };
  }, [trapezoids, vehicleConfiguration, trapezoidFilters]);

  return (
    <>
      <Source id={uniqueLineKey} type="geojson" data={lineString as any}>
        <Layer
          id={uniqueLineKey}
          type="line"
          paint={
            {
              'line-color': color,
            } as any
          }
        />
      </Source>

      <Source id={uniquePointKey} type="geojson" data={featureCollection as any}>
        <Layer
          id={uniquePointKey}
          type="circle"
          paint={{
            'circle-radius': {
              stops: [
                [1, 1],
                [10, 1],
                [17, 4],
                [50, 40],
              ],
            },
            'circle-color': color,
          }}
        />
      </Source>

      {trapezoidPoints && showConfirmationPolygon && (
        <Source id={uniquePolygonKey} type="geojson" data={trapezoidPoints}>
          <Layer
            id={uniquePolygonKey}
            type="line"
            paint={
              {
                'line-color': '#8000FF',
              } as any
            }
          />
        </Source>
      )}

      {reducedTrapezoidPoints && showConfirmationPolygon && (
        <Source id={uniqueReducedPolygonKey} type="geojson" data={reducedTrapezoidPoints}>
          <Layer
            id={uniqueReducedPolygonKey}
            type="line"
            paint={
              {
                'line-color': '#B00B1E',
              } as any
            }
          />
        </Source>
      )}
      <RouteMapDebugVehicleTrackingsGLPopup />
    </>
  );
};
