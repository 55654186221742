import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { loadRateCodeTypes } from 'src/common/ducks';
import { loadRateTypesByWorkOrder, loadRateTypesByServiceContractId } from 'src/customers/ducks/service';
import { ModalProps } from 'src/core/components/Modal';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { Rate } from 'src/finance/interfaces/ServiceRates';
import { RateCodeFormValues } from './RateCodeModal';
import { RateCodeModal } from '.';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { TypedResolver } from 'src/core/components';
import * as Ducks from 'src/finance/ducks/serviceRates';

interface Props extends ModalProps {
  closeModal: () => void;
  formProps: {
    customerName?: string | undefined;
    location?: string | undefined;
    workOrderId?: number | string;
    workOrderDate?: StringOrDate;
  };
  headerId: number;
  initialValues: { rates: Rate[] };
  isBillReleased: boolean;
  onSubmit: (data: RateCodeFormValues) => void;
  serviceContractId: number;
  workOrderId: number;
}

const RateCodeModalResolver: FC<Props> = ({
  closeModal,
  formProps,
  headerId,
  initialValues,
  isBillReleased,
  onSubmit,
  serviceContractId,
  workOrderId,
}) => {
  const dispatch = useDispatch();

  const loadDependencies = async () => {
    const dependencies: Promise<unknown>[] = [];
    dependencies.push(Ducks.loadServiceRates(headerId)(dispatch), Ducks.loadUOMs()(dispatch));

    if (workOrderId) dependencies.push(loadRateTypesByWorkOrder(workOrderId)(dispatch));
    if (serviceContractId && !workOrderId)
      dependencies.push(loadRateTypesByServiceContractId(serviceContractId)(dispatch));
    if (!workOrderId && !serviceContractId) dependencies.push(loadRateCodeTypes()(dispatch));

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      successComponent={RateCodeModal}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      redirectOnError="/finance/billing"
      successProps={{ closeModal, formProps, initialValues, isBillReleased, onSubmit }}
    />
  );
};

export default RateCodeModalResolver;
