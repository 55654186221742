import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import translate from 'src/core/services/translate';
import { TravelPathProperties, TravelPathStatusDetails } from '../interfaces/TravelPath';
import {
  getTravelPathGeoJsonFile as doGetTravelPathGeoJsonFile,
  getTravelPathGeoJsonFileForTemplate as doGetTravelPathGeoJsonFileForTemplate,
  loadTravelPathStatusDetails as doLoadTravelPathStatusDetails,
  loadTravelPathStatusDetailsForTemplate as doLoadTravelPathStatusDetailsForTemplate,
  triggerTravelPath as doTriggerTravelPath,
  triggerTravelPathForTemplate as doTriggerTravelPathForTemplate,
} from '../services/travelPath';
import { createErrorNotification } from 'src/core/services/createNotification';
// Actions
const START_TRIGGER = 'routes/travelPath/START_TRIGGER';
const COMPLETE_TRIGGER = 'routes/travelPath/COMPLETE_TRIGGER';
const FAIL_TRIGGER = 'routes/travelPath/FAIL_TRIGGER';

const START_LOAD = 'routes/travelPath/START_LOAD';
const COMPLETE_LOAD = 'routes/travelPath/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/travelPath/FAIL_LOAD';

const START_DOWNLOAD_GEOJSON_FILE = 'routes/travelPath/START_DOWNLOAD_GEOJSON_FILE';
const COMPLETE_DOWNLOAD_GEOJSON_FILE = 'routes/travelPath/COMPLETE_DOWNLOAD_GEOJSON_FILE';
const FAIL_DOWNLOAD_GEOJSON_FILE = 'routes/travelPath/FAIL_DOWNLOAD_GEOJSON_FILE';

const START_UNLOCK_EDITING = 'routes/travelPath/START_UNLOCK_EDITING';
const COMPLETE_UNLOCK_EDITING = 'routes/travelPath/COMPLETE_UNLOCK_EDITING';
const FAIL_UNLOCK_EDITING = 'routes/travelPath/FAIL_UNLOCK_EDITING';

const SET_SHOW_TRAVEL_PATH = 'routes/travelPath/SET_SHOW_TRAVEL_PATH';

const SET_IS_TRAVEL_PATH_LEGEND_OPEN = 'routes/travelPath/SET_IS_TRAVE_PATH_LEGEND_OPEN';

const RESET = 'routes/travelPath/RESET';
const RESET_FOR_BUILD_OR_EDIT = 'routes/travelPath/RESET_FOR_BUILD_OR_EDIT';

interface State {
  isLoadingTravelPathStatus: boolean;
  isTriggeringTravelPath: boolean;
  isDownloadingGeoJsonFile: boolean;
  travelPathData: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, TravelPathProperties> | null;
  travelPathDataForBuildOrEdit: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, TravelPathProperties> | null;
  travelPathError: string;
  showTravelPath: boolean;
  routeId: number | null;
  routeIdForBuildOrEdit: number | null;
  routeTemplateId: number | null;
  routeTemplateIdForBuildOrEdit: number | null;
  travelPathStatusDetails: TravelPathStatusDetails | null;
  isTravelPathLegendOpen: boolean;
}

// Initial state
const initialState = {
  isLoadingTravelPathStatus: false,
  isTriggeringTravelPath: false,
  isDownloadingGeoJsonFile: false,
  showTravelPath: false,
  travelPathData: null,
  travelPathDataForBuildOrEdit: null,
  travelPathError: '',
  routeId: null,
  routeTemplateId: null,
  routeIdForBuildOrEdit: null,
  routeTemplateIdForBuildOrEdit: null,
  travelPathStatusDetails: null,
  isTravelPathLegendOpen: false,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoadingTravelPathStatus: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoadingTravelPathStatus: false,
          travelPathStatusDetails: action.travelPathStatusDetails,
          travelPathData: action.travelPathStatusDetails?.inProgress ? null : state.travelPathData,
          routeId: action.travelPathStatusDetails?.inProgress ? null : state.routeId,
          routeTemplateId: action.travelPathStatusDetails?.inProgress ? null : state.routeTemplateId,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoadingTravelPathStatus: false,
          travelPathStatusDetails: null,
        },
      });

    case START_UNLOCK_EDITING:
      return update(state, {
        $merge: {
          isLoadingTravelPathStatus: true,
        },
      });

    case COMPLETE_UNLOCK_EDITING:
      return update(state, {
        $merge: {
          isLoadingTravelPathStatus: false,
          travelPathStatusDetails: action.travelPathStatusDetails, // ask API to send the status details in unlock endpoint response
          travelPathData: action.travelPathStatusDetails?.inProgress ? null : state.travelPathData,
          routeId: action.travelPathStatusDetails?.inProgress ? null : state.routeId,
          routeTemplateId: action.travelPathStatusDetails?.inProgress ? null : state.routeTemplateId,
        },
      });

    case FAIL_UNLOCK_EDITING:
      return update(state, {
        $merge: {
          isLoadingTravelPathStatus: false,
          travelPathStatusDetails: null,
        },
      });

    case START_TRIGGER:
      return update(state, {
        $merge: {
          isTriggeringTravelPath: true,
          travelPathError: '',
        },
      });

    case COMPLETE_TRIGGER:
      return update(state, {
        $merge: {
          isTriggeringTravelPath: false,
        },
      });

    case FAIL_TRIGGER:
      return update(state, {
        $merge: {
          isTriggeringTravelPath: false,
          travelPathData: null,
          routeId: null,
          routeTemplateId: null,
        },
      });

    case START_DOWNLOAD_GEOJSON_FILE:
      return update(state, {
        $merge: {
          isDownloadingGeoJsonFile: true,
          travelPathError: '',
        },
      });

    case COMPLETE_DOWNLOAD_GEOJSON_FILE:
      if (action.isForBuildOrEdit)
        return update(state, {
          $merge: {
            isDownloadingGeoJsonFile: false,
            travelPathDataForBuildOrEdit: action.data,
            routeIdForBuildOrEdit: action.routeId,
            routeTemplateIdForBuildOrEdit: action.routeTemplate,
          },
        });
      else
        return update(state, {
          $merge: {
            isDownloadingGeoJsonFile: false,
            travelPathData: action.data,
            routeId: action.routeId,
            routeTemplateId: action.routeTemplate,
          },
        });

    case FAIL_DOWNLOAD_GEOJSON_FILE:
      return update(state, {
        $merge: {
          isDownloadingGeoJsonFile: false,
          travelPathData: null,
          routeId: null,
          routeTemplateId: null,
          showTravelPath: false,
          travelPathError: action.error,
        },
      });

    case SET_SHOW_TRAVEL_PATH:
      return update(state, {
        $merge: {
          showTravelPath: action.showTravelPath,
        },
      });

    case SET_IS_TRAVEL_PATH_LEGEND_OPEN:
      return update(state, {
        $merge: {
          isTravelPathLegendOpen: action.isTravelPathLegendOpen,
        },
      });

    case RESET:
      return initialState;

    case RESET_FOR_BUILD_OR_EDIT:
      return update(state, {
        $merge: {
          travelPathDataForBuildOrEdit: null,
          routeIdForBuildOrEdit: null,
          routeTemplateIdForBuildOrEdit: null,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (travelPathStatusDetails: TravelPathStatusDetails) => ({
  type: COMPLETE_LOAD,
  travelPathStatusDetails,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startTrigger = () => ({
  type: START_TRIGGER,
});

const completeTrigger = () => ({
  type: COMPLETE_TRIGGER,
});

const failTrigger = () => ({
  type: FAIL_TRIGGER,
});

const startDownloadGeoJsonFile = () => ({
  type: START_DOWNLOAD_GEOJSON_FILE,
});

const completeDownloadGeoJsonFile = (
  data: any,
  routeId?: number,
  routeTemplate?: number,
  isForBuildOrEdit?: boolean,
) => ({
  type: COMPLETE_DOWNLOAD_GEOJSON_FILE,
  data,
  routeId,
  routeTemplate,
  isForBuildOrEdit,
});

const failDownloadGeoJsonFile = (error?: string) => ({
  type: FAIL_DOWNLOAD_GEOJSON_FILE,
  error,
});

export const resetTravelPathDetails = () => ({
  type: RESET,
});

export const setShowTravelPath = (showTravelPath: boolean) => ({
  type: SET_SHOW_TRAVEL_PATH,
  showTravelPath,
});

export const setIsTravelPathLegendOpen = (isTravelPathLegendOpen: boolean) => ({
  type: SET_IS_TRAVEL_PATH_LEGEND_OPEN,
  isTravelPathLegendOpen,
});

export const resetTravelPathDetailsForBuildOrEdit = () => ({
  type: RESET_FOR_BUILD_OR_EDIT,
});

// Thunks
export const loadTravelPathStatusDetails = (routeId?: number, routeTemplateId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadTravelPathDetailsPromise = routeId
    ? doLoadTravelPathStatusDetails(routeId)
    : routeTemplateId
    ? doLoadTravelPathStatusDetailsForTemplate(routeTemplateId)
    : null;

  if (!loadTravelPathDetailsPromise) {
    return Promise.reject();
  }

  loadTravelPathDetailsPromise
    .then(travelPathStatusDetails => dispatch(completeLoad(travelPathStatusDetails)))
    .catch(() => dispatch(failLoad()));

  return loadTravelPathDetailsPromise;
};

export const triggerTravelPath = (routeId?: number, routeTemplateId?: number) => (dispatch: Dispatch) => {
  dispatch(startTrigger());

  const triggerTravelPathPromise = routeId
    ? doTriggerTravelPath(routeId)
    : routeTemplateId
    ? doTriggerTravelPathForTemplate(routeTemplateId)
    : null;

  if (!triggerTravelPathPromise) {
    return Promise.reject();
  }

  triggerTravelPathPromise
    .then(() => {
      dispatch(completeTrigger());
    })
    .catch(() => {
      dispatch(failTrigger());
    })
    .finally(() => {
      loadTravelPathStatusDetails(routeId, routeTemplateId)(dispatch);
    });

  return triggerTravelPathPromise;
};

// used on displaying the travel path
export const downloadTravelPathGeoJsonFile =
  (routeId?: number, routeTemplateId?: number, isForBuildOrEdit?: boolean) => (dispatch: Dispatch) => {
    dispatch(startDownloadGeoJsonFile());
    const getTravelPathGeoJsonFilePromise = routeId
      ? doGetTravelPathGeoJsonFile(routeId)
      : routeTemplateId
      ? doGetTravelPathGeoJsonFileForTemplate(routeTemplateId)
      : null;

    if (!getTravelPathGeoJsonFilePromise) {
      return Promise.reject();
    }

    getTravelPathGeoJsonFilePromise
      .then(data => {
        dispatch(completeDownloadGeoJsonFile(data, routeId, routeTemplateId, isForBuildOrEdit));
      })
      .catch(e => {
        if (e.response && e.response.status === 404) {
          dispatch(failDownloadGeoJsonFile(translate('routes.travelPath.alertMessages.notFound')));
          loadTravelPathStatusDetails(routeId, routeTemplateId)(dispatch);
          createErrorNotification(translate('routes.travelPath.alertMessages.notFound'));
        } else dispatch(failDownloadGeoJsonFile());
      });
  };
