import Cookie from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router';

import { getExcludeVehicleFilters } from 'src/common/utils/filters';
import { TypedField } from 'src/core/components';
import Dropdown, { DropdownOption } from 'src/core/components/Dropdown';
import { Grid, GridColumn } from 'src/core/components/styled';
import { WEEKDAYS } from 'src/core/constants/weekdays';
import translate from 'src/core/services/translate';
import { VehicleTypeForVendorDropdown, VehicleTypeForVendorMultiSelect } from 'src/fleet/components';
import {
  ROUTE,
  SNOW_PLOW,
  STREET_SWEEPER,
  DELIVERY_UTILITY,
  FRONT_LOAD,
  RESIDENTIAL,
  ROLL_OFF,
  WASTE_AUDIT,
  TOTER,
  SNOW_PLOW_ID,
  STREET_SWEEPER_ID,
  ROLL_OFF_ID,
  DELIVERY_UTILITY_ID,
} from 'src/fleet/constants';
import {
  ROUTE_PLANNER_VEHICLE_TYPES_COOKIE,
  ROUTE_PLANNER_VEHICLE_TYPES_COOKIE_EXPIRATION,
  ROUTE_PLANNER_VEHICLE_TYPES_FORM_NAME,
} from 'src/routes/constants';
import {
  ROUTE_PLANNER_DAYS_OF_SERVICE_COOKIE,
  ROUTE_PLANNER_VEHICLE_TYPE_COOKIE,
} from 'src/routes/constants/routePlanner';
import { AppState } from 'src/store';
import { createUrl } from 'src/utils/services/queryParams';
import { setRoutePlannerPersistentFilters } from 'src/routes/services/routePlannerFilterStorage';
import { getRoutePlannerFilters } from 'src/routes/services/routePlannerFilters';
import GroupsMultiSelect from '../../GroupsMultiSelect';

interface PropsWithoutReduxForm {}

interface FormData {
  vehicleTypeIds?: number[];
  vehicleTypeId?: number;
  dayOfServiceId?: number;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormData, PropsWithoutReduxForm>;

const RoutePlannerVehicleTypesForm: React.FC<Props> = ({ handleSubmit, initialValues }) => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const filtersPreferences = useSelector((state: AppState) => state.common.filters.filters);

  const formValues = useSelector(getFormValues(ROUTE_PLANNER_VEHICLE_TYPES_FORM_NAME)) as FormData;

  const excludeVehicleFilters = getExcludeVehicleFilters(filtersPreferences);

  const daysOfServiceIds: DropdownOption[] = WEEKDAYS.map(day => ({
    label: day.name,
    value: day.id,
  }));

  const isReoccurringRoutesPage = pathname.includes('reoccurring');
  const isDailyOrReoccurringPage = pathname.includes('daily') || pathname.includes('reoccurring');

  const onVehicleTypeIdChange = (event: any) => {
    const vehicleTypeId = { vehicleTypeId: event.toString() };
    Cookie.set(ROUTE_PLANNER_VEHICLE_TYPE_COOKIE, event, { expires: ROUTE_PLANNER_VEHICLE_TYPES_COOKIE_EXPIRATION });

    // remove material type filter if vehicle type has no material type
    if (
      [ROLL_OFF_ID, DELIVERY_UTILITY_ID, SNOW_PLOW_ID, STREET_SWEEPER_ID].includes(Number(vehicleTypeId.vehicleTypeId))
    ) {
      const filters = getRoutePlannerFilters(search);
      let routePlannerFilters = {};
      if (filters && filters.materialTypeIds) {
        const { supervisorsIds, serviceZonesIds, facilityTypeIds, routeStatusesIds, groupIds } = filters;
        routePlannerFilters = {
          materialTypeIds: '',
          supervisorsIds: supervisorsIds && supervisorsIds.toString(),
          serviceZonesIds: serviceZonesIds && serviceZonesIds.toString(),
          facilityTypeIds: facilityTypeIds && facilityTypeIds.toString(),
          routeStatusesIds: routeStatusesIds && routeStatusesIds.toString(),
          groupIds: groupIds && groupIds.toString(),
        };
      }
      setRoutePlannerPersistentFilters(routePlannerFilters);
      dispatch(
        push(createUrl(pathname, search, { ...routePlannerFilters, vehicleTypeId: vehicleTypeId.vehicleTypeId })),
      );
    } else {
      dispatch(push(createUrl(pathname, search, vehicleTypeId)));
    }
  };

  const onGroupsIdsChange = (event: any) => {
    const groupIds = { groupIds: event ? event.toString() : '' };
    const filters = getRoutePlannerFilters(search);
    const { supervisorsIds, serviceZonesIds, facilityTypeIds, routeStatusesIds, materialTypeIds } = filters;
    const filtersUpdated = {
      supervisorsIds: supervisorsIds && supervisorsIds.toString(),
      serviceZonesIds: serviceZonesIds && serviceZonesIds.toString(),
      facilityTypeIds: facilityTypeIds && facilityTypeIds.toString(),
      routeStatusesIds: routeStatusesIds && routeStatusesIds.toString(),
      materialTypeIds: materialTypeIds && materialTypeIds.toString(),
      groupIds: groupIds.groupIds,
    };
    setRoutePlannerPersistentFilters(filtersUpdated);
    dispatch(push(createUrl(pathname, search, { ...filtersUpdated, groupIds: groupIds.groupIds })));
  };

  const onVehicleTypeIdsChange = (event: any) => {
    const vehicleTypeIds = { vehicleTypeIds: event.toString() };
    Cookie.set(ROUTE_PLANNER_VEHICLE_TYPES_COOKIE, event, { expires: ROUTE_PLANNER_VEHICLE_TYPES_COOKIE_EXPIRATION });
    dispatch(push(createUrl(pathname, search, vehicleTypeIds)));
  };

  const onDaysOfServiceIdChange = (event: any) => {
    const daysOfServiceIds = { dayOfServiceId: event.toString() };

    Cookie.set(ROUTE_PLANNER_DAYS_OF_SERVICE_COOKIE, event, { expires: ROUTE_PLANNER_VEHICLE_TYPES_COOKIE_EXPIRATION });
    dispatch(push(createUrl(pathname, search, daysOfServiceIds)));
  };

  const showDaysOfServiceDropdown =
    isReoccurringRoutesPage &&
    formValues?.vehicleTypeId !== SNOW_PLOW_ID &&
    formValues?.vehicleTypeId !== STREET_SWEEPER_ID;

  const showVehicleTypeDropdown = isDailyOrReoccurringPage || isReoccurringRoutesPage;

  const showVehicleAndGroupsMultiSelects = !showVehicleTypeDropdown;

  return (
    <form onSubmit={handleSubmit} id="vehicleTypeIdsForm">
      <Grid>
        <GridColumn
          size={isDailyOrReoccurringPage ? (isReoccurringRoutesPage ? '6/12' : '2/12') : '12/12'}
          padding="no small"
          width={showVehicleAndGroupsMultiSelects ? '400px' : '200px'}
          verticalAlign="center"
        >
          {showVehicleTypeDropdown && (
            <TypedField
              name="vehicleTypeId"
              component={VehicleTypeForVendorDropdown}
              props={{
                label: translate('vehicles.vehicleType'),
                excludeVehicleTypes: excludeVehicleFilters,
                withTechnicalName: false,
                acceptedVehicleTypes: [
                  TOTER,
                  DELIVERY_UTILITY,
                  RESIDENTIAL,
                  FRONT_LOAD,
                  ROLL_OFF,
                  SNOW_PLOW,
                  STREET_SWEEPER,
                  WASTE_AUDIT,
                ],
                minWidth: '150px',
              }}
              onChange={onVehicleTypeIdChange}
            />
          )}
          {showVehicleAndGroupsMultiSelects && (
            <>
              <TypedField
                name="vehicleTypeIds"
                component={VehicleTypeForVendorMultiSelect}
                props={{
                  label: translate('vehicles.vehicleType'),
                  excludeVehicleTypes: excludeVehicleFilters,
                  vehicleRoleTypeId: ROUTE,
                  withTechnicalName: false,
                  multiSelectProps: {
                    margin: 'no xSmall',
                  },
                }}
                onChange={onVehicleTypeIdsChange}
              />
              <TypedField
                name="groupIds"
                component={GroupsMultiSelect}
                props={{
                  withLabel: true,
                  includeNoneOption: true,
                  multiSelectProps: {
                    canCheckAll: true,
                    fitContentWidth: true,
                    margin: 'no xSmall',
                  },
                }}
                onChange={onGroupsIdsChange}
              />
            </>
          )}
        </GridColumn>
        {showDaysOfServiceDropdown && (
          <GridColumn marginLeft="15px" size="6/12">
            <TypedField
              name="dayOfServiceId"
              component={Dropdown}
              props={{
                label: translate('routes.dayOfService'),
                options: daysOfServiceIds,
                width: '150px',
              }}
              onChange={onDaysOfServiceIdChange}
            />
          </GridColumn>
        )}
      </Grid>
    </form>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  form: ROUTE_PLANNER_VEHICLE_TYPES_FORM_NAME,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(RoutePlannerVehicleTypesForm);
