import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadCompliance as doLoadCompliance,
  loadCOIStatus as doLoadCOIStatus,
  uploadFromUrl as doUploadFromUrl,
  uploadSelfCertification as doUploadSelfCertification,
} from '../services/compliance';

// Actions
const START_LOAD = 'haulerProfile/compliance/START_LOAD';
const START_SIGN = 'haulerProfile/compliance/START_SIGN';
const COMPLETE_LOAD = 'haulerProfile/compliance/COMPLETE_LOAD';
const COMPLETE_LOAD_STATUS = 'haulerProfile/compliance/COMPLETE_LOAD_STATUS';
const FAIL_LOAD = 'haulerProfile/compliance/FAIL_LOAD';
const START_UPLOAD = 'haulerProfile/compliance/START_UPLOAD';
const COMPLETE_UPLOAD = 'haulerProfile/compliance/COMPLETE_UPLOAD';
const FAIL_UPLOAD = 'haulerProfile/compliance/FAIL_UPLOAD';

// Initial state
const initialState = {
  compliance: undefined,
  complianceStatus: undefined,
  isLoading: false,
  isUploading: false,
  isValidGusId: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case START_SIGN:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          compliance: action.compliance.compliance,
          isValidGusId: true,
        },
      });

    case COMPLETE_LOAD_STATUS:
      return update(state, {
        $merge: {
          isLoading: false,
          complianceStatus: action.complianceStatus.coiComplianceStatus,
          isValidGusId: true,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_UPLOAD:
      return update(state, {
        $merge: {
          isUploading: true,
        },
      });

    case COMPLETE_UPLOAD:
      return update(state, {
        $merge: {
          isUploading: false,
        },
      });

    case FAIL_UPLOAD:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const startSign = () => ({
  type: START_SIGN,
});

const completeLoad = (compliance: any) => ({
  type: COMPLETE_LOAD,
  compliance,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const completeUpload = () => ({
  type: COMPLETE_LOAD,
});

const completeLoadStatus = (complianceStatus: any) => ({
  type: COMPLETE_LOAD_STATUS,
  complianceStatus,
});

const failUpload = () => ({
  type: FAIL_LOAD,
});

export const loadCompliance = (userId: string, divisionId?: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  return doLoadCompliance(userId, divisionId)
    .then(compliance => dispatch(completeLoad(compliance)))
    .catch(() => dispatch(failLoad()));
};

export const uploadSignedDocument =
  (userId: string, vendorGroupId: string, selfCertificationDocumentUrl: string, divisionId: string) =>
  (dispatch: Dispatch) => {
    dispatch(startSign());
    const uploadDocumentPromise = doUploadFromUrl(userId, vendorGroupId, selfCertificationDocumentUrl, divisionId);
    uploadDocumentPromise.then(() => dispatch(completeUpload())).catch(() => dispatch(failUpload()));

    return uploadDocumentPromise;
  };

export const uploadSelfCertification =
  (userId: string, vendorGroupId: string, name: string, title: string, divisionId: string) => (dispatch: Dispatch) => {
    dispatch(startSign());
    const uploadSelfCertification = doUploadSelfCertification(userId, vendorGroupId, name, title, divisionId);
    uploadSelfCertification.then(() => dispatch(completeUpload())).catch(() => dispatch(failUpload()));

    return uploadSelfCertification;
  };

export const loadCOIStatus = (gusId?: string, divisionId?: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  return doLoadCOIStatus(gusId, divisionId)
    .then((response: any) => dispatch(completeLoadStatus(response)))
    .catch(() => dispatch(failLoad()));
};
