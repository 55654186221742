import { ChangeEvent, Fragment, useEffect, useMemo, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { submit } from 'redux-form';

import { AppState } from 'src/store';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  ModalFixedFooter,
  ModalSection,
  Popover,
} from '../../../core/components/styled';
import { customerLocationsSelector } from '../../../customers/ducks';
import { DispatchBoardRouteBuilderForm, DispatchBoardRouteBuilderOptionsForm } from '../forms';
import { DispatchBoardRouteBuilderSelectedJobsCounter } from '../styled';
import { DuckFunction } from '../../../contracts/ducks';
import { Facility } from '../../../common/interfaces/Facility';
import { loadVehicles } from '../../../fleet/ducks';
import { Modal, PopoverWrapper } from '../../../core/components';
import { ProgressBar } from '../../../common/components';
import { RouteBuilderFilter } from './';
import {
  ROUTE_BUILDER_BASE_TIME,
  ROUTE_BUILDER_ITEM_HEIGHT_EXPANDED,
  ROUTE_BUILDER_MAX_ROUTES,
  ROUTE_BUILDER_MAX_SELECTED_JOBS,
  ROUTE_BUILDER_TIME_PER_ROUTE,
  TODAY_FORMATTED,
} from '../../../core/constants';
import {
  ROUTE_BUILDER_HEADER_AND_FOOTER_HEIGHT,
  ROUTE_BUILDER_JOBS_LIST_HEIGHT,
  ROUTE_BUILDER_FILTER_HEIGHT,
} from '../../../core/constants/routeBuilder';
import { RouteBuilderFormData } from '../forms/DispatchBoardRouteBuilderForm';
import dispatchBoardRouteBuilderFilteredUnassignedJobsSelector from '../../services/dispatchBoardRouteBuilderFilteredUnassignedJobsSelector';
import translate from '../../../core/services/translate';

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const routeBuilderFn = (
  { numberOfRoutes, routeDate }: { numberOfRoutes: number; routeDate: Date | string },
  defaultStartLocationId?: number,
  defaultIntermediateLocationId?: number,
) => {
  let i;
  const routes = [];
  for (i = 0; i < numberOfRoutes; i++) {
    routes.push({
      routeName: `${routeDate} Route ${i + 1}`,
      startingLocationId: defaultStartLocationId,
      endingLocationId: defaultStartLocationId,
      intermediateLocationId: defaultIntermediateLocationId,
      serviceZoneId: undefined,
      vehicleId: undefined,
    });
  }
  return routes;
};

interface Props {
  closeModal: () => void;
  truckYardFacilities: Facility[];
  disposalFacilities: Facility[];
  defaultStartLocationId?: number;
  /** TODO Intermediate Facility for Route Builder */
  // defaultIntermediateLocationId: PropTypes.number,
  finishBuilding?: boolean;
  onSubmitRouteBuilder: (values: RouteBuilderFormData) => void;
  submit: (formName: string) => void;
  unassignedJobs: any[];
  vehicleTypeId: number;
  vendorId: number;
  loadVehicles: DuckFunction<typeof loadVehicles>;
}

const DispatchBoardRouteBuilderModal = (props: Props) => {
  const didMountRef = useRef(false);

  const {
    closeModal,
    truckYardFacilities,
    disposalFacilities,
    defaultStartLocationId,
    finishBuilding,
    loadVehicles,
    onSubmitRouteBuilder,
    submit,
    unassignedJobs,
    vehicleTypeId,
    vendorId,

    /** TODO Intermediate Facility for Route Builder */
    // defaultIntermediateLocationId,
  } = props;

  const [filters, setFilters] = useState({
    date: undefined,
    equipment: undefined,
    materialType: undefined,
    pickupType: undefined,
    reasonCodeType: undefined,
    searchTerm: undefined,
  });

  const [selectedJobs, setSelectedJobs] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const [routeBuilderOptions, setRouteBuilderOptions] = useState({
    numberOfRoutes: 1,
    routeDate: TODAY_FORMATTED,
  });
  const [filterCount, setFilterCount] = useState(0);

  const { date, equipment, materialType, pickupType, reasonCodeType, searchTerm } = filters;
  const { numberOfRoutes, routeDate } = routeBuilderOptions;

  const percent = useRef(0);

  useEffect(() => {
    setSelectedJobs([]);

    // set to true if not the 1st render
    if (!didMountRef.current) {
      didMountRef.current = true;
    }
    return () => {
      // equivalent of componentWillUnmount do cleanup here
      setActiveStep(1);
      setRouteBuilderOptions({
        numberOfRoutes: 1,
        routeDate: TODAY_FORMATTED,
      });
    };
  }, []);

  const getRouteBuilderInitialValues = () => ({
    optimizeBy: 'numberOfRoutes',
    /** TODO Intermediate Facility for Route Builder */
    routes: routeBuilderFn(routeBuilderOptions, defaultStartLocationId) as any, // defaultIntermediateLocationId),
    unassignedJobIds: selectedJobs,
    routeDate,
  });

  const filteredJobs = useMemo(
    () =>
      dispatchBoardRouteBuilderFilteredUnassignedJobsSelector({
        unassignedJobs,
        date,
        equipment,
        materialType,
        pickupType,
        reasonCodeType,
        searchTerm,
      }),
    [unassignedJobs, date, equipment, materialType, pickupType, reasonCodeType, searchTerm],
  );

  const numberOfRoutesOptions = useMemo(() => {
    const max = Math.min(selectedJobs.length, ROUTE_BUILDER_MAX_ROUTES);
    let i;
    const options = [];
    for (i = 0; i < max; i++) {
      options.push({
        label: (i + 1).toString(), // to please the gods of type validation
        value: i + 1,
      });
    }
    return options;
  }, [selectedJobs.length]);

  const onEquipmentFilterChange = (equipment: string) => {
    setFilterCount(prevCount => prevCount + 1);
    setFilters(prevFilters => ({ ...prevFilters, equipment } as any));
  };

  const onMaterialTypeFilterChange = (materialType: string) => {
    setFilterCount(prevCount => prevCount + 1);
    setFilters(prevFilters => ({ ...prevFilters, materialType } as any));
  };

  const onDateFilterChange = (date: Date | string) => {
    setFilterCount(prevCount => prevCount + 1);
    setFilters(prevFilters => ({ ...prevFilters, date } as any));
  };

  const onPickupTypeFilterChange = (pickupType: ChangeEvent) => {
    setFilterCount(prevCount => prevCount + 1);
    setFilters(prevFilters => ({ ...prevFilters, pickupType } as any));
  };

  const onReasonCodeTypeFilterChange = (reasonCodeType: string) => {
    setFilterCount(prevCount => prevCount + 1);
    setFilters(prevFilters => ({ ...prevFilters, reasonCodeType } as any));
  };

  const onSearchTermFilterChange = (_: ChangeEvent, searchTerm: string) => {
    setFilterCount(prevCount => prevCount + 1);
    setFilters(prevFilters => ({ ...prevFilters, searchTerm } as any));
  };

  const onRouteDateChange = (routeDate: Date | string) => {
    const showActiveTrucks = true;
    const vehicleTypeIdUndefined = undefined;
    loadVehicles(vendorId, showActiveTrucks, vehicleTypeIdUndefined, routeDate);

    setRouteBuilderOptions(prevFilters => ({ ...prevFilters, routeDate } as any));
  };

  const onNumberOfRoutesChange = (numberOfRoutes: ChangeEvent) => {
    setRouteBuilderOptions(prevFilters => ({ ...prevFilters, numberOfRoutes } as any));
  };

  const onCheckAll = () => {
    setSelectedJobs(selectedJobs.length ? [] : (filteredJobs as any).map((job: any) => job.id));
  };

  const onCheck = (id: number, index: number) => {
    const updSelectedJobs: any = [...selectedJobs];

    const selectedJobIndex = updSelectedJobs.indexOf(id);

    if (selectedJobIndex > -1) {
      updSelectedJobs.splice(selectedJobIndex, 1);
    } else {
      updSelectedJobs.splice(index, 0, id);
    }

    setSelectedJobs(updSelectedJobs);
  };

  const onNext = () => {
    setActiveStep(s => s + 1);
  };

  const onBack = () => {
    setActiveStep(s => s - 1);
  };

  const formula = useMemo(
    () => numberOfRoutes * ROUTE_BUILDER_TIME_PER_ROUTE + ROUTE_BUILDER_BASE_TIME,
    [numberOfRoutes],
  );

  useEffect(() => {
    const loop = async () => {
      if (activeStep === 3) {
        for (let i = 0; i < 90; i += 5) {
          percent.current = i;
          // eslint-disable-next-line no-await-in-loop
          await sleep(formula * 50); // 1000 (ms in 1 sec) divided by 100/5 (total percents)
        }

        if (finishBuilding) {
          percent.current = 100;
        }
      }
    };
    loop();
  }, [activeStep, finishBuilding, formula]);

  const onCreateRoutes = () => submit('dispatchBoardRouteBuilderForm');

  const moreThanMaxSelected = selectedJobs.length > ROUTE_BUILDER_MAX_SELECTED_JOBS;
  const noJobsSelected = selectedJobs.length < 1;

  // Don't render if first render and there are no jobs selected.
  // Avoids an unnecessary re-render.
  if (selectedJobs.length === 0 && !didMountRef.current) return null;

  return (
    <Modal
      fixedHeader
      headerPadding="medium medium small"
      flexDirection="column"
      onClose={closeModal}
      padding="no"
      size="large"
      // TODO Intermediate Facility for Route Builder
      // size="large"
      title={translate('routes.routeBuilder')}
      verticalSize={activeStep === 3 ? 'small' : 'large'}
      height={
        activeStep === 1
          ? `${ROUTE_BUILDER_HEADER_AND_FOOTER_HEIGHT + ROUTE_BUILDER_JOBS_LIST_HEIGHT + ROUTE_BUILDER_FILTER_HEIGHT}px`
          : undefined
      }
    >
      <ModalSection
        align="center"
        padding="no medium"
        flexDirection="column"
        height={activeStep === 1 ? `${ROUTE_BUILDER_FILTER_HEIGHT + ROUTE_BUILDER_JOBS_LIST_HEIGHT}px` : '100%'}
      >
        {activeStep === 1 && (
          <RouteBuilderFilter
            allSelected={selectedJobs.length === (filteredJobs as any).length}
            partial={!!(selectedJobs.length && selectedJobs.length !== (filteredJobs as any).length)}
            itemHeightExpanded={ROUTE_BUILDER_ITEM_HEIGHT_EXPANDED}
            filterCount={filterCount}
            handleCheck={onCheck}
            handleCheckAll={onCheckAll}
            handleDateFilterChange={onDateFilterChange}
            handleEquipmentFilterChange={onEquipmentFilterChange}
            handleMaterialTypeFilterChange={onMaterialTypeFilterChange}
            handlePickupTypeFilterChange={onPickupTypeFilterChange}
            handleReasonCodeTypeFilterChange={onReasonCodeTypeFilterChange}
            handleSearchTermFilterChange={onSearchTermFilterChange}
            jobs={filteredJobs}
            selectedJobs={selectedJobs}
            filters={filters}
          />
        )}
        {activeStep === 2 && (
          <Fragment>
            <DispatchBoardRouteBuilderOptionsForm
              handleRouteDateChange={onRouteDateChange}
              handleNumberOfRoutesChange={onNumberOfRoutesChange}
              numberOfRoutesOptions={numberOfRoutesOptions}
              initialValues={{
                routeDate: TODAY_FORMATTED,
                numberOfRoutes: 1,
              }}
            />
            <DispatchBoardRouteBuilderForm
              truckYardFacilities={truckYardFacilities}
              disposalFacilities={disposalFacilities}
              routeDate={routeDate}
              initialValues={getRouteBuilderInitialValues()}
              onSubmit={onSubmitRouteBuilder}
              vehicleTypeId={vehicleTypeId}
              vendorId={vendorId}
              onSubmitSuccess={onNext}
            />
          </Fragment>
        )}
        {activeStep === 3 && (
          <Fragment>
            {translate('dispatchBoard.routeBuilder.routesProcessing')}
            <ProgressBar progress={percent.current} withBorder margin="sMedium auto" />
            {translate('dispatchBoard.routeBuilder.progressMessage', {
              from: Math.ceil(formula * 0.7),
              to: Math.ceil(formula * 1.5),
            })}
          </Fragment>
        )}
      </ModalSection>
      <ModalFixedFooter padding="small medium medium">
        <Grid>
          <GridColumn size="4/12" verticalAlign="center">
            {activeStep === 1 &&
              translate('dispatchBoard.routeBuilder.jobsListed', { listedJobs: (filteredJobs as any).length })}
          </GridColumn>
          <GridColumn size="8/12" align="right" verticalAlign="center">
            {activeStep === 1 && (
              <Fragment>
                <PopoverWrapper
                  triggerButton={
                    <DispatchBoardRouteBuilderSelectedJobsCounter
                      moreThanMaxSelected={moreThanMaxSelected}
                      padding="no small no no"
                    >
                      {translate('dispatchBoard.routeBuilder.jobsSelected', { selectedJobs: selectedJobs.length })}
                    </DispatchBoardRouteBuilderSelectedJobsCounter>
                  }
                  popoverContent={
                    moreThanMaxSelected && (
                      <Popover color="primary">
                        {translate('dispatchBoard.routeBuilder.maxSelectedJobs', {
                          maxSelectedJobs: ROUTE_BUILDER_MAX_SELECTED_JOBS,
                        })}
                      </Popover>
                    )
                  }
                />
                <Button color="primary" onClick={onNext} disabled={moreThanMaxSelected || noJobsSelected}>
                  {translate('common.next')}
                </Button>
              </Fragment>
            )}
            {activeStep === 2 && (
              <ButtonSet margin="no">
                <Button color="secondary" onClick={onBack} margin="no small no">
                  {translate('common.back')}
                </Button>
                <Button color="primary" onClick={onCreateRoutes}>
                  {translate('dispatchBoard.routeBuilder.createRoutes')}
                </Button>
              </ButtonSet>
            )}
            {activeStep === 3 && (
              <ButtonSet margin="no">
                <Button color="secondary" onClick={closeModal} margin="no small no">
                  {translate('common.close')}
                </Button>
              </ButtonSet>
            )}
          </GridColumn>
        </Grid>
      </ModalFixedFooter>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => {
  const truckYardFacilities = customerLocationsSelector(state.fleet.facilities.operationalFacilities) || [];
  const disposalFacilities = customerLocationsSelector(state.fleet.facilities.disposalFacilities) || [];
  const defaultStartLocation = state.fleet.facilities.operationalFacilities.find(
    (facility: any) => facility.isDefaultStartLocation,
  );
  /** TODO Intermediate Facility for Route Builder */
  // const defaultIntermediateLocation = state.fleet.facilities.disposalFacilities.find(
  //   facility => facility.isDefaultIntermediateFacility,
  // );

  return {
    truckYardFacilities,
    disposalFacilities,
    defaultStartLocationId: defaultStartLocation && defaultStartLocation.locationId,
    /** TODO Intermediate Facility for Route Builder */
    // defaultIntermediateLocationId: defaultIntermediateLocation && defaultIntermediateLocation.locationId,
    unassignedJobs: state.routes.dispatchBoard.unassignedJobs.unassignedJobs,
  };
};

const mapDispatchToProps = {
  loadVehicles,
  submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(DispatchBoardRouteBuilderModal);
