import React from 'react';

import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Input, TextArea, UnconnectedCheckbox } from 'src/core/components';
import { Button } from 'src/core/components/styled/Button';
import ButtonSet from 'src/core/components/styled/ButtonSet';
import { Grid, GridColumn } from 'src/core/components/styled/Grid';
import Text from 'src/core/components/styled/Text';
import { ThumbnailWrapper } from 'src/core/components/styled/ThumbnailWrapper';
import { ThumbnailSetImageSlider } from 'src/core/components/ThumbnailSetImageSlider';
import translate from 'src/core/services/translate';
import { Image } from 'src/routes/interfaces/RouteImages';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import { isEmail, isRequired } from 'src/utils/services/validator';

export interface EmailRouteImagesFormValues {
  from: string;
  to: string;
  subject: string;
  body: string;
}

interface PropsWithoutReduxForm {
  images?: Image[];
  toggleImageBlobId: (blobId: string) => void;
  selectedImageBlobIds: string[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<EmailRouteImagesFormValues, PropsWithoutReduxForm>;

const EmailRouteImagesForm = ({ handleSubmit, images = [], selectedImageBlobIds, toggleImageBlobId }: Props) => (
  <form onSubmit={handleSubmit}>
    <Grid multiLine>
      <GridColumn size="6/12">
        <Field name="from" component={Input} label={translate('common.from')} validate={[isRequired, isEmail]} />
      </GridColumn>
      <GridColumn size="6/12">
        <Field name="to" component={Input} label={translate('common.to')} validate={[isRequired, isEmail]} />
      </GridColumn>
      <GridColumn size="12/12">
        <Field name="subject" component={Input} label={translate('common.subject')} validate={[isRequired]} />
      </GridColumn>
      <GridColumn size="12/12">
        <Field name="body" component={TextArea} rows="3" label={translate('common.message')} validate={[isRequired]} />
      </GridColumn>
      <GridColumn size="12/12">
        <Text>{translate('customers.emails.attachments')}:</Text>
      </GridColumn>
      {images.map(image => (
        <GridColumn size="2/12" key={image.blobId}>
          <ThumbnailWrapper
            active={selectedImageBlobIds.includes(image.blobId)}
            onClick={() => toggleImageBlobId(image.blobId)}
          >
            <UnconnectedCheckbox
              checked={selectedImageBlobIds.includes(image.blobId)}
              onChange={() => toggleImageBlobId(image.blobId)}
              onClick={e => e.stopPropagation()}
              margin="no small no no"
            />
            <ThumbnailSetImageSlider size="70px" images={[image.url]} imagesToDownload={[image.rawUrl || image.url]} />
          </ThumbnailWrapper>
        </GridColumn>
      ))}
    </Grid>

    <ButtonSet margin="medium no no">
      <Button type="submit" color="primary">
        {translate('common.send')}
      </Button>
    </ButtonSet>
  </form>
);

export default reduxForm<EmailRouteImagesFormValues, PropsWithoutReduxForm>({
  form: 'emailRouteImages',
  onSubmitFail: focusFirstInvalidField,
})(EmailRouteImagesForm);
