import { INFERENCE_AUDIT_DETECTIONS, INFERENCE_AUDIT_STATUSES, MODEL_CLASS_TYPES } from '../constants';
import { InferenceAudit } from '../interfaces/interfaces';
import translate from '../../core/services/translate';

export const transformInferenceAudit = (inferenceAudit: any): InferenceAudit[] => {
  const { status, modelClass, detections, auditedDetections: auditedDetectionsResp } = inferenceAudit;

  let detectionNames = translate('inferenceAudit.inferenceAuditDetections.notAvailable');
  let auditedDetectionNames = translate('inferenceAudit.inferenceAuditDetections.notAvailable');
  let auditedDetections: string[] = [];

  if (auditedDetectionsResp) {
    auditedDetections = [...auditedDetectionsResp];
  } else if (detections && detections.length > 0) {
    // update auditedDetections with the original ones for the form
    auditedDetections = [...detections];
  }

  if (detections && detections.length > 0) {
    detectionNames = detections
      .map((detection: string) => {
        if (detection) {
          if (INFERENCE_AUDIT_DETECTIONS[detection]) {
            return translate(INFERENCE_AUDIT_DETECTIONS[detection].name);
          }
          // fallback in case new detections get introduced and they're not yet translated
          return translate('inferenceAudit.inferenceAuditDetections.inferenceTranslationMissing');
        }

        return null;
      })
      .join(', ');
  }

  if (auditedDetections.length > 0) {
    auditedDetectionNames = auditedDetections
      .map(auditedDetection => {
        if (auditedDetection && auditedDetection !== '') {
          if (INFERENCE_AUDIT_DETECTIONS[auditedDetection]) {
            return translate(INFERENCE_AUDIT_DETECTIONS[auditedDetection].name);
          }
          // fallback in case new detections get introduced and they're not yet translated
          return translate('inferenceAudit.inferenceAuditDetections.inferenceTranslationMissing');
        }

        return null;
      })
      .join(', ');
  }

  return {
    ...inferenceAudit,
    status: status.toString(),
    statusName: translate(INFERENCE_AUDIT_STATUSES[status].name),
    modelClassName: translate(MODEL_CLASS_TYPES[modelClass].name),
    detectionNames,
    auditedDetections,
    auditedDetectionNames,
  };
};

const transformInferenceAudits = (response: { inferenceAudits: any[] }): any => ({
  ...response,
  inferenceAudits: response.inferenceAudits.map(inferenceAudit => transformInferenceAudit(inferenceAudit)),
});

export default transformInferenceAudits;
