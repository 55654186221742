import { useDispatch } from 'react-redux';

import { useSelector } from 'src/core/hooks/useSelector';
import { DocumentTitle } from '../../../common/components';
import { PageLoading } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import translate from '../../../core/services/translate';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { loadFacilities, loadFacilityRateTypes, loadFacilityTypes, loadVehicleTypesForVendor } from '../../ducks';
import FacilitiesPage from './FacilitiesPage';

const FacilitiesPageResolver = () => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = () => {
    if (!vendorId) {
      return Promise.reject();
    }
    const dependencies: Promise<any>[] = [
      loadFacilities(vendorId)(dispatch),
      loadFacilityRateTypes()(dispatch),
      loadFacilityTypes()(dispatch),
      loadVehicleTypesForVendor(vendorId)(dispatch),
    ];

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('facilities.facilities')}</DocumentTitle>
      <TypedResolver
        redirectOnError="/dashboard"
        resolve={loadDependencies}
        loadingComponent={PageLoading}
        successComponent={FacilitiesPage}
      />
    </>
  );
};

export default FacilitiesPageResolver;
