import { useSelector, useDispatch } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { loadOpen311Lookup, loadOpen311Configuration } from 'src/vendors/ducks/open311Settings';
import { loadServiceRequestErrors } from '../../ducks/open311ConfigErrors';
import Open311ConfigurationModal from './Open311ConfigurationModal';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';

interface Props {
  closeModal: () => void;
}

export default function Open311ConfigurationResolver(props: Props) {
  const { closeModal } = props;
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = () => {
    if (!vendorId) return Promise.reject();
    const dependencies: Promise<any>[] = [];
    dependencies.push(
      loadOpen311Lookup(vendorId)(dispatch),
      loadOpen311Configuration(vendorId)(dispatch),
      loadServiceRequestErrors(vendorId)(dispatch),
    );
    return Promise.all(dependencies);
  };
  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={Open311ConfigurationModal}
      successProps={{ closeModal }}
    />
  );
}
