import styled, { css } from 'styled-components';

import { colorMapperWithoutProps } from '../../../utils/styles/colorMapper';
import { sizeMapper } from '../../../utils/styles';
import { ActionButton } from '../../../core/components/styled';

interface SectionCollapseButtonProps {
  collapsed?: boolean;
}

export const SectionCollapseButton = styled.button<SectionCollapseButtonProps>`
  background: none;
  outline: none;
  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${colorMapperWithoutProps('grayDarker')};

  & > svg {
    width: 20px;
    height: 20px;
    transition: transform 0.2s;
  }

  &:not(:disabled) {
    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }

    ${props =>
      props.collapsed &&
      css`
        & > svg {
          transform: rotate(180deg);
        }
      `}
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: rgba(0, 0, 0, 0.03);
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`;

export const SectionHeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1;
`;

export const SectionHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 2;
`;

interface SectionsHeaderFiltersProps {
  hidden?: boolean;
}

export const SectionsHeaderFilters = styled.div<SectionsHeaderFiltersProps>`
  ${props =>
    props.hidden &&
    css`
      visibility: hidden;
    `}
`;

/**
 * This value was calculated so that every section
 * will show a hint that there are more entries, hence
 * giving the possibility for the user to scroll.
 */
const ARBITRARY_CONTAINER_HEIGHT = 8.39 * 60;

interface SectionTableWrapperProps {
  padding?: string;
}

export const SectionTableWrapper = styled.div<SectionTableWrapperProps>`
  position: relative;
  overflow: auto;
  max-height: ${ARBITRARY_CONTAINER_HEIGHT}px;
  margin: 20px -30px 0;
  width: calc(100% + 60px);
  padding: ${props => sizeMapper(props.padding, 'no')};
`;

interface SectionTableMoreIndicatorWrapperProps {
  disabled?: boolean;
}

export const SectionTableMoreIndicatorWrapper = styled.div<SectionTableMoreIndicatorWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -15px 0 -5px;
  position: relative;
  color: ${props => colorMapperWithoutProps(props.disabled ? 'grayDark' : 'primary')};
  pointer-events: none;

  ${props =>
    !props.disabled &&
    css`
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
        height: 50px;
        background: linear-gradient(to bottom, transparent, white);
      }
    `}

  & ${ActionButton} {
    z-index: 1;

    ${props =>
      props.disabled &&
      css`
        opacity: 0;
      `}
  }
`;
