import { createSelector } from 'reselect';
import { identity } from 'lodash-es';

import { getQueryParams } from '../../utils/services/queryParams';

const getSuspendedLocationsFormInitialValues = (queryString: string) => {
  const { accountStatusTypeId, searchTerm } = getQueryParams(queryString);
  return {
    accountStatusTypeId: accountStatusTypeId && parseInt(accountStatusTypeId),
    searchTerm,
  };
};

const suspendedLocationsFormInitialValuesSelector = createSelector(getSuspendedLocationsFormInitialValues, identity);

export default suspendedLocationsFormInitialValuesSelector;
