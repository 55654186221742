import { http } from '../../core/services/http';
import { transformRecurringServices } from './transformRecurringServices';

// On Call Services
export const loadOnCallServices = (token: string, date: Date | string, page: number, limit: number) =>
  http
    .get(`gus/vendor/oncall/services/${token}`, { params: { date, page, limit } })
    .then(response => transformRecurringServices(response.data));

export const loadOnCallServicesByVendorId = (vendorId: number) =>
  http.get(`gus/vendor/oncall/services/${vendorId}`).then(response => transformRecurringServices(response.data));
