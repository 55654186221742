import React from 'react';

import { Feature } from '../../interfaces/ModelFeature';
import { TableCell } from '../../../core/components/styled';
import { UnconnectedSwitch } from '../../../core/components';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props {
  isEnabled: boolean;
  model: Feature;
  rowProps: any;
}

const AiModelModalTableRowHeaderRow: React.FC<Props> = ({
  model: { id, technicalName },
  isEnabled,
  rowProps: { toggleAIModelHeaderSwitch },
}) => (
  <div style={{ width: '100%', display: 'flex' }}>
    <TableCell width="30%">{translate(createTranslationKey(technicalName, 'vendors.aIModelGroups'))}</TableCell>
    <TableCell style={{ marginLeft: '30%' }} width="25%" align="center">
      <UnconnectedSwitch checked={isEnabled} onChange={toggleAIModelHeaderSwitch(id)} />
    </TableCell>
  </div>
);

export default AiModelModalTableRowHeaderRow;
