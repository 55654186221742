import React, { useState } from 'react';

import { PageLoadingOverlay } from '../../../common/components/styled/Page';
import { TypedResolver } from '../../../core/components';
import { CustomerLocationEmail } from '../../interfaces/CustomerLocationEmail';
import { Email } from '../../interfaces/Email';
import { getCustomerEmail } from '../../services/customerCommunication';
import { CustomerEmailModal } from './CustomerEmailModal';

interface Props {
  email: Partial<Email>;
  customerId?: number;
  routeLocationId?: number;
  routeId?: number;
  onClose: () => void;
  readOnly?: boolean;
  routeLocationDetailPickupExceptionId?: number;
  isInfoEmail?: boolean;
}

export const CustomerEmailModalResolver: React.FC<Props> = ({ routeLocationId, customerId, ...modalProps }) => {
  const [customerLocationsEmails, setCustomerLocationsEmails] = useState<CustomerLocationEmail[]>([]);

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={() => getCustomerEmail(customerId, routeLocationId).then(emails => setCustomerLocationsEmails(emails))}
      successComponent={CustomerEmailModal}
      successProps={{
        customerLocationsEmails,
        routeLocationId,
        customerId,
        ...modalProps,
      }}
    />
  );
};
