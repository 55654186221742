import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

export const CUSTOMER = 'CU';
export const PAYMENT_TERMS = 'PT';
export const CHARGE_TYPE = 'CT';
export const BILL_METHOD = 'BM';
export const CUSTOMER_STATUS = 'CS';

export const BILL_CREATE_REPORTS_LIST = [
  { id: CUSTOMER, name: translate('finance.billCreateReport.cu') },
  { id: PAYMENT_TERMS, name: translate('finance.billCreateReport.pt') },
  { id: CHARGE_TYPE, name: translate('finance.billCreateReport.ct') },
  { id: BILL_METHOD, name: translate('finance.billCreateReport.bm') },
  { id: CUSTOMER_STATUS, name: translate('finance.billCreateReport.cs') },
];

export const BILL_CREATE_REPORTS = mapKeys(BILL_CREATE_REPORTS_LIST, 'id');
