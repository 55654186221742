import { useState, ChangeEvent, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map, size } from 'lodash-es';

import { AppState } from 'src/store';
import { Button, ButtonSet, Grid, GridColumn, Message, PanelSection } from '../../../../core/components/styled';
import confirm from 'src/core/services/confirm';
import { customField } from 'src/vendors/interfaces/Open311';
import { createSuccessNotification, createErrorNotification } from '../../../../core/services/createNotification';
import DefaultNoteFieldsEditorModal from '../ServiceRequestConfigurationTabs/DefaultNoteFieldsEditorModal';
import DefaultNotesTableRow from './DefaultNotesTableRow';
import { deleteDefaultNoteFields } from '../../../ducks/open311Settings';
import { Table, UnconnectedCheckbox } from 'src/core/components';
import translate from '../../../../core/services/translate';

type Props = {
  serviceTypeId?: number;
};

export default function DefaultNoteFieldsConfigurationSection({ serviceTypeId }: Props) {
  const dispatch = useDispatch();
  const open311Configuration = useSelector((state: AppState) => state.vendors.open311Settings.open311Configuration);
  const globalNoteFieldsList = useSelector((state: AppState) => state.vendors.open311Settings?.globalNoteFields);
  const serviceTypes = useSelector((state: AppState) => state.vendors.open311Settings.serviceTypes);
  const serviceTypeNotesFieldsList = useMemo(
    () => serviceTypes && serviceTypes.find(serviceType => serviceType.id === serviceTypeId)?.noteFields,
    [serviceTypeId, serviceTypes],
  );

  const configurationId = open311Configuration?.id || 0;
  const [isDefaultNoteEditorModalOpen, setIsDefaultNoteEditorModalOpen] = useState(false);
  const [selectedDefaultNoteFieldId, setSelectedDefaultNoteFieldId] = useState<number | undefined>();
  const [allDefaultNoteFieldsChecked, setAllDefaultNoteFieldsChecked] = useState<boolean>(false);
  const [selectedDefaultNoteFields, setSelectedDefaultNoteFields] = useState<number[]>([]);
  const [serviceRequestNotes, setServiceReuestNotes] = useState<customField[]>([]);

  useEffect(() => {
    if (serviceTypeId) {
      setServiceReuestNotes(serviceTypeNotesFieldsList || []);
    }
  }, [serviceTypeId, serviceTypeNotesFieldsList]);

  const openDefaultNoteEditorModal = (defaultNoteFieldId: number | undefined) => {
    setIsDefaultNoteEditorModalOpen(true);
    setSelectedDefaultNoteFieldId(defaultNoteFieldId);
  };

  const closeDefaultNoteEditorModal = async (formPristine?: boolean, serviceRequestNotes?: customField[]) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    setIsDefaultNoteEditorModalOpen(false);
    setSelectedDefaultNoteFieldId(undefined);
    if (serviceRequestNotes && serviceRequestNotes.length > 0) {
      setServiceReuestNotes(serviceRequestNotes || []);
    }
  };

  const noteFieldsList = serviceTypeId ? serviceRequestNotes : globalNoteFieldsList;

  const checkAllDefaultNoteFields = () => {
    const defaultNoteFieldsIds: number[] = [];
    noteFieldsList?.forEach((defaultNoteField: customField) => {
      if (selectedDefaultNoteFields.length === 0) {
        defaultNoteFieldsIds.push(defaultNoteField?.id);
      }
    });
    setAllDefaultNoteFieldsChecked(!allDefaultNoteFieldsChecked);
    setSelectedDefaultNoteFields(defaultNoteFieldsIds);
  };

  const checkDefaultNote = (id: number, e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const updatedDefaultNoteFields = [...selectedDefaultNoteFields];
    const indexDelete = updatedDefaultNoteFields.indexOf(id);
    if (indexDelete > -1) {
      updatedDefaultNoteFields.splice(indexDelete, 1);
    } else {
      updatedDefaultNoteFields.push(id);
    }
    setSelectedDefaultNoteFields(updatedDefaultNoteFields);
  };

  const defaultNoteFieldsTableCells = [
    {
      name: 'selectAll',
      component: UnconnectedCheckbox,
      componentProps: {
        onChange: checkAllDefaultNoteFields,
        checked: selectedDefaultNoteFields.length === noteFieldsList?.length,
        partial: 0 < selectedDefaultNoteFields.length && selectedDefaultNoteFields.length < noteFieldsList?.length,
      },
      width: '5%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
    },
    { name: 'fieldName', label: translate('vendors.fieldName'), width: '30%' },
    { name: 'fieldDisplayName', label: translate('vendors.fieldDisplayName'), width: '30%' },
    { name: 'fieldType', label: translate('vendors.fieldType'), width: '20%' },
    { name: 'options', label: translate('common.options'), width: '15%' },
  ];

  const deleteDefaultNoteField = async (serviceTypeId?: number) => {
    if (!(await confirm(translate('vendors.open311.alertMessages.deleteRuleMessage')))) {
      return;
    }
    deleteDefaultNoteFields(
      configurationId,
      selectedDefaultNoteFields,
      serviceTypeId,
    )(dispatch)
      .then(response => {
        setServiceReuestNotes(response);
        checkAllDefaultNoteFields();
        createSuccessNotification(translate('vendors.open311.alertMessages.deletedCreationRules'));
      })
      .catch(() => {
        createErrorNotification(translate('vednors.alertMessages.creationRuleDeleteError'));
      });
  };

  const defaultNoteFieldsTableRows = map(noteFieldsList, defaultNoteField => {
    return {
      ...defaultNoteField,
      isChecked: selectedDefaultNoteFields.includes(defaultNoteField?.id),
    };
  });
  return (
    <>
      <PanelSection vertical id={`creation-rule-form`}>
        {!!size(noteFieldsList) && (
          <Table
            cells={defaultNoteFieldsTableCells}
            rows={defaultNoteFieldsTableRows}
            rowComponent={DefaultNotesTableRow}
            rowProps={{
              isInbound: false,
              editDefaultNoteField: openDefaultNoteEditorModal,
              checkDefaultNote: checkDefaultNote,
            }}
          />
        )}

        {!size(noteFieldsList) && <Message padding="sMedium">{translate('vendors.open311.noCreationRules')}</Message>}
        <Grid centered>
          <ButtonSet margin="sMedium no">
            {!selectedDefaultNoteFields.length && (
              <Button
                id={`open311-creation-rule-add-button`}
                type="button"
                color="primary"
                line
                disabled={defaultNoteFieldsTableRows.length > 9}
                onClick={() => openDefaultNoteEditorModal(undefined)}
              >
                + {translate('vendors.addCustomField')}
              </Button>
            )}

            {!!selectedDefaultNoteFields.length && (
              <Button type="button" color="alert" line onClick={() => deleteDefaultNoteField(serviceTypeId)}>
                {translate('common.delete')}
              </Button>
            )}
          </ButtonSet>
          <GridColumn margin="medium no no">
            <>
              {translate('vendors.open311.defaultFields')} {defaultNoteFieldsTableRows.length} / 10
            </>
          </GridColumn>
        </Grid>

        {isDefaultNoteEditorModalOpen && (
          <DefaultNoteFieldsEditorModal
            configurationId={configurationId}
            notefieldId={selectedDefaultNoteFieldId}
            serviceTypeId={serviceTypeId}
            onClose={closeDefaultNoteEditorModal}
          ></DefaultNoteFieldsEditorModal>
        )}
      </PanelSection>
    </>
  );
}
