import { debounce } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { useState, MouseEvent } from 'react';

import { Address } from 'src/common/interfaces/Facility';
import { Button, Grid, GridColumn, Popover } from 'src/core/components/styled';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DELIVERY_UTILITY_ID } from 'src/fleet/constants';
import { Dropdown, TypeAhead, TypedField, PopoverWrapper } from 'src/core/components';
import { getInitialPickupTypeId } from '../pages/routes/routePageSections/routeStops/utils';
import { isRequired } from 'src/utils/services/validator';
import { loadVendor } from 'src/vendors/ducks';
import { ON_HOLD } from 'src/common/constants';
import { PickupTypeDropdown } from '..';
import { renderCustomerLocationOptionLabel } from './utils/CustomerLocationUtils';
import { ROLL_OFF_ID } from 'src/common/constants/serviceTypes';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { SCHEDULED } from 'src/routes/constants/routeStatuses';
import { useSelector } from 'src/core/hooks/useSelector';
import { JobPriorityTypesDropdown, WasteTypeDropdown } from 'src/common/components';
import CustomerLocationWithPin from '../CustomerLocationWithPin';
import JobPositionTypeDropdown from 'src/common/components/JobPositionTypeDropdown';
import searchCustomerLocations from 'src/routes/services/searchCustomerLocations';
import translate from 'src/core/services/translate';
import PinOnMapModalResolver from '../modals/PinOnMapModalResolver';

type Props = {
  change: (field: string, value: any) => void;
  newOptimizedRouteLocationsLength: number;
  onSubmit: () => void;
  pickupTypeId: number;
  pinAddress: Address;
  routeLocation: RouteLocation;
};

export default function RouteStopsAddForm({
  change,
  newOptimizedRouteLocationsLength,
  onSubmit,
  pickupTypeId,
  pinAddress,
  routeLocation,
}: Props) {
  const dispatch = useDispatch();
  const [isPinOnMapSelected, setPinOnMapSelected] = useState(false);
  const [isPinOnMapModalOpen, setPinOnMapModalOpen] = useState(false);
  const [mapCenterByVendor, setMapCenterByVendor] = useState<google.maps.LatLngLiteral | undefined>(undefined);

  const vendorId = useSelector(currentVendorId);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { reasonCodeTypes } = useSelector(state => state.common.reasonCodeTypes);
  const { pickupTypes } = useSelector(state => state.routes.pickupTypes);

  const handleAddStop = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!routeSummary) return;

    onSubmit();

    setPinOnMapSelected(false);
    change('routeLocation', undefined);
    change('pinAddress', undefined);
    change('pickupTypeId', getInitialPickupTypeId(routeSummary.vehicleTypeId));
    change('reasonCodeTypeId', undefined);
    change('wasteMaterialTypeId', undefined);
    change('positionTypeId', undefined);
    change('jobPriorityTypeId', undefined);
  };

  const loadCustomerLocations = debounce((searchTerm, onOptionsLoaded) => {
    if (!routeSummary) return;
    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }

    const { vehicleTypeId, date } = routeSummary;
    searchCustomerLocations(vendorId, searchTerm, vehicleTypeId, undefined, date).then((customerGroups: any) => {
      const customerLocationsOptions = customerGroups.map((customerGroup: any) => ({
        ...customerGroup,
        options: customerGroup.options.map((customer: any) => ({
          ...customer,
          isOnHold: customer.value.service.serviceContractAccountStatusTypeIdAtDate === ON_HOLD,
        })),
      }));

      return onOptionsLoaded(customerLocationsOptions);
    });
  }, 500);

  const clearPinnedAddress = () => {
    change('pinAddress', null);
    setPinOnMapSelected(false);
  };

  const setOpenSelectOnMap = () => {
    loadVendor(vendorId)(dispatch).then((response: any) => {
      const mapCenterByVendor = { lat: response.homeAddress.latitude, lng: response.homeAddress.longitude };
      setMapCenterByVendor(mapCenterByVendor);
      setPinOnMapModalOpen(true);
    });
  };

  const handleAddressSelection = (address: any) => {
    const newAddress = {
      ...address,
      formattedAddress: address.formattedAddress || address.line1,
    };

    change('pinAddress', newAddress);
    setPinOnMapSelected(true);
    setPinOnMapModalOpen(false);
  };

  const reasonCodeOptions = reasonCodeTypes.map(reasonCode => ({
    label: reasonCode.name,
    value: reasonCode.id,
  }));

  const isFormButtonDisabled = !(routeLocation || pinAddress) || !pickupTypeId;

  return (
    <>
      <Grid position="relative" multiLine alignRight={isPinOnMapSelected} margin="small no" verticalAlign="center">
        {routeSummary?.vehicleTypeId === DELIVERY_UTILITY_ID ? (
          <CustomerLocationWithPin
            colSize={5}
            fieldName="routeLocation"
            getOptions={loadCustomerLocations}
            handleClearAddress={clearPinnedAddress}
            handlePinClick={setOpenSelectOnMap}
            isDeliveryUtility
            isPinIconAbsolute
            isPinOnMapSelected={!!isPinOnMapSelected}
            margin="no"
            renderCustomerLocationOptionLabel={renderCustomerLocationOptionLabel}
          />
        ) : (
          <GridColumn size="4/12">
            <TypedField
              name="routeLocation"
              component={TypeAhead}
              validate={[isRequired]}
              props={
                {
                  margin: 'no',
                  getOptions: loadCustomerLocations,
                  isClearable: true,
                  placeholder: `${translate('common.customer')} / ${translate('common.location')}`,
                  props: {
                    getOptionLabel: renderCustomerLocationOptionLabel,
                  },
                } as any
              }
            />
          </GridColumn>
        )}

        {isPinOnMapSelected && (
          <GridColumn size="2/12">
            <TypedField
              name="wasteMaterialTypeId"
              component={WasteTypeDropdown}
              props={{
                withPlaceholder: true,
                dropdownProps: {
                  margin: 'no',
                  isClearable: true,
                  id: 'waste-material-type',
                },
              }}
            />
          </GridColumn>
        )}

        <GridColumn size="2/12">
          <TypedField
            name="pickupTypeId"
            component={PickupTypeDropdown}
            validate={[isRequired]}
            props={{
              pickupTypes: (pickupTypes as any)[0].pickupTypes || [],
              withPlaceholder: true,
              dropdownProps: {
                margin: 'no',
                isClearable: true,
              },
            }}
          />
        </GridColumn>

        <GridColumn size="2/12">
          <TypedField
            name="positionTypeId"
            component={JobPositionTypeDropdown}
            props={{
              isOptimizedOptionHidden:
                routeSummary?.vehicleTypeId === ROLL_OFF_ID || routeSummary?.routeStatusTypeId !== SCHEDULED,
              withLabel: true,
              dropdownProps: {
                margin: 'no no small',
              },
            }}
          />
        </GridColumn>

        <GridColumn size="2/12">
          <TypedField
            name="reasonCodeTypeId"
            component={Dropdown}
            props={{
              margin: 'no',
              isClearable: true,
              options: reasonCodeOptions,
              label: translate('common.reasonCode'),
            }}
          />
          <TypedField
            name="jobPriorityTypeId"
            component={JobPriorityTypesDropdown}
            props={{
              withLabel: true,
              dropdownProps: {
                margin: 'no ',
              },
            }}
          />
        </GridColumn>

        <GridColumn margin="no" size={isPinOnMapSelected ? '1/12' : '2/12'} align="right">
          <PopoverWrapper
            margin="no"
            triggerButton={
              <Button
                color="primary"
                disabled={isFormButtonDisabled || newOptimizedRouteLocationsLength > 0}
                onClick={handleAddStop}
                size="xMedium"
              >
                {translate('routes.addStop')}
              </Button>
            }
            popoverContent={
              newOptimizedRouteLocationsLength ? (
                <Popover>{translate('routes.alertMessages.addingJobDisabled')}</Popover>
              ) : undefined
            }
            size="large"
          />
        </GridColumn>
      </Grid>

      {isPinOnMapModalOpen && (
        <PinOnMapModalResolver
          handleAddressSelection={handleAddressSelection}
          handleCloseModal={() => setPinOnMapModalOpen(false)}
          mapCenterByVendor={mapCenterByVendor}
          noLoad
        />
      )}
    </>
  );
}
