import React from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { getSourcingNotes } from '../../ducks/opportunities';
import { PageLoadingOverlay } from '../../../common/components/styled';
import SourcingNotesModal from './SourcingNotesModal';
import { TypedResolver } from '../../../core/components';

interface Props extends RouteComponentProps {
  title: string;
  subTitle?: string;
  itemId: number;
  eventServiceId: number;
  onCancel(): void;
}

const SourcingNotesModalResolver: React.FC<Props> = ({ title, subTitle, itemId, eventServiceId, onCancel }) => {
  const dispatch = useDispatch();

  const loadDependencies = () => {
    const promises = [getSourcingNotes(itemId, eventServiceId)(dispatch)];
    return Promise.all(promises);
  };

  return (
    <TypedResolver
      successComponent={SourcingNotesModal}
      successProps={{ title, subTitle, onCancel }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default withRouter(SourcingNotesModalResolver);
