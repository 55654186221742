import React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { UpdateTrackerRouteSwitch } from '../../core/components';

interface FormValues {
  shouldRecreateRoutes: boolean;
}

interface Props {
  hasScheduledDailyRoutes?: boolean;
  isFormDirty?: boolean;
}

const UpdateTrackerRouteSwitchWrapper: React.FC<Props & InjectedFormProps<FormValues, Props>> = ({
  hasScheduledDailyRoutes,
  isFormDirty,
}) => (
  <UpdateTrackerRouteSwitch
    hasScheduledDailyRoutes={hasScheduledDailyRoutes}
    isFormDirty={isFormDirty}
    topMargin="small"
  />
);

export default reduxForm<FormValues, Props>({
  enableReinitialize: true,
  form: 'updateTrackerRouteSwitchWrapper',
})(UpdateTrackerRouteSwitchWrapper);
