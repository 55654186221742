import React, { useState } from 'react';

import { Icon } from '../../../../../core/components';
import translate from '../../../../../core/services/translate';
import {
  RouteTemplateBuilderMapControlContainer,
  RouteTemplateBuilderLegendItem,
  RouteTemplateBuilderLegendItemColor,
  RouteTemplateBuilderLegendItems,
  RouteTemplateBuilderMapControlToggle,
  RouteTemplateStatus,
} from '../../../styled/RouteTemplateBuilderMap';
import {
  RTB_TEMPLATE_STATUSES_MAP,
  PUBLISHED,
  NEW,
  UNASSIGNED_COLOR_SATELLITE,
  UNASSIGNED_FILTER,
  ASSIGNED_FILTER,
} from 'src/routes/constants/routeTemplateBuilder';
import { UNASSIGNED_COLOR } from 'src/routes/constants/routeTemplateBuilder';
import { useSelector } from 'src/core/hooks/useSelector';
import useSelectedDaysOfService from 'src/routes/hooks/useSelectedDaysOfService';
import { RouteTemplateBuilderService } from 'src/routes/interfaces/routeTemplateBuilder/RouteTemplateBuilderService';
import { uniq, flatten } from 'lodash-es';

interface Props {
  isSatelliteView: boolean;
  services: RouteTemplateBuilderService[];
}

export const RouteTemplateBuilderLegend: React.FC<Props> = ({ services, isSatelliteView }) => {
  const [isOpen, setIsOpen] = useState(false);

  const colorsMap = useSelector(s => s.routes.routeTemplateBuilder.routeColorsMap);
  const selectedDraft = useSelector(s => s.routes.routeTemplateBuilder.selectedDraft);
  const routeTemplateIds = useSelector(s => s.routes.routeTemplateBuilder.mapFilters.routeTemplateIds);
  const stopFilterType = useSelector(s => s.routes.routeTemplateBuilder.mapFilters.stopFilterType);
  const daysOfServiceFilter = useSelector(s => s.routes.routeTemplateBuilder.mapFilters.dayOfServiceIds);
  const { selectedDaysOfServiceBundle } = useSelectedDaysOfService();

  const isActuallyOpen = !selectedDraft ? isOpen : false;

  const { assignedServicesAvailable, unassignedServicesAvailable } = React.useMemo(() => {
    let assignedServicesAvailable = false;
    let unassignedServicesAvailable = false;

    for (const service of services) {
      const potentialStopColors = uniq(
        flatten(
          (service.daysOfService || [])
            .filter(d => daysOfServiceFilter.indexOf(d.id) !== -1)
            .map(d => {
              const potentialTemplateIds: (string | null)[] = [];
              const { routeName } = d;

              if (!!routeName && routeTemplateIds.indexOf(routeName) !== -1) {
                potentialTemplateIds.push(routeName);
              }

              if (!routeName) {
                potentialTemplateIds.push(null);
              }

              return potentialTemplateIds;
            }),
        ),
      )
        .map(templateId => {
          if (!!templateId) {
            return stopFilterType !== UNASSIGNED_FILTER ? templateId : null;
          }

          return stopFilterType !== ASSIGNED_FILTER ? 0 : null;
        })
        .filter(color => typeof color === 'string') as number[];

      if (!assignedServicesAvailable) {
        assignedServicesAvailable = !!potentialStopColors.filter(v => v).length;
      }
      if (!unassignedServicesAvailable) {
        unassignedServicesAvailable = potentialStopColors.indexOf(0) !== -1;
      }

      if (assignedServicesAvailable && unassignedServicesAvailable) {
        break;
      }
    }

    return {
      assignedServicesAvailable,
      unassignedServicesAvailable,
    };
  }, [services, daysOfServiceFilter, stopFilterType, routeTemplateIds]);

  return (
    <RouteTemplateBuilderMapControlContainer isOpen={isActuallyOpen} disabled={!!selectedDraft}>
      <RouteTemplateBuilderMapControlToggle onClick={() => setIsOpen(!isOpen)}>
        <Icon height="14px" icon={isOpen ? 'arrowDownFill' : 'arrowUpFill'} />

        <div>{translate('routeTemplateBuilder.legend')}</div>
      </RouteTemplateBuilderMapControlToggle>

      {isActuallyOpen && (
        <RouteTemplateBuilderLegendItems>
          {stopFilterType !== 'assigned' && unassignedServicesAvailable && (
            <RouteTemplateBuilderLegendItem>
              <RouteTemplateBuilderLegendItemColor
                hasBorder={isSatelliteView}
                color={isSatelliteView ? UNASSIGNED_COLOR_SATELLITE : UNASSIGNED_COLOR}
              />

              <div>{translate('routeTemplateBuilder.unassigned')}</div>
            </RouteTemplateBuilderLegendItem>
          )}

          {stopFilterType !== 'unassigned' &&
            assignedServicesAvailable &&
            selectedDaysOfServiceBundle?.routeTemplates.map(template => {
              const colorMapEntry = colorsMap[template.name];

              if (!colorMapEntry || routeTemplateIds.indexOf(template.name) === -1) {
                return null;
              }

              return (
                <RouteTemplateBuilderLegendItem key={template.id}>
                  <RouteTemplateBuilderLegendItemColor color={colorMapEntry.color} />

                  <div>{template.name}</div>

                  {!template.isRouteTemplateEnabled && template.statusId !== NEW && (
                    <RouteTemplateStatus neutral margin="no no no xxSmall">
                      {translate('common.inactive')}
                    </RouteTemplateStatus>
                  )}

                  {template.statusId !== PUBLISHED && (
                    <RouteTemplateStatus statusId={template.statusId} margin="no no no xxSmall">
                      {RTB_TEMPLATE_STATUSES_MAP[template.statusId].name}
                    </RouteTemplateStatus>
                  )}
                </RouteTemplateBuilderLegendItem>
              );
            })}
        </RouteTemplateBuilderLegendItems>
      )}
    </RouteTemplateBuilderMapControlContainer>
  );
};
