import React, { PureComponent } from 'react';

import { Modal } from '../../../core/components';
import { Button, ModalTitle } from '../../../core/components/styled';
import { UploadDocumentArea } from '../styled/UnassignedWeightTickets';
import translate from '../../../core/services/translate';
interface Props {
  vendorId: number;
  uploadFile: (files: any) => void;
  closeModal: () => void;
}

interface State {
  isValidHover: boolean;
}

class WeightTicketsUploadModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isValidHover: false,
    };
  }

  private uploadFileRef: any;

  setUploadFileRef = (ref: any) => {
    this.uploadFileRef = ref;
  };

  isValidFile = (items: any) => items && items.length === 1 && items[0].kind === 'file';

  onDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.isValidFile(e.dataTransfer.items)) {
      this.setState({ isValidHover: true });
    }
  };

  onDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.state.isValidHover && this.isValidFile(e.dataTransfer.items)) {
      this.setState({ isValidHover: true });
    }
  };

  onDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isValidHover: false });
  };

  onDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { uploadFile } = this.props;
    uploadFile(e.dataTransfer.files);
  };

  openFileUploader = () => {
    this.uploadFileRef.click();
  };

  onFileInputChange = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { uploadFile } = this.props;
    uploadFile(e.target.files);
  };

  render() {
    const { closeModal } = this.props;
    const { isValidHover } = this.state;
    return (
      <Modal onClose={closeModal} padding="medium" size="medium">
        <ModalTitle>{translate('autoDispatch.uploadWeightTicket')}</ModalTitle>
        <UploadDocumentArea
          isHoveringOver={isValidHover}
          //isLoading={this.state.isLoading}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          onDragOver={this.onDragOver}
          onDrop={this.onDrop}
        >
          <div>
            <div>{translate('autoDispatch.dropZoneMessage')}</div>
            <Button color="primary" margin="small no no no" onClick={this.openFileUploader} type="button">
              {translate('common.chooseFile')}
            </Button>
            <input
              accept=".jpg,.png,.pdf,.ppt,.xls,.xlsx,.docx,.txt,.doc,.csv"
              id="fileSelect"
              onChange={this.onFileInputChange}
              ref={this.setUploadFileRef}
              style={{ display: 'none' }}
              type="file"
            />
          </div>
        </UploadDocumentArea>
      </Modal>
    );
  }
}

export default WeightTicketsUploadModal;
