import { camelCase, get, map } from 'lodash-es';

import { PICKUP_ISSUE_TYPES, WASTE_AUDIT_EXCEPTION_TYPES, WASTE_AUDIT_LOCATION_STATUS_TYPES } from '../constants';
import { WASTE_AUDIT } from '../../common/constants';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const transformLoadImages = (images: any[]) =>
  map(images, image => {
    const pickupExceptionId = get(image, 'pickupExceptionType.id');
    const pickupExceptionSubTypeId = get(image, 'pickupExceptionSubType.id');
    const pickupExceptionTechnicalName = get(image, 'pickupExceptionType.technicalName');

    let pickupExceptionName;
    if (pickupExceptionId) {
      if (image.vehicleType.technicalName === WASTE_AUDIT) {
        if (pickupExceptionSubTypeId) {
          pickupExceptionName = WASTE_AUDIT_EXCEPTION_TYPES[pickupExceptionSubTypeId].name;
        } else {
          pickupExceptionName = WASTE_AUDIT_LOCATION_STATUS_TYPES[pickupExceptionId].name;
        }
      } else {
        pickupExceptionName = PICKUP_ISSUE_TYPES[pickupExceptionId] ? PICKUP_ISSUE_TYPES[pickupExceptionId].name : '-';
      }
    }
    let pickupIssueReportedReasonTypeName;

    if (pickupExceptionTechnicalName) {
      pickupIssueReportedReasonTypeName = !image.locationId
        ? translate(`dashboard.${camelCase(pickupExceptionTechnicalName)}`)
        : pickupExceptionName;
    }

    map(image.images, img => {
      if (img.imageTimingType) {
        pickupIssueReportedReasonTypeName = translate(
          createTranslationKey(img.imageTimingType.technicalName, 'vendors.pickupIssueReasonTypes'),
        );
      }
    });

    return {
      ...image,
      pickupIssueReportedReasonTypeName,
    };
  });

export default transformLoadImages;
