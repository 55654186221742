import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { map, size, get } from 'lodash-es';
import { Field, reduxForm, isValid, getFormValues, InjectedFormProps } from 'redux-form';

import { AppState } from 'src/store';
import { checkRouteIdsHasScheduledDailyRoutes } from '../../services/routeTemplate';
import { DELIVERY_UTILITY_ID, FRONT_LOAD_ID, RESIDENTIAL_ID } from 'src/fleet/constants/vehicleTypes';
import { Dropdown, UpdateTrackerRouteSwitch, TypedField } from '../../../core/components';
import { DuckFunction } from 'src/contracts/ducks';
import { getComboRouteOptions } from './utils/CustomOptionsUtils';
import { Grid, GridColumn, PanelSection, Button, ButtonSet } from '../../../core/components/styled';
import { isRequired } from '../../../utils/services/validator';
import { loadRouteTemplatesForReassignment } from '../../ducks';
import { ROLL_OFF_ID } from 'src/fleet/constants';
import { WEEKDAYS_BY_SHORTCODE } from '../../../core/constants';
import JobPositionTypeDropdown from 'src/common/components/JobPositionTypeDropdown';
import translate from '../../../core/services/translate';

const dayOfWeekOptions = map(WEEKDAYS_BY_SHORTCODE, dayOfWeek => ({
  label: dayOfWeek.name,
  value: dayOfWeek.shortCode,
}));

interface ComponentProps {
  isFormValid: boolean;
  isLoading: boolean;
  loadRouteTemplatesForReassignment: DuckFunction<typeof loadRouteTemplatesForReassignment>;
  routeTemplatesForReassignment: any[];
  sourceRouteTemplateId: number;
  targetRouteTemplateIdValue: number;
  vehicleTypeId: number;
  vendorId: number;
  wasteMaterialTypeId?: number;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class TransferRouteTemplateLocationsForm extends PureComponent<Props> {
  readonly state = {
    hasScheduledDailyRoutes: false,
  };

  componentDidUpdate(prevProps: ComponentProps) {
    const { sourceRouteTemplateId, vendorId, targetRouteTemplateIdValue } = this.props;

    if (targetRouteTemplateIdValue !== prevProps.targetRouteTemplateIdValue) {
      checkRouteIdsHasScheduledDailyRoutes(`${sourceRouteTemplateId},${targetRouteTemplateIdValue}`, vendorId).then(
        response => {
          this.setState({ hasScheduledDailyRoutes: response });
        },
      );
    }
  }

  loadAvailableRouteTemplatesForReassignment = (event: any, scheduledDay: Date | string) => {
    const { vendorId, sourceRouteTemplateId, vehicleTypeId, wasteMaterialTypeId, loadRouteTemplatesForReassignment } =
      this.props;

    loadRouteTemplatesForReassignment(
      vendorId,
      sourceRouteTemplateId,
      vehicleTypeId,
      wasteMaterialTypeId,
      scheduledDay,
    );
  };

  render() {
    const { isLoading, isFormValid, routeTemplatesForReassignment, vehicleTypeId, handleSubmit } = this.props;
    const { hasScheduledDailyRoutes } = this.state;

    const routeTemplatesForReassignmentOptions = map(routeTemplatesForReassignment, ({ name, id }) => ({
      label: name,
      value: id,
    }));

    const groupedCustomOptions = [
      ...(vehicleTypeId === FRONT_LOAD_ID || vehicleTypeId === RESIDENTIAL_ID || vehicleTypeId === DELIVERY_UTILITY_ID
        ? getComboRouteOptions(vehicleTypeId, routeTemplatesForReassignment, false, true)
        : routeTemplatesForReassignmentOptions),
    ];

    return (
      <form onSubmit={handleSubmit}>
        <PanelSection vertical isLoading={isLoading}>
          <Grid centered>
            <GridColumn size="6/12">
              <Field
                name="scheduledDay"
                component={Dropdown as any}
                margin="large no"
                options={dayOfWeekOptions}
                label={translate('routes.dayOfService')}
                validate={[isRequired]}
                onChange={this.loadAvailableRouteTemplatesForReassignment}
              />
              <Field
                name="targetRouteTemplateId"
                component={Dropdown}
                options={groupedCustomOptions}
                label={translate('routes.targetRouteTemplate')}
                disabled={!size(groupedCustomOptions)}
                margin="large no"
                validate={[isRequired]}
              />
              <TypedField
                name="positionTypeId"
                component={JobPositionTypeDropdown}
                props={{
                  isOptimizedOptionHidden: vehicleTypeId === ROLL_OFF_ID,
                  withLabel: true,
                  dropdownProps: {
                    margin: 'no no small',
                  },
                }}
              />
              <UpdateTrackerRouteSwitch isFormDirty={isFormValid} hasScheduledDailyRoutes={hasScheduledDailyRoutes} />
            </GridColumn>
          </Grid>
          <ButtonSet margin="lMedium no no">
            <Button color="primary">{translate('routes.transferStops')}</Button>
          </ButtonSet>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const formValues = getFormValues('transferRouteTemplateLocations')(state);

  return {
    isFormValid: isValid('transferRouteTemplateLocations')(state),
    isLoading: state.routes.routeTemplatesForReassignment.isLoading,
    routeTemplatesForReassignment: state.routes.routeTemplatesForReassignment.routeTemplatesForReassignment as any,
    targetRouteTemplateIdValue: get(formValues, 'targetRouteTemplateId'),
  };
};

const mapDispatchToProps = {
  loadRouteTemplatesForReassignment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, ComponentProps>({
    form: 'transferRouteTemplateLocations',
    enableReinitialize: true,
  })(TransferRouteTemplateLocationsForm),
);
