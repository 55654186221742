import { push } from 'connected-react-router';
import { filter, isEmpty, map, reduce, size } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getFormValues } from 'redux-form';

import {
  PageActions,
  PageBackButton,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { Table } from 'src/core/components';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  Message,
  Panel,
  PanelSection,
  PanelSectionGroup,
  PanelSectionTitle,
} from 'src/core/components/styled';
import { TableCell } from 'src/core/components/Table';
import { TABLE_ROW_HEIGHT_LARGE, TODAY_FORMATTED } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import {
  createErrorNotificationIncludingTechnicalMessage,
  createSuccessNotification,
} from 'src/core/services/createNotification';
import { multiWordAndSearch } from 'src/core/services/search';
import translate from 'src/core/services/translate';
import OpenBillsForm from 'src/finance/components/forms/OpenBillsForm';
import { OpenBillPdfViewerModal } from 'src/finance/components/modals';
import EditPaymentDetailsModal from 'src/finance/components/modals/EditPaymentDetailsModal';
import { OpenBillsTableRow } from 'src/finance/components/pages/applyChecks/applyChecksPageSections';
import { PaymentDetail } from 'src/finance/components/styled/PaymentDetail';
import { PaymentDetailLabel } from 'src/finance/components/styled/PaymentDetailLabel';
import { PaymentDetailsWrapper } from 'src/finance/components/styled/PaymentDetailsWrapper';
import { PaymentDetailValue } from 'src/finance/components/styled/PaymentDetailValue';
import { INVOICE_PAYMENT_DEFAULT_STATUSES } from 'src/finance/constants/invoicePaymentStatuses';
import { OPEN_BILLS_TABLE_CELLS } from 'src/finance/constants/openBillsTableCells';
import { PAYMENT_STATUS_OPEN_ID } from 'src/finance/constants/paymentStatuses';
import {
  assignOpenBill,
  exportOpenBill,
  loadOpenBills,
  loadPaymentDetails,
  resetOpenBills,
  resetPayments,
  resetPaymentStatistics,
  resetPaymentStatuses,
  unassignOpenBill,
  updatePayment,
} from 'src/finance/ducks';
import {
  CreateEditPaymentData,
  OpenBill,
  PaymentAssignedBill,
  PaymentStatusOptions,
} from 'src/finance/interfaces/ApplyChecks';
import { currency } from 'src/utils/services/formatter';
import { getQueryParams } from 'src/utils/services/queryParams';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { PaymentsSearchQueryParams } from './ApplyChecksPageResolver';
import AssignedBillsTableRow from './applyChecksPageSections/AssignedBillsTableRow';
import PaymentsDetailsSection from './applyChecksPageSections/PaymentsDetailsSection';

interface ModalData {
  assignedBills: PaymentAssignedBill[];
  paymentDate: StringOrDate;
  paymentTotal: number;
}

const tableCellWidths = {
  checkbox: '6%',
  id: '10%',
  billDate: '15%',
  appliedAmount: '15%',
  invoiceTotal: '15%',
  invoiceBalance: '15%',
  amountToApply: '30%',
};

const PaymentDetailsPage = () => {
  const location = useLocation<PaymentsSearchQueryParams>();
  const search = getQueryParams(location.search);
  const searchParams = Object.entries(search)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');

  const vendorId = useSelector(currentVendorId);
  const {
    openBills: openBillsData,
    isLoading: isLoadingOpenBills,
    isExportingBills: isExportingOpenBills,
  } = useSelector(state => state.finance.openBills);

  const {
    paymentDetails,
    paymentDetails: { customerId, customerName, paymentStatusId, paymentNumber, receivedViaId, paymentMethodId },
    isLoading: isLoadingPaymentDetails,
  } = useSelector(state => state.finance.paymentDetails);

  const billTotalAmount = reduce(paymentDetails.assignedBills, (acc, bill) => acc + bill.invoiceTotal, 0);
  const billBalance = reduce(paymentDetails.assignedBills, (acc, bill) => acc + bill.invoiceBalance, 0);

  const [isEditPaymentDetailsModalOpen, setIsEditPaymentDetailsModalOpen] = useState(false);
  const [isEditPaymentDetailsModalEditable, setIsEditPaymentDetailsModalEditable] = useState(false);
  const [selectedOpenBillId, setSelectedOpenBillId] = useState(0);
  const [isOpenBillPdfViewerModalOpen, setIsOpenBillPdfViewerModalOpen] = useState(false);
  const [selectedOpenBillDocumentUrl, setSelectedOpenBillDocumentUrl] = useState<string | null>(null);
  const [selectedAssignedBills, setSelectedAssignedBills] = useState<number[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const originalAppliedAmount = reduce(paymentDetails.assignedBills, (acc, bill) => acc + bill.appliedAmount, 0);
  const originalPaymentBalance = paymentDetails.paymentTotal && paymentDetails.paymentTotal - originalAppliedAmount;
  const openBills = openBillsData?.bills;

  const [paymentDate, setPaymentDate] = useState<StringOrDate>(paymentDetails.paymentDate || TODAY_FORMATTED);
  const [paymentTotal, setPaymentTotal] = useState(paymentDetails.paymentTotal || 0);
  const [assignedBills, setAssignedBills] = useState(paymentDetails.assignedBills);
  const [appliedAmountTotal, setAppliedAmountTotal] = useState(originalAppliedAmount);
  const [currentPaymentBalance, setCurrentPaymentBalance] = useState(originalPaymentBalance);
  const paymentStatusOptions = useSelector(state => state.finance.paymentStatuses.paymentStatuses);
  const billStatuses = useSelector(state => state.finance.billStatuses.billStatuses);

  const onAmountToApplyChange = (billId: number, paymentSubTotal: number) => {
    const updatedAssignedBills = assignedBills.map(bill => ({
      ...bill,
      paymentSubTotal: billId === bill.id ? paymentSubTotal : bill.paymentSubTotal,
    }));

    const updatedAppliedAmount = reduce(updatedAssignedBills, (acc, bill) => acc + bill.paymentSubTotal, 0);

    const updatedPaymentBalance = paymentTotal - updatedAppliedAmount;

    setAssignedBills(updatedAssignedBills);
    setAppliedAmountTotal(updatedAppliedAmount);
    setCurrentPaymentBalance(updatedPaymentBalance);
  };

  const dispatch = useDispatch();
  const formValues: any = useSelector(getFormValues('openBillsForm'));

  useEffect(() => {
    setAssignedBills(paymentDetails.assignedBills);
    setPaymentDate(paymentDetails.paymentDate || TODAY_FORMATTED);
    setPaymentTotal(paymentDetails.paymentTotal);
  }, [paymentDetails]);

  // cleanup on unmount
  useEffect(
    () => () => {
      dispatch(resetPaymentStatistics());
      dispatch(resetPayments());
      dispatch(resetOpenBills());
      dispatch(resetPaymentStatuses());
    },
    [dispatch],
  );

  const noLoadingIndicator = true;
  const refreshData = async () =>
    Promise.all([
      loadPaymentDetails({ vendorId, paymentId: paymentDetails.id })(dispatch),
      loadOpenBills({
        billStatusIds: INVOICE_PAYMENT_DEFAULT_STATUSES,
        vendorId,
        customerId,
        excludedPaymentId: paymentDetails.id,
        noLoadingIndicator,
      })(dispatch),
    ]);

  const filteredOpenBills = filter(openBills, (bill: OpenBill) => {
    // Extract payment status IDs from form values
    const selectedStatusIds = formValues?.paymentStatusId || [];

    // Filter payment status options based on selected status IDs
    const selectedStatusOptions: PaymentStatusOptions[] = paymentStatusOptions.filter(option =>
      selectedStatusIds.includes(option.value),
    );

    // Check if form values are empty
    if (isEmpty(formValues)) {
      return true; // Include all bills if form values are empty
    }

    // Check if any selected status options match the bill status ID
    const isStatusMatch = selectedStatusOptions.some(option => option.value === bill.billStatusId);

    // Check if search term matches customer name or location name
    const isSearchMatch =
      !formValues.searchTerm ||
      multiWordAndSearch(bill.customerName, formValues.searchTerm) ||
      multiWordAndSearch(bill.locationName, formValues.searchTerm);

    // Include the bill if any of the conditions are true
    return isStatusMatch && isSearchMatch;
  });

  const virtualizedPropsOpenBills = {
    height: Math.min(filteredOpenBills.length * TABLE_ROW_HEIGHT_LARGE, TABLE_ROW_HEIGHT_LARGE * 8) || 1,
    itemSize: TABLE_ROW_HEIGHT_LARGE,
  };

  const virtualizedAssignedBills = {
    height:
      Math.min(
        size(paymentDetails.assignedBills) && size(paymentDetails.assignedBills) * TABLE_ROW_HEIGHT_LARGE,
        TABLE_ROW_HEIGHT_LARGE * 8,
      ) || 1,
    itemSize: TABLE_ROW_HEIGHT_LARGE,
  };

  const closeBillPdfViewerModal = () => {
    setIsOpenBillPdfViewerModalOpen(false);
    setSelectedOpenBillDocumentUrl(null);
    setSelectedOpenBillId(0);
  };

  const handleOpenEditPaymentDetailsModal = (isEditable?: boolean) => {
    setIsEditPaymentDetailsModalEditable(!!isEditable);
    setIsEditPaymentDetailsModalOpen(true);
  };

  const closeEditPaymentDetailsModal = (reset?: boolean) => {
    if (reset) {
      setPaymentDate(paymentDetails.paymentDate || TODAY_FORMATTED);
      setPaymentTotal(paymentDetails.paymentTotal || 0);
    }
    setIsEditPaymentDetailsModalOpen(false);
  };

  const handleAssignBillToPayment = (invoiceId: number) => {
    assignOpenBill(
      vendorId,
      invoiceId,
      paymentDetails.id,
      customerId,
    )(dispatch)
      .then(async () => {
        await refreshData();
        createSuccessNotification(translate('finance.alertMessages.openBillAssignSuccess'));
      })
      .catch(e => {
        createErrorNotificationIncludingTechnicalMessage(
          e,
          translate('finance.alertMessages.openBillAssignError'),
          'finance.alertMessages',
        );
      });
  };

  const goToApplyChecksPage = () => {
    dispatch(push('/finance/payments'));
  };

  const handleApplyCheck = () => {
    const dataToSubmit: CreateEditPaymentData = {
      customerId,
      paymentTotal,
      paymentMethodId,
      receivedViaId,
      paymentNumber,
      paymentStatusId,
      receivedDate: '',
      paymentDate,
      applyPayment: true,
      paymentDetails: map(assignedBills, (detail: PaymentAssignedBill) => {
        return {
          id: detail.detailId,
          customerInvoiceId: detail.id,
          paymentSubTotal: detail.paymentSubTotal,
        };
      }),
    };

    if (paymentDetails.id) {
      updatePayment(
        paymentDetails.id,
        dataToSubmit,
      )(dispatch)
        .then(() => {
          createSuccessNotification(translate('finance.alertMessages.paymentSaved'));
          goToApplyChecksPage();
        })
        .catch(e => {
          createErrorNotificationIncludingTechnicalMessage(
            e,
            translate('finance.alertMessages.paymentSaveError'),
            'finance.alertMessages',
          );
        });
    }
  };

  const handleSaveForLater = (redirect: boolean, data: ModalData) => {
    const { paymentTotal, paymentDate, assignedBills } = data;
    const dataToSubmit: CreateEditPaymentData = {
      applyPayment: false,
      customerId,
      paymentTotal,
      paymentMethodId,
      receivedViaId,
      paymentNumber,
      paymentStatusId,
      receivedDate: '',
      paymentDate,
      paymentDetails: map(assignedBills, (detail: PaymentAssignedBill) => ({
        id: detail.detailId,
        customerInvoiceId: detail.id,
        paymentSubTotal: detail.paymentSubTotal || 0,
      })),
    };

    updatePayment(
      paymentDetails.id,
      dataToSubmit,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('finance.alertMessages.paymentSaved'));
        if (redirect) {
          goToApplyChecksPage();
        } else {
          refreshData();
          setIsEditPaymentDetailsModalOpen(false);
        }
      })
      .catch(e => {
        createErrorNotificationIncludingTechnicalMessage(
          e,
          translate('finance.alertMessages.paymentSaveError'),
          'finance.alertMessages',
        );
      });
  };

  const handleExportOpenBill = () => exportOpenBill(selectedOpenBillId)(dispatch);

  const openBillPdfViewerModal = (openBillId: number, openBillDocumentUrl: string) => {
    setIsOpenBillPdfViewerModalOpen(true);
    setSelectedOpenBillDocumentUrl(openBillDocumentUrl);
    setSelectedOpenBillId(openBillId);
  };

  const handleUnassignOpenBill = async (openBillIds: number[]) => {
    if (!(await confirm(translate('finance.alertMessages.confirmUnassignOpenBill')))) return;
    setIsSaving(true);
    unassignOpenBill(
      paymentDetails.id,
      openBillIds,
    )(dispatch)
      .then(async () => {
        await refreshData();
        createSuccessNotification(translate('finance.alertMessages.unassignBilledChargeSuccess'));
      })
      .catch(e => {
        createErrorNotificationIncludingTechnicalMessage(
          e,
          translate('finance.alertMessages.unassignBilledChargeError'),
          'finance.alertMessages',
        );
      })
      .finally(() => setIsSaving(false));
  };

  const handleCheckboxOnChange = (id: number, checked: boolean) =>
    checked
      ? setSelectedAssignedBills(Array.from(new Set([...selectedAssignedBills, id])))
      : setSelectedAssignedBills(selectedAssignedBills.filter(billId => billId !== id));

  const showUnassignBillButton = useMemo(() => selectedAssignedBills.length > 0, [selectedAssignedBills]);

  const handleUnassignButtonClick = () => handleUnassignOpenBill(selectedAssignedBills);

  const customerOptions = map(openBills, (openBill: OpenBill) => ({
    value: openBill.customerId,
    label: openBill.customerName.toLowerCase(),
  }));

  const isPaymentEditable = paymentStatusId === PAYMENT_STATUS_OPEN_ID;

  const assignedBillsTableCells: TableCell[] = [
    {
      name: 'unassignCheckbox',
      width: tableCellWidths.checkbox,
      sortable: false,
    },
    {
      name: 'id',
      label: translate('finance.applyChecks.billId'),
      width: tableCellWidths.id,
      sortable: true,
    },
    {
      name: 'billDate',
      label: translate('finance.applyChecks.billDate'),
      width: tableCellWidths.billDate,
      sortable: true,
    },
    {
      name: 'appliedAmount',
      label: translate('finance.applyChecks.amountPosted'),
      width: tableCellWidths.appliedAmount,
      sortable: true,
    },
    {
      name: 'invoiceTotal',
      label: translate('finance.applyChecks.billAmount'),
      width: tableCellWidths.invoiceTotal,
      sortable: true,
    },
    {
      name: 'invoiceBalance',
      label: translate('finance.applyChecks.remainingBillBalance'),
      width: tableCellWidths.invoiceBalance,
      sortable: true,
    },
    {
      name: 'amountToApply',
      label: isPaymentEditable
        ? translate('finance.applyChecks.amountToApply')
        : translate('finance.applyChecks.appliedAmount'),
      width: tableCellWidths.amountToApply,
      sortable: false,
    },
  ];

  return (
    <PageContent isLoading={isLoadingPaymentDetails}>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer fluid>
            <PageBackButton id="back-button" to={`/finance/payments?${searchParams}`}>
              <PageBackButtonIcon />
            </PageBackButton>
            <PageTitle margin="small">{translate('finance.applyChecks.paymentDetails')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions flex>
          <ButtonSet align="right" margin="no">
            {/* Hidden as a requirement of RPI-427
              <Button margin="no small no no" line color="primary">
                {translate('finance.applyChecks.createMonthlyStatement')}
              </Button>
              <Button margin="no small no no" line color="primary">
                {translate('finance.applyChecks.createNewBillingStatement')}
              </Button>
          */}
            {showUnassignBillButton && (
              <Button
                disabled={!isPaymentEditable || isSaving}
                type="button"
                color="primary"
                margin="no small no no"
                line
                id="payment-details-unassign-button"
                onClick={handleUnassignButtonClick}
              >
                {translate('finance.unassign')}
              </Button>
            )}

            <Button
              disabled={!isPaymentEditable || isSaving}
              type="button"
              color="primary"
              margin="no"
              line
              id="payment-details-edit-button"
              onClick={() => {
                handleOpenEditPaymentDetailsModal(true);
              }}
            >
              {translate('common.edit')}
            </Button>
          </ButtonSet>
        </PageActions>
      </PageHeader>
      <Panel margin="no no xLarge">
        <PanelSectionGroup padding="no" width="100%" isLoading={false}>
          <PaymentsDetailsSection
            paymentDetails={paymentDetails}
            openEditPaymentDetailsModal={() => handleOpenEditPaymentDetailsModal(false)}
            paymentStatusOptions={paymentStatusOptions}
          />
        </PanelSectionGroup>
        <PanelSectionGroup padding="no" width="100%">
          <PanelSection withBorder vertical padding="no medium large">
            <Grid padding="small">
              <GridColumn padding="no" size="2/12">
                <PanelSectionTitle margin="medium no">
                  {translate('finance.applyChecks.assignedBills')}
                </PanelSectionTitle>
              </GridColumn>
              <GridColumn padding="no" size="10/12">
                <PaymentDetailsWrapper>
                  <PaymentDetail margin="medium no no">
                    <PaymentDetailLabel>{translate('finance.applyChecks.billTotal')}</PaymentDetailLabel>
                    <PaymentDetailValue>{currency(billTotalAmount || 0)}</PaymentDetailValue>
                  </PaymentDetail>
                  <PaymentDetail margin="medium no no">
                    <PaymentDetailLabel>{translate('finance.applyChecks.billBalance')}</PaymentDetailLabel>
                    <PaymentDetailValue>{currency(billBalance || 0)}</PaymentDetailValue>
                  </PaymentDetail>
                  <PaymentDetail margin="medium no no">
                    <PaymentDetailLabel>{translate('finance.applyChecks.paymentBalance')}</PaymentDetailLabel>
                    <PaymentDetailValue>{currency(originalPaymentBalance || 0)}</PaymentDetailValue>
                  </PaymentDetail>
                </PaymentDetailsWrapper>
              </GridColumn>
            </Grid>
            <PanelSection padding="no small">
              {!!size(assignedBills) && (
                <Table
                  cells={assignedBillsTableCells}
                  rowComponent={AssignedBillsTableRow}
                  rows={assignedBills}
                  rowProps={{
                    handleCheckboxOnChange,
                    handleUnassignOpenBill,
                    isEditable: isPaymentEditable,
                    isSaving,
                    onAmountToApplyChange,
                    tableCellWidths,
                  }}
                  scrollMarker
                  virtualized
                  virtualizedProps={virtualizedAssignedBills}
                />
              )}
              {openBills && !size(paymentDetails.assignedBills) && (
                <Message padding="sMedium no">{translate('finance.applyChecks.noAssignedBills')}</Message>
              )}
            </PanelSection>
          </PanelSection>
        </PanelSectionGroup>
        <PanelSectionGroup padding="no no lMedium" width="100%" isLoading={isLoadingOpenBills || isExportingOpenBills}>
          <PanelSection withBorder vertical padding="no medium large">
            <Grid padding="small">
              <GridColumn padding="no" size="4/12">
                <PanelSectionTitle margin="medium no">{translate('finance.applyChecks.openBills')}</PanelSectionTitle>
              </GridColumn>
              <GridColumn size="8/12" alignRight>
                <OpenBillsForm customerOptions={customerOptions} />
              </GridColumn>
            </Grid>
            <PanelSection padding="no small">
              {!!size(filteredOpenBills) && (
                <Table
                  cells={OPEN_BILLS_TABLE_CELLS}
                  rowComponent={OpenBillsTableRow}
                  rows={filteredOpenBills}
                  rowProps={{
                    handleAssignBillToPayment: handleAssignBillToPayment,
                    openBillPdfViewerModal: openBillPdfViewerModal,
                    isEditable: isPaymentEditable,
                    maxValue: currentPaymentBalance,
                    billStatuses,
                  }}
                  scrollMarker
                  virtualized
                  virtualizedProps={virtualizedPropsOpenBills}
                />
              )}
              {!size(openBills) && (
                <Message padding="sMedium no">{translate('finance.applyChecks.noOpenBills')}</Message>
              )}
            </PanelSection>
          </PanelSection>
        </PanelSectionGroup>
        <PanelSectionGroup>
          <PanelSection centered padding="no no medium">
            <PageActions flex>
              <Button
                margin="no small no no"
                line
                color="primary"
                onClick={handleApplyCheck}
                disabled={appliedAmountTotal !== paymentTotal || !isPaymentEditable || isSaving}
              >
                {translate('finance.applyChecks.processPayment')}
              </Button>
              <Button
                margin="no small no no"
                line
                color="primary"
                onClick={() =>
                  handleSaveForLater(true, {
                    assignedBills,
                    paymentTotal,
                    paymentDate,
                  })
                }
                disabled={!isPaymentEditable || isSaving}
              >
                {translate('finance.applyChecks.saveForLater')}
              </Button>
            </PageActions>
          </PanelSection>
        </PanelSectionGroup>
        {isEditPaymentDetailsModalOpen && (
          <EditPaymentDetailsModal
            assignedBills={assignedBills}
            closeModal={closeEditPaymentDetailsModal}
            customerId={customerId}
            customerName={customerName}
            handleCheckboxOnChange={handleCheckboxOnChange}
            handleSaveForLater={handleSaveForLater}
            isEditable={isEditPaymentDetailsModalEditable}
            isSaving={isSaving}
            paymentDate={paymentDate}
            paymentDetails={paymentDetails}
            paymentTotal={paymentTotal}
            setPaymentTotal={setPaymentTotal}
            unappliedAmount={currentPaymentBalance}
            virtualizedProps={virtualizedAssignedBills}
          />
        )}

        {isOpenBillPdfViewerModalOpen && (
          <OpenBillPdfViewerModal
            onDownload={handleExportOpenBill}
            onCancel={closeBillPdfViewerModal}
            selectedOpenBillId={selectedOpenBillId}
            selectedOpenBillDocumentUrl={selectedOpenBillDocumentUrl}
          />
        )}
      </Panel>
    </PageContent>
  );
};

export default PaymentDetailsPage;
