import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  ExceptionManagementConfiguration,
  ExceptionManagementConfigurationsState,
} from '../interfaces/ExceptionManagementConfigurations';
import * as Services from '../services/exceptionManagementConfigurations';

// Actions
const START_LOAD = 'vendors/exceptionManagementConfigurations/START_LOAD';
const COMPLETE_LOAD = 'vendors/exceptionManagementConfigurations/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/exceptionManagementConfigurations/FAIL_LOAD';
const START_SAVE = 'vendors/exceptionManagementConfigurations/START_SAVE';
const COMPLETE_SAVE = 'vendors/exceptionManagementConfigurations/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/exceptionManagementConfigurations/FAIL_SAVE';
const SET_VEHICLE_TYPE_ID = 'vendors/exceptionManagementConfigurations/SET_VEHICLE_TYPE_ID';
const RESET = 'vendors/exceptionManagementConfigurations/RESET';

// Initial state
const initialState: ExceptionManagementConfigurationsState = {
  configurations: [],
  isLoading: false,
  isSaving: false,
};

const randomReasonCodeIndex = (reasonCodesCount: number) => Math.floor(Math.random() * 100) % reasonCodesCount;

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          configurations: action.configurations,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case SET_VEHICLE_TYPE_ID:
      return update(state, {
        $merge: {
          vehicleTypeId: action.vehicleTypeId,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (configurations: ExceptionManagementConfiguration[]) => ({
  type: COMPLETE_LOAD,
  configurations,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const reset = () => ({
  type: RESET,
});

export const setVehicleTypeId = (vehicleTypeId?: number) => ({
  type: SET_VEHICLE_TYPE_ID,
  vehicleTypeId,
});

export const loadExceptionManagementConfigurations = (
  vendorId: number,
  vehicleTypeId: number,
  reasonCodesCount = 0,
) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const promise = Services.loadExceptionManagementConfigurations(vendorId, vehicleTypeId);

  promise
    .then(configurations => {
      dispatch(
        completeLoad(
          configurations.map(configuration => ({
            ...configuration,
            reasonCodeTypePlaceholderIndex: randomReasonCodeIndex(reasonCodesCount),
          })),
        ),
      );
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return promise;
};

export const saveExceptionManagementConfigurations = (
  vendorId: number,
  vehicleTypeId: number,
  configurations: ExceptionManagementConfiguration[],
) => (dispatch: Dispatch) => {
  dispatch(startSave());

  const promise = Services.saveExceptionManagementConfigurations(
    vendorId,
    vehicleTypeId,
    configurations.map(configuration => ({
      ...configuration,
      reasonCodeTypePlaceholderIndex: undefined,
    })),
  );

  promise
    .then(() => {
      dispatch(completeSave());
    })
    .catch(() => {
      dispatch(failSave());
    });

  return promise;
};
