import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { MaterialTypes } from '../interfaces/MaterialTypes';
import {
  loadMaterialTypes as doLoadMaterialTypes,
  loadDispatchBoardMaterialTypes as doLoadDispatchBoardMaterialTypes,
  saveMaterialTypes as doSaveMaterialTypes,
} from '../services/materialTypes';
// Actions
const START_LOAD = 'vendors/materialTypes/START_LOAD';
const COMPLETE_LOAD = 'vendors/materialTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/materialTypes/FAIL_LOAD';
const START_LOAD_FORCE_MATERIAL_TYPES = 'vendors/materialTypes/START_LOAD_FORCE_MATERIAL_TYPES';
const COMPLETE_LOAD_FORCE_MATERIAL_TYPES = 'vendors/materialTypes/COMPLETE_LOAD_FORCE_MATERIAL_TYPES';
const FAIL_LOAD_FORCE_MATERIAL_TYPES = 'vendors/materialTypes/FAIL_LOAD_FORCE_MATERIAL_TYPES';
const START_SAVE = 'vendors/materialTypes/START_SAVE';
const COMPLETE_SAVE = 'vendors/materialTypes/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/materialTypes/FAIL_SAVE';
const RESET = 'vendors/materialTypes/RESET';
const RESET_FORCE_MATERIAL_TYPES = 'vendors/materialTypes/RESET_FORCE_MATERIAL_TYPES';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  materialTypes: [] as MaterialTypes[],
  forceMaterialTypes: [] as MaterialTypes[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          materialTypes: action.materialTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_LOAD_FORCE_MATERIAL_TYPES:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_FORCE_MATERIAL_TYPES:
      return update(state, {
        $merge: {
          isLoading: false,
          forceMaterialTypes: action.forceMaterialTypes,
        },
      });

    case FAIL_LOAD_FORCE_MATERIAL_TYPES:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    case RESET_FORCE_MATERIAL_TYPES:
      return update(state, {
        $merge: {
          forceMaterialTypes: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (materialTypes: MaterialTypes[]) => ({
  type: COMPLETE_LOAD,
  materialTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadForceMaterialTypes = () => ({
  type: START_LOAD_FORCE_MATERIAL_TYPES,
});

const completeLoadForceMaterialTypes = (forceMaterialTypes: MaterialTypes[]) => ({
  type: COMPLETE_LOAD_FORCE_MATERIAL_TYPES,
  forceMaterialTypes,
});

const failLoadForceMaterialTypes = () => ({
  type: FAIL_LOAD_FORCE_MATERIAL_TYPES,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadMaterialTypes =
  (vendorId: number, isMaterialTypeActive?: boolean, vehicleTypeId?: number) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadMaterialTypesPromise = doLoadMaterialTypes(vendorId, isMaterialTypeActive, vehicleTypeId);
    loadMaterialTypesPromise
      .then(materialTypes => dispatch(completeLoad(materialTypes)))
      .catch(() => dispatch(failLoad()));

    return loadMaterialTypesPromise;
  };

export const loadDispatchBoardMaterialTypes =
  (vendorId: number, vehicleTypeId?: number, forceMaterialTypeId?: string) => (dispatch: Dispatch) => {
    dispatch(!!forceMaterialTypeId ? startLoadForceMaterialTypes() : startLoad());
    const loadMaterialTypesPromise = doLoadDispatchBoardMaterialTypes(vendorId, vehicleTypeId, forceMaterialTypeId);
    loadMaterialTypesPromise
      .then(materialTypes =>
        dispatch(!!forceMaterialTypeId ? completeLoadForceMaterialTypes(materialTypes) : completeLoad(materialTypes)),
      )
      .catch(() => dispatch(!!forceMaterialTypeId ? failLoadForceMaterialTypes() : failLoad()));

    return loadMaterialTypesPromise;
  };

export const saveMaterialTypes = (vendorId: number, materialTypes: MaterialTypes[]) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveMaterialTypesPromise = doSaveMaterialTypes(vendorId, materialTypes);
  saveMaterialTypesPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveMaterialTypesPromise;
};

export const resetMaterialTypes = () => ({
  type: RESET,
});

export const resetForceMaterialTypes = () => ({
  type: RESET_FORCE_MATERIAL_TYPES,
});
