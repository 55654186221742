import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isSuperAdminSelector } from '../../../account/ducks';
import { DuckFunction } from '../../../contracts/ducks';
import { Modal } from '../../../core/components';
import { ModalTitle } from '../../../core/components/styled';
import confirm from '../../../core/services/confirm';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import { loadAssignedVendors, loadVendors, saveVendor, setDefaultVendor } from '../../ducks';
import { currentUserIdSelector, currentVendorIdSelector } from '../../services/currentVendorSelector';
import vendorEditorFormInitialValuesSelector from '../../services/vendorEditorFormInitialValuesSelector';
import VendorEditorForm, { VendorEditorFormValues } from '../forms/VendorEditorForm';

interface ComponentProps {
  vendor?: any;
  close(pristine: boolean): void;
}

interface Props extends ComponentProps {
  currentVendorId: number;
  currentUserId: string;
  isLoadingVendors: boolean;
  isSettingDefaultVendor: boolean;
  isSavingVendor: boolean;
  isSuperAdmin: boolean;
  formInitialValues: any;
  loadAssignedVendors: (vendorId: number, userId?: string) => Promise<any>;
  loadVendors: () => Promise<any>;
  saveVendor: DuckFunction<typeof saveVendor>;
  setDefaultVendor: DuckFunction<typeof setDefaultVendor>;
}

class VendorEditorModal extends PureComponent<Props> {
  onSubmit = async (formData: VendorEditorFormValues) => {
    const {
      close,
      currentUserId,
      currentVendorId,
      isSuperAdmin,
      loadAssignedVendors,
      loadVendors,
      saveVendor,
      setDefaultVendor,
      vendor,
    } = this.props;

    const { maxNumberOfAppLicenses, activateAppLicenses } = formData;

    if (
      maxNumberOfAppLicenses !== undefined &&
      maxNumberOfAppLicenses !== null &&
      activateAppLicenses &&
      maxNumberOfAppLicenses < vendor?.usedLicenses &&
      !(await confirm(translate('vendors.alertMessages.lowLicensesNumber')))
    ) {
      return;
    }

    saveVendor(formData)
      .then(async vendor => {
        if (!!currentVendorId && vendor.id === currentVendorId) {
          await setDefaultVendor(currentVendorId);
        }
        if (isSuperAdmin) loadVendors();
        else loadAssignedVendors(currentVendorId, currentUserId);
        createSuccessNotification(`${translate('vendors.alertMessages.vendorSaved')}`);
        close(true);
      })
      .catch(error => {
        const {
          response: {
            data: { code },
            status,
          },
        } = error;
        if (status === 400) {
          createErrorNotification(`${translate(createTranslationKey(code, 'vendors.alertMessages'))}`);
        } else {
          throw error;
        }
      });
  };

  render() {
    const { vendor, isLoadingVendors, isSettingDefaultVendor, isSavingVendor, formInitialValues, close } = this.props;

    return (
      <Modal size="mediumLarge" isLoading={isLoadingVendors || isSettingDefaultVendor || isSavingVendor}>
        <ModalTitle>{translate(`vendors.${vendor ? 'edit' : 'create'}CompanyConfiguration`)}</ModalTitle>

        <VendorEditorForm
          initialValues={formInitialValues}
          vendor={vendor}
          onSubmit={this.onSubmit}
          onCancel={close}
          systemOfMeasurementId={formInitialValues.systemOfMeasurementId}
          isAutoSnapToCurbDisabled={formInitialValues.isAutoSnapToCurb}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const speedingThreshold = ownProps.vendor ? ownProps.vendor.speedingThreshold.toString() : '10';
  const formInitialValues = { ...ownProps.vendor, speedingThreshold };

  return {
    currentVendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
    currentUserId: currentUserIdSelector(state.account.login) as any,
    isLoadingVendors: state.vendors.vendors.isLoading,
    isSettingDefaultVendor: state.vendors.defaultVendor.isSettingDefaultVendor,
    isSavingVendor: state.vendors.vendor.isSaving,
    isSuperAdmin: isSuperAdminSelector(state.account.login),
    formInitialValues: vendorEditorFormInitialValuesSelector(formInitialValues),
  };
};

const mapDispatchToProps = { loadAssignedVendors, loadVendors, saveVendor, setDefaultVendor };

export default connect(mapStateToProps, mapDispatchToProps)(VendorEditorModal);
