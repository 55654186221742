import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const MultiSelectOptions = styled.div`
  margin: 0 0 15px;
  max-height: 300px;
  overflow: auto;
`;

export const MultiSelectOverlay = styled.div<{
  fitContentWidth?: boolean;
  minWidth?: string;
  position?: 'top' | 'bottom';
  menuPosition?: string;
  maxWidth?: string;
}>`
  width: ${p => p.fitContentWidth && 'max-content'};
  position: ${p => p.menuPosition || 'absolute'};
  ${p =>
    p.position === 'top'
      ? css`
          bottom: 33px;
        `
      : ''}
  z-index: 3000;
  min-width: ${p => p.minWidth || '100%'};
  max-width: ${p => p.maxWidth || 'none'};
  padding: 20px 20px;
  background: #fff;
  box-shadow: 0 4px 8px ${props => transparentize(0.9, props.theme.grayBase)};

  @media (max-width: ${props => props.theme.tabletBreakpoint}) {
    right: 0;
  }
`;

export const AsyncOptions = styled.div<{ position: string }>`
  margin-top: 10px;
  margin-bottom: 10px;
  ${p =>
    p.position === 'top'
      ? css`
          padding-bottom: 10px;
          border-bottom: 1px solid ${p => p.theme.brandSecondary};
        `
      : css`
          padding-top: 10px;
          border-top: 1px solid ${p => p.theme.brandSecondary};
        `}
`;
