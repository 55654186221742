import React, { useRef, useState } from 'react';

import { ButtonWrapperWithPopover, PopoverWrap } from '../../common/components/styled';

interface Props {
  align?: string;
  autoHide?: boolean;
  color?: string;
  height?: string;
  isBottomPositioned?: boolean;
  margin?: string;
  maxContentWidth?: boolean;
  onClick?: () => void;
  orientation?: 'right' | 'left';
  popoverContent?: any;
  size?: string;
  tooltipBottomPosition?: number;
  tooltipLeftPosition?: number;
  tooltipTopPosition?: number;
  triggerButton?: any;
  width?: string;
}

const PopoverWrapper: React.SFC<Props> = ({
  align,
  autoHide,
  color,
  height,
  isBottomPositioned,
  margin,
  maxContentWidth,
  orientation,
  popoverContent,
  size,
  triggerButton,
  width,
}) => {
  const [tooltipLeftPosition, setTooltipLeftPosition] = useState<number>();
  const [tooltipTopPosition, setTooltipTopPosition] = useState<number>();
  const [tooltipBottomPosition, setTooltipBottomPosition] = useState<number>();
  const popoverRef = useRef<HTMLDivElement>(null);

  const onTooltipMouseEnter = (event: any) => {
    const tooltipLeftPosition = event && event.target.getBoundingClientRect().left;
    const tooltipTopPosition = event && event.target.getBoundingClientRect().top;
    const tooltipBottomPosition =
      event && event.target.getBoundingClientRect().top + event.target.getBoundingClientRect().height;
    const tooltipTriggerWidth = event && event.target.getBoundingClientRect().width;
    const tooltipWidth = popoverRef.current && popoverRef.current.getBoundingClientRect().width;
    const tooltipHeight = popoverRef.current && popoverRef.current.getBoundingClientRect().height;

    let finalLeftPosition = tooltipLeftPosition;
    let finalTopPosition = tooltipTopPosition;
    let finalBottomPosition = tooltipBottomPosition;

    if (tooltipWidth && tooltipHeight && tooltipTriggerWidth) {
      finalLeftPosition = tooltipLeftPosition - tooltipWidth / 2 + tooltipTriggerWidth / 2;
      if (orientation === 'left') {
        finalLeftPosition = tooltipLeftPosition - tooltipWidth + 15;
      } else if (orientation === 'right') {
        finalLeftPosition = tooltipLeftPosition;
      }
      finalTopPosition = tooltipTopPosition - tooltipHeight - 15;
      finalBottomPosition = tooltipBottomPosition + 15;
    }

    setTooltipLeftPosition(finalLeftPosition);
    setTooltipTopPosition(finalTopPosition);
    setTooltipBottomPosition(finalBottomPosition);
  };

  return (
    <ButtonWrapperWithPopover
      autoHide={autoHide}
      color={color}
      height={height}
      margin={margin}
      onMouseEnter={onTooltipMouseEnter}
      width={width}
      align={align}
    >
      {triggerButton}
      <PopoverWrap
        orientation={orientation}
        ref={popoverRef}
        size={size}
        maxContentWidth={maxContentWidth}
        tooltipLeftPosition={tooltipLeftPosition}
        tooltipTopPosition={isBottomPositioned ? tooltipBottomPosition : tooltipTopPosition}
        isBottomPositioned={isBottomPositioned}
      >
        {popoverContent}
      </PopoverWrap>
    </ButtonWrapperWithPopover>
  );
};

export default PopoverWrapper;
