import update from 'immutability-helper';
import { filter, find, get, identity, map } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { VehicleType, VehicleTypesForVendorState } from '../interfaces/VehicleTypes';
import {
  loadVehicleTypesForVendor as doLoadVehicleTypesForVendor,
  saveVehicleTypesForVendor as doSaveVehicleTypesForVendor,
} from '../services/vehicleTypes';
import { AppState } from 'src/store';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from '../constants';

// Actions
const START_LOAD = 'vehicles/vehicleTypesForVendor/START_LOAD';
const COMPLETE_LOAD = 'vehicles/vehicleTypesForVendor/COMPLETE_LOAD';
const FAIL_LOAD = 'vehicles/vehicleTypesForVendor/FAIL_LOAD';
const START_SAVE = 'vehicles/vehicleTypesForVendor/START_SAVE';
const COMPLETE_SAVE = 'vehicles/vehicleTypesForVendor/COMPLETE_SAVE';
const FAIL_SAVE = 'vehicles/vehicleTypesForVendor/FAIL_SAVE';
const RESET = 'vehicles/vehicleTypesForVendor/RESET';

// Initial state =
const initialState: VehicleTypesForVendorState = {
  isLoading: false,
  isSaving: false,
  vehicleTypesForVendor: [],
  persistentVehicleTypesForVendor: [],
};

// Reducer
export const reducer = (state: VehicleTypesForVendorState = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleTypesForVendor: action.vehicleTypesForVendor,
          persistentVehicleTypesForVendor: action.vehicleTypesForVendor,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleTypesForVendor: [],
          persistentVehicleTypesForVendor: [],
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: { ...initialState, persistentVehicleTypesForVendor: state.persistentVehicleTypesForVendor },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehicleTypesForVendor: VehicleType[]) => ({
  type: COMPLETE_LOAD,
  vehicleTypesForVendor,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadVehicleTypesForVendor =
  (vendorId: number, onlyRouteVehicleTypes?: boolean) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadVehicleTypesForVendorPromise = doLoadVehicleTypesForVendor(vendorId, onlyRouteVehicleTypes);
    loadVehicleTypesForVendorPromise
      .then(vehicleTypesForVendor => dispatch(completeLoad(vehicleTypesForVendor)))
      .catch(() => dispatch(failLoad()));
    return loadVehicleTypesForVendorPromise;
  };

export const saveVehicleTypesForVendor = (vendorId: number, vehicleTypeIds: string[]) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveVehicleTypesForVendorPromise = doSaveVehicleTypesForVendor(vendorId, vehicleTypeIds);
  saveVehicleTypesForVendorPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return saveVehicleTypesForVendorPromise;
};

export const resetVehicleTypesForVendor = () => ({
  type: RESET,
});

const getVehicleTypesForVendorWithRoleType = (
  vehicleTypesForVendorState: VehicleTypesForVendorState,
  vehicleRoleType?: number,
  excludeVehicleTypes?: string[],
  acceptedVehicleTypes?: string[],
) =>
  filter(
    vehicleTypesForVendorState.vehicleTypesForVendor,
    vehicleType =>
      (!vehicleRoleType || vehicleType.vehicleRoleType === vehicleRoleType) &&
      (!excludeVehicleTypes || excludeVehicleTypes.indexOf(vehicleType.technicalName) === -1) &&
      (!acceptedVehicleTypes || acceptedVehicleTypes.indexOf(vehicleType.technicalName) !== -1),
  );

export const vehicleTypesForVendorWithRoleTypeSelector = createSelector(getVehicleTypesForVendorWithRoleType, identity);

const getTechnicalNameByVehicleTypeId = (
  vehicleTypesForVendorState: VehicleTypesForVendorState,
  vehicleTypeId: number,
) => {
  const vehicleType = find(vehicleTypesForVendorState.vehicleTypesForVendor, { id: vehicleTypeId });
  return get(vehicleType, 'technicalName', '');
};

export const technicalNameByVehicleTypeIdSelector = createSelector(getTechnicalNameByVehicleTypeId, identity);

const getVehicleIdByTechnicalName = (vehicleTypesForVendorState: VehicleTypesForVendorState, technicalName: string) => {
  const vehicleType = find(vehicleTypesForVendorState.vehicleTypesForVendor, { technicalName });
  return get(vehicleType, 'id', null);
};

const getVehicleIdByTechnicalNames = (vehicleTypesForVendorState: VehicleTypesForVendorState, technicalNames: string) =>
  map(technicalNames, technicalName =>
    get(find(vehicleTypesForVendorState.vehicleTypesForVendor, { technicalName }), 'id', null),
  );
export const vehicleIdByTechnicalNameSelector = createSelector(getVehicleIdByTechnicalName, identity);
export const vehicleIdsByTechnicalNamesSelector = createSelector(getVehicleIdByTechnicalNames, identity);

export const hasRouteTrackerVehiclesSelector = createSelector(
  (state: AppState) => state.fleet.vehicleTypesForVendor.persistentVehicleTypesForVendor,
  vehicleTypes => vehicleTypes.some(vt => ![SNOW_PLOW_ID, STREET_SWEEPER_ID].includes(vt.id)),
);

export const hasSnowPlowVehiclesEnabledSelector = createSelector(
  (state: AppState) => state.fleet.vehicleTypesForVendor.persistentVehicleTypesForVendor,
  vehicleTypes => !!vehicleTypes.find(vt => vt.id === SNOW_PLOW_ID),
);

export const hasStreetSweeperVehiclesEnabledSelector = createSelector(
  (state: AppState) => state.fleet.vehicleTypesForVendor.persistentVehicleTypesForVendor,
  vehicleTypes => !!vehicleTypes.find(vt => vt.id === STREET_SWEEPER_ID),
);
