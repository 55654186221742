import React from 'react';
import { Modal } from '../../core/components';
import translate from '../../core/services/translate';
import { ReleaseNotesEditorForm } from './forms';
import { createErrorNotification, createSuccessNotification } from '../../core/services/createNotification';
import { saveReleaseNote, editReleaseNote, loadReleaseNotes } from '../ducks/releaseNotes';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ReleaseNote } from '../interfaces/ReleaseNote';
import { releaseNotesFilterSelector } from './forms/ReleaseNotesManagementFilterForm';
import { isPristine } from 'redux-form';
import { releaseNotesEditorFormKey } from './forms/ReleaseNotesEditorForm';
import confirm from '../../core/services/confirm';

interface Props {
  onCloseModal: () => void;
  releaseNote?: ReleaseNote;
  itemId?: number;
}

export const ReleaseNoteEditorModal: React.SFC<Props> = ({ releaseNote, onCloseModal }) => {
  const releaseNotesFormInitialValues = releaseNotesFormInitialValuesSelector(releaseNote);

  const dispatch = useDispatch();

  const { to, from } = useSelector(s => releaseNotesFilterSelector(s, 'dateRange'));
  const formPristine = useSelector(s => isPristine(releaseNotesEditorFormKey)(s));

  const handleSubmitReleaseNote = (formData: any) => {
    const saveAction = releaseNote ? editReleaseNote : saveReleaseNote;
    const payLoad = releaseNote ? { ...releaseNote, ...formData } : formData;

    saveAction(payLoad)(dispatch)
      .then(() => {
        loadReleaseNotes(from, to)(dispatch);
        createSuccessNotification(translate('versioning.alerts.saved'));
        onCloseModal();
      })
      .catch(() => {
        createErrorNotification(translate('versioning.alerts.saveError'));
      });
  };

  const closeModal = async () => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    onCloseModal();
  };

  return (
    <>
      <Modal
        title={translate(`versioning.${releaseNote ? 'editReleaseNote' : 'createReleaseNote'}`)}
        onClose={closeModal}
        size="large"
        padding="medium"
      >
        <ReleaseNotesEditorForm initialValues={releaseNotesFormInitialValues} onSubmit={handleSubmitReleaseNote} />
      </Modal>
    </>
  );
};

const releaseNotesFormInitialValuesSelector = (currentNote?: ReleaseNote) => {
  if (!currentNote) {
    return { items: [{}] };
  }
  const { releaseDate, publishDate } = currentNote;
  return {
    ...currentNote,
    releaseDate: moment(releaseDate).format('MM/DD/YYYY'),
    publishDate: publishDate ? moment(publishDate).format('MM/DD/YYYY') : undefined,
  };
};
