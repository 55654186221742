import { theme } from 'src/core/styles';

const CHART_COLORS = [
  '#689f38',
  '#66bb6a',
  '#9ccc65',
  '#c0ca33',
  '#ffca28',
  '#ffab00',
  '#f57c00',
  '#f4511e',
  '#00bcd4',
  '#64b5f6',
  '#536dfe',
  '#1565c0',
];

export default CHART_COLORS;

export const CONTAINERS_CHART_COLORS = [
  theme.brandPrimary,
  theme.brandInfoLight,
  theme.brandWarning,
  theme.brandAlert,
  theme.brandInfo,
  theme.brandDefault,
  theme.brandGreenDark,
  theme.brandPartSuccess,
  theme.markerBackground,
  theme.brandSuccess,
  '#00a599',
  '#7300ff',
  '#42b274',
  '#ff00a8',
  '#89b73f',
  '#ff0058',
  '#d6b000',
  '#ffa600',
];
