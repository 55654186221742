import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import {
  doSaveGlobalFiltersSettings,
  doLoadFiltersSettings,
  doSavePreferencesFiltersSettings,
} from '../services/filters';
import { FilterSetting } from 'src/vendors/interfaces/Filters';

// Constants
const START_LOAD = 'common/filters/START_LOAD';
const COMPLETE_LOAD_FILTERS = 'common/filters/COMPLETE_LOAD_FILTERS';
const FAIL_LOAD_FILTERS = 'common/filters/FAIL_LOAD_FILTERS';
const FAIL_SAVE = 'common/filters/FAIL_SAVE';
const START_SAVE = 'common/filters/START_SAVES';
const COMPLETE_SAVE = 'common/filters/COMPLETE_SAVE';
const RESET = 'common/filters/RESET';

// Initial State
const initialState = {
  isLoading: false,
  filters: [],
};

interface FiltersSettings {
  isLoading: boolean;
  filters: FilterSetting[] | [];
}

// Reducer
export const reducer = (state: FiltersSettings = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case START_SAVE:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case COMPLETE_LOAD_FILTERS:
      return update(state, {
        $merge: {
          isLoading: false,
          filters: action.data,
        },
      });
    case FAIL_LOAD_FILTERS:
      return update(state, {
        $merge: {
          isLoading: false,
          filters: [],
        },
      });
    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });
    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });
    case RESET:
      return update(state, {
        $merge: initialState,
      });
    default:
      return state;
  }
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoadFilters = (filters: FilterSetting[]) => ({
  type: COMPLETE_LOAD_FILTERS,
  data: filters,
});

const failLoadFilters = () => ({
  type: FAIL_LOAD_FILTERS,
});

const startSave = () => ({
  type: START_SAVE,
});

export const completeSave = () => {
  return {
    type: COMPLETE_SAVE,
  };
};

const failSave = () => ({
  type: FAIL_SAVE,
});

export const resetDefaultFilters = () => {
  return {
    type: RESET,
  };
};

// Async Actions
export const loadFiltersSettings = (vendorId: number, userId: string, isGlobal?: boolean) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadGlobalFilters = doLoadFiltersSettings(vendorId, userId, isGlobal);
  loadGlobalFilters.then(data => dispatch(completeLoadFilters(data))).catch(() => dispatch(failLoadFilters()));
  return loadGlobalFilters;
};

export const saveGlobalFiltersSettings =
  (vendorId: number, userId: number, defaultFilters: any) => (dispatch: Dispatch) => {
    const saveDefaultFiltersPromise = doSaveGlobalFiltersSettings(defaultFilters, vendorId, userId);
    dispatch(startSave());
    saveDefaultFiltersPromise.then(data => dispatch(completeSave())).catch(() => dispatch(failSave()));
    return saveDefaultFiltersPromise;
  };

export const savePreferencesFiltersSettings =
  (vendorId: number, userId: number, defaultFilters: any) => (dispatch: Dispatch) => {
    const savePreferencesFiltersPromise = doSavePreferencesFiltersSettings(defaultFilters, vendorId, userId);
    dispatch(startSave());
    savePreferencesFiltersPromise.then(data => dispatch(completeSave())).catch(() => dispatch(failSave()));
    return savePreferencesFiltersPromise;
  };
