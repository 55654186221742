import React from 'react';

import { camelCase } from 'lodash-es';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { ActionButtonTooltip, PopoverWrapper, UnconnectedCheckbox } from '../../../../../core/components';
import { GEO_FENCES_CREATE_EDIT_DELETE } from '../../../../../account/constants';
import { GeoFence } from '../../../../ducks/geoFences';
import { PermissionGuard } from '../../../../../account/components';
import { Popover, TableActionButton, TableCell, TableRow } from '../../../../../core/components/styled';
import { setRouteGeoFenceEditing } from '../../../../ducks/routeGeoFence';
import GeoFenceAlerts from './GeoFenceAlerts';
import RouteGeoFenceAlerts from '../../../../../routes/components/pages/geoFences/components/RouteGeoFenceAlerts';
import translate from '../../../../../core/services/translate';

interface Props {
  checked: boolean;
  deleteGeoFence: (id: number) => void;
  geoFence: GeoFence;
  hasCreateSaveEditGeoFencePermission?: boolean;
  onCheckChange: (geoFence: GeoFence, checked: boolean) => void;
}

const GeoFencesPageTableRow: React.FC<Props> = ({
  checked,
  deleteGeoFence,
  geoFence,
  geoFence: { geoFenceName, geoFenceZoneType, id, routeId, routeName, routeTemplateId },
  hasCreateSaveEditGeoFencePermission,
  onCheckChange,
}) => {
  const dispatch = useDispatch();

  const editGeoFence = () => {
    const editRouteUrl = hasCreateSaveEditGeoFencePermission ? '/edit' : '';
    const geoFenceUrl = routeId
      ? `/routes/route-tracker/${routeId}${editRouteUrl}`
      : routeTemplateId
      ? `/routes/route-templates/${routeTemplateId}${editRouteUrl}`
      : `/fleet/geo-fences/${id}/edit`;

    if (hasCreateSaveEditGeoFencePermission && (routeId || routeTemplateId)) {
      dispatch(setRouteGeoFenceEditing(true));
    }

    dispatch(push(geoFenceUrl));
  };

  return (
    <TableRow>
      <TableCell width="5%" justifyContent="center">
        <PopoverWrapper
          triggerButton={
            <UnconnectedCheckbox
              onChange={() => onCheckChange(geoFence, checked)}
              block
              checked={checked}
              size="small"
            />
          }
          popoverContent={
            <Popover>{translate(checked ? 'routes.geoFences.removeFromMap' : 'routes.geoFences.displayOnMap')}</Popover>
          }
          width="53px"
          size="small"
        />
      </TableCell>

      <TableCell width="50%">{geoFenceName || routeName || '-'}</TableCell>

      <TableCell width="35%">
        {geoFenceZoneType ? translate(`routes.geoFences.zoneTypes.${camelCase(geoFenceZoneType.technicalName)}`) : '-'}
      </TableCell>

      <TableCell width="10%" align="right">
        <PermissionGuard permission={GEO_FENCES_CREATE_EDIT_DELETE}>
          {routeId || routeTemplateId ? (
            <RouteGeoFenceAlerts hasSourceFromList geoFenceId={id} />
          ) : (
            <GeoFenceAlerts geoFenceId={id} />
          )}
        </PermissionGuard>
        <TableActionButton id={`edit-geo-fence-${id}-button`} onClick={editGeoFence}>
          <ActionButtonTooltip
            icon={hasCreateSaveEditGeoFencePermission ? 'edit' : 'redeye'}
            tooltip={hasCreateSaveEditGeoFencePermission ? 'editGeoFence' : 'view'}
          />
        </TableActionButton>
        <PermissionGuard permission={GEO_FENCES_CREATE_EDIT_DELETE}>
          <TableActionButton id={`delete-geo-fence-${id}-button`} onClick={() => deleteGeoFence(id)}>
            <ActionButtonTooltip icon="delete" tooltip="delete" />
          </TableActionButton>
        </PermissionGuard>
      </TableCell>
    </TableRow>
  );
};

export default GeoFencesPageTableRow;
