import { TableCell } from 'src/core/components/Table';
import translate from 'src/core/services/translate';

export const OPEN_BILLS_TABLE_CELLS: TableCell[] = [
  {
    name: 'billId',
    label: translate('finance.applyChecks.billId'),
    width: '8%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'billDate',
    label: translate('finance.applyChecks.billDate'),
    width: '10%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'dueDate',
    label: translate('finance.applyChecks.dueDate'),
    width: '10%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'customerInfo',
    label: translate('finance.applyChecks.customerInfo'),
    width: '21%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'billAmount',
    label: translate('finance.applyChecks.billAmount'),
    width: '12%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'unappliedBillBalance',
    label: translate('finance.applyChecks.unappliedBillBalance'),
    width: '9%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'availableBillBalance',
    label: translate('finance.applyChecks.availableBillBalance'),
    width: '8%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },

  {
    name: 'paymentStatus',
    label: translate('finance.applyChecks.paymentStatus'),
    width: '14%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'options',
    label: translate('common.options'),
    width: '8%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: false,
  },
];
