import { AssignedVendors } from '../interfaces/UserEditor';
import { getBaseUrl } from '../../core/services/environment';
import { http } from '../../core/services/http';
import { Users } from '../interfaces/UserEditor';

export const createUser = (vendorId: number, user?: Users) =>
  http
    .post(`vendors/${vendorId}/users`, { ...user, inviteUrl: getBaseUrl() })
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const updateUser = (vendorId: number, user?: Users) =>
  http
    .put(`vendors/${vendorId}/users/${user?.userId}`, user)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const deleteUser = (vendorId: number, userId: string) =>
  http
    .delete(`vendors/${vendorId}/users/${userId}`)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const getVendorDistricts = (vendorId: number) => http.get(`vendors/${vendorId}/divisions`).then(res => res.data);

export const getUserEditDistricts = (vendorId: number, userId: string) =>
  http.get(`vendors/${vendorId}/users/${userId}/divisions`).then(res => res.data);

export const saveDivisions = (vendorId: number, userId: string, data: any) =>
  http
    .put(`vendors/${vendorId}/users/${userId}/divisions`, data)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const getUserAvailableVendors = (vendorId: number, userId?: string) =>
  http.get(`vendors/${vendorId}/users/${userId}/available-vendors`).then(res => res.data);

export const getUserAssignedVendors = (vendorId: number, userId?: string) =>
  http.get(`vendors/${vendorId}/users/${userId}/assigned-vendors`).then(res => res.data);

export const getAssignedVendors = (userId?: string) =>
  http.get(`users/${userId}/assigned-vendors`).then(res => res.data);

export const saveVendorAccess = (vendorId: number, userId: string, vendors: AssignedVendors[]) =>
  http
    .post(`vendors/${vendorId}/users/${userId}/assigned-vendors`, { userId: userId, vendors })
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
