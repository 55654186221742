import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';
import appBgPin from '../assets/img/common/appBg.png';
import appFgPin from '../assets/img/common/appFg.png';
import appUnPin from '../assets/img/common/appUn.png';

export const UNKNOWN = 0;
export const FOREGROUND = 1;
export const BACKGROUND = 2;

export const APPLICATION_STATUSES = mapKeys(
  [
    { id: UNKNOWN, name: translate('common.applicationStatuses.unknown') },
    { id: FOREGROUND, name: translate('common.applicationStatuses.foreground') },
    { id: BACKGROUND, name: translate('common.applicationStatuses.background') },
  ],
  'id',
);

export const APPLICATION_STATUS_ICONS_LIST = [
  { id: UNKNOWN, icon: appUnPin, iconType: 'appUnPin' },
  { id: BACKGROUND, icon: appBgPin, iconType: 'appBgPin' },
  { id: FOREGROUND, icon: appFgPin, iconType: 'appFgPin' },
];

export const APPLICATION_STATUS_ICONS = mapKeys(APPLICATION_STATUS_ICONS_LIST, 'id');
