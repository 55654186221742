import React from 'react';
import { useDispatch } from 'react-redux';
import { PageLoading } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import { loadReleaseNotes } from '../../ducks/releaseNotes';
import { releaseNotesDateRangeInitialValues } from '../forms/ReleaseNotesManagementFilterForm';
import ReleaseNotesManagementPage from './ReleaseNotesManagementPage';

const { from, to } = releaseNotesDateRangeInitialValues;

export const ReleaseNotesManagementPageResolver: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <TypedResolver
      resolve={() => loadReleaseNotes(from, to)(dispatch)}
      successComponent={ReleaseNotesManagementPage}
      loadingComponent={PageLoading}
    />
  );
};
