import React, { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../../store';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../../common/components';
import { loadFeatures, loadVendor } from '../../../../vendors/ducks';
import { loadGeoFence } from '../../../ducks/geoFences';
import { loadVehicleTypesForVendor } from '../../../../fleet/ducks';
import { PageLoading } from '../../../../common/components/styled';
import { Resolver } from '../../../../core/components';
import { shouldLoadPermissionsSelector, loadPermissions } from '../../../../account/ducks';
import GeoFenceEditorPage from './GeoFenceEditorPage';
import translate from '../../../../core/services/translate';

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  loadFeatures: (vendorId: number) => Promise<any>;
  loadGeoFence: (geoFenceId: number) => Promise<any>;
  loadVendor: (vendorId: number) => Promise<any>;
  loadPermissions: () => Promise<any>;
  loadVehicleTypesForVendor: (vendorId: number) => Promise<any>;
  shouldLoadPermissions?: boolean;
  vendorId: number;
}

class GeoFenceEditorPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      loadFeatures,
      loadGeoFence,
      loadPermissions,
      loadVehicleTypesForVendor,
      loadVendor,
      match: {
        params: { id },
      },
      shouldLoadPermissions,
      vendorId,
    } = this.props;

    const dependencies = [loadVehicleTypesForVendor(vendorId), loadFeatures(vendorId), loadVendor(vendorId)];

    if (id) {
      dependencies.push(loadGeoFence(Number(id)));
    }

    if (shouldLoadPermissions) {
      dependencies.push(loadPermissions());
    }

    return Promise.all(dependencies);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('routes.geoFences.geoFencesTitle')}</DocumentTitle>
        <Resolver
          successComponent={GeoFenceEditorPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/fleet/geo-fences"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  shouldLoadPermissions: shouldLoadPermissionsSelector(state.account.login, state.account.permissions),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadFeatures,
  loadGeoFence,
  loadPermissions,
  loadVehicleTypesForVendor,
  loadVendor,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GeoFenceEditorPageResolver));
