import { map } from 'lodash-es';
import { useMemo } from 'react';
import { POLYLINE_COLORS } from 'src/common/constants';
import FloatingCollapsible from 'src/core/components/FloatingCollapsible';
import { Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { DashboardRouteLegendItem } from '../../styled/DashboardFilterMapbox';

const MapModalRoutesLegend = () => {
  const routes = useSelector(state => state.dashboard.vehiclesData.vehicleBreadcrumbsByRoutes);

  const dataToShow = useMemo(() => {
    if (!routes?.routes) return null;

    return map(routes.routes, (route, index) => {
      let color = index;
      if (color > POLYLINE_COLORS.length - 1) color = 1;
      return (
        <DashboardRouteLegendItem key={route.id} color={POLYLINE_COLORS[color]}>
          <div />
          <Text size="sMedium" color="grayDarker" singleLine title={route.name}>
            {route.name}
          </Text>
        </DashboardRouteLegendItem>
      );
    });
  }, [routes?.routes]);

  return (
    <ComplexMapControl position="bottom-right">
      <FloatingCollapsible isOpenByDefault title={translate('common.routes')} widthWhenOpen={250} widthWhenClosed={150}>
        <Box margin="xSmall">{dataToShow}</Box>
      </FloatingCollapsible>
    </ComplexMapControl>
  );
};

export default MapModalRoutesLegend;
