import update from 'immutability-helper';
import { identity, reduce } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import {
  loadOpportunity as doLoadOpportunities,
  // loadOpportunityByVendorId as doLoadOpportunityByVendorId,
  loadOpportunityByUserId as doLoadOpportunityByUserId,
  updateOpportunity as doUpdateHaulerOpportunity,
  // updateOpportunityByVendorId as doUpdateHaulerOpportunityByVendorId,
  updateOpportunityByUserId as doUpdateOpportunityByUserId,
} from '../services/loadOpportunity';

//  Actions
const START_LOAD = 'opportunity/opportunity/START_LOAD';
export const COMPLETE_LOAD = 'opportunity/opportunity/COMPLATE_LOAD';
const FAIL_LOAD = 'opportunity/opportunity/FAIL_LOAD';
const FAIL_SAVE = 'opportunity/opportunity/SAVE_FAIL';
const START_SAVE = 'opportunity/opportunity/START_SAVE';
const COMPLETE_SAVE = 'opportunity/opportunity/COMPLETE_SAVE';

//  Initial State
const intialState = {
  isLoading: false,
  isSaving: false,
  opportunities: undefined,
  opportunity: undefined,
  deliveries: undefined,
  paymentInfo: undefined,
  haulerName: undefined,
  activitiBaseUrl: undefined,
  readonly: undefined,
  errorMessage: undefined,
  equipmentSubTypes: undefined,
  materialTypes: [],
};

//  Reducer
export const reducer = (state = intialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          opportunities: action.opportunity.opportunities,
          deliveries: action.opportunity.deliveries,
          paymentInfo: action.opportunity.paymentInfo,
          haulerName: action.opportunity.haulerName,
          activitiBaseUrl: action.opportunity.activitiBaseUrl,
          readonly: action.opportunity.readonly,
          errorMessage: action.opportunity.errorMessage,
          equipmentSubTypes: action.opportunity.equipmentSubTypes,
          materialTypes: action.opportunity.materialTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          opportunities: undefined,
          deliveries: undefined,
          paymentInfo: undefined,
          haulerName: undefined,
          activitiBaseUrl: undefined,
          readonly: undefined,
          errorMessage: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          opportunities: action.opportunity.opportunities,
          deliveries: action.opportunity.deliveries,
          paymentInfo: action.opportunity.paymentInfo,
          haulerName: action.opportunity.haulerName,
          activitiBaseUrl: action.opportunity.activitiBaseUrl,
          readonly: action.opportunity.readonly,
          errorMessage: action.opportunity.errorMessage,
        },
      });

    default:
      return state;
  }
};

//  Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (opportunity: any) => ({
  type: COMPLETE_LOAD,
  opportunity,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (opportunity: any) => ({
  type: COMPLETE_SAVE,
  opportunity,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadOpportunity =
  (userId: number | string | undefined, token: string, vendorId: number, isActionRequired: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    if (token) {
      return doLoadOpportunities(token, isActionRequired)
        .then(opportunity => dispatch(completeLoad(opportunity)))
        .catch(() => dispatch(failLoad()));
    } else if (userId) {
      return doLoadOpportunityByUserId(userId, vendorId, isActionRequired)
        .then(opportunity => dispatch(completeLoad(opportunity)))
        .catch(() => dispatch(failLoad()));
    }
  };

export const updateHaulerOpportunity =
  (
    requestOpportunityObj: any,
    userId: string | number,
    token: string,
    vendorId: number,
    isActionRequired?: boolean,
    startDate?: string,
    endDate?: string,
    includeAllStatus?: boolean,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());
    if (token) {
      return doUpdateHaulerOpportunity(requestOpportunityObj, token)
        .then(opportunity => dispatch(completeSave(opportunity)))
        .catch(() => dispatch(failSave()));
    } else if (userId) {
      return doUpdateOpportunityByUserId(
        requestOpportunityObj,
        userId,
        vendorId,
        isActionRequired,
        startDate,
        endDate,
        includeAllStatus,
      )
        .then(opportunity => dispatch(completeSave(opportunity)))
        .catch(() => dispatch(failSave()));
    }
  };

// Selectors
const getVendorPaymentMethodTypes = (paymentMethodStates: any, paymentMethodId: number) => {
  const vendorPaymentMethodTypes = reduce(
    paymentMethodStates,
    (paymentInfo, paymentMethodState) => {
      if (paymentMethodState.paymentMethod === paymentMethodId) {
        paymentInfo.push(paymentMethodState);
      }
      return paymentInfo;
    },
    [] as any[],
  );
  return vendorPaymentMethodTypes;
};

export const vendorPaymentMethodTypes = createSelector(getVendorPaymentMethodTypes, identity);
