import React from 'react';

import { Modal } from '../../../../../core/components';
import { TransferRouteTemplateLocationsForm } from '../../../forms';
import translate from '../../../../../core/services/translate';

interface Props {
  closeModal: () => void;
  onTransferRouteTemplateLocationsSubmit: ({
    targetRouteTemplateId,
    shouldRecreateRoutes,
    positionTypeId,
  }: any) => void;
  sourceRouteTemplateId: number;
  vehicleTypeId: number;
  vendorId: number;
  wasteMaterialTypeId?: number;
}

const TransferRouteTemplateLocationsModal: React.FC<Props> = ({
  closeModal,
  onTransferRouteTemplateLocationsSubmit,
  sourceRouteTemplateId,
  vehicleTypeId,
  vendorId,
  wasteMaterialTypeId,
}) => (
  <Modal title={translate('routes.transferStops')} onClose={closeModal} padding="medium">
    <TransferRouteTemplateLocationsForm
      onSubmit={onTransferRouteTemplateLocationsSubmit}
      sourceRouteTemplateId={sourceRouteTemplateId}
      vehicleTypeId={vehicleTypeId}
      vendorId={vendorId}
      wasteMaterialTypeId={wasteMaterialTypeId}
    />
  </Modal>
);

export default TransferRouteTemplateLocationsModal;
