import React from 'react';

import { Box } from 'src/core/components/styled/Box';
import { getGroupsAssigned } from '../../routes/SnowSweeperRouteHeadingDetails';
import { getRouteTime } from 'src/routes/utils/routeDetails';
import {
  RouteSummary as RouteSummaryContainer,
  RouteSummaryDetails,
  RouteSummaryIcon,
  RouteSummaryIconContainer,
  RouteSummaryItem,
  RouteSummaryLabel,
  RouteSummaryValue,
} from '../../../styled';
import { Group } from 'src/routes/interfaces/Groups';
import { supervisorExperienceFeatureIsEnabled } from '../../../../../vendors/ducks/features';
import { useSelector } from 'src/core/hooks/useSelector';
import { WEEKDAYS_BY_SHORTCODE } from '../../../../../core/constants';
import translate from '../../../../../core/services/translate';

interface Props {
  endTime?: string;
  groups?: Group[];
  noOfStops?: number;
  scheduledDay: string;
  startDate?: string;
  startTime?: string;
  supervisorId?: number;
  vehicleName?: string;
  vehicleTypeName: string;
}

const RouteTemplateSummary: React.FC<Props> = ({
  endTime,
  groups,
  noOfStops,
  scheduledDay,
  startDate,
  startTime,
  supervisorId,
  vehicleName,
  vehicleTypeName,
}) => {
  const supervisors = useSelector(state => state.routes.supervisors.supervisors);
  const selectedSupervisor = supervisorId ? supervisors.find(supervisor => supervisor.id === supervisorId) : undefined;
  const supervisorExperienceEnabled = useSelector(supervisorExperienceFeatureIsEnabled);

  const selectedStartTime = getRouteTime(startTime);
  const selectedEndTime = getRouteTime(endTime);

  return (
    <RouteSummaryContainer>
      <RouteSummaryItem>
        <RouteSummaryIconContainer>
          <RouteSummaryIcon icon="vehicle" />
        </RouteSummaryIconContainer>

        <RouteSummaryDetails>
          <RouteSummaryLabel>{translate('vehicles.vehicle')}</RouteSummaryLabel>

          <RouteSummaryValue id="route-template-vehicle">
            {!!vehicleName && `${vehicleName},`} {vehicleTypeName}
          </RouteSummaryValue>
        </RouteSummaryDetails>
      </RouteSummaryItem>

      {!!noOfStops && (
        <RouteSummaryItem>
          <RouteSummaryDetails>
            <RouteSummaryLabel>{translate('routes.numberOfStops')}</RouteSummaryLabel>

            <RouteSummaryValue id="route-template-no-of-stops">{noOfStops}</RouteSummaryValue>
          </RouteSummaryDetails>
        </RouteSummaryItem>
      )}

      {!!startDate && (
        <RouteSummaryItem>
          <RouteSummaryIconContainer>
            <RouteSummaryIcon icon="calendar" />
          </RouteSummaryIconContainer>

          <RouteSummaryDetails>
            <RouteSummaryLabel>{translate('common.startDate')}</RouteSummaryLabel>

            <RouteSummaryValue id="route-template-start-date">{startDate || '-'}</RouteSummaryValue>
          </RouteSummaryDetails>
        </RouteSummaryItem>
      )}

      {!!scheduledDay && (
        <RouteSummaryItem>
          <RouteSummaryIconContainer>
            <RouteSummaryIcon icon="calendar" />
          </RouteSummaryIconContainer>

          <RouteSummaryDetails>
            <RouteSummaryLabel>{translate('routes.routeDate')}</RouteSummaryLabel>

            <RouteSummaryValue id="route-template-route-date">
              {!!scheduledDay && WEEKDAYS_BY_SHORTCODE[scheduledDay].name}
            </RouteSummaryValue>

            {startTime && (
              <RouteSummaryValue isSizeSmall>{`${selectedStartTime} - ${selectedEndTime}`}</RouteSummaryValue>
            )}
          </RouteSummaryDetails>
        </RouteSummaryItem>
      )}

      {supervisorExperienceEnabled && selectedSupervisor && (
        <RouteSummaryItem>
          <RouteSummaryIconContainer>
            <RouteSummaryIcon icon="supervisor" />
          </RouteSummaryIconContainer>
          <RouteSummaryDetails>
            <RouteSummaryLabel>{translate('routes.supervisor')}</RouteSummaryLabel>
            <RouteSummaryValue>
              {selectedSupervisor.firstName} {selectedSupervisor.lastName}
            </RouteSummaryValue>
          </RouteSummaryDetails>
        </RouteSummaryItem>
      )}

      {groups && (
        <RouteSummaryItem>
          <RouteSummaryIconContainer>
            <RouteSummaryIcon size="large" icon="groups" customViewBox="0 0 25 25" />
          </RouteSummaryIconContainer>
          <RouteSummaryDetails>
            <RouteSummaryLabel>{translate('routes.groups.groups')}</RouteSummaryLabel>
            <RouteSummaryValue>
              <Box maxWidth={100}>{getGroupsAssigned(groups)}</Box>
            </RouteSummaryValue>
          </RouteSummaryDetails>
        </RouteSummaryItem>
      )}
    </RouteSummaryContainer>
  );
};

export default RouteTemplateSummary;
