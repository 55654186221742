import React, { ChangeEvent, PureComponent } from 'react';

import { size } from 'lodash-es';
import { connect } from 'react-redux';
import { WrappedFieldInputProps } from 'redux-form';

import { MultiSelect, TypedField } from '../../core/components';
import { FieldProps } from '../../core/components/FieldProps';
import { MultiSelectProps } from '../../core/components/MultiSelect';
import translate from '../../core/services/translate';
import { VEHICLE_TYPES } from '../../fleet/constants';
import { vehicleTypesForVendorWithRoleTypeSelector } from '../../fleet/ducks';
import { AppState } from '../../store';
import { TechnicalType } from '../../common/interfaces/TechnicalType';

const formatText = (selectedOptions: any[], allOptionsSelected: boolean) =>
  allOptionsSelected
    ? translate('vehicles.allVehicleTypes')
    : translate('vehicles.xVehicleTypesSelected', { selected: size(selectedOptions) });

interface Props extends FieldProps {
  acceptedVehicleTypes?: string[];
  excludeVehicleTypes: any[];
  input: WrappedFieldInputProps;
  label?: string;
  multiSelectProps?: Partial<MultiSelectProps>;
  placeholder?: string;
  vehicleRoleTypeId?: number;
  vehicleTypesForVendor?: TechnicalType[];
  withLabel?: boolean;
  withPlaceholder?: boolean;
  withTechnicalName: boolean;
}

class VehicleTypeForVendorMultiSelect extends PureComponent<Props> {
  onChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      vehicleTypesForVendor,
      multiSelectProps,
      withTechnicalName,
    } = this.props;

    const vehicleTypesForVendorOptions = vehicleTypesForVendor
      ? vehicleTypesForVendor.map(vehicleTypeForVendor => ({
          label: VEHICLE_TYPES[vehicleTypeForVendor.technicalName]
            ? VEHICLE_TYPES[vehicleTypeForVendor.technicalName].name
            : undefined,
          value: withTechnicalName ? vehicleTypeForVendor.technicalName : vehicleTypeForVendor.id,
        }))
      : [];

    return (
      <TypedField
        name={name}
        component={MultiSelect}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('vehicles.vehicleType') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('vehicles.vehicleType') : undefined),
          options: vehicleTypesForVendorOptions,
          formatText,
          defaultToAll: true,
          checkedOptionsFirst: true,
          ...multiSelectProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props) => ({
  vehicleTypesForVendor: (vehicleTypesForVendorWithRoleTypeSelector as any)(
    state.fleet.vehicleTypesForVendor,
    ownProps.vehicleRoleTypeId,
    ownProps.excludeVehicleTypes,
    ownProps.acceptedVehicleTypes,
  ),
});

export default connect(mapStateToProps)(VehicleTypeForVendorMultiSelect);
