import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Modal } from '../../../core/components';
import { ModalSection, Text } from '../../../core/components/styled';
import { AppState } from 'src/store';
import { PopoverDetailsContainer } from '../styled/CustomerLocations';
import translate from 'src/core/services/translate';
import AddNewWorkOrderForm from '../forms/AddNewWorkOrderForm';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { saveAddNewWorkOrder } from 'src/customers/ducks/addNewWorkOrder';
import { RubiconServicesHistory } from 'src/customers/interfaces/RubiconServices';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { REQUEST_TYPE_OPTIONS } from 'src/customers/constants/requestTypeOptions';

interface Props {
  closeModal: (pristine?: boolean) => void;
  setTicketConfirmationValues: (values: any) => void;
  toggleTicketConfirmation: () => void;
  serviceHistory?: RubiconServicesHistory;
  isRubiconServicesPage: boolean;
  hidden?: boolean;
}

export default function AddNewWorkOrderModal({
  closeModal,
  setTicketConfirmationValues,
  toggleTicketConfirmation,
  serviceHistory,
  isRubiconServicesPage,
  hidden,
}: Props) {
  const dispatch = useDispatch();
  const rubiconServices = useSelector((state: AppState) => state.customers.rubiconServices.rubiconServices);
  const vendorId = useSelector(currentVendorId);
  const location = serviceHistory?.siteAddress + ',' + serviceHistory?.siteCity + ',' + serviceHistory?.siteState;

  const handleSubmit = (formValues: any) => {
    const { datePerformed, serviceRequestType, amount, notes, files, serviceId } = formValues;
    const serviceFind = rubiconServices.find(service => {
      return service.svcID === serviceId;
    });
    const serviceRequestDescription = REQUEST_TYPE_OPTIONS[serviceRequestType].name;

    const divisionId = isRubiconServicesPage ? serviceHistory?.vendID : serviceFind?.vendID;
    const location = isRubiconServicesPage
      ? `${serviceHistory?.siteAddress}, ${serviceHistory?.siteCity}, ${serviceHistory?.siteState}`
      : `${serviceFind?.siteAddress}, ${serviceFind?.siteCity}, ${serviceFind?.siteState}`;
    const formData = {
      Customer: isRubiconServicesPage ? serviceHistory?.customerName : serviceFind?.customerName,
      Location: location,
      ServiceId: isRubiconServicesPage ? serviceHistory?.svcID : serviceFind?.svcID,
      DatePerformed: datePerformed,
      ServiceRequestType: serviceRequestType,
      Amount: amount,
      Notes: notes,
      Files: files,
    };
    saveAddNewWorkOrder(
      vendorId,
      divisionId,
      formData,
    )(dispatch)
      .then(response => {
        createSuccessNotification(translate('customers.rubiconService.alertMessages.success'));
        setTicketConfirmationValues({ ...formData, ...response, serviceRequestDescription });
        toggleTicketConfirmation();
        closeModal();
      })
      .catch(() => {
        createErrorNotification(translate('customers.rubiconService.alertMessages.error'));
      });
  };

  return (
    <Modal
      hidden={hidden}
      size="small"
      title={translate('customers.rubiconService.addNewWorkOrder')}
      onClose={closeModal}
    >
      <ModalSection margin="medium no no" align="left">
        {isRubiconServicesPage && (
          <PopoverDetailsContainer>
            <Text block weight="light" padding="xxSmall no xxSmall" singleLine>
              {translate('common.customer')}: {serviceHistory?.customerName}
            </Text>

            <Text block weight="light" padding="xxSmall no xxSmall" singleLine>
              {translate('common.location')}: {location}
            </Text>

            <Text block weight="light" padding="xxSmall no xxSmall" singleLine>
              {translate('customers.serviceId')} : {serviceHistory?.svcID}
            </Text>
          </PopoverDetailsContainer>
        )}
        <AddNewWorkOrderForm
          isRubiconServicesPage={isRubiconServicesPage}
          onSubmit={handleSubmit}
          onClose={closeModal}
          initialValues={{ datePerformed: moment().format('MM/DD/YYYY') }}
        ></AddNewWorkOrderForm>
      </ModalSection>
    </Modal>
  );
}
