import { ChangeEvent, PureComponent } from 'react';
import { map } from 'lodash-es';

import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { MultiDropdown, TypedField } from '../../core/components';
import { WrappedFieldInputProps } from 'redux-form';
import translate from '../../core/services/translate';

interface Props {
  input: WrappedFieldInputProps;
  dropDownOptions: any[];
  label?: string;
  multiSelectProps?: Partial<DropDownFieldProps>;
  placeholder?: string;
  withLabel?: boolean;
  withPlaceholder?: boolean;
}

class ContactTypesMultiSelectDropdown extends PureComponent<Props> {
  onChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      multiSelectProps,
      dropDownOptions,
    } = this.props;

    const contactTypeOptions = map(dropDownOptions, contactType => ({
      label: contactType.name,
      value: contactType.id,
    }));

    return (
      <TypedField
        name={name}
        component={MultiDropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('common.contact.contactType') : undefined),
          options: contactTypeOptions,
          placeholder: placeholder || (withPlaceholder ? translate('common.contact.contactType') : undefined),
          ...multiSelectProps,
        }}
      />
    );
  }
}

export default ContactTypesMultiSelectDropdown;
