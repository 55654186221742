import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { authenticateToken } from '../../../fleet/ducks';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DuckFunction } from '../../../contracts/ducks';
import { DocumentTitle } from '../../../common/components';
import { FullStory } from '../../../fullstory';
import { getQueryParams } from '../../../utils/services/queryParams';
import history from '../../../core/services/history';
import { loadOpportunity } from '../../ducks';
import { loadHolidays } from '../../../common/ducks/holidays';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks/dispatchOpportunitiesMenuCount';
import OpportunityPage from './OpportunityPage';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import translate from '../../../core/services/translate';
import { YES } from '../../../fleet/constants/status';

interface RouteParams {
  token: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  userId: number;
  vendorId: number;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  loadHolidays: DuckFunction<typeof loadHolidays>;
  loadOpportunity: DuckFunction<typeof loadOpportunity>;
  authenticateToken: DuckFunction<typeof authenticateToken>;
}

class OpportunityPageResolver extends PureComponent<Props> {
  loadDependancies = () => {
    const {
      userId,
      vendorId,
      loadDispatchOpportunitiesCount,
      loadHolidays,
      loadOpportunity,
      authenticateToken,
      match: {
        params: { token },
      },
      location,
    } = this.props;
    const { showActionRequired } = getQueryParams(location.search);
    const isActionRequired = showActionRequired === YES ? true : false;
    if (token) {
      FullStory.setTokenBasedUserInfo(token);
    }

    return token
      ? authenticateToken(token).then(response => {
          if (!response || response.isVPortalLoginRequired) {
            history.push({ pathname: '/account/logout', state: { from: history.location } });
          } else {
            if (response) {
              FullStory.setTokenBasedVendorInfo(response);
            }
            return Promise.all([
              loadHolidays(),
              loadDispatchOpportunitiesCount(userId, vendorId, token),
              loadOpportunity(userId, token, vendorId, isActionRequired),
            ]);
          }
        })
      : Promise.all([loadHolidays(), loadOpportunity(userId, token, vendorId, isActionRequired)]);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('opportunity.opportunities.currentOpportunities')}</DocumentTitle>
        <Resolver successComponent={OpportunityPage} loadingComponent={PageLoading} resolve={this.loadDependancies} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadHolidays, loadDispatchOpportunitiesCount, loadOpportunity, authenticateToken };

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityPageResolver);
