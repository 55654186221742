import { connect } from 'react-redux';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { Modal } from '../../../core/components';
import { ModalSection, ModalTitle } from '../../../core/components/styled';
import { saveTimeManagementSettings } from '../../ducks';
import translate from '../../../core/services/translate';
import TimeManagementForm from '../forms/TimeManagementForm';
import { TimeManagementSettings } from 'src/vendors/interfaces/TimeManagement';

interface Props {
  closeModal: (pristine: boolean) => void;
  saveTimeManagementSettings: (vendorId: number, timeManagementSettings: TimeManagementSettings[]) => Promise<any>;
  vendorId?: number;
}

function TimeManagementModal({ closeModal, vendorId, saveTimeManagementSettings }: Props) {
  const handleSubmit = (timeManagementSettings: TimeManagementSettings[]) => {
    saveTimeManagementSettings(vendorId as number, timeManagementSettings as TimeManagementSettings[])
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.timeManagementSettingsSaved'));
        closeModal(true);
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.timeManagementSettingsError')));
  };

  const closeCurrentModal = (pristine: boolean) => {
    closeModal(pristine);
  };

  return (
    <>
      <Modal padding="no" size="large">
        <ModalSection padding="medium no no">
          <ModalTitle>{translate('vendors.featureCodes.timeManagement')}</ModalTitle>
        </ModalSection>
        <ModalSection>
          <TimeManagementForm onCancel={closeCurrentModal} onSubmit={handleSubmit} />
        </ModalSection>
      </Modal>
    </>
  );
}

const mapDispatchToProps = { saveTimeManagementSettings };

export default connect(null, mapDispatchToProps)(TimeManagementModal);
