import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { ServiceHistoryData } from '../interfaces/ServiceHistory';
import { loadServiceHistoryData as doLoadServiceHistoryData } from '../services/serviceHistory';

const START_LOAD = 'routes/serviceHistory/START_LOAD';
const COMPLETE_LOAD = 'routes/serviceHistory/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/serviceHistory/FAIL_LOAD';
const RESET = 'routes/serviceHistory/RESET';

interface State {
  isLoading: boolean;
  serviceHistoryData?: ServiceHistoryData[];
}

const initialState: State = {
  isLoading: false,
  serviceHistoryData: undefined,
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceHistoryData: action.serviceHistoryData,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceHistoryData: undefined,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (serviceHistoryData: ServiceHistoryData[]) => ({
  type: COMPLETE_LOAD,
  serviceHistoryData,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadServiceHistoryData =
  (vendorId: number, routeId: number, startDate?: Date | string, endDate?: Date | string) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadServiceHistoryDataPromise = doLoadServiceHistoryData(vendorId, routeId, startDate, endDate);
    loadServiceHistoryDataPromise
      .then((serviceHistoryData: ServiceHistoryData[]) => dispatch(completeLoad(serviceHistoryData)))
      .catch(() => dispatch(failLoad()));
    return loadServiceHistoryDataPromise;
  };

export const resetServiceHistoryData = () => ({
  type: RESET,
});
