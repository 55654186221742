import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';

interface Props extends RouteComponentProps {
  resolve?: () => Promise<any>;
  redirect?: (where: string) => void;
  onSuccess?: (result: any) => void;
  onError?: (error: any) => void;
  redirectOnError?: string;
  successComponent: any;
  loadingComponent: any;
  successProps?: any;
}

class Resolver extends PureComponent<Props, { resolved: boolean }> {
  state = {
    resolved: false,
  };

  componentDidMount() {
    this.isComponentMounted = true;
    const { resolve, redirect, onSuccess, onError, redirectOnError } = this.props;

    if (resolve) {
      resolve()
        .then(result => {
          if (!this.isComponentMounted) return;
          if (onSuccess) onSuccess(result);
          this.setResolvedState(true);
        })
        .catch(error => {
          if (!this.isComponentMounted) return;
          if (onError) onError(error);
          if (redirectOnError && redirect) redirect(redirectOnError);
        });
    } else {
      this.setResolvedState(true);
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  setResolvedState = (value: boolean) => {
    this.setState({ resolved: value });
  };

  isComponentMounted = false;

  render() {
    const { successComponent: SuccessComponent, loadingComponent: LoadingComponent, successProps } = this.props;
    return this.state.resolved ? <SuccessComponent {...successProps} /> : <LoadingComponent />;
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  redirect: (URL: string) => dispatch(push(URL)),
});

export default withRouter(connect(undefined, mapDispatchToProps)(Resolver));
