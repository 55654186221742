import React, { PureComponent, Fragment } from 'react';
import { find, map, size } from 'lodash-es';
import { connect } from 'react-redux';
import moment from 'moment';
import { Field, reduxForm, formValueSelector, change, InjectedFormProps } from 'redux-form';
import styled from 'styled-components';

import { AppState } from '../../../store';
import { Container, ButtonSet, Button, Grid, GridColumn, Hide } from '../../../core/components/styled';
import { DatePicker, DaysOfWeekPicker, Dropdown, Input, Switch, TypedField } from '../../../core/components';
import { isDateValidValidator, isRequired } from '../../../utils/services/validator';
import {
  OpportunityToggleContainer,
  OpportunityToggle,
  OpportunityArrowRightIcon,
  OpportunityArrowDownIcon,
} from '../styled';
import { ONE_X_PER_TWO_WEEK, ONE_X_PER_MONTH, ON_CALL, PERCENT } from '../../constants';
import serviceChangeRequestFormInitialValueSelector from '../../services/serviceChangeRequestFormInitialValuesSelector';
import translate from '../../../core/services/translate';
import { vendorPaymentMethodTypes } from '../../ducks';
import WEEK_OF_MONTHS from '../../constants/weekOfMonths';
import { UseIsMobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';

const formSelector = formValueSelector('requestServiceChanges');

const CurrencyContainer = styled.div<{ isMobile: boolean }>`
  position: absolute;
  left: 0;
  bottom: ${p => (!p.isMobile ? '22px' : '12px')};
`;

interface ComponentProps {
  afterDeliveryDate?: string;
  beforeDeliveryDate?: string;
  caseId: number;
  equipmentSubType: string;
  offer: any;
  onCancel(pristine: boolean): void;
}

interface PropsWithoutReduxForm extends ComponentProps {
  change: any;
  endDestinations?: any[];
  equipmentSizes: any;
  frequencies: any[];
  initialValues: any;
  isSaving: boolean;
  holidays: any;
  paymentInfo?: any;
  paymentMethods: any;
  response: any;
  requestedChanges: any;
  requestedChangesState: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

interface State {
  frequency: string;
  isExpanded: boolean;
  isPriceChangeEnabled: boolean;
  isRequestEquipmentChangeEnabled: boolean;
  requestedChanges: any;
}

class RequestServiceChangesForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      frequency: '',
      isExpanded: false,
      isPriceChangeEnabled: false,
      isRequestEquipmentChangeEnabled: false,
      requestedChanges: props.requestedChanges,
    };
  }

  onServiceChangeCalculateTaxbasedPercentage = (serviceValue: number, ctrNamePath: string, value: number) => {
    const { change } = this.props;
    const taxValue = serviceValue ? (serviceValue * (value || 0)) / 100 : undefined;
    change(ctrNamePath, parseFloat(taxValue as any) === 0 ? '' : parseFloat(taxValue as any).toFixed(2));
    return taxValue;
  };

  onPercentageChangeCalculateTaxInDollar = (name: any, value: number, id: any) => {
    const { change } = this.props;
    const service = this.getServiceRate();

    const taxValue = service ? (service * (value || 0)) / 100 : undefined;
    const ctrName = name.currentTarget.name.split('.');
    const ctrNamePath = `${name.currentTarget.name}InDollar`;

    change(ctrNamePath, parseFloat(taxValue as any) === 0 ? '' : parseFloat(taxValue as any).toFixed(2));

    const totalMonthlyServiceFees = this.calculateTotalMonthlyServiceFee(
      ctrName[ctrName.length - 1],
      parseFloat(taxValue as any),
      id,
    );

    this.updateOverallServiceCharge(totalMonthlyServiceFees);
  };

  onTaxInDollarChangeCalculatePercentage = (name: any, value: number, id: any) => {
    const { change } = this.props;
    const service = this.getServiceRate();
    const taxPercentage = service ? (100 * (value || 0)) / parseFloat(service) : undefined;

    const ctrNamePath = name.currentTarget.name.substr(0, name.currentTarget.name.length - 8);
    const ctrName = ctrNamePath.split('.');

    change(
      ctrNamePath,
      parseFloat(taxPercentage as any) === 0 || isNaN(taxPercentage as any)
        ? ''
        : parseFloat(taxPercentage as any).toFixed(2),
    );

    const totalMonthlyServiceFees = this.calculateTotalMonthlyServiceFee(
      ctrName[ctrName.length - 1],
      parseFloat(value as any) || 0,
      id,
    );

    this.updateOverallServiceCharge(totalMonthlyServiceFees);
  };

  onServiceChangeCalculateTotalMonthlyServiceFee = (name: any, value: any, rateCode: string) => {
    const totalMonthlyServiceFees = (this.calculateTotalMonthlyServiceFee as any)(rateCode, value);
    this.updateOverallServiceCharge(totalMonthlyServiceFees);
  };

  getServiceRate = () => {
    const objHaulerRateDetails = this.props.requestedChanges.haulerRateDetails.find(
      (haulerRateDetail: any) => haulerRateDetail.bundled && haulerRateDetail.inherit,
    );

    const service = objHaulerRateDetails && objHaulerRateDetails.rate ? objHaulerRateDetails.rate : 0;
    return service;
  };

  updateOverallServiceCharge = (totalMonthlyServiceFees: any) => {
    const { change } = this.props;
    change(
      `${`changeRequests.settings${this.props.caseId}.requestedChanges.overallserviceCharge`}`,
      parseFloat(totalMonthlyServiceFees) === 0 || isNaN(totalMonthlyServiceFees)
        ? ''
        : parseFloat(totalMonthlyServiceFees),
    );
  };

  calculateTotalMonthlyServiceFee = (ctrName: string, taxValue: any, id: any) => {
    let total = 0;
    this.props.requestedChanges.haulerRateDetails.forEach((fees: any, index: number) => {
      if (fees.bundled && fees.rateType === PERCENT && fees.inherit === false && fees.fee && ctrName !== 'service') {
        if (id === index) {
          total += parseFloat(taxValue);
        } else {
          total += parseFloat(fees.rateInDollar) || 0;
        }
      } else if (fees.bundled && fees.inherit) {
        if (ctrName === 'service') {
          total += parseFloat(taxValue);
        } else {
          total += parseFloat(fees.rate);
        }
      } else if (!fees.bundled && !fees.inherit && fees.isMonthlyFee) {
        if (ctrName === fees.rateCd) {
          total += parseFloat(taxValue);
        } else {
          total += parseFloat(fees.rate);
        }
      } else if (
        ctrName === 'service' &&
        fees.bundled &&
        fees.rateType === PERCENT &&
        fees.inherit === false &&
        fees.fee
      ) {
        total += (this.onServiceChangeCalculateTaxbasedPercentage as any)(
          taxValue,
          `${`changeRequests.settings${this.props.caseId}.requestedChanges.haulerRateDetails[${index}].rateInDollar`}`,
          this.props.requestedChanges.haulerRateDetails[index].rate,
        );
      }
    });

    return parseFloat(total as any).toFixed(2);
  };

  toggle = (event: any) => {
    this.setState(({ isExpanded }) => ({
      isExpanded: !isExpanded,
    }));

    event.preventDefault();
  };

  handleRequestChangeToggle = () => {
    this.setState(({ isRequestEquipmentChangeEnabled }) => ({
      isRequestEquipmentChangeEnabled: !isRequestEquipmentChangeEnabled,
    }));
  };

  handlePriceChangeToggle = () => {
    this.setState(({ isPriceChangeEnabled, isExpanded }) => ({
      isPriceChangeEnabled: !isPriceChangeEnabled,
      isExpanded: false,
    }));
  };

  onFrequencyChange = (event: any, value: string) => {
    this.setState({ frequency: value });
  };

  isValidNumberOfDays = (value: any, formValues: any) => {
    const selectedFrequency =
      formValues.changeRequests[`${`settings${this.props.caseId}`}`].requestedChanges.serviceRecommendation.frequencyId;
    const selectedDays = value.length;
    const frequnecyId =
      selectedFrequency === ONE_X_PER_TWO_WEEK || selectedFrequency === ONE_X_PER_MONTH ? '1' : selectedFrequency;
    if (selectedDays !== parseInt(frequnecyId)) {
      return `Select ${frequnecyId} days.`;
    }
    return undefined;
  };

  render() {
    const {
      afterDeliveryDate,
      beforeDeliveryDate,
      caseId,
      equipmentSubType,
      equipmentSizes,
      frequencies,
      handleSubmit,
      holidays,
      isSaving,
      offer,
      onCancel,
      pristine,
      requestedChangesState,
    } = this.props;
    const { frequency, isExpanded, isPriceChangeEnabled, isRequestEquipmentChangeEnabled } = this.state;
    const filterEquipmentsByBin = equipmentSizes.filter((obj: any) => obj.bin === equipmentSubType);
    const equipmentSizesList = size(filterEquipmentsByBin) > 0 ? filterEquipmentsByBin[0].equipmentSizes : [];

    const equipmentsOptions = map(equipmentSizesList, equipment => ({
      label: equipment.svcDescription,
      value: equipment.svcCode,
    }));

    const frequenciesOptions = map(frequencies, frequency => ({
      label: frequency.frequencyDescription,
      value: frequency.frequencyId,
    }));

    const weekOptions = map(WEEK_OF_MONTHS, w => ({
      label: w.name,
      value: w.id,
    }));
    const daysOfServiceEnabled = frequency && frequency !== ON_CALL;
    const isWeekSelectionEnabled = frequency && frequency === ONE_X_PER_MONTH;
    return (
      <UseIsMobileWidthView
        render={isMobile => (
          <Container isLoading={isSaving}>
            <form onSubmit={handleSubmit} noValidate>
              <Container>
                <Field
                  name={`changeRequests.settings${caseId}.requestedChanges.deliveryDate`}
                  component={DatePicker}
                  label={translate('common.startDate')}
                  validate={
                    isRequestEquipmentChangeEnabled || isPriceChangeEnabled
                      ? [isRequired, isDateValidValidator]
                      : undefined
                  }
                  margin="medium no xSmall"
                  disabledDays={[
                    {
                      before: moment(beforeDeliveryDate, 'MM/DD/YYYY').toDate(),
                      after: moment(afterDeliveryDate, 'MM/DD/YYYY').toDate(),
                    },
                    { daysOfWeek: [0, 6] },
                    ...holidays,
                  ]}
                  disabled={!isRequestEquipmentChangeEnabled && !isPriceChangeEnabled}
                />
              </Container>
              <Container>
                <Field
                  name="isRequestEquipmentChangeEnabled"
                  component={Switch}
                  onChange={this.handleRequestChangeToggle}
                  label={translate('opportunity.serviceChangeRequest.requestEquipmentChange')}
                  margin="medium no xSmall"
                />
              </Container>

              {isRequestEquipmentChangeEnabled && (
                <Container>
                  <Grid column={isMobile}>
                    <GridColumn size={isMobile ? '12/12' : '5/12'}>
                      <Field
                        name={`changeRequests.settings${caseId}.requestedChanges.serviceRecommendation.equipmentId`}
                        component={Dropdown as any}
                        options={equipmentsOptions}
                        label={translate('equipmentSize')}
                        validate={isRequestEquipmentChangeEnabled ? [isRequired] : undefined}
                        disabled={!isRequestEquipmentChangeEnabled}
                        margin={isMobile ? 'no' : 'small no'}
                      />
                    </GridColumn>
                    <GridColumn size={isMobile ? '12/12' : '5/12'}>
                      <TypedField
                        name={`changeRequests.settings${caseId}.requestedChanges.serviceRecommendation.frequencyId`}
                        component={Dropdown}
                        validate={isRequestEquipmentChangeEnabled ? [isRequired] : undefined}
                        onChange={(event, value) => this.onFrequencyChange(event, value)}
                        props={{
                          options: frequenciesOptions,
                          label: translate('frequency'),
                          disabled: !isRequestEquipmentChangeEnabled,
                          margin: isMobile ? 'no' : 'small no',
                        }}
                      />
                    </GridColumn>
                    <GridColumn size={isMobile ? '12/12' : '2/12'}>
                      <Field
                        name={`changeRequests.settings${caseId}.requestedChanges.serviceRecommendation.quantity`}
                        component={Input}
                        type="number"
                        label={translate('qty')}
                        validate={isRequestEquipmentChangeEnabled ? [isRequired] : undefined}
                        margin={isMobile ? 'no' : 'small no'}
                        disabled={!isRequestEquipmentChangeEnabled}
                      />
                    </GridColumn>
                  </Grid>
                  {daysOfServiceEnabled && (
                    <GridColumn size={'12/12'}>
                      <Field
                        name={`changeRequests.settings${caseId}.requestedChanges.schedule`}
                        component={DaysOfWeekPicker}
                        label={translate('opportunity.deliveries.pickupWillBeOn')}
                        validate={[isRequired, this.isValidNumberOfDays]}
                        multiple
                        daysOfWeekProps={{ margin: 'Small no Small' }}
                        dayOfWeekProps={{ margin: 'no small no no' }}
                        margin={isMobile ? 'no' : 'xSmall'}
                      />
                    </GridColumn>
                  )}
                  {isWeekSelectionEnabled && (
                    <GridColumn size={'12/12'}>
                      <TypedField
                        name={`changeRequests.settings${caseId}.requestedChanges.week`}
                        component={Dropdown}
                        validate={isRequestEquipmentChangeEnabled ? [isRequired] : undefined}
                        props={{
                          options: weekOptions,
                          label: translate('opportunity.serviceChangeRequest.week'),
                          margin: isMobile ? 'no' : 'xSmall',
                          disabled: !isRequestEquipmentChangeEnabled,
                        }}
                      />
                    </GridColumn>
                  )}
                  <GridColumn size={'12/12'}>
                    <Field
                      name={`changeRequests.settings${caseId}.requestedChanges.serviceRecommendation.notes`}
                      component={Input}
                      type="text"
                      label={translate('autoDispatch.addNote')}
                      size="small"
                      margin={isMobile ? 'no' : 'small'}
                      disabled={!isRequestEquipmentChangeEnabled}
                    />
                  </GridColumn>
                </Container>
              )}

              <Container>
                <Field
                  name="isPriceChangeEnabled"
                  component={Switch}
                  onChange={this.handlePriceChangeToggle}
                  label={translate('opportunity.serviceChangeRequest.requestPriceChange')}
                  margin="medium no xSmall"
                />
              </Container>

              {isPriceChangeEnabled && (
                <Fragment>
                  <Container>
                    {(requestedChangesState.overallserviceCharge || requestedChangesState.overallserviceCharge === 0) &&
                      offer.isServiceBaseRate && (
                        <Grid>
                          <GridColumn size="12/12" padding="no xSmall">
                            <CurrencyContainer isMobile={isMobile}>$</CurrencyContainer>
                            <Field
                              name={`changeRequests.settings${caseId}.requestedChanges.overallserviceCharge`}
                              component={Input}
                              type="number"
                              label={translate('opportunity.overallMonthlyTotal')}
                              margin={isMobile ? 'no' : 'xSmall'}
                              disabled
                            />
                          </GridColumn>
                          <GridColumn size="2/12" padding="no">
                            {offer.isServiceBaseRate && (
                              <OpportunityToggleContainer>
                                {requestedChangesState.overallserviceCharge && (
                                  <OpportunityToggle onClick={this.toggle} title={translate('common.edit')}>
                                    {isExpanded ? (
                                      <OpportunityArrowRightIcon icon="arrowRight" color="gray" />
                                    ) : (
                                      <OpportunityArrowDownIcon icon="arrowDown" color="gray" />
                                    )}
                                  </OpportunityToggle>
                                )}
                              </OpportunityToggleContainer>
                            )}
                          </GridColumn>
                        </Grid>
                      )}
                  </Container>

                  <Container>
                    {!!size(requestedChangesState.haulerRateDetails) &&
                      requestedChangesState.haulerRateDetails.map((fees: any, index: number) => (
                        <Fragment key={index}>
                          <Hide hide={isExpanded}>
                            <Grid margin="no small">
                              {fees.bundled && fees.rateType === PERCENT && !fees.inherit && fees.fee && (
                                <>
                                  <GridColumn padding="no" size="7/12">
                                    <CurrencyContainer isMobile={isMobile}>$</CurrencyContainer>
                                    <Field
                                      name={`changeRequests.settings${caseId}.requestedChanges.haulerRateDetails[${index}].rateInDollar`}
                                      component={Input}
                                      type="number"
                                      label={fees.rateDesc}
                                      margin={isMobile ? 'no xSmall' : 'no small xSmall'}
                                      onChange={(name: any, value: any) =>
                                        this.onTaxInDollarChangeCalculatePercentage(name, value, index)
                                      }
                                      disabled={!isPriceChangeEnabled}
                                    />
                                  </GridColumn>
                                  <GridColumn size="5/12">
                                    <Field
                                      name={`changeRequests.settings${caseId}.requestedChanges.haulerRateDetails[${index}].rate`}
                                      component={Input}
                                      type="number"
                                      label="%"
                                      margin={isMobile ? 'no xSmall' : 'no small xSmall'}
                                      onChange={(name: any, value: any) =>
                                        this.onPercentageChangeCalculateTaxInDollar(name, value, index)
                                      }
                                      disabled={!isPriceChangeEnabled}
                                    />
                                  </GridColumn>
                                </>
                              )}
                              {fees.bundled && fees.inherit && (
                                <GridColumn padding="no" size="12/12">
                                  <CurrencyContainer isMobile={isMobile}>$</CurrencyContainer>
                                  <Field
                                    name={`changeRequests.settings${caseId}.requestedChanges.haulerRateDetails[${index}].rate`}
                                    component={Input}
                                    type="number"
                                    label={`${fees.rateDesc} (${translate(
                                      `opportunity.${fees.uom ? fees.uom.toLowerCase() : ' '}`,
                                    )})`}
                                    validate={isPriceChangeEnabled ? isRequired : undefined}
                                    margin={isMobile ? 'no xSmall' : 'no small xSmall'}
                                    onChange={(name: any, value: any) =>
                                      this.onServiceChangeCalculateTotalMonthlyServiceFee(name, value, 'service')
                                    }
                                    disabled={!isPriceChangeEnabled}
                                  />
                                </GridColumn>
                              )}
                              {!fees.bundled && !fees.inherit && fees.isMonthlyFee && (
                                <GridColumn padding="no" size="12/12">
                                  <CurrencyContainer isMobile={isMobile}>$</CurrencyContainer>
                                  <Field
                                    name={`changeRequests.settings${caseId}.requestedChanges.haulerRateDetails[${index}].rate`}
                                    component={Input}
                                    type="number"
                                    label={`${fees.rateDesc} (${translate(
                                      `opportunity.${fees.uom ? fees.uom.toLowerCase() : ' '}`,
                                    )})`}
                                    disabled={!isPriceChangeEnabled}
                                    validate={isPriceChangeEnabled && fees.mandatory ? isRequired : undefined}
                                    margin={isMobile ? 'no xSmall' : 'no small xSmall'}
                                    onChange={(name: any, value: any) =>
                                      this.onServiceChangeCalculateTotalMonthlyServiceFee(name, value, fees.rateCd)
                                    }
                                  />
                                </GridColumn>
                              )}
                            </Grid>
                          </Hide>
                          {!fees.bundled && !fees.inherit && !fees.isMonthlyFee && (
                            <Grid>
                              <GridColumn padding="no" size="12/12">
                                <CurrencyContainer isMobile={isMobile}>$</CurrencyContainer>
                                <Field
                                  name={`changeRequests.settings${caseId}.requestedChanges.haulerRateDetails[${index}].rate`}
                                  component={Input}
                                  type="number"
                                  label={`${fees.rateDesc} (${translate(
                                    `opportunity.${fees.uom ? fees.uom.toLowerCase() : ' '}`,
                                  )})`}
                                  disabled={!isPriceChangeEnabled}
                                  validate={isPriceChangeEnabled && fees.mandatory ? isRequired : undefined}
                                  margin={isMobile ? 'no xSmall' : 'xSmall'}
                                />
                              </GridColumn>
                            </Grid>
                          )}
                        </Fragment>
                      ))}
                  </Container>

                  <Container>
                    <Field
                      name={`changeRequests.settings${caseId}.requestedChanges.notes`}
                      component={Input}
                      type="text"
                      label={translate('autoDispatch.addNote')}
                      size="small"
                      margin="no small"
                      disabled={!isPriceChangeEnabled}
                    />
                  </Container>
                </Fragment>
              )}

              <ButtonSet margin="large auto no">
                <Button
                  type="submit"
                  color="primary"
                  disabled={!isRequestEquipmentChangeEnabled && !isPriceChangeEnabled}
                >
                  {translate('opportunity.submit')}
                </Button>
                <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
                  {translate('common.cancel')}
                </Button>
              </ButtonSet>
            </form>
          </Container>
        )}
      />
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const changeRequestByCaseId = (find as any)(state.opportunity.serviceChangeRequest.serviceChangeRequests, {
    caseId: ownProps.caseId,
  });
  const { paymentInfo } = state.opportunity.serviceChangeRequest as any;
  return {
    equipmentSizes: state.opportunity.serviceOptions.equipmentSizes,
    frequencies: state.opportunity.serviceOptions.frequencies,
    initialValues: serviceChangeRequestFormInitialValueSelector(
      state.opportunity.serviceChangeRequest.serviceChangeRequests,
    ),
    isSaving: state.opportunity.serviceChangeRequest.isSaving,
    holidays: state.common.holidays.holidays,
    paymentMethods: paymentInfo.paymentMethods,
    paymentInfo: vendorPaymentMethodTypes(
      paymentInfo.vendorPayments,
      formSelector(state, `changeRequests.settings${ownProps.caseId}.requestedChanges.paymentMethod`),
    ),
    response: formSelector(state, `changeRequests.settings${ownProps.caseId}.response`),
    requestedChanges: formSelector(state, `changeRequests.settings${ownProps.caseId}.requestedChanges`),
    requestedChangesState: changeRequestByCaseId.requestedChanges,
  };
};

const mapDispatchToProps = {
  change,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'requestServiceChanges',
    enableReinitialize: true,
  })(RequestServiceChangesForm),
);
