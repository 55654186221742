import React, { Fragment } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { date, time } from '../../utils/services/formatter';
import { Text } from './styled';

const DateTimeDisplay = ({ input: { value } }: WrappedFieldProps) =>
  value && (
    <Fragment>
      <Text block margin="no no xxSmall">
        {date(value)}
      </Text>
      <Text block size="small">
        {time(value)}
      </Text>
    </Fragment>
  );

export default DateTimeDisplay;
