import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { loadStreetNetworkSegmentDetails } from 'src/customers/ducks';
import { clearDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { AlternativeFleetStreetSegment } from 'src/dashboard/interfaces/alterativeFleetOps';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import DashboardSnowSweeperStreetSegmentsGLPopup from './DashboardSnowSweeperStreetSegmentsGLPopup';
import { getSegmentCenter, parseSegmentJSON } from './utils';

interface Props {
  segments: AlternativeFleetStreetSegment[];
  isSnowPlow: boolean;
}

const DashboardSnowSweeperStreetSegmentsGLPopupResolver: FC<Props> = ({ segments, isSnowPlow }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const { selectedFeature: selectedFeatureDashboard } = useSelector(state => state.dashboard.mapControls);

  const segment = useMemo(() => {
    if (!selectedFeatureDashboard || selectedFeatureDashboard.namespace !== 'routeSegments' || !segments.length)
      return undefined;
    const selectedSegment = segments.find(segment => segment.streetSegmentId === selectedFeatureDashboard?.id);
    if (!selectedSegment) {
      return undefined;
    }
    const segmentCenter = getSegmentCenter(parseSegmentJSON(selectedSegment.lineSegment));
    return {
      ...selectedSegment,
      segmentCenter,
    };
  }, [selectedFeatureDashboard, segments]);

  const loadDependencies = useCallback(() => {
    const dependencies = [];

    if (selectedFeatureDashboard?.namespace === 'routeSegments' && selectedFeatureDashboard?.id && vendorId) {
      const vehicleTypeId = isSnowPlow ? SNOW_PLOW_ID : STREET_SWEEPER_ID;
      dependencies.push(
        loadStreetNetworkSegmentDetails(
          vendorId,
          Number(selectedFeatureDashboard.id),
          undefined,
          vehicleTypeId,
        )(dispatch),
      );
    } else return Promise.reject();

    return Promise.all(dependencies);
  }, [dispatch, isSnowPlow, selectedFeatureDashboard, vendorId]);

  if (!segment || !selectedFeatureDashboard) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={segment?.segmentCenter[1]}
      longitude={segment?.segmentCenter[0]}
      onClose={() => dispatch(clearDashboardSelectedFeature())}
    >
      <TypedResolver
        resolve={loadDependencies}
        successComponent={DashboardSnowSweeperStreetSegmentsGLPopup}
        successProps={{ segment, isSnowPlow }}
        loadingComponent={MapGLPopupLoading}
        loadingProps={{ minHeight: 320 }}
        onError={() => dispatch(clearDashboardSelectedFeature())}
      />
    </MapGLPopupWrapper>
  );
};

export default DashboardSnowSweeperStreetSegmentsGLPopupResolver;
