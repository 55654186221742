import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { TypedResolver } from '../../../core/components';
import { PageLoadingOverlay } from '../../../common/components/styled';
import RubiconDispatchesNotesModal from './RubiconDispatchesNotesModal';
import { AppState } from '../../../store';
import { currentUserId, currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { getNotes } from '../../ducks';

interface RouteParams {
  token?: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  onCancel(isReload: boolean, pristine: boolean): void;
}

const RubiconDispatchesNotesModalResolver: React.FC<Props> = ({
  onCancel,
  match: {
    params: { token },
  },
}) => {
  const dispatch = useDispatch();

  const userId = useSelector((state: AppState) => currentUserId(state));
  const vendorId = useSelector((state: AppState) => currentVendorId(state));
  const modal = useSelector((state: AppState) => state.fleet.notes.modal);

  const loadDependencies = () => {
    if (!modal) {
      return Promise.all([]);
    }

    const { workOrder, serviceDate } = modal;
    const promises = [getNotes({ token, userId, vendorId, workOrder, serviceDate })(dispatch)];

    return Promise.all(promises);
  };

  if (!modal) {
    return null;
  }

  return (
    <TypedResolver
      successComponent={RubiconDispatchesNotesModal}
      successProps={{ onCancel }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default withRouter(RubiconDispatchesNotesModalResolver);
