import { FC, memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import { VENDOR_LOCATION_ICON_TYPES_NEW } from 'src/common/constants/vendorLocationIcons';

export const TRAVEL_PATH_HAULER_LOCATIONS_SOURCE = 'travelPathHaulerLocationsSource';

interface Props {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
}

const TravelPathHaulerLocationsGLSource: FC<Props> = ({ geoJSON }) => {
  return (
    <Source id={TRAVEL_PATH_HAULER_LOCATIONS_SOURCE} type="geojson" data={geoJSON}>
      {VENDOR_LOCATION_ICON_TYPES_NEW.map(iconType => (
        <Layer
          key={iconType.id}
          id={iconType.id}
          type="symbol"
          source={TRAVEL_PATH_HAULER_LOCATIONS_SOURCE}
          filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'icon'], iconType.id]]}
          paint={{}}
          layout={{
            'icon-image': iconType.id,
            'icon-size': 0.5,
            'icon-allow-overlap': true,
          }}
        />
      ))}
    </Source>
  );
};

export default memo(TravelPathHaulerLocationsGLSource);
