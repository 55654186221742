import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { submit, isPristine } from 'redux-form';

import { Modal } from '../../../core/components';
import translate from '../../../core/services/translate';
import { Button, ModalSection } from '../../../core/components/styled';
import CompletePreventativeMaintenanceMilestoneForm from '../forms/CompletePreventativeMaintenanceMilestoneForm';
import { AppState } from '../../../store';
import { ShadowedButtonSet } from '../../../core/components/styled/ButtonSet';
import { PM_COMPLETE_MILESTONE_FORM } from '../../constants/preventativeMaintenance';
import {
  PreventativeMaintenanceTypeId,
  PreventativeMaintenanceMilestoneCompletePayload,
} from '../../interfaces/PreventativeMaintenance';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import * as PMDucks from '../../ducks/preventativeMaintenance';
import confirm from '../../../core/services/confirm';

export interface CompletePreventativeMaintenanceMilestoneModalProps {
  vehicleId: number;
  typeId: PreventativeMaintenanceTypeId;
  onClose(): void;
}

const CompletePreventativeMaintenanceMilestoneModal: React.FC<CompletePreventativeMaintenanceMilestoneModalProps> = ({
  vehicleId,
  typeId,
  onClose,
}) => {
  const dispatch = useDispatch();

  const vehicleMilestoneCompleteIsLoading = useSelector(
    (state: AppState) => state.fleet.preventativeMaintenance.vehicleMilestoneCompleteIsLoading,
  );
  const formPristine = useSelector(isPristine(PM_COMPLETE_MILESTONE_FORM));

  const closeModal = async (pristine = formPristine) => {
    if (!pristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    onClose();
  };

  const submitForm = () => {
    dispatch(submit(PM_COMPLETE_MILESTONE_FORM));
  };

  const completeMilestone = (values: PreventativeMaintenanceMilestoneCompletePayload) => {
    PMDucks.updateVehiclePM(
      vehicleId,
      values,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('vehicles.pmCompleted'));

        PMDucks.loadVehiclePMStatus(vehicleId)(dispatch);
        PMDucks.loadVehiclePMLogs(vehicleId)(dispatch);
        closeModal(true);
      })
      .catch(() => {
        createErrorNotification(translate('vehicles.pmCompleteFailed'));
      });
  };

  return (
    <Modal
      hasBorderRadius
      size="small"
      padding="medium no no"
      title={translate('vehicles.completePreventativeMaintenance')}
      onClose={() => closeModal()}
    >
      <ModalSection padding="medium grande" height="350px" isLoading={vehicleMilestoneCompleteIsLoading}>
        <CompletePreventativeMaintenanceMilestoneForm typeId={typeId} onSubmit={completeMilestone} />
      </ModalSection>

      <ShadowedButtonSet>
        <Button color="primary" onClick={submitForm}>
          {translate('common.save')}
        </Button>
      </ShadowedButtonSet>
    </Modal>
  );
};

export default CompletePreventativeMaintenanceMilestoneModal;
