import React, { PureComponent } from 'react';
import moment from 'moment';

import { TableActionButton, TableCell, TableRow, Text, Link } from '../../../core/components/styled';
import { ActionButtonTooltip } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props {
  date: Date | string;
  driverName: string;
  endTime: string;
  exportTripInspection: (id: number, routeDate: Date | string) => void;
  id: number;
  numberOfIssues: number;
  routeDate: string;
  startTime: string;
  tripInspectionType: number;
  vehicleId: number;
}

class VehicleInspectionDataTableRow extends PureComponent<Props> {
  render() {
    const {
      routeDate,
      driverName,
      endTime,
      exportTripInspection,
      id,
      numberOfIssues,
      startTime,
      tripInspectionType,
      vehicleId,
    } = this.props;

    const formatedStartTime = moment(startTime, 'hh:mm:ss').format('LT');
    const formatedEndTime = moment(endTime, 'hh:mm:ss').format('LT');

    return (
      <TableRow>
        <TableCell width={'13%'}>
          <Text block weight="medium" margin="no no xxSmall" singleLine>
            {routeDate}
          </Text>
        </TableCell>

        <TableCell width={'20%'}>
          <Text block weight="medium" margin="no no xxSmall" singleLine>
            {driverName}
          </Text>
        </TableCell>

        <TableCell width={'15%'}>
          <Text block weight="medium" margin="no no xxSmall" singleLine>
            {tripInspectionType}
          </Text>
        </TableCell>

        <TableCell width={'10%'}>
          <Text block weight="medium" margin="no no xxSmall" singleLine>
            {formatedStartTime}
          </Text>
        </TableCell>

        <TableCell width={'10%'}>
          <Text block weight="medium" margin="no no xxSmall" singleLine>
            {formatedEndTime}
          </Text>
        </TableCell>

        <TableCell width={'12%'}>
          <Text block weight="medium" margin="no no xxSmall" singleLine>
            {numberOfIssues}
          </Text>
        </TableCell>

        <TableCell width={'20%'}>
          <Link to={`/fleet/vehicles/${vehicleId}/inspection-data/${id}`}>
            {translate('vehicles.vehicleInspections.viewDetails')}
          </Link>
          <TableActionButton onClick={event => exportTripInspection(id, routeDate)} margin={'no xSmall'}>
            <ActionButtonTooltip icon="download" tooltip="downloadReport" />
          </TableActionButton>
        </TableCell>
      </TableRow>
    );
  }
}

export default VehicleInspectionDataTableRow;
