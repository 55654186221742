import { FC, memo } from 'react';
import { Layer } from 'react-map-gl';

import {
  STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE,
  STREET_NETWORK_SERVICE_AREAS_HAULER_LOCATIONS_LAYER,
} from 'src/customers/constants';

const HaulerLocationsGLLayer: FC = memo(() => (
  <>
    <Layer
      id={STREET_NETWORK_SERVICE_AREAS_HAULER_LOCATIONS_LAYER}
      type="symbol"
      source={STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE}
      filter={[
        'all',
        ['!', ['has', 'point_count']],
        ['==', ['get', 'layer'], STREET_NETWORK_SERVICE_AREAS_HAULER_LOCATIONS_LAYER],
      ]}
      paint={{}}
      layout={{
        'icon-image': ['get', 'icon'],
        'icon-size': 0.5,
        'icon-allow-overlap': true,
      }}
    />
  </>
));

export default HaulerLocationsGLLayer;
