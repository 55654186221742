import React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { Modal, Table } from '../../../core/components';
import { ModalSection, ModalSubtitle, ModalTitle, Text } from '../../../core/components/styled';
import { ROLL_OFF_ID } from '../../../fleet/constants';
import { WeightTicketsTableRow } from '.';
import DisposalTripsSection from 'src/routes/components/modals/routeModalSections/DisposalTripsSection';
import translate from '../../../core/services/translate';

const weightTicketTableCells = [
  { name: 'ticketNumber', label: translate('routes.ticketNumber'), width: '15%' },
  { name: 'locationId', label: translate('common.disposalSite'), width: '30%' },
  { name: 'weight', label: translate('common.weight'), width: '20%' },
  { name: 'unitOfMeasureTypeId', label: translate('common.unit'), width: '15%' },
  { name: 'activityTimeLocal', label: translate('common.timestamp'), width: '20%' },
];

const weightTicketsRollOffTableCells = [
  { name: 'ticketNumber', label: translate('routes.ticketNumber'), width: '11%' },
  { name: 'locationId', label: translate('common.disposalSite'), width: '17%' },
  { name: 'vehicleDriver', label: translate('common.vehicleDriver'), width: '17%' },
  { name: 'weight', label: translate('common.weight'), width: '10%' },
  { name: 'unitOfMeasureTypeId', label: translate('common.unit'), width: '8%' },
  { name: 'activityTimeLocal', label: translate('common.timestamp'), width: '13%' },
  { name: 'customer', label: translate('common.customer'), width: '14%' },
  { name: 'images', label: translate('common.image'), width: '10%' },
];

const weightTicketsOtherTableCells = [
  { name: 'ticketNumber', label: translate('routes.ticketNumber'), width: '11%' },
  { name: 'locationId', label: translate('common.disposalSite'), width: '25%' },
  { name: 'vehicleDriver', label: translate('common.vehicleDriver'), width: '16%' },
  { name: 'weight', label: translate('common.weight'), width: '11%' },
  { name: 'unitOfMeasureTypeId', label: translate('common.unit'), width: '13%' },
  { name: 'activityTimeLocal', label: translate('common.timestamp'), width: '13%' },
  { name: 'images', label: translate('common.image'), width: '11%' },
];

interface Props {
  closeModal: () => void;
  isDisposalSectionHidden?: boolean;
  modalSubTitle?: string;
  modalTitle?: string;
  siteAddress?: string;
  vehicleTypeId?: number;
  weightTickets: any[];
}

const WeightTicketsModal: React.FC<Props> = props => {
  const { closeModal, isDisposalSectionHidden, modalSubTitle, modalTitle, siteAddress, vehicleTypeId, weightTickets } =
    props;

  const tableCells =
    !!vehicleTypeId && vehicleTypeId === ROLL_OFF_ID ? weightTicketsRollOffTableCells : weightTicketsOtherTableCells;

  return (
    <Modal size="large" subTitle={modalSubTitle} title={modalTitle} onClose={closeModal} padding="medium">
      {siteAddress && <ModalSubtitle>{siteAddress}</ModalSubtitle>}

      <ModalSection margin="small no no">
        {weightTickets.length ? (
          <Table
            cells={tableCells || weightTicketTableCells}
            rows={weightTickets}
            rowComponent={WeightTicketsTableRow}
            rowProps={{ vehicleTypeId }}
          />
        ) : (
          <Text block align="center" padding="no no small no">
            {translate('routes.noDisposalTickets')}
          </Text>
        )}
      </ModalSection>

      {!isDisposalSectionHidden && (
        <>
          <ModalTitle margin="medium no no no">{translate('routes.disposalTrips')}</ModalTitle>

          <ModalSection margin="no">
            <DisposalTripsSection margin="small no no no" />
          </ModalSection>
        </>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  weightTickets: state.routes.weightTickets.weightTickets as any,
});

export default connect(mapStateToProps)(WeightTicketsModal);
