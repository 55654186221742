import { Field } from 'redux-form';
import { Switch } from '../../core/components';
import { TableCell, TableRow } from '../../core/components/styled';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID, VEHICLE_TYPE_IDS } from '../../fleet/constants';

interface Props {
  vehicleTypeId: number;
}

const TimeManagementTableRow = ({ vehicleTypeId }: Props) => {
  const isSnowOrSweeper = vehicleTypeId === SNOW_PLOW_ID || vehicleTypeId === STREET_SWEEPER_ID;
  return (
    <TableRow>
      <TableCell width="25%">{VEHICLE_TYPE_IDS[vehicleTypeId].name}</TableCell>
      <TableCell width="15%">
        <Field component={Switch} name={`${vehicleTypeId}.isActive`} disabled={!isSnowOrSweeper} />
      </TableCell>
      <TableCell width="20%">
        <Field component={Switch} name={`${vehicleTypeId}.breaktime`} disabled={!isSnowOrSweeper} />
      </TableCell>
      <TableCell width="20%">
        <Field component={Switch} name={`${vehicleTypeId}.drivingToFromFacility`} disabled={!isSnowOrSweeper} />
      </TableCell>
      <TableCell width="20%">
        <Field component={Switch} name={`${vehicleTypeId}.offRoute`} disabled={!isSnowOrSweeper} />
      </TableCell>
    </TableRow>
  );
};

export default TimeManagementTableRow;
