import { FC } from 'react';
import { size } from 'lodash-es';

import {
  checkIfGeoFenceIsEnabled,
  checkIfSnowPlowIsEnabled,
  checkIfStreetSweepingIsEnabled,
} from 'src/vendors/ducks/features';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import {
  GEO_FENCES_CREATE_EDIT_DELETE,
  GEO_FENCES_DAILY_ROUTE,
  GEO_FENCES_ROUTE_TEMPLATE,
} from 'src/account/constants';
import { IconButtonIcon } from 'src/core/components/styled';
import { PermissionGuard } from 'src/account/components';
import { useSelector } from 'src/core/hooks/useSelector';
import RouteGeoFenceAlerts from 'src/routes/components/pages/geoFences/components/RouteGeoFenceAlerts';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import translate from 'src/core/services/translate';

interface Props {
  editGeoFence: () => void;
  editPolygon: boolean;
  geoFenceEditing: boolean;
  geoFenceExists: boolean;
  geoFenceId?: number;
  handleAddPolygon: () => void;
  handleDeletePolygon: () => void;
  handleEditCancelEditGeoFences: () => void;
  handleEditSaveGeoFences: (e: any) => void;
  handleEnterDrawMode: () => void;
  handleEnterDrawSelectionMode: () => void;
  handleExitDrawMode: () => void;
  handleExitDrawSelectionMode: () => void;
  handleSaveGeoFence: () => void;
  handleUndoGeoFence: (e: any) => void;
  hasChanges: boolean;
  hasDeletedAllPolygons: boolean;
  isAddingPolygon: boolean;
  isDailyRoute?: boolean;
  isRouteTemplate?: boolean;
  isRouteTemplateEditor?: boolean;
  isSnowPlowRoute?: boolean;
  isStreetNetwork?: boolean;
  isStreetSweeperRoute?: boolean;
  polygon: any;
  selectedGeoFenceGeo: any;
  setEditPolygon: (value: boolean) => void;
  setIsStreetSegmentsMapFiltersOpen: (value: boolean) => void;
  setRouteMapActionsOpen: (value: boolean) => void;
  setShowRouteGeoFence: (value: boolean) => void;
  showRouteGeoFence: boolean;
}

const StreetNetworkMapControls: FC<Props> = ({
  editGeoFence,
  editPolygon,
  geoFenceEditing,
  geoFenceExists,
  geoFenceId,
  handleAddPolygon,
  handleDeletePolygon,
  handleEditCancelEditGeoFences,
  handleEditSaveGeoFences,
  handleEnterDrawMode,
  handleEnterDrawSelectionMode,
  handleExitDrawMode,
  handleExitDrawSelectionMode,
  handleSaveGeoFence,
  handleUndoGeoFence,
  hasChanges,
  hasDeletedAllPolygons,
  isAddingPolygon,
  isDailyRoute,
  isRouteTemplate,
  isRouteTemplateEditor,
  isSnowPlowRoute,
  isStreetNetwork,
  isStreetSweeperRoute,
  polygon,
  selectedGeoFenceGeo,
  setEditPolygon,
  setIsStreetSegmentsMapFiltersOpen,
  setRouteMapActionsOpen,
  setShowRouteGeoFence,
  showRouteGeoFence,
}) => {
  const { isEditSegmentsModeOn, isEditGeoFencesModeOn, isEditRouteGeoFenceModeOn, isDrawSelectionModeOn } = useSelector(
    state => state.customers.streetNetwork,
  ) as any;

  const { geoFences } = useSelector(state => state.routes.geoFences.geoFences);

  const isSnowPlowFeatureEnabled = useSelector(checkIfSnowPlowIsEnabled);
  const isStreetSweepingFeatureEnabled = useSelector(checkIfStreetSweepingIsEnabled);
  const isGeoFenceEnabled = useSelector(checkIfGeoFenceIsEnabled);

  return (
    <>
      {!isStreetNetwork && (
        <>
          <ComplexMapControl position="top-left">
            <TooltipIconButton
              tooltip="filters"
              tooltipPosition="right"
              tooltipColor="grayDarker"
              color="secondary"
              margin="no"
              onClick={() => setIsStreetSegmentsMapFiltersOpen(true)}
            >
              <IconButtonIcon icon="mapFilter" color="primary" />
            </TooltipIconButton>
          </ComplexMapControl>

          {isDailyRoute && !isEditSegmentsModeOn && (
            <ComplexMapControl position="top-right">
              <TooltipIconButton
                tooltip="mapActions"
                tooltipPosition="left"
                tooltipColor="grayDarker"
                color="secondary"
                margin="no"
                onClick={() => setRouteMapActionsOpen(true)}
              >
                <IconButtonIcon icon="mapActions" color="primary" />
              </TooltipIconButton>
            </ComplexMapControl>
          )}

          {isEditSegmentsModeOn && !isEditRouteGeoFenceModeOn && (
            <ComplexMapControl vertical position="top-left" yOffset={50}>
              <TooltipIconButton
                tooltipAsString
                tooltip={translate(
                  `routeTemplateBuilder.${
                    isDrawSelectionModeOn
                      ? 'deletePolygon'
                      : (!isSnowPlowFeatureEnabled && isSnowPlowRoute) ||
                        (!isStreetSweepingFeatureEnabled && isStreetSweeperRoute)
                      ? 'featureNotEnabled'
                      : 'drawPolygon'
                  }`,
                )}
                disabled={
                  (!isSnowPlowFeatureEnabled && isSnowPlowRoute) ||
                  (!isStreetSweepingFeatureEnabled && isStreetSweeperRoute)
                }
                tooltipPosition="right"
                tooltipColor="grayDarker"
                color={isDrawSelectionModeOn ? 'warning' : 'secondary'}
                margin="no"
                onClick={isDrawSelectionModeOn ? handleExitDrawSelectionMode : handleEnterDrawSelectionMode}
              >
                <IconButtonIcon
                  icon={isDrawSelectionModeOn ? 'delete' : 'lasso'}
                  size="medium"
                  color={isDrawSelectionModeOn ? 'white' : 'primary'}
                />
              </TooltipIconButton>
              {!!polygon.length && (
                <TooltipIconButton
                  tooltipAsString
                  tooltip={translate(
                    editPolygon ? 'routeTemplateBuilder.disableEditPolygon' : 'routeTemplateBuilder.editPolygon',
                  )}
                  tooltipPosition="right"
                  tooltipColor="grayDarker"
                  margin="small no"
                  color={editPolygon ? 'primary' : 'secondary'}
                  onClick={() => {
                    setEditPolygon(!editPolygon);
                  }}
                >
                  <IconButtonIcon margin="no" icon="edit" size="small" />
                </TooltipIconButton>
              )}
            </ComplexMapControl>
          )}
          {!isRouteTemplate && !isDailyRoute && geoFences && isGeoFenceEnabled && (
            <PermissionGuard permission={GEO_FENCES_CREATE_EDIT_DELETE}>
              <ComplexMapControl vertical position="top-right">
                <TooltipIconButton
                  color="secondary"
                  tooltip={`${isEditGeoFencesModeOn ? 'cancel' : 'editGeoFences'}`}
                  tooltipPosition="left"
                  margin="small no no"
                  onClick={handleEditCancelEditGeoFences}
                >
                  <IconButtonIcon
                    icon={`${isEditGeoFencesModeOn ? 'close' : 'createGeoFence'}`}
                    size={isEditGeoFencesModeOn ? 'small' : 'medium'}
                  />
                </TooltipIconButton>

                {isEditGeoFencesModeOn && (
                  <>
                    <TooltipIconButton
                      disabled={isEditGeoFencesModeOn && !hasChanges}
                      color="secondary"
                      tooltip={`${isEditGeoFencesModeOn ? 'save' : 'editGeoFences'}`}
                      tooltipPosition="left"
                      margin="small no no"
                      onClick={handleEditSaveGeoFences}
                    >
                      <IconButtonIcon icon="check" size="medium" />
                    </TooltipIconButton>

                    {selectedGeoFenceGeo && (
                      <TooltipIconButton
                        disabled={size(selectedGeoFenceGeo.history) <= 1}
                        color="secondary"
                        tooltip="undo"
                        tooltipPosition="left"
                        margin="small no no"
                        onClick={handleUndoGeoFence}
                        type="button"
                      >
                        <IconButtonIcon icon="betterUndo" size="large" />
                      </TooltipIconButton>
                    )}
                  </>
                )}
              </ComplexMapControl>
            </PermissionGuard>
          )}

          {isRouteTemplate && !isEditSegmentsModeOn && isGeoFenceEnabled && (
            <PermissionGuard permission={GEO_FENCES_DAILY_ROUTE || GEO_FENCES_ROUTE_TEMPLATE}>
              <ComplexMapControl vertical position="top-right">
                <TooltipIconButton
                  disabled={!geoFenceExists}
                  color={showRouteGeoFence && geoFenceExists ? 'primary' : 'secondary'}
                  tooltipAsString
                  tooltip={
                    geoFenceExists
                      ? showRouteGeoFence
                        ? translate('routes.geoFences.hideGeoFence')
                        : translate('routes.geoFences.showGeoFence')
                      : translate('routes.geoFences.noGeoFence')
                  }
                  tooltipPosition="left"
                  margin="small no no"
                  onClick={() => setShowRouteGeoFence(!showRouteGeoFence)}
                >
                  <IconButtonIcon icon="geoFence" size="large" />
                </TooltipIconButton>

                <RouteGeoFenceAlerts geoFenceId={geoFenceId} />
              </ComplexMapControl>
            </PermissionGuard>
          )}

          {isRouteTemplateEditor && isGeoFenceEnabled && !isDrawSelectionModeOn && (
            <PermissionGuard permission={GEO_FENCES_DAILY_ROUTE || GEO_FENCES_ROUTE_TEMPLATE}>
              <ComplexMapControl vertical position="top-right">
                {!isEditRouteGeoFenceModeOn && (
                  <TooltipIconButton
                    color={geoFenceEditing ? 'primary' : 'secondary'}
                    tooltipAsString
                    tooltip={
                      geoFenceExists && !hasDeletedAllPolygons
                        ? translate('routes.geoFences.editGeoFence')
                        : translate('routes.geoFences.doCreateGeoFence')
                    }
                    tooltipPosition="left"
                    margin="small no no"
                    onClick={() => {
                      if (geoFenceExists) editGeoFence();
                      else {
                        handleEnterDrawMode();
                      }
                    }}
                  >
                    <IconButtonIcon icon="geoFence" size="large" />
                  </TooltipIconButton>
                )}
                {isEditRouteGeoFenceModeOn && (
                  <>
                    <TooltipIconButton
                      color="secondary"
                      tooltip="cancel"
                      tooltipPosition="left"
                      margin="small no no"
                      onClick={handleExitDrawMode}
                      type="button"
                    >
                      <IconButtonIcon icon="close" size="large" />
                    </TooltipIconButton>
                    {selectedGeoFenceGeo && (
                      <TooltipIconButton
                        disabled={size(selectedGeoFenceGeo.history) <= 1}
                        color="secondary"
                        tooltip="undo"
                        tooltipPosition="left"
                        margin="small no no"
                        onClick={handleUndoGeoFence}
                        type="button"
                      >
                        <IconButtonIcon icon="betterUndo" size="large" />
                      </TooltipIconButton>
                    )}
                    <TooltipIconButton
                      disabled={isAddingPolygon || !selectedGeoFenceGeo}
                      color="secondary"
                      tooltip="deletePolygon"
                      tooltipPosition="left"
                      margin="small no no"
                      onClick={handleDeletePolygon}
                      type="button"
                    >
                      <IconButtonIcon icon="deleteGeoFence" size="large" />
                    </TooltipIconButton>
                    <TooltipIconButton
                      disabled={isAddingPolygon}
                      color="secondary"
                      tooltip="addPolygon"
                      tooltipPosition="left"
                      margin="small no no"
                      onClick={handleAddPolygon}
                      type="button"
                    >
                      <IconButtonIcon icon="createGeoFence" size="large" />
                    </TooltipIconButton>
                    <TooltipIconButton
                      color="secondary"
                      tooltip="save"
                      tooltipPosition="left"
                      margin="small no no"
                      disabled={!hasChanges}
                      onClick={handleSaveGeoFence}
                      type="button"
                    >
                      <IconButtonIcon icon="check" size="large" />
                    </TooltipIconButton>
                  </>
                )}
              </ComplexMapControl>
            </PermissionGuard>
          )}
        </>
      )}
    </>
  );
};

export default StreetNetworkMapControls;
