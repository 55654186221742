import { useSelector, useDispatch } from 'react-redux';

import { AppState } from 'src/store';
import { currentVendorId } from '../../services/currentVendorSelector';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import {
  ESRI_CREDENTIALS_LAYER,
  ESRI_CUSTOMER_LAYER,
  ESRI_GEO_FENCE_LAYER,
  ESRI_ROUTE_LAYER,
  ESRI_SERVICE_LAYER,
} from '../../constants/esriConfig';
import EsriCredentialsForm from 'src/vendors/components/forms/EsriCredentialsForm';
import EsriCustomerConfigForm from '../forms/EsriCustomerConfigForm';
import EsriGeoFenceConfigForm from '../forms/EsriGeoFenceConfigForm';
import EsriRouteConfigForm from '../forms/EsriRouteConfigForm';
import EsriServiceConfigForm from '../forms/EsriServiceConfigForm';
import {
  saveEsriCredentials,
  saveEsriCustomerConfig,
  saveEsriGeoFenceConfig,
  saveEsriRouteConfig,
  saveEsriServiceConfig,
} from '../../ducks/esriIntegrationConfiguration';
import translate from '../../../core/services/translate';
interface Props {
  configLayer: string;
}

export default function EsriCredentialsSection({ configLayer }: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector((state: AppState) => currentVendorId(state));
  const esriConfig = useSelector((state: AppState) => state.vendors.esriIntegrationConfig);
  const { esriCredentials, esriCustomerConfig, esriGeoFenceConfig, esriServiceConfig, esriRouteConfig } = esriConfig;

  const handleSubmit = (formData: any) => {
    let promise;
    if (configLayer === ESRI_CREDENTIALS_LAYER) {
      promise = saveEsriCredentials(vendorId, formData)(dispatch);
    } else if (configLayer === ESRI_CUSTOMER_LAYER) {
      promise = saveEsriCustomerConfig(vendorId, formData)(dispatch);
    } else if (configLayer === ESRI_ROUTE_LAYER) {
      promise = saveEsriRouteConfig(vendorId, formData)(dispatch);
    } else if (configLayer === ESRI_SERVICE_LAYER) {
      promise = saveEsriServiceConfig(vendorId, formData)(dispatch);
    } else if (configLayer === ESRI_GEO_FENCE_LAYER) {
      promise = saveEsriGeoFenceConfig(vendorId, formData)(dispatch);
    }

    if (!promise) {
      return;
    }

    promise
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.esriSyncSettingSaved'));
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.esriSyncSettingError')));
  };

  if (configLayer === ESRI_CREDENTIALS_LAYER) {
    return <EsriCredentialsForm onSubmit={handleSubmit} initialValues={esriCredentials}></EsriCredentialsForm>;
  } else if (configLayer === ESRI_CUSTOMER_LAYER) {
    return <EsriCustomerConfigForm onSubmit={handleSubmit} initialValues={esriCustomerConfig}></EsriCustomerConfigForm>;
  } else if (configLayer === ESRI_ROUTE_LAYER) {
    return <EsriRouteConfigForm onSubmit={handleSubmit} initialValues={esriRouteConfig}></EsriRouteConfigForm>;
  } else if (configLayer === ESRI_GEO_FENCE_LAYER) {
    return <EsriGeoFenceConfigForm onSubmit={handleSubmit} initialValues={esriGeoFenceConfig}></EsriGeoFenceConfigForm>;
  } else {
    return <EsriServiceConfigForm onSubmit={handleSubmit} initialValues={esriServiceConfig}></EsriServiceConfigForm>;
  }
}
