import { PureComponent, ChangeEvent } from 'react';

import { change } from 'redux-form';
import { cloneDeep, debounce, difference, find, flatten, get, map, reduce, set, size, values } from 'lodash-es';
import { connect } from 'react-redux';
import { GoogleMap, Polyline } from '@react-google-maps/api';

import { AppState } from '../../store';
import { COMPLETED, IN_PROGRESS, initialOpenedInfoWindows } from '../constants';
import { CONTAINER_INSIGHTS, VEHICLE_TRACKINGS } from '../constants/markerOptions';
import { ContainerInsights as Insights, VehiclePosition, VehicleTracking } from './mapWithTimeline/Interfaces';
import { ContainerInsights, VehicleInsights, VehiclePositions, VehicleTrackings } from '../../common/components';
import { currentVendorIdSelector } from '../../vendors/services/currentVendorSelector';
import {
  MAP_BOUNDS,
  MAP_CENTER,
  MAP_DEFAULT_ZOOM,
  MAP_GET_BOUNDS,
  MAP_GET_DIV,
  MAP_GET_ZOOM,
  MAP_MAX_ZOOM,
  MAP_OPTIONS,
  MAP_STYLE,
} from '../../core/constants';
import { getDrawingThrottleValue } from '../../core/constants/lassoTool';
import { GOOGLE as google, WASTE_AUDIT } from '../../common/constants';
import { IconButton, IconButtonIcon, Popover, Text } from '../../core/components/styled';
import { isOnDemandVideoDownloadFeatureEnabled } from 'src/vendors/ducks/features';
import { loadClosestStrobeImage } from '../ducks';
import { MapControl } from './styled';
import { PopoverWrapper } from '../../core/components';
import { vehicleStrobeImagesStatusSelector } from '../../vendors/ducks';
import googleMap from '../../core/services/googleMap';
import RouteLocations from './RouteLocations';
import translate from '../../core/services/translate';
import Triggers from './Triggers';

const currentVendorId = currentVendorIdSelector as any;

export interface SelectedLocation {
  customerName: string;
  id: number;
  latitude: number;
  locationName: string;
  longitude: number;
  orderNumber: number;
}

export interface SelectedTemplateLocation {
  latitude: number;
  longitude: number;
  serviceContractRouteTemplateId: number;
}

interface ComponentProps {
  formKey?: string;
  routeIsNotScheduled?: boolean;
}

export interface RouteMapProps extends ComponentProps {
  alertsAreReadOnly?: boolean;
  center?: any;
  change: any;
  containerInsights: Insights[];
  draggablePins?: boolean;
  editButtonId?: string;
  hideTimeline?: boolean;
  isInteractive?: boolean;
  isMapLoading?: boolean;
  isOnDemandVideoDownloadEnabled: boolean;
  isRouteEntityFormChanged?: boolean;
  isRoutePlannerPage?: boolean;
  isRouteTemplate: boolean;
  isYRoute?: boolean;
  loadClosestStrobeImage: Function;
  mapShouldFitBounds?: boolean;
  onAddressChange?: (index?: number, serviceContractId?: number, updatedAddress?: google.maps.MapMouseEvent) => void;
  onMapLoadComplete?: () => void;
  onSelectLocations: (locations: any[]) => void;
  optimizedRouteLocationsLength?: number;
  polygonPathShouldReset?: boolean;
  routeId: number;
  routeLocations: any[];
  routeName: string;
  routeStatusTypeId: number;
  selectedLocation?: SelectedLocation;
  selectedTemplateLocation?: SelectedTemplateLocation;
  showTimeline?: boolean;
  triggers: any[];
  unitOfMeasure: string;
  vehicleInsights?: any[];
  vehiclePositions: VehiclePosition[];
  vehicleStrobeImagesEnabled?: boolean;
  vehicleTrackings: VehicleTracking[];
  vehicleType?: string;
  vendorId?: number;
}

class RouteMap extends PureComponent<RouteMapProps, any> {
  static defaultProps = {
    routeLocations: [],
    vehiclePositions: [],
    vehicleTrackings: [],
    containerInsights: [],
    triggers: [],
  };

  constructor(props: RouteMapProps) {
    super(props);
    const { routeLocations } = props;
    this.initialized = false;

    this.state = {
      center: MAP_CENTER,
      drawingEnabled: false,
      isDrawing: false,
      isLegendCollapsed: false,
      mapBounds: MAP_BOUNDS,
      mapOptions: {
        ...MAP_OPTIONS,
        draggable: true,
        zoomControl: true,
        scrollwheel: true,
      },
      mapZoom: MAP_DEFAULT_ZOOM,
      openedInfoWindows: initialOpenedInfoWindows,
      polygonPath: [],
      strobeImageUrl: undefined,
    };

    this.drawDelay = getDrawingThrottleValue(routeLocations.length);
  }

  private initialized: boolean;
  private drawDelay: number = 0;
  private map?: google.maps.Map;

  componentDidUpdate(prevProps: RouteMapProps) {
    const {
      isMapLoading,
      mapShouldFitBounds,
      onMapLoadComplete,
      polygonPathShouldReset,
      routeLocations = [],
      selectedLocation,
      selectedTemplateLocation,
    } = this.props;

    if (size(difference(prevProps.routeLocations, routeLocations)) > 0) {
      if (mapShouldFitBounds) this.fitBounds(this.props);
      if (polygonPathShouldReset) this.setState({ polygonPath: [] });
    }

    if (prevProps.selectedLocation !== selectedLocation && selectedLocation && mapShouldFitBounds) {
      this.setState({
        openedInfoWindows: this.getInitialOpenedInfoWindows(selectedLocation),
        center: { lat: selectedLocation.latitude, lng: selectedLocation.longitude },
      });
    }

    if (
      prevProps.selectedTemplateLocation !== selectedTemplateLocation &&
      selectedTemplateLocation &&
      mapShouldFitBounds
    ) {
      this.setState({
        openedInfoWindows: this.getInitialOpenedInfoWindows(selectedTemplateLocation),
        center: { lat: selectedTemplateLocation.latitude, lng: selectedTemplateLocation.longitude },
      });
    }
    if (prevProps.isMapLoading !== isMapLoading && !isMapLoading) this.fitBounds(this.props);
    if (onMapLoadComplete) onMapLoadComplete();
  }

  onIdle = () => {
    if (!this.initialized) {
      this.fitBounds(this.props);
    }

    this.initialized = true;
  };

  onZoomChange = debounce(() => {
    if (!this.map) return;

    if (MAP_GET_ZOOM(this.map) && MAP_GET_BOUNDS(this.map)) {
      this.setState({
        mapZoom: MAP_GET_ZOOM(this.map),
        mapBounds: MAP_GET_BOUNDS(this.map),
      });
    }
  }, 500);

  onMouseMove = debounce((e: google.maps.MapMouseEvent) => {
    this.setState((prevState: any) => ({ polygonPath: prevState.polygonPath.concat([e.latLng]) }));
  }, this.drawDelay);

  getInitialOpenedInfoWindows = (selectedLocation: any) => {
    const { containerInsights, isYRoute } = this.props;

    const selectedContainerInsight = selectedLocation.id
      ? find(
          containerInsights,
          ({ routeLocationId, insightId }) => (isYRoute ? insightId : routeLocationId) === selectedLocation.id,
        )
      : undefined;

    return {
      ...initialOpenedInfoWindows,
      routeLocations: selectedLocation ? { [selectedLocation.orderNumber - 1]: true } : {},
      containerInsights: selectedContainerInsight ? { [selectedContainerInsight.insightId]: true } : {},
    };
  };

  setMapRef = (map: google.maps.Map) => {
    this.map = map;
  };

  getClosestStrobeImage = async (path: string) => {
    const { vendorId, vehicleType, vehicleTrackings, containerInsights, loadClosestStrobeImage } = this.props;
    const [key, index] = path.split('.');
    let vehicleId = null;
    let timeStamp = null;

    if (key === CONTAINER_INSIGHTS) {
      const containerInsight = find(containerInsights, { insightId: parseInt(index, 10) }) as Insights;
      vehicleId = containerInsight.vehicle.id;
      timeStamp = containerInsight.reportDateTime;
    } else if (key === VEHICLE_TRACKINGS) {
      const { coordinateGroups, vehicle } = vehicleTrackings[0];
      const vehicleIndex = parseInt(index.toString().split('_')[1], 10);
      const coordinateGroup = coordinateGroups[vehicleIndex - 1];
      vehicleId = vehicle.id;
      timeStamp = coordinateGroup.endTimestamp;
    }

    if (vehicleType !== WASTE_AUDIT && !!timeStamp) {
      await loadClosestStrobeImage(vendorId, vehicleId, timeStamp).then((response: any) => {
        const strobeImageUrl = !!response && response.thumbnailImageUrl ? response.thumbnailImageUrl : undefined;
        this.setState((prevState: any) => ({
          openedInfoWindows: {
            ...prevState.openedInfoWindows,
            imageUrl: strobeImageUrl,
          },
        }));
      });
    }
  };

  fitBounds = ({ routeLocations, vehiclePositions, vehicleTrackings, containerInsights, triggers }: RouteMapProps) => {
    if (!this.map) return;

    const bounds = new google.maps.LatLngBounds();

    const vehicleTrackingCoordinates = reduce<any, any[]>(
      map(vehicleTrackings, ({ coordinateGroups }) => coordinateGroups),
      (vehicleTrackingsCoordinates, coordinateGroup) => [
        ...vehicleTrackingsCoordinates,
        ...reduce<any, any[]>(
          coordinateGroup,
          (groupCoordinates, { coordinates }) => [...groupCoordinates, ...coordinates],
          [],
        ),
      ],
      [],
    );

    const markerPositionsRouteLocations = [
      ...flatten(
        map(
          routeLocations,
          ({
            service,
            location: {
              address: { latitude, longitude },
            },
          }) => {
            const serviceContractBinDetails = get(service, 'serviceContractBinDetails');

            return serviceContractBinDetails
              ? map(serviceContractBinDetails, ({ displayLatitude, displayLongitude }) => ({
                  latitude: displayLatitude || latitude,
                  longitude: displayLongitude || longitude,
                }))
              : {
                  latitude,
                  longitude,
                };
          },
        ),
      ),
    ];

    const markerPositionsExtended = [
      ...vehiclePositions.map(({ coordinates: { latitude, longitude } }) => ({
        latitude,
        longitude,
      })),
      ...vehicleTrackingCoordinates.map(({ latitude, longitude }) => ({
        latitude,
        longitude,
      })),
      ...containerInsights.map(({ latitude, longitude }) => ({
        latitude,
        longitude,
      })),
      ...triggers.map(({ latitude, longitude }) => ({
        latitude,
        longitude,
      })),
    ];

    const markerPositions = [...markerPositionsRouteLocations, ...markerPositionsExtended];

    if (!markerPositions.length) {
      return;
    }

    markerPositions.forEach(markerPosition => {
      bounds.extend(new google.maps.LatLng(markerPosition.latitude, markerPosition.longitude));
    });

    this.map.fitBounds(bounds);

    if (MAP_GET_ZOOM(this.map) > MAP_MAX_ZOOM) {
      this.setState({ mapZoom: MAP_MAX_ZOOM });
    }
  };

  toggleInfoWindow = (path?: string, currentId?: number) => {
    const { vehicleStrobeImagesEnabled } = this.props;

    this.setState((prevState: any) => {
      if (path) {
        const currentValue = get(prevState.openedInfoWindows, path);
        const openedInfoWindows = set(cloneDeep(initialOpenedInfoWindows), path, !currentValue);
        const isInfoWindowOpen =
          values(openedInfoWindows.containerInsights)[0] || values(openedInfoWindows.vehicleTrackings)[0];

        if (vehicleStrobeImagesEnabled && isInfoWindowOpen) {
          this.getClosestStrobeImage(path);
        }

        return {
          ...prevState,
          openedInfoWindows,
        };
      } else {
        return {
          ...prevState,
          openedInfoWindows: initialOpenedInfoWindows,
        };
      }
    });
  };

  disableMapControl = () => {
    const { onSelectLocations } = this.props;
    this.setState((prevState: any) => ({
      mapOptions: {
        ...prevState.mapOptions,
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
      },
      polygonPath: [],
    }));
    onSelectLocations([]);
  };

  enableMapControl = () => {
    this.setState((prevState: any) => ({
      mapOptions: {
        ...prevState.mapOptions,
        draggable: true,
        zoomControl: true,
        scrollwheel: true,
      },
      drawingEnabled: false,
    }));
  };

  enableDrawing = () => {
    const { drawingEnabled } = this.state;
    if (drawingEnabled) {
      this.enableMapControl();
    } else {
      this.disableMapControl();
      this.drawFreeHand();
    }
    this.setState({ drawingEnabled: !drawingEnabled });
  };

  startDrawing = () => {
    const { isRouteEntityFormChanged } = this.props;
    if (!isRouteEntityFormChanged) this.setState({ isDrawing: true });
  };

  endDrawing = () => {
    const { routeLocations, onSelectLocations } = this.props;
    if (this.map) {
      const mapDiv = MAP_GET_DIV(this.map);
      mapDiv.removeEventListener('mousedown', this.startDrawing);
      mapDiv.removeEventListener('mouseup', this.endDrawing);
    }

    this.setState((prevState: any) => {
      const polygonPath =
        size(prevState.polygonPath) > 0 ? prevState.polygonPath.concat([prevState.polygonPath[0]]) : [];

      return {
        isDrawing: false,
        polygonPath,
      };
    });

    const selectedLocations: any[] = [];
    if (size(this.state.polygonPath) > 0) {
      const poly = new google.maps.Polygon({ paths: this.state.polygonPath });

      map(routeLocations, routeLocation => {
        const serviceContractBinDetails = get(routeLocation.service, 'serviceContractBinDetails');
        const serviceLatitude = serviceContractBinDetails
          ? serviceContractBinDetails[0].displayLatitude
          : routeLocation.location.address.latitude;
        const serviceLongitude = serviceContractBinDetails
          ? serviceContractBinDetails[0].displayLongitude
          : routeLocation.location.address.longitude;

        return (
          google.maps.geometry.poly.containsLocation(new google.maps.LatLng(serviceLatitude, serviceLongitude), poly) &&
          selectedLocations.push(routeLocation)
        );
      });

      onSelectLocations(selectedLocations);
    }
    this.enableMapControl();
  };

  drawFreeHand = () => {
    if (this.map) {
      const mapDiv = MAP_GET_DIV(this.map);
      mapDiv.addEventListener('mousedown', this.startDrawing);
      mapDiv.addEventListener('mouseup', this.endDrawing);
    }
  };
  setLegendCollapsed = (isLegendCollapsed: boolean) => {
    this.setState({ isLegendCollapsed });
  };

  onSwitchChange = (event: ChangeEvent) => {
    this.setState({ vehicleTrackingsForSnowPlowIsVisible: event });
  };

  render() {
    const {
      containerInsights,
      draggablePins,
      editButtonId,
      hideTimeline,
      isInteractive,
      isMapLoading,
      isOnDemandVideoDownloadEnabled,
      isRouteEntityFormChanged,
      isRoutePlannerPage,
      onAddressChange,
      optimizedRouteLocationsLength = 0,
      routeLocations,
      routeStatusTypeId,
      triggers,
      unitOfMeasure,
      vehicleInsights,
      vehiclePositions,
      vehicleTrackings,
    } = this.props;

    const { drawingEnabled, isDrawing, mapBounds, mapOptions, mapZoom, openedInfoWindows, polygonPath } = this.state;

    const center = this.props.center || this.state.center;

    const isMapWithTimelineVisible =
      !!vehiclePositions.length &&
      !!vehicleTrackings.length &&
      !hideTimeline &&
      !isInteractive &&
      (routeStatusTypeId === COMPLETED || routeStatusTypeId === IN_PROGRESS);

    return (
      <>
        <GoogleMap
          center={center}
          mapContainerStyle={MAP_STYLE}
          onIdle={this.onIdle}
          onLoad={this.setMapRef}
          onMouseMove={isDrawing ? this.onMouseMove : undefined}
          onZoomChanged={this.onZoomChange}
          onBoundsChanged={this.onZoomChange}
          options={mapOptions}
          zoom={mapZoom}
        >
          {isInteractive && (
            <>
              <Polyline
                path={polygonPath}
                options={{
                  strokeColor: '#009d90',
                }}
              />

              <MapControl left="10px" top={isMapWithTimelineVisible ? '130px' : '80px'}>
                <PopoverWrapper
                  triggerButton={
                    <IconButton
                      onClick={this.enableDrawing}
                      color={drawingEnabled ? 'primary' : 'secondary'}
                      id={editButtonId}
                      disabled={isRouteEntityFormChanged || optimizedRouteLocationsLength > 0}
                    >
                      <IconButtonIcon icon="edit" margin="no" />
                    </IconButton>
                  }
                  popoverContent={
                    (isRouteEntityFormChanged || optimizedRouteLocationsLength > 0) && (
                      <Popover>
                        <Text block weight="medium" margin="xxSmall no xxSmall">
                          {translate(
                            isRouteEntityFormChanged
                              ? 'routes.alertMessages.routeLocationsDirtyForm'
                              : 'routes.alertMessages.drawingDisabled',
                          )}
                        </Text>
                      </Popover>
                    )
                  }
                  size="large"
                />
              </MapControl>
            </>
          )}

          {!!routeLocations && !isMapLoading && (
            <RouteLocations
              isRoutePlannerPage={isRoutePlannerPage}
              mapBounds={mapBounds}
              currentMapZoom={mapZoom}
              draggablePins={draggablePins}
              onAddressChange={onAddressChange}
              openedInfoWindows={openedInfoWindows}
              routeLocations={routeLocations}
              toggleInfoWindow={this.toggleInfoWindow}
            />
          )}

          {!!vehiclePositions && !isMapLoading && (
            <VehiclePositions
              vehiclePositions={vehiclePositions}
              openedInfoWindows={openedInfoWindows}
              toggleInfoWindow={this.toggleInfoWindow}
            />
          )}

          {vehicleTrackings.length > 0 && !isMapLoading && (
            <VehicleTrackings
              isOnDemandVideoDownloadEnabled={isOnDemandVideoDownloadEnabled}
              mapBounds={mapBounds}
              mapZoom={mapZoom}
              openedInfoWindows={openedInfoWindows}
              showAppStatus={true}
              showVehicleTracking={true}
              toggleInfoWindow={this.toggleInfoWindow}
              unitOfMeasure={unitOfMeasure}
              vehicleTrackings={vehicleTrackings}
            />
          )}

          {!!containerInsights && !isMapLoading && (
            <ContainerInsights
              containerInsights={containerInsights}
              openedInfoWindows={openedInfoWindows}
              toggleInfoWindow={this.toggleInfoWindow}
              hideInsightsPopup
            />
          )}

          {!!vehicleInsights && !isMapLoading && (
            <VehicleInsights
              vehicleInsights={vehicleInsights}
              openedInfoWindows={openedInfoWindows}
              toggleInfoWindow={this.toggleInfoWindow}
            />
          )}

          {!!triggers && !isMapLoading && (
            <Triggers
              triggers={triggers}
              openedInfoWindows={openedInfoWindows}
              toggleInfoWindow={this.toggleInfoWindow}
            />
          )}
        </GoogleMap>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const routeTemplate = state.routes.routeTemplate.routeTemplate as any;
  const isRouteTemplate = routeTemplate != null;

  return {
    isOnDemandVideoDownloadEnabled: isOnDemandVideoDownloadFeatureEnabled(state),
    isRouteTemplate,
    vehicleStrobeImagesEnabled: vehicleStrobeImagesStatusSelector(state.vendors.features.features),
    vendorId: currentVendorId(state.account.login, state.vendors.defaultVendor),
  };
};

const mapDispatchToProps = { loadClosestStrobeImage, change };

export default googleMap(connect(mapStateToProps, mapDispatchToProps)(RouteMap));
