import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { YData } from '../interfaces/YData';
import { loadYData as doLoadYData, saveYData as doSaveYData } from '../services/yData';

// Actions
const START_LOAD = 'vendors/yData/START_LOAD';
const COMPLETE_LOAD = 'vendors/yData/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/yData/FAIL_LOAD';
const START_SAVE = 'vendors/yData/START_SAVE';
const COMPLETE_SAVE = 'vendors/yData/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/yData/FAIL_SAVE';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  yData: [] as YData[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          yData: action.yData,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false },
      });

    case START_SAVE:
      return update(state, {
        $merge: { isSaving: true },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (yData: YData[]) => ({
  type: COMPLETE_LOAD,
  yData,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadYData = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadYDataPromise = doLoadYData();
  loadYDataPromise
    .then((yData: YData[]) => {
      dispatch(completeLoad(yData));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadYDataPromise;
};

export const saveYData = (yData: YData[]) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveYDataPromise = doSaveYData(yData);
  saveYDataPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveYDataPromise;
};
