import update from 'immutability-helper';
import { findIndex } from 'lodash-es';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { DISPOSAL_FACILITY, OPERATIONAL_FACILITY } from '../../common/constants';
import { ContainerForReassignment, ContainerForReassignmentPayload } from '../interfaces/containers';
import {
  deleteFacility as doDeleteFacility,
  loadFacilities as doLoadFacilities,
  loadFacilitiesByType as doLoadFacilitiesByType,
  getAssignedContainers as doGetAssignedContainers,
} from '../services/facilities';

// Actions
const START_LOAD = 'fleet/facilities/START_LOAD';
const COMPLETE_LOAD_FACILITIES = 'fleet/facilities/COMPLETE_LOAD_FACILITIES';
const COMPLETE_LOAD_OPERATIONAL_FACILITIES = 'fleet/facilities/COMPLETE_LOAD_OPERATIONAL_FACILITIES';
const FAIL_LOAD = 'fleet/facilities/FAIL_LOAD';
const START_DELETE = 'fleet/facilities/START_DELETE';
const COMPLETE_DELETE = 'fleet/facilities/COMPLETE_DELETE';
const START_LOAD_DISPOSAL_FACILITIES = 'fleet/facilities/START_LOAD_DISPOSAL_FACILITIES';
const COMPLETE_LOAD_DISPOSAL_FACILITIES = 'fleet/facilities/COMPLETE_LOAD_DISPOSAL_FACILITIES';
const FAIL_LOAD_DISPOSAL_FACILITIES = 'fleet/facilities/FAIL_LOAD_DISPOSAL_FACILITIES';
const START_LOAD_CONTAINERS_FOR_REASSIGNMENT = 'fleet/facilities/START_LOAD_CONTAINERS_FOR_REASSIGNMENT';
const COMPLETE_LOAD_CONTAINERS_FOR_REASSIGNMENT = 'fleet/facilities/COMPLETE_LOAD_CONTAINERS_FOR_REASSIGNMENT';
const FAIL_LOAD_CONTAINERS_FOR_REASSIGNMENT = 'fleet/facilities/FAIL_LOAD_CONTAINERS_FOR_REASSIGNMENT';
const RESET_CONTAINERS_FOR_REASSIGNMENT = 'fleet/facilities/RESET_CONTAINERS_FOR_REASSIGNMENT';
interface InitialState {
  isLoading: boolean;
  isDeleting: boolean;
  isSaving: boolean;
  isLoadingContainersForReassignment: boolean;
  facilities?: any;
  containersForReassignment: ContainerForReassignment[];
  operationalFacilities: any;
  disposalFacilities: any[];
  disposalFacilitiesLoading: boolean;
}

type Dispatch = ThunkDispatch<InitialState, any, AnyAction>;

// Initial state
const initialState: InitialState = {
  isLoading: false,
  isDeleting: false,
  isSaving: false,
  isLoadingContainersForReassignment: false,
  facilities: undefined,
  containersForReassignment: [],
  operationalFacilities: undefined,
  disposalFacilities: [],
  disposalFacilitiesLoading: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_FACILITIES:
      return update(state, {
        $merge: {
          isLoading: false,
          facilities: action.facilities,
          operationalFacilities: undefined,
        },
      });

    case COMPLETE_LOAD_OPERATIONAL_FACILITIES:
      return update(state, {
        $merge: {
          isLoading: false,
          facilities: undefined,
          operationalFacilities: action.operationalFacilities,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          facilities: undefined,
          operationalFacilities: undefined,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const facilityIndex = findIndex(state.facilities, { id: action.facilityId });
      return update(state, {
        facilities: { $splice: [[facilityIndex, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case START_LOAD_DISPOSAL_FACILITIES:
      return update(state, {
        $merge: {
          disposalFacilitiesLoading: true,
        },
      });

    case COMPLETE_LOAD_DISPOSAL_FACILITIES:
      return update(state, {
        $merge: {
          disposalFacilities: action.disposalFacilities,
          disposalFacilitiesLoading: false,
        },
      });

    case FAIL_LOAD_DISPOSAL_FACILITIES:
      return update(state, {
        $merge: {
          disposalFacilities: [],
          disposalFacilitiesLoading: false,
        },
      });

    case START_LOAD_CONTAINERS_FOR_REASSIGNMENT:
      return update(state, {
        $merge: {
          isLoadingContainersForReassignment: true,
        },
      });

    case COMPLETE_LOAD_CONTAINERS_FOR_REASSIGNMENT:
      return update(state, {
        $merge: {
          containersForReassignment: action.containersForReassignment,
          isLoadingContainersForReassignment: false,
        },
      });

    case FAIL_LOAD_CONTAINERS_FOR_REASSIGNMENT:
      return update(state, {
        $merge: {
          containersForReassignment: [],
          isLoadingContainersForReassignment: false,
        },
      });

    case RESET_CONTAINERS_FOR_REASSIGNMENT:
      return update(state, {
        $merge: {
          containersForReassignment: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoadFacilities = (facilities: any) => ({
  type: COMPLETE_LOAD_FACILITIES,
  facilities,
});

const completeLoadOperationalFacilities = (operationalFacilities: any) => ({
  type: COMPLETE_LOAD_OPERATIONAL_FACILITIES,
  operationalFacilities,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startDeleteFacility = () => ({
  type: START_DELETE,
});

const completeDeleteFacility = (facilityId: number) => ({
  type: COMPLETE_DELETE,
  facilityId,
});

const startLoadDisposalFacilities = () => ({
  type: START_LOAD_DISPOSAL_FACILITIES,
});

const completeLoadDisposalFacilities = (disposalFacilities: any) => ({
  type: COMPLETE_LOAD_DISPOSAL_FACILITIES,
  disposalFacilities,
});

const failLoadDisposalFacilities = () => ({
  type: FAIL_LOAD_DISPOSAL_FACILITIES,
});

const startLoadContainersForReassignment = () => ({
  type: START_LOAD_CONTAINERS_FOR_REASSIGNMENT,
});

const completeLoadContainersForReassignment = (containersForReassignment: ContainerForReassignment[]) => ({
  type: COMPLETE_LOAD_CONTAINERS_FOR_REASSIGNMENT,
  containersForReassignment,
});

const failLoadContainersForReassignment = () => ({
  type: FAIL_LOAD_CONTAINERS_FOR_REASSIGNMENT,
});

export const resetContainersForReassignment = () => ({
  type: RESET_CONTAINERS_FOR_REASSIGNMENT,
});

export const loadFacilities = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  return doLoadFacilities(vendorId)
    .then(facilities => dispatch(completeLoadFacilities(facilities)))
    .catch(() => dispatch(failLoad()));
};

export const loadOperationalFacilities =
  (vendorId: number, isActive = true, includeInactive?: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const promise = doLoadFacilitiesByType(vendorId, OPERATIONAL_FACILITY, !includeInactive ? isActive : undefined)
      .then((operationalFacilities: any) => dispatch(completeLoadOperationalFacilities(operationalFacilities)))
      .catch(() => dispatch(failLoad()));
    return promise;
  };

export const loadDisposalFacilities =
  (vendorId: number, isActive = true, includeInactive?: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(startLoadDisposalFacilities());
    const promise = doLoadFacilitiesByType(vendorId, DISPOSAL_FACILITY, !includeInactive ? isActive : undefined)
      .then((facilities: any) => dispatch(completeLoadDisposalFacilities(facilities)))
      .catch(() => dispatch(failLoadDisposalFacilities()));
    return promise;
  };

export const deleteFacility =
  (facilityId: number, relocateContainers?: ContainerForReassignmentPayload[]) => (dispatch: Dispatch) => {
    dispatch(startDeleteFacility());
    return doDeleteFacility(facilityId, relocateContainers || []).then(() =>
      dispatch(completeDeleteFacility(facilityId)),
    );
  };

export const loadContainersForReassignment = (facilityId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadContainersForReassignment());

  const loadContainersForReassignmentPromise = doGetAssignedContainers(facilityId);

  loadContainersForReassignmentPromise
    .then((containersForReassignment: ContainerForReassignment[]) => {
      dispatch(completeLoadContainersForReassignment(containersForReassignment));
    })
    .catch(() => {
      dispatch(failLoadContainersForReassignment());
    });

  return loadContainersForReassignmentPromise;
};

// Selectors
