import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FormGroup, FormError, SimpleInput as SimpleFormInput } from './styled';
import { SimpleInputProps } from './styled/SimpleInput';

interface Props extends WrappedFieldProps, SimpleInputProps {
  placeholder?: string;
  isLoading?: boolean;
  disabled?: boolean;
  size?: string;
  margin?: string;
}

const SimpleInput: React.FC<Props> = ({
  input,
  meta: { asyncValidating, submitFailed, error },
  disabled,
  isLoading,
  size,
  margin,
  ...props
}) => (
  <FormGroup isLoading={isLoading || asyncValidating} margin={margin}>
    <SimpleFormInput {...input} {...props} disabled={disabled} />

    {submitFailed && error && <FormError>{error}</FormError>}
  </FormGroup>
);

export default SimpleInput;
