import { PureComponent } from 'react';

import { flatten, get, includes, isEmpty, map, size } from 'lodash-es';
import moment from 'moment';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormAction, getFormValues, submit } from 'redux-form';

import { clearPersistentSOSAlertVehicleId } from 'src/common/utils/sosAlerts';
import { DesktopWidthView } from 'src/core/components/mediaQueries/DesktopWidthView';
import { SNOW_ROAD_CONDITIONS, STREET_SWEEPER_CONDITIONS } from 'src/dashboard/constants/cityInsightTypeConditions';
import { DASHBOARD_FILTER_FORM_NAME } from 'src/dashboard/constants/dashboardFilter';
import { loadRoadConditions, resetRoadConditions } from 'src/dashboard/ducks/roadConditions';
import {
  loadSnowRoadConditionUpdates,
  loadSnowRoadConditions,
  resetSnowRoadConditions,
} from 'src/dashboard/ducks/snowRoadConditions';
import {
  loadStreetSweeperConditionUpdates,
  loadStreetSweeperConditions,
  resetStreetSweeperConditions,
} from 'src/dashboard/ducks/streetSweeperConditions';
import { VEHICLE_TYPES } from 'src/fleet/constants';
import { RUBICON_Z } from 'src/vendors/constants';
import {
  checkIfSnowPlowConditionsAreVisible,
  checkIfSnowPlowIsEnabled,
  checkIfStreetSweepingConditionsAreVisible,
  checkIfStreetSweepingIsEnabled,
} from 'src/vendors/ducks/features';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { Vendor } from 'src/vendors/interfaces/Vendors';
import { breakDownDeviceRoleTypeId } from 'src/vendors/services/vendorEditorFormInitialValuesSelector';
import { PermissionGuard } from '../../../account/components';
import {
  DASHBOARD_FILTERS,
  DASHBOARD_REAL_TIME_INSIGHTS_FEED,
  DASHBOARD_SERVICE_STATS_BOX,
} from '../../../account/constants';
import { isRubiconNonTechHauler, isSmartCitySelector, vendorGusIdSelector } from '../../../account/ducks';
import { Banner } from '../../../common/components';
import { PageContent, PageLoadingOverlay } from '../../../common/components/styled';
import { STREET_SWEEPING } from '../../../common/constants';
import { SOS_ALERT_VEHICLE_ID_KEY } from '../../../common/constants/sosAlert';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { createErrorNotification } from '../../../core/services/createNotification';
import { getItem } from '../../../core/services/persistentStorage';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { loadFeatures, loadVendor } from '../../../vendors/ducks';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { CONTAINER_SERVICE_INSIGHT_TYPES, VEHICLE_INSIGHT_TYPES } from '../../constants';
import {
  loadFleetInsights,
  loadMapInsights,
  loadStreetSweeper,
  loadVehiclePositions,
  loadVehicleTrackings,
  loadVendorLocations,
  resetFilters,
  resetFleetInsights,
  resetInitialFilterFormValues,
  resetMapInsights,
  resetStreetSweeper,
  resetVehiclePositions,
  resetVehicleTrackings,
  resetVendorLocations,
  updateInitialFilterFormValues,
} from '../../ducks';
import {
  getSelectedCityInsightTypes,
  getSelectedContainerIssueInsightTypes,
  getSelectedContainerServiceInsightTypes,
  getSelectedRoadConditionsOptions,
  getSelectedSnowRoadConditionsOptions,
  getSelectedStreetSweeperConditionsOptions,
  getSelectedStreetSweeperVehicle,
  getSelectedVehicleForVehicleTrackings,
  getSelectedVehicleIdsByType,
  getSelectedVehicleInsightTypes,
  getSelectedVehicleTrackingOptions,
  getSelectedVehicleTypes,
  getSelectedVendorLocationTypes,
} from '../../services/getDashboardFilterFormData';
import { getPersistentFilters, setPersistentFilters } from '../../services/persistentFilterStorage';
import { DashboardFilterForm } from '../forms';
import { DashboardMapWrapper } from '../styled';
import { DashboardFleetInsights, DashboardMap, DashboardMapInsights } from './dashboardPageSections';

interface Props extends RouteComponentProps {
  filtersPreferences: FilterSetting[];
  formValues?: object;
  isLoadingFleetInsights: boolean;
  isLoadingMapInsights: boolean;
  isLoadingRoadConditions: boolean;
  isLoadingSnowRoadConditions: boolean;
  isLoadingStreetSweeper: boolean;
  isLoadingStreetSweeperConditions: boolean;
  isLoadingVehiclePositions: boolean;
  isLoadingVehicleTrackings: boolean;
  isLoadingVendor: boolean;
  isLoadingVendorLocations: boolean;
  isNonTechHauler: boolean;
  isSmartCity?: boolean;
  isVendorWithGusId?: boolean;
  loadFeatures: DuckFunction<typeof loadFeatures>;
  loadFleetInsights: DuckFunction<typeof loadFleetInsights>;
  loadMapInsights: DuckFunction<typeof loadMapInsights>;
  loadRoadConditions: DuckFunction<typeof loadRoadConditions>;
  loadSnowRoadConditions: DuckFunction<typeof loadSnowRoadConditions>;
  loadSnowRoadConditionUpdates: DuckFunction<typeof loadSnowRoadConditionUpdates>;
  loadStreetSweeper: DuckFunction<typeof loadStreetSweeper>;
  loadStreetSweeperConditions: DuckFunction<typeof loadStreetSweeperConditions>;
  loadStreetSweeperConditionUpdates: DuckFunction<typeof loadStreetSweeperConditionUpdates>;
  loadVehiclePositions: DuckFunction<typeof loadVehiclePositions>;
  loadVehicleTrackings: DuckFunction<typeof loadVehicleTrackings>;
  loadVendor: DuckFunction<typeof loadVendor>;
  loadVendorLocations: DuckFunction<typeof loadVendorLocations>;
  resetFilters: DuckAction<typeof resetFilters>;
  resetFleetInsights: DuckAction<typeof resetFleetInsights>;
  resetInitialFilterFormValues: DuckAction<typeof resetInitialFilterFormValues>;
  resetMapInsights: DuckAction<typeof resetMapInsights>;
  resetRoadConditions: DuckAction<typeof resetRoadConditions>;
  resetSnowRoadConditions: DuckAction<typeof resetSnowRoadConditions>;
  resetStreetSweeper: DuckAction<typeof resetStreetSweeper>;
  resetStreetSweeperConditions: DuckAction<typeof resetStreetSweeperConditions>;
  resetVehiclePositions: DuckAction<typeof resetVehiclePositions>;
  resetVehicleTrackings: DuckAction<typeof resetVehicleTrackings>;
  resetVendorLocations: DuckAction<typeof resetVendorLocations>;
  snowRoadConditionsEnabled: boolean;
  streetSweeperConditionsEnabled: boolean;
  submit: (form: string) => FormAction;
  updateInitialFilterFormValues: DuckFunction<typeof updateInitialFilterFormValues>;
  vendor?: Vendor;
  vendorId: number;
}

interface State {
  areRestrictionMessagesEnabled: boolean;
  autoRefreshesWithoutSnowRoadConditions: number;
  autoRefreshesWithoutStreetSweeperConditions: number;
  cityInsightsConditionType: string;
  isAutoRefresh: boolean;
  isFilterFormOpen: boolean;
  isFiltersApplied: boolean;
  isFirstLoad: boolean;
  isLastSettingsApplied: boolean;
  isResetApplied: boolean;
  isRubiconZ: boolean;
  shouldMapFitBounds: boolean;
}

class DashboardPage extends PureComponent<Props, State> {
  readonly state: State = {
    areRestrictionMessagesEnabled: true,
    autoRefreshesWithoutSnowRoadConditions: 0,
    autoRefreshesWithoutStreetSweeperConditions: 0,
    cityInsightsConditionType: this.props.streetSweeperConditionsEnabled
      ? STREET_SWEEPER_CONDITIONS
      : SNOW_ROAD_CONDITIONS,
    isAutoRefresh: false,
    isFilterFormOpen: false,
    isFiltersApplied: false,
    isFirstLoad: true,
    isLastSettingsApplied: false,
    isResetApplied: false,
    isRubiconZ: includes(breakDownDeviceRoleTypeId(this.props.vendor?.deviceRoleTypeId), RUBICON_Z),
    shouldMapFitBounds: false,
  };

  componentWillUnmount() {
    this.resetState();
    clearPersistentSOSAlertVehicleId();
  }

  componentDidUpdate(prevProps: Props) {
    const { loadVehiclePositions, vendorId, location } = this.props;

    if (prevProps.location.key !== location.key) {
      const storageKey = getItem(SOS_ALERT_VEHICLE_ID_KEY);
      const sosAlertVehicleId = storageKey && storageKey !== 'undefined' ? JSON.parse(storageKey) : undefined;
      const hasSosAlertVehicleId = sosAlertVehicleId !== undefined;

      if (hasSosAlertVehicleId) {
        this.resetState({ filters: true });

        const currentDate = moment().format('MM/DD/YYYY');
        if (vendorId) loadVehiclePositions(vendorId, currentDate.toString(), [sosAlertVehicleId]);
      }
    }
  }

  componentDidMount() {}

  onSubmit = (formData: any) => {
    const { vendorId } = this.props;
    const {
      areRestrictionMessagesEnabled,
      autoRefreshesWithoutSnowRoadConditions,
      autoRefreshesWithoutStreetSweeperConditions,
      isAutoRefresh,
      isFiltersApplied,
      isFirstLoad,
      isLastSettingsApplied,
      isResetApplied,
      isRubiconZ,
    } = this.state;

    const persistentFilters = getPersistentFilters(vendorId);

    const date = isLastSettingsApplied ? persistentFilters.date : formData.date;
    const searchedVehicleId = isFirstLoad ? undefined : formData.searchedVehicleId;

    const vehicleId = get(searchedVehicleId, 'vehicleId');
    const vehicleType = get(searchedVehicleId, 'vehicleType');
    const { showVehiclePositions, showVehicleTracking, showAppStatus } = getSelectedVehicleTrackingOptions(formData);
    const { showRoadConditions } = getSelectedRoadConditionsOptions(formData);
    const { showSnowRoadConditions } = getSelectedSnowRoadConditionsOptions(formData);
    const { showStreetSweeperConditions } = getSelectedStreetSweeperConditionsOptions(formData);
    const streetSweeper = get(formData, 'vehicleTypes.filters.StreetSweeper', undefined);
    const vehicleIdsByType = getSelectedVehicleIdsByType(formData);
    const streetSweeperVehicleIds =
      vehicleId && vehicleType === STREET_SWEEPING ? [vehicleId] : getSelectedStreetSweeperVehicle(vehicleIdsByType);
    const vehicleTrackingVehicleIds = vehicleId
      ? [vehicleId]
      : (getSelectedVehicleForVehicleTrackings(vehicleIdsByType) as unknown as number[]);
    const vehicleIds = vehicleId ? [vehicleId] : flatten(map(vehicleIdsByType));
    const vehicleTypesIds = getSelectedVehicleTypes(formData).map(vehicleType => VEHICLE_TYPES[vehicleType].id);

    const isSnowRoadConditionsSelected = () => !!this.getFormValue(`${SNOW_ROAD_CONDITIONS}.showSnowRoadConditions`);
    const isStreetSweeperConditionsSelected = () =>
      !!this.getFormValue(`${STREET_SWEEPER_CONDITIONS}.showStreetSweeperConditions`);

    const vehicleInsightTypes = vehicleId
      ? VEHICLE_INSIGHT_TYPES
      : getSelectedVehicleInsightTypes({ ...formData, searchedVehicleId: vehicleId });
    const containerServiceInsightTypes = vehicleId
      ? CONTAINER_SERVICE_INSIGHT_TYPES
      : getSelectedContainerServiceInsightTypes({ ...formData, searchedVehicleId: vehicleId });

    const containerIssueInsightTypes = vehicleId
      ? ['0']
      : getSelectedContainerIssueInsightTypes({ ...formData, searchedVehicleId: vehicleId });

    const cityInsightTypes = vehicleId
      ? []
      : getSelectedCityInsightTypes({ ...formData, searchedVehicleId: vehicleId });
    const vendorLocationTypes = vehicleId
      ? []
      : getSelectedVendorLocationTypes({ ...formData, searchedVehicleId: vehicleId });

    const storageKey = getItem(SOS_ALERT_VEHICLE_ID_KEY);
    const sosAlertVehicleId = storageKey && storageKey !== 'undefined' ? JSON.parse(storageKey) : undefined;
    const hasSosAlertVehicleId = sosAlertVehicleId !== undefined;

    const {
      loadFleetInsights,
      loadMapInsights,
      loadRoadConditions,
      loadSnowRoadConditions,
      loadSnowRoadConditionUpdates,
      loadStreetSweeper,
      loadStreetSweeperConditions,
      loadStreetSweeperConditionUpdates,
      loadVehiclePositions,
      loadVehicleTrackings,
      loadVendor,
      loadVendorLocations,
      resetMapInsights,
      resetRoadConditions,
      resetSnowRoadConditions,
      resetStreetSweeper,
      resetStreetSweeperConditions,
      resetVehiclePositions,
      resetVehicleTrackings,
      resetVendorLocations,
    } = this.props;

    this.enableRestrictionMessages();

    if (
      !searchedVehicleId &&
      (containerServiceInsightTypes.length || containerIssueInsightTypes.length) &&
      size(vehicleIdsByType.residential) > 1
    ) {
      createErrorNotification(`${translate('dashboard.alertMessages.maxOneResidentialVehicle')}`);
      return;
    }

    if (
      !searchedVehicleId &&
      (showVehicleTracking || showAppStatus) &&
      (size(vehicleTrackingVehicleIds) > 5 ||
        size(streetSweeperVehicleIds) > 5 ||
        size(vehicleTrackingVehicleIds) + size(streetSweeperVehicleIds) > 5)
    ) {
      createErrorNotification(`${translate('dashboard.alertMessages.maxFiveVehicles')}`);
      return;
    }

    if (
      isEmpty(vehicleIds) &&
      areRestrictionMessagesEnabled &&
      !showRoadConditions &&
      !showSnowRoadConditions &&
      !showStreetSweeperConditions &&
      isFiltersApplied
    ) {
      createErrorNotification(`${translate('dashboard.alertMessages.noVehiclesSelected')}`);
      return;
    }

    this.closeFilterForm();

    if (searchedVehicleId) {
      resetMapInsights();
    }

    if ((showVehiclePositions && size(vehicleIds) > 0) || searchedVehicleId) {
      if (vendorId) {
        if (hasSosAlertVehicleId) {
          const currentDate = moment().format('MM/DD/YYYY');
          loadVehiclePositions(vendorId, currentDate.toString(), [sosAlertVehicleId]);
        } else {
          loadVehiclePositions(vendorId, date, vehicleIds);
        }
      }
    } else {
      resetVehiclePositions();
    }

    const selectedVehicleTrackingVehicleIds = isRubiconZ
      ? vehicleTrackingVehicleIds
      : [...(vehicleTrackingVehicleIds || []), ...(streetSweeperVehicleIds || [])];

    if (((showVehicleTracking || showAppStatus) && size(selectedVehicleTrackingVehicleIds) > 0) || searchedVehicleId) {
      if (vendorId && !hasSosAlertVehicleId) {
        loadVehicleTrackings(
          vendorId,
          date,
          selectedVehicleTrackingVehicleIds,
          searchedVehicleId ? true : showVehicleTracking,
          searchedVehicleId ? true : showAppStatus,
        );
      }
    } else {
      resetVehicleTrackings();
    }

    if (!searchedVehicleId && showRoadConditions) {
      if (vendorId && !hasSosAlertVehicleId) {
        loadRoadConditions(vendorId);
      }
    } else {
      resetRoadConditions();
    }

    /**
     * All dashboard data is refreshed every one and a half minute,
     * but PMs requested an approximate 5 minute refresh rate for snow road
     * conditions (about 2-3 auto-refreshes) since it's a lot of data.
     */
    if (!isAutoRefresh || autoRefreshesWithoutSnowRoadConditions >= 3) {
      this.setState({ autoRefreshesWithoutSnowRoadConditions: 0 });

      if (!searchedVehicleId && showSnowRoadConditions) {
        if (vendorId && !hasSosAlertVehicleId) {
          loadVendor(vendorId).then(() => {
            if (!isAutoRefresh) {
              loadSnowRoadConditions(vendorId);
            } else {
              loadSnowRoadConditionUpdates(vendorId);
            }
          });
        }
      } else {
        resetSnowRoadConditions();
      }
    } else {
      this.setState({ autoRefreshesWithoutSnowRoadConditions: autoRefreshesWithoutSnowRoadConditions + 1 });
    }

    if (!isAutoRefresh || autoRefreshesWithoutStreetSweeperConditions >= 3) {
      this.setState({ autoRefreshesWithoutStreetSweeperConditions: 0 });

      if (!searchedVehicleId && showStreetSweeperConditions) {
        if (vendorId && !hasSosAlertVehicleId) {
          loadVendor(vendorId).then(() => {
            if (!isAutoRefresh) {
              loadStreetSweeperConditions(vendorId);
            } else {
              loadStreetSweeperConditionUpdates(vendorId);
            }
          });
        }
      } else {
        resetStreetSweeperConditions();
      }
    } else {
      this.setState({
        autoRefreshesWithoutStreetSweeperConditions: autoRefreshesWithoutStreetSweeperConditions + 1,
      });
    }
    if (
      vehicleIds.length &&
      (containerIssueInsightTypes.length ||
        containerServiceInsightTypes.length ||
        vehicleInsightTypes.length ||
        cityInsightTypes.length)
    ) {
      if (vendorId && !hasSosAlertVehicleId) {
        loadMapInsights({
          vendorId,
          date,
          vehicleIds,
          containerIssueInsightTypes,
          containerServiceInsightTypes,
          vehicleInsightTypes,
          cityInsightTypes,
          vehicleTypeIdsCSV: vehicleTypesIds,
        });
      }
    } else {
      resetMapInsights();
    }

    if (vendorId && !hasSosAlertVehicleId) {
      (loadFleetInsights as any)(vendorId, date, 10, undefined, vehicleTypesIds);
    }

    if (vendorLocationTypes.length) {
      if (vendorId && !hasSosAlertVehicleId) {
        loadVendorLocations(vendorId, vendorLocationTypes);
      }
    } else {
      resetVendorLocations();
    }

    if (streetSweeper && showVehicleTracking && !!size(streetSweeperVehicleIds) && isRubiconZ) {
      if (!hasSosAlertVehicleId) (loadStreetSweeper as any)(vendorId, date, streetSweeperVehicleIds.toString());
    } else {
      resetStreetSweeper();
    }

    const formDataToPersist = { ...formData };
    delete formDataToPersist.searchedVehicleId;
    delete formDataToPersist.date;

    if (!isFirstLoad && !isResetApplied) {
      setPersistentFilters({
        vendorId,
        ...formDataToPersist,
        date,
        searchedVehicleId,
        selectedSearchedVehicleId: searchedVehicleId
          ? {
              label: searchedVehicleId.locationName,
              value: searchedVehicleId,
            }
          : undefined,
      });
    }

    this.setState(
      {
        isAutoRefresh: !(isFiltersApplied || isFirstLoad),
        isFiltersApplied: false,
        isResetApplied: false,
        isLastSettingsApplied: false,
        shouldMapFitBounds: false,
        cityInsightsConditionType: isSnowRoadConditionsSelected()
          ? SNOW_ROAD_CONDITIONS
          : isStreetSweeperConditionsSelected()
          ? STREET_SWEEPER_CONDITIONS
          : '',
      },
      () => {
        this.setState({
          isFirstLoad: false,
        });
      },
    );
  };

  onAutoRefresh = () => {
    this.setState({ isAutoRefresh: true, shouldMapFitBounds: false });
  };

  applyFilters = () => {
    clearPersistentSOSAlertVehicleId();
    this.setState({ isFiltersApplied: true, isAutoRefresh: false });
  };

  resetInitialValuesAndSubmitForm = () => {
    clearPersistentSOSAlertVehicleId();

    this.setState({ isResetApplied: true, isAutoRefresh: false });

    const { updateInitialFilterFormValues, submit, vendorId, filtersPreferences } = this.props;

    if (vendorId) {
      const shouldReset = true;
      updateInitialFilterFormValues(shouldReset, vendorId, filtersPreferences);
    }

    submit(DASHBOARD_FILTER_FORM_NAME);
    this.setState({ shouldMapFitBounds: true });
  };

  getFormValue = (name: string, defaultValue?: any) => get(this.props.formValues, name, defaultValue);

  applyLastSettings = async () => {
    const { vendorId, updateInitialFilterFormValues, filtersPreferences, submit } = this.props;

    const isSnowRoadConditionsSelected = () => !!this.getFormValue(`${SNOW_ROAD_CONDITIONS}.showSnowRoadConditions`);
    const isStreetSweeperConditionsSelected = () =>
      !!this.getFormValue(`${STREET_SWEEPER_CONDITIONS}.showStreetSweeperConditions`);

    const shouldReset = false;
    const shouldApplyPersistentFilters = true;
    await updateInitialFilterFormValues(shouldReset, vendorId, filtersPreferences, shouldApplyPersistentFilters);

    this.setState({
      isLastSettingsApplied: true,
      isAutoRefresh: false,
      cityInsightsConditionType: isSnowRoadConditionsSelected()
        ? SNOW_ROAD_CONDITIONS
        : isStreetSweeperConditionsSelected()
        ? STREET_SWEEPER_CONDITIONS
        : '',
    });

    submit(DASHBOARD_FILTER_FORM_NAME);
    this.setState({ shouldMapFitBounds: true });
  };

  openFilterForm = () => {
    this.setState({ isFilterFormOpen: true });
  };

  closeFilterForm = () => {
    this.setState({ isFilterFormOpen: false });
  };

  enableRestrictionMessages = () => {
    this.setState({ areRestrictionMessagesEnabled: true });
  };

  disableRestrictionMessages = () => {
    this.setState({ areRestrictionMessagesEnabled: false });
  };

  setConditionType = (conditionType: string) => {
    this.setState({ cityInsightsConditionType: conditionType });
  };

  resetState = (exclude: any = {}) => {
    const {
      resetFilters,
      resetInitialFilterFormValues,
      resetVehiclePositions,
      resetVehicleTrackings,
      resetMapInsights,
      resetFleetInsights,
      resetVendorLocations,
      resetRoadConditions,
      resetSnowRoadConditions,
      resetStreetSweeperConditions,
    } = this.props;

    if (!exclude.filters) resetFilters();
    if (!exclude.initialFilterFormValues) resetInitialFilterFormValues();
    if (!exclude.vendorLocations) resetVendorLocations();

    resetVehiclePositions();
    resetVehicleTrackings();
    resetMapInsights();
    resetFleetInsights();
    resetRoadConditions();
    resetSnowRoadConditions();
    resetStreetSweeperConditions();
  };

  render() {
    const {
      isLoadingFleetInsights,
      isLoadingMapInsights,
      isLoadingRoadConditions,
      isLoadingSnowRoadConditions,
      isLoadingStreetSweeper,
      isLoadingStreetSweeperConditions,
      isLoadingVehiclePositions,
      isLoadingVehicleTrackings,
      isLoadingVendorLocations,
      isLoadingVendor,
      isNonTechHauler,
      isSmartCity,
      isVendorWithGusId,
    } = this.props;

    const {
      cityInsightsConditionType,
      isAutoRefresh,
      isFilterFormOpen,
      isFirstLoad,
      isLastSettingsApplied,
      isResetApplied,
      isRubiconZ,
      shouldMapFitBounds,
    } = this.state;

    const isLoading =
      isLoadingFleetInsights ||
      isLoadingMapInsights ||
      isLoadingRoadConditions ||
      isLoadingSnowRoadConditions ||
      isLoadingStreetSweeper ||
      isLoadingStreetSweeperConditions ||
      isLoadingVehiclePositions ||
      isLoadingVehicleTrackings ||
      isLoadingVendorLocations ||
      isResetApplied ||
      isLastSettingsApplied ||
      isFirstLoad ||
      isLoadingVendor;

    const isSubMenuHidden = !isVendorWithGusId && !isNonTechHauler;

    return (
      <PageContent
        zIndex={1999}
        fluid
        fitToVerticalViewport
        isSubMenuHidden={isSubMenuHidden}
        margin="no"
        tabletMargin="no"
      >
        <PermissionGuard permission={DASHBOARD_FILTERS}>
          <DashboardFilterForm
            applyFilters={this.applyFilters}
            close={this.closeFilterForm}
            disableRestrictionMessages={this.disableRestrictionMessages}
            isOpen={isFilterFormOpen}
            isSubMenuHidden={isSubMenuHidden}
            onApplyLastSettings={this.applyLastSettings}
            onAutoRefresh={this.onAutoRefresh}
            onReset={this.resetInitialValuesAndSubmitForm}
            onSubmit={this.onSubmit}
            setConditionType={this.setConditionType}
          />
        </PermissionGuard>
        <DesktopWidthView>
          {!isSmartCity && <Banner />}

          <PermissionGuard permission={DASHBOARD_SERVICE_STATS_BOX}>
            <DashboardMapInsights isSubMenuHidden={isSubMenuHidden} />
          </PermissionGuard>

          <PermissionGuard permission={DASHBOARD_REAL_TIME_INSIGHTS_FEED}>
            <DashboardFleetInsights />
          </PermissionGuard>
        </DesktopWidthView>
        <DashboardMapWrapper>
          <DashboardMap
            conditionType={cityInsightsConditionType}
            isAutoRefresh={isAutoRefresh}
            isMapLoading={isLoading}
            onOpenFilters={this.openFilterForm}
            shouldMapFitBounds={shouldMapFitBounds}
            isRubiconZ={isRubiconZ}
          />
        </DashboardMapWrapper>

        {isLoading && <PageLoadingOverlay />}
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    formValues: getFormValues(DASHBOARD_FILTER_FORM_NAME)(state),
    isLoadingFleetInsights: state.dashboard.fleetInsights.isLoading,
    isLoadingMapInsights: state.dashboard.mapInsights.isLoading,
    isLoadingRoadConditions: state.dashboard.roadConditions.isLoading,
    isLoadingSnowRoadConditions: state.dashboard.snowRoadConditions.isLoading,
    isLoadingStreetSweeper: state.dashboard.streetSweeper.isLoading,
    isLoadingStreetSweeperConditions: state.dashboard.streetSweeperConditions.isLoading,
    isLoadingVehiclePositions: state.dashboard.vehiclesData.isLoading,
    isLoadingVehicleTrackings: state.dashboard.vehicleTrackings.isLoading,
    isLoadingVendorLocations: state.dashboard.vendorLocations.isLoading,
    isLoadingVendor: state.vendors.vendor.isLoading,
    isNonTechHauler: isRubiconNonTechHauler(
      state.account.login,
      state.vendors.defaultVendor,
      state.account.createUserAccount,
    ),
    isSmartCity: isSmartCitySelector(state.account.login, state.vendors.defaultVendor),
    isVendorWithGusId: vendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
    vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
    filtersPreferences: state.common.filters.filters || [],
    snowRoadConditionsEnabled: checkIfSnowPlowIsEnabled(state) && checkIfSnowPlowConditionsAreVisible(state),
    streetSweeperConditionsEnabled:
      checkIfStreetSweepingIsEnabled(state) && checkIfStreetSweepingConditionsAreVisible(state),
    vendor: state.vendors.vendor.vendor,
  };
};

const mapDispatchToProps = {
  loadFeatures,
  loadFleetInsights,
  loadMapInsights,
  loadRoadConditions,
  loadSnowRoadConditions,
  loadSnowRoadConditionUpdates,
  loadStreetSweeper,
  loadStreetSweeperConditions,
  loadStreetSweeperConditionUpdates,
  loadVehiclePositions,
  loadVehicleTrackings,
  loadVendor,
  loadVendorLocations,
  resetFilters,
  resetFleetInsights,
  resetInitialFilterFormValues,
  resetMapInsights,
  resetRoadConditions,
  resetSnowRoadConditions,
  resetStreetSweeper,
  resetStreetSweeperConditions,
  resetVehiclePositions,
  resetVehicleTrackings,
  resetVendorLocations,
  submit,
  updateInitialFilterFormValues,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardPage));
