import {
  RawRouteGeoFence,
  RouteGeoFenceMultiPolygon,
  RouteGeoFenceMultiPolygonOrPolygonJson,
} from '../interfaces/RouteGeoFence';
import { booleanClockwise, multiPolygon } from '@turf/turf';

const fixMultiPolygonHandRuleError = (coordinates: number[][][][]) => {
  const multiPolygonObj = multiPolygon(coordinates);

  const fixedPolygons = multiPolygonObj.geometry.coordinates.map(polygonCoordinates => {
    const isCounterClockwise = booleanClockwise(polygonCoordinates[0]);
    if (isCounterClockwise) {
      return polygonCoordinates;
    }

    const reversedCoordinates = polygonCoordinates.map(linearRing => linearRing.reverse());
    return reversedCoordinates;
  });

  const fixedMultiPolygon = multiPolygon(fixedPolygons);

  return fixedMultiPolygon.geometry.coordinates;
};

export const transformRawGeoFence = ({ geoFenceJson, ...geoFence }: RawRouteGeoFence): RouteGeoFenceMultiPolygon => {
  let json: RouteGeoFenceMultiPolygonOrPolygonJson | null = null;
  let rawCoordinates: any[][][] = [];

  try {
    json = JSON.parse(geoFenceJson);
  } catch (e) {}

  if (json && json.geometry.coordinates.length && json.geometry.coordinates[0].length) {
    // if it is a polygon, we need to wrap it in a multi polygon array
    rawCoordinates =
      json.geometry.type === 'MultiPolygon'
        ? fixMultiPolygonHandRuleError(json.geometry.coordinates as number[][][][])
        : [json.geometry.coordinates];
  }

  return {
    ...geoFence,
    geoFenceCoordinates: rawCoordinates,
  };
};

export const transformGeoFence = ({
  geoFenceCoordinates,
  ...geoFence
}: RouteGeoFenceMultiPolygon): RawRouteGeoFence => {
  const hasOnlyOnePolygon = geoFenceCoordinates.length === 1;

  const geoFenceJson: RouteGeoFenceMultiPolygonOrPolygonJson = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: hasOnlyOnePolygon ? 'Polygon' : 'MultiPolygon',
      coordinates: hasOnlyOnePolygon ? geoFenceCoordinates[0] : geoFenceCoordinates,
    },
  };

  return {
    ...geoFence,
    geoFenceJson: JSON.stringify(geoFenceJson),
  };
};
