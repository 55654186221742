import { createSelector } from 'reselect';
import { filter, identity, includes } from 'lodash-es';
import moment from 'moment';

import { DispatchBoardRouteJob } from '../interfaces/DispatchBoardRouteJob';
import {
  UnassignedJobsFilters,
  getMaterialTypesIds,
} from '../components/pages/dispatchBoard/dispatchBoardPageSections/common/useDispatchBoardFilters';
import { MultiSelectValues } from 'src/core/components/MultiSelectEnhanced';

function filterOnTerm(jobs: DispatchBoardRouteJob[], searchTerm: string): DispatchBoardRouteJob[] {
  const searchTermLowerCase = searchTerm.toLowerCase();
  return (
    filter(
      jobs,
      ({
        customerAccountNumber,
        customerName,
        jobType,
        locationAccountNumber,
        locationAddress,
        locationName,
        serviceContractAccountNumber,
        workOrderNumber,
      }) =>
        (!!customerAccountNumber && customerAccountNumber.toLowerCase().indexOf(searchTermLowerCase) !== -1) ||
        (!!customerName && customerName.toLowerCase().indexOf(searchTermLowerCase) !== -1) ||
        (!!jobType && jobType.toLowerCase().indexOf(searchTermLowerCase) !== -1) ||
        (!!locationAccountNumber && locationAccountNumber.toLowerCase().indexOf(searchTermLowerCase) !== -1) ||
        (!!locationAddress && locationAddress.formattedAddress.toLowerCase().indexOf(searchTermLowerCase) !== -1) ||
        (!!locationName && locationName.toLowerCase().indexOf(searchTermLowerCase) !== -1) ||
        (!!serviceContractAccountNumber &&
          serviceContractAccountNumber.toLowerCase().indexOf(searchTermLowerCase) !== -1) ||
        (!!workOrderNumber && workOrderNumber.toLowerCase().indexOf(searchTermLowerCase) !== -1),
    ) || []
  );
}

function filterOnDateRange(jobs: DispatchBoardRouteJob[], fromDate?: Date | string, toDate?: Date | string) {
  if (fromDate == null || toDate == null) return jobs;
  const from = moment(fromDate, 'MM/DD/YYYY');
  const to = moment(toDate, 'MM/DD/YYYY');

  return filter(jobs, ({ date }) => from.isSameOrBefore(date, 'day') && to.isSameOrAfter(date, 'day'));
}

function filterOnWasteType(jobs: DispatchBoardRouteJob[], wasteType?: string) {
  if (!wasteType) return jobs;
  return filter(jobs, job => job.wasteMaterialType === wasteType);
}

function filterOnEquipmentTypeResults(jobs: DispatchBoardRouteJob[], equipmentType?: string) {
  if (!equipmentType) return jobs;
  return filter(jobs, job => job.containerType === equipmentType);
}

function filterOnPickupTypeId(jobs: DispatchBoardRouteJob[], pickupTypeId?: number) {
  if (!pickupTypeId) return jobs;
  return filter(jobs, job => job.pickupTypeId === pickupTypeId);
}

function filterOnReasonCodeTypeId(jobs: DispatchBoardRouteJob[], reasonCodeTypeId?: number) {
  if (!reasonCodeTypeId) return jobs;
  return filter(jobs, job => job.reasonCodeTypeId === reasonCodeTypeId);
}

function filterOnServiceZones(jobs: DispatchBoardRouteJob[], serviceZones?: number[], allServiceZonesLength?: number) {
  if (!serviceZones || allServiceZonesLength === serviceZones.length) return jobs;
  return filter(jobs, job => includes(serviceZones, job.serviceZoneId));
}

function filterOnSupervisors(jobs: DispatchBoardRouteJob[], supervisors?: number[], allSupervisorsLength?: number) {
  if (!supervisors || allSupervisorsLength === supervisors.length) return jobs;
  return filter(jobs, job => includes(supervisors, job.supervisorId));
}

function filterOnMaterialTypes(
  jobs: DispatchBoardRouteJob[],
  materialTypes?: MultiSelectValues,
  allMaterialTypesLength?: number,
) {
  const materialTypeIds = getMaterialTypesIds(materialTypes);
  if (!materialTypes || allMaterialTypesLength === materialTypeIds.length) return jobs;
  return filter(jobs, job => includes(materialTypeIds, job.materialType.id?.toString()));
}

function sortByDate(dateFilterResults: DispatchBoardRouteJob[]) {
  return dateFilterResults.sort((a, b) => (moment(a.date).isSameOrAfter(b.date) ? 1 : -1));
}

function filterOnPriority(jobs: DispatchBoardRouteJob[], jobPriorityTypeId?: number) {
  if (!jobPriorityTypeId) return jobs;
  return filter(jobs, job => job.jobPriorityTypeId === jobPriorityTypeId);
}

export const getDispatchBoardFilteredUnassignedJobs = (
  jobs: DispatchBoardRouteJob[],
  filters: UnassignedJobsFilters,
  allServiceZonesLength: number,
  allSupervisorsLength: number,
  allMaterialTypesLength: number,
): DispatchBoardRouteJob[] => {
  const priorityResults = filterOnPriority(jobs, filters.jobPriorityTypeId);
  const termFilterResult = filterOnTerm(priorityResults, filters.searchTerm);
  const dateFilterResults = filterOnDateRange(termFilterResult, filters.fromDate, filters.toDate);
  const wasteTypeResults = filterOnWasteType(dateFilterResults, filters.wasteType);
  const equipmentTypeResults = filterOnEquipmentTypeResults(wasteTypeResults, filters.equipmentType);
  const pickupTypeIdResults = filterOnPickupTypeId(equipmentTypeResults, filters.pickupTypeId);
  const reasonCodeTypeIdResults = filterOnReasonCodeTypeId(pickupTypeIdResults, filters.reasonCodeTypeId);
  const serviceZonesResults = filterOnServiceZones(
    reasonCodeTypeIdResults,
    filters.serviceZones,
    allServiceZonesLength,
  );

  const supervisorsResults = filterOnSupervisors(serviceZonesResults, filters.supervisors, allSupervisorsLength);

  const materialTypesResults = filterOnMaterialTypes(supervisorsResults, filters.materialTypes, allMaterialTypesLength);

  const sorted = materialTypesResults ? sortByDate(materialTypesResults) : [];
  return sorted;
};

const dispatchBoardFilteredUnassignedJobsSelector = createSelector(getDispatchBoardFilteredUnassignedJobs, identity);

export default dispatchBoardFilteredUnassignedJobsSelector;
