import React, { Fragment, useEffect, useRef, useState } from 'react';

import { InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { Button, Grid, GridColumn, PanelSection, Text } from '../../../core/components/styled';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { GeoFencePopoverClose, GeoFencePopoverTitle, PopoverCloseIcon } from './../styled/GeoFences';
import { TimeField } from '../../../vendors/interfaces/GeoFenceSettings';
import { Input, Switch, TypedField } from '../../../core/components';
import { OFF_ROUTE_STATIONARY, OFF_ROUTE } from '../../constants/geoFenceSettings';
import {
  OFF_ROUTE as OFF_ROUTE_ID,
  OFF_ROUTE_STATIONARY as OFF_ROUTE_STATIONARY_ID,
  ROUTE_GEO_FENCE_GENERAL_ALERTS,
} from '../../../vendors/constants';
import * as Ducks from '../../ducks/routeGeoFence';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';
interface FormValues {
  offRouteAlertTime: string;
  offRouteStationaryAlertTime: string;
}

interface PropsWithoutReduxForm {
  alertsAreReadOnly?: boolean;
  geoFenceId: number;
  closeGeoFenceAlert(): void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const RouteGeoFenceAlertsForm: React.FC<Props> = ({
  alertsAreReadOnly,
  geoFenceId,
  closeGeoFenceAlert,
  change,
  handleSubmit,
}) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const geoFenceSettings = useSelector((state: AppState) => state.routes.geoFence.geoFenceSettings);
  const geoFenceSettingsLoading = useSelector((state: AppState) => state.routes.geoFence.geoFenceSettingsLoading);
  const geoFenceSettingsSaving = useSelector((state: AppState) => state.routes.geoFence.geoFenceSettingsSaving);

  const [isOffRouteGeoFenceEnabled, setIsOffRouteGeoFenceEnabled] = useState(false);
  const [isOffRouteStationaryGeoFenceEnabled, setIsOffRouteStationaryGeoFenceEnabled] = useState(false);
  const [isOffRouteToggleAlertEnabled, setIsOffRouteToggleAlertEnabled] = useState(false);
  const [isOffRouteStationaryToggleAlertEnabled, setIsOffRouteStationaryToggleAlertEnabled] = useState(false);

  const didMountRef = useRef(false);

  const onToggleChange = (geoFenceAlertId: number) => {
    if (geoFenceAlertId === OFF_ROUTE_ID) {
      setIsOffRouteGeoFenceEnabled(!isOffRouteGeoFenceEnabled);
    }

    if (geoFenceAlertId === OFF_ROUTE_STATIONARY_ID) {
      setIsOffRouteStationaryGeoFenceEnabled(!isOffRouteStationaryGeoFenceEnabled);
    }
  };

  const onSubmit = (values: any) => {
    if (!vendorId) {
      return;
    }

    const newSettings = geoFenceSettings.map(setting => {
      const newSetting = { ...setting };
      const type = setting.geoFenceType.technicalName;

      if (type === OFF_ROUTE) {
        newSetting.offRouteAlertTime = values[OFF_ROUTE_ID].offRouteAlertTime;
        newSetting.isActive = values[OFF_ROUTE_ID].isActive;
      }

      if (type === OFF_ROUTE_STATIONARY) {
        newSetting.offRouteStationaryAlertTime = values[OFF_ROUTE_STATIONARY_ID].offRouteStationaryAlertTime;
        newSetting.isActive = values[OFF_ROUTE_STATIONARY_ID].isActive;
      }

      return newSetting;
    });

    Ducks.saveRouteGeoFenceSettings(
      geoFenceId,
      vendorId,
      newSettings,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('routes.geoFences.alertMessages.alertSaveSuccess'));
        closeGeoFenceAlert();
      })
      .catch(() => {
        createErrorNotification(translate('routes.geoFences.alertMessages.alertSaveFail'));
      });
  };

  useEffect(() => {
    if (!vendorId) {
      return;
    }

    // set to true if not the 1st render
    if (!didMountRef.current) {
      didMountRef.current = true;

      // load geo fence settings on 1st render only
      Ducks.loadRouteGeoFenceSettings(
        geoFenceId,
        vendorId,
      )(dispatch).then(settings => {
        const offRoute = settings.find(setting => setting.geoFenceType.technicalName === OFF_ROUTE);
        const offRouteIsActive = offRoute?.isActive || false;
        const offRouteAlertEnabled = offRoute?.isVendorAlertActive || false;

        const offRouteStationary = settings.find(
          setting => setting.geoFenceType.technicalName === OFF_ROUTE_STATIONARY,
        );
        const offRouteStationaryIsActive = offRouteStationary?.isActive || false;
        const offRouteStationaryAlertEnabled = offRouteStationary?.isVendorAlertActive || false;

        if (offRoute) {
          dispatch(change(`[${OFF_ROUTE_ID}].offRouteAlertTime`, offRoute.offRouteAlertTime));
          dispatch(change(`[${OFF_ROUTE_ID}].isActive`, offRouteIsActive));
          setIsOffRouteGeoFenceEnabled(!offRouteIsActive ? offRouteIsActive : offRouteAlertEnabled);
          setIsOffRouteToggleAlertEnabled(offRouteAlertEnabled);
        }

        if (offRouteStationary) {
          dispatch(
            change(
              `[${OFF_ROUTE_STATIONARY_ID}].offRouteStationaryAlertTime`,
              offRouteStationary.offRouteStationaryAlertTime,
            ),
          );
          dispatch(change(`[${OFF_ROUTE_STATIONARY_ID}].isActive`, offRouteStationaryIsActive));
          setIsOffRouteStationaryGeoFenceEnabled(
            !offRouteStationaryIsActive ? offRouteStationaryIsActive : offRouteStationaryAlertEnabled,
          );
          setIsOffRouteStationaryToggleAlertEnabled(offRouteStationaryAlertEnabled);
        }
      });
    }
  }, [change, dispatch, geoFenceId, isOffRouteStationaryToggleAlertEnabled, isOffRouteToggleAlertEnabled, vendorId]);

  return (
    <>
      <GeoFencePopoverTitle>
        <Text size="large" weight="medium">
          {translate('routes.geoFences.geoFenceAlerts')}
        </Text>

        <GeoFencePopoverClose onClick={() => closeGeoFenceAlert()}>
          <PopoverCloseIcon />
        </GeoFencePopoverClose>
      </GeoFencePopoverTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <PanelSection padding="small xSmall no" isLoading={geoFenceSettingsLoading || geoFenceSettingsSaving}>
          <Grid multiLine>
            {ROUTE_GEO_FENCE_GENERAL_ALERTS.map(geoFenceAlert => (
              <Fragment key={geoFenceAlert.id}>
                <GridColumn size="12/12">
                  <TypedField
                    name={`[${geoFenceAlert.id}].isActive`}
                    component={Switch}
                    props={{
                      disabled:
                        (geoFenceAlert.id === OFF_ROUTE_ID && !isOffRouteToggleAlertEnabled) ||
                        (geoFenceAlert.id === OFF_ROUTE_STATIONARY_ID && !isOffRouteStationaryToggleAlertEnabled) ||
                        alertsAreReadOnly,
                      label: translate(`vendors.geoFenceAlerts.${geoFenceAlert.translationKey}`),
                      labelOnLeft: true,
                      margin: 'no no sMedium no',
                      width: '100%',
                    }}
                    onSwitch={() => onToggleChange(geoFenceAlert.id)}
                  />
                </GridColumn>

                {geoFenceAlert.timeFields.map((timeField: TimeField) => {
                  const fieldName = `[${geoFenceAlert.id}].${timeField.name}`;

                  return (
                    <GridColumn size="12/12" key={timeField.name}>
                      <TypedField
                        name={fieldName}
                        component={Input}
                        props={{
                          name: fieldName,
                          readOnly:
                            (geoFenceAlert.id === OFF_ROUTE_ID && !isOffRouteGeoFenceEnabled) ||
                            (geoFenceAlert.id === OFF_ROUTE_STATIONARY_ID && !isOffRouteStationaryGeoFenceEnabled) ||
                            alertsAreReadOnly,
                          isTimeField: true,
                          label: translate(`routes.geoFences.${geoFenceAlert.translationKeyAlert}`),
                        }}
                      />
                    </GridColumn>
                  );
                })}
              </Fragment>
            ))}

            {(isOffRouteToggleAlertEnabled || isOffRouteStationaryToggleAlertEnabled) && !alertsAreReadOnly && (
              <GridColumn size="12/12" align="center">
                <Button color="primary" type="submit">
                  {translate('common.save')}
                </Button>
              </GridColumn>
            )}
          </Grid>
        </PanelSection>
      </form>
    </>
  );
};

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: 'routeGeoFenceSettingsEditorForm',
  enableReinitialize: true,
  onSubmitFail: focusFirstInvalidField,
})(RouteGeoFenceAlertsForm);
