import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Cookie from 'js-cookie';
import { size, some } from 'lodash-es';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Resizable } from 're-resizable';

import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { MapDragHandle, Table } from 'src/core/components';
import { Button, MapContainer, Message, Panel, PanelSection, PanelSectionGroup } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT, TODAY_FORMATTED } from 'src/core/constants';
import confirm from 'src/core/services/confirm';
import { createSuccessNotification } from 'src/core/services/createNotification';
import { multiWordAndSearch } from 'src/core/services/search';
import translate from 'src/core/services/translate';
import { INSIGHTS_COOKIE_DATE_KEY, INSIGHTS_COOKIE_START_DATE_KEY, INSIGHT_CLEARED } from 'src/insights/constants';
import { AppState } from 'src/store';
import { getQueryParams } from 'src/utils/services/queryParams';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import {
  clearInsight as doClearInsight,
  exportCommunityInsights as doExportCommunityInsights,
  loadCommunityInsights as doLoadCommunityInsights,
} from '../../ducks';
import { CommunityInsightItem } from '../../interfaces/communityInsights';
import { CommunityInsightsForm } from '../forms';
import { CommunityInsightsPageTableRow } from './';
import { getAllFiltersPreferencesIds } from 'src/common/utils/filters';
import CommunityInsightsMapGL from './mapGL/CommunityInsightsMapGL';
import {
  clearCommunityinsightsMapSelectedFeature,
  setCommunityinsightsMapSelectedFeature,
} from 'src/insights/ducks/mapControl';

type SortField = '' | 'routeDate' | 'locationFlaggingTypeName' | 'address' | 'routeName' | 'vehicleName' | 'statusId';
type SortDirection = 'asc' | 'desc';

const communityInsightsTableCells = [
  { name: 'routeDate', label: translate('insights.date'), width: '12%', sortable: true },
  { name: 'locationFlaggingTypeName', label: translate('insights.insight'), width: '25%', sortable: true },
  { name: 'address', label: translate('insights.address'), width: '17%', sortable: true },
  { name: 'routeName', label: translate('insights.route'), width: '17%', sortable: true },
  { name: 'vehicleName', label: translate('insights.vehicle'), width: '10%', sortable: true },
  { name: 'statusId', label: translate('insights.status'), width: '8%', sortable: true },
  { name: 'options', label: translate('common.options'), width: '11%', align: 'right', noPaddingRight: true },
];

const CommunityInsightsPage: FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();

  /** State */
  const [insightIds, setInsightIds] = useState<number[] | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [sortField, setSortField] = useState<SortField>('');
  const [statusIds, setStatusIds] = useState<number[] | null>(null);

  /** Selectors */
  const vendorId = useSelector((state: AppState) =>
    currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  );
  const isExporting = useSelector((state: AppState) => state.insights.communityInsights.isExporting);
  const isLoading = useSelector((state: AppState) => state.insights.communityInsights.isLoading);
  const cityInsightTypes = useSelector((state: AppState) => state.dashboard.filters.cityInsightActiveTypes);
  const insightsForMap = useSelector((state: AppState) => state.insights.communityInsights.insights.map);
  const insights = useSelector((state: AppState) => state.insights.communityInsights.insights.list);
  const filtersPreferencesIds = useSelector((state: AppState) =>
    getAllFiltersPreferencesIds(state.common.filters.filters),
  );

  const loadInsights = useCallback(() => {
    if (!vendorId) return;

    dispatch(clearCommunityinsightsMapSelectedFeature());

    const { startDate, endDate } = getQueryParams(location.search);

    const insightsDate = Cookie.get(INSIGHTS_COOKIE_DATE_KEY);
    const insightsStartDate = Cookie.get(INSIGHTS_COOKIE_START_DATE_KEY);

    doLoadCommunityInsights(
      vendorId,
      startDate || insightsStartDate || insightsDate || TODAY_FORMATTED,
      endDate || insightsDate || TODAY_FORMATTED,
    )(dispatch).then(() => {
      dispatch(clearCommunityinsightsMapSelectedFeature());
    });
  }, [vendorId, location, dispatch]);

  const exportInsights = useCallback(() => {
    if (!vendorId) return;

    const { insightIds, statusIds, insightName, startDate, endDate } = getQueryParams(location.search);
    const cityInsightTypeIds = cityInsightTypes.map(cityInsightTypes => cityInsightTypes.id).join();

    const insightsDate = Cookie.get(INSIGHTS_COOKIE_DATE_KEY);
    const insightsStartDate = Cookie.get(INSIGHTS_COOKIE_START_DATE_KEY);

    doExportCommunityInsights(
      vendorId,
      insightIds || cityInsightTypeIds,
      statusIds,
      insightName,
      startDate || insightsStartDate || insightsDate || TODAY_FORMATTED,
      endDate || insightsDate || TODAY_FORMATTED,
      sortDirection,
      sortField,
      filtersPreferencesIds,
    )(dispatch);
  }, [dispatch, location, vendorId, cityInsightTypes, sortField, sortDirection, filtersPreferencesIds]);

  const changeSearchTerm = useCallback(
    (searchTerm: string) => {
      setSearchTerm(searchTerm);
      dispatch(clearCommunityinsightsMapSelectedFeature());
    },
    [setSearchTerm, dispatch],
  );

  const changeInsightIds = useCallback(
    (insightIds: number[] | null) => {
      setInsightIds(insightIds);
      dispatch(clearCommunityinsightsMapSelectedFeature());
    },
    [setInsightIds, dispatch],
  );

  const changeStatusIds = useCallback(
    (statusIds: number[] | null) => {
      setStatusIds(statusIds);
      dispatch(clearCommunityinsightsMapSelectedFeature());
    },
    [setStatusIds, dispatch],
  );

  const sortInsights = useCallback(
    (a: CommunityInsightItem, b: CommunityInsightItem) => {
      let aProp: string;
      let bProp: string;

      switch (sortField) {
        case '':
          return 0;

        case 'locationFlaggingTypeName':
          aProp = a.locationFlaggingType.name.toLowerCase();
          bProp = b.locationFlaggingType.name.toLowerCase();
          break;

        case 'statusId':
          aProp = a.statusId === INSIGHT_CLEARED ? translate('insights.cleared') : translate('insights.open');
          bProp = b.statusId === INSIGHT_CLEARED ? translate('insights.cleared') : translate('insights.open');
          break;

        default:
          aProp = (a[sortField] || '').toString().toLowerCase();
          bProp = (b[sortField] || '').toString().toLowerCase();
          break;
      }

      if (sortDirection === 'asc') {
        return aProp > bProp ? 1 : -1;
      }

      return aProp < bProp ? 1 : -1;
    },
    [sortField, sortDirection],
  );

  const clickRow = useCallback(
    (id: string) => {
      const selectedInsight = insights.find(insight => insight.mapSelectorId === id);

      if (!selectedInsight) {
        dispatch(clearCommunityinsightsMapSelectedFeature());
        return;
      }
      dispatch(setCommunityinsightsMapSelectedFeature(selectedInsight.id));
    },
    [insights, dispatch],
  );

  const clearInsight = useCallback(
    async (id: number) => {
      if (!(await confirm(translate('insights.alertMessages.confirmClearInsight')))) {
        return;
      }

      doClearInsight(id)(dispatch).then(() => {
        createSuccessNotification(translate('insights.alertMessages.insightClearSucess'));
      });
    },
    [dispatch],
  );

  const changeSortOptions = useCallback(
    (sortField: SortField, sortDirection: SortDirection) => {
      setSortField(sortField);
      setSortDirection(sortDirection);
    },
    [setSortField, setSortDirection],
  );

  /** Memos */
  const transformedInsights = useMemo(() => {
    let updatedInsights = insights.map(insight => {
      const mapInsight = insightsForMap.find(mapInsight => mapInsight.mapSelectorId === insight.mapSelectorId);

      return {
        ...insight,
        isCleared: insight.statusId === INSIGHT_CLEARED,
        isCluster: !!mapInsight && mapInsight.isCluster,
      };
    });

    if (searchTerm) {
      updatedInsights = updatedInsights.filter(insight => {
        const fields = [
          insight.id.toString(),
          moment(insight.routeDate).format('MM/DD/YYYY'),
          moment(insight.routeDate).format('MMMM D YYYY'),
          insight.locationFlaggingType.name,
          insight.latitude.toString(),
          insight.longitude.toString(),
          insight.vehicleName,
          insight.address,
          insight.routeName,
          insight.note,
          insight.statusId === INSIGHT_CLEARED ? translate('insights.cleared') : translate('insights.open'),
        ];

        const filteredAndMatchedFields = fields
          .filter(field => typeof field === 'string' && !!field)
          .map(field => multiWordAndSearch(field, searchTerm));

        return some(filteredAndMatchedFields, Boolean);
      });
    }

    if (insightIds) {
      updatedInsights = updatedInsights.filter(insight => insightIds.indexOf(insight.locationFlaggingType.id) !== -1);
    }

    if (statusIds) {
      updatedInsights = updatedInsights.filter(insight => statusIds.indexOf(insight.statusId) !== -1);
    }

    if (sortField && sortDirection) {
      updatedInsights = updatedInsights.sort((a, b) => sortInsights(a, b));
    }

    return updatedInsights;
  }, [searchTerm, insightIds, statusIds, insightsForMap, insights, sortField, sortDirection, sortInsights]);

  const virtualizedProps = useMemo(
    () => ({
      height: Math.min(transformedInsights.length * TABLE_ROW_HEIGHT, TABLE_ROW_HEIGHT * 8) || 1,
      itemSize: TABLE_ROW_HEIGHT,
    }),
    [transformedInsights],
  );

  /** Effects */
  useEffect(() => {
    loadInsights();
  }, [loadInsights, location.search]);

  return (
    <PageContent>
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle>{translate('insights.communityInsights')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>

        <PageActions>
          <Button onClick={exportInsights} color="primary" margin="no">
            {translate('common.export')}
          </Button>
        </PageActions>
      </PageHeader>

      <Panel margin="no">
        <PanelSection display="block" isLoading={isLoading || isExporting}>
          <CommunityInsightsForm
            onSearchTermChange={changeSearchTerm}
            onInsightsChange={changeInsightIds}
            onStatusesChange={changeStatusIds}
          />
          <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }}>
            <MapContainer size="large">
              <CommunityInsightsMapGL insights={transformedInsights} />
            </MapContainer>
          </Resizable>

          <PanelSectionGroup position="top" isLoading={isLoading}>
            {transformedInsights && (
              <PanelSection>
                <Table
                  cells={communityInsightsTableCells}
                  rows={transformedInsights}
                  rowComponent={CommunityInsightsPageTableRow}
                  rowProps={{
                    handleRowClick: clickRow,
                    clearInsight: clearInsight,
                  }}
                  sort={changeSortOptions as any}
                  withClickableRows
                  virtualized
                  noOverflow
                  virtualizedProps={virtualizedProps}
                />
              </PanelSection>
            )}

            {!size(transformedInsights) && <Message padding="sMedium">{translate('insights.noInsightsFound')}</Message>}
          </PanelSectionGroup>
        </PanelSection>
      </Panel>
    </PageContent>
  );
};

export default withRouter(CommunityInsightsPage);
