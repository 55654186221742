import { MouseEvent, PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { ActionButtonTooltip } from 'src/core/components';
import { DispatchBoardJobAction, DispatchBoardJobActions } from 'src/routes/components/styled';
import { JobTypes } from 'src/routes/constants/jobTypes';
import { ModalTypes } from './ModalContainer';

interface JobActionsProps {
  canEditService?: boolean;
  header?: boolean;
  imageCount: number;
  isDragStart?: boolean;
  issueReportedCount: number;
  noteCount: number;
  toggleModal?(modal: ModalTypes, open: boolean, isHeader?: boolean): void;
  type: JobTypes;
  weightTicketCount: number;
  locationId?: number;
  customerId?: number;
  serviceContractId?: number;
}

class JobActions extends PureComponent<JobActionsProps> {
  toggleModal = (event: MouseEvent, modal: ModalTypes, open: boolean) => {
    event.stopPropagation();
    !!this.props.toggleModal && this.props.toggleModal(modal, open, this.props.header);
  };

  render() {
    const {
      canEditService,
      header,
      imageCount,
      isDragStart,
      issueReportedCount,
      noteCount,
      type,
      weightTicketCount,
      locationId,
      customerId,
      serviceContractId,
    } = this.props;

    const isViewOnly = checkIfViewOnly();
    const isSupport = checkIfSupport();

    return (
      <DispatchBoardJobActions isDragStart={isDragStart}>
        {!!weightTicketCount && (
          <DispatchBoardJobAction
            id={`weight-ticket-${type}-button`}
            onClick={e => this.toggleModal(e, 'weightTicket', true)}
          >
            <ActionButtonTooltip icon="ticket" tooltip="tickets" content={weightTicketCount} rotationAngle="-45" />
          </DispatchBoardJobAction>
        )}
        {!!imageCount && (
          <DispatchBoardJobAction
            id={`route-location-images-${type}-button`}
            onClick={e => this.toggleModal(e, 'routeLocationImages', true)}
          >
            <ActionButtonTooltip icon="image" tooltip="images" content={imageCount} />
          </DispatchBoardJobAction>
        )}
        {(!!noteCount || !this.props.header) && (
          <DispatchBoardJobAction
            id={`route-location-notes-${type}button`}
            onClick={e => this.toggleModal(e, 'routeLocationNotes', true)}
          >
            <ActionButtonTooltip icon="note" tooltip="notes" content={noteCount} />
          </DispatchBoardJobAction>
        )}
        {!!issueReportedCount && (
          <DispatchBoardJobAction
            id={`route-location-issues-${type}-button`}
            onClick={e => this.toggleModal(e, 'routeLocationIssues', true)}
          >
            <ActionButtonTooltip icon="issue" tooltip="issues" content={issueReportedCount} />
          </DispatchBoardJobAction>
        )}

        {!header && (
          <>
            {canEditService && (
              <DispatchBoardJobAction id={`edit-service-${type}-button`}>
                <Link
                  id="edit-service-link"
                  color="grayDark"
                  to={`/customers/customers/${customerId}/location/${locationId}/service/${serviceContractId}`}
                >
                  <ActionButtonTooltip icon="edit" tooltip="editService" />
                </Link>
              </DispatchBoardJobAction>
            )}

            {!isViewOnly && !isSupport && (
              <>
                <DispatchBoardJobAction onClick={e => this.toggleModal(e, 'customerEditor', true)}>
                  <ActionButtonTooltip icon="user" tooltip="editCustomer" />
                </DispatchBoardJobAction>

                <DispatchBoardJobAction onClick={e => this.toggleModal(e, 'locationEditor', true)}>
                  <ActionButtonTooltip icon="mapPin" tooltip="editLocation" />
                </DispatchBoardJobAction>
              </>
            )}
          </>
        )}
      </DispatchBoardJobActions>
    );
  }
}

export default JobActions;
