import styled from 'styled-components';

import { sizeMapper } from '../../../utils/styles';
import Icon from '../Icon';

const DragHandleIcon = styled(Icon).attrs({ icon: 'drag' })<{ cursor?: string; color?: string; margin?: string }>`
  display: inline-block;
  width: 11px;
  height: 11px;
  margin: ${props => sizeMapper(props.margin, 'no')};
  line-height: 11px;
  cursor: ${props => props.cursor || 'pointer'};
  color: ${props => props.color || props.theme.gray};
`;

export default DragHandleIcon;
