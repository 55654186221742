import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, WrappedFieldInputProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../store';
import { Dropdown } from '../../core/components';
import insightsFormInitialValuesSelector from '../services/insightsFormInitialValues';
import translate from '../../core/services/translate';

interface ComponentProps extends RouteComponentProps {
  dropdownProps?: any;
  input: WrappedFieldInputProps;
  label?: string;
  placeholder?: string;
  withLabel?: boolean;
  withPlaceholder?: boolean;
}

interface PropsWithoutReduxForm extends ComponentProps {
  activeRoutes?: any[];
  initialValues: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class RoutesForReportDropdown extends PureComponent<Props> {
  onChange = (event: any, value: any) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      activeRoutes = [],
      dropdownProps,
      input: { name },
      label,
      placeholder,
      withLabel,
      withPlaceholder,
    } = this.props;

    const activeRoutesOptions = activeRoutes.map(({ routeName, routeId }) => ({
      label: routeName,
      value: routeId,
    }));

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('routes.routes') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('routes.routes') : undefined)}
        component={Dropdown}
        options={activeRoutesOptions}
        onChange={this.onChange}
        {...dropdownProps}
      />
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const activeRoutes = state.insights.reportingDetails.reportingDetails;
  const initialValues = insightsFormInitialValuesSelector(
    ownProps.location.search,
    null,
    null,
    ownProps.input.value,
  ) as any;

  return {
    activeRoutes,
    initialValues: { ...initialValues, vehicleId: initialValues.vehicleId || ownProps.input.value },
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'routesDropdown',
      enableReinitialize: true,
    })(RoutesForReportDropdown),
  ),
);
