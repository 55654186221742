import { PureComponent } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { map } from 'lodash-es';

import { PanelSection, Button, GridColumn, Grid } from '../../../core/components/styled';
import { Dropdown } from '../../../core/components';
import { isRequired } from '../../../utils/services/validator';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import versionStatusesFormInitialValuesSelector from '../../services/versionStatusesFormInitialValuesSelector';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';

interface ComponentProps extends RouteComponentProps {}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
  vehicleTypes?: any[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class VersionStatusesForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, vehicleTypes, invalid } = this.props;

    const vehicleTypeOptions = map(vehicleTypes, vehicleType => ({
      label: vehicleType.name,
      value: vehicleType.id,
    }));

    return (
      <form onSubmit={handleSubmit} noValidate>
        <PanelSection padding="small xSmall" withBorder>
          <Grid>
            <GridColumn size="3/12">
              <Field
                component={Dropdown}
                name="vehicleTypeId"
                options={vehicleTypeOptions}
                placeholder={translate('vehicles.vehicleType')}
                margin="no"
                validate={[isRequired]}
                isClearable
              />
            </GridColumn>

            <GridColumn size="6/12" align="right">
              <Button type="submit" color="primary" disabled={invalid}>
                {translate('common.search')}
              </Button>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const initialValues = versionStatusesFormInitialValuesSelector(ownProps.history.location.search);
  const vehicleTypes = state.fleet.vehicleTypes.vehicleTypes;

  return {
    initialValues,
    vehicleTypes,
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'versionStatuses',
      enableReinitialize: true,
      onSubmitFail: focusFirstInvalidField,
    })(VersionStatusesForm),
  ),
);
