import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import doLoadEventLog, { loadZEventLog as doLoadZEventLog } from '../services/loadEventLog';
import { IEventLog } from '../interfaces/EventLog';

interface State {
  isLoading: boolean;
  eventLog?: IEventLog[];
  zEventLog?: IEventLog[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Actions
const START_LOAD = 'routes/eventLog/START_LOAD';
const COMPLETE_LOAD = 'routes/eventLog/COMPLETE_LOAD';
const COMPLETE_Z_LOAD = 'routes/eventLog/COMPLETE_Z_LOAD';
const FAIL_LOAD = 'routes/eventLog/FAIL_LOAD';
const RESET = 'routes/eventLog/RESET';

// Initial state
const initialState = {
  isLoading: false,
  eventLog: undefined,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          eventLog: action.eventLog,
        },
      });

    case COMPLETE_Z_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          zEventLog: action.zEventLog,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          eventLog: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (eventLog: IEventLog[]) => ({
  type: COMPLETE_LOAD,
  eventLog,
});

const completeZLoad = (zEventLog: IEventLog[]) => ({
  type: COMPLETE_Z_LOAD,
  zEventLog,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadEventLog = (routeId: number, eventTypeIds?: number[]) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadEventLogPromise = doLoadEventLog(routeId, eventTypeIds);
  loadEventLogPromise.then(eventLog => dispatch(completeLoad(eventLog))).catch(() => dispatch(failLoad()));
  return loadEventLogPromise;
};

export const loadZEventLog = (routeId: number, eventTypeIds?: number[]) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadZEventLogPromise = doLoadZEventLog(routeId, eventTypeIds);
  loadZEventLogPromise.then(zEventLog => dispatch(completeZLoad(zEventLog))).catch(() => dispatch(failLoad()));
  return loadZEventLogPromise;
};

export const resetEventLog = () => ({
  type: RESET,
});
