import { filter, find, get, includes, map, size, toLower } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router';

import { ROUTES_TRACKER_SEQUENCE_ROUTE } from 'src/account/constants';
import { checkIfSupport, checkIfViewOnly, hasPermission } from 'src/account/utils/permissions';
import { PageFooter } from 'src/common/components/styled';
import { Table } from 'src/core/components';
import { Button, Panel, PanelSection, PanelSectionGroup, Text } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import informationPrompt from 'src/core/services/informationPrompt';
import translate from 'src/core/services/translate';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { NoDataMessageContainer } from 'src/routes/components/styled';
import {
  CANCELED,
  COMPLETE,
  FAILED,
  FAILED_CONFIRMED,
  PENDING,
  SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE,
} from 'src/routes/constants';
import { assignTemplatesToGroups, updateRouteSequenceStatus } from 'src/routes/ducks';
import { bulkDeleteRouteTemplates, loadRoutePlannerRouteTemplates } from 'src/routes/ducks/routePlanner';
import { RoutePlannerTemplatesPayload } from 'src/routes/interfaces/routePlanner/RoutePlannerEndpointsPayload';
import { RoutePlannerRouteTemplate } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplate';
import { getRoutePlannerFilters } from 'src/routes/services/routePlannerFilters';
import { getRoutePlannerOptions } from 'src/routes/services/routePlannerVehicleTypesFormInitialValuesSelector';
import { getRoutePlannerBuilderTableCells } from 'src/routes/utils/routePlanner';
import { routeSequencingStatusSelector } from 'src/vendors/ducks';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import RoutePlannerRouteTemplatesListingTableRow from './RoutePlannerRouteTemplatesListingTableRow';
import { RoutesOrTemplatesSearchForm } from 'src/routes/components/forms';
import { AddToGroupsModalResolver, RoutePlannerNewSchedulerModalResolver } from 'src/routes/components/modals';
import { AddToGroupsFormValues } from 'src/routes/components/forms/AddToGroupsForm';

interface Props {
  rows: RoutePlannerRouteTemplate[];
  isLoading: boolean;
  deleteTemplate: (id: number) => void;
}

const RoutePlannerRouteTemplatesListingSection = ({ rows, isLoading, deleteTemplate }: Props) => {
  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const hasRouteSequencePermission = hasPermission(ROUTES_TRACKER_SEQUENCE_ROUTE);
  const routeSequencingEnabled = useSelector(state => routeSequencingStatusSelector(state.vendors.features.features));
  const { routeStatuses, isLoading: isLoadingRouteSequenceStatus } = useSelector(state => state.routes.routeSequence);
  const filterPreferences = useSelector(state => state.common.filters.filters);
  const vehicleTypes = useSelector(state => state.fleet.vehicleTypesForVendor.vehicleTypesForVendor);
  const supervisorEnabled = useSelector(supervisorExperienceFeatureIsEnabled);

  const [selectedRouteTemplates, setSelectedRouteTemplates] = useState<number[]>([]);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [templatesToSchedule, setTemplatesToSchedule] = useState<{ id: number; scheduledDay: number }[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddToGroupsModalOpen, setIsAddToGroupsModalOpen] = useState(false);

  const routePlannerFilters = useMemo(() => {
    return getRoutePlannerFilters(search);
  }, [search]);

  const vehicleTypeAndDayOfServiceFilters = useMemo(() => {
    return getRoutePlannerOptions(vehicleTypes, filterPreferences, search);
  }, [filterPreferences, search, vehicleTypes]);

  const { supervisorsIds, serviceZonesIds, facilityTypeIds, routeStatusesIds, materialTypeIds } = routePlannerFilters;
  const { vehicleTypeId, dayOfServiceId } = vehicleTypeAndDayOfServiceFilters;

  const filteredRouteTemplates = useMemo(
    () =>
      map(
        filter(rows, rw => includes(toLower(rw.routeTemplateName), toLower(searchTerm))),
        route => {
          const routeSequence = find(routeStatuses, r => r.routeId === route.routeTemplateId);

          return {
            ...route,
            routeSequenceStatus: get(routeSequence, 'status'),
            jobId: get(routeSequence, 'jobId'),
            isChecked: selectedRouteTemplates.includes(route.routeTemplateId),
          };
        },
      ),
    [routeStatuses, rows, searchTerm, selectedRouteTemplates],
  );

  const toggleSelectAll = () => {
    if (size(selectedRouteTemplates) === size(filteredRouteTemplates)) {
      setSelectedRouteTemplates([]);
    } else {
      setSelectedRouteTemplates(map(filteredRouteTemplates, 'routeTemplateId'));
    }
  };

  const selectTemplate = (routeId: number) => {
    if (selectedRouteTemplates.includes(routeId)) {
      setSelectedRouteTemplates(selectedRouteTemplates.filter(id => id !== routeId));
    } else {
      setSelectedRouteTemplates([...selectedRouteTemplates, routeId]);
    }
  };

  const onDeleteTemplate = (routeTemplateId: number) => {
    deleteTemplate(routeTemplateId);
  };

  const onBulkDelete = async () => {
    if (
      await confirm(translate('routes.planner.deleteReoccurringConfirmation', { count: selectedRouteTemplates.length }))
    ) {
      bulkDeleteRouteTemplates(
        vendorId,
        selectedRouteTemplates,
      )(dispatch)
        .then(count => {
          createSuccessNotification(
            translate('routes.planner.deleteReoccurringSuccess', { count, total: selectedRouteTemplates.length }),
          );
          const isSnowPlowRoute = vehicleTypeId === SNOW_PLOW_ID;
          const isStreetSweeper = vehicleTypeId === STREET_SWEEPER_ID;
          const filtersToPass = {
            vendorId,
            materialTypeIds: materialTypeIds?.length ? materialTypeIds?.toString() : '',
            scheduledDayIds: dayOfServiceId && !isStreetSweeper && !isSnowPlowRoute ? dayOfServiceId?.toString() : '',
            vehicleTypeIds: vehicleTypeId ? vehicleTypeId?.toString() : '',
            supervisorIds: supervisorsIds?.length && supervisorEnabled ? supervisorsIds?.toString() : '',
            serviceZoneIds: serviceZonesIds?.length ? serviceZonesIds?.toString() : '',
            facilityIds: facilityTypeIds?.length ? facilityTypeIds?.toString() : '',
            isActive: routeStatusesIds?.length
              ? routeStatusesIds.length === 1
                ? !!routeStatusesIds[0]
                : undefined
              : undefined,
          } as RoutePlannerTemplatesPayload;

          loadRoutePlannerRouteTemplates(filtersToPass)(dispatch);
          setSelectedRouteTemplates([]);
        })
        .catch(() => {
          createErrorNotification(translate('routes.planner.deleteReoccurringError'));
        });
    }
    return;
  };

  const handleScheduleClick = (templateId?: number) => {
    let templatesToSchedule: { id: number; scheduledDay: number }[] = [];
    if (templateId) {
      const template = find(rows, { routeTemplateId: templateId });
      if (template && template.schedulerId && template.isActive && !!template.numberOfStops)
        templatesToSchedule = [{ id: templateId, scheduledDay: template.schedulerId }];
    } else {
      templatesToSchedule = filter(
        map(selectedRouteTemplates, id => {
          const template = find(rows, { routeTemplateId: id });
          if (template && template.schedulerId && template.isActive && !!template.numberOfStops)
            return { id, scheduledDay: template.schedulerId };
          return null;
        }),
        d => d !== null,
      ) as { id: number; scheduledDay: number }[];
    }
    setTemplatesToSchedule(templatesToSchedule);
    setIsScheduleModalOpen(true);
  };

  const onTableRowClick = async (
    id: number,
    routeSequenceStatus: string,
    jobId: number,
    isEdit: boolean,
    vehicleTypeId: number,
  ) => {
    const isSnowPlowRoute = vehicleTypeId === SNOW_PLOW_ID;
    const isStreetSweeperRoute = vehicleTypeId === STREET_SWEEPER_ID;

    // TODO: you have to change this
    const getRedirectUrl = () => {
      if (isSnowPlowRoute) return `/routes/route-templates/snow-plow/${id}${isEdit ? '/edit' : ''}`;
      if (isStreetSweeperRoute) return `/routes/route-templates/street-sweeper/${id}`;
      return `/routes/route-templates/${id}${isEdit ? '/edit' : ''}`;
    };

    if (routeSequencingEnabled && hasRouteSequencePermission) {
      switch (routeSequenceStatus) {
        case PENDING: {
          if (!(await confirm(translate('routes.alertMessages.confirmCancelSequenceRequest')))) {
            break;
          }
          updateRouteSequenceStatus({
            routeid: id,
            jobId,
            status: CANCELED,
            sequenceSourceTypeId: SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE,
          })(dispatch).then(() => dispatch(push(getRedirectUrl())));
          break;
        }
        case COMPLETE:
          dispatch(push(`/routes/route-templates/${id}/route-sequence`));
          break;
        case FAILED:
          if (!(await informationPrompt(translate('routes.alertMessages.confirmFailedRouteSequenceRequest')))) {
            break;
          }
          updateRouteSequenceStatus({
            routeid: id,
            jobId,
            status: FAILED_CONFIRMED,
            sequenceSourceTypeId: SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE,
          })(dispatch).then(() => dispatch(push(getRedirectUrl())));
          break;
        default:
          dispatch(push(getRedirectUrl()));
      }
    } else dispatch(push(getRedirectUrl()));
  };

  const toggleAddToGroupsModal = () => {
    setIsAddToGroupsModalOpen(!isAddToGroupsModalOpen);
  };

  const addTemplatesToGroups = ({ groupIds }: AddToGroupsFormValues) => {
    if (groupIds.length === 0) return;

    assignTemplatesToGroups(
      selectedRouteTemplates,
      groupIds,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('routes.alertMessages.routeTemplatesAddedToGroup'));
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.routeTemplatesAddedToGroupError'));
      })
      .finally(() => {
        toggleAddToGroupsModal();
        setSelectedRouteTemplates([]);
      });
  };

  const routesTableCells = getRoutePlannerBuilderTableCells(
    size(rows) === size(selectedRouteTemplates),
    !!size(selectedRouteTemplates) && size(selectedRouteTemplates) < size(rows),
    false,
    toggleSelectAll,
  );

  const routeTemplatesToScheduleSize = size(
    filter(
      filteredRouteTemplates,
      rt => selectedRouteTemplates.includes(rt.routeTemplateId) && rt.isActive && !!rt.numberOfStops,
    ),
  );

  return (
    <>
      <PanelSectionGroup>
        <Panel isLoading={isLoading || isLoadingRouteSequenceStatus} padding="small">
          {!!size(rows) && !isLoading && (
            <PanelSection padding="xSmall no">
              <RoutesOrTemplatesSearchForm onSearchTermChange={setSearchTerm} />
            </PanelSection>
          )}
          {size(filteredRouteTemplates) ? (
            <Table
              cells={routesTableCells}
              rows={filteredRouteTemplates}
              rowComponent={RoutePlannerRouteTemplatesListingTableRow}
              scrollMarker
              virtualized
              virtualizedProps={{
                height:
                  Math.min(size(filteredRouteTemplates) * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 8) || 1,
                itemSize: TABLE_ROW_HEIGHT_SMALL,
              }}
              rowProps={{
                selectTemplate,
                deleteTemplate: onDeleteTemplate,
                scheduleTemplate: handleScheduleClick,
                onTableRowClick,
              }}
              tableHeadRowProps={{
                padding: '0 20px 0 0',
              }}
            />
          ) : (
            <NoDataMessageContainer>
              <Text>
                {!!size(rows) ? translate('routes.planner.noDataMatches') : translate('routes.planner.noData')}
              </Text>
            </NoDataMessageContainer>
          )}
        </Panel>
        {!checkIfViewOnly() && !checkIfSupport() && !!size(selectedRouteTemplates) && (
          <PanelSection centered padding="no">
            <PageFooter>
              <Button
                id="schedule-route-button"
                color="primary"
                onClick={() => handleScheduleClick()}
                margin="no small no no"
                disabled={!routeTemplatesToScheduleSize}
              >
                {`${translate('routes.schedule')} (${routeTemplatesToScheduleSize})`}
              </Button>
              <Button id="delete-planner-button" color="alert" line onClick={onBulkDelete} margin="no small no no">
                {`${translate('routes.planner.deleteReoccurringRoute')} (${size(selectedRouteTemplates)})`}
              </Button>
              <Button
                id="add-to-groups-button"
                color="primary"
                onClick={toggleAddToGroupsModal}
                margin="no small no no"
              >
                {`${translate('routes.groups.addToGroups')}  (${size(selectedRouteTemplates)})`}
              </Button>
            </PageFooter>
          </PanelSection>
        )}
        {isAddToGroupsModalOpen && (
          <AddToGroupsModalResolver onAddToGroups={addTemplatesToGroups} closeModal={toggleAddToGroupsModal} />
        )}
      </PanelSectionGroup>
      {isScheduleModalOpen && (
        <RoutePlannerNewSchedulerModalResolver
          templates={templatesToSchedule}
          onClose={() => {
            setIsScheduleModalOpen(false);
            setTemplatesToSchedule([]);
            setSelectedRouteTemplates([]);
          }}
        />
      )}
    </>
  );
};

export default RoutePlannerRouteTemplatesListingSection;
