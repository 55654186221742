import { formValueSelector } from 'redux-form';

import { Box } from 'src/core/components/styled/Box';
import { checkIfInferenceAuditIsEnabled } from 'src/vendors/ducks/features';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { DASHBOARD_FILTER_FORM_MAPBOX, DASHBOARD_FILTER_FORM_NAME } from '../../../constants/dashboardFilter';
import { Link } from '../../../../core/components/styled';
import { TODAY_FORMATTED } from 'src/core/constants';
import { User } from 'src/account/ducks/login';
import { useSelector } from 'src/core/hooks/useSelector';
import FloatingCollapsible from 'src/core/components/FloatingCollapsible';
import translate from '../../../../core/services/translate';

type Props = {
  auditLinksControlXOffset?: number;
};

const formSelector = formValueSelector(DASHBOARD_FILTER_FORM_NAME);
const formSelectorDashboarMapbox = formValueSelector(DASHBOARD_FILTER_FORM_MAPBOX);

export default function DashboardMapAuditLinks({ auditLinksControlXOffset }: Props) {
  const controlSpacing = useSelector(state => state.dashboard.mapControls.spacing);
  const date = useSelector(state => formSelector(state, 'date'));
  const selectedDate = useSelector(state => formSelectorDashboarMapbox(state, 'date'));
  const inferenceAuditEnabled = useSelector(checkIfInferenceAuditIsEnabled);
  const user = useSelector(state => state.vendors.users.user);
  const legendControl = useSelector(state => state.dashboard.mapControls.legend);
  const navigationControl = useSelector(state => state.dashboard.mapControls.navigation);

  const isInferenceAuditLinkVisible = (user as unknown as User).displayInferenceAudit;

  if (!inferenceAuditEnabled) {
    return null;
  }

  let xOffset = controlSpacing * 2 + navigationControl.width;

  if (legendControl.isVisible) {
    xOffset += controlSpacing + legendControl.width;
  }

  const inferenceAuditLink = `/inference-audit?date=${date || selectedDate || TODAY_FORMATTED}`;

  return isInferenceAuditLinkVisible ? (
    <ComplexMapControl
      position="bottom-left"
      normalMargin={0}
      xOffset={auditLinksControlXOffset || xOffset}
      yOffset={controlSpacing}
      zIndex={99}
    >
      <FloatingCollapsible
        isOpenByDefault
        title={translate('dashboard.audits')}
        widthWhenOpen={200}
        widthWhenClosed={150}
      >
        <Box margin="xSmall">
          {isInferenceAuditLinkVisible && (
            <Box mt={10}>
              <Link to={inferenceAuditLink}>{translate('inferenceAudit.inferenceAudit')}</Link>
            </Box>
          )}
        </Box>
      </FloatingCollapsible>
    </ComplexMapControl>
  ) : null;
}
