import React from 'react';
import { map } from 'lodash-es';
import { Field } from 'redux-form';

import {
  isRequired,
  hasCountry,
  hasCity,
  hasZip,
  maxLength50,
  maxLength10,
} from '../../../../utils/services/validator';
import { Grid, GridColumn } from '../../../../core/components/styled';
import { Input, LocationPicker, Dropdown } from '../../../../core/components';
import { FMCSA_SCORE_OPTIONS } from '../../../constants';
import translate from '../../../../core/services/translate';

const fmcsaScoreOptions = map(FMCSA_SCORE_OPTIONS, option => ({ label: option.name, value: option.id }));

const CompanyHeadquarters = () => (
  <Grid multiLine>
    <GridColumn size="12/12" padding="no">
      <Field
        name="companyHeadquarters.companyName"
        component={Input}
        label={translate('haulerProfile.companyName')}
        validate={[isRequired, maxLength50]}
      />
    </GridColumn>

    <GridColumn size="12/12" padding="no">
      <Field
        name="companyHeadquarters.address"
        component={LocationPicker}
        label={translate('common.address')}
        validate={[isRequired, hasCountry, hasCity, hasZip]}
      />
    </GridColumn>

    <GridColumn size="12/12" padding="no">
      <Field
        name="companyHeadquarters.dUNS"
        component={Input}
        label={translate('haulerProfile.dunsNumber')}
        validate={[maxLength10]}
      />
    </GridColumn>

    <GridColumn size="12/12" padding="no">
      <Field
        name="companyHeadquarters.taxID"
        component={Input}
        label={translate('haulerProfile.taxIdNumber')}
        validate={[maxLength10]}
      />
    </GridColumn>

    <GridColumn size="12/12" padding="no">
      <Field
        name="companyHeadquarters.dOT"
        component={Input}
        label={translate('haulerProfile.dotNumber')}
        validate={[maxLength10]}
      />
    </GridColumn>

    <GridColumn size="12/12" padding="no">
      <Field
        name="companyHeadquarters.fMCSAScore"
        component={Dropdown}
        label={translate('haulerProfile.fmcsaScore')}
        options={fmcsaScoreOptions}
        isClearable
      />
    </GridColumn>

    <GridColumn size="12/12" padding="no">
      <Field name="companyHeadquarters.companyWebsite" component={Input} label={translate('haulerProfile.website')} />
    </GridColumn>

    <GridColumn size="12/12" padding="no">
      <Field
        name="companyHeadquarters.eBIXCompliant"
        component={Input}
        label={translate('haulerProfile.insuranceCompliant')}
        disabled
      />
    </GridColumn>
  </Grid>
);

export default CompanyHeadquarters;
