import { map } from 'lodash-es';
import { useMemo } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import {
  ActionButtonTooltip,
  DatePicker,
  Dropdown,
  FileOpenDialogToggle,
  Input,
  TypedField,
} from 'src/core/components';
import TimePickerInput from 'src/core/components/TimePicker';
import { Button, ButtonSet, Grid, GridColumn, ModalFixedFooter, Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { VehicleDropdown } from 'src/fleet/components';
import { FuelTicket } from 'src/routes/interfaces/FuelTicket';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import { isRequired } from 'src/utils/services/validator';

interface PropsWithoutReduxForm {
  onClearFileName: () => void;
  onClose: (formPristine?: boolean) => void;
  onImageUpload: (files: any) => void;
  routeId?: string[];
  selectedFileName?: string;
  fuelTicketId?: string;
}
export interface FuelTicketEditorFormValues extends FuelTicket {
  fuelAmount: number;
  unitOfMeasureTypeId: number;
  locationId: number;
  fuelCost: number;
  fuelingDate: string;
  fuelingTime: string;
}
type Props = PropsWithoutReduxForm & InjectedFormProps<FuelTicketEditorFormValues, PropsWithoutReduxForm>;

export const FUEL_TICKET_EDITOR_FORM_NAME = 'fuelTicketEditorForm';

const FuelTicketEditorForm = ({
  change,
  onClearFileName,
  onClose,
  onImageUpload,
  handleSubmit,
  selectedFileName,
  pristine,
}: Props) => {
  const fileName = selectedFileName && selectedFileName?.split('.')[1] + '.' + selectedFileName?.split('.')[2];

  const { fuelingStations, unitsOfMeasureValues } = useSelector(state => state.routes.fuelTickets);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  const onTimeChange = (value: Date | string) => {
    change('fuelingTime', value);
  };

  const fuelingStationsOptions = useMemo(
    () =>
      map(fuelingStations, fuelingStation => ({
        label: fuelingStation.fuelingStationName,
        value: fuelingStation.id,
      })),
    [fuelingStations],
  );

  const unitsOfMeasureOptions = useMemo(
    () =>
      map(unitsOfMeasureValues, unitOfMeasure => ({
        label: unitOfMeasure.name,
        value: unitOfMeasure.id,
      })),
    [unitsOfMeasureValues],
  );

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid multiLine>
        <GridColumn size="6/12">
          <TypedField
            component={Input}
            name="fuelAmount"
            validate={[isRequired]}
            props={{
              label: translate('routes.fuelAmount'),
            }}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <TypedField
            component={Dropdown}
            name="locationId"
            validate={[isRequired]}
            props={{
              options: fuelingStationsOptions,
              label: translate('routes.fuelingLocation'),
            }}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <TypedField
            component={Dropdown}
            name="unitOfMeasureTypeId"
            validate={[isRequired]}
            props={{
              options: unitsOfMeasureOptions,
              label: translate('common.unit'),
            }}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <TypedField
            component={Input}
            name="fuelCost"
            validate={[isRequired]}
            props={{
              label: translate('routes.fuelCost'),
            }}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <TypedField
            component={DatePicker}
            name="fuelingDate"
            validate={[isRequired]}
            props={{
              label: translate('routes.fuelDate'),
            }}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <TypedField
            component={TimePickerInput}
            name="fuelingTime"
            validate={[isRequired]}
            props={{
              label: translate('routes.fuelTime'),
              onTimeChange: (value: Date | string) => onTimeChange(value),
            }}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <TypedField
            name="vehicleId"
            component={VehicleDropdown}
            props={{
              vehicleTypeId: routeSummary?.vehicleTypeId,
              withLabel: true,
              dropdownProps: { isClearable: true },
              shouldGroupByAvailability: true,
              menuPosition: 'fixed',
            }}
            validate={[isRequired]}
          />
        </GridColumn>
        {!!fileName ? (
          <GridColumn size="6/12">
            <Text weight="medium" size="small" margin="no">
              {fileName}
            </Text>

            <Button id="clear-image-button" text color="primary" padding="no" onClick={() => onClearFileName()}>
              <ActionButtonTooltip icon="cancelClear" tooltip="clear" />{' '}
            </Button>
          </GridColumn>
        ) : (
          <GridColumn size="6/12">
            <Text block size="small">
              {translate('common.images')}
            </Text>

            <FileOpenDialogToggle
              margin="no xSmall no no"
              icon="upload"
              accept=".jpg"
              onFilesSelected={onImageUpload}
            />
          </GridColumn>
        )}
      </Grid>
      <ModalFixedFooter padding="sMedium small" justifyContent="space-around">
        <ButtonSet align="center" margin="no small">
          <Button type="submit" color="primary">
            {translate('common.save')}
          </Button>
          <Button type="button" margin="no small" color="secondary" onClick={() => onClose(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </ModalFixedFooter>
    </form>
  );
};

export default reduxForm<FuelTicketEditorFormValues, PropsWithoutReduxForm>({
  form: FUEL_TICKET_EDITOR_FORM_NAME,
  onSubmitFail: focusFirstInvalidField,
})(FuelTicketEditorForm);
