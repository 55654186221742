import { FC, useMemo } from 'react';
import TravelPathRouteSegmentsGLSource from './TravelPathRouteSegmentsGLSource';
import { useSelector } from 'src/core/hooks/useSelector';
import { getTravelPathRouteSegmentsGeoJSON } from '../../utils';

interface Props {
  map: mapboxgl.Map;
  isSnowPlow: boolean;
}

const TravelPathRouteSegmentsGL: FC<Props> = ({ map, isSnowPlow }) => {
  const { routeSegments } = useSelector(state => state.routes.travelPathBuildAndEdit);

  const geoJSON = useMemo(() => {
    return getTravelPathRouteSegmentsGeoJSON(routeSegments, isSnowPlow);
  }, [isSnowPlow, routeSegments]);

  return (
    <>
      <TravelPathRouteSegmentsGLSource geoJSON={geoJSON} />
    </>
  );
};

export default TravelPathRouteSegmentsGL;
