import { http } from '../../core/services/http';

export const loadBinSensorSettings = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/configurations/binsensor`).then(response => {
    return response.data;
  });

export const saveBinSensorSettings = (configurationId: number, payload: any) =>
  http.put(`/configurations/${configurationId}/binsensor`, payload).then(response => {
    return response.data;
  });
