import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import { StreetNetworkPropertiesTravelPath } from 'src/routes/interfaces/TravelPath';

export const STREET_NETWORK_OPTIONS_SELECTED_SOURCE = 'streetNetworkOptionsSelectedSource';
export const STREET_NETWORK_OPTIONS_SELECTED_LAYER = 'streetNetworkOptionsSelectedLayer';
export const STREET_NETWORK_OPTIONS_SELECTED_ARROWS_SOURCE = 'streetNetworkOptionsSelectedArrowsSource';
export const STREET_NETWORK_OPTIONS_SELECTED_ARROWS_LAYER = 'streetNetworkOptionsSelectedArrowsLayer';
export const STREET_NETWORK_OPTIONS_SELECTED_ARROWS_ON_LINE_LAYER = 'streetNetworkOptionsSelectedArrowsOnLineLayer';
export const STREET_NETWORK_OPTIONS_SELECTED_SEQUENCE_LAYER = 'streetNetworkOptionsSelectedSequenceLayer';

interface Props {
  streetNetworkOptionsSelected: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>;
  streetNetworkOptionsSelectedArrowsPoints: GeoJSON.FeatureCollection<GeoJSON.Point, GeoJSON.GeoJsonProperties>;
}

const StreetNetworkOptionsSelectedGLSource: React.FC<Props> = ({
  streetNetworkOptionsSelected,
  streetNetworkOptionsSelectedArrowsPoints,
}) => {
  return (
    <>
      <Source type="geojson" data={streetNetworkOptionsSelected} id={STREET_NETWORK_OPTIONS_SELECTED_SOURCE}>
        {/* solid lines */}
        <Layer
          id={STREET_NETWORK_OPTIONS_SELECTED_LAYER}
          type="line"
          source={STREET_NETWORK_OPTIONS_SELECTED_SOURCE}
          layout={{
            'line-cap': 'round',
            'line-join': 'round',
          }}
          paint={{
            'line-color': [
              'case',
              ['get', 'isStartingSegment'],
              '#008000',
              ['get', 'isEndingSegment'],
              'red',
              '#1E90FF',
            ],
            'line-width': 7,
            'line-opacity': 1,
          }}
        />
        <Layer
          id={STREET_NETWORK_OPTIONS_SELECTED_ARROWS_ON_LINE_LAYER}
          type="symbol"
          source={STREET_NETWORK_OPTIONS_SELECTED_SOURCE}
          minzoom={15}
          layout={{
            'symbol-placement': 'line-center',
            'text-field': '▶',
            'text-size': ['interpolate', ['linear'], ['zoom'], 12, 20, 22, 40],
            'text-keep-upright': false,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
          }}
          paint={{
            'text-color': [
              'case',
              ['get', 'isStartingSegment'],
              '#008000',
              ['get', 'isEndingSegment'],
              'red',
              '#1E90FF',
            ],
            'text-halo-color': 'hsl(55, 11%, 96%)',
            'text-halo-width': 2,
          }}
        />
        {/* sequence number */}
        <Layer
          id={STREET_NETWORK_OPTIONS_SELECTED_SEQUENCE_LAYER}
          type="symbol"
          source={STREET_NETWORK_OPTIONS_SELECTED_SOURCE}
          minzoom={17}
          filter={['==', 'isInterconnectingStreets', false]}
          layout={{
            'symbol-placement': 'line',
            'text-field': ['get', 'sequence'],
            'text-size': ['interpolate', ['linear'], ['zoom'], 12, 12, 22, 16],
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'text-keep-upright': true,
          }}
          paint={{
            'text-color': [
              'case',
              ['get', 'isStartingSegment'],
              '#008000',
              ['get', 'isEndingSegment'],
              'red',
              '#1E90FF',
            ],
            'text-halo-color': 'hsl(55, 11%, 96%)',
            'text-halo-width': 3,
          }}
        />
      </Source>
      <Source
        type="geojson"
        data={streetNetworkOptionsSelectedArrowsPoints}
        id={STREET_NETWORK_OPTIONS_SELECTED_ARROWS_SOURCE}
      >
        <Layer
          id={STREET_NETWORK_OPTIONS_SELECTED_ARROWS_LAYER}
          type="symbol"
          minzoom={15}
          filter={['==', 'isInterconnectingStreets', false]}
          source={STREET_NETWORK_OPTIONS_SELECTED_ARROWS_SOURCE}
          layout={{
            'symbol-placement': 'point',
            'text-field': '▶',
            'text-anchor': 'center',
            'text-size': ['interpolate', ['linear'], ['zoom'], 12, 20, 22, 40],
            'text-keep-upright': false,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'text-rotate': ['get', 'rotate'],
          }}
          paint={{
            'text-color': [
              'case',
              ['get', 'isStartingSegment'],
              '#008000',
              ['get', 'isEndingSegment'],
              'red',
              '#1E90FF',
            ],
            'text-halo-color': 'hsl(55, 11%, 96%)',
            'text-halo-width': 2,
          }}
        />
      </Source>
    </>
  );
};

export default memo(StreetNetworkOptionsSelectedGLSource);
