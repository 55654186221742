import styled from 'styled-components';

import { TableRow, TableCell } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { PaymentAttachment } from 'src/routes/interfaces/PaymentAttachment';

interface ComponentProps extends PaymentAttachment {}

const Link = styled.a`
  color: ${p => p.theme.brandPrimary};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const PaymentAttachmentsTableRow: React.FC<ComponentProps> = ({ fileUrl, fileName }) => {
  return (
    <TableRow height={TABLE_ROW_HEIGHT_LARGE}>
      <TableCell width={'100%'} padding="defaultCellVertical xSmall">
        <Link href={fileUrl} target="_blank" rel="noopener noreferrer">
          {fileName}
        </Link>
      </TableCell>
    </TableRow>
  );
};
