import React, { PureComponent } from 'react';

import { reduce } from 'lodash-es';

import { Modal, Table } from '../../../core/components';
import { ModalSection } from '../../../core/components/styled';
import IssuesReportedModalTableRow from './IssuesReportedModalTableRow';
import translate from '../../../core/services/translate';
import { ContainerInsights, ContainerInsightSubType } from 'src/routes/components/mapWithTimeline/Interfaces';

interface Props {
  issues: ContainerInsights;
  closeModal: () => void;
}

const routeLocationIssueTableCells = [
  { name: 'date', label: translate('common.timestamp'), width: '15%' },
  { name: 'pickupIssueReportedReasonType', label: translate('common.issue'), width: '20%' },
  { name: 'detail', label: translate('common.detail'), width: '20% ' },
  { name: 'source', label: translate('common.source'), width: '17% ' },
  { name: 'options', label: translate('common.options'), width: '15%' },
  { name: 'images', label: translate('common.images'), width: '13%' },
];

class IssuesReportedModal extends PureComponent<Props> {
  render() {
    const {
      issues: {
        address,
        customerName,
        customerEmail,
        insightSubTypes,
        locationName,
        reportDateTime,
        vehicle: { name },
        vehicleTypeId,
        routeLocationId,
        route: { id },
      },
      closeModal,
    } = this.props;

    const issues = reduce(
      insightSubTypes,
      (issuesList: ContainerInsightSubType[] = [], insightSubType, key: number) => {
        if (key > 0) {
          issuesList.push(insightSubType);
        }
        return issuesList;
      },
      [],
    );

    return (
      <Modal subTitle={locationName} title={customerName} onClose={closeModal} padding="medium no no">
        <ModalSection margin="medium no no">
          <Table
            cells={routeLocationIssueTableCells}
            rows={issues}
            rowComponent={IssuesReportedModalTableRow}
            rowProps={{
              routeLocationId,
              routeId: id,
              customerEmail,
              address,
              customerName,
              vehicleTypeId,
              vehicleName: name,
              reportDateTime,
            }}
          />
        </ModalSection>
      </Modal>
    );
  }
}

export default IssuesReportedModal;
