import { FC } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { RateCodes, RateConfigurationEditFormValues } from 'src/finance/interfaces/RateManager';
import { Table } from 'src/core/components';
import RateConfigurationEditTableRow from '../pages/rateManager/RateConfigurationEditTableRow';

type PropsWithoutReduxForm = {
  filteredRateCodes: RateCodes[];
  rowScrollTopPosition: number;
  tableCells: any;
  virtualizedProps: any;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<RateConfigurationEditFormValues[], PropsWithoutReduxForm>;

export const RATE_CONFIGURATION_EDIT_FORM_NAME = 'rateConfigurationEditForm';

const RateConfigurationEditForm: FC<Props> = ({
  filteredRateCodes,
  handleSubmit,
  rowScrollTopPosition,
  tableCells,
  virtualizedProps,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Table
        cells={tableCells}
        rows={filteredRateCodes}
        rowComponent={RateConfigurationEditTableRow}
        rowProps={{}}
        virtualizedProps={virtualizedProps}
        virtualized
        scrollMarker
        rowScrollTopPosition={rowScrollTopPosition}
      />
    </form>
  );
};

export default reduxForm<RateConfigurationEditFormValues[], PropsWithoutReduxForm>({
  form: RATE_CONFIGURATION_EDIT_FORM_NAME,
  enableReinitialize: true,
})(RateConfigurationEditForm);
