import React, { useEffect } from 'react';

import { Modal } from '../../core/components';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  ModalFooter,
  ModalSection,
  ModalTitle,
} from '../../core/components/styled';

interface Props {
  action?: (event: any) => void;
  actionText?: string;
  closeModal: () => void;
  message: string | JSX.Element;
  size?: string;
  timeOut?: number;
  title: string;
}

const ConfirmationModal: React.FC<Props> = props => {
  const { action, actionText, closeModal, message, size, timeOut, title } = props;

  useEffect(() => {
    if (timeOut) {
      const timer = setTimeout(() => {
        closeModal();
      }, timeOut);
      return () => clearTimeout(timer);
    }
  }, [closeModal, timeOut]);

  return (
    <Modal onClose={closeModal} padding="medium" size={size}>
      <ModalTitle noTransform>{title}</ModalTitle>
      <ModalSection align="center">{typeof message === 'string' ? <p>{message}</p> : message}</ModalSection>

      {action && actionText && (
        <ModalFooter padding="sMedium no no">
          <Grid>
            <GridColumn size="12/12">
              <ButtonSet margin="no">
                <Button type="submit" color="primary" onClick={action}>
                  {actionText}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        </ModalFooter>
      )}
    </Modal>
  );
};

ConfirmationModal.defaultProps = {
  size: 'xSmall',
};

export default ConfirmationModal;
