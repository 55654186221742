import { FC, useMemo } from 'react';
import { filter, find } from 'lodash-es';
import { useDispatch } from 'react-redux';

import { clearRouteMapSelectedFeature } from 'src/routes/ducks';
import { getMeasurementType } from 'src/dashboard/hooks/useLoadDataForMapboxDashboard';
import { isOnDemandVideoDownloadFeatureEnabled } from 'src/vendors/ducks/features';
import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { RouteVehicleCoord } from 'src/dashboard/interfaces/routesData';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import TravelPathVehicleBreadcrumbsPopup from './TravelPathVehicleBreadcrumbsPopup';

const TravelPathVehicleBreadcrumbsPopupResolver: FC = () => {
  const dispatch = useDispatch();

  const vehicleTrackingsForRoute = useSelector(state => state.routes.travelPathBuildAndEdit.routeVehicleBreadCrumbs);
  const { selectedFeatureForTravelPath } = useSelector(state => state.routes.mapControls);

  const systemOfMeasurementId = useSelector(state => state.vendors.vendor.vendor.systemOfMeasurementId);
  const isOnDemandVideoDownloadEnabled = useSelector(isOnDemandVideoDownloadFeatureEnabled);

  const selectedTracking = useMemo(() => {
    if (
      !selectedFeatureForTravelPath ||
      selectedFeatureForTravelPath.vehicleTrackingGroupIndex === undefined ||
      selectedFeatureForTravelPath.coordinates === undefined
    ) {
      return undefined;
    }

    const { vehicleTrackingGroupIndex: groupIndex, id } = selectedFeatureForTravelPath;

    let group: RouteVehicleCoord[] = [];

    const filtered = filter(vehicleTrackingsForRoute?.vehicles, v => v.id === id && v.coords.length > 0);
    if (filtered.length > 0) {
      group = filtered[0].coords[groupIndex];
    }

    if (!vehicleTrackingsForRoute || !group) {
      return undefined;
    }

    // get the closest coordinate to the selected point
    const closestCoordinate = group.reduce((prev, curr) => {
      if (!selectedFeatureForTravelPath.coordinates) return prev;

      const prevDistance = Math.sqrt(
        Math.pow(prev.lat - selectedFeatureForTravelPath.coordinates.lat, 2) +
          Math.pow(prev.lng - selectedFeatureForTravelPath.coordinates.lng, 2),
      );
      const currDistance = Math.sqrt(
        Math.pow(curr.lat - selectedFeatureForTravelPath.coordinates.lat, 2) +
          Math.pow(curr.lng - selectedFeatureForTravelPath.coordinates.lng, 2),
      );

      return prevDistance < currDistance ? prev : curr;
    }, [] as any);

    const vehicleForRoute = find(vehicleTrackingsForRoute?.vehicles, { id });

    return {
      vehicle: vehicleForRoute?.name || '',
      id: selectedFeatureForTravelPath.id,
      selectedData: closestCoordinate,
      shouldDisplayDownloadVideo: vehicleForRoute?.hdv,
      videoDeviceTypeId: vehicleForRoute?.vdtId,
      anchor: {
        latitude: selectedFeatureForTravelPath.coordinates.lat,
        longitude: selectedFeatureForTravelPath.coordinates.lng,
      },
    };
  }, [selectedFeatureForTravelPath, vehicleTrackingsForRoute]);

  if (!selectedTracking) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedTracking.anchor.latitude}
      longitude={selectedTracking.anchor.longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature(true))}
    >
      <TypedResolver
        key={`${selectedFeatureForTravelPath!.id}.${selectedFeatureForTravelPath!.vehicleTrackingGroupIndex}`}
        successComponent={TravelPathVehicleBreadcrumbsPopup}
        successProps={{
          showTimestamp: true,
          speed: selectedTracking.selectedData.sp,
          unitOfMeasure: getMeasurementType(systemOfMeasurementId),
          vehicleId: selectedTracking.id,
          vehicleName: selectedTracking.vehicle,
          timestamp: selectedTracking.selectedData.ts,
          shouldDisplayDownloadVideo: selectedTracking.shouldDisplayDownloadVideo && isOnDemandVideoDownloadEnabled,
          videoDeviceTypeId: selectedTracking.videoDeviceTypeId,
          deviceId: selectedTracking.selectedData.deid,
          applicationModeStatusId: selectedTracking.selectedData.am,
        }}
        loadingComponent={MapGLPopupLoading}
      />
    </MapGLPopupWrapper>
  );
};

export default TravelPathVehicleBreadcrumbsPopupResolver;
