import translate from '../services/translate';

export const FIRST_JOB_POSITION = 'First';
export const LAST_JOB_POSITION = 'Last';
export const OPTIMIZED_JOB_POSITION = 'Optimized';

export const ROUTE_RECOMMENDATION_POSITION_OPTIONS = [
  {
    value: FIRST_JOB_POSITION,
    label: translate('customers.routeRecommendations.first'),
  },
  {
    value: LAST_JOB_POSITION,
    label: translate('customers.routeRecommendations.last'),
  },
];

export const ROUTE_RECOMMENDATION_POSITION_ALL_OPTIONS = [
  ...ROUTE_RECOMMENDATION_POSITION_OPTIONS,

  {
    value: OPTIMIZED_JOB_POSITION,
    label: translate('customers.routeRecommendations.optimized'),
  },
];
