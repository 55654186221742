import { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { loadProximitySettings } from '../../ducks';
import { PanelSectionLoading } from '../../../core/components/styled';
import { Resolver } from '../../../core/components';
import ProximitySettingsPage from './ProximitySettingsPage';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  loadProximitySettings(...args: any[]): any;
  vendorId: string;
}
class ProximitySettingsPageResolver extends PureComponent<Props> {
  render() {
    const { loadProximitySettings, vendorId } = this.props;

    return (
      <Fragment>
        <DocumentTitle>{translate('settings.proximitySettings')}</DocumentTitle>
        <Resolver
          successComponent={ProximitySettingsPage}
          loadingComponent={PanelSectionLoading}
          resolve={() => loadProximitySettings(vendorId)}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadProximitySettings };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProximitySettingsPageResolver));
