import { InjectedFormProps, reduxForm } from 'redux-form';

import { TypedField } from 'src/core/components';
import { Button, ButtonSet, Grid, GridColumn, ModalFixedFooter } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { isRequired } from 'src/utils/services/validator';
import GroupsMultiSelect from '../GroupsMultiSelect';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';

interface PropsWithoutReduxForm {
  closeModal: () => void;
}

export interface AddToGroupsFormValues {
  groupIds: number[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<AddToGroupsFormValues, PropsWithoutReduxForm>;

const ADD_TO_GROUPS_FORM_NAME = 'ADD_TO_GROUPS_FORM';

const AddToGroupsForm = ({ handleSubmit, closeModal }: Props) => (
  <form onSubmit={handleSubmit}>
    <Grid>
      <GridColumn size="12/12">
        <TypedField
          component={GroupsMultiSelect}
          name="groupIds"
          validate={[isRequired]}
          props={{
            withLabel: true,
            excludeInactive: true,
            multiSelectProps: {
              defaultToAll: false,
              menuPosition: 'fixed',
              maxWidth: '320px',
              minWidth: '320px',
            },
          }}
        />
      </GridColumn>
    </Grid>
    <ModalFixedFooter padding="sMedium small" justifyContent="space-around">
      <ButtonSet align="center" margin="no small">
        <Button type="submit" color="primary">
          {translate('common.save')}
        </Button>
        <Button type="button" margin="no small" color="secondary" onClick={closeModal}>
          {translate('common.cancel')}
        </Button>
      </ButtonSet>
    </ModalFixedFooter>
  </form>
);

export default reduxForm<AddToGroupsFormValues, PropsWithoutReduxForm>({
  form: ADD_TO_GROUPS_FORM_NAME,
  onSubmitFail: focusFirstInvalidField,
  initialValues: {
    groupIds: [],
  },
})(AddToGroupsForm);
