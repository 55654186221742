import {
  SCHEDULED,
  IN_PROGRESS,
  COMPLETED,
  CANCELED,
  REASSIGNED,
  ISSUE_REPORTED,
  SERVICED,
  PLACED_ON_HOLD,
} from '../../common/constants/pickupStatuses';

export const PICKUP_STATUS_LABEL_COLORS = {
  [SCHEDULED.toString()]: 'info',
  [IN_PROGRESS.toString()]: 'warning',
  [COMPLETED.toString()]: 'success',
  [CANCELED.toString()]: 'warning',
  [REASSIGNED.toString()]: 'info',
  [ISSUE_REPORTED.toString()]: 'alert',
  [SERVICED.toString()]: 'partsuccess',
  [PLACED_ON_HOLD.toString()]: 'warning',
};
