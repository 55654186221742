import { camelCase, get, size } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import humps from 'humps';

import { ActionButtonTooltip } from 'src/core/components';
import { Box } from 'src/core/components/styled/Box';
import { clearRouteMapSelectedFeature } from 'src/routes/ducks';
import { CustomerProximitySearchModalResolver } from 'src/customers/components/modals';
import {
  DetailsListActionButton,
  DetailsListActionIcon,
  Grid,
  GridColumn,
  MapInfoImage,
  MapInfoImageWrapper,
  TableActionButton,
  Text,
} from 'src/core/components/styled';
import { getRouteStopIconType } from './utils';
import { INSIGHT_ICONS, PICKUP_STATUSES, ROUTE_PICKUP_TYPE_IDS, SCHEDULED } from 'src/common/constants';
import {
  JOB_PENDING_OPTIMIZATION_ID,
  WASTE_AUDIT_EXCEPTION_TYPES,
  WASTE_AUDIT_LOCATION_STATUS_TYPES,
} from 'src/routes/constants';
import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupTitle,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import { resetStopMapDetails } from 'src/routes/ducks/stopMapDetails';
import {
  RouteLocationImagesModalResolver,
  RouteLocationIssuesModalResolver,
  StrobeImagesModalResolver,
} from 'src/routes/components/modals';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { time } from 'src/utils/services/formatter';
import { useSelector } from 'src/core/hooks/useSelector';
import { WASTE_AUDIT_ID } from 'src/fleet/constants';
import JobPriorityTypeIndicator from 'src/routes/components/pages/common/JobPriorityTypeIndicator';
import LabeledDataView from 'src/core/components/LabeledDataView';
import StrobeImagesResolver from 'src/routes/components/pages/routes/routePageSections/routeMap/StrobeImagesResolver';
import translate from 'src/core/services/translate';

type Props = {
  newAndOptimizedStopsLength: number;
  routeStop: RouteStop;
  shouldLoadClosestStrobeImage?: boolean;
};

export default function RouteMapRouteStopsGLPopup({
  newAndOptimizedStopsLength,
  routeStop,
  shouldLoadClosestStrobeImage,
}: Props) {
  const dispatch = useDispatch();

  const [isIssueImageModalOpen, setIssueImageModalOpen] = useState(false);
  const [isStrobeImageModalOpen, setIsStrobeImageModalOpen] = useState(false);
  const [isIssuesModalOpen, setIssuesModalOpen] = useState(false);
  const [isCustomerProximitySearchModalOpen, setCustomerProximitySearchModalOpen] = useState(false);

  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { wasteAuditTypes } = useSelector(state => state.common.wasteAuditTypes);
  const { stopMapDetails } = useSelector(state => state.routes.stopMapDetails);
  const images = useSelector(state => state.routes.routeLocationImages.routeLocationImages);
  const routeLocationIssues = useSelector(state => state.routes.routeLocationIssues.routeLocationIssues);

  useEffect(
    () => () => {
      dispatch(resetStopMapDetails());
    },
    [dispatch],
  );

  if (!routeSummary) return null;
  const iconType = getRouteStopIconType(routeStop, routeSummary);
  const pickupStatusName = PICKUP_STATUSES[routeStop.pickupStatusTypeId].technicalName;
  const insightType = humps.camelize(pickupStatusName);

  const wasteAuditType = wasteAuditTypes.find(wasteAuditType => wasteAuditType.id === routeSummary.wasteAuditTypeId);

  const getAddress = () => {
    if (stopMapDetails && stopMapDetails.address) return stopMapDetails.address;
    if (routeStop && routeStop.addressForPopup) return routeStop.addressForPopup;
    return '-';
  };

  const isYRoute = false;

  const getWasteAuditStatus = () => {
    let icon = null;
    let title = '';
    if (routeSummary.vehicleTypeId !== WASTE_AUDIT_ID) return null;
    if (routeStop.wasteAuditStatuses && routeStop.wasteAuditStatuses.length === 0) {
      icon =
        routeStop.wasteAuditLocationStatusTypeId !== undefined
          ? get(WASTE_AUDIT_LOCATION_STATUS_TYPES[routeStop.wasteAuditLocationStatusTypeId], 'technicalName')
          : '';
      title =
        routeStop.wasteAuditLocationStatusTypeId !== undefined
          ? get(WASTE_AUDIT_LOCATION_STATUS_TYPES[routeStop.wasteAuditLocationStatusTypeId], 'name')
          : '';
    }
    if (routeStop.wasteAuditStatuses && routeStop.wasteAuditStatuses.length === 1) {
      icon =
        typeof routeStop.wasteAuditStatuses[0] === 'number'
          ? get(WASTE_AUDIT_EXCEPTION_TYPES[routeStop.wasteAuditStatuses[0]], 'technicalName')
          : '';
      title =
        typeof routeStop.wasteAuditStatuses[0] === 'number'
          ? get(WASTE_AUDIT_EXCEPTION_TYPES[routeStop.wasteAuditStatuses[0]], 'name')
          : '';
    }
    if (routeStop.wasteAuditStatuses && routeStop.wasteAuditStatuses.length > 1) {
      icon = 'multiple';
      title = translate(`dashboard.multiple`);
    }
    if (!icon || !title) return null;
    return (
      <Box mt={5} display="flex" alignItems="center">
        {icon && (
          <Box mr={8} width={15} height={15}>
            <img src={INSIGHT_ICONS[humps.camelize(icon)]} width="15" height="15" alt="" />
          </Box>
        )}
        {title && (
          <Text weight="medium" size="medium">
            {title}
          </Text>
        )}
      </Box>
    );
  };

  const getWasteAuditExceptionsMessages = () => {
    let messages = '';
    if (routeSummary.vehicleTypeId !== WASTE_AUDIT_ID) return messages;
    if (routeStop.wasteAuditStatuses && routeStop.wasteAuditStatuses.length) {
      routeStop.wasteAuditStatuses.forEach((status, i) => {
        if (typeof status === 'number') {
          if (
            routeStop.wasteAuditStatuses &&
            routeStop.wasteAuditStatuses.length &&
            i < routeStop.wasteAuditStatuses.length - 1
          )
            messages += `${get(WASTE_AUDIT_EXCEPTION_TYPES[status], 'name')}, `;
          else messages += get(WASTE_AUDIT_EXCEPTION_TYPES[status], 'name');
        }
      });
    }
    return messages;
  };

  const getExceptionMessages = () => {
    const messages: string[] = [];
    if (routeSummary.vehicleTypeId === WASTE_AUDIT_ID) return messages.join(', ');
    routeLocationIssues.forEach(issue => {
      if (issue.pickupExceptionType) {
        if (issue.pickupExceptionSubType)
          messages.push(
            `${translate(`routes.pickupIssueTypes.${camelCase(issue.pickupExceptionType.technicalName)}`)}: ${translate(
              `routes.pickupIssueTypes.exceptionSubTypes.${camelCase(issue.pickupExceptionSubType.technicalName)}`,
            )}`,
          );
        else {
          messages.push(translate(`routes.pickupIssueTypes.${camelCase(issue.pickupExceptionType.technicalName)}`));
        }
      }
    });
    return messages.join(', ');
  };

  const jobIsPendingOptimization = routeStop.orderNo === JOB_PENDING_OPTIMIZATION_ID || routeStop.isOptimal;

  return (
    <>
      <MapGLPopupWrapper
        closeOnClick={false}
        latitude={routeStop.displayLatitude}
        longitude={routeStop.displayLongitude}
        onClose={() => dispatch(clearRouteMapSelectedFeature())}
      >
        <MapGLPopup>
          <MapGLPopupTitle
            beforeTitleContent={
              <Box mr={8} width={15} height={15}>
                <img src={iconType?.iconUrl} width="15" height="15" alt="" />
              </Box>
            }
            title={translate(`dashboard.${insightType}`)}
            afterTitleContent={
              <>
                {!!routeStop.numberOfIssues && routeSummary.vehicleTypeId !== WASTE_AUDIT_ID && (
                  <DetailsListActionButton onClick={() => setIssuesModalOpen(true)} margin="no no no xSmall">
                    <DetailsListActionIcon icon="issue" /> {routeStop.numberOfIssues}
                  </DetailsListActionButton>
                )}
                {routeStop.wasteAuditStatuses &&
                  !!routeStop.numberOfIssues &&
                  !!routeStop.wasteAuditStatuses.length &&
                  routeSummary.vehicleTypeId === WASTE_AUDIT_ID && (
                    <DetailsListActionButton onClick={() => setIssuesModalOpen(true)} margin="no no no xSmall">
                      <DetailsListActionIcon icon="issue" /> {routeStop.numberOfIssues}
                    </DetailsListActionButton>
                  )}

                {!!routeStop.numberOfImages && (
                  <DetailsListActionButton onClick={() => setIssueImageModalOpen(true)} margin="no xSmall">
                    <DetailsListActionIcon icon="image" /> {routeStop.numberOfImages}
                  </DetailsListActionButton>
                )}
                {routeStop.jobPriorityTypeId && (
                  <Box ml={5}>
                    <JobPriorityTypeIndicator iconSize="mMedium" jobPriorityTypeId={routeStop.jobPriorityTypeId} />
                  </Box>
                )}
              </>
            }
            secondTitle={routeSummary.vehicleTypeId === WASTE_AUDIT_ID && getWasteAuditStatus()}
          />

          <MapGLPopupContent>
            <Grid>
              <GridColumn
                margin="xSmall no no no"
                size={`${
                  !!size(images) && !!size(images[0].images) && routeSummary.vehicleTypeId === WASTE_AUDIT_ID
                    ? '7/12'
                    : '12/12'
                }`}
              >
                {routeStop.pickupStatusTypeId !== SCHEDULED && (
                  <LabeledDataView
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('common.truck')}
                    value={routeStop.assistingVehicleRegplate || routeSummary.vehicleName}
                  />
                )}

                {routeStop.statusDate && (
                  <LabeledDataView
                    noWrap
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('common.timestamp')}
                    value={time(routeStop.statusDate)}
                  />
                )}

                {routeSummary.vehicleTypeId !== WASTE_AUDIT_ID && (
                  <LabeledDataView
                    noWrap
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('routes.pickupType')}
                    value={ROUTE_PICKUP_TYPE_IDS[routeStop.pickupTypeId]?.name || '-'}
                  />
                )}

                <LabeledDataView
                  noWrap
                  width="calc(100% - 10px)"
                  size="small"
                  label={translate('dashboard.stopNumber')}
                  value={jobIsPendingOptimization ? null : routeStop.orderNo + newAndOptimizedStopsLength}
                  children={jobIsPendingOptimization && <em>{translate('routes.pendingOptimization')}</em>}
                />

                <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={translate('routes.route')}>
                  {routeSummary.routeId || isYRoute ? (
                    <Box textDecoration="underline">
                      <Link
                        to={
                          isYRoute
                            ? `/routes/y-daily-routes/${routeSummary.routeDetailId}`
                            : `/routes/route-tracker/${routeSummary.routeId}`
                        }
                      >
                        {routeSummary.name}
                      </Link>
                    </Box>
                  ) : (
                    routeSummary.name
                  )}
                </LabeledDataView>

                <LabeledDataView width="calc(100% - 10px)" size="small" label={translate('common.customer')}>
                  {routeStop.customerName}

                  <TableActionButton
                    margin="no no no xSmall"
                    color="primary"
                    onClick={() => setCustomerProximitySearchModalOpen(true)}
                  >
                    <ActionButtonTooltip
                      icon="searchVehicle"
                      size="sMedium"
                      sourceIsInfoWindow
                      tooltip="customerProximitySearch"
                    />
                  </TableActionButton>
                </LabeledDataView>

                <LabeledDataView
                  width="calc(100% - 10px)"
                  size="small"
                  label={translate('common.address')}
                  value={getAddress()}
                />
                {!!getWasteAuditExceptionsMessages().length && (
                  <LabeledDataView
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('dashboard.exceptionReasons')}
                    value={getWasteAuditExceptionsMessages() || ''}
                  />
                )}
                {!!getExceptionMessages().length && (
                  <LabeledDataView
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('dashboard.exceptionReasons')}
                    value={getExceptionMessages() || ''}
                  />
                )}

                {shouldLoadClosestStrobeImage &&
                  routeSummary.vehicleTypeId !== WASTE_AUDIT_ID &&
                  routeStop.pickupStatusTypeId !== SCHEDULED && (
                    <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={translate('common.images')}>
                      <StrobeImagesResolver
                        vehicleId={routeStop.vehicleId}
                        timestamp={routeStop.statusDate}
                        setIsStrobeImageModalOpen={setIsStrobeImageModalOpen}
                      />
                    </LabeledDataView>
                  )}
              </GridColumn>
              {!!size(images) && !!size(images[0].images) && routeSummary.vehicleTypeId === WASTE_AUDIT_ID && (
                <GridColumn verticalAlign="center" size="5/12">
                  <MapInfoImageWrapper onClick={() => setIssueImageModalOpen(true)} width="100%">
                    <MapInfoImage src={images[0].images[0].url} onClick={() => setIssueImageModalOpen(true)} />
                  </MapInfoImageWrapper>
                </GridColumn>
              )}
            </Grid>
          </MapGLPopupContent>
        </MapGLPopup>
      </MapGLPopupWrapper>

      {isIssueImageModalOpen && (
        <RouteLocationImagesModalResolver
          routeId={routeSummary.routeId}
          routeLocationId={routeStop.id}
          closeModal={() => setIssueImageModalOpen(false)}
        />
      )}

      {isStrobeImageModalOpen && (
        <StrobeImagesModalResolver
          vehicleId={routeStop.vehicleId}
          vehicleName={routeStop.assistingVehicleRegplate || routeSummary.vehicleName}
          timeStamp={routeStop.statusDate}
          closeModal={() => setIsStrobeImageModalOpen(false)}
        />
      )}

      {isIssuesModalOpen && (
        <RouteLocationIssuesModalResolver
          modalTitle={routeStop.customerName}
          modalSubTitle={routeStop.locationName}
          routeId={routeSummary.routeId}
          routeLocationId={routeStop.id}
          closeModal={() => setIssuesModalOpen(false)}
          wasteAuditType={wasteAuditType}
          vehicleTypeId={routeSummary.vehicleTypeId}
        />
      )}

      {isCustomerProximitySearchModalOpen && (
        <CustomerProximitySearchModalResolver
          defaultDate={routeStop.statusDate}
          locationId={routeStop.id}
          onCancel={() => setCustomerProximitySearchModalOpen(false)}
        />
      )}
    </>
  );
}
