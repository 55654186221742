import React, { ChangeEvent, PureComponent } from 'react';

import { find, get, map, size } from 'lodash-es';
import { connect } from 'react-redux';
import { WrappedFieldInputProps } from 'redux-form';

import { TechnicalType } from '../../common/interfaces/TechnicalType';
import { MultiSelect, TypedField } from '../../core/components';
import { MultiSelectProps } from '../../core/components/MultiSelect';
import translate from '../../core/services/translate';
import { vehicleTypesForVendorWithRoleTypeSelector } from '../../fleet/ducks';
import { AppState } from '../../store';
import {
  DAILY,
  DISPOSAL_TONS,
  DRIVER_SAFETY_INSPECTION,
  EXCEPTIONS,
  FAULT_CODES,
  INSIGHT_HISTORY,
  ODAKYU_DAILY_ROUTE_SUMMARY,
  PARTICIPATIONS,
  PRE_POST_TRIP_INSPECTION,
  ROLL_OF_SERVICE_HISTORY,
  ROUTE_EXCEPTION,
  ROUTE_SUMMARY,
  SAFETY,
  SET_OUT,
  STREET_SWEEPING_ACTIVITY_SUMMARY,
  STREET_SWEEPING_OBSTACLES,
  UNSERVICED_RESIDENTIAL,
  Y_DEVICE_STATUS,
} from '../constants/reportTypes';
import { CART, FRONT_LOAD, RESIDENTIAL, ROLL_OFF, STREET_SWEEPER, WASTE_AUDIT } from '../constants/vehicleTypes';

interface ComponentProps {
  disabled?: boolean;
  excludeVehicleTypes: any[];
  input: WrappedFieldInputProps;
  label?: string;
  multiSelectProps?: Partial<MultiSelectProps>;
  placeholder?: string;
  reportType?: string;
  vehicleFiltersPreferencesName: string[];
  vehicleRoleTypeId: number;
  withLabel?: boolean;
  withPlaceholder?: boolean;
  withTechnicalName?: boolean;
}

interface Props extends ComponentProps {
  vehicleTypesForVendor?: TechnicalType[];
}

class VehicleTypesForVendorMultiSelect extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const { input, reportType, vehicleFiltersPreferencesName, disabled } = this.props;
    if (reportType !== prevProps.reportType) {
      input.onChange(disabled ? null : vehicleFiltersPreferencesName);
    }
  }

  onChange = (event: ChangeEvent<HTMLInputElement>, value: any) => {
    this.props.input.onChange(value);
  };

  formatText = (selectedOptions: any[], allOptionsSelected: boolean) => {
    const { vehicleTypesForVendor } = this.props;

    if (allOptionsSelected) {
      return translate('vehicles.allVehicleTypes');
    }

    return size(selectedOptions) === 1
      ? get(
          find(vehicleTypesForVendor, vehicleType => vehicleType.technicalName === selectedOptions[0]),
          'name',
        )
      : translate('vehicles.xVehicleTypesSelected', { selected: size(selectedOptions) });
  };

  isVehicleTypeDisabled = (reportType: string, vehicleTypeForVendorName: string) => {
    switch (reportType) {
      case ROUTE_SUMMARY:
        return vehicleTypeForVendorName === STREET_SWEEPER || vehicleTypeForVendorName === WASTE_AUDIT;
      case ROUTE_EXCEPTION:
        return vehicleTypeForVendorName === STREET_SWEEPER || vehicleTypeForVendorName === WASTE_AUDIT;
      case DAILY:
        return vehicleTypeForVendorName === STREET_SWEEPER || vehicleTypeForVendorName === WASTE_AUDIT;
      case INSIGHT_HISTORY:
        return vehicleTypeForVendorName === STREET_SWEEPER || vehicleTypeForVendorName === WASTE_AUDIT;
      case DISPOSAL_TONS:
        return vehicleTypeForVendorName === STREET_SWEEPER || vehicleTypeForVendorName === WASTE_AUDIT;
      case SAFETY:
        return vehicleTypeForVendorName === STREET_SWEEPER || vehicleTypeForVendorName === WASTE_AUDIT;
      case STREET_SWEEPING_ACTIVITY_SUMMARY:
        return (
          vehicleTypeForVendorName === CART ||
          vehicleTypeForVendorName === FRONT_LOAD ||
          vehicleTypeForVendorName === RESIDENTIAL ||
          vehicleTypeForVendorName === ROLL_OFF ||
          vehicleTypeForVendorName === WASTE_AUDIT
        );
      case STREET_SWEEPING_OBSTACLES:
        return (
          vehicleTypeForVendorName === CART ||
          vehicleTypeForVendorName === FRONT_LOAD ||
          vehicleTypeForVendorName === RESIDENTIAL ||
          vehicleTypeForVendorName === ROLL_OFF ||
          vehicleTypeForVendorName === WASTE_AUDIT
        );
      case PARTICIPATIONS:
        return (
          vehicleTypeForVendorName === CART ||
          vehicleTypeForVendorName === FRONT_LOAD ||
          vehicleTypeForVendorName === RESIDENTIAL ||
          vehicleTypeForVendorName === ROLL_OFF ||
          vehicleTypeForVendorName === STREET_SWEEPER
        );
      case SET_OUT:
        return (
          vehicleTypeForVendorName === CART ||
          vehicleTypeForVendorName === FRONT_LOAD ||
          vehicleTypeForVendorName === RESIDENTIAL ||
          vehicleTypeForVendorName === ROLL_OFF ||
          vehicleTypeForVendorName === STREET_SWEEPER
        );
      case EXCEPTIONS:
        return (
          vehicleTypeForVendorName === CART ||
          vehicleTypeForVendorName === FRONT_LOAD ||
          vehicleTypeForVendorName === RESIDENTIAL ||
          vehicleTypeForVendorName === ROLL_OFF ||
          vehicleTypeForVendorName === STREET_SWEEPER
        );
      case FAULT_CODES:
        return vehicleTypeForVendorName === WASTE_AUDIT;
      case UNSERVICED_RESIDENTIAL:
        return (
          vehicleTypeForVendorName === CART ||
          vehicleTypeForVendorName === FRONT_LOAD ||
          vehicleTypeForVendorName === ROLL_OFF ||
          vehicleTypeForVendorName === STREET_SWEEPER ||
          vehicleTypeForVendorName === WASTE_AUDIT
        );
      case ROLL_OF_SERVICE_HISTORY:
        return (
          vehicleTypeForVendorName === CART ||
          vehicleTypeForVendorName === FRONT_LOAD ||
          vehicleTypeForVendorName === RESIDENTIAL ||
          vehicleTypeForVendorName === STREET_SWEEPER ||
          vehicleTypeForVendorName === WASTE_AUDIT
        );
      case PRE_POST_TRIP_INSPECTION:
      case DRIVER_SAFETY_INSPECTION:
        return vehicleTypeForVendorName === STREET_SWEEPER || vehicleTypeForVendorName === WASTE_AUDIT;
      case ODAKYU_DAILY_ROUTE_SUMMARY:
        return true;
      case Y_DEVICE_STATUS:
        return true;
      default:
        return false;
    }
  };

  render() {
    const {
      disabled,
      input: { name },
      label,
      multiSelectProps,
      placeholder,
      reportType = '',
      vehicleTypesForVendor,
      withLabel,
      withPlaceholder,
      withTechnicalName,
    } = this.props;

    const vehicleTypesOptions = map(vehicleTypesForVendor, vehicleType => ({
      label: vehicleType.name,
      value: withTechnicalName ? vehicleType.technicalName : vehicleType.id,
      isDisabled: this.isVehicleTypeDisabled(reportType, vehicleType.name),
    }));

    return (
      <TypedField
        name={name}
        component={MultiSelect}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('vehicles.vehicleType') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('vehicles.vehicleType') : undefined),
          options: vehicleTypesOptions,
          formatText: this.formatText,
          reinitializeKey: reportType,
          disabled: disabled,
          ...multiSelectProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  vehicleTypesForVendor: (vehicleTypesForVendorWithRoleTypeSelector as any)(
    state.fleet.vehicleTypesForVendor,
    ownProps.vehicleRoleTypeId,
    ownProps.excludeVehicleTypes,
  ),
});

export default connect(mapStateToProps)(VehicleTypesForVendorMultiSelect);
