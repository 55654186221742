import { map } from 'lodash-es';
import { ChangeEvent, FC, useMemo } from 'react';
import { WrappedFieldInputProps } from 'redux-form';

import { Dropdown, TypedField } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { DropDownFieldProps } from './DropDownFieldProps';

interface Props extends DropDownFieldProps {
  input: WrappedFieldInputProps;
}

const JobPriorityTypesDropdown: FC<Props> = ({
  input,
  dropdownProps,
  label,
  placeholder,
  withLabel,
  withPlaceholder,
}) => {
  const { jobPriorityTypes } = useSelector(state => state.common.jobPriorityTypes);

  const options = useMemo(
    () =>
      map(jobPriorityTypes, jobPriorityType => ({
        label: jobPriorityType.name,
        value: jobPriorityType.id,
      })),
    [jobPriorityTypes],
  );

  const onChange = (event: ChangeEvent, value: number) => {
    input.onChange(value);
  };

  return (
    <TypedField
      name={input.name}
      component={Dropdown}
      label={label || (withLabel ? translate('common.jobPriority') : undefined)}
      placeholder={placeholder || (withPlaceholder ? translate('common.jobPriority') : undefined)}
      options={options}
      onChange={onChange}
      isClearable
      {...dropdownProps}
    />
  );
};

export default JobPriorityTypesDropdown;
