import { http } from '../../core/services/http';
import { SearchServiceRatesContracts } from '../interfaces/RateBuilder';
import { RateBuilderRate } from '../interfaces/Services';

export const saveServiceRatesContracts = (vendorId: number, serviceContractIds: number[], rates: RateBuilderRate[]) => {
  return http
    .post('rateBuilder/saveServiceContracts', {
      vendorId,
      serviceContractIds,
      rates,
    })
    .then(response => {
      return response.data;
    });
};

export const loadServiceRatesContracts = ({
  vendorId,
  serviceTypeId,
  equipmentTypeIds,
  equipmentSizeIds,
  numberOfContainers,
  pickupFrequencyTypeIds,
  wasteMaterialTypeIds,
  accountStatusTypeIds,
  includedRateCodeIds,
  excludedRateCodeIds,
  citySearchTerm,
  stateSearchTerm,
  zipSearchTerm,
  accountStatusId,
}: SearchServiceRatesContracts) =>
  http
    .post('rateBuilder/searchServiceContracts', {
      vendorId,
      serviceTypeId,
      ...(equipmentTypeIds?.length ? { equipmentTypeIds } : {}),
      ...(equipmentSizeIds?.length ? { equipmentSizeIds } : {}),
      ...(numberOfContainers?.length ? { numberOfContainers: numberOfContainers.map(i => Number(i)) } : {}),
      ...(pickupFrequencyTypeIds?.length ? { pickupFrequencyTypeIds } : {}),
      ...(wasteMaterialTypeIds?.length ? { wasteMaterialTypeIds } : {}),
      ...(accountStatusTypeIds?.length ? { accountStatusTypeIds } : {}),
      ...(includedRateCodeIds?.length ? { includedRateCodeIds } : {}),
      ...(excludedRateCodeIds?.length ? { excludedRateCodeIds } : {}),
      ...(citySearchTerm ? { citySearchTerm } : {}),
      ...(stateSearchTerm ? { stateSearchTerm } : {}),
      ...(zipSearchTerm ? { zipSearchTerm } : {}),
      ...(accountStatusId ? { accountStatusId } : {}),
    })
    .then(response => response.data)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
