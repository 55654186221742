import React, { PureComponent, Fragment } from 'react';
import { find, map } from 'lodash-es';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';

// import { vendorPaymentMethodTypes } from '../../ducks';
import { isRequired } from '../../../utils/services/validator';
import { Dropdown, InlineEditor, TextArea } from '../../../core/components';
import { Container, ButtonSet, Button, Text } from '../../../core/components/styled';
// import { PERCENT } from '../../../opportunity/constants';
import translate from '../../../core/services/translate';
import recurringServicesInitialValueSelector from '../../services/recurringServicesInitialValueSelector';
import { AppState } from '../../../store';
// import { InputMask } from '../../../opportunity/components/styled';

interface ComponentProps {
  workOrder: number;
  workOrderIdentifier: string;
  onCancel(...args: any[]): any;
}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
  response: any;
  rateCodesState: any;
  issueType: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class ReportIssueProcessedServicesForm extends PureComponent<Props> {
  render() {
    const {
      handleSubmit,
      onCancel,
      pristine,
      //   paymentMethods,
      //   paymentInfo,

      workOrderIdentifier,
      workOrder,
      //   rateCodes,
      //   rateCodesState,
      issueType,
    } = this.props;

    const issueTypes = map(issueType, issueTypeOption => ({
      label: issueTypeOption.label,
      value: issueTypeOption.value,
    }));

    return (
      <form onSubmit={handleSubmit} noValidate>
        <Container>
          <Text block margin="large small large" weight="medium" size="small" singleLine>
            {translate('payment.workOrder')}# : {workOrder}
          </Text>
        </Container>
        <Container>
          <Field
            name={`workOrdersInfo.settings${workOrderIdentifier}.issueType`}
            component={Dropdown}
            options={issueTypes}
            label={translate('payment.selectIssueType')}
            validate={[isRequired]}
            margin="small small small"
          />
        </Container>

        <Container>
          <Fragment>
            <Field
              name={`workOrdersInfo.settings${workOrderIdentifier}.issueDescription`}
              component={InlineEditor}
              isInEditMode
              inputComponent={TextArea}
              inputProps={{ placeholder: translate('payment.enterDescriptionHere'), margin: 'large small small' }}
              validate={[isRequired]}
              rows="3"
            />
          </Fragment>
        </Container>
        <ButtonSet>
          <Button type="submit" color="primary">
            {translate('opportunity.submit')}
          </Button>
          <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

const formSelector = formValueSelector('acceptRecurringServices');

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const workOrdersInfo = find(state.payments.processedServices.workOrdersInfo, {
    workOrderIdentifier: ownProps.workOrderIdentifier,
  }) as any;

  return {
    initialValues: recurringServicesInitialValueSelector(state.payments.processedServices.workOrdersInfo),
    response: formSelector(state, `workOrdersInfo.settings${ownProps.workOrderIdentifier}.response`),
    // rateCodes: formSelector(state, `workOrdersInfo.settings${ownProps.workOrderIdentifier}.rateCodes`),
    rateCodesState: workOrdersInfo.rateCodes,
    issueType: state.payments.processedServices.issueType,
    // paymentMethods: ownProps.workOrdersInfo.paymentMethods,
    // paymentInfo: vendorPaymentMethodTypes(
    //   state.opportunity.opportunity.paymentInfo.vendorPayments,
    //   formSelector(state, `opportunities.settings${ownProps.caseId}.requestedChanges.paymentMethod`),
    // ),
  };
};

export default connect(mapStateToProps)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'reportIssueProcessedServices',
  })(ReportIssueProcessedServicesForm),
);
