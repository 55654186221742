import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { PaymentStatusOptions } from '../interfaces/ApplyChecks';

import { loadPaymentStatuses as doLoadPaymentStatuses } from '../services/paymentStatuses';

// Actions
const START_LOAD = 'finance/applyChecks/paymentStatuses/START_LOAD';
const COMPLETE_LOAD = 'finance/applyPayemnts/paymentStatuses/COMPLETE_LOAD';
const FAIL_LOAD = 'finance/applyChecks/paymentStatuses/FAIL_LOAD';
const RESET = 'finance/applyChecks/paymentStatuses/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  paymentStatuses: PaymentStatusOptions[];
} = {
  isLoading: false,
  paymentStatuses: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentStatuses: action.paymentStatuses,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentStatuses: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (paymentStatuses: any) => ({
  type: COMPLETE_LOAD,
  paymentStatuses,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadPaymentStatuses = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadPaymentStatusesPromise = doLoadPaymentStatuses();
  loadPaymentStatusesPromise
    .then((paymentStatuses: any) => dispatch(completeLoad(paymentStatuses)))
    .catch(() => dispatch(failLoad()));
  return loadPaymentStatusesPromise;
};

export const resetPaymentStatuses = () => ({
  type: RESET,
});
