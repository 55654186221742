import { useEffect, useMemo } from 'react';

import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { changeMobilePageDetailOverlayTitleContent, closeMobilePageDetailOverlay } from '../ducks/mobilePage';
import { useSelector } from '../hooks/useSelector';
import { fadeAndSlideInLeftFn } from '../styles/animations';
import Icon from './Icon';
import { Button } from './styled/Button';

const MobileListDetailViewOverlayContainer = styled.div`
  position: fixed;
  top: 0;
  background: white;
  z-index: 6001;
  overflow-y: scroll;
  height: 100vh;
  width: 100vw;
  animation: ${fadeAndSlideInLeftFn('0')} 0.3s ease-out;
`;

const HeaderContainer = styled.div`
  position: fixed;
  padding: 0 15px;
  top: 0;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  box-shadow: 0 2px 4px rgba(36, 40, 51, 0.1);
`;

const ContentContainer = styled.div`
  padding: 100px 30px 120px;
`;

const BackButton = styled(Button)`
  display: flex;
  margin-right: 10px;
  align-items: center;
`;

export const MobileListDetailViewOverlay: React.FC<{ onClose?: () => void }> = ({ onClose, children }) => {
  const containerElement = useMemo(() => document.createElement('div'), []);

  const dispatch = useDispatch();

  const close = onClose || (() => closeMobilePageDetailOverlay()(dispatch));

  const detailOverlayTitleContent = useSelector(s => s.core.mobilePage.detailOverlayTitleContent);

  useEffect(() => {
    document.body.appendChild(containerElement);
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.removeChild(containerElement);
      document.body.style.overflowY = 'initial';
      changeMobilePageDetailOverlayTitleContent(undefined)(dispatch);
    };
  }, [containerElement, dispatch]);

  return ReactDOM.createPortal(
    <MobileListDetailViewOverlayContainer>
      <HeaderContainer>
        <BackButton text onClick={close}>
          <Icon width="24px" icon="arrowLeft" />
        </BackButton>
        {detailOverlayTitleContent || null}
      </HeaderContainer>
      <ContentContainer>{children}</ContentContainer>
    </MobileListDetailViewOverlayContainer>,
    containerElement,
  );
};

export default MobileListDetailViewOverlay;
