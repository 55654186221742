import { size } from 'lodash-es';
import { CONTAINER_TYPE_ROLL_OFF_ID } from 'src/common/constants';

import { Grid, GridColumn, Message, ModalSection, Separator, Text } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { Modal, Table } from '../../../core/components';
import translate from '../../../core/services/translate';
import ContainerActivityTableRow from './ContainerActivityTableRow';

interface Props {
  containerId?: number;
  containerTypeId?: number;
  closeModal: () => void;
}

const ContainerActivityModal: React.FC<Props> = ({ closeModal, containerTypeId }) => {
  const modalTitle = translate('containers.containerActivity');
  const containerActivity = useSelector(state => state.fleet.containerActivity.containerActivity);

  if (!containerActivity) return null;

  const containerActivityTableCells = [
    {
      name: 'date',
      label: translate('common.date'),
      width: '15%',
      padding: 'defaultCellVertical',
      sortable: true,
    },
    {
      name: 'activityType',
      label: translate('containers.activity'),
      width: '20%',
      padding: 'defaultCellVertical',
      sortable: true,
    },
    {
      name: 'locationType.name',
      label: translate('common.locationType'),
      width: '15%',
      padding: 'defaultCellVertical',
      sortable: true,
    },
    {
      name: 'customerName',
      label: translate('common.location'),
      width: '35%',
      padding: 'defaultCellVertical',
      sortable: true,
    },
    {
      name: 'daysSince',
      label: translate('containers.daysSince'),
      width: '15%',
      padding: 'defaultCellVertical',
      sortable: true,
    },
  ];

  const virtualizedProps = {
    height: Math.min(size(containerActivity.containerActivities) * TABLE_ROW_HEIGHT, TABLE_ROW_HEIGHT * 6) || 1,
    itemSize: TABLE_ROW_HEIGHT,
  };
  return (
    <Modal padding="medium" size="large" title={modalTitle} onClose={closeModal}>
      <ModalSection padding="medium">
        <Grid>
          <GridColumn size="3/12" multiLine>
            <Text block margin="small no">
              {translate('containers.containerNumber')}
            </Text>
            <Text block weight="medium">
              {containerActivity.containerNumber}
            </Text>
          </GridColumn>
          <GridColumn size="3/12" multiLine>
            <Text block margin="small no">
              {translate('containers.sinceLastActivity')}
            </Text>
            <Text block weight="medium">
              {translate('containers.xDays', { days: containerActivity.daysSinceLastActivity })}
            </Text>
          </GridColumn>
          {containerTypeId === CONTAINER_TYPE_ROLL_OFF_ID && (
            <GridColumn size="3/12" multiLine>
              <Text block margin="small no">
                {translate('containers.noOfTurnsInLast30Days')}
              </Text>
              <Text block weight="medium">
                {containerActivity.turnsInLast30Days}
              </Text>
            </GridColumn>
          )}
        </Grid>
      </ModalSection>

      <ModalSection padding="medium no">
        {!!size(containerActivity.containerActivities) ? (
          <Table
            cells={containerActivityTableCells}
            noOverflow
            rowComponent={ContainerActivityTableRow}
            rowProps={{
              totalRows: size(containerActivity.containerActivities),
              rowHeight: TABLE_ROW_HEIGHT,
            }}
            rows={containerActivity.containerActivities}
            scrollMarker
            virtualized
            virtualizedProps={virtualizedProps}
            withClickableRows
          />
        ) : (
          <>
            <Separator />
            <Message>{translate('containers.noActivity')}</Message>
          </>
        )}
      </ModalSection>
    </Modal>
  );
};

export default ContainerActivityModal;
