import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import { Switch } from '../../../core/components';
import { HolidayPlannerSettings } from '../../interfaces/HolidayPlanner';
import { HolidayPlannerSettingsWrapper } from '../styled/HolidayPlanner';
import translate from '../../../core/services/translate';
import { Text } from '../../../core/components/styled';

type Props = InjectedFormProps<HolidayPlannerSettings>;

const HolidayPlannerSettingsForm: React.FC<Props> = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <HolidayPlannerSettingsWrapper>
        <Text block weight="medium" align="left">
          {translate('routes.holidayPlanner.globalSettings')}
        </Text>

        <Field
          name="useBusinessDays"
          component={Switch}
          props={{
            label: translate('routes.holidayPlanner.useBusinessDays'),
            margin: 'small no no',
          }}
        />
      </HolidayPlannerSettingsWrapper>
    </form>
  );
};

export default reduxForm<HolidayPlannerSettings>({
  form: 'holidayPlannerSettings',
  enableReinitialize: true,
})(HolidayPlannerSettingsForm);
