import update from 'immutability-helper';
import { get, identity } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { GetStateFunction } from '../../contracts/ducks';
import {
  clearAiModelConfiguration,
  createVehicle as doCreateVehicle,
  loadBinColors as doLoadBinColors,
  loadVehicleFacilites as doLoadVehicleFacilites,
  loadVehicle as doLoadVehicle,
  loadVendorAiModelConfiguration,
  updateVehicle as doUpdateVehicle,
  updateVehicleConfig as doUpdateVehicleConfig,
  checkVehiclesHaveRoutesInRange as doCheckVehiclesHaveRoutesInRange,
} from '../services/vehicle';
import { Facility } from 'src/common/interfaces/Facility';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

// Actions
const START_LOAD = 'vendor/vehicle/START_LOAD';
export const COMPLETE_LOAD = 'vendor/vehicle/COMPLETE_LOAD';
const FAIL_LOAD = 'vendor/vehicle/FAIL_LOAD';
const START_SAVE = 'vendor/vehicle/START_SAVE';
export const COMPLETE_VEHICLE_SAVE = 'vendor/vehicle/COMPLETE_VEHICLE_SAVE';
const COMPLETE_MODEL_CONFIG_SAVE = 'vendor/vehicle/COMPLETE_MODEL_CONFIG_SAVE';
const FAIL_SAVE = 'vendor/vehicle/FAIL_SAVE';
const START_LOAD_AI_MODEL = 'vendor/vehicle/START_LOAD_AI_MODEL';
const COMPLETE_LOAD_AI_MODEL = 'vendor/vehicle/COMPLETE_LOAD_AI_MODEL';
const FAIL_LOAD_AI_MODEL = 'vendor/vehicle/FAIL_LOAD_AI_MODEL';
const START_CLEAR_AI_MODEL = 'vendor/vehicle/START_CLEAR_AI_MODEL';
const COMPLETE_CLEAR_AI_MODEL = 'vendor/vehicle/COMPLETE_CLEAR_AI_MODEL';
const FAIL_CLEAR_AI_MODEL = 'vendor/vehicle/FAIL_CLEAR_AI_MODEL';
const RESET = 'vendor/vehicle/RESET';
const START_LOAD_BIN_COLORS = 'vendor/vehicle/START_LOAD_BIN_COLORS';
const COMPLETE_LOAD_BIN_COLORS = 'vendor/vehicle/COMPLETE_LOAD_BIN_COLORS';
const FAIL_LOAD_BIN_COLORS = 'vendor/vehicle/FAIL_LOAD_BIN_COLORS';
const START_LOAD_VEHICLE_FACILITIES = 'vendor/vehicle/START_LOAD_VEHICLE_FACILITIES';
const COMPLETE_LOAD_VEHICLE_FACILITIES = 'vendor/vehicle/COMPLETE_LOAD_VEHICLE_FACILITIES';
const FAIL_LOAD_VEHICLE_FACILITIES = 'vendor/vehicle/FAIL_LOAD_VEHICLE_FACILITIES';
const START_CHECK_VEHICLES_HAVE_ROUTES_IN_RANGE = 'vendor/vehicle/START_CHECK_VEHICLES_HAVE_ROUTES_IN_RANGE';
const COMPLETE_CHECK_VEHICLES_HAVE_ROUTES_IN_RANGE = 'vendor/vehicle/COMPLETE_CHECK_VEHICLES_HAVE_ROUTES_IN_RANGE';
const FAIL_CHECK_VEHICLES_HAVE_ROUTES_IN_RANGE = 'vendor/vehicle/FAIL_CHECK_VEHICLES_HAVE_ROUTES_IN_RANGE';

// Initial state =
const initialState: any = {
  binColors: [],
  isCheckingVehiclesHaveRoutesInRange: false,
  isLoading: false,
  isSaving: false,
  vehicle: undefined,
  vehicleFacilities: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicle: action.vehicle,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicle: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_VEHICLE_SAVE:
      return {
        ...state,
        isSaving: false,
        vehicle: action.vehicle,
      };

    case COMPLETE_MODEL_CONFIG_SAVE:
      return {
        ...state,
        aIModelConfiguration: action.vehicle,
        isSaving: false,
      };

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_LOAD_AI_MODEL:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_AI_MODEL:
      return update(state, {
        vehicle: { vendorAiModelTypes: { $set: action.aiModelTypes } },
        $merge: {
          isLoading: false,
        },
      });

    case FAIL_LOAD_AI_MODEL:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_CLEAR_AI_MODEL:
      return update(state, {
        $merge: {
          isClearingAiModel: true,
        },
      });

    case COMPLETE_CLEAR_AI_MODEL:
      return update(state, {
        vehicle: { vendorAiModelTypes: { $set: undefined } },
        $merge: {
          isClearingAiModel: false,
        },
      });

    case FAIL_CLEAR_AI_MODEL:
      return update(state, {
        $merge: {
          isClearingAiModel: false,
        },
      });

    case START_LOAD_BIN_COLORS:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_BIN_COLORS:
      return update(state, {
        $merge: {
          binColors: action.binColors,
          isLoading: false,
        },
      });

    case FAIL_LOAD_BIN_COLORS:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_LOAD_VEHICLE_FACILITIES:
      return update(state, {
        $merge: {
          isLoadingVehicleFacilities: true,
        },
      });

    case COMPLETE_LOAD_VEHICLE_FACILITIES:
      return update(state, {
        $merge: {
          isLoadingVehicleFacilities: false,
          vehicleFacilities: action.vehicleFacilities,
        },
      });

    case FAIL_LOAD_VEHICLE_FACILITIES:
      return update(state, {
        $merge: {
          isLoadingVehicleFacilities: false,
          driverFacilities: undefined,
        },
      });

    case START_CHECK_VEHICLES_HAVE_ROUTES_IN_RANGE:
      return update(state, {
        $merge: {
          isCheckingVehiclesHaveRoutesInRange: true,
        },
      });

    case COMPLETE_CHECK_VEHICLES_HAVE_ROUTES_IN_RANGE:
      return update(state, {
        $merge: {
          isCheckingVehiclesHaveRoutesInRange: false,
        },
      });

    case FAIL_CHECK_VEHICLES_HAVE_ROUTES_IN_RANGE:
      return update(state, {
        $merge: {
          isCheckingVehiclesHaveRoutesInRange: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehicle: any) => ({
  type: COMPLETE_LOAD,
  vehicle,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeModelConfigSave = (vehicle: any) => ({
  type: COMPLETE_MODEL_CONFIG_SAVE,
  vehicle,
});

const completeVehicleSave = (vehicle: any) => ({
  type: COMPLETE_VEHICLE_SAVE,
  vehicle,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoadAiModel = () => ({
  type: START_LOAD_AI_MODEL,
});

const completeLoadAiModel = (aiModelTypes: any) => ({
  type: COMPLETE_LOAD_AI_MODEL,
  aiModelTypes,
});

const failLoadAiModel = () => ({
  type: FAIL_LOAD_AI_MODEL,
});

const startClearAiModel = () => ({
  type: START_CLEAR_AI_MODEL,
});

const completeClearAiModel = (vehicle: any) => ({
  type: COMPLETE_CLEAR_AI_MODEL,
  vehicle,
});

const failClearAiModel = () => ({
  type: FAIL_CLEAR_AI_MODEL,
});

const startLoadBinColors = () => ({
  type: START_LOAD_BIN_COLORS,
});

const completeLoadBinColors = (binColors: TechnicalType[]) => ({
  type: COMPLETE_LOAD_BIN_COLORS,
  binColors,
});

const failLoadBinColors = () => ({
  type: FAIL_LOAD_BIN_COLORS,
});

const startLoadVehicleFacilites = () => ({
  type: START_LOAD_VEHICLE_FACILITIES,
});

const completeLoadVehicleFacilites = (vehicleFacilities: Facility[]) => ({
  type: COMPLETE_LOAD_VEHICLE_FACILITIES,
  vehicleFacilities,
});

const failLoadVehicleFacilites = () => ({
  type: FAIL_LOAD_VEHICLE_FACILITIES,
});

const startCheckVehiclesHaveRoutesInRange = () => ({
  type: START_CHECK_VEHICLES_HAVE_ROUTES_IN_RANGE,
});

const completeCheckVehiclesHaveRoutesInRange = () => ({
  type: COMPLETE_CHECK_VEHICLES_HAVE_ROUTES_IN_RANGE,
});

const failCheckVehiclesHaveRoutesInRange = () => ({
  type: FAIL_CHECK_VEHICLES_HAVE_ROUTES_IN_RANGE,
});

export const loadVehicle = (vehicleId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadVehiclePromise = doLoadVehicle(vehicleId);
  loadVehiclePromise.then(vehicle => dispatch(completeLoad(vehicle))).catch(() => dispatch(failLoad()));
  return loadVehiclePromise;
};

export const loadVehicleFacilites = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadVehicleFacilites());
  const loadVehicleFacilitesPromise = doLoadVehicleFacilites(vendorId);
  loadVehicleFacilitesPromise
    .then((vehicleFacilities: Facility[]) => dispatch(completeLoadVehicleFacilites(vehicleFacilities)))
    .catch(() => dispatch(failLoadVehicleFacilites()));
  return loadVehicleFacilitesPromise;
};

export const loadAiModel = (vehicleId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadAiModel());
  const loadAiModelPromise = loadVendorAiModelConfiguration(vehicleId);
  loadAiModelPromise
    .then(aiModelTypes => dispatch(completeLoadAiModel(aiModelTypes)))
    .catch(() => dispatch(failLoadAiModel()));
  return loadAiModelPromise;
};

export const loadBinColors = () => (dispatch: Dispatch) => {
  dispatch(startLoadBinColors());
  const loadBinColorsPromise = doLoadBinColors();
  loadBinColorsPromise
    .then(binColors => dispatch(completeLoadBinColors(binColors)))
    .catch(() => dispatch(failLoadBinColors()));
  return loadBinColorsPromise;
};

export const clearAiModel = (vehicleId: number) => (dispatch: Dispatch) => {
  dispatch(startClearAiModel());
  const clearAiModelPromise = clearAiModelConfiguration(vehicleId);
  clearAiModelPromise
    .then(vehicle => dispatch(completeClearAiModel(vehicle)))
    .catch(() => dispatch(failClearAiModel()));
  return clearAiModelPromise;
};

export const saveVehicleModelConfig =
  (modelConfigurations: any, commands: any) => (dispatch: Dispatch, getState: GetStateFunction) => {
    const vehicle = getState().fleet.vehicle.vehicle.vendorAiModelTypes;
    const newVehicle = { ...vehicle, modelConfigurations, commands };

    dispatch(startSave());
    const saveVehiclePromise = doUpdateVehicleConfig(newVehicle);
    saveVehiclePromise
      .then(({ data }) => dispatch(completeModelConfigSave(data)))
      .catch(() => {
        dispatch(failSave());
      });

    return saveVehiclePromise;
  };

export const saveVehicle = (data: any) => (dispatch: Dispatch, getState: GetStateFunction) => {
  const state = getState() as any;
  const vendorId =
    state.account.login &&
    state.account.login.user &&
    state.account.login.user.vendor &&
    state.account.login.user.vendor.id
      ? state.account.login.user.vendor.id
      : state.vendors.defaultVendor.defaultVendor.id;
  const newData = data.id ? data : { ...data, vendorId };

  let binColors = [];
  if (data.binColors.length && Object.prototype.hasOwnProperty.call(data.binColors[0], 'id')) {
    binColors = data.binColors;
  } else {
    data.binColors.forEach((colorId: any) => {
      binColors.push({ id: colorId });
    });
  }

  dispatch(startSave());

  const saveVehiclePromise = data.id
    ? doUpdateVehicle({ ...data, binColors })
    : doCreateVehicle({ ...newData, binColors });
  saveVehiclePromise
    .then(({ data }) => dispatch(completeVehicleSave(data)))
    .catch(() => {
      dispatch(failSave());
    });

  return saveVehiclePromise;
};

export const checkVehiclesHaveRoutesInRange =
  (vendorId: number, vehicleIds: number[], startDate?: Date | string, endDate?: Date | string) =>
  (dispatch: Dispatch) => {
    dispatch(startCheckVehiclesHaveRoutesInRange());
    const checkVehiclesHaveRoutesInRangePromise = doCheckVehiclesHaveRoutesInRange(
      vendorId,
      vehicleIds,
      startDate,
      endDate,
    );
    checkVehiclesHaveRoutesInRangePromise
      .then(() => dispatch(completeCheckVehiclesHaveRoutesInRange()))
      .catch(() => dispatch(failCheckVehiclesHaveRoutesInRange()));
    return checkVehiclesHaveRoutesInRangePromise;
  };

export const resetVehicle = () => ({
  type: RESET,
});

// Selectors

export const modelConfigurationSelector = (state: any) =>
  state.fleet.vehicle.vehicle.vendorAiModelTypes && state.fleet.vehicle.vehicle.vendorAiModelTypes.modelConfigurations;

export const selectCommands = (state: any) =>
  state.fleet.vehicle.vehicle.vendorAiModelTypes && state.fleet.vehicle.vehicle.vendorAiModelTypes.commands;

const getAiModelTypes = (vehicleState: any) => get(vehicleState, 'aiModelTypes', undefined);
export const aiModelTypesSelector = createSelector(getAiModelTypes, identity);

const getVendorAiModelTypes = (vehicleState: any) => get(vehicleState, 'vendorAiModelTypes', undefined);
export const vendorAiModelTypesSelector = createSelector(getVendorAiModelTypes, identity);

const getVehicleId = (vehicleState: any) => get(vehicleState, 'id', undefined);
export const vehicleIdSelector = createSelector(getVehicleId, identity);
