import { camelCase } from 'lodash-es';
import humps from 'humps';
import React, { useState } from 'react';

import { ActionButtonTooltip, UnconnectedDaysOfWeekPicker, PopoverWrapper } from '../../../../core/components';
import { ACTIVE, ON_HOLD } from '../../../constants/serviceStatuses';
import {
  Circle,
  DetailsListBodySection,
  Popover,
  TableActionButton,
  TableCell,
  TableRow,
  Text,
} from 'src/core/components/styled';
import { date } from '../../../../utils/services/formatter';
import { getFrequencyTranslated } from '../../forms/EditWorkOrderForm';
import { LinkContainer } from 'src/routes/components/styled';
import { RubiconServicesIconImage } from 'src/fleet/components/styled/RubiconDispatches';
import { SERVICE_TYPE_ICONS } from 'src/customers/constants';
import AddNewWorkOrderModalResolver from '../../modals/AddNewWorkOrderModalResolver';
import EditWorkOrderModalResolver from '../../modals/EditWorkOrderModalResolver';
import RubiconServicesDocumentListModalResolver from '../../modals/RubiconServicesDocumentListModalResolver';
import RubiconServicesRatesModalResolver from '../../modals/RubiconServiceRatesModalResolver';
import RubiconServicesTicketStatusModalResolver from '../../modals/RubiconServicesTicketStatusModalResolver';
import TicketConfirmationModalResolver from '../../modals/TicketConfirmationModalResolver';
import translate from 'src/core/services/translate';

interface Props {
  containerCount?: number;
  custId: string;
  customerName: string;
  destinationName: string;
  disposalRate?: number;
  disposalRateDescription?: string;
  divisionName: string;
  equipmentName: string;
  equipmentSize: string;
  equipmentType: string;
  freqDescr?: string;
  frequencyDays?: string;
  hasDocuments?: any;
  hasHaulerTicket: boolean;
  haulingRate?: number;
  haulingRateDescription?: string;
  isTemp: string;
  material: string;
  refreshPageOnCloseModal: (isRefreshPage?: boolean) => void;
  serviceStatus: string;
  siteAddress: string;
  siteCity: string;
  siteCode: string;
  siteID: string;
  siteName: string;
  siteState: string;
  startDate: string;
  endDate: string;
  svcID: number;
  vendID: string;
}

export const RubiconServicesTableRow: React.FC<Props> = ({
  containerCount,
  custId,
  customerName,
  destinationName,
  disposalRate,
  disposalRateDescription,
  divisionName,
  equipmentName,
  equipmentSize,
  equipmentType,
  freqDescr,
  frequencyDays,
  hasDocuments,
  hasHaulerTicket,
  haulingRate,
  haulingRateDescription,
  isTemp,
  material,
  refreshPageOnCloseModal,
  serviceStatus,
  siteAddress,
  siteCity,
  siteCode,
  siteID,
  siteName,
  siteState,
  startDate,
  endDate,
  svcID,
  vendID,
}) => {
  const serviceHistory = {
    containerCount,
    custId,
    customerName,
    disposalRate,
    disposalRateDescription,
    divisionName,
    equipmentName,
    equipmentSize,
    equipmentType,
    freqDescr,
    frequencyDays,
    hasDocuments,
    hasHaulerTicket,
    haulingRate,
    haulingRateDescription,
    material,
    serviceStatus,
    siteAddress,
    siteCity,
    siteCode,
    siteID,
    siteName,
    siteState,
    svcID,
    vendID,
  };
  const [isDocumentListModalOpen, toggleIsDocumentListModalOpen] = useState(false);
  const [isRubiconRatesModalOpen, toggleIsRubiconRatesModalOpen] = useState(false);
  const [isAddNewWorkOrderModalOpen, toggleIsAddNewWorkOrderModalOpen] = useState(false);
  const [isEditWorkOrderModalOpen, toggleIsEditWorkOrderModalOpen] = useState(false);
  const [isTicketStatusModalOpen, toggleIsTicketStatusModalOpen] = useState(false);
  const [isTicketConfirmationModalOpen, toggleIsTicketConfirmationModalOpen] = useState(false);
  const [ticketConfirmationValues, setTicketConfirmationValues] = useState({
    addNewWorkOrderSettings: null,
    dataCorrectionResponse: null,
  });

  const color = serviceStatus === ACTIVE ? 'success' : serviceStatus === ON_HOLD ? 'alert' : 'grayDarker';
  const status =
    serviceStatus === ACTIVE
      ? translate(`common.accountStatuses.active`)
      : serviceStatus === ON_HOLD
      ? translate('common.accountStatuses.onHold')
      : translate('dashboard.inactive');

  const image = SERVICE_TYPE_ICONS[humps.camelize(equipmentName.split('.').join(''))]
    ? SERVICE_TYPE_ICONS[humps.camelize(equipmentName.split('.').join(''))]
    : SERVICE_TYPE_ICONS['unknown'];
  const locationAddress = `${siteAddress}, ${siteCity}, ${siteState}`;

  const closeTicketConfirmationModal = () => {
    toggleIsTicketConfirmationModalOpen(false);

    if (ticketConfirmationValues?.addNewWorkOrderSettings || ticketConfirmationValues?.dataCorrectionResponse) {
      refreshPageOnCloseModal(true);
    }
  };
  const serviceType = isTemp === 'Y' ? 'T' : 'P';
  return (
    <>
      {isDocumentListModalOpen && (
        <RubiconServicesDocumentListModalResolver
          closeModal={() => toggleIsDocumentListModalOpen(false)}
          serviceNumber={svcID}
        ></RubiconServicesDocumentListModalResolver>
      )}
      <TableRow>
        <TableCell width="18%" padding="defaultCellVertical no no small">
          <RubiconServicesIconImage src={image} />
          <DetailsListBodySection flex overflow="hidden" padding="no no no small">
            <Text block weight="light" size="small" singleLine>
              {translate('customers.serviceId')}
            </Text>
            <Text block weight="medium" size="small" singleLine>
              {svcID}
              <PopoverWrapper
                triggerButton={<Circle color={color} />}
                popoverContent={<Popover>{status}</Popover>}
                width="15px"
                size="xSmall"
                margin="no xSmall"
              />
              ({serviceType})
            </Text>
            <Text block weight="light" size="small" singleLine>
              {translate('common.vendor')} {translate('haulerProfile.division')}
            </Text>
            <Text block weight="medium" size="small" singleLine>
              {divisionName}
            </Text>
          </DetailsListBodySection>
        </TableCell>
        <TableCell vertical align="center" width="20%">
          <Text block weight="medium" size="small" singleLine>
            {customerName}
          </Text>
          <Text block weight="medium" singleLine>
            {siteCode}
          </Text>
          <Text block weight="medium" size="small" singleLine>
            {siteAddress}
          </Text>
          <Text block weight="medium" size="small" singleLine>
            {siteCity}, {siteState}
          </Text>
        </TableCell>
        <TableCell vertical align="center" width="16%">
          <Text block weight="medium" size="small" singleLine>
            {translate(`haulerProfile.equipments.equipmentSubTypes.${equipmentType}`)}
          </Text>
          <Text block weight="medium" size="small" singleLine>
            {equipmentSize}
          </Text>
          <Text block weight="medium" size="small" singleLine>
            {material}
          </Text>
          {!!destinationName && (
            <Text block margin="xSmall no xSmall" weight="medium" size="small">
              {translate('opportunity.opportunities.endDestination')}: {destinationName}
            </Text>
          )}
        </TableCell>
        <TableCell align-items="left" vertical align="center" width="18%">
          <Text block weight="medium" size="small" singleLine>
            {getFrequencyTranslated(freqDescr)}
          </Text>
          <Text block weight="medium" size="small" singleLine>
            {containerCount} {translate('customers.containers')}
          </Text>
          <UnconnectedDaysOfWeekPicker
            selectedWeekdays={frequencyDays?.split('')}
            isReadOnly
            multiple
            dayOfWeekProps={{ margin: "no xSmall no no'" }}
            daysOfWeekProps={{ margin: 'no' }}
          />
          {!!startDate && (
            <Text block margin="xSmall no xSmall" weight="medium" size="small" singleLine>
              {translate('customers.serviceStart')}: {date(startDate)}
            </Text>
          )}
          {!!endDate && (
            <Text block margin="xSmall no xSmall" weight="medium" size="small" singleLine>
              {translate('customers.serviceEnd')}: {date(endDate)}
            </Text>
          )}
        </TableCell>
        <TableCell vertical align="center" width="12%">
          {haulingRate && (
            <Text block weight="medium" size="small" singleLine>
              {translate(`opportunity.haulingRates.${camelCase(haulingRateDescription)}`)}: {haulingRate}
            </Text>
          )}
          {disposalRate && (
            <Text
              block
              weight="medium"
              size="small"
              singleLine
              title={`${translate(`opportunity.haulingRates.${camelCase(disposalRateDescription)}`)}: ${disposalRate}`}
            >
              {translate(`opportunity.haulingRates.${camelCase(disposalRateDescription)}`)}: {disposalRate}
            </Text>
          )}

          <LinkContainer margin="small no no no" onClick={() => toggleIsRubiconRatesModalOpen(true)}>
            <Text align="center" block weight="medium" color="primary" size="small">
              {translate('customers.viewRates')}
            </Text>
          </LinkContainer>
        </TableCell>
        <TableCell width="12%">
        {hasDocuments === 'true' && (  
        <TableActionButton margin="xSmall no no" onClick={() => toggleIsDocumentListModalOpen(true)}>
              <ActionButtonTooltip
                customViewBox="0 0 32 20"
                size="medium"
                icon="redeye"
                color="primary"
                tooltip="viewDocument"
              />
          </TableActionButton> 
         )}  
          <TableActionButton margin="small no no" onClick={() => toggleIsAddNewWorkOrderModalOpen(true)}>
            <ActionButtonTooltip
              customViewBox="0 0 26 26"
              size="medium"
              icon="requestedNote"
              color="primary"
              tooltip="addNewWorkOrder"
            />
          </TableActionButton>
          <TableActionButton margin="xSmall no no" onClick={() => toggleIsEditWorkOrderModalOpen(true)}>
            <ActionButtonTooltip size="sMedium" icon="changeRequest" color="primary" tooltip="requestChange" />
          </TableActionButton>
          {hasHaulerTicket && (
            <TableActionButton margin="xSmall no no" onClick={() => toggleIsTicketStatusModalOpen(true)}>
              <PopoverWrapper
                triggerButton={<Circle color={'alert'} />}
                popoverContent={<Popover>{translate('customers.rubiconService.changeRequestSubmitted')}</Popover>}
                width="20px"
                size="small"
                margin="no xSmall"
              />
            </TableActionButton>
          )}
        </TableCell>
      </TableRow>

      {isAddNewWorkOrderModalOpen && (
        <AddNewWorkOrderModalResolver
          closeModal={() => toggleIsAddNewWorkOrderModalOpen(false)}
          setTicketConfirmationValues={(values: any) => {
            setTicketConfirmationValues(values);
          }}
          isRubiconServicesPage={true}
          serviceId={svcID}
          serviceStatus={serviceStatus}
          serviceHistory={serviceHistory}
          toggleTicketConfirmation={() => {
            toggleIsTicketConfirmationModalOpen(true);
          }}
        ></AddNewWorkOrderModalResolver>
      )}

      {isEditWorkOrderModalOpen && (
        <EditWorkOrderModalResolver
          closeModal={() => toggleIsEditWorkOrderModalOpen(false)}
          setTicketConfirmationValues={(values: any) => {
            setTicketConfirmationValues(values);
          }}
          isRubiconServicesPage={true}
          serviceId={svcID}
          serviceStatus={serviceStatus}
          serviceHistory={serviceHistory}
          toggleTicketConfirmation={() => {
            toggleIsTicketConfirmationModalOpen(true);
          }}
        ></EditWorkOrderModalResolver>
      )}

      {isRubiconRatesModalOpen && (
        <RubiconServicesRatesModalResolver
          closeModal={() => toggleIsRubiconRatesModalOpen(false)}
          serviceId={svcID}
          serviceStatus={serviceStatus}
        ></RubiconServicesRatesModalResolver>
      )}

      {isTicketConfirmationModalOpen && (
        <TicketConfirmationModalResolver
          closeModal={closeTicketConfirmationModal}
          values={ticketConfirmationValues}
        ></TicketConfirmationModalResolver>
      )}

      {isTicketStatusModalOpen && (
        <RubiconServicesTicketStatusModalResolver
          closeModal={() => toggleIsTicketStatusModalOpen(false)}
          customerName={customerName}
          location={locationAddress}
          serviceId={svcID}
          serviceStatus={serviceStatus}
        ></RubiconServicesTicketStatusModalResolver>
      )}
    </>
  );
};
