import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { ActionButtonTooltip, UnconnectedCheckbox } from '../../../core/components';
import { DRIVER, DRIVER_SUPERVISOR, SUPERVISOR } from '../../constants/resourceRoleTypes';
import { FLEET_DRIVERS_DELETE, FLEET_DRIVERS_EDIT } from '../../../account/constants';
import { PermissionGuard } from '../../../account/components';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { TableActionButton, TableCell, TableRow, Text } from '../../../core/components/styled';
import { TechnicalType } from '../../../common/interfaces/TechnicalType';
import translate from '../../../core/services/translate';

interface Props {
  checked: boolean;
  driverLicenseNumber?: string;
  hasDriverSafetyInspections: boolean;
  id: number;
  isActiveBoolean: boolean;
  isAvailable: boolean;
  isNotSupportAndIsNotViewOnly: boolean;
  locationId?: number;
  locationName?: string;
  name: string;
  onCheckChange: (event: any, id: number) => void;
  onDeleteResource: (resourceId: number) => void;
  phoneNumber?: string;
  resourceRoleType: TechnicalType;
}

const ResourcesPageTableRow: React.FC<Props> = ({
  checked,
  driverLicenseNumber,
  hasDriverSafetyInspections,
  id,
  isActiveBoolean,
  isAvailable,
  isNotSupportAndIsNotViewOnly,
  locationId,
  locationName,
  name,
  onCheckChange,
  onDeleteResource,
  phoneNumber,
  resourceRoleType: role,
}) => {
  const dispatch = useDispatch();
  const supervisorEnabled = useSelector(supervisorExperienceFeatureIsEnabled);

  const onEditDriver = () => dispatch(push(`/fleet/resources/${id}/edit`));

  const handleDriverInspectionLog = () => dispatch(push(`/fleet/resources/${id}/driverInspectionLog`));

  return (
    <TableRow height={TABLE_ROW_HEIGHT_SMALL}>
      <TableCell width={isNotSupportAndIsNotViewOnly ? '5%' : '0'} justifyContent="center">
        {isNotSupportAndIsNotViewOnly && (
          <UnconnectedCheckbox onChange={event => onCheckChange(event, id)} checked={checked} size="small" />
        )}
      </TableCell>

      <TableCell width="23%">{name}</TableCell>

      <TableCell width="18%">{driverLicenseNumber || '-'}</TableCell>

      <TableCell width="14%">{phoneNumber || '-'}</TableCell>

      <TableCell width="14%">
        {supervisorEnabled && (
          <>
            {(role.id === DRIVER || role.id === DRIVER_SUPERVISOR) && (
              <ActionButtonTooltip
                color="grayDarker"
                icon="steeringWheel"
                iconSize="mMedium"
                tooltipAsString
                tooltip={translate('common.driver')}
                margin="no small no no"
              />
            )}

            {(role.id === SUPERVISOR || role.id === DRIVER_SUPERVISOR) && (
              <ActionButtonTooltip
                color="grayDarker"
                icon="supervisor"
                iconSize="mMedium"
                tooltipAsString
                tooltip={translate('dashboard.supervisor')}
              />
            )}
          </>
        )}
      </TableCell>

      <TableCell width="10%" wrap="wrap">
        {isActiveBoolean ? translate('common.active') : translate('common.inactive')}
        <br />
        <Text size="small" color={isAvailable ? 'success' : 'alert'}>
          {isAvailable ? translate('common.available') : translate('common.unavailable')}
        </Text>
      </TableCell>

      <TableCell width="16%" align="right">
        {locationName && (
          <TableActionButton color="grayDarker">
            <ActionButtonTooltip
              icon="facility"
              iconSize="sMedium"
              customViewBox="0 5 15 10"
              tooltip={`${locationName} #${locationId}`}
              tooltipAsString
            />
          </TableActionButton>
        )}

        {hasDriverSafetyInspections && (
          <TableActionButton
            color="grayDarker"
            id={`driver-inspection-log-${id}-button`}
            onClick={handleDriverInspectionLog}
          >
            <ActionButtonTooltip icon="pendingIssue" iconSize="sMedium" tooltip="driverInspectionLog" />
          </TableActionButton>
        )}
        <PermissionGuard permission={FLEET_DRIVERS_EDIT}>
          <TableActionButton color="grayDarker" id={`edit-driver-${id}-button`} onClick={onEditDriver}>
            <ActionButtonTooltip icon="edit" iconSize="sMedium" tooltip="edit" />
          </TableActionButton>
        </PermissionGuard>

        <PermissionGuard permission={FLEET_DRIVERS_DELETE}>
          <TableActionButton
            color="grayDarker"
            id={`delete-driver-${id}-button`}
            onClick={event => {
              event.stopPropagation();
              onDeleteResource(id);
            }}
          >
            <ActionButtonTooltip icon="delete" iconSize="sMedium" tooltip="delete" />
          </TableActionButton>
        </PermissionGuard>
      </TableCell>
    </TableRow>
  );
};

export default ResourcesPageTableRow;
