import { AppState } from 'src/store';
import { Field, getFormValues, WrappedFieldArrayProps } from 'redux-form';
import { keyBy } from 'lodash-es';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ActionButtonTooltip, Input } from 'src/core/components';
import { isRequired } from 'src/utils/services/validator';
import { RATE_CODE_EDITOR_FORM_NAME } from './RateCodeModal';
import { Rate, RateType, UOM } from 'src/finance/interfaces/ServiceRates';
import { RateTypesByWorkOrderOrServiceContractIdDropdown } from 'src/customers/components/modals/RateCodeModal';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import UOMDropdown from './UOMDropdown';

type Props = WrappedFieldArrayProps<Rate> & {
  handleRemoveRate: (index: number) => void;
  cellWidths: { [key: string]: string };
  isBillReleased: boolean;
};

const BillFormTableRow = ({ fields, handleRemoveRate, cellWidths, isBillReleased }: Props) => {
  const rateTypes: RateType[] = useSelector((state: AppState) => state.customers.service.rateTypesByWorkOrder || []);
  const UOMs: UOM[] = useSelector((state: AppState) => state.finance.serviceRates.UOMs || []);

  const mappedUOMs = keyBy(UOMs, 'id');

  const formValues: any = useSelector(getFormValues(RATE_CODE_EDITOR_FORM_NAME));

  const getAmountForRow = useCallback(
    (index: number) => {
      const { rates = [] } = formValues;
      const { quantity = 0, rate = 0 } = rates[index];
      return quantity * rate;
    },
    [formValues],
  );

  const getUomsByRateCode = (rateCodeId?: number | string) => {
    const uomsByRateCode = rateTypes.find(rateType => Number(rateType.id) === rateCodeId)?.rateCodeServiceType[0]
      ?.unitOfMeasureTypeIds;

    return uomsByRateCode;
  };

  return (
    <>
      {fields.map((field, index) => {
        const currentRow = fields.get(index);
        const { id, isManual, overridable, rateCodeId = 0, uomId = 0, typeCode, rateDescr } = currentRow;
        const isNewRow = id && +id < 0;

        const rateCode = rateTypes.find(rateType => rateType.id === rateCodeId)?.typeCode || typeCode;
        const rateCodeDescription = rateTypes.find(rateType => rateType.id === rateCodeId)?.description || rateDescr;

        return (
          <TableRow key={`rateCodeCell-${field}`} className="tableRow">
            <TableCell width={cellWidths.description}>
              {isNewRow ? (
                <Field
                  name={`${field}.rateCodeId`}
                  component={RateTypesByWorkOrderOrServiceContractIdDropdown}
                  validate={[isRequired]}
                  margin="no"
                />
              ) : (
                <Text>
                  {rateCode} / {rateCodeDescription}
                </Text>
              )}
            </TableCell>
            <TableCell width={cellWidths.added}>
              <Field name={`${field}.isManual`} component={Input} type="checkbox" disabled margin="no" />
            </TableCell>
            <TableCell width={cellWidths.overridable}>
              <Field name={`${field}.overridable`} component={Input} type="checkbox" disabled margin="no" />
            </TableCell>
            <TableCell width={cellWidths.rate}>
              <Field
                name={`${field}.rate`}
                component={Input}
                type="number"
                disabled={!(!isBillReleased && (isManual || overridable))}
                validate={[isRequired]}
                margin="no"
              />
            </TableCell>
            <TableCell width={cellWidths.quantity}>
              <Field
                name={`${field}.quantity`}
                component={Input}
                type="number"
                validate={[isRequired]}
                disabled={!!isBillReleased}
                margin="no"
              />
            </TableCell>
            <TableCell width={cellWidths.uom}>
              {isNewRow ? (
                <Field
                  name={`${field}.uomId`}
                  component={UOMDropdown}
                  validate={[isRequired]}
                  margin="no"
                  uomsByRateCode={getUomsByRateCode(rateCodeId)}
                />
              ) : (
                <Text>{mappedUOMs[uomId]?.name || ''}</Text>
              )}
            </TableCell>
            <TableCell width={cellWidths.amount}>
              <Text>{getAmountForRow(index)}</Text>
            </TableCell>
            <TableCell width={cellWidths.action}>
              {!isBillReleased && (isNewRow || isManual) && (
                <TableActionButton onClick={() => handleRemoveRate(index)} id={`bill-${field}-delete-button`}>
                  <ActionButtonTooltip icon="delete" tooltip="deleteRate" />
                </TableActionButton>
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default BillFormTableRow;
