import React from 'react';
import { Clusterer } from '@react-google-maps/marker-clusterer';

import VehicleInsightMarker from './VehicleInsightMarker';
import { RubiconMarkerClusterer } from './RubiconMarkerClusterer';
import { OpenedInfoWindows } from '../interfaces/OpenedInfoWindows';

interface Props {
  vehicleInsights: any[];
  openedInfoWindows: OpenedInfoWindows;
  toggleInfoWindow: (key: string) => void;
}

const VehicleInsights: React.SFC<Props> = ({ vehicleInsights, openedInfoWindows, toggleInfoWindow }) => (
  <RubiconMarkerClusterer>
    {(clusterer: Clusterer) => (
      <>
        {vehicleInsights.map(vehicleInsight => (
          <VehicleInsightMarker
            key={vehicleInsight.insightId}
            vehicleInsight={vehicleInsight}
            isInfoWindowOpen={openedInfoWindows.vehicleInsights[vehicleInsight.insightId]}
            toggleInfoWindow={toggleInfoWindow}
            clusterer={clusterer}
          />
        ))}
      </>
    )}
  </RubiconMarkerClusterer>
);

export default VehicleInsights;
