import moment from 'moment';
import { FC, useState } from 'react';

import { ActionButtonTooltip, PopoverWrapper } from 'src/core/components';
import {
  COMPLETED_ID,
  COMPLETED,
  FAILED_ID,
  FAILED,
  IN_PROGRESS_ID,
  IN_PROGRESS,
  QUEUED_ID,
  QUEUED,
  VideoRequestErrorMessageDeviceOffline,
} from '../constants/videoRequests';
import { dateFormat, timeFormat } from 'src/utils/services/validator';
import { InfoIcon } from 'src/routes/components/styled';
import { Popover } from 'src/core/components/styled';
import { TableActionButton, TableCell, TableRow } from 'src/core/components/styled';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import translate from 'src/core/services/translate';
import VideoMessageModalResolver from 'src/common/components/VideoMessageModalResolver';
import useDownloadFileWithProgress from 'src/common/hooks/useDownloadFIleWithProgress';
import { ProgressDoughnut } from 'src/common/components';

interface Props {
  date: string;
  fileUrl: string;
  id: number;
  isInitialLoad: boolean;
  timeStampEnd: Date | string;
  timeStampStart: Date | string;
  vehicle: string;
  videoDownloadErrorMessageKey?: string;
  videoDownloadStatus: TechnicalType;
  videoRequestDate: Date | string;
  videoId?: string;
}

const VideoRequestsTableRow: FC<Props> = ({
  date,
  fileUrl,
  id: videoDownloadJobId,
  isInitialLoad,
  timeStampEnd,
  timeStampStart,
  vehicle,
  videoDownloadErrorMessageKey,
  videoDownloadStatus: { id: statusId },
  videoId,
  videoRequestDate,
}) => {
  const shouldOpenVideoFootageModal = Number(videoId) === videoDownloadJobId;

  const [isVideoFootageModalOpen, setIsVideoFootageModalOpen] = useState<boolean>(
    isInitialLoad && shouldOpenVideoFootageModal,
  );

  const status =
    statusId === QUEUED_ID
      ? QUEUED
      : statusId === FAILED_ID
      ? FAILED
      : statusId === IN_PROGRESS_ID
      ? IN_PROGRESS
      : COMPLETED;

  const statusName = translate(
    `routes.videoRequest.tooltip.${
      videoDownloadErrorMessageKey === VideoRequestErrorMessageDeviceOffline
        ? 'videoRequestErrorMessageDeviceOffline'
        : 'videoRequestErrorMessageRequestNotProcessed'
    }`,
  );

  const timeStart = moment(timeStampStart).format(timeFormat);
  const timeEnd = moment(timeStampEnd).format(timeFormat);
  const fileName = `${vehicle}: ${timeStart} - ${timeEnd}`;

  const { download, progress, isDownloading } = useDownloadFileWithProgress();

  const downloadVideo = async (downloadUrl: string) => {
    download(downloadUrl, `${fileName}.mp4`);
  };

  const toggleVideoFootageModal = (isOpen: boolean) => {
    setIsVideoFootageModalOpen(isOpen);
  };

  return (
    <>
      <TableRow isHighlighted={shouldOpenVideoFootageModal}>
        <TableCell hasLeftBorder width="27%">
          {fileName}
        </TableCell>
        <TableCell width="13%">{translate('routes.videoRequest.videoRequest')}</TableCell>
        <TableCell width="12%">
          {status}
          {statusId === FAILED_ID && (
            <PopoverWrapper
              margin="no no no xSmall"
              triggerButton={<InfoIcon />}
              popoverContent={<Popover>{statusName}</Popover>}
            />
          )}
        </TableCell>
        <TableCell width="13%">{1}</TableCell>
        <TableCell width="12%">{moment(date).format(dateFormat)}</TableCell>
        <TableCell width="14%">{moment(videoRequestDate).format(dateFormat)}</TableCell>
        <TableCell width="10%">
          {statusId === COMPLETED_ID && (
            <>
              {fileUrl && (
                <TableActionButton onClick={() => (isDownloading ? () => {} : downloadVideo(fileUrl))}>
                  {isDownloading ? (
                    <ProgressDoughnut progress={progress.percent} size={35} />
                  ) : (
                    <ActionButtonTooltip size="sMedium" icon="download" tooltip="downloadVideo" />
                  )}
                </TableActionButton>
              )}
              <TableActionButton onClick={() => toggleVideoFootageModal(true)}>
                <ActionButtonTooltip
                  margin="no xxSmall no no"
                  size="sMedium"
                  icon="playRoutePlayback"
                  tooltip="playVideo"
                />
              </TableActionButton>
            </>
          )}
        </TableCell>
      </TableRow>

      {isVideoFootageModalOpen && (
        <VideoMessageModalResolver
          videoDownloadJobId={videoDownloadJobId || Number(videoId)}
          closeModal={() => toggleVideoFootageModal(false)}
        />
      )}
    </>
  );
};

export default VideoRequestsTableRow;
