import { filter, find, flatten, get } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

import { clearDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { getMeasurementType } from 'src/dashboard/hooks/useLoadDataForMapboxDashboard';
import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import DashboardVehicleTrackingsGLPopup from '../dashboardVehicleTrackings/DashboardVehicleTrackingsGLPopup';

interface Props {
  isOnDemandVideoDownloadEnabled: boolean;
}

const DashboardApplicationStatusGLPopupResolver: React.FC<Props> = ({ isOnDemandVideoDownloadEnabled }) => {
  const dispatch = useDispatch();

  const showAppStatusForVehicle = useSelector(state => state.dashboard.vehiclesData.showAppStatus);
  const showAppStatusForRoute = useSelector(state => state.dashboard.routesData.showAppStatus);
  const vehicleTrackingsForVehicle = useSelector(state => state.dashboard.vehiclesData.vehicleBreadcrumbs);
  const vehicleTrackingsForRoute = useSelector(state => state.dashboard.routesData.routeVehiclesBreadCrumbs);
  const selectedFeature = useSelector(state => state.dashboard.mapControls.selectedFeature);
  const systemOfMeasurementId = useSelector(state => state.vendors.vendor.vendor.systemOfMeasurementId);

  const selectedTracking = useMemo(() => {
    if (
      !selectedFeature ||
      selectedFeature.namespace !== 'applicationModeChanges' ||
      selectedFeature.vehicleTrackingApplicationStatusIndex === undefined
    ) {
      return undefined;
    }

    const {
      id: vehicleId,
      vehicleTrackingApplicationStatusIndex: statusIndex,
      vehicleTrackingsSource,
    } = selectedFeature;

    let status = null;
    let trackingsForVehicle = null;

    if (showAppStatusForVehicle) {
      trackingsForVehicle = vehicleTrackingsForVehicle;
      const flatTrackings = flatten(trackingsForVehicle?.coords || []);
      const vehicleApplicationModeChanges = filter(flatTrackings, (coord, index) => {
        if (index === 0) return false;
        return coord.am !== flatTrackings[index - 1].am;
      });
      status = get(vehicleApplicationModeChanges, statusIndex);
    } else if (showAppStatusForRoute) {
      trackingsForVehicle = find(vehicleTrackingsForRoute?.vehicles, { id: vehicleId });
      const flatTrackings = flatten(trackingsForVehicle?.coords || []);
      const vehicleApplicationModeChanges = filter(flatTrackings, (coord, index) => {
        if (index === 0) return false;
        return coord.am !== flatTrackings[index - 1].am;
      });
      status = get(vehicleApplicationModeChanges, statusIndex);
    }

    if (!status || !trackingsForVehicle) {
      return undefined;
    }

    return {
      unitOfMeasure: getMeasurementType(systemOfMeasurementId),
      vehicle: trackingsForVehicle.name,
      vehicleId: trackingsForVehicle.id,
      status,
      shouldDisplayDownloadVideo:
        vehicleTrackingsSource === 'route'
          ? vehicleTrackingsForRoute?.vehicles.find(vehicle => vehicle.id === vehicleId)?.hdv
          : trackingsForVehicle?.hdv,
    };
  }, [
    selectedFeature,
    showAppStatusForRoute,
    showAppStatusForVehicle,
    vehicleTrackingsForRoute?.vehicles,
    vehicleTrackingsForVehicle,
    systemOfMeasurementId,
  ]);

  if (!selectedTracking) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedTracking.status.lat}
      longitude={selectedTracking.status.lng}
      onClose={() => dispatch(clearDashboardSelectedFeature())}
    >
      <TypedResolver
        key={`${selectedFeature!.id}.${selectedFeature!.vehicleTrackingApplicationStatusIndex}`}
        successComponent={DashboardVehicleTrackingsGLPopup}
        successProps={{
          applicationModeStatusId: selectedTracking.status.am,
          shouldDisplayDownloadVideo: selectedTracking.shouldDisplayDownloadVideo && isOnDemandVideoDownloadEnabled,
          speed: selectedTracking.status.sp,
          timestamp: selectedTracking.status.ts,
          unitOfMeasure: selectedTracking.unitOfMeasure,
          vehicleId: selectedTracking.vehicleId,
          vehicleName: selectedTracking.vehicle,
        }}
        loadingComponent={MapGLPopupLoading}
      />
    </MapGLPopupWrapper>
  );
};

export default DashboardApplicationStatusGLPopupResolver;
