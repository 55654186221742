import { map } from 'lodash-es';

import { ContainerColorConfiguration } from '../interfaces/ContainerColorConfiguration';
import { http } from '../../core/services/http';

export const loadContainerColorConfiguration = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/containerColorSettings`).then(response => response.data);

export const saveContainerColorConfiguration = (
  vendorId: number,
  containerColorConfiguration: ContainerColorConfiguration[],
) =>
  http
    .put(`/vendors/${vendorId}/containerColorSettings`, map(containerColorConfiguration))
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
