import { camelCase } from 'lodash-es';

import { UnscheduledStops } from 'src/routes/interfaces/RouteStop';
import { TableCell, TableRow } from '../../../../core/components/styled';
import { dateAndTime } from '../../../../utils/services/formatter';
import translate from 'src/core/services/translate';

const RoutePageUnscheduledStopsTableRow = ({ stopNumber, address, pickupStatus, reportDateTime }: UnscheduledStops) => (
  <TableRow id={`stop-${stopNumber}`}>
    <TableCell width={'10%'}>{stopNumber}</TableCell>
    <TableCell width={'45%'}>{address}</TableCell>
    <TableCell width={'20%'}>{translate(`common.pickupStatuses.${camelCase(pickupStatus)}`)}</TableCell>
    <TableCell width={'25%'}>{reportDateTime ? dateAndTime(reportDateTime) : '-'}</TableCell>
  </TableRow>
);

export default RoutePageUnscheduledStopsTableRow;
