import { http, httpInsightsReporting } from '../../core/services/http';
import { RouteMapVehicleData } from '../interfaces/RouteMapVehicleData';

export const loadRouteMapVehicleData = (vendorId: number, routeId: number, includeVehicleTracking?: boolean) =>
  httpInsightsReporting
    .get<RouteMapVehicleData>(`${vendorId}/routeDetailsVehicleInformation`, {
      params: { routeId, includeVehicleTracking },
    })
    .then(response => response.data);

export const loadRouteMapFilters = (
  vendorId: number,
  routeId?: number,
  startDate?: Date | string,
  endDate?: Date | string,
) =>
  http
    .get(`/${vendorId}/routes/${routeId}/routeDetail/alternativeFleet/mapFilters`, {
      params: { startDate, endDate },
    })
    .then(response => response.data);
