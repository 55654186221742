import update from 'immutability-helper';
import { findIndex } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';

import { OpenBillResponseData } from '../interfaces/ApplyChecks';

import {
  assignOpenBill as doAssignOpenBill,
  unassignOpenBill as doUnassignOpenBill,
  exportOpenBill as doExportOpenBill,
  exportOpenBills as doExportOpenBills,
  loadOpenBills as doLoadOpenBills,
} from '../services/openBills';
import { ILoadOpenBillsParams } from '../interfaces/openBills';

// Actions
const START_LOAD = 'finance/applyChecks/openBills/START_LOAD';
const COMPLETE_LOAD = 'finance/applyPayemnts/openBills/COMPLETE_LOAD';
const FAIL_LOAD = 'finance/applyChecks/openBills/FAIL_LOAD';
const START_ASSIGN = 'finance/applyChecks/openBills/START_ASSIGN';
const COMPLETE_ASSIGN = 'finance/applyPayemnts/openBills/COMPLETE_ASSIGN';
const FAIL_ASSIGN = 'finance/applyChecks/openBills/FAIL_ASSIGN';
const START_UNASSIGN = 'finance/applyChecks/openBills/START_UNASSIGN';
const COMPLETE_UNASSIGN = 'finance/applyPayemnts/openBills/COMPLETE_UNASSIGN';
const FAIL_UNASSIGN = 'finance/applyChecks/openBills/FAIL_UNASSIGN';
const START_EXPORT_BILLS = 'finance/applyChecks/openBills/START_EXPORT_BILLS';
const COMPLETE_EXPORT_BILLS = 'finance/applyPayemnts/openBills/COMPLETE_EXPORT_BILLS';
const FAIL_EXPORT_BILLS = 'finance/applyChecks/openBills/FAIL_EXPORT_BILLS';
const START_EXPORT_BILL = 'finance/applyChecks/openBills/START_EXPORT_BILL';
const COMPLETE_EXPORT_BILL = 'finance/applyPayemnts/openBills/COMPLETE_EXPORT_BILL';
const FAIL_EXPORT_BILL = 'finance/applyChecks/openBills/FAIL_EXPORT_BILL';
const RESET = 'finance/applyChecks/openBills/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  isAssigning: boolean;
  isUnassigning: boolean;
  isExportingBills: boolean;
  isExportingBill: boolean;
  openBills?: OpenBillResponseData;
} = {
  isLoading: false,
  isAssigning: false,
  isUnassigning: false,
  isExportingBills: false,
  isExportingBill: false,
  openBills: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          openBills: action.openBills,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          openBills: undefined,
        },
      });

    case START_ASSIGN:
      return update(state, {
        $merge: {
          isAssigning: true,
        },
      });

    case COMPLETE_ASSIGN:
      return update(state, {
        $merge: {
          isAssigning: false,
        },
      });

    case FAIL_ASSIGN:
      return update(state, {
        $merge: {
          isAssigning: false,
        },
      });

    case START_UNASSIGN:
      return update(state, {
        $merge: {
          isUnassigning: true,
        },
      });

    case COMPLETE_UNASSIGN: {
      const index = findIndex(state.openBills?.bills, { invoiceId: action.billId });
      return update(state, {
        openBills: { bills: { $splice: [[index, 1]] } },
        $merge: { isUnassigning: false },
      });
    }

    case FAIL_UNASSIGN:
      return update(state, {
        $merge: {
          isUnassigning: false,
        },
      });

    case START_EXPORT_BILLS:
      return update(state, {
        $merge: {
          isExportingBills: true,
        },
      });

    case COMPLETE_EXPORT_BILLS:
      return update(state, {
        $merge: {
          isExportingBills: false,
        },
      });

    case FAIL_EXPORT_BILLS:
      return update(state, {
        $merge: {
          isExportingBills: false,
        },
      });

    case START_EXPORT_BILL:
      return update(state, {
        $merge: {
          isExportingBill: true,
        },
      });

    case COMPLETE_EXPORT_BILL:
      return update(state, {
        $merge: {
          isExportingBill: false,
        },
      });

    case FAIL_EXPORT_BILL:
      return update(state, {
        $merge: {
          isExportingBill: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (openBills: any) => ({
  type: COMPLETE_LOAD,
  openBills,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startAssign = () => ({
  type: START_ASSIGN,
});

const completeAssign = () => ({
  type: COMPLETE_ASSIGN,
});

const failAssign = () => ({
  type: FAIL_ASSIGN,
});
const startUnassign = () => ({
  type: START_UNASSIGN,
});

const completeUnassign = () => ({
  type: COMPLETE_UNASSIGN,
});

const failUnassign = () => ({
  type: FAIL_UNASSIGN,
});

const startExportBills = () => ({
  type: START_EXPORT_BILLS,
});

const completeExportBills = () => ({
  type: COMPLETE_EXPORT_BILLS,
});

const failExportBills = () => ({
  type: FAIL_EXPORT_BILLS,
});

const startExportBill = () => ({
  type: START_EXPORT_BILL,
});

const completeExportBill = () => ({
  type: COMPLETE_EXPORT_BILL,
});

const failExportBill = () => ({
  type: FAIL_EXPORT_BILL,
});

export const loadOpenBills =
  ({
    vendorId,
    billStatusIds,
    startDate,
    endDate,
    customerId,
    locationId,
    excludedPaymentId,
    noLoadingIndicator,
  }: ILoadOpenBillsParams) =>
    (dispatch: Dispatch) => {
      !noLoadingIndicator && dispatch(startLoad());
      const loadOpenBillsPromise = doLoadOpenBills(
        {
          vendorId,
          billStatusIds,
          startDate,
          endDate,
          customerId,
          locationId,
          excludedPaymentId,
        });
      loadOpenBillsPromise.then((openBills: any) => dispatch(completeLoad(openBills))).catch(() => dispatch(failLoad()));
      return loadOpenBillsPromise;
    };

export const assignOpenBill =
  (vendorId: number, billId: number, paymentId: number, customerId: number) => (dispatch: Dispatch) => {
    dispatch(startAssign());
    const assignOpenBillPromise = doAssignOpenBill(vendorId, billId, paymentId, customerId);
    assignOpenBillPromise.then(() => dispatch(completeAssign())).catch(() => dispatch(failAssign()));
    return assignOpenBillPromise;
  };
export const unassignOpenBill = (paymentId: number, billIds: number[]) => (dispatch: Dispatch) => {
  dispatch(startUnassign());
  const unassignOpenBillPromise = doUnassignOpenBill(paymentId, billIds);
  unassignOpenBillPromise.then(() => dispatch(completeUnassign())).catch(() => dispatch(failUnassign()));
  return unassignOpenBillPromise;
};

export const exportOpenBills = () => (dispatch: Dispatch) => {
  dispatch(startExportBills());
  const exportOpenBillsPromise = doExportOpenBills();
  exportOpenBillsPromise.then(() => dispatch(completeExportBills())).catch(() => dispatch(failExportBills()));
  return exportOpenBillsPromise;
};

export const exportOpenBill = (billId: number) => (dispatch: Dispatch) => {
  dispatch(startExportBill());
  const exportOpenBillPromise = doExportOpenBill(billId);
  exportOpenBillPromise.then(() => dispatch(completeExportBill())).catch(() => dispatch(failExportBill()));
  return exportOpenBillPromise;
};

export const resetOpenBills = () => ({
  type: RESET,
});
