import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const AWARD = 1;
export const REQUEST_FOR_PRICING = 2;
export const SOURCING_TOOL = 'SourcingTool';
export const CHANGE_OF_BILLING = 'Change of Billing';

export const REQUEST_TYPE_LIST = [
  { id: AWARD, technicalName: 'Award', name: translate('opportunity.opportunities.requestType.award') },
  {
    id: REQUEST_FOR_PRICING,
    technicalName: 'RequestForPricing',
    name: translate('opportunity.opportunities.requestType.requestForPricing'),
  },
];

export const REQUEST_TYPE = mapKeys(REQUEST_TYPE_LIST, 'id');
