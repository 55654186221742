import { http } from '../../core/services/http';

import { WeightTickets } from '../interfaces/WeightTickets';

export const createWeightTicket = (routeId: number, weightTicket: WeightTickets) =>
  http.post(`routes/${routeId}/weightTicket`, weightTicket).then(response => response.data);

export const updateWeightTicket = (weightTicket: WeightTickets) =>
  http.put(`routes/weightTicket/${weightTicket.id}`, weightTicket).then(response => response.data);

export const deleteWeightTicket = (weightTicketId: number) => http.delete(`routes/weightTicket/${weightTicketId}`);

export const uploadWeightTicketImage = async (file: File, routeId: number) => {
  const formData = new FormData();
  const dataObject = JSON.stringify([{ RouteId: routeId, imageSourceTypeId: 1 }]);

  formData.append('Image', file);
  formData.append('data', dataObject);

  const response = await http.post('images', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};

export const linkImageToWeightTicket = async (imageId: number | null, weightTicketId: number) => {
  const response = http.post('routes/weightTicket/addImage', {
    imageId,
    RouteLegId: weightTicketId,
  });

  return response;
};
