import { mapKeys } from 'lodash-es';

import translate from '../../core/services/translate';

export const ZERO_HOURS = 1;
export const TWELVE_HOURS = 2;
export const TWENTY_FOUR_HOURS = 3;
export const THIRTY_SIX_HOURS = 4;

export const BULKY_CANCELLATION_TIME_FRAMES = mapKeys(
  [
    {
      id: ZERO_HOURS,
      name: translate('vendors.streetSweeper.noOfHours', {
        hours: '0',
      }),
      technicalName: 'ZeroHours',
    },
    {
      id: TWELVE_HOURS,
      name: translate('vendors.streetSweeper.noOfHours', {
        hours: '12',
      }),
      technicalName: 'TwelveHours',
    },
    {
      id: TWENTY_FOUR_HOURS,
      name: translate('vendors.streetSweeper.noOfHours', {
        hours: '24',
      }),
      technicalName: 'TwentyFourHours',
    },
    {
      id: THIRTY_SIX_HOURS,
      name: translate('vendors.streetSweeper.noOfHours', {
        hours: '36+',
      }),
      technicalName: 'ThirtySixHours',
    },
  ],
  'id',
);
