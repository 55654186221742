import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { MessageThread } from '../interfaces/MessageThread';
import { MessageType } from '../interfaces/MessateType';

const getThreadName = (thread: MessageThread) => {
  if (thread.messageTypeId === MessageType.Direct || thread.messageTypeId === MessageType.Route) {
    return thread.threadName;
  }
  if (thread.messageTypeId === MessageType.All) {
    return translate('messaging.messageTypes.all');
  }
  if (thread.messageTypeId === MessageType.VehicleType) {
    return translate(createTranslationKey(thread.threadName, 'vehicles.vehicleTypes'));
  }
  return '';
};

export const transformMessageThreads = (threads: MessageThread[]) =>
  threads.map(t => {
    t.threadName = getThreadName(t);
    return t;
  });
