import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';

import { Container, ButtonSet, Button } from '../../../core/components/styled';
import { DatePicker } from '../../../core/components';
import translate from '../../../core/services/translate';
import deliveryFormInitialValueSelector from '../../services/deliveryFormInitialValueSelector';
import { AppState } from '../../../store';
import { isDateValidValidator } from 'src/utils/services/validator';

interface ComponentProps {
  caseId: number;
  beforeDeliveryDate?: string;
  afterDeliveryDate?: string;
  onCancel(pristine: boolean): void;
}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
  response: any;
  requestedChanges: any;
  WasDelivered: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

interface State {
  response?: any;
}

class ServiceNotOnScheduleDeliveryForm extends PureComponent<Props, State> {
  readonly state: State = {
    response: undefined,
  };

  render() {
    const { caseId, handleSubmit, beforeDeliveryDate, afterDeliveryDate, onCancel, pristine } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <Container>
          <Field
            name={`deliveries.settings${caseId}.requestedChanges.deliveryDate`}
            component={DatePicker}
            label={translate('opportunity.deliveries.willDeliverOn')}
            disabledDays={[
              {
                before: moment(beforeDeliveryDate, 'MM/DD/YYYY').toDate(),
                after: moment(afterDeliveryDate, 'MM/DD/YYYY').toDate(),
              },
              { daysOfWeek: [0, 6] },
            ]}
            margin="large small small"
            validate={[isDateValidValidator]}
          />
        </Container>
        <ButtonSet>
          <Button type="submit" color="primary">
            {translate('opportunity.submit')}
          </Button>
          <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

const formSelector = formValueSelector('serviceNotOnScheduleDelivery');

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  initialValues: deliveryFormInitialValueSelector(
    state.opportunity.opportunity.deliveries,
    state.fleet.rubiconDispatches.rubiconDispatches,
  ),
  response: formSelector(state, `deliveries.settings${ownProps.caseId}.response`),
  requestedChanges: formSelector(state, `deliveries.settings${ownProps.caseId}.requestedChanges`),
  WasDelivered: formSelector(state, `deliveries.settings${ownProps.caseId}.WasDelivered`),
});

export default connect(mapStateToProps)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'serviceNotOnScheduleDelivery',
  })(ServiceNotOnScheduleDeliveryForm),
);
