import React, { ChangeEvent, PureComponent } from 'react';

import { map } from 'lodash-es';

import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { VENDOR_TYPES } from '../constants';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  byName?: boolean;
  serviceTypeId?: number;
}

class VendorTypeDropdown extends PureComponent<Props> {
  onChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
    } = this.props;

    const vendorTypeOptions = map(VENDOR_TYPES, vendorType => ({
      label: vendorType.name,
      value: vendorType.id,
    }));

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('vendors.vendorType') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('vendors.vendorType') : undefined),
          options: vendorTypeOptions,
          ...dropdownProps,
        }}
      />
    );
  }
}

export default VendorTypeDropdown;
