import { Fragment, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { FixedSizeList, VariableSizeList } from 'react-window';

import { ChevronDownArrow } from '../../core/components';
import { REACT_WINDOW_CLASS_NAME } from '../constants/reactWindow';
import { TABLE_ROW_MAX_ITEMS } from '../constants';
import { TableCellsOuterWrapper, TableScrollMarker } from './styled';

const Row = ({ data, style, index }: any) => {
  const { RowComponent, rowProps, rows } = data;

  return (
    <div style={style}>
      <RowComponent key={index} index={index} orderNumber={index} {...rowProps} {...rows[index]} />
    </div>
  );
};

const SCROLLED_HEIGHT = '100%';

interface Props {
  noOverflow?: boolean;
  overflow?: string;
  rowComponent: any;
  rowProps?: any;
  rows: any[];
  rowScrollTopPosition?: number;
  scrollMarker?: boolean;
  setContainerRef?: (ref: any) => void;
  virtualized?: boolean;
  virtualizedProps?: any;
}

class TableCells extends PureComponent<Props, { scrolled: number | string }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      scrolled: 0,
    };
  }

  private listRef?: VariableSizeList;
  private scrollDivRef?: HTMLDivElement;

  componentDidUpdate(prevProps: Props) {
    const { rowScrollTopPosition, virtualizedProps } = this.props;

    if (this.isVariableList()) {
      if (prevProps.virtualizedProps.activeItem !== virtualizedProps.activeItem && this.listRef) {
        this.listRef.resetAfterIndex(0, true);
      }
    }

    if (rowScrollTopPosition && prevProps.rowScrollTopPosition !== rowScrollTopPosition) {
      rowScrollTopPosition >= 0 && this?.listRef?.scrollTo(rowScrollTopPosition);
    }
  }

  setRefs = (ref: VariableSizeList) => {
    const { setContainerRef } = this.props;
    // eslint-disable-next-line react/no-find-dom-node
    this.scrollDivRef = ReactDOM.findDOMNode(ref) as HTMLDivElement;
    this.listRef = ref;
    if (setContainerRef) setContainerRef(ref);
  };

  getRows = (rows: any[], RowComponent: any, rowProps: any) => {
    const { setContainerRef } = this.props;

    return (
      <div ref={setContainerRef}>
        {rows.map((row, index) => (
          <RowComponent key={index} index={index} orderNumber={index} {...rowProps} {...row} />
        ))}
      </div>
    );
  };

  isVariableList = () => {
    return (
      this.props.virtualized &&
      this.props.virtualizedProps &&
      typeof this.props.virtualizedProps.itemSize === 'function'
    );
  };

  handleScroll = () => {
    if (!this.scrollDivRef) {
      return;
    }
    const scrollDivRef = this.scrollDivRef;
    const scrollTop = parseInt(scrollDivRef.scrollTop.toString());
    const clientHeight = scrollDivRef.clientHeight;
    const scrollHeight = scrollDivRef.scrollHeight;
    const winHeightPx = scrollHeight - clientHeight;
    const scrolled = `${(scrollTop / winHeightPx) * 100}%`;
    if (scrolled === SCROLLED_HEIGHT || (this.state.scrolled === SCROLLED_HEIGHT && scrolled !== SCROLLED_HEIGHT)) {
      this.setState({
        scrolled,
      });
    }
  };

  render() {
    const {
      overflow,
      rowComponent: RowComponent,
      rowProps,
      rows,
      scrollMarker,
      virtualized,
      virtualizedProps,
      noOverflow,
    } = this.props;
    const { scrolled } = this.state;

    const TableCellsWrapper: any = virtualized ? (this.isVariableList() ? VariableSizeList : FixedSizeList) : Fragment;
    const scrollIndicatorVisible = scrolled !== SCROLLED_HEIGHT && rows.length > TABLE_ROW_MAX_ITEMS - 1;

    const props = virtualized
      ? {
          ...virtualizedProps,
          itemCount: rows.length,
          itemData: { rows, RowComponent, rowProps },
          width: '100%',
          onScroll: scrollMarker ? this.handleScroll : undefined,
          ref: this.setRefs,
          className: REACT_WINDOW_CLASS_NAME,
        }
      : {};

    return (
      <TableCellsOuterWrapper id="table-wrapper" overflow={overflow} noOverflow={noOverflow}>
        <TableCellsWrapper {...props}>
          {virtualized ? Row : this.getRows(rows, RowComponent, rowProps)}
        </TableCellsWrapper>
        {scrollMarker && scrollIndicatorVisible && (
          <TableScrollMarker>
            <ChevronDownArrow width={14} />
          </TableScrollMarker>
        )}
      </TableCellsOuterWrapper>
    );
  }
}

export default TableCells;
