import { http } from '../../core/services/http';
import { SnowRoadSegmentCondition, SnowRoadSegmentConditionUpdates } from '../interfaces/snowRoadConditions';

export const loadSnowRoadConditions = (vendorId: number) =>
  http.get<SnowRoadSegmentCondition[]>(`vendors/${vendorId}/snowRoadConditions`).then(response => response.data);

export const loadSnowRoadConditionUpdates = (vendorId: number) =>
  http
    .get<SnowRoadSegmentConditionUpdates[]>(`vendors/${vendorId}/snowRoadConditions`, {
      params: {
        optimizedResultsOnly: true,
      },
    })
    .then(response => response.data);
