import React, { PureComponent } from 'react';
import { size } from 'lodash-es';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';
import { push } from 'connected-react-router';
import moment from 'moment';

import {
  loadProcessedServices,
  updateProcessedServices,
  updateAcceptSelectedProcessedPaymentServices,
  resetProcessedService,
} from '../../ducks';
import { AcceptPaymentForSelectedProcessedModal } from '../../../payments/components/modal';
import {
  ProcessedServicesForm,
  PricingInfoProcessedForm,
  PricingInfoProcessedFormFooter,
  PaymentReportDateForm,
} from '../forms';
import pricingInfoIntialValueSelector from '../../services/pricingInfoIntialValueSelector';
import {
  PageContent,
  PageHeader,
  PageDetails,
  PageTitleContainer,
  PageSubtitle,
} from '../../../common/components/styled';
import {
  Panel,
  PanelSectionGroup,
  PanelSection,
  Text,
  Grid,
  GridColumn,
  Button,
} from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import confirm from '../../../core/services/confirm';
import { AppState } from '../../../store';

interface RouteParams {
  token: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  pricingInfo: any;
  isLoading: boolean;
  isSaving: boolean;
  vendorId: number;
  haulerName?: string;
  errorMessage?: string;
  workOrdersInfo?: any[];
  push: any;
  reset: any;
  loadProcessedServices(...args: any[]): any;
  updateProcessedServices(...args: any[]): any;
  updateAcceptSelectedProcessedPaymentServices(...args: any[]): any;
  resetProcessedService(...args: any[]): any;
}

interface State {
  isAcceptPaymentForSelectedProcessedModalOpen: boolean;
  isExpanded: boolean;
}

const AcceptPaymentForSelectedProcessedModalMock = AcceptPaymentForSelectedProcessedModal as any;

class ProcessedServicesPage extends PureComponent<Props, State> {
  readonly state = {
    isAcceptPaymentForSelectedProcessedModalOpen: false,
    isExpanded: false,
  };

  componentWillUnmount() {
    const { resetProcessedService } = this.props;

    resetProcessedService();
  }

  onDateChange = async (event: any) => {
    const { match, vendorId, loadProcessedServices } = this.props;
    const token = match.params.token;
    const date = moment(event).format('MM/DD/YYYY');

    await loadProcessedServices(vendorId, token, date);
  };

  // Accept
  openAcceptPaymentForSelectedProcessedModal = (event: any) => {
    this.setState({ isAcceptPaymentForSelectedProcessedModalOpen: true });
    event.stopPropagation();

    return false;
  };

  closeAcceptPaymentForSelectedProcessedModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('acceptPaymentForSelected');
    this.setState({ isAcceptPaymentForSelectedProcessedModalOpen: false });
  };

  toggle = () => {
    this.setState(({ isExpanded }) => ({
      isExpanded: !isExpanded,
    }));
  };

  render() {
    const { pricingInfo, isLoading, isSaving, haulerName, errorMessage, vendorId } = this.props;

    return (
      <PageContent>
        <PageHeader withSubTitle>
          <PageDetails>
            <PageTitleContainer>
              {/* {(!errorMessage || (!!size(workOrdersInfo) || workOrdersInfo.length > 1)) && (
                // <PageTitle>{translate('opportunity.opportunities.currentOpportunities')}</PageTitle>
              )} */}
            </PageTitleContainer>
          </PageDetails>
        </PageHeader>
        <PageTitleContainer>
          <PageSubtitle size="medium" margin="small small small">
            {haulerName}
          </PageSubtitle>
        </PageTitleContainer>
        <Panel>
          <PanelSectionGroup isLoading={isLoading}>
            <PaymentReportDateForm {...this.props} onDateChange={this.onDateChange} />
            <Panel>
              {!!size(pricingInfo) && <PricingInfoProcessedForm vendorId={vendorId} />}
              {(!size(pricingInfo) || pricingInfo.length < 1) && (
                <Text block weight="medium" size="large" margin="xxLarge xxGrande large ">
                  {translate('payment.noRecord')}
                </Text>
              )}
            </Panel>

            {errorMessage && (
              <Panel>
                <Text block weight="medium" size="large" color="alert" margin="xxLarge xxGrande large ">
                  Error:
                </Text>
                <Text block weight="medium" size="small" color="gray">
                  {errorMessage}
                </Text>
              </Panel>
            )}
          </PanelSectionGroup>
        </Panel>
        <Panel>
          <PanelSectionGroup isLoading={isLoading || isSaving}>
            <PanelSection centered>{!!size(pricingInfo) && <ProcessedServicesForm vendorId={vendorId} />}</PanelSection>
          </PanelSectionGroup>
        </Panel>
        <Panel>
          <PanelSectionGroup isLoading={isLoading}>
            <Grid padding="no no no no" multiLine>
              {!!size(pricingInfo) && (
                <GridColumn size="12/12" align="right">
                  {!!size(pricingInfo) && <PricingInfoProcessedFormFooter />}
                  {/* <PanelSection padding="small xSmall" withBorder align="right"> */}
                  <Button
                    background-color="transparent"
                    color="primary"
                    size="small"
                    onClick={this.openAcceptPaymentForSelectedProcessedModal}
                    disabled={pricingInfo.acceptedWOCount <= 0}
                    margin="small xSmall small"
                  >
                    {translate('payment.acceptPaymentForSelected')}
                  </Button>{' '}
                  {this.state.isAcceptPaymentForSelectedProcessedModalOpen && (
                    <AcceptPaymentForSelectedProcessedModalMock
                      {...this.props}
                      onCancel={this.closeAcceptPaymentForSelectedProcessedModal}
                    />
                  )}
                  {/* </PanelSection> */}
                </GridColumn>
              )}
            </Grid>
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  workOrdersInfo: state.payments.processedServices.workOrdersInfo,
  pricingInfo: state.payments.processedServices.pricingInfo,
  paymentInfo: state.payments.processedServices.paymentInfo,
  issueType: state.payments.processedServices.issueType,
  haulerName: state.payments.processedServices.haulerName,
  errorMessage: state.payments.processedServices.errorMessage,
  isLoading: state.payments.processedServices.isLoading,
  isSaving: state.payments.processedServices.isSaving,
  initialValues: pricingInfoIntialValueSelector(state.payments.processedServices.pricingInfo),
});

const mapDispatchToProps = {
  loadProcessedServices,
  updateProcessedServices,
  updateAcceptSelectedProcessedPaymentServices,
  resetProcessedService,
  push,
  reset,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProcessedServicesPage));
