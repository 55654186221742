import { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import { checkForActivePM } from '../../ducks/preventativeMaintenance';
import { currentVendorId, currentUserId } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { loadVehicleFacilites } from '../../ducks/vehicle';
import { loadVehicleList, loadVehicleSummary } from '../../ducks/vehicles';
import { loadVehicleTypes, loadVehicleTypesForVendor } from '../../ducks';
import { loadVendor, loadUserById, loadYData, loadFeatures } from 'src/vendors/ducks';
import { PageLoading } from '../../../common/components/styled';
import { shouldLoadPermissionsSelector, loadPermissions } from '../../../account/ducks';
import { TypedResolver } from '../../../core/components';
import { Y_DATA } from 'src/vendors/ducks/features';
import translate from '../../../core/services/translate';
import VehiclesPage from './VehiclesPage';

interface Props extends RouteComponentProps {
  checkVendorForActivePM: DuckFunction<typeof checkForActivePM>;
  loadFeatures: DuckFunction<typeof loadFeatures>;
  loadPermissions: DuckFunction<typeof loadPermissions>;
  loadUser: DuckFunction<typeof loadUserById>;
  loadVehicleFacilites: DuckFunction<typeof loadVehicleFacilites>;
  loadVehicleList: DuckFunction<typeof loadVehicleList>;
  loadVehicleSummary: DuckFunction<typeof loadVehicleSummary>;
  loadVehicleTypes: DuckFunction<typeof loadVehicleTypes>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  loadVendor: DuckFunction<typeof loadVendor>;
  loadYData: DuckFunction<typeof loadYData>;
  shouldLoadPermissions?: boolean;
  userId?: string;
  vendorId?: number;
}

class VehiclesPageResolver extends PureComponent<Props> {
  loadDependencies = async () => {
    const {
      checkVendorForActivePM,
      loadFeatures,
      loadPermissions,
      loadUser,
      loadVehicleFacilites,
      loadVehicleTypes,
      loadVehicleTypesForVendor,
      loadVendor,
      loadYData,
      shouldLoadPermissions,
      userId,
      vendorId,
    } = this.props;

    const dependencies: Promise<any>[] = [];

    await loadVehicleTypes();

    if (vendorId) {
      await loadVehicleTypesForVendor(vendorId);

      dependencies.push(
        checkVendorForActivePM(vendorId),
        loadVendor(vendorId),
        loadVehicleFacilites(vendorId),
        loadFeatures(vendorId).then(response => {
          const isYDataEnabled = (response || []).find(f => f.code === Y_DATA && f.enabled);
          if (isYDataEnabled) loadYData();
        }),
      );

      if (userId) {
        dependencies.push(loadUser(vendorId, userId));
      }
    }

    if (shouldLoadPermissions) {
      dependencies.push(loadPermissions());
    }

    return Promise.all(dependencies);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('vehicles.vehicles')}</DocumentTitle>

        <TypedResolver
          successComponent={VehiclesPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/dashboard"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  shouldLoadPermissions: shouldLoadPermissionsSelector(state.account.login, state.account.permissions),
  userId: currentUserId(state),
  vendorId: currentVendorId(state),
});

const mapDispatchToProps = {
  checkVendorForActivePM: checkForActivePM,
  loadFeatures,
  loadPermissions,
  loadUser: loadUserById,
  loadVehicleFacilites,
  loadVehicleList,
  loadVehicleSummary,
  loadVehicleTypes,
  loadVehicleTypesForVendor,
  loadVendor,
  loadYData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VehiclesPageResolver));
