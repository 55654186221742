const initialOpenedInfoWindows = {
  applicationModeChanges: {},
  cityInsights: {},
  containerInsights: {},
  imageUrl: '',
  routeLocations: {},
  serviceExceptions: {},
  snowRoadConditions: {},
  streetSweeperConditions: {},
  streetSweeperTrackings: {},
  triggers: {},
  vehicleInsights: {},
  vehiclePositions: {},
  vehicleTrackings: {},
  vendorLocations: {},
};

export default initialOpenedInfoWindows;
