import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const PARTICIPATION = 'Participation';
export const EXCEPTIONS = 'Exceptions';

export const WASTE_AUDIT_CONFIGURATION_TYPES = mapKeys(
  [
    { id: PARTICIPATION, name: translate('vendors.wasteAuditConfigurationTypes.participation') },
    { id: EXCEPTIONS, name: translate('vendors.wasteAuditConfigurationTypes.exceptions') },
  ],
  'id',
);
