import React from 'react';
import Switch from 'react-switch';
import { theme } from '../styles';
import { SwitchWrapper, SwitchLabel } from './styled';

interface Props {
  afterText?: string;
  beforeText?: string;
  checked: boolean;
  handleChange: (
    checked: boolean,
    event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string,
  ) => void;
  margin?: string;
  offColor?: string;
  offHandleColor?: string;
}

const ToggleSwitch: React.FC<Props> = ({
  afterText,
  beforeText,
  checked,
  handleChange,
  margin,
  offColor,
  offHandleColor,
}) => (
  <SwitchWrapper margin={margin}>
    <SwitchLabel disabled={checked}>{beforeText}</SwitchLabel>
    <Switch
      checked={checked}
      onChange={handleChange}
      handleDiameter={22}
      uncheckedIcon={false}
      checkedIcon={false}
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      offColor={offColor}
      onColor={theme.brandPrimary}
      offHandleColor={offHandleColor}
      onHandleColor={theme.grayLight}
      height={30}
      width={50}
    />
    <SwitchLabel disabled={!checked}>{afterText}</SwitchLabel>
  </SwitchWrapper>
);

ToggleSwitch.defaultProps = {
  offColor: theme.grayLight,
  offHandleColor: theme.brandPrimary,
};

export default ToggleSwitch;
