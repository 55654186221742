import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { getFormValues } from 'redux-form';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { loadRouteLocationImages, loadRouteLocationIssues, loadStopMapDetails } from 'src/routes/ducks';
import { MapGLPopupWrapper, MapGLPopupLoading } from 'src/common/components/map/MapGLPopup';
import { ROUTE_STOPS_FORM_NAME } from '../../routeStops/RouteStopsForm';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { SCHEDULED } from 'src/routes/constants';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import RouteMapRouteStopsGLPopup from './RouteMapRouteStopsGLPopup';

type Props = {
  isEditMode?: boolean;
};

const Loading: React.FC<{ latitude: number; longitude: number }> = ({ latitude, longitude }) => {
  const dispatch = useDispatch();

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={latitude}
      longitude={longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopupLoading />
    </MapGLPopupWrapper>
  );
};

export default function RouteMapRouteStopsGLPopupResolver({ isEditMode }: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { routeStops } = useSelector(state => state.routes.routeStops);
  const { routeStopsForMap } = useSelector(state => state.routes.routeStops);
  const routeStopsFormValues = useSelector(getFormValues(ROUTE_STOPS_FORM_NAME)) as {
    routeStops?: RouteStop[];
  };
  const { selectedFeature } = useSelector(state => state.routes.mapControls);

  const newAndOptimizedStopsLength =
    routeStopsFormValues?.routeStops?.filter(stop => stop.isOptimal && stop.isNew).length || 0;

  const routeStop: RouteStop = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.routeStops) {
      return undefined;
    }
    if (!isEditMode)
      return (routeStopsForMap.length ? routeStopsForMap : routeStops).find(
        routeStop => routeStop.id === selectedFeature.id,
      );

    const optimalRouteStopLength =
      routeStopsFormValues?.routeStops?.filter(stop => stop.isOptimal || stop.orderNo === JOB_PENDING_OPTIMIZATION_ID)
        .length || 0;

    const currentRouteStop = routeStopsFormValues?.routeStops
      ?.map((stop: any, index: number) => ({
        ...stop,
        orderNo:
          stop?.isOptimal || stop?.orderNo === JOB_PENDING_OPTIMIZATION_ID
            ? JOB_PENDING_OPTIMIZATION_ID
            : index + 1 - optimalRouteStopLength,
      }))
      .find(routeStop => routeStop.id === selectedFeature.id);

    return currentRouteStop;
  }, [selectedFeature, routeStops, routeStopsForMap, isEditMode, routeStopsFormValues]);

  if (!routeStop || !routeSummary) {
    return null;
  }

  const loadDependencies = () => {
    if (!selectedFeature) return Promise.reject();
    if (isEditMode && (routeStop.isNew || routeStop.isPinned)) {
      return Promise.resolve(routeStop);
    }
    const dependencies = [
      loadStopMapDetails(vendorId, routeSummary.routeId, selectedFeature.id)(dispatch),
      loadRouteLocationImages(routeSummary.routeId, selectedFeature.id)(dispatch),
      loadRouteLocationIssues(routeSummary.routeId, selectedFeature.id)(dispatch),
    ];

    return Promise.all<any>(dependencies);
  };

  const shouldLoadClosestStrobeImage =
    routeSummary.routeStatusTypeId !== SCHEDULED && (!!routeSummary.vehicleId || !!routeStop.vehicleId);

  return (
    <TypedResolver
      key={routeStop.id}
      successComponent={RouteMapRouteStopsGLPopup}
      successProps={{ newAndOptimizedStopsLength, routeStop, shouldLoadClosestStrobeImage }}
      loadingComponent={Loading}
      loadingProps={{ latitude: routeStop.displayLatitude, longitude: routeStop.displayLongitude }}
      resolve={loadDependencies}
    />
  );
}
