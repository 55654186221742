import { map } from 'lodash-es';
import moment from 'moment';
import { OPEN_DISPATCH_STATUS } from '../constants';

const getDaysLeft = (serviceDate: any) => {
  const a = moment(serviceDate, 'MM/DD/YYYY');
  const b = moment();
  return a.diff(b, 'days');
};

export const transformOpportunities = (openDispatches: any) =>
  map(openDispatches.opportunities, o => ({
    ...o,
    lastUpdatedNote: o.note,
    lastUpdatedReasonCode: o.reasonCode,
    requestedServiceDate: moment(o.originalRequestedDate).format('MM/DD/YYYY'),
    serviceDate: moment(o.serviceDate).format('MM/DD/YYYY'),
    completedDate: undefined,
    lastPickupDate: o.previousPickupServiceDate ? moment(o.previousPickupServiceDate).format('MM/DD/YYYY') : '-',
    daysLeft: o.serviceDate ? getDaysLeft(moment(o.originalRequestedDate).format('MM/DD/YYYY')) : 0,
    portalStatus: OPEN_DISPATCH_STATUS[o.status],
    note: undefined,
    reasonCode: undefined,
  }));

export const transformOpenDispatches = (openDispatches: any) => ({
  ...openDispatches,
  opportunities: transformOpportunities(openDispatches),
});

export const transformUpdateOpenDispatches = (
  rubiconPONbr: any,
  status: any,
  note: any,
  reasonCode: any,
  date: any,
  action: any,
  weightTicket: any,
  uom: any,
  token: string | number,
  vendId?: string,
  weightTicketUom?: string,
  searchFilter?: any,
  completedDate?: any,
) => ({
  workOrder: rubiconPONbr,
  status,
  note,
  reasonId: reasonCode,
  serviceDate: moment(date).format('MM/DD/YYYY'),
  action,
  weightTicketSize: weightTicket ? parseFloat(weightTicket) : null,
  unitOfMeasureTypeId: weightTicketUom ? null : uom,
  user: undefined,
  token,
  vendID: vendId || null,
  date: completedDate || null,
  weightTicketUom,
  searchFilter,
});
