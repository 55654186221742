import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { NavigationSettings } from '../interfaces/NavigationSettings';

import {
  loadNavigationSettings as doLoadNavigationSettings,
  saveNavigationSettings as doSaveNavigationSettings,
} from '../services/navigationSettings';

// Actions
const COMPLETE_LOAD = 'vendors/navigationSettings/COMPLETE_LOAD';
const COMPLETE_SAVE = 'vendors/navigationSettings/COMPLETE_SAVE';
const FAIL_LOAD = 'vendors/navigationSettings/FAIL_LOAD';
const FAIL_SAVE = 'vendors/navigationSettings/FAIL_SAVE';
const START_LOAD = 'vendors/navigationSettings/START_LOAD';
const START_SAVE = 'vendors/navigationSettings/START_SAVE';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  navigationSettings: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          navigationSettings: action.navigationSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (navigationSettings: NavigationSettings[]) => ({
  type: COMPLETE_LOAD,
  navigationSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadNavigationSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadNavigationSettingsPromise = doLoadNavigationSettings(vendorId);
  loadNavigationSettingsPromise
    .then(navigationSettings => dispatch(completeLoad(navigationSettings)))
    .catch(() => dispatch(failLoad()));

  return loadNavigationSettingsPromise;
};

export const saveNavigationSettings =
  (vendorId: number, navigationSettings: NavigationSettings[]) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveNavigationSettingsPromise = doSaveNavigationSettings(vendorId, navigationSettings);
    saveNavigationSettingsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveNavigationSettingsPromise;
  };
