import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const NUMBER_1 = 1;
export const NUMBER_2 = 2;
export const NUMBER_3 = 3;
export const LEFT_AS_TRASH = 4;
export const THANKS = 5;
export const OOPS = 6;
export const CONTAMINATED = 7;
export const DAMAGED_CONTAINER = 8;
export const RECYCLE_LESS_THAN_30_GALLONS = 9;
export const RECYCLE_30_48_GALLONS = 10;
export const RECYCLE_MORE_THAN_48_GALLONS = 11;
export const TRASH_LESS_THAN_30_GALLONS = 12;
export const TRASH_30_48_GALLONS = 13;
export const TRASH_MORE_THAN_48_GALLONS = 14;
export const DO_NO_BAG_RECYCLABLES = 15;
export const NO_PLASTIC_BAGS = 16;
export const NO_FOOD_OR_LIQUID = 17;
export const COMMERCIAL = 18;
export const RESIDENTIAL = 19;
export const ROLL_OFF = 20;
export const OTHER = 21;
export const TRUCK = 22;
export const TRUCKLOAD = 23;
export const ONE_TWENTY_FIVE_LB_SAMPLE = 24;
export const POST_SORT = 25;
export const UNLOCKED_CONTAINER = 26;
export const CARDBOARD_IN_CONTAINER = 27;
export const PASTIC_IN_CONTAINER = 28;
export const REC_ROLLTAINER_OUTSIDE = 29;
export const CARDBOARD_NOT_FLATTENED = 30;
export const ELECTRONICS = 31;
export const TANGLERS = 32;
export const STYROFOAM = 33;
export const NO_CLOTHING_OR_LINENS = 34;
export const WASTE_AUDIT_EXCEPTIONS_YARD_WASTE = 35;
export const WASTE_AUDIT_EXCEPTIONS_BULK_ITEMS = 36;
export const NO_BAGGED_GARBAGE = 37;
export const SCRAP_METAL_WOOD_FURNITURE = 38;
export const GLASS = 39;
export const SCRAP_METAL = 40;
export const NEEDLES = 41;
export const HAZARDOUS_WASTE = 42;
export const BATTERIES = 43;
export const FLAMMABLES_FUEL_TANKS_BATTERIES = 44;
export const FOAM = 45;
export const DIAPERS = 46;
export const SHREDDED_PAPER = 47;
export const TO_GO_CONTAINERS = 48;
export const MEDICAL_WASTE = 49;
export const PIZZA_BOXES = 50;
export const SCRAP_METAL_POTS_PANS = 51;
export const REJECTION_TAG = 52;
export const WARNING_TAG = 53;
export const A_WA_EXCEPTION_TYPE = 54;
export const B_WA_EXCEPTION_TYPE = 55;
export const C_WA_EXCEPTION_TYPE = 56;
export const PERCENTAGE_FULL_FROM_1_TO_25 = 57;
export const PERCENTAGE_FULL_FROM_26_TO_50 = 58;
export const PERCENTAGE_FULL_FROM_51_TO_75 = 59;
export const PERCENTAGE_FULL_FROM_76_TO_100 = 60;
export const ANIMAL_WASTE = 61;
export const LUMBER = 62;
export const DIRT_ROCKS = 63;
export const EMPTY_CONTAINER = 64;

export const WASTE_AUDIT_EXCEPTION_TYPES = mapKeys(
  [
    { id: NUMBER_1, name: '#1', technicalName: '#1' },
    { id: NUMBER_2, name: '#2', technicalName: '#2' },
    { id: NUMBER_3, name: '#3', technicalName: '#2' },
    { id: LEFT_AS_TRASH, name: translate('routes.wasteAuditStatuses.leftAsTrash'), technicalName: 'leftAsTrash' },
    { id: THANKS, name: translate('routes.wasteAuditStatuses.thanks'), technicalName: 'thanks' },
    { id: OOPS, name: translate('routes.wasteAuditStatuses.ooops'), technicalName: 'ooops' },
    { id: CONTAMINATED, name: translate('routes.wasteAuditStatuses.contaminated'), technicalName: 'contaminated' },
    {
      id: DAMAGED_CONTAINER,
      name: translate('routes.wasteAuditStatuses.damagedContainer'),
      technicalName: 'damagedContainer',
    },
    {
      id: RECYCLE_LESS_THAN_30_GALLONS,
      name: translate('routes.wasteAuditStatuses.recycleLessThan30Gallons'),
      technicalName: 'recycleLessThan30Gallons',
    },
    {
      id: RECYCLE_30_48_GALLONS,
      name: translate('routes.wasteAuditStatuses.recycle3048Gallons'),
      technicalName: 'recycle3048Gallons',
    },
    {
      id: RECYCLE_MORE_THAN_48_GALLONS,
      name: translate('routes.wasteAuditStatuses.recycleMoreThan48Gallons'),
      technicalName: 'recycleMoreThan48Gallons',
    },
    {
      id: TRASH_LESS_THAN_30_GALLONS,
      name: translate('routes.wasteAuditStatuses.trashLessThan30Gallons'),
      technicalName: 'trashLessThan30Gallons',
    },
    {
      id: TRASH_30_48_GALLONS,
      name: translate('routes.wasteAuditStatuses.trash3048Gallons'),
      technicalName: 'trash3048Gallons',
    },
    {
      id: TRASH_MORE_THAN_48_GALLONS,
      name: translate('routes.wasteAuditStatuses.trashMoreThan48Gallons'),
      technicalName: 'trashMoreThan48Gallons',
    },
    {
      id: DO_NO_BAG_RECYCLABLES,
      name: translate('routes.wasteAuditStatuses.doNotBagRecyclables'),
      technicalName: 'doNotBagRecyclables',
    },
    { id: NO_PLASTIC_BAGS, name: translate('routes.wasteAuditStatuses.noPlasticBags'), technicalName: 'noPlasticBags' },
    {
      id: NO_FOOD_OR_LIQUID,
      name: translate('routes.wasteAuditStatuses.noFoodOrLiquid'),
      technicalName: 'noFoodOrLiquid',
    },
    { id: COMMERCIAL, name: translate('routes.wasteAuditStatuses.commercial'), technicalName: 'comemrcial' },
    { id: RESIDENTIAL, name: translate('routes.wasteAuditStatuses.residential'), technicalName: 'residential' },
    { id: ROLL_OFF, name: translate('routes.wasteAuditStatuses.rollOff'), technicalName: 'rollOff' },
    { id: OTHER, name: translate('routes.wasteAuditStatuses.other'), technicalName: 'other' },
    { id: TRUCK, name: translate('routes.wasteAuditStatuses.truck'), technicalName: 'truck' },
    { id: TRUCKLOAD, name: translate('routes.wasteAuditStatuses.truckload'), technicalName: 'truckload' },
    {
      id: ONE_TWENTY_FIVE_LB_SAMPLE,
      name: translate('routes.wasteAuditStatuses.oneHundredTwentyFiveLbSample'),
      technicalName: 'oneHundredTwentyFiveLbSample',
    },
    { id: POST_SORT, name: translate('routes.wasteAuditStatuses.postSort'), technicalName: 'postSort' },
    {
      id: UNLOCKED_CONTAINER,
      name: translate('routes.wasteAuditStatuses.unlockedContainer'),
      technicalName: 'unlockedContainer',
    },
    {
      id: CARDBOARD_IN_CONTAINER,
      name: translate('routes.wasteAuditStatuses.cardboardInContainer'),
      technicalName: 'cardboardInContainer',
    },
    {
      id: PASTIC_IN_CONTAINER,
      name: translate('routes.wasteAuditStatuses.plasticInContainer'),
      technicalName: 'plasticInContainer',
    },
    {
      id: REC_ROLLTAINER_OUTSIDE,
      name: translate('routes.wasteAuditStatuses.recRolltainerOutside'),
      technicalName: 'recRolltainerOutside',
    },
    {
      id: CARDBOARD_NOT_FLATTENED,
      name: translate('routes.wasteAuditStatuses.cardboardNotFlattened'),
      technicalName: 'cardboardNotFlattened',
    },
    { id: ELECTRONICS, name: translate('routes.wasteAuditStatuses.electronics'), technicalName: 'electronics' },
    { id: TANGLERS, name: translate('routes.wasteAuditStatuses.tanglers'), technicalName: 'tanglers' },
    { id: STYROFOAM, name: translate('routes.wasteAuditStatuses.styrofoam'), technicalName: 'styrofoam' },
    { id: NO_CLOTHING_OR_LINENS, name: translate('routes.wasteAuditStatuses.textiles'), technicalName: 'textiles' },
    {
      id: WASTE_AUDIT_EXCEPTIONS_YARD_WASTE,
      name: translate('routes.wasteAuditStatuses.yardWaste'),
      technicalName: 'yardWaste',
    },
    {
      id: WASTE_AUDIT_EXCEPTIONS_BULK_ITEMS,
      name: translate('routes.wasteAuditStatuses.bulkItem'),
      technicalName: 'bulkItem',
    },
    {
      id: NO_BAGGED_GARBAGE,
      name: translate('routes.wasteAuditStatuses.baggedGarbage'),
      technicalName: 'baggedGarbage',
    },
    {
      id: SCRAP_METAL_WOOD_FURNITURE,
      name: translate('routes.wasteAuditStatuses.scrapMetalWoodFurniture'),
      technicalName: 'scrapMetalWoodFurniture',
    },
    { id: GLASS, name: translate('routes.wasteAuditStatuses.glass'), technicalName: 'glass' },
    { id: SCRAP_METAL, name: translate('routes.wasteAuditStatuses.scrapMetal'), technicalName: 'scrapMetal' },
    { id: NEEDLES, name: translate('routes.wasteAuditStatuses.needles'), technicalName: 'needles' },
    {
      id: HAZARDOUS_WASTE,
      name: translate('routes.wasteAuditStatuses.hazardousWaste'),
      technicalName: 'hazardousWaste',
    },
    { id: BATTERIES, name: translate('routes.wasteAuditStatuses.batteries'), technicalName: 'batteries' },
    {
      id: FLAMMABLES_FUEL_TANKS_BATTERIES,
      name: translate('routes.wasteAuditStatuses.flammablesFuelTanksBatteries'),
      technicalName: 'flammablesFuelTanksBatteries',
    },
    { id: FOAM, name: translate('routes.wasteAuditStatuses.foam'), technicalName: 'foam' },
    { id: DIAPERS, name: translate('routes.wasteAuditStatuses.diapers'), technicalName: 'diapers' },
    { id: SHREDDED_PAPER, name: translate('routes.wasteAuditStatuses.shreddedPaper'), technicalName: 'shreddedPaper' },
    {
      id: TO_GO_CONTAINERS,
      name: translate('routes.wasteAuditStatuses.toGoContainers'),
      technicalName: 'toGoContainers',
    },
    { id: MEDICAL_WASTE, name: translate('routes.wasteAuditStatuses.medicalWaste'), technicalName: 'medicalWaste' },
    { id: PIZZA_BOXES, name: translate('routes.wasteAuditStatuses.pizzaBoxes'), technicalName: 'pizzaBoxes' },
    {
      id: SCRAP_METAL_POTS_PANS,
      name: translate('routes.wasteAuditStatuses.scrapMetalPotsPans'),
      technicalName: 'scrapMetalPotsPans',
    },
    { id: REJECTION_TAG, name: translate('routes.wasteAuditStatuses.rejectionTag'), technicalName: 'rejectionTag' },
    { id: WARNING_TAG, name: translate('routes.wasteAuditStatuses.warningTag'), technicalName: 'warningTag' },
    { id: A_WA_EXCEPTION_TYPE, name: translate('routes.wasteAuditStatuses.A'), technicalName: 'A' },
    { id: B_WA_EXCEPTION_TYPE, name: translate('routes.wasteAuditStatuses.B'), technicalName: 'B' },
    { id: C_WA_EXCEPTION_TYPE, name: translate('routes.wasteAuditStatuses.C'), technicalName: 'C' },
    {
      id: PERCENTAGE_FULL_FROM_1_TO_25,
      name: translate('routes.wasteAuditStatuses.percentageFullFrom1To25'),
      technicalName: 'percentageFullFrom1To25',
    },
    {
      id: PERCENTAGE_FULL_FROM_26_TO_50,
      name: translate('routes.wasteAuditStatuses.percentageFullFrom26To50'),
      technicalName: 'percentageFullFrom26To50',
    },
    {
      id: PERCENTAGE_FULL_FROM_51_TO_75,
      name: translate('routes.wasteAuditStatuses.percentageFullFrom51To75'),
      technicalName: 'percentageFullFrom51To75',
    },
    {
      id: PERCENTAGE_FULL_FROM_76_TO_100,
      name: translate('routes.wasteAuditStatuses.percentageFullFrom76To100'),
      technicalName: 'percentageFullFrom76To100',
    },
    { id: ANIMAL_WASTE, name: translate('routes.wasteAuditStatuses.animalWaste'), technicalName: 'animalWaste' },
    { id: LUMBER, name: translate('routes.wasteAuditStatuses.lumber'), technicalName: 'lumber' },
    { id: DIRT_ROCKS, name: translate('routes.wasteAuditStatuses.dirtRocks'), technicalName: 'dirtRocks' },
    {
      id: EMPTY_CONTAINER,
      name: translate('routes.wasteAuditStatuses.emptyContainer'),
      technicalName: 'emptyContainer',
    },
  ],
  'id',
);
