import moment from 'moment';

import translate from '../../core/services/translate';
import { Holiday } from '../interfaces/HolidayPlanner';
import { buildRecurrenceId } from './holidayPlanner';

export const isRequired = (value: any) =>
  typeof value === 'number' || !!value ? undefined : translate('routes.holidayPlanner.isRequired');

export const isRequiredAndNotZero = (value: any) =>
  value || value !== 0 ? undefined : translate('routes.holidayPlanner.isRequired');

export const isFutureDate = (value: string) => {
  if (!value) {
    return;
  }

  const date = moment(value, 'MM/DD/YYYY');

  if (date.isSameOrBefore(moment())) {
    return translate('routes.holidayPlanner.mustBeFutureDate');
  }
};

export const isUniqueHolidayDate = (otherDates: string[]) => (date: Date | string) => {
  if (!date) {
    return;
  }

  date = moment(date, 'MM/DD/YYYY').format('MM/DD');

  if (otherDates.indexOf(date) === -1) {
    return;
  }

  return translate('routes.holidayPlanner.mustBeUniqueHolidayDate');
};

export const isUniqueRecurrence = (otherRecurrences: string[]) => (value: unknown, allValues: Holiday) => {
  const recurrenceId = buildRecurrenceId(allValues);

  if (otherRecurrences.indexOf(recurrenceId) === -1) {
    return;
  }

  return translate('routes.holidayPlanner.mustBeUniqueRecurrence');
};

export const isOtherThanHolidayDate = (value: string, allValues: Holiday) => {
  if (!value || !allValues.calculatedHolidayDate) {
    return;
  }

  const date = moment(value, 'MM/DD/YYYY').toDate();
  const calculatedHolidayDate = moment(allValues.calculatedHolidayDate, 'MM/DD/YYYY').toDate();

  if (date.getDate() === calculatedHolidayDate.getDate() && date.getMonth() === calculatedHolidayDate.getMonth()) {
    return translate('routes.holidayPlanner.mustBeDifferentFromHolidayDate');
  }
};
