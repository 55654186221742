import { BareButtonStyle } from 'src/core/components/styled';
import styled, { css } from 'styled-components';

export const DashboardTabsButtons = styled.div<{ isCollapsed?: boolean }>`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: absolute;
  z-index: 99;
  bottom: 10px;
  margin: 0 auto;
  left: 50%;
  overflow: hidden;
  border-radius: 15px;
  transition: transform 0.3s ease-in-out;

  ${props =>
    props.isCollapsed &&
    css`
      transform: translate(-50%, 0%);
    `}
`;

export const DashboardTabsButton = styled.button<{ active?: boolean }>`
  ${BareButtonStyle}
  padding: 10px 15px;
  min-width: 130px;
  white-space: nowrap;
  border: none;
  background-color: ${props => (props.active ? props.theme.brandPrimary : props.theme.brandWhite)};
  color: ${props => (props.active ? props.theme.brandWhite : props.theme.brandPrimary)};
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: ${props => props.theme.brandPrimary};
    color: ${props => props.theme.brandWhite};
  }

  &:not(:last-child) {
    border-right: 2px solid #f2f2f5;
    ${props => props.active && `border-right: 2px solid ${props.theme.brandPrimary}`}
  }
`;
