import { Fragment, MouseEvent, useState } from 'react';
import { connect } from 'react-redux';

import { ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS } from 'src/account/constants';
import {
  hasPermissionSelector, isAdminSelector,
  isAdminVendorSelector,
  isSuperAdminSelector, isVendorManagerSelector, isVendorSelector
} from 'src/account/ducks';
import { PICKUP_STATUSES } from 'src/common/constants';
import { ActionButtonTooltip, UnconnectedCheckbox } from 'src/core/components';
import { Button, Label, Text, ToggleIcon } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { DispatchBoardJobsForm } from 'src/routes/components/forms';
import {
  Action,
  ActionsContainer,
  Content,
  ContentContainer,
  DispatchBoardJobsSelectedInstructions,
  DispatchBoardJobsSelectedNoOfJobs,
  DispatchBoardJobsSelectedTextWrapper,
  DispatchBoardJobsSelectedWrapper,
  FilterContainer,
  FilterInlineWrapper,
  JobsCount,
  MapIcon,
  MapIconContainer,
  PrimaryDetails,
  RouteDate,
  RouteHeaderContainerDiv,
  RouteName,
  SecondaryDetails,
  ToggleContainer,
  UnassignedJobsFilterContainer,
} from 'src/routes/components/styled';
import { JobTypes, PICKUP_STATUS_LABEL_COLORS, SELECTED_TRANSFERRED_STOPS } from 'src/routes/constants';
import { DispatchBoardRouteJob } from 'src/routes/interfaces/DispatchBoardRouteJob';
import { AppState } from 'src/store';
import { date as formatDate } from 'src/utils/services/formatter';
import BackButton from './BackButton';
import JobActions from './JobActions';
import { ModalTypes } from './ModalContainer';
import UnassignedJobsFilterForm from './UnassignedJobsFilterForm';
import { FilterFunctions, UnassignedJobsFilters } from './useDispatchBoardFilters';

interface CommonProps {
  currentUser: any;
  driverName?: string;
  filterFunctions: FilterFunctions;
  handleDeleteJobs?: () => void;
  hasPermissionToManageScheduledJobs: boolean;
  isAdmin?: boolean;
  isAdminVendor?: boolean;
  isRubiconHauler?: boolean;
  isSuperAdmin?: boolean;
  isVendorManager?: boolean;
  jobsCountText: string;
  name?: string;
  onCancelOptimization?: () => void;
  onClose: (event: MouseEvent) => void;
  optimizedJobsLength?: number;
  routeDetails?: any;
  routeJobs?: DispatchBoardRouteJob[];
  type: JobTypes;
  vehicleTypeId: number;
}

// Note `conditionalPropType` TS alternative
interface UnassignedProps extends CommonProps {
  date?: Date | string;
  dispatchBoardSearchFilters: UnassignedJobsFilters;
  jobsCount: number;
  onDelete?: (event: MouseEvent) => void;
  onMapClick?: (event: MouseEvent) => void;
  onToggleSelectAllJobs: (isClear?: boolean) => void;
  selectedJobsCount: number;
  statusId?: number;
  toggleModal?: (modal: ModalTypes, isOpen?: boolean, isHeader?: boolean) => void;
  toggleRouteEditorModal: (value: boolean, isCreate?: boolean) => void;
  type: JobTypes.unassigned | JobTypes.onHold;
}

interface AssignedProps extends CommonProps {
  date?: Date | string;
  dispatchBoardSearchFilters?: UnassignedJobsFilters;
  jobsCount?: number;
  onDelete: (event: MouseEvent) => void;
  onMapClick: (event: MouseEvent) => void;
  onToggleSelectAllJobs?: (isClear?: boolean) => void;
  selectedJobsCount?: number;
  statusId?: number;
  toggleModal: (modal: ModalTypes, isOpen?: boolean, isHeader?: boolean) => void;
  toggleRouteEditorModal?: (value: boolean, isCreate?: boolean) => void;
  type: JobTypes;
}

type Props = UnassignedProps | AssignedProps;

const RouteHeaderContainer = (props: Props) => {
  const {
    currentUser,
    date,
    dispatchBoardSearchFilters,
    driverName,
    filterFunctions,
    handleDeleteJobs,
    hasPermissionToManageScheduledJobs,
    isAdmin,
    isAdminVendor,
    isRubiconHauler,
    isSuperAdmin,
    isVendorManager,
    jobsCount,
    jobsCountText,
    name,
    onClose,
    onDelete,
    onMapClick,
    onToggleSelectAllJobs,
    optimizedJobsLength,
    routeDetails,
    routeJobs,
    selectedJobsCount = 0,
    statusId,
    toggleModal,
    toggleRouteEditorModal,
    type,
    vehicleTypeId,
  } = props;

  const inUnassignedOrOnHold = type === JobTypes.unassigned || type === JobTypes.onHold;

  // expand filters if there are any
  const defaultIsExpanded =
    !!dispatchBoardSearchFilters &&
    (!!dispatchBoardSearchFilters.fromDate ||
      !!dispatchBoardSearchFilters.toDate ||
      !!dispatchBoardSearchFilters.wasteType ||
      !!dispatchBoardSearchFilters.searchTerm ||
      !!dispatchBoardSearchFilters.equipmentType ||
      !!dispatchBoardSearchFilters.pickupTypeId ||
      !!dispatchBoardSearchFilters.serviceZones ||
      !!dispatchBoardSearchFilters.supervisors ||
      !!dispatchBoardSearchFilters.materialTypes ||
      !!dispatchBoardSearchFilters.reasonCodeTypeId);

  const [isExpanded, setIsExpanded] = useState(defaultIsExpanded);

  const onToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleEditorModal = (event: MouseEvent, modal: ModalTypes, open: boolean) => {
    event.stopPropagation();
    !!toggleModal && toggleModal(modal, open);
  };

  const checked = selectedJobsCount > 0 && selectedJobsCount === jobsCount;
  const partial = selectedJobsCount > 0 && selectedJobsCount !== jobsCount;
  const isClear = partial || checked;

  let unassignedJobsFilterFormInitialValues;
  let dispatchBoardJobsFormInitialValues;

  if (dispatchBoardSearchFilters) {
    unassignedJobsFilterFormInitialValues = {
      dateRange: dispatchBoardSearchFilters.fromDate &&
        dispatchBoardSearchFilters.toDate && {
          from: dispatchBoardSearchFilters.fromDate,
          to: dispatchBoardSearchFilters.toDate,
        },
      wasteType: dispatchBoardSearchFilters.wasteType,
    };
    dispatchBoardJobsFormInitialValues = {
      searchTerm: dispatchBoardSearchFilters.searchTerm,
      equipmentType: dispatchBoardSearchFilters.equipmentType,
      pickupTypeId: dispatchBoardSearchFilters.pickupTypeId,
      reasonCodeTypeId: dispatchBoardSearchFilters.reasonCodeTypeId,
      serviceZones: dispatchBoardSearchFilters.serviceZones,
      supervisors: dispatchBoardSearchFilters.supervisors,
      materialTypes: dispatchBoardSearchFilters.materialTypes,
    };
  }

  const cancelOptimization = () => {
    const { onCancelOptimization } = props;
    onCancelOptimization && onCancelOptimization();
  };

  const isDeleteJobsAndAddRouteButtonVisible =
    isAdmin || isAdminVendor || isVendorManager || isSuperAdmin || (isRubiconHauler && currentUser?.roleId === 1);

  return (
    <RouteHeaderContainerDiv isOpen={isExpanded}>
      <ContentContainer margin="no">
        <BackButton onClick={onClose} type={type} padding="xxSmall no no" />
        <Content>
          <PrimaryDetails>
            <RouteName onClick={onClose}>{name}</RouteName>
            <FilterInlineWrapper>
              {inUnassignedOrOnHold && !!onToggleSelectAllJobs && (
                <UnconnectedCheckbox
                  size="small"
                  checked={checked}
                  partial={partial}
                  onChange={() => onToggleSelectAllJobs(isClear)}
                  margin="xxSmall xSmall xSmall no"
                />
              )}
              <JobsCount>{jobsCountText}</JobsCount>

              <UnconnectedCheckbox
                size="small"
                onChange={filterFunctions.onJobPriorityFilterChange}
                checked={dispatchBoardSearchFilters && dispatchBoardSearchFilters.jobPriorityTypeId === 1}
                margin="xxSmall xSmall xSmall small"
                label={translate('common.highPriority')}
              />

              {!!optimizedJobsLength && (
                <Button
                  type="button"
                  color="primary"
                  size="xSmall"
                  margin="no no no xSmall"
                  onClick={() => cancelOptimization()}
                >
                  {translate('routes.cancelOptimization')}
                </Button>
              )}
            </FilterInlineWrapper>

            {!!driverName && (
              <Text size="xSmall" margin="no no small">
                {driverName}
              </Text>
            )}

            {isExpanded && !!routeJobs && (
              <JobActions
                weightTicketCount={routeJobs.reduce(
                  (total, r) => r.weightTicketCount + total,
                  routeDetails.routeWeightTicketCount || 0,
                )}
                imageCount={routeJobs.reduce((total, r) => r.imageCount + total, routeDetails.routeImageCount || 0)}
                noteCount={routeJobs.reduce((total, r) => r.noteCount + total, routeDetails.routeNoteCount || 0)}
                issueReportedCount={routeJobs.reduce((total, r) => r.issueReportedCount + total, 0)}
                toggleModal={toggleModal}
                header
                canEditService={hasPermissionToManageScheduledJobs}
                type={type}
              />
            )}
          </PrimaryDetails>
          {type !== JobTypes.onHold && (
            <SecondaryDetails isOpen={isExpanded}>
              <RouteDate>{formatDate(date)}</RouteDate>
              {!isExpanded && (type === JobTypes.source || (type === JobTypes.unassigned && selectedJobsCount > 0)) && (
                <MapIconContainer onClick={onMapClick}>
                  <MapIcon icon="routes" />
                </MapIconContainer>
              )}
              {isExpanded && (
                <Fragment>
                  {statusId !== undefined && (
                    <Label color={PICKUP_STATUS_LABEL_COLORS[statusId.toString()]}>
                      {PICKUP_STATUSES[statusId].name}
                    </Label>
                  )}
                  {hasPermissionToManageScheduledJobs && (
                    <ActionsContainer>
                      <Action id={`edit-route-${type}-button`} onClick={e => toggleEditorModal(e, 'routeEditor', true)}>
                        <ActionButtonTooltip icon="edit" tooltip="editRoute" />
                      </Action>

                      <Action id={`delete-route-${type}-button`} onClick={onDelete}>
                        <ActionButtonTooltip icon="delete" tooltip="deleteRoute" />
                      </Action>
                    </ActionsContainer>
                  )}
                </Fragment>
              )}
            </SecondaryDetails>
          )}
        </Content>
        <ToggleContainer id={`toggle-route-${type}-button`} onClick={onToggle}>
          <ToggleIcon size={10} isOpen={isExpanded} icon={isExpanded ? 'remove' : 'add'} />
          {` ${translate('common.filter')}`}
        </ToggleContainer>
      </ContentContainer>
      {inUnassignedOrOnHold && (
        <UnassignedJobsFilterContainer padding="no">
          <UnassignedJobsFilterForm
            initialValues={unassignedJobsFilterFormInitialValues}
            filterFunctions={filterFunctions}
            form="unassignedJobsFilterForm"
          />
        </UnassignedJobsFilterContainer>
      )}
      <FilterContainer isOpen={isExpanded}>
        <DispatchBoardJobsForm
          initialValues={dispatchBoardJobsFormInitialValues}
          filterFunctions={filterFunctions}
          form={`${type}DispatchBoardJobsForm`}
          vehicleTypeId={vehicleTypeId}
        />
      </FilterContainer>
      <DispatchBoardJobsSelectedWrapper isOpen={selectedJobsCount > 0}>
        <DispatchBoardJobsSelectedTextWrapper>
          <DispatchBoardJobsSelectedNoOfJobs moreThanMaxSelected={selectedJobsCount > SELECTED_TRANSFERRED_STOPS}>
            {translate('dispatchBoard.routeBuilder.jobsSelected', {
              selectedJobs: selectedJobsCount,
            })}
          </DispatchBoardJobsSelectedNoOfJobs>
          <DispatchBoardJobsSelectedInstructions>
            {translate('dispatchBoard.routeBuilder.instructions')}
          </DispatchBoardJobsSelectedInstructions>
        </DispatchBoardJobsSelectedTextWrapper>
        {selectedJobsCount > 0 && isDeleteJobsAndAddRouteButtonVisible && (
          <>
            <Button
              line
              color="primary"
              id="add-route-button"
              disabled={selectedJobsCount > SELECTED_TRANSFERRED_STOPS || selectedJobsCount <= 0}
              onClick={() => !!toggleRouteEditorModal && toggleRouteEditorModal(true, true)}
              size="xMedium"
            >
              + {translate('routes.addRoute')}
            </Button>

            <Button
              line
              color="primary"
              id="delete-job-button"
              onClick={handleDeleteJobs}
              margin="no no no small"
              size="xMedium"
            >
              {translate('routes.deleteJobs')}
            </Button>
          </>
        )}
      </DispatchBoardJobsSelectedWrapper>
    </RouteHeaderContainerDiv>
  );
};

const mapStateToProps = (state: AppState) => ({
  hasPermissionToManageScheduledJobs: hasPermissionSelector(
    state.account.permissions,
    ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS,
  ),
  isAdmin: isAdminSelector(state.account.login),
  isRubiconAdmin: isAdminVendorSelector(state.account.login),
  isSuperAdmin: isSuperAdminSelector(state.account.login),
  isVendorManager: isVendorManagerSelector(state.account.login),
  isRubiconHauler: isVendorSelector(state.account.login),
  currentUser: state.vendors.users.user,
});

export default connect(mapStateToProps)(RouteHeaderContainer);
