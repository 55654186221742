import React from 'react';
import { Switch, Route } from 'react-router';

import { Page } from '../../common/components';
import { InvoicePageResolver } from './pages';
import { AuthRoute } from '../../account/components';

const Invoice = () => (
  <Page>
    <Switch>
      <Route exact path="/invoices/:token" component={InvoicePageResolver} />
      <AuthRoute exact path="/invoices" component={InvoicePageResolver as any} />
    </Switch>
  </Page>
);

export default Invoice;
