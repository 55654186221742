import { omit } from 'lodash-es';

import { http } from 'src/core/services/http';
import { SNOW_PLOW_ID } from 'src/fleet/constants';
import { AlternativeFleetStreetSegmentsPayload } from '../interfaces/alterativeFleetOps';

export const loadAlternativeFleetRoutes = (vendorId: number, vehicleTypeId: number) =>
  http
    .get(`/${vendorId}/alternativeFleetOperations/routeTemplatesAndDailyRoutes`, {
      params: { vendorId, vehicleTypeId },
    })
    .then(response => response.data);

export const loadAlternativeFleetSegments = (params: AlternativeFleetStreetSegmentsPayload, cancelToken: any) =>
  http
    .get(
      `/${params.vendorId}/alternativeFleetOperations/${
        params.vehicleTypeId === SNOW_PLOW_ID ? 'snowPlow' : 'streetSweeper'
      }/segments`,
      {
        params: {
          ...omit(params, ['lastActivity', 'pickupStatusTypeIds']),
          groupIds: params.groupIds?.join(','),
          priorityTypeIds: params.priorityTypeIds?.join(','),
          serviceActivityIds: params.serviceActivityIds?.join(','),
          serviceZoneIds: params.serviceZoneIds?.join(','),
        },

        cancelToken,
      },
    )
    .then(response => response.data);

export const loadAlternativeFleetSnowPlowServiceActivities = (vendorId: number) =>
  http.get(`/${vendorId}/alternativeFleetOperations/snowPlow/getServiceActivities`).then(response => response.data);

export const loadAlternativeFleetStreetSweeperServiceActivities = (vendorId: number) =>
  http
    .get(`/${vendorId}/alternativeFleetOperations/streetSweeper/getServiceActivities`)
    .then(response => response.data);
