import { map } from 'lodash-es';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const transformLoadInsightReports = (insightReports: any[]) =>
  map(insightReports, insightReport => {
    const reportType = insightReport.reportType;
    const reportName = translate(createTranslationKey(reportType, 'insights.reportTypes'));
    const reportDescription = translate(createTranslationKey(reportType, 'insights.reportTypeDescriptions'));
    const unit = translate(createTranslationKey(insightReport.unit, 'insights.reportTypeUnits'));

    return {
      ...insightReport,
      reportName,
      reportDescription,
      unit,
    };
  });

export default transformLoadInsightReports;
