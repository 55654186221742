import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { loadDrivers, loadDriverFacilites } from '../../ducks';
import { PageLoading } from '../../../common/components/styled';
import { shouldLoadPermissions, loadPermissions } from '../../../account/ducks/permissions';
import { TypedResolver } from '../../../core/components';
import { loadFeatures } from '../../../vendors/ducks';
import { DocumentTitle } from 'src/common/components';
import translate from 'src/core/services/translate';
import ResourcesPage from './ResourcesPage';

const ResourcesPageResolver: React.FC = () => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const permissionsShouldBeLoaded = useSelector(shouldLoadPermissions);

  const loadDependencies = () => {
    const dependencies: Promise<unknown>[] = [];

    if (vendorId) {
      dependencies.push(
        loadDrivers(vendorId)(dispatch),
        loadFeatures(vendorId)(dispatch),
        loadDriverFacilites(vendorId)(dispatch),
      );
    }

    if (permissionsShouldBeLoaded) {
      dependencies.push(loadPermissions()(dispatch));
    }

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('resources.resources')}</DocumentTitle>
      <TypedResolver
        loadingComponent={PageLoading}
        redirectOnError="/dashboard"
        resolve={loadDependencies}
        successComponent={ResourcesPage}
      />
    </>
  );
};

export default ResourcesPageResolver;
