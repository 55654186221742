import update from 'immutability-helper';
import { identity, reduce } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { LIMIT_PER_PAGE } from '../constants';
import {
  loadProcessedServices as doLoadProcessedServices,
  loadProcessedServicesByVendorId as doLoadProcessedServicesByVendorId,
} from '../services/loadProcessedServices';
import {
  loadRecurringServicesIssueType as doLoadProcessedServicesIssueType,
  updateAcceptSelectedPaymentServices as doUpdateAcceptSelectedPaymentServices,
  updateIssueReportRecurringServices as doUpdateIssueReportProcessedServices,
  updateIssueReportRecurringServicesByVendorId as doUpdateIssueReportProcessedServicesByVendorId,
  updateRecurringServices as doUpdateProcessedServices,
  updateRecurringServicesByVendorId as doUpdateProcessedServicesByVendorId,
  updateUndoAcceptRecurringServices as doUpdateUndoAcceptProcessedServices,
  updateUndoAcceptRecurringServicesByVendorId as doUpdateUndoAcceptProcessedServicesByVendorId,
  updateUndoReportRecurringServices as doUpdateUndoReportProcessedServices,
  updateUndoReportRecurringServicesByVendorId as doUpdateUndoReportProcessedServicesByVendorId,
} from '../services/loadRecurringServices';

//  Actions
const START_LOAD = 'payments/processedServices/START_LOAD';
const COMPLETE_LOAD = 'payments/processedServices/COMPLETE_LOAD';
const FAIL_LOAD = 'payments/processedServices/FAIL_LOAD';
const FAIL_SAVE = 'payments/processedServices/SAVE_FAIL';
const START_SAVE = 'payments/processedServices/START_SAVE';
const COMPLETE_SAVE = 'payments/processedServices/COMPLETE_SAVE';
const RESET = 'payments/processedServices/RESET';

const START_TYPE_LOAD = 'payments/issueType/START_TYPE_LOAD';
const COMPLETE_TYPE_LOAD = 'payments/issueType/COMPLETE_TYPE_LOAD';
const FAIL_TYPE_LOAD = 'payments/issueType/FAIL_TYPE_LOAD';

//  Initial State
const initialState = {
  isLoading: false,
  isSaving: false,
  limit: undefined,
  page: undefined,
  total: undefined,
  workOrdersInfo: undefined,
  pricingInfo: undefined,
  paymentInfo: undefined,
  haulerName: undefined,
  // activitiBaseUrl: undefined,
  readonly: undefined,
  errorMessage: undefined,
  issueType: undefined,
};

//  Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          limit: action.processedServices.limit,
          page: action.processedServices.page,
          total: action.processedServices.total,
          workOrdersInfo: action.processedServices.workOrdersInfo,
          pricingInfo: action.processedServices.pricingInfo,
          paymentInfo: action.processedServices.paymentInfo,
          haulerName: action.processedServices.haulerName,
          // activitiBaseUrl: action.processedServices.activitiBaseUrl,
          readonly: action.processedServices.readonly,
          errorMessage: action.processedServices.errorMessage,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          limit: undefined,
          page: undefined,
          total: undefined,
          workOrdersInfo: undefined,
          pricingInfo: undefined,
          paymentInfo: undefined,
          haulerName: undefined,
          // activitiBaseUrl: undefined,
          readonly: undefined,
          errorMessage: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          limit: action.processedServices.limit,
          page: action.processedServices.page,
          total: action.processedServices.total,
          workOrdersInfo: action.processedServices.workOrdersInfo,
          pricingInfo: action.processedServices.pricingInfo,
          paymentInfo: action.processedServices.paymentInfo,
          haulerName: action.processedServices.haulerName,
          // activitiBaseUrl: action.processedServices.activitiBaseUrl,
          readonly: action.processedServices.readonly,
          errorMessage: action.processedServices.errorMessage,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    case START_TYPE_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_TYPE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          issueType: action.issueType,
        },
      });

    case FAIL_TYPE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          issueType: action.issueType,
        },
      });

    default:
      return state;
  }
};

//  Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (processedServices: any) => ({
  type: COMPLETE_LOAD,
  processedServices,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (processedServices: any) => ({
  type: COMPLETE_SAVE,
  processedServices,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startTypeLoad = () => ({
  type: START_TYPE_LOAD,
});

const completeTypeLoad = (issueType: any) => ({
  type: COMPLETE_TYPE_LOAD,
  issueType,
});

const failTypeLoad = () => ({
  type: FAIL_TYPE_LOAD,
});

export const loadProcessedServicesIssueType = (vendorId: number, token: string) => (dispatch: Dispatch) => {
  dispatch(startTypeLoad());
  if (token) {
    return doLoadProcessedServicesIssueType(token)
      .then(issueType => dispatch(completeTypeLoad(issueType)))
      .catch(() => dispatch(failTypeLoad()));
  }
};

export const loadProcessedServices = (vendorId: number, token: string, date: Date | string, page: number, limit: number) => (
  dispatch: Dispatch,
) => {
  dispatch(startLoad());
  if (token) {
    return doLoadProcessedServices(token, date || '', page || 1, limit || LIMIT_PER_PAGE)
      .then(processedServices => dispatch(completeLoad(processedServices)))
      .catch(() => dispatch(failLoad()));
  } else if (vendorId) {
    return doLoadProcessedServicesByVendorId(vendorId)
      .then(processedServices => dispatch(completeLoad(processedServices)))
      .catch(() => dispatch(failLoad()));
  }
};

export const updateProcessedServices = (
  requestProcessedServicesObj: any,
  vendorId: number,
  token: string,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startSave());
  if (token) {
    return doUpdateProcessedServices(requestProcessedServicesObj, token, page || 1, limit || LIMIT_PER_PAGE)
      .then(processedServices => dispatch(completeSave(processedServices)))
      .catch(() => dispatch(failSave()));
  } else if (vendorId) {
    return doUpdateProcessedServicesByVendorId(requestProcessedServicesObj, vendorId)
      .then(processedServices => dispatch(completeSave(processedServices)))
      .catch(() => dispatch(failSave()));
  }
};

export const updateIssueReportProcessedServices = (
  requestProcessedServicesObj: any,
  vendorId: number,
  token: string,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startSave());
  if (token) {
    return doUpdateIssueReportProcessedServices(requestProcessedServicesObj, token, page || 1, limit || LIMIT_PER_PAGE)
      .then(processedServices => dispatch(completeSave(processedServices)))
      .catch(() => dispatch(failSave()));
  } else if (vendorId) {
    return doUpdateIssueReportProcessedServicesByVendorId(requestProcessedServicesObj, vendorId)
      .then(processedServices => dispatch(completeSave(processedServices)))
      .catch(() => dispatch(failSave()));
  }
};

export const updateUndoReportProcessedServices = (
  requestProcessedServicesObj: any,
  vendorId: number,
  token: string,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startSave());
  if (token) {
    return doUpdateUndoReportProcessedServices(requestProcessedServicesObj, token, page || 1, limit || LIMIT_PER_PAGE)
      .then(processedServices => dispatch(completeSave(processedServices)))
      .catch(() => dispatch(failSave()));
  } else if (vendorId) {
    return doUpdateUndoReportProcessedServicesByVendorId(requestProcessedServicesObj, vendorId)
      .then(processedServices => dispatch(completeSave(processedServices)))
      .catch(() => dispatch(failSave()));
  }
};

export const updateUndoAcceptProcessedServices = (
  requestProcessedServicesObj: any,
  vendorId: number,
  token: string,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startSave());
  if (token) {
    return doUpdateUndoAcceptProcessedServices(requestProcessedServicesObj, token, page || 1, limit || LIMIT_PER_PAGE)
      .then(processedServices => dispatch(completeSave(processedServices)))
      .catch(() => dispatch(failSave()));
  } else if (vendorId) {
    return doUpdateUndoAcceptProcessedServicesByVendorId(requestProcessedServicesObj, vendorId)
      .then(processedServices => dispatch(completeSave(processedServices)))
      .catch(() => dispatch(failSave()));
  }
};

export const updateAcceptSelectedProcessedPaymentServices = (
  requestProcessedServicesObj: any,
  vendorId: number,
  token: string,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startSave());
  if (token) {
    return doUpdateAcceptSelectedPaymentServices(requestProcessedServicesObj, token, page || 1, limit || LIMIT_PER_PAGE)
      .then(processedServices => dispatch(completeSave(processedServices)))
      .catch(() => dispatch(failSave()));
  }
};

// Selectors
const getVendorPaymentMethodTypes = (paymentMethodStates: any, paymentMethodId: any) => {
  const vendorPaymentMethodTypes = reduce(
    paymentMethodStates,
    (paymentInfo, paymentMethodState) => {
      if (paymentMethodState.paymentMethod === paymentMethodId) {
        paymentInfo.push(paymentMethodState);
      }
      return paymentInfo;
    },
    [] as any[],
  );
  return vendorPaymentMethodTypes;
};

export const vendorPaymentMethodTypes = createSelector(getVendorPaymentMethodTypes, identity);

export const resetProcessedService = () => ({
  type: RESET,
});
