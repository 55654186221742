import React, { useState, Fragment } from 'react';

import {
  Field,
  formValueSelector,
  getFormValues,
  InjectedFormProps,
  isDirty,
  reduxForm,
  setSubmitFailed,
  submit,
  WrappedFieldProps,
} from 'redux-form';
import { connect, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { AppState } from '../../../store';
import {
  ActionButtonTooltip,
  Input,
  Dropdown,
  DatePicker,
  Switch,
  PopoverWrapper,
  SliderWithTooltip,
} from '../../../core/components';
import { ALL } from '../../constants/holidayPlanner';
import { createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { Holiday, HolidayPlannerSettings, RawHolidayOptionalId } from '../../interfaces/HolidayPlanner';
import {
  HolidayPlannerCancel,
  HolidayPlannerSave,
  HolidayPlannerScheduler,
  HolidayPlannerSpinner,
  HolidayPlannerTableCellAbsolute,
  HolidayPlannerTableRow,
  HolidayPlannerSliderWrapper,
} from '../styled/HolidayPlanner';
import { HolidayPlannerSelectRoutesModalResolver } from '../modals';
import { loadRouteDatesImpacted } from '../../services/holidayPlanner';
import { POSTPONEMENT_TYPES, DATE_TYPES, DAYS_COUNT } from '../../constants/holidayPlanner';
import { TableCell, TableActionButton, Text, FormError, Button, Popover } from '../../../core/components/styled';
import { WEEKDAYS_STARTING_SUNDAY } from '../../../core/constants/weekdays';
import * as Ducks from '../../ducks/holidayPlanner';
import * as Utils from '../../utils/holidayPlanner';
import * as Validations from '../../utils/holidayPlannerValidations';
import MONTHS from '../../../common/constants/months';
import translate from '../../../core/services/translate';
import { isDateValidValidator } from 'src/utils/services/validator';

interface PropsWithoutReduxForm {
  form?: string;
  holidayPlannerFormValues?: Holiday;
  holidayPlannerSettings?: HolidayPlannerSettings;
  isFormDirty?: boolean;
  otherHolidayDates: string[];
  otherRecurrences: string[];
  requiresSchedule?: boolean;
  routeTemplatesCountToDisplay?: number | string;
  saveInProgress?: boolean;
  toggleEditMode(routeTemplatesCountDisplayed: number | string): void;
  vendorId?: number;
  currentError?: string;
  currentNumberOfDaysIsNull: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<Holiday, PropsWithoutReduxForm>;

const HolidayPlannerModalTableRowEdit: React.FC<Props> = ({
  change,
  form,
  handleSubmit,
  holidayPlannerFormValues,
  holidayPlannerSettings,
  isFormDirty,
  otherHolidayDates,
  otherRecurrences,
  requiresSchedule = false,
  routeTemplatesCountToDisplay,
  saveInProgress = false,
  toggleEditMode,
  vendorId,
  currentError,
  currentNumberOfDaysIsNull,
}) => {
  const dispatch = useDispatch();
  const selector = formValueSelector(form);

  const {
    calculatedHolidayDate,
    calculatedPostponeDate,
    id,
    isActive,
    isDefault,
    isExactPostpone,
    isRecurrent,
    postponementType,
    postponeNumberOfDays,
    recurrenceDayOfWeek,
    recurrenceDayType,
    recurrenceMonthOfYear,
    routeDatesImpacted,
    routeTemplatesCount,
  } = useSelector((state: AppState) =>
    selector(
      state,
      'calculatedHolidayDate',
      'calculatedPostponeDate',
      'id',
      'isActive',
      'isDefault',
      'isExactPostpone',
      'isRecurrent',
      'postponementType',
      'postponeNumberOfDays',
      'recurrenceDayOfWeek',
      'recurrenceDayType',
      'recurrenceMonthOfYear',
      'routeDatesImpacted',
      'routeTemplatesCount',
    ),
  );

  const routeTemplatesCountValue = routeTemplatesCount >= 0 && routeTemplatesCount !== null ? routeTemplatesCount : ALL;

  const getRouteDatesImpacted = (routeDatesImpacted?: any[]) => {
    return routeDatesImpacted
      ? routeDatesImpacted
          .map((routeDate: string) => {
            return moment(routeDate).format('MM/DD');
          })
          .join(', ')
      : moment(calculatedPostponeDate).format('MM/DD');
  };

  const [isHolidayPlannerSelectRoutesModal, setHolidayPlannerSelectRoutesModal] = useState(false);
  const [numberOfDaysFromDateIsZero, setNumberOfDaysFromDateIsZero] = useState(currentNumberOfDaysIsNull);
  const [routeDatesImpactedLoadError, setRouteDatesImpactedLoadError] = useState(false);
  const [routeDatesImpactedString, setRouteDatesImpactedString] = useState(getRouteDatesImpacted(routeDatesImpacted));
  const [isLoadingRouteDatesImpacted, setIsLoadingRouteDatesImpacted] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const [numberOfDaysFromDate, setNumberOfDaysFromDate] = useState(
    postponeNumberOfDays
      ? postponementType.id === POSTPONEMENT_TYPES.AFTER
        ? postponeNumberOfDays
        : -postponeNumberOfDays
      : 0,
  );
  const hasNumberOfDaysFromDate1After = numberOfDaysFromDate === 1;

  const setNumberOfDaysFromDateValue = (postponeNumberOfDays: number) => {
    setNumberOfDaysFromDate(postponeNumberOfDays);
    setNumberOfDaysFromDateIsZero(postponeNumberOfDays === 0);
    setRouteDatesImpactedLoadError(false);
    setIsFormChanged(true);
    dispatch(change('postponeNumberOfDays', Math.abs(postponeNumberOfDays)));
    dispatch(
      change('postponementType.id', postponeNumberOfDays < 0 ? POSTPONEMENT_TYPES.BEFORE : POSTPONEMENT_TYPES.AFTER),
    );

    if (postponeNumberOfDays === 1 && holidayPlannerFormValues) {
      const holidayPlanner = {
        ...Utils.transformHoliday(holidayPlannerFormValues),
        postponeNumberOfDays,
        routeShiftingDays: holidayPlannerFormValues.routeShiftingDays,
        postponementType: holidayPlannerFormValues.postponementType || {
          id: POSTPONEMENT_TYPES.AFTER,
          name: translate('routes.holidayPlanner.postponementTypes.after'),
          technicalName: translate('routes.holidayPlanner.postponementTypes.after'),
        },
      };
      loadRouteDates(holidayPlanner);
    }
  };

  const [routeTemplatesCountDisplayed, setRouteTemplatesCountDisplayed] = useState(
    routeTemplatesCountToDisplay && (routeTemplatesCountToDisplay >= 0 || routeTemplatesCountToDisplay === ALL)
      ? routeTemplatesCountToDisplay
      : routeTemplatesCountValue,
  );

  const recurrenceTypes = useSelector((state: AppState) =>
    state.routes.holidayPlanner.recurrenceTypes.map((type: any) => ({
      label: translate(`routes.holidayPlanner.recurrenceTypes.${type.technicalName}`),
      value: type.id,
    })),
  );

  const recurrenceDays = React.useMemo(
    () =>
      WEEKDAYS_STARTING_SUNDAY.map(day => ({
        label: day.name,
        value: day.id,
      })),
    [],
  );

  const dateTypes = React.useMemo(
    () =>
      DATE_TYPES.map(dateType => ({
        label: dateType.name,
        value: dateType.value,
      })),
    [],
  );

  const daysCount = React.useMemo(
    () =>
      DAYS_COUNT.map(day => ({
        label: day.name,
        value: day.value,
      })),
    [],
  );

  const recurrenceMonths = React.useMemo(
    () =>
      Object.keys(MONTHS).map((key, index) => ({
        label: translate(MONTHS[key].translationKey),
        value: index + 1,
      })),
    [],
  );

  const isUniqueHolidayDate = React.useMemo(
    () => Validations.isUniqueHolidayDate(otherHolidayDates),
    [otherHolidayDates],
  );

  const isUniqueRecurrence = React.useMemo(() => Validations.isUniqueRecurrence(otherRecurrences), [otherRecurrences]);

  const triggerSubmit = React.useCallback(() => {
    if (!routeDatesImpactedLoadError) {
      setNumberOfDaysFromDateIsZero(numberOfDaysFromDate === 0);
      setIsFormChanged(false);
      dispatch(submit(form));
    }
  }, [dispatch, form, numberOfDaysFromDate, routeDatesImpactedLoadError]);

  React.useEffect(() => {
    if (!id || !requiresSchedule) {
      return;
    }

    dispatch(change('isActive', true));

    if (isExactPostpone) {
      dispatch(setSubmitFailed(form, 'postponeToDate'));
    } else {
      dispatch(setSubmitFailed(form, 'postponementType.id', 'postponeNumberOfDays'));
    }

    dispatch(Ducks.unmarkHolidayFromActivation(id));
  }, [id, requiresSchedule, isExactPostpone, change, dispatch, form]);

  const selectedHolidayDate = moment(calculatedHolidayDate, 'MM/DD/YYYY').toDate();

  const openHolidayPlannerSelectRoutesModal = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setHolidayPlannerSelectRoutesModal(true);
  };

  const closeHolidayPlannerSelectRoutesModal = () => {
    setHolidayPlannerSelectRoutesModal(false);
  };

  const setRouteTemplatesCount = (selectedTemplatesLength: number | string) => {
    setRouteTemplatesCountDisplayed(selectedTemplatesLength);
  };

  const getSliderLabelColor = (postponementTypeID: Number) => {
    return postponementType && postponementType.id === postponementTypeID && numberOfDaysFromDate !== 0
      ? 'primary'
      : undefined;
  };

  const daysFormatter = (daysCount: number) =>
    `${Math.abs(daysCount)} ${
      Math.abs(daysCount) === 1 ? translate('routes.holidayPlanner.day') : translate('routes.holidayPlanner.days')
    }`;

  const onHolidayDateChange = (value: any) => {
    const exactDayOfMonth = Number(moment(value, 'MM/DD/YYYY').format('D'));
    const exactMonthOfYear = Number(moment(value, 'MM/DD/YYYY').format('M'));

    setIsFormChanged(true);

    if (holidayPlannerFormValues && hasNumberOfDaysFromDate1After) {
      const holidayPlanner = {
        ...Utils.transformHoliday(holidayPlannerFormValues),
        exactDayOfMonth,
        exactMonthOfYear,
      };
      loadRouteDates(holidayPlanner);
    }
  };

  const onRouteShiftingDaysChange = (value: any) => {
    if (holidayPlannerFormValues) {
      const holidayPlanner = {
        ...Utils.transformHoliday(holidayPlannerFormValues),
        routeShiftingDays: value,
      };
      loadRouteDates(holidayPlanner);
    }
  };

  const hasRecurrenceDayTypeId = recurrenceDayType && recurrenceDayType.id;
  const hasRecurrenceDayOfWeek = recurrenceDayOfWeek >= 0;

  const onRecurrenceDayTypeChange = (value: any) => {
    if (holidayPlannerFormValues && hasNumberOfDaysFromDate1After && hasRecurrenceDayOfWeek && recurrenceMonthOfYear) {
      const holidayPlanner = {
        ...Utils.transformHoliday(holidayPlannerFormValues),
        recurrenceDayType: { id: value },
      };
      loadRouteDates(holidayPlanner);
    }

    setIsFormChanged(true);
  };

  const onRecurrenceDayOfWeekChange = (value: any) => {
    if (holidayPlannerFormValues && hasNumberOfDaysFromDate1After && hasRecurrenceDayTypeId && recurrenceMonthOfYear) {
      const holidayPlanner = {
        ...Utils.transformHoliday(holidayPlannerFormValues),
        recurrenceDayOfWeek: value,
      };
      loadRouteDates(holidayPlanner);
    }

    setIsFormChanged(true);
  };

  const onRecurrenceMonthOfYearChange = (value: any) => {
    if (holidayPlannerFormValues && hasNumberOfDaysFromDate1After && hasRecurrenceDayTypeId && hasRecurrenceDayOfWeek) {
      const holidayPlanner = {
        ...Utils.transformHoliday(holidayPlannerFormValues),
        recurrenceMonthOfYear: value,
      };
      loadRouteDates(holidayPlanner);
    }

    setIsFormChanged(true);
  };

  const onIsRecurrentToggle = (value: any) => {
    if (holidayPlannerFormValues && hasNumberOfDaysFromDate1After) {
      const holidayPlannerFormValuesChanged = {
        ...holidayPlannerFormValues,
        isRecurrent: value,
      };
      const holidayPlanner = {
        ...Utils.transformHoliday(holidayPlannerFormValuesChanged),
      };
      loadRouteDates(holidayPlanner);
    }

    setIsFormChanged(true);
  };

  const loadRouteDates = (holidayPlanner: RawHolidayOptionalId) => {
    const useBusinessDays = holidayPlannerSettings ? holidayPlannerSettings.useBusinessDays : false;
    const holidayPlannerUpdated = {
      ...holidayPlanner,
      routeShiftingDays: holidayPlanner.routeShiftingDays === null ? 0 : holidayPlanner.routeShiftingDays,
    };
    setIsLoadingRouteDatesImpacted(true);

    if (vendorId && holidayPlanner)
      loadRouteDatesImpacted(vendorId, holidayPlannerUpdated, useBusinessDays)
        .then(data => {
          setRouteDatesImpactedString(getRouteDatesImpacted(data));
          setRouteDatesImpactedLoadError(false);
        })
        .catch(error => {
          const errorCode = error && error.response && error.response.data && error.response.data.code;

          switch (errorCode) {
            case 'InvalidHolidayPlannerRouteDatesImpacted':
              setRouteDatesImpactedLoadError(true);
              break;

            default:
              setRouteDatesImpactedLoadError(false);
              createErrorNotification(translate('routes.holidayPlanner.alertMessages.loadRouteDatesImpactedError'));
              break;
          }
        })
        .finally(() => {
          setIsLoadingRouteDatesImpacted(false);
        });
  };

  const isRouteShiftingDaysDisabled =
    (isRecurrent &&
      ((recurrenceDayType && !hasRecurrenceDayTypeId) ||
        typeof recurrenceDayOfWeek !== 'number' ||
        !recurrenceMonthOfYear)) ||
    (!isRecurrent && !calculatedHolidayDate);

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <HolidayPlannerTableRow alignItems="baseline" isInEditMode>
          <TableCell width="50%" padding="no" wrap="wrap" alignItems="baseline" hasRightBorder>
            <TableCell width="50%" padding="no small no no" wrap="wrap">
              <Text block weight="medium" align="left" size="large" margin="no no sMedium no">
                {translate('common.holiday')}
              </Text>
              <Field
                name="name"
                component={Input}
                validate={[Validations.isRequired]}
                props={{
                  raisedLabel: true,
                  margin: 'no',
                  width: '100%',
                  label: translate('routes.holidayPlanner.holidayName'),
                  placeholder: translate('routes.holidayPlanner.holidayNameExample'),
                  disabled: isDefault,
                }}
              />
              <Field
                name="isActive"
                component={Switch}
                props={{
                  label: translate('common.active'),
                  margin: 'sMedium no no no',
                  width: '100%',
                }}
              />
              <Field
                name="isRecurrent"
                component={Switch}
                props={{
                  label: translate('routes.holidayPlanner.recurrent'),
                  disabled: isDefault,
                  margin: 'small no no no',
                  width: '100%',
                }}
                onSwitch={onIsRecurrentToggle}
              />
              <PopoverWrapper
                triggerButton={
                  <Button
                    id="open-select-routes-button"
                    color="primary"
                    onClick={(event: any) => openHolidayPlannerSelectRoutesModal(event)}
                    margin="medium no no no"
                    disabled={!id || !isActive || (isFormDirty && !isDefault)}
                  >
                    {translate('routes.holidayPlanner.selectRoutes')} ({id ? routeTemplatesCountDisplayed : 0})
                  </Button>
                }
                popoverContent={
                  isFormDirty &&
                  !isDefault && (
                    <Popover>
                      <Text block weight="medium" margin="xxSmall no xxSmall">
                        {translate('routes.holidayPlanner.alertMessages.saveHolidayBeforeSelectingRoutes')}
                      </Text>
                    </Popover>
                  )
                }
                size="large"
              />
            </TableCell>
            <TableCell width="50%" padding="no" wrap="wrap" alignItems="baseline">
              <Text block weight="medium" align="left" size="large" margin="no no sMedium no">
                {translate('common.date')}
              </Text>
              {isRecurrent ? (
                <Fragment>
                  <Field
                    name="recurrenceDayType.id"
                    component={Dropdown}
                    validate={[Validations.isRequired]}
                    props={{
                      raisedLabel: true,
                      margin: 'no',
                      width: '100%',
                      label: translate('routes.holidayPlanner.recurrence'),
                      placeholder: translate('routes.holidayPlanner.recurrenceExample'),
                      options: recurrenceTypes,
                      disabled: isDefault,
                    }}
                    onChange={onRecurrenceDayTypeChange}
                  />
                  <Field
                    name="recurrenceDayOfWeek"
                    component={Dropdown}
                    validate={[Validations.isRequired]}
                    props={{
                      raisedLabel: true,
                      margin: 'sMedium no no no',
                      width: '100%',
                      label: translate('routes.holidayPlanner.day'),
                      placeholder: translate('routes.holidayPlanner.dayExample'),
                      options: recurrenceDays,
                      disabled: isDefault,
                    }}
                    onChange={onRecurrenceDayOfWeekChange}
                  />
                  <Field
                    name="recurrenceMonthOfYear"
                    component={Dropdown}
                    validate={[Validations.isRequired]}
                    props={{
                      raisedLabel: true,
                      margin: 'sMedium no no no',
                      width: '100%',
                      label: translate('routes.holidayPlanner.month'),
                      placeholder: translate('routes.holidayPlanner.monthExample'),
                      options: recurrenceMonths,
                      disabled: isDefault,
                    }}
                    onChange={onRecurrenceMonthOfYearChange}
                  />
                  <Field
                    name="asyncValidateField"
                    component={({ meta: { submitFailed, error } }: WrappedFieldProps) => {
                      if (submitFailed && error) {
                        return <FormError>{error}</FormError>;
                      }

                      return null;
                    }}
                    validate={[isUniqueRecurrence]}
                  />
                </Fragment>
              ) : (
                <Field
                  name="calculatedHolidayDate"
                  component={DatePicker}
                  validate={[Validations.isRequired, isUniqueHolidayDate, isDateValidValidator]}
                  onChange={onHolidayDateChange}
                  props={{
                    raisedLabel: true,
                    margin: 'no',
                    width: '100%',
                    label: translate('routes.holidayPlanner.holidayDate'),
                    placeholder: translate('routes.holidayPlanner.holidayDateExample'),
                    mask: 'MMMM Do',
                    disabled: isDefault,
                    disabledDays: [
                      {
                        before: moment().add(1, 'day').toDate(),
                      },
                    ],
                  }}
                />
              )}
            </TableCell>
          </TableCell>

          <TableCell width="50%" padding="no" wrap="wrap" alignItems="baseline" hasLeftBorder>
            <HolidayPlannerTableCellAbsolute>
              <TableActionButton onClick={() => toggleEditMode(routeTemplatesCountDisplayed)}>
                <HolidayPlannerCancel>
                  <ActionButtonTooltip iconSize="sMedium" icon="close" tooltip="cancel" />
                </HolidayPlannerCancel>
              </TableActionButton>

              {saveInProgress ? (
                <HolidayPlannerSpinner bigger />
              ) : (
                <TableActionButton onClick={triggerSubmit}>
                  <HolidayPlannerSave>
                    <ActionButtonTooltip iconSize="medium" icon="check" tooltip="save" />
                  </HolidayPlannerSave>
                </TableActionButton>
              )}
            </HolidayPlannerTableCellAbsolute>

            <HolidayPlannerScheduler>
              <Text block weight="medium" align="left" size="large" margin="no no sMedium no">
                {translate('routes.holidayPlanner.reScheduleRoutes')}
              </Text>
              <TableCell vertical align="center" minHeight="0" width="100%" padding="no">
                <Field
                  name="isExactPostpone"
                  component={Dropdown}
                  props={{
                    raisedLabel: true,
                    margin: 'no',
                    width: '50%',
                    label: translate('routes.holidayPlanner.dateType'),
                    options: dateTypes,
                    disabled: !isActive,
                  }}
                  onChange={() => setIsFormChanged(true)}
                />
              </TableCell>

              {isExactPostpone ? (
                <TableCell vertical align="center" minHeight="0" width="100%" padding="sMedium no no no">
                  <Field
                    name="postponeToDate"
                    component={DatePicker}
                    validate={
                      !isActive
                        ? null
                        : [
                            Validations.isRequired,
                            Validations.isFutureDate,
                            Validations.isOtherThanHolidayDate,
                            isDateValidValidator,
                          ]
                    }
                    onChange={() => setIsFormChanged(true)}
                    props={{
                      raisedLabel: true,
                      margin: 'no',
                      width: '50%',
                      label: translate('routes.holidayPlanner.selectSpecificDate'),
                      placeholder: `10/31/${new Date().getFullYear()}`,
                      disabled: !isActive,
                      disabledDays:
                        !isRecurrent && !!calculatedHolidayDate
                          ? [
                              {
                                before: moment().toDate(),
                              },
                              (day: any) =>
                                day.getDate() === selectedHolidayDate.getDate() &&
                                day.getMonth() === selectedHolidayDate.getMonth(),
                            ]
                          : [],
                    }}
                  />
                </TableCell>
              ) : (
                <TableCell wrap="wrap" minHeight="0" width="100%" padding="sMedium no no no">
                  <Text block singleLine align="left" size="small" lineHeight="20px" color="grayDark" cursor="default">
                    {translate('routes.holidayPlanner.numberOfDays')}
                  </Text>
                  <Field name="postponementType.id" component={Input} type="hidden" />
                  <Field
                    name="postponeNumberOfDays"
                    component={Input}
                    type="hidden"
                    validate={[Validations.isRequired, Validations.isRequiredAndNotZero]}
                  />
                  <HolidayPlannerSliderWrapper disabled={!isActive}>
                    <Text
                      block
                      size="medium"
                      margin="no sMedium no no"
                      color={getSliderLabelColor(POSTPONEMENT_TYPES.BEFORE)}
                    >
                      {translate('routes.holidayPlanner.beforeTheHoliday')}
                    </Text>
                    <SliderWithTooltip
                      disabled={!isActive}
                      max={7}
                      min={-3}
                      onChange={setNumberOfDaysFromDateValue}
                      startPoint={0}
                      tipFormatter={daysFormatter}
                      value={numberOfDaysFromDate}
                    />
                    <Text
                      block
                      size="medium"
                      margin="no no no sMedium"
                      color={getSliderLabelColor(POSTPONEMENT_TYPES.AFTER)}
                    >
                      {translate('routes.holidayPlanner.afterTheHoliday')}
                    </Text>
                  </HolidayPlannerSliderWrapper>
                  {numberOfDaysFromDateIsZero && <FormError>{translate('routes.holidayPlanner.isRequired')}</FormError>}

                  {numberOfDaysFromDate === 1 ? (
                    <TableCell width="100%" padding="no">
                      <TableCell vertical align="center" minHeight="0" width="50%" padding="sMedium no no no">
                        <PopoverWrapper
                          triggerButton={
                            <Field
                              name="routeShiftingDays"
                              component={Dropdown}
                              props={{
                                disabled: !isActive || isRouteShiftingDaysDisabled,
                                label: translate('routes.holidayPlanner.shiftRoutesForAdditionalDays'),
                                margin: 'no',
                                options: daysCount,
                                raisedLabel: true,
                                width: '100%',
                              }}
                              onChange={onRouteShiftingDaysChange}
                            />
                          }
                          popoverContent={
                            isRouteShiftingDaysDisabled && (
                              <Popover>
                                <Text block weight="medium" margin="xxSmall no xxSmall">
                                  {translate('routes.holidayPlanner.alertMessages.selectHolidayDate')}
                                </Text>
                              </Popover>
                            )
                          }
                          width="100%"
                        />
                      </TableCell>
                      <TableCell vertical align="center" minHeight="0" width="50%" padding="lMedium no no sMedium">
                        {routeDatesImpactedLoadError ? (
                          <FormError>
                            {translate('routes.holidayPlanner.alertMessages.invalidHolidayPlannerRouteDatesImpacted')}
                          </FormError>
                        ) : (
                          <Text size="small" fontStyle="italic" color="grayDark">
                            {holidayPlannerFormValues && holidayPlannerFormValues.routeShiftingDays === 0
                              ? translate('routes.holidayPlanner.newServicDate')
                              : translate('routes.holidayPlanner.routeDatesImpacted')}
                            :{' '}
                            {isLoadingRouteDatesImpacted ? (
                              <HolidayPlannerSpinner position="absolute" margin="no no no small" />
                            ) : !isRouteShiftingDaysDisabled ? (
                              routeDatesImpactedString
                            ) : (
                              '-'
                            )}
                          </Text>
                        )}
                      </TableCell>
                    </TableCell>
                  ) : numberOfDaysFromDate > 1 ? (
                    <TableCell width="100%" padding="sMedium no no no">
                      <Text size="small" fontStyle="italic" color="grayDark">
                        {translate('routes.holidayPlanner.after1DayMessage')}
                      </Text>
                    </TableCell>
                  ) : numberOfDaysFromDate < 0 ? (
                    <TableCell width="100%" padding="sMedium no no no">
                      <Text size="small" fontStyle="italic" color="grayDark">
                        {translate('routes.holidayPlanner.before1DayMessage')}
                      </Text>
                    </TableCell>
                  ) : null}
                </TableCell>
              )}

              {(!routeDatesImpactedLoadError || currentError) &&
                (currentError ? (
                  <FormError>{!isFormChanged ? currentError : undefined}</FormError>
                ) : (
                  <Field
                    name="asyncValidateFieldPostpone"
                    component={({ meta: { submitFailed, error, submitting } }: WrappedFieldProps) => {
                      if (submitFailed && error && !submitting) {
                        return <FormError>{!isFormChanged ? error : undefined}</FormError>;
                      }

                      return null;
                    }}
                  />
                ))}
            </HolidayPlannerScheduler>
          </TableCell>
        </HolidayPlannerTableRow>
      </form>
      {isHolidayPlannerSelectRoutesModal && (
        <HolidayPlannerSelectRoutesModalResolver
          closeModal={closeHolidayPlannerSelectRoutesModal}
          holidayId={id}
          isDefaultHoliday={isDefault}
          setRouteTemplatesCountDisplayed={setRouteTemplatesCount}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: AppState, ownProps: PropsWithoutReduxForm) => {
  const holidayPlannerFormValues = (getFormValues as any)(`${ownProps.form}`)(state);
  const holidayPlannerSettings = (getFormValues as any)('holidayPlannerSettings')(state);

  return {
    holidayPlannerFormValues,
    holidayPlannerSettings,
    isFormDirty: isDirty(`${ownProps.form}`)(state),
    vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  };
};

export default connect(mapStateToProps)(
  reduxForm<Holiday, PropsWithoutReduxForm>({
    enableReinitialize: false,
  })(HolidayPlannerModalTableRowEdit),
);
