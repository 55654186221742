import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { push } from 'connected-react-router';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from 'src/store';
import { ButtonLink, Panel, PanelSectionGroup } from 'src/core/components/styled';
import { Customer } from 'src/customers/interfaces/Customers';
import { CustomerDetails, CustomerLocations } from './customerDetailsPageSections';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { STREET } from 'src/common/constants';
import translate from 'src/core/services/translate';

interface MatchParams {
  customerId?: string;
}

interface LocationState {
  accountNumber: string;
  accountStatusTypeId: string;
  customerStatusTypeIds: string;
  customerTypeId: string;
  searchTerm: string;
}

interface Props extends RouteComponentProps<MatchParams, {}, LocationState> {
  currentCustomersPageUrl?: string;
  customer?: Customer;
  isLoading: boolean;
  push: (url: string) => void;
}

class CustomerDetailsPage extends PureComponent<Props> {
  render() {
    const {
      customer,
      match: {
        params: { customerId },
      },
      isLoading,
    } = this.props;

    const goBackToPreviousPage = () => {
      const { push, currentCustomersPageUrl } = this.props;

      return currentCustomersPageUrl ? push(currentCustomersPageUrl) : push('/customers/customers');
    };

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButtonAction id="back-button" onClick={goBackToPreviousPage}>
                <PageBackButtonIcon />
              </PageBackButtonAction>
              <PageTitle>{translate('customers.customerDetails')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions>
            {customer?.customerTypeId !== STREET && customerId && (
              <ButtonLink
                id="customer-service-history-button"
                line
                to={`/customers/customers/${customerId}/serviceHistory`}
                color="primary"
                margin="no xSmall no no"
              >
                {translate('customers.serviceHistory')}
              </ButtonLink>
            )}
          </PageActions>
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoading}>
            <CustomerDetails />
            <CustomerLocations />
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  customer: state.customers.customer.customer,
  isLoading: state.customers.customer.isLoading,
  currentCustomersPageUrl: state.customers.customer.currentCustomersPageUrl,
});

const mapDispatchToProps = {
  push,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsPage));
