import React, { createContext, useContext, useRef, useState, ReactNode, useCallback, useEffect } from 'react';
import MapView from "@arcgis/core/views/MapView";
import ArcGISMap from '@arcgis/core/Map';
import RouteLayer from '@arcgis/core/layers/RouteLayer';
import Credential from "@arcgis/core/identity/Credential";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import IdentityManager from "@arcgis/core/identity/IdentityManager";
import esriRequest from '@arcgis/core/request';

interface EsriMapContextType {
  map: __esri.Map | null;
  view: __esri.MapView | null;
  createMapView: (ref: HTMLDivElement, basemap: string) => void;
  mapRef: React.MutableRefObject<HTMLDivElement | null>;
  routelayerState: __esri.RouteLayer | null;
  credential: Credential | null;
  hostedLayers: any[];
}

const EsriMapContext = createContext<EsriMapContextType | null>(null);

export const useEsriMap = (): EsriMapContextType => {
  const context = useContext(EsriMapContext);
  if (!context) {
    throw new Error("useEsriMap must be used within an EsriMapProvider");
  }
  return context;
};

interface EsriMapProviderProps {
  children: ReactNode;
}

const oauthInfo = new OAuthInfo({
  // appId: process.env.REACT_APP_ESRI_APPID as string,npm
  // popup: false,
  // popupCallbackUrl: process.env.REACT_APP_ESRI_CALLBACK as string,
  appId: "yYtIAqA2wzT7PWUJ",
  popup: false,
  popupCallbackUrl: "https://hauler.qa.aws.rubicon.com/dashboard/all-ops/esri"
});

export const EsriMapProvider: React.FC<EsriMapProviderProps> = ({ children }) => {

  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<__esri.Map | null>(null);
  const [view, setView] = useState<__esri.MapView | null>(null);
  const [routelayerState, setRouteLayerState] = useState<__esri.RouteLayer | null>(null);
  const [credential, setCredential] = useState<Credential | null>(null);
  const [hostedLayers, setHostedLayers] = useState([]);

  const checkStatus = async () => {
    let credential : Promise<__esri.Credential> | __esri.Credential

    try {
      credential = await IdentityManager.checkSignInStatus(`${oauthInfo.portalUrl}/sharing`);
    } catch (error) {
      credential  = await IdentityManager.getCredential(`${oauthInfo.portalUrl}/sharing`, {
        error: null as any,
        oAuthPopupConfirmation: false,
        token: null as any,
      })
    }
    setCredential(credential);
  };

  useEffect(() => {
    IdentityManager.registerOAuthInfos([oauthInfo]);
    checkStatus();
  }, []);

  const createMapView = useCallback((ref: HTMLDivElement, basemap: string) => {
    if (ref && !view) {
      const routeLayer = new RouteLayer();

      const mapInstance = new ArcGISMap({
        basemap: basemap,
        layers: [routeLayer],
      });

      const viewInstance = new MapView({
        container: ref,
        map: mapInstance,
        center: [-118, 34],
        zoom: 2,
      });

      setMap(mapInstance);
      setView(viewInstance);
      setRouteLayerState(routeLayer);
    }
  }, [view]);

  useEffect(() => {
    async function fetchHostedLayers() {
      if (credential) {
        try {
          // Get the user's token
          // Query the ArcGIS REST API to get the user's content
          const response = await esriRequest(
            `https://www.arcgis.com/sharing/rest/content/users/${credential.userId}`,
            {
              query: {
                token: credential.token,
                f: 'json',
                num: 100, // Adjust the number of items to retrieve as needed
              },
            }
          );

          // Filter the response to get only hosted feature layers
          const hostedFeatureLayers = response.data.items.filter((item: { owner: string; userId: string; type: string }) => {
            return item.type === 'Feature Service' && item.owner === credential.userId;
          });

          const dropdown = hostedFeatureLayers.map((x: { title: string; value: number }) => {
            return { label: x.title, value: x };
          });

          // Set the hosted layers to state
          setHostedLayers(dropdown);

        } catch (error) {
          console.error('Error fetching hosted layers:', error);
        }
      }
    }

    // Call the function to fetch hosted layers
    fetchHostedLayers();

    // Cleanup function
    return () => {
      // Perform cleanup if needed
    };
  }, [credential]);

  return (
    <EsriMapContext.Provider value={{ map, view, createMapView, mapRef, routelayerState, credential, hostedLayers }}>
      {children}
    </EsriMapContext.Provider>
  );
};
