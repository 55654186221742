import { reduce, find, omit } from 'lodash-es';
import { RESIDENTIAL } from '../../fleet/constants/vehicleTypes';
import { BIN_NOT_OUT_PICKED_UP, BIN_NOT_OUT, PICKED_UP } from '../../routes/constants';

export const transformLoadExceptionConfigurations = (exceptionConfigurations: any, vehicleTypeTechnicalName: any) => {
  if (vehicleTypeTechnicalName === RESIDENTIAL) {
    const containerNotOut = find(exceptionConfigurations, { id: BIN_NOT_OUT });
    const binNotOutPickedUp = {
      ...containerNotOut,
      id: BIN_NOT_OUT_PICKED_UP,
      technicalName: 'binNotOutPickedUp',
    };
    return reduce(
      exceptionConfigurations,
      (configs, exception) => {
        if (exception.id === BIN_NOT_OUT || exception.id === PICKED_UP) {
          if (!find(configs, { id: BIN_NOT_OUT_PICKED_UP })) {
            configs.push(binNotOutPickedUp);
          }
          return configs;
        }

        configs.push(exception);
        return configs;
      },
      [] as any[],
    );
  }

  return exceptionConfigurations;
};

export const transformSaveExceptionConfigurations = (exceptionConfigurations: any, vehicleTypeTechnicalName: any) => {
  if (vehicleTypeTechnicalName === RESIDENTIAL) {
    const containerNotOutPickedUp = find(exceptionConfigurations, { id: BIN_NOT_OUT_PICKED_UP });
    const containerNotOut = {
      ...omit(containerNotOutPickedUp, ['name', 'technicalName']),
      id: BIN_NOT_OUT,
    };
    const pickedUp = {
      ...omit(containerNotOutPickedUp, ['name', 'technicalName']),
      id: PICKED_UP,
    };
    return reduce(
      exceptionConfigurations,
      (configs, exception) => {
        if (exception.id === BIN_NOT_OUT_PICKED_UP) {
          configs.push(containerNotOut);
          configs.push(pickedUp);
          return configs;
        }

        configs.push(exception);
        return configs;
      },
      [] as any[],
    );
  }
  return exceptionConfigurations;
};
