import { FC, ReactNode, useState } from 'react';

import { PanelSectionGroup, Grid, GridColumn, Text, PanelSection } from '../../core/components/styled';
import { Icon } from '../../core/components';
import {
  SectionCollapseButton,
  SectionHeader,
  SectionHeaderContent,
  SectionHeaderTitle,
  SectionHeaderTitleWrapper,
  SectionsHeaderFilters,
} from 'src/common/components/styled';

interface CollapsiblePageSectionProps {
  content: ReactNode;
  title: string;
  subtitle?: string;
  subtitleColor?: string;
  headerAction?: ReactNode;
  headerContent?: ReactNode;
  headerFilters?: ReactNode;
  header?: ReactNode;
  sectionLoading?: boolean;
  sectionHasBorder?: boolean;
  headerActionVisible?: boolean;
  sectionPadding?: string;
  sectionDisabled?: boolean;
  collapsedInitially?: boolean;
}

const CollapsiblePageSection: FC<CollapsiblePageSectionProps> = ({
  content,
  title,
  headerAction,
  headerContent,
  headerFilters,
  headerActionVisible = false,
  sectionLoading = false,
  sectionHasBorder = true,
  collapsedInitially = false,
  sectionDisabled = false,
  subtitle,
  subtitleColor = 'grayDark',
  sectionPadding = 'sMedium no',
}) => {
  const [sectionCollapsed, setSectionCollapsed] = useState<boolean>(collapsedInitially);

  return (
    <PanelSection vertical padding={sectionPadding} isLoading={sectionLoading} withBorder={sectionHasBorder}>
      <PanelSectionGroup padding="no" width="100%">
        <Grid multiLine>
          <GridColumn size="12/12" padding="no">
            <SectionHeader>
              <SectionHeaderTitleWrapper>
                <SectionHeaderTitle>
                  <Text size="xLarge">{title}</Text>

                  {!!subtitle && (
                    <Text color={subtitleColor} size="small" margin="xxSmall no no">
                      {subtitle}
                    </Text>
                  )}
                </SectionHeaderTitle>

                {!!headerAction && (sectionCollapsed || headerActionVisible) && headerAction}
              </SectionHeaderTitleWrapper>

              <SectionHeaderContent>
                {headerContent}

                {!!headerFilters && (
                  <SectionsHeaderFilters hidden={!sectionCollapsed}>{headerFilters}</SectionsHeaderFilters>
                )}

                <SectionCollapseButton
                  disabled={sectionDisabled}
                  collapsed={sectionCollapsed}
                  onClick={() => setSectionCollapsed(!sectionCollapsed)}
                >
                  <Icon icon="arrowDown" />
                </SectionCollapseButton>
              </SectionHeaderContent>
            </SectionHeader>
          </GridColumn>

          {!sectionDisabled && sectionCollapsed && (
            <GridColumn size="12/12" padding="no">
              {content}
            </GridColumn>
          )}
        </Grid>
      </PanelSectionGroup>
    </PanelSection>
  );
};

export default CollapsiblePageSection;
