import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { getWorkFlowTypes } from 'src/finance/services/workFlowTypes';

// Actions
const START_LOAD_WORK_FLOW_TYPES = 'finance/billing/workFlowTypes/START_LOAD_WORK_FLOW_TYPES';
const COMPLETE_LOAD_WORK_FLOW_TYPES = 'finance/billing/workFlowTypes/COMPLETE_LOAD_WORK_FLOW_TYPES';
const FAIL_LOAD_WORK_FLOW_TYPES = 'finance/billing/workFlowTypes/FAIL_LOAD_WORK_FLOW_TYPES';

// Initial state
const initialState: {
  isLoading: boolean;
  workFlowTypes: TechnicalType[];
} = {
  isLoading: false,
  workFlowTypes: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_WORK_FLOW_TYPES:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_WORK_FLOW_TYPES:
      return update(state, {
        $merge: {
          isLoading: false,
          workFlowTypes: action.workFlowTypes,
        },
      });

    case FAIL_LOAD_WORK_FLOW_TYPES:
      return update(state, {
        $merge: {
          isLoading: false,
          workFlowTypes: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD_WORK_FLOW_TYPES,
});

const completeLoad = (workFlowTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD_WORK_FLOW_TYPES,
  workFlowTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD_WORK_FLOW_TYPES,
});

export const loadWorkFlowTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const getWorkFlowTypesPromise = getWorkFlowTypes();
  getWorkFlowTypesPromise
    .then((workFlowTypes: any[]) => dispatch(completeLoad(workFlowTypes)))
    .catch(() => dispatch(failLoad()));
  return getWorkFlowTypesPromise;
};
