import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from 'src/store';
import { ActionButtonTooltip } from '../../../../core/components';
import { date, dateAndTime } from 'src/utils/services/formatter';
import { Label, TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { PICKUP_STATUSES, SCHEDULED } from 'src/common/constants';
import {
  PICKUP_STATUS_LABEL_COLORS,
  PICKUP_TYPE_PICKUP,
  ROUTE_PICKUP_TYPES,
  ROUTE_STATUSES_BY_ID,
} from 'src/routes/constants';
import { ServiceHistory } from '../../../interfaces/ServiceHistory';
import {
  RouteLocationImagesModalResolver,
  RouteLocationIssuesModalResolver,
  RouteLocationNotesModalResolver,
} from 'src/routes/components/modals';
import { WeightTicketsResolver } from 'src/routes/components/pages/routes/routePageSections';
import translate from 'src/core/services/translate';

export const AdhocServiceHistoryTableRow: React.FC<ServiceHistory> = serviceHistory => {
  const {
    address,
    customerName,
    driverName,
    imageCount,
    issueReportedCount,
    jobType,
    locationName,
    noteCount,
    pickupStatus,
    pickupStatusId,
    reasonCodeTypeId,
    routeId,
    routeLocationId,
    routeName,
    serviceName,
    serviceDate,
    vehicleName,
    wasteAuditType,
    wasteMaterialTypeName,
    weightTicketCount,
    workOrderNumber,
  } = serviceHistory;
  const reasonCodeTypes = useSelector((state: AppState) => state.common.reasonCodeTypes.reasonCodeTypes);
  const reasonCode = reasonCodeTypes?.length > 0 ? reasonCodeTypes.find(code => code?.id === reasonCodeTypeId) : '';

  const [isRouteLocationNotesModalOpen, setIsRouteLocationNotesModalOpen] = React.useState<boolean>(false);
  const [isRouteLocationImagesModalOpen, setIsRouteLocationImagesModalOpen] = React.useState<boolean>(false);
  const [isRouteLocationIssuesModalOpen, setIsRouteLocationIssuesModalOpe] = React.useState<boolean>(false);
  const [isWeightTicketModalOpen, setIsWeightTicketModalOpen] = React.useState<boolean>(false);

  const openRouteLocationNotesModal = () => {
    setIsRouteLocationNotesModalOpen(true);
  };
  const closeRouteLocationNotesModal = () => {
    setIsRouteLocationNotesModalOpen(false);
  };

  const openRouteLocationIssuesModal = () => {
    setIsRouteLocationIssuesModalOpe(true);
  };

  const closeRouteLocationIssuesModal = () => {
    setIsRouteLocationIssuesModalOpe(false);
  };

  const openRouteLocationImagesModal = () => {
    setIsRouteLocationImagesModalOpen(true);
  };

  const closeRouteLocationImagesModal = () => {
    setIsRouteLocationImagesModalOpen(false);
  };

  const openWeightTicketModal = () => {
    setIsWeightTicketModalOpen(true);
  };

  const closeWeightTicketModal = () => {
    setIsWeightTicketModalOpen(false);
  };

  return (
    <>
      <TableRow>
        <TableCell vertical width="20%">
          <Text weight="light" size="small" margin="no no xxSmall" title={address} doubleLine>
            {address}
          </Text>
        </TableCell>
        <TableCell vertical width="15%" align="center">
          <Text block weight="medium" size="small" margin="no no xxSmall" singleLine title={serviceName}>
            {serviceName}
          </Text>
          <Text weight="light" size="small" margin="no no xxSmall" singleLine title={wasteMaterialTypeName}>
            {wasteMaterialTypeName}
          </Text>
        </TableCell>

        <TableCell width="11%">
          <Text>
            {!!serviceDate && pickupStatus === ROUTE_STATUSES_BY_ID[SCHEDULED].technicalName
              ? date(serviceDate)
              : !!serviceDate
              ? dateAndTime(serviceDate)
              : ''}
          </Text>
        </TableCell>

        <TableCell width="12%" vertical align="center">
          <Label color={PICKUP_STATUS_LABEL_COLORS[pickupStatusId.toString()]}>
            {PICKUP_STATUSES[pickupStatusId].name}
          </Label>
        </TableCell>

        <TableCell vertical align="center" width="14%">
          <Text block weight="medium" size="small" margin="no" singleLine>
            {routeName}
          </Text>

          {!!vehicleName && (
            <Text weight="light" margin="xxSmall no no" singleLine>
              {vehicleName}
            </Text>
          )}

          {!!driverName && (
            <Text weight="light" margin="xxSmall no no" singleLine>
              {driverName}
            </Text>
          )}
        </TableCell>

        <TableCell vertical align="center" width="10%" padding="defaultCellVertical no">
          <Text block weight="medium" size="small" margin="no" singleLine>
            {jobType
              ? jobType === PICKUP_TYPE_PICKUP
                ? translate('routes.pickupTypes.pickup')
                : ROUTE_PICKUP_TYPES[jobType].name
              : undefined}
          </Text>

          {!!reasonCode && (
            <Text weight="light" size="small" margin="xxSmall no no">
              {reasonCode?.name}
            </Text>
          )}
        </TableCell>
        <TableCell vertical width="10%">
          <Text block weight="light" size="xSmall" margin="no no xxSmall" singleLine>
            {workOrderNumber}
          </Text>
        </TableCell>

        <TableCell width="8%" align="right">
          {!!issueReportedCount && (
            <TableActionButton onClick={openRouteLocationIssuesModal}>
              <ActionButtonTooltip icon="issue" tooltip="issues" content={issueReportedCount} />
            </TableActionButton>
          )}
          {!!weightTicketCount && (
            <TableActionButton onClick={openWeightTicketModal}>
              <ActionButtonTooltip icon="ticket" tooltip="tickets" content={weightTicketCount} rotationAngle="-45" />
            </TableActionButton>
          )}

          {!!noteCount && (
            <TableActionButton onClick={openRouteLocationNotesModal}>
              <ActionButtonTooltip icon="note" tooltip="notes" content={noteCount} />
            </TableActionButton>
          )}
          {!!imageCount && (
            <TableActionButton onClick={openRouteLocationImagesModal}>
              <ActionButtonTooltip icon="image" tooltip="images" content={imageCount} />
            </TableActionButton>
          )}
        </TableCell>
      </TableRow>
      {!!isRouteLocationNotesModalOpen && (
        <RouteLocationNotesModalResolver
          modalTitle={customerName}
          modalSubTitle={locationName}
          routeId={routeId}
          routeLocationId={routeLocationId}
          siteAddress={address}
          closeModal={closeRouteLocationNotesModal}
          isReadOnly
        />
      )}
      {!!isRouteLocationIssuesModalOpen && (
        <RouteLocationIssuesModalResolver
          routeId={routeId}
          routeLocationId={routeLocationId}
          modalTitle={customerName}
          modalSubTitle={locationName}
          closeModal={closeRouteLocationIssuesModal}
          wasteAuditType={wasteAuditType}
        />
      )}
      {!!isRouteLocationImagesModalOpen && (
        <RouteLocationImagesModalResolver
          routeId={routeId}
          routeLocationId={routeLocationId}
          closeModal={closeRouteLocationImagesModal}
        />
      )}
      {!!isWeightTicketModalOpen && (
        <WeightTicketsResolver
          routeId={routeId}
          closeModal={closeWeightTicketModal}
          routeLocationId={routeLocationId}
          modalTitle={customerName}
          modalSubTitle={locationName}
          siteAddress={address}
          isModal
        />
      )}
    </>
  );
};
