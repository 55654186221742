import React from 'react';
import { formValueSelector, reduxForm, InjectedFormProps } from 'redux-form';
import { useSelector } from 'react-redux';
import { map } from 'lodash-es';

import { AppState } from '../../../store';
import { BulkDeclineAwardsFormData } from '../../interfaces/Opportunities';
import { ButtonSet, Button, Container } from '../../../core/components/styled';
import { Dropdown, TypedField, Input } from '../../../core/components';
import { isRequired } from '../../../utils/services/validator';
import { OTHER, REASONS } from '../../constants';
import translate from '../../../core/services/translate';

const formSelector = formValueSelector('bulkDeclineAwards');

interface ComponentProps {
  onCancel(pristine: boolean): void;
}

interface PropsWithoutReduxForm extends ComponentProps {}

type Props = PropsWithoutReduxForm & InjectedFormProps<BulkDeclineAwardsFormData, PropsWithoutReduxForm>;

const BulkDeclineAwardsForm: React.FC<Props> = ({ form, handleSubmit, onCancel, pristine }) => {
  const reason = useSelector((state: AppState) => formSelector(state, 'reason'));

  const reasonOptions = map(REASONS, reasonOption => ({
    label: reasonOption.name,
    value: reasonOption.id,
  }));
  const isNoteFieldrequired = reason === OTHER ? [isRequired] : undefined;
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Container>
        <TypedField
          name="reason"
          component={Dropdown}
          props={{
            options: reasonOptions,
            label: translate('opportunity.selectReasonType'),
            margin: 'medium small small',
          }}
          validate={[isRequired]}
        />
      </Container>
      <Container>
        <TypedField
          name="notes"
          component={Input}
          validate={isNoteFieldrequired}
          props={{
            margin: 'medium small xLarge',
            label: translate('opportunity.opportunities.reason'),
          }}
        />
      </Container>
      <ButtonSet margin="medium no no">
        <Button type="submit" color="primary">
          {translate('opportunity.submit')}
        </Button>
        <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
          {translate('common.cancel')}
        </Button>
      </ButtonSet>
    </form>
  );
};

export default reduxForm<BulkDeclineAwardsFormData, PropsWithoutReduxForm>({
  form: 'bulkDeclineAwards',
})(BulkDeclineAwardsForm);
