import { omit } from 'lodash-es';

import { ALERT_STATUS_ACTIVE, CITY_ALERT_TYPES, CITY_ALERT_TYPE_ID } from '../constants/locationAndCityAlerts';
import { http } from 'src/core/services/http';
import {
  LocationAndCityAlertsForList,
  LocationAndCityAlertsFormValues,
  LocationAndCityAlertsParams,
} from '../interfaces/LocationAndCityAlerts';
import { TODAY_FORMATTED, THREE_DAYS_BEFORE_TODAY } from 'src/core/constants';

const getSearchParams = (searchParams: LocationAndCityAlertsParams) => {
  return {
    ...omit(searchParams, ['noCityAlertTypeSelected', 'noLocationAlertTypeSelected']),
    statusTypeIds: searchParams.statusTypeIds?.toString().split(',').map(Number) || [ALERT_STATUS_ACTIVE],
    cityAlertTypeIds: searchParams.cityAlertTypeIds?.toString().split(',').map(Number),
    locationAlertTypeIds: searchParams.locationAlertTypeIds?.toString().split(',').map(Number),
    startDate: searchParams.startDate || THREE_DAYS_BEFORE_TODAY,
    endDate: searchParams.endDate || TODAY_FORMATTED,
  };
};

export const loadLocationAndCityAlertsForMap = (searchParams: LocationAndCityAlertsParams) =>
  http
    .post(`/alerts/map`, {
      ...getSearchParams(searchParams),
    })
    .then(response => response.data);

export const loadLocationAndCityAlertsForList = (searchParams: LocationAndCityAlertsParams) =>
  http
    .post(`/alerts/list`, {
      ...getSearchParams(searchParams),
    })
    .then(response => {
      const alerts = response.data.map((alert: LocationAndCityAlertsForList) => ({
        ...alert,
        cityAlertTypeName:
          alert.alertTypeId === CITY_ALERT_TYPE_ID && alert.cityAlertTypeId
            ? CITY_ALERT_TYPES[alert.cityAlertTypeId].name
            : alert.title || '',
        routeName: alert.routeNames?.length > 0 && alert.routeNames[0],
      }));

      return alerts;
    });

export const saveLocationAndCityAlert = (payload: LocationAndCityAlertsFormValues) =>
  http.put('/alerts', payload).then(response => response.data);

export const loadLocationAndCityAlert = (alertId: number, alertTypeId: number) =>
  http
    .get(`/alerts/${alertId}`, {
      params: { alertTypeId },
    })
    .then(response => response.data);
