import React, { PureComponent } from 'react';

import { size } from 'lodash-es';
import humps from 'humps';
import moment from 'moment';

import { ActionButtonTooltip } from 'src/core/components';
import { CommunityInsightItem } from '../../interfaces/communityInsights';
import { CommunityInsightsIcon } from './../styled';
import { CommunityInsightsNoteModal } from './../modals';
import { getBlobIdFromUrl } from 'src/utils/imageHelpers';
import { INSIGHT_ICONS } from 'src/common/constants';
import { MATERIAL_CONTAMINATION_ID } from '../../constants';
import { TableActionButton, TableCell, TableRow } from 'src/core/components/styled';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import RouteImagesModal from 'src/routes/components/modals/RouteImagesModal';
import translate from 'src/core/services/translate';

interface Props extends CommunityInsightItem {
  isCleared: boolean;
  isCluster: boolean;
  handleRowClick(id: string): void;
  clearInsight(id: number): Promise<void>;
}

interface State {
  isInsightImageModalOpen: boolean;
  isInsightNoteModalOpen: boolean;
}

class CommunityInsightsPageTableRow extends PureComponent<Props, State> {
  readonly state: State = {
    isInsightNoteModalOpen: false,
    isInsightImageModalOpen: false,
  };

  openInsightNoteModal = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ isInsightNoteModalOpen: true });
  };

  closeInsightNoteModal = () => {
    this.setState({ isInsightNoteModalOpen: false });
  };

  openInsightImageModal = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ isInsightImageModalOpen: true });
  };

  closeInsightImageModal = () => {
    this.setState({ isInsightImageModalOpen: false });
  };

  clickRow = () => this.props.handleRowClick(this.props.mapSelectorId);

  clearInsight = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    this.props.clearInsight(this.props.id);
  };

  render() {
    const {
      address,
      contaminationSubTypes = [],
      images: issueImages,
      imageUrl,
      rawImageUrl,
      isCleared,
      isCluster,
      locationFlaggingType,
      locationFlagTimeStamp,
      note,
      routeDate,
      routeName,
      statusId,
      vehicleName,
    } = this.props;

    const { isInsightNoteModalOpen, isInsightImageModalOpen } = this.state;
    const locationFlaggingTypeName = translate(
      createTranslationKey(locationFlaggingType.technicalName, 'vendors.locationFlags'),
    );

    const isMaterialContamination = locationFlaggingType.id === MATERIAL_CONTAMINATION_ID;
    const hasMaterialSubtypes = isMaterialContamination && size(contaminationSubTypes) > 0;
    const contaminationSubTypesName = hasMaterialSubtypes
      ? contaminationSubTypes
          .map(contaminationSubType =>
            translate(createTranslationKey(contaminationSubType, 'insights.contaminationSubType')),
          )
          .join(', ')
      : undefined;
    const contaminationSubTypeFormatted = hasMaterialSubtypes && (
      <>
        :<br /> {contaminationSubTypesName}
      </>
    );

    const imageInfo = {
      contaminationSubTypesName,
      issueName: locationFlaggingTypeName,
      locationName: address,
      routeName,
      timeStamp: locationFlagTimeStamp,
    };

    const images = !!issueImages?.length
      ? issueImages.map(image => ({
          ...imageInfo,
          imageUrl: image.imageUrl,
          rawImageUrl: image?.rawImageUrl || image.imageUrl,
          imageBlobId: image.imageBlobId,
        }))
      : !!imageUrl
      ? [
          {
            ...imageInfo,
            imageUrl,
            rawImageUrl: rawImageUrl || imageUrl,
            imageBlobId: getBlobIdFromUrl(imageUrl),
          },
        ]
      : [];

    return (
      <>
        <TableRow onClick={this.clickRow} height={70}>
          <TableCell width="12%">{moment(routeDate).format('MM/DD/YYYY')}</TableCell>

          <TableCell width="25%">
            <CommunityInsightsIcon src={INSIGHT_ICONS[humps.camelize(locationFlaggingType.technicalName)]} />
            {locationFlaggingTypeName}
            {contaminationSubTypeFormatted}
          </TableCell>

          <TableCell width="17%">{address}</TableCell>

          <TableCell width="17%">{routeName}</TableCell>

          <TableCell width="10%">{vehicleName}</TableCell>

          <TableCell width="8%">
            {statusId === 1 || isCleared ? translate('insights.cleared') : translate('insights.open')}
          </TableCell>

          <TableCell align="right" width="11%">
            {note && (
              <TableActionButton onClick={this.openInsightNoteModal}>
                <ActionButtonTooltip icon="note" tooltip="viewNote" />
              </TableActionButton>
            )}

            {!!images.length && (
              <TableActionButton onClick={isCluster ? this.clickRow : this.openInsightImageModal}>
                <ActionButtonTooltip
                  icon="image"
                  tooltip={images.length > 1 ? 'viewImages' : 'viewImage'}
                  content={images.length > 0 ? images.length : undefined}
                />
              </TableActionButton>
            )}

            {statusId === 0 && !isCleared && (
              <TableActionButton onClick={this.clearInsight}>
                <ActionButtonTooltip icon="close" tooltip="clear" />
              </TableActionButton>
            )}
          </TableCell>
        </TableRow>

        {isInsightNoteModalOpen && !!note && (
          <CommunityInsightsNoteModal
            closeModal={this.closeInsightNoteModal}
            modalTitle={translate('common.note')}
            note={note}
          />
        )}

        {!!isInsightImageModalOpen && <RouteImagesModal closeModal={this.closeInsightImageModal} images={images} />}
      </>
    );
  }
}

export default CommunityInsightsPageTableRow;
