import moment from 'moment';

import { dateFormat } from 'src/utils/services/validator';
import { http } from '../../core/services/http';
import { OPERATIONAL_FACILITY } from 'src/common/constants';
import { TODAY } from 'src/core/constants';
import { transformLoadBinColors } from './transformLoadBinColors';
import { Vehicle } from '../interfaces/Vehicles';

export const loadVehicle = (vehicleId: number) => http.get(`vehicles/${vehicleId}`).then(response => response.data);

export const loadVehicleFacilites = (vendorId: number) =>
  http
    .get('facilities', {
      params: {
        vendorId,
        facilityTypeId: OPERATIONAL_FACILITY,
      },
    })
    .then(response => response.data);

export const createVehicle = (vehicle: any) =>
  http
    .post(`vendors/${vehicle.vendorId}/vehicles`, vehicle)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const updateVehicle = (vehicle: any) =>
  http
    .put(`vehicles/${vehicle.id}`, vehicle)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const updateVehicleConfig = (vehicle: any) =>
  http
    .post(`vehicles/${vehicle.vehicle.id}/modelConfiguration`, vehicle)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const clearAiModelConfiguration = (vehicleId: number) =>
  http.delete(`vehicles/${vehicleId}/modelConfiguration`).then(response => response.data);

export const loadVendorAiModelConfiguration = (vehicleId: number) =>
  http.get(`/vehicles/${vehicleId}/modelConfiguration`).then(response => response.data);

export const deleteVehicle = (vehicleId: number) =>
  http.delete(`vehicles/${vehicleId}`).then(response => response.data);

export const deleteVehicles = (vendorId: number, deletedVehiclesIds: number[]) =>
  http
    .delete(`/vendors/${vendorId}/vehicles`, {
      params: {
        vehicleIds: deletedVehiclesIds.toString(),
      },
    })
    .then(response => response.data);

export const assignVehicles = (vendorId: number, vehicles: Vehicle[]) =>
  http.put(`vendors/${vendorId}/vehicles`, vehicles);

export const loadDriverFacilites = (vendorId: number) =>
  http
    .get('facilities', {
      params: {
        vendorId,
        facilityTypeId: OPERATIONAL_FACILITY,
        isActive: true,
      },
    })
    .then(response => response.data);

export const loadBinColors = () =>
  http.get('/common/systemTypes/binColors').then(response => transformLoadBinColors(response.data));

export const checkVehiclesHaveRoutesInRange = (
  vendorId: number,
  vehicleIds: number[],
  startDate?: Date | string,
  endDate?: Date | string,
) =>
  http
    .get<any[]>(`vendors/${vendorId}/vehiclesHaveRoutesInRange`, {
      params: {
        vehicleIds: vehicleIds.join(','),
        startDate: startDate ? moment(startDate).format(dateFormat) : TODAY,
        endDate: endDate ? moment(endDate).format(dateFormat) : undefined,
      },
    })
    .then(response => response.data);
