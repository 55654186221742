import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { DriverSafetyConfigurations } from '../interfaces/DriverSafety';
import {
  loadDriverSafetyConfigurations as doLoadDriverSafetyConfigurations,
  loadDriverSafetyConfigurationsForVehicle as doLoadDriverSafetyConfigurationsForVehicle,
  saveDriverSafetyConfigurations as doSaveDriverSafetyConfigurations,
  saveDriverSafetyConfigurationsForVehicle as doSaveDriverSafetyConfigurationsForVehicle,
} from '../services/driverSafety';
// Actions
const START_LOAD = 'vendors/driverSafetyConfigurations/START_LOAD';
const COMPLETE_LOAD = 'vendors/driverSafetyConfigurations/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/driverSafetyConfigurations/FAIL_LOAD';
const START_SAVE = 'vendors/driverSafetyConfigurations/START_SAVE';
const COMPLETE_SAVE = 'vendors/driverSafetyConfigurations/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/driverSafetyConfigurations/FAIL_SAVE';
const RESET = 'vendors/driverSafetyConfigurations/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  driverSafetyConfigurations: [] as DriverSafetyConfigurations[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          driverSafetyConfigurations: action.driverSafetyConfigurations,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (driverSafetyConfigurations: DriverSafetyConfigurations[]) => ({
  type: COMPLETE_LOAD,
  driverSafetyConfigurations,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadDriverSafetyConfigurations =
  (vendorId: number, vehicleTypeId?: number, shouldUpdateState?: boolean) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadDriverSafetyConfigurationsPromise = doLoadDriverSafetyConfigurations(vendorId, vehicleTypeId);
    loadDriverSafetyConfigurationsPromise
      .then(driverSafetyConfigurations =>
        dispatch(shouldUpdateState ? completeLoad(driverSafetyConfigurations) : failLoad()),
      )
      .catch(() => dispatch(failLoad()));

    return loadDriverSafetyConfigurationsPromise;
  };

export const loadDriverSafetyConfigurationsForVehicle =
  (vendorId: number, vehicleId?: number) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadDriverSafetyConfigurationsForVehiclePromise = doLoadDriverSafetyConfigurationsForVehicle(
      vendorId,
      vehicleId,
    );
    loadDriverSafetyConfigurationsForVehiclePromise
      .then(driverSafetyConfigurations => dispatch(completeLoad(driverSafetyConfigurations)))
      .catch(() => dispatch(failLoad()));

    return loadDriverSafetyConfigurationsForVehiclePromise;
  };

export const saveDriverSafetyConfigurations =
  (vendorId: number, vehicleTypeId: number, driverSafetyConfigurations: DriverSafetyConfigurations[]) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveDriverSafetyConfigurationsPromise = doSaveDriverSafetyConfigurations(
      vendorId,
      vehicleTypeId,
      driverSafetyConfigurations,
    );
    saveDriverSafetyConfigurationsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveDriverSafetyConfigurationsPromise;
  };

export const saveDriverSafetyConfigurationsForVehicle =
  (vendorId: number, vehicleId: number, driverSafetyConfigurations: DriverSafetyConfigurations[]) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveDriverSafetyConfigurationsForVehiclePromise = doSaveDriverSafetyConfigurationsForVehicle(
      vendorId,
      vehicleId,
      driverSafetyConfigurations,
    );
    saveDriverSafetyConfigurationsForVehiclePromise
      .then(() => dispatch(completeSave()))
      .catch(() => dispatch(failSave()));

    return saveDriverSafetyConfigurationsForVehiclePromise;
  };

export const resetDriverSafetyConfigurations = () => ({
  type: RESET,
});
