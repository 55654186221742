import React, { PureComponent } from 'react';

import { CSSProperties } from 'styled-components';
import { FixedSizeList as List } from 'react-window';

import { DISPATCH_BOARD_ROUTE_ITEM_HEIGHT } from 'src/routes/constants/dispatchBoard';
import { DispatchBoardInfiniteWrapper, DispatchBoardScrollMarker } from '../../../styled';
import { JobTypes } from '../../../../constants';
import { REACT_WINDOW_CLASS_NAME } from '../../../../../core/constants/reactWindow';
import DispatchBoardRoute from './DispatchBoardRoute';

const DispatchBoardRouteItem = ({ style, data, index }: { style: CSSProperties; data: any; index: number }) => {
  const route = data.items[index];
  const { onJobDrop, onRouteClick, onRouteToggle, type, isRouteSelected } = data;

  return (
    <div style={style}>
      <DispatchBoardRoute
        isSelected={isRouteSelected(route.id)}
        key={route.id}
        onJobDrop={onJobDrop}
        onRouteClick={onRouteClick}
        onRouteToggle={onRouteToggle}
        route={route}
        type={type}
      />
    </div>
  );
};

interface Props {
  hasUnassignedJobs: boolean;
  onJobDrop: (
    sourceRouteId: number,
    targetRouteId: number,
    jobsSelected: any[],
    position?: number,
    type?: string,
  ) => void;
  onRouteClick: (route: any) => void;
  onRouteToggle?: (route: any) => void;
  routes: any[];
  selectedRoutes?: any[];
  type: string;
}

class DispatchBoardRoutes extends PureComponent<Props> {
  isRouteSelected = (routeId: number) => {
    const isSelected =
      !!routeId && !!this.props.selectedRoutes && !!this.props.selectedRoutes.find(s => s.id === routeId);
    return isSelected;
  };

  render() {
    const { hasUnassignedJobs, onJobDrop, onRouteClick, onRouteToggle, routes, type } = this.props;

    const MAX_ITEMS = type === JobTypes.target && hasUnassignedJobs ? 9 : 8;

    const containerHeight = Math.min(
      MAX_ITEMS * DISPATCH_BOARD_ROUTE_ITEM_HEIGHT,
      routes.length * DISPATCH_BOARD_ROUTE_ITEM_HEIGHT,
    );

    return (
      <DispatchBoardInfiniteWrapper>
        <List
          itemData={{
            items: routes,
            onJobDrop,
            onRouteClick,
            onRouteToggle,
            type,
            isRouteSelected: this.isRouteSelected,
          }}
          itemCount={routes.length}
          width="100%"
          height={containerHeight || 1}
          itemSize={DISPATCH_BOARD_ROUTE_ITEM_HEIGHT}
          className={REACT_WINDOW_CLASS_NAME}
          style={routes.length > MAX_ITEMS - 1 ? { paddingBottom: '60px' } : undefined}
        >
          {DispatchBoardRouteItem}
        </List>
        {routes.length > MAX_ITEMS - 1 && <DispatchBoardScrollMarker />}
      </DispatchBoardInfiniteWrapper>
    );
  }
}

export default DispatchBoardRoutes;
