import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import humps from 'humps';

import { useSelector } from 'src/core/hooks/useSelector';
import {
  MapGLPopupWrapper,
  MapGLPopup,
  MapGLPopupTitle,
  MapGLPopupContent,
} from 'src/common/components/map/MapGLPopup';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { clearDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { getInsightId } from './utils';
import { CityInsight } from 'src/dashboard/interfaces/cityInsights';
import { getIconTypeForCityInsight } from 'src/common/constants/insightIcons';
import translate from 'src/core/services/translate';
import { Box } from 'src/core/components/styled/Box';
import { MATERIAL_CONTAMINATION_ID } from 'src/insights/constants';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { date } from 'src/utils/services/formatter';
import { getBlobIdFromUrl } from 'src/utils/imageHelpers';
import { MapInfoImageWrapper, MapInfoImage } from 'src/core/components/styled';
import { RouteImagesModal } from 'src/routes/components/modals';

const DashboardCityInsightsGLPopup: React.FC = () => {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const dispatch = useDispatch();
  const cityInsights = useSelector(
    state => ((state.dashboard.mapInsightsMapbox?.mapInsights as any)?.cityInsights.insights || []) as CityInsight[],
  );
  const selectedFeature = useSelector(state => state.dashboard.mapControls.selectedFeature);

  const cityInsight = useMemo(() => {
    if (!selectedFeature || selectedFeature.namespace !== 'cityInsights') {
      return undefined;
    }

    return cityInsights.find(insight => getInsightId(insight) === selectedFeature.id);
  }, [selectedFeature, cityInsights]);

  const singleInsight = !cityInsight?.isCluster ? cityInsight?.communityInsights[0] : null;

  if (!cityInsight || !singleInsight) {
    return null;
  }

  const isMaterialContamination = singleInsight.locationFlaggingType.id === MATERIAL_CONTAMINATION_ID;
  const hasMaterialSubtypes = isMaterialContamination && (singleInsight.contaminationSubTypes?.length || 0) > 0;
  const contaminationSubTypesName =
    hasMaterialSubtypes &&
    singleInsight?.contaminationSubTypes &&
    singleInsight.contaminationSubTypes
      .map(contaminationSubType =>
        translate(createTranslationKey(contaminationSubType, 'insights.contaminationSubType')),
      )
      .join(', ');
  const contaminationSubTypeFormatted = hasMaterialSubtypes && `: ${contaminationSubTypesName}`;

  let images: Array<{
    contaminationSubTypesName?: string;
    imageBlobId: string;
    imageUrl: string;
    rawImageUrl?: string;
    issueName: string;
    locationName: string;
    routeName?: string;
    timeStamp: string;
  }> = [];

  cityInsight.communityInsights.forEach(insight => {
    const contaminationSubTypesName =
      insight?.contaminationSubTypes &&
      insight.contaminationSubTypes
        .map(contaminationSubType =>
          translate(createTranslationKey(contaminationSubType, 'insights.contaminationSubType')),
        )
        .join(', ');

    const imageInfo = {
      issueName: translate(createTranslationKey(insight.locationFlaggingType.technicalName, 'vendors.locationFlags')),
      locationName: insight.address,
      routeName: insight.routeName,
      timeStamp: insight.locationFlagTimeStamp,
      contaminationSubTypesName,
    };

    images = !!insight.images?.length
      ? insight.images.map(image => ({
          ...imageInfo,
          imageUrl: image.imageUrl,
          rawImageUrl: image?.rawImageUrl || image.imageUrl,
          imageBlobId: image.imageBlobId,
        }))
      : !!insight.imageUrl
      ? [
          {
            ...imageInfo,
            imageUrl: insight.imageUrl,
            rawImageUrl: insight?.rawImageUrl || insight.imageUrl,
            imageBlobId: getBlobIdFromUrl(insight.imageUrl),
          },
        ]
      : [];
  });

  return (
    <>
      <MapGLPopupWrapper
        closeOnClick={false}
        latitude={cityInsight.latitude}
        longitude={cityInsight.longitude}
        onClose={() => dispatch(clearDashboardSelectedFeature())}
      >
        <MapGLPopup>
          <MapGLPopupTitle
            beforeTitleContent={
              <Box mr={8} width={15} height={15}>
                <img src={getIconTypeForCityInsight(cityInsight)?.iconUrl} width="15" height="15" alt="" />
              </Box>
            }
            title={translate(`dashboard.${humps.camelize(cityInsight.locationFlaggingType.technicalName)}`)}
            afterTitleContent={contaminationSubTypeFormatted}
          />

          <MapGLPopupContent>
            {!!singleInsight.routeName && (
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('routes.route')}
                value={singleInsight.routeName}
              />
            )}

            {!!singleInsight.routeDate && (
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('insights.date')}
                value={date(singleInsight.routeDate)}
              />
            )}

            {!!singleInsight.vehicleName && (
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('common.truck')}
                value={singleInsight.vehicleName}
              />
            )}

            {singleInsight.statusId >= 0 && (
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('insights.status')}
                value={singleInsight.statusId === 1 ? translate('insights.cleared') : translate('insights.open')}
              />
            )}

            {!!singleInsight.address && (
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('insights.address')}
                value={singleInsight.address}
              />
            )}

            {!!images.length && (
              <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={translate('common.images')}>
                <Box mt={5}>
                  <MapInfoImageWrapper
                    width="40%"
                    overlay={images.length > 1 ? images.length : undefined}
                    onClick={() => setIsImageModalOpen(true)}
                  >
                    <MapInfoImage src={images[0].imageUrl} />
                  </MapInfoImageWrapper>
                </Box>
              </LabeledDataView>
            )}
          </MapGLPopupContent>
        </MapGLPopup>
      </MapGLPopupWrapper>

      {!!isImageModalOpen && <RouteImagesModal images={images} closeModal={() => setIsImageModalOpen(false)} />}
    </>
  );
};

export default DashboardCityInsightsGLPopup;
