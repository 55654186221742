import { isDirty } from 'redux-form';
import { map } from 'lodash-es';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { dateFormat } from 'src/utils/services/validator';
import {
  getIsDriverUnavailable,
  getIsVehicleUnavailable,
  getRoutesResourceAvailabilityTranslation,
} from 'src/routes/utils/routeDetails';
import { getRouteSaveErrorMessage } from 'src/routes/utils/routeDetails';
import { isNavi3FeatureEnabled, isTravelPathNavigationFeatureEnabled } from 'src/vendors/ducks/features';
import {
  loadRoutesResourceAvailability,
  loadRouteSummary,
  loadTravelPathStatusDetails,
  updateRouteDetails,
} from 'src/routes/ducks';
import { loadSupervisors } from 'src/routes/ducks/supervisors';
import { loadVehicles } from 'src/fleet/ducks';
import { Modal } from 'src/core/components';
import { RouteDetailsFormData } from 'src/routes/interfaces/RouteDetails';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import EditRouteDetailsForm, { EDIT_ROUTE_DETAILS_FORM_NAME } from '../forms/EditRouteDetailsForm';
import translate from 'src/core/services/translate';

const editRouteDetailsModalId = 'editRouteDetailsModalId';

type Props = {
  closeModal: () => void;
};

export default function EditRouteDetailsModal({ closeModal }: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const isFormDirty = useSelector(isDirty(EDIT_ROUTE_DETAILS_FORM_NAME));
  const { isSaving } = useSelector(state => state.routes.routeDetails);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { supervisorsLoading: isLoadingSupervisors } = useSelector(state => state.routes.supervisors);
  const { isLoading: isLoadingVehicles } = useSelector(state => state.fleet.vehicles);
  const { isLoadingRoutesResourceAvailability, routesResourceAvailability } = useSelector(
    state => state.routes.routeTracker,
  );

  const isTravelPathFeatureEnabled = useSelector(isTravelPathNavigationFeatureEnabled);
  const isNaviV3FeatureEnabled = useSelector(isNavi3FeatureEnabled);

  if (!routeSummary) return null;

  const editRouteDetailsFormInitialValues = {
    ...routeSummary,
    groups: map(routeSummary.groups, group => group.id),
    routeName: routeSummary.name,
    date: moment(routeSummary.date).format(dateFormat),
  };

  const handleEditRouteDetails = async (formData: RouteDetailsFormData) => {
    const isDriverUnavailable = getIsDriverUnavailable(routesResourceAvailability);
    const isVehicleUnavailable = getIsVehicleUnavailable(routesResourceAvailability);

    if (isDriverUnavailable || isVehicleUnavailable) {
      if (!(await confirm(getRoutesResourceAvailabilityTranslation(isDriverUnavailable, isVehicleUnavailable)))) {
        return;
      }
    }

    const dataToSend = {
      ...formData,
      groups: map(formData.groups, group => ({
        id: Number(group),
      })),
      startTime: formData.startTime ? moment(formData.startTime, 'hh:mm a').format('HH:mm') : undefined,
      endTime: formData.endTime ? moment(formData.endTime, 'hh:mm a').format('HH:mm') : undefined,
      vehicleId: isVehicleUnavailable ? null : formData.vehicleId,
    };

    scrollToTopOfModal(editRouteDetailsModalId);

    updateRouteDetails(
      vendorId,
      routeSummary.routeId,
      dataToSend,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('routes.alertMessages.routeSaved'));
        closeModal();
        loadRouteSummary(vendorId, routeSummary.routeId)(dispatch);

        if (routeSummary.totalStopsCount >= 2 && (isTravelPathFeatureEnabled || isNaviV3FeatureEnabled)) {
          loadTravelPathStatusDetails(routeSummary.routeId)(dispatch);
        }
      })
      .catch(error => {
        const { code } = error?.response?.data;
        getRouteSaveErrorMessage(code, formData.date);
      });
  };

  const handleCloseModal = async () => {
    if (isFormDirty) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    closeModal();
  };

  const handleDateChange = (date: any | Date | string) => {
    const showActiveTrucks = true;
    const vehicleTypeId = undefined;

    loadSupervisors(vendorId, date)(dispatch);
    loadVehicles(vendorId, showActiveTrucks, vehicleTypeId, date)(dispatch);
    loadRoutesResourceAvailability([{ routeId: routeSummary.routeId, date }])(dispatch);
  };

  const isLoadingModal = isSaving || isLoadingSupervisors || isLoadingVehicles || isLoadingRoutesResourceAvailability;

  return (
    <Modal
      title={translate('routes.editRouteDetails')}
      size="small"
      isLoading={isLoadingModal}
      onClose={handleCloseModal}
      id={editRouteDetailsModalId}
      overflow={isLoadingModal ? 'hidden' : 'auto'}
      padding="medium no no no"
    >
      <EditRouteDetailsForm
        initialValues={editRouteDetailsFormInitialValues}
        onSubmit={handleEditRouteDetails}
        closeModal={handleCloseModal}
        handleDateChange={handleDateChange}
      />
    </Modal>
  );
}
