import update from 'immutability-helper';
import { findIndex } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';

import {
  loadTripConfigurationForVendor as doLoadTripConfigurationForVendor,
  saveTripConfigurationsForVendor as doSaveTripConfigurationsForVendor,
} from '../services/tripConfiguration';

// Actions
const START_LOAD = 'vendors/tripConfigurationForVendor/START_LOAD';
const COMPLETE_LOAD = 'vendors/tripConfigurationForVendor/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/tripConfigurationForVendor/FAIL_LOAD';
const TOGGLE_TRIP_CONFIGURATION_IS_ACTIVE = 'vendors/tripConfigurationForVendor/TOGGLE_TRIP_CONFIGURATION_IS_ACTIVE';
const TOGGLE_TRIP_CONFIGURATION_IS_REQUIRED =
  'vendors/tripConfigurationForVendor/TOGGLE_TRIP_CONFIGURATION_IS_REQUIRED';
const TOGGLE_TRIP_CONFIGURATION_SEND_EMAIL_NOTIFICATION =
  'vendors/tripConfigurationForVendor/TOGGLE_TRIP_CONFIGURATION_SEND_EMAIL_NOTIFICATION';
const TOGGLE_TRIP_CONFIGURATION_PICTURE = 'vendors/tripConfigurationForVendor/TOGGLE_TRIP_CONFIGURATION_PICTURE';
const TOGGLE_TRIP_CONFIGURATION_PICTURE_NOT_FLAGGED =
  'vendors/tripConfigurationForVendor/TOGGLE_TRIP_CONFIGURATION_PICTURE_NOT_FLAGGED';
const SORT_ORDER_CHANGE = 'vendors/tripConfigurationForVendor/SORT_ORDER_CHANGE';
const START_SAVE = 'vendors/tripConfigurationForVendor/START_SAVE';
const COMPLETE_SAVE = 'vendors/tripConfigurationForVendor/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/tripConfigurationForVendor/FAIL_SAVE';
const RESET = 'vendors/tripConfigurationForVendor/RESET';

// Initial state =
const initialState = {
  isLoading: false,
  isSaving: false,
  tripConfigurationForVendor: undefined,
};

const getConfigurationIndex = (state: any, id: number, technicalName: string) =>
  findIndex(
    (state.tripConfigurationForVendor as any).tripInspectionCategories[id].tripInspectionSubCategories,
    (x: any) => x.tripInspectionSubCategory.technicalName === technicalName,
  );

const setPictureConfig = (
  technicalName: string,
  canTakePictureConfig: any,
  state: any,
  issueNotFlagged: boolean,
  tripConfigurationId: number,
) => {
  const configurationIndex = tripConfigurationId;
  const configurationIndexLabel = getConfigurationIndex(state, tripConfigurationId, technicalName);

  return update(state, {
    tripConfigurationForVendor: {
      tripInspectionCategories: {
        [`${configurationIndex}`]: {
          tripInspectionSubCategories: {
            [configurationIndexLabel as any]: issueNotFlagged
              ? { canTakePictureIfIssueNotFlagged: { $set: canTakePictureConfig } }
              : { canTakePicture: { $set: canTakePictureConfig } },
          },
        },
      },
    },
  });
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          tripConfigurationForVendor: action.tripConfigurationForVendor,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          tripConfigurationForVendor: undefined,
        },
      });

    case TOGGLE_TRIP_CONFIGURATION_IS_ACTIVE: {
      const { technicalName, isActive, isMileage, tripConfigurationId } = action;

      if (!isMileage) {
        const configurationIndex = tripConfigurationId;
        const configurationIndexLabel = getConfigurationIndex(state, tripConfigurationId, technicalName);

        return update(state, {
          tripConfigurationForVendor: {
            tripInspectionCategories: {
              [`${configurationIndex}`]: {
                tripInspectionSubCategories: {
                  [configurationIndexLabel as any]: { isActive: { $set: isActive } },
                },
              },
            },
          },
        } as any);
      }

      return update(state, {
        tripConfigurationForVendor: {
          additionalTripInspectionItem: {
            isActive: { $set: isActive },
          },
        },
      } as any);
    }

    case TOGGLE_TRIP_CONFIGURATION_IS_REQUIRED: {
      const { technicalName, isRequired, tripConfigurationId } = action;

      const configurationIndex = tripConfigurationId;
      const configurationIndexLabel = getConfigurationIndex(state, tripConfigurationId, technicalName);

      return update(state, {
        tripConfigurationForVendor: {
          tripInspectionCategories: {
            [`${configurationIndex}`]: {
              tripInspectionSubCategories: {
                [configurationIndexLabel as any]: { isRequired: { $set: isRequired } },
              },
            },
          },
        },
      } as any);
    }

    case TOGGLE_TRIP_CONFIGURATION_SEND_EMAIL_NOTIFICATION: {
      const { technicalName, sendEmailNotification, tripConfigurationId } = action;

      const configurationIndex = tripConfigurationId;
      const configurationIndexLabel = getConfigurationIndex(state, tripConfigurationId, technicalName);

      return update(state, {
        tripConfigurationForVendor: {
          tripInspectionCategories: {
            [`${configurationIndex}`]: {
              tripInspectionSubCategories: {
                [configurationIndexLabel as any]: { sendEmailNotification: { $set: sendEmailNotification } },
              },
            },
          },
        },
      } as any);
    }

    case TOGGLE_TRIP_CONFIGURATION_PICTURE: {
      const { technicalName, canTakePicture, tripConfigurationId } = action;
      const issueNotFlagged = false;

      return setPictureConfig(technicalName, canTakePicture, state, issueNotFlagged, tripConfigurationId);
    }

    case TOGGLE_TRIP_CONFIGURATION_PICTURE_NOT_FLAGGED: {
      const { technicalName, canTakePictureIfIssueNotFlagged, tripConfigurationId } = action;
      const issueNotFlagged = true;

      return setPictureConfig(
        technicalName,
        canTakePictureIfIssueNotFlagged,
        state,
        issueNotFlagged,
        tripConfigurationId,
      );
    }

    case SORT_ORDER_CHANGE: {
      const { tripConfigurations, count } = action;
      return update(state, {
        tripConfigurationForVendor: {
          tripInspectionCategories: {
            [`${count}`]: {
              tripInspectionSubCategories: { $set: tripConfigurations },
            },
          },
        },
      } as any);
    }

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (tripConfigurationForVendor: any) => ({
  type: COMPLETE_LOAD,
  tripConfigurationForVendor,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadTripConfigurationForVendor =
  (vendorId: number, tripInspectionTypeId: number, vehicleTypeId: number) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadTripConfigurationForVendorPromise = doLoadTripConfigurationForVendor(
      vendorId,
      tripInspectionTypeId,
      vehicleTypeId,
    );
    loadTripConfigurationForVendorPromise
      .then(tripConfigurationForVendor => dispatch(completeLoad(tripConfigurationForVendor)))
      .catch(() => dispatch(failLoad()));
    return loadTripConfigurationForVendorPromise;
  };

export const toggleTripConfigurationIsActive = (
  technicalName: string,
  isActive: boolean,
  isMileage: boolean,
  tripConfigurationId: number,
) => ({
  type: TOGGLE_TRIP_CONFIGURATION_IS_ACTIVE,
  technicalName,
  isActive,
  isMileage,
  tripConfigurationId,
});

export const toggleTripConfigurationIsRequired = (
  technicalName: string,
  isRequired: boolean,
  tripConfigurationId: number,
) => ({
  type: TOGGLE_TRIP_CONFIGURATION_IS_REQUIRED,
  technicalName,
  isRequired,
  tripConfigurationId,
});

export const toggleTripConfigurationSendEmailNotification = (
  technicalName: string,
  sendEmailNotification: boolean,
  tripConfigurationId: number,
) => ({
  type: TOGGLE_TRIP_CONFIGURATION_SEND_EMAIL_NOTIFICATION,
  technicalName,
  sendEmailNotification,
  tripConfigurationId,
});

export const toggleTripConfigurationPicture = (
  technicalName: string,
  canTakePicture: boolean,
  tripConfigurationId: number,
) => ({
  type: TOGGLE_TRIP_CONFIGURATION_PICTURE,
  technicalName,
  canTakePicture,
  tripConfigurationId,
});

export const toggleTripConfigurationPictureNotFlagged = (
  technicalName: string,
  canTakePictureIfIssueNotFlagged: boolean,
  tripConfigurationId: number,
) => ({
  type: TOGGLE_TRIP_CONFIGURATION_PICTURE_NOT_FLAGGED,
  technicalName,
  canTakePictureIfIssueNotFlagged,
  tripConfigurationId,
});

export const handleSortOrderChange = (tripConfigurations: any, count: number) => ({
  type: SORT_ORDER_CHANGE,
  tripConfigurations,
  count,
});

export const saveTripConfigurationsForVendor =
  (vendorId: number, tripConfigurations: any, tripInspectionTypeId: number, vehicleTypeId?: number) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveTripConfigurationsForVendorPromise = doSaveTripConfigurationsForVendor(
      vendorId,
      tripConfigurations,
      tripInspectionTypeId,
      vehicleTypeId,
    );
    saveTripConfigurationsForVendorPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveTripConfigurationsForVendorPromise;
  };

export const resetTripConfigurationsForVendor = () => ({
  type: RESET,
});
