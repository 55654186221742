import { find, get, identity, reduce, set } from 'lodash-es';
import { createSelector } from 'reselect';

const getContactById = (contactsState: any, id?: number) => {
  const contact = find(get(contactsState, 'contacts'), { id });
  const contactTypeIds =
    contact &&
    reduce(
      contact.selectedContactTypes,
      (initialValues, { id }) => set(initialValues, `contactTypeId:${id}`, true),
      {},
    );

  return {
    ...contact,
    ...contactTypeIds,
  };
};

const contactEditorFormInitialValuesSelector = createSelector(
  getContactById,
  identity,
);

export default contactEditorFormInitialValuesSelector;
