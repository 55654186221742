import { http } from 'src/core/services/http';
import { Rate } from '../interfaces/ServiceRates';

export const loadServiceRates = (headerId: number) =>
  http.get(`statements/headers/${headerId}/detailsBilled`).then(response => response.data);

export const saveServiceRates = (headerId: number, rates: Rate[]) =>
  http.post(`statements/headers/${headerId}/detailsBilled`, rates).then(response => response.data);

export const loadRateTypes = () => http.get(`common/systemTypes/rateCodes`).then(response => response.data);

export const loadUOMTypes = () => http.get('common/systemTypes/unitOfMeasureType').then(response => response.data);
