import update from 'immutability-helper';
import { createSelector } from 'reselect';
import { identity, get } from 'lodash-es';
import { AnyAction } from 'redux';
import {
  loadService as doLoadService,
  saveServiceWorkflow as doSaveServiceWorkflow,
  loadServiceWorkOrders as doLoadServiceWorkOrders,
  loadRateTypes as doLoadRateTypes,
  loadRateTypesByServiceType as doLoadRateTypesByServiceType,
  loadRateTypesByWorkOrder as doLoadRateTypesByWorkOrder,
  loadRateTypesByServiceContractId as doLoadRateTypesByServiceContractId,
} from '../services/service';
import { ThunkDispatch } from 'redux-thunk';
import { Service, ServiceWorkOrder, ServiceWorkflowSavePayload } from '../interfaces/Services';
import { createErrorNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { RateType } from 'src/finance/interfaces/ServiceRates';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

// Actions
const START_LOAD = 'customers/service/START_LOAD';
const COMPLETE_LOAD = 'customers/service/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/service/FAIL_LOAD';

const START_SAVE_WORKFLOW = 'customers/service/START_SAVE_WORKFLOW';
const COMPLETE_SAVE_WORKFLOW = 'customers/service/COMPLETE_SAVE_WORKFLOW';
const FAIL_SAVE_WORKFLOW = 'customers/service/FAIL_SAVE_WORKFLOW';

const START_LOAD_WORK_ORDERS = 'customers/service/workOrders/START_LOAD_WORK_ORDERS';
const COMPLETE_LOAD_WORK_ORDERS = 'customers/service/workOrders/COMPLETE_LOAD_WORK_ORDERS';
const FAIL_LOAD_WORK_ORDERS = 'customers/service/workOrders/FAIL_LOAD_WORK_ORDERS';

const START_LOAD_RATE_TYPES = 'customers/service/rates/START_LOAD_RATE_TYPES';
export const COMPLETE_LOAD_RATE_TYPES = 'customer/rateCode/COMPLETE_LOAD_RATE_TYPES';
export const FAIL_LOAD_RATE_TYPES = 'customers/service/rates/FAIL_LOAD_RATE_TYPES';

const START_LOAD_RATE_TYPES_BY_SERVICE_TYPE = 'customers/service/rates/START_LOAD_RATE_TYPES_BY_SERVICE_TYPE';
const COMPLETE_LOAD_RATE_TYPES_BY_SERVICE_TYPE = 'customer/rateCode/COMPLETE_LOAD_RATE_TYPES_BY_SERVICE_TYPE';
const FAIL_LOAD_RATE_TYPES_BY_SERVICE_TYPE = 'customers/service/rates/FAIL_LOAD_RATE_TYPES_BY_SERVICE_TYPE';

const START_LOAD_RATE_TYPES_BY_WORK_ORDER = 'customers/service/rates/START_LOAD_RATE_TYPES_BY_WORK_ORDER';
const COMPLETE_LOAD_RATE_TYPES_BY_WORK_ORDER = 'customer/rateCode/COMPLETE_LOAD_RATE_TYPES_BY_WORK_ORDER';
const FAIL_LOAD_RATE_TYPES_BY_WORK_ORDER = 'customers/service/rates/FAIL_LOAD_RATE_TYPES_BY_WORK_ORDER';
const RESET_RATE_TYPES_BY_WORK_ORDER = 'customers/service/rates/RESET_RATE_TYPES_BY_WORK_ORDER';

const START_LOAD_RATE_TYPES_BY_SERVICE_CONTRACT_ID =
  'customers/service/rates/START_LOAD_RATE_TYPES_BY_SERVICE_CONTRACT_ID';
const COMPLETE_LOAD_RATE_TYPES_BY_SERVICE_CONTRACT_ID =
  'customer/rateCode/COMPLETE_LOAD_RATE_TYPES_BY_SERVICE_CONTRACT_ID';
const FAIL_LOAD_RATE_TYPES_BY_SERVICE_CONTRACT_ID =
  'customers/service/rates/FAIL_LOAD_RATE_TYPES_BY_SERVICE_CONTRACT_ID';
const RESET_RATE_TYPES_BY_SERVICE_CONTRACT_ID = 'customers/service/rates/RESET_RATE_TYPES_BY_SERVICE_CONTRACT_ID';

const RESET = 'customers/service/RESET';

interface State {
  isLoading: boolean;
  isSavingWorkflow: boolean;
  rateTypes: RateType[];
  rateTypesByServiceContractId: RateType[];
  rateTypesByServiceType: RateType[];
  rateTypesByWorkOrder: RateType[];
  service?: Service;
  workOrders: ServiceWorkOrder[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  isSavingWorkflow: false,
  rateTypes: [],
  rateTypesByServiceContractId: [],
  rateTypesByServiceType: [],
  rateTypesByWorkOrder: [],
  service: undefined,
  workOrders: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          service: action.service,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          service: undefined,
        },
      });

    case START_SAVE_WORKFLOW:
      return update(state, {
        $merge: {
          isSavingWorkflow: true,
        },
      });

    case COMPLETE_SAVE_WORKFLOW:
      return update(state, {
        $merge: {
          isSavingWorkflow: false,
        },
      });

    case FAIL_SAVE_WORKFLOW:
      return update(state, {
        $merge: {
          isSavingWorkflow: false,
        },
      });

    case START_LOAD_WORK_ORDERS:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_WORK_ORDERS:
      return update(state, {
        $merge: {
          isLoading: false,
          workOrders: action.workOrders,
        },
      });

    case FAIL_LOAD_WORK_ORDERS:
      return update(state, {
        $merge: {
          isLoading: false,
          workOrders: [],
        },
      });

    case START_LOAD_RATE_TYPES:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_RATE_TYPES:
      return update(state, {
        $merge: {
          isLoading: false,
          rateTypes: action.rateTypes,
        },
      });

    case FAIL_LOAD_RATE_TYPES:
      return update(state, {
        $merge: {
          isLoading: false,
          rateTypes: [],
        },
      });

    case START_LOAD_RATE_TYPES_BY_SERVICE_TYPE:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_RATE_TYPES_BY_SERVICE_TYPE:
      return update(state, {
        $merge: {
          isLoading: false,
          rateTypesByServiceType: action.rateTypesByServiceType,
        },
      });

    case FAIL_LOAD_RATE_TYPES_BY_SERVICE_TYPE:
      return update(state, {
        $merge: {
          isLoading: false,
          rateTypesByServiceType: [],
        },
      });

    case START_LOAD_RATE_TYPES_BY_WORK_ORDER:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_RATE_TYPES_BY_WORK_ORDER:
      return update(state, {
        $merge: {
          isLoading: false,
          rateTypesByWorkOrder: action.rateTypesByWorkOrder,
        },
      });

    case FAIL_LOAD_RATE_TYPES_BY_WORK_ORDER:
      return update(state, {
        $merge: {
          isLoading: false,
          rateTypesByWorkOrder: [],
        },
      });

    case RESET_RATE_TYPES_BY_WORK_ORDER:
      return update(state, {
        $merge: {
          rateTypesByWorkOrder: [],
        },
      });

    case START_LOAD_RATE_TYPES_BY_SERVICE_CONTRACT_ID:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_RATE_TYPES_BY_SERVICE_CONTRACT_ID:
      return update(state, {
        $merge: {
          isLoading: false,
          rateTypesByServiceContractId: action.rateTypesByServiceContractId,
        },
      });

    case FAIL_LOAD_RATE_TYPES_BY_SERVICE_CONTRACT_ID:
      return update(state, {
        $merge: {
          isLoading: false,
          rateTypesByServiceContractId: [],
        },
      });

    case RESET_RATE_TYPES_BY_SERVICE_CONTRACT_ID:
      return update(state, {
        $merge: {
          rateTypesByServiceContractId: [],
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (service: Service) => ({
  type: COMPLETE_LOAD,
  service,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSaveWorkflow = () => ({
  type: START_SAVE_WORKFLOW,
});

const completeSaveWorkflow = () => ({
  type: COMPLETE_SAVE_WORKFLOW,
});

const failSaveWorkflow = () => ({
  type: FAIL_SAVE_WORKFLOW,
});

const startLoadWorkOrders = () => ({
  type: START_LOAD_WORK_ORDERS,
});

const completeLoadWorkOrders = (workOrders: ServiceWorkOrder[]) => ({
  type: COMPLETE_LOAD_WORK_ORDERS,
  workOrders,
});

const failLoadWorkOrders = () => ({
  type: FAIL_LOAD_WORK_ORDERS,
});

const startLoadRateTypes = () => ({
  type: START_LOAD_RATE_TYPES,
});

const completeLoadRateTypes = (rateTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD_RATE_TYPES,
  rateTypes,
});

const failLoadRateTypes = () => ({
  type: FAIL_LOAD_RATE_TYPES,
});

const startLoadRateTypesByServiceType = () => ({
  type: START_LOAD_RATE_TYPES_BY_SERVICE_TYPE,
});

const completeLoadRateTypesByServiceType = (rateTypesByServiceType: TechnicalType[]) => ({
  type: COMPLETE_LOAD_RATE_TYPES_BY_SERVICE_TYPE,
  rateTypesByServiceType,
});

const failLoadRateTypeByServiceTypes = () => ({
  type: FAIL_LOAD_RATE_TYPES_BY_SERVICE_TYPE,
});

const startLoadRateTypesByWorkOrder = () => ({
  type: START_LOAD_RATE_TYPES_BY_WORK_ORDER,
});

const completeLoadRateTypesByWorkOrder = (rateTypesByWorkOrder: TechnicalType[]) => ({
  type: COMPLETE_LOAD_RATE_TYPES_BY_WORK_ORDER,
  rateTypesByWorkOrder,
});

const failLoadRateTypeByWorkOrder = () => ({
  type: FAIL_LOAD_RATE_TYPES_BY_WORK_ORDER,
});

const doResetRateTypesByWorkOrder = () => ({
  type: RESET_RATE_TYPES_BY_WORK_ORDER,
});

const startLoadRateTypesByServiceContractId = () => ({
  type: START_LOAD_RATE_TYPES_BY_SERVICE_CONTRACT_ID,
});

const completeLoadRateTypesByServiceContractId = (rateTypesByServiceContractId: TechnicalType[]) => ({
  type: COMPLETE_LOAD_RATE_TYPES_BY_SERVICE_CONTRACT_ID,
  rateTypesByServiceContractId,
});

const failLoadRateTypesByServiceContractId = () => ({
  type: FAIL_LOAD_RATE_TYPES_BY_SERVICE_CONTRACT_ID,
});

const doResetRateTypesByServiceContractId = () => ({
  type: RESET_RATE_TYPES_BY_SERVICE_CONTRACT_ID,
});

const reset = () => ({
  type: RESET,
});

export const loadService = (serviceId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadServicePromise = doLoadService(serviceId);
  loadServicePromise
    .then(service => {
      dispatch(completeLoad(service));
    })
    .catch(() => dispatch(failLoad()));
  return loadServicePromise;
};

export const saveServiceWorkflow = (payload: ServiceWorkflowSavePayload) => (dispatch: Dispatch) => {
  dispatch(startSaveWorkflow());
  const saveServiceWorkflowPromise = doSaveServiceWorkflow(payload);
  saveServiceWorkflowPromise
    .then(() => {
      dispatch(completeSaveWorkflow());
    })
    .catch(err => {
      const code = err.response?.data?.code;
      dispatch(failSaveWorkflow());
      createErrorNotification(translate(createTranslationKey(code, 'customers.alertMessages')));
    });
  return saveServiceWorkflowPromise;
};

export const loadServiceWorkOrders =
  (serviceId: number, startDate?: string | Date, endDate?: string | Date) => (dispatch: Dispatch) => {
    dispatch(startLoadWorkOrders());
    const loadServiceWorkOrdersPromise = doLoadServiceWorkOrders(serviceId, startDate, endDate);
    loadServiceWorkOrdersPromise
      .then((workOrders: any) => {
        dispatch(completeLoadWorkOrders(workOrders));
      })
      .catch(() => dispatch(failLoadWorkOrders()));
    return loadServiceWorkOrdersPromise;
  };

export const loadRateTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoadRateTypes());

  const loadRateTypesPromise = doLoadRateTypes();

  loadRateTypesPromise
    .then(rateTypes => dispatch(completeLoadRateTypes(rateTypes)))
    .catch(() => dispatch(failLoadRateTypes()));

  return loadRateTypesPromise;
};

export const loadRateTypesByServiceType = (serviceTypeId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoadRateTypesByServiceType());

  const loadRateTypesByServiceTypePromise = doLoadRateTypesByServiceType(serviceTypeId);

  loadRateTypesByServiceTypePromise
    .then(rateTypesByServiceType => dispatch(completeLoadRateTypesByServiceType(rateTypesByServiceType)))
    .catch(() => dispatch(failLoadRateTypeByServiceTypes()));

  return loadRateTypesByServiceTypePromise;
};

export const loadRateTypesByWorkOrder = (workOrderId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoadRateTypesByWorkOrder());

  const loadRateTypesByWorkOrderPromise = doLoadRateTypesByWorkOrder(workOrderId);

  loadRateTypesByWorkOrderPromise
    .then(rateTypesByWorkOrder => dispatch(completeLoadRateTypesByWorkOrder(rateTypesByWorkOrder)))
    .catch(() => dispatch(failLoadRateTypeByWorkOrder()));

  return loadRateTypesByWorkOrderPromise;
};

export const resetRateTypesByWorkOrder = () => (dispatch: Dispatch) => {
  dispatch(doResetRateTypesByWorkOrder());
};

export const loadRateTypesByServiceContractId = (serviceContractId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoadRateTypesByServiceContractId());

  const loadRateTypesByServiceContractIdPromise = doLoadRateTypesByServiceContractId(serviceContractId);

  loadRateTypesByServiceContractIdPromise
    .then(rateTypesServiceContractId => dispatch(completeLoadRateTypesByServiceContractId(rateTypesServiceContractId)))
    .catch(() => dispatch(failLoadRateTypesByServiceContractId()));

  return loadRateTypesByServiceContractIdPromise;
};

export const resetRateTypesByServiceContractId = () => (dispatch: Dispatch) => {
  dispatch(doResetRateTypesByServiceContractId());
};

export const resetService = () => (dispatch: Dispatch) => {
  dispatch(reset());
};

const getService = (serviceState: State) => get(serviceState, 'service', undefined);

export const serviceDetailsSelector = createSelector(getService, identity);
