import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

interface FaultCodeDetails {
  date: Date | string;
  faultCodeDetails: string;
  vehicleName: string;
  vehicleType: string;
}

const transformLoadReportingFaultCodeDetails = ({
  date,
  faultCodeDetails,
  vehicleName,
  vehicleType,
}: FaultCodeDetails) => ({
  eventDate: date,
  faultCodeDetails,
  vehicleName,
  vehicleTypeName: translate(createTranslationKey(vehicleType, 'vehicles.vehicleTypes')),
});

export default transformLoadReportingFaultCodeDetails;
