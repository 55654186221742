import { useState, ReactNode } from 'react';

import { Grid, GridColumn, ModalSection, Text } from 'src/core/components/styled';
import { Icon } from 'src/core/components';
import { RouteSectionCollapseButton, RouteSectionHeader } from 'src/routes/components/modals/routeModalSections/Styled';

type Props = {
  content: ReactNode;
  title: string;
  defaultOpen?: boolean;
};

export default function ServiceRequestSection({ content, title, defaultOpen }: Props) {
  const [isCollapsed, setCollapsed] = useState(defaultOpen);

  return (
    <ModalSection overflow="hidden">
      <Grid multiLine>
        <GridColumn size="12/12" padding="xxSmall no">
          <RouteSectionHeader>
            <Text margin="no xSmall" size="xLarge" color={isCollapsed ? 'primary' : 'grayDarker'}>
              {title}
            </Text>
            <RouteSectionCollapseButton isOpen={isCollapsed} onClick={() => setCollapsed(isCollapsed => !isCollapsed)}>
              <Icon icon="chevronDown" />
            </RouteSectionCollapseButton>
          </RouteSectionHeader>
        </GridColumn>

        {isCollapsed && (
          <GridColumn size="12/12" padding="no">
            {content}
          </GridColumn>
        )}
      </Grid>
    </ModalSection>
  );
}
