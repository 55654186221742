import update from 'immutability-helper';
import { find, get, identity } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { loadNotificationTypes as doNotificationTypes } from '../services/notificationTypes';
import { NotificationTypes } from '../interfaces/CustomerNotifications';

// Actions
const START_LOAD = 'vendors/notificationTypes/START_LOAD';
const COMPLETE_LOAD = 'vendors/notificationTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/notificationTypes/FAIL_LOAD';
const RESET = 'vendors/notificationTypes/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  notificationTypes: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          notificationTypes: action.notificationTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (notificationTypes: NotificationTypes) => ({
  type: COMPLETE_LOAD,
  notificationTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const getTechnicalNameByNotificationId = (notificationTypes: any, notificationTypeId: number) => {
  const notificationType = find(notificationTypes.notificationTypes, {
    id: notificationTypeId,
  });
  return get(notificationType, 'technicalName', '');
};

export const technicalNameByNotificationIdSelector = createSelector(getTechnicalNameByNotificationId, identity);

export const loadNotificationTypes = (vendorId: number, vehicleTypeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadNotificationTypesPromise = doNotificationTypes(vendorId, vehicleTypeId);
  loadNotificationTypesPromise
    .then(notificationTypes => dispatch(completeLoad(notificationTypes)))
    .catch(() => dispatch(failLoad()));

  return loadNotificationTypesPromise;
};

export const resetNotificationTypes = () => ({
  type: RESET,
});
