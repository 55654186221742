import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import { ButtonSet, Button } from '../../../core/components/styled';
import { Input } from '../../../core/components';
import { AccountPanelLogo, AccountPanelTitle, AccountPanelError } from '../styled';
import {
  isRequired,
  isPasswordMatch,
  isEmail,
  minLength8,
  passwordComplexity,
} from '../../../utils/services/validator';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {
  isCheckingUserFailed: boolean;
  isFailedCreatingUser: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const CreateUserAccountForm: React.FC<Props> = ({ isCheckingUserFailed, isFailedCreatingUser, handleSubmit }) => (
  <form onSubmit={handleSubmit} noValidate>
    <AccountPanelLogo />

    <AccountPanelTitle>{translate('account.createAnAccount')}</AccountPanelTitle>

    {isCheckingUserFailed && (
      <AccountPanelError>
        {'Vendor Not Found.  Please contact your Rubicon Vendor Relations Manager for further assistance.'}
      </AccountPanelError>
    )}

    {!isCheckingUserFailed && (
      <div>
        <Field name="name" component={Input} label={translate('common.name')} type="text" validate={[isRequired]} />

        <Field
          name="email"
          component={Input}
          label={translate('common.email')}
          type="text"
          validate={[isRequired, isEmail]}
        />

        <Field
          name="password"
          component={Input}
          label={translate('common.password')}
          type="password"
          validate={[isRequired, minLength8, passwordComplexity]}
        />

        <Field
          name="confirmPassword"
          component={Input}
          label={translate('common.confirmPassword')}
          type="password"
          validate={[isRequired, isPasswordMatch]}
        />

        {isFailedCreatingUser && <AccountPanelError>{translate('account.emailExists')}</AccountPanelError>}

        <ButtonSet>
          <Button type="submit" color="primary">
            {translate('account.createUserAccount')}
          </Button>
        </ButtonSet>
      </div>
    )}
  </form>
);

export default reduxForm<any, PropsWithoutReduxForm>({
  form: 'CreateUserAccount',
  onSubmitFail: focusFirstInvalidField,
})(CreateUserAccountForm);
