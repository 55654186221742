import { AnyAction } from 'redux';
import { map } from 'lodash-es';
import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import update from 'immutability-helper';

import translate from '../../../core/services/translate';
import arrayMove from '../../../utils/services/arrayMove';
import {
  deleteRouteJobs as doDeleteRouteJobs,
  loadRouteJobs as doLoadRouteJobs,
  moveRouteJobToPosition as doMoveRouteJobToPosition,
} from '../../services/dispatchBoardRouteJobs';
import { DispatchBoardRouteJobSimplified } from 'src/routes/interfaces/DispatchBoardRouteJob';

interface State {
  isLoading: boolean;
  routeId?: number;
  isDeletingJob: boolean;
  routeJobs: any[];
  routeDetails: any;
}

const CancelToken = axios.CancelToken;
let cancelMoveJobToPosition: any;

type Dispatch = ThunkDispatch<State, {}, AnyAction>;

// Initial state
export const baseInitialState: State = {
  isLoading: false,
  routeId: undefined,
  isDeletingJob: false,
  routeJobs: [],
  routeDetails: {},
};

// Reducer
export const baseReducer = (state = baseInitialState, action: AnyAction, actionMap: any) => {
  switch (action.type) {
    case actionMap.START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case actionMap.COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeId: action.routeId,
          routeJobs: action.routeJobs,
          routeDetails: action.routeDetails,
        },
      });

    case actionMap.FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeJobs: [],
        },
      });

    case actionMap.START_DELETE_JOB:
      return update(state, {
        $merge: { isDeletingJob: true },
      });

    case actionMap.COMPLETE_DELETE_JOB:
      return update(state, {
        $merge: { isDeletingJob: false },
      });

    case actionMap.FAIL_DELETE_JOB:
      return update(state, {
        $merge: { isDeletingJob: false },
      });

    case actionMap.MOVE_JOB_TO_POSITION:
      return update(state, {
        routeJobs: { $set: arrayMove(state.routeJobs, action.fromPosition, action.toPosition) },
      });

    case actionMap.RESET:
      return update(state, { $merge: baseInitialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = (actionMap: AnyAction) => ({
  type: actionMap.START_LOAD,
});

const completeLoad = (actionMap: AnyAction, routeId: number, route: any) => ({
  type: actionMap.COMPLETE_LOAD,
  routeId,
  routeJobs: route.jobs,
  routeDetails: route.routeDetails,
});

const failLoad = (actionMap: AnyAction) => ({
  type: actionMap.FAIL_LOAD,
});

const startDeleteJob = (actionMap: AnyAction) => ({
  type: actionMap.START_DELETE_JOB,
});

const completeDeleteJob = (actionMap: AnyAction) => ({
  type: actionMap.COMPLETE_DELETE_JOB,
});

const failDeleteJob = (actionMap: AnyAction) => ({
  type: actionMap.FAIL_DELETE_JOB,
});

const moveJobToPosition = (actionMap: AnyAction, fromPosition: number, toPosition: number) => ({
  type: actionMap.MOVE_JOB_TO_POSITION,
  fromPosition,
  toPosition,
});

export const loadDispatchBoardJobs = (dispatch: Dispatch, actionMap: any, routeId: number, searchTerm?: string) => {
  dispatch(startLoad(actionMap));
  const promise = doLoadRouteJobs(routeId, searchTerm);
  promise.then(route => dispatch(completeLoad(actionMap, routeId, route))).catch(() => dispatch(failLoad(actionMap)));
  return promise;
};

export const moveDispatchBoardJobToPosition = (
  dispatch: Dispatch,
  getState: () => any,
  actionMap: any,
  fromPosition: number,
  toPosition: number,
  isSource: boolean,
) => {
  // Cancel previous call, if there is one in progress.
  if (cancelMoveJobToPosition) cancelMoveJobToPosition(translate('common.inFlightRequestCanceled'));
  dispatch(moveJobToPosition(actionMap, fromPosition, toPosition));
  const { routeId, routeJobs } = isSource
    ? getState().routes.dispatchBoard.sourceRouteJobs
    : getState().routes.dispatchBoard.targetRouteJobs;
  const jobPositions = map(routeJobs, ({ id }, index) => ({
    jobId: id,
    orderNo: index + 1,
  }));
  const config = {
    cancelToken: new CancelToken(c => {
      cancelMoveJobToPosition = c;
    }),
  };
  return doMoveRouteJobToPosition(routeId, jobPositions, config);
};

export const moveDispatchBoardAllJobsToPosition = (
  routeId: number,
  jobPositions: DispatchBoardRouteJobSimplified[],
) => {
  return doMoveRouteJobToPosition(routeId, jobPositions);
};

export const deleteDispatchBoardJob = (dispatch: Dispatch, actionMap: any, routeId: number, jobId: number) => {
  dispatch(startDeleteJob(actionMap));
  const promise = doDeleteRouteJobs(routeId, [jobId]);
  promise.then(() => dispatch(completeDeleteJob(actionMap))).catch(() => dispatch(failDeleteJob(actionMap)));
  return promise;
};

export const resetDispatchBoardJobs = (actionMap: any) => ({
  type: actionMap.RESET,
});
