import { change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from 'src/store';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { CustomerLocation, CustomerLocationAddress } from 'src/customers/interfaces/Customers';
import { customerLocationByIdSelector, saveCustomerLocation } from 'src/customers/ducks';
import { DesktopWidthView } from 'src/core/components/mediaQueries/DesktopWidthView';
import { DuckFunction } from 'src/contracts/ducks';
import { getFutureAccountStatus } from 'src/customers/services/futureAccountStatusValidator';
import { LngLat } from 'mapbox-gl';
import { MobileWidthView, UseIsMobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';
import { Modal } from 'src/core/components';
import { ModalSection, ModalTitle } from 'src/core/components/styled';
import { PinOnMapMapbox } from 'src/routes/components';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import CustomerLocationEditorForm from '../forms/CustomerLocationEditorForm';
import CustomerMapMapbox from '../mapGL/CustomerMapMapbox';
import translate from 'src/core/services/translate';

const formSelector = formValueSelector('customerLocationEditor');

interface Props extends RouteComponentProps<{ customerId: string }> {
  change: any;
  customerLocation?: any;
  isLoadingBulkyPickupByLocation: boolean;
  isLockedAddress?: boolean;
  isResetingBulkyPickUp: boolean;
  isSaving: boolean;
  isTemporaryAddress?: boolean;
  locationId?: number;
  newCustomerId?: number;
  onCancel: (pristine: boolean) => void;
  onSaveLocation: (location: any) => void;
  saveCustomerLocation: DuckFunction<typeof saveCustomerLocation>;
  serviceContractFullAddress: CustomerLocationAddress;
  serviceContractLocation: string;
}

class CustomerLocationsEditorModal extends PureComponent<
  Props,
  { customerLocation: CustomerLocation; addressChanged: boolean; pinOnMap: boolean }
> {
  readonly state = {
    customerLocation: this.props.customerLocation ? this.props.customerLocation : undefined,
    addressChanged: false,
    pinOnMap: false,
  };

  onAddressChange = (address: CustomerLocationAddress) => {
    this.setState({
      customerLocation: { ...this.state.customerLocation, address },
      addressChanged: true,
      pinOnMap: false,
    });
  };

  onDragPin = (newAddress: LngLat) => {
    if (!!newAddress) {
      this.setState({
        addressChanged: true,
        customerLocation: {
          ...this.state.customerLocation,
          address: {
            ...this.state.customerLocation.address,
            latitude: newAddress.lat,
            longitude: newAddress.lng,
          },
        },
      });
      this.props.change('customerLocationEditor', 'address', this.state.customerLocation.address);
    }
  };

  onSubmitCustomerLocation = async (formData: any) => {
    const {
      saveCustomerLocation,
      onCancel,
      onSaveLocation,
      match: {
        params: { customerId },
      },
      location,
      newCustomerId,
    } = this.props;

    const { isTemporaryAddress, temporaryAddress, address } = formData;
    const futureAccountStatus = get(formData, 'futureAccountStatus', {});
    const data = {
      ...formData,
      address: isTemporaryAddress ? { ...address, formattedAddress: temporaryAddress } : address,
      futureAccountStatus: getFutureAccountStatus(futureAccountStatus),
    };
    const redirectToPage = newCustomerId === undefined;

    await saveCustomerLocation(
      { ...data, customerId: Number(customerId || newCustomerId), name: data.locationName },
      location,
      redirectToPage,
    )
      .then(response => {
        createSuccessNotification(`${translate('customers.alertMessages.locationSaved')}`);
        if (data.id) onCancel(true);

        if (newCustomerId && typeof onSaveLocation === 'function') {
          onSaveLocation(response.data);
        }
      })
      .catch(({ code }) => {
        createErrorNotification(`${translate(createTranslationKey(code, 'customers.alertMessages'))}`);
      });
  };

  onMapPinSelected = (address: CustomerLocationAddress) => {
    this.onAddressChange(address);
    this.props.change('customerLocationEditor', 'address', {
      ...address,
      formattedAddress: address.line1 || address.formattedAddress,
    });
    this.setState({ pinOnMap: false });
  };

  render() {
    const {
      isLoadingBulkyPickupByLocation,
      isLockedAddress,
      isResetingBulkyPickUp,
      isSaving,
      isTemporaryAddress,
      locationId,
      onCancel,
      serviceContractFullAddress,
      serviceContractLocation,
    } = this.props;

    const { customerLocation, pinOnMap } = this.state;

    let customerLocations: CustomerLocation[];
    if (customerLocation) {
      customerLocations = [customerLocation];
    } else if (serviceContractFullAddress) {
      customerLocations = [{ address: serviceContractFullAddress } as any];
    } else {
      customerLocations = [];
    }

    const hasAddress = customerLocation && customerLocation.address;

    const pinMapCenter = hasAddress
      ? {
          lat: customerLocation.address.latitude,
          lng: customerLocation.address.longitude,
        }
      : undefined;

    const pinOnMapMarker = hasAddress
      ? [
          {
            address: {
              latitude: customerLocation.address.latitude,
              longitude: customerLocation.address.longitude,
            },
          },
        ]
      : undefined;

    return (
      <UseIsMobileWidthView
        render={isMobile => (
          <Modal
            padding="no"
            isLoading={isSaving || isResetingBulkyPickUp || isLoadingBulkyPickupByLocation}
            flexDirection={!isMobile ? 'row' : 'column-reverse'}
            size="xLarge"
          >
            <ModalSection padding="no" fluid>
              <CustomerLocationEditorForm
                onSubmit={this.onSubmitCustomerLocation}
                onPinLocationClick={() => this.setState({ pinOnMap: !customerLocation?.isEsriRecord })}
                closeModal={onCancel}
                onAddressChange={this.onAddressChange}
                locationId={locationId}
                serviceContractLocation={serviceContractLocation}
                serviceContractFullAddress={serviceContractFullAddress}
              />
            </ModalSection>
            <ModalSection height={isMobile ? '250px' : undefined} padding={pinOnMap ? 'medium no' : 'no'} fluid>
              {pinOnMap ? (
                <>
                  <DesktopWidthView>
                    <PinOnMapMapbox
                      mapCenterByVendor={pinMapCenter}
                      handleBackClick={() => this.setState({ pinOnMap: false })}
                      handlePinSelection={this.onMapPinSelected}
                      modalHasTitle={false}
                      mapMarker={pinOnMapMarker}
                      shouldBeDraggableByDefault={true}
                      shouldHideMapFilters={true}
                    />
                  </DesktopWidthView>
                  <MobileWidthView>
                    <Modal>
                      <PinOnMapMapbox
                        mapCenterByVendor={pinMapCenter}
                        handleBackClick={() => this.setState({ pinOnMap: false })}
                        handlePinSelection={this.onMapPinSelected}
                        modalHasTitle={false}
                        mapMarker={pinOnMapMarker}
                        shouldBeDraggableByDefault={true}
                        shouldHideMapFilters={true}
                      />
                    </Modal>
                  </MobileWidthView>
                </>
              ) : (
                <CustomerMapMapbox
                  customerLocations={customerLocations}
                  singlePin
                  onAddressChange={this.onDragPin}
                  draggablePin={isTemporaryAddress && !isLockedAddress}
                  addressChanged={this.state.addressChanged}
                />
              )}
            </ModalSection>
            {isMobile && (
              <ModalTitle margin="small no">
                {translate(`customers.${locationId ? 'edit' : 'create'}Location`)}
              </ModalTitle>
            )}
          </Modal>
        )}
      />
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props) => {
  const { locationId } = ownProps;
  const customerLocation = (customerLocationByIdSelector as any)(state.customers.locations, locationId);
  const isTemporaryAddress = formSelector(state, 'isTemporaryAddress');
  const isLockedAddress = formSelector(state, 'isLockedAddress');

  return {
    customerLocation,
    isLoadingBulkyPickupByLocation: state.customers.locations.isLoadingBulkyPickupByLocation,
    isLockedAddress,
    isResetingBulkyPickUp: state.customers.locations.isResetingBulkyPickUp,
    isSaving: state.customers.locations.isSaving,
    isTemporaryAddress,
  };
};

const mapDispatchToProps = {
  saveCustomerLocation,
  change,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerLocationsEditorModal));
