import { Icon } from 'src/core/components';
import Tooltip from 'src/core/components/Tooltip';
import { Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import translate from 'src/core/services/translate';
import { DashboardMapDataInformationWrapper } from 'src/dashboard/components/styled';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';

interface MapLastRefreshProps {
  lastRefreshed: string;
  isSatelliteView: boolean;
  onRefresh: () => void;
}

const MapLastRefresh = ({ lastRefreshed, isSatelliteView, onRefresh }: MapLastRefreshProps) => {
  return (
    <ComplexMapControl position="top-left" zIndex={1} xOffset={50} yOffset={-10}>
      <DashboardMapDataInformationWrapper color={isSatelliteView ? 'white' : 'black'}>
        <Box display="flex" alignItems="center">
          <Text block singleLine>
            {translate('dashboard.filterKeys.lastRefreshed')}: <span>{lastRefreshed || 'NA'}</span>
          </Text>
          <Tooltip
            tooltip="refresh"
            width={14}
            height={14}
            borderRadius={14}
            display="inline-flex"
            alignItems="center"
            tooltipPosition="right"
            justifyContent="center"
            ml={10}
            cursor="pointer"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onRefresh();
            }}
          >
            <Icon icon="refresh" height="14px" width="14px" color={isSatelliteView ? 'white' : 'black'} />
          </Tooltip>
        </Box>
      </DashboardMapDataInformationWrapper>
    </ComplexMapControl>
  );
};

export default MapLastRefresh;
