import { filter, get, isEqual, map } from 'lodash-es';
import { connect } from 'react-redux';

import { change, Field, formValueSelector, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';

import { AppState } from 'src/store';
import { customerLocationsSelector } from 'src/customers/ducks';
import { DatePicker, Dropdown, Input, NavigationPrompt, Switch, TypedField } from '../../../core/components';
import { Facility } from 'src/common/interfaces/Facility';
import { FILTER_SERVICE_ZONE_ID, FILTER_SUPERVISOR_ID } from 'src/common/constants';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { getExcludedFiltersIds } from 'src/common/utils/filters';
import { Grid, GridColumn, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import { Group } from 'src/routes/interfaces/Groups';
import { GroupsMultiSelect, ServiceZoneDropdown, SupervisorDropdown } from '..';
import { isDateValidValidator, isRequired, maxLength50 } from '../../../utils/services/validator';
import { ROUTE_PRIORITY_TYPES_OPTIONS } from 'src/routes/constants/routePriorityTypes';
import { RouteTemplate } from '../../interfaces/RouteTemplates';
import { SNOW_PLOW_ID, STREET_SWEEPER, VEHICLE_TYPE_IDS, WASTE_AUDIT } from '../../../fleet/constants';
import { StreetNetwork } from 'src/customers/interfaces/StreetNetwork';
import { Supervisor } from 'src/routes/interfaces/Supervisors';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { technicalNameByVehicleTypeIdSelector } from 'src/vendors/ducks';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import snowOrSweeperRouteTemplateEditorFomInitialValuesSelector from 'src/routes/services/snowOrSweeperRouteTemplateEditorFomInitialValuesSelector';
import translate from '../../../core/services/translate';

export interface FormValues {
  isTestRoute: boolean;
  routeDate: Date | string;
  routeName: string;
  endingLocationId: number;
  startingLocationId: number;
  supervisorId: number;
  vendorServiceZoneId: number;
  wasteMaterialTypeId: number;
  shouldRecreateRoutes: boolean;
  routePriorityTypeId: number;
  groupIds: number[];
}
interface PropsWithoutReduxForm {
  change?: any;
  disabledFields: { [key: string]: boolean | string | number | undefined };
  disposalFacilities?: Facility[];
  filtersPreferences?: FilterSetting[];
  groups?: Group[];
  isCreateMode?: boolean;
  isSnowPlowRoute?: boolean;
  routeTemplate?: RouteTemplate;
  routeTemplateSegments?: StreetNetwork[];
  serviceZones?: number[];
  supervisorExperienceEnabled?: boolean;
  supervisors?: Supervisor[];
  truckYardFacilities?: Facility[];
  vehicleTypeTechnicalName?: string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

export const SNOW_OR_SWEEPER_ROUTE_TEMPLATE_EDITOR_FORM_NAME = 'snowOrSweeperRouteTemplateEditorForm';

const SnowOrSweeperRouteTemplateEditorForm = (props: Props) => {
  const {
    change,
    disabledFields,
    handleSubmit,
    pristine,
    truckYardFacilities,
    disposalFacilities,
    routeTemplate,
    routeTemplateSegments,
    submitSucceeded,
    supervisorExperienceEnabled,
    supervisors,
    vehicleTypeTechnicalName = VEHICLE_TYPE_IDS[SNOW_PLOW_ID].technicalName,
    isCreateMode,
    filtersPreferences,
    serviceZones,
    initialValues,
    groups,
  } = props;

  const routeTemplateLocationIds = map(routeTemplateSegments, routeTemplateSegment => routeTemplateSegment.id);

  const locationIds = map(
    filter(
      get(routeTemplate, 'routeLocations'),
      routeTemplateLocation => !!routeTemplateLocation.location.streetSegment,
    ),
    routeTemplateLocation => routeTemplateLocation.location?.streetSegment?.id,
  );

  const differentRouteTemplateLocations = !isEqual(routeTemplateLocationIds, locationIds);
  const isVisibleSupervisor = vehicleTypeTechnicalName !== STREET_SWEEPER && vehicleTypeTechnicalName !== WASTE_AUDIT;

  const excludedServiceZonesFiltersIds = isCreateMode
    ? getExcludedFiltersIds(serviceZones, filtersPreferences, FILTER_SERVICE_ZONE_ID)
    : filter(
        getExcludedFiltersIds(serviceZones, filtersPreferences, FILTER_SERVICE_ZONE_ID),
        id => initialValues && initialValues.vendorServiceZoneId !== id,
      );
  const excludedSupervisorsFiltersIds = isCreateMode
    ? getExcludedFiltersIds(supervisors, filtersPreferences, FILTER_SUPERVISOR_ID)
    : filter(
        getExcludedFiltersIds(supervisors, filtersPreferences, FILTER_SUPERVISOR_ID),
        id => initialValues && initialValues.supervisorId !== id,
      );
  const onStartingLocationChange = (event: any, value: number) => {
    change('endingLocationId', value);
  };

  // groupIds to exclude those inactive groups  except if initial value is inactive
  const groupIdsToExclude = map(
    filter(groups, group => !initialValues?.groupIds?.includes(group.id) && !group.isActive),
    'id',
  );

  let filteredTruckYardFacilities = [] as Facility[];
  if (!!truckYardFacilities)
    filteredTruckYardFacilities = !isCreateMode
      ? truckYardFacilities?.filter(
          facility =>
            facility.isActive || (!facility.isActive && facility.locationId === initialValues.startingLocationId),
        )
      : truckYardFacilities;

  let filteredCustomerLocations = [] as Facility[];
  if (!!truckYardFacilities && !!disposalFacilities)
    filteredCustomerLocations = !isCreateMode
      ? [...truckYardFacilities, ...disposalFacilities].filter(
          facility =>
            facility.isActive || (!facility.isActive && facility.locationId === initialValues.endingLocationId),
        )
      : [...truckYardFacilities, ...disposalFacilities];

  const startingLocationOptions = customerLocationsSelector(filteredTruckYardFacilities);
  const endingLocationOptions = customerLocationsSelector(filteredCustomerLocations);

  return (
    <PanelSectionGroup>
      <NavigationPrompt when={(!pristine || differentRouteTemplateLocations) && !submitSucceeded} />
      <form onSubmit={handleSubmit} noValidate>
        <PanelSection padding="small xSmall no">
          <Grid>
            <GridColumn size="4/12">
              <Field
                name="routeTemplateName"
                component={Input}
                label={translate('routes.routeName')}
                validate={[isRequired, maxLength50]}
                disabled={disabledFields.routeTemplateName}
              />
            </GridColumn>
            <GridColumn size="2/12">
              <Field
                name="startDate"
                component={DatePicker}
                margin="no"
                label={translate('common.startDate')}
                disabled={disabledFields.startDate}
                validate={[isDateValidValidator]}
              />
            </GridColumn>
            <GridColumn size="3/12">
              <Field
                name="startingLocationId"
                component={Dropdown as any}
                options={startingLocationOptions}
                label={translate('routes.startingLocation')}
                disabled={disabledFields.startLocationId}
                onChange={onStartingLocationChange}
                isClearable
              />
            </GridColumn>
            <GridColumn size="3/12">
              <Field
                name="endingLocationId"
                component={Dropdown as any}
                options={endingLocationOptions}
                label={translate('routes.endingLocation')}
                disabled={disabledFields.startLocationId}
                isClearable
              />
            </GridColumn>
          </Grid>
        </PanelSection>
        <PanelSection padding="no xSmall xSmall" withBorder>
          <Grid multiLine>
            <GridColumn size="3/12">
              <Field
                name="vendorServiceZoneId"
                component={ServiceZoneDropdown}
                label={translate('routes.serviceZone')}
                props={{
                  excludeServiceZonesIds: excludedServiceZonesFiltersIds,
                }}
              />
            </GridColumn>
            {supervisorExperienceEnabled && isVisibleSupervisor && (
              <GridColumn size="3/12">
                <TypedField
                  name="supervisorId"
                  component={SupervisorDropdown}
                  props={{
                    withLabel: true,
                    excludeSupervisorsIds: excludedSupervisorsFiltersIds,
                  }}
                />
              </GridColumn>
            )}
            <GridColumn size="2/12">
              <TypedField
                name="groupIds"
                component={GroupsMultiSelect}
                props={{
                  withLabel: true,
                  excludeGroupsIds: groupIdsToExclude,
                  cantSelectInactive: true,
                  multiSelectProps: {
                    defaultToAll: false,
                  },
                }}
              />
            </GridColumn>
            <GridColumn size="2/12">
              <Field
                name="routePriorityTypeId"
                component={Dropdown}
                options={ROUTE_PRIORITY_TYPES_OPTIONS}
                label={translate('customers.streetNetwork.priority')}
                validate={[isRequired]}
              />
            </GridColumn>
            <GridColumn size="2/12" alignVerticalCenter>
              <Field
                name="isEnabled"
                component={Switch}
                label={translate('routes.activateRouteTemplate')}
                margin="no"
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    </PanelSectionGroup>
  );
};

const formSelector = formValueSelector(SNOW_OR_SWEEPER_ROUTE_TEMPLATE_EDITOR_FORM_NAME);

const mapStateToProps = (state: AppState, ownProps: PropsWithoutReduxForm) => {
  const vehicleTypeId = Number(formSelector(state, 'vehicleTypeId'));
  const formValues = getFormValues('updateTrackerRouteSwitchWrapper')(state) as any;
  return {
    initialValues: snowOrSweeperRouteTemplateEditorFomInitialValuesSelector(
      state.routes.routeTemplate,
      state.routes.geoFence,
      formValues ? formValues.shouldRecreateRoutes : false,
      ownProps.isSnowPlowRoute,
    ),
    truckYardFacilities: state.fleet.facilities.operationalFacilities,
    disposalFacilities: state.fleet.facilities.disposalFacilities,
    routeTemplate: state.routes.routeTemplate.routeTemplate,
    vehicleTypeTechnicalName: technicalNameByVehicleTypeIdSelector(state.fleet.vehicleTypesForVendor, vehicleTypeId),
    supervisors: state.routes.supervisors.supervisors,
    supervisorExperienceEnabled: supervisorExperienceFeatureIsEnabled(state),
    filtersPreferences: state.common.filters.filters as unknown as FilterSetting[],
    serviceZones: state.routes.serviceZones.serviceZones,
    groups: state.routes.groups.groups,
  };
};

const mapDispatchToProps = { change };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<FormValues, PropsWithoutReduxForm>({
    enableReinitialize: true,
    form: SNOW_OR_SWEEPER_ROUTE_TEMPLATE_EDITOR_FORM_NAME,
    onSubmitFail: focusFirstInvalidField,
  })(SnowOrSweeperRouteTemplateEditorForm),
);
