import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { BareButtonStyle } from './Button';
import { loading, LIGHT } from '../../styles';
import { sizeMapper } from '../../../utils/styles';
import Icon from '../Icon';

export const ImageSliderNavigationIcon = styled(Icon)`
  width: 12px;
  height: 12px;
  margin-top: 2px;
`;

export const ImageSliderNavigation = styled.button.attrs({ type: 'button' })<{ previous?: boolean; next?: boolean }>`
  ${BareButtonStyle};
  position: absolute;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  background-color: ${props => transparentize(0.6, props.theme.grayBase)};
  border: 2px solid ${transparentize(0.6, '#fff')};
  border-radius: 20px;
  color: #fff;

  &:active {
    background-color: ${props => transparentize(0.5, props.theme.grayBase)};
    border-color: ${transparentize(0.5, '#fff')};
  }

  ${props =>
    props.previous &&
    css`
      left: 10px;
    `};

  ${props =>
    props.next &&
    css`
      right: 10px;
    `};
`;

export const ImageSliderLoading = styled.span`
  position: absolute;
  top: 20px;
  right: 20px;
  ${loading('20px', LIGHT)};
`;

export const ImageSliderPosition = styled.span`
  position: absolute;
  bottom: 20px;
  left: 50%;
  display: inline-block;
  width: 100px;
  margin-left: -50px;
  text-align: center;
  text-shadow: 0 0 4px ${props => transparentize(0.4, props.theme.grayBase)};
  color: #fff;
`;

export const ImageSliderRotate = styled.button`
  ${BareButtonStyle};
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
`;

export const ImageSliderDownloadIcon = styled(Icon).attrs({ icon: 'download' })`
  width: 14px;
  height: 14px;
  margin-right: 6px;
  vertical-align: middle;
`;

export const VideoDownloadWrapper = styled.div`
  margin: 15px 0 0 0;
  position: relative;
`;

export const VideoDownload = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  text-shadow: 0 0 4px ${props => transparentize(0.4, props.theme.grayBase)};
  color: #fff;
  cursor: pointer;
  z-index: 1;
  padding: 12px;
  background: #000;
`;

export const ImageSliderDownload = styled.a`
  position: absolute;
  bottom: 20px;
  right: 20px;
  text-shadow: 0 0 4px ${props => transparentize(0.4, props.theme.grayBase)};
  color: #fff;
  cursor: pointer;
`;

export const ImageSliderImage = styled.img<{ rotationAngle?: number }>`
  display: block;
  max-width: 800px;
  max-height: 60vh;

  ${props =>
    props.rotationAngle &&
    css`
      transform: rotate(${props.rotationAngle}deg);
    `};
`;

export const ThumbnailsImageSliderImage = styled.img`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin: 5px;
`;
export const ImageSliderContent = styled.div``;

export const ThumbnailsImageSliderContent = styled.div<{ isActive?: boolean }>`
  display: inline-flex;
  margin: 2px 2px 5px;
  ${props =>
    props.isActive &&
    css`
      border: 2px solid #00a599;
    `};
`;
export const ImageControl = styled.div`
  font-size: 12px;
  color: ${props => props.theme.brandDefault};

  &:first-child {
    margin-right: 10px;
  }

  &:last-child {
    margin-left: 10px;
  }
`;

interface Props {
  margin?: string;
  position?: string;
}

export const ImageSlider = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => sizeMapper(props.margin, 'no')};
  overflow: hidden;
  background-color: ${props => props.theme.grayBase};
  ${props =>
    props.position &&
    css`
      ${ImageSliderContent} {
        transform: translateX(-${props.position}00%);
      }
    `};
`;
export const ThumbnailImageSlider = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => sizeMapper(props.margin, 'no')};
  overflow: hidden;
  background-color: #ffffff;
  ${props =>
    props.position &&
    css`
      ${ThumbnailsImageSliderContent} {
        transform: translateX(-${props.position}00%);
      }
    `};
`;

export const ImageSliderWrapper = styled.div``;
