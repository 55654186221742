import FacilitiesGeoFenceGLSource from './FacilitiesGeoFenceGLSource';

type Props = {
  geoFencesGeoJSON: any;
  isSatellite: boolean;
};

export default function FacilitiesGeoFenceGL({ geoFencesGeoJSON, isSatellite }: Props) {
  return <FacilitiesGeoFenceGLSource geoJSON={geoFencesGeoJSON} isSatellite={isSatellite} />;
}
