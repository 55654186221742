import { map } from 'lodash-es';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';
import { TechnicalType } from '../interfaces/TechnicalType';

const transformLoadPickupFrequencyTypes = (pickupFrequencyTypes: TechnicalType[]) =>
  map(pickupFrequencyTypes, pickupFrequencyType => ({
    ...pickupFrequencyType,
    name: translate(createTranslationKey(pickupFrequencyType.technicalName, 'common.pickupFrequencyTypes')),
  }));

export default transformLoadPickupFrequencyTypes;
