import { MobileModal } from 'src/core/components/MobileModal';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import OpportunitiesPageFilterForm from '../forms/OpportunitiesPageFilterForm';

interface Props {
  onClose: () => void;
  initialValues: any;
  onChange: (values: any) => void;
  disabledEquipmentSubTypeIds?: string[];
  disabledMaterialTypeIds?: string[];
  disabledServiceIds?: number[];
  disabledDateRange: boolean;
  defaultFilterId?: number | string | null;
  prevDefaultFilterId?: number | string | null;
  hidden: boolean;
}

export const OpportunitiesMobileFiltersModal: React.FC<Props> = ({
  onClose,
  initialValues,
  onChange,
  hidden,
  ...disabledFilters
}) => {
  const isLoading = useSelector(s => s.fleet.rubiconDispatches.isLoading);

  return (
    <MobileModal
      isLoading={isLoading}
      flex
      flexDirection="column"
      onClose={onClose}
      title={translate('common.filters')}
      hidden={hidden}
    >
      <OpportunitiesPageFilterForm
        initialValues={initialValues}
        onChange={onChange}
        {...disabledFilters}
      />
    </MobileModal>
  );
};
