import { useState } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import moment from 'moment';

import { DatePicker, Input, TypedField } from 'src/core/components';
import { SnowSweeperDatePickerWrapper } from '../styled/RouteTracker';

export const SNOW_SWEEPER_DATE_PICKER_FORM = 'snowSweeperDatePickerForm';

type SnowOrSweeperDatePickerFormValues = {
  startDate: any | Date | string;
  endDate: any | Date | string;
};

type PropsWithoutReduxForm = {
  onDateChange: (startDate: any | Date | string, endDate: any | Date | string) => void;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<SnowOrSweeperDatePickerFormValues, PropsWithoutReduxForm>;

export default reduxForm<SnowOrSweeperDatePickerFormValues, PropsWithoutReduxForm>({
  form: SNOW_SWEEPER_DATE_PICKER_FORM,
  enableReinitialize: true,
})(function SnowSweeperDatePickerForm({ onDateChange, initialValues }: Props) {
  const startDate = initialValues && initialValues.startDate ? moment(initialValues.startDate).toDate() : undefined;
  const endDate = initialValues && initialValues.endDate ? moment(initialValues.endDate).toDate() : undefined;

  const [segmentsFilterStartDate, setSegmentsFilterStartDate] = useState(moment(startDate).format('MM/DD/YYYY'));
  const [segmentsFilterEndDate, setSegmentsFilterEndDate] = useState(moment(endDate).format('MM/DD/YYYY'));

  const handleOnDateChange = (startDate: any | Date | string, endDate: any | Date | string) => {
    setSegmentsFilterStartDate(startDate);
    setSegmentsFilterEndDate(endDate);

    onDateChange(startDate, endDate);
  };

  return (
    <form>
      {/* TODO: SNOW 3.0 - remove 'display: none' when date picker should be visible */}
      <div style={{ display: 'none' }}>
        <SnowSweeperDatePickerWrapper>
          {segmentsFilterStartDate} - {segmentsFilterEndDate}
        </SnowSweeperDatePickerWrapper>
        <TypedField
          name="startDate"
          component={DatePicker}
          props={{
            disabledDays: [
              {
                after: endDate,
              },
              {
                before: moment(startDate).subtract(3, 'days').toDate(),
              },
            ],
            width: '215px',
            isValueHidden: true,
          }}
          onChange={startDate => handleOnDateChange(startDate, moment(endDate).format('MM/DD/YYYY'))}
        />
        <TypedField name="endDate" component={Input} props={{ type: 'hidden' }} />
      </div>
    </form>
  );
});
