import React, { MouseEvent } from 'react';

import { size } from 'lodash-es';
import { RouteComponentProps } from 'react-router';

import { ActionButtonTooltip } from 'src/core/components';
import {
  CUSTOMERS_DELETE,
  ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS,
  ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS,
} from 'src/account/constants';
import { CUSTOMER_TYPE_IDS_FOR_JOB_ADDITION } from 'src/customers/constants/customerTypes';
import { CustomerEmailModalResolver } from '../modals/CustomerEmailModalResolver';
import { CustomerLocation } from 'src/customers/interfaces/Customers';
import { DispatchBoardJobEditorModalResolver } from 'src/routes/components/modals';
import { hasPermission } from 'src/account/utils/permissions';
import { PermissionGuard } from 'src/account/components';
import { STREET } from 'src/common/constants';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { useSelector } from 'src/core/hooks/useSelector';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';

interface Props
  extends RouteComponentProps<
    {},
    {},
    {
      searchParams: string;
    }
  > {
  customerType: string;
  deleteCustomer: (customerId: number, event: MouseEvent) => void;
  id: number;
  locations: CustomerLocation[];
  name: string;
  push: (url: string) => void;
  customerTypeId: number;
  isPaymentOverdue: boolean;
}

const CustomersTableRow = ({
  id,
  name,
  customerType,
  customerTypeId,
  locations,
  push,
  deleteCustomer,
  location,
  isPaymentOverdue,
}: Props) => {
  const [jobEditorOpen, setJobEditorOpen] = React.useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = React.useState(false);

  const isBillingFeatureActive = useSelector(state => billingFeatureStatusSelector(state.vendors.features.features));

  const openJobEditor = React.useCallback(event => {
    event.stopPropagation();
    setJobEditorOpen(true);
  }, []);

  const closeJobEditor = React.useCallback(() => {
    setJobEditorOpen(false);
  }, []);

  const searchParams = location.search;

  const isViewOnly =
    !hasPermission(ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS) &&
    !hasPermission(ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS);

  const customerTypeSupportsJobAdd = CUSTOMER_TYPE_IDS_FOR_JOB_ADDITION.indexOf(customerTypeId) !== -1;

  return (
    <>
      <TableRow onClick={() => push(`/customers/customers/${id}/edit${searchParams}`)} id={`customer-${id}`}>
        <TableCell width="30%" vertical>
          <Text block weight="medium" margin="no no xxSmall" singleLine id={`customer-${id}-name`}>
            {name}
            {isPaymentOverdue && isBillingFeatureActive && (
              <ActionButtonTooltip size="medium" icon="overdue" tooltip="pastDue" margin="no no no xxSmall" />
            )}
          </Text>

          <Text weight="light" margin="no no xxSmall" singleLine id={`customer-${id}-type`}>
            {customerType}
          </Text>
        </TableCell>

        <TableCell width="60%" vertical>
          {!!size(locations) && (
            <Text block weight="medium" margin="no no xxSmall" singleLine id={`customer-${id}-address`}>
              {locations[0].address.line1}
            </Text>
          )}

          {!!size(locations) && size(locations) > 1 && (
            <Text block weight="light" margin="no no xxSmall" singleLine>
              {translate('customers.numberOfLocations', { size: size(locations) - 1 })}
            </Text>
          )}
        </TableCell>

        <TableCell width="10%" align="right">
          {customerTypeId !== STREET && (
            <TableActionButton
              onClick={event => {
                event.stopPropagation();
                setIsEmailModalOpen(true);
              }}
              id={`customer-${id}-send-mail`}
            >
              <ActionButtonTooltip icon="email" tooltip="sendEmail" />
            </TableActionButton>
          )}
          {customerTypeSupportsJobAdd && !isViewOnly && (
            <TableActionButton onClick={openJobEditor} id={`route-${id}-add-job`}>
              <ActionButtonTooltip icon="add" tooltip="addJob" />
            </TableActionButton>
          )}

          {customerTypeId !== STREET && (
            <PermissionGuard permission={CUSTOMERS_DELETE}>
              <TableActionButton onClick={event => deleteCustomer(id, event)} id={`customer-${id}-delete-button`}>
                <ActionButtonTooltip icon="delete" tooltip="delete" />
              </TableActionButton>
            </PermissionGuard>
          )}
        </TableCell>
      </TableRow>

      {jobEditorOpen && <DispatchBoardJobEditorModalResolver customerId={id} closeModal={closeJobEditor} />}
      {isEmailModalOpen && (
        <CustomerEmailModalResolver customerId={id} onClose={() => setIsEmailModalOpen(false)} isInfoEmail email={{}} />
      )}
    </>
  );
};

export default CustomersTableRow;
