import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../../core/services/createNotification';
import { customField } from '../../../interfaces/Open311';
import DefaultNoteFieldsEditorForm from '../../forms/DefaultNoteFieldsEditorForm';
import { Modal } from '../../../../core/components';
import { PROPERTY } from 'src/core/constants/scheduledDateType';
import { saveGlobalNoteField, saveServiceRequestNoteField } from '../../../ducks/open311Settings';
import translate from '../../../../core/services/translate';

type Props = {
  configurationId: number;
  notefieldId?: number;
  serviceTypeId?: number;
  onClose: (formPristine?: boolean, serviceRequestNotes?: customField[]) => void;
};

export default function DefaultNoteFieldsEditorModal({ configurationId, notefieldId, serviceTypeId, onClose }: Props) {
  const dispatch = useDispatch();
  const isSaving = useSelector((state: AppState) => state.vendors.open311Settings.isSaving);
  const globalNoteFields = useSelector((state: AppState) => state.vendors.open311Settings.globalNoteFields);
  const serviceTypes = useSelector((state: AppState) => state.vendors.open311Settings.serviceTypes);

  const serviceTypeNoteFields = serviceTypes?.find(st => st.id === serviceTypeId)?.noteFields;
  const isEditMode = !!notefieldId;

  const rawGlobalNoteField = globalNoteFields?.find(gn => gn.id === notefieldId);
  let formInitialValues: any = { fieldType: PROPERTY };

  if (rawGlobalNoteField) {
    const { fieldName, fieldDisplayName, fieldType } = rawGlobalNoteField;
    formInitialValues = {
      fieldName,
      fieldDisplayName,
      fieldType,
    };
  } else if (serviceTypeNoteFields) {
    const rawServiceTypeNoteField = serviceTypeNoteFields?.find(gn => gn.id === notefieldId);
    if (rawServiceTypeNoteField) {
      const { fieldName, fieldDisplayName, fieldType } = rawServiceTypeNoteField;
      formInitialValues = {
        fieldName,
        fieldDisplayName,
        fieldType,
      };
    }
  }

  const handleSubmitCreationRule = async (formData: any) => {
    const saveGlobalNoteFieldObj = {
      id: notefieldId || 0,
      configurationId,
      ...formData,
    };
    const serviceTypeNoteFieldsObj = {
      id: notefieldId || 0,
      serviceTypeId,
      ...formData,
    };
    const promise = serviceTypeId
      ? saveServiceRequestNoteField(configurationId, serviceTypeId, serviceTypeNoteFieldsObj, notefieldId)(dispatch)
      : saveGlobalNoteField(configurationId, saveGlobalNoteFieldObj, notefieldId)(dispatch);
    promise
      .then(async response => {
        createSuccessNotification(translate('vendors.open311.alertMessages.creationRuleSaved'));
        onClose(true, response);
      })
      .catch(() => {
        createErrorNotification(translate('vendors.open311.alertMessages.creationRuleSaveError'));
      });
  };

  return (
    <Modal
      size="large"
      isLoading={isSaving}
      onClose={onClose}
      title={translate(`vendors.${isEditMode ? 'editDefaultNoteField' : 'addCustomField'}`)}
    >
      <DefaultNoteFieldsEditorForm
        initialValues={formInitialValues}
        onSubmit={handleSubmitCreationRule}
        onClose={onClose}
      />
    </Modal>
  );
}
