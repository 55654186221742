import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import { orderBy } from 'lodash-es';

import AgreementsPage from './AgreementsPage';
import { AppState } from '../../../store';
import {
  currentVendorIdSelector,
  currentVendorGusIdSelector,
  currentUserIdSelector,
} from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { Districts } from '../../../vendors/interfaces/DivisionAccess';
import { loadHaulerProfileDivisions } from '../../ducks/divisions';
import {
  HAULER_PROFILE_DIVISION_COOKIE,
  HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION,
} from '../../constants/HaulerProfileDivisionCookie';
import { loadFiles } from '../../ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props {
  vendorGroupId?: string;
  userId?: string;
  vendorId: number;
  loadFiles(...args: any[]): any;
  loadHaulerProfileDivisions(...args: any[]): any;
}

class AgreementsPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { vendorGroupId, userId, vendorId, loadHaulerProfileDivisions, loadFiles } = this.props;
    const divisionIdFromCookie = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE);
    const params = { vendorGroupId, userId, divisionId: divisionIdFromCookie };

    return divisionIdFromCookie
      ? Promise.all([
          loadFiles({
            ...params,
            includeSubFolders: true,
          }),
          loadHaulerProfileDivisions(vendorId),
        ])
      : loadHaulerProfileDivisions(vendorId).then((response: Districts[]) => {
          const allDivisions = response;
          if (allDivisions.length > 0) {
            const assignedDivisionsOrderByName = orderBy(allDivisions, 'name', 'asc');
            const divisionId = assignedDivisionsOrderByName[0].value;
            Cookie.set(HAULER_PROFILE_DIVISION_COOKIE, divisionId, {
              expires: HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION,
            });
            const newParams = { vendorGroupId, userId, divisionId };
            return Promise.all([
              loadFiles({
                ...newParams,
                includeSubFolders: true,
              }),
            ]);
          }
        });
  };
  render() {
    const { vendorGroupId, userId } = this.props;
    const params = { vendorGroupId, userId };

    return (
      <Fragment>
        <DocumentTitle>{translate('haulerProfile.agreements')}</DocumentTitle>
        <Resolver
          resolve={this.loadDependencies}
          successComponent={AgreementsPage}
          successProps={{ params }}
          loadingComponent={PageLoading}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorGroupId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadFiles,
  loadHaulerProfileDivisions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgreementsPageResolver);
