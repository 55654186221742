import React, { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { ActionButtonTooltip } from '../../../../../core/components';
import { IconButtonIcon } from '../../../../../core/components/styled';
import { PopoverWrapper } from '../../../../../core/components';
import { TableActionButton } from '../../../../../core/components/styled';
import { TOP } from '../../../../../core/constants';
import createPopover from '../../../../../core/services/createPopover';
import RouteGeoFenceAlertsForm from '../../../forms/RouteGeoFenceAlertsForm';
import TooltipIconButton from '../../../../../core/components/TooltipIconButton';
import translate from '../../../../../core/services/translate';

interface RouteParams {
  routeId: string;
  routeTemplateId: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  alertsAreReadOnly?: boolean;
  geoFenceId?: number;
  routeIsNotScheduled?: boolean;
  hasSourceFromList?: boolean;
}

interface State {
  isGeoFenceAlertVisible: boolean;
}

class RouteGeoFenceAlerts extends PureComponent<Props, State> {
  readonly state: State = {
    isGeoFenceAlertVisible: false,
  };

  popupCloseHandler?: any;

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = () => {
    const { isGeoFenceAlertVisible } = this.state;

    if (isGeoFenceAlertVisible) {
      this.setState({ isGeoFenceAlertVisible: !isGeoFenceAlertVisible });
    }
  };

  closeGeoFenceAlert = () => {
    const { isGeoFenceAlertVisible } = this.state;

    if (isGeoFenceAlertVisible) {
      this.setState({ isGeoFenceAlertVisible: !isGeoFenceAlertVisible });
    }
    this.closePopover();
  };

  showGeoFenceAlert(event: any) {
    event.stopPropagation();

    const { alertsAreReadOnly, geoFenceId } = this.props;
    const { isGeoFenceAlertVisible } = this.state;

    this.setState({ isGeoFenceAlertVisible: true });

    if (!isGeoFenceAlertVisible) {
      (this as any).closePopover = createPopover(
        event.target,
        RouteGeoFenceAlertsForm,
        {
          alertsAreReadOnly,
          geoFenceId,
          closeGeoFenceAlert: this.closeGeoFenceAlert,
        },
        {
          position: TOP,
          tabletRightAlign: true,
        },
      );
    } else {
      this.closePopover();
    }
  }

  closePopover = () => {
    if ((this as any).closePopover) (this as any).closePopover();
  };

  render() {
    const { isGeoFenceAlertVisible } = this.state;
    const {
      geoFenceId,
      hasSourceFromList,
      routeIsNotScheduled = false,
      match: {
        params: { routeId, routeTemplateId },
      },
    } = this.props;

    let tooltipMessage = translate('routes.geoFences.geoFenceAlerts');
    let buttonDisabled = false;

    if (!geoFenceId) {
      buttonDisabled = true;

      if (!routeId && !routeTemplateId) {
        tooltipMessage = translate('routes.geoFences.alertMessages.saveRouteBefore');
      } else {
        tooltipMessage = translate('routes.geoFences.alertMessages.createGeoFenceBefore');
      }
    }

    if (routeIsNotScheduled) {
      buttonDisabled = true;
      tooltipMessage = translate('routes.geoFences.availableForScheduledOnly');
    }

    return hasSourceFromList ? (
      <TableActionButton
        id={`edit-alert-geo-fence-${geoFenceId}-button`}
        onClick={(event: React.MouseEvent) => this.showGeoFenceAlert(event)}
      >
        <ActionButtonTooltip icon="bell" tooltip="editAlert" />
      </TableActionButton>
    ) : (
      <PopoverWrapper
        triggerButton={
          <TooltipIconButton
            tooltipAsString
            tooltipPosition="left"
            margin="small no no"
            disabled={buttonDisabled}
            color={isGeoFenceAlertVisible ? 'primary' : 'secondary'}
            tooltip={tooltipMessage}
            onClick={(event: React.MouseEvent) => this.showGeoFenceAlert(event)}
          >
            <IconButtonIcon icon="addAlert" size="large" />
          </TooltipIconButton>
        }
        size="medium"
      />
    );
  }
}

export default withRouter(RouteGeoFenceAlerts);
