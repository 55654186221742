import styled from 'styled-components';

import { Devices, theme } from '../../../core/styles';

export const SocialPulsePostContainer = styled.div`
  display: flex;
  margin: 15px 0;
`;

export const SocialPulsePostContent = styled.div`
  width: auto;
  @media ${Devices.mobile} {
    margin: 0px 10px;
  }
`;

export const SocialPostLink = styled.a`
  display: block;
  color: inherit;
`;

export const SocialPulsePostHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const SocialPulsePostIdentity = styled.div`
  display: block;
`;

export const SocialPulsePostLogo = styled.img`
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 45px;
  height: 45px;
  margin: 0 40px 0 0;
`;

export const SocialPulsePostSource = styled.div`
  color: ${theme.black};
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  margin: 0;
`;

export const SocialPulsePostAccount = styled.div`
  color: ${theme.black};
  font-size: 12px;
  line-height: 14px;
`;

export const SocialPulsePostFollow = styled.a`
  background: transparent;
  color: ${props => props.theme.brandPrimary};
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 14px;
  text-align: center;
  border: 1px solid ${props => props.theme.brandPrimary};
  border-radius: 12px;
  outline: none;
  padding: 5px 15px;
  margin: 5px 0px;
  cursor: pointer;

  &:active {
    background-color: ${props => props.theme.brandPrimary};
    color: ${theme.brandWhite};
  }
`;

export const SocialPulsePostText = styled.div`
  color: #808080;
  font-size: 14px;
  line-height: 20px;
`;
