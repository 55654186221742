import { push } from 'connected-react-router';
import { size } from 'lodash-es';
import { FC, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { reset } from 'redux-form';

import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { Table } from 'src/core/components';
import { Button, Message, Panel, PanelSection, PanelSectionGroup } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { createGroup, deleteGroup, updateGroup } from 'src/routes/ducks';
import { Group } from 'src/routes/interfaces/Groups';
import { isRoutePlannerNewLayoutActive } from 'src/routes/services/routePlannerPersistentBetaFlag';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { GroupEditorModal } from '../../modals';
import GroupTableRow from './GroupTableRow';

const GroupsPage: FC = () => {
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);

  const [selectedGroupId, setSelectedGroupId] = useState(0);

  const { isLoading, isSaving, isDeleting, groups } = useSelector(state => state.routes.groups);
  const isSupport = checkIfSupport();
  const isViewOnly = checkIfViewOnly();

  const hasPermissions = !isSupport && !isViewOnly;

  const dispatch = useDispatch();

  const handleGoBack = () => {
    const isRoutePlannerNewLayout = isRoutePlannerNewLayoutActive();
    dispatch(
      push(isRoutePlannerNewLayout ? '/routes/route-planner' : '/routes/route-templates', {
        prevPath: '/routes/service-zones',
      }),
    );
  };

  const handleDeleteGroup = async (groupId: number, event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!(await confirm(translate('routes.alertMessages.confirmDeleteGroup')))) {
      return;
    }
    deleteGroup(groupId)(dispatch)
      .then(() => {
        createSuccessNotification(translate('routes.alertMessages.groupDeleted'));
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.groupDeleteError'));
      });
  };

  const handleEditGroup = (groupId: number) => {
    setSelectedGroupId(groupId);
    setIsGroupModalOpen(true);
  };

  const handleOpenGroupModal = () => {
    setIsGroupModalOpen(true);
  };

  const handleCloseGroupModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    dispatch(reset('groupEditorForm'));
    setIsGroupModalOpen(false);
    setSelectedGroupId(0);
  };

  const handleSaveGroup = (values: Group) => {
    if (values.id)
      updateGroup(values)(dispatch)
        .then(() => {
          createSuccessNotification(translate('routes.alertMessages.groupSaved'));
          setSelectedGroupId(0);
          setIsGroupModalOpen(false);
          dispatch(reset('groupEditorForm'));
        })
        .catch(error => {
          const code = error.response.data && error.response.data.code;
          createErrorNotification(`${translate(createTranslationKey(code, 'routes.alertMessages'))}`);
        });
    else
      createGroup(values)(dispatch)
        .then(() => {
          createSuccessNotification(translate('routes.alertMessages.groupCreated'));
          setSelectedGroupId(0);
          setIsGroupModalOpen(false);
          dispatch(reset('groupEditorForm'));
        })
        .catch(error => {
          const code = error.response.data && error.response.data.code;
          createErrorNotification(`${translate(createTranslationKey(code, 'routes.alertMessages'))}`);
        });
  };

  const cells = [
    {
      name: 'groupName',
      label: translate('routes.groups.groupName'),
      width: '80%',
      sortable: true,
    },
    { name: 'isActive', label: translate('common.active'), width: '10%', align: 'right', sortable: true },
    { name: 'options', label: translate('common.options'), width: '10%', align: 'right' },
  ];

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction onClick={handleGoBack} id="back-button">
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle>{translate('routes.groups.manageGroups')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        {hasPermissions && (
          <PageActions>
            <Button color="primary" id="create-service-zone-button" onClick={handleOpenGroupModal}>
              {translate('routes.groups.createGroup')}
            </Button>
          </PageActions>
        )}
      </PageHeader>
      <Panel>
        <PanelSectionGroup isLoading={isLoading || isSaving || isDeleting}>
          <PanelSection>
            {!size(groups) ? (
              <Message padding="sMedium">{translate('routes.groups.noGroups')}</Message>
            ) : (
              <Table
                cells={cells}
                rows={groups}
                rowComponent={GroupTableRow}
                rowProps={{
                  deleteGroup: handleDeleteGroup,
                  editGroup: handleEditGroup,
                }}
              />
            )}
          </PanelSection>
        </PanelSectionGroup>
      </Panel>
      {isGroupModalOpen && (
        <GroupEditorModal groupId={selectedGroupId} closeModal={handleCloseGroupModal} onGroupSave={handleSaveGroup} />
      )}
    </PageContent>
  );
};

export default GroupsPage;
