import { FC, useCallback, useState } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { CustomerPaymentMethod } from 'src/customers/interfaces/CustomerPaymentMethod';
import { RadioCheckbox } from 'src/core/components';
import { TableRow, TableCell, Text } from '../../../../core/components/styled';
import translate from 'src/core/services/translate';

export const AUTOMATIC_PAYMENTS_FORM = 'automaticPaymentsForm';

interface PropsWithoutReduxForm extends CustomerPaymentMethod {
  defaultAutomaticPayment: { isDefaultAutoPay?: string };
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const AutomaticPaymentsTableRow: FC<Props> = ({
  achBankName,
  achNumber,
  cardExpirationMonth,
  cardExpirationYear,
  cardNumber,
  paymentConnectPaymentMethodId,
  type,
  initialValues,
}) => {
  const [defaultAutoPayment, setDefaultAutoPayment] = useState(initialValues?.isDefaultAutoPay);

  const getDetails1 = useCallback(() => {
    return `****${type === 'card' ? `-${cardNumber}` : achNumber}`;
  }, [achNumber, cardNumber, type]);

  const getDetails2 = useCallback(() => {
    return type === 'card' ? `${cardExpirationMonth}/${cardExpirationYear}` : achBankName;
  }, [achBankName, cardExpirationMonth, cardExpirationYear, type]);

  const onDefaultAutoPaymentChange = (paymentConnectPaymentMethodId: string) => {
    setTimeout(() => {
      setDefaultAutoPayment(paymentConnectPaymentMethodId);
    }, 1);
  };

  const paymentConnectPaymentMethod = paymentConnectPaymentMethodId.toString();

  return (
    <TableRow>
      <TableCell width="30%">
        <Field
          name="isDefaultAutoPay"
          id="isDefaultAutoPay"
          type="radio"
          component={RadioCheckbox}
          value={paymentConnectPaymentMethod}
          checked={paymentConnectPaymentMethod === defaultAutoPayment}
          onChange={() => onDefaultAutoPaymentChange(paymentConnectPaymentMethod)}
          isStyleAsCircle
        />
      </TableCell>
      <TableCell width="30%">
        <Text>{translate(`finance.paymentTypes.${type}`)}</Text>
      </TableCell>
      <TableCell vertical width="40%">
        <Text>{getDetails1()}</Text>
        <Text>{getDetails2()}</Text>
      </TableCell>
    </TableRow>
  );
};

export default reduxForm<{}, any>({
  form: AUTOMATIC_PAYMENTS_FORM,
  enableReinitialize: true,
})(AutomaticPaymentsTableRow);
