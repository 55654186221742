import React from 'react';
import { connect } from 'react-redux';

import { isAdminVendorSelector, isAdminSelector } from '../ducks';
import { AppState } from '../../store';

interface Props {
  isAdminOnly: boolean;
}

const AdminOnlyGuard: React.FC<Props> = ({ isAdminOnly, children }) => {
  if (isAdminOnly && children) {
    return <>{children}</>;
  }

  return null;
};

const mapStateToProps = (state: AppState) => ({
  isAdminOnly: !isAdminVendorSelector(state.account.login) && isAdminSelector(state.account.login),
});

export default connect<Props, never, { children: React.ReactNode }, AppState>(mapStateToProps)(AdminOnlyGuard);
