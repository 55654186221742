import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  authenticateToken as doAuthenticateToken,
  createUnassignedJob as doCreateUnassignedJob,
  getServiceContractByCode as doGetServiceContractByCode,
  loadOpenDispatches as doLoadOpenDispatches,
  loadOpenDispatchesByUserId as doLoadOpenDispatchesByUserId,
  saveServiceContract as doSaveServiceContract,
  updateOpenDispatches as doupdateOpenDispatches,
  updateOpenDispatchesByUserId as doUpdateOpenDispatchesByUserId,
  responseMissedPickupByToken as doResponseMissedPickupByToken,
  responseMissedPickupByUserId as doResponseMissedPickupByUserId,
} from '../services/loadOpenDispatches';
import { transformUpdateOpenDispatches as dotransformUpdateOpenDispatches } from '../services/transformOpenDispatches';

//  Actions
const START_LOAD = 'fleet/openDispatches/START_LOAD';
const COMPLETE_LOAD = 'fleet/openDispatches/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/openDispatches/FAIL_LOAD';
const FAIL_SAVE = 'fleet/openDispatches/SAVE_FAIL';
const START_SAVE = 'fleet/openDispatches/START_SAVE';
const COMPLETE_SAVE = 'fleet/openDispatches/COMPLETE_SAVE';
const IS_TOKEN_LOGIN_REQUIRED = 'fleet/openDispatches/IS_TOKEN_LOGIN_REQUIRED';
const START_GET_SERVICE_CONTRACT = 'routes/routes/START_GET_SERVICE_CONTRACT';
const COMPLETE_GET_SERVICE_CONTRACT = 'routes/routes/COMPLETE_GET_SERVICE_CONTRACT';
const FAIL_GET_SERVICE_CONTRACT = 'routes/routes/FAIL_GET_SERVICE_CONTRACT';
const START_CREATE_UNASSIGNED_JOB = 'routes/routes/START_CREATE_UNASSIGNED_JOB';
const COMPLETE_CREATE_UNASSIGNED_JOB = 'routes/routes/COMPLETE_CREATE_UNASSIGNED_JOB';
const FAIL_CREATE_UNASSIGNED_JOB = 'routes/routes/FAIL_CREATE_UNASSIGNED_JOB';
const SET_NEW_SERVICE_CONTRACT_ID = 'routes/routes/SET_NEW_SERVICE_CONTRACT_ID';
const START_SAVE_SERVICE_CONTRACT = 'routes/routes/START_SAVE_SERVICE_CONTRACT';
const COMPLETE_SAVE_SERVICE_CONTRACT = 'routes/routes/COMPLETE_SAVE_SERVICE_CONTRACT';
const FAIL_SAVE_SERVICE_CONTRACT = 'routes/routes/FAIL_SAVE_SERVICE_CONTRACT';

//  Initial State
const intialState = {
  isLoading: false,
  isSaving: false,
  opportunities: undefined,
  reasonCodes: undefined,
  haulerName: undefined,
  errorMessage: undefined,
  serviceContract: undefined,
  newServiceContractId: undefined,
  isTokenLoginRequired: false,
  total: undefined,
};

//  Reducer
export const reducer = (state = intialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          opportunities: action.openDispatches.opportunities,
          reasonCodes: action.openDispatches.reasonCodes,
          haulerName: action.openDispatches.haulerName,
          errorMessage: action.openDispatches.errorMessage,
          total: action.openDispatches.total,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          opportunities: undefined,
          reasonCodes: undefined,
          haulerName: undefined,
          errorMessage: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          opportunities: action.openDispatches.opportunities,
          reasonCodes: action.openDispatches.reasonCodes,
          haulerName: action.openDispatches.haulerName,
          errorMessage: action.openDispatches.errorMessage,
          total: action.openDispatches.total,
        },
      });

    case START_GET_SERVICE_CONTRACT:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_GET_SERVICE_CONTRACT:
      return update(state, {
        $merge: {
          serviceContract: action.serviceContract,
          isLoading: false,
        },
      });

    case FAIL_GET_SERVICE_CONTRACT:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceContract: undefined,
        },
      });

    case START_CREATE_UNASSIGNED_JOB:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_CREATE_UNASSIGNED_JOB:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case FAIL_CREATE_UNASSIGNED_JOB:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case SET_NEW_SERVICE_CONTRACT_ID:
      return update(state, {
        $merge: {
          newServiceContractId: action.newServiceContractId,
        },
      });

    case IS_TOKEN_LOGIN_REQUIRED:
      return update(state, {
        $merge: {
          isTokenLoginRequired: action.isVPortalLoginRequired,
        },
      });

    default:
      return state;
  }
};

//  Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (openDispatches: any) => ({
  type: COMPLETE_LOAD,
  openDispatches,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (openDispatches: any) => ({
  type: COMPLETE_SAVE,
  openDispatches,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const isTokenLoginRequired = (isVPortalLoginRequired: boolean) => ({
  type: IS_TOKEN_LOGIN_REQUIRED,
  isVPortalLoginRequired,
});

const startGetServiceContract = () => ({
  type: START_GET_SERVICE_CONTRACT,
});

const completeGetServiceContract = (serviceContract: any) => ({
  type: COMPLETE_GET_SERVICE_CONTRACT,
  serviceContract,
});

const failGetServiceContract = () => ({
  type: FAIL_GET_SERVICE_CONTRACT,
});

const startCreateUnassignedJob = () => ({
  type: START_CREATE_UNASSIGNED_JOB,
});

const completeCreateUnassignedJob = () => ({
  type: COMPLETE_CREATE_UNASSIGNED_JOB,
});

const failCreateUnassignedJob = () => ({
  type: FAIL_CREATE_UNASSIGNED_JOB,
});

const startSaveServiceContract = () => ({
  type: START_SAVE_SERVICE_CONTRACT,
});

const completeSaveServiceContract = () => ({
  type: COMPLETE_SAVE_SERVICE_CONTRACT,
});

const failSaveServiceContract = () => ({
  type: FAIL_SAVE_SERVICE_CONTRACT,
});

const setContractId = (newServiceContractId?: number) => ({
  type: SET_NEW_SERVICE_CONTRACT_ID,
  newServiceContractId,
});

export const authenticateToken = (token: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const authenticateTokenPromise = doAuthenticateToken(token);
  authenticateTokenPromise
    .then(response => dispatch(isTokenLoginRequired(response.isVPortalLoginRequired)))
    .catch(() => dispatch(failLoad()));
  return authenticateTokenPromise;
};

export const loadOpenDispatches =
  (
    userId: string | undefined,
    token: string | undefined,
    vendorId: number | undefined,
    startDate: string,
    endDate: string,
    dispatchStatus: any,
    workOrder: any,
    workOrderType?: any,
    page?: number,
    limit?: number,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    if (token) {
      const loadOpenDispatchPromise = doLoadOpenDispatches(
        token,
        startDate,
        endDate,
        dispatchStatus,
        workOrder,
        workOrderType,
        page,
        limit,
      );
      loadOpenDispatchPromise
        .then(openDispatches => dispatch(completeLoad(openDispatches)))
        .catch(() => dispatch(failLoad()));
      return loadOpenDispatchPromise;
    } else if (userId && vendorId) {
      const loadOpenDispatchByUserIdPromise = doLoadOpenDispatchesByUserId(
        userId,
        vendorId,
        startDate,
        endDate,
        dispatchStatus,
        workOrder,
        workOrderType,
        page,
        limit,
      );
      loadOpenDispatchByUserIdPromise
        .then(openDispatches => dispatch(completeLoad(openDispatches)))
        .catch(() => dispatch(failLoad()));
      return loadOpenDispatchByUserIdPromise;
    }
  };

export const updateOpenDispatches =
  (
    rubiconPONbr: any,
    status: any,
    note: any,
    reasonCode: any,
    date: Date | string,
    action: any,
    weightTicket: any,
    uom: any,
    userId: string | undefined,
    token: string | undefined,
    vendorId: number | undefined,
    vendId: string,
    weightTicketUom?: string,
    searchFilter?: any,
    completedDate?: string,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());
    if (token) {
      const updateOpenDispatchesPromise = doupdateOpenDispatches(
        dotransformUpdateOpenDispatches(
          rubiconPONbr,
          status,
          note,
          reasonCode,
          date,
          action,
          weightTicket,
          uom,
          token,
          vendId,
          weightTicketUom,
          searchFilter,
          completedDate,
        ),
      );
      updateOpenDispatchesPromise
        .then(opportunity => dispatch(completeSave(opportunity)))
        .catch(() => dispatch(failSave()));

      return updateOpenDispatchesPromise;
    } else if (userId && vendorId) {
      const updateOpenDispatchesbyUserIdPromise = doUpdateOpenDispatchesByUserId(
        dotransformUpdateOpenDispatches(
          rubiconPONbr,
          status,
          note,
          reasonCode,
          date,
          action,
          weightTicket,
          uom,
          userId,
          vendId,
          weightTicketUom,
          searchFilter,
          completedDate,
        ),
        userId,
        vendorId,
      );
      updateOpenDispatchesbyUserIdPromise
        .then(opportunity => dispatch(completeSave(opportunity)))
        .catch(() => dispatch(failSave()));
      return updateOpenDispatchesbyUserIdPromise;
    }
  };

export const responseMissedPickup =
  (
    itemId: number,
    responseType: string,
    serviceDate: Date | string | undefined,
    reasonCode: any,
    userId: string | undefined,
    token: string | undefined,
    vendorId: number | undefined,
    dayOfWeek?: string[],
    weightTicketSize?: string,
    unitOfMeasureTypeId?: string | number,
    weightTicketUom?: string | number,
    note?: string,
    file?: File,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());
    if (token) {
      const responseMissedPickupPromise = doResponseMissedPickupByToken(
        itemId,
        responseType,
        serviceDate,
        reasonCode,
        token,
        dayOfWeek,
        weightTicketSize ? parseFloat(weightTicketSize) : 0,
        unitOfMeasureTypeId,
        weightTicketUom,
        note,
        file,
      );
      responseMissedPickupPromise
        .then((response: any) => dispatch(completeSave(response)))
        .catch(() => dispatch(failSave()));

      return responseMissedPickupPromise;
    } else if (userId && vendorId) {
      const responseMissedPickupByUserIdPromise = doResponseMissedPickupByUserId(
        itemId,
        responseType,
        serviceDate,
        reasonCode,
        userId,
        vendorId,
        dayOfWeek,
        weightTicketSize ? parseFloat(weightTicketSize) : 0,
        unitOfMeasureTypeId,
        weightTicketUom,
        note,
        file,
      );
      responseMissedPickupByUserIdPromise
        .then((response: any) => dispatch(completeSave(response)))
        .catch(() => dispatch(failSave()));
      return responseMissedPickupByUserIdPromise;
    }
  };

export const getServiceContractByCode = (serviceContractCode?: string) => (dispatch: Dispatch) => {
  dispatch(startGetServiceContract());
  const getServiceContractPromise = doGetServiceContractByCode(serviceContractCode);
  getServiceContractPromise
    .then(data => dispatch(completeGetServiceContract(data)))
    .catch(() => dispatch(failGetServiceContract()));
  return getServiceContractPromise;
};

export const createUnassignedJob =
  (
    vendorId: number,
    serviceContractId?: number,
    routeNote?: any,
    serviceScheduledDate?: Date | string,
    workOrderNumber?: any,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startCreateUnassignedJob());
    const createUnassignedJobPromise = doCreateUnassignedJob(
      vendorId,
      serviceContractId,
      routeNote,
      serviceScheduledDate,
      workOrderNumber,
    );
    createUnassignedJobPromise
      .then(() => dispatch(completeCreateUnassignedJob()))
      .catch(() => dispatch(failCreateUnassignedJob()));
    return createUnassignedJobPromise;
  };

export const saveServiceContract =
  (serviceContractId: number, serviceContractCode?: string) => (dispatch: Dispatch) => {
    dispatch(startSaveServiceContract());
    const saveServiceContractPromise = doSaveServiceContract(serviceContractId, serviceContractCode);
    saveServiceContractPromise
      .then(() => dispatch(completeSaveServiceContract()))
      .catch(() => dispatch(failSaveServiceContract()));
    return saveServiceContractPromise;
  };

export const setNewServiceContractId = (newServiceContractId?: number) => (dispatch: Dispatch) => {
  dispatch(setContractId(newServiceContractId));
};
