import React from 'react';

import { range } from 'lodash-es';

import { withRouter, RouteComponentProps } from 'react-router';
import { getQueryParams, createUrl } from '../../utils/services/queryParams';
import {
  Pagination as PaginationContainer,
  PaginationItem,
  PaginationSpan,
  PaginationControl,
  PanelSection,
} from './styled';
import calculatePaginationBoundaries from '../services/calculatePaginationBoundaries';
import translate from '../services/translate';
import { LIMIT_PER_PAGE } from '../constants';

interface Props extends RouteComponentProps {
  totalResults: number;
  limitPerPage?: number;
  maxPaginationItems?: number;
  displayUnwrapped?: boolean;
}

const Pagination: React.FC<Props> = ({
  totalResults,
  limitPerPage = LIMIT_PER_PAGE,
  maxPaginationItems = 10,
  location,
  displayUnwrapped = false,
}) => {
  if (totalResults <= limitPerPage) return null;

  const url = location.pathname;
  const urlParams = location.search;
  const currentPage = Number(getQueryParams(urlParams).page) || 1;

  const { firstPage, lastPage, totalPages } = calculatePaginationBoundaries(
    totalResults,
    limitPerPage,
    maxPaginationItems,
    currentPage,
  );

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === lastPage;

  const pageOne = 1;

  const paginationControl = (
    <PaginationContainer withoutFirstControl={isFirstPage} withoutLastControl={isLastPage}>
      {isFirstPage ? (
        <PaginationItem key={pageOne} exact to={createUrl(url, urlParams, { page: pageOne })} isActive={() => true}>
          {pageOne}
        </PaginationItem>
      ) : (
        <>
          <PaginationControl exact to={createUrl(url, urlParams, { page: currentPage - 1 })}>
            {translate('common.previous')}
          </PaginationControl>

          <PaginationItem key={pageOne} exact to={createUrl(url, urlParams, { page: pageOne })} isActive={() => false}>
            {pageOne}
          </PaginationItem>
          {currentPage - 1 > maxPaginationItems / 2 && <PaginationSpan>...</PaginationSpan>}
        </>
      )}

      {range(firstPage + 1, lastPage + 1).map(page => (
        <PaginationItem key={page} exact to={createUrl(url, urlParams, { page })} isActive={() => currentPage === page}>
          {page}
        </PaginationItem>
      ))}

      {isLastPage ? null : (
        <>
          {currentPage + maxPaginationItems / 2 <= totalPages && (
            <>
              {currentPage + maxPaginationItems / 2 < totalPages && <PaginationSpan>...</PaginationSpan>}
              <PaginationItem
                key={totalPages}
                exact
                to={createUrl(url, urlParams, { page: totalPages })}
                isActive={() => currentPage === totalPages}
              >
                {totalPages}
              </PaginationItem>
            </>
          )}

          <PaginationControl exact to={createUrl(url, urlParams, { page: currentPage + 1 })}>
            {translate('common.next')}
          </PaginationControl>
        </>
      )}
    </PaginationContainer>
  );

  return displayUnwrapped ? paginationControl : <PanelSection centered>{paginationControl}</PanelSection>;
};

export default withRouter(Pagination);
