import React from 'react';

import { InjectedFormProps, reduxForm } from 'redux-form';
import { get, find } from 'lodash-es';
import { useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { TypedField } from '../../../core/components';
import { Vehicles } from '../../interfaces/ZDeviceConfiguration';
import { VehicleTypesForExceptionConfigurationDropdown } from '..';

interface PropsWithoutReduxForm {
  vehicles?: Vehicles[];
  onChangeVehicleTypeId(id: number): void;
  loadModelConfigurations?(
    vendorId: number,
    vehicleTypeId: number,
    vehicleType?: string,
    includeSubtypes?: boolean,
  ): Promise<any> | void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const VehicleTypeForm: React.FC<Props> = ({ loadModelConfigurations, onChangeVehicleTypeId, vehicles = [] }) => {
  const vendorId = useSelector(currentVendorId);

  const onVehicleTypeIdChange = React.useCallback(
    async (_, vehicleTypeId: number) => {
      const vehicleType = get(find(vehicles, { id: vehicleTypeId }), 'technicalName');

      if (!vendorId) {
        return;
      }

      await (loadModelConfigurations && loadModelConfigurations(vendorId, vehicleTypeId, vehicleType, true));
      vehicleTypeId && onChangeVehicleTypeId(vehicleTypeId);
    },
    [vendorId, vehicles, onChangeVehicleTypeId, loadModelConfigurations],
  );

  return (
    <form>
      <PanelSection padding="small xSmall">
        <Grid centered>
          <GridColumn size="4/12">
            <TypedField
              name="vehicleTypeId"
              component={VehicleTypesForExceptionConfigurationDropdown}
              onChange={onVehicleTypeIdChange}
              props={{
                withLabel: true,
                vehicleTypes: vehicles,
                dropdownProps: {
                  margin: 'no',
                },
              }}
            />
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  form: 'vehicleTypeForm',
  enableReinitialize: true,
})(VehicleTypeForm);
