import { push } from 'connected-react-router';
import { FC, MouseEvent, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { MoreButtonPopupButton } from 'src/core/components/styled/MoreButton';
import { BOTTOM } from 'src/core/constants';
import createPopover from 'src/core/services/createPopover';
import translate from 'src/core/services/translate';

interface Props {
  isSnowPlowFeatureEnabled?: boolean;
  isStreetSweeperFeatureEnabled?: boolean;
}

const PlanARouteExtended: FC<Props> = ({ isSnowPlowFeatureEnabled, isStreetSweeperFeatureEnabled }) => {
  const closePopover = useRef<() => void>();
  const dispatch = useDispatch();

  const createSubMenu = useCallback(
    (e: MouseEvent, isTemplate?: boolean) => {
      if (closePopover.current) {
        closePopover.current();
      }

      closePopover.current = createPopover(
        e.target as HTMLElement,
        () => (
          <Box display="flex" flexDirection="column">
            {isSnowPlowFeatureEnabled && (
              <MoreButtonPopupButton
                justifyContent="center"
                onClick={() =>
                  dispatch(
                    push(isTemplate ? '/routes/route-templates/snow-plow/create' : '/routes/snow-tracker/create'),
                  )
                }
                color="primary"
                id="plan-snow-route-button"
              >
                {translate('dashboard.snowPlow')}
              </MoreButtonPopupButton>
            )}
            {isStreetSweeperFeatureEnabled && (
              <MoreButtonPopupButton
                justifyContent="center"
                onClick={() =>
                  dispatch(
                    push(
                      isTemplate ? '/routes/route-templates/street-sweeper/create' : '/routes/sweeper-tracker/create',
                    ),
                  )
                }
                color="primary"
                id="plan-sweeper-route-button"
              >
                {translate('dashboard.streetSweeper')}
              </MoreButtonPopupButton>
            )}
          </Box>
        ),
        {},
        {
          hasCloseButton: true,
          position: BOTTOM,
          size: 'xSmall',
        },
      );
    },
    [dispatch, isSnowPlowFeatureEnabled, isStreetSweeperFeatureEnabled],
  );

  return (
    <Box display="flex" flexDirection="column" width={160}>
      <Button
        onClick={(e: MouseEvent) => createSubMenu(e, false)}
        color="primary"
        id="plan-daily-route-button"
        size="small"
      >
        {translate('routes.dailyRoute')}
      </Button>
      <Button
        onClick={(e: MouseEvent) => createSubMenu(e, true)}
        color="primary"
        id="plan-template-route-button"
        margin="small no no"
        size="small"
      >
        {translate('routes.routeTemplate')}
      </Button>
    </Box>
  );
};

export default PlanARouteExtended;
