import { PureComponent } from 'react';

import { connect } from 'react-redux';
import { Field, WrappedFieldInputProps } from 'redux-form';
import { filter } from 'lodash-es';

import { AppState } from '../../store';
import { Dropdown } from '../../core/components';
import { FILTER_VEHICLE_TYPE_ID } from '../../common/constants';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { totalVehicleCountSelector } from '../ducks';
import translate from '../../core/services/translate';

interface ComponentProps {}

interface Props extends ComponentProps {
  dropdownProps?: any;
  filtersPreferences: FilterSetting[];
  input: WrappedFieldInputProps;
  runningVehicleCounts?: any[];
}

class RunningVehicleCountDropdown extends PureComponent<Props> {
  onChange = (event: any, value: any) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      dropdownProps,
      filtersPreferences,
      runningVehicleCounts = [],
    } = this.props;

    const vehicleFiltersPreferences = filter(
      filtersPreferences,
      item => item.userGlobalFilterTypeId === FILTER_VEHICLE_TYPE_ID,
    );
    const vehicleFiltersPreferencesLocked = filter(
      vehicleFiltersPreferences,
      (filter: FilterSetting) => filter.isLockedSection,
    );

    const filteredVehicleFiltersPreferences = vehicleFiltersPreferencesLocked.length
      ? runningVehicleCounts.filter(vehicle =>
          vehicleFiltersPreferencesLocked.some(vehicleLocked => vehicle.vehicleTypeId === vehicleLocked.filterValueId),
        )
      : runningVehicleCounts;

    const runningVehicleCountOptions = filteredVehicleFiltersPreferences.map(vehicleType => ({
      label: `${vehicleType.name} (${vehicleType.count})`,
      value: vehicleType.vehicleTypeId,
      count: vehicleType.count,
    }));

    const totalVehicleCount = totalVehicleCountSelector(runningVehicleCountOptions);

    const allOptions = [
      {
        label: `${translate('vehicles.allVehicles')} (${totalVehicleCount})`,
        value: '',
      },
      ...runningVehicleCountOptions,
    ];

    return <Field name={name} component={Dropdown} options={allOptions} onChange={this.onChange} {...dropdownProps} />;
  }
}

const mapStateToProps = (state: AppState) => ({
  filtersPreferences: state.common.filters.filters,
  runningVehicleCounts: state.insights.runningVehicleCounts.runningVehicleCounts,
});

export default connect(mapStateToProps)(RunningVehicleCountDropdown);
