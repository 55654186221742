import React, { Fragment, PureComponent } from 'react';

import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Cookie from 'js-cookie';

import { AppState } from '../../../store';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { INSIGHTS_COOKIE_DATE_KEY } from '../../constants';
import { loadFeatures } from '../../../vendors/ducks';
import { loadFiltersSettings } from 'src/common/ducks/filters';
import { loadInsightDashboardPreferences, loadRunningVehicleCounts } from '../../ducks';
import { loadPermissions, shouldLoadPermissionsSelector } from '../../../account/ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { TODAY_FORMATTED } from '../../../core/constants';
import InsightsPage from './InsightsPage';
import translate from '../../../core/services/translate';
import moment from 'moment';

interface ComponentProps extends RouteComponentProps {
  insightGroupIds: number[];
}

interface Props extends ComponentProps {
  loadFeatures: DuckFunction<typeof loadFeatures>;
  loadFiltersSettings: DuckFunction<typeof loadFiltersSettings>;
  loadInsightDashboardPreferences: DuckFunction<typeof loadInsightDashboardPreferences>;
  loadPermissions: DuckFunction<typeof loadPermissions>;
  loadRunningVehicleCounts: DuckFunction<typeof loadRunningVehicleCounts>;
  shouldLoadPermissions: boolean;
  vendorId: number;
  userId: string;
}

class InsightsPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      insightGroupIds,
      loadFeatures,
      loadFiltersSettings,
      loadInsightDashboardPreferences,
      loadPermissions,
      loadRunningVehicleCounts,
      location: { search },
      shouldLoadPermissions,
      userId,
      vendorId,
    } = this.props;

    const insightsDate = Cookie.get(INSIGHTS_COOKIE_DATE_KEY);
    const { date = moment(insightsDate).format('MM/DD/YYYY') || TODAY_FORMATTED } = getQueryParams(search);

    const dependenciesWithoutPermissions: any[] = [
      loadFeatures(vendorId),
      loadRunningVehicleCounts(vendorId, date, insightGroupIds),
      loadInsightDashboardPreferences(vendorId, insightGroupIds),
      loadFiltersSettings(vendorId, userId),
    ];

    return Promise.all(
      shouldLoadPermissions ? [loadPermissions(), ...dependenciesWithoutPermissions] : dependenciesWithoutPermissions,
    );
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('insights.insights')}</DocumentTitle>
        <Resolver
          successComponent={InsightsPage}
          successProps={{
            insightGroupIds: this.props.insightGroupIds,
          }}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  shouldLoadPermissions: (shouldLoadPermissionsSelector as any)(state.account.login, state.account.permissions),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  userId: currentUserIdSelector(state.account.login) as any,
});

const mapDispatchToProps = {
  loadFeatures,
  loadFiltersSettings,
  loadInsightDashboardPreferences,
  loadPermissions,
  loadRunningVehicleCounts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InsightsPageResolver));
