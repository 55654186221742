import React, { useEffect } from 'react';
import { Layer, Source } from 'react-map-gl';

import { DISPATCH_BOARD_SOURCE } from 'src/routes/constants/dispatchBoard';
import { handleDispatchBoardJobsFeatureState } from './utils';

interface Props<P extends object = {}> {
  map: mapboxgl.Map;
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Point, P>;
  selectedJobIds: number[];
}

const DispatchBoardMapGLSource = React.memo<Props>(({ map, geoJSON, selectedJobIds }) => {
  useEffect(() => {
    handleDispatchBoardJobsFeatureState(map);
    map.once('styledata', () => handleDispatchBoardJobsFeatureState(map));
  }, [map, geoJSON]);

  return (
    <Source type="geojson" id={DISPATCH_BOARD_SOURCE} data={geoJSON} tolerance={0.0001}>
      <Layer
        id="allJobsPoints"
        source={DISPATCH_BOARD_SOURCE}
        type="circle"
        filter={['!', ['in', ['get', 'id'], ['literal', selectedJobIds]]]}
        paint={{
          'circle-color': ['feature-state', 'color'],
          'circle-radius': 12,
        }}
      />

      <Layer
        id="selectedJobs"
        source={DISPATCH_BOARD_SOURCE}
        type="circle"
        filter={['in', ['get', 'id'], ['literal', selectedJobIds]]}
        paint={{
          'circle-color': '#009688',
          'circle-radius': 12,
        }}
      />

      <Layer
        id="allJobsStopNumbers"
        source={DISPATCH_BOARD_SOURCE}
        type="symbol"
        filter={['has', 'stopNumber']}
        paint={{
          'text-halo-color': '#fff',
          'text-halo-width': 2,
        }}
        layout={{
          'text-field': '{stopNumber}',
          'text-size': 14,
        }}
      />
    </Source>
  );
});

export default DispatchBoardMapGLSource;
