import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  deleteContact as doDeleteContact,
  deleteContactByToken as doDeleteContactByToken,
  loadContacts as doLoadContacts,
  loadContactsByToken as doLoadContactsByToken,
  saveContact as doSaveContact,
  saveContactByToken as doSaveContactByToken,
  updateContact as doUpdateContact,
  updateContactByToken as doUpdateContactByToken,
} from '../services/contacts';

// Actions
const START_LOAD = 'fleet/contacts/START_LOAD';
const COMPLETE_LOAD = 'fleet/contacts/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/contacts/FAIL_LOAD';
const START_SAVE = 'fleet/contacts/START_SAVE';
const COMPLETE_SAVE = 'fleet/contacts/COMPLETE_SAVE';
const FAIL_SAVE = 'fleet/contacts/FAIL_SAVE';
const START_UPDATE = 'fleet/contacts/START_UPDATE';
const COMPLETE_UPDATE = 'fleet/contacts/COMPLETE_UPDATE';
const FAIL_UPDATE = 'fleet/contacts/FAIL_UPDATE';
const START_DELETE = 'fleet/contacts/START_DELETE';
const COMPLETE_DELETE = 'fleet/contacts/COMPLETE_DELETE';
const FAIL_DELETE = 'fleet/contacts/FAIL_DELETE';
const RESET = 'fleet/contacts/RESET';

// Initial state
const initialState = {
  isDeleting: false,
  isLoading: false,
  isSaving: false,
  isUpdating: false,
  contacts: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          contacts: action.contacts.contacts,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          contacts: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_UPDATE:
      return update(state, {
        $merge: {
          isUpdating: true,
        },
      });

    case COMPLETE_UPDATE:
      return update(state, {
        $merge: {
          isUpdating: false,
        },
      });

    case FAIL_UPDATE:
      return update(state, {
        $merge: {
          isUpdating: false,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE:
      return update(state, {
        $merge: { isDeleting: false },
      });

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (contacts: any) => ({
  type: COMPLETE_LOAD,
  contacts,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startUpdate = () => ({ type: START_UPDATE });

const completeUpdate = () => ({ type: COMPLETE_UPDATE });

const failUpdate = () => ({ type: FAIL_UPDATE });

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = () => ({
  type: COMPLETE_DELETE,
});

const failDelete = () => ({ type: FAIL_DELETE });

export const loadContacts = (token: string, haulerId: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadContactsPromise = token ? doLoadContactsByToken(token) : doLoadContacts(haulerId);
  loadContactsPromise.then(contacts => dispatch(completeLoad(contacts))).catch(() => dispatch(failLoad()));
  return loadContactsPromise;
};

export const saveContact = (token: string, haulerId: string, data: any) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveContactPromise = token ? doSaveContactByToken(token, data) : doSaveContact(haulerId, data);
  saveContactPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return saveContactPromise;
};

export const updateContact = (token: string, haulerId: string, data: any) => (dispatch: Dispatch) => {
  dispatch(startUpdate());
  const updateContactPromise = token ? doUpdateContactByToken(token, data) : doUpdateContact(haulerId, data);
  updateContactPromise.then(() => dispatch(completeUpdate())).catch(() => dispatch(failUpdate()));
  return updateContactPromise;
};

export const deleteContact = (token: string, haulerId: string, contactId: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteContactPromise = token ? doDeleteContactByToken(token, contactId) : doDeleteContact(haulerId, contactId);
  deleteContactPromise.then(() => dispatch(completeDelete())).catch(() => dispatch(failDelete()));
  return deleteContactPromise;
};

export const resetContacts = () => ({
  type: RESET,
});
