import { camelCase, includes, map } from 'lodash-es';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, submit } from 'redux-form';
import { useState } from 'react';

import { AppState } from '../../../store';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  ModalFixedFooter,
  PanelSection,
  Text,
} from '../../../core/components/styled';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { DaysOfWeekPicker, Dropdown, FileInput, Input, TextArea, TypedField } from '../../../core/components';
import RubiconServicesRates from './RubiconServicesRates';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {
  onClose: (formPristine?: boolean) => void;
  onSubmit: (formValues: any) => any;
}

interface FormValues {}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const isValidNumberOfDays = (value: string, formValues: any) => {
  const selectedFrequency = parseInt(formValues?.frequencyType);
  if (formValues?.frequencyType) {
    const selectedDays = value?.length;
    const frequnecyId = !!selectedFrequency ? selectedFrequency : 1;
    if (selectedDays !== frequnecyId && formValues?.frequencyType !== 'OC') {
      return `Select ${frequnecyId} days.`;
    }
  }
  return undefined;
};

export const getFrequencyTranslated = (frequency?: string) => {
  const days = 'Days';
  const weeks = 'Weeks';
  const months = 'Months';

  const frequencyLabel = includes(frequency, days)
    ? days
    : includes(frequency, weeks)
    ? weeks
    : includes(frequency, months)
    ? months
    : undefined;

  const frequencyTranslated = frequencyLabel
    ? translate(`opportunity.serviceFrequency.x${frequencyLabel}`, {
        count: frequency?.split(`${frequencyLabel}`)[0],
      })
    : translate(`opportunity.serviceFrequency.${camelCase(frequency)}`);

  return frequencyTranslated;
};

const EditWorkOrderForm: React.FC<Props> = ({ onClose, pristine }) => {
  const dispatch = useDispatch();
  const rubiconServiceOptions = useSelector((state: AppState) => state.customers.rubiconTypes.rubiconServicesOptions);

  const [equipmentSizeByEquipmentType, setEquipmentSizeByType] = useState<any[]>([]);
  const [isDayPickerDisabled, setDisableDayPicker] = useState<boolean>(true);

  const equipmentOptions = rubiconServiceOptions.equipments ? rubiconServiceOptions.equipments : [];
  const equipmentSizeOptions = rubiconServiceOptions.equipmentSize ? rubiconServiceOptions.equipmentSize : [];
  const materialOptions = rubiconServiceOptions.materials ? rubiconServiceOptions.materials : [];
  const frequencyOptions = rubiconServiceOptions.frequency ? rubiconServiceOptions.frequency : [];

  const frequencyOptionsFormatted = frequencyOptions.map(frequency => {
    return {
      label: getFrequencyTranslated(frequency.label),
      value: frequency.value,
    };
  });

  const onEquipmentTypeChange = (event: any, value: string) => {
    const filterEquipmentsByEquipmentType = equipmentSizeOptions.filter(
      (equipmentSize: any) => equipmentSize.type === value,
    );

    const equipmentSizeList = map(filterEquipmentsByEquipmentType, ({ description }) => ({
      label: description,
      value: description,
    }));

    setEquipmentSizeByType(equipmentSizeList);
  };

  const onFrequencyChange = (event: any, value: string) => {
    const isDayPickerDisabled = value === 'OC' || false;
    setDisableDayPicker(isDayPickerDisabled);
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(submit('EditWorkOrderForm'));
      }}
    >
      <PanelSection padding="small xSmall no">
        <Grid multiLine>
          <Grid>
            <GridColumn size="4/12">
              <TypedField
                name="equipmentType"
                component={Dropdown}
                onChange={onEquipmentTypeChange}
                props={{
                  options: equipmentOptions,
                  label: translate('common.equipmentType'),
                  isClearable: true,
                }}
              />
            </GridColumn>
            <GridColumn size="4/12">
              <TypedField
                name="equipmentSize"
                component={Dropdown}
                props={{
                  options: equipmentSizeByEquipmentType,
                  label: translate('common.equipmentSize'),
                  isClearable: true,
                }}
              />
            </GridColumn>
            <GridColumn size="4/12">
              <TypedField
                name="materialType"
                component={Dropdown}
                props={{
                  options: materialOptions,
                  label: translate('routes.materialType'),
                  isClearable: true,
                }}
              />
            </GridColumn>
          </Grid>

          <Grid>
            <GridColumn size="4/12">
              <TypedField
                name="container"
                component={Input}
                props={{
                  label: translate('containers.container'),
                }}
              />
            </GridColumn>
            <GridColumn size="4/12">
              <TypedField
                name="frequencyType"
                component={Dropdown}
                onChange={onFrequencyChange}
                props={{
                  options: frequencyOptionsFormatted,
                  label: translate('customers.frequency'),
                  isClearable: true,
                }}
              />
            </GridColumn>
            <GridColumn size="4/12">
              <Text block weight="light" size="sMedium">
                {translate('customers.rubiconService.dayOfWeek')}
              </Text>
              <Field
                name="daysOfWeek"
                component={DaysOfWeekPicker}
                validate={[isValidNumberOfDays]}
                multiple
                daysOfWeekProps={{ margin: 'small no no' }}
                dayOfWeekProps={{ margin: 'no xSmall no no' }}
                isReadOnly={isDayPickerDisabled}
              />
            </GridColumn>
          </Grid>
          <RubiconServicesRates></RubiconServicesRates>
          <Grid>
            <GridColumn size="6/12">
              <Field name="notes" component={TextArea} rows="2" label={translate('opportunity.notes')} />
            </GridColumn>
            <GridColumn size="6/12">
              <Field name="files" margin="medium medium medium small" component={FileInput} type="text" />
            </GridColumn>
          </Grid>
        </Grid>
      </PanelSection>
      <ModalFixedFooter flex-direction="column" align="center" padding="xLarge small" justifyContent="space-around">
        <ButtonSet align="center" margin="large small">
          <Button type="submit" color="primary" disabled={pristine}>
            {translate('common.save')}
          </Button>

          <Button type="button" margin="no small" color="secondary" onClick={() => onClose(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </ModalFixedFooter>
    </form>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    initialValues: {
      rubiconServicesRates: [
        {
          rateAmount: '',
          rateDescription: null,
          uom: null,
        },
      ],
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm<FormValues, PropsWithoutReduxForm>({
    form: 'EditWorkOrderForm',
    onSubmitFail: focusFirstInvalidField,
  })(EditWorkOrderForm),
);
