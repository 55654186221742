import React from 'react';

import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { currentVendorId } from '../../services/currentVendorSelector';
import { isAdminSelector } from '../../../account/ducks';
import { loadUsers } from '../../ducks';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import ChangePasswordModal from './ChangePasswordModal';

interface Props extends ModalProps {
  isAdmin: boolean;
  onSubmit(formData: any): void;
}

const ChangePasswordModalResolver: React.FC<Props> = ({ isAdmin, closeModal, onSubmit }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    return Promise.all(vendorId && isAdmin ? [loadUsers(vendorId)(dispatch)] : []);
  }, [vendorId, dispatch, isAdmin]);

  return (
    <Resolver
      successComponent={ChangePasswordModal}
      successProps={{ closeModal, onSubmit }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  isAdmin: isAdminSelector(state.account.login),
});

export default connect(mapStateToProps)(ChangePasswordModalResolver);
