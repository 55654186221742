import { http } from '../../core/services/http';

const loadServiceAccountStatus = (serviceContractId: number, date: Date | string) =>
  http
    .get<number>(`services/${serviceContractId}/accountStatus`, {
      params: { date },
    })
    .then(response => response.data);

export default loadServiceAccountStatus;
