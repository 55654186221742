import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { RoutePlannerTemplatesPayload } from 'src/routes/interfaces/routePlanner/RoutePlannerEndpointsPayload';
import { RoutePlannerRouteTemplate } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplate';
import {
  loadRoutePlannerRouteTemplates as doLoadRoutePlannerRouteTemplates,
  assignDriverToRouteTemplate as doAssignDriver,
  assignVehicleToRouteTemplate as doAssignVehicle,
  removeDriverFromRouteTemplate as doRemoveDriver,
  removeVehicleFromRouteTemplate as doRemoveVehicle,
  bulkDeleteRouteTemplates as doBulkDelete,
} from 'src/routes/services/routePlanner';

const START_LOAD = 'routePlannerRouteTemplates/START_LOAD';
const COMPLETE_LOAD = 'routePlannerRouteTemplates/COMPLETE_LOAD';
const FAIL_LOAD = 'routePlannerRouteTemplates/FAIL_LOAD';
const START_SAVE = 'routePlannerRouteTemplates/START_SAVE';
const COMPLETE_SAVE = 'routePlannerRouteTemplates/COMPLETE_SAVE';
const FAIL_SAVE = 'routePlannerRouteTemplates/FAIL_SAVE';
const START_BULK_DELETE = 'routePlannerRouteTemplates/START_BULK_DELETE';
const COMPLETE_BULK_DELETE = 'routePlannerRouteTemplates/COMPLETE_BULK_DELETE';
const FAIL_BULK_DELETE = 'routePlannerRouteTemplates/FAIL_BULK_DELETE';
const RESET = 'routePlannerRouteTemplates/RESET';

interface State {
  isLoading: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  routeTemplates: RoutePlannerRouteTemplate[];
}

const initialState: State = {
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  routeTemplates: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeTemplates: action.routeTemplates,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeTemplates: [],
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_BULK_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_BULK_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case FAIL_BULK_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeTemplates: RoutePlannerRouteTemplate[]) => ({
  type: COMPLETE_LOAD,
  routeTemplates,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startBulkDelete = () => ({
  type: START_BULK_DELETE,
});

const completeBulkDelete = () => ({
  type: COMPLETE_BULK_DELETE,
});

const failBulkDelete = () => ({
  type: FAIL_BULK_DELETE,
});

export const resetRoutePlannerRouteTemplates = () => ({
  type: RESET,
});

export const loadRoutePlannerRouteTemplates = (params: RoutePlannerTemplatesPayload) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRoutePlannerRouteTemplatesPromise = doLoadRoutePlannerRouteTemplates(params);
  loadRoutePlannerRouteTemplatesPromise
    .then((routeTemplates: RoutePlannerRouteTemplate[]) => {
      dispatch(completeLoad(routeTemplates));
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return loadRoutePlannerRouteTemplatesPromise;
};

export const assignDriverToRouteTemplate =
  (vendorId: number, routeTemplateId: number, driverId: number) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const assignDriverPromise = doAssignDriver(vendorId, routeTemplateId, driverId);
    assignDriverPromise
      .then(() => {
        dispatch(completeSave());
      })
      .catch(() => {
        dispatch(failSave());
      });

    return assignDriverPromise;
  };

export const removeDriverFromRouteTemplate =
  (vendorId: number, routeTemplateId: number, driverId: number) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const removeDriverPromise = doRemoveDriver(vendorId, routeTemplateId, driverId);
    removeDriverPromise
      .then(() => {
        dispatch(completeSave());
      })
      .catch(() => {
        dispatch(failSave());
      });

    return removeDriverPromise;
  };

export const assignVehicleToRouteTemplate =
  (vendorId: number, routeTemplateId: number, vehicleId: number) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const assignVehiclePromise = doAssignVehicle(vendorId, routeTemplateId, vehicleId);
    assignVehiclePromise
      .then(() => {
        dispatch(completeSave());
      })
      .catch(() => {
        dispatch(failSave());
      });

    return assignVehiclePromise;
  };

export const removeVehicleFromRouteTemplate =
  (vendorId: number, routeTemplateId: number, vehicleId: number) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const removeVehiclePromise = doRemoveVehicle(vendorId, routeTemplateId, vehicleId);
    removeVehiclePromise
      .then(() => {
        dispatch(completeSave());
      })
      .catch(() => {
        dispatch(failSave());
      });

    return removeVehiclePromise;
  };

export const bulkDeleteRouteTemplates = (vendorId: number, routeTemplateIds: number[]) => (dispatch: Dispatch) => {
  dispatch(startBulkDelete());
  const bulkDeletePromise = doBulkDelete(vendorId, routeTemplateIds);
  bulkDeletePromise
    .then(() => {
      dispatch(completeBulkDelete());
    })
    .catch(() => {
      dispatch(failBulkDelete());
    });

  return bulkDeletePromise;
};
