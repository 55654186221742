import { getFeatureCollection, getLineStringFeature } from 'src/common/components/map/util';
import { POLYLINE_COLORS } from 'src/common/constants';
import { ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE } from '../constants';
import { RouteMapVehicleCoordinateGroup, RouteMapVehicleTracking } from 'src/routes/interfaces/RouteMapVehicleData';

export type VehicleTrackingFeatureProperties = {
  id: number;
  clickable: boolean;
  vehicleId: number;
  coordinateGroupIndex: number;
};
export const getTrackingCoordinateGroupId = (tracking: RouteMapVehicleTracking, coordinateGroupIndex: number) =>
  Number(`${tracking.vehicle.id}.${coordinateGroupIndex}`);

export const getVehicleTrackingsGeoJSON = (vehicleTrackings: RouteMapVehicleTracking[]) =>
  getFeatureCollection<GeoJSON.LineString, VehicleTrackingFeatureProperties>(
    vehicleTrackings
      .map((vehicleTracking, index) => {
        let color = index + 1;
        if (color > POLYLINE_COLORS.length - 1) color = 1;

        return vehicleTracking.coordinateGroups?.map((coordinateGroup, coordinateGroupIndex) =>
          getLineStringFeature(
            getTrackingCoordinateGroupId(vehicleTracking, coordinateGroupIndex),
            coordinateGroup.coordinates.map(({ latitude, longitude }) => [longitude, latitude]),
            {
              id: getTrackingCoordinateGroupId(vehicleTracking, coordinateGroupIndex),
              clickable: true,
              vehicleId: vehicleTracking.vehicle.id,
              coordinateGroupIndex,
              color: vehicleTracking.vehicle.isAssisting ? POLYLINE_COLORS[color] : POLYLINE_COLORS[0],
            },
          ),
        );
      })
      .reduce((acc, current) => acc.concat(current), []),
  );

export const handleRouteMapVehicleTrackingsFeatureState = (
  map: mapboxgl.Map,
  vehicleTrackings: RouteMapVehicleTracking[],
) => {
  if (!map.getSource(ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE)) {
    return;
  }

  vehicleTrackings.forEach((vehicleTracking, vehicleTrackingIndex) => {
    vehicleTracking.coordinateGroups?.forEach((_, coordinateGroupIndex) => {
      map.setFeatureState(
        {
          id: getTrackingCoordinateGroupId(vehicleTracking, coordinateGroupIndex),
          source: ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE,
        },
        {
          color: POLYLINE_COLORS[0],
        },
      );
    });
  });
};

export const getVehicleTrackingCoordinateGroupCenter = (coordinateGroup: RouteMapVehicleCoordinateGroup) =>
  coordinateGroup.coordinates[Math.floor(coordinateGroup.coordinates.length / 2)];
