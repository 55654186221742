import { push } from 'connected-react-router';
import Cookie from 'js-cookie';
import { filter, find, map, orderBy, size } from 'lodash-es';
import moment from 'moment';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { SESSION_COOKIE_KEY } from 'src/account/services/session';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import { Button, PanelSection, PanelSectionGroup, Text } from 'src/core/components/styled';
import { TOP } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import createPopover from 'src/core/services/createPopover';
import translate from 'src/core/services/translate';
import { SNOW_PLOW_ID } from 'src/fleet/constants';
import { SEQUENCE_SOURCE_TYPE_DAILY_ROUTE } from 'src/routes/constants';
import { deleteRouteTracker, loadRouteSequenceStatus } from 'src/routes/ducks';
import {
  assignDriverToRoute,
  assignVehicleToRoute,
  loadRoutePlannerRouteDrivers,
  loadRoutePlannerRouteVehicles,
  loadRoutePlannerRoutes,
  removeDriverFromRoute,
  removeVehicleFromRoute,
  updateRoutePlannerDriversStatus,
  updateRoutePlannerVehiclesStatus,
} from 'src/routes/ducks/routePlanner';
import { RoutePlannerRoute } from 'src/routes/interfaces/routePlanner/RoutePlannerRoute';
import { RoutePlannerRouteDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteDriver';
import { RoutePlannerRouteVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteVehicle';
import { getRoutePlannerFilters } from 'src/routes/services/routePlannerFilters';
import { getRoutePlannerOptions } from 'src/routes/services/routePlannerVehicleTypesFormInitialValuesSelector';
import { getQueryParams } from 'src/utils/services/queryParams';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { UpdateStatusFormValues } from '../../forms/routePlanner/DriverOrVehicleStatusSingleForm';
import DriverOrVehicleStatusSingleFormResolver from '../../forms/routePlanner/DriverOrVehicleStatusSingleFormResolver';
import DailyAndReoccurringFiltersSectionResolver from '../../modals/routePlanner/DailyAndReoccurringFilterByModalResolver';
import { STATUS_EDITOR_TABS } from '../../modals/routePlanner/DriversAndVehiclesStatusEditModal';
import DriversAndVehiclesStatusEditModalResolver from '../../modals/routePlanner/DriversAndVehiclesStatusEditModalResolver';
import { NoDataMessageContainer } from '../../styled';
import AssigningDriversAndVehiclesSection from './RoutePlannerDailyAndReoccurringPageSections/AssigningDriversAndVehiclesSection';
import DailyAndReoccurringStatisticsSection from './RoutePlannerDailyAndReoccurringPageSections/DailyAndReoccurringStatisticsSection';
import RoutePlannerRouteListingSection from './RoutePlannerDailyAndReoccurringPageSections/RoutePlannerRouteListingSection';

const RoutePlannerDailyRoutesPage: React.FC = () => {
  const { search, pathname } = useLocation();

  const vendorId = useSelector(currentVendorId);

  const dispatch = useDispatch();

  const [isAvailabilityEditModalOpen, setIsAvailabilityEditModalOpen] = useState(false);
  const [availabilityEditActiveTab, setAvailabilityEditActiveTab] = useState(STATUS_EDITOR_TABS.DRIVERS_STATUS);
  const [changedDrivers, setChangedDrivers] = useState<RoutePlannerRouteDriver[]>([]);
  const [changedVehicles, setChangedVehicles] = useState<RoutePlannerRouteVehicle[]>([]);
  const [changedRoutes, setChangedRoutes] = useState<RoutePlannerRoute[]>([]);
  const [deletedRoutes, setDeletedRoutes] = useState<number[]>([]);
  const [routeIdsUpdating, setRouteIdsUpdating] = useState<number[]>([]);

  // used to keep in sync filtered routes with the ones in the table
  const [searchTerm, setSearchTerm] = useState('');
  const [noDriver, setNoDriver] = useState(false);
  const [noVehicle, setNoVehicle] = useState(false);
  const [unavailableVehiclesOrDrivers, setUnavailableVehiclesOrDrivers] = useState(false);

  const vehicleTypes = useSelector(state => state.fleet.vehicleTypesForVendor.vehicleTypesForVendor);
  const filterPreferences = useSelector(state => state.common.filters.filters);
  const supervisorEnabled = useSelector(supervisorExperienceFeatureIsEnabled);
  const { drivers, isLoading: isLoadingDrivers } = useSelector(
    state => state.routes.routePlanner.routePlannerRouteDrivers,
  );
  const { vehicles, isLoading: isLoadingVehicles } = useSelector(
    state => state.routes.routePlanner.routePlannerRouteVehicles,
  );
  const { routes, isLoading: isLoadingRoutes } = useSelector(state => state.routes.routePlanner.routePlannerRoutes);

  const isViewOnlyOrSupport = checkIfSupport() || checkIfViewOnly();

  let closeUpdateStatusPopover = () => {};

  const routePlannerFilters = useMemo(() => {
    return getRoutePlannerFilters(search);
  }, [search]);

  const vehicleTypeAndDayOfServiceFilters = useMemo(() => {
    return getRoutePlannerOptions(vehicleTypes, filterPreferences, search);
  }, [filterPreferences, search, vehicleTypes]);

  const { date } = getQueryParams(search);
  const dateParam = date ? moment(date, 'DD/MM/YYYY').format('MM-DD-YYYY') : moment().format('MM-DD-YYYY');
  const { supervisorsIds, serviceZonesIds, facilityTypeIds, materialTypeIds, groupIds } = routePlannerFilters;
  const { vehicleTypeId } = vehicleTypeAndDayOfServiceFilters;

  const isSnowPlowRoute = vehicleTypeId === SNOW_PLOW_ID;

  const filtersToPass = useMemo(
    () => ({
      vendorId,
      date: dateParam,
      materialTypeIds: materialTypeIds?.length ? materialTypeIds?.toString() : '',
      vehicleTypeIds: vehicleTypeId ? vehicleTypeId?.toString() : '',
      supervisorIds: supervisorsIds?.length && supervisorEnabled ? supervisorsIds?.toString() : '',
      serviceZoneIds: serviceZonesIds?.length ? serviceZonesIds?.toString() : '',
      facilityIds: facilityTypeIds?.length ? facilityTypeIds?.toString() : '',
      groupIds: groupIds?.length ? groupIds?.toString() : '',
    }),
    [
      vendorId,
      dateParam,
      materialTypeIds,
      vehicleTypeId,
      supervisorsIds,
      supervisorEnabled,
      serviceZonesIds,
      facilityTypeIds,
      groupIds,
    ],
  );

  useEffect(() => {
    if (vehicleTypeId) {
      loadRoutePlannerRouteVehicles(filtersToPass)(dispatch);
      loadRoutePlannerRouteDrivers(filtersToPass)(dispatch);

      loadRoutePlannerRoutes(filtersToPass)(dispatch).then((routes: RoutePlannerRoute[]) => {
        if (routes?.length) {
          const routeIds = routes.map(route => route.routeId);
          if (getIsVendorNotChanged(vendorId) && Cookie.get(SESSION_COOKIE_KEY)) {
            if (routeIds.length) loadRouteSequenceStatus(routeIds, SEQUENCE_SOURCE_TYPE_DAILY_ROUTE)(dispatch);
          }
        }
      });
    }
  }, [
    dateParam,
    dispatch,
    facilityTypeIds,
    filterPreferences,
    filtersToPass,
    materialTypeIds,
    routePlannerFilters,
    serviceZonesIds,
    supervisorEnabled,
    supervisorsIds,
    vehicleTypeAndDayOfServiceFilters,
    vehicleTypeId,
    vehicleTypes,
    vendorId,
  ]);

  // refresh the route sequence statuses
  useEffect(() => {
    const interval = setInterval(() => {
      if (routes.length) {
        const routeIds = routes.map(route => route.routeId);
        if (getIsVendorNotChanged(vendorId) && Cookie.get(SESSION_COOKIE_KEY)) {
          loadRouteSequenceStatus(routeIds, SEQUENCE_SOURCE_TYPE_DAILY_ROUTE)(dispatch);
        }
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch, routes, vendorId]);

  const addRouteUpdating = (id: number) => {
    if (!routeIdsUpdating.includes(id)) {
      setRouteIdsUpdating([...routeIdsUpdating, id]);
    }
  };

  const removeRouteUpdating = (id: number) => {
    setRouteIdsUpdating(filter(routeIdsUpdating, routeId => routeId !== id));
  };

  // updating the drivers, vehicles and routes that have been changed
  const combinedDrivers: RoutePlannerRouteDriver[] = useMemo(() => {
    return orderBy(
      map(drivers, driver => {
        const changedDriver = find(changedDrivers, changedDriver => changedDriver.driverId === driver.driverId);
        return changedDriver || driver;
      }),
      ['driverName'],
      ['asc'],
    );
  }, [drivers, changedDrivers]);

  const combinedVehicles: RoutePlannerRouteVehicle[] = useMemo(() => {
    return orderBy(
      map(vehicles, vehicle => {
        const changedVehicle = find(changedVehicles, changedVehicle => changedVehicle.vehicleId === vehicle.vehicleId);
        return changedVehicle || vehicle;
      }),
      ['vehicleName'],
      ['asc'],
    );
  }, [vehicles, changedVehicles]);

  const combinedRoutes: RoutePlannerRoute[] = useMemo(() => {
    return orderBy(
      map(
        filter(routes, r => !deletedRoutes.includes(r.routeId)),
        route => {
          const changedRoute = find(changedRoutes, changedRoute => changedRoute.routeId === route.routeId);
          return changedRoute
            ? {
                ...changedRoute,
                isUpdating: routeIdsUpdating.includes(changedRoute.routeId),
              }
            : route;
        },
      ),
      ['routeStatusTypeId', 'routeName'],
      ['asc'],
    );
  }, [routes, deletedRoutes, changedRoutes, routeIdsUpdating]);

  const filteredRoutesForTableSection: RoutePlannerRoute[] = useMemo(() => {
    return filter(combinedRoutes, route => {
      return (
        route.routeName.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (noDriver ? route.driverId === null : true) &&
        (noVehicle ? route.vehicleId === null : true) &&
        (unavailableVehiclesOrDrivers ? route.isDriverUnavailable || route.isVehicleUnavailable || false : true)
      );
    });
  }, [combinedRoutes, searchTerm, noDriver, noVehicle, unavailableVehiclesOrDrivers]);

  const assignDriver = (driverId: number, routeId: number, isUnassignedFrom?: number) => {
    const changedDriver = find(combinedDrivers, driver => driver.driverId === driverId);
    const changedRoute = find(combinedRoutes, route => route.routeId === routeId);
    const unassignedFromRoute = find(combinedRoutes, route => route.routeId === isUnassignedFrom);

    if (changedDriver && changedRoute) {
      addRouteUpdating(routeId);
      assignDriverToRoute(
        vendorId,
        changedRoute.routeId,
        changedDriver.driverId,
      )(dispatch)
        .then(() => {
          // updating the changes locally
          const newDriver = {
            ...changedDriver,
            routes: [
              ...filter(
                changedDriver.routes,
                route => route.routeId !== routeId && (isUnassignedFrom ? route.routeId !== isUnassignedFrom : true),
              ),
              { routeId, routeName: changedRoute.routeName },
            ],
          };
          setChangedDrivers(prevDrivers => [...filter(prevDrivers, driver => driver.driverId !== driverId), newDriver]);
          const updatedRoute = {
            ...changedRoute,
            driverId,
            driverName: changedDriver.driverName,
          };
          if (isUnassignedFrom && unassignedFromRoute) {
            // if dragged from one route to another we unassign the driver from the previous route
            removeDriverFromRoute(
              vendorId,
              unassignedFromRoute.routeId,
              driverId,
            )(dispatch)
              .then(() => {
                const updatedUnassignedRoute = {
                  ...unassignedFromRoute,
                  driverId: undefined,
                  driverName: undefined,
                };
                setChangedRoutes(prevRoutes => [
                  ...filter(prevRoutes, route => route.routeId !== routeId && route.routeId !== isUnassignedFrom),
                  updatedRoute,
                  updatedUnassignedRoute,
                ]);
              })
              .catch(error => {
                error.exceptionMessage && createErrorNotification(error.exceptionMessage);
              });
          } else
            setChangedRoutes(prevRoutes => [...filter(prevRoutes, route => route.routeId !== routeId), updatedRoute]);
          removeRouteUpdating(routeId);
          createSuccessNotification(translate('routes.planner.driverAssignedSuccessfully'));
        })
        .catch(error => {
          error.exceptionMessage && createErrorNotification(error.exceptionMessage);
          removeRouteUpdating(routeId);
        });
    }
  };

  const assignVehicle = (vehicleId: number, routeId: number, isUnassignedFrom?: number) => {
    const changedVehicle = find(combinedVehicles, vehicle => vehicle.vehicleId === vehicleId);
    const changedRoute = find(combinedRoutes, route => route.routeId === routeId);
    const unassignedFromRoute = find(combinedRoutes, route => route.routeId === isUnassignedFrom);

    if (changedVehicle && changedRoute) {
      addRouteUpdating(routeId);
      assignVehicleToRoute(
        vendorId,
        changedRoute.routeId,
        changedVehicle.vehicleId,
      )(dispatch)
        .then(() => {
          // updating the changes locally
          const newVehicle = {
            ...changedVehicle,
            routes: [
              ...filter(changedVehicle.routes, v => (isUnassignedFrom ? v.routeId !== isUnassignedFrom : true)),
              { routeId: changedRoute.routeId, routeName: changedRoute.routeName },
            ],
          };
          setChangedVehicles(prevVehicles => [
            ...filter(prevVehicles, vehicle => vehicle.vehicleId !== vehicleId),
            newVehicle,
          ]);
          const updatedRoute = {
            ...changedRoute,
            vehicleId,
            vehicleName: changedVehicle.vehicleName,
          };
          if (isUnassignedFrom && unassignedFromRoute) {
            // when dragged from one route to another make sure to unassign from the previous route
            removeVehicleFromRoute(
              vendorId,
              unassignedFromRoute.routeId,
              vehicleId,
            )(dispatch)
              .then(() => {
                const updatedUnassignedRoute = {
                  ...unassignedFromRoute,
                  vehicleId: undefined,
                  vehicleName: undefined,
                };
                setChangedRoutes(prevRoutes => [
                  ...filter(prevRoutes, route => route.routeId !== routeId && route.routeId !== isUnassignedFrom),
                  updatedRoute,
                  updatedUnassignedRoute,
                ]);
              })
              .catch(error => {
                error.exceptionMessage && createErrorNotification(error.exceptionMessage);
              });
          } else
            setChangedRoutes(prevRoutes => [...filter(prevRoutes, route => route.routeId !== routeId), updatedRoute]);
          removeRouteUpdating(routeId);
          createSuccessNotification(translate('routes.planner.vehicleAssignedSuccessfully'));
        })
        .catch(error => {
          error.exceptionMessage && createErrorNotification(error.exceptionMessage);
          removeRouteUpdating(routeId);
        });
    }
  };

  const unassignDriver = (driverId: number, routeId: number, softRemove?: boolean) => {
    const changedDriver = find(combinedDrivers, driver => driver.driverId === driverId);
    const changedRoute = find(combinedRoutes, route => route.routeId === routeId);

    if (changedDriver && changedRoute) {
      if (softRemove) {
        // when a driver is replaced by another driver
        const newDriver = {
          ...changedDriver,
          routes: filter(changedDriver.routes, route => route.routeId !== routeId),
        };
        setChangedDrivers(prevDrivers => [...filter(prevDrivers, driver => driver.driverId !== driverId), newDriver]);
        const updatedRoute = {
          ...changedRoute,
          driverId: undefined,
          driverName: undefined,
        };
        setChangedRoutes(prevRoutes => [...filter(prevRoutes, route => route.routeId !== routeId), updatedRoute]);
      } else {
        addRouteUpdating(routeId);
        removeDriverFromRoute(
          vendorId,
          changedRoute.routeId,
          changedDriver.driverId,
        )(dispatch)
          .then(() => {
            // updating the changes locally
            const newDriver = {
              ...changedDriver,
              routes: filter(changedDriver.routes, route => route.routeId !== routeId),
            };
            setChangedDrivers(prevDrivers => [
              ...filter(prevDrivers, driver => driver.driverId !== driverId),
              newDriver,
            ]);
            const updatedRoute = {
              ...changedRoute,
              driverId: undefined,
              driverName: undefined,
            };
            setChangedRoutes(prevRoutes => [...filter(prevRoutes, route => route.routeId !== routeId), updatedRoute]);
            removeRouteUpdating(routeId);
            createSuccessNotification(translate('routes.planner.driverRemovedSuccessfully'));
          })
          .catch(error => {
            error.exceptionMessage && createErrorNotification(error.exceptionMessage);
            removeRouteUpdating(routeId);
          });
      }
    }
  };

  const unassignVehicle = (vehicleId: number, routeId: number, softRemove?: boolean) => {
    const changedVehicle = find(combinedVehicles, vehicle => vehicle.vehicleId === vehicleId);
    const changedRoute = find(combinedRoutes, route => route.routeId === routeId);

    if (changedVehicle && changedRoute) {
      if (softRemove) {
        // when a vehicle is replaced by another vehicle
        const newVehicle = {
          ...changedVehicle,
          routes: filter(changedVehicle.routes, route => route.routeId !== routeId),
        };
        setChangedVehicles(prevVehicles => [
          ...filter(prevVehicles, vehicle => vehicle.vehicleId !== vehicleId),
          newVehicle,
        ]);
        const updatedRoute = {
          ...changedRoute,
          vehicleId: undefined,
          vehicleName: undefined,
        };
        setChangedRoutes(prevRoutes => [...filter(prevRoutes, route => route.routeId !== routeId), updatedRoute]);
      } else {
        addRouteUpdating(routeId);
        removeVehicleFromRoute(
          vendorId,
          changedRoute.routeId,
          changedVehicle.vehicleId,
        )(dispatch)
          .then(() => {
            // updating the changes locally
            const newVehicle = {
              ...changedVehicle,
              routes: filter(changedVehicle.routes, route => route.routeId !== routeId),
            };
            setChangedVehicles(prevVehicles => [
              ...filter(prevVehicles, vehicle => vehicle.vehicleId !== vehicleId),
              newVehicle,
            ]);
            const updatedRoute = {
              ...changedRoute,
              vehicleId: undefined,
              vehicleName: undefined,
            };
            setChangedRoutes(prevRoutes => [...filter(prevRoutes, route => route.routeId !== routeId), updatedRoute]);
            removeRouteUpdating(routeId);
            createSuccessNotification(translate('routes.planner.vehicleRemovedSuccessfully'));
          })
          .catch(error => {
            error.exceptionMessage && createErrorNotification(error.exceptionMessage);
            removeRouteUpdating(routeId);
          });
      }
    }
  };

  const deleteRoute = async (routeId: number) => {
    if (!(await confirm(translate('routes.planner.deleteRoutesConfirmationSingle')))) return;

    const changedRoute = find(combinedRoutes, route => route.routeId === routeId);
    if (changedRoute) {
      if (changedRoute.driverId) {
        const changedDriver = find(combinedDrivers, driver => driver.driverId === changedRoute.driverId);
        if (changedDriver) {
          const newDriver = {
            ...changedDriver,
            routes: filter(changedDriver.routes, route => route.routeId !== routeId),
          };
          setChangedDrivers(prevDrivers => [
            ...filter(prevDrivers, driver => driver.driverId !== changedRoute.driverId),
            newDriver,
          ]);
        }
      }
      if (changedRoute.vehicleId) {
        const changedVehicle = find(combinedVehicles, vehicle => vehicle.vehicleId === changedRoute.vehicleId);
        if (changedVehicle) {
          const newVehicle = {
            ...changedVehicle,
            routes: filter(changedVehicle.routes, route => route.routeId !== routeId),
          };
          setChangedVehicles(prevVehicles => [
            ...filter(prevVehicles, vehicle => vehicle.vehicleId !== changedRoute.vehicleId),
            newVehicle,
          ]);
        }
      }
      deleteRouteTracker(routeId)(dispatch)
        .then(() => {
          createSuccessNotification(translate('routes.planner.deleteRoutesSuccessSingle'));
          setDeletedRoutes(prevRoutes => [...prevRoutes, routeId]);
        })
        .catch(() => {
          createErrorNotification(translate('routes.planner.deleteRoutesError'));
        });
    }
  };

  const onUpdateIndividualStatusClick = (event: MouseEvent<HTMLElement>, driverId?: number, vehicleId?: number) => {
    event.stopPropagation();
    let driver = find(combinedDrivers, driver => driver.driverId === driverId);
    let vehicle = find(combinedVehicles, vehicle => vehicle.vehicleId === vehicleId);
    closeUpdateStatusPopover = createPopover(
      event.currentTarget,
      DriverOrVehicleStatusSingleFormResolver,
      {
        driver,
        vehicle,
        date: dateParam,
        onUpdateStatus: ({ driverStatus, vehicleStatus, updatedStatus }: UpdateStatusFormValues) => {
          if (driverStatus) {
            updateRoutePlannerDriversStatus(vendorId, [{ ...driverStatus, isAvailable: updatedStatus }])(dispatch)
              .then(() => {
                // updating the changes locally
                const changedDriver = find(combinedDrivers, driver => driver.driverId === driverId);
                if (changedDriver) {
                  const newDriver = {
                    ...changedDriver,
                    isAvailable: updatedStatus,
                  };
                  setChangedDrivers(prevDrivers => [
                    ...filter(prevDrivers, driver => driver.driverId !== driverId),
                    newDriver,
                  ]);
                }

                //update locally any route that has this driver assigned
                const changedRoutes = filter(combinedRoutes, route => route.driverId === driverId);
                if (size(changedRoutes)) {
                  const updatedRoutes = map(changedRoutes, route => ({
                    ...route,
                    isDriverUnavailable: !updatedStatus,
                  }));
                  setChangedRoutes(prevRoutes => [
                    ...filter(prevRoutes, route => route.driverId !== driverId),
                    ...updatedRoutes,
                  ]);
                }

                createSuccessNotification(translate('routes.planner.updatedStatusSuccess'));
              })
              .catch(error => {
                error.exceptionMessage
                  ? createErrorNotification(error.exceptionMessage)
                  : createErrorNotification(translate('routes.planner.updatedStatusError'));
              });
          } else if (vehicleStatus) {
            updateRoutePlannerVehiclesStatus(vendorId, [{ ...vehicleStatus, isAvailable: updatedStatus }])(dispatch)
              .then(() => {
                // updating the changes locally
                const changedVehicle = find(combinedVehicles, vehicle => vehicle.vehicleId === vehicleId);
                if (changedVehicle) {
                  const newVehicle = {
                    ...changedVehicle,
                    isAvailable: updatedStatus,
                  };
                  setChangedVehicles(prevVehicles => [
                    ...filter(prevVehicles, vehicle => vehicle.vehicleId !== vehicleId),
                    newVehicle,
                  ]);

                  //update locally any route that has this vehicle assigned
                  const changedRoutes = filter(combinedRoutes, route => route.vehicleId === vehicleId);
                  if (size(changedRoutes)) {
                    const updatedRoutes = map(changedRoutes, route => ({
                      ...route,
                      isVehicleUnavailable: !updatedStatus,
                    }));
                    setChangedRoutes(prevRoutes => [
                      ...filter(prevRoutes, route => route.vehicleId !== vehicleId),
                      ...updatedRoutes,
                    ]);
                  }
                }
                createSuccessNotification(translate('routes.planner.updatedStatusSuccess'));
              })
              .catch(error => {
                error.exceptionMessage
                  ? createErrorNotification(error.exceptionMessage)
                  : createErrorNotification(translate('routes.planner.updatedStatusError'));
              });
          }
          closeUpdateStatusPopover();
        },
        onClose: closeUpdateStatusPopover,
      },
      {
        position: TOP,
        size: 'large',
      },
    );
  };

  const openAvailabilityStatusEditor = (editDrivers: boolean) => {
    if (editDrivers) setAvailabilityEditActiveTab(STATUS_EDITOR_TABS.DRIVERS_STATUS);
    else setAvailabilityEditActiveTab(STATUS_EDITOR_TABS.VEHICLES_STATUS);
    setIsAvailabilityEditModalOpen(true);
  };

  const onUpdateVehiclesAndDriversAvailabilityStatus = (
    drivers: RoutePlannerRouteDriver[],
    vehicles: RoutePlannerRouteVehicle[],
  ) => {
    if (!!size(drivers)) {
      const driversStatusPayload = map(drivers, driver => ({
        driverId: driver.driverId,
        isAvailable: driver.isAvailable,
        date: dateParam,
      }));

      updateRoutePlannerDriversStatus(
        vendorId,
        driversStatusPayload,
      )(dispatch)
        .then(() => {
          // updating the drivers locally
          setChangedDrivers(prevDrivers => [
            ...filter(prevDrivers, d => !find(drivers, driver => driver.driverId === d.driverId)),
            ...drivers,
          ]);
          createSuccessNotification(translate('routes.planner.updatedStatusSuccess'));
        })
        .catch(error => {
          error.exceptionMessage
            ? createErrorNotification(error.exceptionMessage)
            : createErrorNotification(translate('routes.planner.updatedStatusError'));
        });
    }
    if (!!size(vehicles)) {
      const vehiclesStatusPayload = map(vehicles, vehicle => ({
        vehicleId: vehicle.vehicleId,
        isAvailable: vehicle.isAvailable,
        date: dateParam,
      }));
      updateRoutePlannerVehiclesStatus(
        vendorId,
        vehiclesStatusPayload,
      )(dispatch)
        .then(() => {
          // updating the vehicles locally
          setChangedVehicles(prevVehicles => [
            ...filter(prevVehicles, v => !find(vehicles, vehicle => vehicle.vehicleId === v.vehicleId)),
            ...vehicles,
          ]);
          createSuccessNotification(translate('routes.planner.updatedStatusSuccess'));
        })
        .catch(error => {
          error.exceptionMessage
            ? createErrorNotification(error.exceptionMessage)
            : createErrorNotification(translate('routes.planner.updatedStatusError'));
        });
    }
  };

  const goBackToPreviousPage = () => {
    dispatch(push('/routes/route-planner', { prevPath: pathname }));
  };

  const dailyPlannerTitle = `${moment(date, 'DD/MM/YYYY').format('dddd')} - ${moment(date, 'DD/MM/YYYY').format(
    'MM/DD/YYYY',
  )}`;

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction onClick={goBackToPreviousPage} id="back-button">
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle>{translate('routes.planner.routePlannerDetails')}</PageTitle>
            <PageSubtitle size="medium">{dailyPlannerTitle}</PageSubtitle>
          </PageTitleContainer>
        </PageDetails>
        {!isViewOnlyOrSupport && (
          <PageActions align="center">
            <Button
              margin="xSmall sMedium no no"
              line
              color="primary"
              id="route-planner-add-route-button"
              onClick={() =>
                dispatch(push(isSnowPlowRoute ? '/routes/snow-tracker/create' : '/routes/route-tracker/create'))
              }
            >
              + {translate('routes.addRoute')}
            </Button>
          </PageActions>
        )}
      </PageHeader>
      <PanelSectionGroup>
        <DailyAndReoccurringFiltersSectionResolver />
      </PanelSectionGroup>
      {vehicleTypeId ? (
        <>
          <PanelSection display="block" isLoading={isLoadingRoutes || isLoadingDrivers || isLoadingVehicles}>
            <DailyAndReoccurringStatisticsSection
              drivers={combinedDrivers}
              vehicles={combinedVehicles}
              routes={combinedRoutes}
            />
          </PanelSection>
          <AssigningDriversAndVehiclesSection
            drivers={combinedDrivers}
            isDaily
            isLoadingDrivers={isLoadingDrivers}
            isLoadingRoutes={isLoadingRoutes}
            isLoadingVehicles={isLoadingVehicles}
            onAssignDriver={assignDriver}
            onAssignVehicle={assignVehicle}
            onEditAvailability={onUpdateIndividualStatusClick}
            onUnassignDriver={unassignDriver}
            onUnassignVehicle={unassignVehicle}
            openAvailabilityStatusBulkEditor={openAvailabilityStatusEditor}
            routes={combinedRoutes}
            routeTemplates={[]}
            setNoDriver={setNoDriver}
            setNoVehicle={setNoVehicle}
            setSearchTerm={setSearchTerm}
            setUnavailableVehiclesOrDrivers={setUnavailableVehiclesOrDrivers}
            vehicles={combinedVehicles}
          />
          <RoutePlannerRouteListingSection
            rows={filteredRoutesForTableSection}
            isLoading={isLoadingRoutes}
            deleteRoute={deleteRoute}
          />
        </>
      ) : (
        <NoDataMessageContainer>
          <Text>{translate('routes.planner.selectVehicleType')}</Text>
        </NoDataMessageContainer>
      )}
      {isAvailabilityEditModalOpen && (
        <DriversAndVehiclesStatusEditModalResolver
          date={dateParam}
          filters={filtersToPass}
          activeTab={availabilityEditActiveTab}
          closeModal={() => setIsAvailabilityEditModalOpen(false)}
          onStatusChanged={onUpdateVehiclesAndDriversAvailabilityStatus}
        />
      )}
    </PageContent>
  );
};

export default RoutePlannerDailyRoutesPage;
