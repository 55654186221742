import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

import { getQueryParams } from '../../utils/services/queryParams';
import { TODAY_FORMATTED, ONE_MONTH_AGO } from '../../core/constants';

const getContainerHistoryFormInitialValues = (queryString: string) => {
  const { startDate = ONE_MONTH_AGO, endDate = TODAY_FORMATTED } = getQueryParams(queryString);

  return {
    dateRange: {
      from: startDate,
      to: endDate,
    },
  };
};

const containerHistoryFormInitialValuesSelector = createSelector(getContainerHistoryFormInitialValues, identity);

export default containerHistoryFormInitialValuesSelector;
