import { mapKeys } from 'lodash-es';

import translate from '../../core/services/translate';

export const FLEET_MANAGEMENT = 1;
export const ACCOUNT_MANAGEMENT = 2;
export const ROUTE_OPTIMIZATION = 3;


export const FEATURE_CATEGORY_TYPE = mapKeys(
  [
    { id: FLEET_MANAGEMENT, name: translate('vendors.fleetManagement') },
    { id: ACCOUNT_MANAGEMENT, name: translate('vendors.accountManagement') },
    { id: ROUTE_OPTIMIZATION, name: translate('vendors.routeOptimization') },
  ],
  'id',
);
