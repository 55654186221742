import { Field } from 'redux-form';
import translate from 'src/core/services/translate';
import { Dropdown, Switch } from '../../core/components';
import { GridColumn, TableCell, TableRow, Text } from '../../core/components/styled';
import {
  DELIVERY_UTILITY_ID,
  FRONT_LOAD_ID,
  RESIDENTIAL_ID,
  SNOW_PLOW_ID,
  STREET_SWEEPER_ID,
  TOTER_ID,
  VEHICLE_TYPE_IDS,
} from '../../fleet/constants';

interface Props {
  vehicleTypeId: number;
}

const alleyPriorityTypes = [
  {
    label: translate('common.alleyPriorityTypes.avoid'),
    value: 1,
  },
  {
    label: translate('common.alleyPriorityTypes.prefer'),
    value: 2,
  },
  {
    label: translate('common.alleyPriorityTypes.indifferent'),
    value: 3,
  },
];

const NavigationTableRow = ({ vehicleTypeId }: Props) => {
  const showNavigationConfig = [
    FRONT_LOAD_ID,
    TOTER_ID,
    RESIDENTIAL_ID,
    STREET_SWEEPER_ID,
    SNOW_PLOW_ID,
    DELIVERY_UTILITY_ID,
  ].includes(vehicleTypeId);

  return (
    <TableRow>
      <TableCell width="20%">{VEHICLE_TYPE_IDS[vehicleTypeId].name}</TableCell>
      <TableCell width="10%">
        <Field component={Switch} name={`${vehicleTypeId}.isActive`} />
      </TableCell>
      <TableCell width="12%">
        <Field component={Switch} name={`${vehicleTypeId}.avoidFreeways`} />
      </TableCell>
      <TableCell width="13%">
        <Field component={Switch} name={`${vehicleTypeId}.avoidTollRoads`} />
      </TableCell>
      <TableCell width="11%">
        <Field component={Switch} name={`${vehicleTypeId}.avoidUTurns`} />
      </TableCell>
      <TableCell width="13%">
        <Field component={Switch} name={`${vehicleTypeId}.useRealTimeTrafficData`} />
      </TableCell>
      <TableCell width="15%">
        {showNavigationConfig ? (
          <Field component={Switch} name={`${vehicleTypeId}.isSequentialNavigationEnabled`} />
        ) : (
          <Text color="gray" size="medium">
            <em>{translate('opportunity.serviceFrequency.nA')}</em>
          </Text>
        )}
      </TableCell>
      <TableCell width="18%">
        <GridColumn size="12/12" padding="no small no no">
          <Field
            component={Dropdown}
            props={{
              options: alleyPriorityTypes,
              label: translate('vendors.navigation.alleyPriority'),
            }}
            name={`${vehicleTypeId}.alleyPriorityTypeId`}
          />
        </GridColumn>
      </TableCell>
    </TableRow>
  );
};

export default NavigationTableRow;
