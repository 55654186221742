import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { RouteComponentProps } from 'react-router';

import { getQueryParams } from '../../../utils/services/queryParams';
import { LIMIT_PER_PAGE } from '../../constants';
import { Grid, GridColumn, ModalTitle, ModalSubtitle } from '../../../core/components/styled';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { Modal } from '../../../core/components';
import translate from '../../../core/services/translate';
import AcceptProcessedServicesForm from '../forms/AcceptRecurringServiceForm';
import { AppState } from '../../../store';

interface RouteParams {
  token: string;
}

interface ComponentProps {
  rateCodes: any[];
  workOrderIdentifier: string;
  workOrder: number;
  vendorId: number;
  action: number;
  updateRecurringServices(...args: any[]): any;
  onCancel(...args: any[]): any;
}

interface Props extends ComponentProps, RouteComponentProps<RouteParams> {
  pricingInfo: any;
  isSaving: boolean;
}

class AcceptRecurringServicesModal extends PureComponent<Props> {
  onSubmit = async () => {
    const {
      vendorId,
      workOrder,
      action,
      rateCodes,
      pricingInfo,
      location,
      onCancel,
      match: {
        params: { token },
      },
    } = this.props;
    const { page } = getQueryParams(location.search);
    const requestRecurringServicesObj: any = {};

    requestRecurringServicesObj.workOrder = workOrder;
    requestRecurringServicesObj.date = moment(pricingInfo.reportDate).format('YYYY-MM-DD');
    requestRecurringServicesObj.rubiconInvoiceNo = pricingInfo.rubiconInvoiceNo;

    if (action === 0 || action === 4) {
      requestRecurringServicesObj.rateCodes = rateCodes;
    }

    await this.props
      .updateRecurringServices(requestRecurringServicesObj, vendorId, token, page, LIMIT_PER_PAGE)
      .then(() => {
        createSuccessNotification(translate('opportunity.opportunitySave'));
        onCancel(true);
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  };

  stopPropagation = (event: any) => {
    event.preventDefault();
  };

  render() {
    const { isSaving, onCancel } = this.props;
    return (
      <Modal size="small" isLoading={isSaving}>
        <ModalTitle>{translate('opportunity.acceptService')}</ModalTitle>
        <ModalSubtitle />
        <Grid centered>
          <GridColumn size="8/12">
            <AcceptProcessedServicesForm
              workOrderIdentifier={this.props.workOrderIdentifier}
              rateCodes={this.props.rateCodes}
              onSubmit={this.onSubmit}
              onCancel={onCancel}
            />
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  pricingInfo: state.payments.recurringServices.pricingInfo,
  isSaving: state.payments.recurringServices.isSaving,
});

export default connect(mapStateToProps, undefined)(AcceptRecurringServicesModal);
