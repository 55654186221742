import React from 'react';

import { WEEKDAYS, DaysOfWeekShortCodes, DaysOfWeekIds, Weekday } from '../constants/weekdays';
import { FormGroup, FormLabel } from './styled';
import { DaysOfWeekProps, DayOfWeekProps, DaysOfWeek, DayOfWeek } from './styled/DaysOfWeekPicker';

interface Props {
  selectedDays?: (DaysOfWeekShortCodes | DaysOfWeekIds | number)[];
  label?: string;
  margin?: string;
  daysOfWeekProps?: Partial<DaysOfWeekProps>;
  dayOfWeekProps?: Partial<DayOfWeekProps>;
}

export default function DaysOfWeekDisplay({
  selectedDays = [],
  label,
  margin,
  daysOfWeekProps,
  dayOfWeekProps,
}: Props) {
  const isDayOfWeekSelected = (day: Weekday) =>
    selectedDays.indexOf(day.id) !== -1 || selectedDays.indexOf(day.shortCode) !== -1;

  return (
    <FormGroup hasValue margin={margin}>
      {!!label && <FormLabel>{label}</FormLabel>}

      <DaysOfWeek {...daysOfWeekProps} isReadOnly>
        {WEEKDAYS.map(weekday => (
          <DayOfWeek key={weekday.id} {...dayOfWeekProps} isSelected={isDayOfWeekSelected(weekday)}>
            {weekday.shortCodeName}
          </DayOfWeek>
        ))}
      </DaysOfWeek>
    </FormGroup>
  );
}
