import React, { ComponentType, ComponentProps } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import axios from 'axios';

type Props<S extends ComponentType<any>, L extends ComponentType<any>> = {
  successComponent: S;
  loadingComponent: L;
  successProps?: ComponentProps<S>;
  loadingProps?: ComponentProps<L>;
  redirectOnError?: string;
  resolve?: () => Promise<any>;
  onSuccess?: (result: any) => void;
  onError?: (error: any) => void;
};

const TypedResolver = <S extends ComponentType<any>, L extends ComponentType<any>>({
  successComponent: SuccessComponent,
  loadingComponent: LoadingComponent,
  successProps = {} as any,
  loadingProps = {} as any,
  redirectOnError,
  resolve,
  onSuccess,
  onError,
}: Props<S, L>) => {
  const dispatch = useDispatch();

  const [initiated, setInitiated] = React.useState<boolean>(false);
  const [resolved, setResolved] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (initiated) {
      return;
    }

    setInitiated(true);

    if (resolve) {
      resolve()
        .then(result => {
          if (onSuccess) onSuccess(result);

          setResolved(true);
        })
        .catch(error => {
          if (onError) onError(error);
          if (redirectOnError && !axios.isCancel(error)) dispatch(push(redirectOnError));
        });
    } else {
      setResolved(true);
    }
  }, [initiated, setInitiated, resolve, setResolved, onSuccess, onError, redirectOnError, dispatch]);

  return resolved ? <SuccessComponent {...successProps} /> : <LoadingComponent {...loadingProps} />;
};

export default TypedResolver;
