import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { PageLoading } from 'src/common/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  loadRoutePlannerDriverStatus,
  loadRoutePlannerVehicleStatus,
  resetRoutePlannerDriverStatus,
  resetRoutePlannerVehicleStatus,
} from 'src/routes/ducks/routePlanner';
import { RoutePlannerRouteDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteDriver';
import { RoutePlannerRouteTemplateDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateDriver';
import { RoutePlannerRouteTemplateVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateVehicle';
import { RoutePlannerRouteVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteVehicle';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import DriverOrVehicleStatusSingleForm, { UpdateStatusFormValues } from './DriverOrVehicleStatusSingleForm';

type Props = {
  vehicle?: RoutePlannerRouteVehicle | RoutePlannerRouteTemplateVehicle;
  driver?: RoutePlannerRouteDriver | RoutePlannerRouteTemplateDriver;
  date: string;
  onUpdateStatus: (values: UpdateStatusFormValues) => void;
  onClose: () => void;
};

export default function DriverOrVehicleStatusSingleFormResolver({
  vehicle,
  driver,
  date,
  onUpdateStatus,
  onClose,
}: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const { vehicleStatus, isLoading: isLoadingVehicleStatus } = useSelector(
    state => state.routes.routePlanner.routePlannerVehicleStatus,
  );
  const { driverStatus, isLoading: isLoadingDriverStatus } = useSelector(
    state => state.routes.routePlanner.routePlannerDriverStatus,
  );

  useEffect(() => {
    dispatch(resetRoutePlannerDriverStatus());
    dispatch(resetRoutePlannerVehicleStatus());
    if (driver) {
      loadRoutePlannerDriverStatus(vendorId, driver.driverId, date)(dispatch).catch(() => onClose());
    } else if (vehicle) {
      loadRoutePlannerVehicleStatus(vendorId, vehicle.vehicleId, date)(dispatch).catch(() => onClose());
    }
  }, [date, dispatch, driver, onClose, vehicle, vendorId]);

  const initialValues = {
    vehicleStatus: vehicleStatus || undefined,
    driverStatus: driverStatus || undefined,
    currentStatus: vehicleStatus ? vehicleStatus.isAvailable : driverStatus ? driverStatus.isAvailable : false,
    updatedStatus: vehicleStatus ? vehicleStatus.isAvailable : driverStatus ? driverStatus.isAvailable : false,
  };

  if (isLoadingVehicleStatus || isLoadingDriverStatus)
    return (
      <Box minHeight={150}>
        <PageLoading height="150px" />
      </Box>
    );

  return (
    <DriverOrVehicleStatusSingleForm
      onSubmit={onUpdateStatus}
      initialValues={initialValues}
      form={vehicle ? `vehicleStatus_${vehicle.vehicleId}` : `driverStatus_${driver?.driverId}`}
      vehicle={vehicle}
      driver={driver}
    />
  );
}
