import { MonthlyBody, MonthlyCalendar } from '@zach.codes/react-calendar';
import '@zach.codes/react-calendar/dist/calendar-tailwind-no-reset.css';
import moment from 'moment';

import { PopoverWrapper } from 'src/core/components';
import MonthlyDay from 'src/core/components/MonthlyDay';
import MonthlyNav from 'src/core/components/MonthlyNav';
import { CalendarWrapper, MonthlyEventItem } from 'src/core/components/styled/Calendar';
import UnbilledChargesMonthlyEventItemPopoverButton from 'src/finance/components/pages/billing/components/UnbilledChargesMonthlyEventItemPopoverButton';
import UnbilledChargesMonthlyEventItemPopoverContent from 'src/finance/components/pages/billing/components/UnbilledChargesMonthlyEventItemPopoverContent';
import { CalendarUnbilledCharge } from 'src/finance/interfaces/Billing';

interface Props {
  calendarUnbilledCharges: CalendarUnbilledCharge[];
  currentMonth: {
    startDate: Date;
    endDate: Date;
  };
  handleDateChange: (date: Date) => void;
}

const UnbilledChargesCalendar: React.FC<Props> = ({ calendarUnbilledCharges, currentMonth, handleDateChange }) => (
  <CalendarWrapper>
    <MonthlyCalendar currentMonth={currentMonth.startDate} onCurrentMonthChange={handleDateChange}>
      <MonthlyNav />
      <MonthlyBody
        events={calendarUnbilledCharges.map(item => ({
          ...item,
          date: moment(item.date).toDate(),
        }))}
      >
        <MonthlyDay<CalendarUnbilledCharge>
          height="9rem"
          highlightCurrentDay
          noOverflow
          renderDay={(data: CalendarUnbilledCharge[]) => {
            return data.map((uc, i) => (
              <MonthlyEventItem key={i}>
                <PopoverWrapper
                  width="100%"
                  size="large"
                  triggerButton={<UnbilledChargesMonthlyEventItemPopoverButton charge={uc} />}
                  popoverContent={<UnbilledChargesMonthlyEventItemPopoverContent charge={uc} />}
                />
              </MonthlyEventItem>
            ));
          }}
        />
      </MonthlyBody>
    </MonthlyCalendar>
  </CalendarWrapper>
);

export default UnbilledChargesCalendar;
