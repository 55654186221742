import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { ACTIVE } from '../../constants/routeTemplateStatuses';
import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { loadRouteTemplatesForHolidayPlanner } from '../../ducks';
import { loadVehicleTypesForVendor } from '../../../fleet/ducks';
import { PAGE, MAX_LIMIT } from '../../../core/constants/pagination';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { ROUTE, SNOW_PLOW } from '../../../fleet/constants';
import { TechnicalType } from '../../../common/interfaces/TechnicalType';
import { vehicleTypesForVendorWithRoleTypeSelector } from '../../../fleet/ducks';
import HolidayPlannerSelectRoutesModal from './HolidayPlannerSelectRoutesModal';

interface Props {
  closeModal: () => void;
  holidayId: number;
  isDefaultHoliday: boolean;
  loadRouteTemplatesForHolidayPlanner: (
    vendorId: number,
    routeTemplateName: string,
    daysOfServiceIds: any[],
    vehicleTypeIds: string,
    routeStatus: number,
    page: number,
    limit: number,
    sortOrder: string,
    sortedBy: string,
    holidayId: number,
    isDefaultHoliday: boolean,
  ) => Promise<any>;
  loadVehicleTypesForVendor: (vendorId: number) => Promise<any>;
  setRouteTemplatesCountDisplayed: (selectedTemplatesLength: number | string) => void;
  vendorId: number;
}

class HolidayPlannerSelectRoutesModalResolver extends PureComponent<Props> {
  loadDependencies = async () => {
    const { holidayId, isDefaultHoliday, loadRouteTemplatesForHolidayPlanner, loadVehicleTypesForVendor, vendorId } =
      this.props;

    const routeTemplateName = '';
    const daysOfServiceIds = [] as any;
    const routeStatus = ACTIVE;
    const page = PAGE;
    const limit = MAX_LIMIT;
    const sortOrder = 'asc';
    const sortedBy = 'wasteMaterialTypeName';
    let vehicleTypeIds = '';

    await loadVehicleTypesForVendor(vendorId).then(data => {
      const vehicleTypesForVendor = { vehicleTypesForVendor: data };
      const vehicleTypesForVendorOptions = (vehicleTypesForVendorWithRoleTypeSelector as any)(
        vehicleTypesForVendor,
        ROUTE,
        SNOW_PLOW,
      );
      vehicleTypeIds = vehicleTypesForVendorOptions.map((vehicleTypeId: TechnicalType) => vehicleTypeId.id).toString();
    });

    return loadRouteTemplatesForHolidayPlanner(
      vendorId,
      routeTemplateName,
      daysOfServiceIds,
      vehicleTypeIds,
      routeStatus,
      page,
      limit,
      sortOrder,
      sortedBy,
      holidayId,
      isDefaultHoliday,
    );
  };

  render() {
    const { closeModal, vendorId, holidayId, isDefaultHoliday, setRouteTemplatesCountDisplayed } = this.props;

    return (
      <Resolver
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
        successComponent={HolidayPlannerSelectRoutesModal}
        successProps={{ closeModal, vendorId, holidayId, isDefaultHoliday, setRouteTemplatesCountDisplayed }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadRouteTemplatesForHolidayPlanner, loadVehicleTypesForVendor };

export default connect(mapStateToProps, mapDispatchToProps)(HolidayPlannerSelectRoutesModalResolver);
