import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import moment from 'moment';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { date } from '../../../utils/services/formatter';
import { DELIVERY_UTILITY_ID, ROLL_OFF_ID, SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { formSegmentsFilterDateRange } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import {
  linkImageToWeightTicket,
  loadRoute,
  loadServiceDetails,
  loadSnowSweeperServiceDetails,
  loadWeightTickets,
  saveWeightTicket,
  uploadWeightTicketImage,
} from '../../ducks';
import { loadDisposalTickets } from 'src/routes/ducks/disposals';
import { Modal } from '../../../core/components';
import { TONS, POUNDS, KILOGRAMS, WEIGHT_MEASUREMENT_UNITS } from '../../../common/constants';
import translate from '../../../core/services/translate';
import WeightTicketEditorForm from '../forms/WeightTicketEditorForm';

const validateWeightTicket = (weightTicket: any) => {
  const { ticketNumber, locationId, weight, unitOfMeasureTypeId } = weightTicket;
  return !!ticketNumber && !!locationId && !!weight && weight > 0 && !!unitOfMeasureTypeId;
};

const TONS_LIMIT = 55;
const POUNDS_LIMIT = 110000;
const KILOGRAMS_LIMIT = 49895;

const validateWeightTicketWeight = (weightTicket: any) => {
  const { weight, unitOfMeasureTypeId } = weightTicket;
  return (
    (weight <= TONS_LIMIT && unitOfMeasureTypeId === TONS) ||
    (weight <= POUNDS_LIMIT && unitOfMeasureTypeId === POUNDS) ||
    (weight <= KILOGRAMS_LIMIT && unitOfMeasureTypeId === KILOGRAMS)
  );
};

type Props = {
  onClose: (formPristine?: boolean) => void;
  routeId: number;
  routeLocationId?: number;
  weightTicketId?: number;
  isRollOff: boolean;
  isUsingDisposals?: boolean;
};

export default function WeightTicketEditorModal({
  onClose,
  routeId,
  routeLocationId,
  weightTicketId,
  isRollOff,
  isUsingDisposals,
}: Props) {
  const dispatch = useDispatch();
  const isEditMode = weightTicketId ? true : false;

  const [uploadedImageId, setUploadedImageId] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(undefined);
  const [fileName, setFileName] = useState(undefined);

  const vendorId = useSelector(currentVendorId);
  const { isUploadingWeightTicketImage, isLinkingWeightTicketImage, isSaving } = useSelector(
    (state: AppState) => state.routes.weightTicket,
  );
  const { routeDate, vehicleTypeId, wasteMaterialTypeId, routeLocations } = useSelector((state: AppState) => ({
    routeDate: state.routes.route.route?.routeDate,
    vehicleTypeId: state.routes.route.route?.vehicleTypeId,
    wasteMaterialTypeId: state.routes.routeSummary.routeSummary?.wasteMaterialTypeId,
    routeLocations: state.routes.routeStops.routeStops as any,
  }));
  const allWeightTickets = useSelector((state: AppState) => state.routes.weightTickets.weightTickets) || [];
  const rawWeightTicket = allWeightTickets.find(wt => wt.id === weightTicketId);

  const segmentsFilterStartDate = useSelector(state => formSegmentsFilterDateRange(state, 'startDate'));
  const segmentsFilterEndDate = useSelector(state => formSegmentsFilterDateRange(state, 'endDate'));

  const isSnowPlowRoute = vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = vehicleTypeId === STREET_SWEEPER_ID;
  const isSnowOrSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

  let weightTicketInitialValues = {};
  if (rawWeightTicket) {
    const {
      activityTimeLocal,
      locationId,
      routeLocationId,
      ticketNumber,
      unitOfMeasureTypeId,
      wasteMaterialTypeId,
      weight,
    } = rawWeightTicket;

    weightTicketInitialValues = {
      disposalDate: date(activityTimeLocal),
      disposalTime: moment(activityTimeLocal).format('HH:mm'),
      locationId,
      routeLocationId,
      ticketNumber,
      unitOfMeasureTypeId,
      wasteMaterialTypeId,
      weight,
    };
  } else {
    weightTicketInitialValues = {
      disposalDate: date(routeDate),
      disposalTime: moment().format('HH:mm'),
      wasteMaterialTypeId:
        vehicleTypeId === ROLL_OFF_ID || vehicleTypeId === DELIVERY_UTILITY_ID
          ? routeLocations[0]?.service.wasteMaterialTypeId
          : wasteMaterialTypeId,
    };
  }

  const handleSubmitWeightTicket = async (formData: any) => {
    const { disposalDate, disposalTime } = formData;
    const date = moment(disposalDate).format('YYYY-MM-DD');
    const disposalDateTime = moment(date + disposalTime, 'YYYY-MM-DDLT');
    let weightTicket;
    if (!weightTicketId) {
      weightTicket = {
        ...formData,
        activityTimeLocal: disposalDateTime.format('YYYY-MM-DDTHH:mm:ss'),
      };
    } else {
      weightTicket = {
        ...rawWeightTicket,
        ...formData,
        activityTimeLocal: disposalDateTime.format('YYYY-MM-DDTHH:mm:ss'),
      };
    }

    if (!validateWeightTicket(weightTicket)) {
      createErrorNotification(translate('routes.alertMessages.weightTicketValidationFailed'));
      return;
    }

    if (!validateWeightTicketWeight(weightTicket)) {
      switch (weightTicket.unitOfMeasureTypeId) {
        case TONS:
          return createErrorNotification(
            translate('routes.alertMessages.weightTicketWeightValidationFailed', {
              limit: TONS_LIMIT,
              unitOfMeasureType: WEIGHT_MEASUREMENT_UNITS[TONS].name,
            }),
          );
        case POUNDS:
          return createErrorNotification(
            translate('routes.alertMessages.weightTicketWeightValidationFailed', {
              limit: POUNDS_LIMIT,
              unitOfMeasureType: WEIGHT_MEASUREMENT_UNITS[POUNDS].name,
            }),
          );
        case KILOGRAMS:
          return createErrorNotification(
            translate('routes.alertMessages.weightTicketWeightValidationFailed', {
              limit: KILOGRAMS_LIMIT,
              unitOfMeasureType: WEIGHT_MEASUREMENT_UNITS[KILOGRAMS].name,
            }),
          );
        default:
          return;
      }
    }

    if (uploadedImageId) {
      weightTicket.image = {
        url: uploadedImageUrl,
      };
    }

    saveWeightTicket(
      routeId,
      weightTicket,
    )(dispatch)
      .then(response => {
        const { routeLegId } = response;
        if (uploadedImageId) {
          linkImageToWeightTicket(
            uploadedImageId,
            routeLegId,
          )(dispatch).then(() => {
            refreshDisposalTickets();
          });
        } else {
          refreshDisposalTickets();
        }
        createSuccessNotification(translate('routes.alertMessages.weightTicketSaved'));
        onClose(true);
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.weightTicketSaveError'));
      });
  };

  const refreshDisposalTickets = () => {
    setUploadedImageId(null);
    if (isUsingDisposals) {
      loadDisposalTickets(vendorId, routeId)(dispatch);

      isSnowOrSweeperRoute
        ? loadSnowSweeperServiceDetails(vendorId, routeId, segmentsFilterStartDate, segmentsFilterEndDate)(dispatch)
        : loadServiceDetails(vendorId, routeId)(dispatch);
    } else {
      loadWeightTickets(routeId, routeLocationId)(dispatch);
      routeId !== undefined && loadRoute(routeId)(dispatch);
    }
  };

  const onImageUpload = (files: any) => {
    uploadWeightTicketImage(
      files[0],
      routeId,
    )(dispatch)
      .then(async response => {
        setUploadedImageId(response[0].id);
        setUploadedImageUrl(response[0].imageUrl);
        setFileName(response[0].imageFileName);
        createSuccessNotification(translate('common.alertMessages.uploadCompleted'));
      })
      .catch(() => {
        createErrorNotification(translate('common.alertMessages.invalidImageFormat'));
      });
  };

  const onClearFileName = () => {
    setFileName(undefined);
    setUploadedImageUrl(undefined);
    setUploadedImageId(null);
  };

  return (
    <Modal
      size="large"
      isLoading={isSaving || isUploadingWeightTicketImage || isLinkingWeightTicketImage}
      onClose={onClose}
      title={translate(`common.weightTickets.${isEditMode ? 'edit' : 'add'}WeightTicket`)}
    >
      <WeightTicketEditorForm
        initialValues={weightTicketInitialValues}
        isRollOff={isRollOff}
        onSubmit={handleSubmitWeightTicket}
        onClose={onClose}
        onImageUpload={onImageUpload}
        onClearfileName={onClearFileName}
        selectedFileName={fileName}
        routeLocations={routeLocations}
      />
    </Modal>
  );
}
