import React, { PureComponent } from 'react';

import { push } from 'connected-react-router';
import { size } from 'lodash-es';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { CUSTOMERS_SUSPENDED_LOCATIONS_EDIT, CUSTOMERS_SUSPENDED_LOCATIONS_STATUS } from 'src/account/constants';
import { hasPermissionSelector } from 'src/account/ducks';
import { DuckFunction } from 'src/contracts/ducks';
import { Pagination, Table } from 'src/core/components';
import { Message, PanelSection, PanelSectionGroup } from 'src/core/components/styled';
import { SortOrder } from 'src/core/components/Table';
import { LIMIT_PER_PAGE } from 'src/core/constants';
import translate from 'src/core/services/translate';
import { loadSuspendedLocations, resetSuspendedLocations } from 'src/customers/ducks';
import { CustomerLocation } from 'src/customers/interfaces/Customers';
import suspendedLocationsInitialValuesSelector from 'src/customers/services/supendedLocationsInitialValuesSelector';
import { AppState } from 'src/store';
import { getQueryParams } from 'src/utils/services/queryParams';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { SuspendedLocationsFormTableRow } from '.';

interface ComponentProps extends RouteComponentProps {
  hasEditLocationPermission: boolean;
  hasEditStatusPermission: boolean;
  loadSuspendedLocations: DuckFunction<typeof loadSuspendedLocations>;
  onSortOrderChange: (sortedBy: string, sortOrder: SortOrder) => void;
  onStatusChange: (key: string, customerId?: number, locationId?: number, serviceId?: number, value?: number) => void;
  push: (url: string) => void;
  resetSuspendedLocations: DuckFunction<typeof resetSuspendedLocations>;
  suspendedLocations: CustomerLocation[];
  total: number;
  vendorId: number;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;
//This has three functions passed in as props
//-an onSubmit
//-an onStatusChange
//-an onSortOrderChange
class SuspendedLocationsForm extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const { location, vendorId, loadSuspendedLocations } = this.props;
    if (prevProps.location.search !== location.search) {
      const { searchTerm, accountStatusTypeId, page, sortedBy, sortOrder } = getQueryParams(location.search);
      loadSuspendedLocations(vendorId, searchTerm, accountStatusTypeId, sortedBy, sortOrder, page, LIMIT_PER_PAGE);
    }
  }

  componentWillUnmount() {
    this.props.resetSuspendedLocations();
  }

  render() {
    const {
      total,
      suspendedLocations,
      push,
      onStatusChange,
      onSortOrderChange,
      handleSubmit,
      hasEditStatusPermission,
      hasEditLocationPermission,
    } = this.props;

    const suspendedTableCells = [
      { name: 'CustomerName', label: translate('customers.customerName'), width: '23%', sortable: true },
      { name: 'LocationName', label: translate('common.location'), width: '28%', sortable: true },
      { name: 'ServiceContract', label: translate('common.service'), width: '27%', sortable: true },
      { name: 'status', label: translate('common.status'), width: '14%' },
      {
        name: 'actions',
        label: hasEditLocationPermission && translate('common.options'),
        width: '9%',
        align: 'right',
      },
    ];

    return (
      <PanelSectionGroup>
        <form noValidate onSubmit={handleSubmit}>
          <PanelSection>
            {!!size(suspendedLocations) && (
              <Table
                cells={suspendedTableCells}
                rows={suspendedLocations}
                rowComponent={SuspendedLocationsFormTableRow}
                rowProps={{ push, onStatusChange, hasEditStatusPermission }}
                withClickableRows
                sort={onSortOrderChange}
              />
            )}
          </PanelSection>
          {!size(suspendedLocations) && (
            <Message padding="sMedium">{translate('customers.noSuspendedLocations')}</Message>
          )}

          <Pagination totalResults={total} />
        </form>
      </PanelSectionGroup>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  initialValues: suspendedLocationsInitialValuesSelector(state.customers.suspendedLocations),
  total: (state.customers.suspendedLocations as any).total,
  suspendedLocations: state.customers.suspendedLocations.suspendedLocations as any,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor) as any,
  hasEditStatusPermission: hasPermissionSelector(state.account.permissions, CUSTOMERS_SUSPENDED_LOCATIONS_STATUS),
  hasEditLocationPermission: hasPermissionSelector(state.account.permissions, CUSTOMERS_SUSPENDED_LOCATIONS_EDIT),
});

const mapDispatchToProps = {
  loadSuspendedLocations,
  resetSuspendedLocations,
  push,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, ComponentProps>({
      form: 'suspendedLocations',
      enableReinitialize: true,
    })(SuspendedLocationsForm),
  ),
);
