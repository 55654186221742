import { map } from 'lodash-es';

import translate from '../../core/services/translate';
import { ServiceZone } from '../interfaces/ServiceZones';

export const transformLoadServiceZones = (serviceZones: ServiceZone[]) =>
  map(serviceZones, serviceZone => ({
    ...serviceZone,
    routeTemplateCount: serviceZone.routeTemplateCount || 0,
    isServiceZoneActive: serviceZone.isActive ? translate('common.yes') : translate('common.no'),
  }));

export const transformLoadServiceZone = (serviceZone: ServiceZone) => ({
  ...serviceZone,
  routeTemplateCount: serviceZone.routeTemplateCount || 0,
  isServiceZoneActive: serviceZone.isActive ? translate('common.yes') : translate('common.no'),
});
