import update from 'immutability-helper';
import { Dispatch, AnyAction } from 'redux';

import {
  PreventativeMaintenanceState,
  PreventativeMaintenanceConfiguration,
  PreventativeMaintenanceStatus,
  PreventativeMaintenanceLog,
  PreventativeMaintenanceMilestoneCompletePayload,
  PreventativeMaintenanceRawConfiguration,
} from '../interfaces/PreventativeMaintenance';
import * as Services from '../services/preventativeMaintenance';
import { orderBy } from 'lodash-es';
import { createErrorNotification } from '../../core/services/createNotification';
import translate from '../../core/services/translate';

const START_LOAD = 'fleet/preventativeMaintenance/START_LOAD';
const COMPLETE_LOAD = 'fleet/preventativeMaintenance/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/preventativeMaintenance/FAIL_LOAD';
const START_SAVE = 'fleet/preventativeMaintenance/START_SAVE';
const COMPLETE_SAVE = 'fleet/preventativeMaintenance/COMPLETE_SAVE';
const FAIL_SAVE = 'fleet/preventativeMaintenance/FAIL_SAVE';
const START_LOAD_VEHICLE_PM_STATUS = 'fleet/preventativeMaintenance/START_LOAD_VEHICLE_PM_STATUS';
const COMPLETE_LOAD_VEHICLE_PM_STATUS = 'fleet/preventativeMaintenance/COMPLETE_LOAD_VEHICLE_PM_STATUS';
const FAIL_LOAD_VEHICLE_PM_STATUS = 'fleet/preventativeMaintenance/FAIL_LOAD_VEHICLE_PM_STATUS';
const START_LOAD_VEHICLE_PM_LOGS = 'fleet/preventativeMaintenance/START_LOAD_VEHICLE_PM_LOGS';
const COMPLETE_LOAD_VEHICLE_PM_LOGS = 'fleet/preventativeMaintenance/COMPLETE_LOAD_VEHICLE_PM_LOGS';
const FAIL_LOAD_VEHICLE_PM_LOGS = 'fleet/preventativeMaintenance/FAIL_LOAD_VEHICLE_PM_LOGS';
const START_LOAD_VEHICLE_TYPE_LEVEL_PM = 'fleet/preventativeMaintenance/START_LOAD_VEHICLE_TYPE_LEVEL_PM';
const COMPLETE_LOAD_VEHICLE_TYPE_LEVEL_PM = 'fleet/preventativeMaintenance/COMPLETE_LOAD_VEHICLE_TYPE_LEVEL_PM';
const FAIL_LOAD_VEHICLE_TYPE_LEVEL_PM = 'fleet/preventativeMaintenance/FAIL_LOAD_VEHICLE_TYPE_LEVEL_PM';
const START_VEHICLE_MILESTONE_COMPLETE = 'fleet/preventativeMaintenance/START_VEHICLE_MILESTONE_COMPLETE';
const CLEANUP_VEHICLE_MILESTONE_COMPLETE = 'fleet/preventativeMaintenance/CLEANUP_VEHICLE_MILESTONE_COMPLETE';
const VENDOR_HAS_ACTIVE_PM = 'fleet/preventativeMaintenance/VENDOR_HAS_ACTIVE_PM';
const VENDOR_HAS_NO_ACTIVE_PM = 'fleet/preventativeMaintenance/VENDOR_HAS_NO_ACTIVE_PM';
const VEHICLE_TYPE_HAS_ACTIVE_PM = 'fleet/preventativeMaintenance/VEHICLE_TYPE_HAS_ACTIVE_PM';
const VEHICLE_TYPE_HAS_NO_ACTIVE_PM = 'fleet/preventativeMaintenance/VEHICLE_TYPE_HAS_NO_ACTIVE_PM';
const RESET = 'fleet/preventativeMaintenance/RESET';

const initialState: PreventativeMaintenanceState = {
  configurationLoading: false,
  configurationSaving: false,
  configurationInherited: false,
  vehiclePMStatusLoading: false,
  vehiclePMLogs: [],
  vehiclePMLogsLoading: false,
  vehicleTypeLevelPMLoading: false,
  vehicleMilestoneCompleteIsLoading: false,
  vendorHasActivePM: false,
  vehicleTypeHasActivePM: false,
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          configurationLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: action.configurationIsTopLevel
          ? {
              topLevelConfiguration: action.configuration,
            }
          : {
              configurationLoading: false,
              configuration: action.configuration,
              configurationInherited: action.configurationInherited,
            },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          configurationLoading: false,
          configurationInherited: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          configurationSaving: true,
        },
      });

    case COMPLETE_SAVE:
    case FAIL_SAVE:
      return update(state, {
        $merge: {
          configurationSaving: false,
        },
      });

    case START_LOAD_VEHICLE_PM_STATUS:
      return update(state, {
        $merge: {
          vehiclePMStatusLoading: true,
        },
      });

    case COMPLETE_LOAD_VEHICLE_PM_STATUS:
      return update(state, {
        $merge: {
          vehiclePMStatusLoading: false,
          vehiclePMStatus: action.vehiclePMStatus,
        },
      });

    case FAIL_LOAD_VEHICLE_PM_STATUS:
      return update(state, {
        $merge: {
          vehiclePMStatusLoading: false,
        },
      });

    case START_LOAD_VEHICLE_PM_LOGS:
      return update(state, {
        $merge: {
          vehiclePMLogsLoading: true,
        },
      });

    case COMPLETE_LOAD_VEHICLE_PM_LOGS:
      return update(state, {
        $merge: {
          vehiclePMLogsLoading: false,
          vehiclePMLogs: orderBy(action.vehiclePMLogs, 'completedDate', 'desc'),
        },
      });

    case FAIL_LOAD_VEHICLE_PM_LOGS:
      return update(state, {
        $merge: {
          vehiclePMLogsLoading: false,
        },
      });

    case START_LOAD_VEHICLE_TYPE_LEVEL_PM:
      return update(state, {
        $merge: {
          vehicleTypeLevelPMLoading: true,
        },
      });

    case COMPLETE_LOAD_VEHICLE_TYPE_LEVEL_PM:
      return update(state, {
        $merge: {
          vehicleTypeLevelPMLoading: false,
          vehicleTypeLevelPM: action.vehicleTypeLevelPM,
        },
      });

    case FAIL_LOAD_VEHICLE_TYPE_LEVEL_PM:
      return update(state, {
        $merge: {
          vehicleTypeLevelPMLoading: false,
        },
      });

    case START_VEHICLE_MILESTONE_COMPLETE:
      return update(state, {
        $merge: {
          vehicleMilestoneCompleteIsLoading: true,
        },
      });

    case CLEANUP_VEHICLE_MILESTONE_COMPLETE:
      return update(state, {
        $merge: {
          vehicleMilestoneCompleteIsLoading: false,
        },
      });

    case VENDOR_HAS_ACTIVE_PM:
      return update(state, {
        $merge: {
          vendorHasActivePM: true,
        },
      });

    case VENDOR_HAS_NO_ACTIVE_PM:
      return update(state, {
        $merge: {
          vendorHasActivePM: false,
        },
      });

    case VEHICLE_TYPE_HAS_ACTIVE_PM:
      return update(state, {
        $merge: {
          vehicleTypeHasActivePM: true,
        },
      });

    case VEHICLE_TYPE_HAS_NO_ACTIVE_PM:
      return update(state, {
        $merge: {
          vehicleTypeHasActivePM: false,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (
  { inheritedValues, ...configuration }: PreventativeMaintenanceRawConfiguration,
  configurationIsTopLevel = false,
) => ({
  type: COMPLETE_LOAD,
  configuration,
  configurationInherited: inheritedValues,
  configurationIsTopLevel,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoadVehiclePMStatus = () => ({
  type: START_LOAD_VEHICLE_PM_STATUS,
});

const completeLoadVehiclePMStatus = (vehiclePMStatus: PreventativeMaintenanceStatus) => ({
  type: COMPLETE_LOAD_VEHICLE_PM_STATUS,
  vehiclePMStatus,
});

const failLoadVehiclePMStatus = () => ({
  type: FAIL_LOAD_VEHICLE_PM_STATUS,
});

const startLoadVehiclePMLogs = () => ({
  type: START_LOAD_VEHICLE_PM_LOGS,
});

const completeLoadVehiclePMLogs = (vehiclePMLogs: PreventativeMaintenanceLog[]) => ({
  type: COMPLETE_LOAD_VEHICLE_PM_LOGS,
  vehiclePMLogs,
});

const failLoadVehiclePMLogs = () => ({
  type: FAIL_LOAD_VEHICLE_PM_LOGS,
});

const startLoadVehicleTypeLevelPM = () => ({
  type: START_LOAD_VEHICLE_TYPE_LEVEL_PM,
});

const completeLoadVehicleTypeLevelPM = (vehicleTypeLevelPM: PreventativeMaintenanceConfiguration) => ({
  type: COMPLETE_LOAD_VEHICLE_TYPE_LEVEL_PM,
  vehicleTypeLevelPM,
});

const failLoadVehicleTypeLevelPM = () => ({
  type: FAIL_LOAD_VEHICLE_TYPE_LEVEL_PM,
});

const startVehicleMilestoneComplete = () => ({
  type: START_VEHICLE_MILESTONE_COMPLETE,
});

const cleanupVehicleMilestoneComplete = () => ({
  type: CLEANUP_VEHICLE_MILESTONE_COMPLETE,
});

const markVendorWithActivePM = () => ({
  type: VENDOR_HAS_ACTIVE_PM,
});

const markVendorWithNoActivePM = () => ({
  type: VENDOR_HAS_NO_ACTIVE_PM,
});

const markVehicleTypeWithActivePM = () => ({
  type: VEHICLE_TYPE_HAS_ACTIVE_PM,
});

const markVehicleTypeWithNoActivePM = () => ({
  type: VEHICLE_TYPE_HAS_NO_ACTIVE_PM,
});

export const reset = () => ({
  type: RESET,
});

// Async Actions
export const loadPMConfigurationForVehicleType = (
  vendorId: number,
  vehicleTypeId: number,
  configurationIsTopLevel?: boolean,
) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const promise = Services.loadPMConfigurationForVehicleType(vendorId, vehicleTypeId);

  promise
    .then(configuration => {
      dispatch(completeLoad(configuration, configurationIsTopLevel));
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return promise;
};

export const loadPMConfigurationForVehicle = (vehicleId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const promise = Services.loadPMConfigurationForVehicle(vehicleId);

  promise
    .then(configuration => {
      dispatch(completeLoad(configuration));
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return promise;
};

export const savePMConfigurationForVehicleType = (
  vendorId: number,
  vehicleTypeId: number,
  configuration: PreventativeMaintenanceConfiguration,
) => (dispatch: Dispatch) => {
  dispatch(startSave());

  const promise = Services.savePMConfigurationForVehicleType(vendorId, vehicleTypeId, configuration);

  promise
    .then(() => {
      dispatch(completeSave());
    })
    .catch(err => {
      createErrorNotification(
        err && err.response.data && err.response.data.code
          ? translate(`vehicles.preventativeMaintenanceErrors.${err.response.data.code}`)
          : translate('common.commonErrorSaveMessage'),
      );
      dispatch(failSave());
    });

  return promise;
};

export const savePMConfigurationForVehicle = (
  vehicleId: number,
  configuration: PreventativeMaintenanceConfiguration,
) => (dispatch: Dispatch) => {
  dispatch(startSave());

  const promise = Services.savePMConfigurationForVehicle(vehicleId, configuration);

  promise
    .then(() => {
      dispatch(completeSave());
    })
    .catch(() => {
      dispatch(failSave());
    });

  return promise;
};

export const loadVehiclePMStatus = (vehicleId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadVehiclePMStatus());

  const promise = Services.loadVehiclePMStatus(vehicleId);

  promise
    .then(vehiclePMStatus => {
      dispatch(completeLoadVehiclePMStatus(vehiclePMStatus));
    })
    .catch(() => {
      dispatch(failLoadVehiclePMStatus());
    });

  return promise;
};

export const loadVehiclePMLogs = (vehicleId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadVehiclePMLogs());

  const promise = Services.loadVehiclePMLogs(vehicleId);

  promise
    .then(vehiclePMLogs => {
      dispatch(completeLoadVehiclePMLogs(vehiclePMLogs));
    })
    .catch(() => {
      dispatch(failLoadVehiclePMLogs());
    });

  return promise;
};

export const loadVehicleTypeLevelPM = (vendorId: number, vehicleTypeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadVehicleTypeLevelPM());

  const promise = Services.loadPMConfigurationForVehicleType(vendorId, vehicleTypeId);

  promise
    .then(configuration => {
      dispatch(completeLoadVehicleTypeLevelPM(configuration));
    })
    .catch(() => {
      dispatch(failLoadVehicleTypeLevelPM());
    });

  return promise;
};

export const updateVehiclePM = (
  vendorId: number,
  maintenanceDetails: PreventativeMaintenanceMilestoneCompletePayload,
) => (dispatch: Dispatch) => {
  dispatch(startVehicleMilestoneComplete());

  const promise = Services.updateVehiclePM(vendorId, maintenanceDetails);

  promise.finally(() => {
    dispatch(cleanupVehicleMilestoneComplete());
  });

  return promise;
};

export const checkForActivePM = (vendorId: number, vehicleTypeId?: number) => (dispatch: Dispatch) => {
  const promise = Services.checkForActivePM(vendorId, vehicleTypeId);

  promise
    .then(hasActivePM => {
      if (hasActivePM) {
        dispatch(!!vehicleTypeId ? markVehicleTypeWithActivePM() : markVendorWithActivePM());
      } else {
        dispatch(!!vehicleTypeId ? markVehicleTypeWithNoActivePM() : markVendorWithNoActivePM());
      }
    })
    .catch(() => {
      dispatch(markVendorWithNoActivePM());
    });

  return promise;
};
