import { map } from 'lodash-es';
import { useMemo, useState, MouseEvent } from 'react';

import { ActionButtonTooltip, DragHandle } from 'src/core/components';
import { TableActionButton, TableCell, TableRow, TableRowDraggable, Text } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_SMALLER, TOP } from 'src/core/constants';
import createPopover from 'src/core/services/createPopover';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import { RoutePlannerRouteTemplateVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateVehicle';
import { RoutePlannerRouteVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteVehicle';

interface RoutePlannerVehicleTableRowProps extends RoutePlannerRouteVehicle, RoutePlannerRouteTemplateVehicle {
  onDragStart: (vehicleId: number) => void;
  onDragEnd: () => void;
  onEditAvailability: (event: any, driverId?: number, vehicleId?: number) => void;
  isDaily?: boolean;
  isViewOnlyOrSupport: boolean;
}

const RoutePlannerVehicleTableRow = ({
  vehicleName,
  vehicleId,
  vehicleTypeId,
  routes,
  routesTemplates,
  isDaily,
  isAvailable,
  isViewOnlyOrSupport,
  onDragStart,
  onDragEnd,
  onEditAvailability,
}: RoutePlannerVehicleTableRowProps) => {
  const cellHeight = `${TABLE_ROW_HEIGHT_SMALLER}px`;
  const [isDragging, setIsDragging] = useState(false);
  let closePopover = () => {};

  const routeNamesWithReoccurringIcon = useMemo(
    () =>
      map(routes, (route, i) => (
        <span key={route.routeId}>
          {route.routeName}
          {route.routeTemplateId && <Text size="xLarge"> ↻</Text>} {`${i < routes.length - 1 ? ', ' : ''}`}
        </span>
      )),
    [routes],
  );

  const combinedRouteTemplatesNames = useMemo(
    () => map(routesTemplates, template => template.routeTemplateName).join(', '),
    [routesTemplates],
  );

  const vehicleTypeName = VEHICLE_TYPE_IDS[vehicleTypeId]?.name;

  const routesOrTemplatesCount = isDaily ? routes?.length : routesTemplates?.length;

  const isVehicleAvailable = isDaily ? isAvailable : true;

  const openPopover = (event: MouseEvent<HTMLSpanElement>, content?: any) => {
    event.stopPropagation();
    closePopover = createPopover(
      event.currentTarget,
      Text,
      { size: 'small', color: 'grayDarkest', weight: 'medium', children: content },
      { position: TOP },
    );
  };

  return (
    <TableRow height={TABLE_ROW_HEIGHT_SMALLER} key={`${vehicleId}_${vehicleTypeId}_${vehicleName}`}>
      <TableCell width="5%" align="center" padding="no" minHeight={cellHeight}>
        {isVehicleAvailable && !isViewOnlyOrSupport && (
          <>
            <TableRowDraggable
              draggable
              onDragStart={e => {
                onDragStart(vehicleId);
                setIsDragging(true);
              }}
              onDragEnd={e => {
                onDragEnd();
                setIsDragging(false);
              }}
              display={isDragging ? 'block' : 'inline-block'}
              width={isDragging ? '100%' : '40px'}
            />
            <DragHandle />
          </>
        )}
      </TableCell>
      <TableCell width="75%" vertical align="center" minHeight={cellHeight}>
        <Text
          size="sMedium"
          weight="medium"
          singleLine
          title={`${vehicleName} • ${vehicleTypeName}`}
          color={!isVehicleAvailable ? 'grayDark' : routesOrTemplatesCount > 1 ? 'alert' : 'grayDarkest'}
          fontStyle={!isVehicleAvailable ? 'italic' : 'normal'}
        >
          {vehicleName} •{' '}
          <Text size="small" weight="light">
            {vehicleTypeName}
          </Text>
        </Text>
        {!isDaily && (
          <Text
            color="grayDarker"
            weight="medium"
            size="small"
            singleLine
            onMouseEnter={(e: MouseEvent<HTMLSpanElement>) => openPopover(e, combinedRouteTemplatesNames)}
            onMouseLeave={() => closePopover && closePopover()}
          >
            {combinedRouteTemplatesNames}
          </Text>
        )}
        {isDaily && (
          <Text
            color="grayDarker"
            weight="medium"
            size="small"
            singleLine
            onMouseEnter={(e: MouseEvent<HTMLSpanElement>) => openPopover(e, routeNamesWithReoccurringIcon)}
            onMouseLeave={() => closePopover && closePopover()}
          >
            {routeNamesWithReoccurringIcon}
          </Text>
        )}
      </TableCell>
      <TableCell width="20%" align="right" minHeight={cellHeight}>
        {isDaily && !isViewOnlyOrSupport && (
          <TableActionButton color="grayDarker" onClick={ev => onEditAvailability(ev, undefined, vehicleId)}>
            <ActionButtonTooltip iconSize="small" icon="edit" tooltip="editAvailability" />
          </TableActionButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default RoutePlannerVehicleTableRow;
