import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { loadNavigationSettings } from '../../ducks/navigationSettings';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import NavigationModal from './NavigationModal';

interface Props {
  closeModal: () => void;
  loadNavigationSettings: (vendorId: number) => Promise<any>;
  vendorId?: number;
}

class NavigationModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { loadNavigationSettings, vendorId } = this.props;
    return loadNavigationSettings(vendorId as number);
  };

  render() {
    const { closeModal, vendorId } = this.props;

    return (
      <Resolver
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
        successComponent={NavigationModal}
        successProps={{ closeModal, vendorId }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadNavigationSettings };

export default connect(mapStateToProps, mapDispatchToProps)(NavigationModalResolver);
