import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { NEW_INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';

import { RouteMapFeature, setRouteMapSelectedFeature } from 'src/routes/ducks/mapControls';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { ROUTE_MAP_CLUSTERS_SOURCE, ROUTE_MAP_ROUTE_STOPS_LAYER } from '../layerIds';
import RouteMapRouteStopsGLLayers from './RouteMapRouteStopsGLLayers';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import RouteMapRouteStopsGLPopupResolver from './RouteMapRouteStopsGLPopupResolver';

const mapImages = NEW_INSIGHT_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: ROUTE_MAP_CLUSTERS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

type Props = {
  map: mapboxgl.Map;
  showOrderNumbers?: boolean;
  zoomLevel?: number;
  routeStops?: RouteLocation[];
};

export default function RouteMapRouteStopsGL({ map, showOrderNumbers, zoomLevel, routeStops }: Props) {
  useMapImages(mapImages, map, mapImagesOptions);
  const dispatch = useDispatch();

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === ROUTE_MAP_CLUSTERS_SOURCE && feature.properties?.layer === ROUTE_MAP_ROUTE_STOPS_LAYER,
          );

        if (feature) {
          dispatch(setRouteMapSelectedFeature(RouteMapFeature.routeStops, feature.properties?.id as number));
        }
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <RouteMapRouteStopsGLLayers map={map} showOrderNumbers={showOrderNumbers} zoomLevel={zoomLevel} />
      <RouteMapRouteStopsGLPopupResolver routeStops={routeStops} />
    </>
  );
}
