import { duration } from 'src/utils/services/formatter';
import { RouteDetail, RouteDetailLabel, RouteDetailsWrapper, RouteDetailValue } from '../../styled/RouteDetails';
import { Table } from 'src/core/components';
import { Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import DrivingIncidentsTableRow from './DrivingIncidentTableRow';
import translate from 'src/core/services/translate';

export const drivingIncidentsTableCellWidths = ['25%', '25%', '25%', '25%'];

const drivingIncidentsTableCells = [
  {
    name: 'timestamp',
    label: translate('routes.timestamp'),
    width: drivingIncidentsTableCellWidths[0],
    sortable: true,
  },
  {
    name: 'incidentTypeId',
    label: translate('routes.incidentType'),
    width: drivingIncidentsTableCellWidths[1],
  },
  {
    name: 'durationTime',
    label: translate('common.duration'),
    width: drivingIncidentsTableCellWidths[2],
    sortable: true,
  },
  {
    name: 'locationName',
    label: translate('common.location'),
    width: drivingIncidentsTableCellWidths[3],
  },
];

export default function DrivingIncidentsSection() {
  const { drivingIncidents: drivingIncidentsData } = useSelector(state => state.routes.drivingIncidents);

  if (!drivingIncidentsData) return null;

  return (
    <>
      <RouteDetailsWrapper>
        <RouteDetail>
          <RouteDetailLabel>{translate('dashboard.stationaryTime')}</RouteDetailLabel>
          <RouteDetailValue>
            {duration(drivingIncidentsData.totalStationaryTime, 'seconds', 'hh:mm:ss')}
          </RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('insights.reportTypes.offRouteTime')}</RouteDetailLabel>
          <RouteDetailValue>{duration(drivingIncidentsData.totalOffRouteTime, 'seconds', 'hh:mm:ss')}</RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('dashboard.hardDriving')}</RouteDetailLabel>
          <RouteDetailValue>{drivingIncidentsData.hardDrivingCount}</RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('dashboard.hardBraking')}</RouteDetailLabel>
          <RouteDetailValue>{drivingIncidentsData.hardBrakeCount}</RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('dashboard.hardTurning')}</RouteDetailLabel>
          <RouteDetailValue>{drivingIncidentsData.hardTurnCount}</RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('dashboard.hardAcceleration')}</RouteDetailLabel>
          <RouteDetailValue>{drivingIncidentsData.hardAccelerationCount}</RouteDetailValue>
        </RouteDetail>
      </RouteDetailsWrapper>

      {drivingIncidentsData.drivingIncidents.length ? (
        <Table
          cells={drivingIncidentsTableCells}
          rows={drivingIncidentsData.drivingIncidents}
          rowComponent={DrivingIncidentsTableRow}
          sortedBy="timestamp"
        />
      ) : (
        <Text block align="center">
          {translate('routes.noDrivingIncidents')}
        </Text>
      )}
    </>
  );
}
