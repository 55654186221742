import React, { ChangeEvent } from 'react';

import { map } from 'lodash-es';
import { RouteComponentProps } from 'react-router';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { MultiSelect, TypedField } from 'src/core/components';
import translate from 'src/core/services/translate';
import { accountStatusFormatText } from 'src/customers/services/accountStatusFormatText';
import { ACCOUNT_STATUSES } from 'src/common/constants/accountStatuses';
import { StatusIds } from 'src/customers/interfaces/Services';
import { MultiSelectOption } from 'src/core/components/MultiSelect';

interface ComponentProps extends RouteComponentProps {
  filter: (e: ChangeEvent<HTMLInputElement>, serviceStatusFilters: StatusIds[]) => void;
}

interface FormProps {
  serviceStatusTypeIds: StatusIds[];
}

type Props = ComponentProps & InjectedFormProps<FormProps, ComponentProps>;

const CustomerLocationServiceFilterForm = (props: Props) => {
  const accountStatusOptions: MultiSelectOption[] = map(ACCOUNT_STATUSES, accountStatus => ({
    label: accountStatus.name,
    value: accountStatus.id,
  }));

  return (
    <form onSubmit={props.handleSubmit}>
      <TypedField
        component={MultiSelect}
        name={'serviceStatusTypeIds'}
        onChange={props.filter}
        props={{
          defaultToAll: false,
          formatText: accountStatusFormatText,
          margin: 'xxSmall no no small',
          normalizeValues: Number,
          options: accountStatusOptions,
          placeholder: translate('common.status'),
        }}
      />
    </form>
  );
};

export default reduxForm<FormProps, ComponentProps>({
  enableReinitialize: true,
  form: 'customerLocationServiceFilterForm',
})(CustomerLocationServiceFilterForm);
