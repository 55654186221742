import { isEmpty } from 'lodash-es';
import { TODAY_END_OF_DAY } from 'src/core/constants';
import { getItem, removeItem, setItem } from 'src/core/services/persistentStorage';

export const ROUTE_PLANNER_FILTER_PERSISTENT_STORAGE_KEY = 'routePlannerFilters';

const FILTER_EXPIRATION = {
  completed: TODAY_END_OF_DAY,
};

export const getRoutePlannerPersistentFilters = () => {
  let filters = JSON.parse(getItem(ROUTE_PLANNER_FILTER_PERSISTENT_STORAGE_KEY) || '{}');
  if (isEmpty(filters)) {
    removeItem(ROUTE_PLANNER_FILTER_PERSISTENT_STORAGE_KEY);
    filters = {};
  }
  return filters;
};

export const setRoutePlannerPersistentFilters = (filters: any) => {
  setItem(ROUTE_PLANNER_FILTER_PERSISTENT_STORAGE_KEY, JSON.stringify(filters), FILTER_EXPIRATION.completed as any);
};

export const updateRoutePlannerPersistentFiltersByKey = (vendorId: number, key: any, value: any) => {
  const filters = getRoutePlannerPersistentFilters();
  if (Object.prototype.hasOwnProperty.call(filters, key)) {
    filters[key] = value;
    setRoutePlannerPersistentFilters(filters);
  }
};
