import React from 'react';
import { Clusterer } from '@react-google-maps/marker-clusterer';

import VendorLocationMarker from './VendorLocationMarker';
import { RubiconMarkerClusterer } from './RubiconMarkerClusterer';
import { OpenedInfoWindows } from '../interfaces/OpenedInfoWindows';

interface Props {
  vendorLocations: any[];
  openedInfoWindows: OpenedInfoWindows;
  toggleInfoWindow: (key: string) => void;
}

const VendorLocations: React.SFC<Props> = ({ vendorLocations, openedInfoWindows, toggleInfoWindow }) => (
  <RubiconMarkerClusterer>
    {(clusterer: Clusterer) => (
      <>
        {vendorLocations.map(vendorLocation => (
          <VendorLocationMarker
            key={vendorLocation.id}
            vendorLocation={vendorLocation}
            isInfoWindowOpen={openedInfoWindows.vendorLocations[vendorLocation.id]}
            toggleInfoWindow={toggleInfoWindow}
            clusterer={clusterer}
          />
        ))}
      </>
    )}
  </RubiconMarkerClusterer>
);

export default VendorLocations;
