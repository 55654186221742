import humps from 'humps';
import update from 'immutability-helper';
import { camelCase, findIndex, get, identity, includes, reduce, some, sortBy } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { ACTIVE } from '../../common/constants';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';
import { DEFAULT_FILTERS } from '../constants';
import { CityInsightType } from '../interfaces/cityInsightTypes';
import { FiltersState } from '../interfaces/filters';
import doLoadCityInsightTypes from '../services/loadCityInsightTypes';
import doLoadPickupExceptionTypes from '../services/loadPickupExceptionTypes';
import doLoadVehicles from '../services/loadVehicles';

// Actions
const START_LOAD_VEHICLES = 'dashboard/filters/START_LOAD_VEHICLES';
export const COMPLETE_LOAD_VEHICLES = 'dashboard/filters/COMPLETE_LOAD_VEHICLES';
const FAIL_LOAD_VEHICLES = 'dashboard/filters/FAIL_LOAD_VEHICLES';
const START_LOAD_CITY_INSIGHT_TYPES = 'dashboard/filters/START_LOAD_CITY_INSIGHT_TYPES';
export const COMPLETE_LOAD_CITY_INSIGHT_TYPES = 'dashboard/filters/COMPLETE_LOAD_CITY_INSIGHT_TYPES';
const FAIL_LOAD_CITY_INSIGHT_TYPES = 'dashboard/filters/FAIL_LOAD_CITY_INSIGHT_TYPES';
const START_LOAD_PICKUP_EXCEPTION_TYPES = 'dashboard/filters/START_LOAD_PICKUP_EXCEPTION_TYPES';
export const COMPLETE_LOAD_PICKUP_EXCEPTION_TYPES = 'dashboard/filters/COMPLETE_LOAD_PICKUP_EXCEPTION_TYPES';
const FAIL_LOAD_PICKUP_EXCEPTION_TYPES = 'dashboard/filters/FAIL_LOAD_PICKUP_EXCEPTION_TYPES';
const FILTER_PICKUP_EXCEPTION_TYPES = 'dashboard/filters/FILTER_PICKUP_EXCEPTION_TYPES';
const RESET = 'dashboard/filters/RESET';

// Initial state
const initialState: FiltersState = {
  cityInsightActiveTypes: [],
  filters: DEFAULT_FILTERS,
  isLoadingCityInsightTypes: false,
  isLoadingPickupExceptionTypes: false,
  isLoadingVehicles: false,
  pickupExceptionTypes: undefined,
};

// Reducer helpers
const createVehicleFilters = (vehiclesByType: any[]) =>
  vehiclesByType.map(vehiclesForType => {
    const {
      vehicleType: { technicalName },
      vehicles,
    } = vehiclesForType;
    return {
      label: createTranslationKey(technicalName, 'vehicles.vehicleTypes'),
      unCheckedByDefault: true,
      formName: `vehicleTypes.filters.${technicalName}`,
      canCheckAll: true,
      checkAllFormName: `all${humps.pascalize(technicalName)}Vehicles`,
      filters: vehicles.map((vehicle: any) => ({
        label: vehicle.name,
        formName: `vehicles.filters.${humps.camelize(technicalName)}:${vehicle.id}`,
        isDisabled: vehicle.status !== ACTIVE,
      })),
    };
  });

const createCityInsightTypeFilters = (cityInsightTypes: any[]) =>
  cityInsightTypes.map(cityInsightType => ({
    label: createTranslationKey(cityInsightType.technicalName, 'dashboard'),
    formName: `cityInsightTypes.filters.${cityInsightType.technicalName}`,
  }));

const createPickupExceptionTypesilters = (pickupExceptionTypes: any[]) =>
  pickupExceptionTypes.map(pickupExceptionType => ({
    label: translate(`routes.pickupIssueTypes.${camelCase(pickupExceptionType.technicalName)}`),
    formName: `containerServiceInsightTypes.issuesReportedFilters.${pickupExceptionType.id}`,
  }));

const filterPickupExceptions = (pickupExceptionTypes: any[] = [], selectedVehicleIds: number[]) =>
  reduce(
    sortBy(pickupExceptionTypes, 'name'),
    (filteredResults, exceptionType) => {
      const exeptionTypes = filteredResults as any[];
      if (some(exceptionType.vehicleTypeIds, vehicleId => includes(selectedVehicleIds, vehicleId))) {
        exeptionTypes.push({
          label: translate(`routes.pickupIssueTypes.${camelCase(exceptionType.technicalName)}`),
          formName: `containerServiceInsightTypes.issuesReportedFilters.${exceptionType.id}`,
        });
      }
      return exeptionTypes;
    },
    [] as any[],
  );

const dashboardStationary = {
  label: 'dashboard.stationary',
  formName: 'vehicleInsightTypes.filters.stationary',
};

const dashboardSpeeding = {
  label: 'dashboard.speeding',
  formName: 'vehicleInsightTypes.filters.speeding',
};

const dashboardOutsideGeoFence = {
  label: 'dashboard.outsideGeoFence',
  formName: 'vehicleInsightTypes.filters.outsidegeofence',
};

const dashboardOffRoute = {
  label: 'dashboard.offRoute',
  formName: 'vehicleInsightTypes.filters.offroute',
};

const dashboardOffRouteStationary = {
  label: 'dashboard.offRouteStationary',
  formName: 'vehicleInsightTypes.filters.offroutestationary',
};

const dashboardInsideGeoFence = {
  label: 'dashboard.insideGeoFence',
  formName: 'vehicleInsightTypes.filters.insidegeofence',
};

const dashboardHardDriving = {
  label: 'dashboard.hardDriving',
  formName: 'vehicleInsightTypes.hardDriving',
  canCheckAll: true,
  checkAllFormName: 'allHardDrivingVehicleInsigthTypes',
  hasSubFilters: true,
  filters: [
    {
      label: 'dashboard.hardAcceleration',
      formName: 'vehicleInsightTypes.hardDrivingFilters.hardAcceleration',
    },
    {
      label: 'dashboard.hardBraking',
      formName: 'vehicleInsightTypes.hardDrivingFilters.hardBraking',
    },
    {
      label: 'dashboard.hardTurning',
      formName: 'vehicleInsightTypes.hardDrivingFilters.hardTurning',
    },
  ],
};

const defaultVehicleInsightTypes = [dashboardStationary, dashboardSpeeding, dashboardHardDriving];
const defaultVehicleInsightTypesWithGeoFence = [
  dashboardStationary,
  dashboardSpeeding,
  dashboardOffRoute,
  dashboardOffRouteStationary,
  dashboardOutsideGeoFence,
  dashboardInsideGeoFence,
  dashboardHardDriving,
];

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_VEHICLES:
      return update(state, {
        $merge: {
          isLoadingVehicles: true,
        },
      });

    case COMPLETE_LOAD_VEHICLES:
      return update(state, {
        isLoadingVehicles: { $set: false },
        filters: {
          vehicles: { filters: { $set: createVehicleFilters(action.vehicles) } },
          vehicleInsightTypes: {
            filters: {
              $set: action.isGeoFenceActive ? defaultVehicleInsightTypesWithGeoFence : defaultVehicleInsightTypes,
            },
          },
        },
      } as any);

    case FAIL_LOAD_VEHICLES:
      return update(state, {
        isLoadingVehicles: { $set: false },
        filters: {
          vehicles: { filters: { $set: [] } },
        },
      });

    case START_LOAD_CITY_INSIGHT_TYPES:
      return update(state, {
        $merge: {
          isLoadingCityInsightTypes: true,
        },
      });

    case COMPLETE_LOAD_CITY_INSIGHT_TYPES:
      return update(state, {
        isLoadingCityInsightTypes: { $set: false },
        filters: {
          cityInsightTypes: { filters: { $set: createCityInsightTypeFilters(action.cityInsightTypes) } },
        },
        cityInsightActiveTypes: { $set: action.cityInsightTypes },
      } as any);

    case FAIL_LOAD_CITY_INSIGHT_TYPES:
      return update(state, {
        isLoadingCityInsightTypes: { $set: false },
        filters: {
          pickupExceptionTypes: { filters: { $set: [] } },
        },
        cityInsightActiveTypes: { $set: [] },
      } as any);

    case START_LOAD_PICKUP_EXCEPTION_TYPES:
      return update(state, {
        $merge: {
          isLoadingPickupExceptionTypes: true,
        },
      });

    case COMPLETE_LOAD_PICKUP_EXCEPTION_TYPES: {
      const index = findIndex(state.filters.containerInsightTypes.filters, {
        label: 'dashboard.issuesReported',
      });

      return update(state, {
        isLoadingPickupExceptionTypes: { $set: false },
        pickupExceptionTypes: { $set: action.pickupExceptionTypes },
        filters: {
          containerInsightTypes: {
            filters: { [index]: { filters: { $set: createPickupExceptionTypesilters(action.pickupExceptionTypes) } } },
          },
        },
      } as any);
    }

    case FAIL_LOAD_PICKUP_EXCEPTION_TYPES:
      return update(state, {
        isLoadingPickupExceptionTypes: { $set: false },
        filters: {
          pickupExceptionTypes: { filters: { $set: [] } },
        },
      } as any);

    case FILTER_PICKUP_EXCEPTION_TYPES: {
      const index = findIndex(state.filters.containerInsightTypes.filters, {
        label: 'dashboard.issuesReported',
      });

      return update(state, {
        isLoadingPickupExceptionTypes: { $set: false },
        pickupExceptionType: { $set: action.pickupExceptionTypes },
        filters: {
          containerInsightTypes: {
            filters: {
              [index]: {
                filters: { $set: filterPickupExceptions(state.pickupExceptionTypes, action.vehicleIds) },
              },
            },
          },
        },
      } as any);
    }

    case RESET:
      return update(state, {
        $merge: {
          isLoadingVehicles: false,
          isLoadingCityInsightTypes: false,
          filters: DEFAULT_FILTERS,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoadVehicles = () => ({
  type: START_LOAD_VEHICLES,
});

const completeLoadVehicles = (vehicles: any, isGeoFenceActive?: boolean) => ({
  type: COMPLETE_LOAD_VEHICLES,
  vehicles,
  isGeoFenceActive,
});

const failLoadVehicles = () => ({
  type: FAIL_LOAD_VEHICLES,
});

export const loadVehicles =
  (vendorId: number, date: Date | string, isGeoFenceActive?: boolean, vehicleTypes?: string[]) =>
  (dispatch: Dispatch) => {
    dispatch(startLoadVehicles());
    return doLoadVehicles(vendorId, date, vehicleTypes?.join(','))
      .then(vehicles => dispatch(completeLoadVehicles(vehicles, isGeoFenceActive)))
      .catch(() => dispatch(failLoadVehicles()));
  };

const startLoadCityInsightTypes = () => ({
  type: START_LOAD_CITY_INSIGHT_TYPES,
});

const completeLoadCityInsightTypes = (cityInsightTypes: CityInsightType[]) => ({
  type: COMPLETE_LOAD_CITY_INSIGHT_TYPES,
  cityInsightTypes,
});

const failLoadCityInsightTypes = () => ({
  type: FAIL_LOAD_CITY_INSIGHT_TYPES,
});

// Action creators
const startLoadPickupExceptionTypes = () => ({
  type: START_LOAD_PICKUP_EXCEPTION_TYPES,
});

const completeLoadPickupExceptionTypes = (pickupExceptionTypes: any) => ({
  type: COMPLETE_LOAD_PICKUP_EXCEPTION_TYPES,
  pickupExceptionTypes,
});

const failLoadPickupExceptionTypes = () => ({
  type: FAIL_LOAD_PICKUP_EXCEPTION_TYPES,
});

const filterPickupExceptionTypes = (vehicleIds: number[]) => ({
  type: FILTER_PICKUP_EXCEPTION_TYPES,
  vehicleIds,
});

export const loadCityInsightTypes = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadCityInsightTypes());

  return doLoadCityInsightTypes(vendorId)
    .then(cityInsightTypes => {
      dispatch(completeLoadCityInsightTypes(cityInsightTypes));

      return cityInsightTypes || ([] as any);
    })
    .catch(() => {
      dispatch(failLoadCityInsightTypes());

      return [] as any;
    });
};

export const loadPickupExceptionTypes = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadPickupExceptionTypes());
  return doLoadPickupExceptionTypes(vendorId)
    .then(pickupExceptionTypes => dispatch(completeLoadPickupExceptionTypes(pickupExceptionTypes)))
    .catch(() => dispatch(failLoadPickupExceptionTypes()));
};

export const filterPickupExceptionTypesByVehicle = (vehicleIds: number[]) => (dispatch: Dispatch) =>
  dispatch(filterPickupExceptionTypes(vehicleIds));

export const resetFilters = () => ({
  type: RESET,
});

// Selectors
const getFiltersByVehicleSearchTerm = (filters: any, dashboardFilterFormValues: any) => {
  const searchKeyword = get(dashboardFilterFormValues, 'vehicleSearchTerm');

  if (!searchKeyword) {
    return filters;
  }

  const vehicleFilters = filters.vehicles.filters.map((filtersForVehicleType: any) => ({
    ...filtersForVehicleType,
    filters: filtersForVehicleType.filters.filter(
      (vehicleFilter: any) =>
        vehicleFilter.label.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 ||
        get(dashboardFilterFormValues, vehicleFilter.formName),
    ),
  }));

  return {
    ...filters,
    vehicles: {
      ...filters.vehicles,
      filters: vehicleFilters,
    },
  };
};

export const filtersByVehicleSearchTermSelector = createSelector(getFiltersByVehicleSearchTerm, identity);
