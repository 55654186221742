import { PureComponent } from 'react';

import { connect } from 'react-redux';
import { get, map, size } from 'lodash-es';
import { WrappedFieldProps } from 'redux-form';
import DayPicker, { DateUtils } from 'react-day-picker';
import moment, { DurationInputArg2 } from 'moment';

import { AppState } from 'src/store';
import { CALENDAR_INPUT_LENGTH } from '../constants/calendar';
import { CALENDAR_WEEKDAYS, MONTHS } from '../../common/constants';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { DATE_RANGE_PICKER_TODAY_7_30 } from '../../core/constants/weekdays';
import {
  DatePicker as DatePickerContainer,
  DateRangePickerOption,
  DateRangePickerOptions,
  DateRangePickerOverlay,
  FormError,
  FormGroup,
  FormGroupClear,
  FormGroupClearContainer,
  FormLabel,
  Input,
} from './styled';
import { DataRetention } from 'src/vendors/interfaces/DataRetention';
import { DuckFunction } from 'src/contracts/ducks';
import { loadDataRetention } from 'src/vendors/ducks';
import { MAX_SEARCHABLE_MONTHS } from '../../core/constants';
import { MultipleInputWrapper } from './styled/Input';
import { onCalendarInputKeyDown } from '../services/calendar';
import translate from '../services/translate';

const DayPickerMock = DayPicker as any;

export const getMaxSearchableMonths = (response: DataRetention[]) => {
  const selecteDataRetention = response.find((data: DataRetention) => data.isActive);
  const numberOfMonthsInYear = 12;
  const dataRetentionValue =
    selecteDataRetention?.timeMeasurementType?.technicalName === 'Years'
      ? (selecteDataRetention?.dataRetentionValue || 1) * numberOfMonthsInYear
      : selecteDataRetention?.dataRetentionValue;

  return dataRetentionValue || 0;
};

export interface DateRangeOptionValue {
  from: Date;
  to: Date;
}

export interface DateRangeOption {
  value: DateRangeOptionValue;
}

export interface Props extends WrappedFieldProps {
  alignRight?: boolean;
  availableDates?: string[] | Date[];
  dateFilterBlur?: () => void;
  dateFilterFocus?: () => void;
  disabled?: boolean;
  disabledDays?: any[];
  fixedInputWidth?: boolean;
  hasMarginLeft?: string;
  isClearable?: boolean;
  isResetRangeVisible?: boolean;
  label?: string;
  loadDataRetention: DuckFunction<typeof loadDataRetention>;
  margin?: string;
  middleLineHasDifferentMargin?: boolean;
  maxInterval?: {
    /**
     * TODO
     * Awesome typing, but it could generate some errors.
     * Will generate tech debt ticket just for this.
     */
    // amount: Exclude<DurationInputArg1, null | undefined>;

    amount: number;
    unit: DurationInputArg2;
  };
  meta: any;
  name?: string;
  numberOfMonths?: number;
  options?: any[];
  placeholder?: string;
  tabletAlignHalfLeft?: boolean;
  tabletAlignLeft?: boolean;
  totalWidth?: string;
  vendorId?: number;
  width?: string;
  getUTCDate?: boolean;
}

interface State {
  currentMonth?: Date | string;
  dateRangeOptions: any[];
  dateRangeOptionsDisabledDays: any[];
  enteredTo?: Date;
  focus?: boolean;
  from?: Date;
  inputValue: any;
  isOverlayOpen: boolean;
  maxSearchableMonths: number;
  to?: Date;
}

class DateRangePicker extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.getInitialState(props),
      maxSearchableMonths: MAX_SEARCHABLE_MONTHS,
    };
  }

  componentDidMount() {
    const { loadDataRetention, vendorId } = this.props;

    const setMaxSearchableMonths = (response: DataRetention[]) => {
      const dataRetentionValue = getMaxSearchableMonths(response);
      this.setState({ maxSearchableMonths: dataRetentionValue });
    };

    vendorId &&
      loadDataRetention().then(response => {
        setMaxSearchableMonths(response);
      });
  }

  getInitialState(props: Props) {
    const from = get(props.input.value, 'from');
    const to = get(props.input.value, 'to');
    const fromAsDate = from && moment(from, 'MM/DD/YYYY').toDate();
    const toAsDate = to && moment(to, 'MM/DD/YYYY').toDate();

    return {
      currentMonth: this.props.input.value,
      dateRangeOptions: props.options || DATE_RANGE_PICKER_TODAY_7_30,
      dateRangeOptionsDisabledDays: props.disabledDays || [],
      enteredTo: toAsDate,
      focus: false,
      from: fromAsDate,
      inputValue: this.props.input.value,
      isOverlayOpen: false,
      to: toAsDate,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.input.value !== prevProps.input.value) {
      this.setState(this.getInitialState(this.props));
    }
  }

  onDayClick = (day: Date) => {
    const { maxInterval, input, getUTCDate } = this.props;
    const { dateRangeOptionsDisabledDays, from, to, maxSearchableMonths } = this.state;

    if (
      (size(dateRangeOptionsDisabledDays) &&
        ((dateRangeOptionsDisabledDays[0].before &&
          moment(day).endOf('day') < moment(dateRangeOptionsDisabledDays[0].before).startOf('day')) ||
          (dateRangeOptionsDisabledDays[0].after &&
            moment(day).endOf('day') > moment(dateRangeOptionsDisabledDays[0].after).endOf('day')))) ||
      (size(dateRangeOptionsDisabledDays) &&
        !dateRangeOptionsDisabledDays[0].before &&
        moment(day).endOf('day') < moment().subtract(maxSearchableMonths, 'months').startOf('day')) ||
      (!size(dateRangeOptionsDisabledDays) &&
        moment(day).endOf('day') < moment().subtract(maxSearchableMonths, 'months').startOf('day'))
    ) {
      return;
    }
    // Keep it for possible future use
    // if (from && to && day >= from && day <= to) {
    //   this.setState({
    //     from: undefined,
    //     to: undefined,
    //     enteredTo: undefined,
    //   });

    //   return;
    // }

    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: undefined,
        enteredTo: undefined,
      });

      return;
    }

    this.setState({
      to: day,
      enteredTo: day,
      isOverlayOpen: false,
    });

    if (maxInterval && moment(day).diff(moment(from), 'days') > maxInterval.amount) {
      input.onChange({
        from: (getUTCDate ? moment(from).utc() : moment(from)).format('MM/DD/YYYY'),
        to: (getUTCDate ? moment(from).utc() : moment(from)).add(maxInterval.amount, maxInterval.unit).format('MM/DD/YYYY'),
      });
    } else {
      input.onChange({
        from: (getUTCDate ? moment(from).utc() : moment(from)).format('MM/DD/YYYY'),
        to: (getUTCDate ? moment(day).utc() : moment(day)).format('MM/DD/YYYY'),
      });
    }
  };

  onDayMouseEnter = (day: Date) => {
    const { maxInterval } = this.props;
    const { from, to } = this.state;

    if (
      maxInterval &&
      !moment(day).isBetween(moment(from), moment(from).add(maxInterval.amount, maxInterval.unit).add(1, 'days'))
    ) {
      return;
    }

    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      });
    }
  };

  onOptionClick = ({ value: { from, to } }: DateRangeOption) => {
    this.setState({
      from,
      to,
      enteredTo: to,
      isOverlayOpen: false,
    });
    const { input, getUTCDate } = this.props;

    const date = {
      from: (getUTCDate ? moment(from).utc() : moment(from)).format('MM/DD/YYYY'),
      to: (getUTCDate ? moment(to).utc() : moment(to)).format('MM/DD/YYYY'),
    };

    input.onChange(date);
    input.onBlur(date);
  };

  onOverlayMouseDown = (event: any) => {
    event.preventDefault();
  };

  openOverlay = () => {
    const { dateFilterFocus } = this.props;

    if (dateFilterFocus) dateFilterFocus();
    this.setState({ isOverlayOpen: true });
  };

  clearInput = () => {
    this.setState({ inputValue: '', currentMonth: new Date(moment().year(), moment().month()) });
    this.props.input.onChange(null);
  };

  resetState = () => {
    const { dateFilterBlur } = this.props;

    if (dateFilterBlur) dateFilterBlur();
    this.setState(this.getInitialState(this.props));
  };

  isSelectingFirstDay = (from?: Date, to?: Date, day?: Date) => {
    const isBeforeFirstDay = from && day && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  isActiveOption = (option: any) => {
    const { from, to } = this.state;
    if (!from || !to) return false;

    return moment(from).isSame(option.value.from, 'day') && moment(to).isSame(option.value.to, 'day');
  };

  handleFromInputChange = (value: string) => {
    const {
      maxInterval,
      input: {
        value: { to },
      },
      getUTCDate,
    } = this.props;
    const { dateRangeOptionsDisabledDays, maxSearchableMonths } = this.state;

    const currentDate = getUTCDate
      ? moment(moment().toDate()).utc().format('MM/DD/YYYY')
      : moment(moment().toDate()).format('MM/DD/YYYY')
    const intervalDiff = maxInterval && moment(to).diff(moment(value), maxInterval.unit) > maxInterval.amount;

    const dateRangeOptionsDisabledDaysBefore =
      dateRangeOptionsDisabledDays.length && dateRangeOptionsDisabledDays.length > 1
        ? !!dateRangeOptionsDisabledDays[0].before
          ? dateRangeOptionsDisabledDays[0].before
          : dateRangeOptionsDisabledDays[1].before
        : dateRangeOptionsDisabledDays[0].before;
    const daysBefore = getUTCDate
      ? moment(dateRangeOptionsDisabledDaysBefore).utc()
      : moment(dateRangeOptionsDisabledDaysBefore);
    const diff2 = dateRangeOptionsDisabledDaysBefore
      ? moment(daysBefore.format('MM/DD/YYYY')).diff(moment(value), 'days') >= 1
      : moment(currentDate).diff(moment(value), 'months') > maxSearchableMonths;

    this.setState({ inputValue: { from: value, to } });
    const fromDay = (getUTCDate ? moment(value).utc() : moment(value)).format('MM/DD/YYYY');
    const toDay = (getUTCDate ? moment(to).utc() : moment(to)).format('MM/DD/YYYY');
    const diff = moment.duration(moment(to).diff(moment(value))).asDays();
    if (value.length === CALENDAR_INPUT_LENGTH && diff) {
      if (diff >= 0 && !diff2 && !intervalDiff) {
        this.props.input.onChange({
          from: fromDay,
          to: toDay,
        });
      } else {
        this.setState({ inputValue: { from: currentDate, to: currentDate } });
        this.props.input.onChange({
          from: dateRangeOptionsDisabledDays.length > 0 && moment().isAfter(toDay) ? toDay : currentDate,
          to: dateRangeOptionsDisabledDays.length > 0 && moment().isAfter(toDay) ? toDay : currentDate,
        });
      }
    }
  };

  handleToInputChange = (value: string) => {
    const {
      maxInterval,
      input: {
        value: { from },
      },
      getUTCDate,
    } = this.props;
    const {
      dateRangeOptionsDisabledDays,
      maxSearchableMonths,
      inputValue,
    } = this.state;

    const currentDate = moment(moment().toDate()).utc().format('MM/DD/YYYY');
    const intervalDiff = maxInterval && moment(value).diff(moment(from), maxInterval.unit) > maxInterval.amount;

    this.setState({ inputValue: { from: inputValue.from, to: value } });
    const fromDay = (getUTCDate ? moment(inputValue.from).utc() : moment(inputValue.from)).format('MM/DD/YYYY');
    const toDay = (getUTCDate ? moment(value).utc() : moment(value)).format('MM/DD/YYYY');
    const diff = moment.duration(moment(value).diff(moment(inputValue.from))).asDays();

    if (value.length === CALENDAR_INPUT_LENGTH && diff) {
      const disabledDayAfter = getUTCDate
        ? moment(dateRangeOptionsDisabledDays[0].after).utc()
        : moment(dateRangeOptionsDisabledDays[0].after);
      const diff2 =
        dateRangeOptionsDisabledDays.length && dateRangeOptionsDisabledDays[0].after
          ? moment(disabledDayAfter.format('MM/DD/YYYY')).diff(moment(value), 'days') < 0
          : Math.abs(moment(currentDate).diff(moment(value), 'months')) > maxSearchableMonths;
      if (diff >= 0 && !diff2 && !intervalDiff) {
        this.props.input.onChange({
          from: fromDay,
          to: toDay,
        });
      } else {
        this.props.input.onChange({
          from: dateRangeOptionsDisabledDays.length > 0 && moment().isAfter(toDay) ? fromDay : currentDate,
          to: dateRangeOptionsDisabledDays.length > 0 && moment().isAfter(toDay) ? fromDay : currentDate,
        });
      }
    }
  };

  resetSelections = (event: any) => {
    event.preventDefault();

    this.setState({
      from: undefined,
      to: undefined,
      enteredTo: undefined,
    });
  };

  render() {
    const {
      alignRight,
      availableDates,
      disabled,
      fixedInputWidth,
      hasMarginLeft,
      input,
      isClearable,
      isResetRangeVisible,
      label,
      margin,
      maxInterval,
      meta: { submitFailed, error },
      middleLineHasDifferentMargin,
      name,
      numberOfMonths,
      placeholder,
      tabletAlignHalfLeft,
      tabletAlignLeft,
      totalWidth,
      width,
    } = this.props;

    const {
      dateRangeOptions,
      dateRangeOptionsDisabledDays,
      enteredTo,
      focus,
      from,
      inputValue,
      isOverlayOpen,
      maxSearchableMonths,
    } = this.state;

    const modifiers = { start: from, end: enteredTo };
    const selectedDays = [from, { from, to: enteredTo }];
    const value = input.value && input.value.from && input.value.to ? `${input.value.from} - ${input.value.to}` : '';
    const months = map(MONTHS, month => translate(month.translationKey));
    const weekdaysShort = map(CALENDAR_WEEKDAYS, day => translate(day.translationKey));

    const inputLineSize =
      hasMarginLeft === 'normal'
        ? 'normalLineInput'
        : hasMarginLeft === 'small'
        ? 'smallLineInput'
        : hasMarginLeft === 'smaller' && !middleLineHasDifferentMargin
        ? 'smallerLineInput'
        : hasMarginLeft === 'smaller' && middleLineHasDifferentMargin
        ? 'hasSize'
        : '';

    const getIsDayDisabled = (day: Date) => {
      const isDateAvailable = !availableDates?.some(disabledDay =>
        DateUtils.isSameDay(day, moment(disabledDay).toDate()),
      );
      return isDateAvailable;
    };

    return (
      <FormGroup hasValue={value} margin={margin} width={width}>
        {!!label && <FormLabel>{label}</FormLabel>}

        {isClearable && !!inputValue && (
          <FormGroupClearContainer>
            <FormGroupClear disabled={disabled} onClick={this.clearInput} />
          </FormGroupClearContainer>
        )}

        <MultipleInputWrapper
          width={totalWidth ? totalWidth : hasMarginLeft === 'smaller' ? 225 : ''}
          onClick={this.openOverlay}
          onBlur={this.resetState}
        >
          <Input
            maxLength={CALENDAR_INPUT_LENGTH}
            disabled={disabled}
            name={name}
            placeholder={placeholder}
            focus={focus}
            onClick={() => {
              this.setState({ focus: true });
            }}
            onChange={e => {
              this.handleFromInputChange(e.target.value);
            }}
            value={inputValue ? inputValue.from : ''}
            onKeyDown={onCalendarInputKeyDown}
            inputWidth={fixedInputWidth ? '80px' : undefined}
          />

          {!!inputValue && (
            <Input
              onChange={e => e.preventDefault()}
              disabled={disabled}
              hasMarginLeft={inputLineSize}
              name={name}
              placeholder={placeholder}
              focus={focus}
              onClick={() => {
                this.setState({ focus: true });
              }}
              value={'-'}
              fixedInputWidth={fixedInputWidth}
            />
          )}

          <Input
            maxLength={CALENDAR_INPUT_LENGTH}
            disabled={disabled}
            hasMarginLeft={hasMarginLeft}
            focus={focus}
            onClick={() => this.setState({ focus: true })}
            onChange={e => {
              this.handleToInputChange(e.target.value);
            }}
            onKeyDown={onCalendarInputKeyDown}
            withSelectStyle
            value={inputValue ? inputValue.to : ''}
            inputWidth={fixedInputWidth ? '120px' : undefined}
            noMarginLeft={fixedInputWidth}
          />
        </MultipleInputWrapper>

        {isOverlayOpen && !disabled && (
          <DateRangePickerOverlay
            numberOfMonths={numberOfMonths}
            alignRight={alignRight}
            onMouseDown={this.onOverlayMouseDown}
            tabletAlignHalfLeft={tabletAlignHalfLeft}
            tabletAlignLeft={tabletAlignLeft}
          >
            <DateRangePickerOptions>
              {dateRangeOptions.map(option => (
                <DateRangePickerOption
                  key={option.label}
                  onClick={() => this.onOptionClick(option)}
                  isActive={this.isActiveOption(option)}
                >
                  {option.label}
                </DateRangePickerOption>
              ))}
              {isResetRangeVisible && (
                <DateRangePickerOption onClick={this.resetSelections} last>
                  {translate('common.reset')}
                </DateRangePickerOption>
              )}
            </DateRangePickerOptions>

            <DatePickerContainer isDateRangePicker={true} numberOfMonths={numberOfMonths}>
              <DayPickerMock
                numberOfMonths={numberOfMonths || 2}
                month={from}
                onDayClick={this.onDayClick}
                onDayMouseEnter={this.onDayMouseEnter}
                selectedDays={selectedDays}
                modifiers={modifiers}
                months={months}
                weekdaysShort={weekdaysShort}
                disabledDays={
                  availableDates?.length
                    ? getIsDayDisabled
                    : [
                        ...dateRangeOptionsDisabledDays,
                        maxInterval && {
                          after: moment(from).add(maxInterval.amount, maxInterval.unit).toDate(),
                        },
                        dateRangeOptionsDisabledDays.length > 0 && dateRangeOptionsDisabledDays[0].before
                          ? { before: dateRangeOptionsDisabledDays[0].before }
                          : {
                              before: moment().subtract(maxSearchableMonths, 'months').toDate(),
                            },
                      ]
                }
              />
            </DatePickerContainer>
          </DateRangePickerOverlay>
        )}

        {submitFailed && error && <FormError>{error}</FormError>}
      </FormGroup>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor) as any,
});

const mapDispatchToProps = {
  loadDataRetention,
};

export default connect(mapStateToProps, mapDispatchToProps)(DateRangePicker);
