import React from 'react';
import { Layer } from 'react-map-gl';

import { DASHBOARD_CLUSTERS_SOURCE, DASHBOARD_VEHICLE_POSITIONS_LAYER } from 'src/dashboard/constants/dashboardMapGL';

interface Props {
  sosAlertVehicleId?: number;
}

const DashboardVehiclePositionsGLLayers: React.FC<Props> = React.memo(({ sosAlertVehicleId = null }) => (
  <>
    <Layer
      id={DASHBOARD_VEHICLE_POSITIONS_LAYER}
      type="symbol"
      source={DASHBOARD_CLUSTERS_SOURCE}
      filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], DASHBOARD_VEHICLE_POSITIONS_LAYER]]}
      paint={{}}
      layout={{
        'icon-image': ['get', 'icon'],
        'icon-size': ['case', ['==', ['get', 'isPickupTruck'], true], 0.55, 0.75],
        'icon-allow-overlap': true,
        'icon-anchor': 'center',
      }}
    />
    <Layer
      id="sosAlertVehicles"
      type="symbol"
      source={DASHBOARD_CLUSTERS_SOURCE}
      filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'id'], sosAlertVehicleId]]}
      paint={{}}
      layout={{
        'icon-image': 'exclamation',
        'icon-size': 1,
        'icon-offset': [0, -50],
        'icon-allow-overlap': true,
      }}
    />
  </>
));

export default DashboardVehiclePositionsGLLayers;
