import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { DisposalTicket } from '../interfaces/DisposalTicket';
import { getDisposalTickets, getDisposalTrips } from '../services/disposals';
import { DisposalTrip } from '../interfaces/DisposalTrip';

const START_LOAD_TICKETS = 'routes/disposals/START_LOAD_TICKETS';
const COMPLETE_LOAD_TICKETS = 'routes/disposals/COMPLETE_LOAD_TICKETS';
const FAIL_LOAD_TICKETS = 'routes/disposals/FAIL_LOAD_TICKETS';

const START_LOAD_TRIPS = 'routes/disposals/START_LOAD_TRIPS';
const COMPLETE_LOAD_TRIPS = 'routes/disposals/COMPLETE_LOAD_TRIPS';
const FAIL_LOAD_TRIPS = 'routes/disposals/FAIL_LOAD_TRIPS';

const RESET_TICKETS = 'routes/disposals/RESET_TICKETS';
const RESET_TRIPS = 'routes/disposals/RESET_TRIPS';

interface State {
  disposalTickets: DisposalTicket[];
  isLoadingTickets: boolean;
  disposalTrips: DisposalTrip[];
  isLoadingTrips: boolean;
}

const initialState: State = {
  disposalTickets: [],
  isLoadingTickets: false,
  disposalTrips: [],
  isLoadingTrips: false,
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_TICKETS:
      return update(state, {
        $merge: {
          isLoadingTickets: true,
        },
      });

    case START_LOAD_TRIPS:
      return update(state, {
        $merge: {
          isLoadingTrips: true,
        },
      });

    case COMPLETE_LOAD_TICKETS:
      return update(state, {
        $merge: {
          isLoadingTickets: false,
          disposalTickets: action.disposalTickets,
        },
      });

    case COMPLETE_LOAD_TRIPS:
      return update(state, {
        $merge: {
          isLoadingTrips: false,
          disposalTrips: action.disposalTrips,
        },
      });

    case RESET_TICKETS:
    case FAIL_LOAD_TICKETS:
      return update(state, {
        $merge: {
          isLoadingTickets: false,
          disposalTickets: [],
        },
      });

    case RESET_TRIPS:
    case FAIL_LOAD_TRIPS:
      return update(state, {
        $merge: {
          isLoadingTrips: false,
          disposalTrips: [],
        },
      });

    default:
      return state;
  }
};

const startLoadTickets = () => ({
  type: START_LOAD_TICKETS,
});

const completeLoadTickets = (disposalTickets: DisposalTicket[]) => ({
  type: COMPLETE_LOAD_TICKETS,
  disposalTickets,
});

const failLoadTickets = () => ({
  type: FAIL_LOAD_TICKETS,
});

const startLoadTrips = () => ({
  type: START_LOAD_TRIPS,
});

const completeLoadTrips = (disposalTrips: DisposalTrip[]) => ({
  type: COMPLETE_LOAD_TRIPS,
  disposalTrips,
});

const failLoadTrips = () => ({
  type: FAIL_LOAD_TRIPS,
});

export const loadDisposalTickets = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadTickets());
  const loadDisposalPromise = getDisposalTickets(vendorId, routeId);
  loadDisposalPromise
    .then(disposalTickets => dispatch(completeLoadTickets(disposalTickets)))
    .catch(() => dispatch(failLoadTickets()));
  return loadDisposalPromise;
};

export const loadDisposalTrips = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadTrips());
  const loadDisposalPromise = getDisposalTrips(vendorId, routeId);
  loadDisposalPromise
    .then(disposalTrips => dispatch(completeLoadTrips(disposalTrips)))
    .catch(() => dispatch(failLoadTrips()));
  return loadDisposalPromise;
};

export const resetDisposalTickets = () => ({
  type: RESET_TICKETS,
});

export const resetDisposalTrips = () => ({
  type: RESET_TRIPS,
});
