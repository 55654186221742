import { DrawPolygonMode, EditingMode, Editor } from 'react-map-gl-draw';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import { IconButtonIcon } from 'src/core/components/styled/Button';
import translate from 'src/core/services/translate';
import { ComplexMapControl } from '../../styled/RouteMap';
import { GEO_FENCE_OPTIONS, GEO_FENCE_OPTIONS_SAT, GEO_FENCE_OPTIONS_SELECTED } from 'src/core/constants';

export const PolygonDrawControlGL: React.FC<{
  isDrawing: boolean;
  setDrawMode: (drawMode?: DrawPolygonMode) => void;
  showEditPolygon: boolean;
  editPolygon: boolean;
  setEditPolygon: (editPolygon: boolean) => void;
  drawMode?: DrawPolygonMode | EditingMode;
  polygon: any;
  onUpdate: (feature: any) => void;
  isSatelliteEnabled: boolean;
}> = ({
  isDrawing,
  setDrawMode,
  showEditPolygon,
  editPolygon,
  setEditPolygon,
  drawMode,
  polygon,
  onUpdate,
  isSatelliteEnabled,
}) => {
  const getFeatureStyle = ({
    state,
  }: {
    feature: any;
    index: number;
    state: 'SELECTED' | 'HOVERED' | 'INACTIVE' | 'UNCOMMITTED' | 'CLOSING';
  }) => {
    const cursor =
      drawMode instanceof EditingMode ? 'pointer' : drawMode instanceof DrawPolygonMode ? 'crosshair' : 'default';

    if (state === 'SELECTED' || state === 'HOVERED') {
      return {
        stroke: GEO_FENCE_OPTIONS_SELECTED.strokeColor,
        fill: GEO_FENCE_OPTIONS_SELECTED.fillColor,
        strokeWidth: 2,
        fillOpacity: 0.1,
        cursor,
      };
    }
    return {
      stroke: isSatelliteEnabled ? GEO_FENCE_OPTIONS_SAT.strokeColor : GEO_FENCE_OPTIONS.strokeColor,
      fill: isSatelliteEnabled ? GEO_FENCE_OPTIONS_SAT.fillColor : GEO_FENCE_OPTIONS.fillColor,
      strokeWidth: 2,
      fillOpacity: 0.1,
      cursor,
    };
  };
  return (
    <>
      <ComplexMapControl position="top-left" vertical>
        <TooltipIconButton
          tooltipAsString
          tooltip={translate(`routeTemplateBuilder.${isDrawing ? 'deletePolygon' : 'drawPolygon'}`)}
          tooltipPosition="right"
          tooltipColor="grayDarker"
          color={isDrawing ? 'warning' : 'secondary'}
          margin="no"
          onClick={() => setDrawMode(isDrawing ? undefined : new DrawPolygonMode())}
        >
          <IconButtonIcon icon={isDrawing ? 'delete' : 'lasso'} size="large" color={isDrawing ? 'white' : 'primary'} />
        </TooltipIconButton>
        {showEditPolygon && (
          <TooltipIconButton
            tooltipAsString
            tooltip={translate(
              editPolygon ? 'routeTemplateBuilder.disableEditPolygon' : 'routeTemplateBuilder.editPolygon',
            )}
            tooltipPosition="right"
            tooltipColor="grayDarker"
            margin="small no"
            color={editPolygon ? 'primary' : 'secondary'}
            onClick={() => {
              setEditPolygon(!editPolygon);
            }}
          >
            <IconButtonIcon margin="no" icon="edit" />
          </TooltipIconButton>
        )}
      </ComplexMapControl>
      <Editor
        clickRadius={12}
        mode={drawMode}
        features={polygon ? polygon : []}
        onUpdate={onUpdate}
        featureStyle={getFeatureStyle}
      />
    </>
  );
};
