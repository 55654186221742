import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { loadDisposalFacilities } from '../../../fleet/ducks/facilities';
import { loadRouteStopsForSequence } from 'src/routes/ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import ServiceSideModal from './ServiceSideModal';
import { loadRouteSequenceSettings } from 'src/vendors/ducks';
import { loadEquipmentSizes } from 'src/common/ducks';

interface Props {
  closeModal(): void;
  intermediateLocationId?: number | null;
  onSubmit(formData: any): void;
  routeId?: number;
  vehicleTypeId: number;
}

const ServiceSideModalResolver: React.FC<Props> = passedProps => {
  const dispatch = useDispatch();

  const vendorId = useSelector((state: AppState) => currentVendorId(state));

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.all([]);
    }

    const { routeId } = passedProps;

    const dependencies: any[] = [];
    dependencies.push(
      loadDisposalFacilities(vendorId)(dispatch),
      loadRouteSequenceSettings(vendorId)(dispatch),
      loadEquipmentSizes()(dispatch),
    );
    if (routeId) dependencies.push(loadRouteStopsForSequence(vendorId, routeId)(dispatch));

    return Promise.all(dependencies);
  }, [vendorId, dispatch, passedProps]);

  return (
    <TypedResolver
      successComponent={ServiceSideModal}
      successProps={passedProps}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default ServiceSideModalResolver;
