import { push } from 'connected-react-router';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router';

import { DocumentTitle } from 'src/common/components';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { loadWasteTypes } from 'src/common/ducks';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { loadVehicleTypesForVendor } from 'src/fleet/ducks';
import { loadGroups } from 'src/routes/ducks';
import { loadRoutePlannerRouteTemplateMetricsSettings } from 'src/routes/ducks/routePlanner';
import { getRoutePlannerFilters } from 'src/routes/services/routePlannerFilters';
import { createUrl, setQueryParams } from 'src/utils/services/queryParams';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import RoutePlannerReoccurringPage from './RoutePlannerReoccurringPage';

const RoutePlannerReoccurringPageResolver: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const vendorId = useSelector(currentVendorId);
  const routePlannerFilters = getRoutePlannerFilters(search);
  const supervisorEnabled = useSelector(supervisorExperienceFeatureIsEnabled);
  const { supervisorsIds, serviceZonesIds, facilityTypeIds, routeStatusesIds, materialTypeIds, groupIds } =
    routePlannerFilters;
  const isDailyRoutesPage = false;

  // getting the persistent filters from storage and adding them to the url if not present
  let filtersToPass = {
    materialTypeIds: materialTypeIds?.length ? materialTypeIds?.toString() : '',
    supervisorsIds: supervisorsIds?.length && supervisorEnabled ? supervisorsIds?.toString() : '',
    serviceZonesIds: serviceZonesIds?.length ? serviceZonesIds?.toString() : '',
    facilityTypeIds: facilityTypeIds?.length ? facilityTypeIds?.toString() : '',
    routeStatusesIds: !isDailyRoutesPage && routeStatusesIds?.length ? routeStatusesIds?.toString() : '',
    groupIds: groupIds?.length ? groupIds?.toString() : '',
  };

  if (setQueryParams(search, filtersToPass) !== search) dispatch(push(createUrl(pathname, search, filtersToPass)));

  const loadDependencies = () => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: any[] = [
      loadRoutePlannerRouteTemplateMetricsSettings(vendorId)(dispatch),
      loadWasteTypes()(dispatch),
      loadVehicleTypesForVendor(vendorId)(dispatch),
      loadGroups(vendorId)(dispatch),
    ];

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('routes.planner.reoccurringRoutes')}</DocumentTitle>
      <TypedResolver
        successComponent={RoutePlannerReoccurringPage}
        loadingComponent={PageLoadingOverlay}
        resolve={loadDependencies}
      />
    </>
  );
};

export default RoutePlannerReoccurringPageResolver;
