import { push } from 'connected-react-router';
import { map, size } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  PageBackButton,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { Table } from 'src/core/components';
import { DateRangeOptionValue } from 'src/core/components/DateRangePicker';
import { TableCell as TableCellProps } from 'src/core/components/Table';
import {
  Button,
  Message,
  Panel,
  PanelSection,
  PanelSectionGroup,
  PanelSectionHeader,
  PanelSectionTitle,
} from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { NINETY_DAYS_BEFORE_TODAY, TODAY_FORMATTED } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  createErrorNotificationIncludingTechnicalMessage,
  createSuccessNotification,
} from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { BillCreateFilterForm } from 'src/finance/components/forms';
import { BillCreateStatistics } from 'src/finance/components/pages/billing/billingPageSections';
import { loadBillCreateSummary, resetBillCreateSummary } from 'src/finance/ducks/billCreateSummary';
import { createBills, resetCreateBills } from 'src/finance/ducks/createBills';
import { PreviewBills } from 'src/finance/interfaces/BillCreateSummary';
import billCreateFilterFormInitialValueSelector from 'src/finance/services/billCreateFilterFormInitialValueSelector';
import { createUrl, getQueryParams } from 'src/utils/services/queryParams';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import BillPreviewFooterRow from './billingPageSections/BillPreviewTableFooter';
import BillPreviewTableRow from './billingPageSections/BillPreviewTableRow';

export interface BillCreateQueryParams {
  startDate: StringOrDate;
  endDate: StringOrDate;
  customerId?: number;
  customerName?: string;
  locationId?: number;
  locationName?: string;
  customerStatusIds?: number[];
  customerTypeIds?: number[];
  billMethodIds?: number[];
  paymentTermsIds?: number[];
  unbilledSearchTerm?: string;
  workOrderTypeId?: number;
  containerTypeId?: number;
  isOverdue?: boolean;
}

export interface FooterSums {
  onCallWOs?: {
    count: number;
    amount: number;
  };
  recurringWOs?: {
    count: number;
    amount: number;
  };
  scheduledWOs?: {
    count: number;
    amount: number;
  };
  totalWOs?: {
    count: number;
    amount: number;
  };
  cellWidths?: {
    customerId: string;
    customerStatusId: string;
    recurringWOsCount: string;
    scheduledWOsCount: string;
    onCallWOsCount: string;
    totalWOsCount: string;
    options: string;
  };
  length?: number;
}

const BillCreatePage: React.FC<RouteComponentProps> = ({ location: { pathname, search } }) => {
  const dispatch = useDispatch();

  const billCreateInitialValues = billCreateFilterFormInitialValueSelector(search);

  const vendorId = useSelector(currentVendorId);
  const billCreateSummary = useSelector(state => state.finance.billCreateSummary.billCreateSummary);
  const isLoadingBillsCreate = useSelector(state => state.finance.billCreateSummary.isLoading);

  const {
    startDate,
    endDate,
    customerId,
    locationId,
    unbilledSearchTerm,
    workOrderTypeId,
    containerTypeId,
    isOverdue,
  } = getQueryParams<BillCreateQueryParams>(search);

  const {
    customerStatusIds = [],
    customerTypeIds = [],
    billMethodIds = [],
    paymentTermsIds = [],
  } = getQueryParams<BillCreateQueryParams>(search, {
    mapTypeIdsToArray: true,
  });

  const cellWidths = {
    customerId: '35%',
    customerStatusId: '12%',
    recurringWOsCount: '12%',
    scheduledWOsCount: '12%',
    onCallWOsCount: '12%',
    totalWOsCount: '12%',
    options: '5%',
  };

  const billPreviewTableCells: TableCellProps[] = [
    {
      name: 'customerId',
      label: translate('finance.customerInfo'),
      width: cellWidths.customerId,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'customerStatusId',
      label: translate('finance.customerStatus'),
      width: cellWidths.customerStatusId,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'recurringWOsCount',
      label: translate('finance.recurringWos'),
      width: cellWidths.recurringWOsCount,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'scheduledWOsCount',
      label: translate('finance.scheduledWos'),
      width: cellWidths.scheduledWOsCount,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'onCallWOsCount',
      label: translate('finance.onCallWos'),
      width: cellWidths.onCallWOsCount,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'totalWOsCount',
      label: translate('finance.totalWos'),
      width: cellWidths.totalWOsCount,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'action',
      label: '',
      width: cellWidths.options,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
    },
  ];

  const previewBillsList = map(billCreateSummary.previewBills, bill => {
    return {
      ...bill,
      recurringWOsCount: bill.recurringWOs['count'],
      scheduledWOsCount: bill.scheduledWOs['count'],
      onCallWOsCount: bill.onCallWOs['count'],
      totalWOsCount: bill.totalWOs['count'],
    };
  });

  const calculateFooterSums = (previewBills: PreviewBills[]) => {
    const sums: FooterSums = {
      length: previewBills?.length,
    };

    if (previewBills) {
      previewBills?.forEach((bill: any) => {
        sums.recurringWOs = {
          amount: (sums?.recurringWOs?.amount || 0) + bill.recurringWOs.amount,
          count: (sums?.recurringWOs?.count || 0) + bill.recurringWOs.count,
        };

        sums.scheduledWOs = {
          amount: (sums?.scheduledWOs?.amount || 0) + bill.scheduledWOs?.amount,
          count: (sums?.scheduledWOs?.count || 0) + bill.scheduledWOs?.count,
        };

        sums.onCallWOs = {
          amount: (sums?.onCallWOs?.amount || 0) + bill.onCallWOs?.amount,
          count: (sums?.onCallWOs?.count || 0) + bill.onCallWOs?.count,
        };

        sums.totalWOs = {
          amount: (sums?.totalWOs?.amount || 0) + bill.totalWOs?.amount,
          count: (sums?.totalWOs?.count || 0) + bill.totalWOs?.count,
        };
      });
    }

    return sums;
  };

  const billPreviewFooterProps = useMemo(
    () => calculateFooterSums(billCreateSummary?.previewBills),
    [billCreateSummary?.previewBills],
  );

  const createNewBills = () => {
    createBills(
      vendorId,
      startDate || NINETY_DAYS_BEFORE_TODAY,
      endDate || TODAY_FORMATTED,
      customerId,
      locationId,
      customerStatusIds,
      customerTypeIds,
      billMethodIds,
      paymentTermsIds,
      unbilledSearchTerm,
      workOrderTypeId,
      containerTypeId,
      isOverdue,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('finance.alertMessages.billsCreated'));
        dispatch(push('/finance/billing'));
      })
      .catch(e => {
        createErrorNotificationIncludingTechnicalMessage(
          e,
          translate('finance.alertMessages.billCreateError'),
          'finance.alertMessages',
        );
      });
  };

  useEffect(() => {
    if (startDate && endDate) {
      loadBillCreateSummary(
        vendorId,
        startDate,
        endDate,
        customerId,
        locationId,
        customerStatusIds,
        customerTypeIds,
        billMethodIds,
        paymentTermsIds,
        unbilledSearchTerm,
        workOrderTypeId,
        containerTypeId,
        isOverdue,
      )(dispatch);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    dispatch,
    vendorId,
    startDate,
    endDate,
    customerId,
    locationId,
    customerStatusIds.toString(),
    customerTypeIds.toString(),
    billMethodIds.toString(),
    paymentTermsIds.toString(),
    unbilledSearchTerm,
    workOrderTypeId,
    containerTypeId,
    isOverdue,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(
    () => () => {
      dispatch(resetBillCreateSummary());
      dispatch(resetCreateBills());
    },
    [dispatch],
  );

  const handleSubmitSearch = ({
    newBillDateRange,
    customer,
    location,
    customerStatusIds,
    customerTypeIds,
    billMethodIds,
    paymentTermsIds,
    isOverdue,
  }: {
    newBillDateRange: DateRangeOptionValue;
    customer?: any;
    location?: any;
    customerStatusIds?: number[];
    customerTypeIds?: number[];
    billMethodIds?: number[];
    paymentTermsIds?: number[];
    isOverdue?: boolean;
  }) => {
    dispatch(
      push(
        createUrl(pathname, search, {
          startDate: newBillDateRange.from,
          endDate: newBillDateRange.to,
          customerId: customer?.value ? customer?.value.customerId : customer?.customerId,
          customerName: customer?.value ? customer?.value.customerName : customer?.customerName,
          locationId: location?.value ? location?.value.locationId : location?.locationId,
          locationName: location?.value ? location?.value.locationName : location?.locationName,
          customerStatusIds: customerStatusIds?.join(','),
          customerTypeIds: customerTypeIds?.join(','),
          billMethodIds: billMethodIds?.join(','),
          paymentTermsIds: paymentTermsIds?.join(','),
          isOverdue: isOverdue,
        }),
      ),
    );
  };

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButton to="/finance/billing" id="back-button">
              <PageBackButtonIcon />
            </PageBackButton>
            <PageTitle id="bills-create-title">{translate('finance.createBills')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
      </PageHeader>
      <Panel margin="no">
        <PanelSectionGroup padding="no" width="100%">
          <BillCreateFilterForm onSubmit={handleSubmitSearch} initialValues={billCreateInitialValues} />
          {!!size(billCreateSummary) ? (
            <>
              <PanelSection withBorder isLoading={isLoadingBillsCreate} padding="small">
                {!!size(billCreateSummary.amountReports) ? (
                  <BillCreateStatistics />
                ) : (
                  <Message padding="sMedium">{'No Bill Create charts found.'}</Message>
                )}
              </PanelSection>
              <PanelSection padding="no medium">
                <PanelSectionHeader margin="large no medium">
                  <PanelSectionTitle margin="no">{translate('finance.billPreview')}</PanelSectionTitle>
                </PanelSectionHeader>
              </PanelSection>
              <PanelSection padding="no medium medium" isLoading={isLoadingBillsCreate} withBorder vertical>
                {!!size(billCreateSummary.previewBills) ? (
                  <Table
                    cells={billPreviewTableCells}
                    rowComponent={BillPreviewTableRow}
                    rows={previewBillsList}
                    rowProps={{ cellWidths }}
                    tableFooterComponent={<BillPreviewFooterRow {...billPreviewFooterProps} cellWidths={cellWidths} />}
                  />
                ) : (
                  <Message padding="no no small no">{translate('finance.noBillPreviewCharges')}</Message>
                )}
              </PanelSection>
            </>
          ) : (
            <Message padding="sMedium">{translate('finance.fillCreateForm')}</Message>
          )}
          <PanelSection padding="medium" isLoading={isLoadingBillsCreate}>
            <Box fontSize={22} width="100%" display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                margin="no no no medium"
                type="button"
                color="primary"
                id="bills-create-button"
                onClick={createNewBills}
                disabled={!size(previewBillsList)}
              >
                {translate('finance.createBills')}
              </Button>
            </Box>
          </PanelSection>
        </PanelSectionGroup>
      </Panel>
    </PageContent>
  );
};

export default withRouter(BillCreatePage);
