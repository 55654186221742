import React from 'react';
import { ActionButtonTooltip, ToggleSwitch } from 'src/core/components';
import translate from 'src/core/services/translate';
import { theme } from 'src/core/styles';

interface Props {
  checked: boolean;
  onChange: (value: boolean) => void;
}

export const RouteTemplateBuilderLeaveStopsOnRoutesToggle: React.FC<Props> = ({ checked, onChange }) => {
  return (
    <>
      <ToggleSwitch
        offColor={theme.grayDark}
        offHandleColor={theme.grayLighter}
        margin="no"
        handleChange={onChange}
        afterText={translate('routeTemplateBuilder.leaveRouteStopsOnOriginalTemplates')}
        checked={checked}
      />
      <ActionButtonTooltip
        tooltipAsString
        color="grayDarker"
        iconSize="sMedium"
        icon="info"
        tooltip={translate('routeTemplateBuilder.leaveRouteStopsOnOriginalTemplatesInfo')}
      />
    </>
  );
};
