import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { DrawPolygonMode, EditingMode, Editor } from 'react-map-gl-draw';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { debounce } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { getFormValues } from 'redux-form';

import { MapGL } from 'src/common/components/map/MapGL';
import { getMapBounds } from 'src/common/components/map/util';
import { IconButtonIcon } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import { resetVendorLocations } from 'src/dashboard/ducks';
import { ContainerFacility, ContainerLocationForMap } from 'src/fleet/interfaces/containers';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { clearRouteMapSelectedFeature, setIsDrawingMode, setRouteMapViewport } from 'src/routes/ducks/mapControls';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { ContainersMapLegend } from './ContainersMapLegend';
import { ContainersFiltersFormValues, CONTAINERS_FILTERS_FORM } from 'src/fleet/components/forms/ContainersFormNew';
import { ContainersMapInfoOverlay } from 'src/fleet/components/styled/ContainersMap';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import MapGLWrapper from 'src/common/components/map/MapGLWrapper';
import ContainersClustersGL from './ContainersClustersGL';
import translate from 'src/core/services/translate';
import FacilitiesGL from './FacilitiesGL';
import ContainersGL from './ContainersGL';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';

type Props = {
  containerLocations: ContainerLocationForMap[];
  facilities: ContainerFacility[];
  selectedContainers: number[];
  setSelectedContainers: React.Dispatch<React.SetStateAction<number[]>>;
};

const ContainersMapGL = ({ containerLocations, facilities, selectedContainers, setSelectedContainers }: Props) => {
  const dispatch = useDispatch();
  const editorRef = useRef<Editor>(null);
  const [map, setMap] = useState<mapboxgl.Map>();
  const [editPolygon, setEditPolygon] = useState<boolean>(true);
  const [polygon, setPolygon] = useState<any>();
  const [drawMode, setDrawMode] = useState<DrawPolygonMode | EditingMode>();
  const [isFacilitiesDisplayed, setIsFacilitiesDisplayed] = useState<boolean>(false);

  const { viewport, isDrawingMode } = useSelector(state => state.routes.mapControls);
  const formValues = useSelector(getFormValues(CONTAINERS_FILTERS_FORM)) as ContainersFiltersFormValues;

  const [mapViewStyle, setMapViewStyle] = useState<any>({
    isSatelliteEnabled: false,
    mapCenter: null,
    mapZoom: null,
  });

  const vendorId = useSelector(currentVendorId);
  const dragPan = useSelector(s => s.routes.routeMapSettings.dragPan);

  useEffect(() => {
    dispatch(setIsDrawingMode(false));
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(resetVendorLocations());
      dispatch(setIsDrawingMode(false));
    },
    [dispatch],
  );

  useEffect(() => {
    if (isDrawingMode) {
      if (!drawMode) {
        setDrawMode(new DrawPolygonMode());
      }
    } else {
      if (polygon && editorRef.current) {
        editorRef.current.deleteFeatures(0);
        setPolygon(undefined);
      }

      if (!!drawMode) {
        setDrawMode(undefined);
      }
    }
  }, [isDrawingMode, drawMode, polygon, dispatch]);

  useEffect(() => {
    map?.once('load', () => {
      map.on('click', event => {
        const features = map.queryRenderedFeatures(event.point).filter(feature => !!feature.properties?.cluster_id);
        if (features.length) {
          dispatch(clearRouteMapSelectedFeature());
        }
      });
      map.on('mousemove', event => {
        const features = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.properties?.clickable === true || !!feature.properties?.cluster_id);

        map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
      map.on('mouseleave', () => {
        map.getCanvas().style.cursor = '';
      });
    });
  }, [map, dispatch]);

  useEffect(() => {
    setSelectedContainers([]);
    const points: { latitude: number; longitude: number }[] = [];
    if (!isFacilitiesDisplayed) {
      containerLocations.forEach(({ la, lo }) => {
        points.push({ latitude: la, longitude: lo });
      });
    } else {
      facilities.forEach(({ address: { latitude, longitude } }) => {
        points.push({ latitude: latitude, longitude: longitude });
      });
    }
    if (mapViewStyle.mapCenter && mapViewStyle.mapZoom) {
      const bounds = getMapBounds([{ latitude: mapViewStyle.mapCenter.lat, longitude: mapViewStyle.mapCenter.lng }], {
        capZoom: mapViewStyle.mapZoom,
      });
      dispatch(setRouteMapViewport(bounds));
    } else if (!!points.length) {
      const bounds = getMapBounds(points, {
        capZoom: 16,
      });
      dispatch(setRouteMapViewport(bounds));
    }
  }, [
    mapViewStyle.mapCenter,
    mapViewStyle.mapZoom,
    containerLocations,
    facilities,
    isFacilitiesDisplayed,
    dispatch,
    setSelectedContainers,
    vendorId,
    map,
  ]);

  const selectPointsInPolygon = useCallback(
    (points: ContainerLocationForMap[], newPolygon: any) => {
      const includedPoints: number[] = [];
      points
        .filter(p => booleanPointInPolygon([p.lo, p.la], newPolygon))
        .forEach(p => {
          const cIds = p.con.map(c => c.id);
          includedPoints.push(...cIds);
        });
      setSelectedContainers(includedPoints);
      setPolygon(newPolygon);
    },
    [setSelectedContainers],
  );

  const selectPointsInPolygonDebounced = useMemo(() => debounce(selectPointsInPolygon, 200), [selectPointsInPolygon]);

  const handleMapViewStyleChange = (enabled: boolean) => {
    setMapViewStyle({
      isSatelliteEnabled: enabled,
      mapCenter: map?.getCenter(),
      mapZoom: map?.getZoom(),
    });
  };

  const handleExitDrawMode = (uncheckStops?: boolean) => {
    dispatch(setIsDrawingMode(false));
    if (uncheckStops) {
      setSelectedContainers([]);
    }
  };

  const handleEnterDrawMode = () => {
    dispatch(setIsDrawingMode(true));
  };

  const checkContainersInPolygon = (event: any) => {
    const newPolygon = event.data[0];

    setPolygon(newPolygon);

    if (event.editType === 'addFeature') {
      selectPointsInPolygon(containerLocations, newPolygon);
      setDrawMode(new EditingMode());
    } else if (event.editType === 'movePosition') {
      selectPointsInPolygonDebounced(containerLocations, newPolygon);
    }
  };

  const isSupport = checkIfSupport();
  const isViewOnly = checkIfViewOnly();
  return (
    <MapGLWrapper drawingEnabled={!!drawMode && editPolygon} isDrawing={!!drawMode && !polygon}>
      {formValues && formValues.containerTypeId ? (
        <MapGL
          dragPan={dragPan}
          disableDefaultSatelliteView
          enableNewSatelliteView
          disableDefaultNavigationControl
          enableNewNavigationControl
          viewport={viewport}
          onMapRefLoaded={setMap}
          setIsSatelliteViewEnabled={handleMapViewStyleChange}
        >
          <ComplexMapControl vertical position="top-left">
            {!isFacilitiesDisplayed && !isViewOnly && !isSupport && (
              <TooltipIconButton
                tooltipAsString
                tooltip={translate(`routeTemplateBuilder.${drawMode ? 'deletePolygon' : 'drawPolygon'}`)}
                tooltipPosition="right"
                tooltipColor="grayDarker"
                color={drawMode ? 'warning' : 'secondary'}
                margin="no"
                onClick={
                  drawMode
                    ? () => handleExitDrawMode(true)
                    : () => {
                        setEditPolygon(true);
                        handleEnterDrawMode();
                      }
                }
              >
                <IconButtonIcon
                  icon={drawMode ? 'delete' : 'lasso'}
                  size="large"
                  color={drawMode ? 'white' : 'primary'}
                />
              </TooltipIconButton>
            )}
            {polygon && !isFacilitiesDisplayed && !isViewOnly && !isSupport && (
              <TooltipIconButton
                tooltipAsString
                tooltip={translate(
                  editPolygon ? 'routeTemplateBuilder.disableEditPolygon' : 'routeTemplateBuilder.editPolygon',
                )}
                tooltipPosition="right"
                tooltipColor="grayDarker"
                margin="small no"
                color={editPolygon ? 'primary' : 'secondary'}
                onClick={() => {
                  setEditPolygon(!editPolygon);
                }}
              >
                <IconButtonIcon margin="no" icon="edit" />
              </TooltipIconButton>
            )}
            <TooltipIconButton
              tooltipAsString
              tooltip={translate(isFacilitiesDisplayed ? 'facilities.facilitiesHide' : 'facilities.facilitiesShow')}
              tooltipPosition="right"
              tooltipColor="grayDarker"
              margin="small no"
              color={isFacilitiesDisplayed ? 'primary' : 'secondary'}
              onClick={() => {
                setIsFacilitiesDisplayed(!isFacilitiesDisplayed);
                isDrawingMode && dispatch(setIsDrawingMode(false));
              }}
            >
              <IconButtonIcon size="medium" margin="no" icon="facility" customViewBox="0 5 15 10" />
            </TooltipIconButton>
          </ComplexMapControl>
          <ComplexMapControl vertical position="bottom-right">
            <ContainersMapLegend
              containerLocations={containerLocations}
              facilities={facilities}
              isFacilitiesDisplayed={isFacilitiesDisplayed}
            />
          </ComplexMapControl>
          {drawMode && (
            <Editor
              ref={editorRef}
              clickRadius={12}
              mode={drawMode}
              onUpdate={checkContainersInPolygon}
              features={polygon ? [polygon] : []}
            />
          )}
          {map && (
            <>
              <ContainersClustersGL
                map={map}
                containerLocations={containerLocations}
                facilities={facilities}
                isFacilitiesDisplayed={isFacilitiesDisplayed}
                selectedContainers={selectedContainers}
              />
              <ContainersGL map={map} containerLocations={containerLocations} />
              <FacilitiesGL map={map} facilities={facilities} />
            </>
          )}
        </MapGL>
      ) : (
        <ContainersMapInfoOverlay>
          <h4>{translate('containers.loadDataMessage')}</h4>
        </ContainersMapInfoOverlay>
      )}
    </MapGLWrapper>
  );
};

export default ContainersMapGL;
