import { useSelector } from 'react-redux';
import { Modal } from '../../../core/components';
import { Grid, GridColumn, ModalSection, Text } from '../../../core/components/styled';
import { AppState } from 'src/store';
import translate from 'src/core/services/translate';

interface Props {
  closeModal: (pristine?: boolean) => void;
}
export default function EsriSyncValidationModal({ closeModal }: Props) {
  const { esriSyncValidation } = useSelector((state: AppState) => state.vendors.esriSyncSettings);
  const esriSyncStatus = esriSyncValidation.status;

  const color = esriSyncStatus === 'Success' ? 'success' : esriSyncStatus === 'Error' ? 'alert' : 'grayDarker';
  return (
    <Modal size="small" title={translate('vendors.esriSync.esriSyncValidation')} onClose={closeModal}>
      <ModalSection margin="medium no no">
        <Text align="center" color={color} block weight="medium" size="Large">
          {translate('vendors.esriSync.esriSyncStatus')} {esriSyncValidation && esriSyncValidation.status}
        </Text>
        <Grid multiLine>
          <GridColumn>
            <Text color="success" block weight="medium" size="small">
              {translate('vendors.esriSync.successMessages')}
            </Text>
            {esriSyncValidation.successMessages.map(message => (
              <Text padding="no no no medium" color="success" block weight="light" size="small">
                {message}
              </Text>
            ))}
            <Text color="grayDarker" block weight="medium" size="small">
              {translate('vendors.esriSync.warningMessages')}
            </Text>
            {esriSyncValidation.warningMessages.map(message => (
              <Text padding="no no no medium" color="grayDarker" block weight="light" size="small">
                {message}
              </Text>
            ))}
            <Text color="alert" block weight="medium" size="small">
              {translate('vendors.esriSync.errorMessages')}
            </Text>
            {esriSyncValidation.errorMessages.map(message => (
              <Text padding="no no no medium" color="alert" block weight="light" size="small">
                {message}
              </Text>
            ))}
          </GridColumn>
        </Grid>
      </ModalSection>
    </Modal>
  );
}
