import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupTitle,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import { CUSTOMER_SUBTYPES } from 'src/common/constants';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';

const TravelPathHaulerLocationGLPopup = () => {
  const dispatch = useDispatch();
  const haulerLocations = useSelector(state => state.routes.travelPathBuildAndEdit.haulerLocations);
  const { selectedFeatureForTravelPath } = useSelector(state => state.routes.mapControls);

  const haulerLocation = useMemo(() => {
    if (!selectedFeatureForTravelPath || selectedFeatureForTravelPath.feature !== RouteMapFeature.vendorLocations) {
      return undefined;
    }

    return haulerLocations.find(location => location.id === selectedFeatureForTravelPath.id);
  }, [haulerLocations, selectedFeatureForTravelPath]);

  if (!haulerLocation) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={haulerLocation.latitude}
      longitude={haulerLocation.longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature(true))}
    >
      <MapGLPopup>
        <MapGLPopupTitle title={haulerLocation.locationName} />

        <MapGLPopupContent>
          {!!haulerLocation.customerType && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.locationType')}
              value={CUSTOMER_SUBTYPES[haulerLocation.customerSubTypeId].name}
            />
          )}

          {!!haulerLocation.address && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('insights.address')}
              value={haulerLocation.address}
            />
          )}
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
};

export default TravelPathHaulerLocationGLPopup;
