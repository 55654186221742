import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Grid, GridColumn, ModalTitle, ButtonSet, Button, FormLabel } from '../../core/components/styled';
import { UnconnectedDropdown, Modal } from '../../core/components';
import translate from '../../core/services/translate';
import { getCurrentLanguageId, setCurrentLanguageId } from '../../core/services/i18n';
import { saveLanguageOptions, loadLanguageOptions } from '../../common/ducks';
import { createSuccessNotification, createErrorNotification } from '../../core/services/createNotification';
import { DropdownOption } from '../../core/components/Dropdown';
import { AppState } from '../../store';

const saveLanguagePreferences = (
  language: number,
  saveLanguageOptions: (languageId: number) => Promise<any>,
  onSaved: () => void,
) => {
  if (language !== getCurrentLanguageId()) {
    saveLanguageOptions(language)
      .then(() => {
        createSuccessNotification(translate('account.alertMessages.languageSaved'));
        onSaved();
        setCurrentLanguageId(language);
        window.location.reload();
      })
      .catch(() => {
        createErrorNotification(translate('account.alertMessages.savelanguageError'));
      });
  }
};

interface Props {
  languageOptions: DropdownOption[];
  onCloseModal: () => void;
  saveLanguageOptions: (languageId: number) => Promise<any>;
  loadLanguageOptions: () => () => Promise<any>;
  areLanguagesLoading: boolean;
}

const LanguageSettingsModal: React.SFC<Props> = ({
  languageOptions,
  onCloseModal,
  saveLanguageOptions,
  loadLanguageOptions,
  areLanguagesLoading,
}) => {
  if (!areLanguagesLoading && !languageOptions.length) {
    loadLanguageOptions();
  }

  const [language, setLanguage] = useState(Number(getCurrentLanguageId()));

  return (
    <Modal onClose={onCloseModal} verticalSize="xSmall" size="xSmall" padding="medium">
      <Grid centered multiLine>
        <GridColumn>
          <ModalTitle>{translate('account.languageSettings')}</ModalTitle>
        </GridColumn>
        <GridColumn size="8/12" padding="small">
          <FormLabel>{translate('account.selectLanguage')}</FormLabel>
        </GridColumn>
        <GridColumn size="8/12" padding="small">
          <UnconnectedDropdown onChange={setLanguage} value={language} options={languageOptions} />
        </GridColumn>
        <GridColumn size="6/12" padding="medium no no no">
          <ButtonSet>
            <Button
              onClick={() => saveLanguagePreferences(language, saveLanguageOptions, onCloseModal)}
              color="primary"
            >
              {translate('common.save')}
            </Button>
          </ButtonSet>
        </GridColumn>
      </Grid>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  areLanguagesLoading: state.common.languageOptions.isLoading,
  languageOptions: (state.common.languageOptions.languageOptions || []).map((o: { name: string; id: number }) => ({
    label: o.name,
    value: o.id,
  })),
});

const mapDispatchToProps = {
  saveLanguageOptions,
  loadLanguageOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSettingsModal);
