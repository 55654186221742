import styled, { css } from 'styled-components';
import { sizeMapper } from '../../../utils/styles';

interface Props {
  size?: string;
  margin?: string;
  src?: string;
}

const ProfileImage = styled.div<Props>`
  width: ${props => sizeMapper(props.size, 'xxGrande')};
  height: ${props => sizeMapper(props.size, 'xxGrande')};
  margin: ${props => sizeMapper(props.margin, 'no')};
  border-radius: 50%;
  background-color: #f2f2f3;
  background-size: cover;

  ${props =>
    props.src &&
    css`
      background-image: url('${props.src}');
    `};
`;

export default ProfileImage;
