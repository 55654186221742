import { orderBy } from 'lodash-es';

import { BulkyItemScheduler } from '../interfaces/BulkyItemScheduler';
import { http } from '../../core/services/http';
import { MaterialPickupSettings } from 'src/vendors/interfaces/BulkyItemScheduler';
import { OTHER_ID } from '../constants/bulkyItemScheduler';

export const loadBulkyItemScheduler = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/bulkyItemSchedulerSettings`).then(response => {
    const materialPickupSettingsOrdered = orderBy(response.data.materialPickupSettings, 'bulkyCategoryTypeId', 'asc');
    const index = materialPickupSettingsOrdered.findIndex(
      (settings: MaterialPickupSettings) => settings.bulkyCategoryTypeId === OTHER_ID,
    );
    materialPickupSettingsOrdered.push(materialPickupSettingsOrdered.splice(index, 1)[0]);

    return {
      ...response.data,
      customBulkyPickUpScheduleSettings: {
        ...response.data.customBulkyPickUpScheduleSettings,
        dayOfWeekList: orderBy(response.data.customBulkyPickUpScheduleSettings?.dayOfWeekList, 'dayOfWeek', 'asc'),
      },
      materialPickupSettings: response.data.materialPickupSettings?.length ? materialPickupSettingsOrdered : [],
    };
  });

export const saveBulkyItemScheduler = (bulkyItemScheduler: BulkyItemScheduler, vendorId: number) =>
  http.post(`/vendors/${vendorId}/bulkyItemSchedulerSettings`, bulkyItemScheduler).then(response => response.data);

export const loadBulkyCategoryTypes = () => http.get('/common/bulkyCategoryTypes').then(response => response.data);

export const loadBulkyItemTypes = () => http.get('/common/bulkyItemTypes').then(response => response.data);

export const loadPeriodTypes = () => http.get('/common/periodTypes').then(response => response.data);
