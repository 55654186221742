import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  createNotificationEmailTemplate,
  loadNotificationEmailTemplates as doLoadNotificationEmailTemplates,
  updateNotificationEmailTemplate,
} from '../services/notificationEmailTemplates';

// Actions
const START_LOAD = 'vendors/notificationEmailTemplates/START_LOAD';
const COMPLETE_LOAD = 'vendors/notificationEmailTemplates/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/notificationEmailTemplates/FAIL_LOAD';
const START_SAVE = 'vendors/notificationEmailTemplates/START_SAVE';
const COMPLETE_SAVE = 'vendors/notificationEmailTemplates/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/notificationEmailTemplates/FAIL_SAVE';
const RESET = 'vendors/notificationEmailTemplates/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  notificationEmailTemplates: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          notificationEmailTemplates: action.notificationEmailTemplates,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (notificationEmailTemplates: any) => ({
  type: COMPLETE_LOAD,
  notificationEmailTemplates,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadNotificationEmailTemplates = (
  vendorId: number,
  vehicleTypeId: number,
  notificationType: any,
  pickupExceptionTypeId?: number,
) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadNotificationEmailTemplatesPromise = doLoadNotificationEmailTemplates(
    vendorId,
    vehicleTypeId,
    notificationType,
    pickupExceptionTypeId,
  );
  loadNotificationEmailTemplatesPromise
    .then(notificationEmailTemplates => dispatch(completeLoad(notificationEmailTemplates)))
    .catch(() => dispatch(failLoad()));

  return loadNotificationEmailTemplatesPromise;
};

export const saveNotificationEmailTemplate = (vendorId: number, emailTemplate: any) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveNotificationEmailTemplatePromise = emailTemplate.id
    ? updateNotificationEmailTemplate(vendorId, emailTemplate)
    : createNotificationEmailTemplate(vendorId, emailTemplate);
  saveNotificationEmailTemplatePromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveNotificationEmailTemplatePromise;
};

export const resetNotificationEmailTemplates = () => ({
  type: RESET,
});
