import { reduce, size } from 'lodash-es';
import { useState } from 'react';

import {
  Button,
  Grid,
  Message,
  ModalFixedFooter,
  Panel,
  PanelSection,
  PanelSectionGroup,
} from 'src/core/components/styled';
import { currency } from 'src/utils/services/formatter';
import { Modal, Table, UnconnectedInput } from 'src/core/components';
import { PAYMENT_STATUSES } from 'src/finance/constants/paymentStatuses';
import { PaymentAssignedBill, PaymentDetail as IPaymentDetail } from 'src/finance/interfaces/ApplyChecks';
import { PaymentDetail } from 'src/finance/components/styled/PaymentDetail';
import { PaymentDetailLabel } from 'src/finance/components/styled/PaymentDetailLabel';
import { PaymentDetailsImage } from 'src/finance/components/styled/PaymentDetailsImage';
import { PaymentDetailValue } from 'src/finance/components/styled/PaymentDetailValue';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { TableCell } from 'src/core/components/Table';
import { useSelector } from 'src/core/hooks/useSelector';
import AssignedBillsTableRow from 'src/finance/components/pages/applyChecks/applyChecksPageSections/AssignedBillsTableRow';
import translate from 'src/core/services/translate';
import UnconnectedDatePicker from 'src/core/components/UnconnectedDatePicker';

const editPaymentDetailsModalId = 'edit-payment-details-modal-id';

const tableCellWidths = {
  id: '15%',
  appliedAmount: '25%',
  invoiceBalance: '25%',
  amountToApply: '35%',
};

const assignedBillsTableCells: TableCell[] = [
  {
    name: 'id',
    label: translate('finance.applyChecks.billId'),
    width: tableCellWidths.id,
    padding: 'defaultCellVertical small',
    sortable: true,
  },

  {
    name: 'appliedAmount',
    label: translate('finance.applyChecks.amountPosted'),
    width: tableCellWidths.appliedAmount,
    sortable: true,
  },

  {
    name: 'remainingBillBalance',
    label: translate('finance.applyChecks.remainingBillBalance'),
    width: tableCellWidths.invoiceBalance,
    sortable: true,
  },

  {
    name: 'amountToApply',
    label: translate('finance.applyChecks.amountToApply'),
    width: tableCellWidths.amountToApply,
    sortable: false,
  },
];

interface Props {
  assignedBills: PaymentAssignedBill[];
  closeModal(reset?: boolean): void;
  customerId: number;
  customerName: string;
  handleCheckboxOnChange?: (id: number, checked: boolean) => void;
  handleSaveForLater: (redirect: boolean, data: any) => void;
  handleUnassignOpenBill?: (openBillIds: number[]) => void;
  isEditable?: boolean;
  isSaving?: boolean;
  paymentDate: StringOrDate;
  paymentDetails: IPaymentDetail;
  paymentTotal: number;
  setPaymentTotal: (total: number) => void;
  unappliedAmount?: number;
  virtualizedProps: {
    height: number;
    itemSize: number;
  };
}

const EditPaymentDetailsModal = ({
  assignedBills,
  closeModal,
  customerId,
  customerName,
  handleCheckboxOnChange,
  handleSaveForLater,
  handleUnassignOpenBill,
  isEditable,
  isSaving,
  paymentDate,
  paymentDetails,
  paymentTotal,
  setPaymentTotal,
  unappliedAmount,
  virtualizedProps,
}: Props) => {
  const hasImage = paymentDetails?.imageUrl;

  const isUpdating = useSelector(state => state.finance.payments.isUpdating);

  const [canSave, setCanSave] = useState(false);
  const [isSaveFailed, setIsSaveFailed] = useState(false);
  const [modalPaymentDate, setModalPaymentDate] = useState(paymentDate);
  const [modalPaymentTotal, setModalPaymentTotal] = useState(paymentTotal);
  const [modalAssignedBills, setModalAssignedBills] = useState(assignedBills);
  const [modalUnappliedAmount, setModalUnappliedAmount] = useState(unappliedAmount);

  const handleAmountToApplyChange = (billId: number, paymentSubTotal: number) => {
    const updatedAssignedBills = modalAssignedBills.map(bill => ({
      ...bill,
      paymentSubTotal: billId === bill.id ? paymentSubTotal : bill.paymentSubTotal,
    }));

    const updatedAppliedAmount = reduce(updatedAssignedBills, (acc, bill) => acc + bill.paymentSubTotal, 0);

    const updatedPaymentBalance = modalPaymentTotal - updatedAppliedAmount;

    setModalAssignedBills(updatedAssignedBills);
    setModalUnappliedAmount(updatedPaymentBalance);
  };

  const updatePaymentDate = (date: StringOrDate) => {
    setModalPaymentDate(date);
    setCanSave(true);
  };

  const updatePaymentTotal = (total: number | string) => {
    const updatedAppliedAmount = reduce(modalAssignedBills, (acc, bill) => acc + bill.paymentSubTotal, 0);
    const updatedPaymentBalance = Number(total) - updatedAppliedAmount;
    setModalUnappliedAmount(updatedPaymentBalance);
    setModalPaymentTotal(Number(total));
    setCanSave(true);
  };

  const updateAmountToApply = (billId: number, paymentSubTotal: number) => {
    handleAmountToApplyChange(billId, paymentSubTotal);
    setCanSave(true);
  };

  const handleSave = () => {
    scrollToTopOfModal(editPaymentDetailsModalId);

    if (!modalPaymentDate || !modalPaymentTotal || modalPaymentTotal === 0) {
      setIsSaveFailed(true);
      setCanSave(false);
    } else {
      handleSaveForLater(false, {
        paymentDate: modalPaymentDate,
        paymentTotal: modalPaymentTotal,
        assignedBills: modalAssignedBills,
      });
    }
  };

  return (
    <Modal
      onClose={() => closeModal(true)}
      verticalSize="mediumLarge"
      title={
        isEditable
          ? translate('finance.applyChecks.editPaymentDetails')
          : translate('finance.applyChecks.paymentDetails')
      }
      subTitle={paymentDetails && paymentDetails.paymentNumber && `#${paymentDetails.paymentNumber}`}
      id={editPaymentDetailsModalId}
      padding="medium no no"
      isLoading={isUpdating}
      overflow={isUpdating ? 'hidden' : 'auto'}
    >
      <Panel noBoxShadow padding="small small xLarge small">
        <PanelSectionGroup>
          <PanelSection>
            <Grid>
              <PaymentDetail margin="small no small">
                <UnconnectedDatePicker
                  name="paymentDate"
                  value={modalPaymentDate}
                  onDateChange={updatePaymentDate}
                  label={translate('finance.applyChecks.paymentDate')}
                  margin="no"
                  disabled={!isEditable}
                  width="100%"
                  hasError={!modalPaymentDate && isSaveFailed}
                  error={translate('common.validationMessages.isRequired')}
                />
              </PaymentDetail>
              <PaymentDetail margin="small no small">
                <PaymentDetailLabel>{translate('finance.applyChecks.status')}</PaymentDetailLabel>
                <PaymentDetailValue>
                  {paymentDetails?.paymentStatusId
                    ? PAYMENT_STATUSES[paymentDetails.paymentStatusId]?.name || '-'
                    : '-'}
                </PaymentDetailValue>
              </PaymentDetail>
              <PaymentDetail margin="small no small">
                <PaymentDetailLabel>{translate('finance.applyChecks.customer')}</PaymentDetailLabel>
                <PaymentDetailValue>{customerName || '-'}</PaymentDetailValue>
              </PaymentDetail>
            </Grid>
          </PanelSection>
          <PanelSection>
            <Grid>
              <PaymentDetail margin="small no small">
                <UnconnectedInput
                  label={translate('finance.applyChecks.paymentAmount')}
                  prepend="$"
                  type="number"
                  onChange={updatePaymentTotal}
                  value={modalPaymentTotal}
                  min={0}
                  preventNumberExponent
                  disabled={!isEditable}
                  margin="no"
                  width="100%"
                  hasError={(!modalPaymentTotal || modalPaymentTotal === 0) && isSaveFailed}
                  error={
                    modalPaymentTotal === 0
                      ? translate('common.validationMessages.xGreaterThan', { min: 0 })
                      : translate('common.validationMessages.isRequired')
                  }
                />
              </PaymentDetail>
              <PaymentDetail margin="small no small">
                <PaymentDetailLabel>{translate('finance.applyChecks.unappliedAmount')}</PaymentDetailLabel>
                <PaymentDetailValue>{modalUnappliedAmount ? currency(modalUnappliedAmount) : '-'}</PaymentDetailValue>
              </PaymentDetail>
              <PaymentDetail margin="small no small">
                <PaymentDetailLabel>{translate('finance.applyChecks.customerId')}</PaymentDetailLabel>
                <PaymentDetailValue>{customerId || '-'}</PaymentDetailValue>
              </PaymentDetail>
            </Grid>
          </PanelSection>
        </PanelSectionGroup>
        <PanelSectionGroup>
          {isEditable ? (
            !!size(assignedBills) ? (
              <Table
                cells={assignedBillsTableCells}
                rowComponent={AssignedBillsTableRow}
                rows={modalAssignedBills}
                rowProps={{
                  handleCheckboxOnChange,
                  handleUnassignOpenBill,
                  isEditable,
                  isSaving,
                  onAmountToApplyChange: updateAmountToApply,
                  tableCellWidths,
                }}
                scrollMarker
                virtualized
                virtualizedProps={virtualizedProps}
              />
            ) : (
              <Message padding="no">{translate('finance.applyChecks.noAssignedBills')}</Message>
            )
          ) : (
            !!hasImage && (
              <PanelSection margin="no" padding="no">
                <PaymentDetailsImage src={hasImage} />
              </PanelSection>
            )
          )}
        </PanelSectionGroup>
      </Panel>

      <ModalFixedFooter isShadowed>
        {isEditable ? (
          <>
            <Button margin="no small no no" onClick={() => closeModal(true)} color="secondary">
              {translate('common.cancel')}
            </Button>
            <Button disabled={!canSave} onClick={handleSave} margin="no small no no" color="primary">
              {translate('common.save')}
            </Button>{' '}
          </>
        ) : (
          <Button margin="no small no no" onClick={closeModal} type="button" color="primary">
            {translate('common.done')}
          </Button>
        )}
      </ModalFixedFooter>
    </Modal>
  );
};

export default EditPaymentDetailsModal;
