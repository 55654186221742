import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadBillingModule as doLoadBillingModule,
  saveBillingModule as doSaveBillingModule,
} from '../services/billingModule';
import { IBillingModule } from '../interfaces/BillingModule';

// Actions
const COMPLETE_LOAD = 'vendors/billingModule/COMPLETE_LOAD';
const COMPLETE_SAVE = 'vendors/billingModule/COMPLETE_SAVE';
const FAIL_LOAD = 'vendors/billingModule/FAIL_LOAD';
const FAIL_SAVE = 'vendors/billingModule/FAIL_SAVE';
const START_LOAD = 'vendors/billingModule/START_LOAD';
const START_SAVE = 'vendors/billingModule/START_SAVE';

// Initial state
const initialState = {
  isFailed: false,
  isLoading: false,
  isSaving: false,
  billingModule: {} as IBillingModule,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          billingModule: action.billingModule,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          isFailed: true,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (billingModule: IBillingModule) => ({
  type: COMPLETE_LOAD,
  billingModule,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadBillingModule = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadBillingModulePromise = doLoadBillingModule(vendorId);
  loadBillingModulePromise
    .then(billingModule => {
      dispatch(completeLoad(billingModule));
    })
    .catch(() => dispatch(failLoad()));

  return loadBillingModulePromise;
};

export const saveBillingModule = (vendorId: number, billingModule: IBillingModule) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveBillingModulePromise = doSaveBillingModule(vendorId, billingModule);
  saveBillingModulePromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveBillingModulePromise;
};
