import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import doLoadRouteTemplatesForReassignment from '../services/loadRouteTemplatesForReassignment';

// Actions
const START_LOAD = 'routes/routeTemplatesForReassignment/START_LOAD';
const COMPLETE_LOAD = 'routes/routeTemplatesForReassignment/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeTemplatesForReassignment/FAIL_LOAD';
const RESET = 'routes/routeTemplatesForReassignment/RESET';

interface State {
  isLoading: boolean;
  routeTemplatesForReassignment: any;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  routeTemplatesForReassignment: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeTemplatesForReassignment: action.routeTemplatesForReassignment,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeTemplatesForReassignment: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeTemplatesForReassignment: any) => ({
  type: COMPLETE_LOAD,
  routeTemplatesForReassignment,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRouteTemplatesForReassignment =
  (
    vendorId: number,
    sourceRouteTemplateId: number,
    vehicleTypeId: number,
    wasteMaterialTypeId?: number,
    scheduledDay?: Date | string,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadRouteTemplatesForReassignmentPromise = doLoadRouteTemplatesForReassignment(
      vendorId,
      sourceRouteTemplateId,
      vehicleTypeId,
      wasteMaterialTypeId,
      scheduledDay,
    );
    loadRouteTemplatesForReassignmentPromise
      .then(routeTemplatesForReassignment => dispatch(completeLoad(routeTemplatesForReassignment)))
      .catch(() => dispatch(failLoad()));
    return loadRouteTemplatesForReassignmentPromise;
  };

export const resetRouteTemplatesForReassignment = () => ({
  type: RESET,
});
