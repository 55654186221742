import { GeoJSONSource } from 'mapbox-gl';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getFeatureCollection } from 'src/common/components/map/util';
import { useSelector } from 'src/core/hooks/useSelector';
import { setStreetNetworkMapViewport } from 'src/customers/ducks';
import { RouteDriversVehiclesItem, RouteVehiclesBreadCrumbsItem } from 'src/dashboard/interfaces/routesData';
import { getApplicationStatusForRouteGeoJSON } from 'src/routes/components/pages/routes/routePageSections/routeMap/applicationStatus/utils';
import { getCityAlertsGeoJSON } from 'src/routes/components/pages/routes/routePageSections/routeMap/cityAlerts/utils';
import { getCityInsightsGeoJSON } from 'src/routes/components/pages/routes/routePageSections/routeMap/cityInsights/utils';
import RouteMapClustersGLSource from 'src/routes/components/pages/routes/routePageSections/routeMap/cluster/RouteMapClustersGLSource';
import {
  ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER,
  ROUTE_MAP_CLUSTERS_SOURCE,
} from 'src/routes/components/pages/routes/routePageSections/routeMap/constants';
import { getHaulerLocationsGeoJSON } from 'src/routes/components/pages/routes/routePageSections/routeMap/haulerLocations/utils';
import { getVehicleInsightsGeoJSON } from 'src/routes/components/pages/routes/routePageSections/routeMap/vehicleInsights/utils';
import { getVehiclePositionsGeoJSON } from 'src/routes/components/pages/routes/routePageSections/routeMap/vehiclePositions/utils';
import { setMapGeoJson } from 'src/routes/ducks/routeMapSettings';
import { RouteMapCityInsight } from 'src/routes/interfaces/RouteMapCityInsights';
import { RouteMapVehiclePosition, RouteMapVehicleTracking } from 'src/routes/interfaces/RouteMapVehicleData';
import { RouteMapVehicleInsight } from 'src/routes/interfaces/RouteMapVehicleInsights';

type Props = {
  cityInsights: RouteMapCityInsight[];
  map: mapboxgl.Map;
  vehicleInsights: RouteMapVehicleInsight[];
  vehiclePositions: RouteMapVehiclePosition[];
  vehicleTrackings: RouteVehiclesBreadCrumbsItem[];
  timelineVehicleTrackings?: RouteMapVehicleTracking[];
};

export default function StreetNetworkMapClustersGL({
  cityInsights,
  map,
  vehicleInsights,
  vehiclePositions,
  vehicleTrackings,
}: Props) {
  const dispatch = useDispatch();
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const vehicleTypeName = useSelector(state => state.routes.route.route?.vehicleTypeName);
  const { vendorLocations: haulerLocations } = useSelector(state => state.dashboard.vendorLocations);
  const cityAlerts = useSelector(state => state.vendors.cityAlerts.cityAlerts);
  const geoJson = useSelector(s => s.routes.routeMapSettings.mapGeoJson);
  const vehicleFilters = useSelector(
    state => state.routes.routeMapVehicleData.filters,
  ) as unknown as RouteDriversVehiclesItem[];

  useEffect(() => {
    let collection = getFeatureCollection<GeoJSON.Point, any>([]);

    if (vehiclePositions.length) {
      const vehiclePositionsCollection = getVehiclePositionsGeoJSON(vehiclePositions, routeSummary?.vehicleTypeName);

      collection.features = collection.features.concat(vehiclePositionsCollection.features);
    }

    if (vehicleTrackings.length) {
      const applicationStatusCollection = getApplicationStatusForRouteGeoJSON(vehicleTrackings, vehicleFilters);
      collection.features = collection.features.concat(applicationStatusCollection.features);
    }

    if (vehicleInsights.length) {
      const vehicleInsightsCollection = getVehicleInsightsGeoJSON(vehicleInsights);
      collection.features = collection.features.concat(vehicleInsightsCollection.features);
    }

    if (cityInsights.length) {
      const cityInsightsCollection = getCityInsightsGeoJSON(cityInsights);
      collection.features = collection.features.concat(cityInsightsCollection.features);
    }

    if (cityAlerts.length) {
      const cityAlertsCollection = getCityAlertsGeoJSON(cityAlerts);
      collection.features = collection.features.concat(cityAlertsCollection.features);
    }

    if (haulerLocations.length) {
      const haulerLocationsCollection = getHaulerLocationsGeoJSON(haulerLocations);
      collection.features = collection.features.concat(haulerLocationsCollection.features);
    }
    dispatch(setMapGeoJson(collection));
  }, [
    routeSummary?.vehicleTypeName,
    vehiclePositions,
    vehicleTrackings,
    vehicleInsights,
    cityInsights,
    haulerLocations,
    dispatch,
    vehicleTypeName,
    routeSummary,
    vehicleFilters,
    cityAlerts,
  ]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER, event => {
        const [feature] = map
          .queryRenderedFeatures(event.point, {
            layers: [ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER],
          })
          .filter(feature => feature.source === ROUTE_MAP_CLUSTERS_SOURCE);

        const clusterId = feature.properties?.cluster_id;
        const source = map.getSource(ROUTE_MAP_CLUSTERS_SOURCE) as GeoJSONSource;

        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          dispatch(
            setStreetNetworkMapViewport({
              latitude: (feature.geometry as any).coordinates[1],
              longitude: (feature.geometry as any).coordinates[0],
              zoom,
            }),
          );
        });
      });
      // moving the trackings layer before the clusters layer
      if (map.getLayer(ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER)) {
        if (map.getLayer('allVehicleTrackingsLine'))
          map.moveLayer('allVehicleTrackingsLine', ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER);
        if (map.getLayer('allVehicleTrackingsPoints'))
          map.moveLayer('allVehicleTrackingsPoints', ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER);
        if (map.getLayer('allVehicleTrackingsArrows'))
          map.moveLayer('allVehicleTrackingsArrows', ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER);
      }
    });
  }, [map, dispatch]);

  return <RouteMapClustersGLSource geoJSON={geoJson} />;
}
