import { map } from 'lodash-es';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from 'src/core/components';
import { ModalTitle } from 'src/core/components/styled';
import { TOMORROW } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { saveServiceWorkflow } from 'src/customers/ducks';
import { ServiceWorkflowSavePayload } from 'src/customers/interfaces/Services';
import { checkIfContainerManagementIsEnabled } from 'src/vendors/ducks/features';
import { ServiceContractWorkflowForm } from '../../forms';
import { ServiceContractWorkflowFormValues } from '../../forms/ServiceContractWorkflowForm';

type Props = {
  closeModal: () => void;
  vendorId: number;
  selectedCustomerId?: number;
  selectedLocationId?: number;
  onSubmitSuccess?: () => void;
};

const ServiceContractWorkflowModal: FC<Props> = ({
  closeModal,
  selectedCustomerId,
  selectedLocationId,
  onSubmitSuccess,
}) => {
  const dispatch = useDispatch();
  const isContainerManagementFeatureEnabled = useSelector(checkIfContainerManagementIsEnabled);
  const service = useSelector(state => state.customers.service.service);
  const isSavingWorkflow = useSelector(state => state.customers.service.isSavingWorkflow);

  const handleSaveWorkFlow = useCallback(
    (values: ServiceContractWorkflowFormValues) => {
      const payload: ServiceWorkflowSavePayload = {
        serviceContractId: values.serviceId!,
        numberOfContainers: isContainerManagementFeatureEnabled
          ? service?.numberOfContainers
          : values.numberOfContainers,
        binNumber: isContainerManagementFeatureEnabled ? service?.binNumber : values.containerNo,
        equipmentSizeId: values.sizeId,
        createDeliveryJob: values.createDeliveryJob ? true : false,
        deliveryJobDate: values?.createDeliveryJob ? values.deliveryJobDate : undefined,
        createRemovalJob: values.createRemovalJob ? true : false,
        removalJobDate: values?.createRemovalJob ? values.removalJobDate : undefined,
        effectiveDate: values.changeEffectiveDate,
        vendorContainers: isContainerManagementFeatureEnabled
          ? map(values.containerManagementContainers, container => ({
              id: container.id,
              locationId: container.locationId,
            }))
          : service?.vendorContainers,
      };

      saveServiceWorkflow(payload)(dispatch)
        .then(() => {
          createSuccessNotification(translate('customers.alertMessages.serviceWorkflowSaveSuccess'));
          if (onSubmitSuccess) onSubmitSuccess();

          closeModal();
        })
        .catch(() => {});
    },
    [
      closeModal,
      dispatch,
      isContainerManagementFeatureEnabled,
      onSubmitSuccess,
      service?.binNumber,
      service?.numberOfContainers,
      service?.vendorContainers,
    ],
  );

  const initialValues: ServiceContractWorkflowFormValues = useMemo(
    () => ({
      actions: {
        searchByAccountNumber: false,
        changeSize: false,
        manageContainers: false,
        replaceContainer: false,
        updateCount: false,
      },
      changeEffectiveDate: TOMORROW,
      createDeliveryJob: false,
      createRemovalJob: false,
      containerNo: '',
      customerId: selectedCustomerId,
      locationId: selectedLocationId,
    }),
    [selectedCustomerId, selectedLocationId],
  );

  return (
    <Modal padding="medium" size="medium" onClose={closeModal} overflow="visible" isLoading={isSavingWorkflow}>
      <ModalTitle>{translate('customers.serviceUpdate')}</ModalTitle>
      <ServiceContractWorkflowForm
        closeModal={closeModal}
        onSubmit={handleSaveWorkFlow}
        initialValues={initialValues}
      />
    </Modal>
  );
};
export default ServiceContractWorkflowModal;
