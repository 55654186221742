import React from 'react';
import { VehicleMoreIndicatorWrapper } from '../../styled/VehiclesPage';
import { ActionButtonTooltip } from '../../../../core/components';

interface Props {
  disabled?: boolean;
}

const VehicleMoreIndicator: React.FC<Props> = ({ disabled }) => (
  <VehicleMoreIndicatorWrapper disabled={disabled}>
    <ActionButtonTooltip iconSize="mLarge" icon="chevronCircleDown" />
  </VehicleMoreIndicatorWrapper>
);

export default VehicleMoreIndicator;
