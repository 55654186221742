import React, { ChangeEvent, MouseEvent, PureComponent } from 'react';
import { Checkbox as CheckboxContainer, CheckboxCheck, CheckboxInput, CheckboxText } from './styled';

export interface UnconnectedCheckboxProps {
  block?: boolean;
  checkboxMargin?: string;
  checked?: boolean;
  cursor?: string;
  disabled?: boolean;
  flex?: boolean;
  id?: string;
  label?: string;
  margin?: string;
  name?: string;
  noLabel?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: MouseEvent) => void;
  overflow?: boolean;
  partial?: boolean;
  size?: string;
}

class UnconnectedCheckbox extends PureComponent<UnconnectedCheckboxProps> {
  render() {
    const {
      block,
      checkboxMargin,
      checked,
      cursor,
      disabled,
      flex,
      id,
      label,
      margin,
      name,
      noLabel,
      onChange,
      onClick,
      overflow,
      partial,
      size,
    } = this.props;

    return (
      <CheckboxContainer
        block={block}
        checkboxMargin={checkboxMargin}
        cursor={cursor}
        flex={flex}
        isDisabled={disabled}
        margin={margin}
        noLabel={noLabel}
        onClick={onClick}
        overflow={overflow}
        size={size}
      >
        <CheckboxInput type="checkbox" disabled={disabled} name={name} checked={checked} onChange={onChange} id={id} />
        <CheckboxCheck partial={partial} margin={checkboxMargin} />
        {label && <CheckboxText>{label}</CheckboxText>}
      </CheckboxContainer>
    );
  }
}

export default UnconnectedCheckbox;
