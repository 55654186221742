import { GeoJSONSource } from 'mapbox-gl';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';

import { getCommunityInsightsGeoJSON } from './utils';
import { getFeatureCollection } from 'src/common/components/map/util';
import { setCommunityinsightsMapViewport } from 'src/insights/ducks/mapControl';
import CommunityInsightsClustersGLSource from './CommunityInsightsClustersGLSource';

type Props = {
  map: mapboxgl.Map;
  insights: any[];
};

export const COMMUNITY_INSIGHTS_CLUSTERS_SOURCE = 'communityInsightsClustersSource';
export const COMMUNITY_INSIGHTS_CLUSTERS_LAYER = 'communityInsightsClustersLayer';

export default function CommunityInsightsClustersGL({ map, insights }: Props) {
  const dispatch = useDispatch();

  const geoJson = useMemo(() => {
    let collection = getFeatureCollection<GeoJSON.Point, any>([]);

    if (insights.length) {
      const insightsCollection = getCommunityInsightsGeoJSON(insights);
      collection.features = collection.features.concat(insightsCollection.features);
    }
    return collection;
  }, [insights]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', COMMUNITY_INSIGHTS_CLUSTERS_LAYER, event => {
        const [feature] = map
          .queryRenderedFeatures(event.point, {
            layers: [COMMUNITY_INSIGHTS_CLUSTERS_LAYER],
          })
          .filter(feature => feature.source === COMMUNITY_INSIGHTS_CLUSTERS_SOURCE);

        const clusterId = feature.properties?.cluster_id;
        const source = map.getSource(COMMUNITY_INSIGHTS_CLUSTERS_SOURCE) as GeoJSONSource;

        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          dispatch(
            setCommunityinsightsMapViewport({
              latitude: (feature.geometry as any).coordinates[1],
              longitude: (feature.geometry as any).coordinates[0],
              zoom,
            }),
          );
        });
      });
    });
  }, [map, dispatch]);

  return <CommunityInsightsClustersGLSource geoJSON={geoJson} />;
}
