import { map } from 'lodash-es';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';

const transformLoadWasteTypes = (wasteTypes: any[]) =>
  map(wasteTypes, wasteType => ({
    ...wasteType,
    name: translate(createTranslationKey(wasteType.technicalName, 'common.wasteTypes')),
  }));

export default transformLoadWasteTypes;
