import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { ContainerFacility } from '../interfaces/containers';

import { loadContainerFacilities as doLoadContainerFacilities } from '../services/containers';

// Actions
const START_LOAD = 'fleet/containers/containerFacilities/START_LOAD';
const COMPLETE_LOAD = 'fleet/containers/containerFacilities/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/containers/containerFacilities/FAIL_LOAD';
const RESET = 'fleet/containers/containerFacilities/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  containerFacilities: ContainerFacility[];
} = {
  isLoading: false,
  containerFacilities: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containerFacilities: action.containerFacilities,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containerFacilities: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (containerFacilities: any) => ({
  type: COMPLETE_LOAD,
  containerFacilities,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadContainerFacilities = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadContainerFacilitiesPromise = doLoadContainerFacilities(vendorId);
  loadContainerFacilitiesPromise
    .then((containerFacilities: any) => dispatch(completeLoad(containerFacilities)))
    .catch(() => dispatch(failLoad()));
  return loadContainerFacilitiesPromise;
};

export const resetContainerFacilities = () => ({
  type: RESET,
});
